import Page from "@components/Page";
import { Stack } from "@mui/material";

import MeetingConfirmedSvg from "@components/icons/meeting_confirmed_1.svg";
export default function HomePage() {
  return (
    <Page>
      <Stack
        display="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100%",
          width: "100%",
        }}
      >
        <img src={MeetingConfirmedSvg} alt="Meeting Confirmed" height={200} />
      </Stack>
    </Page>
  );
}
