import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

/* agenda, account_management, availability, managed_availability */
type TrackMeetingViewedParams = {
  lawyerId: UUID;
  campaignMemberMeetingId: UUID;
};

export const trackMeetingViewed = async ({
  lawyerId,
  campaignMemberMeetingId,
}: TrackMeetingViewedParams) => {
  const options: FetchOptions = {
    method: "POST",
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/meetings/${campaignMemberMeetingId}/viewed`,
    options
  );

  return response;
};

export const useTrackMeetingViewed = () =>
  useServiceCall<any, TrackMeetingViewedParams>({
    serviceCall: trackMeetingViewed,
  });
