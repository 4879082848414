import { ApolloProvider } from "@apollo/client";
import { WithAuthenticatorProps } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
import createApolloClient from "@clients/apolloClient";
import { LinearProgress, ThemeProvider, createTheme } from "@mui/material";
import React, { Suspense, useMemo } from "react";
import "./App.css";

import Router from "@components/Router";
import { themeOptions } from "@design/theme";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ROUTE_CREATE_REFERRAL, ROUTE_MEETING } from "./routes";

import HomePage from "@pages/HomePage";
import CampaignMemberMeetingPage from "@pages/meetings/CampaignMemberMeetingPage";
import CreateReferralPage from "@pages/referrals/CreateReferralPage";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { Route, Routes } from "react-router-dom";

TimeAgo.addDefaultLocale(en);

const Loading = () => <LinearProgress color="secondary" />;

const App: React.FC<WithAuthenticatorProps> = () => {
  const theme = createTheme(themeOptions);

  const apolloClient = useMemo(() => {
    return createApolloClient();
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Router>
              <Routes>
                <Route
                  path={ROUTE_MEETING}
                  element={<CampaignMemberMeetingPage />}
                />
                <Route
                  path={ROUTE_CREATE_REFERRAL}
                  element={<CreateReferralPage />}
                />
                <Route path="*" element={<HomePage />} />
              </Routes>
            </Router>
          </LocalizationProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Suspense>
  );
};

export default App;
