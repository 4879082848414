import { Container } from "@mui/material";

interface PageProps {
  children: React.ReactNode;
}

export default function Page({ children }: PageProps) {
  return (
    <Container
      sx={{
        height: "100vh",
        width: "100vw",
      }}
    >
      {children}
    </Container>
  );
}
