import { CampaignMemberMeetingStatus } from "@/models";
import Page from "@components/Page";
import { CheckIcon } from "@components/icons";
import {
  CampaignMemberMeetingProvider,
  useCampaigmMemberMeetingContext as useCampaignMemberMeetingContext,
} from "@context/CampaigmMemberMeetingContext";
import { Chip, Divider, Stack, Typography } from "@mui/material";
import { usePatchCampaignMemberMeeting } from "@services/meetings/patchCampaignMemberMeeting";
import { useTrackMeetingViewed } from "@services/meetings/trackMeetingViewed";
import { getMeetingDisplayDate } from "@utils/date";
import { UUID } from "@utils/text";
import { useEffect, useMemo } from "react";

import MeetingConfirmedSvg from "@components/icons/meeting_confirmed_1.svg";
import { useParams, useSearchParams } from "react-router-dom";

export default function CampaignMemberMeetingPage() {
  const { campaignMemberMeetingId } = useParams();

  return campaignMemberMeetingId ? (
    <CampaignMemberMeetingProvider
      campaignMemberMeetingId={campaignMemberMeetingId as UUID}
    >
      <CampaignMemberMeeting />
    </CampaignMemberMeetingProvider>
  ) : null;
}

function CampaignMemberMeeting() {
  const { campaignMemberMeeting } = useCampaignMemberMeetingContext();

  const lawyerId = campaignMemberMeeting?.campaignMember?.campaign?.lawyer?.id;

  // Get token from URL
  const [searchParams] = useSearchParams();
  const token = useMemo(() => searchParams.get("token"), [searchParams]);

  // Update campaign member meeting with token
  const [patchCampaignMemberMeeting] = usePatchCampaignMemberMeeting();

  useEffect(() => {
    if (!campaignMemberMeeting) return;
    if (!token) return;
    if (!lawyerId) return;

    patchCampaignMemberMeeting({
      lawyerId,
      campaignMemberMeetingId: campaignMemberMeeting.id,
      schedulingToken: token,
    });
  }, [lawyerId, campaignMemberMeeting?.id, token]);

  // Track meeting viewed
  const [trackMeetingViewed, { loading: isTrackingMeetingViewed }] =
    useTrackMeetingViewed();
  useEffect(() => {
    if (!lawyerId) return;
    if (!campaignMemberMeeting?.id) return;

    trackMeetingViewed({
      lawyerId,
      campaignMemberMeetingId: campaignMemberMeeting.id,
    });
  }, [campaignMemberMeeting]);

  // If the meeting is confirmed, show the meeting details
  const isMeetingConfirmed =
    campaignMemberMeeting?.status === CampaignMemberMeetingStatus.CONFIRMED &&
    campaignMemberMeeting.startTime &&
    campaignMemberMeeting.endTime;

  // If the meeting is scheduled, redirect to the scheduling URL
  useEffect(() => {
    if (
      campaignMemberMeeting?.status === "SCHEDULED" &&
      campaignMemberMeeting.schedulingUrl
    ) {
      window.location.href = campaignMemberMeeting.schedulingUrl;
    }
  }, [campaignMemberMeeting]);

  return isMeetingConfirmed ? (
    <Page>
      <Stack
        spacing={2}
        direction="column"
        alignItems="center"
        textAlign={"center"}
        height={"100vh"}
        justifyContent="center"
        bgcolor={"background.info"}
      >
        <img
          src={MeetingConfirmedSvg}
          alt="Meeting Confirmed"
          style={{ width: "100%", maxWidth: "200px" }}
        />
        <Stack direction={"row"} spacing={1} alignItems="center">
          <CheckIcon fontSize="medium" color="success" />
          <Typography variant="h6">Meeting Booked</Typography>
        </Stack>
        <Divider
          sx={{
            width: "100%",
            maxWidth: "300px",
            margin: "auto",
            marginBottom: 2,
            marginTop: 2,
          }}
        />
        <Typography variant="h5">{campaignMemberMeeting.summary}</Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {campaignMemberMeeting.description}
        </Typography>
        <Stack direction={"row"} spacing={1} alignItems="center">
          <Chip
            color="success"
            size="medium"
            label={
              <Typography variant="h6">
                {getMeetingDisplayDate(
                  campaignMemberMeeting.startTime,
                  campaignMemberMeeting.endTime
                )}
              </Typography>
            }
          />
        </Stack>
      </Stack>
    </Page>
  ) : null;
}
