import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { StaticRouter } from "react-router-dom/server";

function Router(props: { children?: React.ReactNode }) {
  const { children } = props;

  if (typeof window === "undefined") {
    return <StaticRouter location={"/"}>{children}</StaticRouter>;
  }

  return <BrowserRouter>{children}</BrowserRouter>;
}

export default Router;
