import { UUID } from "@utils/text";
// Conversation Context

import { VisibilityState as VisibilityStateGraphQL } from "@/graphql/generated";

export enum LinkedinLoginStatus {
  LOGGED_OUT = "LOGGED_OUT",
  LOGGED_IN = "LOGGED_IN",
  WAITING_FOR_VERIFICATION = "WAITING_FOR_VERIFICATION",
  VERIFICATION_FAILED = "VERIFICATION_FAILED",
  INCORRECT_CREDS = "INCORRECT_CREDS",
}

export enum PaymentMethod {
  STRIPE = "STRIPE",
  STRIPE_PAYG = "STRIPE_PAYG",
  OTHER = "OTHER",
}

export enum PaymentSetupStatus {
  NOT_STARTED = "NOT_STARTED",
  REQUIRES_ACTION = "REQUIRES_ACTION",
  REQUIRES_PAYMENT_METHOD = "REQUIRES_PAYMENT_METHOD",
  REQUIRES_CONFIRMATION = "REQUIRES_CONFIRMATION",
  REQUIRES_CAPTURE = "REQUIRES_CAPTURE",
  CANCELED = "CANCELED",
  PROCESSING = "PROCESSING",
  SUCCEEDED = "SUCCEEDED",
}

export enum LinkedinLoginResult {
  SUCCESS = "SUCCESS",
  MISSING_EMAIL = "MISSING_EMAIL",
  MISSING_PASSWORD = "MISSING_PASSWORD",
  MISSING_COUNTRY = "MISSING_COUNTRY",
  INCORRECT_EMAIL = "INCORRECT_EMAIL",
  INCORRECT_PASSWORD = "INCORRECT_PASSWORD",
  INCORRECT_EMAIL_OR_PASSWORD = "INCORRECT_EMAIL_OR_PASSWORD",
  VERIFICATION_CODE = "VERIFICATION_CODE",
  CODE_2FA = "CODE_2FA",
  UNSOLVED_CAPTCHA = "UNSOLVED_CAPTCHA",
  UNKNOWN_FAILURE = "UNKNOWN_FAILURE",
  ID_REQUIRED = "ID_REQUIRED",
}

export enum VisibilityState {
  VISIBLE = "VISIBLE",
  BLURRED = "BLURRED",
  HIDDEN = "HIDDEN",
  FREE = "FREE",
  FREE_EXTRA = "FREE_EXTRA",
}

export const FREE_VISIBILITY_STATES = [
  VisibilityState.FREE,
  VisibilityState.FREE_EXTRA,
];

export const DISPLAYED_VISIBILITY_STATES = [
  VisibilityState.VISIBLE,
  VisibilityState.BLURRED,
  ...FREE_VISIBILITY_STATES,
];
export const DISPLAYED_VISIBILITY_STATES_GRAPHQL =
  DISPLAYED_VISIBILITY_STATES.map(
    (visibility) => visibility.toString() as VisibilityStateGraphQL
  );

export type Profile = {
  id: UUID;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  userId: string;
  photoUrl: string;
  lawyer: Lawyer;
};
export type CronofyAuth = {
  id: UUID;
};

export enum ChargedPer {
  LEAD = "LEAD",
  MEETING = "MEETING",
}

export type Lawyer = {
  id: UUID;
  name?: string;
  email?: string;
  firmName?: string | null;
  status?: string;
  logoSrc?: string | null;
  websiteUrl?: string | null;
  calendlyLink?: string | null;
  liEmail?: string | null;
  liLoginStatus?: LinkedinLoginStatus | null;
  linkedinUrl?: string | null;
  liVerificationCode?: string | null;
  countryCode?: string | null;
  paymentMethod?: PaymentMethod;
  postalCode?: string | null;
  stripeCustomerId?: string | null;
  freeLeads?: number;
  leadsPerMonth?: number;
  pausedUntilTime?: Date | null;
  cronofyAuths?: CronofyAuth[];
  chargedPer?: ChargedPer;
  timezone?: string | null;
};

export type Company = {
  id: UUID;
  name?: string | null;
  websiteUrl?: string | null;
  facebookUrl?: string | null;
  summary?: string | null;
  twitterUrl?: string | null;
  linkedinUrl?: string | null;
  logoUrl?: string | null;
};

export type Contact = {
  id: UUID;
  title?: string | null;
  email?: string | null;
  emailMessages?: OutreachMessage[] | null;
  twitterUrl?: string | null;
  timezone?: string | null;
  photoUrl?: string | null;
  phoneNumber?: string | null;
  linkedinUrl?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  facebookUrl?: string | null;
  summary?: string | null;
  company?: Company | null;
  linkedinMessages?: OutreachMessage[] | null;
  meetingInvitees?: MeetingInvitee[] | null;
};

type MeetingInvitee = {
  id: UUID;
  meeting?: Meeting;
  contact?: Contact;
  rescheduleUrl?: string | null;
  cancelUrl?: string | null;
  calendlyUri?: string | null;
};

/* Messaging */
enum OutreachMessageStatus {
  SCHEDULED = "SCHEDULED",
  SENT = "SENT",
  FAILED = "FAILED",
  CANCELLED = "CANCELLED",
}

export type OutreachMessage = {
  id: UUID;
  isLawyer: boolean;
  intent: string | null;
  repliedIntent: string | null;
  repliedTime: Date | null;
  campaignId: UUID | null;
  campaignMemberId: UUID | null;
  companyId: UUID | null;
  contactId: UUID;
  content?: string | null;
  contentHtml?: string | null;
  forwardedInternallyTime: Date | null;
  forwardedToLawyerTime: Date | null;
  ccLawyerTime: Date | null;
  reviewedTime: Date | null;
  sentTime?: Date;
  subject: string;
  createdTime: Date;
  isReviewed: boolean;
  nylasPayload?: NylasPayload | null;
  senderProfile?: SenderProfile | null;
  template?: Template | null;
  contact?: Contact | null;
  campaign?: Campaign | null;
  campaignMember?: CampaignMember | null;
  company?: Company | null;
  lawyer: Lawyer;
  lawyerViewedTime?: Date | null;
  campaignChannel: CampaignChannel;
  status?: OutreachMessageStatus;
  sendAt?: Date;
  members: CampaignMember[];
};

export type SenderProfile = {
  id: UUID;
  firstName: string | null;
  lastName: string | null;
  profileType: string | null;
  email: string | null;
  photoUrl: string | null | null;
  title: string | null;
  firmName: string | null;
  logoSrc: string | null;
};

export type Template = {
  id: string;
  orderIndex: number;
  content: string | null;
};

export type Email = {
  id: UUID;
  status: string;
};

export type CampaignMember = {
  id: UUID;
  contactId: UUID;
  campaignId?: UUID;
  status: CampaignMemberStatus;
  createdTime?: string | null;
  nextActionTime?: string | null;
  emails?: Array<Email> | null;
  linkedinConnections?: Array<LinkedInConnection> | null;
  contact?: Contact;
  campaign?: Campaign;
  connections?: LinkedInConnection[];
  updatedTime: string;
  feedbacks?: CampaignMemberFeedback[];
  meetings?: CampaignMemberMeeting[];
  charges?: CampaignMemberCharge[];
  lawyerViewedTime?: Date | null;
  emailMessages: OutreachMessage[];
  linkedinMessages: OutreachMessage[];
  visibility: VisibilityState;
};

export enum CampaignMemberMeetingStatus {
  SCHEDULED = "SCHEDULED",
  CONFIRMED = "CONFIRMED",
  HELD = "HELD",
  CANCELLED = "CANCELLED",
}

export type CampaignMemberMeeting = {
  id: UUID;
  status: CampaignMemberMeetingStatus;
  description: string;
  summary: string;
  startTime: Date;
  endTime: Date;
  updatedTime: string;
  campaignMemberId: UUID;
  campaignMember: CampaignMember;
  schedulingUrl: string | null;
};

export type MeetingFeedbackRating =
  | "VERY_BAD"
  | "BAD"
  | "OK"
  | "GOOD"
  | "VERY_GOOD";

export type CampaignMemberFeedback = {
  id: UUID;
  campaignMemberId: UUID;
  note: string;
  rating: MeetingFeedbackRating;
  createdTime: Date;
};

export enum CampaignMemberStatus {
  SCHEDULED_OUTREACH = "SCHEDULED_OUTREACH", // Show
  CONTACTED = "CONTACTED", // Show
  COMPLETED = "COMPLETED", // Show
  REPLIED = "REPLIED", // Show
  REJECTED = "REJECTED", // Do not show
  UNSUBSCRIBED = "UNSUBSCRIBED", // Do not
  WARM_REPLY = "WARM_REPLY", // Show
  SCHEDULING = "SCHEDULING", // Show
  LAWYER_HANDLING = "LAWYER_HANDLING", // Show
  MEETING_BOOKED = "MEETING_BOOKED", // Show
  WORK_BILLED = "WORK_BILLED", // Show
  NEVER_AGAIN = "NEVER_AGAIN", // Do not show
  CANCELED = "CANCELED", // Do not show
}

export const CONTACTED_MEMBER_STATUSES: Array<CampaignMemberStatus> = [
  CampaignMemberStatus.CONTACTED,
  CampaignMemberStatus.REPLIED,
  CampaignMemberStatus.WARM_REPLY,
  CampaignMemberStatus.SCHEDULING,
  CampaignMemberStatus.LAWYER_HANDLING,
  CampaignMemberStatus.MEETING_BOOKED,
  CampaignMemberStatus.WORK_BILLED,
  CampaignMemberStatus.COMPLETED,
];

export type CampaignMemberCreate = {
  contactId: UUID;
  status?: string;
};

export enum CampaignChannel {
  EMAIL = "EMAIL",
  LINKEDIN = "LINKEDIN",
}

export type Campaign = {
  id: UUID;
  name: string;
  subject?: string;
  status: string;
  deleted: boolean;
  createdTime: Date;
  members?: Array<CampaignMember>;
  channel: CampaignChannel;
  emailTemplates: Array<Template>;
  linkedinTemplates: Array<Template>;
  targetingRequest: string;
  targetDescription: string;
  personalisationQuery: string;
  lawyerViewedTime?: Date | null;
  lawyerId: UUID;
  suggestedTargetingId: UUID;
  suggestedTargeting?: SuggestedTargeting | null;
  lawyer?: Lawyer | null;
};

export type WarmReply = {
  degree: string;
  text: string;
  email: string;
};

export type LinkedInConnection = {
  id: number;
  messageText?: string;
  status?: string;
  sendAt?: string;
  campaignMember?: CampaignMember;
};

export type Meeting = {
  id: UUID;
  calendlyUri: string;
  canceledBy: string | null;
  cancelerType: string | null;
  cancellationReason: string | null;
  contactFeedbackRequested: boolean;
  createdTime: string;
  startTime: string;
  endTime: string;
  locationAddress: string | null;
  name: string;
  locationType: string | null;
  locationStatus: string | null;
  invitees: MeetingInvitee[];
};

export type PersonalisationType = {
  id: UUID;
  name: string;
};

export interface PersonalisationQuery {
  rules?: (
    | {
        field: string;
        operator: string;
        value: string[] | string | number;
      }
    | PersonalisationQuery
  )[];
  combinator?: string;
}

export type Prospect = {
  companyId: UUID;
  companyLinkedinUrl: string;
  companyName: string;
  companyPhotoUrl: string;
  companyWebsiteUrl: string;
  contactEmail: string;
  contactFirstName: string;
  contactId: UUID;
  contactLastName: string;
  contactLinkedinUrl: string;
  contactPhotoUrl: string;
  contactTitle: string;
  score: number;
  summary?: string;
  reasoning?: string;
};

export const prospectToContact = (prospect: Prospect): Contact => {
  return {
    id: prospect.contactId,
    email: prospect.contactEmail,
    firstName: prospect.contactFirstName,
    lastName: prospect.contactLastName,
    title: prospect.contactTitle,
    photoUrl: prospect.contactPhotoUrl,
    linkedinUrl: prospect.contactLinkedinUrl,
    summary: prospect.summary,
    company: {
      id: prospect.companyId,
      name: prospect.companyName,
      logoUrl: prospect.companyPhotoUrl,
      websiteUrl: prospect.companyWebsiteUrl,
      linkedinUrl: prospect.companyLinkedinUrl,
    },
  };
};

export type BlacklistedOutreach = {
  id: UUID;
  email?: string;
  emailDomain?: string | null;
  linkedinUrl?: string;
  reason?: string;
};

export type CampaignMemberCharge = {
  id: UUID;
  chargedAt?: Date | null;
  reportedAt?: Date | null;
  stripeInvoiceId?: string | null;
  stripeInvoiceUrl?: string | null;
  paidAt?: Date | null;
  failedAt?: Date | null;

  campaignMemberId?: string; // Assuming UUID is represented as a string
  campaignMember?: CampaignMember | null; // Assuming CampaignMember is defined elsewhere
  refunds?: Array<Refund> | null;
};

export enum RefundStatus {
  REQUESTED = "REQUESTED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export enum RefundApprovalStatus {
  VALID = "VALID",
  WITH_DISPUTE = "WITH_DISPUTE",
}

export type Refund = {
  stripeCreditNoteUrl: string;
  reason: string;
  status: RefundStatus;
  approvalStatus: RefundApprovalStatus;
  note: string;
  id: UUID;
  updatedTime: Date;
  amount: number;
  createdTime: Date;
};

export type Conversation = {
  campaignMemberId: UUID;
  contact: Contact;
  campaign: Campaign;
  visibility: VisibilityState;
  invoiceUrl: string | null;
  updatedTime: Date;
  lastReplyTime: Date;
  isUnread: boolean;
  charge: CampaignMemberCharge;
};

export type EmailAddress = {
  name: string;
  email: string;
};

export type NylasPayload = {
  cc: Array<EmailAddress>;
  to: Array<EmailAddress>;
  bcc: Array<EmailAddress>;
  from: Array<EmailAddress>;
};

export type SuggestedTargeting = {
  id: UUID;
  personalisationQuery: PersonalisationQuery;
  targetCompanyDescription: string;
  focuses: string[];
  title: string;
  minimumCompanyCount?: number;
  companyCount?: number;
};

export type CampaignRequest = {
  id: string;
  createdTime: string;
  updatedTime: string;
  suggestedTargeting: SuggestedTargeting;
}[];
