import { PaletteOptions } from "@mui/material";

export type AppPaletteOptions = PaletteOptions & {
  warmReply: {
    main: string;
  };
  background: {
    info: string; // Define the type for 'info' property
  };
  text: {
    tertiary: string;
  };
};

const palette: AppPaletteOptions = {
  mode: "light",
  primary: {
    main: "#6440ab",
    light: "#7161ab",
    dark: "#240e31",
  },
  secondary: {
    main: "#f04bc4",
    light: "#e373c5",
  },
  error: {
    main: "#ff7189",
  },
  success: {
    main: "#19c37d",
  },
  info: {
    main: "#178ed2",
  },
  warning: {
    main: "#FFD700",
    dark: "#776713",
  },
  text: {
    primary: "#442e59",
    secondary: "#898989",
    tertiary: "#b2b2b2",
  },
  action: {
    active: "#a58dd7",
    hover: "#e0e0e0",
    selected: "#e0e0e0",
    disabled: "#949394",
    disabledBackground: "#e0e0e0",
  },
  background: {
    default: "#6440ab",
    paper: "#ffffff",
    info: "#fafafa",
  },
  warmReply: {
    main: "#fc5b32",
  },
  divider: "#E9E9E9",
};

export default palette as AppPaletteOptions;
