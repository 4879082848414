import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

/* agenda, account_management, availability, managed_availability */
export type ReferralCreate = {
  name: string;
  firmName: string;
  email: string;
  phoneNumber: string;
  icp?: string;
};

type CreateReferralParams = {
  lawyerId: UUID;
} & ReferralCreate;

export const createReferral = async ({
  lawyerId,
  name,
  firmName,
  email,
  phoneNumber,
  icp,
}: CreateReferralParams) => {
  const options: FetchOptions = {
    method: "POST",
    payload: {
      name,
      firmName,
      email,
      phoneNumber,
      icp,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/referrals`,
    options
  );

  return response;
};

export const useCreateReferral = () =>
  useServiceCall<any, CreateReferralParams>({
    serviceCall: createReferral,
  });
