import { useGetLawyerByPublicIdLazyQuery } from "@/graphql/generated";
import { Lawyer } from "@/models";
import {
  CheckIcon,
  CheckSingleIcon,
  JoinWaitlistIcon,
} from "@components/icons";
import LogoIcon from "@components/icons/LogoIcon";
import { LoadingButton } from "@mui/lab";

import WaitlistSvg from "@components/icons/waitlist.svg";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  ReferralCreate,
  useCreateReferral,
} from "@services/referrals/createReferral";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function CreateReferralPage() {
  // Navigate
  const navigate = useNavigate();

  // Get laywer by public id
  const { lawyerPublicId } = useParams();
  const [getLawyerByPublicId, { data: lawyerByPublicIdResponse }] =
    useGetLawyerByPublicIdLazyQuery();

  useEffect(() => {
    if (!lawyerPublicId) return;
    getLawyerByPublicId({
      variables: {
        publicId: lawyerPublicId,
      },
    });
  }, [lawyerPublicId]);

  // Get lawyer id
  const [lawyer, setLawyer] = useState<Lawyer | null>(null);

  useEffect(() => {
    if (!lawyerByPublicIdResponse) return;

    const lawyer = lawyerByPublicIdResponse?.allLawyers?.nodes?.[0];

    if (!lawyer) {
      navigate("/");
      return;
    }

    setLawyer(lawyer);
  }, [lawyerByPublicIdResponse]);

  // Create referral
  const [referralCreate, setReferralCreate] = useState<ReferralCreate>({
    name: "",
    firmName: "",
    email: "",
    phoneNumber: "",
    icp: "",
  });

  const [
    createReferral,
    { data: createReferralResponse, loading: isCreatingReferral },
  ] = useCreateReferral();

  const handleCreateReferral = () => {
    if (!lawyer) return;
    createReferral({
      lawyerId: lawyer.id,
      ...referralCreate,
    });
  };

  const referralCreated = !!createReferralResponse?.id;
  const canSubmit =
    !isCreatingReferral &&
    !referralCreated &&
    referralCreate.name &&
    referralCreate.firmName &&
    referralCreate.email &&
    referralCreate.phoneNumber;

  return lawyer ? (
    <Container maxWidth="md">
      <Stack py={4} spacing={2}>
        <Box color="#6440ab">
          <LogoIcon height={35} />
        </Box>

        <Card elevation={0}>
          <CardHeader
            title="Join Kular today!"
            subheader="Join Kular today and get two leads for free!"
          />
          <CardContent>
            <Stack
              direction={"column"}
              spacing={4}
              alignItems={"flex-start"}
              mb={4}
            >
              <img src={WaitlistSvg} alt="Join Kular" height={300} />
            </Stack>
            <Stack mb={2} spacing={1} direction={"row"} alignItems={"center"}>
              <Typography
                variant="subtitle1"
                paragraph
                color="text.primary"
                fontWeight={700}
              >
                <b>Referred by:</b>
              </Typography>
              <CheckIcon color="success" />
              <Typography variant="subtitle1">
                {lawyer.name} from {lawyer.firmName}
              </Typography>
            </Stack>
            {referralCreated ? (
              <Stack py={4} spacing={2}>
                <Stack
                  direction={"column"}
                  spacing={1}
                  alignItems={"flex-start"}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    <CheckIcon color="success" />
                    <Typography variant="h6">
                      Thank you for your interest in Kular.
                    </Typography>
                  </Stack>

                  <Typography variant="subtitle1" paragraph>
                    We have received your submission and will get back to you as
                    soon as possible.
                  </Typography>
                </Stack>
              </Stack>
            ) : null}
            {!referralCreated ? (
              <form noValidate autoComplete="off">
                <Stack spacing={4}>
                  <FormControl fullWidth disabled={isCreatingReferral}>
                    <Typography variant="subtitle1" paragraph>
                      Please fill out the form below and a Kular representative
                      will contact you to get you two leads for free, no strings
                      attached.
                    </Typography>
                    <Stack spacing={2}>
                      <FormControl fullWidth disabled={isCreatingReferral}>
                        <TextField
                          label="Your Name"
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <CheckSingleIcon
                                  color={
                                    referralCreate.name ? "success" : "disabled"
                                  }
                                />
                              </InputAdornment>
                            ),
                          }}
                          value={referralCreate.name}
                          onChange={(e) =>
                            setReferralCreate({
                              ...referralCreate,
                              name: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                      <FormControl fullWidth disabled={isCreatingReferral}>
                        <TextField
                          label="Firm Name"
                          variant="outlined"
                          value={referralCreate.firmName}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <CheckSingleIcon
                                  color={
                                    referralCreate.firmName
                                      ? "success"
                                      : "disabled"
                                  }
                                />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            setReferralCreate({
                              ...referralCreate,
                              firmName: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                      <FormControl fullWidth disabled={isCreatingReferral}>
                        <TextField
                          label="Email"
                          variant="outlined"
                          value={referralCreate.email}
                          type="email"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <CheckSingleIcon
                                  color={
                                    referralCreate.email
                                      ? "success"
                                      : "disabled"
                                  }
                                />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            setReferralCreate({
                              ...referralCreate,
                              email: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <TextField
                          label="Phone Number"
                          variant="outlined"
                          type="tel"
                          value={referralCreate.phoneNumber}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <CheckSingleIcon
                                  color={
                                    referralCreate.phoneNumber
                                      ? "success"
                                      : "disabled"
                                  }
                                />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) =>
                            setReferralCreate({
                              ...referralCreate,
                              phoneNumber: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Stack>
                  </FormControl>
                  <Stack spacing={2}>
                    <FormControl fullWidth disabled={isCreatingReferral}>
                      <TextField
                        label="Ideal Customer Profile (optional)"
                        placeholder="What is your ideal customer?."
                        value={referralCreate.icp}
                        minRows={4}
                        maxRows={4}
                        multiline
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <CheckSingleIcon
                                color={
                                  referralCreate.icp ? "success" : "disabled"
                                }
                              />
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) =>
                          setReferralCreate({
                            ...referralCreate,
                            icp: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </Stack>
                </Stack>
                <Box mt={2} textAlign={"center"}>
                  <LoadingButton
                    loading={isCreatingReferral}
                    variant="contained"
                    color="success"
                    size="large"
                    disabled={!canSubmit}
                    startIcon={<JoinWaitlistIcon />}
                    onClick={handleCreateReferral}
                    sx={{
                      color: "white",
                    }}
                  >
                    Join Kular
                  </LoadingButton>
                </Box>
              </form>
            ) : null}
          </CardContent>
        </Card>
      </Stack>
    </Container>
  ) : null;
}
