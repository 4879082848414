export type UUID = `${string}-${string}-${string}-${string}-${string}`;
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_DOMAIN_REGEX =
  /^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,6})$/;
export const EMAIL_OR_DOMAIN_REGEX = new RegExp(
  `^${EMAIL_REGEX.source}|${EMAIL_DOMAIN_REGEX.source}$`
);

export const getFullName = (
  ...names: (string | null | undefined)[]
): string => {
  const fullName = names
    ?.filter(Boolean)
    .map((name) => {
      return name?.trim();
    })
    .join(" ");

  return fullName;
};

export const isUUID = (value: string) => {
  return value.match(
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  );
};

export const getFirstNCharacters = (
  emailContent: string,
  n: number
): string => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = emailContent;

  // Get the text without HTML tags
  const plainTextContent =
    tempElement.textContent || tempElement.innerText || "";

  // Get the first n characters of the plain text content
  const firstNCharacters = plainTextContent.slice(0, n);

  return firstNCharacters;
};

export const getNameInitials = (...names: (string | null)[]): string => {
  const fullName = getFullName(...names);
  const nameParts = fullName?.split(" ");
  const nameInitials = nameParts
    ?.filter(Boolean)
    .map((name) => name?.charAt(0).toUpperCase())
    .join("");

  return nameInitials;
};

function camelToTitleCase(input: string): string {
  return input
    .replace(/([A-Z])/g, " $1") // Insert space before uppercase letters
    .replace(/^./, (str) => str.toUpperCase()); // Convert the first letter to uppercase
}

export function camelToTitleCaseAndAddSpaces(input: string): string {
  const spacedWords = camelToTitleCase(input);
  return spacedWords.replace(/([A-Z])/g, " $1"); // Add space before uppercase letters (including the first one)
}

export const camelToSnakeCase = (key: string): string => {
  return key.replace(/([A-Z])/g, "_$1").toLowerCase();
};

export const snakeToCamelCase = (key: string): string => {
  return key.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace("-", "").replace("_", "")
  );
};

export const calculateReadTimeInMilliSeconds = (text: string): number => {
  const wordsPerMinute = 200;
  const numberOfWords = text?.split(/\s/g).length;
  return Math.ceil(60 * (numberOfWords / wordsPerMinute)) * 1500;
};

export const joinRoutePath = (...paths: string[]) => {
  // Remove leading and trailing slashes from each path segment
  const cleanedPaths = paths.map((segment) => segment.replace(/^\/|\/$/g, ""));

  // Join the cleaned path segments with a single slash
  return `/${cleanedPaths.join("/")}`;
};

export const snakeCaseToDisplayText = (snakeCaseString: string): string => {
  // Split the snake_caseString into an array of words
  const words = snakeCaseString.split("_");

  // Capitalize each word and join them with spaces
  const displayText = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return displayText;
};

export const capitalizeFirstLetter = (word: string): string => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export function scrambleWords(text: string | undefined | null): string {
  if (!text) {
    return "";
  }

  const words = text.split(" ").map(scrambleWord);
  return words.join(" ");
}

const scrambleWord = (word: string): string => {
  if (word.length <= 2) {
    return word; // Preserve short words
  }

  const characters = word.split("");
  for (let i = characters.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [characters[i], characters[j]] = [characters[j], characters[i]]; // Swap characters
  }

  return characters.join("");
};
