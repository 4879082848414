import { useServiceCall } from "@hooks/useServiceCall";
import { FetchOptions, fetchOutreachService } from "@utils/requests";
import { UUID } from "@utils/text";

/* agenda, account_management, availability, managed_availability */
type PatchCampaignMemberMeetingParams = {
  lawyerId: UUID;
  campaignMemberMeetingId: UUID;
  schedulingToken: string;
};

export const patchCampaignMemberMeeting = async ({
  lawyerId,
  campaignMemberMeetingId,
  schedulingToken,
}: PatchCampaignMemberMeetingParams) => {
  const options: FetchOptions = {
    method: "PATCH",
    payload: {
      schedulingToken,
    },
  };

  const response = await fetchOutreachService(
    `/lawyers/${lawyerId}/meetings/${campaignMemberMeetingId}`,
    options
  );

  return response;
};

export const usePatchCampaignMemberMeeting = () =>
  useServiceCall<any, PatchCampaignMemberMeetingParams>({
    serviceCall: patchCampaignMemberMeeting,
  });
