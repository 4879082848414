import "@aws-amplify/ui-react/styles.css";

export const ROUTE_DASHBOARD = "/";
export const ROUTE_MEETING = "/meetings/:campaignMemberMeetingId";
export const ROUTE_CREATE_REFERRAL = "/referrals/:lawyerPublicId";

export const getRoute = (
  route: string,
  params?: Record<string, any> | null,
  hash?: string
) => {
  let returnRoute = route;

  if (params) {
    returnRoute = Object.keys(params).reduce((acc, param) => {
      return acc.replace(`:${param}`, params[param]);
    }, route);
  }

  // Add hash to route
  if (hash) {
    returnRoute = `${returnRoute}#${hash}`;
  }

  return returnRoute;
};
