import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigFloat: { input: any; output: any; }
  BigInt: { input: any; output: any; }
  Cursor: { input: any; output: any; }
  Datetime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigInt']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigInt']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigInt']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigInt']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigInt']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type BlacklistedOutreach = Node & {
  __typename?: 'BlacklistedOutreach';
  createdTime: Scalars['Datetime']['output'];
  deleted: Scalars['Boolean']['output'];
  email?: Maybe<Scalars['String']['output']>;
  emailDomain?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Lawyer` that is related to this `BlacklistedOutreach`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId?: Maybe<Scalars['UUID']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  updatedTime: Scalars['Datetime']['output'];
};

export type BlacklistedOutreachAggregates = {
  __typename?: 'BlacklistedOutreachAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BlacklistedOutreachDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `BlacklistedOutreach` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type BlacklistedOutreachCondition = {
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `emailDomain` field. */
  emailDomain?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `reason` field. */
  reason?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type BlacklistedOutreachDistinctCountAggregates = {
  __typename?: 'BlacklistedOutreachDistinctCountAggregates';
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emailDomain across the matching connection */
  emailDomain?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of linkedinUrl across the matching connection */
  linkedinUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of reason across the matching connection */
  reason?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `BlacklistedOutreach` object types. All fields are combined with a logical ‘and.’ */
export type BlacklistedOutreachFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BlacklistedOutreachFilter>>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emailDomain` field. */
  emailDomain?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BlacklistedOutreachFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BlacklistedOutreachFilter>>;
  /** Filter by the object’s `reason` field. */
  reason?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `BlacklistedOutreach` for usage during aggregation. */
export enum BlacklistedOutreachGroupBy {
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  Deleted = 'DELETED',
  Email = 'EMAIL',
  EmailDomain = 'EMAIL_DOMAIN',
  LawyerId = 'LAWYER_ID',
  LinkedinUrl = 'LINKEDIN_URL',
  Reason = 'REASON',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type BlacklistedOutreachHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type BlacklistedOutreachHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `BlacklistedOutreach` aggregates. */
export type BlacklistedOutreachHavingInput = {
  AND?: InputMaybe<Array<BlacklistedOutreachHavingInput>>;
  OR?: InputMaybe<Array<BlacklistedOutreachHavingInput>>;
  average?: InputMaybe<BlacklistedOutreachHavingAverageInput>;
  distinctCount?: InputMaybe<BlacklistedOutreachHavingDistinctCountInput>;
  max?: InputMaybe<BlacklistedOutreachHavingMaxInput>;
  min?: InputMaybe<BlacklistedOutreachHavingMinInput>;
  stddevPopulation?: InputMaybe<BlacklistedOutreachHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BlacklistedOutreachHavingStddevSampleInput>;
  sum?: InputMaybe<BlacklistedOutreachHavingSumInput>;
  variancePopulation?: InputMaybe<BlacklistedOutreachHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BlacklistedOutreachHavingVarianceSampleInput>;
};

export type BlacklistedOutreachHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type BlacklistedOutreachHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type BlacklistedOutreachHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type BlacklistedOutreachHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type BlacklistedOutreachHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type BlacklistedOutreachHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type BlacklistedOutreachHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type BlacklistedOutreachSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: BlacklistedOutreachSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type BlacklistedOutreachSortBy = {
  field?: InputMaybe<BlacklistedOutreachSortableField>;
};

/** Sortable concrete fields for the `BlacklistedOutreach` type. */
export enum BlacklistedOutreachSortableField {
  CreatedTime = 'CREATED_TIME',
  Deleted = 'DELETED',
  Email = 'EMAIL',
  EmailDomain = 'EMAIL_DOMAIN',
  Id = 'ID',
  LawyerId = 'LAWYER_ID',
  LinkedinUrl = 'LINKEDIN_URL',
  Reason = 'REASON',
  UpdatedTime = 'UPDATED_TIME'
}

/** A connection to a list of `BlacklistedOutreach` values. */
export type BlacklistedOutreachesConnection = {
  __typename?: 'BlacklistedOutreachesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BlacklistedOutreachAggregates>;
  /** A list of edges which contains the `BlacklistedOutreach` and cursor to aid in pagination. */
  edges: Array<BlacklistedOutreachesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BlacklistedOutreachAggregates>>;
  /** A list of `BlacklistedOutreach` objects. */
  nodes: Array<Maybe<BlacklistedOutreach>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BlacklistedOutreach` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `BlacklistedOutreach` values. */
export type BlacklistedOutreachesConnectionGroupedAggregatesArgs = {
  groupBy: Array<BlacklistedOutreachGroupBy>;
  having?: InputMaybe<BlacklistedOutreachHavingInput>;
};

/** A `BlacklistedOutreach` edge in the connection. */
export type BlacklistedOutreachesEdge = {
  __typename?: 'BlacklistedOutreachesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `BlacklistedOutreach` at the end of the edge. */
  node?: Maybe<BlacklistedOutreach>;
};

/** Methods to use when ordering `BlacklistedOutreach`. */
export enum BlacklistedOutreachesOrderBy {
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  DeletedAsc = 'DELETED_ASC',
  DeletedDesc = 'DELETED_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  EmailDomainAsc = 'EMAIL_DOMAIN_ASC',
  EmailDomainDesc = 'EMAIL_DOMAIN_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  LinkedinUrlAsc = 'LINKEDIN_URL_ASC',
  LinkedinUrlDesc = 'LINKEDIN_URL_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReasonAsc = 'REASON_ASC',
  ReasonDesc = 'REASON_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type Campaign = Node & {
  __typename?: 'Campaign';
  activatedTime?: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `CampaignMember`. */
  campaignMembersByCampaignId: CampaignMembersConnection;
  campaignTargetId?: Maybe<Scalars['UUID']['output']>;
  channel: Scalars['String']['output'];
  conversionScore?: Maybe<Scalars['Float']['output']>;
  createdTime?: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `DailyStatistic`. */
  dailyStatisticsByCampaignId: DailyStatisticsConnection;
  deactivatedTime?: Maybe<Scalars['Datetime']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  draftedByLawyer?: Maybe<Scalars['Boolean']['output']>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByCampaignId: EmailMessagesConnection;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  isPersonalised: Scalars['Boolean']['output'];
  isSalesFunnelCampaign: Scalars['Boolean']['output'];
  isTestMode: Scalars['Boolean']['output'];
  /** Reads a single `Lawyer` that is related to this `Campaign`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId?: Maybe<Scalars['UUID']['output']>;
  lawyerViewedTime?: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `LinkedinScheduledMessage`. */
  linkedinScheduledMessagesByCampaignId: LinkedinScheduledMessagesConnection;
  /** Reads and enables pagination through a set of `LinkedinTemplate`. */
  linkedinTemplatesByCampaignId: LinkedinTemplatesConnection;
  maxAllocation?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  patentQuery?: Maybe<Scalars['JSON']['output']>;
  personalisationQuery?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `PersonalisationStatistic`. */
  personalisationStatisticsByCampaignId: PersonalisationStatisticsConnection;
  priority?: Maybe<Scalars['Boolean']['output']>;
  reviewedBy?: Maybe<Scalars['String']['output']>;
  reviewedTime?: Maybe<Scalars['Datetime']['output']>;
  scheduledActivationTime?: Maybe<Scalars['Datetime']['output']>;
  searchAfter?: Maybe<Scalars['Datetime']['output']>;
  searchAfterScore?: Maybe<Scalars['Float']['output']>;
  searchBefore?: Maybe<Scalars['Datetime']['output']>;
  searchStarted?: Maybe<Scalars['Datetime']['output']>;
  status: CampaignStatus;
  subject?: Maybe<Scalars['String']['output']>;
  /** Reads a single `SuggestedTargeting` that is related to this `Campaign`. */
  suggestedTargetingBySuggestedTargetingId?: Maybe<SuggestedTargeting>;
  suggestedTargetingId?: Maybe<Scalars['UUID']['output']>;
  targetDescription?: Maybe<Scalars['String']['output']>;
  targetingRequest?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Template`. */
  templatesByCampaignId: TemplatesConnection;
  updatedTime?: Maybe<Scalars['Datetime']['output']>;
  useLlmFilter?: Maybe<Scalars['Boolean']['output']>;
  vectorMatchThreshold?: Maybe<Scalars['Float']['output']>;
};


export type CampaignCampaignMembersByCampaignIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignMemberCondition>;
  filter?: InputMaybe<CampaignMemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignMembersOrderBy>>;
  sort?: InputMaybe<Array<CampaignMemberSort>>;
};


export type CampaignDailyStatisticsByCampaignIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DailyStatisticCondition>;
  filter?: InputMaybe<DailyStatisticFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DailyStatisticsOrderBy>>;
  sort?: InputMaybe<Array<DailyStatisticsSort>>;
};


export type CampaignEmailMessagesByCampaignIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailMessageCondition>;
  filter?: InputMaybe<EmailMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailMessagesOrderBy>>;
  sort?: InputMaybe<Array<EmailMessageSort>>;
};


export type CampaignLinkedinScheduledMessagesByCampaignIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinScheduledMessageCondition>;
  filter?: InputMaybe<LinkedinScheduledMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinScheduledMessagesOrderBy>>;
  sort?: InputMaybe<Array<LinkedinScheduledMessageSort>>;
};


export type CampaignLinkedinTemplatesByCampaignIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinTemplateCondition>;
  filter?: InputMaybe<LinkedinTemplateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinTemplatesOrderBy>>;
  sort?: InputMaybe<Array<LinkedinTemplateSort>>;
};


export type CampaignPersonalisationStatisticsByCampaignIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PersonalisationStatisticCondition>;
  filter?: InputMaybe<PersonalisationStatisticFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonalisationStatisticsOrderBy>>;
  sort?: InputMaybe<Array<PersonalisationStatisticsSort>>;
};


export type CampaignTemplatesByCampaignIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TemplateCondition>;
  filter?: InputMaybe<TemplateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TemplatesOrderBy>>;
  sort?: InputMaybe<Array<TemplateSort>>;
};

export type CampaignAggregates = {
  __typename?: 'CampaignAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CampaignAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CampaignDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CampaignMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CampaignMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CampaignStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CampaignStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CampaignSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CampaignVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CampaignVarianceSampleAggregates>;
};

export type CampaignAverageAggregates = {
  __typename?: 'CampaignAverageAggregates';
  /** Mean average of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Mean average of maxAllocation across the matching connection */
  maxAllocation?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of searchAfterScore across the matching connection */
  searchAfterScore?: Maybe<Scalars['Float']['output']>;
  /** Mean average of vectorMatchThreshold across the matching connection */
  vectorMatchThreshold?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `Campaign` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CampaignCondition = {
  /** Checks for equality with the object’s `activatedTime` field. */
  activatedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `campaignTargetId` field. */
  campaignTargetId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `channel` field. */
  channel?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `conversionScore` field. */
  conversionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deactivatedTime` field. */
  deactivatedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `draftedByLawyer` field. */
  draftedByLawyer?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `isPersonalised` field. */
  isPersonalised?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isSalesFunnelCampaign` field. */
  isSalesFunnelCampaign?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTestMode` field. */
  isTestMode?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerViewedTime` field. */
  lawyerViewedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `maxAllocation` field. */
  maxAllocation?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `patentQuery` field. */
  patentQuery?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `personalisationQuery` field. */
  personalisationQuery?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `reviewedBy` field. */
  reviewedBy?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `reviewedTime` field. */
  reviewedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `scheduledActivationTime` field. */
  scheduledActivationTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `searchAfter` field. */
  searchAfter?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `searchAfterScore` field. */
  searchAfterScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `searchBefore` field. */
  searchBefore?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `searchStarted` field. */
  searchStarted?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<CampaignStatus>;
  /** Checks for equality with the object’s `subject` field. */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `suggestedTargetingId` field. */
  suggestedTargetingId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `targetDescription` field. */
  targetDescription?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `targetingRequest` field. */
  targetingRequest?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `useLlmFilter` field. */
  useLlmFilter?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `vectorMatchThreshold` field. */
  vectorMatchThreshold?: InputMaybe<Scalars['Float']['input']>;
};

export type CampaignDistinctCountAggregates = {
  __typename?: 'CampaignDistinctCountAggregates';
  /** Distinct count of activatedTime across the matching connection */
  activatedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of campaignTargetId across the matching connection */
  campaignTargetId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of channel across the matching connection */
  channel?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of deactivatedTime across the matching connection */
  deactivatedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of draftedByLawyer across the matching connection */
  draftedByLawyer?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isPersonalised across the matching connection */
  isPersonalised?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isSalesFunnelCampaign across the matching connection */
  isSalesFunnelCampaign?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isTestMode across the matching connection */
  isTestMode?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerViewedTime across the matching connection */
  lawyerViewedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of maxAllocation across the matching connection */
  maxAllocation?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of notes across the matching connection */
  notes?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of patentQuery across the matching connection */
  patentQuery?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of personalisationQuery across the matching connection */
  personalisationQuery?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of priority across the matching connection */
  priority?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of reviewedBy across the matching connection */
  reviewedBy?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of reviewedTime across the matching connection */
  reviewedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of scheduledActivationTime across the matching connection */
  scheduledActivationTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of searchAfter across the matching connection */
  searchAfter?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of searchAfterScore across the matching connection */
  searchAfterScore?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of searchBefore across the matching connection */
  searchBefore?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of searchStarted across the matching connection */
  searchStarted?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of subject across the matching connection */
  subject?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of suggestedTargetingId across the matching connection */
  suggestedTargetingId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of targetDescription across the matching connection */
  targetDescription?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of targetingRequest across the matching connection */
  targetingRequest?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of useLlmFilter across the matching connection */
  useLlmFilter?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of vectorMatchThreshold across the matching connection */
  vectorMatchThreshold?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Campaign` object types. All fields are combined with a logical ‘and.’ */
export type CampaignFilter = {
  /** Filter by the object’s `activatedTime` field. */
  activatedTime?: InputMaybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CampaignFilter>>;
  /** Filter by the object’s `campaignTargetId` field. */
  campaignTargetId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `channel` field. */
  channel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `conversionScore` field. */
  conversionScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deactivatedTime` field. */
  deactivatedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `draftedByLawyer` field. */
  draftedByLawyer?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isPersonalised` field. */
  isPersonalised?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSalesFunnelCampaign` field. */
  isSalesFunnelCampaign?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTestMode` field. */
  isTestMode?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerViewedTime` field. */
  lawyerViewedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `maxAllocation` field. */
  maxAllocation?: InputMaybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CampaignFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CampaignFilter>>;
  /** Filter by the object’s `personalisationQuery` field. */
  personalisationQuery?: InputMaybe<StringFilter>;
  /** Filter by the object’s `priority` field. */
  priority?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `reviewedBy` field. */
  reviewedBy?: InputMaybe<StringFilter>;
  /** Filter by the object’s `reviewedTime` field. */
  reviewedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `scheduledActivationTime` field. */
  scheduledActivationTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `searchAfter` field. */
  searchAfter?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `searchAfterScore` field. */
  searchAfterScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `searchBefore` field. */
  searchBefore?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `searchStarted` field. */
  searchStarted?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<CampaignStatusFilter>;
  /** Filter by the object’s `subject` field. */
  subject?: InputMaybe<StringFilter>;
  /** Filter by the object’s `suggestedTargetingId` field. */
  suggestedTargetingId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `targetDescription` field. */
  targetDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `targetingRequest` field. */
  targetingRequest?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `useLlmFilter` field. */
  useLlmFilter?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `vectorMatchThreshold` field. */
  vectorMatchThreshold?: InputMaybe<FloatFilter>;
};

/** Grouping methods for `Campaign` for usage during aggregation. */
export enum CampaignGroupBy {
  ActivatedTime = 'ACTIVATED_TIME',
  ActivatedTimeTruncatedToDay = 'ACTIVATED_TIME_TRUNCATED_TO_DAY',
  ActivatedTimeTruncatedToHour = 'ACTIVATED_TIME_TRUNCATED_TO_HOUR',
  CampaignTargetId = 'CAMPAIGN_TARGET_ID',
  Channel = 'CHANNEL',
  ConversionScore = 'CONVERSION_SCORE',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  DeactivatedTime = 'DEACTIVATED_TIME',
  DeactivatedTimeTruncatedToDay = 'DEACTIVATED_TIME_TRUNCATED_TO_DAY',
  DeactivatedTimeTruncatedToHour = 'DEACTIVATED_TIME_TRUNCATED_TO_HOUR',
  Deleted = 'DELETED',
  DraftedByLawyer = 'DRAFTED_BY_LAWYER',
  IsPersonalised = 'IS_PERSONALISED',
  IsSalesFunnelCampaign = 'IS_SALES_FUNNEL_CAMPAIGN',
  IsTestMode = 'IS_TEST_MODE',
  LawyerId = 'LAWYER_ID',
  LawyerViewedTime = 'LAWYER_VIEWED_TIME',
  LawyerViewedTimeTruncatedToDay = 'LAWYER_VIEWED_TIME_TRUNCATED_TO_DAY',
  LawyerViewedTimeTruncatedToHour = 'LAWYER_VIEWED_TIME_TRUNCATED_TO_HOUR',
  MaxAllocation = 'MAX_ALLOCATION',
  Name = 'NAME',
  Notes = 'NOTES',
  PatentQuery = 'PATENT_QUERY',
  PersonalisationQuery = 'PERSONALISATION_QUERY',
  Priority = 'PRIORITY',
  ReviewedBy = 'REVIEWED_BY',
  ReviewedTime = 'REVIEWED_TIME',
  ReviewedTimeTruncatedToDay = 'REVIEWED_TIME_TRUNCATED_TO_DAY',
  ReviewedTimeTruncatedToHour = 'REVIEWED_TIME_TRUNCATED_TO_HOUR',
  ScheduledActivationTime = 'SCHEDULED_ACTIVATION_TIME',
  ScheduledActivationTimeTruncatedToDay = 'SCHEDULED_ACTIVATION_TIME_TRUNCATED_TO_DAY',
  ScheduledActivationTimeTruncatedToHour = 'SCHEDULED_ACTIVATION_TIME_TRUNCATED_TO_HOUR',
  SearchAfter = 'SEARCH_AFTER',
  SearchAfterScore = 'SEARCH_AFTER_SCORE',
  SearchAfterTruncatedToDay = 'SEARCH_AFTER_TRUNCATED_TO_DAY',
  SearchAfterTruncatedToHour = 'SEARCH_AFTER_TRUNCATED_TO_HOUR',
  SearchBefore = 'SEARCH_BEFORE',
  SearchBeforeTruncatedToDay = 'SEARCH_BEFORE_TRUNCATED_TO_DAY',
  SearchBeforeTruncatedToHour = 'SEARCH_BEFORE_TRUNCATED_TO_HOUR',
  SearchStarted = 'SEARCH_STARTED',
  SearchStartedTruncatedToDay = 'SEARCH_STARTED_TRUNCATED_TO_DAY',
  SearchStartedTruncatedToHour = 'SEARCH_STARTED_TRUNCATED_TO_HOUR',
  Status = 'STATUS',
  Subject = 'SUBJECT',
  SuggestedTargetingId = 'SUGGESTED_TARGETING_ID',
  TargetingRequest = 'TARGETING_REQUEST',
  TargetDescription = 'TARGET_DESCRIPTION',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR',
  UseLlmFilter = 'USE_LLM_FILTER',
  VectorMatchThreshold = 'VECTOR_MATCH_THRESHOLD'
}

export type CampaignHavingAverageInput = {
  activatedTime?: InputMaybe<HavingDatetimeFilter>;
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  deactivatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  maxAllocation?: InputMaybe<HavingIntFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  scheduledActivationTime?: InputMaybe<HavingDatetimeFilter>;
  searchAfter?: InputMaybe<HavingDatetimeFilter>;
  searchAfterScore?: InputMaybe<HavingFloatFilter>;
  searchBefore?: InputMaybe<HavingDatetimeFilter>;
  searchStarted?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  vectorMatchThreshold?: InputMaybe<HavingFloatFilter>;
};

export type CampaignHavingDistinctCountInput = {
  activatedTime?: InputMaybe<HavingDatetimeFilter>;
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  deactivatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  maxAllocation?: InputMaybe<HavingIntFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  scheduledActivationTime?: InputMaybe<HavingDatetimeFilter>;
  searchAfter?: InputMaybe<HavingDatetimeFilter>;
  searchAfterScore?: InputMaybe<HavingFloatFilter>;
  searchBefore?: InputMaybe<HavingDatetimeFilter>;
  searchStarted?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  vectorMatchThreshold?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `Campaign` aggregates. */
export type CampaignHavingInput = {
  AND?: InputMaybe<Array<CampaignHavingInput>>;
  OR?: InputMaybe<Array<CampaignHavingInput>>;
  average?: InputMaybe<CampaignHavingAverageInput>;
  distinctCount?: InputMaybe<CampaignHavingDistinctCountInput>;
  max?: InputMaybe<CampaignHavingMaxInput>;
  min?: InputMaybe<CampaignHavingMinInput>;
  stddevPopulation?: InputMaybe<CampaignHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CampaignHavingStddevSampleInput>;
  sum?: InputMaybe<CampaignHavingSumInput>;
  variancePopulation?: InputMaybe<CampaignHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CampaignHavingVarianceSampleInput>;
};

export type CampaignHavingMaxInput = {
  activatedTime?: InputMaybe<HavingDatetimeFilter>;
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  deactivatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  maxAllocation?: InputMaybe<HavingIntFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  scheduledActivationTime?: InputMaybe<HavingDatetimeFilter>;
  searchAfter?: InputMaybe<HavingDatetimeFilter>;
  searchAfterScore?: InputMaybe<HavingFloatFilter>;
  searchBefore?: InputMaybe<HavingDatetimeFilter>;
  searchStarted?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  vectorMatchThreshold?: InputMaybe<HavingFloatFilter>;
};

export type CampaignHavingMinInput = {
  activatedTime?: InputMaybe<HavingDatetimeFilter>;
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  deactivatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  maxAllocation?: InputMaybe<HavingIntFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  scheduledActivationTime?: InputMaybe<HavingDatetimeFilter>;
  searchAfter?: InputMaybe<HavingDatetimeFilter>;
  searchAfterScore?: InputMaybe<HavingFloatFilter>;
  searchBefore?: InputMaybe<HavingDatetimeFilter>;
  searchStarted?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  vectorMatchThreshold?: InputMaybe<HavingFloatFilter>;
};

export type CampaignHavingStddevPopulationInput = {
  activatedTime?: InputMaybe<HavingDatetimeFilter>;
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  deactivatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  maxAllocation?: InputMaybe<HavingIntFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  scheduledActivationTime?: InputMaybe<HavingDatetimeFilter>;
  searchAfter?: InputMaybe<HavingDatetimeFilter>;
  searchAfterScore?: InputMaybe<HavingFloatFilter>;
  searchBefore?: InputMaybe<HavingDatetimeFilter>;
  searchStarted?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  vectorMatchThreshold?: InputMaybe<HavingFloatFilter>;
};

export type CampaignHavingStddevSampleInput = {
  activatedTime?: InputMaybe<HavingDatetimeFilter>;
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  deactivatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  maxAllocation?: InputMaybe<HavingIntFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  scheduledActivationTime?: InputMaybe<HavingDatetimeFilter>;
  searchAfter?: InputMaybe<HavingDatetimeFilter>;
  searchAfterScore?: InputMaybe<HavingFloatFilter>;
  searchBefore?: InputMaybe<HavingDatetimeFilter>;
  searchStarted?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  vectorMatchThreshold?: InputMaybe<HavingFloatFilter>;
};

export type CampaignHavingSumInput = {
  activatedTime?: InputMaybe<HavingDatetimeFilter>;
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  deactivatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  maxAllocation?: InputMaybe<HavingIntFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  scheduledActivationTime?: InputMaybe<HavingDatetimeFilter>;
  searchAfter?: InputMaybe<HavingDatetimeFilter>;
  searchAfterScore?: InputMaybe<HavingFloatFilter>;
  searchBefore?: InputMaybe<HavingDatetimeFilter>;
  searchStarted?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  vectorMatchThreshold?: InputMaybe<HavingFloatFilter>;
};

export type CampaignHavingVariancePopulationInput = {
  activatedTime?: InputMaybe<HavingDatetimeFilter>;
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  deactivatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  maxAllocation?: InputMaybe<HavingIntFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  scheduledActivationTime?: InputMaybe<HavingDatetimeFilter>;
  searchAfter?: InputMaybe<HavingDatetimeFilter>;
  searchAfterScore?: InputMaybe<HavingFloatFilter>;
  searchBefore?: InputMaybe<HavingDatetimeFilter>;
  searchStarted?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  vectorMatchThreshold?: InputMaybe<HavingFloatFilter>;
};

export type CampaignHavingVarianceSampleInput = {
  activatedTime?: InputMaybe<HavingDatetimeFilter>;
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  deactivatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  maxAllocation?: InputMaybe<HavingIntFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  scheduledActivationTime?: InputMaybe<HavingDatetimeFilter>;
  searchAfter?: InputMaybe<HavingDatetimeFilter>;
  searchAfterScore?: InputMaybe<HavingFloatFilter>;
  searchBefore?: InputMaybe<HavingDatetimeFilter>;
  searchStarted?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  vectorMatchThreshold?: InputMaybe<HavingFloatFilter>;
};

export type CampaignMaxAggregates = {
  __typename?: 'CampaignMaxAggregates';
  /** Maximum of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Maximum of maxAllocation across the matching connection */
  maxAllocation?: Maybe<Scalars['Int']['output']>;
  /** Maximum of searchAfterScore across the matching connection */
  searchAfterScore?: Maybe<Scalars['Float']['output']>;
  /** Maximum of vectorMatchThreshold across the matching connection */
  vectorMatchThreshold?: Maybe<Scalars['Float']['output']>;
};

export type CampaignMember = Node & {
  __typename?: 'CampaignMember';
  /** Reads a single `Campaign` that is related to this `CampaignMember`. */
  campaignByCampaignId?: Maybe<Campaign>;
  campaignId: Scalars['UUID']['output'];
  /** Reads and enables pagination through a set of `CampaignMemberCharge`. */
  campaignMemberChargesByCampaignMemberId: CampaignMemberChargesConnection;
  /** Reads and enables pagination through a set of `CampaignMemberFeedback`. */
  campaignMemberFeedbacksByCampaignMemberId: CampaignMemberFeedbacksConnection;
  /** Reads and enables pagination through a set of `CampaignMemberMeeting`. */
  campaignMemberMeetingsByCampaignMemberId: CampaignMemberMeetingsConnection;
  /** Reads a single `Contact` that is related to this `CampaignMember`. */
  contactByContactId?: Maybe<Contact>;
  contactId: Scalars['UUID']['output'];
  createdTime: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByCampaignMemberId: EmailMessagesConnection;
  /** Reads and enables pagination through a set of `Email`. */
  emailsByCampaignMemberId: EmailsConnection;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  includeLawyer: Scalars['Boolean']['output'];
  isConversationTime?: Maybe<Scalars['Datetime']['output']>;
  latestEmailMessageCreatedTime?: Maybe<Scalars['Datetime']['output']>;
  lawyerViewedTime?: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `LinkedinConnectionRequest`. */
  linkedinConnectionRequestsByCampaignMemberId: LinkedinConnectionRequestsConnection;
  /** Reads and enables pagination through a set of `LinkedinEvent`. */
  linkedinEventsByCampaignMemberId: LinkedinEventsConnection;
  /** Reads and enables pagination through a set of `LinkedinMessage`. */
  linkedinMessagesByCampaignMemberId: LinkedinMessagesConnection;
  /** Reads and enables pagination through a set of `LinkedinScheduledMessage`. */
  linkedinScheduledMessagesByCampaignMemberId: LinkedinScheduledMessagesConnection;
  natSequenceCreatedTime?: Maybe<Scalars['Datetime']['output']>;
  nextActionTime?: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  status?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Trace`. */
  tracesByCampaignMemberId: TracesConnection;
  updatedTime: Scalars['Datetime']['output'];
  visibility?: Maybe<VisibilityState>;
};


export type CampaignMemberCampaignMemberChargesByCampaignMemberIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignMemberChargeCondition>;
  filter?: InputMaybe<CampaignMemberChargeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignMemberChargesOrderBy>>;
  sort?: InputMaybe<Array<CampaignMemberChargeSort>>;
};


export type CampaignMemberCampaignMemberFeedbacksByCampaignMemberIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignMemberFeedbackCondition>;
  filter?: InputMaybe<CampaignMemberFeedbackFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignMemberFeedbacksOrderBy>>;
  sort?: InputMaybe<Array<CampaignMemberFeedbackSort>>;
};


export type CampaignMemberCampaignMemberMeetingsByCampaignMemberIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignMemberMeetingCondition>;
  filter?: InputMaybe<CampaignMemberMeetingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignMemberMeetingsOrderBy>>;
  sort?: InputMaybe<Array<CampaignMemberMeetingSort>>;
};


export type CampaignMemberEmailMessagesByCampaignMemberIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailMessageCondition>;
  filter?: InputMaybe<EmailMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailMessagesOrderBy>>;
  sort?: InputMaybe<Array<EmailMessageSort>>;
};


export type CampaignMemberEmailsByCampaignMemberIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailCondition>;
  filter?: InputMaybe<EmailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailsOrderBy>>;
  sort?: InputMaybe<Array<EmailSort>>;
};


export type CampaignMemberLinkedinConnectionRequestsByCampaignMemberIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinConnectionRequestCondition>;
  filter?: InputMaybe<LinkedinConnectionRequestFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinConnectionRequestsOrderBy>>;
  sort?: InputMaybe<Array<LinkedinConnectionRequestSort>>;
};


export type CampaignMemberLinkedinEventsByCampaignMemberIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinEventCondition>;
  filter?: InputMaybe<LinkedinEventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinEventsOrderBy>>;
  sort?: InputMaybe<Array<LinkedinEventSort>>;
};


export type CampaignMemberLinkedinMessagesByCampaignMemberIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinMessageCondition>;
  filter?: InputMaybe<LinkedinMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinMessagesOrderBy>>;
  sort?: InputMaybe<Array<LinkedinMessageSort>>;
};


export type CampaignMemberLinkedinScheduledMessagesByCampaignMemberIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinScheduledMessageCondition>;
  filter?: InputMaybe<LinkedinScheduledMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinScheduledMessagesOrderBy>>;
  sort?: InputMaybe<Array<LinkedinScheduledMessageSort>>;
};


export type CampaignMemberTracesByCampaignMemberIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TraceCondition>;
  filter?: InputMaybe<TraceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TracesOrderBy>>;
  sort?: InputMaybe<Array<TraceSort>>;
};

export type CampaignMemberAggregates = {
  __typename?: 'CampaignMemberAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CampaignMemberDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

export type CampaignMemberCharge = Node & {
  __typename?: 'CampaignMemberCharge';
  amount?: Maybe<Scalars['Int']['output']>;
  /** Reads a single `CampaignMember` that is related to this `CampaignMemberCharge`. */
  campaignMemberByCampaignMemberId?: Maybe<CampaignMember>;
  campaignMemberId: Scalars['UUID']['output'];
  chargedAt: Scalars['Datetime']['output'];
  failedAt?: Maybe<Scalars['Datetime']['output']>;
  freeAt?: Maybe<Scalars['Datetime']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  paidAt?: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `Refund`. */
  refundsByCampaignMemberChargeId: RefundsConnection;
  reportedAt?: Maybe<Scalars['Datetime']['output']>;
  stripeInvoiceId?: Maybe<Scalars['String']['output']>;
  stripeInvoiceUrl?: Maybe<Scalars['String']['output']>;
  taxAmount?: Maybe<Scalars['Int']['output']>;
};


export type CampaignMemberChargeRefundsByCampaignMemberChargeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RefundCondition>;
  filter?: InputMaybe<RefundFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RefundsOrderBy>>;
  sort?: InputMaybe<Array<RefundSort>>;
};

export type CampaignMemberChargeAggregates = {
  __typename?: 'CampaignMemberChargeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CampaignMemberChargeAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CampaignMemberChargeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CampaignMemberChargeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CampaignMemberChargeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CampaignMemberChargeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CampaignMemberChargeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CampaignMemberChargeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CampaignMemberChargeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CampaignMemberChargeVarianceSampleAggregates>;
};

export type CampaignMemberChargeAverageAggregates = {
  __typename?: 'CampaignMemberChargeAverageAggregates';
  /** Mean average of amount across the matching connection */
  amount?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of taxAmount across the matching connection */
  taxAmount?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `CampaignMemberCharge` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CampaignMemberChargeCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `chargedAt` field. */
  chargedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `failedAt` field. */
  failedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `freeAt` field. */
  freeAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `paidAt` field. */
  paidAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `reportedAt` field. */
  reportedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `stripeInvoiceId` field. */
  stripeInvoiceId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `stripeInvoiceUrl` field. */
  stripeInvoiceUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `taxAmount` field. */
  taxAmount?: InputMaybe<Scalars['Int']['input']>;
};

export type CampaignMemberChargeDistinctCountAggregates = {
  __typename?: 'CampaignMemberChargeDistinctCountAggregates';
  /** Distinct count of amount across the matching connection */
  amount?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of campaignMemberId across the matching connection */
  campaignMemberId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of chargedAt across the matching connection */
  chargedAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of failedAt across the matching connection */
  failedAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of freeAt across the matching connection */
  freeAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of paidAt across the matching connection */
  paidAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of reportedAt across the matching connection */
  reportedAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stripeInvoiceId across the matching connection */
  stripeInvoiceId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stripeInvoiceUrl across the matching connection */
  stripeInvoiceUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of taxAmount across the matching connection */
  taxAmount?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `CampaignMemberCharge` object types. All fields are combined with a logical ‘and.’ */
export type CampaignMemberChargeFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CampaignMemberChargeFilter>>;
  /** Filter by the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `chargedAt` field. */
  chargedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `failedAt` field. */
  failedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `freeAt` field. */
  freeAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CampaignMemberChargeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CampaignMemberChargeFilter>>;
  /** Filter by the object’s `paidAt` field. */
  paidAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `reportedAt` field. */
  reportedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `stripeInvoiceId` field. */
  stripeInvoiceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stripeInvoiceUrl` field. */
  stripeInvoiceUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `taxAmount` field. */
  taxAmount?: InputMaybe<IntFilter>;
};

/** Grouping methods for `CampaignMemberCharge` for usage during aggregation. */
export enum CampaignMemberChargeGroupBy {
  Amount = 'AMOUNT',
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  ChargedAt = 'CHARGED_AT',
  ChargedAtTruncatedToDay = 'CHARGED_AT_TRUNCATED_TO_DAY',
  ChargedAtTruncatedToHour = 'CHARGED_AT_TRUNCATED_TO_HOUR',
  FailedAt = 'FAILED_AT',
  FailedAtTruncatedToDay = 'FAILED_AT_TRUNCATED_TO_DAY',
  FailedAtTruncatedToHour = 'FAILED_AT_TRUNCATED_TO_HOUR',
  FreeAt = 'FREE_AT',
  FreeAtTruncatedToDay = 'FREE_AT_TRUNCATED_TO_DAY',
  FreeAtTruncatedToHour = 'FREE_AT_TRUNCATED_TO_HOUR',
  PaidAt = 'PAID_AT',
  PaidAtTruncatedToDay = 'PAID_AT_TRUNCATED_TO_DAY',
  PaidAtTruncatedToHour = 'PAID_AT_TRUNCATED_TO_HOUR',
  ReportedAt = 'REPORTED_AT',
  ReportedAtTruncatedToDay = 'REPORTED_AT_TRUNCATED_TO_DAY',
  ReportedAtTruncatedToHour = 'REPORTED_AT_TRUNCATED_TO_HOUR',
  StripeInvoiceId = 'STRIPE_INVOICE_ID',
  StripeInvoiceUrl = 'STRIPE_INVOICE_URL',
  TaxAmount = 'TAX_AMOUNT'
}

export type CampaignMemberChargeHavingAverageInput = {
  amount?: InputMaybe<HavingIntFilter>;
  chargedAt?: InputMaybe<HavingDatetimeFilter>;
  failedAt?: InputMaybe<HavingDatetimeFilter>;
  freeAt?: InputMaybe<HavingDatetimeFilter>;
  paidAt?: InputMaybe<HavingDatetimeFilter>;
  reportedAt?: InputMaybe<HavingDatetimeFilter>;
  taxAmount?: InputMaybe<HavingIntFilter>;
};

export type CampaignMemberChargeHavingDistinctCountInput = {
  amount?: InputMaybe<HavingIntFilter>;
  chargedAt?: InputMaybe<HavingDatetimeFilter>;
  failedAt?: InputMaybe<HavingDatetimeFilter>;
  freeAt?: InputMaybe<HavingDatetimeFilter>;
  paidAt?: InputMaybe<HavingDatetimeFilter>;
  reportedAt?: InputMaybe<HavingDatetimeFilter>;
  taxAmount?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `CampaignMemberCharge` aggregates. */
export type CampaignMemberChargeHavingInput = {
  AND?: InputMaybe<Array<CampaignMemberChargeHavingInput>>;
  OR?: InputMaybe<Array<CampaignMemberChargeHavingInput>>;
  average?: InputMaybe<CampaignMemberChargeHavingAverageInput>;
  distinctCount?: InputMaybe<CampaignMemberChargeHavingDistinctCountInput>;
  max?: InputMaybe<CampaignMemberChargeHavingMaxInput>;
  min?: InputMaybe<CampaignMemberChargeHavingMinInput>;
  stddevPopulation?: InputMaybe<CampaignMemberChargeHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CampaignMemberChargeHavingStddevSampleInput>;
  sum?: InputMaybe<CampaignMemberChargeHavingSumInput>;
  variancePopulation?: InputMaybe<CampaignMemberChargeHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CampaignMemberChargeHavingVarianceSampleInput>;
};

export type CampaignMemberChargeHavingMaxInput = {
  amount?: InputMaybe<HavingIntFilter>;
  chargedAt?: InputMaybe<HavingDatetimeFilter>;
  failedAt?: InputMaybe<HavingDatetimeFilter>;
  freeAt?: InputMaybe<HavingDatetimeFilter>;
  paidAt?: InputMaybe<HavingDatetimeFilter>;
  reportedAt?: InputMaybe<HavingDatetimeFilter>;
  taxAmount?: InputMaybe<HavingIntFilter>;
};

export type CampaignMemberChargeHavingMinInput = {
  amount?: InputMaybe<HavingIntFilter>;
  chargedAt?: InputMaybe<HavingDatetimeFilter>;
  failedAt?: InputMaybe<HavingDatetimeFilter>;
  freeAt?: InputMaybe<HavingDatetimeFilter>;
  paidAt?: InputMaybe<HavingDatetimeFilter>;
  reportedAt?: InputMaybe<HavingDatetimeFilter>;
  taxAmount?: InputMaybe<HavingIntFilter>;
};

export type CampaignMemberChargeHavingStddevPopulationInput = {
  amount?: InputMaybe<HavingIntFilter>;
  chargedAt?: InputMaybe<HavingDatetimeFilter>;
  failedAt?: InputMaybe<HavingDatetimeFilter>;
  freeAt?: InputMaybe<HavingDatetimeFilter>;
  paidAt?: InputMaybe<HavingDatetimeFilter>;
  reportedAt?: InputMaybe<HavingDatetimeFilter>;
  taxAmount?: InputMaybe<HavingIntFilter>;
};

export type CampaignMemberChargeHavingStddevSampleInput = {
  amount?: InputMaybe<HavingIntFilter>;
  chargedAt?: InputMaybe<HavingDatetimeFilter>;
  failedAt?: InputMaybe<HavingDatetimeFilter>;
  freeAt?: InputMaybe<HavingDatetimeFilter>;
  paidAt?: InputMaybe<HavingDatetimeFilter>;
  reportedAt?: InputMaybe<HavingDatetimeFilter>;
  taxAmount?: InputMaybe<HavingIntFilter>;
};

export type CampaignMemberChargeHavingSumInput = {
  amount?: InputMaybe<HavingIntFilter>;
  chargedAt?: InputMaybe<HavingDatetimeFilter>;
  failedAt?: InputMaybe<HavingDatetimeFilter>;
  freeAt?: InputMaybe<HavingDatetimeFilter>;
  paidAt?: InputMaybe<HavingDatetimeFilter>;
  reportedAt?: InputMaybe<HavingDatetimeFilter>;
  taxAmount?: InputMaybe<HavingIntFilter>;
};

export type CampaignMemberChargeHavingVariancePopulationInput = {
  amount?: InputMaybe<HavingIntFilter>;
  chargedAt?: InputMaybe<HavingDatetimeFilter>;
  failedAt?: InputMaybe<HavingDatetimeFilter>;
  freeAt?: InputMaybe<HavingDatetimeFilter>;
  paidAt?: InputMaybe<HavingDatetimeFilter>;
  reportedAt?: InputMaybe<HavingDatetimeFilter>;
  taxAmount?: InputMaybe<HavingIntFilter>;
};

export type CampaignMemberChargeHavingVarianceSampleInput = {
  amount?: InputMaybe<HavingIntFilter>;
  chargedAt?: InputMaybe<HavingDatetimeFilter>;
  failedAt?: InputMaybe<HavingDatetimeFilter>;
  freeAt?: InputMaybe<HavingDatetimeFilter>;
  paidAt?: InputMaybe<HavingDatetimeFilter>;
  reportedAt?: InputMaybe<HavingDatetimeFilter>;
  taxAmount?: InputMaybe<HavingIntFilter>;
};

export type CampaignMemberChargeMaxAggregates = {
  __typename?: 'CampaignMemberChargeMaxAggregates';
  /** Maximum of amount across the matching connection */
  amount?: Maybe<Scalars['Int']['output']>;
  /** Maximum of taxAmount across the matching connection */
  taxAmount?: Maybe<Scalars['Int']['output']>;
};

export type CampaignMemberChargeMinAggregates = {
  __typename?: 'CampaignMemberChargeMinAggregates';
  /** Minimum of amount across the matching connection */
  amount?: Maybe<Scalars['Int']['output']>;
  /** Minimum of taxAmount across the matching connection */
  taxAmount?: Maybe<Scalars['Int']['output']>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type CampaignMemberChargeSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: CampaignMemberChargeSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type CampaignMemberChargeSortBy = {
  field?: InputMaybe<CampaignMemberChargeSortableField>;
};

/** Sortable concrete fields for the `CampaignMemberCharge` type. */
export enum CampaignMemberChargeSortableField {
  Amount = 'AMOUNT',
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  ChargedAt = 'CHARGED_AT',
  FailedAt = 'FAILED_AT',
  FreeAt = 'FREE_AT',
  Id = 'ID',
  PaidAt = 'PAID_AT',
  ReportedAt = 'REPORTED_AT',
  StripeInvoiceId = 'STRIPE_INVOICE_ID',
  StripeInvoiceUrl = 'STRIPE_INVOICE_URL',
  TaxAmount = 'TAX_AMOUNT'
}

export type CampaignMemberChargeStddevPopulationAggregates = {
  __typename?: 'CampaignMemberChargeStddevPopulationAggregates';
  /** Population standard deviation of amount across the matching connection */
  amount?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of taxAmount across the matching connection */
  taxAmount?: Maybe<Scalars['BigFloat']['output']>;
};

export type CampaignMemberChargeStddevSampleAggregates = {
  __typename?: 'CampaignMemberChargeStddevSampleAggregates';
  /** Sample standard deviation of amount across the matching connection */
  amount?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of taxAmount across the matching connection */
  taxAmount?: Maybe<Scalars['BigFloat']['output']>;
};

export type CampaignMemberChargeSumAggregates = {
  __typename?: 'CampaignMemberChargeSumAggregates';
  /** Sum of amount across the matching connection */
  amount: Scalars['BigInt']['output'];
  /** Sum of taxAmount across the matching connection */
  taxAmount: Scalars['BigInt']['output'];
};

export type CampaignMemberChargeVariancePopulationAggregates = {
  __typename?: 'CampaignMemberChargeVariancePopulationAggregates';
  /** Population variance of amount across the matching connection */
  amount?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of taxAmount across the matching connection */
  taxAmount?: Maybe<Scalars['BigFloat']['output']>;
};

export type CampaignMemberChargeVarianceSampleAggregates = {
  __typename?: 'CampaignMemberChargeVarianceSampleAggregates';
  /** Sample variance of amount across the matching connection */
  amount?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of taxAmount across the matching connection */
  taxAmount?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `CampaignMemberCharge` values. */
export type CampaignMemberChargesConnection = {
  __typename?: 'CampaignMemberChargesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CampaignMemberChargeAggregates>;
  /** A list of edges which contains the `CampaignMemberCharge` and cursor to aid in pagination. */
  edges: Array<CampaignMemberChargesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CampaignMemberChargeAggregates>>;
  /** A list of `CampaignMemberCharge` objects. */
  nodes: Array<Maybe<CampaignMemberCharge>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CampaignMemberCharge` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `CampaignMemberCharge` values. */
export type CampaignMemberChargesConnectionGroupedAggregatesArgs = {
  groupBy: Array<CampaignMemberChargeGroupBy>;
  having?: InputMaybe<CampaignMemberChargeHavingInput>;
};

/** A `CampaignMemberCharge` edge in the connection. */
export type CampaignMemberChargesEdge = {
  __typename?: 'CampaignMemberChargesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `CampaignMemberCharge` at the end of the edge. */
  node?: Maybe<CampaignMemberCharge>;
};

/** Methods to use when ordering `CampaignMemberCharge`. */
export enum CampaignMemberChargesOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  CampaignMemberIdAsc = 'CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberIdDesc = 'CAMPAIGN_MEMBER_ID_DESC',
  ChargedAtAsc = 'CHARGED_AT_ASC',
  ChargedAtDesc = 'CHARGED_AT_DESC',
  FailedAtAsc = 'FAILED_AT_ASC',
  FailedAtDesc = 'FAILED_AT_DESC',
  FreeAtAsc = 'FREE_AT_ASC',
  FreeAtDesc = 'FREE_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PaidAtAsc = 'PAID_AT_ASC',
  PaidAtDesc = 'PAID_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RefundsByCampaignMemberChargeIdAverageAmountAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_AMOUNT_ASC',
  RefundsByCampaignMemberChargeIdAverageAmountDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_AMOUNT_DESC',
  RefundsByCampaignMemberChargeIdAverageApprovalStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_APPROVAL_STATUS_ASC',
  RefundsByCampaignMemberChargeIdAverageApprovalStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_APPROVAL_STATUS_DESC',
  RefundsByCampaignMemberChargeIdAverageCampaignMemberChargeIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_CAMPAIGN_MEMBER_CHARGE_ID_ASC',
  RefundsByCampaignMemberChargeIdAverageCampaignMemberChargeIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_CAMPAIGN_MEMBER_CHARGE_ID_DESC',
  RefundsByCampaignMemberChargeIdAverageCreatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_CREATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdAverageCreatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_CREATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdAverageIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_ID_ASC',
  RefundsByCampaignMemberChargeIdAverageIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_ID_DESC',
  RefundsByCampaignMemberChargeIdAverageNoteAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_NOTE_ASC',
  RefundsByCampaignMemberChargeIdAverageNoteDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_NOTE_DESC',
  RefundsByCampaignMemberChargeIdAverageReasonAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_REASON_ASC',
  RefundsByCampaignMemberChargeIdAverageReasonDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_REASON_DESC',
  RefundsByCampaignMemberChargeIdAverageStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_STATUS_ASC',
  RefundsByCampaignMemberChargeIdAverageStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_STATUS_DESC',
  RefundsByCampaignMemberChargeIdAverageStripeCreditNoteIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_STRIPE_CREDIT_NOTE_ID_ASC',
  RefundsByCampaignMemberChargeIdAverageStripeCreditNoteIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_STRIPE_CREDIT_NOTE_ID_DESC',
  RefundsByCampaignMemberChargeIdAverageStripeCreditNoteUrlAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_STRIPE_CREDIT_NOTE_URL_ASC',
  RefundsByCampaignMemberChargeIdAverageStripeCreditNoteUrlDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_STRIPE_CREDIT_NOTE_URL_DESC',
  RefundsByCampaignMemberChargeIdAverageUpdatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_UPDATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdAverageUpdatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_AVERAGE_UPDATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdCountAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_COUNT_ASC',
  RefundsByCampaignMemberChargeIdCountDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_COUNT_DESC',
  RefundsByCampaignMemberChargeIdDistinctCountAmountAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_AMOUNT_ASC',
  RefundsByCampaignMemberChargeIdDistinctCountAmountDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_AMOUNT_DESC',
  RefundsByCampaignMemberChargeIdDistinctCountApprovalStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_APPROVAL_STATUS_ASC',
  RefundsByCampaignMemberChargeIdDistinctCountApprovalStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_APPROVAL_STATUS_DESC',
  RefundsByCampaignMemberChargeIdDistinctCountCampaignMemberChargeIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_CHARGE_ID_ASC',
  RefundsByCampaignMemberChargeIdDistinctCountCampaignMemberChargeIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_CHARGE_ID_DESC',
  RefundsByCampaignMemberChargeIdDistinctCountCreatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdDistinctCountCreatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdDistinctCountIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_ID_ASC',
  RefundsByCampaignMemberChargeIdDistinctCountIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_ID_DESC',
  RefundsByCampaignMemberChargeIdDistinctCountNoteAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_NOTE_ASC',
  RefundsByCampaignMemberChargeIdDistinctCountNoteDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_NOTE_DESC',
  RefundsByCampaignMemberChargeIdDistinctCountReasonAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_REASON_ASC',
  RefundsByCampaignMemberChargeIdDistinctCountReasonDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_REASON_DESC',
  RefundsByCampaignMemberChargeIdDistinctCountStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_STATUS_ASC',
  RefundsByCampaignMemberChargeIdDistinctCountStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_STATUS_DESC',
  RefundsByCampaignMemberChargeIdDistinctCountStripeCreditNoteIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_STRIPE_CREDIT_NOTE_ID_ASC',
  RefundsByCampaignMemberChargeIdDistinctCountStripeCreditNoteIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_STRIPE_CREDIT_NOTE_ID_DESC',
  RefundsByCampaignMemberChargeIdDistinctCountStripeCreditNoteUrlAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_STRIPE_CREDIT_NOTE_URL_ASC',
  RefundsByCampaignMemberChargeIdDistinctCountStripeCreditNoteUrlDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_STRIPE_CREDIT_NOTE_URL_DESC',
  RefundsByCampaignMemberChargeIdDistinctCountUpdatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdDistinctCountUpdatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdMaxAmountAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_AMOUNT_ASC',
  RefundsByCampaignMemberChargeIdMaxAmountDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_AMOUNT_DESC',
  RefundsByCampaignMemberChargeIdMaxApprovalStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_APPROVAL_STATUS_ASC',
  RefundsByCampaignMemberChargeIdMaxApprovalStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_APPROVAL_STATUS_DESC',
  RefundsByCampaignMemberChargeIdMaxCampaignMemberChargeIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_CAMPAIGN_MEMBER_CHARGE_ID_ASC',
  RefundsByCampaignMemberChargeIdMaxCampaignMemberChargeIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_CAMPAIGN_MEMBER_CHARGE_ID_DESC',
  RefundsByCampaignMemberChargeIdMaxCreatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_CREATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdMaxCreatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_CREATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdMaxIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_ID_ASC',
  RefundsByCampaignMemberChargeIdMaxIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_ID_DESC',
  RefundsByCampaignMemberChargeIdMaxNoteAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_NOTE_ASC',
  RefundsByCampaignMemberChargeIdMaxNoteDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_NOTE_DESC',
  RefundsByCampaignMemberChargeIdMaxReasonAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_REASON_ASC',
  RefundsByCampaignMemberChargeIdMaxReasonDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_REASON_DESC',
  RefundsByCampaignMemberChargeIdMaxStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_STATUS_ASC',
  RefundsByCampaignMemberChargeIdMaxStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_STATUS_DESC',
  RefundsByCampaignMemberChargeIdMaxStripeCreditNoteIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_STRIPE_CREDIT_NOTE_ID_ASC',
  RefundsByCampaignMemberChargeIdMaxStripeCreditNoteIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_STRIPE_CREDIT_NOTE_ID_DESC',
  RefundsByCampaignMemberChargeIdMaxStripeCreditNoteUrlAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_STRIPE_CREDIT_NOTE_URL_ASC',
  RefundsByCampaignMemberChargeIdMaxStripeCreditNoteUrlDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_STRIPE_CREDIT_NOTE_URL_DESC',
  RefundsByCampaignMemberChargeIdMaxUpdatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_UPDATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdMaxUpdatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MAX_UPDATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdMinAmountAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_AMOUNT_ASC',
  RefundsByCampaignMemberChargeIdMinAmountDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_AMOUNT_DESC',
  RefundsByCampaignMemberChargeIdMinApprovalStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_APPROVAL_STATUS_ASC',
  RefundsByCampaignMemberChargeIdMinApprovalStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_APPROVAL_STATUS_DESC',
  RefundsByCampaignMemberChargeIdMinCampaignMemberChargeIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_CAMPAIGN_MEMBER_CHARGE_ID_ASC',
  RefundsByCampaignMemberChargeIdMinCampaignMemberChargeIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_CAMPAIGN_MEMBER_CHARGE_ID_DESC',
  RefundsByCampaignMemberChargeIdMinCreatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_CREATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdMinCreatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_CREATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdMinIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_ID_ASC',
  RefundsByCampaignMemberChargeIdMinIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_ID_DESC',
  RefundsByCampaignMemberChargeIdMinNoteAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_NOTE_ASC',
  RefundsByCampaignMemberChargeIdMinNoteDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_NOTE_DESC',
  RefundsByCampaignMemberChargeIdMinReasonAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_REASON_ASC',
  RefundsByCampaignMemberChargeIdMinReasonDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_REASON_DESC',
  RefundsByCampaignMemberChargeIdMinStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_STATUS_ASC',
  RefundsByCampaignMemberChargeIdMinStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_STATUS_DESC',
  RefundsByCampaignMemberChargeIdMinStripeCreditNoteIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_STRIPE_CREDIT_NOTE_ID_ASC',
  RefundsByCampaignMemberChargeIdMinStripeCreditNoteIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_STRIPE_CREDIT_NOTE_ID_DESC',
  RefundsByCampaignMemberChargeIdMinStripeCreditNoteUrlAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_STRIPE_CREDIT_NOTE_URL_ASC',
  RefundsByCampaignMemberChargeIdMinStripeCreditNoteUrlDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_STRIPE_CREDIT_NOTE_URL_DESC',
  RefundsByCampaignMemberChargeIdMinUpdatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_UPDATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdMinUpdatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_MIN_UPDATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdStddevPopulationAmountAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_AMOUNT_ASC',
  RefundsByCampaignMemberChargeIdStddevPopulationAmountDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_AMOUNT_DESC',
  RefundsByCampaignMemberChargeIdStddevPopulationApprovalStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_APPROVAL_STATUS_ASC',
  RefundsByCampaignMemberChargeIdStddevPopulationApprovalStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_APPROVAL_STATUS_DESC',
  RefundsByCampaignMemberChargeIdStddevPopulationCampaignMemberChargeIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_CHARGE_ID_ASC',
  RefundsByCampaignMemberChargeIdStddevPopulationCampaignMemberChargeIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_CHARGE_ID_DESC',
  RefundsByCampaignMemberChargeIdStddevPopulationCreatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdStddevPopulationCreatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdStddevPopulationIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_ID_ASC',
  RefundsByCampaignMemberChargeIdStddevPopulationIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_ID_DESC',
  RefundsByCampaignMemberChargeIdStddevPopulationNoteAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_NOTE_ASC',
  RefundsByCampaignMemberChargeIdStddevPopulationNoteDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_NOTE_DESC',
  RefundsByCampaignMemberChargeIdStddevPopulationReasonAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_REASON_ASC',
  RefundsByCampaignMemberChargeIdStddevPopulationReasonDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_REASON_DESC',
  RefundsByCampaignMemberChargeIdStddevPopulationStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_STATUS_ASC',
  RefundsByCampaignMemberChargeIdStddevPopulationStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_STATUS_DESC',
  RefundsByCampaignMemberChargeIdStddevPopulationStripeCreditNoteIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_STRIPE_CREDIT_NOTE_ID_ASC',
  RefundsByCampaignMemberChargeIdStddevPopulationStripeCreditNoteIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_STRIPE_CREDIT_NOTE_ID_DESC',
  RefundsByCampaignMemberChargeIdStddevPopulationStripeCreditNoteUrlAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_STRIPE_CREDIT_NOTE_URL_ASC',
  RefundsByCampaignMemberChargeIdStddevPopulationStripeCreditNoteUrlDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_STRIPE_CREDIT_NOTE_URL_DESC',
  RefundsByCampaignMemberChargeIdStddevPopulationUpdatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdStddevPopulationUpdatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdStddevSampleAmountAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_AMOUNT_ASC',
  RefundsByCampaignMemberChargeIdStddevSampleAmountDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_AMOUNT_DESC',
  RefundsByCampaignMemberChargeIdStddevSampleApprovalStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_APPROVAL_STATUS_ASC',
  RefundsByCampaignMemberChargeIdStddevSampleApprovalStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_APPROVAL_STATUS_DESC',
  RefundsByCampaignMemberChargeIdStddevSampleCampaignMemberChargeIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_CHARGE_ID_ASC',
  RefundsByCampaignMemberChargeIdStddevSampleCampaignMemberChargeIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_CHARGE_ID_DESC',
  RefundsByCampaignMemberChargeIdStddevSampleCreatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdStddevSampleCreatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdStddevSampleIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_ID_ASC',
  RefundsByCampaignMemberChargeIdStddevSampleIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_ID_DESC',
  RefundsByCampaignMemberChargeIdStddevSampleNoteAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_NOTE_ASC',
  RefundsByCampaignMemberChargeIdStddevSampleNoteDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_NOTE_DESC',
  RefundsByCampaignMemberChargeIdStddevSampleReasonAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_REASON_ASC',
  RefundsByCampaignMemberChargeIdStddevSampleReasonDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_REASON_DESC',
  RefundsByCampaignMemberChargeIdStddevSampleStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_STATUS_ASC',
  RefundsByCampaignMemberChargeIdStddevSampleStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_STATUS_DESC',
  RefundsByCampaignMemberChargeIdStddevSampleStripeCreditNoteIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_STRIPE_CREDIT_NOTE_ID_ASC',
  RefundsByCampaignMemberChargeIdStddevSampleStripeCreditNoteIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_STRIPE_CREDIT_NOTE_ID_DESC',
  RefundsByCampaignMemberChargeIdStddevSampleStripeCreditNoteUrlAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_STRIPE_CREDIT_NOTE_URL_ASC',
  RefundsByCampaignMemberChargeIdStddevSampleStripeCreditNoteUrlDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_STRIPE_CREDIT_NOTE_URL_DESC',
  RefundsByCampaignMemberChargeIdStddevSampleUpdatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdStddevSampleUpdatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdSumAmountAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_AMOUNT_ASC',
  RefundsByCampaignMemberChargeIdSumAmountDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_AMOUNT_DESC',
  RefundsByCampaignMemberChargeIdSumApprovalStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_APPROVAL_STATUS_ASC',
  RefundsByCampaignMemberChargeIdSumApprovalStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_APPROVAL_STATUS_DESC',
  RefundsByCampaignMemberChargeIdSumCampaignMemberChargeIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_CAMPAIGN_MEMBER_CHARGE_ID_ASC',
  RefundsByCampaignMemberChargeIdSumCampaignMemberChargeIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_CAMPAIGN_MEMBER_CHARGE_ID_DESC',
  RefundsByCampaignMemberChargeIdSumCreatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_CREATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdSumCreatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_CREATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdSumIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_ID_ASC',
  RefundsByCampaignMemberChargeIdSumIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_ID_DESC',
  RefundsByCampaignMemberChargeIdSumNoteAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_NOTE_ASC',
  RefundsByCampaignMemberChargeIdSumNoteDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_NOTE_DESC',
  RefundsByCampaignMemberChargeIdSumReasonAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_REASON_ASC',
  RefundsByCampaignMemberChargeIdSumReasonDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_REASON_DESC',
  RefundsByCampaignMemberChargeIdSumStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_STATUS_ASC',
  RefundsByCampaignMemberChargeIdSumStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_STATUS_DESC',
  RefundsByCampaignMemberChargeIdSumStripeCreditNoteIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_STRIPE_CREDIT_NOTE_ID_ASC',
  RefundsByCampaignMemberChargeIdSumStripeCreditNoteIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_STRIPE_CREDIT_NOTE_ID_DESC',
  RefundsByCampaignMemberChargeIdSumStripeCreditNoteUrlAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_STRIPE_CREDIT_NOTE_URL_ASC',
  RefundsByCampaignMemberChargeIdSumStripeCreditNoteUrlDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_STRIPE_CREDIT_NOTE_URL_DESC',
  RefundsByCampaignMemberChargeIdSumUpdatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_UPDATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdSumUpdatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_SUM_UPDATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdVariancePopulationAmountAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_AMOUNT_ASC',
  RefundsByCampaignMemberChargeIdVariancePopulationAmountDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_AMOUNT_DESC',
  RefundsByCampaignMemberChargeIdVariancePopulationApprovalStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_APPROVAL_STATUS_ASC',
  RefundsByCampaignMemberChargeIdVariancePopulationApprovalStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_APPROVAL_STATUS_DESC',
  RefundsByCampaignMemberChargeIdVariancePopulationCampaignMemberChargeIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_CHARGE_ID_ASC',
  RefundsByCampaignMemberChargeIdVariancePopulationCampaignMemberChargeIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_CHARGE_ID_DESC',
  RefundsByCampaignMemberChargeIdVariancePopulationCreatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdVariancePopulationCreatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdVariancePopulationIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_ID_ASC',
  RefundsByCampaignMemberChargeIdVariancePopulationIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_ID_DESC',
  RefundsByCampaignMemberChargeIdVariancePopulationNoteAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_NOTE_ASC',
  RefundsByCampaignMemberChargeIdVariancePopulationNoteDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_NOTE_DESC',
  RefundsByCampaignMemberChargeIdVariancePopulationReasonAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_REASON_ASC',
  RefundsByCampaignMemberChargeIdVariancePopulationReasonDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_REASON_DESC',
  RefundsByCampaignMemberChargeIdVariancePopulationStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_STATUS_ASC',
  RefundsByCampaignMemberChargeIdVariancePopulationStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_STATUS_DESC',
  RefundsByCampaignMemberChargeIdVariancePopulationStripeCreditNoteIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_STRIPE_CREDIT_NOTE_ID_ASC',
  RefundsByCampaignMemberChargeIdVariancePopulationStripeCreditNoteIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_STRIPE_CREDIT_NOTE_ID_DESC',
  RefundsByCampaignMemberChargeIdVariancePopulationStripeCreditNoteUrlAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_STRIPE_CREDIT_NOTE_URL_ASC',
  RefundsByCampaignMemberChargeIdVariancePopulationStripeCreditNoteUrlDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_STRIPE_CREDIT_NOTE_URL_DESC',
  RefundsByCampaignMemberChargeIdVariancePopulationUpdatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdVariancePopulationUpdatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdVarianceSampleAmountAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_AMOUNT_ASC',
  RefundsByCampaignMemberChargeIdVarianceSampleAmountDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_AMOUNT_DESC',
  RefundsByCampaignMemberChargeIdVarianceSampleApprovalStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_APPROVAL_STATUS_ASC',
  RefundsByCampaignMemberChargeIdVarianceSampleApprovalStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_APPROVAL_STATUS_DESC',
  RefundsByCampaignMemberChargeIdVarianceSampleCampaignMemberChargeIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_CHARGE_ID_ASC',
  RefundsByCampaignMemberChargeIdVarianceSampleCampaignMemberChargeIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_CHARGE_ID_DESC',
  RefundsByCampaignMemberChargeIdVarianceSampleCreatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdVarianceSampleCreatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  RefundsByCampaignMemberChargeIdVarianceSampleIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_ID_ASC',
  RefundsByCampaignMemberChargeIdVarianceSampleIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_ID_DESC',
  RefundsByCampaignMemberChargeIdVarianceSampleNoteAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_NOTE_ASC',
  RefundsByCampaignMemberChargeIdVarianceSampleNoteDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_NOTE_DESC',
  RefundsByCampaignMemberChargeIdVarianceSampleReasonAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_REASON_ASC',
  RefundsByCampaignMemberChargeIdVarianceSampleReasonDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_REASON_DESC',
  RefundsByCampaignMemberChargeIdVarianceSampleStatusAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_STATUS_ASC',
  RefundsByCampaignMemberChargeIdVarianceSampleStatusDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_STATUS_DESC',
  RefundsByCampaignMemberChargeIdVarianceSampleStripeCreditNoteIdAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_STRIPE_CREDIT_NOTE_ID_ASC',
  RefundsByCampaignMemberChargeIdVarianceSampleStripeCreditNoteIdDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_STRIPE_CREDIT_NOTE_ID_DESC',
  RefundsByCampaignMemberChargeIdVarianceSampleStripeCreditNoteUrlAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_STRIPE_CREDIT_NOTE_URL_ASC',
  RefundsByCampaignMemberChargeIdVarianceSampleStripeCreditNoteUrlDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_STRIPE_CREDIT_NOTE_URL_DESC',
  RefundsByCampaignMemberChargeIdVarianceSampleUpdatedTimeAsc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  RefundsByCampaignMemberChargeIdVarianceSampleUpdatedTimeDesc = 'REFUNDS_BY_CAMPAIGN_MEMBER_CHARGE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  ReportedAtAsc = 'REPORTED_AT_ASC',
  ReportedAtDesc = 'REPORTED_AT_DESC',
  StripeInvoiceIdAsc = 'STRIPE_INVOICE_ID_ASC',
  StripeInvoiceIdDesc = 'STRIPE_INVOICE_ID_DESC',
  StripeInvoiceUrlAsc = 'STRIPE_INVOICE_URL_ASC',
  StripeInvoiceUrlDesc = 'STRIPE_INVOICE_URL_DESC',
  TaxAmountAsc = 'TAX_AMOUNT_ASC',
  TaxAmountDesc = 'TAX_AMOUNT_DESC'
}

/**
 * A condition to be used against `CampaignMember` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CampaignMemberCondition = {
  /** Checks for equality with the object’s `campaignId` field. */
  campaignId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `contactId` field. */
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `includeLawyer` field. */
  includeLawyer?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isConversationTime` field. */
  isConversationTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `latestEmailMessageCreatedTime` field. */
  latestEmailMessageCreatedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lawyerViewedTime` field. */
  lawyerViewedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `natSequenceCreatedTime` field. */
  natSequenceCreatedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `nextActionTime` field. */
  nextActionTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `visibility` field. */
  visibility?: InputMaybe<VisibilityState>;
};

export type CampaignMemberDistinctCountAggregates = {
  __typename?: 'CampaignMemberDistinctCountAggregates';
  /** Distinct count of campaignId across the matching connection */
  campaignId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactId across the matching connection */
  contactId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of includeLawyer across the matching connection */
  includeLawyer?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isConversationTime across the matching connection */
  isConversationTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of latestEmailMessageCreatedTime across the matching connection */
  latestEmailMessageCreatedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerViewedTime across the matching connection */
  lawyerViewedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of natSequenceCreatedTime across the matching connection */
  natSequenceCreatedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of nextActionTime across the matching connection */
  nextActionTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of visibility across the matching connection */
  visibility?: Maybe<Scalars['BigInt']['output']>;
};

export type CampaignMemberFeedback = Node & {
  __typename?: 'CampaignMemberFeedback';
  /** Reads a single `CampaignMember` that is related to this `CampaignMemberFeedback`. */
  campaignMemberByCampaignMemberId?: Maybe<CampaignMember>;
  campaignMemberId: Scalars['UUID']['output'];
  createdTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  rating: MemberFeedbackRating;
  updatedTime: Scalars['Datetime']['output'];
};

export type CampaignMemberFeedbackAggregates = {
  __typename?: 'CampaignMemberFeedbackAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CampaignMemberFeedbackDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `CampaignMemberFeedback` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CampaignMemberFeedbackCondition = {
  /** Checks for equality with the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `note` field. */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `rating` field. */
  rating?: InputMaybe<MemberFeedbackRating>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type CampaignMemberFeedbackDistinctCountAggregates = {
  __typename?: 'CampaignMemberFeedbackDistinctCountAggregates';
  /** Distinct count of campaignMemberId across the matching connection */
  campaignMemberId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of note across the matching connection */
  note?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of rating across the matching connection */
  rating?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `CampaignMemberFeedback` object types. All fields are combined with a logical ‘and.’ */
export type CampaignMemberFeedbackFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CampaignMemberFeedbackFilter>>;
  /** Filter by the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CampaignMemberFeedbackFilter>;
  /** Filter by the object’s `note` field. */
  note?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CampaignMemberFeedbackFilter>>;
  /** Filter by the object’s `rating` field. */
  rating?: InputMaybe<MemberFeedbackRatingFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `CampaignMemberFeedback` for usage during aggregation. */
export enum CampaignMemberFeedbackGroupBy {
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  Note = 'NOTE',
  Rating = 'RATING',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type CampaignMemberFeedbackHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberFeedbackHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `CampaignMemberFeedback` aggregates. */
export type CampaignMemberFeedbackHavingInput = {
  AND?: InputMaybe<Array<CampaignMemberFeedbackHavingInput>>;
  OR?: InputMaybe<Array<CampaignMemberFeedbackHavingInput>>;
  average?: InputMaybe<CampaignMemberFeedbackHavingAverageInput>;
  distinctCount?: InputMaybe<CampaignMemberFeedbackHavingDistinctCountInput>;
  max?: InputMaybe<CampaignMemberFeedbackHavingMaxInput>;
  min?: InputMaybe<CampaignMemberFeedbackHavingMinInput>;
  stddevPopulation?: InputMaybe<CampaignMemberFeedbackHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CampaignMemberFeedbackHavingStddevSampleInput>;
  sum?: InputMaybe<CampaignMemberFeedbackHavingSumInput>;
  variancePopulation?: InputMaybe<CampaignMemberFeedbackHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CampaignMemberFeedbackHavingVarianceSampleInput>;
};

export type CampaignMemberFeedbackHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberFeedbackHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberFeedbackHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberFeedbackHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberFeedbackHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberFeedbackHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberFeedbackHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type CampaignMemberFeedbackSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: CampaignMemberFeedbackSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type CampaignMemberFeedbackSortBy = {
  field?: InputMaybe<CampaignMemberFeedbackSortableField>;
};

/** Sortable concrete fields for the `CampaignMemberFeedback` type. */
export enum CampaignMemberFeedbackSortableField {
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  Note = 'NOTE',
  Rating = 'RATING',
  UpdatedTime = 'UPDATED_TIME'
}

/** A connection to a list of `CampaignMemberFeedback` values. */
export type CampaignMemberFeedbacksConnection = {
  __typename?: 'CampaignMemberFeedbacksConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CampaignMemberFeedbackAggregates>;
  /** A list of edges which contains the `CampaignMemberFeedback` and cursor to aid in pagination. */
  edges: Array<CampaignMemberFeedbacksEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CampaignMemberFeedbackAggregates>>;
  /** A list of `CampaignMemberFeedback` objects. */
  nodes: Array<Maybe<CampaignMemberFeedback>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CampaignMemberFeedback` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `CampaignMemberFeedback` values. */
export type CampaignMemberFeedbacksConnectionGroupedAggregatesArgs = {
  groupBy: Array<CampaignMemberFeedbackGroupBy>;
  having?: InputMaybe<CampaignMemberFeedbackHavingInput>;
};

/** A `CampaignMemberFeedback` edge in the connection. */
export type CampaignMemberFeedbacksEdge = {
  __typename?: 'CampaignMemberFeedbacksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `CampaignMemberFeedback` at the end of the edge. */
  node?: Maybe<CampaignMemberFeedback>;
};

/** Methods to use when ordering `CampaignMemberFeedback`. */
export enum CampaignMemberFeedbacksOrderBy {
  CampaignMemberIdAsc = 'CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberIdDesc = 'CAMPAIGN_MEMBER_ID_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RatingAsc = 'RATING_ASC',
  RatingDesc = 'RATING_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

/** A filter to be used against `CampaignMember` object types. All fields are combined with a logical ‘and.’ */
export type CampaignMemberFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CampaignMemberFilter>>;
  /** Filter by the object’s `campaignId` field. */
  campaignId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `contactId` field. */
  contactId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `includeLawyer` field. */
  includeLawyer?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isConversationTime` field. */
  isConversationTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `latestEmailMessageCreatedTime` field. */
  latestEmailMessageCreatedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lawyerViewedTime` field. */
  lawyerViewedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `natSequenceCreatedTime` field. */
  natSequenceCreatedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `nextActionTime` field. */
  nextActionTime?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CampaignMemberFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CampaignMemberFilter>>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `visibility` field. */
  visibility?: InputMaybe<VisibilityStateFilter>;
};

/** Grouping methods for `CampaignMember` for usage during aggregation. */
export enum CampaignMemberGroupBy {
  CampaignId = 'CAMPAIGN_ID',
  ContactId = 'CONTACT_ID',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  IncludeLawyer = 'INCLUDE_LAWYER',
  IsConversationTime = 'IS_CONVERSATION_TIME',
  IsConversationTimeTruncatedToDay = 'IS_CONVERSATION_TIME_TRUNCATED_TO_DAY',
  IsConversationTimeTruncatedToHour = 'IS_CONVERSATION_TIME_TRUNCATED_TO_HOUR',
  LatestEmailMessageCreatedTime = 'LATEST_EMAIL_MESSAGE_CREATED_TIME',
  LatestEmailMessageCreatedTimeTruncatedToDay = 'LATEST_EMAIL_MESSAGE_CREATED_TIME_TRUNCATED_TO_DAY',
  LatestEmailMessageCreatedTimeTruncatedToHour = 'LATEST_EMAIL_MESSAGE_CREATED_TIME_TRUNCATED_TO_HOUR',
  LawyerViewedTime = 'LAWYER_VIEWED_TIME',
  LawyerViewedTimeTruncatedToDay = 'LAWYER_VIEWED_TIME_TRUNCATED_TO_DAY',
  LawyerViewedTimeTruncatedToHour = 'LAWYER_VIEWED_TIME_TRUNCATED_TO_HOUR',
  NatSequenceCreatedTime = 'NAT_SEQUENCE_CREATED_TIME',
  NatSequenceCreatedTimeTruncatedToDay = 'NAT_SEQUENCE_CREATED_TIME_TRUNCATED_TO_DAY',
  NatSequenceCreatedTimeTruncatedToHour = 'NAT_SEQUENCE_CREATED_TIME_TRUNCATED_TO_HOUR',
  NextActionTime = 'NEXT_ACTION_TIME',
  NextActionTimeTruncatedToDay = 'NEXT_ACTION_TIME_TRUNCATED_TO_DAY',
  NextActionTimeTruncatedToHour = 'NEXT_ACTION_TIME_TRUNCATED_TO_HOUR',
  Status = 'STATUS',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR',
  Visibility = 'VISIBILITY'
}

export type CampaignMemberHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  isConversationTime?: InputMaybe<HavingDatetimeFilter>;
  latestEmailMessageCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  natSequenceCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  nextActionTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  isConversationTime?: InputMaybe<HavingDatetimeFilter>;
  latestEmailMessageCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  natSequenceCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  nextActionTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `CampaignMember` aggregates. */
export type CampaignMemberHavingInput = {
  AND?: InputMaybe<Array<CampaignMemberHavingInput>>;
  OR?: InputMaybe<Array<CampaignMemberHavingInput>>;
  average?: InputMaybe<CampaignMemberHavingAverageInput>;
  distinctCount?: InputMaybe<CampaignMemberHavingDistinctCountInput>;
  max?: InputMaybe<CampaignMemberHavingMaxInput>;
  min?: InputMaybe<CampaignMemberHavingMinInput>;
  stddevPopulation?: InputMaybe<CampaignMemberHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CampaignMemberHavingStddevSampleInput>;
  sum?: InputMaybe<CampaignMemberHavingSumInput>;
  variancePopulation?: InputMaybe<CampaignMemberHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CampaignMemberHavingVarianceSampleInput>;
};

export type CampaignMemberHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  isConversationTime?: InputMaybe<HavingDatetimeFilter>;
  latestEmailMessageCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  natSequenceCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  nextActionTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  isConversationTime?: InputMaybe<HavingDatetimeFilter>;
  latestEmailMessageCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  natSequenceCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  nextActionTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  isConversationTime?: InputMaybe<HavingDatetimeFilter>;
  latestEmailMessageCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  natSequenceCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  nextActionTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  isConversationTime?: InputMaybe<HavingDatetimeFilter>;
  latestEmailMessageCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  natSequenceCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  nextActionTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  isConversationTime?: InputMaybe<HavingDatetimeFilter>;
  latestEmailMessageCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  natSequenceCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  nextActionTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  isConversationTime?: InputMaybe<HavingDatetimeFilter>;
  latestEmailMessageCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  natSequenceCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  nextActionTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  isConversationTime?: InputMaybe<HavingDatetimeFilter>;
  latestEmailMessageCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  natSequenceCreatedTime?: InputMaybe<HavingDatetimeFilter>;
  nextActionTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberMeeting = Node & {
  __typename?: 'CampaignMemberMeeting';
  /** Reads a single `CampaignMember` that is related to this `CampaignMemberMeeting`. */
  campaignMemberByCampaignMemberId?: Maybe<CampaignMember>;
  campaignMemberId: Scalars['UUID']['output'];
  createdTime: Scalars['Datetime']['output'];
  cronofyEventId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['Datetime']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  schedulingId?: Maybe<Scalars['String']['output']>;
  schedulingToken?: Maybe<Scalars['String']['output']>;
  schedulingUrl?: Maybe<Scalars['String']['output']>;
  startTime?: Maybe<Scalars['Datetime']['output']>;
  status: Campaignmembermeetingstatus;
  summary?: Maybe<Scalars['String']['output']>;
  updatedTime: Scalars['Datetime']['output'];
};

export type CampaignMemberMeetingAggregates = {
  __typename?: 'CampaignMemberMeetingAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CampaignMemberMeetingDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `CampaignMemberMeeting` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CampaignMemberMeetingCondition = {
  /** Checks for equality with the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `cronofyEventId` field. */
  cronofyEventId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `endTime` field. */
  endTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `schedulingId` field. */
  schedulingId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `schedulingToken` field. */
  schedulingToken?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `schedulingUrl` field. */
  schedulingUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `startTime` field. */
  startTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Campaignmembermeetingstatus>;
  /** Checks for equality with the object’s `summary` field. */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type CampaignMemberMeetingDistinctCountAggregates = {
  __typename?: 'CampaignMemberMeetingDistinctCountAggregates';
  /** Distinct count of campaignMemberId across the matching connection */
  campaignMemberId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of cronofyEventId across the matching connection */
  cronofyEventId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endTime across the matching connection */
  endTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of schedulingId across the matching connection */
  schedulingId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of schedulingToken across the matching connection */
  schedulingToken?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of schedulingUrl across the matching connection */
  schedulingUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startTime across the matching connection */
  startTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of summary across the matching connection */
  summary?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `CampaignMemberMeeting` object types. All fields are combined with a logical ‘and.’ */
export type CampaignMemberMeetingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CampaignMemberMeetingFilter>>;
  /** Filter by the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `cronofyEventId` field. */
  cronofyEventId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `endTime` field. */
  endTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CampaignMemberMeetingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CampaignMemberMeetingFilter>>;
  /** Filter by the object’s `schedulingId` field. */
  schedulingId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `schedulingToken` field. */
  schedulingToken?: InputMaybe<StringFilter>;
  /** Filter by the object’s `schedulingUrl` field. */
  schedulingUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `startTime` field. */
  startTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<CampaignmembermeetingstatusFilter>;
  /** Filter by the object’s `summary` field. */
  summary?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `CampaignMemberMeeting` for usage during aggregation. */
export enum CampaignMemberMeetingGroupBy {
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  CronofyEventId = 'CRONOFY_EVENT_ID',
  Description = 'DESCRIPTION',
  EndTime = 'END_TIME',
  EndTimeTruncatedToDay = 'END_TIME_TRUNCATED_TO_DAY',
  EndTimeTruncatedToHour = 'END_TIME_TRUNCATED_TO_HOUR',
  SchedulingId = 'SCHEDULING_ID',
  SchedulingToken = 'SCHEDULING_TOKEN',
  SchedulingUrl = 'SCHEDULING_URL',
  StartTime = 'START_TIME',
  StartTimeTruncatedToDay = 'START_TIME_TRUNCATED_TO_DAY',
  StartTimeTruncatedToHour = 'START_TIME_TRUNCATED_TO_HOUR',
  Status = 'STATUS',
  Summary = 'SUMMARY',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type CampaignMemberMeetingHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberMeetingHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `CampaignMemberMeeting` aggregates. */
export type CampaignMemberMeetingHavingInput = {
  AND?: InputMaybe<Array<CampaignMemberMeetingHavingInput>>;
  OR?: InputMaybe<Array<CampaignMemberMeetingHavingInput>>;
  average?: InputMaybe<CampaignMemberMeetingHavingAverageInput>;
  distinctCount?: InputMaybe<CampaignMemberMeetingHavingDistinctCountInput>;
  max?: InputMaybe<CampaignMemberMeetingHavingMaxInput>;
  min?: InputMaybe<CampaignMemberMeetingHavingMinInput>;
  stddevPopulation?: InputMaybe<CampaignMemberMeetingHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CampaignMemberMeetingHavingStddevSampleInput>;
  sum?: InputMaybe<CampaignMemberMeetingHavingSumInput>;
  variancePopulation?: InputMaybe<CampaignMemberMeetingHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CampaignMemberMeetingHavingVarianceSampleInput>;
};

export type CampaignMemberMeetingHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberMeetingHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberMeetingHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberMeetingHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberMeetingHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberMeetingHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CampaignMemberMeetingHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type CampaignMemberMeetingSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: CampaignMemberMeetingSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type CampaignMemberMeetingSortBy = {
  field?: InputMaybe<CampaignMemberMeetingSortableField>;
};

/** Sortable concrete fields for the `CampaignMemberMeeting` type. */
export enum CampaignMemberMeetingSortableField {
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CreatedTime = 'CREATED_TIME',
  CronofyEventId = 'CRONOFY_EVENT_ID',
  Description = 'DESCRIPTION',
  EndTime = 'END_TIME',
  Id = 'ID',
  SchedulingId = 'SCHEDULING_ID',
  SchedulingToken = 'SCHEDULING_TOKEN',
  SchedulingUrl = 'SCHEDULING_URL',
  StartTime = 'START_TIME',
  Status = 'STATUS',
  Summary = 'SUMMARY',
  UpdatedTime = 'UPDATED_TIME'
}

/** A connection to a list of `CampaignMemberMeeting` values. */
export type CampaignMemberMeetingsConnection = {
  __typename?: 'CampaignMemberMeetingsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CampaignMemberMeetingAggregates>;
  /** A list of edges which contains the `CampaignMemberMeeting` and cursor to aid in pagination. */
  edges: Array<CampaignMemberMeetingsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CampaignMemberMeetingAggregates>>;
  /** A list of `CampaignMemberMeeting` objects. */
  nodes: Array<Maybe<CampaignMemberMeeting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CampaignMemberMeeting` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `CampaignMemberMeeting` values. */
export type CampaignMemberMeetingsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CampaignMemberMeetingGroupBy>;
  having?: InputMaybe<CampaignMemberMeetingHavingInput>;
};

/** A `CampaignMemberMeeting` edge in the connection. */
export type CampaignMemberMeetingsEdge = {
  __typename?: 'CampaignMemberMeetingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `CampaignMemberMeeting` at the end of the edge. */
  node?: Maybe<CampaignMemberMeeting>;
};

/** Methods to use when ordering `CampaignMemberMeeting`. */
export enum CampaignMemberMeetingsOrderBy {
  CampaignMemberIdAsc = 'CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberIdDesc = 'CAMPAIGN_MEMBER_ID_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  CronofyEventIdAsc = 'CRONOFY_EVENT_ID_ASC',
  CronofyEventIdDesc = 'CRONOFY_EVENT_ID_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  EndTimeAsc = 'END_TIME_ASC',
  EndTimeDesc = 'END_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SchedulingIdAsc = 'SCHEDULING_ID_ASC',
  SchedulingIdDesc = 'SCHEDULING_ID_DESC',
  SchedulingTokenAsc = 'SCHEDULING_TOKEN_ASC',
  SchedulingTokenDesc = 'SCHEDULING_TOKEN_DESC',
  SchedulingUrlAsc = 'SCHEDULING_URL_ASC',
  SchedulingUrlDesc = 'SCHEDULING_URL_DESC',
  StartTimeAsc = 'START_TIME_ASC',
  StartTimeDesc = 'START_TIME_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SummaryAsc = 'SUMMARY_ASC',
  SummaryDesc = 'SUMMARY_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type CampaignMemberSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: CampaignMemberSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type CampaignMemberSortBy = {
  field?: InputMaybe<CampaignMemberSortableField>;
};

/** Sortable concrete fields for the `CampaignMember` type. */
export enum CampaignMemberSortableField {
  CampaignId = 'CAMPAIGN_ID',
  ContactId = 'CONTACT_ID',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  IncludeLawyer = 'INCLUDE_LAWYER',
  IsConversationTime = 'IS_CONVERSATION_TIME',
  LatestEmailMessageCreatedTime = 'LATEST_EMAIL_MESSAGE_CREATED_TIME',
  LawyerViewedTime = 'LAWYER_VIEWED_TIME',
  NatSequenceCreatedTime = 'NAT_SEQUENCE_CREATED_TIME',
  NextActionTime = 'NEXT_ACTION_TIME',
  Status = 'STATUS',
  UpdatedTime = 'UPDATED_TIME',
  Visibility = 'VISIBILITY'
}

/** A connection to a list of `CampaignMember` values. */
export type CampaignMembersConnection = {
  __typename?: 'CampaignMembersConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CampaignMemberAggregates>;
  /** A list of edges which contains the `CampaignMember` and cursor to aid in pagination. */
  edges: Array<CampaignMembersEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CampaignMemberAggregates>>;
  /** A list of `CampaignMember` objects. */
  nodes: Array<Maybe<CampaignMember>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CampaignMember` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `CampaignMember` values. */
export type CampaignMembersConnectionGroupedAggregatesArgs = {
  groupBy: Array<CampaignMemberGroupBy>;
  having?: InputMaybe<CampaignMemberHavingInput>;
};

/** A `CampaignMember` edge in the connection. */
export type CampaignMembersEdge = {
  __typename?: 'CampaignMembersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `CampaignMember` at the end of the edge. */
  node?: Maybe<CampaignMember>;
};

/** Methods to use when ordering `CampaignMember`. */
export enum CampaignMembersOrderBy {
  AllocationMetricsByCampaignMemberIdAverageAcceptedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ACCEPTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdAverageAcceptedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ACCEPTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdAverageCampaignMemberIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  AllocationMetricsByCampaignMemberIdAverageCampaignMemberIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  AllocationMetricsByCampaignMemberIdAverageCreatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdAverageCreatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdAverageEsScoreAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ES_SCORE_ASC',
  AllocationMetricsByCampaignMemberIdAverageEsScoreDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ES_SCORE_DESC',
  AllocationMetricsByCampaignMemberIdAverageIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_ASC',
  AllocationMetricsByCampaignMemberIdAverageIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_DESC',
  AllocationMetricsByCampaignMemberIdAverageRejectedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REJECTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdAverageRejectedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REJECTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdAverageUpdatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdAverageUpdatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdDistinctCountAcceptedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ACCEPTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdDistinctCountAcceptedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ACCEPTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  AllocationMetricsByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  AllocationMetricsByCampaignMemberIdDistinctCountCreatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdDistinctCountCreatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdDistinctCountEsScoreAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ES_SCORE_ASC',
  AllocationMetricsByCampaignMemberIdDistinctCountEsScoreDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ES_SCORE_DESC',
  AllocationMetricsByCampaignMemberIdDistinctCountIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_ASC',
  AllocationMetricsByCampaignMemberIdDistinctCountIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_DESC',
  AllocationMetricsByCampaignMemberIdDistinctCountRejectedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REJECTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdDistinctCountRejectedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REJECTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdDistinctCountUpdatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdDistinctCountUpdatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdMaxAcceptedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_ACCEPTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdMaxAcceptedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_ACCEPTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdMaxCampaignMemberIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  AllocationMetricsByCampaignMemberIdMaxCampaignMemberIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  AllocationMetricsByCampaignMemberIdMaxCreatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdMaxCreatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdMaxEsScoreAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_ES_SCORE_ASC',
  AllocationMetricsByCampaignMemberIdMaxEsScoreDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_ES_SCORE_DESC',
  AllocationMetricsByCampaignMemberIdMaxIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_ASC',
  AllocationMetricsByCampaignMemberIdMaxIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_DESC',
  AllocationMetricsByCampaignMemberIdMaxRejectedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_REJECTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdMaxRejectedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_REJECTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdMaxUpdatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdMaxUpdatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdMinAcceptedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_ACCEPTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdMinAcceptedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_ACCEPTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdMinCampaignMemberIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  AllocationMetricsByCampaignMemberIdMinCampaignMemberIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  AllocationMetricsByCampaignMemberIdMinCreatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdMinCreatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdMinEsScoreAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_ES_SCORE_ASC',
  AllocationMetricsByCampaignMemberIdMinEsScoreDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_ES_SCORE_DESC',
  AllocationMetricsByCampaignMemberIdMinIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_ASC',
  AllocationMetricsByCampaignMemberIdMinIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_DESC',
  AllocationMetricsByCampaignMemberIdMinRejectedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_REJECTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdMinRejectedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_REJECTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdMinUpdatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdMinUpdatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdStddevPopulationAcceptedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ACCEPTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdStddevPopulationAcceptedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ACCEPTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  AllocationMetricsByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  AllocationMetricsByCampaignMemberIdStddevPopulationCreatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdStddevPopulationCreatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdStddevPopulationEsScoreAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ES_SCORE_ASC',
  AllocationMetricsByCampaignMemberIdStddevPopulationEsScoreDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ES_SCORE_DESC',
  AllocationMetricsByCampaignMemberIdStddevPopulationIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_ASC',
  AllocationMetricsByCampaignMemberIdStddevPopulationIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_DESC',
  AllocationMetricsByCampaignMemberIdStddevPopulationRejectedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REJECTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdStddevPopulationRejectedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REJECTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdStddevPopulationUpdatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdStddevPopulationUpdatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdStddevSampleAcceptedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ACCEPTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdStddevSampleAcceptedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ACCEPTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  AllocationMetricsByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  AllocationMetricsByCampaignMemberIdStddevSampleCreatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdStddevSampleCreatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdStddevSampleEsScoreAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ES_SCORE_ASC',
  AllocationMetricsByCampaignMemberIdStddevSampleEsScoreDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ES_SCORE_DESC',
  AllocationMetricsByCampaignMemberIdStddevSampleIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_ASC',
  AllocationMetricsByCampaignMemberIdStddevSampleIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_DESC',
  AllocationMetricsByCampaignMemberIdStddevSampleRejectedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REJECTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdStddevSampleRejectedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REJECTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdStddevSampleUpdatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdStddevSampleUpdatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdSumAcceptedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_ACCEPTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdSumAcceptedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_ACCEPTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdSumCampaignMemberIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  AllocationMetricsByCampaignMemberIdSumCampaignMemberIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  AllocationMetricsByCampaignMemberIdSumCreatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdSumCreatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdSumEsScoreAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_ES_SCORE_ASC',
  AllocationMetricsByCampaignMemberIdSumEsScoreDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_ES_SCORE_DESC',
  AllocationMetricsByCampaignMemberIdSumIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_ASC',
  AllocationMetricsByCampaignMemberIdSumIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_DESC',
  AllocationMetricsByCampaignMemberIdSumRejectedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_REJECTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdSumRejectedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_REJECTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdSumUpdatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdSumUpdatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdVariancePopulationAcceptedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ACCEPTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdVariancePopulationAcceptedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ACCEPTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  AllocationMetricsByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  AllocationMetricsByCampaignMemberIdVariancePopulationCreatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdVariancePopulationCreatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdVariancePopulationEsScoreAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ES_SCORE_ASC',
  AllocationMetricsByCampaignMemberIdVariancePopulationEsScoreDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ES_SCORE_DESC',
  AllocationMetricsByCampaignMemberIdVariancePopulationIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_ASC',
  AllocationMetricsByCampaignMemberIdVariancePopulationIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_DESC',
  AllocationMetricsByCampaignMemberIdVariancePopulationRejectedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REJECTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdVariancePopulationRejectedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REJECTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdVariancePopulationUpdatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdVariancePopulationUpdatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdVarianceSampleAcceptedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ACCEPTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdVarianceSampleAcceptedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ACCEPTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  AllocationMetricsByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  AllocationMetricsByCampaignMemberIdVarianceSampleCreatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdVarianceSampleCreatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  AllocationMetricsByCampaignMemberIdVarianceSampleEsScoreAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ES_SCORE_ASC',
  AllocationMetricsByCampaignMemberIdVarianceSampleEsScoreDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ES_SCORE_DESC',
  AllocationMetricsByCampaignMemberIdVarianceSampleIdAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC',
  AllocationMetricsByCampaignMemberIdVarianceSampleIdDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC',
  AllocationMetricsByCampaignMemberIdVarianceSampleRejectedCountAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REJECTED_COUNT_ASC',
  AllocationMetricsByCampaignMemberIdVarianceSampleRejectedCountDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REJECTED_COUNT_DESC',
  AllocationMetricsByCampaignMemberIdVarianceSampleUpdatedTimeAsc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  AllocationMetricsByCampaignMemberIdVarianceSampleUpdatedTimeDesc = 'ALLOCATION_METRICS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  CampaignIdAsc = 'CAMPAIGN_ID_ASC',
  CampaignIdDesc = 'CAMPAIGN_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdAverageAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdAverageAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdAverageCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdAverageCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdAverageChargedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CHARGED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdAverageChargedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CHARGED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdAverageFailedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_FAILED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdAverageFailedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_FAILED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdAverageFreeAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_FREE_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdAverageFreeAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_FREE_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdAverageIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdAverageIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdAveragePaidAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_PAID_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdAveragePaidAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_PAID_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdAverageReportedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REPORTED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdAverageReportedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REPORTED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdAverageStripeInvoiceIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STRIPE_INVOICE_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdAverageStripeInvoiceIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STRIPE_INVOICE_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdAverageStripeInvoiceUrlAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STRIPE_INVOICE_URL_ASC',
  CampaignMemberChargesByCampaignMemberIdAverageStripeInvoiceUrlDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STRIPE_INVOICE_URL_DESC',
  CampaignMemberChargesByCampaignMemberIdAverageTaxAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_TAX_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdAverageTaxAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_TAX_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdCountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdCountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountChargedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CHARGED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountChargedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CHARGED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountFailedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_FAILED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountFailedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_FAILED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountFreeAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_FREE_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountFreeAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_FREE_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountPaidAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_PAID_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountPaidAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_PAID_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountReportedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REPORTED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountReportedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REPORTED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountStripeInvoiceIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STRIPE_INVOICE_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountStripeInvoiceIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STRIPE_INVOICE_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountStripeInvoiceUrlAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STRIPE_INVOICE_URL_ASC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountStripeInvoiceUrlDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STRIPE_INVOICE_URL_DESC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountTaxAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_TAX_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdDistinctCountTaxAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_TAX_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdMaxAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdMaxAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdMaxCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdMaxCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdMaxChargedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_CHARGED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdMaxChargedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_CHARGED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdMaxFailedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_FAILED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdMaxFailedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_FAILED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdMaxFreeAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_FREE_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdMaxFreeAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_FREE_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdMaxIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdMaxIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdMaxPaidAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_PAID_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdMaxPaidAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_PAID_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdMaxReportedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_REPORTED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdMaxReportedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_REPORTED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdMaxStripeInvoiceIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_STRIPE_INVOICE_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdMaxStripeInvoiceIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_STRIPE_INVOICE_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdMaxStripeInvoiceUrlAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_STRIPE_INVOICE_URL_ASC',
  CampaignMemberChargesByCampaignMemberIdMaxStripeInvoiceUrlDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_STRIPE_INVOICE_URL_DESC',
  CampaignMemberChargesByCampaignMemberIdMaxTaxAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_TAX_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdMaxTaxAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MAX_TAX_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdMinAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdMinAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdMinCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdMinCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdMinChargedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_CHARGED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdMinChargedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_CHARGED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdMinFailedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_FAILED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdMinFailedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_FAILED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdMinFreeAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_FREE_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdMinFreeAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_FREE_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdMinIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdMinIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdMinPaidAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_PAID_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdMinPaidAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_PAID_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdMinReportedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_REPORTED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdMinReportedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_REPORTED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdMinStripeInvoiceIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_STRIPE_INVOICE_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdMinStripeInvoiceIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_STRIPE_INVOICE_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdMinStripeInvoiceUrlAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_STRIPE_INVOICE_URL_ASC',
  CampaignMemberChargesByCampaignMemberIdMinStripeInvoiceUrlDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_STRIPE_INVOICE_URL_DESC',
  CampaignMemberChargesByCampaignMemberIdMinTaxAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_TAX_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdMinTaxAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_MIN_TAX_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationChargedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CHARGED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationChargedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CHARGED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationFailedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_FAILED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationFailedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_FAILED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationFreeAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_FREE_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationFreeAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_FREE_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationPaidAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_PAID_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationPaidAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_PAID_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationReportedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REPORTED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationReportedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REPORTED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationStripeInvoiceIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STRIPE_INVOICE_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationStripeInvoiceIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STRIPE_INVOICE_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationStripeInvoiceUrlAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STRIPE_INVOICE_URL_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationStripeInvoiceUrlDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STRIPE_INVOICE_URL_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationTaxAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_TAX_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevPopulationTaxAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_TAX_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleChargedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CHARGED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleChargedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CHARGED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleFailedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_FAILED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleFailedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_FAILED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleFreeAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_FREE_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleFreeAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_FREE_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevSamplePaidAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_PAID_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevSamplePaidAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_PAID_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleReportedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REPORTED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleReportedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REPORTED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleStripeInvoiceIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STRIPE_INVOICE_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleStripeInvoiceIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STRIPE_INVOICE_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleStripeInvoiceUrlAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STRIPE_INVOICE_URL_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleStripeInvoiceUrlDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STRIPE_INVOICE_URL_DESC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleTaxAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_TAX_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdStddevSampleTaxAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_TAX_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdSumAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdSumAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdSumCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdSumCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdSumChargedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_CHARGED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdSumChargedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_CHARGED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdSumFailedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_FAILED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdSumFailedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_FAILED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdSumFreeAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_FREE_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdSumFreeAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_FREE_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdSumIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdSumIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdSumPaidAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_PAID_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdSumPaidAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_PAID_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdSumReportedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_REPORTED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdSumReportedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_REPORTED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdSumStripeInvoiceIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_STRIPE_INVOICE_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdSumStripeInvoiceIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_STRIPE_INVOICE_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdSumStripeInvoiceUrlAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_STRIPE_INVOICE_URL_ASC',
  CampaignMemberChargesByCampaignMemberIdSumStripeInvoiceUrlDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_STRIPE_INVOICE_URL_DESC',
  CampaignMemberChargesByCampaignMemberIdSumTaxAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_TAX_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdSumTaxAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_SUM_TAX_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationChargedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CHARGED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationChargedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CHARGED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationFailedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_FAILED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationFailedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_FAILED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationFreeAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_FREE_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationFreeAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_FREE_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationPaidAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_PAID_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationPaidAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_PAID_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationReportedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REPORTED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationReportedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REPORTED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationStripeInvoiceIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STRIPE_INVOICE_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationStripeInvoiceIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STRIPE_INVOICE_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationStripeInvoiceUrlAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STRIPE_INVOICE_URL_ASC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationStripeInvoiceUrlDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STRIPE_INVOICE_URL_DESC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationTaxAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_TAX_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdVariancePopulationTaxAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_TAX_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_AMOUNT_DESC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleChargedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CHARGED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleChargedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CHARGED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleFailedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_FAILED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleFailedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_FAILED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleFreeAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_FREE_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleFreeAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_FREE_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdVarianceSamplePaidAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_PAID_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdVarianceSamplePaidAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_PAID_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleReportedAtAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REPORTED_AT_ASC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleReportedAtDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REPORTED_AT_DESC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleStripeInvoiceIdAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STRIPE_INVOICE_ID_ASC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleStripeInvoiceIdDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STRIPE_INVOICE_ID_DESC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleStripeInvoiceUrlAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STRIPE_INVOICE_URL_ASC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleStripeInvoiceUrlDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STRIPE_INVOICE_URL_DESC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleTaxAmountAsc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_TAX_AMOUNT_ASC',
  CampaignMemberChargesByCampaignMemberIdVarianceSampleTaxAmountDesc = 'CAMPAIGN_MEMBER_CHARGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_TAX_AMOUNT_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdAverageCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdAverageCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdAverageCreatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdAverageCreatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdAverageIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdAverageIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdAverageNoteAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_NOTE_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdAverageNoteDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_NOTE_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdAverageRatingAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_RATING_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdAverageRatingDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_RATING_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdAverageUpdatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdAverageUpdatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdCountAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdCountDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdDistinctCountCreatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdDistinctCountCreatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdDistinctCountIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdDistinctCountIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdDistinctCountNoteAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_NOTE_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdDistinctCountNoteDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_NOTE_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdDistinctCountRatingAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_RATING_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdDistinctCountRatingDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_RATING_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdDistinctCountUpdatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdDistinctCountUpdatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdMaxCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdMaxCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdMaxCreatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdMaxCreatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdMaxIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdMaxIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdMaxNoteAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MAX_NOTE_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdMaxNoteDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MAX_NOTE_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdMaxRatingAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MAX_RATING_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdMaxRatingDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MAX_RATING_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdMaxUpdatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdMaxUpdatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdMinCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdMinCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdMinCreatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdMinCreatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdMinIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdMinIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdMinNoteAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MIN_NOTE_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdMinNoteDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MIN_NOTE_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdMinRatingAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MIN_RATING_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdMinRatingDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MIN_RATING_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdMinUpdatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdMinUpdatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevPopulationCreatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevPopulationCreatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevPopulationIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevPopulationIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevPopulationNoteAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_NOTE_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevPopulationNoteDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_NOTE_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevPopulationRatingAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_RATING_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevPopulationRatingDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_RATING_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevPopulationUpdatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevPopulationUpdatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevSampleCreatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevSampleCreatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevSampleIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevSampleIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevSampleNoteAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_NOTE_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevSampleNoteDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_NOTE_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevSampleRatingAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_RATING_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevSampleRatingDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_RATING_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevSampleUpdatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdStddevSampleUpdatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdSumCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdSumCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdSumCreatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdSumCreatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdSumIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdSumIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdSumNoteAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_SUM_NOTE_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdSumNoteDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_SUM_NOTE_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdSumRatingAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_SUM_RATING_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdSumRatingDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_SUM_RATING_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdSumUpdatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdSumUpdatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdVariancePopulationCreatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdVariancePopulationCreatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdVariancePopulationIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdVariancePopulationIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdVariancePopulationNoteAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_NOTE_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdVariancePopulationNoteDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_NOTE_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdVariancePopulationRatingAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_RATING_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdVariancePopulationRatingDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_RATING_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdVariancePopulationUpdatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdVariancePopulationUpdatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdVarianceSampleCreatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdVarianceSampleCreatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdVarianceSampleIdAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdVarianceSampleIdDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdVarianceSampleNoteAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_NOTE_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdVarianceSampleNoteDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_NOTE_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdVarianceSampleRatingAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_RATING_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdVarianceSampleRatingDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_RATING_DESC',
  CampaignMemberFeedbacksByCampaignMemberIdVarianceSampleUpdatedTimeAsc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  CampaignMemberFeedbacksByCampaignMemberIdVarianceSampleUpdatedTimeDesc = 'CAMPAIGN_MEMBER_FEEDBACKS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdAverageCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdAverageCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdAverageCreatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdAverageCreatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdAverageCronofyEventIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CRONOFY_EVENT_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdAverageCronofyEventIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CRONOFY_EVENT_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdAverageDescriptionAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_DESCRIPTION_ASC',
  CampaignMemberMeetingsByCampaignMemberIdAverageDescriptionDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_DESCRIPTION_DESC',
  CampaignMemberMeetingsByCampaignMemberIdAverageEndTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_END_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdAverageEndTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_END_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdAverageIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdAverageIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdAverageSchedulingIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SCHEDULING_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdAverageSchedulingIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SCHEDULING_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdAverageSchedulingTokenAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SCHEDULING_TOKEN_ASC',
  CampaignMemberMeetingsByCampaignMemberIdAverageSchedulingTokenDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SCHEDULING_TOKEN_DESC',
  CampaignMemberMeetingsByCampaignMemberIdAverageSchedulingUrlAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SCHEDULING_URL_ASC',
  CampaignMemberMeetingsByCampaignMemberIdAverageSchedulingUrlDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SCHEDULING_URL_DESC',
  CampaignMemberMeetingsByCampaignMemberIdAverageStartTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_START_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdAverageStartTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_START_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdAverageStatusAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STATUS_ASC',
  CampaignMemberMeetingsByCampaignMemberIdAverageStatusDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STATUS_DESC',
  CampaignMemberMeetingsByCampaignMemberIdAverageSummaryAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SUMMARY_ASC',
  CampaignMemberMeetingsByCampaignMemberIdAverageSummaryDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SUMMARY_DESC',
  CampaignMemberMeetingsByCampaignMemberIdAverageUpdatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdAverageUpdatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdCountAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  CampaignMemberMeetingsByCampaignMemberIdCountDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountCreatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountCreatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountCronofyEventIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CRONOFY_EVENT_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountCronofyEventIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CRONOFY_EVENT_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountDescriptionAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_DESCRIPTION_ASC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountDescriptionDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_DESCRIPTION_DESC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountEndTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_END_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountEndTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_END_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountSchedulingIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SCHEDULING_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountSchedulingIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SCHEDULING_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountSchedulingTokenAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SCHEDULING_TOKEN_ASC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountSchedulingTokenDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SCHEDULING_TOKEN_DESC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountSchedulingUrlAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SCHEDULING_URL_ASC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountSchedulingUrlDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SCHEDULING_URL_DESC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountStartTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_START_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountStartTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_START_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountStatusAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STATUS_ASC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountStatusDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STATUS_DESC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountSummaryAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SUMMARY_ASC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountSummaryDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SUMMARY_DESC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountUpdatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdDistinctCountUpdatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMaxCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMaxCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMaxCreatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMaxCreatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMaxCronofyEventIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_CRONOFY_EVENT_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMaxCronofyEventIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_CRONOFY_EVENT_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMaxDescriptionAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_DESCRIPTION_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMaxDescriptionDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_DESCRIPTION_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMaxEndTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_END_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMaxEndTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_END_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMaxIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMaxIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMaxSchedulingIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_SCHEDULING_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMaxSchedulingIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_SCHEDULING_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMaxSchedulingTokenAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_SCHEDULING_TOKEN_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMaxSchedulingTokenDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_SCHEDULING_TOKEN_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMaxSchedulingUrlAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_SCHEDULING_URL_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMaxSchedulingUrlDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_SCHEDULING_URL_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMaxStartTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_START_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMaxStartTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_START_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMaxStatusAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_STATUS_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMaxStatusDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_STATUS_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMaxSummaryAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_SUMMARY_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMaxSummaryDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_SUMMARY_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMaxUpdatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMaxUpdatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMinCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMinCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMinCreatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMinCreatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMinCronofyEventIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_CRONOFY_EVENT_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMinCronofyEventIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_CRONOFY_EVENT_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMinDescriptionAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_DESCRIPTION_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMinDescriptionDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_DESCRIPTION_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMinEndTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_END_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMinEndTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_END_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMinIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMinIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMinSchedulingIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_SCHEDULING_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMinSchedulingIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_SCHEDULING_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMinSchedulingTokenAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_SCHEDULING_TOKEN_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMinSchedulingTokenDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_SCHEDULING_TOKEN_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMinSchedulingUrlAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_SCHEDULING_URL_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMinSchedulingUrlDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_SCHEDULING_URL_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMinStartTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_START_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMinStartTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_START_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMinStatusAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_STATUS_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMinStatusDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_STATUS_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMinSummaryAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_SUMMARY_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMinSummaryDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_SUMMARY_DESC',
  CampaignMemberMeetingsByCampaignMemberIdMinUpdatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdMinUpdatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationCreatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationCreatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationCronofyEventIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CRONOFY_EVENT_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationCronofyEventIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CRONOFY_EVENT_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationDescriptionAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_DESCRIPTION_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationDescriptionDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_DESCRIPTION_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationEndTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_END_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationEndTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_END_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationSchedulingIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SCHEDULING_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationSchedulingIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SCHEDULING_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationSchedulingTokenAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SCHEDULING_TOKEN_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationSchedulingTokenDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SCHEDULING_TOKEN_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationSchedulingUrlAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SCHEDULING_URL_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationSchedulingUrlDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SCHEDULING_URL_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationStartTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_START_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationStartTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_START_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationStatusAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STATUS_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationStatusDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STATUS_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationSummaryAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SUMMARY_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationSummaryDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SUMMARY_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationUpdatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevPopulationUpdatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleCreatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleCreatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleCronofyEventIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CRONOFY_EVENT_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleCronofyEventIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CRONOFY_EVENT_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleDescriptionAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_DESCRIPTION_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleDescriptionDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_DESCRIPTION_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleEndTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_END_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleEndTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_END_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleSchedulingIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SCHEDULING_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleSchedulingIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SCHEDULING_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleSchedulingTokenAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SCHEDULING_TOKEN_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleSchedulingTokenDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SCHEDULING_TOKEN_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleSchedulingUrlAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SCHEDULING_URL_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleSchedulingUrlDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SCHEDULING_URL_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleStartTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_START_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleStartTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_START_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleStatusAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STATUS_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleStatusDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STATUS_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleSummaryAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SUMMARY_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleSummaryDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SUMMARY_DESC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleUpdatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdStddevSampleUpdatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdSumCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdSumCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdSumCreatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdSumCreatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdSumCronofyEventIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_CRONOFY_EVENT_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdSumCronofyEventIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_CRONOFY_EVENT_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdSumDescriptionAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_DESCRIPTION_ASC',
  CampaignMemberMeetingsByCampaignMemberIdSumDescriptionDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_DESCRIPTION_DESC',
  CampaignMemberMeetingsByCampaignMemberIdSumEndTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_END_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdSumEndTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_END_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdSumIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdSumIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdSumSchedulingIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_SCHEDULING_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdSumSchedulingIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_SCHEDULING_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdSumSchedulingTokenAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_SCHEDULING_TOKEN_ASC',
  CampaignMemberMeetingsByCampaignMemberIdSumSchedulingTokenDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_SCHEDULING_TOKEN_DESC',
  CampaignMemberMeetingsByCampaignMemberIdSumSchedulingUrlAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_SCHEDULING_URL_ASC',
  CampaignMemberMeetingsByCampaignMemberIdSumSchedulingUrlDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_SCHEDULING_URL_DESC',
  CampaignMemberMeetingsByCampaignMemberIdSumStartTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_START_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdSumStartTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_START_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdSumStatusAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_STATUS_ASC',
  CampaignMemberMeetingsByCampaignMemberIdSumStatusDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_STATUS_DESC',
  CampaignMemberMeetingsByCampaignMemberIdSumSummaryAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_SUMMARY_ASC',
  CampaignMemberMeetingsByCampaignMemberIdSumSummaryDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_SUMMARY_DESC',
  CampaignMemberMeetingsByCampaignMemberIdSumUpdatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdSumUpdatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationCreatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationCreatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationCronofyEventIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CRONOFY_EVENT_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationCronofyEventIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CRONOFY_EVENT_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationDescriptionAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_DESCRIPTION_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationDescriptionDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_DESCRIPTION_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationEndTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_END_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationEndTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_END_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationSchedulingIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SCHEDULING_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationSchedulingIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SCHEDULING_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationSchedulingTokenAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SCHEDULING_TOKEN_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationSchedulingTokenDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SCHEDULING_TOKEN_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationSchedulingUrlAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SCHEDULING_URL_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationSchedulingUrlDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SCHEDULING_URL_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationStartTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_START_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationStartTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_START_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationStatusAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STATUS_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationStatusDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STATUS_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationSummaryAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SUMMARY_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationSummaryDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SUMMARY_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationUpdatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVariancePopulationUpdatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleCreatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleCreatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleCronofyEventIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CRONOFY_EVENT_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleCronofyEventIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CRONOFY_EVENT_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleDescriptionAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleDescriptionDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleEndTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_END_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleEndTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_END_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleSchedulingIdAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SCHEDULING_ID_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleSchedulingIdDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SCHEDULING_ID_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleSchedulingTokenAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SCHEDULING_TOKEN_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleSchedulingTokenDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SCHEDULING_TOKEN_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleSchedulingUrlAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SCHEDULING_URL_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleSchedulingUrlDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SCHEDULING_URL_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleStartTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_START_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleStartTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_START_TIME_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleStatusAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STATUS_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleStatusDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STATUS_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleSummaryAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SUMMARY_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleSummaryDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SUMMARY_DESC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleUpdatedTimeAsc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  CampaignMemberMeetingsByCampaignMemberIdVarianceSampleUpdatedTimeDesc = 'CAMPAIGN_MEMBER_MEETINGS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdAverageCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdAverageCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdAverageFlagAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_FLAG_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdAverageFlagDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_FLAG_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdAverageIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdAverageIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdAverageValueAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_VALUE_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdAverageValueDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_VALUE_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdCountAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdCountDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdDistinctCountFlagAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_FLAG_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdDistinctCountFlagDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_FLAG_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdDistinctCountIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdDistinctCountIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdDistinctCountValueAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_VALUE_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdDistinctCountValueDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_VALUE_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdMaxCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdMaxCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdMaxFlagAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_FLAG_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdMaxFlagDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_FLAG_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdMaxIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdMaxIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdMaxValueAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_VALUE_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdMaxValueDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_VALUE_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdMinCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdMinCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdMinFlagAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_FLAG_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdMinFlagDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_FLAG_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdMinIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdMinIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdMinValueAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_VALUE_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdMinValueDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_VALUE_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevPopulationFlagAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_FLAG_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevPopulationFlagDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_FLAG_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevPopulationIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevPopulationIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevPopulationValueAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_VALUE_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevPopulationValueDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_VALUE_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevSampleFlagAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_FLAG_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevSampleFlagDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_FLAG_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevSampleIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevSampleIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevSampleValueAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_VALUE_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdStddevSampleValueDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_VALUE_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdSumCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdSumCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdSumFlagAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_FLAG_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdSumFlagDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_FLAG_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdSumIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdSumIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdSumValueAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_VALUE_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdSumValueDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_VALUE_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdVariancePopulationFlagAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_FLAG_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdVariancePopulationFlagDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_FLAG_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdVariancePopulationIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdVariancePopulationIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdVariancePopulationValueAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_VALUE_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdVariancePopulationValueDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_VALUE_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdVarianceSampleFlagAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_FLAG_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdVarianceSampleFlagDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_FLAG_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdVarianceSampleIdAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdVarianceSampleIdDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC',
  CampaignMemberPersonalisationsByCampaignMemberIdVarianceSampleValueAsc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_VALUE_ASC',
  CampaignMemberPersonalisationsByCampaignMemberIdVarianceSampleValueDesc = 'CAMPAIGN_MEMBER_PERSONALISATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_VALUE_DESC',
  CampaignMemberVariationsByCampaignMemberIdAverageCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdAverageCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdAverageCreatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdAverageCreatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdAverageUpdatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdAverageUpdatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdAverageVariationIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_VARIATION_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdAverageVariationIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_VARIATION_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdCountAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  CampaignMemberVariationsByCampaignMemberIdCountDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  CampaignMemberVariationsByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdDistinctCountCreatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdDistinctCountCreatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdDistinctCountUpdatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdDistinctCountUpdatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdDistinctCountVariationIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_VARIATION_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdDistinctCountVariationIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_VARIATION_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdMaxCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdMaxCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdMaxCreatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdMaxCreatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdMaxUpdatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdMaxUpdatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdMaxVariationIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_VARIATION_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdMaxVariationIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MAX_VARIATION_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdMinCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdMinCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdMinCreatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdMinCreatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdMinUpdatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdMinUpdatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdMinVariationIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_VARIATION_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdMinVariationIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_MIN_VARIATION_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdStddevPopulationCreatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdStddevPopulationCreatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdStddevPopulationUpdatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdStddevPopulationUpdatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdStddevPopulationVariationIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_VARIATION_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdStddevPopulationVariationIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_VARIATION_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdStddevSampleCreatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdStddevSampleCreatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdStddevSampleUpdatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdStddevSampleUpdatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdStddevSampleVariationIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_VARIATION_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdStddevSampleVariationIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_VARIATION_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdSumCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdSumCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdSumCreatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdSumCreatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdSumUpdatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdSumUpdatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdSumVariationIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_VARIATION_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdSumVariationIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_SUM_VARIATION_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdVariancePopulationCreatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdVariancePopulationCreatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdVariancePopulationUpdatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdVariancePopulationUpdatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdVariancePopulationVariationIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_VARIATION_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdVariancePopulationVariationIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_VARIATION_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  CampaignMemberVariationsByCampaignMemberIdVarianceSampleCreatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdVarianceSampleCreatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdVarianceSampleUpdatedTimeAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  CampaignMemberVariationsByCampaignMemberIdVarianceSampleUpdatedTimeDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  CampaignMemberVariationsByCampaignMemberIdVarianceSampleVariationIdAsc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_VARIATION_ID_ASC',
  CampaignMemberVariationsByCampaignMemberIdVarianceSampleVariationIdDesc = 'CAMPAIGN_MEMBER_VARIATIONS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_VARIATION_ID_DESC',
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  EmailsByCampaignMemberIdAverageCampaignMemberIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByCampaignMemberIdAverageCampaignMemberIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByCampaignMemberIdAverageContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTENT_ASC',
  EmailsByCampaignMemberIdAverageContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTENT_DESC',
  EmailsByCampaignMemberIdAverageCreatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_ASC',
  EmailsByCampaignMemberIdAverageCreatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_DESC',
  EmailsByCampaignMemberIdAverageIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_ASC',
  EmailsByCampaignMemberIdAverageIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_DESC',
  EmailsByCampaignMemberIdAverageSenderProfileIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  EmailsByCampaignMemberIdAverageSenderProfileIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  EmailsByCampaignMemberIdAverageSendAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SEND_AT_ASC',
  EmailsByCampaignMemberIdAverageSendAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SEND_AT_DESC',
  EmailsByCampaignMemberIdAverageSentAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SENT_AT_ASC',
  EmailsByCampaignMemberIdAverageSentAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SENT_AT_DESC',
  EmailsByCampaignMemberIdAverageSmtpMessageIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SMTP_MESSAGE_ID_ASC',
  EmailsByCampaignMemberIdAverageSmtpMessageIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SMTP_MESSAGE_ID_DESC',
  EmailsByCampaignMemberIdAverageStatusAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STATUS_ASC',
  EmailsByCampaignMemberIdAverageStatusDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STATUS_DESC',
  EmailsByCampaignMemberIdAverageTemplateContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_TEMPLATE_CONTENT_ASC',
  EmailsByCampaignMemberIdAverageTemplateContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_TEMPLATE_CONTENT_DESC',
  EmailsByCampaignMemberIdAverageTemplateIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_TEMPLATE_ID_ASC',
  EmailsByCampaignMemberIdAverageTemplateIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_TEMPLATE_ID_DESC',
  EmailsByCampaignMemberIdAverageUpdatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailsByCampaignMemberIdAverageUpdatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailsByCampaignMemberIdCountAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  EmailsByCampaignMemberIdCountDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  EmailsByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByCampaignMemberIdDistinctCountContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTENT_ASC',
  EmailsByCampaignMemberIdDistinctCountContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTENT_DESC',
  EmailsByCampaignMemberIdDistinctCountCreatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailsByCampaignMemberIdDistinctCountCreatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailsByCampaignMemberIdDistinctCountIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_ASC',
  EmailsByCampaignMemberIdDistinctCountIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_DESC',
  EmailsByCampaignMemberIdDistinctCountSenderProfileIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  EmailsByCampaignMemberIdDistinctCountSenderProfileIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  EmailsByCampaignMemberIdDistinctCountSendAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SEND_AT_ASC',
  EmailsByCampaignMemberIdDistinctCountSendAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SEND_AT_DESC',
  EmailsByCampaignMemberIdDistinctCountSentAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SENT_AT_ASC',
  EmailsByCampaignMemberIdDistinctCountSentAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SENT_AT_DESC',
  EmailsByCampaignMemberIdDistinctCountSmtpMessageIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_ASC',
  EmailsByCampaignMemberIdDistinctCountSmtpMessageIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_DESC',
  EmailsByCampaignMemberIdDistinctCountStatusAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STATUS_ASC',
  EmailsByCampaignMemberIdDistinctCountStatusDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STATUS_DESC',
  EmailsByCampaignMemberIdDistinctCountTemplateContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_TEMPLATE_CONTENT_ASC',
  EmailsByCampaignMemberIdDistinctCountTemplateContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_TEMPLATE_CONTENT_DESC',
  EmailsByCampaignMemberIdDistinctCountTemplateIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  EmailsByCampaignMemberIdDistinctCountTemplateIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  EmailsByCampaignMemberIdDistinctCountUpdatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailsByCampaignMemberIdDistinctCountUpdatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailsByCampaignMemberIdMaxCampaignMemberIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByCampaignMemberIdMaxCampaignMemberIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByCampaignMemberIdMaxContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_CONTENT_ASC',
  EmailsByCampaignMemberIdMaxContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_CONTENT_DESC',
  EmailsByCampaignMemberIdMaxCreatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_ASC',
  EmailsByCampaignMemberIdMaxCreatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_DESC',
  EmailsByCampaignMemberIdMaxIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_ASC',
  EmailsByCampaignMemberIdMaxIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_DESC',
  EmailsByCampaignMemberIdMaxSenderProfileIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_SENDER_PROFILE_ID_ASC',
  EmailsByCampaignMemberIdMaxSenderProfileIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_SENDER_PROFILE_ID_DESC',
  EmailsByCampaignMemberIdMaxSendAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_SEND_AT_ASC',
  EmailsByCampaignMemberIdMaxSendAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_SEND_AT_DESC',
  EmailsByCampaignMemberIdMaxSentAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_SENT_AT_ASC',
  EmailsByCampaignMemberIdMaxSentAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_SENT_AT_DESC',
  EmailsByCampaignMemberIdMaxSmtpMessageIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_SMTP_MESSAGE_ID_ASC',
  EmailsByCampaignMemberIdMaxSmtpMessageIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_SMTP_MESSAGE_ID_DESC',
  EmailsByCampaignMemberIdMaxStatusAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_STATUS_ASC',
  EmailsByCampaignMemberIdMaxStatusDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_STATUS_DESC',
  EmailsByCampaignMemberIdMaxTemplateContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_TEMPLATE_CONTENT_ASC',
  EmailsByCampaignMemberIdMaxTemplateContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_TEMPLATE_CONTENT_DESC',
  EmailsByCampaignMemberIdMaxTemplateIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_TEMPLATE_ID_ASC',
  EmailsByCampaignMemberIdMaxTemplateIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_TEMPLATE_ID_DESC',
  EmailsByCampaignMemberIdMaxUpdatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_ASC',
  EmailsByCampaignMemberIdMaxUpdatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_DESC',
  EmailsByCampaignMemberIdMinCampaignMemberIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByCampaignMemberIdMinCampaignMemberIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByCampaignMemberIdMinContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_CONTENT_ASC',
  EmailsByCampaignMemberIdMinContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_CONTENT_DESC',
  EmailsByCampaignMemberIdMinCreatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_ASC',
  EmailsByCampaignMemberIdMinCreatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_DESC',
  EmailsByCampaignMemberIdMinIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_ASC',
  EmailsByCampaignMemberIdMinIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_DESC',
  EmailsByCampaignMemberIdMinSenderProfileIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_SENDER_PROFILE_ID_ASC',
  EmailsByCampaignMemberIdMinSenderProfileIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_SENDER_PROFILE_ID_DESC',
  EmailsByCampaignMemberIdMinSendAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_SEND_AT_ASC',
  EmailsByCampaignMemberIdMinSendAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_SEND_AT_DESC',
  EmailsByCampaignMemberIdMinSentAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_SENT_AT_ASC',
  EmailsByCampaignMemberIdMinSentAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_SENT_AT_DESC',
  EmailsByCampaignMemberIdMinSmtpMessageIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_SMTP_MESSAGE_ID_ASC',
  EmailsByCampaignMemberIdMinSmtpMessageIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_SMTP_MESSAGE_ID_DESC',
  EmailsByCampaignMemberIdMinStatusAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_STATUS_ASC',
  EmailsByCampaignMemberIdMinStatusDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_STATUS_DESC',
  EmailsByCampaignMemberIdMinTemplateContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_TEMPLATE_CONTENT_ASC',
  EmailsByCampaignMemberIdMinTemplateContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_TEMPLATE_CONTENT_DESC',
  EmailsByCampaignMemberIdMinTemplateIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_TEMPLATE_ID_ASC',
  EmailsByCampaignMemberIdMinTemplateIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_TEMPLATE_ID_DESC',
  EmailsByCampaignMemberIdMinUpdatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_ASC',
  EmailsByCampaignMemberIdMinUpdatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_DESC',
  EmailsByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByCampaignMemberIdStddevPopulationContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTENT_ASC',
  EmailsByCampaignMemberIdStddevPopulationContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTENT_DESC',
  EmailsByCampaignMemberIdStddevPopulationCreatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailsByCampaignMemberIdStddevPopulationCreatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailsByCampaignMemberIdStddevPopulationIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_ASC',
  EmailsByCampaignMemberIdStddevPopulationIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_DESC',
  EmailsByCampaignMemberIdStddevPopulationSenderProfileIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailsByCampaignMemberIdStddevPopulationSenderProfileIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailsByCampaignMemberIdStddevPopulationSendAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SEND_AT_ASC',
  EmailsByCampaignMemberIdStddevPopulationSendAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SEND_AT_DESC',
  EmailsByCampaignMemberIdStddevPopulationSentAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SENT_AT_ASC',
  EmailsByCampaignMemberIdStddevPopulationSentAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SENT_AT_DESC',
  EmailsByCampaignMemberIdStddevPopulationSmtpMessageIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailsByCampaignMemberIdStddevPopulationSmtpMessageIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailsByCampaignMemberIdStddevPopulationStatusAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STATUS_ASC',
  EmailsByCampaignMemberIdStddevPopulationStatusDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STATUS_DESC',
  EmailsByCampaignMemberIdStddevPopulationTemplateContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_TEMPLATE_CONTENT_ASC',
  EmailsByCampaignMemberIdStddevPopulationTemplateContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_TEMPLATE_CONTENT_DESC',
  EmailsByCampaignMemberIdStddevPopulationTemplateIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  EmailsByCampaignMemberIdStddevPopulationTemplateIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  EmailsByCampaignMemberIdStddevPopulationUpdatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailsByCampaignMemberIdStddevPopulationUpdatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailsByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByCampaignMemberIdStddevSampleContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTENT_ASC',
  EmailsByCampaignMemberIdStddevSampleContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTENT_DESC',
  EmailsByCampaignMemberIdStddevSampleCreatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailsByCampaignMemberIdStddevSampleCreatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailsByCampaignMemberIdStddevSampleIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_ASC',
  EmailsByCampaignMemberIdStddevSampleIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_DESC',
  EmailsByCampaignMemberIdStddevSampleSenderProfileIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailsByCampaignMemberIdStddevSampleSenderProfileIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailsByCampaignMemberIdStddevSampleSendAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SEND_AT_ASC',
  EmailsByCampaignMemberIdStddevSampleSendAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SEND_AT_DESC',
  EmailsByCampaignMemberIdStddevSampleSentAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SENT_AT_ASC',
  EmailsByCampaignMemberIdStddevSampleSentAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SENT_AT_DESC',
  EmailsByCampaignMemberIdStddevSampleSmtpMessageIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailsByCampaignMemberIdStddevSampleSmtpMessageIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailsByCampaignMemberIdStddevSampleStatusAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STATUS_ASC',
  EmailsByCampaignMemberIdStddevSampleStatusDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STATUS_DESC',
  EmailsByCampaignMemberIdStddevSampleTemplateContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_TEMPLATE_CONTENT_ASC',
  EmailsByCampaignMemberIdStddevSampleTemplateContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_TEMPLATE_CONTENT_DESC',
  EmailsByCampaignMemberIdStddevSampleTemplateIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  EmailsByCampaignMemberIdStddevSampleTemplateIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  EmailsByCampaignMemberIdStddevSampleUpdatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailsByCampaignMemberIdStddevSampleUpdatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailsByCampaignMemberIdSumCampaignMemberIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByCampaignMemberIdSumCampaignMemberIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByCampaignMemberIdSumContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_CONTENT_ASC',
  EmailsByCampaignMemberIdSumContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_CONTENT_DESC',
  EmailsByCampaignMemberIdSumCreatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_ASC',
  EmailsByCampaignMemberIdSumCreatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_DESC',
  EmailsByCampaignMemberIdSumIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_ASC',
  EmailsByCampaignMemberIdSumIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_DESC',
  EmailsByCampaignMemberIdSumSenderProfileIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_SENDER_PROFILE_ID_ASC',
  EmailsByCampaignMemberIdSumSenderProfileIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_SENDER_PROFILE_ID_DESC',
  EmailsByCampaignMemberIdSumSendAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_SEND_AT_ASC',
  EmailsByCampaignMemberIdSumSendAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_SEND_AT_DESC',
  EmailsByCampaignMemberIdSumSentAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_SENT_AT_ASC',
  EmailsByCampaignMemberIdSumSentAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_SENT_AT_DESC',
  EmailsByCampaignMemberIdSumSmtpMessageIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_SMTP_MESSAGE_ID_ASC',
  EmailsByCampaignMemberIdSumSmtpMessageIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_SMTP_MESSAGE_ID_DESC',
  EmailsByCampaignMemberIdSumStatusAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_STATUS_ASC',
  EmailsByCampaignMemberIdSumStatusDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_STATUS_DESC',
  EmailsByCampaignMemberIdSumTemplateContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_TEMPLATE_CONTENT_ASC',
  EmailsByCampaignMemberIdSumTemplateContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_TEMPLATE_CONTENT_DESC',
  EmailsByCampaignMemberIdSumTemplateIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_TEMPLATE_ID_ASC',
  EmailsByCampaignMemberIdSumTemplateIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_TEMPLATE_ID_DESC',
  EmailsByCampaignMemberIdSumUpdatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_ASC',
  EmailsByCampaignMemberIdSumUpdatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_DESC',
  EmailsByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByCampaignMemberIdVariancePopulationContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTENT_ASC',
  EmailsByCampaignMemberIdVariancePopulationContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTENT_DESC',
  EmailsByCampaignMemberIdVariancePopulationCreatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailsByCampaignMemberIdVariancePopulationCreatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailsByCampaignMemberIdVariancePopulationIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_ASC',
  EmailsByCampaignMemberIdVariancePopulationIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_DESC',
  EmailsByCampaignMemberIdVariancePopulationSenderProfileIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailsByCampaignMemberIdVariancePopulationSenderProfileIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailsByCampaignMemberIdVariancePopulationSendAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SEND_AT_ASC',
  EmailsByCampaignMemberIdVariancePopulationSendAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SEND_AT_DESC',
  EmailsByCampaignMemberIdVariancePopulationSentAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SENT_AT_ASC',
  EmailsByCampaignMemberIdVariancePopulationSentAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SENT_AT_DESC',
  EmailsByCampaignMemberIdVariancePopulationSmtpMessageIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailsByCampaignMemberIdVariancePopulationSmtpMessageIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailsByCampaignMemberIdVariancePopulationStatusAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STATUS_ASC',
  EmailsByCampaignMemberIdVariancePopulationStatusDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STATUS_DESC',
  EmailsByCampaignMemberIdVariancePopulationTemplateContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_TEMPLATE_CONTENT_ASC',
  EmailsByCampaignMemberIdVariancePopulationTemplateContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_TEMPLATE_CONTENT_DESC',
  EmailsByCampaignMemberIdVariancePopulationTemplateIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  EmailsByCampaignMemberIdVariancePopulationTemplateIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  EmailsByCampaignMemberIdVariancePopulationUpdatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailsByCampaignMemberIdVariancePopulationUpdatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailsByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByCampaignMemberIdVarianceSampleContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  EmailsByCampaignMemberIdVarianceSampleContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  EmailsByCampaignMemberIdVarianceSampleCreatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailsByCampaignMemberIdVarianceSampleCreatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailsByCampaignMemberIdVarianceSampleIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailsByCampaignMemberIdVarianceSampleIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailsByCampaignMemberIdVarianceSampleSenderProfileIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailsByCampaignMemberIdVarianceSampleSenderProfileIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailsByCampaignMemberIdVarianceSampleSendAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SEND_AT_ASC',
  EmailsByCampaignMemberIdVarianceSampleSendAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SEND_AT_DESC',
  EmailsByCampaignMemberIdVarianceSampleSentAtAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SENT_AT_ASC',
  EmailsByCampaignMemberIdVarianceSampleSentAtDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SENT_AT_DESC',
  EmailsByCampaignMemberIdVarianceSampleSmtpMessageIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailsByCampaignMemberIdVarianceSampleSmtpMessageIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailsByCampaignMemberIdVarianceSampleStatusAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STATUS_ASC',
  EmailsByCampaignMemberIdVarianceSampleStatusDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STATUS_DESC',
  EmailsByCampaignMemberIdVarianceSampleTemplateContentAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_TEMPLATE_CONTENT_ASC',
  EmailsByCampaignMemberIdVarianceSampleTemplateContentDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_TEMPLATE_CONTENT_DESC',
  EmailsByCampaignMemberIdVarianceSampleTemplateIdAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  EmailsByCampaignMemberIdVarianceSampleTemplateIdDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  EmailsByCampaignMemberIdVarianceSampleUpdatedTimeAsc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailsByCampaignMemberIdVarianceSampleUpdatedTimeDesc = 'EMAILS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdAverageAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignMemberIdAverageAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignMemberIdAverageCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignMemberIdAverageCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignMemberIdAverageCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignMemberIdAverageCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignMemberIdAverageCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdAverageCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdAverageCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_COMPANY_ID_ASC',
  EmailMessagesByCampaignMemberIdAverageCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_COMPANY_ID_DESC',
  EmailMessagesByCampaignMemberIdAverageContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTACT_ID_ASC',
  EmailMessagesByCampaignMemberIdAverageContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTACT_ID_DESC',
  EmailMessagesByCampaignMemberIdAverageContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTENT_ASC',
  EmailMessagesByCampaignMemberIdAverageContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTENT_DESC',
  EmailMessagesByCampaignMemberIdAverageContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTENT_HTML_ASC',
  EmailMessagesByCampaignMemberIdAverageContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTENT_HTML_DESC',
  EmailMessagesByCampaignMemberIdAverageCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdAverageCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdAverageEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_EMAIL_ID_ASC',
  EmailMessagesByCampaignMemberIdAverageEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_EMAIL_ID_DESC',
  EmailMessagesByCampaignMemberIdAverageForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignMemberIdAverageForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignMemberIdAverageForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdAverageForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdAverageGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignMemberIdAverageGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignMemberIdAverageHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdAverageHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdAverageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdAverageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdAverageIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_INTENT_ASC',
  EmailMessagesByCampaignMemberIdAverageIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_INTENT_DESC',
  EmailMessagesByCampaignMemberIdAverageIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_IS_LAWYER_ASC',
  EmailMessagesByCampaignMemberIdAverageIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_IS_LAWYER_DESC',
  EmailMessagesByCampaignMemberIdAverageIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_IS_REVIEWED_ASC',
  EmailMessagesByCampaignMemberIdAverageIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_IS_REVIEWED_DESC',
  EmailMessagesByCampaignMemberIdAverageLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_LAWYER_ID_ASC',
  EmailMessagesByCampaignMemberIdAverageLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_LAWYER_ID_DESC',
  EmailMessagesByCampaignMemberIdAverageLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdAverageLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdAverageNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignMemberIdAverageNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignMemberIdAverageOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ORDER_INDEX_ASC',
  EmailMessagesByCampaignMemberIdAverageOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ORDER_INDEX_DESC',
  EmailMessagesByCampaignMemberIdAveragePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignMemberIdAveragePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignMemberIdAverageRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignMemberIdAverageRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignMemberIdAverageRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REPLIED_TIME_ASC',
  EmailMessagesByCampaignMemberIdAverageRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REPLIED_TIME_DESC',
  EmailMessagesByCampaignMemberIdAverageRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdAverageRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdAverageReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdAverageReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdAverageSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignMemberIdAverageSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignMemberIdAverageSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SENT_TIME_ASC',
  EmailMessagesByCampaignMemberIdAverageSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SENT_TIME_DESC',
  EmailMessagesByCampaignMemberIdAverageSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdAverageSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdAverageSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SUBJECT_ASC',
  EmailMessagesByCampaignMemberIdAverageSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SUBJECT_DESC',
  EmailMessagesByCampaignMemberIdAverageTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignMemberIdAverageTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignMemberIdAverageUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdAverageUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdCountAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  EmailMessagesByCampaignMemberIdCountDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTENT_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTENT_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTENT_HTML_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTENT_HTML_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_INTENT_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_INTENT_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_IS_REVIEWED_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_IS_REVIEWED_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ORDER_INDEX_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ORDER_INDEX_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REPLIED_TIME_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REPLIED_TIME_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SENT_TIME_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SENT_TIME_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SUBJECT_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SUBJECT_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignMemberIdDistinctCountUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdDistinctCountUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdMaxAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignMemberIdMaxAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignMemberIdMaxCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignMemberIdMaxCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignMemberIdMaxCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignMemberIdMaxCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignMemberIdMaxCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdMaxCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdMaxCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_COMPANY_ID_ASC',
  EmailMessagesByCampaignMemberIdMaxCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_COMPANY_ID_DESC',
  EmailMessagesByCampaignMemberIdMaxContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CONTACT_ID_ASC',
  EmailMessagesByCampaignMemberIdMaxContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CONTACT_ID_DESC',
  EmailMessagesByCampaignMemberIdMaxContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CONTENT_ASC',
  EmailMessagesByCampaignMemberIdMaxContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CONTENT_DESC',
  EmailMessagesByCampaignMemberIdMaxContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CONTENT_HTML_ASC',
  EmailMessagesByCampaignMemberIdMaxContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CONTENT_HTML_DESC',
  EmailMessagesByCampaignMemberIdMaxCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdMaxCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdMaxEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_EMAIL_ID_ASC',
  EmailMessagesByCampaignMemberIdMaxEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_EMAIL_ID_DESC',
  EmailMessagesByCampaignMemberIdMaxForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignMemberIdMaxForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignMemberIdMaxForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdMaxForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdMaxGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignMemberIdMaxGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignMemberIdMaxHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdMaxHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdMaxIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_ID_ASC',
  EmailMessagesByCampaignMemberIdMaxIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_ID_DESC',
  EmailMessagesByCampaignMemberIdMaxIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_INTENT_ASC',
  EmailMessagesByCampaignMemberIdMaxIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_INTENT_DESC',
  EmailMessagesByCampaignMemberIdMaxIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_IS_LAWYER_ASC',
  EmailMessagesByCampaignMemberIdMaxIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_IS_LAWYER_DESC',
  EmailMessagesByCampaignMemberIdMaxIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_IS_REVIEWED_ASC',
  EmailMessagesByCampaignMemberIdMaxIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_IS_REVIEWED_DESC',
  EmailMessagesByCampaignMemberIdMaxLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_LAWYER_ID_ASC',
  EmailMessagesByCampaignMemberIdMaxLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_LAWYER_ID_DESC',
  EmailMessagesByCampaignMemberIdMaxLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdMaxLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdMaxNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignMemberIdMaxNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignMemberIdMaxOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_ORDER_INDEX_ASC',
  EmailMessagesByCampaignMemberIdMaxOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_ORDER_INDEX_DESC',
  EmailMessagesByCampaignMemberIdMaxPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignMemberIdMaxPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignMemberIdMaxRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignMemberIdMaxRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignMemberIdMaxRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REPLIED_TIME_ASC',
  EmailMessagesByCampaignMemberIdMaxRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REPLIED_TIME_DESC',
  EmailMessagesByCampaignMemberIdMaxRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdMaxRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdMaxReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdMaxReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdMaxSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignMemberIdMaxSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignMemberIdMaxSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_SENT_TIME_ASC',
  EmailMessagesByCampaignMemberIdMaxSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_SENT_TIME_DESC',
  EmailMessagesByCampaignMemberIdMaxSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdMaxSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdMaxSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_SUBJECT_ASC',
  EmailMessagesByCampaignMemberIdMaxSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_SUBJECT_DESC',
  EmailMessagesByCampaignMemberIdMaxTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignMemberIdMaxTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignMemberIdMaxUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdMaxUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdMinAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignMemberIdMinAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignMemberIdMinCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignMemberIdMinCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignMemberIdMinCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignMemberIdMinCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignMemberIdMinCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdMinCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdMinCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_COMPANY_ID_ASC',
  EmailMessagesByCampaignMemberIdMinCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_COMPANY_ID_DESC',
  EmailMessagesByCampaignMemberIdMinContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CONTACT_ID_ASC',
  EmailMessagesByCampaignMemberIdMinContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CONTACT_ID_DESC',
  EmailMessagesByCampaignMemberIdMinContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CONTENT_ASC',
  EmailMessagesByCampaignMemberIdMinContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CONTENT_DESC',
  EmailMessagesByCampaignMemberIdMinContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CONTENT_HTML_ASC',
  EmailMessagesByCampaignMemberIdMinContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CONTENT_HTML_DESC',
  EmailMessagesByCampaignMemberIdMinCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdMinCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdMinEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_EMAIL_ID_ASC',
  EmailMessagesByCampaignMemberIdMinEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_EMAIL_ID_DESC',
  EmailMessagesByCampaignMemberIdMinForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignMemberIdMinForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignMemberIdMinForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdMinForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdMinGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignMemberIdMinGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignMemberIdMinHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdMinHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdMinIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_ID_ASC',
  EmailMessagesByCampaignMemberIdMinIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_ID_DESC',
  EmailMessagesByCampaignMemberIdMinIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_INTENT_ASC',
  EmailMessagesByCampaignMemberIdMinIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_INTENT_DESC',
  EmailMessagesByCampaignMemberIdMinIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_IS_LAWYER_ASC',
  EmailMessagesByCampaignMemberIdMinIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_IS_LAWYER_DESC',
  EmailMessagesByCampaignMemberIdMinIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_IS_REVIEWED_ASC',
  EmailMessagesByCampaignMemberIdMinIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_IS_REVIEWED_DESC',
  EmailMessagesByCampaignMemberIdMinLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_LAWYER_ID_ASC',
  EmailMessagesByCampaignMemberIdMinLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_LAWYER_ID_DESC',
  EmailMessagesByCampaignMemberIdMinLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdMinLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdMinNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignMemberIdMinNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignMemberIdMinOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_ORDER_INDEX_ASC',
  EmailMessagesByCampaignMemberIdMinOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_ORDER_INDEX_DESC',
  EmailMessagesByCampaignMemberIdMinPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignMemberIdMinPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignMemberIdMinRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignMemberIdMinRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignMemberIdMinRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REPLIED_TIME_ASC',
  EmailMessagesByCampaignMemberIdMinRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REPLIED_TIME_DESC',
  EmailMessagesByCampaignMemberIdMinRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdMinRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdMinReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdMinReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdMinSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignMemberIdMinSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignMemberIdMinSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_SENT_TIME_ASC',
  EmailMessagesByCampaignMemberIdMinSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_SENT_TIME_DESC',
  EmailMessagesByCampaignMemberIdMinSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdMinSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdMinSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_SUBJECT_ASC',
  EmailMessagesByCampaignMemberIdMinSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_SUBJECT_DESC',
  EmailMessagesByCampaignMemberIdMinTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignMemberIdMinTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignMemberIdMinUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdMinUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTENT_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTENT_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_INTENT_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_INTENT_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SENT_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SENT_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SUBJECT_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SUBJECT_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevPopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevPopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTENT_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTENT_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_INTENT_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_INTENT_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByCampaignMemberIdStddevSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SUBJECT_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SUBJECT_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignMemberIdStddevSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdStddevSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdSumAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignMemberIdSumAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignMemberIdSumCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignMemberIdSumCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignMemberIdSumCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignMemberIdSumCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignMemberIdSumCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdSumCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdSumCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_COMPANY_ID_ASC',
  EmailMessagesByCampaignMemberIdSumCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_COMPANY_ID_DESC',
  EmailMessagesByCampaignMemberIdSumContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CONTACT_ID_ASC',
  EmailMessagesByCampaignMemberIdSumContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CONTACT_ID_DESC',
  EmailMessagesByCampaignMemberIdSumContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CONTENT_ASC',
  EmailMessagesByCampaignMemberIdSumContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CONTENT_DESC',
  EmailMessagesByCampaignMemberIdSumContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CONTENT_HTML_ASC',
  EmailMessagesByCampaignMemberIdSumContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CONTENT_HTML_DESC',
  EmailMessagesByCampaignMemberIdSumCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdSumCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdSumEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_EMAIL_ID_ASC',
  EmailMessagesByCampaignMemberIdSumEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_EMAIL_ID_DESC',
  EmailMessagesByCampaignMemberIdSumForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignMemberIdSumForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignMemberIdSumForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdSumForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdSumGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignMemberIdSumGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignMemberIdSumHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdSumHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdSumIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_ID_ASC',
  EmailMessagesByCampaignMemberIdSumIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_ID_DESC',
  EmailMessagesByCampaignMemberIdSumIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_INTENT_ASC',
  EmailMessagesByCampaignMemberIdSumIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_INTENT_DESC',
  EmailMessagesByCampaignMemberIdSumIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_IS_LAWYER_ASC',
  EmailMessagesByCampaignMemberIdSumIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_IS_LAWYER_DESC',
  EmailMessagesByCampaignMemberIdSumIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_IS_REVIEWED_ASC',
  EmailMessagesByCampaignMemberIdSumIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_IS_REVIEWED_DESC',
  EmailMessagesByCampaignMemberIdSumLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_LAWYER_ID_ASC',
  EmailMessagesByCampaignMemberIdSumLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_LAWYER_ID_DESC',
  EmailMessagesByCampaignMemberIdSumLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdSumLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdSumNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignMemberIdSumNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignMemberIdSumOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_ORDER_INDEX_ASC',
  EmailMessagesByCampaignMemberIdSumOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_ORDER_INDEX_DESC',
  EmailMessagesByCampaignMemberIdSumPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignMemberIdSumPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignMemberIdSumRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignMemberIdSumRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignMemberIdSumRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REPLIED_TIME_ASC',
  EmailMessagesByCampaignMemberIdSumRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REPLIED_TIME_DESC',
  EmailMessagesByCampaignMemberIdSumRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdSumRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdSumReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdSumReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdSumSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignMemberIdSumSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignMemberIdSumSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_SENT_TIME_ASC',
  EmailMessagesByCampaignMemberIdSumSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_SENT_TIME_DESC',
  EmailMessagesByCampaignMemberIdSumSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdSumSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdSumSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_SUBJECT_ASC',
  EmailMessagesByCampaignMemberIdSumSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_SUBJECT_DESC',
  EmailMessagesByCampaignMemberIdSumTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignMemberIdSumTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignMemberIdSumUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdSumUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTENT_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTENT_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_INTENT_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_INTENT_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SENT_TIME_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SENT_TIME_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SUBJECT_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SUBJECT_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignMemberIdVariancePopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdVariancePopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_INTENT_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_INTENT_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByCampaignMemberIdVarianceSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignMemberIdVarianceSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SUBJECT_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SUBJECT_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignMemberIdVarianceSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByCampaignMemberIdVarianceSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IncludeLawyerAsc = 'INCLUDE_LAWYER_ASC',
  IncludeLawyerDesc = 'INCLUDE_LAWYER_DESC',
  IsConversationTimeAsc = 'IS_CONVERSATION_TIME_ASC',
  IsConversationTimeDesc = 'IS_CONVERSATION_TIME_DESC',
  LatestEmailMessageCreatedTimeAsc = 'LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  LatestEmailMessageCreatedTimeDesc = 'LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  LawyerViewedTimeAsc = 'LAWYER_VIEWED_TIME_ASC',
  LawyerViewedTimeDesc = 'LAWYER_VIEWED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageCampaignMemberIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageCampaignMemberIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageCreatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageCreatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageMessageTextAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_MESSAGE_TEXT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageMessageTextDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_MESSAGE_TEXT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageSendAtAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SEND_AT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageSendAtDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SEND_AT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageStatusAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STATUS_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageStatusDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STATUS_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageUpdatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdAverageUpdatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdCountAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdCountDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountCreatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountCreatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountMessageTextAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_MESSAGE_TEXT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountMessageTextDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_MESSAGE_TEXT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountSendAtAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SEND_AT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountSendAtDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SEND_AT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountStatusAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STATUS_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountStatusDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STATUS_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountUpdatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdDistinctCountUpdatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxCampaignMemberIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxCampaignMemberIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxCreatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxCreatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxMessageTextAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_MESSAGE_TEXT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxMessageTextDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_MESSAGE_TEXT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxSendAtAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_SEND_AT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxSendAtDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_SEND_AT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxStatusAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_STATUS_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxStatusDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_STATUS_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxUpdatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMaxUpdatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMinCampaignMemberIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMinCampaignMemberIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMinCreatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMinCreatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMinIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMinIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMinMessageTextAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_MESSAGE_TEXT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMinMessageTextDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_MESSAGE_TEXT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMinSendAtAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_SEND_AT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMinSendAtDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_SEND_AT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMinStatusAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_STATUS_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMinStatusDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_STATUS_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdMinUpdatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdMinUpdatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationCreatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationCreatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationMessageTextAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_MESSAGE_TEXT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationMessageTextDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_MESSAGE_TEXT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationSendAtAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SEND_AT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationSendAtDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SEND_AT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationStatusAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STATUS_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationStatusDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STATUS_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationUpdatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevPopulationUpdatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleCreatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleCreatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleMessageTextAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_MESSAGE_TEXT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleMessageTextDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_MESSAGE_TEXT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleSendAtAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SEND_AT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleSendAtDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SEND_AT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleStatusAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STATUS_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleStatusDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STATUS_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleUpdatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdStddevSampleUpdatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdSumCampaignMemberIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdSumCampaignMemberIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdSumCreatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdSumCreatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdSumIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdSumIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdSumMessageTextAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_MESSAGE_TEXT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdSumMessageTextDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_MESSAGE_TEXT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdSumSendAtAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_SEND_AT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdSumSendAtDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_SEND_AT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdSumStatusAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_STATUS_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdSumStatusDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_STATUS_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdSumUpdatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdSumUpdatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationCreatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationCreatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationMessageTextAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_MESSAGE_TEXT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationMessageTextDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_MESSAGE_TEXT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationSendAtAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SEND_AT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationSendAtDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SEND_AT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationStatusAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STATUS_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationStatusDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STATUS_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationUpdatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVariancePopulationUpdatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleCreatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleCreatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleIdAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleIdDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleMessageTextAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_MESSAGE_TEXT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleMessageTextDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_MESSAGE_TEXT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleSendAtAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SEND_AT_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleSendAtDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SEND_AT_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleStatusAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STATUS_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleStatusDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STATUS_DESC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleUpdatedTimeAsc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  LinkedinConnectionRequestsByCampaignMemberIdVarianceSampleUpdatedTimeDesc = 'LINKEDIN_CONNECTION_REQUESTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  LinkedinEventsByCampaignMemberIdAverageCampaignMemberIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinEventsByCampaignMemberIdAverageCampaignMemberIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinEventsByCampaignMemberIdAverageCreatedTimeAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_ASC',
  LinkedinEventsByCampaignMemberIdAverageCreatedTimeDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_DESC',
  LinkedinEventsByCampaignMemberIdAverageIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_ASC',
  LinkedinEventsByCampaignMemberIdAverageIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_DESC',
  LinkedinEventsByCampaignMemberIdAverageStatusAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STATUS_ASC',
  LinkedinEventsByCampaignMemberIdAverageStatusDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STATUS_DESC',
  LinkedinEventsByCampaignMemberIdAverageStatusPayloadAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STATUS_PAYLOAD_ASC',
  LinkedinEventsByCampaignMemberIdAverageStatusPayloadDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STATUS_PAYLOAD_DESC',
  LinkedinEventsByCampaignMemberIdCountAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  LinkedinEventsByCampaignMemberIdCountDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  LinkedinEventsByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinEventsByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinEventsByCampaignMemberIdDistinctCountCreatedTimeAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LinkedinEventsByCampaignMemberIdDistinctCountCreatedTimeDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LinkedinEventsByCampaignMemberIdDistinctCountIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_ASC',
  LinkedinEventsByCampaignMemberIdDistinctCountIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_DESC',
  LinkedinEventsByCampaignMemberIdDistinctCountStatusAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STATUS_ASC',
  LinkedinEventsByCampaignMemberIdDistinctCountStatusDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STATUS_DESC',
  LinkedinEventsByCampaignMemberIdDistinctCountStatusPayloadAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STATUS_PAYLOAD_ASC',
  LinkedinEventsByCampaignMemberIdDistinctCountStatusPayloadDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STATUS_PAYLOAD_DESC',
  LinkedinEventsByCampaignMemberIdMaxCampaignMemberIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinEventsByCampaignMemberIdMaxCampaignMemberIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinEventsByCampaignMemberIdMaxCreatedTimeAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_ASC',
  LinkedinEventsByCampaignMemberIdMaxCreatedTimeDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_DESC',
  LinkedinEventsByCampaignMemberIdMaxIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_ASC',
  LinkedinEventsByCampaignMemberIdMaxIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MAX_ID_DESC',
  LinkedinEventsByCampaignMemberIdMaxStatusAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MAX_STATUS_ASC',
  LinkedinEventsByCampaignMemberIdMaxStatusDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MAX_STATUS_DESC',
  LinkedinEventsByCampaignMemberIdMaxStatusPayloadAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MAX_STATUS_PAYLOAD_ASC',
  LinkedinEventsByCampaignMemberIdMaxStatusPayloadDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MAX_STATUS_PAYLOAD_DESC',
  LinkedinEventsByCampaignMemberIdMinCampaignMemberIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinEventsByCampaignMemberIdMinCampaignMemberIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinEventsByCampaignMemberIdMinCreatedTimeAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_ASC',
  LinkedinEventsByCampaignMemberIdMinCreatedTimeDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_DESC',
  LinkedinEventsByCampaignMemberIdMinIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_ASC',
  LinkedinEventsByCampaignMemberIdMinIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MIN_ID_DESC',
  LinkedinEventsByCampaignMemberIdMinStatusAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MIN_STATUS_ASC',
  LinkedinEventsByCampaignMemberIdMinStatusDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MIN_STATUS_DESC',
  LinkedinEventsByCampaignMemberIdMinStatusPayloadAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MIN_STATUS_PAYLOAD_ASC',
  LinkedinEventsByCampaignMemberIdMinStatusPayloadDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_MIN_STATUS_PAYLOAD_DESC',
  LinkedinEventsByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinEventsByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinEventsByCampaignMemberIdStddevPopulationCreatedTimeAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LinkedinEventsByCampaignMemberIdStddevPopulationCreatedTimeDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LinkedinEventsByCampaignMemberIdStddevPopulationIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_ASC',
  LinkedinEventsByCampaignMemberIdStddevPopulationIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_DESC',
  LinkedinEventsByCampaignMemberIdStddevPopulationStatusAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STATUS_ASC',
  LinkedinEventsByCampaignMemberIdStddevPopulationStatusDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STATUS_DESC',
  LinkedinEventsByCampaignMemberIdStddevPopulationStatusPayloadAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STATUS_PAYLOAD_ASC',
  LinkedinEventsByCampaignMemberIdStddevPopulationStatusPayloadDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STATUS_PAYLOAD_DESC',
  LinkedinEventsByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinEventsByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinEventsByCampaignMemberIdStddevSampleCreatedTimeAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LinkedinEventsByCampaignMemberIdStddevSampleCreatedTimeDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LinkedinEventsByCampaignMemberIdStddevSampleIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_ASC',
  LinkedinEventsByCampaignMemberIdStddevSampleIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_DESC',
  LinkedinEventsByCampaignMemberIdStddevSampleStatusAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STATUS_ASC',
  LinkedinEventsByCampaignMemberIdStddevSampleStatusDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STATUS_DESC',
  LinkedinEventsByCampaignMemberIdStddevSampleStatusPayloadAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STATUS_PAYLOAD_ASC',
  LinkedinEventsByCampaignMemberIdStddevSampleStatusPayloadDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STATUS_PAYLOAD_DESC',
  LinkedinEventsByCampaignMemberIdSumCampaignMemberIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinEventsByCampaignMemberIdSumCampaignMemberIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinEventsByCampaignMemberIdSumCreatedTimeAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_ASC',
  LinkedinEventsByCampaignMemberIdSumCreatedTimeDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_DESC',
  LinkedinEventsByCampaignMemberIdSumIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_ASC',
  LinkedinEventsByCampaignMemberIdSumIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_SUM_ID_DESC',
  LinkedinEventsByCampaignMemberIdSumStatusAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_SUM_STATUS_ASC',
  LinkedinEventsByCampaignMemberIdSumStatusDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_SUM_STATUS_DESC',
  LinkedinEventsByCampaignMemberIdSumStatusPayloadAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_SUM_STATUS_PAYLOAD_ASC',
  LinkedinEventsByCampaignMemberIdSumStatusPayloadDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_SUM_STATUS_PAYLOAD_DESC',
  LinkedinEventsByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinEventsByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinEventsByCampaignMemberIdVariancePopulationCreatedTimeAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LinkedinEventsByCampaignMemberIdVariancePopulationCreatedTimeDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LinkedinEventsByCampaignMemberIdVariancePopulationIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_ASC',
  LinkedinEventsByCampaignMemberIdVariancePopulationIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_DESC',
  LinkedinEventsByCampaignMemberIdVariancePopulationStatusAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STATUS_ASC',
  LinkedinEventsByCampaignMemberIdVariancePopulationStatusDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STATUS_DESC',
  LinkedinEventsByCampaignMemberIdVariancePopulationStatusPayloadAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STATUS_PAYLOAD_ASC',
  LinkedinEventsByCampaignMemberIdVariancePopulationStatusPayloadDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STATUS_PAYLOAD_DESC',
  LinkedinEventsByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinEventsByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinEventsByCampaignMemberIdVarianceSampleCreatedTimeAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LinkedinEventsByCampaignMemberIdVarianceSampleCreatedTimeDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LinkedinEventsByCampaignMemberIdVarianceSampleIdAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC',
  LinkedinEventsByCampaignMemberIdVarianceSampleIdDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC',
  LinkedinEventsByCampaignMemberIdVarianceSampleStatusAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STATUS_ASC',
  LinkedinEventsByCampaignMemberIdVarianceSampleStatusDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STATUS_DESC',
  LinkedinEventsByCampaignMemberIdVarianceSampleStatusPayloadAsc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STATUS_PAYLOAD_ASC',
  LinkedinEventsByCampaignMemberIdVarianceSampleStatusPayloadDesc = 'LINKEDIN_EVENTS_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STATUS_PAYLOAD_DESC',
  LinkedinMessagesByCampaignMemberIdAverageCampaignMemberIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinMessagesByCampaignMemberIdAverageCampaignMemberIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinMessagesByCampaignMemberIdAverageContentAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTENT_ASC',
  LinkedinMessagesByCampaignMemberIdAverageContentDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTENT_DESC',
  LinkedinMessagesByCampaignMemberIdAverageCreatedTimeAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_ASC',
  LinkedinMessagesByCampaignMemberIdAverageCreatedTimeDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_DESC',
  LinkedinMessagesByCampaignMemberIdAverageDateAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_DATE_ASC',
  LinkedinMessagesByCampaignMemberIdAverageDateDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_DATE_DESC',
  LinkedinMessagesByCampaignMemberIdAverageIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_ASC',
  LinkedinMessagesByCampaignMemberIdAverageIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_DESC',
  LinkedinMessagesByCampaignMemberIdAverageIsLawyerAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_IS_LAWYER_ASC',
  LinkedinMessagesByCampaignMemberIdAverageIsLawyerDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_IS_LAWYER_DESC',
  LinkedinMessagesByCampaignMemberIdAverageLabelAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_LABEL_ASC',
  LinkedinMessagesByCampaignMemberIdAverageLabelDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_LABEL_DESC',
  LinkedinMessagesByCampaignMemberIdCountAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  LinkedinMessagesByCampaignMemberIdCountDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  LinkedinMessagesByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinMessagesByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinMessagesByCampaignMemberIdDistinctCountContentAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTENT_ASC',
  LinkedinMessagesByCampaignMemberIdDistinctCountContentDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTENT_DESC',
  LinkedinMessagesByCampaignMemberIdDistinctCountCreatedTimeAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LinkedinMessagesByCampaignMemberIdDistinctCountCreatedTimeDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LinkedinMessagesByCampaignMemberIdDistinctCountDateAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_DATE_ASC',
  LinkedinMessagesByCampaignMemberIdDistinctCountDateDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_DATE_DESC',
  LinkedinMessagesByCampaignMemberIdDistinctCountIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_ASC',
  LinkedinMessagesByCampaignMemberIdDistinctCountIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_DESC',
  LinkedinMessagesByCampaignMemberIdDistinctCountIsLawyerAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  LinkedinMessagesByCampaignMemberIdDistinctCountIsLawyerDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  LinkedinMessagesByCampaignMemberIdDistinctCountLabelAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_LABEL_ASC',
  LinkedinMessagesByCampaignMemberIdDistinctCountLabelDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_LABEL_DESC',
  LinkedinMessagesByCampaignMemberIdMaxCampaignMemberIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinMessagesByCampaignMemberIdMaxCampaignMemberIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinMessagesByCampaignMemberIdMaxContentAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CONTENT_ASC',
  LinkedinMessagesByCampaignMemberIdMaxContentDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CONTENT_DESC',
  LinkedinMessagesByCampaignMemberIdMaxCreatedTimeAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_ASC',
  LinkedinMessagesByCampaignMemberIdMaxCreatedTimeDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_DESC',
  LinkedinMessagesByCampaignMemberIdMaxDateAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_DATE_ASC',
  LinkedinMessagesByCampaignMemberIdMaxDateDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_DATE_DESC',
  LinkedinMessagesByCampaignMemberIdMaxIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_ID_ASC',
  LinkedinMessagesByCampaignMemberIdMaxIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_ID_DESC',
  LinkedinMessagesByCampaignMemberIdMaxIsLawyerAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_IS_LAWYER_ASC',
  LinkedinMessagesByCampaignMemberIdMaxIsLawyerDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_IS_LAWYER_DESC',
  LinkedinMessagesByCampaignMemberIdMaxLabelAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_LABEL_ASC',
  LinkedinMessagesByCampaignMemberIdMaxLabelDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_LABEL_DESC',
  LinkedinMessagesByCampaignMemberIdMinCampaignMemberIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinMessagesByCampaignMemberIdMinCampaignMemberIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinMessagesByCampaignMemberIdMinContentAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CONTENT_ASC',
  LinkedinMessagesByCampaignMemberIdMinContentDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CONTENT_DESC',
  LinkedinMessagesByCampaignMemberIdMinCreatedTimeAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_ASC',
  LinkedinMessagesByCampaignMemberIdMinCreatedTimeDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_DESC',
  LinkedinMessagesByCampaignMemberIdMinDateAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_DATE_ASC',
  LinkedinMessagesByCampaignMemberIdMinDateDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_DATE_DESC',
  LinkedinMessagesByCampaignMemberIdMinIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_ID_ASC',
  LinkedinMessagesByCampaignMemberIdMinIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_ID_DESC',
  LinkedinMessagesByCampaignMemberIdMinIsLawyerAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_IS_LAWYER_ASC',
  LinkedinMessagesByCampaignMemberIdMinIsLawyerDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_IS_LAWYER_DESC',
  LinkedinMessagesByCampaignMemberIdMinLabelAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_LABEL_ASC',
  LinkedinMessagesByCampaignMemberIdMinLabelDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_LABEL_DESC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationContentAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTENT_ASC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationContentDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTENT_DESC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationCreatedTimeAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationCreatedTimeDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationDateAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_DATE_ASC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationDateDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_DATE_DESC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_ASC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_DESC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationIsLawyerAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationIsLawyerDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationLabelAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_LABEL_ASC',
  LinkedinMessagesByCampaignMemberIdStddevPopulationLabelDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_LABEL_DESC',
  LinkedinMessagesByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinMessagesByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinMessagesByCampaignMemberIdStddevSampleContentAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTENT_ASC',
  LinkedinMessagesByCampaignMemberIdStddevSampleContentDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTENT_DESC',
  LinkedinMessagesByCampaignMemberIdStddevSampleCreatedTimeAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LinkedinMessagesByCampaignMemberIdStddevSampleCreatedTimeDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LinkedinMessagesByCampaignMemberIdStddevSampleDateAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_DATE_ASC',
  LinkedinMessagesByCampaignMemberIdStddevSampleDateDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_DATE_DESC',
  LinkedinMessagesByCampaignMemberIdStddevSampleIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_ASC',
  LinkedinMessagesByCampaignMemberIdStddevSampleIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_DESC',
  LinkedinMessagesByCampaignMemberIdStddevSampleIsLawyerAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  LinkedinMessagesByCampaignMemberIdStddevSampleIsLawyerDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  LinkedinMessagesByCampaignMemberIdStddevSampleLabelAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_LABEL_ASC',
  LinkedinMessagesByCampaignMemberIdStddevSampleLabelDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_LABEL_DESC',
  LinkedinMessagesByCampaignMemberIdSumCampaignMemberIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinMessagesByCampaignMemberIdSumCampaignMemberIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinMessagesByCampaignMemberIdSumContentAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CONTENT_ASC',
  LinkedinMessagesByCampaignMemberIdSumContentDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CONTENT_DESC',
  LinkedinMessagesByCampaignMemberIdSumCreatedTimeAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_ASC',
  LinkedinMessagesByCampaignMemberIdSumCreatedTimeDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_DESC',
  LinkedinMessagesByCampaignMemberIdSumDateAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_DATE_ASC',
  LinkedinMessagesByCampaignMemberIdSumDateDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_DATE_DESC',
  LinkedinMessagesByCampaignMemberIdSumIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_ID_ASC',
  LinkedinMessagesByCampaignMemberIdSumIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_ID_DESC',
  LinkedinMessagesByCampaignMemberIdSumIsLawyerAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_IS_LAWYER_ASC',
  LinkedinMessagesByCampaignMemberIdSumIsLawyerDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_IS_LAWYER_DESC',
  LinkedinMessagesByCampaignMemberIdSumLabelAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_LABEL_ASC',
  LinkedinMessagesByCampaignMemberIdSumLabelDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_LABEL_DESC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationContentAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTENT_ASC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationContentDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTENT_DESC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationCreatedTimeAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationCreatedTimeDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationDateAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_DATE_ASC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationDateDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_DATE_DESC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_ASC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_DESC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationIsLawyerAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationIsLawyerDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationLabelAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_LABEL_ASC',
  LinkedinMessagesByCampaignMemberIdVariancePopulationLabelDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_LABEL_DESC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleContentAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleContentDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleCreatedTimeAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleCreatedTimeDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleDateAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_DATE_ASC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleDateDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_DATE_DESC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleIdAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleIdDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleIsLawyerAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleIsLawyerDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleLabelAsc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_LABEL_ASC',
  LinkedinMessagesByCampaignMemberIdVarianceSampleLabelDesc = 'LINKEDIN_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_LABEL_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdAverageUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdCountAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdCountDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdDistinctCountUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMaxUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdMinUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdMinUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevPopulationUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdStddevSampleUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdSumUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdSumUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVariancePopulationUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignMemberIdVarianceSampleUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  Natural = 'NATURAL',
  NatSequenceCreatedTimeAsc = 'NAT_SEQUENCE_CREATED_TIME_ASC',
  NatSequenceCreatedTimeDesc = 'NAT_SEQUENCE_CREATED_TIME_DESC',
  NextActionTimeAsc = 'NEXT_ACTION_TIME_ASC',
  NextActionTimeDesc = 'NEXT_ACTION_TIME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SqsBlockadesByCampaignMemberIdAverageCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByCampaignMemberIdAverageCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByCampaignMemberIdAverageCreatedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdAverageCreatedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdAverageDelayedUntilAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_DELAYED_UNTIL_ASC',
  SqsBlockadesByCampaignMemberIdAverageDelayedUntilDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_DELAYED_UNTIL_DESC',
  SqsBlockadesByCampaignMemberIdAverageDispatchedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_DISPATCHED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdAverageDispatchedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_DISPATCHED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdAverageEventAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_EVENT_ASC',
  SqsBlockadesByCampaignMemberIdAverageEventDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_EVENT_DESC',
  SqsBlockadesByCampaignMemberIdAverageIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_ASC',
  SqsBlockadesByCampaignMemberIdAverageIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_DESC',
  SqsBlockadesByCampaignMemberIdAverageLawyerIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_LAWYER_ID_ASC',
  SqsBlockadesByCampaignMemberIdAverageLawyerIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_LAWYER_ID_DESC',
  SqsBlockadesByCampaignMemberIdAverageMessageGroupIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByCampaignMemberIdAverageMessageGroupIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByCampaignMemberIdAverageTypeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_TYPE_ASC',
  SqsBlockadesByCampaignMemberIdAverageTypeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_TYPE_DESC',
  SqsBlockadesByCampaignMemberIdCountAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  SqsBlockadesByCampaignMemberIdCountDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  SqsBlockadesByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByCampaignMemberIdDistinctCountCreatedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdDistinctCountCreatedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdDistinctCountDelayedUntilAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_DELAYED_UNTIL_ASC',
  SqsBlockadesByCampaignMemberIdDistinctCountDelayedUntilDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_DELAYED_UNTIL_DESC',
  SqsBlockadesByCampaignMemberIdDistinctCountDispatchedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_DISPATCHED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdDistinctCountDispatchedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_DISPATCHED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdDistinctCountEventAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_EVENT_ASC',
  SqsBlockadesByCampaignMemberIdDistinctCountEventDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_EVENT_DESC',
  SqsBlockadesByCampaignMemberIdDistinctCountIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_ASC',
  SqsBlockadesByCampaignMemberIdDistinctCountIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_DESC',
  SqsBlockadesByCampaignMemberIdDistinctCountLawyerIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  SqsBlockadesByCampaignMemberIdDistinctCountLawyerIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  SqsBlockadesByCampaignMemberIdDistinctCountMessageGroupIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByCampaignMemberIdDistinctCountMessageGroupIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByCampaignMemberIdDistinctCountTypeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_TYPE_ASC',
  SqsBlockadesByCampaignMemberIdDistinctCountTypeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_TYPE_DESC',
  SqsBlockadesByCampaignMemberIdMaxCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByCampaignMemberIdMaxCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByCampaignMemberIdMaxCreatedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdMaxCreatedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdMaxDelayedUntilAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_DELAYED_UNTIL_ASC',
  SqsBlockadesByCampaignMemberIdMaxDelayedUntilDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_DELAYED_UNTIL_DESC',
  SqsBlockadesByCampaignMemberIdMaxDispatchedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_DISPATCHED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdMaxDispatchedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_DISPATCHED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdMaxEventAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_EVENT_ASC',
  SqsBlockadesByCampaignMemberIdMaxEventDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_EVENT_DESC',
  SqsBlockadesByCampaignMemberIdMaxIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_ID_ASC',
  SqsBlockadesByCampaignMemberIdMaxIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_ID_DESC',
  SqsBlockadesByCampaignMemberIdMaxLawyerIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_LAWYER_ID_ASC',
  SqsBlockadesByCampaignMemberIdMaxLawyerIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_LAWYER_ID_DESC',
  SqsBlockadesByCampaignMemberIdMaxMessageGroupIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByCampaignMemberIdMaxMessageGroupIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByCampaignMemberIdMaxTypeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_TYPE_ASC',
  SqsBlockadesByCampaignMemberIdMaxTypeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MAX_TYPE_DESC',
  SqsBlockadesByCampaignMemberIdMinCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByCampaignMemberIdMinCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByCampaignMemberIdMinCreatedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdMinCreatedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdMinDelayedUntilAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_DELAYED_UNTIL_ASC',
  SqsBlockadesByCampaignMemberIdMinDelayedUntilDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_DELAYED_UNTIL_DESC',
  SqsBlockadesByCampaignMemberIdMinDispatchedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_DISPATCHED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdMinDispatchedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_DISPATCHED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdMinEventAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_EVENT_ASC',
  SqsBlockadesByCampaignMemberIdMinEventDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_EVENT_DESC',
  SqsBlockadesByCampaignMemberIdMinIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_ID_ASC',
  SqsBlockadesByCampaignMemberIdMinIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_ID_DESC',
  SqsBlockadesByCampaignMemberIdMinLawyerIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_LAWYER_ID_ASC',
  SqsBlockadesByCampaignMemberIdMinLawyerIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_LAWYER_ID_DESC',
  SqsBlockadesByCampaignMemberIdMinMessageGroupIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByCampaignMemberIdMinMessageGroupIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByCampaignMemberIdMinTypeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_TYPE_ASC',
  SqsBlockadesByCampaignMemberIdMinTypeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_MIN_TYPE_DESC',
  SqsBlockadesByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByCampaignMemberIdStddevPopulationCreatedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdStddevPopulationCreatedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdStddevPopulationDelayedUntilAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_DELAYED_UNTIL_ASC',
  SqsBlockadesByCampaignMemberIdStddevPopulationDelayedUntilDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_DELAYED_UNTIL_DESC',
  SqsBlockadesByCampaignMemberIdStddevPopulationDispatchedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_DISPATCHED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdStddevPopulationDispatchedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_DISPATCHED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdStddevPopulationEventAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_EVENT_ASC',
  SqsBlockadesByCampaignMemberIdStddevPopulationEventDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_EVENT_DESC',
  SqsBlockadesByCampaignMemberIdStddevPopulationIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_ASC',
  SqsBlockadesByCampaignMemberIdStddevPopulationIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_DESC',
  SqsBlockadesByCampaignMemberIdStddevPopulationLawyerIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  SqsBlockadesByCampaignMemberIdStddevPopulationLawyerIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  SqsBlockadesByCampaignMemberIdStddevPopulationMessageGroupIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByCampaignMemberIdStddevPopulationMessageGroupIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByCampaignMemberIdStddevPopulationTypeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_TYPE_ASC',
  SqsBlockadesByCampaignMemberIdStddevPopulationTypeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_TYPE_DESC',
  SqsBlockadesByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByCampaignMemberIdStddevSampleCreatedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdStddevSampleCreatedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdStddevSampleDelayedUntilAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_DELAYED_UNTIL_ASC',
  SqsBlockadesByCampaignMemberIdStddevSampleDelayedUntilDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_DELAYED_UNTIL_DESC',
  SqsBlockadesByCampaignMemberIdStddevSampleDispatchedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_DISPATCHED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdStddevSampleDispatchedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_DISPATCHED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdStddevSampleEventAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_EVENT_ASC',
  SqsBlockadesByCampaignMemberIdStddevSampleEventDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_EVENT_DESC',
  SqsBlockadesByCampaignMemberIdStddevSampleIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_ASC',
  SqsBlockadesByCampaignMemberIdStddevSampleIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_DESC',
  SqsBlockadesByCampaignMemberIdStddevSampleLawyerIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  SqsBlockadesByCampaignMemberIdStddevSampleLawyerIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  SqsBlockadesByCampaignMemberIdStddevSampleMessageGroupIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByCampaignMemberIdStddevSampleMessageGroupIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByCampaignMemberIdStddevSampleTypeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_TYPE_ASC',
  SqsBlockadesByCampaignMemberIdStddevSampleTypeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_TYPE_DESC',
  SqsBlockadesByCampaignMemberIdSumCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByCampaignMemberIdSumCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByCampaignMemberIdSumCreatedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdSumCreatedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdSumDelayedUntilAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_DELAYED_UNTIL_ASC',
  SqsBlockadesByCampaignMemberIdSumDelayedUntilDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_DELAYED_UNTIL_DESC',
  SqsBlockadesByCampaignMemberIdSumDispatchedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_DISPATCHED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdSumDispatchedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_DISPATCHED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdSumEventAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_EVENT_ASC',
  SqsBlockadesByCampaignMemberIdSumEventDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_EVENT_DESC',
  SqsBlockadesByCampaignMemberIdSumIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_ID_ASC',
  SqsBlockadesByCampaignMemberIdSumIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_ID_DESC',
  SqsBlockadesByCampaignMemberIdSumLawyerIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_LAWYER_ID_ASC',
  SqsBlockadesByCampaignMemberIdSumLawyerIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_LAWYER_ID_DESC',
  SqsBlockadesByCampaignMemberIdSumMessageGroupIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByCampaignMemberIdSumMessageGroupIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByCampaignMemberIdSumTypeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_TYPE_ASC',
  SqsBlockadesByCampaignMemberIdSumTypeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_SUM_TYPE_DESC',
  SqsBlockadesByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByCampaignMemberIdVariancePopulationCreatedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdVariancePopulationCreatedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdVariancePopulationDelayedUntilAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_DELAYED_UNTIL_ASC',
  SqsBlockadesByCampaignMemberIdVariancePopulationDelayedUntilDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_DELAYED_UNTIL_DESC',
  SqsBlockadesByCampaignMemberIdVariancePopulationDispatchedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_DISPATCHED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdVariancePopulationDispatchedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_DISPATCHED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdVariancePopulationEventAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_EVENT_ASC',
  SqsBlockadesByCampaignMemberIdVariancePopulationEventDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_EVENT_DESC',
  SqsBlockadesByCampaignMemberIdVariancePopulationIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_ASC',
  SqsBlockadesByCampaignMemberIdVariancePopulationIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_DESC',
  SqsBlockadesByCampaignMemberIdVariancePopulationLawyerIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  SqsBlockadesByCampaignMemberIdVariancePopulationLawyerIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  SqsBlockadesByCampaignMemberIdVariancePopulationMessageGroupIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByCampaignMemberIdVariancePopulationMessageGroupIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByCampaignMemberIdVariancePopulationTypeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_TYPE_ASC',
  SqsBlockadesByCampaignMemberIdVariancePopulationTypeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_TYPE_DESC',
  SqsBlockadesByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByCampaignMemberIdVarianceSampleCreatedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdVarianceSampleCreatedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdVarianceSampleDelayedUntilAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_DELAYED_UNTIL_ASC',
  SqsBlockadesByCampaignMemberIdVarianceSampleDelayedUntilDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_DELAYED_UNTIL_DESC',
  SqsBlockadesByCampaignMemberIdVarianceSampleDispatchedTimeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_DISPATCHED_TIME_ASC',
  SqsBlockadesByCampaignMemberIdVarianceSampleDispatchedTimeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_DISPATCHED_TIME_DESC',
  SqsBlockadesByCampaignMemberIdVarianceSampleEventAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_EVENT_ASC',
  SqsBlockadesByCampaignMemberIdVarianceSampleEventDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_EVENT_DESC',
  SqsBlockadesByCampaignMemberIdVarianceSampleIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC',
  SqsBlockadesByCampaignMemberIdVarianceSampleIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC',
  SqsBlockadesByCampaignMemberIdVarianceSampleLawyerIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  SqsBlockadesByCampaignMemberIdVarianceSampleLawyerIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  SqsBlockadesByCampaignMemberIdVarianceSampleMessageGroupIdAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByCampaignMemberIdVarianceSampleMessageGroupIdDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByCampaignMemberIdVarianceSampleTypeAsc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_TYPE_ASC',
  SqsBlockadesByCampaignMemberIdVarianceSampleTypeDesc = 'SQS_BLOCKADES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_TYPE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TracesByCampaignMemberIdAverageActionAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ACTION_ASC',
  TracesByCampaignMemberIdAverageActionDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ACTION_DESC',
  TracesByCampaignMemberIdAverageCampaignMemberIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  TracesByCampaignMemberIdAverageCampaignMemberIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  TracesByCampaignMemberIdAverageCreatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_ASC',
  TracesByCampaignMemberIdAverageCreatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_CREATED_TIME_DESC',
  TracesByCampaignMemberIdAverageEmailMessageIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  TracesByCampaignMemberIdAverageEmailMessageIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  TracesByCampaignMemberIdAverageIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_ASC',
  TracesByCampaignMemberIdAverageIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_ID_DESC',
  TracesByCampaignMemberIdAverageProfileIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_PROFILE_ID_ASC',
  TracesByCampaignMemberIdAverageProfileIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_PROFILE_ID_DESC',
  TracesByCampaignMemberIdAverageUpdatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_ASC',
  TracesByCampaignMemberIdAverageUpdatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_AVERAGE_UPDATED_TIME_DESC',
  TracesByCampaignMemberIdCountAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_COUNT_ASC',
  TracesByCampaignMemberIdCountDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_COUNT_DESC',
  TracesByCampaignMemberIdDistinctCountActionAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ACTION_ASC',
  TracesByCampaignMemberIdDistinctCountActionDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ACTION_DESC',
  TracesByCampaignMemberIdDistinctCountCampaignMemberIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  TracesByCampaignMemberIdDistinctCountCampaignMemberIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  TracesByCampaignMemberIdDistinctCountCreatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  TracesByCampaignMemberIdDistinctCountCreatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  TracesByCampaignMemberIdDistinctCountEmailMessageIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  TracesByCampaignMemberIdDistinctCountEmailMessageIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  TracesByCampaignMemberIdDistinctCountIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_ASC',
  TracesByCampaignMemberIdDistinctCountIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_ID_DESC',
  TracesByCampaignMemberIdDistinctCountProfileIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_PROFILE_ID_ASC',
  TracesByCampaignMemberIdDistinctCountProfileIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_PROFILE_ID_DESC',
  TracesByCampaignMemberIdDistinctCountUpdatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  TracesByCampaignMemberIdDistinctCountUpdatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  TracesByCampaignMemberIdMaxActionAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_ACTION_ASC',
  TracesByCampaignMemberIdMaxActionDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_ACTION_DESC',
  TracesByCampaignMemberIdMaxCampaignMemberIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  TracesByCampaignMemberIdMaxCampaignMemberIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  TracesByCampaignMemberIdMaxCreatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_ASC',
  TracesByCampaignMemberIdMaxCreatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_CREATED_TIME_DESC',
  TracesByCampaignMemberIdMaxEmailMessageIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_EMAIL_MESSAGE_ID_ASC',
  TracesByCampaignMemberIdMaxEmailMessageIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_EMAIL_MESSAGE_ID_DESC',
  TracesByCampaignMemberIdMaxIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_ID_ASC',
  TracesByCampaignMemberIdMaxIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_ID_DESC',
  TracesByCampaignMemberIdMaxProfileIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_PROFILE_ID_ASC',
  TracesByCampaignMemberIdMaxProfileIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_PROFILE_ID_DESC',
  TracesByCampaignMemberIdMaxUpdatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_ASC',
  TracesByCampaignMemberIdMaxUpdatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MAX_UPDATED_TIME_DESC',
  TracesByCampaignMemberIdMinActionAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_ACTION_ASC',
  TracesByCampaignMemberIdMinActionDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_ACTION_DESC',
  TracesByCampaignMemberIdMinCampaignMemberIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  TracesByCampaignMemberIdMinCampaignMemberIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  TracesByCampaignMemberIdMinCreatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_ASC',
  TracesByCampaignMemberIdMinCreatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_CREATED_TIME_DESC',
  TracesByCampaignMemberIdMinEmailMessageIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_EMAIL_MESSAGE_ID_ASC',
  TracesByCampaignMemberIdMinEmailMessageIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_EMAIL_MESSAGE_ID_DESC',
  TracesByCampaignMemberIdMinIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_ID_ASC',
  TracesByCampaignMemberIdMinIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_ID_DESC',
  TracesByCampaignMemberIdMinProfileIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_PROFILE_ID_ASC',
  TracesByCampaignMemberIdMinProfileIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_PROFILE_ID_DESC',
  TracesByCampaignMemberIdMinUpdatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_ASC',
  TracesByCampaignMemberIdMinUpdatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_MIN_UPDATED_TIME_DESC',
  TracesByCampaignMemberIdStddevPopulationActionAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ACTION_ASC',
  TracesByCampaignMemberIdStddevPopulationActionDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ACTION_DESC',
  TracesByCampaignMemberIdStddevPopulationCampaignMemberIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  TracesByCampaignMemberIdStddevPopulationCampaignMemberIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  TracesByCampaignMemberIdStddevPopulationCreatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  TracesByCampaignMemberIdStddevPopulationCreatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  TracesByCampaignMemberIdStddevPopulationEmailMessageIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  TracesByCampaignMemberIdStddevPopulationEmailMessageIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  TracesByCampaignMemberIdStddevPopulationIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_ASC',
  TracesByCampaignMemberIdStddevPopulationIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_ID_DESC',
  TracesByCampaignMemberIdStddevPopulationProfileIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_PROFILE_ID_ASC',
  TracesByCampaignMemberIdStddevPopulationProfileIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_PROFILE_ID_DESC',
  TracesByCampaignMemberIdStddevPopulationUpdatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  TracesByCampaignMemberIdStddevPopulationUpdatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  TracesByCampaignMemberIdStddevSampleActionAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ACTION_ASC',
  TracesByCampaignMemberIdStddevSampleActionDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ACTION_DESC',
  TracesByCampaignMemberIdStddevSampleCampaignMemberIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  TracesByCampaignMemberIdStddevSampleCampaignMemberIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  TracesByCampaignMemberIdStddevSampleCreatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  TracesByCampaignMemberIdStddevSampleCreatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  TracesByCampaignMemberIdStddevSampleEmailMessageIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  TracesByCampaignMemberIdStddevSampleEmailMessageIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  TracesByCampaignMemberIdStddevSampleIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_ASC',
  TracesByCampaignMemberIdStddevSampleIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_ID_DESC',
  TracesByCampaignMemberIdStddevSampleProfileIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_PROFILE_ID_ASC',
  TracesByCampaignMemberIdStddevSampleProfileIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_PROFILE_ID_DESC',
  TracesByCampaignMemberIdStddevSampleUpdatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  TracesByCampaignMemberIdStddevSampleUpdatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  TracesByCampaignMemberIdSumActionAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_ACTION_ASC',
  TracesByCampaignMemberIdSumActionDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_ACTION_DESC',
  TracesByCampaignMemberIdSumCampaignMemberIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  TracesByCampaignMemberIdSumCampaignMemberIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  TracesByCampaignMemberIdSumCreatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_ASC',
  TracesByCampaignMemberIdSumCreatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_CREATED_TIME_DESC',
  TracesByCampaignMemberIdSumEmailMessageIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_EMAIL_MESSAGE_ID_ASC',
  TracesByCampaignMemberIdSumEmailMessageIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_EMAIL_MESSAGE_ID_DESC',
  TracesByCampaignMemberIdSumIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_ID_ASC',
  TracesByCampaignMemberIdSumIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_ID_DESC',
  TracesByCampaignMemberIdSumProfileIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_PROFILE_ID_ASC',
  TracesByCampaignMemberIdSumProfileIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_PROFILE_ID_DESC',
  TracesByCampaignMemberIdSumUpdatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_ASC',
  TracesByCampaignMemberIdSumUpdatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_SUM_UPDATED_TIME_DESC',
  TracesByCampaignMemberIdVariancePopulationActionAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ACTION_ASC',
  TracesByCampaignMemberIdVariancePopulationActionDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ACTION_DESC',
  TracesByCampaignMemberIdVariancePopulationCampaignMemberIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  TracesByCampaignMemberIdVariancePopulationCampaignMemberIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  TracesByCampaignMemberIdVariancePopulationCreatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  TracesByCampaignMemberIdVariancePopulationCreatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  TracesByCampaignMemberIdVariancePopulationEmailMessageIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  TracesByCampaignMemberIdVariancePopulationEmailMessageIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  TracesByCampaignMemberIdVariancePopulationIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_ASC',
  TracesByCampaignMemberIdVariancePopulationIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_ID_DESC',
  TracesByCampaignMemberIdVariancePopulationProfileIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_PROFILE_ID_ASC',
  TracesByCampaignMemberIdVariancePopulationProfileIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_PROFILE_ID_DESC',
  TracesByCampaignMemberIdVariancePopulationUpdatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  TracesByCampaignMemberIdVariancePopulationUpdatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  TracesByCampaignMemberIdVarianceSampleActionAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ACTION_ASC',
  TracesByCampaignMemberIdVarianceSampleActionDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ACTION_DESC',
  TracesByCampaignMemberIdVarianceSampleCampaignMemberIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  TracesByCampaignMemberIdVarianceSampleCampaignMemberIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  TracesByCampaignMemberIdVarianceSampleCreatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  TracesByCampaignMemberIdVarianceSampleCreatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  TracesByCampaignMemberIdVarianceSampleEmailMessageIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  TracesByCampaignMemberIdVarianceSampleEmailMessageIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  TracesByCampaignMemberIdVarianceSampleIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC',
  TracesByCampaignMemberIdVarianceSampleIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC',
  TracesByCampaignMemberIdVarianceSampleProfileIdAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  TracesByCampaignMemberIdVarianceSampleProfileIdDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_PROFILE_ID_DESC',
  TracesByCampaignMemberIdVarianceSampleUpdatedTimeAsc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  TracesByCampaignMemberIdVarianceSampleUpdatedTimeDesc = 'TRACES_BY_CAMPAIGN_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC',
  VisibilityAsc = 'VISIBILITY_ASC',
  VisibilityDesc = 'VISIBILITY_DESC'
}

export type CampaignMinAggregates = {
  __typename?: 'CampaignMinAggregates';
  /** Minimum of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Minimum of maxAllocation across the matching connection */
  maxAllocation?: Maybe<Scalars['Int']['output']>;
  /** Minimum of searchAfterScore across the matching connection */
  searchAfterScore?: Maybe<Scalars['Float']['output']>;
  /** Minimum of vectorMatchThreshold across the matching connection */
  vectorMatchThreshold?: Maybe<Scalars['Float']['output']>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type CampaignSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: CampaignSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type CampaignSortBy = {
  field?: InputMaybe<CampaignSortableField>;
};

/** Sortable concrete fields for the `Campaign` type. */
export enum CampaignSortableField {
  ActivatedTime = 'ACTIVATED_TIME',
  CampaignTargetId = 'CAMPAIGN_TARGET_ID',
  Channel = 'CHANNEL',
  ConversionScore = 'CONVERSION_SCORE',
  CreatedTime = 'CREATED_TIME',
  DeactivatedTime = 'DEACTIVATED_TIME',
  Deleted = 'DELETED',
  DraftedByLawyer = 'DRAFTED_BY_LAWYER',
  Id = 'ID',
  IsPersonalised = 'IS_PERSONALISED',
  IsSalesFunnelCampaign = 'IS_SALES_FUNNEL_CAMPAIGN',
  IsTestMode = 'IS_TEST_MODE',
  LawyerId = 'LAWYER_ID',
  LawyerViewedTime = 'LAWYER_VIEWED_TIME',
  MaxAllocation = 'MAX_ALLOCATION',
  Name = 'NAME',
  Notes = 'NOTES',
  PatentQuery = 'PATENT_QUERY',
  PersonalisationQuery = 'PERSONALISATION_QUERY',
  Priority = 'PRIORITY',
  ReviewedBy = 'REVIEWED_BY',
  ReviewedTime = 'REVIEWED_TIME',
  ScheduledActivationTime = 'SCHEDULED_ACTIVATION_TIME',
  SearchAfter = 'SEARCH_AFTER',
  SearchAfterScore = 'SEARCH_AFTER_SCORE',
  SearchBefore = 'SEARCH_BEFORE',
  SearchStarted = 'SEARCH_STARTED',
  Status = 'STATUS',
  Subject = 'SUBJECT',
  SuggestedTargetingId = 'SUGGESTED_TARGETING_ID',
  TargetingRequest = 'TARGETING_REQUEST',
  TargetDescription = 'TARGET_DESCRIPTION',
  UpdatedTime = 'UPDATED_TIME',
  UseLlmFilter = 'USE_LLM_FILTER',
  VectorMatchThreshold = 'VECTOR_MATCH_THRESHOLD'
}

export enum CampaignStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Revised = 'REVISED'
}

/** A filter to be used against CampaignStatus fields. All fields are combined with a logical ‘and.’ */
export type CampaignStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<CampaignStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<CampaignStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<CampaignStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<CampaignStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<CampaignStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<CampaignStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<CampaignStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<CampaignStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<CampaignStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<CampaignStatus>>;
};

export type CampaignStddevPopulationAggregates = {
  __typename?: 'CampaignStddevPopulationAggregates';
  /** Population standard deviation of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of maxAllocation across the matching connection */
  maxAllocation?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of searchAfterScore across the matching connection */
  searchAfterScore?: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of vectorMatchThreshold across the matching connection */
  vectorMatchThreshold?: Maybe<Scalars['Float']['output']>;
};

export type CampaignStddevSampleAggregates = {
  __typename?: 'CampaignStddevSampleAggregates';
  /** Sample standard deviation of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of maxAllocation across the matching connection */
  maxAllocation?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of searchAfterScore across the matching connection */
  searchAfterScore?: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of vectorMatchThreshold across the matching connection */
  vectorMatchThreshold?: Maybe<Scalars['Float']['output']>;
};

export type CampaignSumAggregates = {
  __typename?: 'CampaignSumAggregates';
  /** Sum of conversionScore across the matching connection */
  conversionScore: Scalars['Float']['output'];
  /** Sum of maxAllocation across the matching connection */
  maxAllocation: Scalars['BigInt']['output'];
  /** Sum of searchAfterScore across the matching connection */
  searchAfterScore: Scalars['Float']['output'];
  /** Sum of vectorMatchThreshold across the matching connection */
  vectorMatchThreshold: Scalars['Float']['output'];
};

export type CampaignVariancePopulationAggregates = {
  __typename?: 'CampaignVariancePopulationAggregates';
  /** Population variance of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Population variance of maxAllocation across the matching connection */
  maxAllocation?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of searchAfterScore across the matching connection */
  searchAfterScore?: Maybe<Scalars['Float']['output']>;
  /** Population variance of vectorMatchThreshold across the matching connection */
  vectorMatchThreshold?: Maybe<Scalars['Float']['output']>;
};

export type CampaignVarianceSampleAggregates = {
  __typename?: 'CampaignVarianceSampleAggregates';
  /** Sample variance of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Sample variance of maxAllocation across the matching connection */
  maxAllocation?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of searchAfterScore across the matching connection */
  searchAfterScore?: Maybe<Scalars['Float']['output']>;
  /** Sample variance of vectorMatchThreshold across the matching connection */
  vectorMatchThreshold?: Maybe<Scalars['Float']['output']>;
};

export enum Campaignmembermeetingstatus {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Held = 'HELD',
  Scheduled = 'SCHEDULED'
}

/** A filter to be used against Campaignmembermeetingstatus fields. All fields are combined with a logical ‘and.’ */
export type CampaignmembermeetingstatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Campaignmembermeetingstatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Campaignmembermeetingstatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Campaignmembermeetingstatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Campaignmembermeetingstatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Campaignmembermeetingstatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Campaignmembermeetingstatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Campaignmembermeetingstatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Campaignmembermeetingstatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Campaignmembermeetingstatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Campaignmembermeetingstatus>>;
};

/** A connection to a list of `Campaign` values. */
export type CampaignsConnection = {
  __typename?: 'CampaignsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CampaignAggregates>;
  /** A list of edges which contains the `Campaign` and cursor to aid in pagination. */
  edges: Array<CampaignsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CampaignAggregates>>;
  /** A list of `Campaign` objects. */
  nodes: Array<Maybe<Campaign>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Campaign` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Campaign` values. */
export type CampaignsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CampaignGroupBy>;
  having?: InputMaybe<CampaignHavingInput>;
};

/** A `Campaign` edge in the connection. */
export type CampaignsEdge = {
  __typename?: 'CampaignsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Campaign` at the end of the edge. */
  node?: Maybe<Campaign>;
};

/** Methods to use when ordering `Campaign`. */
export enum CampaignsOrderBy {
  ActivatedTimeAsc = 'ACTIVATED_TIME_ASC',
  ActivatedTimeDesc = 'ACTIVATED_TIME_DESC',
  ApolloQueriesByCampaignIdAverageApolloUrlAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_APOLLO_URL_ASC',
  ApolloQueriesByCampaignIdAverageApolloUrlDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_APOLLO_URL_DESC',
  ApolloQueriesByCampaignIdAverageCampaignIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_ASC',
  ApolloQueriesByCampaignIdAverageCampaignIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_DESC',
  ApolloQueriesByCampaignIdAverageCreatedTimeAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_ASC',
  ApolloQueriesByCampaignIdAverageCreatedTimeDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_DESC',
  ApolloQueriesByCampaignIdAverageIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_ID_ASC',
  ApolloQueriesByCampaignIdAverageIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_ID_DESC',
  ApolloQueriesByCampaignIdAverageJobRoleAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_JOB_ROLE_ASC',
  ApolloQueriesByCampaignIdAverageJobRoleDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_JOB_ROLE_DESC',
  ApolloQueriesByCampaignIdAverageKeywordAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_KEYWORD_ASC',
  ApolloQueriesByCampaignIdAverageKeywordDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_KEYWORD_DESC',
  ApolloQueriesByCampaignIdAverageLastPageSearchedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_LAST_PAGE_SEARCHED_ASC',
  ApolloQueriesByCampaignIdAverageLastPageSearchedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_LAST_PAGE_SEARCHED_DESC',
  ApolloQueriesByCampaignIdAverageNumContactsEnrichedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_NUM_CONTACTS_ENRICHED_ASC',
  ApolloQueriesByCampaignIdAverageNumContactsEnrichedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_NUM_CONTACTS_ENRICHED_DESC',
  ApolloQueriesByCampaignIdAverageNumContactsFoundAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_NUM_CONTACTS_FOUND_ASC',
  ApolloQueriesByCampaignIdAverageNumContactsFoundDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_NUM_CONTACTS_FOUND_DESC',
  ApolloQueriesByCampaignIdAverageNumContactsMatchCampaignAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_NUM_CONTACTS_MATCH_CAMPAIGN_ASC',
  ApolloQueriesByCampaignIdAverageNumContactsMatchCampaignDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_NUM_CONTACTS_MATCH_CAMPAIGN_DESC',
  ApolloQueriesByCampaignIdAverageNumContactsSavedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_NUM_CONTACTS_SAVED_ASC',
  ApolloQueriesByCampaignIdAverageNumContactsSavedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_NUM_CONTACTS_SAVED_DESC',
  ApolloQueriesByCampaignIdAverageSearchParamsAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_SEARCH_PARAMS_ASC',
  ApolloQueriesByCampaignIdAverageSearchParamsDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_SEARCH_PARAMS_DESC',
  ApolloQueriesByCampaignIdAverageTotalPagesAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_TOTAL_PAGES_ASC',
  ApolloQueriesByCampaignIdAverageTotalPagesDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_AVERAGE_TOTAL_PAGES_DESC',
  ApolloQueriesByCampaignIdCountAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_COUNT_ASC',
  ApolloQueriesByCampaignIdCountDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_COUNT_DESC',
  ApolloQueriesByCampaignIdDistinctCountApolloUrlAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_APOLLO_URL_ASC',
  ApolloQueriesByCampaignIdDistinctCountApolloUrlDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_APOLLO_URL_DESC',
  ApolloQueriesByCampaignIdDistinctCountCampaignIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  ApolloQueriesByCampaignIdDistinctCountCampaignIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  ApolloQueriesByCampaignIdDistinctCountCreatedTimeAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  ApolloQueriesByCampaignIdDistinctCountCreatedTimeDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  ApolloQueriesByCampaignIdDistinctCountIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_ASC',
  ApolloQueriesByCampaignIdDistinctCountIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_DESC',
  ApolloQueriesByCampaignIdDistinctCountJobRoleAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_JOB_ROLE_ASC',
  ApolloQueriesByCampaignIdDistinctCountJobRoleDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_JOB_ROLE_DESC',
  ApolloQueriesByCampaignIdDistinctCountKeywordAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_KEYWORD_ASC',
  ApolloQueriesByCampaignIdDistinctCountKeywordDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_KEYWORD_DESC',
  ApolloQueriesByCampaignIdDistinctCountLastPageSearchedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAST_PAGE_SEARCHED_ASC',
  ApolloQueriesByCampaignIdDistinctCountLastPageSearchedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAST_PAGE_SEARCHED_DESC',
  ApolloQueriesByCampaignIdDistinctCountNumContactsEnrichedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_NUM_CONTACTS_ENRICHED_ASC',
  ApolloQueriesByCampaignIdDistinctCountNumContactsEnrichedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_NUM_CONTACTS_ENRICHED_DESC',
  ApolloQueriesByCampaignIdDistinctCountNumContactsFoundAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_NUM_CONTACTS_FOUND_ASC',
  ApolloQueriesByCampaignIdDistinctCountNumContactsFoundDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_NUM_CONTACTS_FOUND_DESC',
  ApolloQueriesByCampaignIdDistinctCountNumContactsMatchCampaignAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_NUM_CONTACTS_MATCH_CAMPAIGN_ASC',
  ApolloQueriesByCampaignIdDistinctCountNumContactsMatchCampaignDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_NUM_CONTACTS_MATCH_CAMPAIGN_DESC',
  ApolloQueriesByCampaignIdDistinctCountNumContactsSavedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_NUM_CONTACTS_SAVED_ASC',
  ApolloQueriesByCampaignIdDistinctCountNumContactsSavedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_NUM_CONTACTS_SAVED_DESC',
  ApolloQueriesByCampaignIdDistinctCountSearchParamsAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_PARAMS_ASC',
  ApolloQueriesByCampaignIdDistinctCountSearchParamsDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_PARAMS_DESC',
  ApolloQueriesByCampaignIdDistinctCountTotalPagesAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_TOTAL_PAGES_ASC',
  ApolloQueriesByCampaignIdDistinctCountTotalPagesDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_DISTINCT_COUNT_TOTAL_PAGES_DESC',
  ApolloQueriesByCampaignIdMaxApolloUrlAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_APOLLO_URL_ASC',
  ApolloQueriesByCampaignIdMaxApolloUrlDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_APOLLO_URL_DESC',
  ApolloQueriesByCampaignIdMaxCampaignIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_ASC',
  ApolloQueriesByCampaignIdMaxCampaignIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_DESC',
  ApolloQueriesByCampaignIdMaxCreatedTimeAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_CREATED_TIME_ASC',
  ApolloQueriesByCampaignIdMaxCreatedTimeDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_CREATED_TIME_DESC',
  ApolloQueriesByCampaignIdMaxIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_ID_ASC',
  ApolloQueriesByCampaignIdMaxIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_ID_DESC',
  ApolloQueriesByCampaignIdMaxJobRoleAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_JOB_ROLE_ASC',
  ApolloQueriesByCampaignIdMaxJobRoleDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_JOB_ROLE_DESC',
  ApolloQueriesByCampaignIdMaxKeywordAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_KEYWORD_ASC',
  ApolloQueriesByCampaignIdMaxKeywordDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_KEYWORD_DESC',
  ApolloQueriesByCampaignIdMaxLastPageSearchedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_LAST_PAGE_SEARCHED_ASC',
  ApolloQueriesByCampaignIdMaxLastPageSearchedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_LAST_PAGE_SEARCHED_DESC',
  ApolloQueriesByCampaignIdMaxNumContactsEnrichedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_NUM_CONTACTS_ENRICHED_ASC',
  ApolloQueriesByCampaignIdMaxNumContactsEnrichedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_NUM_CONTACTS_ENRICHED_DESC',
  ApolloQueriesByCampaignIdMaxNumContactsFoundAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_NUM_CONTACTS_FOUND_ASC',
  ApolloQueriesByCampaignIdMaxNumContactsFoundDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_NUM_CONTACTS_FOUND_DESC',
  ApolloQueriesByCampaignIdMaxNumContactsMatchCampaignAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_NUM_CONTACTS_MATCH_CAMPAIGN_ASC',
  ApolloQueriesByCampaignIdMaxNumContactsMatchCampaignDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_NUM_CONTACTS_MATCH_CAMPAIGN_DESC',
  ApolloQueriesByCampaignIdMaxNumContactsSavedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_NUM_CONTACTS_SAVED_ASC',
  ApolloQueriesByCampaignIdMaxNumContactsSavedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_NUM_CONTACTS_SAVED_DESC',
  ApolloQueriesByCampaignIdMaxSearchParamsAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_SEARCH_PARAMS_ASC',
  ApolloQueriesByCampaignIdMaxSearchParamsDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_SEARCH_PARAMS_DESC',
  ApolloQueriesByCampaignIdMaxTotalPagesAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_TOTAL_PAGES_ASC',
  ApolloQueriesByCampaignIdMaxTotalPagesDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MAX_TOTAL_PAGES_DESC',
  ApolloQueriesByCampaignIdMinApolloUrlAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_APOLLO_URL_ASC',
  ApolloQueriesByCampaignIdMinApolloUrlDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_APOLLO_URL_DESC',
  ApolloQueriesByCampaignIdMinCampaignIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_ASC',
  ApolloQueriesByCampaignIdMinCampaignIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_DESC',
  ApolloQueriesByCampaignIdMinCreatedTimeAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_CREATED_TIME_ASC',
  ApolloQueriesByCampaignIdMinCreatedTimeDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_CREATED_TIME_DESC',
  ApolloQueriesByCampaignIdMinIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_ID_ASC',
  ApolloQueriesByCampaignIdMinIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_ID_DESC',
  ApolloQueriesByCampaignIdMinJobRoleAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_JOB_ROLE_ASC',
  ApolloQueriesByCampaignIdMinJobRoleDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_JOB_ROLE_DESC',
  ApolloQueriesByCampaignIdMinKeywordAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_KEYWORD_ASC',
  ApolloQueriesByCampaignIdMinKeywordDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_KEYWORD_DESC',
  ApolloQueriesByCampaignIdMinLastPageSearchedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_LAST_PAGE_SEARCHED_ASC',
  ApolloQueriesByCampaignIdMinLastPageSearchedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_LAST_PAGE_SEARCHED_DESC',
  ApolloQueriesByCampaignIdMinNumContactsEnrichedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_NUM_CONTACTS_ENRICHED_ASC',
  ApolloQueriesByCampaignIdMinNumContactsEnrichedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_NUM_CONTACTS_ENRICHED_DESC',
  ApolloQueriesByCampaignIdMinNumContactsFoundAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_NUM_CONTACTS_FOUND_ASC',
  ApolloQueriesByCampaignIdMinNumContactsFoundDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_NUM_CONTACTS_FOUND_DESC',
  ApolloQueriesByCampaignIdMinNumContactsMatchCampaignAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_NUM_CONTACTS_MATCH_CAMPAIGN_ASC',
  ApolloQueriesByCampaignIdMinNumContactsMatchCampaignDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_NUM_CONTACTS_MATCH_CAMPAIGN_DESC',
  ApolloQueriesByCampaignIdMinNumContactsSavedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_NUM_CONTACTS_SAVED_ASC',
  ApolloQueriesByCampaignIdMinNumContactsSavedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_NUM_CONTACTS_SAVED_DESC',
  ApolloQueriesByCampaignIdMinSearchParamsAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_SEARCH_PARAMS_ASC',
  ApolloQueriesByCampaignIdMinSearchParamsDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_SEARCH_PARAMS_DESC',
  ApolloQueriesByCampaignIdMinTotalPagesAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_TOTAL_PAGES_ASC',
  ApolloQueriesByCampaignIdMinTotalPagesDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_MIN_TOTAL_PAGES_DESC',
  ApolloQueriesByCampaignIdStddevPopulationApolloUrlAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_APOLLO_URL_ASC',
  ApolloQueriesByCampaignIdStddevPopulationApolloUrlDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_APOLLO_URL_DESC',
  ApolloQueriesByCampaignIdStddevPopulationCampaignIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  ApolloQueriesByCampaignIdStddevPopulationCampaignIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  ApolloQueriesByCampaignIdStddevPopulationCreatedTimeAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  ApolloQueriesByCampaignIdStddevPopulationCreatedTimeDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  ApolloQueriesByCampaignIdStddevPopulationIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_ASC',
  ApolloQueriesByCampaignIdStddevPopulationIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_DESC',
  ApolloQueriesByCampaignIdStddevPopulationJobRoleAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_JOB_ROLE_ASC',
  ApolloQueriesByCampaignIdStddevPopulationJobRoleDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_JOB_ROLE_DESC',
  ApolloQueriesByCampaignIdStddevPopulationKeywordAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_KEYWORD_ASC',
  ApolloQueriesByCampaignIdStddevPopulationKeywordDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_KEYWORD_DESC',
  ApolloQueriesByCampaignIdStddevPopulationLastPageSearchedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAST_PAGE_SEARCHED_ASC',
  ApolloQueriesByCampaignIdStddevPopulationLastPageSearchedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAST_PAGE_SEARCHED_DESC',
  ApolloQueriesByCampaignIdStddevPopulationNumContactsEnrichedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_NUM_CONTACTS_ENRICHED_ASC',
  ApolloQueriesByCampaignIdStddevPopulationNumContactsEnrichedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_NUM_CONTACTS_ENRICHED_DESC',
  ApolloQueriesByCampaignIdStddevPopulationNumContactsFoundAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_NUM_CONTACTS_FOUND_ASC',
  ApolloQueriesByCampaignIdStddevPopulationNumContactsFoundDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_NUM_CONTACTS_FOUND_DESC',
  ApolloQueriesByCampaignIdStddevPopulationNumContactsMatchCampaignAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_NUM_CONTACTS_MATCH_CAMPAIGN_ASC',
  ApolloQueriesByCampaignIdStddevPopulationNumContactsMatchCampaignDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_NUM_CONTACTS_MATCH_CAMPAIGN_DESC',
  ApolloQueriesByCampaignIdStddevPopulationNumContactsSavedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_NUM_CONTACTS_SAVED_ASC',
  ApolloQueriesByCampaignIdStddevPopulationNumContactsSavedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_NUM_CONTACTS_SAVED_DESC',
  ApolloQueriesByCampaignIdStddevPopulationSearchParamsAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_PARAMS_ASC',
  ApolloQueriesByCampaignIdStddevPopulationSearchParamsDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_PARAMS_DESC',
  ApolloQueriesByCampaignIdStddevPopulationTotalPagesAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_TOTAL_PAGES_ASC',
  ApolloQueriesByCampaignIdStddevPopulationTotalPagesDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_POPULATION_TOTAL_PAGES_DESC',
  ApolloQueriesByCampaignIdStddevSampleApolloUrlAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_APOLLO_URL_ASC',
  ApolloQueriesByCampaignIdStddevSampleApolloUrlDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_APOLLO_URL_DESC',
  ApolloQueriesByCampaignIdStddevSampleCampaignIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  ApolloQueriesByCampaignIdStddevSampleCampaignIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  ApolloQueriesByCampaignIdStddevSampleCreatedTimeAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  ApolloQueriesByCampaignIdStddevSampleCreatedTimeDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  ApolloQueriesByCampaignIdStddevSampleIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_ASC',
  ApolloQueriesByCampaignIdStddevSampleIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_DESC',
  ApolloQueriesByCampaignIdStddevSampleJobRoleAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_JOB_ROLE_ASC',
  ApolloQueriesByCampaignIdStddevSampleJobRoleDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_JOB_ROLE_DESC',
  ApolloQueriesByCampaignIdStddevSampleKeywordAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_KEYWORD_ASC',
  ApolloQueriesByCampaignIdStddevSampleKeywordDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_KEYWORD_DESC',
  ApolloQueriesByCampaignIdStddevSampleLastPageSearchedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAST_PAGE_SEARCHED_ASC',
  ApolloQueriesByCampaignIdStddevSampleLastPageSearchedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAST_PAGE_SEARCHED_DESC',
  ApolloQueriesByCampaignIdStddevSampleNumContactsEnrichedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NUM_CONTACTS_ENRICHED_ASC',
  ApolloQueriesByCampaignIdStddevSampleNumContactsEnrichedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NUM_CONTACTS_ENRICHED_DESC',
  ApolloQueriesByCampaignIdStddevSampleNumContactsFoundAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NUM_CONTACTS_FOUND_ASC',
  ApolloQueriesByCampaignIdStddevSampleNumContactsFoundDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NUM_CONTACTS_FOUND_DESC',
  ApolloQueriesByCampaignIdStddevSampleNumContactsMatchCampaignAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NUM_CONTACTS_MATCH_CAMPAIGN_ASC',
  ApolloQueriesByCampaignIdStddevSampleNumContactsMatchCampaignDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NUM_CONTACTS_MATCH_CAMPAIGN_DESC',
  ApolloQueriesByCampaignIdStddevSampleNumContactsSavedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NUM_CONTACTS_SAVED_ASC',
  ApolloQueriesByCampaignIdStddevSampleNumContactsSavedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NUM_CONTACTS_SAVED_DESC',
  ApolloQueriesByCampaignIdStddevSampleSearchParamsAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_PARAMS_ASC',
  ApolloQueriesByCampaignIdStddevSampleSearchParamsDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_PARAMS_DESC',
  ApolloQueriesByCampaignIdStddevSampleTotalPagesAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_TOTAL_PAGES_ASC',
  ApolloQueriesByCampaignIdStddevSampleTotalPagesDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_TOTAL_PAGES_DESC',
  ApolloQueriesByCampaignIdSumApolloUrlAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_APOLLO_URL_ASC',
  ApolloQueriesByCampaignIdSumApolloUrlDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_APOLLO_URL_DESC',
  ApolloQueriesByCampaignIdSumCampaignIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_ASC',
  ApolloQueriesByCampaignIdSumCampaignIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_DESC',
  ApolloQueriesByCampaignIdSumCreatedTimeAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_CREATED_TIME_ASC',
  ApolloQueriesByCampaignIdSumCreatedTimeDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_CREATED_TIME_DESC',
  ApolloQueriesByCampaignIdSumIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_ID_ASC',
  ApolloQueriesByCampaignIdSumIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_ID_DESC',
  ApolloQueriesByCampaignIdSumJobRoleAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_JOB_ROLE_ASC',
  ApolloQueriesByCampaignIdSumJobRoleDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_JOB_ROLE_DESC',
  ApolloQueriesByCampaignIdSumKeywordAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_KEYWORD_ASC',
  ApolloQueriesByCampaignIdSumKeywordDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_KEYWORD_DESC',
  ApolloQueriesByCampaignIdSumLastPageSearchedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_LAST_PAGE_SEARCHED_ASC',
  ApolloQueriesByCampaignIdSumLastPageSearchedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_LAST_PAGE_SEARCHED_DESC',
  ApolloQueriesByCampaignIdSumNumContactsEnrichedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_NUM_CONTACTS_ENRICHED_ASC',
  ApolloQueriesByCampaignIdSumNumContactsEnrichedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_NUM_CONTACTS_ENRICHED_DESC',
  ApolloQueriesByCampaignIdSumNumContactsFoundAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_NUM_CONTACTS_FOUND_ASC',
  ApolloQueriesByCampaignIdSumNumContactsFoundDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_NUM_CONTACTS_FOUND_DESC',
  ApolloQueriesByCampaignIdSumNumContactsMatchCampaignAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_NUM_CONTACTS_MATCH_CAMPAIGN_ASC',
  ApolloQueriesByCampaignIdSumNumContactsMatchCampaignDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_NUM_CONTACTS_MATCH_CAMPAIGN_DESC',
  ApolloQueriesByCampaignIdSumNumContactsSavedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_NUM_CONTACTS_SAVED_ASC',
  ApolloQueriesByCampaignIdSumNumContactsSavedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_NUM_CONTACTS_SAVED_DESC',
  ApolloQueriesByCampaignIdSumSearchParamsAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_SEARCH_PARAMS_ASC',
  ApolloQueriesByCampaignIdSumSearchParamsDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_SEARCH_PARAMS_DESC',
  ApolloQueriesByCampaignIdSumTotalPagesAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_TOTAL_PAGES_ASC',
  ApolloQueriesByCampaignIdSumTotalPagesDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_SUM_TOTAL_PAGES_DESC',
  ApolloQueriesByCampaignIdVariancePopulationApolloUrlAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_APOLLO_URL_ASC',
  ApolloQueriesByCampaignIdVariancePopulationApolloUrlDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_APOLLO_URL_DESC',
  ApolloQueriesByCampaignIdVariancePopulationCampaignIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  ApolloQueriesByCampaignIdVariancePopulationCampaignIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  ApolloQueriesByCampaignIdVariancePopulationCreatedTimeAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  ApolloQueriesByCampaignIdVariancePopulationCreatedTimeDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  ApolloQueriesByCampaignIdVariancePopulationIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_ASC',
  ApolloQueriesByCampaignIdVariancePopulationIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_DESC',
  ApolloQueriesByCampaignIdVariancePopulationJobRoleAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_JOB_ROLE_ASC',
  ApolloQueriesByCampaignIdVariancePopulationJobRoleDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_JOB_ROLE_DESC',
  ApolloQueriesByCampaignIdVariancePopulationKeywordAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_KEYWORD_ASC',
  ApolloQueriesByCampaignIdVariancePopulationKeywordDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_KEYWORD_DESC',
  ApolloQueriesByCampaignIdVariancePopulationLastPageSearchedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAST_PAGE_SEARCHED_ASC',
  ApolloQueriesByCampaignIdVariancePopulationLastPageSearchedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAST_PAGE_SEARCHED_DESC',
  ApolloQueriesByCampaignIdVariancePopulationNumContactsEnrichedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NUM_CONTACTS_ENRICHED_ASC',
  ApolloQueriesByCampaignIdVariancePopulationNumContactsEnrichedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NUM_CONTACTS_ENRICHED_DESC',
  ApolloQueriesByCampaignIdVariancePopulationNumContactsFoundAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NUM_CONTACTS_FOUND_ASC',
  ApolloQueriesByCampaignIdVariancePopulationNumContactsFoundDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NUM_CONTACTS_FOUND_DESC',
  ApolloQueriesByCampaignIdVariancePopulationNumContactsMatchCampaignAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NUM_CONTACTS_MATCH_CAMPAIGN_ASC',
  ApolloQueriesByCampaignIdVariancePopulationNumContactsMatchCampaignDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NUM_CONTACTS_MATCH_CAMPAIGN_DESC',
  ApolloQueriesByCampaignIdVariancePopulationNumContactsSavedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NUM_CONTACTS_SAVED_ASC',
  ApolloQueriesByCampaignIdVariancePopulationNumContactsSavedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NUM_CONTACTS_SAVED_DESC',
  ApolloQueriesByCampaignIdVariancePopulationSearchParamsAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_PARAMS_ASC',
  ApolloQueriesByCampaignIdVariancePopulationSearchParamsDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_PARAMS_DESC',
  ApolloQueriesByCampaignIdVariancePopulationTotalPagesAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_TOTAL_PAGES_ASC',
  ApolloQueriesByCampaignIdVariancePopulationTotalPagesDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_TOTAL_PAGES_DESC',
  ApolloQueriesByCampaignIdVarianceSampleApolloUrlAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_APOLLO_URL_ASC',
  ApolloQueriesByCampaignIdVarianceSampleApolloUrlDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_APOLLO_URL_DESC',
  ApolloQueriesByCampaignIdVarianceSampleCampaignIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  ApolloQueriesByCampaignIdVarianceSampleCampaignIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  ApolloQueriesByCampaignIdVarianceSampleCreatedTimeAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  ApolloQueriesByCampaignIdVarianceSampleCreatedTimeDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  ApolloQueriesByCampaignIdVarianceSampleIdAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_ASC',
  ApolloQueriesByCampaignIdVarianceSampleIdDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_DESC',
  ApolloQueriesByCampaignIdVarianceSampleJobRoleAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_JOB_ROLE_ASC',
  ApolloQueriesByCampaignIdVarianceSampleJobRoleDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_JOB_ROLE_DESC',
  ApolloQueriesByCampaignIdVarianceSampleKeywordAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_KEYWORD_ASC',
  ApolloQueriesByCampaignIdVarianceSampleKeywordDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_KEYWORD_DESC',
  ApolloQueriesByCampaignIdVarianceSampleLastPageSearchedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAST_PAGE_SEARCHED_ASC',
  ApolloQueriesByCampaignIdVarianceSampleLastPageSearchedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAST_PAGE_SEARCHED_DESC',
  ApolloQueriesByCampaignIdVarianceSampleNumContactsEnrichedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NUM_CONTACTS_ENRICHED_ASC',
  ApolloQueriesByCampaignIdVarianceSampleNumContactsEnrichedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NUM_CONTACTS_ENRICHED_DESC',
  ApolloQueriesByCampaignIdVarianceSampleNumContactsFoundAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NUM_CONTACTS_FOUND_ASC',
  ApolloQueriesByCampaignIdVarianceSampleNumContactsFoundDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NUM_CONTACTS_FOUND_DESC',
  ApolloQueriesByCampaignIdVarianceSampleNumContactsMatchCampaignAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NUM_CONTACTS_MATCH_CAMPAIGN_ASC',
  ApolloQueriesByCampaignIdVarianceSampleNumContactsMatchCampaignDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NUM_CONTACTS_MATCH_CAMPAIGN_DESC',
  ApolloQueriesByCampaignIdVarianceSampleNumContactsSavedAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NUM_CONTACTS_SAVED_ASC',
  ApolloQueriesByCampaignIdVarianceSampleNumContactsSavedDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NUM_CONTACTS_SAVED_DESC',
  ApolloQueriesByCampaignIdVarianceSampleSearchParamsAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_PARAMS_ASC',
  ApolloQueriesByCampaignIdVarianceSampleSearchParamsDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_PARAMS_DESC',
  ApolloQueriesByCampaignIdVarianceSampleTotalPagesAsc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_TOTAL_PAGES_ASC',
  ApolloQueriesByCampaignIdVarianceSampleTotalPagesDesc = 'APOLLO_QUERIES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_TOTAL_PAGES_DESC',
  CampaignAuditLogsByCampaignIdAverageAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_AVERAGE_AFTER_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdAverageAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_AVERAGE_AFTER_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdAverageBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_AVERAGE_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdAverageBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_AVERAGE_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdAverageCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByCampaignIdAverageCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByCampaignIdAverageCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_ASC',
  CampaignAuditLogsByCampaignIdAverageCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_DESC',
  CampaignAuditLogsByCampaignIdAverageIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_AVERAGE_ID_ASC',
  CampaignAuditLogsByCampaignIdAverageIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_AVERAGE_ID_DESC',
  CampaignAuditLogsByCampaignIdAverageProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_AVERAGE_PROFILE_ID_ASC',
  CampaignAuditLogsByCampaignIdAverageProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_AVERAGE_PROFILE_ID_DESC',
  CampaignAuditLogsByCampaignIdCountAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_COUNT_ASC',
  CampaignAuditLogsByCampaignIdCountDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_COUNT_DESC',
  CampaignAuditLogsByCampaignIdDistinctCountAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_DISTINCT_COUNT_AFTER_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdDistinctCountAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_DISTINCT_COUNT_AFTER_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdDistinctCountBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_DISTINCT_COUNT_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdDistinctCountBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_DISTINCT_COUNT_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdDistinctCountCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByCampaignIdDistinctCountCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByCampaignIdDistinctCountCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CampaignAuditLogsByCampaignIdDistinctCountCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CampaignAuditLogsByCampaignIdDistinctCountIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_ASC',
  CampaignAuditLogsByCampaignIdDistinctCountIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_DESC',
  CampaignAuditLogsByCampaignIdDistinctCountProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_DISTINCT_COUNT_PROFILE_ID_ASC',
  CampaignAuditLogsByCampaignIdDistinctCountProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_DISTINCT_COUNT_PROFILE_ID_DESC',
  CampaignAuditLogsByCampaignIdMaxAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MAX_AFTER_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdMaxAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MAX_AFTER_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdMaxBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MAX_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdMaxBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MAX_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdMaxCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByCampaignIdMaxCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByCampaignIdMaxCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MAX_CREATED_TIME_ASC',
  CampaignAuditLogsByCampaignIdMaxCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MAX_CREATED_TIME_DESC',
  CampaignAuditLogsByCampaignIdMaxIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MAX_ID_ASC',
  CampaignAuditLogsByCampaignIdMaxIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MAX_ID_DESC',
  CampaignAuditLogsByCampaignIdMaxProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MAX_PROFILE_ID_ASC',
  CampaignAuditLogsByCampaignIdMaxProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MAX_PROFILE_ID_DESC',
  CampaignAuditLogsByCampaignIdMinAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MIN_AFTER_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdMinAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MIN_AFTER_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdMinBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MIN_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdMinBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MIN_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdMinCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByCampaignIdMinCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByCampaignIdMinCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MIN_CREATED_TIME_ASC',
  CampaignAuditLogsByCampaignIdMinCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MIN_CREATED_TIME_DESC',
  CampaignAuditLogsByCampaignIdMinIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MIN_ID_ASC',
  CampaignAuditLogsByCampaignIdMinIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MIN_ID_DESC',
  CampaignAuditLogsByCampaignIdMinProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MIN_PROFILE_ID_ASC',
  CampaignAuditLogsByCampaignIdMinProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_MIN_PROFILE_ID_DESC',
  CampaignAuditLogsByCampaignIdStddevPopulationAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_POPULATION_AFTER_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdStddevPopulationAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_POPULATION_AFTER_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdStddevPopulationBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_POPULATION_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdStddevPopulationBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_POPULATION_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdStddevPopulationCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByCampaignIdStddevPopulationCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByCampaignIdStddevPopulationCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CampaignAuditLogsByCampaignIdStddevPopulationCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CampaignAuditLogsByCampaignIdStddevPopulationIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_ASC',
  CampaignAuditLogsByCampaignIdStddevPopulationIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_DESC',
  CampaignAuditLogsByCampaignIdStddevPopulationProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_POPULATION_PROFILE_ID_ASC',
  CampaignAuditLogsByCampaignIdStddevPopulationProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_POPULATION_PROFILE_ID_DESC',
  CampaignAuditLogsByCampaignIdStddevSampleAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_AFTER_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdStddevSampleAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_AFTER_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdStddevSampleBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdStddevSampleBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdStddevSampleCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByCampaignIdStddevSampleCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByCampaignIdStddevSampleCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CampaignAuditLogsByCampaignIdStddevSampleCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CampaignAuditLogsByCampaignIdStddevSampleIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_ASC',
  CampaignAuditLogsByCampaignIdStddevSampleIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_DESC',
  CampaignAuditLogsByCampaignIdStddevSampleProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_PROFILE_ID_ASC',
  CampaignAuditLogsByCampaignIdStddevSampleProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_PROFILE_ID_DESC',
  CampaignAuditLogsByCampaignIdSumAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_SUM_AFTER_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdSumAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_SUM_AFTER_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdSumBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_SUM_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdSumBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_SUM_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdSumCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByCampaignIdSumCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByCampaignIdSumCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_SUM_CREATED_TIME_ASC',
  CampaignAuditLogsByCampaignIdSumCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_SUM_CREATED_TIME_DESC',
  CampaignAuditLogsByCampaignIdSumIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_SUM_ID_ASC',
  CampaignAuditLogsByCampaignIdSumIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_SUM_ID_DESC',
  CampaignAuditLogsByCampaignIdSumProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_SUM_PROFILE_ID_ASC',
  CampaignAuditLogsByCampaignIdSumProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_SUM_PROFILE_ID_DESC',
  CampaignAuditLogsByCampaignIdVariancePopulationAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_AFTER_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdVariancePopulationAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_AFTER_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdVariancePopulationBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdVariancePopulationBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdVariancePopulationCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByCampaignIdVariancePopulationCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByCampaignIdVariancePopulationCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CampaignAuditLogsByCampaignIdVariancePopulationCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CampaignAuditLogsByCampaignIdVariancePopulationIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_ASC',
  CampaignAuditLogsByCampaignIdVariancePopulationIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_DESC',
  CampaignAuditLogsByCampaignIdVariancePopulationProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_PROFILE_ID_ASC',
  CampaignAuditLogsByCampaignIdVariancePopulationProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_PROFILE_ID_DESC',
  CampaignAuditLogsByCampaignIdVarianceSampleAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_AFTER_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdVarianceSampleAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_AFTER_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdVarianceSampleBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByCampaignIdVarianceSampleBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByCampaignIdVarianceSampleCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByCampaignIdVarianceSampleCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByCampaignIdVarianceSampleCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CampaignAuditLogsByCampaignIdVarianceSampleCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CampaignAuditLogsByCampaignIdVarianceSampleIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_ASC',
  CampaignAuditLogsByCampaignIdVarianceSampleIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_DESC',
  CampaignAuditLogsByCampaignIdVarianceSampleProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  CampaignAuditLogsByCampaignIdVarianceSampleProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_PROFILE_ID_DESC',
  CampaignMembersByCampaignIdAverageCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_ASC',
  CampaignMembersByCampaignIdAverageCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_DESC',
  CampaignMembersByCampaignIdAverageContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_CONTACT_ID_ASC',
  CampaignMembersByCampaignIdAverageContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_CONTACT_ID_DESC',
  CampaignMembersByCampaignIdAverageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdAverageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdAverageIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_ID_ASC',
  CampaignMembersByCampaignIdAverageIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_ID_DESC',
  CampaignMembersByCampaignIdAverageIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_INCLUDE_LAWYER_ASC',
  CampaignMembersByCampaignIdAverageIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_INCLUDE_LAWYER_DESC',
  CampaignMembersByCampaignIdAverageIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByCampaignIdAverageIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByCampaignIdAverageLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdAverageLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdAverageLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByCampaignIdAverageLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByCampaignIdAverageNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdAverageNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdAverageNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_NEXT_ACTION_TIME_ASC',
  CampaignMembersByCampaignIdAverageNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_NEXT_ACTION_TIME_DESC',
  CampaignMembersByCampaignIdAverageStatusAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_STATUS_ASC',
  CampaignMembersByCampaignIdAverageStatusDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_STATUS_DESC',
  CampaignMembersByCampaignIdAverageUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_ASC',
  CampaignMembersByCampaignIdAverageUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_DESC',
  CampaignMembersByCampaignIdAverageVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_VISIBILITY_ASC',
  CampaignMembersByCampaignIdAverageVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_AVERAGE_VISIBILITY_DESC',
  CampaignMembersByCampaignIdCountAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_COUNT_ASC',
  CampaignMembersByCampaignIdCountDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_COUNT_DESC',
  CampaignMembersByCampaignIdDistinctCountCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  CampaignMembersByCampaignIdDistinctCountCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  CampaignMembersByCampaignIdDistinctCountContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  CampaignMembersByCampaignIdDistinctCountContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  CampaignMembersByCampaignIdDistinctCountCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdDistinctCountCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdDistinctCountIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_ASC',
  CampaignMembersByCampaignIdDistinctCountIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_DESC',
  CampaignMembersByCampaignIdDistinctCountIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_INCLUDE_LAWYER_ASC',
  CampaignMembersByCampaignIdDistinctCountIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_INCLUDE_LAWYER_DESC',
  CampaignMembersByCampaignIdDistinctCountIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByCampaignIdDistinctCountIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByCampaignIdDistinctCountLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdDistinctCountLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdDistinctCountLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByCampaignIdDistinctCountLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByCampaignIdDistinctCountNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdDistinctCountNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdDistinctCountNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_NEXT_ACTION_TIME_ASC',
  CampaignMembersByCampaignIdDistinctCountNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_NEXT_ACTION_TIME_DESC',
  CampaignMembersByCampaignIdDistinctCountStatusAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_STATUS_ASC',
  CampaignMembersByCampaignIdDistinctCountStatusDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_STATUS_DESC',
  CampaignMembersByCampaignIdDistinctCountUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  CampaignMembersByCampaignIdDistinctCountUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  CampaignMembersByCampaignIdDistinctCountVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_VISIBILITY_ASC',
  CampaignMembersByCampaignIdDistinctCountVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_DISTINCT_COUNT_VISIBILITY_DESC',
  CampaignMembersByCampaignIdMaxCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_ASC',
  CampaignMembersByCampaignIdMaxCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_DESC',
  CampaignMembersByCampaignIdMaxContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_CONTACT_ID_ASC',
  CampaignMembersByCampaignIdMaxContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_CONTACT_ID_DESC',
  CampaignMembersByCampaignIdMaxCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdMaxCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdMaxIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_ID_ASC',
  CampaignMembersByCampaignIdMaxIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_ID_DESC',
  CampaignMembersByCampaignIdMaxIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_INCLUDE_LAWYER_ASC',
  CampaignMembersByCampaignIdMaxIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_INCLUDE_LAWYER_DESC',
  CampaignMembersByCampaignIdMaxIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByCampaignIdMaxIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByCampaignIdMaxLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdMaxLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdMaxLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByCampaignIdMaxLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByCampaignIdMaxNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdMaxNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdMaxNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_NEXT_ACTION_TIME_ASC',
  CampaignMembersByCampaignIdMaxNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_NEXT_ACTION_TIME_DESC',
  CampaignMembersByCampaignIdMaxStatusAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_STATUS_ASC',
  CampaignMembersByCampaignIdMaxStatusDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_STATUS_DESC',
  CampaignMembersByCampaignIdMaxUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_ASC',
  CampaignMembersByCampaignIdMaxUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_DESC',
  CampaignMembersByCampaignIdMaxVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_VISIBILITY_ASC',
  CampaignMembersByCampaignIdMaxVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MAX_VISIBILITY_DESC',
  CampaignMembersByCampaignIdMinCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_ASC',
  CampaignMembersByCampaignIdMinCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_DESC',
  CampaignMembersByCampaignIdMinContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_CONTACT_ID_ASC',
  CampaignMembersByCampaignIdMinContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_CONTACT_ID_DESC',
  CampaignMembersByCampaignIdMinCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdMinCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdMinIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_ID_ASC',
  CampaignMembersByCampaignIdMinIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_ID_DESC',
  CampaignMembersByCampaignIdMinIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_INCLUDE_LAWYER_ASC',
  CampaignMembersByCampaignIdMinIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_INCLUDE_LAWYER_DESC',
  CampaignMembersByCampaignIdMinIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByCampaignIdMinIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByCampaignIdMinLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdMinLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdMinLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByCampaignIdMinLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByCampaignIdMinNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdMinNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdMinNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_NEXT_ACTION_TIME_ASC',
  CampaignMembersByCampaignIdMinNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_NEXT_ACTION_TIME_DESC',
  CampaignMembersByCampaignIdMinStatusAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_STATUS_ASC',
  CampaignMembersByCampaignIdMinStatusDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_STATUS_DESC',
  CampaignMembersByCampaignIdMinUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_ASC',
  CampaignMembersByCampaignIdMinUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_DESC',
  CampaignMembersByCampaignIdMinVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_VISIBILITY_ASC',
  CampaignMembersByCampaignIdMinVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_MIN_VISIBILITY_DESC',
  CampaignMembersByCampaignIdStddevPopulationCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  CampaignMembersByCampaignIdStddevPopulationCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  CampaignMembersByCampaignIdStddevPopulationContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  CampaignMembersByCampaignIdStddevPopulationContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  CampaignMembersByCampaignIdStddevPopulationCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdStddevPopulationCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdStddevPopulationIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_ASC',
  CampaignMembersByCampaignIdStddevPopulationIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_DESC',
  CampaignMembersByCampaignIdStddevPopulationIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_INCLUDE_LAWYER_ASC',
  CampaignMembersByCampaignIdStddevPopulationIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_INCLUDE_LAWYER_DESC',
  CampaignMembersByCampaignIdStddevPopulationIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByCampaignIdStddevPopulationIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByCampaignIdStddevPopulationLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdStddevPopulationLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdStddevPopulationLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByCampaignIdStddevPopulationLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByCampaignIdStddevPopulationNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdStddevPopulationNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdStddevPopulationNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_NEXT_ACTION_TIME_ASC',
  CampaignMembersByCampaignIdStddevPopulationNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_NEXT_ACTION_TIME_DESC',
  CampaignMembersByCampaignIdStddevPopulationStatusAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_STATUS_ASC',
  CampaignMembersByCampaignIdStddevPopulationStatusDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_STATUS_DESC',
  CampaignMembersByCampaignIdStddevPopulationUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  CampaignMembersByCampaignIdStddevPopulationUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  CampaignMembersByCampaignIdStddevPopulationVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_VISIBILITY_ASC',
  CampaignMembersByCampaignIdStddevPopulationVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_POPULATION_VISIBILITY_DESC',
  CampaignMembersByCampaignIdStddevSampleCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  CampaignMembersByCampaignIdStddevSampleCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  CampaignMembersByCampaignIdStddevSampleContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  CampaignMembersByCampaignIdStddevSampleContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  CampaignMembersByCampaignIdStddevSampleCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdStddevSampleCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdStddevSampleIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_ASC',
  CampaignMembersByCampaignIdStddevSampleIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_DESC',
  CampaignMembersByCampaignIdStddevSampleIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_INCLUDE_LAWYER_ASC',
  CampaignMembersByCampaignIdStddevSampleIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_INCLUDE_LAWYER_DESC',
  CampaignMembersByCampaignIdStddevSampleIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByCampaignIdStddevSampleIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByCampaignIdStddevSampleLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdStddevSampleLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdStddevSampleLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByCampaignIdStddevSampleLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByCampaignIdStddevSampleNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdStddevSampleNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdStddevSampleNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NEXT_ACTION_TIME_ASC',
  CampaignMembersByCampaignIdStddevSampleNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NEXT_ACTION_TIME_DESC',
  CampaignMembersByCampaignIdStddevSampleStatusAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_STATUS_ASC',
  CampaignMembersByCampaignIdStddevSampleStatusDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_STATUS_DESC',
  CampaignMembersByCampaignIdStddevSampleUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  CampaignMembersByCampaignIdStddevSampleUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  CampaignMembersByCampaignIdStddevSampleVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_VISIBILITY_ASC',
  CampaignMembersByCampaignIdStddevSampleVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_VISIBILITY_DESC',
  CampaignMembersByCampaignIdSumCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_ASC',
  CampaignMembersByCampaignIdSumCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_DESC',
  CampaignMembersByCampaignIdSumContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_CONTACT_ID_ASC',
  CampaignMembersByCampaignIdSumContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_CONTACT_ID_DESC',
  CampaignMembersByCampaignIdSumCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdSumCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdSumIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_ID_ASC',
  CampaignMembersByCampaignIdSumIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_ID_DESC',
  CampaignMembersByCampaignIdSumIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_INCLUDE_LAWYER_ASC',
  CampaignMembersByCampaignIdSumIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_INCLUDE_LAWYER_DESC',
  CampaignMembersByCampaignIdSumIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByCampaignIdSumIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByCampaignIdSumLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdSumLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdSumLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByCampaignIdSumLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByCampaignIdSumNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdSumNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdSumNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_NEXT_ACTION_TIME_ASC',
  CampaignMembersByCampaignIdSumNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_NEXT_ACTION_TIME_DESC',
  CampaignMembersByCampaignIdSumStatusAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_STATUS_ASC',
  CampaignMembersByCampaignIdSumStatusDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_STATUS_DESC',
  CampaignMembersByCampaignIdSumUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_ASC',
  CampaignMembersByCampaignIdSumUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_DESC',
  CampaignMembersByCampaignIdSumVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_VISIBILITY_ASC',
  CampaignMembersByCampaignIdSumVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_SUM_VISIBILITY_DESC',
  CampaignMembersByCampaignIdVariancePopulationCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  CampaignMembersByCampaignIdVariancePopulationCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  CampaignMembersByCampaignIdVariancePopulationContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  CampaignMembersByCampaignIdVariancePopulationContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  CampaignMembersByCampaignIdVariancePopulationCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdVariancePopulationCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdVariancePopulationIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_ASC',
  CampaignMembersByCampaignIdVariancePopulationIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_DESC',
  CampaignMembersByCampaignIdVariancePopulationIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_INCLUDE_LAWYER_ASC',
  CampaignMembersByCampaignIdVariancePopulationIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_INCLUDE_LAWYER_DESC',
  CampaignMembersByCampaignIdVariancePopulationIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByCampaignIdVariancePopulationIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByCampaignIdVariancePopulationLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdVariancePopulationLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdVariancePopulationLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByCampaignIdVariancePopulationLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByCampaignIdVariancePopulationNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdVariancePopulationNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdVariancePopulationNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NEXT_ACTION_TIME_ASC',
  CampaignMembersByCampaignIdVariancePopulationNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NEXT_ACTION_TIME_DESC',
  CampaignMembersByCampaignIdVariancePopulationStatusAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_STATUS_ASC',
  CampaignMembersByCampaignIdVariancePopulationStatusDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_STATUS_DESC',
  CampaignMembersByCampaignIdVariancePopulationUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  CampaignMembersByCampaignIdVariancePopulationUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  CampaignMembersByCampaignIdVariancePopulationVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_VISIBILITY_ASC',
  CampaignMembersByCampaignIdVariancePopulationVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_VISIBILITY_DESC',
  CampaignMembersByCampaignIdVarianceSampleCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  CampaignMembersByCampaignIdVarianceSampleCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  CampaignMembersByCampaignIdVarianceSampleContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  CampaignMembersByCampaignIdVarianceSampleContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  CampaignMembersByCampaignIdVarianceSampleCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdVarianceSampleCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdVarianceSampleIdAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_ASC',
  CampaignMembersByCampaignIdVarianceSampleIdDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_DESC',
  CampaignMembersByCampaignIdVarianceSampleIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_INCLUDE_LAWYER_ASC',
  CampaignMembersByCampaignIdVarianceSampleIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_INCLUDE_LAWYER_DESC',
  CampaignMembersByCampaignIdVarianceSampleIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByCampaignIdVarianceSampleIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByCampaignIdVarianceSampleLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdVarianceSampleLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdVarianceSampleLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByCampaignIdVarianceSampleLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByCampaignIdVarianceSampleNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByCampaignIdVarianceSampleNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByCampaignIdVarianceSampleNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NEXT_ACTION_TIME_ASC',
  CampaignMembersByCampaignIdVarianceSampleNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NEXT_ACTION_TIME_DESC',
  CampaignMembersByCampaignIdVarianceSampleStatusAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_STATUS_ASC',
  CampaignMembersByCampaignIdVarianceSampleStatusDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_STATUS_DESC',
  CampaignMembersByCampaignIdVarianceSampleUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  CampaignMembersByCampaignIdVarianceSampleUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  CampaignMembersByCampaignIdVarianceSampleVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_VISIBILITY_ASC',
  CampaignMembersByCampaignIdVarianceSampleVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_VISIBILITY_DESC',
  CampaignSearchStatsByCampaignIdAverageCampaignIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_ASC',
  CampaignSearchStatsByCampaignIdAverageCampaignIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_DESC',
  CampaignSearchStatsByCampaignIdAverageCreatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdAverageCreatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdAverageIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_ID_ASC',
  CampaignSearchStatsByCampaignIdAverageIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_ID_DESC',
  CampaignSearchStatsByCampaignIdAverageSearchAfterAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_SEARCH_AFTER_ASC',
  CampaignSearchStatsByCampaignIdAverageSearchAfterDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_SEARCH_AFTER_DESC',
  CampaignSearchStatsByCampaignIdAverageSearchAfterScoreAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_SEARCH_AFTER_SCORE_ASC',
  CampaignSearchStatsByCampaignIdAverageSearchAfterScoreDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_SEARCH_AFTER_SCORE_DESC',
  CampaignSearchStatsByCampaignIdAverageSearchBeforeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_SEARCH_BEFORE_ASC',
  CampaignSearchStatsByCampaignIdAverageSearchBeforeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_SEARCH_BEFORE_DESC',
  CampaignSearchStatsByCampaignIdAverageSearchStartedAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_SEARCH_STARTED_ASC',
  CampaignSearchStatsByCampaignIdAverageSearchStartedDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_SEARCH_STARTED_DESC',
  CampaignSearchStatsByCampaignIdAverageSearchTypeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_SEARCH_TYPE_ASC',
  CampaignSearchStatsByCampaignIdAverageSearchTypeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_SEARCH_TYPE_DESC',
  CampaignSearchStatsByCampaignIdAverageUpdatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdAverageUpdatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdCountAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_COUNT_ASC',
  CampaignSearchStatsByCampaignIdCountDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_COUNT_DESC',
  CampaignSearchStatsByCampaignIdDistinctCountCampaignIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  CampaignSearchStatsByCampaignIdDistinctCountCampaignIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  CampaignSearchStatsByCampaignIdDistinctCountCreatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdDistinctCountCreatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdDistinctCountIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_ASC',
  CampaignSearchStatsByCampaignIdDistinctCountIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_DESC',
  CampaignSearchStatsByCampaignIdDistinctCountSearchAfterAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_AFTER_ASC',
  CampaignSearchStatsByCampaignIdDistinctCountSearchAfterDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_AFTER_DESC',
  CampaignSearchStatsByCampaignIdDistinctCountSearchAfterScoreAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_AFTER_SCORE_ASC',
  CampaignSearchStatsByCampaignIdDistinctCountSearchAfterScoreDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_AFTER_SCORE_DESC',
  CampaignSearchStatsByCampaignIdDistinctCountSearchBeforeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_BEFORE_ASC',
  CampaignSearchStatsByCampaignIdDistinctCountSearchBeforeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_BEFORE_DESC',
  CampaignSearchStatsByCampaignIdDistinctCountSearchStartedAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_STARTED_ASC',
  CampaignSearchStatsByCampaignIdDistinctCountSearchStartedDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_STARTED_DESC',
  CampaignSearchStatsByCampaignIdDistinctCountSearchTypeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_TYPE_ASC',
  CampaignSearchStatsByCampaignIdDistinctCountSearchTypeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_TYPE_DESC',
  CampaignSearchStatsByCampaignIdDistinctCountUpdatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdDistinctCountUpdatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdMaxCampaignIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_ASC',
  CampaignSearchStatsByCampaignIdMaxCampaignIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_DESC',
  CampaignSearchStatsByCampaignIdMaxCreatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_CREATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdMaxCreatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_CREATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdMaxIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_ID_ASC',
  CampaignSearchStatsByCampaignIdMaxIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_ID_DESC',
  CampaignSearchStatsByCampaignIdMaxSearchAfterAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_SEARCH_AFTER_ASC',
  CampaignSearchStatsByCampaignIdMaxSearchAfterDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_SEARCH_AFTER_DESC',
  CampaignSearchStatsByCampaignIdMaxSearchAfterScoreAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_SEARCH_AFTER_SCORE_ASC',
  CampaignSearchStatsByCampaignIdMaxSearchAfterScoreDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_SEARCH_AFTER_SCORE_DESC',
  CampaignSearchStatsByCampaignIdMaxSearchBeforeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_SEARCH_BEFORE_ASC',
  CampaignSearchStatsByCampaignIdMaxSearchBeforeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_SEARCH_BEFORE_DESC',
  CampaignSearchStatsByCampaignIdMaxSearchStartedAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_SEARCH_STARTED_ASC',
  CampaignSearchStatsByCampaignIdMaxSearchStartedDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_SEARCH_STARTED_DESC',
  CampaignSearchStatsByCampaignIdMaxSearchTypeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_SEARCH_TYPE_ASC',
  CampaignSearchStatsByCampaignIdMaxSearchTypeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_SEARCH_TYPE_DESC',
  CampaignSearchStatsByCampaignIdMaxUpdatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdMaxUpdatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdMinCampaignIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_ASC',
  CampaignSearchStatsByCampaignIdMinCampaignIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_DESC',
  CampaignSearchStatsByCampaignIdMinCreatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_CREATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdMinCreatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_CREATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdMinIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_ID_ASC',
  CampaignSearchStatsByCampaignIdMinIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_ID_DESC',
  CampaignSearchStatsByCampaignIdMinSearchAfterAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_SEARCH_AFTER_ASC',
  CampaignSearchStatsByCampaignIdMinSearchAfterDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_SEARCH_AFTER_DESC',
  CampaignSearchStatsByCampaignIdMinSearchAfterScoreAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_SEARCH_AFTER_SCORE_ASC',
  CampaignSearchStatsByCampaignIdMinSearchAfterScoreDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_SEARCH_AFTER_SCORE_DESC',
  CampaignSearchStatsByCampaignIdMinSearchBeforeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_SEARCH_BEFORE_ASC',
  CampaignSearchStatsByCampaignIdMinSearchBeforeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_SEARCH_BEFORE_DESC',
  CampaignSearchStatsByCampaignIdMinSearchStartedAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_SEARCH_STARTED_ASC',
  CampaignSearchStatsByCampaignIdMinSearchStartedDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_SEARCH_STARTED_DESC',
  CampaignSearchStatsByCampaignIdMinSearchTypeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_SEARCH_TYPE_ASC',
  CampaignSearchStatsByCampaignIdMinSearchTypeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_SEARCH_TYPE_DESC',
  CampaignSearchStatsByCampaignIdMinUpdatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdMinUpdatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdStddevPopulationCampaignIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  CampaignSearchStatsByCampaignIdStddevPopulationCampaignIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  CampaignSearchStatsByCampaignIdStddevPopulationCreatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdStddevPopulationCreatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdStddevPopulationIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_ASC',
  CampaignSearchStatsByCampaignIdStddevPopulationIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_DESC',
  CampaignSearchStatsByCampaignIdStddevPopulationSearchAfterAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_AFTER_ASC',
  CampaignSearchStatsByCampaignIdStddevPopulationSearchAfterDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_AFTER_DESC',
  CampaignSearchStatsByCampaignIdStddevPopulationSearchAfterScoreAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_AFTER_SCORE_ASC',
  CampaignSearchStatsByCampaignIdStddevPopulationSearchAfterScoreDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_AFTER_SCORE_DESC',
  CampaignSearchStatsByCampaignIdStddevPopulationSearchBeforeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_BEFORE_ASC',
  CampaignSearchStatsByCampaignIdStddevPopulationSearchBeforeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_BEFORE_DESC',
  CampaignSearchStatsByCampaignIdStddevPopulationSearchStartedAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_STARTED_ASC',
  CampaignSearchStatsByCampaignIdStddevPopulationSearchStartedDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_STARTED_DESC',
  CampaignSearchStatsByCampaignIdStddevPopulationSearchTypeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_TYPE_ASC',
  CampaignSearchStatsByCampaignIdStddevPopulationSearchTypeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_TYPE_DESC',
  CampaignSearchStatsByCampaignIdStddevPopulationUpdatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdStddevPopulationUpdatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdStddevSampleCampaignIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  CampaignSearchStatsByCampaignIdStddevSampleCampaignIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  CampaignSearchStatsByCampaignIdStddevSampleCreatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdStddevSampleCreatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdStddevSampleIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_ASC',
  CampaignSearchStatsByCampaignIdStddevSampleIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_DESC',
  CampaignSearchStatsByCampaignIdStddevSampleSearchAfterAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_AFTER_ASC',
  CampaignSearchStatsByCampaignIdStddevSampleSearchAfterDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_AFTER_DESC',
  CampaignSearchStatsByCampaignIdStddevSampleSearchAfterScoreAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_AFTER_SCORE_ASC',
  CampaignSearchStatsByCampaignIdStddevSampleSearchAfterScoreDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_AFTER_SCORE_DESC',
  CampaignSearchStatsByCampaignIdStddevSampleSearchBeforeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_BEFORE_ASC',
  CampaignSearchStatsByCampaignIdStddevSampleSearchBeforeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_BEFORE_DESC',
  CampaignSearchStatsByCampaignIdStddevSampleSearchStartedAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_STARTED_ASC',
  CampaignSearchStatsByCampaignIdStddevSampleSearchStartedDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_STARTED_DESC',
  CampaignSearchStatsByCampaignIdStddevSampleSearchTypeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_TYPE_ASC',
  CampaignSearchStatsByCampaignIdStddevSampleSearchTypeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_TYPE_DESC',
  CampaignSearchStatsByCampaignIdStddevSampleUpdatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdStddevSampleUpdatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdSumCampaignIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_ASC',
  CampaignSearchStatsByCampaignIdSumCampaignIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_DESC',
  CampaignSearchStatsByCampaignIdSumCreatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_CREATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdSumCreatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_CREATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdSumIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_ID_ASC',
  CampaignSearchStatsByCampaignIdSumIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_ID_DESC',
  CampaignSearchStatsByCampaignIdSumSearchAfterAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_SEARCH_AFTER_ASC',
  CampaignSearchStatsByCampaignIdSumSearchAfterDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_SEARCH_AFTER_DESC',
  CampaignSearchStatsByCampaignIdSumSearchAfterScoreAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_SEARCH_AFTER_SCORE_ASC',
  CampaignSearchStatsByCampaignIdSumSearchAfterScoreDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_SEARCH_AFTER_SCORE_DESC',
  CampaignSearchStatsByCampaignIdSumSearchBeforeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_SEARCH_BEFORE_ASC',
  CampaignSearchStatsByCampaignIdSumSearchBeforeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_SEARCH_BEFORE_DESC',
  CampaignSearchStatsByCampaignIdSumSearchStartedAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_SEARCH_STARTED_ASC',
  CampaignSearchStatsByCampaignIdSumSearchStartedDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_SEARCH_STARTED_DESC',
  CampaignSearchStatsByCampaignIdSumSearchTypeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_SEARCH_TYPE_ASC',
  CampaignSearchStatsByCampaignIdSumSearchTypeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_SEARCH_TYPE_DESC',
  CampaignSearchStatsByCampaignIdSumUpdatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdSumUpdatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdVariancePopulationCampaignIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  CampaignSearchStatsByCampaignIdVariancePopulationCampaignIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  CampaignSearchStatsByCampaignIdVariancePopulationCreatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdVariancePopulationCreatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdVariancePopulationIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_ASC',
  CampaignSearchStatsByCampaignIdVariancePopulationIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_DESC',
  CampaignSearchStatsByCampaignIdVariancePopulationSearchAfterAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_AFTER_ASC',
  CampaignSearchStatsByCampaignIdVariancePopulationSearchAfterDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_AFTER_DESC',
  CampaignSearchStatsByCampaignIdVariancePopulationSearchAfterScoreAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_AFTER_SCORE_ASC',
  CampaignSearchStatsByCampaignIdVariancePopulationSearchAfterScoreDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_AFTER_SCORE_DESC',
  CampaignSearchStatsByCampaignIdVariancePopulationSearchBeforeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_BEFORE_ASC',
  CampaignSearchStatsByCampaignIdVariancePopulationSearchBeforeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_BEFORE_DESC',
  CampaignSearchStatsByCampaignIdVariancePopulationSearchStartedAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_STARTED_ASC',
  CampaignSearchStatsByCampaignIdVariancePopulationSearchStartedDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_STARTED_DESC',
  CampaignSearchStatsByCampaignIdVariancePopulationSearchTypeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_TYPE_ASC',
  CampaignSearchStatsByCampaignIdVariancePopulationSearchTypeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_TYPE_DESC',
  CampaignSearchStatsByCampaignIdVariancePopulationUpdatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdVariancePopulationUpdatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdVarianceSampleCampaignIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  CampaignSearchStatsByCampaignIdVarianceSampleCampaignIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  CampaignSearchStatsByCampaignIdVarianceSampleCreatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdVarianceSampleCreatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CampaignSearchStatsByCampaignIdVarianceSampleIdAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_ASC',
  CampaignSearchStatsByCampaignIdVarianceSampleIdDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_DESC',
  CampaignSearchStatsByCampaignIdVarianceSampleSearchAfterAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_AFTER_ASC',
  CampaignSearchStatsByCampaignIdVarianceSampleSearchAfterDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_AFTER_DESC',
  CampaignSearchStatsByCampaignIdVarianceSampleSearchAfterScoreAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_AFTER_SCORE_ASC',
  CampaignSearchStatsByCampaignIdVarianceSampleSearchAfterScoreDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_AFTER_SCORE_DESC',
  CampaignSearchStatsByCampaignIdVarianceSampleSearchBeforeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_BEFORE_ASC',
  CampaignSearchStatsByCampaignIdVarianceSampleSearchBeforeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_BEFORE_DESC',
  CampaignSearchStatsByCampaignIdVarianceSampleSearchStartedAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_STARTED_ASC',
  CampaignSearchStatsByCampaignIdVarianceSampleSearchStartedDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_STARTED_DESC',
  CampaignSearchStatsByCampaignIdVarianceSampleSearchTypeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_TYPE_ASC',
  CampaignSearchStatsByCampaignIdVarianceSampleSearchTypeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_TYPE_DESC',
  CampaignSearchStatsByCampaignIdVarianceSampleUpdatedTimeAsc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  CampaignSearchStatsByCampaignIdVarianceSampleUpdatedTimeDesc = 'CAMPAIGN_SEARCH_STATS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  CampaignTargetIdAsc = 'CAMPAIGN_TARGET_ID_ASC',
  CampaignTargetIdDesc = 'CAMPAIGN_TARGET_ID_DESC',
  ChannelAsc = 'CHANNEL_ASC',
  ChannelDesc = 'CHANNEL_DESC',
  ContactCampaignTagLinksByCampaignIdAverageCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByCampaignIdAverageCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByCampaignIdAverageContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_AVERAGE_CONTACT_ID_ASC',
  ContactCampaignTagLinksByCampaignIdAverageContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_AVERAGE_CONTACT_ID_DESC',
  ContactCampaignTagLinksByCampaignIdAverageTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_AVERAGE_TAG_ID_ASC',
  ContactCampaignTagLinksByCampaignIdAverageTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_AVERAGE_TAG_ID_DESC',
  ContactCampaignTagLinksByCampaignIdCountAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_COUNT_ASC',
  ContactCampaignTagLinksByCampaignIdCountDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_COUNT_DESC',
  ContactCampaignTagLinksByCampaignIdDistinctCountCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByCampaignIdDistinctCountCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByCampaignIdDistinctCountContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ContactCampaignTagLinksByCampaignIdDistinctCountContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ContactCampaignTagLinksByCampaignIdDistinctCountTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_DISTINCT_COUNT_TAG_ID_ASC',
  ContactCampaignTagLinksByCampaignIdDistinctCountTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_DISTINCT_COUNT_TAG_ID_DESC',
  ContactCampaignTagLinksByCampaignIdMaxCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByCampaignIdMaxCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByCampaignIdMaxContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_MAX_CONTACT_ID_ASC',
  ContactCampaignTagLinksByCampaignIdMaxContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_MAX_CONTACT_ID_DESC',
  ContactCampaignTagLinksByCampaignIdMaxTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_MAX_TAG_ID_ASC',
  ContactCampaignTagLinksByCampaignIdMaxTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_MAX_TAG_ID_DESC',
  ContactCampaignTagLinksByCampaignIdMinCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByCampaignIdMinCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByCampaignIdMinContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_MIN_CONTACT_ID_ASC',
  ContactCampaignTagLinksByCampaignIdMinContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_MIN_CONTACT_ID_DESC',
  ContactCampaignTagLinksByCampaignIdMinTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_MIN_TAG_ID_ASC',
  ContactCampaignTagLinksByCampaignIdMinTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_MIN_TAG_ID_DESC',
  ContactCampaignTagLinksByCampaignIdStddevPopulationCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByCampaignIdStddevPopulationCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByCampaignIdStddevPopulationContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ContactCampaignTagLinksByCampaignIdStddevPopulationContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ContactCampaignTagLinksByCampaignIdStddevPopulationTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_STDDEV_POPULATION_TAG_ID_ASC',
  ContactCampaignTagLinksByCampaignIdStddevPopulationTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_STDDEV_POPULATION_TAG_ID_DESC',
  ContactCampaignTagLinksByCampaignIdStddevSampleCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByCampaignIdStddevSampleCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByCampaignIdStddevSampleContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ContactCampaignTagLinksByCampaignIdStddevSampleContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ContactCampaignTagLinksByCampaignIdStddevSampleTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_TAG_ID_ASC',
  ContactCampaignTagLinksByCampaignIdStddevSampleTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_TAG_ID_DESC',
  ContactCampaignTagLinksByCampaignIdSumCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByCampaignIdSumCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByCampaignIdSumContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_SUM_CONTACT_ID_ASC',
  ContactCampaignTagLinksByCampaignIdSumContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_SUM_CONTACT_ID_DESC',
  ContactCampaignTagLinksByCampaignIdSumTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_SUM_TAG_ID_ASC',
  ContactCampaignTagLinksByCampaignIdSumTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_SUM_TAG_ID_DESC',
  ContactCampaignTagLinksByCampaignIdVariancePopulationCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByCampaignIdVariancePopulationCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByCampaignIdVariancePopulationContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ContactCampaignTagLinksByCampaignIdVariancePopulationContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ContactCampaignTagLinksByCampaignIdVariancePopulationTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_TAG_ID_ASC',
  ContactCampaignTagLinksByCampaignIdVariancePopulationTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_TAG_ID_DESC',
  ContactCampaignTagLinksByCampaignIdVarianceSampleCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByCampaignIdVarianceSampleCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByCampaignIdVarianceSampleContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ContactCampaignTagLinksByCampaignIdVarianceSampleContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ContactCampaignTagLinksByCampaignIdVarianceSampleTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_TAG_ID_ASC',
  ContactCampaignTagLinksByCampaignIdVarianceSampleTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_TAG_ID_DESC',
  ConversionScoreAsc = 'CONVERSION_SCORE_ASC',
  ConversionScoreDesc = 'CONVERSION_SCORE_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  DailyStatisticsByCampaignIdAverageCampaignIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_ASC',
  DailyStatisticsByCampaignIdAverageCampaignIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_DESC',
  DailyStatisticsByCampaignIdAverageDateAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_DATE_ASC',
  DailyStatisticsByCampaignIdAverageDateDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_DATE_DESC',
  DailyStatisticsByCampaignIdAverageGroupNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_GROUP_NAME_ASC',
  DailyStatisticsByCampaignIdAverageGroupNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_GROUP_NAME_DESC',
  DailyStatisticsByCampaignIdAverageIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_ID_ASC',
  DailyStatisticsByCampaignIdAverageIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_ID_DESC',
  DailyStatisticsByCampaignIdAverageLawyerIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_LAWYER_ID_ASC',
  DailyStatisticsByCampaignIdAverageLawyerIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_LAWYER_ID_DESC',
  DailyStatisticsByCampaignIdAverageNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_NAME_ASC',
  DailyStatisticsByCampaignIdAverageNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_NAME_DESC',
  DailyStatisticsByCampaignIdAverageTemplateIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_TEMPLATE_ID_ASC',
  DailyStatisticsByCampaignIdAverageTemplateIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_TEMPLATE_ID_DESC',
  DailyStatisticsByCampaignIdAverageValueAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_VALUE_ASC',
  DailyStatisticsByCampaignIdAverageValueDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_VALUE_DESC',
  DailyStatisticsByCampaignIdCountAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_COUNT_ASC',
  DailyStatisticsByCampaignIdCountDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_COUNT_DESC',
  DailyStatisticsByCampaignIdDistinctCountCampaignIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  DailyStatisticsByCampaignIdDistinctCountCampaignIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  DailyStatisticsByCampaignIdDistinctCountDateAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_DATE_ASC',
  DailyStatisticsByCampaignIdDistinctCountDateDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_DATE_DESC',
  DailyStatisticsByCampaignIdDistinctCountGroupNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_GROUP_NAME_ASC',
  DailyStatisticsByCampaignIdDistinctCountGroupNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_GROUP_NAME_DESC',
  DailyStatisticsByCampaignIdDistinctCountIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_ASC',
  DailyStatisticsByCampaignIdDistinctCountIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_DESC',
  DailyStatisticsByCampaignIdDistinctCountLawyerIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  DailyStatisticsByCampaignIdDistinctCountLawyerIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  DailyStatisticsByCampaignIdDistinctCountNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_NAME_ASC',
  DailyStatisticsByCampaignIdDistinctCountNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_NAME_DESC',
  DailyStatisticsByCampaignIdDistinctCountTemplateIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  DailyStatisticsByCampaignIdDistinctCountTemplateIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  DailyStatisticsByCampaignIdDistinctCountValueAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_VALUE_ASC',
  DailyStatisticsByCampaignIdDistinctCountValueDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_VALUE_DESC',
  DailyStatisticsByCampaignIdMaxCampaignIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_ASC',
  DailyStatisticsByCampaignIdMaxCampaignIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_DESC',
  DailyStatisticsByCampaignIdMaxDateAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_DATE_ASC',
  DailyStatisticsByCampaignIdMaxDateDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_DATE_DESC',
  DailyStatisticsByCampaignIdMaxGroupNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_GROUP_NAME_ASC',
  DailyStatisticsByCampaignIdMaxGroupNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_GROUP_NAME_DESC',
  DailyStatisticsByCampaignIdMaxIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_ID_ASC',
  DailyStatisticsByCampaignIdMaxIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_ID_DESC',
  DailyStatisticsByCampaignIdMaxLawyerIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_LAWYER_ID_ASC',
  DailyStatisticsByCampaignIdMaxLawyerIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_LAWYER_ID_DESC',
  DailyStatisticsByCampaignIdMaxNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_NAME_ASC',
  DailyStatisticsByCampaignIdMaxNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_NAME_DESC',
  DailyStatisticsByCampaignIdMaxTemplateIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_TEMPLATE_ID_ASC',
  DailyStatisticsByCampaignIdMaxTemplateIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_TEMPLATE_ID_DESC',
  DailyStatisticsByCampaignIdMaxValueAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_VALUE_ASC',
  DailyStatisticsByCampaignIdMaxValueDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MAX_VALUE_DESC',
  DailyStatisticsByCampaignIdMinCampaignIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_ASC',
  DailyStatisticsByCampaignIdMinCampaignIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_DESC',
  DailyStatisticsByCampaignIdMinDateAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_DATE_ASC',
  DailyStatisticsByCampaignIdMinDateDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_DATE_DESC',
  DailyStatisticsByCampaignIdMinGroupNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_GROUP_NAME_ASC',
  DailyStatisticsByCampaignIdMinGroupNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_GROUP_NAME_DESC',
  DailyStatisticsByCampaignIdMinIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_ID_ASC',
  DailyStatisticsByCampaignIdMinIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_ID_DESC',
  DailyStatisticsByCampaignIdMinLawyerIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_LAWYER_ID_ASC',
  DailyStatisticsByCampaignIdMinLawyerIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_LAWYER_ID_DESC',
  DailyStatisticsByCampaignIdMinNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_NAME_ASC',
  DailyStatisticsByCampaignIdMinNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_NAME_DESC',
  DailyStatisticsByCampaignIdMinTemplateIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_TEMPLATE_ID_ASC',
  DailyStatisticsByCampaignIdMinTemplateIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_TEMPLATE_ID_DESC',
  DailyStatisticsByCampaignIdMinValueAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_VALUE_ASC',
  DailyStatisticsByCampaignIdMinValueDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_MIN_VALUE_DESC',
  DailyStatisticsByCampaignIdStddevPopulationCampaignIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  DailyStatisticsByCampaignIdStddevPopulationCampaignIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  DailyStatisticsByCampaignIdStddevPopulationDateAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_DATE_ASC',
  DailyStatisticsByCampaignIdStddevPopulationDateDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_DATE_DESC',
  DailyStatisticsByCampaignIdStddevPopulationGroupNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_GROUP_NAME_ASC',
  DailyStatisticsByCampaignIdStddevPopulationGroupNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_GROUP_NAME_DESC',
  DailyStatisticsByCampaignIdStddevPopulationIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_ASC',
  DailyStatisticsByCampaignIdStddevPopulationIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_DESC',
  DailyStatisticsByCampaignIdStddevPopulationLawyerIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  DailyStatisticsByCampaignIdStddevPopulationLawyerIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  DailyStatisticsByCampaignIdStddevPopulationNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_NAME_ASC',
  DailyStatisticsByCampaignIdStddevPopulationNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_NAME_DESC',
  DailyStatisticsByCampaignIdStddevPopulationTemplateIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  DailyStatisticsByCampaignIdStddevPopulationTemplateIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  DailyStatisticsByCampaignIdStddevPopulationValueAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_VALUE_ASC',
  DailyStatisticsByCampaignIdStddevPopulationValueDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_VALUE_DESC',
  DailyStatisticsByCampaignIdStddevSampleCampaignIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  DailyStatisticsByCampaignIdStddevSampleCampaignIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  DailyStatisticsByCampaignIdStddevSampleDateAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_DATE_ASC',
  DailyStatisticsByCampaignIdStddevSampleDateDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_DATE_DESC',
  DailyStatisticsByCampaignIdStddevSampleGroupNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_GROUP_NAME_ASC',
  DailyStatisticsByCampaignIdStddevSampleGroupNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_GROUP_NAME_DESC',
  DailyStatisticsByCampaignIdStddevSampleIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_ASC',
  DailyStatisticsByCampaignIdStddevSampleIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_DESC',
  DailyStatisticsByCampaignIdStddevSampleLawyerIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  DailyStatisticsByCampaignIdStddevSampleLawyerIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  DailyStatisticsByCampaignIdStddevSampleNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NAME_ASC',
  DailyStatisticsByCampaignIdStddevSampleNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NAME_DESC',
  DailyStatisticsByCampaignIdStddevSampleTemplateIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  DailyStatisticsByCampaignIdStddevSampleTemplateIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  DailyStatisticsByCampaignIdStddevSampleValueAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_VALUE_ASC',
  DailyStatisticsByCampaignIdStddevSampleValueDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_VALUE_DESC',
  DailyStatisticsByCampaignIdSumCampaignIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_ASC',
  DailyStatisticsByCampaignIdSumCampaignIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_DESC',
  DailyStatisticsByCampaignIdSumDateAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_DATE_ASC',
  DailyStatisticsByCampaignIdSumDateDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_DATE_DESC',
  DailyStatisticsByCampaignIdSumGroupNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_GROUP_NAME_ASC',
  DailyStatisticsByCampaignIdSumGroupNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_GROUP_NAME_DESC',
  DailyStatisticsByCampaignIdSumIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_ID_ASC',
  DailyStatisticsByCampaignIdSumIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_ID_DESC',
  DailyStatisticsByCampaignIdSumLawyerIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_LAWYER_ID_ASC',
  DailyStatisticsByCampaignIdSumLawyerIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_LAWYER_ID_DESC',
  DailyStatisticsByCampaignIdSumNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_NAME_ASC',
  DailyStatisticsByCampaignIdSumNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_NAME_DESC',
  DailyStatisticsByCampaignIdSumTemplateIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_TEMPLATE_ID_ASC',
  DailyStatisticsByCampaignIdSumTemplateIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_TEMPLATE_ID_DESC',
  DailyStatisticsByCampaignIdSumValueAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_VALUE_ASC',
  DailyStatisticsByCampaignIdSumValueDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_SUM_VALUE_DESC',
  DailyStatisticsByCampaignIdVariancePopulationCampaignIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  DailyStatisticsByCampaignIdVariancePopulationCampaignIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  DailyStatisticsByCampaignIdVariancePopulationDateAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_DATE_ASC',
  DailyStatisticsByCampaignIdVariancePopulationDateDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_DATE_DESC',
  DailyStatisticsByCampaignIdVariancePopulationGroupNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_GROUP_NAME_ASC',
  DailyStatisticsByCampaignIdVariancePopulationGroupNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_GROUP_NAME_DESC',
  DailyStatisticsByCampaignIdVariancePopulationIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_ASC',
  DailyStatisticsByCampaignIdVariancePopulationIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_DESC',
  DailyStatisticsByCampaignIdVariancePopulationLawyerIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  DailyStatisticsByCampaignIdVariancePopulationLawyerIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  DailyStatisticsByCampaignIdVariancePopulationNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NAME_ASC',
  DailyStatisticsByCampaignIdVariancePopulationNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NAME_DESC',
  DailyStatisticsByCampaignIdVariancePopulationTemplateIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  DailyStatisticsByCampaignIdVariancePopulationTemplateIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  DailyStatisticsByCampaignIdVariancePopulationValueAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_VALUE_ASC',
  DailyStatisticsByCampaignIdVariancePopulationValueDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_VALUE_DESC',
  DailyStatisticsByCampaignIdVarianceSampleCampaignIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  DailyStatisticsByCampaignIdVarianceSampleCampaignIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  DailyStatisticsByCampaignIdVarianceSampleDateAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_DATE_ASC',
  DailyStatisticsByCampaignIdVarianceSampleDateDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_DATE_DESC',
  DailyStatisticsByCampaignIdVarianceSampleGroupNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_GROUP_NAME_ASC',
  DailyStatisticsByCampaignIdVarianceSampleGroupNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_GROUP_NAME_DESC',
  DailyStatisticsByCampaignIdVarianceSampleIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_ASC',
  DailyStatisticsByCampaignIdVarianceSampleIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_DESC',
  DailyStatisticsByCampaignIdVarianceSampleLawyerIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  DailyStatisticsByCampaignIdVarianceSampleLawyerIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  DailyStatisticsByCampaignIdVarianceSampleNameAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NAME_ASC',
  DailyStatisticsByCampaignIdVarianceSampleNameDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NAME_DESC',
  DailyStatisticsByCampaignIdVarianceSampleTemplateIdAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  DailyStatisticsByCampaignIdVarianceSampleTemplateIdDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  DailyStatisticsByCampaignIdVarianceSampleValueAsc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_VALUE_ASC',
  DailyStatisticsByCampaignIdVarianceSampleValueDesc = 'DAILY_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_VALUE_DESC',
  DeactivatedTimeAsc = 'DEACTIVATED_TIME_ASC',
  DeactivatedTimeDesc = 'DEACTIVATED_TIME_DESC',
  DeletedAsc = 'DELETED_ASC',
  DeletedDesc = 'DELETED_DESC',
  DraftedByLawyerAsc = 'DRAFTED_BY_LAWYER_ASC',
  DraftedByLawyerDesc = 'DRAFTED_BY_LAWYER_DESC',
  DryRunsByCampaignIdAverageCampaignIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_ASC',
  DryRunsByCampaignIdAverageCampaignIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_DESC',
  DryRunsByCampaignIdAverageCreatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_ASC',
  DryRunsByCampaignIdAverageCreatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_DESC',
  DryRunsByCampaignIdAverageDryRunGroupIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_DRY_RUN_GROUP_ID_ASC',
  DryRunsByCampaignIdAverageDryRunGroupIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_DRY_RUN_GROUP_ID_DESC',
  DryRunsByCampaignIdAverageIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_ID_ASC',
  DryRunsByCampaignIdAverageIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_ID_DESC',
  DryRunsByCampaignIdAverageLookAheadDaysAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_LOOK_AHEAD_DAYS_ASC',
  DryRunsByCampaignIdAverageLookAheadDaysDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_LOOK_AHEAD_DAYS_DESC',
  DryRunsByCampaignIdAverageMaxAllocationAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_MAX_ALLOCATION_ASC',
  DryRunsByCampaignIdAverageMaxAllocationDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_MAX_ALLOCATION_DESC',
  DryRunsByCampaignIdAveragePersonalisationQueryAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_PERSONALISATION_QUERY_ASC',
  DryRunsByCampaignIdAveragePersonalisationQueryDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_PERSONALISATION_QUERY_DESC',
  DryRunsByCampaignIdAverageProgressAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_PROGRESS_ASC',
  DryRunsByCampaignIdAverageProgressDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_PROGRESS_DESC',
  DryRunsByCampaignIdAverageSearchTypeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_SEARCH_TYPE_ASC',
  DryRunsByCampaignIdAverageSearchTypeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_SEARCH_TYPE_DESC',
  DryRunsByCampaignIdAverageStatusAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_STATUS_ASC',
  DryRunsByCampaignIdAverageStatusDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_STATUS_DESC',
  DryRunsByCampaignIdAverageTargetDescriptionAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_TARGET_DESCRIPTION_ASC',
  DryRunsByCampaignIdAverageTargetDescriptionDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_TARGET_DESCRIPTION_DESC',
  DryRunsByCampaignIdAverageUpdatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_ASC',
  DryRunsByCampaignIdAverageUpdatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_DESC',
  DryRunsByCampaignIdCountAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_COUNT_ASC',
  DryRunsByCampaignIdCountDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_COUNT_DESC',
  DryRunsByCampaignIdDistinctCountCampaignIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  DryRunsByCampaignIdDistinctCountCampaignIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  DryRunsByCampaignIdDistinctCountCreatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  DryRunsByCampaignIdDistinctCountCreatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  DryRunsByCampaignIdDistinctCountDryRunGroupIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_DRY_RUN_GROUP_ID_ASC',
  DryRunsByCampaignIdDistinctCountDryRunGroupIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_DRY_RUN_GROUP_ID_DESC',
  DryRunsByCampaignIdDistinctCountIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_ASC',
  DryRunsByCampaignIdDistinctCountIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_DESC',
  DryRunsByCampaignIdDistinctCountLookAheadDaysAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_LOOK_AHEAD_DAYS_ASC',
  DryRunsByCampaignIdDistinctCountLookAheadDaysDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_LOOK_AHEAD_DAYS_DESC',
  DryRunsByCampaignIdDistinctCountMaxAllocationAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_MAX_ALLOCATION_ASC',
  DryRunsByCampaignIdDistinctCountMaxAllocationDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_MAX_ALLOCATION_DESC',
  DryRunsByCampaignIdDistinctCountPersonalisationQueryAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_PERSONALISATION_QUERY_ASC',
  DryRunsByCampaignIdDistinctCountPersonalisationQueryDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_PERSONALISATION_QUERY_DESC',
  DryRunsByCampaignIdDistinctCountProgressAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_PROGRESS_ASC',
  DryRunsByCampaignIdDistinctCountProgressDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_PROGRESS_DESC',
  DryRunsByCampaignIdDistinctCountSearchTypeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_TYPE_ASC',
  DryRunsByCampaignIdDistinctCountSearchTypeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEARCH_TYPE_DESC',
  DryRunsByCampaignIdDistinctCountStatusAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_STATUS_ASC',
  DryRunsByCampaignIdDistinctCountStatusDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_STATUS_DESC',
  DryRunsByCampaignIdDistinctCountTargetDescriptionAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_TARGET_DESCRIPTION_ASC',
  DryRunsByCampaignIdDistinctCountTargetDescriptionDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_TARGET_DESCRIPTION_DESC',
  DryRunsByCampaignIdDistinctCountUpdatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  DryRunsByCampaignIdDistinctCountUpdatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  DryRunsByCampaignIdMaxCampaignIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_ASC',
  DryRunsByCampaignIdMaxCampaignIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_DESC',
  DryRunsByCampaignIdMaxCreatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_CREATED_TIME_ASC',
  DryRunsByCampaignIdMaxCreatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_CREATED_TIME_DESC',
  DryRunsByCampaignIdMaxDryRunGroupIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_DRY_RUN_GROUP_ID_ASC',
  DryRunsByCampaignIdMaxDryRunGroupIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_DRY_RUN_GROUP_ID_DESC',
  DryRunsByCampaignIdMaxIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_ID_ASC',
  DryRunsByCampaignIdMaxIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_ID_DESC',
  DryRunsByCampaignIdMaxLookAheadDaysAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_LOOK_AHEAD_DAYS_ASC',
  DryRunsByCampaignIdMaxLookAheadDaysDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_LOOK_AHEAD_DAYS_DESC',
  DryRunsByCampaignIdMaxMaxAllocationAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_MAX_ALLOCATION_ASC',
  DryRunsByCampaignIdMaxMaxAllocationDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_MAX_ALLOCATION_DESC',
  DryRunsByCampaignIdMaxPersonalisationQueryAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_PERSONALISATION_QUERY_ASC',
  DryRunsByCampaignIdMaxPersonalisationQueryDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_PERSONALISATION_QUERY_DESC',
  DryRunsByCampaignIdMaxProgressAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_PROGRESS_ASC',
  DryRunsByCampaignIdMaxProgressDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_PROGRESS_DESC',
  DryRunsByCampaignIdMaxSearchTypeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_SEARCH_TYPE_ASC',
  DryRunsByCampaignIdMaxSearchTypeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_SEARCH_TYPE_DESC',
  DryRunsByCampaignIdMaxStatusAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_STATUS_ASC',
  DryRunsByCampaignIdMaxStatusDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_STATUS_DESC',
  DryRunsByCampaignIdMaxTargetDescriptionAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_TARGET_DESCRIPTION_ASC',
  DryRunsByCampaignIdMaxTargetDescriptionDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_TARGET_DESCRIPTION_DESC',
  DryRunsByCampaignIdMaxUpdatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_ASC',
  DryRunsByCampaignIdMaxUpdatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_DESC',
  DryRunsByCampaignIdMinCampaignIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_ASC',
  DryRunsByCampaignIdMinCampaignIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_DESC',
  DryRunsByCampaignIdMinCreatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_CREATED_TIME_ASC',
  DryRunsByCampaignIdMinCreatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_CREATED_TIME_DESC',
  DryRunsByCampaignIdMinDryRunGroupIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_DRY_RUN_GROUP_ID_ASC',
  DryRunsByCampaignIdMinDryRunGroupIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_DRY_RUN_GROUP_ID_DESC',
  DryRunsByCampaignIdMinIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_ID_ASC',
  DryRunsByCampaignIdMinIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_ID_DESC',
  DryRunsByCampaignIdMinLookAheadDaysAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_LOOK_AHEAD_DAYS_ASC',
  DryRunsByCampaignIdMinLookAheadDaysDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_LOOK_AHEAD_DAYS_DESC',
  DryRunsByCampaignIdMinMaxAllocationAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_MAX_ALLOCATION_ASC',
  DryRunsByCampaignIdMinMaxAllocationDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_MAX_ALLOCATION_DESC',
  DryRunsByCampaignIdMinPersonalisationQueryAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_PERSONALISATION_QUERY_ASC',
  DryRunsByCampaignIdMinPersonalisationQueryDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_PERSONALISATION_QUERY_DESC',
  DryRunsByCampaignIdMinProgressAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_PROGRESS_ASC',
  DryRunsByCampaignIdMinProgressDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_PROGRESS_DESC',
  DryRunsByCampaignIdMinSearchTypeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_SEARCH_TYPE_ASC',
  DryRunsByCampaignIdMinSearchTypeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_SEARCH_TYPE_DESC',
  DryRunsByCampaignIdMinStatusAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_STATUS_ASC',
  DryRunsByCampaignIdMinStatusDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_STATUS_DESC',
  DryRunsByCampaignIdMinTargetDescriptionAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_TARGET_DESCRIPTION_ASC',
  DryRunsByCampaignIdMinTargetDescriptionDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_TARGET_DESCRIPTION_DESC',
  DryRunsByCampaignIdMinUpdatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_ASC',
  DryRunsByCampaignIdMinUpdatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_DESC',
  DryRunsByCampaignIdStddevPopulationCampaignIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  DryRunsByCampaignIdStddevPopulationCampaignIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  DryRunsByCampaignIdStddevPopulationCreatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  DryRunsByCampaignIdStddevPopulationCreatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  DryRunsByCampaignIdStddevPopulationDryRunGroupIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_DRY_RUN_GROUP_ID_ASC',
  DryRunsByCampaignIdStddevPopulationDryRunGroupIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_DRY_RUN_GROUP_ID_DESC',
  DryRunsByCampaignIdStddevPopulationIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_ASC',
  DryRunsByCampaignIdStddevPopulationIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_DESC',
  DryRunsByCampaignIdStddevPopulationLookAheadDaysAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_LOOK_AHEAD_DAYS_ASC',
  DryRunsByCampaignIdStddevPopulationLookAheadDaysDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_LOOK_AHEAD_DAYS_DESC',
  DryRunsByCampaignIdStddevPopulationMaxAllocationAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_MAX_ALLOCATION_ASC',
  DryRunsByCampaignIdStddevPopulationMaxAllocationDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_MAX_ALLOCATION_DESC',
  DryRunsByCampaignIdStddevPopulationPersonalisationQueryAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_PERSONALISATION_QUERY_ASC',
  DryRunsByCampaignIdStddevPopulationPersonalisationQueryDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_PERSONALISATION_QUERY_DESC',
  DryRunsByCampaignIdStddevPopulationProgressAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_PROGRESS_ASC',
  DryRunsByCampaignIdStddevPopulationProgressDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_PROGRESS_DESC',
  DryRunsByCampaignIdStddevPopulationSearchTypeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_TYPE_ASC',
  DryRunsByCampaignIdStddevPopulationSearchTypeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEARCH_TYPE_DESC',
  DryRunsByCampaignIdStddevPopulationStatusAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_STATUS_ASC',
  DryRunsByCampaignIdStddevPopulationStatusDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_STATUS_DESC',
  DryRunsByCampaignIdStddevPopulationTargetDescriptionAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_TARGET_DESCRIPTION_ASC',
  DryRunsByCampaignIdStddevPopulationTargetDescriptionDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_TARGET_DESCRIPTION_DESC',
  DryRunsByCampaignIdStddevPopulationUpdatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  DryRunsByCampaignIdStddevPopulationUpdatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  DryRunsByCampaignIdStddevSampleCampaignIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  DryRunsByCampaignIdStddevSampleCampaignIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  DryRunsByCampaignIdStddevSampleCreatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  DryRunsByCampaignIdStddevSampleCreatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  DryRunsByCampaignIdStddevSampleDryRunGroupIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_DRY_RUN_GROUP_ID_ASC',
  DryRunsByCampaignIdStddevSampleDryRunGroupIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_DRY_RUN_GROUP_ID_DESC',
  DryRunsByCampaignIdStddevSampleIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_ASC',
  DryRunsByCampaignIdStddevSampleIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_DESC',
  DryRunsByCampaignIdStddevSampleLookAheadDaysAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LOOK_AHEAD_DAYS_ASC',
  DryRunsByCampaignIdStddevSampleLookAheadDaysDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LOOK_AHEAD_DAYS_DESC',
  DryRunsByCampaignIdStddevSampleMaxAllocationAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_MAX_ALLOCATION_ASC',
  DryRunsByCampaignIdStddevSampleMaxAllocationDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_MAX_ALLOCATION_DESC',
  DryRunsByCampaignIdStddevSamplePersonalisationQueryAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_PERSONALISATION_QUERY_ASC',
  DryRunsByCampaignIdStddevSamplePersonalisationQueryDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_PERSONALISATION_QUERY_DESC',
  DryRunsByCampaignIdStddevSampleProgressAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_PROGRESS_ASC',
  DryRunsByCampaignIdStddevSampleProgressDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_PROGRESS_DESC',
  DryRunsByCampaignIdStddevSampleSearchTypeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_TYPE_ASC',
  DryRunsByCampaignIdStddevSampleSearchTypeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEARCH_TYPE_DESC',
  DryRunsByCampaignIdStddevSampleStatusAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_STATUS_ASC',
  DryRunsByCampaignIdStddevSampleStatusDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_STATUS_DESC',
  DryRunsByCampaignIdStddevSampleTargetDescriptionAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_TARGET_DESCRIPTION_ASC',
  DryRunsByCampaignIdStddevSampleTargetDescriptionDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_TARGET_DESCRIPTION_DESC',
  DryRunsByCampaignIdStddevSampleUpdatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  DryRunsByCampaignIdStddevSampleUpdatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  DryRunsByCampaignIdSumCampaignIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_ASC',
  DryRunsByCampaignIdSumCampaignIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_DESC',
  DryRunsByCampaignIdSumCreatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_CREATED_TIME_ASC',
  DryRunsByCampaignIdSumCreatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_CREATED_TIME_DESC',
  DryRunsByCampaignIdSumDryRunGroupIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_DRY_RUN_GROUP_ID_ASC',
  DryRunsByCampaignIdSumDryRunGroupIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_DRY_RUN_GROUP_ID_DESC',
  DryRunsByCampaignIdSumIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_ID_ASC',
  DryRunsByCampaignIdSumIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_ID_DESC',
  DryRunsByCampaignIdSumLookAheadDaysAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_LOOK_AHEAD_DAYS_ASC',
  DryRunsByCampaignIdSumLookAheadDaysDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_LOOK_AHEAD_DAYS_DESC',
  DryRunsByCampaignIdSumMaxAllocationAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_MAX_ALLOCATION_ASC',
  DryRunsByCampaignIdSumMaxAllocationDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_MAX_ALLOCATION_DESC',
  DryRunsByCampaignIdSumPersonalisationQueryAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_PERSONALISATION_QUERY_ASC',
  DryRunsByCampaignIdSumPersonalisationQueryDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_PERSONALISATION_QUERY_DESC',
  DryRunsByCampaignIdSumProgressAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_PROGRESS_ASC',
  DryRunsByCampaignIdSumProgressDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_PROGRESS_DESC',
  DryRunsByCampaignIdSumSearchTypeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_SEARCH_TYPE_ASC',
  DryRunsByCampaignIdSumSearchTypeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_SEARCH_TYPE_DESC',
  DryRunsByCampaignIdSumStatusAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_STATUS_ASC',
  DryRunsByCampaignIdSumStatusDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_STATUS_DESC',
  DryRunsByCampaignIdSumTargetDescriptionAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_TARGET_DESCRIPTION_ASC',
  DryRunsByCampaignIdSumTargetDescriptionDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_TARGET_DESCRIPTION_DESC',
  DryRunsByCampaignIdSumUpdatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_ASC',
  DryRunsByCampaignIdSumUpdatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_DESC',
  DryRunsByCampaignIdVariancePopulationCampaignIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  DryRunsByCampaignIdVariancePopulationCampaignIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  DryRunsByCampaignIdVariancePopulationCreatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  DryRunsByCampaignIdVariancePopulationCreatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  DryRunsByCampaignIdVariancePopulationDryRunGroupIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_DRY_RUN_GROUP_ID_ASC',
  DryRunsByCampaignIdVariancePopulationDryRunGroupIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_DRY_RUN_GROUP_ID_DESC',
  DryRunsByCampaignIdVariancePopulationIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_ASC',
  DryRunsByCampaignIdVariancePopulationIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_DESC',
  DryRunsByCampaignIdVariancePopulationLookAheadDaysAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LOOK_AHEAD_DAYS_ASC',
  DryRunsByCampaignIdVariancePopulationLookAheadDaysDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LOOK_AHEAD_DAYS_DESC',
  DryRunsByCampaignIdVariancePopulationMaxAllocationAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_MAX_ALLOCATION_ASC',
  DryRunsByCampaignIdVariancePopulationMaxAllocationDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_MAX_ALLOCATION_DESC',
  DryRunsByCampaignIdVariancePopulationPersonalisationQueryAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_PERSONALISATION_QUERY_ASC',
  DryRunsByCampaignIdVariancePopulationPersonalisationQueryDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_PERSONALISATION_QUERY_DESC',
  DryRunsByCampaignIdVariancePopulationProgressAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_PROGRESS_ASC',
  DryRunsByCampaignIdVariancePopulationProgressDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_PROGRESS_DESC',
  DryRunsByCampaignIdVariancePopulationSearchTypeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_TYPE_ASC',
  DryRunsByCampaignIdVariancePopulationSearchTypeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEARCH_TYPE_DESC',
  DryRunsByCampaignIdVariancePopulationStatusAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_STATUS_ASC',
  DryRunsByCampaignIdVariancePopulationStatusDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_STATUS_DESC',
  DryRunsByCampaignIdVariancePopulationTargetDescriptionAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_TARGET_DESCRIPTION_ASC',
  DryRunsByCampaignIdVariancePopulationTargetDescriptionDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_TARGET_DESCRIPTION_DESC',
  DryRunsByCampaignIdVariancePopulationUpdatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  DryRunsByCampaignIdVariancePopulationUpdatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  DryRunsByCampaignIdVarianceSampleCampaignIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  DryRunsByCampaignIdVarianceSampleCampaignIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  DryRunsByCampaignIdVarianceSampleCreatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  DryRunsByCampaignIdVarianceSampleCreatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  DryRunsByCampaignIdVarianceSampleDryRunGroupIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_DRY_RUN_GROUP_ID_ASC',
  DryRunsByCampaignIdVarianceSampleDryRunGroupIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_DRY_RUN_GROUP_ID_DESC',
  DryRunsByCampaignIdVarianceSampleIdAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_ASC',
  DryRunsByCampaignIdVarianceSampleIdDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_DESC',
  DryRunsByCampaignIdVarianceSampleLookAheadDaysAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LOOK_AHEAD_DAYS_ASC',
  DryRunsByCampaignIdVarianceSampleLookAheadDaysDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LOOK_AHEAD_DAYS_DESC',
  DryRunsByCampaignIdVarianceSampleMaxAllocationAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_MAX_ALLOCATION_ASC',
  DryRunsByCampaignIdVarianceSampleMaxAllocationDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_MAX_ALLOCATION_DESC',
  DryRunsByCampaignIdVarianceSamplePersonalisationQueryAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_PERSONALISATION_QUERY_ASC',
  DryRunsByCampaignIdVarianceSamplePersonalisationQueryDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_PERSONALISATION_QUERY_DESC',
  DryRunsByCampaignIdVarianceSampleProgressAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_PROGRESS_ASC',
  DryRunsByCampaignIdVarianceSampleProgressDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_PROGRESS_DESC',
  DryRunsByCampaignIdVarianceSampleSearchTypeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_TYPE_ASC',
  DryRunsByCampaignIdVarianceSampleSearchTypeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEARCH_TYPE_DESC',
  DryRunsByCampaignIdVarianceSampleStatusAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_STATUS_ASC',
  DryRunsByCampaignIdVarianceSampleStatusDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_STATUS_DESC',
  DryRunsByCampaignIdVarianceSampleTargetDescriptionAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_TARGET_DESCRIPTION_ASC',
  DryRunsByCampaignIdVarianceSampleTargetDescriptionDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_TARGET_DESCRIPTION_DESC',
  DryRunsByCampaignIdVarianceSampleUpdatedTimeAsc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  DryRunsByCampaignIdVarianceSampleUpdatedTimeDesc = 'DRY_RUNS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EmailMessagesByCampaignIdAverageAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignIdAverageAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignIdAverageCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignIdAverageCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignIdAverageCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignIdAverageCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignIdAverageCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdAverageCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdAverageCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_COMPANY_ID_ASC',
  EmailMessagesByCampaignIdAverageCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_COMPANY_ID_DESC',
  EmailMessagesByCampaignIdAverageContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CONTACT_ID_ASC',
  EmailMessagesByCampaignIdAverageContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CONTACT_ID_DESC',
  EmailMessagesByCampaignIdAverageContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CONTENT_ASC',
  EmailMessagesByCampaignIdAverageContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CONTENT_DESC',
  EmailMessagesByCampaignIdAverageContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CONTENT_HTML_ASC',
  EmailMessagesByCampaignIdAverageContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CONTENT_HTML_DESC',
  EmailMessagesByCampaignIdAverageCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_ASC',
  EmailMessagesByCampaignIdAverageCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_DESC',
  EmailMessagesByCampaignIdAverageEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_EMAIL_ID_ASC',
  EmailMessagesByCampaignIdAverageEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_EMAIL_ID_DESC',
  EmailMessagesByCampaignIdAverageForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignIdAverageForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignIdAverageForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdAverageForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdAverageGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignIdAverageGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignIdAverageHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdAverageHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdAverageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_ID_ASC',
  EmailMessagesByCampaignIdAverageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_ID_DESC',
  EmailMessagesByCampaignIdAverageIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_INTENT_ASC',
  EmailMessagesByCampaignIdAverageIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_INTENT_DESC',
  EmailMessagesByCampaignIdAverageIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_IS_LAWYER_ASC',
  EmailMessagesByCampaignIdAverageIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_IS_LAWYER_DESC',
  EmailMessagesByCampaignIdAverageIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_IS_REVIEWED_ASC',
  EmailMessagesByCampaignIdAverageIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_IS_REVIEWED_DESC',
  EmailMessagesByCampaignIdAverageLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_LAWYER_ID_ASC',
  EmailMessagesByCampaignIdAverageLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_LAWYER_ID_DESC',
  EmailMessagesByCampaignIdAverageLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignIdAverageLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignIdAverageNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignIdAverageNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignIdAverageOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_ORDER_INDEX_ASC',
  EmailMessagesByCampaignIdAverageOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_ORDER_INDEX_DESC',
  EmailMessagesByCampaignIdAveragePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignIdAveragePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignIdAverageRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignIdAverageRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignIdAverageRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REPLIED_TIME_ASC',
  EmailMessagesByCampaignIdAverageRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REPLIED_TIME_DESC',
  EmailMessagesByCampaignIdAverageRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdAverageRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdAverageReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignIdAverageReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignIdAverageSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignIdAverageSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignIdAverageSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_SENT_TIME_ASC',
  EmailMessagesByCampaignIdAverageSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_SENT_TIME_DESC',
  EmailMessagesByCampaignIdAverageSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdAverageSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdAverageSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_SUBJECT_ASC',
  EmailMessagesByCampaignIdAverageSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_SUBJECT_DESC',
  EmailMessagesByCampaignIdAverageTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignIdAverageTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignIdAverageUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailMessagesByCampaignIdAverageUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailMessagesByCampaignIdCountAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_COUNT_ASC',
  EmailMessagesByCampaignIdCountDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_COUNT_DESC',
  EmailMessagesByCampaignIdDistinctCountAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignIdDistinctCountAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignIdDistinctCountCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignIdDistinctCountCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignIdDistinctCountCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignIdDistinctCountCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignIdDistinctCountCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdDistinctCountCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdDistinctCountCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  EmailMessagesByCampaignIdDistinctCountCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  EmailMessagesByCampaignIdDistinctCountContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  EmailMessagesByCampaignIdDistinctCountContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  EmailMessagesByCampaignIdDistinctCountContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTENT_ASC',
  EmailMessagesByCampaignIdDistinctCountContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTENT_DESC',
  EmailMessagesByCampaignIdDistinctCountContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTENT_HTML_ASC',
  EmailMessagesByCampaignIdDistinctCountContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTENT_HTML_DESC',
  EmailMessagesByCampaignIdDistinctCountCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailMessagesByCampaignIdDistinctCountCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailMessagesByCampaignIdDistinctCountEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailMessagesByCampaignIdDistinctCountEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailMessagesByCampaignIdDistinctCountForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignIdDistinctCountForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignIdDistinctCountForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdDistinctCountForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdDistinctCountGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignIdDistinctCountGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignIdDistinctCountHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdDistinctCountHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdDistinctCountIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_ASC',
  EmailMessagesByCampaignIdDistinctCountIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_DESC',
  EmailMessagesByCampaignIdDistinctCountIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_INTENT_ASC',
  EmailMessagesByCampaignIdDistinctCountIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_INTENT_DESC',
  EmailMessagesByCampaignIdDistinctCountIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  EmailMessagesByCampaignIdDistinctCountIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  EmailMessagesByCampaignIdDistinctCountIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_IS_REVIEWED_ASC',
  EmailMessagesByCampaignIdDistinctCountIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_IS_REVIEWED_DESC',
  EmailMessagesByCampaignIdDistinctCountLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  EmailMessagesByCampaignIdDistinctCountLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  EmailMessagesByCampaignIdDistinctCountLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignIdDistinctCountLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignIdDistinctCountNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignIdDistinctCountNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignIdDistinctCountOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ORDER_INDEX_ASC',
  EmailMessagesByCampaignIdDistinctCountOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ORDER_INDEX_DESC',
  EmailMessagesByCampaignIdDistinctCountPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignIdDistinctCountPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignIdDistinctCountRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignIdDistinctCountRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignIdDistinctCountRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REPLIED_TIME_ASC',
  EmailMessagesByCampaignIdDistinctCountRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REPLIED_TIME_DESC',
  EmailMessagesByCampaignIdDistinctCountRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdDistinctCountRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdDistinctCountReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignIdDistinctCountReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignIdDistinctCountSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignIdDistinctCountSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignIdDistinctCountSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SENT_TIME_ASC',
  EmailMessagesByCampaignIdDistinctCountSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SENT_TIME_DESC',
  EmailMessagesByCampaignIdDistinctCountSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdDistinctCountSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdDistinctCountSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SUBJECT_ASC',
  EmailMessagesByCampaignIdDistinctCountSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SUBJECT_DESC',
  EmailMessagesByCampaignIdDistinctCountTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignIdDistinctCountTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignIdDistinctCountUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailMessagesByCampaignIdDistinctCountUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailMessagesByCampaignIdMaxAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignIdMaxAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignIdMaxCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignIdMaxCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignIdMaxCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignIdMaxCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignIdMaxCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdMaxCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdMaxCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_COMPANY_ID_ASC',
  EmailMessagesByCampaignIdMaxCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_COMPANY_ID_DESC',
  EmailMessagesByCampaignIdMaxContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CONTACT_ID_ASC',
  EmailMessagesByCampaignIdMaxContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CONTACT_ID_DESC',
  EmailMessagesByCampaignIdMaxContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CONTENT_ASC',
  EmailMessagesByCampaignIdMaxContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CONTENT_DESC',
  EmailMessagesByCampaignIdMaxContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CONTENT_HTML_ASC',
  EmailMessagesByCampaignIdMaxContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CONTENT_HTML_DESC',
  EmailMessagesByCampaignIdMaxCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CREATED_TIME_ASC',
  EmailMessagesByCampaignIdMaxCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_CREATED_TIME_DESC',
  EmailMessagesByCampaignIdMaxEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_EMAIL_ID_ASC',
  EmailMessagesByCampaignIdMaxEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_EMAIL_ID_DESC',
  EmailMessagesByCampaignIdMaxForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignIdMaxForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignIdMaxForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdMaxForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdMaxGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignIdMaxGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignIdMaxHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdMaxHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdMaxIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_ID_ASC',
  EmailMessagesByCampaignIdMaxIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_ID_DESC',
  EmailMessagesByCampaignIdMaxIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_INTENT_ASC',
  EmailMessagesByCampaignIdMaxIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_INTENT_DESC',
  EmailMessagesByCampaignIdMaxIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_IS_LAWYER_ASC',
  EmailMessagesByCampaignIdMaxIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_IS_LAWYER_DESC',
  EmailMessagesByCampaignIdMaxIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_IS_REVIEWED_ASC',
  EmailMessagesByCampaignIdMaxIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_IS_REVIEWED_DESC',
  EmailMessagesByCampaignIdMaxLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_LAWYER_ID_ASC',
  EmailMessagesByCampaignIdMaxLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_LAWYER_ID_DESC',
  EmailMessagesByCampaignIdMaxLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignIdMaxLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignIdMaxNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignIdMaxNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignIdMaxOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_ORDER_INDEX_ASC',
  EmailMessagesByCampaignIdMaxOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_ORDER_INDEX_DESC',
  EmailMessagesByCampaignIdMaxPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignIdMaxPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignIdMaxRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignIdMaxRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignIdMaxRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_REPLIED_TIME_ASC',
  EmailMessagesByCampaignIdMaxRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_REPLIED_TIME_DESC',
  EmailMessagesByCampaignIdMaxRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdMaxRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdMaxReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignIdMaxReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignIdMaxSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignIdMaxSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignIdMaxSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_SENT_TIME_ASC',
  EmailMessagesByCampaignIdMaxSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_SENT_TIME_DESC',
  EmailMessagesByCampaignIdMaxSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdMaxSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdMaxSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_SUBJECT_ASC',
  EmailMessagesByCampaignIdMaxSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_SUBJECT_DESC',
  EmailMessagesByCampaignIdMaxTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignIdMaxTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignIdMaxUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_ASC',
  EmailMessagesByCampaignIdMaxUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_DESC',
  EmailMessagesByCampaignIdMinAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignIdMinAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignIdMinCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignIdMinCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignIdMinCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignIdMinCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignIdMinCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdMinCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdMinCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_COMPANY_ID_ASC',
  EmailMessagesByCampaignIdMinCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_COMPANY_ID_DESC',
  EmailMessagesByCampaignIdMinContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CONTACT_ID_ASC',
  EmailMessagesByCampaignIdMinContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CONTACT_ID_DESC',
  EmailMessagesByCampaignIdMinContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CONTENT_ASC',
  EmailMessagesByCampaignIdMinContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CONTENT_DESC',
  EmailMessagesByCampaignIdMinContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CONTENT_HTML_ASC',
  EmailMessagesByCampaignIdMinContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CONTENT_HTML_DESC',
  EmailMessagesByCampaignIdMinCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CREATED_TIME_ASC',
  EmailMessagesByCampaignIdMinCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_CREATED_TIME_DESC',
  EmailMessagesByCampaignIdMinEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_EMAIL_ID_ASC',
  EmailMessagesByCampaignIdMinEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_EMAIL_ID_DESC',
  EmailMessagesByCampaignIdMinForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignIdMinForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignIdMinForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdMinForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdMinGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignIdMinGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignIdMinHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdMinHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdMinIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_ID_ASC',
  EmailMessagesByCampaignIdMinIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_ID_DESC',
  EmailMessagesByCampaignIdMinIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_INTENT_ASC',
  EmailMessagesByCampaignIdMinIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_INTENT_DESC',
  EmailMessagesByCampaignIdMinIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_IS_LAWYER_ASC',
  EmailMessagesByCampaignIdMinIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_IS_LAWYER_DESC',
  EmailMessagesByCampaignIdMinIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_IS_REVIEWED_ASC',
  EmailMessagesByCampaignIdMinIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_IS_REVIEWED_DESC',
  EmailMessagesByCampaignIdMinLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_LAWYER_ID_ASC',
  EmailMessagesByCampaignIdMinLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_LAWYER_ID_DESC',
  EmailMessagesByCampaignIdMinLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignIdMinLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignIdMinNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignIdMinNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignIdMinOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_ORDER_INDEX_ASC',
  EmailMessagesByCampaignIdMinOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_ORDER_INDEX_DESC',
  EmailMessagesByCampaignIdMinPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignIdMinPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignIdMinRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignIdMinRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignIdMinRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_REPLIED_TIME_ASC',
  EmailMessagesByCampaignIdMinRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_REPLIED_TIME_DESC',
  EmailMessagesByCampaignIdMinRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdMinRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdMinReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignIdMinReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignIdMinSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignIdMinSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignIdMinSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_SENT_TIME_ASC',
  EmailMessagesByCampaignIdMinSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_SENT_TIME_DESC',
  EmailMessagesByCampaignIdMinSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdMinSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdMinSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_SUBJECT_ASC',
  EmailMessagesByCampaignIdMinSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_SUBJECT_DESC',
  EmailMessagesByCampaignIdMinTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignIdMinTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignIdMinUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_ASC',
  EmailMessagesByCampaignIdMinUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_DESC',
  EmailMessagesByCampaignIdStddevPopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignIdStddevPopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignIdStddevPopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignIdStddevPopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignIdStddevPopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignIdStddevPopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignIdStddevPopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdStddevPopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdStddevPopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByCampaignIdStddevPopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByCampaignIdStddevPopulationContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByCampaignIdStddevPopulationContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByCampaignIdStddevPopulationContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTENT_ASC',
  EmailMessagesByCampaignIdStddevPopulationContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTENT_DESC',
  EmailMessagesByCampaignIdStddevPopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByCampaignIdStddevPopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByCampaignIdStddevPopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByCampaignIdStddevPopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByCampaignIdStddevPopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByCampaignIdStddevPopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByCampaignIdStddevPopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignIdStddevPopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignIdStddevPopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdStddevPopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdStddevPopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignIdStddevPopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignIdStddevPopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdStddevPopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdStddevPopulationIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_ASC',
  EmailMessagesByCampaignIdStddevPopulationIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_DESC',
  EmailMessagesByCampaignIdStddevPopulationIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_INTENT_ASC',
  EmailMessagesByCampaignIdStddevPopulationIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_INTENT_DESC',
  EmailMessagesByCampaignIdStddevPopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByCampaignIdStddevPopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByCampaignIdStddevPopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByCampaignIdStddevPopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByCampaignIdStddevPopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByCampaignIdStddevPopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByCampaignIdStddevPopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignIdStddevPopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignIdStddevPopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignIdStddevPopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignIdStddevPopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByCampaignIdStddevPopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByCampaignIdStddevPopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignIdStddevPopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignIdStddevPopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignIdStddevPopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignIdStddevPopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByCampaignIdStddevPopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByCampaignIdStddevPopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdStddevPopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdStddevPopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignIdStddevPopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignIdStddevPopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignIdStddevPopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignIdStddevPopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SENT_TIME_ASC',
  EmailMessagesByCampaignIdStddevPopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SENT_TIME_DESC',
  EmailMessagesByCampaignIdStddevPopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdStddevPopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdStddevPopulationSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SUBJECT_ASC',
  EmailMessagesByCampaignIdStddevPopulationSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SUBJECT_DESC',
  EmailMessagesByCampaignIdStddevPopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignIdStddevPopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignIdStddevPopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByCampaignIdStddevPopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByCampaignIdStddevSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignIdStddevSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignIdStddevSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignIdStddevSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignIdStddevSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignIdStddevSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignIdStddevSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdStddevSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdStddevSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByCampaignIdStddevSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByCampaignIdStddevSampleContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByCampaignIdStddevSampleContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByCampaignIdStddevSampleContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTENT_ASC',
  EmailMessagesByCampaignIdStddevSampleContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTENT_DESC',
  EmailMessagesByCampaignIdStddevSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByCampaignIdStddevSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByCampaignIdStddevSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByCampaignIdStddevSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByCampaignIdStddevSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByCampaignIdStddevSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByCampaignIdStddevSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignIdStddevSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignIdStddevSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdStddevSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdStddevSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignIdStddevSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignIdStddevSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdStddevSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdStddevSampleIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_ASC',
  EmailMessagesByCampaignIdStddevSampleIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_DESC',
  EmailMessagesByCampaignIdStddevSampleIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_INTENT_ASC',
  EmailMessagesByCampaignIdStddevSampleIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_INTENT_DESC',
  EmailMessagesByCampaignIdStddevSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByCampaignIdStddevSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByCampaignIdStddevSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByCampaignIdStddevSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByCampaignIdStddevSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByCampaignIdStddevSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByCampaignIdStddevSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignIdStddevSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignIdStddevSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignIdStddevSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignIdStddevSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByCampaignIdStddevSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByCampaignIdStddevSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignIdStddevSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignIdStddevSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignIdStddevSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignIdStddevSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByCampaignIdStddevSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByCampaignIdStddevSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdStddevSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdStddevSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignIdStddevSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignIdStddevSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignIdStddevSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignIdStddevSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByCampaignIdStddevSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByCampaignIdStddevSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdStddevSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdStddevSampleSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SUBJECT_ASC',
  EmailMessagesByCampaignIdStddevSampleSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SUBJECT_DESC',
  EmailMessagesByCampaignIdStddevSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignIdStddevSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignIdStddevSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByCampaignIdStddevSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailMessagesByCampaignIdSumAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignIdSumAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignIdSumCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignIdSumCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignIdSumCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignIdSumCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignIdSumCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdSumCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdSumCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_COMPANY_ID_ASC',
  EmailMessagesByCampaignIdSumCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_COMPANY_ID_DESC',
  EmailMessagesByCampaignIdSumContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CONTACT_ID_ASC',
  EmailMessagesByCampaignIdSumContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CONTACT_ID_DESC',
  EmailMessagesByCampaignIdSumContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CONTENT_ASC',
  EmailMessagesByCampaignIdSumContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CONTENT_DESC',
  EmailMessagesByCampaignIdSumContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CONTENT_HTML_ASC',
  EmailMessagesByCampaignIdSumContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CONTENT_HTML_DESC',
  EmailMessagesByCampaignIdSumCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CREATED_TIME_ASC',
  EmailMessagesByCampaignIdSumCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_CREATED_TIME_DESC',
  EmailMessagesByCampaignIdSumEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_EMAIL_ID_ASC',
  EmailMessagesByCampaignIdSumEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_EMAIL_ID_DESC',
  EmailMessagesByCampaignIdSumForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignIdSumForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignIdSumForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdSumForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdSumGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignIdSumGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignIdSumHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdSumHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdSumIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_ID_ASC',
  EmailMessagesByCampaignIdSumIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_ID_DESC',
  EmailMessagesByCampaignIdSumIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_INTENT_ASC',
  EmailMessagesByCampaignIdSumIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_INTENT_DESC',
  EmailMessagesByCampaignIdSumIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_IS_LAWYER_ASC',
  EmailMessagesByCampaignIdSumIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_IS_LAWYER_DESC',
  EmailMessagesByCampaignIdSumIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_IS_REVIEWED_ASC',
  EmailMessagesByCampaignIdSumIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_IS_REVIEWED_DESC',
  EmailMessagesByCampaignIdSumLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_LAWYER_ID_ASC',
  EmailMessagesByCampaignIdSumLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_LAWYER_ID_DESC',
  EmailMessagesByCampaignIdSumLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignIdSumLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignIdSumNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignIdSumNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignIdSumOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_ORDER_INDEX_ASC',
  EmailMessagesByCampaignIdSumOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_ORDER_INDEX_DESC',
  EmailMessagesByCampaignIdSumPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignIdSumPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignIdSumRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignIdSumRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignIdSumRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_REPLIED_TIME_ASC',
  EmailMessagesByCampaignIdSumRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_REPLIED_TIME_DESC',
  EmailMessagesByCampaignIdSumRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdSumRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdSumReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignIdSumReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignIdSumSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignIdSumSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignIdSumSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_SENT_TIME_ASC',
  EmailMessagesByCampaignIdSumSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_SENT_TIME_DESC',
  EmailMessagesByCampaignIdSumSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdSumSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdSumSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_SUBJECT_ASC',
  EmailMessagesByCampaignIdSumSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_SUBJECT_DESC',
  EmailMessagesByCampaignIdSumTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignIdSumTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignIdSumUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_ASC',
  EmailMessagesByCampaignIdSumUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_DESC',
  EmailMessagesByCampaignIdVariancePopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignIdVariancePopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignIdVariancePopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignIdVariancePopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignIdVariancePopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignIdVariancePopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignIdVariancePopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdVariancePopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdVariancePopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByCampaignIdVariancePopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByCampaignIdVariancePopulationContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByCampaignIdVariancePopulationContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByCampaignIdVariancePopulationContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTENT_ASC',
  EmailMessagesByCampaignIdVariancePopulationContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTENT_DESC',
  EmailMessagesByCampaignIdVariancePopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByCampaignIdVariancePopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByCampaignIdVariancePopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByCampaignIdVariancePopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByCampaignIdVariancePopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByCampaignIdVariancePopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByCampaignIdVariancePopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignIdVariancePopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignIdVariancePopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdVariancePopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdVariancePopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignIdVariancePopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignIdVariancePopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdVariancePopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdVariancePopulationIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_ASC',
  EmailMessagesByCampaignIdVariancePopulationIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_DESC',
  EmailMessagesByCampaignIdVariancePopulationIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_INTENT_ASC',
  EmailMessagesByCampaignIdVariancePopulationIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_INTENT_DESC',
  EmailMessagesByCampaignIdVariancePopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByCampaignIdVariancePopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByCampaignIdVariancePopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByCampaignIdVariancePopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByCampaignIdVariancePopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByCampaignIdVariancePopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByCampaignIdVariancePopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignIdVariancePopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignIdVariancePopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignIdVariancePopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignIdVariancePopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByCampaignIdVariancePopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByCampaignIdVariancePopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignIdVariancePopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignIdVariancePopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignIdVariancePopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignIdVariancePopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByCampaignIdVariancePopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByCampaignIdVariancePopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdVariancePopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdVariancePopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignIdVariancePopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignIdVariancePopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignIdVariancePopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignIdVariancePopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SENT_TIME_ASC',
  EmailMessagesByCampaignIdVariancePopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SENT_TIME_DESC',
  EmailMessagesByCampaignIdVariancePopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdVariancePopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdVariancePopulationSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SUBJECT_ASC',
  EmailMessagesByCampaignIdVariancePopulationSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SUBJECT_DESC',
  EmailMessagesByCampaignIdVariancePopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignIdVariancePopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignIdVariancePopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByCampaignIdVariancePopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByCampaignIdVarianceSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByCampaignIdVarianceSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByCampaignIdVarianceSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByCampaignIdVarianceSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByCampaignIdVarianceSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCampaignIdVarianceSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCampaignIdVarianceSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdVarianceSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdVarianceSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByCampaignIdVarianceSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByCampaignIdVarianceSampleContactIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByCampaignIdVarianceSampleContactIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByCampaignIdVarianceSampleContentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  EmailMessagesByCampaignIdVarianceSampleContentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  EmailMessagesByCampaignIdVarianceSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByCampaignIdVarianceSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByCampaignIdVarianceSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByCampaignIdVarianceSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByCampaignIdVarianceSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByCampaignIdVarianceSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByCampaignIdVarianceSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCampaignIdVarianceSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCampaignIdVarianceSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdVarianceSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdVarianceSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByCampaignIdVarianceSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByCampaignIdVarianceSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdVarianceSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdVarianceSampleIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailMessagesByCampaignIdVarianceSampleIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailMessagesByCampaignIdVarianceSampleIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_INTENT_ASC',
  EmailMessagesByCampaignIdVarianceSampleIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_INTENT_DESC',
  EmailMessagesByCampaignIdVarianceSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByCampaignIdVarianceSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByCampaignIdVarianceSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByCampaignIdVarianceSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByCampaignIdVarianceSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByCampaignIdVarianceSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByCampaignIdVarianceSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCampaignIdVarianceSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCampaignIdVarianceSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCampaignIdVarianceSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCampaignIdVarianceSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByCampaignIdVarianceSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByCampaignIdVarianceSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCampaignIdVarianceSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCampaignIdVarianceSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByCampaignIdVarianceSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByCampaignIdVarianceSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByCampaignIdVarianceSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByCampaignIdVarianceSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCampaignIdVarianceSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCampaignIdVarianceSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByCampaignIdVarianceSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByCampaignIdVarianceSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCampaignIdVarianceSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCampaignIdVarianceSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByCampaignIdVarianceSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByCampaignIdVarianceSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCampaignIdVarianceSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCampaignIdVarianceSampleSubjectAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SUBJECT_ASC',
  EmailMessagesByCampaignIdVarianceSampleSubjectDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SUBJECT_DESC',
  EmailMessagesByCampaignIdVarianceSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByCampaignIdVarianceSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByCampaignIdVarianceSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByCampaignIdVarianceSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPersonalisedAsc = 'IS_PERSONALISED_ASC',
  IsPersonalisedDesc = 'IS_PERSONALISED_DESC',
  IsSalesFunnelCampaignAsc = 'IS_SALES_FUNNEL_CAMPAIGN_ASC',
  IsSalesFunnelCampaignDesc = 'IS_SALES_FUNNEL_CAMPAIGN_DESC',
  IsTestModeAsc = 'IS_TEST_MODE_ASC',
  IsTestModeDesc = 'IS_TEST_MODE_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  LawyerViewedTimeAsc = 'LAWYER_VIEWED_TIME_ASC',
  LawyerViewedTimeDesc = 'LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdAverageUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdAverageUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdCountAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_COUNT_ASC',
  LinkedinScheduledMessagesByCampaignIdCountDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_COUNT_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdDistinctCountUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdMaxUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdMaxUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdMinCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdMinCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdMinCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdMinCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdMinCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdMinCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdMinContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdMinContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdMinContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdMinContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdMinCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdMinCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdMinForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdMinForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdMinIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdMinIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdMinIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdMinIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdMinIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignIdMinIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignIdMinIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignIdMinIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignIdMinIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignIdMinIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignIdMinLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdMinLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdMinLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdMinLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdMinRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdMinRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdMinReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdMinReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdMinReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdMinReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdMinSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignIdMinSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignIdMinStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdMinStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdMinUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdMinUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevPopulationUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdStddevSampleUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdSumCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdSumCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdSumCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdSumCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdSumCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdSumCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdSumContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdSumContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdSumContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdSumContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdSumCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdSumCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdSumForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdSumForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdSumIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdSumIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdSumIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdSumIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdSumIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignIdSumIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignIdSumIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignIdSumIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignIdSumIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignIdSumIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignIdSumLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdSumLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdSumLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdSumLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdSumRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdSumRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdSumReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdSumReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdSumReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdSumReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdSumSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignIdSumSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignIdSumStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdSumStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdSumUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdSumUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdVariancePopulationUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_INTENT_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_INTENT_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEND_AT_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SEND_AT_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_STATUS_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_STATUS_DESC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCampaignIdVarianceSampleUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdAverageCampaignIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_ASC',
  LinkedinTemplatesByCampaignIdAverageCampaignIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_DESC',
  LinkedinTemplatesByCampaignIdAverageContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdAverageContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdAverageConversionScoreAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CONVERSION_SCORE_ASC',
  LinkedinTemplatesByCampaignIdAverageConversionScoreDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CONVERSION_SCORE_DESC',
  LinkedinTemplatesByCampaignIdAverageCreatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdAverageCreatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdAverageIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_ID_ASC',
  LinkedinTemplatesByCampaignIdAverageIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_ID_DESC',
  LinkedinTemplatesByCampaignIdAverageLanguageAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_LANGUAGE_ASC',
  LinkedinTemplatesByCampaignIdAverageLanguageDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_LANGUAGE_DESC',
  LinkedinTemplatesByCampaignIdAverageNameAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_NAME_ASC',
  LinkedinTemplatesByCampaignIdAverageNameDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_NAME_DESC',
  LinkedinTemplatesByCampaignIdAverageOrderIndexAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_ORDER_INDEX_ASC',
  LinkedinTemplatesByCampaignIdAverageOrderIndexDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_ORDER_INDEX_DESC',
  LinkedinTemplatesByCampaignIdAverageRevisedContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_REVISED_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdAverageRevisedContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_REVISED_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdAverageStatusAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_STATUS_ASC',
  LinkedinTemplatesByCampaignIdAverageStatusDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_STATUS_DESC',
  LinkedinTemplatesByCampaignIdAverageUpdatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdAverageUpdatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdCountAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_COUNT_ASC',
  LinkedinTemplatesByCampaignIdCountDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_COUNT_DESC',
  LinkedinTemplatesByCampaignIdDistinctCountCampaignIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  LinkedinTemplatesByCampaignIdDistinctCountCampaignIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  LinkedinTemplatesByCampaignIdDistinctCountContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdDistinctCountContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdDistinctCountConversionScoreAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONVERSION_SCORE_ASC',
  LinkedinTemplatesByCampaignIdDistinctCountConversionScoreDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONVERSION_SCORE_DESC',
  LinkedinTemplatesByCampaignIdDistinctCountCreatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdDistinctCountCreatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdDistinctCountIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_ASC',
  LinkedinTemplatesByCampaignIdDistinctCountIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_DESC',
  LinkedinTemplatesByCampaignIdDistinctCountLanguageAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LANGUAGE_ASC',
  LinkedinTemplatesByCampaignIdDistinctCountLanguageDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LANGUAGE_DESC',
  LinkedinTemplatesByCampaignIdDistinctCountNameAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_NAME_ASC',
  LinkedinTemplatesByCampaignIdDistinctCountNameDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_NAME_DESC',
  LinkedinTemplatesByCampaignIdDistinctCountOrderIndexAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ORDER_INDEX_ASC',
  LinkedinTemplatesByCampaignIdDistinctCountOrderIndexDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ORDER_INDEX_DESC',
  LinkedinTemplatesByCampaignIdDistinctCountRevisedContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REVISED_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdDistinctCountRevisedContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REVISED_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdDistinctCountStatusAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_STATUS_ASC',
  LinkedinTemplatesByCampaignIdDistinctCountStatusDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_STATUS_DESC',
  LinkedinTemplatesByCampaignIdDistinctCountUpdatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdDistinctCountUpdatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdMaxCampaignIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_ASC',
  LinkedinTemplatesByCampaignIdMaxCampaignIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_DESC',
  LinkedinTemplatesByCampaignIdMaxContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdMaxContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdMaxConversionScoreAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_CONVERSION_SCORE_ASC',
  LinkedinTemplatesByCampaignIdMaxConversionScoreDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_CONVERSION_SCORE_DESC',
  LinkedinTemplatesByCampaignIdMaxCreatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_CREATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdMaxCreatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_CREATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdMaxIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_ID_ASC',
  LinkedinTemplatesByCampaignIdMaxIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_ID_DESC',
  LinkedinTemplatesByCampaignIdMaxLanguageAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_LANGUAGE_ASC',
  LinkedinTemplatesByCampaignIdMaxLanguageDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_LANGUAGE_DESC',
  LinkedinTemplatesByCampaignIdMaxNameAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_NAME_ASC',
  LinkedinTemplatesByCampaignIdMaxNameDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_NAME_DESC',
  LinkedinTemplatesByCampaignIdMaxOrderIndexAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_ORDER_INDEX_ASC',
  LinkedinTemplatesByCampaignIdMaxOrderIndexDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_ORDER_INDEX_DESC',
  LinkedinTemplatesByCampaignIdMaxRevisedContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_REVISED_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdMaxRevisedContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_REVISED_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdMaxStatusAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_STATUS_ASC',
  LinkedinTemplatesByCampaignIdMaxStatusDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_STATUS_DESC',
  LinkedinTemplatesByCampaignIdMaxUpdatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdMaxUpdatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdMinCampaignIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_ASC',
  LinkedinTemplatesByCampaignIdMinCampaignIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_DESC',
  LinkedinTemplatesByCampaignIdMinContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdMinContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdMinConversionScoreAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_CONVERSION_SCORE_ASC',
  LinkedinTemplatesByCampaignIdMinConversionScoreDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_CONVERSION_SCORE_DESC',
  LinkedinTemplatesByCampaignIdMinCreatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_CREATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdMinCreatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_CREATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdMinIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_ID_ASC',
  LinkedinTemplatesByCampaignIdMinIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_ID_DESC',
  LinkedinTemplatesByCampaignIdMinLanguageAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_LANGUAGE_ASC',
  LinkedinTemplatesByCampaignIdMinLanguageDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_LANGUAGE_DESC',
  LinkedinTemplatesByCampaignIdMinNameAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_NAME_ASC',
  LinkedinTemplatesByCampaignIdMinNameDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_NAME_DESC',
  LinkedinTemplatesByCampaignIdMinOrderIndexAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_ORDER_INDEX_ASC',
  LinkedinTemplatesByCampaignIdMinOrderIndexDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_ORDER_INDEX_DESC',
  LinkedinTemplatesByCampaignIdMinRevisedContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_REVISED_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdMinRevisedContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_REVISED_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdMinStatusAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_STATUS_ASC',
  LinkedinTemplatesByCampaignIdMinStatusDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_STATUS_DESC',
  LinkedinTemplatesByCampaignIdMinUpdatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdMinUpdatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdStddevPopulationCampaignIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  LinkedinTemplatesByCampaignIdStddevPopulationCampaignIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  LinkedinTemplatesByCampaignIdStddevPopulationContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdStddevPopulationContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdStddevPopulationConversionScoreAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONVERSION_SCORE_ASC',
  LinkedinTemplatesByCampaignIdStddevPopulationConversionScoreDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONVERSION_SCORE_DESC',
  LinkedinTemplatesByCampaignIdStddevPopulationCreatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdStddevPopulationCreatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdStddevPopulationIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_ASC',
  LinkedinTemplatesByCampaignIdStddevPopulationIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_DESC',
  LinkedinTemplatesByCampaignIdStddevPopulationLanguageAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LANGUAGE_ASC',
  LinkedinTemplatesByCampaignIdStddevPopulationLanguageDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LANGUAGE_DESC',
  LinkedinTemplatesByCampaignIdStddevPopulationNameAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_NAME_ASC',
  LinkedinTemplatesByCampaignIdStddevPopulationNameDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_NAME_DESC',
  LinkedinTemplatesByCampaignIdStddevPopulationOrderIndexAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ORDER_INDEX_ASC',
  LinkedinTemplatesByCampaignIdStddevPopulationOrderIndexDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ORDER_INDEX_DESC',
  LinkedinTemplatesByCampaignIdStddevPopulationRevisedContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REVISED_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdStddevPopulationRevisedContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REVISED_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdStddevPopulationStatusAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_STATUS_ASC',
  LinkedinTemplatesByCampaignIdStddevPopulationStatusDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_STATUS_DESC',
  LinkedinTemplatesByCampaignIdStddevPopulationUpdatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdStddevPopulationUpdatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdStddevSampleCampaignIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  LinkedinTemplatesByCampaignIdStddevSampleCampaignIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  LinkedinTemplatesByCampaignIdStddevSampleContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdStddevSampleContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdStddevSampleConversionScoreAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONVERSION_SCORE_ASC',
  LinkedinTemplatesByCampaignIdStddevSampleConversionScoreDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONVERSION_SCORE_DESC',
  LinkedinTemplatesByCampaignIdStddevSampleCreatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdStddevSampleCreatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdStddevSampleIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_ASC',
  LinkedinTemplatesByCampaignIdStddevSampleIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_DESC',
  LinkedinTemplatesByCampaignIdStddevSampleLanguageAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LANGUAGE_ASC',
  LinkedinTemplatesByCampaignIdStddevSampleLanguageDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LANGUAGE_DESC',
  LinkedinTemplatesByCampaignIdStddevSampleNameAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NAME_ASC',
  LinkedinTemplatesByCampaignIdStddevSampleNameDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_NAME_DESC',
  LinkedinTemplatesByCampaignIdStddevSampleOrderIndexAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ORDER_INDEX_ASC',
  LinkedinTemplatesByCampaignIdStddevSampleOrderIndexDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ORDER_INDEX_DESC',
  LinkedinTemplatesByCampaignIdStddevSampleRevisedContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REVISED_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdStddevSampleRevisedContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REVISED_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdStddevSampleStatusAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_STATUS_ASC',
  LinkedinTemplatesByCampaignIdStddevSampleStatusDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_STATUS_DESC',
  LinkedinTemplatesByCampaignIdStddevSampleUpdatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdStddevSampleUpdatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdSumCampaignIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_ASC',
  LinkedinTemplatesByCampaignIdSumCampaignIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_DESC',
  LinkedinTemplatesByCampaignIdSumContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdSumContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdSumConversionScoreAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_CONVERSION_SCORE_ASC',
  LinkedinTemplatesByCampaignIdSumConversionScoreDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_CONVERSION_SCORE_DESC',
  LinkedinTemplatesByCampaignIdSumCreatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_CREATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdSumCreatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_CREATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdSumIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_ID_ASC',
  LinkedinTemplatesByCampaignIdSumIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_ID_DESC',
  LinkedinTemplatesByCampaignIdSumLanguageAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_LANGUAGE_ASC',
  LinkedinTemplatesByCampaignIdSumLanguageDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_LANGUAGE_DESC',
  LinkedinTemplatesByCampaignIdSumNameAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_NAME_ASC',
  LinkedinTemplatesByCampaignIdSumNameDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_NAME_DESC',
  LinkedinTemplatesByCampaignIdSumOrderIndexAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_ORDER_INDEX_ASC',
  LinkedinTemplatesByCampaignIdSumOrderIndexDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_ORDER_INDEX_DESC',
  LinkedinTemplatesByCampaignIdSumRevisedContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_REVISED_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdSumRevisedContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_REVISED_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdSumStatusAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_STATUS_ASC',
  LinkedinTemplatesByCampaignIdSumStatusDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_STATUS_DESC',
  LinkedinTemplatesByCampaignIdSumUpdatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdSumUpdatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdVariancePopulationCampaignIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  LinkedinTemplatesByCampaignIdVariancePopulationCampaignIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  LinkedinTemplatesByCampaignIdVariancePopulationContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdVariancePopulationContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdVariancePopulationConversionScoreAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONVERSION_SCORE_ASC',
  LinkedinTemplatesByCampaignIdVariancePopulationConversionScoreDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONVERSION_SCORE_DESC',
  LinkedinTemplatesByCampaignIdVariancePopulationCreatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdVariancePopulationCreatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdVariancePopulationIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_ASC',
  LinkedinTemplatesByCampaignIdVariancePopulationIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_DESC',
  LinkedinTemplatesByCampaignIdVariancePopulationLanguageAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LANGUAGE_ASC',
  LinkedinTemplatesByCampaignIdVariancePopulationLanguageDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LANGUAGE_DESC',
  LinkedinTemplatesByCampaignIdVariancePopulationNameAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NAME_ASC',
  LinkedinTemplatesByCampaignIdVariancePopulationNameDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_NAME_DESC',
  LinkedinTemplatesByCampaignIdVariancePopulationOrderIndexAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ORDER_INDEX_ASC',
  LinkedinTemplatesByCampaignIdVariancePopulationOrderIndexDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ORDER_INDEX_DESC',
  LinkedinTemplatesByCampaignIdVariancePopulationRevisedContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REVISED_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdVariancePopulationRevisedContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REVISED_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdVariancePopulationStatusAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_STATUS_ASC',
  LinkedinTemplatesByCampaignIdVariancePopulationStatusDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_STATUS_DESC',
  LinkedinTemplatesByCampaignIdVariancePopulationUpdatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdVariancePopulationUpdatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdVarianceSampleCampaignIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  LinkedinTemplatesByCampaignIdVarianceSampleCampaignIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  LinkedinTemplatesByCampaignIdVarianceSampleContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdVarianceSampleContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdVarianceSampleConversionScoreAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONVERSION_SCORE_ASC',
  LinkedinTemplatesByCampaignIdVarianceSampleConversionScoreDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONVERSION_SCORE_DESC',
  LinkedinTemplatesByCampaignIdVarianceSampleCreatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdVarianceSampleCreatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LinkedinTemplatesByCampaignIdVarianceSampleIdAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_ASC',
  LinkedinTemplatesByCampaignIdVarianceSampleIdDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_DESC',
  LinkedinTemplatesByCampaignIdVarianceSampleLanguageAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LANGUAGE_ASC',
  LinkedinTemplatesByCampaignIdVarianceSampleLanguageDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LANGUAGE_DESC',
  LinkedinTemplatesByCampaignIdVarianceSampleNameAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NAME_ASC',
  LinkedinTemplatesByCampaignIdVarianceSampleNameDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_NAME_DESC',
  LinkedinTemplatesByCampaignIdVarianceSampleOrderIndexAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ORDER_INDEX_ASC',
  LinkedinTemplatesByCampaignIdVarianceSampleOrderIndexDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ORDER_INDEX_DESC',
  LinkedinTemplatesByCampaignIdVarianceSampleRevisedContentAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REVISED_CONTENT_ASC',
  LinkedinTemplatesByCampaignIdVarianceSampleRevisedContentDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REVISED_CONTENT_DESC',
  LinkedinTemplatesByCampaignIdVarianceSampleStatusAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_STATUS_ASC',
  LinkedinTemplatesByCampaignIdVarianceSampleStatusDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_STATUS_DESC',
  LinkedinTemplatesByCampaignIdVarianceSampleUpdatedTimeAsc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  LinkedinTemplatesByCampaignIdVarianceSampleUpdatedTimeDesc = 'LINKEDIN_TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  MaxAllocationAsc = 'MAX_ALLOCATION_ASC',
  MaxAllocationDesc = 'MAX_ALLOCATION_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NotesAsc = 'NOTES_ASC',
  NotesDesc = 'NOTES_DESC',
  PatentQueryAsc = 'PATENT_QUERY_ASC',
  PatentQueryDesc = 'PATENT_QUERY_DESC',
  PersonalisationQueryAsc = 'PERSONALISATION_QUERY_ASC',
  PersonalisationQueryDesc = 'PERSONALISATION_QUERY_DESC',
  PersonalisationStatisticsByCampaignIdAverageCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByCampaignIdAverageCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByCampaignIdAverageIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_ID_ASC',
  PersonalisationStatisticsByCampaignIdAverageIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_ID_DESC',
  PersonalisationStatisticsByCampaignIdAverageLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_LAWYER_ID_ASC',
  PersonalisationStatisticsByCampaignIdAverageLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_LAWYER_ID_DESC',
  PersonalisationStatisticsByCampaignIdAveragePersValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_PERS_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdAveragePersValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_PERS_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdAverageTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_TYPE_ID_ASC',
  PersonalisationStatisticsByCampaignIdAverageTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_TYPE_ID_DESC',
  PersonalisationStatisticsByCampaignIdAverageValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdAverageValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_AVERAGE_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdCountAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_COUNT_ASC',
  PersonalisationStatisticsByCampaignIdCountDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_COUNT_DESC',
  PersonalisationStatisticsByCampaignIdDistinctCountCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByCampaignIdDistinctCountCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByCampaignIdDistinctCountIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_ASC',
  PersonalisationStatisticsByCampaignIdDistinctCountIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_DESC',
  PersonalisationStatisticsByCampaignIdDistinctCountLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  PersonalisationStatisticsByCampaignIdDistinctCountLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  PersonalisationStatisticsByCampaignIdDistinctCountPersValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_PERS_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdDistinctCountPersValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_PERS_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdDistinctCountTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_TYPE_ID_ASC',
  PersonalisationStatisticsByCampaignIdDistinctCountTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_TYPE_ID_DESC',
  PersonalisationStatisticsByCampaignIdDistinctCountValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdDistinctCountValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_DISTINCT_COUNT_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdMaxCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByCampaignIdMaxCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByCampaignIdMaxIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MAX_ID_ASC',
  PersonalisationStatisticsByCampaignIdMaxIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MAX_ID_DESC',
  PersonalisationStatisticsByCampaignIdMaxLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MAX_LAWYER_ID_ASC',
  PersonalisationStatisticsByCampaignIdMaxLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MAX_LAWYER_ID_DESC',
  PersonalisationStatisticsByCampaignIdMaxPersValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MAX_PERS_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdMaxPersValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MAX_PERS_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdMaxTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MAX_TYPE_ID_ASC',
  PersonalisationStatisticsByCampaignIdMaxTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MAX_TYPE_ID_DESC',
  PersonalisationStatisticsByCampaignIdMaxValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MAX_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdMaxValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MAX_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdMinCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByCampaignIdMinCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByCampaignIdMinIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MIN_ID_ASC',
  PersonalisationStatisticsByCampaignIdMinIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MIN_ID_DESC',
  PersonalisationStatisticsByCampaignIdMinLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MIN_LAWYER_ID_ASC',
  PersonalisationStatisticsByCampaignIdMinLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MIN_LAWYER_ID_DESC',
  PersonalisationStatisticsByCampaignIdMinPersValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MIN_PERS_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdMinPersValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MIN_PERS_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdMinTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MIN_TYPE_ID_ASC',
  PersonalisationStatisticsByCampaignIdMinTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MIN_TYPE_ID_DESC',
  PersonalisationStatisticsByCampaignIdMinValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MIN_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdMinValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_MIN_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdStddevPopulationCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByCampaignIdStddevPopulationCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByCampaignIdStddevPopulationIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_ASC',
  PersonalisationStatisticsByCampaignIdStddevPopulationIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_DESC',
  PersonalisationStatisticsByCampaignIdStddevPopulationLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  PersonalisationStatisticsByCampaignIdStddevPopulationLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  PersonalisationStatisticsByCampaignIdStddevPopulationPersValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_PERS_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdStddevPopulationPersValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_PERS_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdStddevPopulationTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_TYPE_ID_ASC',
  PersonalisationStatisticsByCampaignIdStddevPopulationTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_TYPE_ID_DESC',
  PersonalisationStatisticsByCampaignIdStddevPopulationValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdStddevPopulationValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_POPULATION_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdStddevSampleCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByCampaignIdStddevSampleCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByCampaignIdStddevSampleIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_ASC',
  PersonalisationStatisticsByCampaignIdStddevSampleIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_DESC',
  PersonalisationStatisticsByCampaignIdStddevSampleLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  PersonalisationStatisticsByCampaignIdStddevSampleLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  PersonalisationStatisticsByCampaignIdStddevSamplePersValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_PERS_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdStddevSamplePersValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_PERS_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdStddevSampleTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_TYPE_ID_ASC',
  PersonalisationStatisticsByCampaignIdStddevSampleTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_TYPE_ID_DESC',
  PersonalisationStatisticsByCampaignIdStddevSampleValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdStddevSampleValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdSumCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByCampaignIdSumCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByCampaignIdSumIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_SUM_ID_ASC',
  PersonalisationStatisticsByCampaignIdSumIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_SUM_ID_DESC',
  PersonalisationStatisticsByCampaignIdSumLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_SUM_LAWYER_ID_ASC',
  PersonalisationStatisticsByCampaignIdSumLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_SUM_LAWYER_ID_DESC',
  PersonalisationStatisticsByCampaignIdSumPersValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_SUM_PERS_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdSumPersValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_SUM_PERS_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdSumTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_SUM_TYPE_ID_ASC',
  PersonalisationStatisticsByCampaignIdSumTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_SUM_TYPE_ID_DESC',
  PersonalisationStatisticsByCampaignIdSumValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_SUM_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdSumValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_SUM_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdVariancePopulationCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByCampaignIdVariancePopulationCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByCampaignIdVariancePopulationIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_ASC',
  PersonalisationStatisticsByCampaignIdVariancePopulationIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_DESC',
  PersonalisationStatisticsByCampaignIdVariancePopulationLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  PersonalisationStatisticsByCampaignIdVariancePopulationLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  PersonalisationStatisticsByCampaignIdVariancePopulationPersValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_PERS_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdVariancePopulationPersValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_PERS_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdVariancePopulationTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_TYPE_ID_ASC',
  PersonalisationStatisticsByCampaignIdVariancePopulationTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_TYPE_ID_DESC',
  PersonalisationStatisticsByCampaignIdVariancePopulationValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdVariancePopulationValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdVarianceSampleCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByCampaignIdVarianceSampleCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByCampaignIdVarianceSampleIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_ASC',
  PersonalisationStatisticsByCampaignIdVarianceSampleIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_DESC',
  PersonalisationStatisticsByCampaignIdVarianceSampleLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  PersonalisationStatisticsByCampaignIdVarianceSampleLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  PersonalisationStatisticsByCampaignIdVarianceSamplePersValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_PERS_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdVarianceSamplePersValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_PERS_VALUE_DESC',
  PersonalisationStatisticsByCampaignIdVarianceSampleTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_TYPE_ID_ASC',
  PersonalisationStatisticsByCampaignIdVarianceSampleTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_TYPE_ID_DESC',
  PersonalisationStatisticsByCampaignIdVarianceSampleValueAsc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_VALUE_ASC',
  PersonalisationStatisticsByCampaignIdVarianceSampleValueDesc = 'PERSONALISATION_STATISTICS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_VALUE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC',
  ReviewedByAsc = 'REVIEWED_BY_ASC',
  ReviewedByDesc = 'REVIEWED_BY_DESC',
  ReviewedTimeAsc = 'REVIEWED_TIME_ASC',
  ReviewedTimeDesc = 'REVIEWED_TIME_DESC',
  ScheduledActivationTimeAsc = 'SCHEDULED_ACTIVATION_TIME_ASC',
  ScheduledActivationTimeDesc = 'SCHEDULED_ACTIVATION_TIME_DESC',
  SearchAfterAsc = 'SEARCH_AFTER_ASC',
  SearchAfterDesc = 'SEARCH_AFTER_DESC',
  SearchAfterScoreAsc = 'SEARCH_AFTER_SCORE_ASC',
  SearchAfterScoreDesc = 'SEARCH_AFTER_SCORE_DESC',
  SearchBeforeAsc = 'SEARCH_BEFORE_ASC',
  SearchBeforeDesc = 'SEARCH_BEFORE_DESC',
  SearchStartedAsc = 'SEARCH_STARTED_ASC',
  SearchStartedDesc = 'SEARCH_STARTED_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SubjectAsc = 'SUBJECT_ASC',
  SubjectDesc = 'SUBJECT_DESC',
  SuggestedTargetingIdAsc = 'SUGGESTED_TARGETING_ID_ASC',
  SuggestedTargetingIdDesc = 'SUGGESTED_TARGETING_ID_DESC',
  TargetingRequestAsc = 'TARGETING_REQUEST_ASC',
  TargetingRequestDesc = 'TARGETING_REQUEST_DESC',
  TargetDescriptionAsc = 'TARGET_DESCRIPTION_ASC',
  TargetDescriptionDesc = 'TARGET_DESCRIPTION_DESC',
  TemplatesByCampaignIdAverageCampaignIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_ASC',
  TemplatesByCampaignIdAverageCampaignIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_DESC',
  TemplatesByCampaignIdAverageContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CONTENT_ASC',
  TemplatesByCampaignIdAverageContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CONTENT_DESC',
  TemplatesByCampaignIdAverageConversionScoreAsc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CONVERSION_SCORE_ASC',
  TemplatesByCampaignIdAverageConversionScoreDesc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CONVERSION_SCORE_DESC',
  TemplatesByCampaignIdAverageCreatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_ASC',
  TemplatesByCampaignIdAverageCreatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_DESC',
  TemplatesByCampaignIdAverageDelayAsc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_DELAY_ASC',
  TemplatesByCampaignIdAverageDelayDesc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_DELAY_DESC',
  TemplatesByCampaignIdAverageIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_ID_ASC',
  TemplatesByCampaignIdAverageIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_ID_DESC',
  TemplatesByCampaignIdAverageLanguageAsc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_LANGUAGE_ASC',
  TemplatesByCampaignIdAverageLanguageDesc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_LANGUAGE_DESC',
  TemplatesByCampaignIdAverageOrderIndexAsc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_ORDER_INDEX_ASC',
  TemplatesByCampaignIdAverageOrderIndexDesc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_ORDER_INDEX_DESC',
  TemplatesByCampaignIdAverageRevisedContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_REVISED_CONTENT_ASC',
  TemplatesByCampaignIdAverageRevisedContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_REVISED_CONTENT_DESC',
  TemplatesByCampaignIdAverageSendgridIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_SENDGRID_ID_ASC',
  TemplatesByCampaignIdAverageSendgridIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_SENDGRID_ID_DESC',
  TemplatesByCampaignIdAverageSpintaxAsc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_SPINTAX_ASC',
  TemplatesByCampaignIdAverageSpintaxDesc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_SPINTAX_DESC',
  TemplatesByCampaignIdAverageStatusAsc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_STATUS_ASC',
  TemplatesByCampaignIdAverageStatusDesc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_STATUS_DESC',
  TemplatesByCampaignIdAverageUpdatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_ASC',
  TemplatesByCampaignIdAverageUpdatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_AVERAGE_UPDATED_TIME_DESC',
  TemplatesByCampaignIdCountAsc = 'TEMPLATES_BY_CAMPAIGN_ID_COUNT_ASC',
  TemplatesByCampaignIdCountDesc = 'TEMPLATES_BY_CAMPAIGN_ID_COUNT_DESC',
  TemplatesByCampaignIdDistinctCountCampaignIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  TemplatesByCampaignIdDistinctCountCampaignIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  TemplatesByCampaignIdDistinctCountContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTENT_ASC',
  TemplatesByCampaignIdDistinctCountContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTENT_DESC',
  TemplatesByCampaignIdDistinctCountConversionScoreAsc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONVERSION_SCORE_ASC',
  TemplatesByCampaignIdDistinctCountConversionScoreDesc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONVERSION_SCORE_DESC',
  TemplatesByCampaignIdDistinctCountCreatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  TemplatesByCampaignIdDistinctCountCreatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  TemplatesByCampaignIdDistinctCountDelayAsc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_DELAY_ASC',
  TemplatesByCampaignIdDistinctCountDelayDesc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_DELAY_DESC',
  TemplatesByCampaignIdDistinctCountIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_ASC',
  TemplatesByCampaignIdDistinctCountIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_DESC',
  TemplatesByCampaignIdDistinctCountLanguageAsc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LANGUAGE_ASC',
  TemplatesByCampaignIdDistinctCountLanguageDesc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_LANGUAGE_DESC',
  TemplatesByCampaignIdDistinctCountOrderIndexAsc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ORDER_INDEX_ASC',
  TemplatesByCampaignIdDistinctCountOrderIndexDesc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_ORDER_INDEX_DESC',
  TemplatesByCampaignIdDistinctCountRevisedContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REVISED_CONTENT_ASC',
  TemplatesByCampaignIdDistinctCountRevisedContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_REVISED_CONTENT_DESC',
  TemplatesByCampaignIdDistinctCountSendgridIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SENDGRID_ID_ASC',
  TemplatesByCampaignIdDistinctCountSendgridIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SENDGRID_ID_DESC',
  TemplatesByCampaignIdDistinctCountSpintaxAsc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SPINTAX_ASC',
  TemplatesByCampaignIdDistinctCountSpintaxDesc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_SPINTAX_DESC',
  TemplatesByCampaignIdDistinctCountStatusAsc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_STATUS_ASC',
  TemplatesByCampaignIdDistinctCountStatusDesc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_STATUS_DESC',
  TemplatesByCampaignIdDistinctCountUpdatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  TemplatesByCampaignIdDistinctCountUpdatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  TemplatesByCampaignIdMaxCampaignIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_ASC',
  TemplatesByCampaignIdMaxCampaignIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_DESC',
  TemplatesByCampaignIdMaxContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_CONTENT_ASC',
  TemplatesByCampaignIdMaxContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_CONTENT_DESC',
  TemplatesByCampaignIdMaxConversionScoreAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_CONVERSION_SCORE_ASC',
  TemplatesByCampaignIdMaxConversionScoreDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_CONVERSION_SCORE_DESC',
  TemplatesByCampaignIdMaxCreatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_CREATED_TIME_ASC',
  TemplatesByCampaignIdMaxCreatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_CREATED_TIME_DESC',
  TemplatesByCampaignIdMaxDelayAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_DELAY_ASC',
  TemplatesByCampaignIdMaxDelayDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_DELAY_DESC',
  TemplatesByCampaignIdMaxIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_ID_ASC',
  TemplatesByCampaignIdMaxIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_ID_DESC',
  TemplatesByCampaignIdMaxLanguageAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_LANGUAGE_ASC',
  TemplatesByCampaignIdMaxLanguageDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_LANGUAGE_DESC',
  TemplatesByCampaignIdMaxOrderIndexAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_ORDER_INDEX_ASC',
  TemplatesByCampaignIdMaxOrderIndexDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_ORDER_INDEX_DESC',
  TemplatesByCampaignIdMaxRevisedContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_REVISED_CONTENT_ASC',
  TemplatesByCampaignIdMaxRevisedContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_REVISED_CONTENT_DESC',
  TemplatesByCampaignIdMaxSendgridIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_SENDGRID_ID_ASC',
  TemplatesByCampaignIdMaxSendgridIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_SENDGRID_ID_DESC',
  TemplatesByCampaignIdMaxSpintaxAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_SPINTAX_ASC',
  TemplatesByCampaignIdMaxSpintaxDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_SPINTAX_DESC',
  TemplatesByCampaignIdMaxStatusAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_STATUS_ASC',
  TemplatesByCampaignIdMaxStatusDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_STATUS_DESC',
  TemplatesByCampaignIdMaxUpdatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_ASC',
  TemplatesByCampaignIdMaxUpdatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MAX_UPDATED_TIME_DESC',
  TemplatesByCampaignIdMinCampaignIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_ASC',
  TemplatesByCampaignIdMinCampaignIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_DESC',
  TemplatesByCampaignIdMinContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_CONTENT_ASC',
  TemplatesByCampaignIdMinContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_CONTENT_DESC',
  TemplatesByCampaignIdMinConversionScoreAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_CONVERSION_SCORE_ASC',
  TemplatesByCampaignIdMinConversionScoreDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_CONVERSION_SCORE_DESC',
  TemplatesByCampaignIdMinCreatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_CREATED_TIME_ASC',
  TemplatesByCampaignIdMinCreatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_CREATED_TIME_DESC',
  TemplatesByCampaignIdMinDelayAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_DELAY_ASC',
  TemplatesByCampaignIdMinDelayDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_DELAY_DESC',
  TemplatesByCampaignIdMinIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_ID_ASC',
  TemplatesByCampaignIdMinIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_ID_DESC',
  TemplatesByCampaignIdMinLanguageAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_LANGUAGE_ASC',
  TemplatesByCampaignIdMinLanguageDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_LANGUAGE_DESC',
  TemplatesByCampaignIdMinOrderIndexAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_ORDER_INDEX_ASC',
  TemplatesByCampaignIdMinOrderIndexDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_ORDER_INDEX_DESC',
  TemplatesByCampaignIdMinRevisedContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_REVISED_CONTENT_ASC',
  TemplatesByCampaignIdMinRevisedContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_REVISED_CONTENT_DESC',
  TemplatesByCampaignIdMinSendgridIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_SENDGRID_ID_ASC',
  TemplatesByCampaignIdMinSendgridIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_SENDGRID_ID_DESC',
  TemplatesByCampaignIdMinSpintaxAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_SPINTAX_ASC',
  TemplatesByCampaignIdMinSpintaxDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_SPINTAX_DESC',
  TemplatesByCampaignIdMinStatusAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_STATUS_ASC',
  TemplatesByCampaignIdMinStatusDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_STATUS_DESC',
  TemplatesByCampaignIdMinUpdatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_ASC',
  TemplatesByCampaignIdMinUpdatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_MIN_UPDATED_TIME_DESC',
  TemplatesByCampaignIdStddevPopulationCampaignIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  TemplatesByCampaignIdStddevPopulationCampaignIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  TemplatesByCampaignIdStddevPopulationContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTENT_ASC',
  TemplatesByCampaignIdStddevPopulationContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTENT_DESC',
  TemplatesByCampaignIdStddevPopulationConversionScoreAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONVERSION_SCORE_ASC',
  TemplatesByCampaignIdStddevPopulationConversionScoreDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONVERSION_SCORE_DESC',
  TemplatesByCampaignIdStddevPopulationCreatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  TemplatesByCampaignIdStddevPopulationCreatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  TemplatesByCampaignIdStddevPopulationDelayAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_DELAY_ASC',
  TemplatesByCampaignIdStddevPopulationDelayDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_DELAY_DESC',
  TemplatesByCampaignIdStddevPopulationIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_ASC',
  TemplatesByCampaignIdStddevPopulationIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_DESC',
  TemplatesByCampaignIdStddevPopulationLanguageAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LANGUAGE_ASC',
  TemplatesByCampaignIdStddevPopulationLanguageDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_LANGUAGE_DESC',
  TemplatesByCampaignIdStddevPopulationOrderIndexAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ORDER_INDEX_ASC',
  TemplatesByCampaignIdStddevPopulationOrderIndexDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_ORDER_INDEX_DESC',
  TemplatesByCampaignIdStddevPopulationRevisedContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REVISED_CONTENT_ASC',
  TemplatesByCampaignIdStddevPopulationRevisedContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_REVISED_CONTENT_DESC',
  TemplatesByCampaignIdStddevPopulationSendgridIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SENDGRID_ID_ASC',
  TemplatesByCampaignIdStddevPopulationSendgridIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SENDGRID_ID_DESC',
  TemplatesByCampaignIdStddevPopulationSpintaxAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SPINTAX_ASC',
  TemplatesByCampaignIdStddevPopulationSpintaxDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_SPINTAX_DESC',
  TemplatesByCampaignIdStddevPopulationStatusAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_STATUS_ASC',
  TemplatesByCampaignIdStddevPopulationStatusDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_STATUS_DESC',
  TemplatesByCampaignIdStddevPopulationUpdatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  TemplatesByCampaignIdStddevPopulationUpdatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  TemplatesByCampaignIdStddevSampleCampaignIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  TemplatesByCampaignIdStddevSampleCampaignIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  TemplatesByCampaignIdStddevSampleContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTENT_ASC',
  TemplatesByCampaignIdStddevSampleContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTENT_DESC',
  TemplatesByCampaignIdStddevSampleConversionScoreAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONVERSION_SCORE_ASC',
  TemplatesByCampaignIdStddevSampleConversionScoreDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONVERSION_SCORE_DESC',
  TemplatesByCampaignIdStddevSampleCreatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  TemplatesByCampaignIdStddevSampleCreatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  TemplatesByCampaignIdStddevSampleDelayAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_DELAY_ASC',
  TemplatesByCampaignIdStddevSampleDelayDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_DELAY_DESC',
  TemplatesByCampaignIdStddevSampleIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_ASC',
  TemplatesByCampaignIdStddevSampleIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_DESC',
  TemplatesByCampaignIdStddevSampleLanguageAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LANGUAGE_ASC',
  TemplatesByCampaignIdStddevSampleLanguageDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LANGUAGE_DESC',
  TemplatesByCampaignIdStddevSampleOrderIndexAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ORDER_INDEX_ASC',
  TemplatesByCampaignIdStddevSampleOrderIndexDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ORDER_INDEX_DESC',
  TemplatesByCampaignIdStddevSampleRevisedContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REVISED_CONTENT_ASC',
  TemplatesByCampaignIdStddevSampleRevisedContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_REVISED_CONTENT_DESC',
  TemplatesByCampaignIdStddevSampleSendgridIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SENDGRID_ID_ASC',
  TemplatesByCampaignIdStddevSampleSendgridIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SENDGRID_ID_DESC',
  TemplatesByCampaignIdStddevSampleSpintaxAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SPINTAX_ASC',
  TemplatesByCampaignIdStddevSampleSpintaxDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_SPINTAX_DESC',
  TemplatesByCampaignIdStddevSampleStatusAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_STATUS_ASC',
  TemplatesByCampaignIdStddevSampleStatusDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_STATUS_DESC',
  TemplatesByCampaignIdStddevSampleUpdatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  TemplatesByCampaignIdStddevSampleUpdatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  TemplatesByCampaignIdSumCampaignIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_ASC',
  TemplatesByCampaignIdSumCampaignIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_DESC',
  TemplatesByCampaignIdSumContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_CONTENT_ASC',
  TemplatesByCampaignIdSumContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_CONTENT_DESC',
  TemplatesByCampaignIdSumConversionScoreAsc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_CONVERSION_SCORE_ASC',
  TemplatesByCampaignIdSumConversionScoreDesc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_CONVERSION_SCORE_DESC',
  TemplatesByCampaignIdSumCreatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_CREATED_TIME_ASC',
  TemplatesByCampaignIdSumCreatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_CREATED_TIME_DESC',
  TemplatesByCampaignIdSumDelayAsc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_DELAY_ASC',
  TemplatesByCampaignIdSumDelayDesc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_DELAY_DESC',
  TemplatesByCampaignIdSumIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_ID_ASC',
  TemplatesByCampaignIdSumIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_ID_DESC',
  TemplatesByCampaignIdSumLanguageAsc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_LANGUAGE_ASC',
  TemplatesByCampaignIdSumLanguageDesc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_LANGUAGE_DESC',
  TemplatesByCampaignIdSumOrderIndexAsc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_ORDER_INDEX_ASC',
  TemplatesByCampaignIdSumOrderIndexDesc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_ORDER_INDEX_DESC',
  TemplatesByCampaignIdSumRevisedContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_REVISED_CONTENT_ASC',
  TemplatesByCampaignIdSumRevisedContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_REVISED_CONTENT_DESC',
  TemplatesByCampaignIdSumSendgridIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_SENDGRID_ID_ASC',
  TemplatesByCampaignIdSumSendgridIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_SENDGRID_ID_DESC',
  TemplatesByCampaignIdSumSpintaxAsc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_SPINTAX_ASC',
  TemplatesByCampaignIdSumSpintaxDesc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_SPINTAX_DESC',
  TemplatesByCampaignIdSumStatusAsc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_STATUS_ASC',
  TemplatesByCampaignIdSumStatusDesc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_STATUS_DESC',
  TemplatesByCampaignIdSumUpdatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_ASC',
  TemplatesByCampaignIdSumUpdatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_SUM_UPDATED_TIME_DESC',
  TemplatesByCampaignIdVariancePopulationCampaignIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  TemplatesByCampaignIdVariancePopulationCampaignIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  TemplatesByCampaignIdVariancePopulationContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTENT_ASC',
  TemplatesByCampaignIdVariancePopulationContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTENT_DESC',
  TemplatesByCampaignIdVariancePopulationConversionScoreAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONVERSION_SCORE_ASC',
  TemplatesByCampaignIdVariancePopulationConversionScoreDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONVERSION_SCORE_DESC',
  TemplatesByCampaignIdVariancePopulationCreatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  TemplatesByCampaignIdVariancePopulationCreatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  TemplatesByCampaignIdVariancePopulationDelayAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_DELAY_ASC',
  TemplatesByCampaignIdVariancePopulationDelayDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_DELAY_DESC',
  TemplatesByCampaignIdVariancePopulationIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_ASC',
  TemplatesByCampaignIdVariancePopulationIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_DESC',
  TemplatesByCampaignIdVariancePopulationLanguageAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LANGUAGE_ASC',
  TemplatesByCampaignIdVariancePopulationLanguageDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LANGUAGE_DESC',
  TemplatesByCampaignIdVariancePopulationOrderIndexAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ORDER_INDEX_ASC',
  TemplatesByCampaignIdVariancePopulationOrderIndexDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ORDER_INDEX_DESC',
  TemplatesByCampaignIdVariancePopulationRevisedContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REVISED_CONTENT_ASC',
  TemplatesByCampaignIdVariancePopulationRevisedContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_REVISED_CONTENT_DESC',
  TemplatesByCampaignIdVariancePopulationSendgridIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SENDGRID_ID_ASC',
  TemplatesByCampaignIdVariancePopulationSendgridIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SENDGRID_ID_DESC',
  TemplatesByCampaignIdVariancePopulationSpintaxAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SPINTAX_ASC',
  TemplatesByCampaignIdVariancePopulationSpintaxDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_SPINTAX_DESC',
  TemplatesByCampaignIdVariancePopulationStatusAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_STATUS_ASC',
  TemplatesByCampaignIdVariancePopulationStatusDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_STATUS_DESC',
  TemplatesByCampaignIdVariancePopulationUpdatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  TemplatesByCampaignIdVariancePopulationUpdatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  TemplatesByCampaignIdVarianceSampleCampaignIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  TemplatesByCampaignIdVarianceSampleCampaignIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  TemplatesByCampaignIdVarianceSampleContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  TemplatesByCampaignIdVarianceSampleContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  TemplatesByCampaignIdVarianceSampleConversionScoreAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONVERSION_SCORE_ASC',
  TemplatesByCampaignIdVarianceSampleConversionScoreDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONVERSION_SCORE_DESC',
  TemplatesByCampaignIdVarianceSampleCreatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  TemplatesByCampaignIdVarianceSampleCreatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  TemplatesByCampaignIdVarianceSampleDelayAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_DELAY_ASC',
  TemplatesByCampaignIdVarianceSampleDelayDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_DELAY_DESC',
  TemplatesByCampaignIdVarianceSampleIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_ASC',
  TemplatesByCampaignIdVarianceSampleIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_DESC',
  TemplatesByCampaignIdVarianceSampleLanguageAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LANGUAGE_ASC',
  TemplatesByCampaignIdVarianceSampleLanguageDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LANGUAGE_DESC',
  TemplatesByCampaignIdVarianceSampleOrderIndexAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ORDER_INDEX_ASC',
  TemplatesByCampaignIdVarianceSampleOrderIndexDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ORDER_INDEX_DESC',
  TemplatesByCampaignIdVarianceSampleRevisedContentAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REVISED_CONTENT_ASC',
  TemplatesByCampaignIdVarianceSampleRevisedContentDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_REVISED_CONTENT_DESC',
  TemplatesByCampaignIdVarianceSampleSendgridIdAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SENDGRID_ID_ASC',
  TemplatesByCampaignIdVarianceSampleSendgridIdDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SENDGRID_ID_DESC',
  TemplatesByCampaignIdVarianceSampleSpintaxAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SPINTAX_ASC',
  TemplatesByCampaignIdVarianceSampleSpintaxDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_SPINTAX_DESC',
  TemplatesByCampaignIdVarianceSampleStatusAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_STATUS_ASC',
  TemplatesByCampaignIdVarianceSampleStatusDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_STATUS_DESC',
  TemplatesByCampaignIdVarianceSampleUpdatedTimeAsc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  TemplatesByCampaignIdVarianceSampleUpdatedTimeDesc = 'TEMPLATES_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  TransactionalEmailsByCampaignIdAverageCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_ASC',
  TransactionalEmailsByCampaignIdAverageCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_CAMPAIGN_ID_DESC',
  TransactionalEmailsByCampaignIdAverageContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_CONTACT_ID_ASC',
  TransactionalEmailsByCampaignIdAverageContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_CONTACT_ID_DESC',
  TransactionalEmailsByCampaignIdAverageCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_ASC',
  TransactionalEmailsByCampaignIdAverageCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_CREATED_TIME_DESC',
  TransactionalEmailsByCampaignIdAverageIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_ID_ASC',
  TransactionalEmailsByCampaignIdAverageIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_ID_DESC',
  TransactionalEmailsByCampaignIdAverageLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_LAWYER_ID_ASC',
  TransactionalEmailsByCampaignIdAverageLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_LAWYER_ID_DESC',
  TransactionalEmailsByCampaignIdAverageMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_MEETING_ID_ASC',
  TransactionalEmailsByCampaignIdAverageMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_MEETING_ID_DESC',
  TransactionalEmailsByCampaignIdAveragePostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByCampaignIdAveragePostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_AVERAGE_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByCampaignIdCountAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_COUNT_ASC',
  TransactionalEmailsByCampaignIdCountDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_COUNT_DESC',
  TransactionalEmailsByCampaignIdDistinctCountCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  TransactionalEmailsByCampaignIdDistinctCountCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  TransactionalEmailsByCampaignIdDistinctCountContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  TransactionalEmailsByCampaignIdDistinctCountContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  TransactionalEmailsByCampaignIdDistinctCountCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  TransactionalEmailsByCampaignIdDistinctCountCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  TransactionalEmailsByCampaignIdDistinctCountIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_ASC',
  TransactionalEmailsByCampaignIdDistinctCountIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_ID_DESC',
  TransactionalEmailsByCampaignIdDistinctCountLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  TransactionalEmailsByCampaignIdDistinctCountLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  TransactionalEmailsByCampaignIdDistinctCountMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_MEETING_ID_ASC',
  TransactionalEmailsByCampaignIdDistinctCountMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_MEETING_ID_DESC',
  TransactionalEmailsByCampaignIdDistinctCountPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByCampaignIdDistinctCountPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_DISTINCT_COUNT_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByCampaignIdMaxCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_ASC',
  TransactionalEmailsByCampaignIdMaxCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_CAMPAIGN_ID_DESC',
  TransactionalEmailsByCampaignIdMaxContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_CONTACT_ID_ASC',
  TransactionalEmailsByCampaignIdMaxContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_CONTACT_ID_DESC',
  TransactionalEmailsByCampaignIdMaxCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_CREATED_TIME_ASC',
  TransactionalEmailsByCampaignIdMaxCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_CREATED_TIME_DESC',
  TransactionalEmailsByCampaignIdMaxIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_ID_ASC',
  TransactionalEmailsByCampaignIdMaxIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_ID_DESC',
  TransactionalEmailsByCampaignIdMaxLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_LAWYER_ID_ASC',
  TransactionalEmailsByCampaignIdMaxLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_LAWYER_ID_DESC',
  TransactionalEmailsByCampaignIdMaxMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_MEETING_ID_ASC',
  TransactionalEmailsByCampaignIdMaxMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_MEETING_ID_DESC',
  TransactionalEmailsByCampaignIdMaxPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByCampaignIdMaxPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MAX_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByCampaignIdMinCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_ASC',
  TransactionalEmailsByCampaignIdMinCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_CAMPAIGN_ID_DESC',
  TransactionalEmailsByCampaignIdMinContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_CONTACT_ID_ASC',
  TransactionalEmailsByCampaignIdMinContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_CONTACT_ID_DESC',
  TransactionalEmailsByCampaignIdMinCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_CREATED_TIME_ASC',
  TransactionalEmailsByCampaignIdMinCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_CREATED_TIME_DESC',
  TransactionalEmailsByCampaignIdMinIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_ID_ASC',
  TransactionalEmailsByCampaignIdMinIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_ID_DESC',
  TransactionalEmailsByCampaignIdMinLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_LAWYER_ID_ASC',
  TransactionalEmailsByCampaignIdMinLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_LAWYER_ID_DESC',
  TransactionalEmailsByCampaignIdMinMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_MEETING_ID_ASC',
  TransactionalEmailsByCampaignIdMinMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_MEETING_ID_DESC',
  TransactionalEmailsByCampaignIdMinPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByCampaignIdMinPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_MIN_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByCampaignIdStddevPopulationCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  TransactionalEmailsByCampaignIdStddevPopulationCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  TransactionalEmailsByCampaignIdStddevPopulationContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  TransactionalEmailsByCampaignIdStddevPopulationContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  TransactionalEmailsByCampaignIdStddevPopulationCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  TransactionalEmailsByCampaignIdStddevPopulationCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  TransactionalEmailsByCampaignIdStddevPopulationIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_ASC',
  TransactionalEmailsByCampaignIdStddevPopulationIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_ID_DESC',
  TransactionalEmailsByCampaignIdStddevPopulationLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  TransactionalEmailsByCampaignIdStddevPopulationLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  TransactionalEmailsByCampaignIdStddevPopulationMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_MEETING_ID_ASC',
  TransactionalEmailsByCampaignIdStddevPopulationMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_MEETING_ID_DESC',
  TransactionalEmailsByCampaignIdStddevPopulationPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByCampaignIdStddevPopulationPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_POPULATION_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByCampaignIdStddevSampleCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  TransactionalEmailsByCampaignIdStddevSampleCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  TransactionalEmailsByCampaignIdStddevSampleContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  TransactionalEmailsByCampaignIdStddevSampleContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  TransactionalEmailsByCampaignIdStddevSampleCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  TransactionalEmailsByCampaignIdStddevSampleCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  TransactionalEmailsByCampaignIdStddevSampleIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_ASC',
  TransactionalEmailsByCampaignIdStddevSampleIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_ID_DESC',
  TransactionalEmailsByCampaignIdStddevSampleLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  TransactionalEmailsByCampaignIdStddevSampleLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  TransactionalEmailsByCampaignIdStddevSampleMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_MEETING_ID_ASC',
  TransactionalEmailsByCampaignIdStddevSampleMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_MEETING_ID_DESC',
  TransactionalEmailsByCampaignIdStddevSamplePostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByCampaignIdStddevSamplePostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_STDDEV_SAMPLE_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByCampaignIdSumCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_ASC',
  TransactionalEmailsByCampaignIdSumCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_CAMPAIGN_ID_DESC',
  TransactionalEmailsByCampaignIdSumContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_CONTACT_ID_ASC',
  TransactionalEmailsByCampaignIdSumContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_CONTACT_ID_DESC',
  TransactionalEmailsByCampaignIdSumCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_CREATED_TIME_ASC',
  TransactionalEmailsByCampaignIdSumCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_CREATED_TIME_DESC',
  TransactionalEmailsByCampaignIdSumIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_ID_ASC',
  TransactionalEmailsByCampaignIdSumIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_ID_DESC',
  TransactionalEmailsByCampaignIdSumLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_LAWYER_ID_ASC',
  TransactionalEmailsByCampaignIdSumLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_LAWYER_ID_DESC',
  TransactionalEmailsByCampaignIdSumMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_MEETING_ID_ASC',
  TransactionalEmailsByCampaignIdSumMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_MEETING_ID_DESC',
  TransactionalEmailsByCampaignIdSumPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByCampaignIdSumPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_SUM_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByCampaignIdVariancePopulationCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  TransactionalEmailsByCampaignIdVariancePopulationCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  TransactionalEmailsByCampaignIdVariancePopulationContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  TransactionalEmailsByCampaignIdVariancePopulationContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  TransactionalEmailsByCampaignIdVariancePopulationCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  TransactionalEmailsByCampaignIdVariancePopulationCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  TransactionalEmailsByCampaignIdVariancePopulationIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_ASC',
  TransactionalEmailsByCampaignIdVariancePopulationIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_ID_DESC',
  TransactionalEmailsByCampaignIdVariancePopulationLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  TransactionalEmailsByCampaignIdVariancePopulationLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  TransactionalEmailsByCampaignIdVariancePopulationMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_MEETING_ID_ASC',
  TransactionalEmailsByCampaignIdVariancePopulationMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_MEETING_ID_DESC',
  TransactionalEmailsByCampaignIdVariancePopulationPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByCampaignIdVariancePopulationPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_POPULATION_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByCampaignIdVarianceSampleCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  TransactionalEmailsByCampaignIdVarianceSampleCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  TransactionalEmailsByCampaignIdVarianceSampleContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  TransactionalEmailsByCampaignIdVarianceSampleContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  TransactionalEmailsByCampaignIdVarianceSampleCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  TransactionalEmailsByCampaignIdVarianceSampleCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  TransactionalEmailsByCampaignIdVarianceSampleIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_ASC',
  TransactionalEmailsByCampaignIdVarianceSampleIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_ID_DESC',
  TransactionalEmailsByCampaignIdVarianceSampleLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  TransactionalEmailsByCampaignIdVarianceSampleLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  TransactionalEmailsByCampaignIdVarianceSampleMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_MEETING_ID_ASC',
  TransactionalEmailsByCampaignIdVarianceSampleMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_MEETING_ID_DESC',
  TransactionalEmailsByCampaignIdVarianceSamplePostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByCampaignIdVarianceSamplePostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CAMPAIGN_ID_VARIANCE_SAMPLE_POSTMARK_TEMPLATE_ID_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC',
  UseLlmFilterAsc = 'USE_LLM_FILTER_ASC',
  UseLlmFilterDesc = 'USE_LLM_FILTER_DESC',
  VectorMatchThresholdAsc = 'VECTOR_MATCH_THRESHOLD_ASC',
  VectorMatchThresholdDesc = 'VECTOR_MATCH_THRESHOLD_DESC'
}

export enum ChargedPer {
  Lead = 'LEAD',
  Meeting = 'MEETING',
  Month = 'MONTH'
}

/** A filter to be used against ChargedPer fields. All fields are combined with a logical ‘and.’ */
export type ChargedPerFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ChargedPer>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ChargedPer>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ChargedPer>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ChargedPer>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ChargedPer>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ChargedPer>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ChargedPer>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ChargedPer>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ChargedPer>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ChargedPer>>;
};

export type Client = Node & {
  __typename?: 'Client';
  companyNotes?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Contact` that is related to this `Client`. */
  contactByContactId?: Maybe<Contact>;
  contactId: Scalars['UUID']['output'];
  contactNotes?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Lawyer` that is related to this `Client`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

export type ClientAggregates = {
  __typename?: 'ClientAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ClientDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/** A condition to be used against `Client` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ClientCondition = {
  /** Checks for equality with the object’s `companyNotes` field. */
  companyNotes?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `contactId` field. */
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `contactNotes` field. */
  contactNotes?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
};

export type ClientDistinctCountAggregates = {
  __typename?: 'ClientDistinctCountAggregates';
  /** Distinct count of companyNotes across the matching connection */
  companyNotes?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactId across the matching connection */
  contactId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactNotes across the matching connection */
  contactNotes?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Client` object types. All fields are combined with a logical ‘and.’ */
export type ClientFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ClientFilter>>;
  /** Filter by the object’s `companyNotes` field. */
  companyNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `contactId` field. */
  contactId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `contactNotes` field. */
  contactNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ClientFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ClientFilter>>;
};

/** Grouping methods for `Client` for usage during aggregation. */
export enum ClientGroupBy {
  CompanyNotes = 'COMPANY_NOTES',
  ContactId = 'CONTACT_ID',
  ContactNotes = 'CONTACT_NOTES',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  LawyerId = 'LAWYER_ID'
}

export type ClientHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Client` aggregates. */
export type ClientHavingInput = {
  AND?: InputMaybe<Array<ClientHavingInput>>;
  OR?: InputMaybe<Array<ClientHavingInput>>;
  average?: InputMaybe<ClientHavingAverageInput>;
  distinctCount?: InputMaybe<ClientHavingDistinctCountInput>;
  max?: InputMaybe<ClientHavingMaxInput>;
  min?: InputMaybe<ClientHavingMinInput>;
  stddevPopulation?: InputMaybe<ClientHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ClientHavingStddevSampleInput>;
  sum?: InputMaybe<ClientHavingSumInput>;
  variancePopulation?: InputMaybe<ClientHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ClientHavingVarianceSampleInput>;
};

export type ClientHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type ClientSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: ClientSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type ClientSortBy = {
  field?: InputMaybe<ClientSortableField>;
};

/** Sortable concrete fields for the `Client` type. */
export enum ClientSortableField {
  CompanyNotes = 'COMPANY_NOTES',
  ContactId = 'CONTACT_ID',
  ContactNotes = 'CONTACT_NOTES',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  LawyerId = 'LAWYER_ID'
}

/** A connection to a list of `Client` values. */
export type ClientsConnection = {
  __typename?: 'ClientsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ClientAggregates>;
  /** A list of edges which contains the `Client` and cursor to aid in pagination. */
  edges: Array<ClientsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ClientAggregates>>;
  /** A list of `Client` objects. */
  nodes: Array<Maybe<Client>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Client` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Client` values. */
export type ClientsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ClientGroupBy>;
  having?: InputMaybe<ClientHavingInput>;
};

/** A `Client` edge in the connection. */
export type ClientsEdge = {
  __typename?: 'ClientsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Client` at the end of the edge. */
  node?: Maybe<Client>;
};

/** Methods to use when ordering `Client`. */
export enum ClientsOrderBy {
  CompanyNotesAsc = 'COMPANY_NOTES_ASC',
  CompanyNotesDesc = 'COMPANY_NOTES_DESC',
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  ContactNotesAsc = 'CONTACT_NOTES_ASC',
  ContactNotesDesc = 'CONTACT_NOTES_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `Company` values. */
export type CompaniesConnection = {
  __typename?: 'CompaniesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyAggregates>;
  /** A list of edges which contains the `Company` and cursor to aid in pagination. */
  edges: Array<CompaniesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyAggregates>>;
  /** A list of `Company` objects. */
  nodes: Array<Maybe<Company>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Company` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Company` values. */
export type CompaniesConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyGroupBy>;
  having?: InputMaybe<CompanyHavingInput>;
};

/** A `Company` edge in the connection. */
export type CompaniesEdge = {
  __typename?: 'CompaniesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Company` at the end of the edge. */
  node?: Maybe<Company>;
};

/** Methods to use when ordering `Company`. */
export enum CompaniesOrderBy {
  ApolloIdAsc = 'APOLLO_ID_ASC',
  ApolloIdDesc = 'APOLLO_ID_DESC',
  CampaignTargetCompaniesByCompanyIdAverageCampaignTargetIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_AVERAGE_CAMPAIGN_TARGET_ID_ASC',
  CampaignTargetCompaniesByCompanyIdAverageCampaignTargetIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_AVERAGE_CAMPAIGN_TARGET_ID_DESC',
  CampaignTargetCompaniesByCompanyIdAverageCompanyDescriptionAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_AVERAGE_COMPANY_DESCRIPTION_ASC',
  CampaignTargetCompaniesByCompanyIdAverageCompanyDescriptionDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_AVERAGE_COMPANY_DESCRIPTION_DESC',
  CampaignTargetCompaniesByCompanyIdAverageCompanyIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC',
  CampaignTargetCompaniesByCompanyIdAverageCompanyIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC',
  CampaignTargetCompaniesByCompanyIdAverageCreatedTimeAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_AVERAGE_CREATED_TIME_ASC',
  CampaignTargetCompaniesByCompanyIdAverageCreatedTimeDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_AVERAGE_CREATED_TIME_DESC',
  CampaignTargetCompaniesByCompanyIdAverageIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_AVERAGE_ID_ASC',
  CampaignTargetCompaniesByCompanyIdAverageIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_AVERAGE_ID_DESC',
  CampaignTargetCompaniesByCompanyIdCountAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_COUNT_ASC',
  CampaignTargetCompaniesByCompanyIdCountDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_COUNT_DESC',
  CampaignTargetCompaniesByCompanyIdDistinctCountCampaignTargetIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_CAMPAIGN_TARGET_ID_ASC',
  CampaignTargetCompaniesByCompanyIdDistinctCountCampaignTargetIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_CAMPAIGN_TARGET_ID_DESC',
  CampaignTargetCompaniesByCompanyIdDistinctCountCompanyDescriptionAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_DESCRIPTION_ASC',
  CampaignTargetCompaniesByCompanyIdDistinctCountCompanyDescriptionDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_DESCRIPTION_DESC',
  CampaignTargetCompaniesByCompanyIdDistinctCountCompanyIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  CampaignTargetCompaniesByCompanyIdDistinctCountCompanyIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  CampaignTargetCompaniesByCompanyIdDistinctCountCreatedTimeAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CampaignTargetCompaniesByCompanyIdDistinctCountCreatedTimeDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CampaignTargetCompaniesByCompanyIdDistinctCountIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC',
  CampaignTargetCompaniesByCompanyIdDistinctCountIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC',
  CampaignTargetCompaniesByCompanyIdMaxCampaignTargetIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MAX_CAMPAIGN_TARGET_ID_ASC',
  CampaignTargetCompaniesByCompanyIdMaxCampaignTargetIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MAX_CAMPAIGN_TARGET_ID_DESC',
  CampaignTargetCompaniesByCompanyIdMaxCompanyDescriptionAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MAX_COMPANY_DESCRIPTION_ASC',
  CampaignTargetCompaniesByCompanyIdMaxCompanyDescriptionDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MAX_COMPANY_DESCRIPTION_DESC',
  CampaignTargetCompaniesByCompanyIdMaxCompanyIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MAX_COMPANY_ID_ASC',
  CampaignTargetCompaniesByCompanyIdMaxCompanyIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MAX_COMPANY_ID_DESC',
  CampaignTargetCompaniesByCompanyIdMaxCreatedTimeAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MAX_CREATED_TIME_ASC',
  CampaignTargetCompaniesByCompanyIdMaxCreatedTimeDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MAX_CREATED_TIME_DESC',
  CampaignTargetCompaniesByCompanyIdMaxIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MAX_ID_ASC',
  CampaignTargetCompaniesByCompanyIdMaxIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MAX_ID_DESC',
  CampaignTargetCompaniesByCompanyIdMinCampaignTargetIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MIN_CAMPAIGN_TARGET_ID_ASC',
  CampaignTargetCompaniesByCompanyIdMinCampaignTargetIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MIN_CAMPAIGN_TARGET_ID_DESC',
  CampaignTargetCompaniesByCompanyIdMinCompanyDescriptionAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MIN_COMPANY_DESCRIPTION_ASC',
  CampaignTargetCompaniesByCompanyIdMinCompanyDescriptionDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MIN_COMPANY_DESCRIPTION_DESC',
  CampaignTargetCompaniesByCompanyIdMinCompanyIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MIN_COMPANY_ID_ASC',
  CampaignTargetCompaniesByCompanyIdMinCompanyIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MIN_COMPANY_ID_DESC',
  CampaignTargetCompaniesByCompanyIdMinCreatedTimeAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MIN_CREATED_TIME_ASC',
  CampaignTargetCompaniesByCompanyIdMinCreatedTimeDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MIN_CREATED_TIME_DESC',
  CampaignTargetCompaniesByCompanyIdMinIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MIN_ID_ASC',
  CampaignTargetCompaniesByCompanyIdMinIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_MIN_ID_DESC',
  CampaignTargetCompaniesByCompanyIdStddevPopulationCampaignTargetIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_CAMPAIGN_TARGET_ID_ASC',
  CampaignTargetCompaniesByCompanyIdStddevPopulationCampaignTargetIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_CAMPAIGN_TARGET_ID_DESC',
  CampaignTargetCompaniesByCompanyIdStddevPopulationCompanyDescriptionAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_DESCRIPTION_ASC',
  CampaignTargetCompaniesByCompanyIdStddevPopulationCompanyDescriptionDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_DESCRIPTION_DESC',
  CampaignTargetCompaniesByCompanyIdStddevPopulationCompanyIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  CampaignTargetCompaniesByCompanyIdStddevPopulationCompanyIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  CampaignTargetCompaniesByCompanyIdStddevPopulationCreatedTimeAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CampaignTargetCompaniesByCompanyIdStddevPopulationCreatedTimeDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CampaignTargetCompaniesByCompanyIdStddevPopulationIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC',
  CampaignTargetCompaniesByCompanyIdStddevPopulationIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC',
  CampaignTargetCompaniesByCompanyIdStddevSampleCampaignTargetIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_CAMPAIGN_TARGET_ID_ASC',
  CampaignTargetCompaniesByCompanyIdStddevSampleCampaignTargetIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_CAMPAIGN_TARGET_ID_DESC',
  CampaignTargetCompaniesByCompanyIdStddevSampleCompanyDescriptionAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_DESCRIPTION_ASC',
  CampaignTargetCompaniesByCompanyIdStddevSampleCompanyDescriptionDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_DESCRIPTION_DESC',
  CampaignTargetCompaniesByCompanyIdStddevSampleCompanyIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CampaignTargetCompaniesByCompanyIdStddevSampleCompanyIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CampaignTargetCompaniesByCompanyIdStddevSampleCreatedTimeAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CampaignTargetCompaniesByCompanyIdStddevSampleCreatedTimeDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CampaignTargetCompaniesByCompanyIdStddevSampleIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC',
  CampaignTargetCompaniesByCompanyIdStddevSampleIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC',
  CampaignTargetCompaniesByCompanyIdSumCampaignTargetIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_SUM_CAMPAIGN_TARGET_ID_ASC',
  CampaignTargetCompaniesByCompanyIdSumCampaignTargetIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_SUM_CAMPAIGN_TARGET_ID_DESC',
  CampaignTargetCompaniesByCompanyIdSumCompanyDescriptionAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_SUM_COMPANY_DESCRIPTION_ASC',
  CampaignTargetCompaniesByCompanyIdSumCompanyDescriptionDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_SUM_COMPANY_DESCRIPTION_DESC',
  CampaignTargetCompaniesByCompanyIdSumCompanyIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_SUM_COMPANY_ID_ASC',
  CampaignTargetCompaniesByCompanyIdSumCompanyIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_SUM_COMPANY_ID_DESC',
  CampaignTargetCompaniesByCompanyIdSumCreatedTimeAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_SUM_CREATED_TIME_ASC',
  CampaignTargetCompaniesByCompanyIdSumCreatedTimeDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_SUM_CREATED_TIME_DESC',
  CampaignTargetCompaniesByCompanyIdSumIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_SUM_ID_ASC',
  CampaignTargetCompaniesByCompanyIdSumIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_SUM_ID_DESC',
  CampaignTargetCompaniesByCompanyIdVariancePopulationCampaignTargetIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_CAMPAIGN_TARGET_ID_ASC',
  CampaignTargetCompaniesByCompanyIdVariancePopulationCampaignTargetIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_CAMPAIGN_TARGET_ID_DESC',
  CampaignTargetCompaniesByCompanyIdVariancePopulationCompanyDescriptionAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_DESCRIPTION_ASC',
  CampaignTargetCompaniesByCompanyIdVariancePopulationCompanyDescriptionDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_DESCRIPTION_DESC',
  CampaignTargetCompaniesByCompanyIdVariancePopulationCompanyIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CampaignTargetCompaniesByCompanyIdVariancePopulationCompanyIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CampaignTargetCompaniesByCompanyIdVariancePopulationCreatedTimeAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CampaignTargetCompaniesByCompanyIdVariancePopulationCreatedTimeDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CampaignTargetCompaniesByCompanyIdVariancePopulationIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC',
  CampaignTargetCompaniesByCompanyIdVariancePopulationIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC',
  CampaignTargetCompaniesByCompanyIdVarianceSampleCampaignTargetIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_CAMPAIGN_TARGET_ID_ASC',
  CampaignTargetCompaniesByCompanyIdVarianceSampleCampaignTargetIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_CAMPAIGN_TARGET_ID_DESC',
  CampaignTargetCompaniesByCompanyIdVarianceSampleCompanyDescriptionAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_DESCRIPTION_ASC',
  CampaignTargetCompaniesByCompanyIdVarianceSampleCompanyDescriptionDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_DESCRIPTION_DESC',
  CampaignTargetCompaniesByCompanyIdVarianceSampleCompanyIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CampaignTargetCompaniesByCompanyIdVarianceSampleCompanyIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CampaignTargetCompaniesByCompanyIdVarianceSampleCreatedTimeAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CampaignTargetCompaniesByCompanyIdVarianceSampleCreatedTimeDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CampaignTargetCompaniesByCompanyIdVarianceSampleIdAsc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC',
  CampaignTargetCompaniesByCompanyIdVarianceSampleIdDesc = 'CAMPAIGN_TARGET_COMPANIES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC',
  CompanyEnrichmentLogsByCompanyIdAverageCompanyIdAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC',
  CompanyEnrichmentLogsByCompanyIdAverageCompanyIdDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC',
  CompanyEnrichmentLogsByCompanyIdAverageCreatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_AVERAGE_CREATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdAverageCreatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_AVERAGE_CREATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdAverageEnrichmentStatusAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_AVERAGE_ENRICHMENT_STATUS_ASC',
  CompanyEnrichmentLogsByCompanyIdAverageEnrichmentStatusDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_AVERAGE_ENRICHMENT_STATUS_DESC',
  CompanyEnrichmentLogsByCompanyIdAverageEnrichmentTypeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_AVERAGE_ENRICHMENT_TYPE_ASC',
  CompanyEnrichmentLogsByCompanyIdAverageEnrichmentTypeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_AVERAGE_ENRICHMENT_TYPE_DESC',
  CompanyEnrichmentLogsByCompanyIdAverageUpdatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_AVERAGE_UPDATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdAverageUpdatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_AVERAGE_UPDATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdCountAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_COUNT_ASC',
  CompanyEnrichmentLogsByCompanyIdCountDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_COUNT_DESC',
  CompanyEnrichmentLogsByCompanyIdDistinctCountCompanyIdAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyEnrichmentLogsByCompanyIdDistinctCountCompanyIdDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyEnrichmentLogsByCompanyIdDistinctCountCreatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdDistinctCountCreatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdDistinctCountEnrichmentStatusAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_DISTINCT_COUNT_ENRICHMENT_STATUS_ASC',
  CompanyEnrichmentLogsByCompanyIdDistinctCountEnrichmentStatusDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_DISTINCT_COUNT_ENRICHMENT_STATUS_DESC',
  CompanyEnrichmentLogsByCompanyIdDistinctCountEnrichmentTypeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_DISTINCT_COUNT_ENRICHMENT_TYPE_ASC',
  CompanyEnrichmentLogsByCompanyIdDistinctCountEnrichmentTypeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_DISTINCT_COUNT_ENRICHMENT_TYPE_DESC',
  CompanyEnrichmentLogsByCompanyIdDistinctCountUpdatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdDistinctCountUpdatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdMaxCompanyIdAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC',
  CompanyEnrichmentLogsByCompanyIdMaxCompanyIdDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC',
  CompanyEnrichmentLogsByCompanyIdMaxCreatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MAX_CREATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdMaxCreatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MAX_CREATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdMaxEnrichmentStatusAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MAX_ENRICHMENT_STATUS_ASC',
  CompanyEnrichmentLogsByCompanyIdMaxEnrichmentStatusDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MAX_ENRICHMENT_STATUS_DESC',
  CompanyEnrichmentLogsByCompanyIdMaxEnrichmentTypeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MAX_ENRICHMENT_TYPE_ASC',
  CompanyEnrichmentLogsByCompanyIdMaxEnrichmentTypeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MAX_ENRICHMENT_TYPE_DESC',
  CompanyEnrichmentLogsByCompanyIdMaxUpdatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MAX_UPDATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdMaxUpdatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MAX_UPDATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdMinCompanyIdAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC',
  CompanyEnrichmentLogsByCompanyIdMinCompanyIdDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC',
  CompanyEnrichmentLogsByCompanyIdMinCreatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MIN_CREATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdMinCreatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MIN_CREATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdMinEnrichmentStatusAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MIN_ENRICHMENT_STATUS_ASC',
  CompanyEnrichmentLogsByCompanyIdMinEnrichmentStatusDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MIN_ENRICHMENT_STATUS_DESC',
  CompanyEnrichmentLogsByCompanyIdMinEnrichmentTypeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MIN_ENRICHMENT_TYPE_ASC',
  CompanyEnrichmentLogsByCompanyIdMinEnrichmentTypeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MIN_ENRICHMENT_TYPE_DESC',
  CompanyEnrichmentLogsByCompanyIdMinUpdatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MIN_UPDATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdMinUpdatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_MIN_UPDATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdStddevPopulationCompanyIdAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyEnrichmentLogsByCompanyIdStddevPopulationCompanyIdDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyEnrichmentLogsByCompanyIdStddevPopulationCreatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdStddevPopulationCreatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdStddevPopulationEnrichmentStatusAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_POPULATION_ENRICHMENT_STATUS_ASC',
  CompanyEnrichmentLogsByCompanyIdStddevPopulationEnrichmentStatusDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_POPULATION_ENRICHMENT_STATUS_DESC',
  CompanyEnrichmentLogsByCompanyIdStddevPopulationEnrichmentTypeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_POPULATION_ENRICHMENT_TYPE_ASC',
  CompanyEnrichmentLogsByCompanyIdStddevPopulationEnrichmentTypeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_POPULATION_ENRICHMENT_TYPE_DESC',
  CompanyEnrichmentLogsByCompanyIdStddevPopulationUpdatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdStddevPopulationUpdatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdStddevSampleCompanyIdAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyEnrichmentLogsByCompanyIdStddevSampleCompanyIdDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyEnrichmentLogsByCompanyIdStddevSampleCreatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdStddevSampleCreatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdStddevSampleEnrichmentStatusAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_SAMPLE_ENRICHMENT_STATUS_ASC',
  CompanyEnrichmentLogsByCompanyIdStddevSampleEnrichmentStatusDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_SAMPLE_ENRICHMENT_STATUS_DESC',
  CompanyEnrichmentLogsByCompanyIdStddevSampleEnrichmentTypeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_SAMPLE_ENRICHMENT_TYPE_ASC',
  CompanyEnrichmentLogsByCompanyIdStddevSampleEnrichmentTypeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_SAMPLE_ENRICHMENT_TYPE_DESC',
  CompanyEnrichmentLogsByCompanyIdStddevSampleUpdatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdStddevSampleUpdatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdSumCompanyIdAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC',
  CompanyEnrichmentLogsByCompanyIdSumCompanyIdDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC',
  CompanyEnrichmentLogsByCompanyIdSumCreatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_SUM_CREATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdSumCreatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_SUM_CREATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdSumEnrichmentStatusAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_SUM_ENRICHMENT_STATUS_ASC',
  CompanyEnrichmentLogsByCompanyIdSumEnrichmentStatusDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_SUM_ENRICHMENT_STATUS_DESC',
  CompanyEnrichmentLogsByCompanyIdSumEnrichmentTypeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_SUM_ENRICHMENT_TYPE_ASC',
  CompanyEnrichmentLogsByCompanyIdSumEnrichmentTypeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_SUM_ENRICHMENT_TYPE_DESC',
  CompanyEnrichmentLogsByCompanyIdSumUpdatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_SUM_UPDATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdSumUpdatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_SUM_UPDATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdVariancePopulationCompanyIdAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyEnrichmentLogsByCompanyIdVariancePopulationCompanyIdDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyEnrichmentLogsByCompanyIdVariancePopulationCreatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdVariancePopulationCreatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdVariancePopulationEnrichmentStatusAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_POPULATION_ENRICHMENT_STATUS_ASC',
  CompanyEnrichmentLogsByCompanyIdVariancePopulationEnrichmentStatusDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_POPULATION_ENRICHMENT_STATUS_DESC',
  CompanyEnrichmentLogsByCompanyIdVariancePopulationEnrichmentTypeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_POPULATION_ENRICHMENT_TYPE_ASC',
  CompanyEnrichmentLogsByCompanyIdVariancePopulationEnrichmentTypeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_POPULATION_ENRICHMENT_TYPE_DESC',
  CompanyEnrichmentLogsByCompanyIdVariancePopulationUpdatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdVariancePopulationUpdatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdVarianceSampleCompanyIdAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyEnrichmentLogsByCompanyIdVarianceSampleCompanyIdDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyEnrichmentLogsByCompanyIdVarianceSampleCreatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdVarianceSampleCreatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CompanyEnrichmentLogsByCompanyIdVarianceSampleEnrichmentStatusAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_SAMPLE_ENRICHMENT_STATUS_ASC',
  CompanyEnrichmentLogsByCompanyIdVarianceSampleEnrichmentStatusDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_SAMPLE_ENRICHMENT_STATUS_DESC',
  CompanyEnrichmentLogsByCompanyIdVarianceSampleEnrichmentTypeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_SAMPLE_ENRICHMENT_TYPE_ASC',
  CompanyEnrichmentLogsByCompanyIdVarianceSampleEnrichmentTypeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_SAMPLE_ENRICHMENT_TYPE_DESC',
  CompanyEnrichmentLogsByCompanyIdVarianceSampleUpdatedTimeAsc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  CompanyEnrichmentLogsByCompanyIdVarianceSampleUpdatedTimeDesc = 'COMPANY_ENRICHMENT_LOGS_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  CompanyEnrichmentLogLinksByCompanyIdAverageCompanyIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdAverageCompanyIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdAverageEnrichmentIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_AVERAGE_ENRICHMENT_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdAverageEnrichmentIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_AVERAGE_ENRICHMENT_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdCountAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_COUNT_ASC',
  CompanyEnrichmentLogLinksByCompanyIdCountDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_COUNT_DESC',
  CompanyEnrichmentLogLinksByCompanyIdDistinctCountCompanyIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdDistinctCountCompanyIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdDistinctCountEnrichmentIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_DISTINCT_COUNT_ENRICHMENT_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdDistinctCountEnrichmentIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_DISTINCT_COUNT_ENRICHMENT_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdMaxCompanyIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdMaxCompanyIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdMaxEnrichmentIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_MAX_ENRICHMENT_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdMaxEnrichmentIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_MAX_ENRICHMENT_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdMinCompanyIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdMinCompanyIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdMinEnrichmentIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_MIN_ENRICHMENT_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdMinEnrichmentIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_MIN_ENRICHMENT_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdStddevPopulationCompanyIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdStddevPopulationCompanyIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdStddevPopulationEnrichmentIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_STDDEV_POPULATION_ENRICHMENT_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdStddevPopulationEnrichmentIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_STDDEV_POPULATION_ENRICHMENT_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdStddevSampleCompanyIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdStddevSampleCompanyIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdStddevSampleEnrichmentIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_STDDEV_SAMPLE_ENRICHMENT_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdStddevSampleEnrichmentIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_STDDEV_SAMPLE_ENRICHMENT_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdSumCompanyIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdSumCompanyIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdSumEnrichmentIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_SUM_ENRICHMENT_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdSumEnrichmentIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_SUM_ENRICHMENT_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdVariancePopulationCompanyIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdVariancePopulationCompanyIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdVariancePopulationEnrichmentIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_VARIANCE_POPULATION_ENRICHMENT_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdVariancePopulationEnrichmentIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_VARIANCE_POPULATION_ENRICHMENT_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdVarianceSampleCompanyIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdVarianceSampleCompanyIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyEnrichmentLogLinksByCompanyIdVarianceSampleEnrichmentIdAsc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_VARIANCE_SAMPLE_ENRICHMENT_ID_ASC',
  CompanyEnrichmentLogLinksByCompanyIdVarianceSampleEnrichmentIdDesc = 'COMPANY_ENRICHMENT_LOG_LINKS_BY_COMPANY_ID_VARIANCE_SAMPLE_ENRICHMENT_ID_DESC',
  CompanyFormDsByCompanyIdAverageAccessionNumberAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_ACCESSION_NUMBER_ASC',
  CompanyFormDsByCompanyIdAverageAccessionNumberDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_ACCESSION_NUMBER_DESC',
  CompanyFormDsByCompanyIdAverageCikAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_CIK_ASC',
  CompanyFormDsByCompanyIdAverageCikDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_CIK_DESC',
  CompanyFormDsByCompanyIdAverageCompanyIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC',
  CompanyFormDsByCompanyIdAverageCompanyIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC',
  CompanyFormDsByCompanyIdAverageEntityNameAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_ENTITY_NAME_ASC',
  CompanyFormDsByCompanyIdAverageEntityNameDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_ENTITY_NAME_DESC',
  CompanyFormDsByCompanyIdAverageEntityTypeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_ENTITY_TYPE_ASC',
  CompanyFormDsByCompanyIdAverageEntityTypeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_ENTITY_TYPE_DESC',
  CompanyFormDsByCompanyIdAverageHasNonAccreditedInvestorsAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_HAS_NON_ACCREDITED_INVESTORS_ASC',
  CompanyFormDsByCompanyIdAverageHasNonAccreditedInvestorsDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_HAS_NON_ACCREDITED_INVESTORS_DESC',
  CompanyFormDsByCompanyIdAverageIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_ID_ASC',
  CompanyFormDsByCompanyIdAverageIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_ID_DESC',
  CompanyFormDsByCompanyIdAverageIndustryGroupAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_INDUSTRY_GROUP_ASC',
  CompanyFormDsByCompanyIdAverageIndustryGroupDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_INDUSTRY_GROUP_DESC',
  CompanyFormDsByCompanyIdAverageJurisdictionOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_JURISDICTION_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdAverageJurisdictionOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_JURISDICTION_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdAveragePayloadAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_PAYLOAD_ASC',
  CompanyFormDsByCompanyIdAveragePayloadDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_PAYLOAD_DESC',
  CompanyFormDsByCompanyIdAverageSignatureDateAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_SIGNATURE_DATE_ASC',
  CompanyFormDsByCompanyIdAverageSignatureDateDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_SIGNATURE_DATE_DESC',
  CompanyFormDsByCompanyIdAverageTotalAmountSoldAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_TOTAL_AMOUNT_SOLD_ASC',
  CompanyFormDsByCompanyIdAverageTotalAmountSoldDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_TOTAL_AMOUNT_SOLD_DESC',
  CompanyFormDsByCompanyIdAverageTotalNumberAlreadyInvestedAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_TOTAL_NUMBER_ALREADY_INVESTED_ASC',
  CompanyFormDsByCompanyIdAverageTotalNumberAlreadyInvestedDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_TOTAL_NUMBER_ALREADY_INVESTED_DESC',
  CompanyFormDsByCompanyIdAverageTotalOfferingAmountAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_TOTAL_OFFERING_AMOUNT_ASC',
  CompanyFormDsByCompanyIdAverageTotalOfferingAmountDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_TOTAL_OFFERING_AMOUNT_DESC',
  CompanyFormDsByCompanyIdAverageUpdatedTimeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_UPDATED_TIME_ASC',
  CompanyFormDsByCompanyIdAverageUpdatedTimeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_UPDATED_TIME_DESC',
  CompanyFormDsByCompanyIdAverageYearOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_YEAR_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdAverageYearOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_AVERAGE_YEAR_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdCountAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_COUNT_ASC',
  CompanyFormDsByCompanyIdCountDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_COUNT_DESC',
  CompanyFormDsByCompanyIdDistinctCountAccessionNumberAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_ACCESSION_NUMBER_ASC',
  CompanyFormDsByCompanyIdDistinctCountAccessionNumberDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_ACCESSION_NUMBER_DESC',
  CompanyFormDsByCompanyIdDistinctCountCikAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_CIK_ASC',
  CompanyFormDsByCompanyIdDistinctCountCikDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_CIK_DESC',
  CompanyFormDsByCompanyIdDistinctCountCompanyIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyFormDsByCompanyIdDistinctCountCompanyIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyFormDsByCompanyIdDistinctCountEntityNameAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_ENTITY_NAME_ASC',
  CompanyFormDsByCompanyIdDistinctCountEntityNameDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_ENTITY_NAME_DESC',
  CompanyFormDsByCompanyIdDistinctCountEntityTypeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_ENTITY_TYPE_ASC',
  CompanyFormDsByCompanyIdDistinctCountEntityTypeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_ENTITY_TYPE_DESC',
  CompanyFormDsByCompanyIdDistinctCountHasNonAccreditedInvestorsAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_HAS_NON_ACCREDITED_INVESTORS_ASC',
  CompanyFormDsByCompanyIdDistinctCountHasNonAccreditedInvestorsDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_HAS_NON_ACCREDITED_INVESTORS_DESC',
  CompanyFormDsByCompanyIdDistinctCountIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC',
  CompanyFormDsByCompanyIdDistinctCountIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC',
  CompanyFormDsByCompanyIdDistinctCountIndustryGroupAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_INDUSTRY_GROUP_ASC',
  CompanyFormDsByCompanyIdDistinctCountIndustryGroupDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_INDUSTRY_GROUP_DESC',
  CompanyFormDsByCompanyIdDistinctCountJurisdictionOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_JURISDICTION_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdDistinctCountJurisdictionOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_JURISDICTION_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdDistinctCountPayloadAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_PAYLOAD_ASC',
  CompanyFormDsByCompanyIdDistinctCountPayloadDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_PAYLOAD_DESC',
  CompanyFormDsByCompanyIdDistinctCountSignatureDateAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_SIGNATURE_DATE_ASC',
  CompanyFormDsByCompanyIdDistinctCountSignatureDateDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_SIGNATURE_DATE_DESC',
  CompanyFormDsByCompanyIdDistinctCountTotalAmountSoldAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_TOTAL_AMOUNT_SOLD_ASC',
  CompanyFormDsByCompanyIdDistinctCountTotalAmountSoldDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_TOTAL_AMOUNT_SOLD_DESC',
  CompanyFormDsByCompanyIdDistinctCountTotalNumberAlreadyInvestedAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_TOTAL_NUMBER_ALREADY_INVESTED_ASC',
  CompanyFormDsByCompanyIdDistinctCountTotalNumberAlreadyInvestedDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_TOTAL_NUMBER_ALREADY_INVESTED_DESC',
  CompanyFormDsByCompanyIdDistinctCountTotalOfferingAmountAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_TOTAL_OFFERING_AMOUNT_ASC',
  CompanyFormDsByCompanyIdDistinctCountTotalOfferingAmountDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_TOTAL_OFFERING_AMOUNT_DESC',
  CompanyFormDsByCompanyIdDistinctCountUpdatedTimeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  CompanyFormDsByCompanyIdDistinctCountUpdatedTimeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  CompanyFormDsByCompanyIdDistinctCountYearOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdDistinctCountYearOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_DISTINCT_COUNT_YEAR_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdMaxAccessionNumberAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_ACCESSION_NUMBER_ASC',
  CompanyFormDsByCompanyIdMaxAccessionNumberDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_ACCESSION_NUMBER_DESC',
  CompanyFormDsByCompanyIdMaxCikAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_CIK_ASC',
  CompanyFormDsByCompanyIdMaxCikDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_CIK_DESC',
  CompanyFormDsByCompanyIdMaxCompanyIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC',
  CompanyFormDsByCompanyIdMaxCompanyIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC',
  CompanyFormDsByCompanyIdMaxEntityNameAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_ENTITY_NAME_ASC',
  CompanyFormDsByCompanyIdMaxEntityNameDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_ENTITY_NAME_DESC',
  CompanyFormDsByCompanyIdMaxEntityTypeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_ENTITY_TYPE_ASC',
  CompanyFormDsByCompanyIdMaxEntityTypeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_ENTITY_TYPE_DESC',
  CompanyFormDsByCompanyIdMaxHasNonAccreditedInvestorsAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_HAS_NON_ACCREDITED_INVESTORS_ASC',
  CompanyFormDsByCompanyIdMaxHasNonAccreditedInvestorsDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_HAS_NON_ACCREDITED_INVESTORS_DESC',
  CompanyFormDsByCompanyIdMaxIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_ID_ASC',
  CompanyFormDsByCompanyIdMaxIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_ID_DESC',
  CompanyFormDsByCompanyIdMaxIndustryGroupAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_INDUSTRY_GROUP_ASC',
  CompanyFormDsByCompanyIdMaxIndustryGroupDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_INDUSTRY_GROUP_DESC',
  CompanyFormDsByCompanyIdMaxJurisdictionOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_JURISDICTION_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdMaxJurisdictionOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_JURISDICTION_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdMaxPayloadAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_PAYLOAD_ASC',
  CompanyFormDsByCompanyIdMaxPayloadDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_PAYLOAD_DESC',
  CompanyFormDsByCompanyIdMaxSignatureDateAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_SIGNATURE_DATE_ASC',
  CompanyFormDsByCompanyIdMaxSignatureDateDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_SIGNATURE_DATE_DESC',
  CompanyFormDsByCompanyIdMaxTotalAmountSoldAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_TOTAL_AMOUNT_SOLD_ASC',
  CompanyFormDsByCompanyIdMaxTotalAmountSoldDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_TOTAL_AMOUNT_SOLD_DESC',
  CompanyFormDsByCompanyIdMaxTotalNumberAlreadyInvestedAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_TOTAL_NUMBER_ALREADY_INVESTED_ASC',
  CompanyFormDsByCompanyIdMaxTotalNumberAlreadyInvestedDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_TOTAL_NUMBER_ALREADY_INVESTED_DESC',
  CompanyFormDsByCompanyIdMaxTotalOfferingAmountAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_TOTAL_OFFERING_AMOUNT_ASC',
  CompanyFormDsByCompanyIdMaxTotalOfferingAmountDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_TOTAL_OFFERING_AMOUNT_DESC',
  CompanyFormDsByCompanyIdMaxUpdatedTimeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_UPDATED_TIME_ASC',
  CompanyFormDsByCompanyIdMaxUpdatedTimeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_UPDATED_TIME_DESC',
  CompanyFormDsByCompanyIdMaxYearOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_YEAR_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdMaxYearOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MAX_YEAR_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdMinAccessionNumberAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_ACCESSION_NUMBER_ASC',
  CompanyFormDsByCompanyIdMinAccessionNumberDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_ACCESSION_NUMBER_DESC',
  CompanyFormDsByCompanyIdMinCikAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_CIK_ASC',
  CompanyFormDsByCompanyIdMinCikDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_CIK_DESC',
  CompanyFormDsByCompanyIdMinCompanyIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC',
  CompanyFormDsByCompanyIdMinCompanyIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC',
  CompanyFormDsByCompanyIdMinEntityNameAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_ENTITY_NAME_ASC',
  CompanyFormDsByCompanyIdMinEntityNameDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_ENTITY_NAME_DESC',
  CompanyFormDsByCompanyIdMinEntityTypeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_ENTITY_TYPE_ASC',
  CompanyFormDsByCompanyIdMinEntityTypeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_ENTITY_TYPE_DESC',
  CompanyFormDsByCompanyIdMinHasNonAccreditedInvestorsAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_HAS_NON_ACCREDITED_INVESTORS_ASC',
  CompanyFormDsByCompanyIdMinHasNonAccreditedInvestorsDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_HAS_NON_ACCREDITED_INVESTORS_DESC',
  CompanyFormDsByCompanyIdMinIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_ID_ASC',
  CompanyFormDsByCompanyIdMinIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_ID_DESC',
  CompanyFormDsByCompanyIdMinIndustryGroupAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_INDUSTRY_GROUP_ASC',
  CompanyFormDsByCompanyIdMinIndustryGroupDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_INDUSTRY_GROUP_DESC',
  CompanyFormDsByCompanyIdMinJurisdictionOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_JURISDICTION_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdMinJurisdictionOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_JURISDICTION_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdMinPayloadAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_PAYLOAD_ASC',
  CompanyFormDsByCompanyIdMinPayloadDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_PAYLOAD_DESC',
  CompanyFormDsByCompanyIdMinSignatureDateAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_SIGNATURE_DATE_ASC',
  CompanyFormDsByCompanyIdMinSignatureDateDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_SIGNATURE_DATE_DESC',
  CompanyFormDsByCompanyIdMinTotalAmountSoldAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_TOTAL_AMOUNT_SOLD_ASC',
  CompanyFormDsByCompanyIdMinTotalAmountSoldDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_TOTAL_AMOUNT_SOLD_DESC',
  CompanyFormDsByCompanyIdMinTotalNumberAlreadyInvestedAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_TOTAL_NUMBER_ALREADY_INVESTED_ASC',
  CompanyFormDsByCompanyIdMinTotalNumberAlreadyInvestedDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_TOTAL_NUMBER_ALREADY_INVESTED_DESC',
  CompanyFormDsByCompanyIdMinTotalOfferingAmountAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_TOTAL_OFFERING_AMOUNT_ASC',
  CompanyFormDsByCompanyIdMinTotalOfferingAmountDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_TOTAL_OFFERING_AMOUNT_DESC',
  CompanyFormDsByCompanyIdMinUpdatedTimeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_UPDATED_TIME_ASC',
  CompanyFormDsByCompanyIdMinUpdatedTimeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_UPDATED_TIME_DESC',
  CompanyFormDsByCompanyIdMinYearOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_YEAR_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdMinYearOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_MIN_YEAR_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdStddevPopulationAccessionNumberAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_ACCESSION_NUMBER_ASC',
  CompanyFormDsByCompanyIdStddevPopulationAccessionNumberDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_ACCESSION_NUMBER_DESC',
  CompanyFormDsByCompanyIdStddevPopulationCikAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_CIK_ASC',
  CompanyFormDsByCompanyIdStddevPopulationCikDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_CIK_DESC',
  CompanyFormDsByCompanyIdStddevPopulationCompanyIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyFormDsByCompanyIdStddevPopulationCompanyIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyFormDsByCompanyIdStddevPopulationEntityNameAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_ENTITY_NAME_ASC',
  CompanyFormDsByCompanyIdStddevPopulationEntityNameDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_ENTITY_NAME_DESC',
  CompanyFormDsByCompanyIdStddevPopulationEntityTypeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_ENTITY_TYPE_ASC',
  CompanyFormDsByCompanyIdStddevPopulationEntityTypeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_ENTITY_TYPE_DESC',
  CompanyFormDsByCompanyIdStddevPopulationHasNonAccreditedInvestorsAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_HAS_NON_ACCREDITED_INVESTORS_ASC',
  CompanyFormDsByCompanyIdStddevPopulationHasNonAccreditedInvestorsDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_HAS_NON_ACCREDITED_INVESTORS_DESC',
  CompanyFormDsByCompanyIdStddevPopulationIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC',
  CompanyFormDsByCompanyIdStddevPopulationIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC',
  CompanyFormDsByCompanyIdStddevPopulationIndustryGroupAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_INDUSTRY_GROUP_ASC',
  CompanyFormDsByCompanyIdStddevPopulationIndustryGroupDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_INDUSTRY_GROUP_DESC',
  CompanyFormDsByCompanyIdStddevPopulationJurisdictionOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_JURISDICTION_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdStddevPopulationJurisdictionOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_JURISDICTION_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdStddevPopulationPayloadAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_PAYLOAD_ASC',
  CompanyFormDsByCompanyIdStddevPopulationPayloadDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_PAYLOAD_DESC',
  CompanyFormDsByCompanyIdStddevPopulationSignatureDateAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_SIGNATURE_DATE_ASC',
  CompanyFormDsByCompanyIdStddevPopulationSignatureDateDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_SIGNATURE_DATE_DESC',
  CompanyFormDsByCompanyIdStddevPopulationTotalAmountSoldAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_TOTAL_AMOUNT_SOLD_ASC',
  CompanyFormDsByCompanyIdStddevPopulationTotalAmountSoldDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_TOTAL_AMOUNT_SOLD_DESC',
  CompanyFormDsByCompanyIdStddevPopulationTotalNumberAlreadyInvestedAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_TOTAL_NUMBER_ALREADY_INVESTED_ASC',
  CompanyFormDsByCompanyIdStddevPopulationTotalNumberAlreadyInvestedDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_TOTAL_NUMBER_ALREADY_INVESTED_DESC',
  CompanyFormDsByCompanyIdStddevPopulationTotalOfferingAmountAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_TOTAL_OFFERING_AMOUNT_ASC',
  CompanyFormDsByCompanyIdStddevPopulationTotalOfferingAmountDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_TOTAL_OFFERING_AMOUNT_DESC',
  CompanyFormDsByCompanyIdStddevPopulationUpdatedTimeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  CompanyFormDsByCompanyIdStddevPopulationUpdatedTimeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  CompanyFormDsByCompanyIdStddevPopulationYearOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdStddevPopulationYearOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_POPULATION_YEAR_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdStddevSampleAccessionNumberAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_ACCESSION_NUMBER_ASC',
  CompanyFormDsByCompanyIdStddevSampleAccessionNumberDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_ACCESSION_NUMBER_DESC',
  CompanyFormDsByCompanyIdStddevSampleCikAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_CIK_ASC',
  CompanyFormDsByCompanyIdStddevSampleCikDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_CIK_DESC',
  CompanyFormDsByCompanyIdStddevSampleCompanyIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyFormDsByCompanyIdStddevSampleCompanyIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyFormDsByCompanyIdStddevSampleEntityNameAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_ENTITY_NAME_ASC',
  CompanyFormDsByCompanyIdStddevSampleEntityNameDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_ENTITY_NAME_DESC',
  CompanyFormDsByCompanyIdStddevSampleEntityTypeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_ENTITY_TYPE_ASC',
  CompanyFormDsByCompanyIdStddevSampleEntityTypeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_ENTITY_TYPE_DESC',
  CompanyFormDsByCompanyIdStddevSampleHasNonAccreditedInvestorsAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_HAS_NON_ACCREDITED_INVESTORS_ASC',
  CompanyFormDsByCompanyIdStddevSampleHasNonAccreditedInvestorsDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_HAS_NON_ACCREDITED_INVESTORS_DESC',
  CompanyFormDsByCompanyIdStddevSampleIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC',
  CompanyFormDsByCompanyIdStddevSampleIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC',
  CompanyFormDsByCompanyIdStddevSampleIndustryGroupAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_INDUSTRY_GROUP_ASC',
  CompanyFormDsByCompanyIdStddevSampleIndustryGroupDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_INDUSTRY_GROUP_DESC',
  CompanyFormDsByCompanyIdStddevSampleJurisdictionOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_JURISDICTION_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdStddevSampleJurisdictionOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_JURISDICTION_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdStddevSamplePayloadAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_PAYLOAD_ASC',
  CompanyFormDsByCompanyIdStddevSamplePayloadDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_PAYLOAD_DESC',
  CompanyFormDsByCompanyIdStddevSampleSignatureDateAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_SIGNATURE_DATE_ASC',
  CompanyFormDsByCompanyIdStddevSampleSignatureDateDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_SIGNATURE_DATE_DESC',
  CompanyFormDsByCompanyIdStddevSampleTotalAmountSoldAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_TOTAL_AMOUNT_SOLD_ASC',
  CompanyFormDsByCompanyIdStddevSampleTotalAmountSoldDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_TOTAL_AMOUNT_SOLD_DESC',
  CompanyFormDsByCompanyIdStddevSampleTotalNumberAlreadyInvestedAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_TOTAL_NUMBER_ALREADY_INVESTED_ASC',
  CompanyFormDsByCompanyIdStddevSampleTotalNumberAlreadyInvestedDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_TOTAL_NUMBER_ALREADY_INVESTED_DESC',
  CompanyFormDsByCompanyIdStddevSampleTotalOfferingAmountAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_TOTAL_OFFERING_AMOUNT_ASC',
  CompanyFormDsByCompanyIdStddevSampleTotalOfferingAmountDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_TOTAL_OFFERING_AMOUNT_DESC',
  CompanyFormDsByCompanyIdStddevSampleUpdatedTimeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  CompanyFormDsByCompanyIdStddevSampleUpdatedTimeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  CompanyFormDsByCompanyIdStddevSampleYearOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdStddevSampleYearOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_STDDEV_SAMPLE_YEAR_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdSumAccessionNumberAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_ACCESSION_NUMBER_ASC',
  CompanyFormDsByCompanyIdSumAccessionNumberDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_ACCESSION_NUMBER_DESC',
  CompanyFormDsByCompanyIdSumCikAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_CIK_ASC',
  CompanyFormDsByCompanyIdSumCikDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_CIK_DESC',
  CompanyFormDsByCompanyIdSumCompanyIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC',
  CompanyFormDsByCompanyIdSumCompanyIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC',
  CompanyFormDsByCompanyIdSumEntityNameAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_ENTITY_NAME_ASC',
  CompanyFormDsByCompanyIdSumEntityNameDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_ENTITY_NAME_DESC',
  CompanyFormDsByCompanyIdSumEntityTypeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_ENTITY_TYPE_ASC',
  CompanyFormDsByCompanyIdSumEntityTypeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_ENTITY_TYPE_DESC',
  CompanyFormDsByCompanyIdSumHasNonAccreditedInvestorsAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_HAS_NON_ACCREDITED_INVESTORS_ASC',
  CompanyFormDsByCompanyIdSumHasNonAccreditedInvestorsDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_HAS_NON_ACCREDITED_INVESTORS_DESC',
  CompanyFormDsByCompanyIdSumIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_ID_ASC',
  CompanyFormDsByCompanyIdSumIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_ID_DESC',
  CompanyFormDsByCompanyIdSumIndustryGroupAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_INDUSTRY_GROUP_ASC',
  CompanyFormDsByCompanyIdSumIndustryGroupDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_INDUSTRY_GROUP_DESC',
  CompanyFormDsByCompanyIdSumJurisdictionOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_JURISDICTION_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdSumJurisdictionOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_JURISDICTION_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdSumPayloadAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_PAYLOAD_ASC',
  CompanyFormDsByCompanyIdSumPayloadDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_PAYLOAD_DESC',
  CompanyFormDsByCompanyIdSumSignatureDateAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_SIGNATURE_DATE_ASC',
  CompanyFormDsByCompanyIdSumSignatureDateDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_SIGNATURE_DATE_DESC',
  CompanyFormDsByCompanyIdSumTotalAmountSoldAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_TOTAL_AMOUNT_SOLD_ASC',
  CompanyFormDsByCompanyIdSumTotalAmountSoldDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_TOTAL_AMOUNT_SOLD_DESC',
  CompanyFormDsByCompanyIdSumTotalNumberAlreadyInvestedAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_TOTAL_NUMBER_ALREADY_INVESTED_ASC',
  CompanyFormDsByCompanyIdSumTotalNumberAlreadyInvestedDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_TOTAL_NUMBER_ALREADY_INVESTED_DESC',
  CompanyFormDsByCompanyIdSumTotalOfferingAmountAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_TOTAL_OFFERING_AMOUNT_ASC',
  CompanyFormDsByCompanyIdSumTotalOfferingAmountDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_TOTAL_OFFERING_AMOUNT_DESC',
  CompanyFormDsByCompanyIdSumUpdatedTimeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_UPDATED_TIME_ASC',
  CompanyFormDsByCompanyIdSumUpdatedTimeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_UPDATED_TIME_DESC',
  CompanyFormDsByCompanyIdSumYearOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_YEAR_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdSumYearOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_SUM_YEAR_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdVariancePopulationAccessionNumberAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_ACCESSION_NUMBER_ASC',
  CompanyFormDsByCompanyIdVariancePopulationAccessionNumberDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_ACCESSION_NUMBER_DESC',
  CompanyFormDsByCompanyIdVariancePopulationCikAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_CIK_ASC',
  CompanyFormDsByCompanyIdVariancePopulationCikDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_CIK_DESC',
  CompanyFormDsByCompanyIdVariancePopulationCompanyIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyFormDsByCompanyIdVariancePopulationCompanyIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyFormDsByCompanyIdVariancePopulationEntityNameAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_ENTITY_NAME_ASC',
  CompanyFormDsByCompanyIdVariancePopulationEntityNameDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_ENTITY_NAME_DESC',
  CompanyFormDsByCompanyIdVariancePopulationEntityTypeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_ENTITY_TYPE_ASC',
  CompanyFormDsByCompanyIdVariancePopulationEntityTypeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_ENTITY_TYPE_DESC',
  CompanyFormDsByCompanyIdVariancePopulationHasNonAccreditedInvestorsAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_HAS_NON_ACCREDITED_INVESTORS_ASC',
  CompanyFormDsByCompanyIdVariancePopulationHasNonAccreditedInvestorsDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_HAS_NON_ACCREDITED_INVESTORS_DESC',
  CompanyFormDsByCompanyIdVariancePopulationIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC',
  CompanyFormDsByCompanyIdVariancePopulationIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC',
  CompanyFormDsByCompanyIdVariancePopulationIndustryGroupAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_INDUSTRY_GROUP_ASC',
  CompanyFormDsByCompanyIdVariancePopulationIndustryGroupDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_INDUSTRY_GROUP_DESC',
  CompanyFormDsByCompanyIdVariancePopulationJurisdictionOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_JURISDICTION_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdVariancePopulationJurisdictionOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_JURISDICTION_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdVariancePopulationPayloadAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_PAYLOAD_ASC',
  CompanyFormDsByCompanyIdVariancePopulationPayloadDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_PAYLOAD_DESC',
  CompanyFormDsByCompanyIdVariancePopulationSignatureDateAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_SIGNATURE_DATE_ASC',
  CompanyFormDsByCompanyIdVariancePopulationSignatureDateDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_SIGNATURE_DATE_DESC',
  CompanyFormDsByCompanyIdVariancePopulationTotalAmountSoldAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_TOTAL_AMOUNT_SOLD_ASC',
  CompanyFormDsByCompanyIdVariancePopulationTotalAmountSoldDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_TOTAL_AMOUNT_SOLD_DESC',
  CompanyFormDsByCompanyIdVariancePopulationTotalNumberAlreadyInvestedAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_TOTAL_NUMBER_ALREADY_INVESTED_ASC',
  CompanyFormDsByCompanyIdVariancePopulationTotalNumberAlreadyInvestedDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_TOTAL_NUMBER_ALREADY_INVESTED_DESC',
  CompanyFormDsByCompanyIdVariancePopulationTotalOfferingAmountAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_TOTAL_OFFERING_AMOUNT_ASC',
  CompanyFormDsByCompanyIdVariancePopulationTotalOfferingAmountDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_TOTAL_OFFERING_AMOUNT_DESC',
  CompanyFormDsByCompanyIdVariancePopulationUpdatedTimeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  CompanyFormDsByCompanyIdVariancePopulationUpdatedTimeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  CompanyFormDsByCompanyIdVariancePopulationYearOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdVariancePopulationYearOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_POPULATION_YEAR_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdVarianceSampleAccessionNumberAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_ACCESSION_NUMBER_ASC',
  CompanyFormDsByCompanyIdVarianceSampleAccessionNumberDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_ACCESSION_NUMBER_DESC',
  CompanyFormDsByCompanyIdVarianceSampleCikAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_CIK_ASC',
  CompanyFormDsByCompanyIdVarianceSampleCikDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_CIK_DESC',
  CompanyFormDsByCompanyIdVarianceSampleCompanyIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyFormDsByCompanyIdVarianceSampleCompanyIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyFormDsByCompanyIdVarianceSampleEntityNameAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_ENTITY_NAME_ASC',
  CompanyFormDsByCompanyIdVarianceSampleEntityNameDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_ENTITY_NAME_DESC',
  CompanyFormDsByCompanyIdVarianceSampleEntityTypeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_ENTITY_TYPE_ASC',
  CompanyFormDsByCompanyIdVarianceSampleEntityTypeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_ENTITY_TYPE_DESC',
  CompanyFormDsByCompanyIdVarianceSampleHasNonAccreditedInvestorsAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_HAS_NON_ACCREDITED_INVESTORS_ASC',
  CompanyFormDsByCompanyIdVarianceSampleHasNonAccreditedInvestorsDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_HAS_NON_ACCREDITED_INVESTORS_DESC',
  CompanyFormDsByCompanyIdVarianceSampleIdAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC',
  CompanyFormDsByCompanyIdVarianceSampleIdDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC',
  CompanyFormDsByCompanyIdVarianceSampleIndustryGroupAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_INDUSTRY_GROUP_ASC',
  CompanyFormDsByCompanyIdVarianceSampleIndustryGroupDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_INDUSTRY_GROUP_DESC',
  CompanyFormDsByCompanyIdVarianceSampleJurisdictionOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_JURISDICTION_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdVarianceSampleJurisdictionOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_JURISDICTION_OF_INCORPORATION_DESC',
  CompanyFormDsByCompanyIdVarianceSamplePayloadAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_PAYLOAD_ASC',
  CompanyFormDsByCompanyIdVarianceSamplePayloadDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_PAYLOAD_DESC',
  CompanyFormDsByCompanyIdVarianceSampleSignatureDateAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_SIGNATURE_DATE_ASC',
  CompanyFormDsByCompanyIdVarianceSampleSignatureDateDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_SIGNATURE_DATE_DESC',
  CompanyFormDsByCompanyIdVarianceSampleTotalAmountSoldAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_TOTAL_AMOUNT_SOLD_ASC',
  CompanyFormDsByCompanyIdVarianceSampleTotalAmountSoldDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_TOTAL_AMOUNT_SOLD_DESC',
  CompanyFormDsByCompanyIdVarianceSampleTotalNumberAlreadyInvestedAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_TOTAL_NUMBER_ALREADY_INVESTED_ASC',
  CompanyFormDsByCompanyIdVarianceSampleTotalNumberAlreadyInvestedDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_TOTAL_NUMBER_ALREADY_INVESTED_DESC',
  CompanyFormDsByCompanyIdVarianceSampleTotalOfferingAmountAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_TOTAL_OFFERING_AMOUNT_ASC',
  CompanyFormDsByCompanyIdVarianceSampleTotalOfferingAmountDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_TOTAL_OFFERING_AMOUNT_DESC',
  CompanyFormDsByCompanyIdVarianceSampleUpdatedTimeAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  CompanyFormDsByCompanyIdVarianceSampleUpdatedTimeDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  CompanyFormDsByCompanyIdVarianceSampleYearOfIncorporationAsc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_OF_INCORPORATION_ASC',
  CompanyFormDsByCompanyIdVarianceSampleYearOfIncorporationDesc = 'COMPANY_FORM_DS_BY_COMPANY_ID_VARIANCE_SAMPLE_YEAR_OF_INCORPORATION_DESC',
  CompanyLegalNamesByCompanyIdAverageCompanyIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC',
  CompanyLegalNamesByCompanyIdAverageCompanyIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC',
  CompanyLegalNamesByCompanyIdAverageIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_AVERAGE_ID_ASC',
  CompanyLegalNamesByCompanyIdAverageIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_AVERAGE_ID_DESC',
  CompanyLegalNamesByCompanyIdAverageLegalNameAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_AVERAGE_LEGAL_NAME_ASC',
  CompanyLegalNamesByCompanyIdAverageLegalNameDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_AVERAGE_LEGAL_NAME_DESC',
  CompanyLegalNamesByCompanyIdCountAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_COUNT_ASC',
  CompanyLegalNamesByCompanyIdCountDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_COUNT_DESC',
  CompanyLegalNamesByCompanyIdDistinctCountCompanyIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyLegalNamesByCompanyIdDistinctCountCompanyIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyLegalNamesByCompanyIdDistinctCountIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC',
  CompanyLegalNamesByCompanyIdDistinctCountIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC',
  CompanyLegalNamesByCompanyIdDistinctCountLegalNameAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_DISTINCT_COUNT_LEGAL_NAME_ASC',
  CompanyLegalNamesByCompanyIdDistinctCountLegalNameDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_DISTINCT_COUNT_LEGAL_NAME_DESC',
  CompanyLegalNamesByCompanyIdMaxCompanyIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_MAX_COMPANY_ID_ASC',
  CompanyLegalNamesByCompanyIdMaxCompanyIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_MAX_COMPANY_ID_DESC',
  CompanyLegalNamesByCompanyIdMaxIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_MAX_ID_ASC',
  CompanyLegalNamesByCompanyIdMaxIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_MAX_ID_DESC',
  CompanyLegalNamesByCompanyIdMaxLegalNameAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_MAX_LEGAL_NAME_ASC',
  CompanyLegalNamesByCompanyIdMaxLegalNameDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_MAX_LEGAL_NAME_DESC',
  CompanyLegalNamesByCompanyIdMinCompanyIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_MIN_COMPANY_ID_ASC',
  CompanyLegalNamesByCompanyIdMinCompanyIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_MIN_COMPANY_ID_DESC',
  CompanyLegalNamesByCompanyIdMinIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_MIN_ID_ASC',
  CompanyLegalNamesByCompanyIdMinIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_MIN_ID_DESC',
  CompanyLegalNamesByCompanyIdMinLegalNameAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_MIN_LEGAL_NAME_ASC',
  CompanyLegalNamesByCompanyIdMinLegalNameDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_MIN_LEGAL_NAME_DESC',
  CompanyLegalNamesByCompanyIdStddevPopulationCompanyIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyLegalNamesByCompanyIdStddevPopulationCompanyIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyLegalNamesByCompanyIdStddevPopulationIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC',
  CompanyLegalNamesByCompanyIdStddevPopulationIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC',
  CompanyLegalNamesByCompanyIdStddevPopulationLegalNameAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_STDDEV_POPULATION_LEGAL_NAME_ASC',
  CompanyLegalNamesByCompanyIdStddevPopulationLegalNameDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_STDDEV_POPULATION_LEGAL_NAME_DESC',
  CompanyLegalNamesByCompanyIdStddevSampleCompanyIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyLegalNamesByCompanyIdStddevSampleCompanyIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyLegalNamesByCompanyIdStddevSampleIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC',
  CompanyLegalNamesByCompanyIdStddevSampleIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC',
  CompanyLegalNamesByCompanyIdStddevSampleLegalNameAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_STDDEV_SAMPLE_LEGAL_NAME_ASC',
  CompanyLegalNamesByCompanyIdStddevSampleLegalNameDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_STDDEV_SAMPLE_LEGAL_NAME_DESC',
  CompanyLegalNamesByCompanyIdSumCompanyIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_SUM_COMPANY_ID_ASC',
  CompanyLegalNamesByCompanyIdSumCompanyIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_SUM_COMPANY_ID_DESC',
  CompanyLegalNamesByCompanyIdSumIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_SUM_ID_ASC',
  CompanyLegalNamesByCompanyIdSumIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_SUM_ID_DESC',
  CompanyLegalNamesByCompanyIdSumLegalNameAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_SUM_LEGAL_NAME_ASC',
  CompanyLegalNamesByCompanyIdSumLegalNameDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_SUM_LEGAL_NAME_DESC',
  CompanyLegalNamesByCompanyIdVariancePopulationCompanyIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyLegalNamesByCompanyIdVariancePopulationCompanyIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyLegalNamesByCompanyIdVariancePopulationIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC',
  CompanyLegalNamesByCompanyIdVariancePopulationIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC',
  CompanyLegalNamesByCompanyIdVariancePopulationLegalNameAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_VARIANCE_POPULATION_LEGAL_NAME_ASC',
  CompanyLegalNamesByCompanyIdVariancePopulationLegalNameDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_VARIANCE_POPULATION_LEGAL_NAME_DESC',
  CompanyLegalNamesByCompanyIdVarianceSampleCompanyIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyLegalNamesByCompanyIdVarianceSampleCompanyIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyLegalNamesByCompanyIdVarianceSampleIdAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC',
  CompanyLegalNamesByCompanyIdVarianceSampleIdDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC',
  CompanyLegalNamesByCompanyIdVarianceSampleLegalNameAsc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_VARIANCE_SAMPLE_LEGAL_NAME_ASC',
  CompanyLegalNamesByCompanyIdVarianceSampleLegalNameDesc = 'COMPANY_LEGAL_NAMES_BY_COMPANY_ID_VARIANCE_SAMPLE_LEGAL_NAME_DESC',
  CompanyPerigonPayloadsByCompanyIdAverageCompanyIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdAverageCompanyIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdAverageIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_AVERAGE_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdAverageIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_AVERAGE_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdAveragePayloadAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_AVERAGE_PAYLOAD_ASC',
  CompanyPerigonPayloadsByCompanyIdAveragePayloadDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_AVERAGE_PAYLOAD_DESC',
  CompanyPerigonPayloadsByCompanyIdCountAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_COUNT_ASC',
  CompanyPerigonPayloadsByCompanyIdCountDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_COUNT_DESC',
  CompanyPerigonPayloadsByCompanyIdDistinctCountCompanyIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdDistinctCountCompanyIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdDistinctCountIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdDistinctCountIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdDistinctCountPayloadAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_DISTINCT_COUNT_PAYLOAD_ASC',
  CompanyPerigonPayloadsByCompanyIdDistinctCountPayloadDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_DISTINCT_COUNT_PAYLOAD_DESC',
  CompanyPerigonPayloadsByCompanyIdMaxCompanyIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdMaxCompanyIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdMaxIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_MAX_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdMaxIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_MAX_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdMaxPayloadAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_MAX_PAYLOAD_ASC',
  CompanyPerigonPayloadsByCompanyIdMaxPayloadDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_MAX_PAYLOAD_DESC',
  CompanyPerigonPayloadsByCompanyIdMinCompanyIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdMinCompanyIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdMinIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_MIN_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdMinIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_MIN_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdMinPayloadAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_MIN_PAYLOAD_ASC',
  CompanyPerigonPayloadsByCompanyIdMinPayloadDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_MIN_PAYLOAD_DESC',
  CompanyPerigonPayloadsByCompanyIdStddevPopulationCompanyIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdStddevPopulationCompanyIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdStddevPopulationIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdStddevPopulationIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdStddevPopulationPayloadAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_STDDEV_POPULATION_PAYLOAD_ASC',
  CompanyPerigonPayloadsByCompanyIdStddevPopulationPayloadDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_STDDEV_POPULATION_PAYLOAD_DESC',
  CompanyPerigonPayloadsByCompanyIdStddevSampleCompanyIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdStddevSampleCompanyIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdStddevSampleIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdStddevSampleIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdStddevSamplePayloadAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_STDDEV_SAMPLE_PAYLOAD_ASC',
  CompanyPerigonPayloadsByCompanyIdStddevSamplePayloadDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_STDDEV_SAMPLE_PAYLOAD_DESC',
  CompanyPerigonPayloadsByCompanyIdSumCompanyIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdSumCompanyIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdSumIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_SUM_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdSumIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_SUM_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdSumPayloadAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_SUM_PAYLOAD_ASC',
  CompanyPerigonPayloadsByCompanyIdSumPayloadDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_SUM_PAYLOAD_DESC',
  CompanyPerigonPayloadsByCompanyIdVariancePopulationCompanyIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdVariancePopulationCompanyIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdVariancePopulationIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdVariancePopulationIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdVariancePopulationPayloadAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_VARIANCE_POPULATION_PAYLOAD_ASC',
  CompanyPerigonPayloadsByCompanyIdVariancePopulationPayloadDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_VARIANCE_POPULATION_PAYLOAD_DESC',
  CompanyPerigonPayloadsByCompanyIdVarianceSampleCompanyIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdVarianceSampleCompanyIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdVarianceSampleIdAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC',
  CompanyPerigonPayloadsByCompanyIdVarianceSampleIdDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC',
  CompanyPerigonPayloadsByCompanyIdVarianceSamplePayloadAsc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_VARIANCE_SAMPLE_PAYLOAD_ASC',
  CompanyPerigonPayloadsByCompanyIdVarianceSamplePayloadDesc = 'COMPANY_PERIGON_PAYLOADS_BY_COMPANY_ID_VARIANCE_SAMPLE_PAYLOAD_DESC',
  CompanyPersonalisationsByCompanyIdAverageCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC',
  CompanyPersonalisationsByCompanyIdAverageCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC',
  CompanyPersonalisationsByCompanyIdAverageDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_AVERAGE_DATE_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdAverageDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_AVERAGE_DATE_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdAverageIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_AVERAGE_ID_ASC',
  CompanyPersonalisationsByCompanyIdAverageIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_AVERAGE_ID_DESC',
  CompanyPersonalisationsByCompanyIdAverageIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_AVERAGE_INT_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdAverageIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_AVERAGE_INT_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdAverageStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_AVERAGE_STR_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdAverageStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_AVERAGE_STR_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdAverageTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_AVERAGE_TYPE_ID_ASC',
  CompanyPersonalisationsByCompanyIdAverageTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_AVERAGE_TYPE_ID_DESC',
  CompanyPersonalisationsByCompanyIdCountAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_COUNT_ASC',
  CompanyPersonalisationsByCompanyIdCountDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_COUNT_DESC',
  CompanyPersonalisationsByCompanyIdDistinctCountCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyPersonalisationsByCompanyIdDistinctCountCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyPersonalisationsByCompanyIdDistinctCountDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_DISTINCT_COUNT_DATE_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdDistinctCountDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_DISTINCT_COUNT_DATE_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdDistinctCountIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC',
  CompanyPersonalisationsByCompanyIdDistinctCountIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC',
  CompanyPersonalisationsByCompanyIdDistinctCountIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_DISTINCT_COUNT_INT_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdDistinctCountIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_DISTINCT_COUNT_INT_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdDistinctCountStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_DISTINCT_COUNT_STR_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdDistinctCountStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_DISTINCT_COUNT_STR_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdDistinctCountTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_DISTINCT_COUNT_TYPE_ID_ASC',
  CompanyPersonalisationsByCompanyIdDistinctCountTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_DISTINCT_COUNT_TYPE_ID_DESC',
  CompanyPersonalisationsByCompanyIdMaxCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC',
  CompanyPersonalisationsByCompanyIdMaxCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC',
  CompanyPersonalisationsByCompanyIdMaxDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MAX_DATE_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdMaxDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MAX_DATE_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdMaxIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MAX_ID_ASC',
  CompanyPersonalisationsByCompanyIdMaxIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MAX_ID_DESC',
  CompanyPersonalisationsByCompanyIdMaxIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MAX_INT_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdMaxIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MAX_INT_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdMaxStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MAX_STR_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdMaxStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MAX_STR_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdMaxTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MAX_TYPE_ID_ASC',
  CompanyPersonalisationsByCompanyIdMaxTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MAX_TYPE_ID_DESC',
  CompanyPersonalisationsByCompanyIdMinCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC',
  CompanyPersonalisationsByCompanyIdMinCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC',
  CompanyPersonalisationsByCompanyIdMinDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MIN_DATE_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdMinDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MIN_DATE_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdMinIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MIN_ID_ASC',
  CompanyPersonalisationsByCompanyIdMinIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MIN_ID_DESC',
  CompanyPersonalisationsByCompanyIdMinIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MIN_INT_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdMinIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MIN_INT_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdMinStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MIN_STR_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdMinStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MIN_STR_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdMinTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MIN_TYPE_ID_ASC',
  CompanyPersonalisationsByCompanyIdMinTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_MIN_TYPE_ID_DESC',
  CompanyPersonalisationsByCompanyIdStddevPopulationCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyPersonalisationsByCompanyIdStddevPopulationCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyPersonalisationsByCompanyIdStddevPopulationDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_POPULATION_DATE_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdStddevPopulationDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_POPULATION_DATE_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdStddevPopulationIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC',
  CompanyPersonalisationsByCompanyIdStddevPopulationIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC',
  CompanyPersonalisationsByCompanyIdStddevPopulationIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_POPULATION_INT_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdStddevPopulationIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_POPULATION_INT_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdStddevPopulationStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_POPULATION_STR_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdStddevPopulationStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_POPULATION_STR_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdStddevPopulationTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_POPULATION_TYPE_ID_ASC',
  CompanyPersonalisationsByCompanyIdStddevPopulationTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_POPULATION_TYPE_ID_DESC',
  CompanyPersonalisationsByCompanyIdStddevSampleCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyPersonalisationsByCompanyIdStddevSampleCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyPersonalisationsByCompanyIdStddevSampleDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_SAMPLE_DATE_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdStddevSampleDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_SAMPLE_DATE_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdStddevSampleIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC',
  CompanyPersonalisationsByCompanyIdStddevSampleIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC',
  CompanyPersonalisationsByCompanyIdStddevSampleIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_SAMPLE_INT_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdStddevSampleIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_SAMPLE_INT_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdStddevSampleStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_SAMPLE_STR_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdStddevSampleStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_SAMPLE_STR_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdStddevSampleTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_SAMPLE_TYPE_ID_ASC',
  CompanyPersonalisationsByCompanyIdStddevSampleTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_STDDEV_SAMPLE_TYPE_ID_DESC',
  CompanyPersonalisationsByCompanyIdSumCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC',
  CompanyPersonalisationsByCompanyIdSumCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC',
  CompanyPersonalisationsByCompanyIdSumDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_SUM_DATE_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdSumDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_SUM_DATE_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdSumIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_SUM_ID_ASC',
  CompanyPersonalisationsByCompanyIdSumIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_SUM_ID_DESC',
  CompanyPersonalisationsByCompanyIdSumIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_SUM_INT_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdSumIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_SUM_INT_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdSumStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_SUM_STR_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdSumStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_SUM_STR_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdSumTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_SUM_TYPE_ID_ASC',
  CompanyPersonalisationsByCompanyIdSumTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_SUM_TYPE_ID_DESC',
  CompanyPersonalisationsByCompanyIdVariancePopulationCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyPersonalisationsByCompanyIdVariancePopulationCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyPersonalisationsByCompanyIdVariancePopulationDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_POPULATION_DATE_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdVariancePopulationDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_POPULATION_DATE_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdVariancePopulationIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC',
  CompanyPersonalisationsByCompanyIdVariancePopulationIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC',
  CompanyPersonalisationsByCompanyIdVariancePopulationIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_POPULATION_INT_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdVariancePopulationIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_POPULATION_INT_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdVariancePopulationStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_POPULATION_STR_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdVariancePopulationStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_POPULATION_STR_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdVariancePopulationTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_POPULATION_TYPE_ID_ASC',
  CompanyPersonalisationsByCompanyIdVariancePopulationTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_POPULATION_TYPE_ID_DESC',
  CompanyPersonalisationsByCompanyIdVarianceSampleCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyPersonalisationsByCompanyIdVarianceSampleCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyPersonalisationsByCompanyIdVarianceSampleDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_SAMPLE_DATE_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdVarianceSampleDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_SAMPLE_DATE_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdVarianceSampleIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC',
  CompanyPersonalisationsByCompanyIdVarianceSampleIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC',
  CompanyPersonalisationsByCompanyIdVarianceSampleIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_SAMPLE_INT_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdVarianceSampleIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_SAMPLE_INT_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdVarianceSampleStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_SAMPLE_STR_VALUE_ASC',
  CompanyPersonalisationsByCompanyIdVarianceSampleStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_SAMPLE_STR_VALUE_DESC',
  CompanyPersonalisationsByCompanyIdVarianceSampleTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_SAMPLE_TYPE_ID_ASC',
  CompanyPersonalisationsByCompanyIdVarianceSampleTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_COMPANY_ID_VARIANCE_SAMPLE_TYPE_ID_DESC',
  CompanyVectorsByCompanyIdAverageCollectionAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_AVERAGE_COLLECTION_ASC',
  CompanyVectorsByCompanyIdAverageCollectionDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_AVERAGE_COLLECTION_DESC',
  CompanyVectorsByCompanyIdAverageCompanyIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC',
  CompanyVectorsByCompanyIdAverageCompanyIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC',
  CompanyVectorsByCompanyIdAverageEmbeddingIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_AVERAGE_EMBEDDING_ID_ASC',
  CompanyVectorsByCompanyIdAverageEmbeddingIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_AVERAGE_EMBEDDING_ID_DESC',
  CompanyVectorsByCompanyIdCountAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_COUNT_ASC',
  CompanyVectorsByCompanyIdCountDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_COUNT_DESC',
  CompanyVectorsByCompanyIdDistinctCountCollectionAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_DISTINCT_COUNT_COLLECTION_ASC',
  CompanyVectorsByCompanyIdDistinctCountCollectionDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_DISTINCT_COUNT_COLLECTION_DESC',
  CompanyVectorsByCompanyIdDistinctCountCompanyIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyVectorsByCompanyIdDistinctCountCompanyIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyVectorsByCompanyIdDistinctCountEmbeddingIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_DISTINCT_COUNT_EMBEDDING_ID_ASC',
  CompanyVectorsByCompanyIdDistinctCountEmbeddingIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_DISTINCT_COUNT_EMBEDDING_ID_DESC',
  CompanyVectorsByCompanyIdMaxCollectionAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_MAX_COLLECTION_ASC',
  CompanyVectorsByCompanyIdMaxCollectionDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_MAX_COLLECTION_DESC',
  CompanyVectorsByCompanyIdMaxCompanyIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC',
  CompanyVectorsByCompanyIdMaxCompanyIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC',
  CompanyVectorsByCompanyIdMaxEmbeddingIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_MAX_EMBEDDING_ID_ASC',
  CompanyVectorsByCompanyIdMaxEmbeddingIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_MAX_EMBEDDING_ID_DESC',
  CompanyVectorsByCompanyIdMinCollectionAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_MIN_COLLECTION_ASC',
  CompanyVectorsByCompanyIdMinCollectionDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_MIN_COLLECTION_DESC',
  CompanyVectorsByCompanyIdMinCompanyIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC',
  CompanyVectorsByCompanyIdMinCompanyIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC',
  CompanyVectorsByCompanyIdMinEmbeddingIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_MIN_EMBEDDING_ID_ASC',
  CompanyVectorsByCompanyIdMinEmbeddingIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_MIN_EMBEDDING_ID_DESC',
  CompanyVectorsByCompanyIdStddevPopulationCollectionAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_STDDEV_POPULATION_COLLECTION_ASC',
  CompanyVectorsByCompanyIdStddevPopulationCollectionDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_STDDEV_POPULATION_COLLECTION_DESC',
  CompanyVectorsByCompanyIdStddevPopulationCompanyIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyVectorsByCompanyIdStddevPopulationCompanyIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyVectorsByCompanyIdStddevPopulationEmbeddingIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_STDDEV_POPULATION_EMBEDDING_ID_ASC',
  CompanyVectorsByCompanyIdStddevPopulationEmbeddingIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_STDDEV_POPULATION_EMBEDDING_ID_DESC',
  CompanyVectorsByCompanyIdStddevSampleCollectionAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_STDDEV_SAMPLE_COLLECTION_ASC',
  CompanyVectorsByCompanyIdStddevSampleCollectionDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_STDDEV_SAMPLE_COLLECTION_DESC',
  CompanyVectorsByCompanyIdStddevSampleCompanyIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyVectorsByCompanyIdStddevSampleCompanyIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyVectorsByCompanyIdStddevSampleEmbeddingIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_STDDEV_SAMPLE_EMBEDDING_ID_ASC',
  CompanyVectorsByCompanyIdStddevSampleEmbeddingIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_STDDEV_SAMPLE_EMBEDDING_ID_DESC',
  CompanyVectorsByCompanyIdSumCollectionAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_SUM_COLLECTION_ASC',
  CompanyVectorsByCompanyIdSumCollectionDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_SUM_COLLECTION_DESC',
  CompanyVectorsByCompanyIdSumCompanyIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC',
  CompanyVectorsByCompanyIdSumCompanyIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC',
  CompanyVectorsByCompanyIdSumEmbeddingIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_SUM_EMBEDDING_ID_ASC',
  CompanyVectorsByCompanyIdSumEmbeddingIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_SUM_EMBEDDING_ID_DESC',
  CompanyVectorsByCompanyIdVariancePopulationCollectionAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_VARIANCE_POPULATION_COLLECTION_ASC',
  CompanyVectorsByCompanyIdVariancePopulationCollectionDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_VARIANCE_POPULATION_COLLECTION_DESC',
  CompanyVectorsByCompanyIdVariancePopulationCompanyIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyVectorsByCompanyIdVariancePopulationCompanyIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyVectorsByCompanyIdVariancePopulationEmbeddingIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_VARIANCE_POPULATION_EMBEDDING_ID_ASC',
  CompanyVectorsByCompanyIdVariancePopulationEmbeddingIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_VARIANCE_POPULATION_EMBEDDING_ID_DESC',
  CompanyVectorsByCompanyIdVarianceSampleCollectionAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_COLLECTION_ASC',
  CompanyVectorsByCompanyIdVarianceSampleCollectionDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_COLLECTION_DESC',
  CompanyVectorsByCompanyIdVarianceSampleCompanyIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyVectorsByCompanyIdVarianceSampleCompanyIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyVectorsByCompanyIdVarianceSampleEmbeddingIdAsc = 'COMPANY_VECTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_EMBEDDING_ID_ASC',
  CompanyVectorsByCompanyIdVarianceSampleEmbeddingIdDesc = 'COMPANY_VECTORS_BY_COMPANY_ID_VARIANCE_SAMPLE_EMBEDDING_ID_DESC',
  ContactsByCompanyIdAverageApolloEnrichmentPayloadAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_APOLLO_ENRICHMENT_PAYLOAD_ASC',
  ContactsByCompanyIdAverageApolloEnrichmentPayloadDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_APOLLO_ENRICHMENT_PAYLOAD_DESC',
  ContactsByCompanyIdAverageApolloIdAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_APOLLO_ID_ASC',
  ContactsByCompanyIdAverageApolloIdDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_APOLLO_ID_DESC',
  ContactsByCompanyIdAverageApolloQueryIdAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_APOLLO_QUERY_ID_ASC',
  ContactsByCompanyIdAverageApolloQueryIdDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_APOLLO_QUERY_ID_DESC',
  ContactsByCompanyIdAverageCompanyIdAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC',
  ContactsByCompanyIdAverageCompanyIdDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC',
  ContactsByCompanyIdAverageCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_COMPANY_NAME_ASC',
  ContactsByCompanyIdAverageCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_COMPANY_NAME_DESC',
  ContactsByCompanyIdAverageCreatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_CREATED_TIME_ASC',
  ContactsByCompanyIdAverageCreatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_CREATED_TIME_DESC',
  ContactsByCompanyIdAverageCrunchbaseUrlAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_CRUNCHBASE_URL_ASC',
  ContactsByCompanyIdAverageCrunchbaseUrlDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_CRUNCHBASE_URL_DESC',
  ContactsByCompanyIdAverageEmailAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_EMAIL_ASC',
  ContactsByCompanyIdAverageEmailDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_EMAIL_DESC',
  ContactsByCompanyIdAverageEmailDomainRevAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_EMAIL_DOMAIN_REV_ASC',
  ContactsByCompanyIdAverageEmailDomainRevDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_EMAIL_DOMAIN_REV_DESC',
  ContactsByCompanyIdAverageFacebookUrlAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_FACEBOOK_URL_ASC',
  ContactsByCompanyIdAverageFacebookUrlDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_FACEBOOK_URL_DESC',
  ContactsByCompanyIdAverageFirstNameAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_FIRST_NAME_ASC',
  ContactsByCompanyIdAverageFirstNameDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_FIRST_NAME_DESC',
  ContactsByCompanyIdAverageIdAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_ID_ASC',
  ContactsByCompanyIdAverageIdDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_ID_DESC',
  ContactsByCompanyIdAverageLastEnrichedAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_LAST_ENRICHED_ASC',
  ContactsByCompanyIdAverageLastEnrichedDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_LAST_ENRICHED_DESC',
  ContactsByCompanyIdAverageLastNameAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_LAST_NAME_ASC',
  ContactsByCompanyIdAverageLastNameDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_LAST_NAME_DESC',
  ContactsByCompanyIdAverageLinkedinCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_LINKEDIN_COMPANY_NAME_ASC',
  ContactsByCompanyIdAverageLinkedinCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_LINKEDIN_COMPANY_NAME_DESC',
  ContactsByCompanyIdAverageLinkedinNameAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_LINKEDIN_NAME_ASC',
  ContactsByCompanyIdAverageLinkedinNameDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_LINKEDIN_NAME_DESC',
  ContactsByCompanyIdAverageLinkedinUidAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_LINKEDIN_UID_ASC',
  ContactsByCompanyIdAverageLinkedinUidDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_LINKEDIN_UID_DESC',
  ContactsByCompanyIdAverageLinkedinUrlAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_LINKEDIN_URL_ASC',
  ContactsByCompanyIdAverageLinkedinUrlDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_LINKEDIN_URL_DESC',
  ContactsByCompanyIdAveragePhoneNumberAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_PHONE_NUMBER_ASC',
  ContactsByCompanyIdAveragePhoneNumberDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_PHONE_NUMBER_DESC',
  ContactsByCompanyIdAveragePhotoUrlAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_PHOTO_URL_ASC',
  ContactsByCompanyIdAveragePhotoUrlDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_PHOTO_URL_DESC',
  ContactsByCompanyIdAverageSummaryAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_SUMMARY_ASC',
  ContactsByCompanyIdAverageSummaryDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_SUMMARY_DESC',
  ContactsByCompanyIdAverageTimezoneAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_TIMEZONE_ASC',
  ContactsByCompanyIdAverageTimezoneDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_TIMEZONE_DESC',
  ContactsByCompanyIdAverageTitleAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_TITLE_ASC',
  ContactsByCompanyIdAverageTitleDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_TITLE_DESC',
  ContactsByCompanyIdAverageTwitterUrlAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_TWITTER_URL_ASC',
  ContactsByCompanyIdAverageTwitterUrlDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_TWITTER_URL_DESC',
  ContactsByCompanyIdAverageUpdatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_UPDATED_TIME_ASC',
  ContactsByCompanyIdAverageUpdatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_AVERAGE_UPDATED_TIME_DESC',
  ContactsByCompanyIdCountAsc = 'CONTACTS_BY_COMPANY_ID_COUNT_ASC',
  ContactsByCompanyIdCountDesc = 'CONTACTS_BY_COMPANY_ID_COUNT_DESC',
  ContactsByCompanyIdDistinctCountApolloEnrichmentPayloadAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_APOLLO_ENRICHMENT_PAYLOAD_ASC',
  ContactsByCompanyIdDistinctCountApolloEnrichmentPayloadDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_APOLLO_ENRICHMENT_PAYLOAD_DESC',
  ContactsByCompanyIdDistinctCountApolloIdAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_APOLLO_ID_ASC',
  ContactsByCompanyIdDistinctCountApolloIdDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_APOLLO_ID_DESC',
  ContactsByCompanyIdDistinctCountApolloQueryIdAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_APOLLO_QUERY_ID_ASC',
  ContactsByCompanyIdDistinctCountApolloQueryIdDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_APOLLO_QUERY_ID_DESC',
  ContactsByCompanyIdDistinctCountCompanyIdAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  ContactsByCompanyIdDistinctCountCompanyIdDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  ContactsByCompanyIdDistinctCountCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_NAME_ASC',
  ContactsByCompanyIdDistinctCountCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_NAME_DESC',
  ContactsByCompanyIdDistinctCountCreatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  ContactsByCompanyIdDistinctCountCreatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  ContactsByCompanyIdDistinctCountCrunchbaseUrlAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_CRUNCHBASE_URL_ASC',
  ContactsByCompanyIdDistinctCountCrunchbaseUrlDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_CRUNCHBASE_URL_DESC',
  ContactsByCompanyIdDistinctCountEmailAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_EMAIL_ASC',
  ContactsByCompanyIdDistinctCountEmailDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_EMAIL_DESC',
  ContactsByCompanyIdDistinctCountEmailDomainRevAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_EMAIL_DOMAIN_REV_ASC',
  ContactsByCompanyIdDistinctCountEmailDomainRevDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_EMAIL_DOMAIN_REV_DESC',
  ContactsByCompanyIdDistinctCountFacebookUrlAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_FACEBOOK_URL_ASC',
  ContactsByCompanyIdDistinctCountFacebookUrlDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_FACEBOOK_URL_DESC',
  ContactsByCompanyIdDistinctCountFirstNameAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_FIRST_NAME_ASC',
  ContactsByCompanyIdDistinctCountFirstNameDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_FIRST_NAME_DESC',
  ContactsByCompanyIdDistinctCountIdAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC',
  ContactsByCompanyIdDistinctCountIdDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC',
  ContactsByCompanyIdDistinctCountLastEnrichedAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_LAST_ENRICHED_ASC',
  ContactsByCompanyIdDistinctCountLastEnrichedDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_LAST_ENRICHED_DESC',
  ContactsByCompanyIdDistinctCountLastNameAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_LAST_NAME_ASC',
  ContactsByCompanyIdDistinctCountLastNameDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_LAST_NAME_DESC',
  ContactsByCompanyIdDistinctCountLinkedinCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_LINKEDIN_COMPANY_NAME_ASC',
  ContactsByCompanyIdDistinctCountLinkedinCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_LINKEDIN_COMPANY_NAME_DESC',
  ContactsByCompanyIdDistinctCountLinkedinNameAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_LINKEDIN_NAME_ASC',
  ContactsByCompanyIdDistinctCountLinkedinNameDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_LINKEDIN_NAME_DESC',
  ContactsByCompanyIdDistinctCountLinkedinUidAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_LINKEDIN_UID_ASC',
  ContactsByCompanyIdDistinctCountLinkedinUidDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_LINKEDIN_UID_DESC',
  ContactsByCompanyIdDistinctCountLinkedinUrlAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_LINKEDIN_URL_ASC',
  ContactsByCompanyIdDistinctCountLinkedinUrlDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_LINKEDIN_URL_DESC',
  ContactsByCompanyIdDistinctCountPhoneNumberAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_PHONE_NUMBER_ASC',
  ContactsByCompanyIdDistinctCountPhoneNumberDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_PHONE_NUMBER_DESC',
  ContactsByCompanyIdDistinctCountPhotoUrlAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_PHOTO_URL_ASC',
  ContactsByCompanyIdDistinctCountPhotoUrlDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_PHOTO_URL_DESC',
  ContactsByCompanyIdDistinctCountSummaryAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_SUMMARY_ASC',
  ContactsByCompanyIdDistinctCountSummaryDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_SUMMARY_DESC',
  ContactsByCompanyIdDistinctCountTimezoneAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_TIMEZONE_ASC',
  ContactsByCompanyIdDistinctCountTimezoneDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_TIMEZONE_DESC',
  ContactsByCompanyIdDistinctCountTitleAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_TITLE_ASC',
  ContactsByCompanyIdDistinctCountTitleDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_TITLE_DESC',
  ContactsByCompanyIdDistinctCountTwitterUrlAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_TWITTER_URL_ASC',
  ContactsByCompanyIdDistinctCountTwitterUrlDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_TWITTER_URL_DESC',
  ContactsByCompanyIdDistinctCountUpdatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  ContactsByCompanyIdDistinctCountUpdatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  ContactsByCompanyIdMaxApolloEnrichmentPayloadAsc = 'CONTACTS_BY_COMPANY_ID_MAX_APOLLO_ENRICHMENT_PAYLOAD_ASC',
  ContactsByCompanyIdMaxApolloEnrichmentPayloadDesc = 'CONTACTS_BY_COMPANY_ID_MAX_APOLLO_ENRICHMENT_PAYLOAD_DESC',
  ContactsByCompanyIdMaxApolloIdAsc = 'CONTACTS_BY_COMPANY_ID_MAX_APOLLO_ID_ASC',
  ContactsByCompanyIdMaxApolloIdDesc = 'CONTACTS_BY_COMPANY_ID_MAX_APOLLO_ID_DESC',
  ContactsByCompanyIdMaxApolloQueryIdAsc = 'CONTACTS_BY_COMPANY_ID_MAX_APOLLO_QUERY_ID_ASC',
  ContactsByCompanyIdMaxApolloQueryIdDesc = 'CONTACTS_BY_COMPANY_ID_MAX_APOLLO_QUERY_ID_DESC',
  ContactsByCompanyIdMaxCompanyIdAsc = 'CONTACTS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC',
  ContactsByCompanyIdMaxCompanyIdDesc = 'CONTACTS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC',
  ContactsByCompanyIdMaxCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_MAX_COMPANY_NAME_ASC',
  ContactsByCompanyIdMaxCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_MAX_COMPANY_NAME_DESC',
  ContactsByCompanyIdMaxCreatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_MAX_CREATED_TIME_ASC',
  ContactsByCompanyIdMaxCreatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_MAX_CREATED_TIME_DESC',
  ContactsByCompanyIdMaxCrunchbaseUrlAsc = 'CONTACTS_BY_COMPANY_ID_MAX_CRUNCHBASE_URL_ASC',
  ContactsByCompanyIdMaxCrunchbaseUrlDesc = 'CONTACTS_BY_COMPANY_ID_MAX_CRUNCHBASE_URL_DESC',
  ContactsByCompanyIdMaxEmailAsc = 'CONTACTS_BY_COMPANY_ID_MAX_EMAIL_ASC',
  ContactsByCompanyIdMaxEmailDesc = 'CONTACTS_BY_COMPANY_ID_MAX_EMAIL_DESC',
  ContactsByCompanyIdMaxEmailDomainRevAsc = 'CONTACTS_BY_COMPANY_ID_MAX_EMAIL_DOMAIN_REV_ASC',
  ContactsByCompanyIdMaxEmailDomainRevDesc = 'CONTACTS_BY_COMPANY_ID_MAX_EMAIL_DOMAIN_REV_DESC',
  ContactsByCompanyIdMaxFacebookUrlAsc = 'CONTACTS_BY_COMPANY_ID_MAX_FACEBOOK_URL_ASC',
  ContactsByCompanyIdMaxFacebookUrlDesc = 'CONTACTS_BY_COMPANY_ID_MAX_FACEBOOK_URL_DESC',
  ContactsByCompanyIdMaxFirstNameAsc = 'CONTACTS_BY_COMPANY_ID_MAX_FIRST_NAME_ASC',
  ContactsByCompanyIdMaxFirstNameDesc = 'CONTACTS_BY_COMPANY_ID_MAX_FIRST_NAME_DESC',
  ContactsByCompanyIdMaxIdAsc = 'CONTACTS_BY_COMPANY_ID_MAX_ID_ASC',
  ContactsByCompanyIdMaxIdDesc = 'CONTACTS_BY_COMPANY_ID_MAX_ID_DESC',
  ContactsByCompanyIdMaxLastEnrichedAsc = 'CONTACTS_BY_COMPANY_ID_MAX_LAST_ENRICHED_ASC',
  ContactsByCompanyIdMaxLastEnrichedDesc = 'CONTACTS_BY_COMPANY_ID_MAX_LAST_ENRICHED_DESC',
  ContactsByCompanyIdMaxLastNameAsc = 'CONTACTS_BY_COMPANY_ID_MAX_LAST_NAME_ASC',
  ContactsByCompanyIdMaxLastNameDesc = 'CONTACTS_BY_COMPANY_ID_MAX_LAST_NAME_DESC',
  ContactsByCompanyIdMaxLinkedinCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_MAX_LINKEDIN_COMPANY_NAME_ASC',
  ContactsByCompanyIdMaxLinkedinCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_MAX_LINKEDIN_COMPANY_NAME_DESC',
  ContactsByCompanyIdMaxLinkedinNameAsc = 'CONTACTS_BY_COMPANY_ID_MAX_LINKEDIN_NAME_ASC',
  ContactsByCompanyIdMaxLinkedinNameDesc = 'CONTACTS_BY_COMPANY_ID_MAX_LINKEDIN_NAME_DESC',
  ContactsByCompanyIdMaxLinkedinUidAsc = 'CONTACTS_BY_COMPANY_ID_MAX_LINKEDIN_UID_ASC',
  ContactsByCompanyIdMaxLinkedinUidDesc = 'CONTACTS_BY_COMPANY_ID_MAX_LINKEDIN_UID_DESC',
  ContactsByCompanyIdMaxLinkedinUrlAsc = 'CONTACTS_BY_COMPANY_ID_MAX_LINKEDIN_URL_ASC',
  ContactsByCompanyIdMaxLinkedinUrlDesc = 'CONTACTS_BY_COMPANY_ID_MAX_LINKEDIN_URL_DESC',
  ContactsByCompanyIdMaxPhoneNumberAsc = 'CONTACTS_BY_COMPANY_ID_MAX_PHONE_NUMBER_ASC',
  ContactsByCompanyIdMaxPhoneNumberDesc = 'CONTACTS_BY_COMPANY_ID_MAX_PHONE_NUMBER_DESC',
  ContactsByCompanyIdMaxPhotoUrlAsc = 'CONTACTS_BY_COMPANY_ID_MAX_PHOTO_URL_ASC',
  ContactsByCompanyIdMaxPhotoUrlDesc = 'CONTACTS_BY_COMPANY_ID_MAX_PHOTO_URL_DESC',
  ContactsByCompanyIdMaxSummaryAsc = 'CONTACTS_BY_COMPANY_ID_MAX_SUMMARY_ASC',
  ContactsByCompanyIdMaxSummaryDesc = 'CONTACTS_BY_COMPANY_ID_MAX_SUMMARY_DESC',
  ContactsByCompanyIdMaxTimezoneAsc = 'CONTACTS_BY_COMPANY_ID_MAX_TIMEZONE_ASC',
  ContactsByCompanyIdMaxTimezoneDesc = 'CONTACTS_BY_COMPANY_ID_MAX_TIMEZONE_DESC',
  ContactsByCompanyIdMaxTitleAsc = 'CONTACTS_BY_COMPANY_ID_MAX_TITLE_ASC',
  ContactsByCompanyIdMaxTitleDesc = 'CONTACTS_BY_COMPANY_ID_MAX_TITLE_DESC',
  ContactsByCompanyIdMaxTwitterUrlAsc = 'CONTACTS_BY_COMPANY_ID_MAX_TWITTER_URL_ASC',
  ContactsByCompanyIdMaxTwitterUrlDesc = 'CONTACTS_BY_COMPANY_ID_MAX_TWITTER_URL_DESC',
  ContactsByCompanyIdMaxUpdatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_MAX_UPDATED_TIME_ASC',
  ContactsByCompanyIdMaxUpdatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_MAX_UPDATED_TIME_DESC',
  ContactsByCompanyIdMinApolloEnrichmentPayloadAsc = 'CONTACTS_BY_COMPANY_ID_MIN_APOLLO_ENRICHMENT_PAYLOAD_ASC',
  ContactsByCompanyIdMinApolloEnrichmentPayloadDesc = 'CONTACTS_BY_COMPANY_ID_MIN_APOLLO_ENRICHMENT_PAYLOAD_DESC',
  ContactsByCompanyIdMinApolloIdAsc = 'CONTACTS_BY_COMPANY_ID_MIN_APOLLO_ID_ASC',
  ContactsByCompanyIdMinApolloIdDesc = 'CONTACTS_BY_COMPANY_ID_MIN_APOLLO_ID_DESC',
  ContactsByCompanyIdMinApolloQueryIdAsc = 'CONTACTS_BY_COMPANY_ID_MIN_APOLLO_QUERY_ID_ASC',
  ContactsByCompanyIdMinApolloQueryIdDesc = 'CONTACTS_BY_COMPANY_ID_MIN_APOLLO_QUERY_ID_DESC',
  ContactsByCompanyIdMinCompanyIdAsc = 'CONTACTS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC',
  ContactsByCompanyIdMinCompanyIdDesc = 'CONTACTS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC',
  ContactsByCompanyIdMinCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_MIN_COMPANY_NAME_ASC',
  ContactsByCompanyIdMinCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_MIN_COMPANY_NAME_DESC',
  ContactsByCompanyIdMinCreatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_MIN_CREATED_TIME_ASC',
  ContactsByCompanyIdMinCreatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_MIN_CREATED_TIME_DESC',
  ContactsByCompanyIdMinCrunchbaseUrlAsc = 'CONTACTS_BY_COMPANY_ID_MIN_CRUNCHBASE_URL_ASC',
  ContactsByCompanyIdMinCrunchbaseUrlDesc = 'CONTACTS_BY_COMPANY_ID_MIN_CRUNCHBASE_URL_DESC',
  ContactsByCompanyIdMinEmailAsc = 'CONTACTS_BY_COMPANY_ID_MIN_EMAIL_ASC',
  ContactsByCompanyIdMinEmailDesc = 'CONTACTS_BY_COMPANY_ID_MIN_EMAIL_DESC',
  ContactsByCompanyIdMinEmailDomainRevAsc = 'CONTACTS_BY_COMPANY_ID_MIN_EMAIL_DOMAIN_REV_ASC',
  ContactsByCompanyIdMinEmailDomainRevDesc = 'CONTACTS_BY_COMPANY_ID_MIN_EMAIL_DOMAIN_REV_DESC',
  ContactsByCompanyIdMinFacebookUrlAsc = 'CONTACTS_BY_COMPANY_ID_MIN_FACEBOOK_URL_ASC',
  ContactsByCompanyIdMinFacebookUrlDesc = 'CONTACTS_BY_COMPANY_ID_MIN_FACEBOOK_URL_DESC',
  ContactsByCompanyIdMinFirstNameAsc = 'CONTACTS_BY_COMPANY_ID_MIN_FIRST_NAME_ASC',
  ContactsByCompanyIdMinFirstNameDesc = 'CONTACTS_BY_COMPANY_ID_MIN_FIRST_NAME_DESC',
  ContactsByCompanyIdMinIdAsc = 'CONTACTS_BY_COMPANY_ID_MIN_ID_ASC',
  ContactsByCompanyIdMinIdDesc = 'CONTACTS_BY_COMPANY_ID_MIN_ID_DESC',
  ContactsByCompanyIdMinLastEnrichedAsc = 'CONTACTS_BY_COMPANY_ID_MIN_LAST_ENRICHED_ASC',
  ContactsByCompanyIdMinLastEnrichedDesc = 'CONTACTS_BY_COMPANY_ID_MIN_LAST_ENRICHED_DESC',
  ContactsByCompanyIdMinLastNameAsc = 'CONTACTS_BY_COMPANY_ID_MIN_LAST_NAME_ASC',
  ContactsByCompanyIdMinLastNameDesc = 'CONTACTS_BY_COMPANY_ID_MIN_LAST_NAME_DESC',
  ContactsByCompanyIdMinLinkedinCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_MIN_LINKEDIN_COMPANY_NAME_ASC',
  ContactsByCompanyIdMinLinkedinCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_MIN_LINKEDIN_COMPANY_NAME_DESC',
  ContactsByCompanyIdMinLinkedinNameAsc = 'CONTACTS_BY_COMPANY_ID_MIN_LINKEDIN_NAME_ASC',
  ContactsByCompanyIdMinLinkedinNameDesc = 'CONTACTS_BY_COMPANY_ID_MIN_LINKEDIN_NAME_DESC',
  ContactsByCompanyIdMinLinkedinUidAsc = 'CONTACTS_BY_COMPANY_ID_MIN_LINKEDIN_UID_ASC',
  ContactsByCompanyIdMinLinkedinUidDesc = 'CONTACTS_BY_COMPANY_ID_MIN_LINKEDIN_UID_DESC',
  ContactsByCompanyIdMinLinkedinUrlAsc = 'CONTACTS_BY_COMPANY_ID_MIN_LINKEDIN_URL_ASC',
  ContactsByCompanyIdMinLinkedinUrlDesc = 'CONTACTS_BY_COMPANY_ID_MIN_LINKEDIN_URL_DESC',
  ContactsByCompanyIdMinPhoneNumberAsc = 'CONTACTS_BY_COMPANY_ID_MIN_PHONE_NUMBER_ASC',
  ContactsByCompanyIdMinPhoneNumberDesc = 'CONTACTS_BY_COMPANY_ID_MIN_PHONE_NUMBER_DESC',
  ContactsByCompanyIdMinPhotoUrlAsc = 'CONTACTS_BY_COMPANY_ID_MIN_PHOTO_URL_ASC',
  ContactsByCompanyIdMinPhotoUrlDesc = 'CONTACTS_BY_COMPANY_ID_MIN_PHOTO_URL_DESC',
  ContactsByCompanyIdMinSummaryAsc = 'CONTACTS_BY_COMPANY_ID_MIN_SUMMARY_ASC',
  ContactsByCompanyIdMinSummaryDesc = 'CONTACTS_BY_COMPANY_ID_MIN_SUMMARY_DESC',
  ContactsByCompanyIdMinTimezoneAsc = 'CONTACTS_BY_COMPANY_ID_MIN_TIMEZONE_ASC',
  ContactsByCompanyIdMinTimezoneDesc = 'CONTACTS_BY_COMPANY_ID_MIN_TIMEZONE_DESC',
  ContactsByCompanyIdMinTitleAsc = 'CONTACTS_BY_COMPANY_ID_MIN_TITLE_ASC',
  ContactsByCompanyIdMinTitleDesc = 'CONTACTS_BY_COMPANY_ID_MIN_TITLE_DESC',
  ContactsByCompanyIdMinTwitterUrlAsc = 'CONTACTS_BY_COMPANY_ID_MIN_TWITTER_URL_ASC',
  ContactsByCompanyIdMinTwitterUrlDesc = 'CONTACTS_BY_COMPANY_ID_MIN_TWITTER_URL_DESC',
  ContactsByCompanyIdMinUpdatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_MIN_UPDATED_TIME_ASC',
  ContactsByCompanyIdMinUpdatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_MIN_UPDATED_TIME_DESC',
  ContactsByCompanyIdStddevPopulationApolloEnrichmentPayloadAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_APOLLO_ENRICHMENT_PAYLOAD_ASC',
  ContactsByCompanyIdStddevPopulationApolloEnrichmentPayloadDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_APOLLO_ENRICHMENT_PAYLOAD_DESC',
  ContactsByCompanyIdStddevPopulationApolloIdAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_APOLLO_ID_ASC',
  ContactsByCompanyIdStddevPopulationApolloIdDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_APOLLO_ID_DESC',
  ContactsByCompanyIdStddevPopulationApolloQueryIdAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_APOLLO_QUERY_ID_ASC',
  ContactsByCompanyIdStddevPopulationApolloQueryIdDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_APOLLO_QUERY_ID_DESC',
  ContactsByCompanyIdStddevPopulationCompanyIdAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  ContactsByCompanyIdStddevPopulationCompanyIdDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  ContactsByCompanyIdStddevPopulationCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_NAME_ASC',
  ContactsByCompanyIdStddevPopulationCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_NAME_DESC',
  ContactsByCompanyIdStddevPopulationCreatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  ContactsByCompanyIdStddevPopulationCreatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  ContactsByCompanyIdStddevPopulationCrunchbaseUrlAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_CRUNCHBASE_URL_ASC',
  ContactsByCompanyIdStddevPopulationCrunchbaseUrlDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_CRUNCHBASE_URL_DESC',
  ContactsByCompanyIdStddevPopulationEmailAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_EMAIL_ASC',
  ContactsByCompanyIdStddevPopulationEmailDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_EMAIL_DESC',
  ContactsByCompanyIdStddevPopulationEmailDomainRevAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_EMAIL_DOMAIN_REV_ASC',
  ContactsByCompanyIdStddevPopulationEmailDomainRevDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_EMAIL_DOMAIN_REV_DESC',
  ContactsByCompanyIdStddevPopulationFacebookUrlAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_FACEBOOK_URL_ASC',
  ContactsByCompanyIdStddevPopulationFacebookUrlDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_FACEBOOK_URL_DESC',
  ContactsByCompanyIdStddevPopulationFirstNameAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_FIRST_NAME_ASC',
  ContactsByCompanyIdStddevPopulationFirstNameDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_FIRST_NAME_DESC',
  ContactsByCompanyIdStddevPopulationIdAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC',
  ContactsByCompanyIdStddevPopulationIdDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC',
  ContactsByCompanyIdStddevPopulationLastEnrichedAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_LAST_ENRICHED_ASC',
  ContactsByCompanyIdStddevPopulationLastEnrichedDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_LAST_ENRICHED_DESC',
  ContactsByCompanyIdStddevPopulationLastNameAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_LAST_NAME_ASC',
  ContactsByCompanyIdStddevPopulationLastNameDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_LAST_NAME_DESC',
  ContactsByCompanyIdStddevPopulationLinkedinCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_LINKEDIN_COMPANY_NAME_ASC',
  ContactsByCompanyIdStddevPopulationLinkedinCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_LINKEDIN_COMPANY_NAME_DESC',
  ContactsByCompanyIdStddevPopulationLinkedinNameAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_LINKEDIN_NAME_ASC',
  ContactsByCompanyIdStddevPopulationLinkedinNameDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_LINKEDIN_NAME_DESC',
  ContactsByCompanyIdStddevPopulationLinkedinUidAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_LINKEDIN_UID_ASC',
  ContactsByCompanyIdStddevPopulationLinkedinUidDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_LINKEDIN_UID_DESC',
  ContactsByCompanyIdStddevPopulationLinkedinUrlAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_LINKEDIN_URL_ASC',
  ContactsByCompanyIdStddevPopulationLinkedinUrlDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_LINKEDIN_URL_DESC',
  ContactsByCompanyIdStddevPopulationPhoneNumberAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_PHONE_NUMBER_ASC',
  ContactsByCompanyIdStddevPopulationPhoneNumberDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_PHONE_NUMBER_DESC',
  ContactsByCompanyIdStddevPopulationPhotoUrlAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_PHOTO_URL_ASC',
  ContactsByCompanyIdStddevPopulationPhotoUrlDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_PHOTO_URL_DESC',
  ContactsByCompanyIdStddevPopulationSummaryAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_SUMMARY_ASC',
  ContactsByCompanyIdStddevPopulationSummaryDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_SUMMARY_DESC',
  ContactsByCompanyIdStddevPopulationTimezoneAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_TIMEZONE_ASC',
  ContactsByCompanyIdStddevPopulationTimezoneDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_TIMEZONE_DESC',
  ContactsByCompanyIdStddevPopulationTitleAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_TITLE_ASC',
  ContactsByCompanyIdStddevPopulationTitleDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_TITLE_DESC',
  ContactsByCompanyIdStddevPopulationTwitterUrlAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_TWITTER_URL_ASC',
  ContactsByCompanyIdStddevPopulationTwitterUrlDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_TWITTER_URL_DESC',
  ContactsByCompanyIdStddevPopulationUpdatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  ContactsByCompanyIdStddevPopulationUpdatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  ContactsByCompanyIdStddevSampleApolloEnrichmentPayloadAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_APOLLO_ENRICHMENT_PAYLOAD_ASC',
  ContactsByCompanyIdStddevSampleApolloEnrichmentPayloadDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_APOLLO_ENRICHMENT_PAYLOAD_DESC',
  ContactsByCompanyIdStddevSampleApolloIdAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_APOLLO_ID_ASC',
  ContactsByCompanyIdStddevSampleApolloIdDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_APOLLO_ID_DESC',
  ContactsByCompanyIdStddevSampleApolloQueryIdAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_APOLLO_QUERY_ID_ASC',
  ContactsByCompanyIdStddevSampleApolloQueryIdDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_APOLLO_QUERY_ID_DESC',
  ContactsByCompanyIdStddevSampleCompanyIdAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  ContactsByCompanyIdStddevSampleCompanyIdDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  ContactsByCompanyIdStddevSampleCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_NAME_ASC',
  ContactsByCompanyIdStddevSampleCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_NAME_DESC',
  ContactsByCompanyIdStddevSampleCreatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  ContactsByCompanyIdStddevSampleCreatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  ContactsByCompanyIdStddevSampleCrunchbaseUrlAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_CRUNCHBASE_URL_ASC',
  ContactsByCompanyIdStddevSampleCrunchbaseUrlDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_CRUNCHBASE_URL_DESC',
  ContactsByCompanyIdStddevSampleEmailAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_EMAIL_ASC',
  ContactsByCompanyIdStddevSampleEmailDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_EMAIL_DESC',
  ContactsByCompanyIdStddevSampleEmailDomainRevAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_EMAIL_DOMAIN_REV_ASC',
  ContactsByCompanyIdStddevSampleEmailDomainRevDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_EMAIL_DOMAIN_REV_DESC',
  ContactsByCompanyIdStddevSampleFacebookUrlAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_FACEBOOK_URL_ASC',
  ContactsByCompanyIdStddevSampleFacebookUrlDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_FACEBOOK_URL_DESC',
  ContactsByCompanyIdStddevSampleFirstNameAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_FIRST_NAME_ASC',
  ContactsByCompanyIdStddevSampleFirstNameDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_FIRST_NAME_DESC',
  ContactsByCompanyIdStddevSampleIdAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC',
  ContactsByCompanyIdStddevSampleIdDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC',
  ContactsByCompanyIdStddevSampleLastEnrichedAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_ENRICHED_ASC',
  ContactsByCompanyIdStddevSampleLastEnrichedDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_ENRICHED_DESC',
  ContactsByCompanyIdStddevSampleLastNameAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_NAME_ASC',
  ContactsByCompanyIdStddevSampleLastNameDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_LAST_NAME_DESC',
  ContactsByCompanyIdStddevSampleLinkedinCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_LINKEDIN_COMPANY_NAME_ASC',
  ContactsByCompanyIdStddevSampleLinkedinCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_LINKEDIN_COMPANY_NAME_DESC',
  ContactsByCompanyIdStddevSampleLinkedinNameAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_LINKEDIN_NAME_ASC',
  ContactsByCompanyIdStddevSampleLinkedinNameDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_LINKEDIN_NAME_DESC',
  ContactsByCompanyIdStddevSampleLinkedinUidAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_LINKEDIN_UID_ASC',
  ContactsByCompanyIdStddevSampleLinkedinUidDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_LINKEDIN_UID_DESC',
  ContactsByCompanyIdStddevSampleLinkedinUrlAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_LINKEDIN_URL_ASC',
  ContactsByCompanyIdStddevSampleLinkedinUrlDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_LINKEDIN_URL_DESC',
  ContactsByCompanyIdStddevSamplePhoneNumberAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_PHONE_NUMBER_ASC',
  ContactsByCompanyIdStddevSamplePhoneNumberDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_PHONE_NUMBER_DESC',
  ContactsByCompanyIdStddevSamplePhotoUrlAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_PHOTO_URL_ASC',
  ContactsByCompanyIdStddevSamplePhotoUrlDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_PHOTO_URL_DESC',
  ContactsByCompanyIdStddevSampleSummaryAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_SUMMARY_ASC',
  ContactsByCompanyIdStddevSampleSummaryDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_SUMMARY_DESC',
  ContactsByCompanyIdStddevSampleTimezoneAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_TIMEZONE_ASC',
  ContactsByCompanyIdStddevSampleTimezoneDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_TIMEZONE_DESC',
  ContactsByCompanyIdStddevSampleTitleAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_TITLE_ASC',
  ContactsByCompanyIdStddevSampleTitleDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_TITLE_DESC',
  ContactsByCompanyIdStddevSampleTwitterUrlAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_TWITTER_URL_ASC',
  ContactsByCompanyIdStddevSampleTwitterUrlDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_TWITTER_URL_DESC',
  ContactsByCompanyIdStddevSampleUpdatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  ContactsByCompanyIdStddevSampleUpdatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  ContactsByCompanyIdSumApolloEnrichmentPayloadAsc = 'CONTACTS_BY_COMPANY_ID_SUM_APOLLO_ENRICHMENT_PAYLOAD_ASC',
  ContactsByCompanyIdSumApolloEnrichmentPayloadDesc = 'CONTACTS_BY_COMPANY_ID_SUM_APOLLO_ENRICHMENT_PAYLOAD_DESC',
  ContactsByCompanyIdSumApolloIdAsc = 'CONTACTS_BY_COMPANY_ID_SUM_APOLLO_ID_ASC',
  ContactsByCompanyIdSumApolloIdDesc = 'CONTACTS_BY_COMPANY_ID_SUM_APOLLO_ID_DESC',
  ContactsByCompanyIdSumApolloQueryIdAsc = 'CONTACTS_BY_COMPANY_ID_SUM_APOLLO_QUERY_ID_ASC',
  ContactsByCompanyIdSumApolloQueryIdDesc = 'CONTACTS_BY_COMPANY_ID_SUM_APOLLO_QUERY_ID_DESC',
  ContactsByCompanyIdSumCompanyIdAsc = 'CONTACTS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC',
  ContactsByCompanyIdSumCompanyIdDesc = 'CONTACTS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC',
  ContactsByCompanyIdSumCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_SUM_COMPANY_NAME_ASC',
  ContactsByCompanyIdSumCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_SUM_COMPANY_NAME_DESC',
  ContactsByCompanyIdSumCreatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_SUM_CREATED_TIME_ASC',
  ContactsByCompanyIdSumCreatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_SUM_CREATED_TIME_DESC',
  ContactsByCompanyIdSumCrunchbaseUrlAsc = 'CONTACTS_BY_COMPANY_ID_SUM_CRUNCHBASE_URL_ASC',
  ContactsByCompanyIdSumCrunchbaseUrlDesc = 'CONTACTS_BY_COMPANY_ID_SUM_CRUNCHBASE_URL_DESC',
  ContactsByCompanyIdSumEmailAsc = 'CONTACTS_BY_COMPANY_ID_SUM_EMAIL_ASC',
  ContactsByCompanyIdSumEmailDesc = 'CONTACTS_BY_COMPANY_ID_SUM_EMAIL_DESC',
  ContactsByCompanyIdSumEmailDomainRevAsc = 'CONTACTS_BY_COMPANY_ID_SUM_EMAIL_DOMAIN_REV_ASC',
  ContactsByCompanyIdSumEmailDomainRevDesc = 'CONTACTS_BY_COMPANY_ID_SUM_EMAIL_DOMAIN_REV_DESC',
  ContactsByCompanyIdSumFacebookUrlAsc = 'CONTACTS_BY_COMPANY_ID_SUM_FACEBOOK_URL_ASC',
  ContactsByCompanyIdSumFacebookUrlDesc = 'CONTACTS_BY_COMPANY_ID_SUM_FACEBOOK_URL_DESC',
  ContactsByCompanyIdSumFirstNameAsc = 'CONTACTS_BY_COMPANY_ID_SUM_FIRST_NAME_ASC',
  ContactsByCompanyIdSumFirstNameDesc = 'CONTACTS_BY_COMPANY_ID_SUM_FIRST_NAME_DESC',
  ContactsByCompanyIdSumIdAsc = 'CONTACTS_BY_COMPANY_ID_SUM_ID_ASC',
  ContactsByCompanyIdSumIdDesc = 'CONTACTS_BY_COMPANY_ID_SUM_ID_DESC',
  ContactsByCompanyIdSumLastEnrichedAsc = 'CONTACTS_BY_COMPANY_ID_SUM_LAST_ENRICHED_ASC',
  ContactsByCompanyIdSumLastEnrichedDesc = 'CONTACTS_BY_COMPANY_ID_SUM_LAST_ENRICHED_DESC',
  ContactsByCompanyIdSumLastNameAsc = 'CONTACTS_BY_COMPANY_ID_SUM_LAST_NAME_ASC',
  ContactsByCompanyIdSumLastNameDesc = 'CONTACTS_BY_COMPANY_ID_SUM_LAST_NAME_DESC',
  ContactsByCompanyIdSumLinkedinCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_SUM_LINKEDIN_COMPANY_NAME_ASC',
  ContactsByCompanyIdSumLinkedinCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_SUM_LINKEDIN_COMPANY_NAME_DESC',
  ContactsByCompanyIdSumLinkedinNameAsc = 'CONTACTS_BY_COMPANY_ID_SUM_LINKEDIN_NAME_ASC',
  ContactsByCompanyIdSumLinkedinNameDesc = 'CONTACTS_BY_COMPANY_ID_SUM_LINKEDIN_NAME_DESC',
  ContactsByCompanyIdSumLinkedinUidAsc = 'CONTACTS_BY_COMPANY_ID_SUM_LINKEDIN_UID_ASC',
  ContactsByCompanyIdSumLinkedinUidDesc = 'CONTACTS_BY_COMPANY_ID_SUM_LINKEDIN_UID_DESC',
  ContactsByCompanyIdSumLinkedinUrlAsc = 'CONTACTS_BY_COMPANY_ID_SUM_LINKEDIN_URL_ASC',
  ContactsByCompanyIdSumLinkedinUrlDesc = 'CONTACTS_BY_COMPANY_ID_SUM_LINKEDIN_URL_DESC',
  ContactsByCompanyIdSumPhoneNumberAsc = 'CONTACTS_BY_COMPANY_ID_SUM_PHONE_NUMBER_ASC',
  ContactsByCompanyIdSumPhoneNumberDesc = 'CONTACTS_BY_COMPANY_ID_SUM_PHONE_NUMBER_DESC',
  ContactsByCompanyIdSumPhotoUrlAsc = 'CONTACTS_BY_COMPANY_ID_SUM_PHOTO_URL_ASC',
  ContactsByCompanyIdSumPhotoUrlDesc = 'CONTACTS_BY_COMPANY_ID_SUM_PHOTO_URL_DESC',
  ContactsByCompanyIdSumSummaryAsc = 'CONTACTS_BY_COMPANY_ID_SUM_SUMMARY_ASC',
  ContactsByCompanyIdSumSummaryDesc = 'CONTACTS_BY_COMPANY_ID_SUM_SUMMARY_DESC',
  ContactsByCompanyIdSumTimezoneAsc = 'CONTACTS_BY_COMPANY_ID_SUM_TIMEZONE_ASC',
  ContactsByCompanyIdSumTimezoneDesc = 'CONTACTS_BY_COMPANY_ID_SUM_TIMEZONE_DESC',
  ContactsByCompanyIdSumTitleAsc = 'CONTACTS_BY_COMPANY_ID_SUM_TITLE_ASC',
  ContactsByCompanyIdSumTitleDesc = 'CONTACTS_BY_COMPANY_ID_SUM_TITLE_DESC',
  ContactsByCompanyIdSumTwitterUrlAsc = 'CONTACTS_BY_COMPANY_ID_SUM_TWITTER_URL_ASC',
  ContactsByCompanyIdSumTwitterUrlDesc = 'CONTACTS_BY_COMPANY_ID_SUM_TWITTER_URL_DESC',
  ContactsByCompanyIdSumUpdatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_SUM_UPDATED_TIME_ASC',
  ContactsByCompanyIdSumUpdatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_SUM_UPDATED_TIME_DESC',
  ContactsByCompanyIdVariancePopulationApolloEnrichmentPayloadAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_APOLLO_ENRICHMENT_PAYLOAD_ASC',
  ContactsByCompanyIdVariancePopulationApolloEnrichmentPayloadDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_APOLLO_ENRICHMENT_PAYLOAD_DESC',
  ContactsByCompanyIdVariancePopulationApolloIdAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_APOLLO_ID_ASC',
  ContactsByCompanyIdVariancePopulationApolloIdDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_APOLLO_ID_DESC',
  ContactsByCompanyIdVariancePopulationApolloQueryIdAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_APOLLO_QUERY_ID_ASC',
  ContactsByCompanyIdVariancePopulationApolloQueryIdDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_APOLLO_QUERY_ID_DESC',
  ContactsByCompanyIdVariancePopulationCompanyIdAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  ContactsByCompanyIdVariancePopulationCompanyIdDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  ContactsByCompanyIdVariancePopulationCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_NAME_ASC',
  ContactsByCompanyIdVariancePopulationCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_NAME_DESC',
  ContactsByCompanyIdVariancePopulationCreatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  ContactsByCompanyIdVariancePopulationCreatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  ContactsByCompanyIdVariancePopulationCrunchbaseUrlAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_CRUNCHBASE_URL_ASC',
  ContactsByCompanyIdVariancePopulationCrunchbaseUrlDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_CRUNCHBASE_URL_DESC',
  ContactsByCompanyIdVariancePopulationEmailAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_EMAIL_ASC',
  ContactsByCompanyIdVariancePopulationEmailDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_EMAIL_DESC',
  ContactsByCompanyIdVariancePopulationEmailDomainRevAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_EMAIL_DOMAIN_REV_ASC',
  ContactsByCompanyIdVariancePopulationEmailDomainRevDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_EMAIL_DOMAIN_REV_DESC',
  ContactsByCompanyIdVariancePopulationFacebookUrlAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_FACEBOOK_URL_ASC',
  ContactsByCompanyIdVariancePopulationFacebookUrlDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_FACEBOOK_URL_DESC',
  ContactsByCompanyIdVariancePopulationFirstNameAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_FIRST_NAME_ASC',
  ContactsByCompanyIdVariancePopulationFirstNameDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_FIRST_NAME_DESC',
  ContactsByCompanyIdVariancePopulationIdAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC',
  ContactsByCompanyIdVariancePopulationIdDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC',
  ContactsByCompanyIdVariancePopulationLastEnrichedAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_ENRICHED_ASC',
  ContactsByCompanyIdVariancePopulationLastEnrichedDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_ENRICHED_DESC',
  ContactsByCompanyIdVariancePopulationLastNameAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_NAME_ASC',
  ContactsByCompanyIdVariancePopulationLastNameDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_LAST_NAME_DESC',
  ContactsByCompanyIdVariancePopulationLinkedinCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_LINKEDIN_COMPANY_NAME_ASC',
  ContactsByCompanyIdVariancePopulationLinkedinCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_LINKEDIN_COMPANY_NAME_DESC',
  ContactsByCompanyIdVariancePopulationLinkedinNameAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_LINKEDIN_NAME_ASC',
  ContactsByCompanyIdVariancePopulationLinkedinNameDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_LINKEDIN_NAME_DESC',
  ContactsByCompanyIdVariancePopulationLinkedinUidAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_LINKEDIN_UID_ASC',
  ContactsByCompanyIdVariancePopulationLinkedinUidDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_LINKEDIN_UID_DESC',
  ContactsByCompanyIdVariancePopulationLinkedinUrlAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_LINKEDIN_URL_ASC',
  ContactsByCompanyIdVariancePopulationLinkedinUrlDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_LINKEDIN_URL_DESC',
  ContactsByCompanyIdVariancePopulationPhoneNumberAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_PHONE_NUMBER_ASC',
  ContactsByCompanyIdVariancePopulationPhoneNumberDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_PHONE_NUMBER_DESC',
  ContactsByCompanyIdVariancePopulationPhotoUrlAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_PHOTO_URL_ASC',
  ContactsByCompanyIdVariancePopulationPhotoUrlDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_PHOTO_URL_DESC',
  ContactsByCompanyIdVariancePopulationSummaryAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_SUMMARY_ASC',
  ContactsByCompanyIdVariancePopulationSummaryDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_SUMMARY_DESC',
  ContactsByCompanyIdVariancePopulationTimezoneAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_TIMEZONE_ASC',
  ContactsByCompanyIdVariancePopulationTimezoneDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_TIMEZONE_DESC',
  ContactsByCompanyIdVariancePopulationTitleAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_TITLE_ASC',
  ContactsByCompanyIdVariancePopulationTitleDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_TITLE_DESC',
  ContactsByCompanyIdVariancePopulationTwitterUrlAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_TWITTER_URL_ASC',
  ContactsByCompanyIdVariancePopulationTwitterUrlDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_TWITTER_URL_DESC',
  ContactsByCompanyIdVariancePopulationUpdatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  ContactsByCompanyIdVariancePopulationUpdatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  ContactsByCompanyIdVarianceSampleApolloEnrichmentPayloadAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_APOLLO_ENRICHMENT_PAYLOAD_ASC',
  ContactsByCompanyIdVarianceSampleApolloEnrichmentPayloadDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_APOLLO_ENRICHMENT_PAYLOAD_DESC',
  ContactsByCompanyIdVarianceSampleApolloIdAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_APOLLO_ID_ASC',
  ContactsByCompanyIdVarianceSampleApolloIdDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_APOLLO_ID_DESC',
  ContactsByCompanyIdVarianceSampleApolloQueryIdAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_APOLLO_QUERY_ID_ASC',
  ContactsByCompanyIdVarianceSampleApolloQueryIdDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_APOLLO_QUERY_ID_DESC',
  ContactsByCompanyIdVarianceSampleCompanyIdAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  ContactsByCompanyIdVarianceSampleCompanyIdDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  ContactsByCompanyIdVarianceSampleCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_NAME_ASC',
  ContactsByCompanyIdVarianceSampleCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_NAME_DESC',
  ContactsByCompanyIdVarianceSampleCreatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  ContactsByCompanyIdVarianceSampleCreatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  ContactsByCompanyIdVarianceSampleCrunchbaseUrlAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_CRUNCHBASE_URL_ASC',
  ContactsByCompanyIdVarianceSampleCrunchbaseUrlDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_CRUNCHBASE_URL_DESC',
  ContactsByCompanyIdVarianceSampleEmailAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_EMAIL_ASC',
  ContactsByCompanyIdVarianceSampleEmailDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_EMAIL_DESC',
  ContactsByCompanyIdVarianceSampleEmailDomainRevAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_EMAIL_DOMAIN_REV_ASC',
  ContactsByCompanyIdVarianceSampleEmailDomainRevDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_EMAIL_DOMAIN_REV_DESC',
  ContactsByCompanyIdVarianceSampleFacebookUrlAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_FACEBOOK_URL_ASC',
  ContactsByCompanyIdVarianceSampleFacebookUrlDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_FACEBOOK_URL_DESC',
  ContactsByCompanyIdVarianceSampleFirstNameAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_FIRST_NAME_ASC',
  ContactsByCompanyIdVarianceSampleFirstNameDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_FIRST_NAME_DESC',
  ContactsByCompanyIdVarianceSampleIdAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC',
  ContactsByCompanyIdVarianceSampleIdDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC',
  ContactsByCompanyIdVarianceSampleLastEnrichedAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_ENRICHED_ASC',
  ContactsByCompanyIdVarianceSampleLastEnrichedDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_ENRICHED_DESC',
  ContactsByCompanyIdVarianceSampleLastNameAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_NAME_ASC',
  ContactsByCompanyIdVarianceSampleLastNameDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LAST_NAME_DESC',
  ContactsByCompanyIdVarianceSampleLinkedinCompanyNameAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LINKEDIN_COMPANY_NAME_ASC',
  ContactsByCompanyIdVarianceSampleLinkedinCompanyNameDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LINKEDIN_COMPANY_NAME_DESC',
  ContactsByCompanyIdVarianceSampleLinkedinNameAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LINKEDIN_NAME_ASC',
  ContactsByCompanyIdVarianceSampleLinkedinNameDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LINKEDIN_NAME_DESC',
  ContactsByCompanyIdVarianceSampleLinkedinUidAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LINKEDIN_UID_ASC',
  ContactsByCompanyIdVarianceSampleLinkedinUidDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LINKEDIN_UID_DESC',
  ContactsByCompanyIdVarianceSampleLinkedinUrlAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LINKEDIN_URL_ASC',
  ContactsByCompanyIdVarianceSampleLinkedinUrlDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_LINKEDIN_URL_DESC',
  ContactsByCompanyIdVarianceSamplePhoneNumberAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_PHONE_NUMBER_ASC',
  ContactsByCompanyIdVarianceSamplePhoneNumberDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_PHONE_NUMBER_DESC',
  ContactsByCompanyIdVarianceSamplePhotoUrlAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_PHOTO_URL_ASC',
  ContactsByCompanyIdVarianceSamplePhotoUrlDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_PHOTO_URL_DESC',
  ContactsByCompanyIdVarianceSampleSummaryAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_SUMMARY_ASC',
  ContactsByCompanyIdVarianceSampleSummaryDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_SUMMARY_DESC',
  ContactsByCompanyIdVarianceSampleTimezoneAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_TIMEZONE_ASC',
  ContactsByCompanyIdVarianceSampleTimezoneDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_TIMEZONE_DESC',
  ContactsByCompanyIdVarianceSampleTitleAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_TITLE_ASC',
  ContactsByCompanyIdVarianceSampleTitleDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_TITLE_DESC',
  ContactsByCompanyIdVarianceSampleTwitterUrlAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_TWITTER_URL_ASC',
  ContactsByCompanyIdVarianceSampleTwitterUrlDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_TWITTER_URL_DESC',
  ContactsByCompanyIdVarianceSampleUpdatedTimeAsc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  ContactsByCompanyIdVarianceSampleUpdatedTimeDesc = 'CONTACTS_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EmailMessagesByCompanyIdAverageAutoRepliedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_AUTO_REPLIED_ASC',
  EmailMessagesByCompanyIdAverageAutoRepliedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_AUTO_REPLIED_DESC',
  EmailMessagesByCompanyIdAverageCampaignIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CAMPAIGN_ID_ASC',
  EmailMessagesByCompanyIdAverageCampaignIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CAMPAIGN_ID_DESC',
  EmailMessagesByCompanyIdAverageCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCompanyIdAverageCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCompanyIdAverageCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdAverageCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdAverageCompanyIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC',
  EmailMessagesByCompanyIdAverageCompanyIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC',
  EmailMessagesByCompanyIdAverageContactIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CONTACT_ID_ASC',
  EmailMessagesByCompanyIdAverageContactIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CONTACT_ID_DESC',
  EmailMessagesByCompanyIdAverageContentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CONTENT_ASC',
  EmailMessagesByCompanyIdAverageContentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CONTENT_DESC',
  EmailMessagesByCompanyIdAverageContentHtmlAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CONTENT_HTML_ASC',
  EmailMessagesByCompanyIdAverageContentHtmlDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CONTENT_HTML_DESC',
  EmailMessagesByCompanyIdAverageCreatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CREATED_TIME_ASC',
  EmailMessagesByCompanyIdAverageCreatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_CREATED_TIME_DESC',
  EmailMessagesByCompanyIdAverageEmailIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_EMAIL_ID_ASC',
  EmailMessagesByCompanyIdAverageEmailIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_EMAIL_ID_DESC',
  EmailMessagesByCompanyIdAverageForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCompanyIdAverageForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCompanyIdAverageForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdAverageForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdAverageGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_GENERATED_REPLY_ASC',
  EmailMessagesByCompanyIdAverageGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_GENERATED_REPLY_DESC',
  EmailMessagesByCompanyIdAverageHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdAverageHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdAverageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_ID_ASC',
  EmailMessagesByCompanyIdAverageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_ID_DESC',
  EmailMessagesByCompanyIdAverageIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_INTENT_ASC',
  EmailMessagesByCompanyIdAverageIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_INTENT_DESC',
  EmailMessagesByCompanyIdAverageIsLawyerAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_IS_LAWYER_ASC',
  EmailMessagesByCompanyIdAverageIsLawyerDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_IS_LAWYER_DESC',
  EmailMessagesByCompanyIdAverageIsReviewedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_IS_REVIEWED_ASC',
  EmailMessagesByCompanyIdAverageIsReviewedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_IS_REVIEWED_DESC',
  EmailMessagesByCompanyIdAverageLawyerIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_LAWYER_ID_ASC',
  EmailMessagesByCompanyIdAverageLawyerIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_LAWYER_ID_DESC',
  EmailMessagesByCompanyIdAverageLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCompanyIdAverageLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCompanyIdAverageNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCompanyIdAverageNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCompanyIdAverageOrderIndexAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_ORDER_INDEX_ASC',
  EmailMessagesByCompanyIdAverageOrderIndexDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_ORDER_INDEX_DESC',
  EmailMessagesByCompanyIdAveragePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCompanyIdAveragePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCompanyIdAverageRepliedIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_REPLIED_INTENT_ASC',
  EmailMessagesByCompanyIdAverageRepliedIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_REPLIED_INTENT_DESC',
  EmailMessagesByCompanyIdAverageRepliedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_REPLIED_TIME_ASC',
  EmailMessagesByCompanyIdAverageRepliedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_REPLIED_TIME_DESC',
  EmailMessagesByCompanyIdAverageRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdAverageRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdAverageReviewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_REVIEWED_TIME_ASC',
  EmailMessagesByCompanyIdAverageReviewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_REVIEWED_TIME_DESC',
  EmailMessagesByCompanyIdAverageSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCompanyIdAverageSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCompanyIdAverageSentTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_SENT_TIME_ASC',
  EmailMessagesByCompanyIdAverageSentTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_SENT_TIME_DESC',
  EmailMessagesByCompanyIdAverageSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdAverageSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdAverageSubjectAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_SUBJECT_ASC',
  EmailMessagesByCompanyIdAverageSubjectDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_SUBJECT_DESC',
  EmailMessagesByCompanyIdAverageTemplateIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_TEMPLATE_ID_ASC',
  EmailMessagesByCompanyIdAverageTemplateIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_TEMPLATE_ID_DESC',
  EmailMessagesByCompanyIdAverageUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailMessagesByCompanyIdAverageUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailMessagesByCompanyIdCountAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_COUNT_ASC',
  EmailMessagesByCompanyIdCountDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_COUNT_DESC',
  EmailMessagesByCompanyIdDistinctCountAutoRepliedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_AUTO_REPLIED_ASC',
  EmailMessagesByCompanyIdDistinctCountAutoRepliedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_AUTO_REPLIED_DESC',
  EmailMessagesByCompanyIdDistinctCountCampaignIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  EmailMessagesByCompanyIdDistinctCountCampaignIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  EmailMessagesByCompanyIdDistinctCountCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCompanyIdDistinctCountCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCompanyIdDistinctCountCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdDistinctCountCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdDistinctCountCompanyIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  EmailMessagesByCompanyIdDistinctCountCompanyIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  EmailMessagesByCompanyIdDistinctCountContactIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  EmailMessagesByCompanyIdDistinctCountContactIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  EmailMessagesByCompanyIdDistinctCountContentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CONTENT_ASC',
  EmailMessagesByCompanyIdDistinctCountContentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CONTENT_DESC',
  EmailMessagesByCompanyIdDistinctCountContentHtmlAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CONTENT_HTML_ASC',
  EmailMessagesByCompanyIdDistinctCountContentHtmlDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CONTENT_HTML_DESC',
  EmailMessagesByCompanyIdDistinctCountCreatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailMessagesByCompanyIdDistinctCountCreatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailMessagesByCompanyIdDistinctCountEmailIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailMessagesByCompanyIdDistinctCountEmailIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailMessagesByCompanyIdDistinctCountForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCompanyIdDistinctCountForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCompanyIdDistinctCountForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdDistinctCountForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdDistinctCountGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_GENERATED_REPLY_ASC',
  EmailMessagesByCompanyIdDistinctCountGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_GENERATED_REPLY_DESC',
  EmailMessagesByCompanyIdDistinctCountHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdDistinctCountHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdDistinctCountIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC',
  EmailMessagesByCompanyIdDistinctCountIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC',
  EmailMessagesByCompanyIdDistinctCountIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_INTENT_ASC',
  EmailMessagesByCompanyIdDistinctCountIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_INTENT_DESC',
  EmailMessagesByCompanyIdDistinctCountIsLawyerAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  EmailMessagesByCompanyIdDistinctCountIsLawyerDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  EmailMessagesByCompanyIdDistinctCountIsReviewedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_IS_REVIEWED_ASC',
  EmailMessagesByCompanyIdDistinctCountIsReviewedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_IS_REVIEWED_DESC',
  EmailMessagesByCompanyIdDistinctCountLawyerIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  EmailMessagesByCompanyIdDistinctCountLawyerIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  EmailMessagesByCompanyIdDistinctCountLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCompanyIdDistinctCountLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCompanyIdDistinctCountNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCompanyIdDistinctCountNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCompanyIdDistinctCountOrderIndexAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_ORDER_INDEX_ASC',
  EmailMessagesByCompanyIdDistinctCountOrderIndexDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_ORDER_INDEX_DESC',
  EmailMessagesByCompanyIdDistinctCountPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCompanyIdDistinctCountPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCompanyIdDistinctCountRepliedIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REPLIED_INTENT_ASC',
  EmailMessagesByCompanyIdDistinctCountRepliedIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REPLIED_INTENT_DESC',
  EmailMessagesByCompanyIdDistinctCountRepliedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REPLIED_TIME_ASC',
  EmailMessagesByCompanyIdDistinctCountRepliedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REPLIED_TIME_DESC',
  EmailMessagesByCompanyIdDistinctCountRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdDistinctCountRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdDistinctCountReviewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  EmailMessagesByCompanyIdDistinctCountReviewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  EmailMessagesByCompanyIdDistinctCountSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCompanyIdDistinctCountSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCompanyIdDistinctCountSentTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_SENT_TIME_ASC',
  EmailMessagesByCompanyIdDistinctCountSentTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_SENT_TIME_DESC',
  EmailMessagesByCompanyIdDistinctCountSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdDistinctCountSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdDistinctCountSubjectAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_SUBJECT_ASC',
  EmailMessagesByCompanyIdDistinctCountSubjectDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_SUBJECT_DESC',
  EmailMessagesByCompanyIdDistinctCountTemplateIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  EmailMessagesByCompanyIdDistinctCountTemplateIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  EmailMessagesByCompanyIdDistinctCountUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailMessagesByCompanyIdDistinctCountUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailMessagesByCompanyIdMaxAutoRepliedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_AUTO_REPLIED_ASC',
  EmailMessagesByCompanyIdMaxAutoRepliedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_AUTO_REPLIED_DESC',
  EmailMessagesByCompanyIdMaxCampaignIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CAMPAIGN_ID_ASC',
  EmailMessagesByCompanyIdMaxCampaignIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CAMPAIGN_ID_DESC',
  EmailMessagesByCompanyIdMaxCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCompanyIdMaxCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCompanyIdMaxCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdMaxCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdMaxCompanyIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_COMPANY_ID_ASC',
  EmailMessagesByCompanyIdMaxCompanyIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_COMPANY_ID_DESC',
  EmailMessagesByCompanyIdMaxContactIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CONTACT_ID_ASC',
  EmailMessagesByCompanyIdMaxContactIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CONTACT_ID_DESC',
  EmailMessagesByCompanyIdMaxContentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CONTENT_ASC',
  EmailMessagesByCompanyIdMaxContentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CONTENT_DESC',
  EmailMessagesByCompanyIdMaxContentHtmlAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CONTENT_HTML_ASC',
  EmailMessagesByCompanyIdMaxContentHtmlDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CONTENT_HTML_DESC',
  EmailMessagesByCompanyIdMaxCreatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CREATED_TIME_ASC',
  EmailMessagesByCompanyIdMaxCreatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_CREATED_TIME_DESC',
  EmailMessagesByCompanyIdMaxEmailIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_EMAIL_ID_ASC',
  EmailMessagesByCompanyIdMaxEmailIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_EMAIL_ID_DESC',
  EmailMessagesByCompanyIdMaxForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCompanyIdMaxForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCompanyIdMaxForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdMaxForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdMaxGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_GENERATED_REPLY_ASC',
  EmailMessagesByCompanyIdMaxGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_GENERATED_REPLY_DESC',
  EmailMessagesByCompanyIdMaxHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdMaxHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdMaxIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_ID_ASC',
  EmailMessagesByCompanyIdMaxIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_ID_DESC',
  EmailMessagesByCompanyIdMaxIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_INTENT_ASC',
  EmailMessagesByCompanyIdMaxIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_INTENT_DESC',
  EmailMessagesByCompanyIdMaxIsLawyerAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_IS_LAWYER_ASC',
  EmailMessagesByCompanyIdMaxIsLawyerDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_IS_LAWYER_DESC',
  EmailMessagesByCompanyIdMaxIsReviewedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_IS_REVIEWED_ASC',
  EmailMessagesByCompanyIdMaxIsReviewedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_IS_REVIEWED_DESC',
  EmailMessagesByCompanyIdMaxLawyerIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_LAWYER_ID_ASC',
  EmailMessagesByCompanyIdMaxLawyerIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_LAWYER_ID_DESC',
  EmailMessagesByCompanyIdMaxLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCompanyIdMaxLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCompanyIdMaxNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCompanyIdMaxNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCompanyIdMaxOrderIndexAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_ORDER_INDEX_ASC',
  EmailMessagesByCompanyIdMaxOrderIndexDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_ORDER_INDEX_DESC',
  EmailMessagesByCompanyIdMaxPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCompanyIdMaxPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCompanyIdMaxRepliedIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_REPLIED_INTENT_ASC',
  EmailMessagesByCompanyIdMaxRepliedIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_REPLIED_INTENT_DESC',
  EmailMessagesByCompanyIdMaxRepliedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_REPLIED_TIME_ASC',
  EmailMessagesByCompanyIdMaxRepliedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_REPLIED_TIME_DESC',
  EmailMessagesByCompanyIdMaxRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdMaxRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdMaxReviewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_REVIEWED_TIME_ASC',
  EmailMessagesByCompanyIdMaxReviewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_REVIEWED_TIME_DESC',
  EmailMessagesByCompanyIdMaxSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCompanyIdMaxSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCompanyIdMaxSentTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_SENT_TIME_ASC',
  EmailMessagesByCompanyIdMaxSentTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_SENT_TIME_DESC',
  EmailMessagesByCompanyIdMaxSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdMaxSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdMaxSubjectAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_SUBJECT_ASC',
  EmailMessagesByCompanyIdMaxSubjectDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_SUBJECT_DESC',
  EmailMessagesByCompanyIdMaxTemplateIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_TEMPLATE_ID_ASC',
  EmailMessagesByCompanyIdMaxTemplateIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_TEMPLATE_ID_DESC',
  EmailMessagesByCompanyIdMaxUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_UPDATED_TIME_ASC',
  EmailMessagesByCompanyIdMaxUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MAX_UPDATED_TIME_DESC',
  EmailMessagesByCompanyIdMinAutoRepliedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_AUTO_REPLIED_ASC',
  EmailMessagesByCompanyIdMinAutoRepliedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_AUTO_REPLIED_DESC',
  EmailMessagesByCompanyIdMinCampaignIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CAMPAIGN_ID_ASC',
  EmailMessagesByCompanyIdMinCampaignIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CAMPAIGN_ID_DESC',
  EmailMessagesByCompanyIdMinCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCompanyIdMinCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCompanyIdMinCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdMinCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdMinCompanyIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_COMPANY_ID_ASC',
  EmailMessagesByCompanyIdMinCompanyIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_COMPANY_ID_DESC',
  EmailMessagesByCompanyIdMinContactIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CONTACT_ID_ASC',
  EmailMessagesByCompanyIdMinContactIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CONTACT_ID_DESC',
  EmailMessagesByCompanyIdMinContentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CONTENT_ASC',
  EmailMessagesByCompanyIdMinContentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CONTENT_DESC',
  EmailMessagesByCompanyIdMinContentHtmlAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CONTENT_HTML_ASC',
  EmailMessagesByCompanyIdMinContentHtmlDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CONTENT_HTML_DESC',
  EmailMessagesByCompanyIdMinCreatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CREATED_TIME_ASC',
  EmailMessagesByCompanyIdMinCreatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_CREATED_TIME_DESC',
  EmailMessagesByCompanyIdMinEmailIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_EMAIL_ID_ASC',
  EmailMessagesByCompanyIdMinEmailIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_EMAIL_ID_DESC',
  EmailMessagesByCompanyIdMinForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCompanyIdMinForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCompanyIdMinForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdMinForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdMinGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_GENERATED_REPLY_ASC',
  EmailMessagesByCompanyIdMinGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_GENERATED_REPLY_DESC',
  EmailMessagesByCompanyIdMinHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdMinHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdMinIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_ID_ASC',
  EmailMessagesByCompanyIdMinIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_ID_DESC',
  EmailMessagesByCompanyIdMinIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_INTENT_ASC',
  EmailMessagesByCompanyIdMinIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_INTENT_DESC',
  EmailMessagesByCompanyIdMinIsLawyerAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_IS_LAWYER_ASC',
  EmailMessagesByCompanyIdMinIsLawyerDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_IS_LAWYER_DESC',
  EmailMessagesByCompanyIdMinIsReviewedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_IS_REVIEWED_ASC',
  EmailMessagesByCompanyIdMinIsReviewedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_IS_REVIEWED_DESC',
  EmailMessagesByCompanyIdMinLawyerIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_LAWYER_ID_ASC',
  EmailMessagesByCompanyIdMinLawyerIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_LAWYER_ID_DESC',
  EmailMessagesByCompanyIdMinLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCompanyIdMinLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCompanyIdMinNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCompanyIdMinNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCompanyIdMinOrderIndexAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_ORDER_INDEX_ASC',
  EmailMessagesByCompanyIdMinOrderIndexDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_ORDER_INDEX_DESC',
  EmailMessagesByCompanyIdMinPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCompanyIdMinPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCompanyIdMinRepliedIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_REPLIED_INTENT_ASC',
  EmailMessagesByCompanyIdMinRepliedIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_REPLIED_INTENT_DESC',
  EmailMessagesByCompanyIdMinRepliedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_REPLIED_TIME_ASC',
  EmailMessagesByCompanyIdMinRepliedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_REPLIED_TIME_DESC',
  EmailMessagesByCompanyIdMinRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdMinRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdMinReviewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_REVIEWED_TIME_ASC',
  EmailMessagesByCompanyIdMinReviewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_REVIEWED_TIME_DESC',
  EmailMessagesByCompanyIdMinSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCompanyIdMinSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCompanyIdMinSentTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_SENT_TIME_ASC',
  EmailMessagesByCompanyIdMinSentTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_SENT_TIME_DESC',
  EmailMessagesByCompanyIdMinSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdMinSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdMinSubjectAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_SUBJECT_ASC',
  EmailMessagesByCompanyIdMinSubjectDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_SUBJECT_DESC',
  EmailMessagesByCompanyIdMinTemplateIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_TEMPLATE_ID_ASC',
  EmailMessagesByCompanyIdMinTemplateIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_TEMPLATE_ID_DESC',
  EmailMessagesByCompanyIdMinUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_UPDATED_TIME_ASC',
  EmailMessagesByCompanyIdMinUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_MIN_UPDATED_TIME_DESC',
  EmailMessagesByCompanyIdStddevPopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByCompanyIdStddevPopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByCompanyIdStddevPopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByCompanyIdStddevPopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByCompanyIdStddevPopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCompanyIdStddevPopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCompanyIdStddevPopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdStddevPopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdStddevPopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByCompanyIdStddevPopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByCompanyIdStddevPopulationContactIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByCompanyIdStddevPopulationContactIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByCompanyIdStddevPopulationContentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CONTENT_ASC',
  EmailMessagesByCompanyIdStddevPopulationContentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CONTENT_DESC',
  EmailMessagesByCompanyIdStddevPopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByCompanyIdStddevPopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByCompanyIdStddevPopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByCompanyIdStddevPopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByCompanyIdStddevPopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByCompanyIdStddevPopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByCompanyIdStddevPopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCompanyIdStddevPopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCompanyIdStddevPopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdStddevPopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdStddevPopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByCompanyIdStddevPopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByCompanyIdStddevPopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdStddevPopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdStddevPopulationIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC',
  EmailMessagesByCompanyIdStddevPopulationIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC',
  EmailMessagesByCompanyIdStddevPopulationIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_INTENT_ASC',
  EmailMessagesByCompanyIdStddevPopulationIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_INTENT_DESC',
  EmailMessagesByCompanyIdStddevPopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByCompanyIdStddevPopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByCompanyIdStddevPopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByCompanyIdStddevPopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByCompanyIdStddevPopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByCompanyIdStddevPopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByCompanyIdStddevPopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCompanyIdStddevPopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCompanyIdStddevPopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCompanyIdStddevPopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCompanyIdStddevPopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByCompanyIdStddevPopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByCompanyIdStddevPopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCompanyIdStddevPopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCompanyIdStddevPopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByCompanyIdStddevPopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByCompanyIdStddevPopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByCompanyIdStddevPopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByCompanyIdStddevPopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdStddevPopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdStddevPopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByCompanyIdStddevPopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByCompanyIdStddevPopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCompanyIdStddevPopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCompanyIdStddevPopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_SENT_TIME_ASC',
  EmailMessagesByCompanyIdStddevPopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_SENT_TIME_DESC',
  EmailMessagesByCompanyIdStddevPopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdStddevPopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdStddevPopulationSubjectAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_SUBJECT_ASC',
  EmailMessagesByCompanyIdStddevPopulationSubjectDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_SUBJECT_DESC',
  EmailMessagesByCompanyIdStddevPopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByCompanyIdStddevPopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByCompanyIdStddevPopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByCompanyIdStddevPopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByCompanyIdStddevSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByCompanyIdStddevSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByCompanyIdStddevSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByCompanyIdStddevSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByCompanyIdStddevSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCompanyIdStddevSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCompanyIdStddevSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdStddevSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdStddevSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByCompanyIdStddevSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByCompanyIdStddevSampleContactIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByCompanyIdStddevSampleContactIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByCompanyIdStddevSampleContentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CONTENT_ASC',
  EmailMessagesByCompanyIdStddevSampleContentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CONTENT_DESC',
  EmailMessagesByCompanyIdStddevSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByCompanyIdStddevSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByCompanyIdStddevSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByCompanyIdStddevSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByCompanyIdStddevSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByCompanyIdStddevSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByCompanyIdStddevSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCompanyIdStddevSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCompanyIdStddevSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdStddevSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdStddevSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByCompanyIdStddevSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByCompanyIdStddevSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdStddevSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdStddevSampleIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC',
  EmailMessagesByCompanyIdStddevSampleIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC',
  EmailMessagesByCompanyIdStddevSampleIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_INTENT_ASC',
  EmailMessagesByCompanyIdStddevSampleIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_INTENT_DESC',
  EmailMessagesByCompanyIdStddevSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByCompanyIdStddevSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByCompanyIdStddevSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByCompanyIdStddevSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByCompanyIdStddevSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByCompanyIdStddevSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByCompanyIdStddevSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCompanyIdStddevSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCompanyIdStddevSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCompanyIdStddevSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCompanyIdStddevSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByCompanyIdStddevSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByCompanyIdStddevSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCompanyIdStddevSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCompanyIdStddevSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByCompanyIdStddevSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByCompanyIdStddevSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByCompanyIdStddevSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByCompanyIdStddevSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdStddevSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdStddevSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByCompanyIdStddevSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByCompanyIdStddevSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCompanyIdStddevSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCompanyIdStddevSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByCompanyIdStddevSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByCompanyIdStddevSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdStddevSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdStddevSampleSubjectAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_SUBJECT_ASC',
  EmailMessagesByCompanyIdStddevSampleSubjectDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_SUBJECT_DESC',
  EmailMessagesByCompanyIdStddevSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByCompanyIdStddevSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByCompanyIdStddevSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByCompanyIdStddevSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailMessagesByCompanyIdSumAutoRepliedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_AUTO_REPLIED_ASC',
  EmailMessagesByCompanyIdSumAutoRepliedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_AUTO_REPLIED_DESC',
  EmailMessagesByCompanyIdSumCampaignIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CAMPAIGN_ID_ASC',
  EmailMessagesByCompanyIdSumCampaignIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CAMPAIGN_ID_DESC',
  EmailMessagesByCompanyIdSumCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCompanyIdSumCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCompanyIdSumCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdSumCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdSumCompanyIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_COMPANY_ID_ASC',
  EmailMessagesByCompanyIdSumCompanyIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_COMPANY_ID_DESC',
  EmailMessagesByCompanyIdSumContactIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CONTACT_ID_ASC',
  EmailMessagesByCompanyIdSumContactIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CONTACT_ID_DESC',
  EmailMessagesByCompanyIdSumContentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CONTENT_ASC',
  EmailMessagesByCompanyIdSumContentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CONTENT_DESC',
  EmailMessagesByCompanyIdSumContentHtmlAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CONTENT_HTML_ASC',
  EmailMessagesByCompanyIdSumContentHtmlDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CONTENT_HTML_DESC',
  EmailMessagesByCompanyIdSumCreatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CREATED_TIME_ASC',
  EmailMessagesByCompanyIdSumCreatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_CREATED_TIME_DESC',
  EmailMessagesByCompanyIdSumEmailIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_EMAIL_ID_ASC',
  EmailMessagesByCompanyIdSumEmailIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_EMAIL_ID_DESC',
  EmailMessagesByCompanyIdSumForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCompanyIdSumForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCompanyIdSumForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdSumForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdSumGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_GENERATED_REPLY_ASC',
  EmailMessagesByCompanyIdSumGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_GENERATED_REPLY_DESC',
  EmailMessagesByCompanyIdSumHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdSumHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdSumIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_ID_ASC',
  EmailMessagesByCompanyIdSumIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_ID_DESC',
  EmailMessagesByCompanyIdSumIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_INTENT_ASC',
  EmailMessagesByCompanyIdSumIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_INTENT_DESC',
  EmailMessagesByCompanyIdSumIsLawyerAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_IS_LAWYER_ASC',
  EmailMessagesByCompanyIdSumIsLawyerDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_IS_LAWYER_DESC',
  EmailMessagesByCompanyIdSumIsReviewedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_IS_REVIEWED_ASC',
  EmailMessagesByCompanyIdSumIsReviewedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_IS_REVIEWED_DESC',
  EmailMessagesByCompanyIdSumLawyerIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_LAWYER_ID_ASC',
  EmailMessagesByCompanyIdSumLawyerIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_LAWYER_ID_DESC',
  EmailMessagesByCompanyIdSumLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCompanyIdSumLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCompanyIdSumNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCompanyIdSumNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCompanyIdSumOrderIndexAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_ORDER_INDEX_ASC',
  EmailMessagesByCompanyIdSumOrderIndexDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_ORDER_INDEX_DESC',
  EmailMessagesByCompanyIdSumPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCompanyIdSumPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCompanyIdSumRepliedIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_REPLIED_INTENT_ASC',
  EmailMessagesByCompanyIdSumRepliedIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_REPLIED_INTENT_DESC',
  EmailMessagesByCompanyIdSumRepliedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_REPLIED_TIME_ASC',
  EmailMessagesByCompanyIdSumRepliedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_REPLIED_TIME_DESC',
  EmailMessagesByCompanyIdSumRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdSumRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdSumReviewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_REVIEWED_TIME_ASC',
  EmailMessagesByCompanyIdSumReviewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_REVIEWED_TIME_DESC',
  EmailMessagesByCompanyIdSumSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCompanyIdSumSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCompanyIdSumSentTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_SENT_TIME_ASC',
  EmailMessagesByCompanyIdSumSentTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_SENT_TIME_DESC',
  EmailMessagesByCompanyIdSumSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdSumSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdSumSubjectAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_SUBJECT_ASC',
  EmailMessagesByCompanyIdSumSubjectDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_SUBJECT_DESC',
  EmailMessagesByCompanyIdSumTemplateIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_TEMPLATE_ID_ASC',
  EmailMessagesByCompanyIdSumTemplateIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_TEMPLATE_ID_DESC',
  EmailMessagesByCompanyIdSumUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_UPDATED_TIME_ASC',
  EmailMessagesByCompanyIdSumUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_SUM_UPDATED_TIME_DESC',
  EmailMessagesByCompanyIdVariancePopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByCompanyIdVariancePopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByCompanyIdVariancePopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByCompanyIdVariancePopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByCompanyIdVariancePopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCompanyIdVariancePopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCompanyIdVariancePopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdVariancePopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdVariancePopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByCompanyIdVariancePopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByCompanyIdVariancePopulationContactIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByCompanyIdVariancePopulationContactIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByCompanyIdVariancePopulationContentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CONTENT_ASC',
  EmailMessagesByCompanyIdVariancePopulationContentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CONTENT_DESC',
  EmailMessagesByCompanyIdVariancePopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByCompanyIdVariancePopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByCompanyIdVariancePopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByCompanyIdVariancePopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByCompanyIdVariancePopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByCompanyIdVariancePopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByCompanyIdVariancePopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCompanyIdVariancePopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCompanyIdVariancePopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdVariancePopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdVariancePopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByCompanyIdVariancePopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByCompanyIdVariancePopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdVariancePopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdVariancePopulationIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC',
  EmailMessagesByCompanyIdVariancePopulationIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC',
  EmailMessagesByCompanyIdVariancePopulationIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_INTENT_ASC',
  EmailMessagesByCompanyIdVariancePopulationIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_INTENT_DESC',
  EmailMessagesByCompanyIdVariancePopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByCompanyIdVariancePopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByCompanyIdVariancePopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByCompanyIdVariancePopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByCompanyIdVariancePopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByCompanyIdVariancePopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByCompanyIdVariancePopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCompanyIdVariancePopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCompanyIdVariancePopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCompanyIdVariancePopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCompanyIdVariancePopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByCompanyIdVariancePopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByCompanyIdVariancePopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCompanyIdVariancePopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCompanyIdVariancePopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByCompanyIdVariancePopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByCompanyIdVariancePopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByCompanyIdVariancePopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByCompanyIdVariancePopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdVariancePopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdVariancePopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByCompanyIdVariancePopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByCompanyIdVariancePopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCompanyIdVariancePopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCompanyIdVariancePopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_SENT_TIME_ASC',
  EmailMessagesByCompanyIdVariancePopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_SENT_TIME_DESC',
  EmailMessagesByCompanyIdVariancePopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdVariancePopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdVariancePopulationSubjectAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_SUBJECT_ASC',
  EmailMessagesByCompanyIdVariancePopulationSubjectDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_SUBJECT_DESC',
  EmailMessagesByCompanyIdVariancePopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByCompanyIdVariancePopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByCompanyIdVariancePopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByCompanyIdVariancePopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByCompanyIdVarianceSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByCompanyIdVarianceSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByCompanyIdVarianceSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByCompanyIdVarianceSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByCompanyIdVarianceSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByCompanyIdVarianceSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByCompanyIdVarianceSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdVarianceSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdVarianceSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByCompanyIdVarianceSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByCompanyIdVarianceSampleContactIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByCompanyIdVarianceSampleContactIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByCompanyIdVarianceSampleContentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  EmailMessagesByCompanyIdVarianceSampleContentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  EmailMessagesByCompanyIdVarianceSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByCompanyIdVarianceSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByCompanyIdVarianceSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByCompanyIdVarianceSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByCompanyIdVarianceSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByCompanyIdVarianceSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByCompanyIdVarianceSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByCompanyIdVarianceSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByCompanyIdVarianceSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdVarianceSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdVarianceSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByCompanyIdVarianceSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByCompanyIdVarianceSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdVarianceSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdVarianceSampleIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailMessagesByCompanyIdVarianceSampleIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailMessagesByCompanyIdVarianceSampleIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_INTENT_ASC',
  EmailMessagesByCompanyIdVarianceSampleIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_INTENT_DESC',
  EmailMessagesByCompanyIdVarianceSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByCompanyIdVarianceSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByCompanyIdVarianceSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByCompanyIdVarianceSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByCompanyIdVarianceSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByCompanyIdVarianceSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByCompanyIdVarianceSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByCompanyIdVarianceSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByCompanyIdVarianceSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByCompanyIdVarianceSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByCompanyIdVarianceSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByCompanyIdVarianceSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByCompanyIdVarianceSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByCompanyIdVarianceSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByCompanyIdVarianceSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByCompanyIdVarianceSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByCompanyIdVarianceSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByCompanyIdVarianceSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByCompanyIdVarianceSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByCompanyIdVarianceSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByCompanyIdVarianceSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByCompanyIdVarianceSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByCompanyIdVarianceSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByCompanyIdVarianceSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByCompanyIdVarianceSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByCompanyIdVarianceSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByCompanyIdVarianceSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByCompanyIdVarianceSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByCompanyIdVarianceSampleSubjectAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_SUBJECT_ASC',
  EmailMessagesByCompanyIdVarianceSampleSubjectDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_SUBJECT_DESC',
  EmailMessagesByCompanyIdVarianceSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByCompanyIdVarianceSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByCompanyIdVarianceSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByCompanyIdVarianceSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  FacebookUrlAsc = 'FACEBOOK_URL_ASC',
  FacebookUrlDesc = 'FACEBOOK_URL_DESC',
  HouseUrlAsc = 'HOUSE_URL_ASC',
  HouseUrlDesc = 'HOUSE_URL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KeywordsAsc = 'KEYWORDS_ASC',
  KeywordsDesc = 'KEYWORDS_DESC',
  LastIndustryEnrichmentAsc = 'LAST_INDUSTRY_ENRICHMENT_ASC',
  LastIndustryEnrichmentDesc = 'LAST_INDUSTRY_ENRICHMENT_DESC',
  LastLegalUrlsEnrichmentAsc = 'LAST_LEGAL_URLS_ENRICHMENT_ASC',
  LastLegalUrlsEnrichmentDesc = 'LAST_LEGAL_URLS_ENRICHMENT_DESC',
  LastPerigonEnrichmentAsc = 'LAST_PERIGON_ENRICHMENT_ASC',
  LastPerigonEnrichmentDesc = 'LAST_PERIGON_ENRICHMENT_DESC',
  LastSecEnrichmentAsc = 'LAST_SEC_ENRICHMENT_ASC',
  LastSecEnrichmentDesc = 'LAST_SEC_ENRICHMENT_DESC',
  LegalNameAsc = 'LEGAL_NAME_ASC',
  LegalNameDesc = 'LEGAL_NAME_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_CONTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_CONTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_INTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_INTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_SEND_AT_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_SEND_AT_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdAverageUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdAverageUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_AVERAGE_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdCountAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_COUNT_ASC',
  LinkedinScheduledMessagesByCompanyIdCountDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_COUNT_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CONTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CONTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_INTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_INTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_SEND_AT_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_SEND_AT_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdDistinctCountUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_CONTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_CONTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_INTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_INTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_SEND_AT_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_SEND_AT_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdMaxUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdMaxUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MAX_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdMinCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdMinCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdMinCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdMinCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdMinCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdMinCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdMinContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdMinContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdMinContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_CONTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdMinContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_CONTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdMinCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdMinCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdMinForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdMinForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdMinIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdMinIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdMinIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_INTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdMinIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_INTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdMinIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCompanyIdMinIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCompanyIdMinIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCompanyIdMinIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCompanyIdMinIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCompanyIdMinIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCompanyIdMinLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdMinLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdMinLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdMinLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdMinRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdMinRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdMinReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdMinReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdMinReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdMinReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdMinSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_SEND_AT_ASC',
  LinkedinScheduledMessagesByCompanyIdMinSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_SEND_AT_DESC',
  LinkedinScheduledMessagesByCompanyIdMinStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdMinStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdMinUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdMinUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_MIN_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CONTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CONTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_INTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_INTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_SEND_AT_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_SEND_AT_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevPopulationUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CONTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CONTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_INTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_INTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_SEND_AT_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_SEND_AT_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdStddevSampleUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdSumCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdSumCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdSumCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdSumCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdSumCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdSumCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdSumContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdSumContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdSumContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_CONTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdSumContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_CONTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdSumCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdSumCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdSumForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdSumForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdSumIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdSumIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdSumIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_INTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdSumIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_INTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdSumIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCompanyIdSumIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCompanyIdSumIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCompanyIdSumIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCompanyIdSumIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCompanyIdSumIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCompanyIdSumLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdSumLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdSumLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdSumLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdSumRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdSumRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdSumReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdSumReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdSumReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdSumReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdSumSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_SEND_AT_ASC',
  LinkedinScheduledMessagesByCompanyIdSumSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_SEND_AT_DESC',
  LinkedinScheduledMessagesByCompanyIdSumStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdSumStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdSumUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdSumUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_SUM_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CONTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CONTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_INTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_INTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_SEND_AT_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_SEND_AT_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdVariancePopulationUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_INTENT_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_INTENT_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_SEND_AT_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_SEND_AT_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_STATUS_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_STATUS_DESC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByCompanyIdVarianceSampleUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  LinkedinUidAsc = 'LINKEDIN_UID_ASC',
  LinkedinUidDesc = 'LINKEDIN_UID_DESC',
  LinkedinUrlAsc = 'LINKEDIN_URL_ASC',
  LinkedinUrlDesc = 'LINKEDIN_URL_DESC',
  LogoUrlAsc = 'LOGO_URL_ASC',
  LogoUrlDesc = 'LOGO_URL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SocialProofsByCompanyIdAverageCompanyIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_ASC',
  SocialProofsByCompanyIdAverageCompanyIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_COMPANY_ID_DESC',
  SocialProofsByCompanyIdAverageContactIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_CONTACT_ID_ASC',
  SocialProofsByCompanyIdAverageContactIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_CONTACT_ID_DESC',
  SocialProofsByCompanyIdAverageCreatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_CREATED_TIME_ASC',
  SocialProofsByCompanyIdAverageCreatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_CREATED_TIME_DESC',
  SocialProofsByCompanyIdAverageIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_ID_ASC',
  SocialProofsByCompanyIdAverageIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_ID_DESC',
  SocialProofsByCompanyIdAverageLawyerIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_LAWYER_ID_ASC',
  SocialProofsByCompanyIdAverageLawyerIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_LAWYER_ID_DESC',
  SocialProofsByCompanyIdAverageTextAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_TEXT_ASC',
  SocialProofsByCompanyIdAverageTextDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_TEXT_DESC',
  SocialProofsByCompanyIdAverageUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_UPDATED_TIME_ASC',
  SocialProofsByCompanyIdAverageUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_AVERAGE_UPDATED_TIME_DESC',
  SocialProofsByCompanyIdCountAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_COUNT_ASC',
  SocialProofsByCompanyIdCountDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_COUNT_DESC',
  SocialProofsByCompanyIdDistinctCountCompanyIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  SocialProofsByCompanyIdDistinctCountCompanyIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  SocialProofsByCompanyIdDistinctCountContactIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  SocialProofsByCompanyIdDistinctCountContactIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  SocialProofsByCompanyIdDistinctCountCreatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  SocialProofsByCompanyIdDistinctCountCreatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  SocialProofsByCompanyIdDistinctCountIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_ID_ASC',
  SocialProofsByCompanyIdDistinctCountIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_ID_DESC',
  SocialProofsByCompanyIdDistinctCountLawyerIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  SocialProofsByCompanyIdDistinctCountLawyerIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  SocialProofsByCompanyIdDistinctCountTextAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_TEXT_ASC',
  SocialProofsByCompanyIdDistinctCountTextDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_TEXT_DESC',
  SocialProofsByCompanyIdDistinctCountUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  SocialProofsByCompanyIdDistinctCountUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  SocialProofsByCompanyIdMaxCompanyIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_COMPANY_ID_ASC',
  SocialProofsByCompanyIdMaxCompanyIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_COMPANY_ID_DESC',
  SocialProofsByCompanyIdMaxContactIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_CONTACT_ID_ASC',
  SocialProofsByCompanyIdMaxContactIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_CONTACT_ID_DESC',
  SocialProofsByCompanyIdMaxCreatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_CREATED_TIME_ASC',
  SocialProofsByCompanyIdMaxCreatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_CREATED_TIME_DESC',
  SocialProofsByCompanyIdMaxIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_ID_ASC',
  SocialProofsByCompanyIdMaxIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_ID_DESC',
  SocialProofsByCompanyIdMaxLawyerIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_LAWYER_ID_ASC',
  SocialProofsByCompanyIdMaxLawyerIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_LAWYER_ID_DESC',
  SocialProofsByCompanyIdMaxTextAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_TEXT_ASC',
  SocialProofsByCompanyIdMaxTextDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_TEXT_DESC',
  SocialProofsByCompanyIdMaxUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_UPDATED_TIME_ASC',
  SocialProofsByCompanyIdMaxUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MAX_UPDATED_TIME_DESC',
  SocialProofsByCompanyIdMinCompanyIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_COMPANY_ID_ASC',
  SocialProofsByCompanyIdMinCompanyIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_COMPANY_ID_DESC',
  SocialProofsByCompanyIdMinContactIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_CONTACT_ID_ASC',
  SocialProofsByCompanyIdMinContactIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_CONTACT_ID_DESC',
  SocialProofsByCompanyIdMinCreatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_CREATED_TIME_ASC',
  SocialProofsByCompanyIdMinCreatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_CREATED_TIME_DESC',
  SocialProofsByCompanyIdMinIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_ID_ASC',
  SocialProofsByCompanyIdMinIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_ID_DESC',
  SocialProofsByCompanyIdMinLawyerIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_LAWYER_ID_ASC',
  SocialProofsByCompanyIdMinLawyerIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_LAWYER_ID_DESC',
  SocialProofsByCompanyIdMinTextAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_TEXT_ASC',
  SocialProofsByCompanyIdMinTextDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_TEXT_DESC',
  SocialProofsByCompanyIdMinUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_UPDATED_TIME_ASC',
  SocialProofsByCompanyIdMinUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_MIN_UPDATED_TIME_DESC',
  SocialProofsByCompanyIdStddevPopulationCompanyIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  SocialProofsByCompanyIdStddevPopulationCompanyIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  SocialProofsByCompanyIdStddevPopulationContactIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  SocialProofsByCompanyIdStddevPopulationContactIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  SocialProofsByCompanyIdStddevPopulationCreatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  SocialProofsByCompanyIdStddevPopulationCreatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  SocialProofsByCompanyIdStddevPopulationIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_ID_ASC',
  SocialProofsByCompanyIdStddevPopulationIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_ID_DESC',
  SocialProofsByCompanyIdStddevPopulationLawyerIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  SocialProofsByCompanyIdStddevPopulationLawyerIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  SocialProofsByCompanyIdStddevPopulationTextAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_TEXT_ASC',
  SocialProofsByCompanyIdStddevPopulationTextDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_TEXT_DESC',
  SocialProofsByCompanyIdStddevPopulationUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  SocialProofsByCompanyIdStddevPopulationUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  SocialProofsByCompanyIdStddevSampleCompanyIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SocialProofsByCompanyIdStddevSampleCompanyIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SocialProofsByCompanyIdStddevSampleContactIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  SocialProofsByCompanyIdStddevSampleContactIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  SocialProofsByCompanyIdStddevSampleCreatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  SocialProofsByCompanyIdStddevSampleCreatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  SocialProofsByCompanyIdStddevSampleIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_ASC',
  SocialProofsByCompanyIdStddevSampleIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_ID_DESC',
  SocialProofsByCompanyIdStddevSampleLawyerIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  SocialProofsByCompanyIdStddevSampleLawyerIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  SocialProofsByCompanyIdStddevSampleTextAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_TEXT_ASC',
  SocialProofsByCompanyIdStddevSampleTextDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_TEXT_DESC',
  SocialProofsByCompanyIdStddevSampleUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  SocialProofsByCompanyIdStddevSampleUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  SocialProofsByCompanyIdSumCompanyIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_COMPANY_ID_ASC',
  SocialProofsByCompanyIdSumCompanyIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_COMPANY_ID_DESC',
  SocialProofsByCompanyIdSumContactIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_CONTACT_ID_ASC',
  SocialProofsByCompanyIdSumContactIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_CONTACT_ID_DESC',
  SocialProofsByCompanyIdSumCreatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_CREATED_TIME_ASC',
  SocialProofsByCompanyIdSumCreatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_CREATED_TIME_DESC',
  SocialProofsByCompanyIdSumIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_ID_ASC',
  SocialProofsByCompanyIdSumIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_ID_DESC',
  SocialProofsByCompanyIdSumLawyerIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_LAWYER_ID_ASC',
  SocialProofsByCompanyIdSumLawyerIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_LAWYER_ID_DESC',
  SocialProofsByCompanyIdSumTextAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_TEXT_ASC',
  SocialProofsByCompanyIdSumTextDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_TEXT_DESC',
  SocialProofsByCompanyIdSumUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_UPDATED_TIME_ASC',
  SocialProofsByCompanyIdSumUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_SUM_UPDATED_TIME_DESC',
  SocialProofsByCompanyIdVariancePopulationCompanyIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SocialProofsByCompanyIdVariancePopulationCompanyIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SocialProofsByCompanyIdVariancePopulationContactIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  SocialProofsByCompanyIdVariancePopulationContactIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  SocialProofsByCompanyIdVariancePopulationCreatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  SocialProofsByCompanyIdVariancePopulationCreatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  SocialProofsByCompanyIdVariancePopulationIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_ASC',
  SocialProofsByCompanyIdVariancePopulationIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_ID_DESC',
  SocialProofsByCompanyIdVariancePopulationLawyerIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  SocialProofsByCompanyIdVariancePopulationLawyerIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  SocialProofsByCompanyIdVariancePopulationTextAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_TEXT_ASC',
  SocialProofsByCompanyIdVariancePopulationTextDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_TEXT_DESC',
  SocialProofsByCompanyIdVariancePopulationUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  SocialProofsByCompanyIdVariancePopulationUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  SocialProofsByCompanyIdVarianceSampleCompanyIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SocialProofsByCompanyIdVarianceSampleCompanyIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SocialProofsByCompanyIdVarianceSampleContactIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  SocialProofsByCompanyIdVarianceSampleContactIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  SocialProofsByCompanyIdVarianceSampleCreatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  SocialProofsByCompanyIdVarianceSampleCreatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  SocialProofsByCompanyIdVarianceSampleIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_ASC',
  SocialProofsByCompanyIdVarianceSampleIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_ID_DESC',
  SocialProofsByCompanyIdVarianceSampleLawyerIdAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  SocialProofsByCompanyIdVarianceSampleLawyerIdDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  SocialProofsByCompanyIdVarianceSampleTextAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_TEXT_ASC',
  SocialProofsByCompanyIdVarianceSampleTextDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_TEXT_DESC',
  SocialProofsByCompanyIdVarianceSampleUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  SocialProofsByCompanyIdVarianceSampleUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_COMPANY_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  SummaryAsc = 'SUMMARY_ASC',
  SummaryDesc = 'SUMMARY_DESC',
  TwitterUrlAsc = 'TWITTER_URL_ASC',
  TwitterUrlDesc = 'TWITTER_URL_DESC',
  WebsiteUrlAsc = 'WEBSITE_URL_ASC',
  WebsiteUrlDesc = 'WEBSITE_URL_DESC'
}

export type Company = Node & {
  __typename?: 'Company';
  apolloId?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `CompanyPersonalisation`. */
  companyPersonalisationsByCompanyId: CompanyPersonalisationsConnection;
  /** Reads and enables pagination through a set of `Contact`. */
  contactsByCompanyId: ContactsConnection;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByCompanyId: EmailMessagesConnection;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  houseUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  keywords?: Maybe<Scalars['String']['output']>;
  lastIndustryEnrichment?: Maybe<Scalars['Datetime']['output']>;
  lastLegalUrlsEnrichment?: Maybe<Scalars['Datetime']['output']>;
  lastPerigonEnrichment?: Maybe<Scalars['Datetime']['output']>;
  lastSecEnrichment?: Maybe<Scalars['Datetime']['output']>;
  legalName?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `LinkedinScheduledMessage`. */
  linkedinScheduledMessagesByCompanyId: LinkedinScheduledMessagesConnection;
  linkedinUid?: Maybe<Scalars['String']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  logoUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `SocialProof`. */
  socialProofsByCompanyId: SocialProofsConnection;
  summary?: Maybe<Scalars['String']['output']>;
  twitterUrl?: Maybe<Scalars['String']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};


export type CompanyCompanyPersonalisationsByCompanyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyPersonalisationCondition>;
  filter?: InputMaybe<CompanyPersonalisationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyPersonalisationsOrderBy>>;
  sort?: InputMaybe<Array<CompanyPersonalisationSort>>;
};


export type CompanyContactsByCompanyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ContactCondition>;
  filter?: InputMaybe<ContactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
  sort?: InputMaybe<Array<ContactSort>>;
};


export type CompanyEmailMessagesByCompanyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailMessageCondition>;
  filter?: InputMaybe<EmailMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailMessagesOrderBy>>;
  sort?: InputMaybe<Array<EmailMessageSort>>;
};


export type CompanyLinkedinScheduledMessagesByCompanyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinScheduledMessageCondition>;
  filter?: InputMaybe<LinkedinScheduledMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinScheduledMessagesOrderBy>>;
  sort?: InputMaybe<Array<LinkedinScheduledMessageSort>>;
};


export type CompanySocialProofsByCompanyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SocialProofCondition>;
  filter?: InputMaybe<SocialProofFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SocialProofsOrderBy>>;
  sort?: InputMaybe<Array<SocialProofSort>>;
};

export type CompanyAggregates = {
  __typename?: 'CompanyAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/** A condition to be used against `Company` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type CompanyCondition = {
  /** Checks for equality with the object’s `apolloId` field. */
  apolloId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `houseUrl` field. */
  houseUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `keywords` field. */
  keywords?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `lastIndustryEnrichment` field. */
  lastIndustryEnrichment?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastLegalUrlsEnrichment` field. */
  lastLegalUrlsEnrichment?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastPerigonEnrichment` field. */
  lastPerigonEnrichment?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastSecEnrichment` field. */
  lastSecEnrichment?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `legalName` field. */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `linkedinUid` field. */
  linkedinUid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `logoUrl` field. */
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `summary` field. */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyDistinctCountAggregates = {
  __typename?: 'CompanyDistinctCountAggregates';
  /** Distinct count of apolloId across the matching connection */
  apolloId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of facebookUrl across the matching connection */
  facebookUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of houseUrl across the matching connection */
  houseUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of keywords across the matching connection */
  keywords?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastIndustryEnrichment across the matching connection */
  lastIndustryEnrichment?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastLegalUrlsEnrichment across the matching connection */
  lastLegalUrlsEnrichment?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastPerigonEnrichment across the matching connection */
  lastPerigonEnrichment?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastSecEnrichment across the matching connection */
  lastSecEnrichment?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of legalName across the matching connection */
  legalName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of linkedinUid across the matching connection */
  linkedinUid?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of linkedinUrl across the matching connection */
  linkedinUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of logoUrl across the matching connection */
  logoUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of summary across the matching connection */
  summary?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of twitterUrl across the matching connection */
  twitterUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of websiteUrl across the matching connection */
  websiteUrl?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Company` object types. All fields are combined with a logical ‘and.’ */
export type CompanyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CompanyFilter>>;
  /** Filter by the object’s `apolloId` field. */
  apolloId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `houseUrl` field. */
  houseUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `keywords` field. */
  keywords?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastIndustryEnrichment` field. */
  lastIndustryEnrichment?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastLegalUrlsEnrichment` field. */
  lastLegalUrlsEnrichment?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastPerigonEnrichment` field. */
  lastPerigonEnrichment?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastSecEnrichment` field. */
  lastSecEnrichment?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `legalName` field. */
  legalName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `linkedinUid` field. */
  linkedinUid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `logoUrl` field. */
  logoUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CompanyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CompanyFilter>>;
  /** Filter by the object’s `summary` field. */
  summary?: InputMaybe<StringFilter>;
  /** Filter by the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<StringFilter>;
};

/** Grouping methods for `Company` for usage during aggregation. */
export enum CompanyGroupBy {
  ApolloId = 'APOLLO_ID',
  FacebookUrl = 'FACEBOOK_URL',
  HouseUrl = 'HOUSE_URL',
  Keywords = 'KEYWORDS',
  LastIndustryEnrichment = 'LAST_INDUSTRY_ENRICHMENT',
  LastIndustryEnrichmentTruncatedToDay = 'LAST_INDUSTRY_ENRICHMENT_TRUNCATED_TO_DAY',
  LastIndustryEnrichmentTruncatedToHour = 'LAST_INDUSTRY_ENRICHMENT_TRUNCATED_TO_HOUR',
  LastLegalUrlsEnrichment = 'LAST_LEGAL_URLS_ENRICHMENT',
  LastLegalUrlsEnrichmentTruncatedToDay = 'LAST_LEGAL_URLS_ENRICHMENT_TRUNCATED_TO_DAY',
  LastLegalUrlsEnrichmentTruncatedToHour = 'LAST_LEGAL_URLS_ENRICHMENT_TRUNCATED_TO_HOUR',
  LastPerigonEnrichment = 'LAST_PERIGON_ENRICHMENT',
  LastPerigonEnrichmentTruncatedToDay = 'LAST_PERIGON_ENRICHMENT_TRUNCATED_TO_DAY',
  LastPerigonEnrichmentTruncatedToHour = 'LAST_PERIGON_ENRICHMENT_TRUNCATED_TO_HOUR',
  LastSecEnrichment = 'LAST_SEC_ENRICHMENT',
  LastSecEnrichmentTruncatedToDay = 'LAST_SEC_ENRICHMENT_TRUNCATED_TO_DAY',
  LastSecEnrichmentTruncatedToHour = 'LAST_SEC_ENRICHMENT_TRUNCATED_TO_HOUR',
  LegalName = 'LEGAL_NAME',
  LinkedinUid = 'LINKEDIN_UID',
  LinkedinUrl = 'LINKEDIN_URL',
  LogoUrl = 'LOGO_URL',
  Name = 'NAME',
  Summary = 'SUMMARY',
  TwitterUrl = 'TWITTER_URL',
  WebsiteUrl = 'WEBSITE_URL'
}

export type CompanyHavingAverageInput = {
  lastIndustryEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastLegalUrlsEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastPerigonEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastSecEnrichment?: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyHavingDistinctCountInput = {
  lastIndustryEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastLegalUrlsEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastPerigonEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastSecEnrichment?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Company` aggregates. */
export type CompanyHavingInput = {
  AND?: InputMaybe<Array<CompanyHavingInput>>;
  OR?: InputMaybe<Array<CompanyHavingInput>>;
  average?: InputMaybe<CompanyHavingAverageInput>;
  distinctCount?: InputMaybe<CompanyHavingDistinctCountInput>;
  max?: InputMaybe<CompanyHavingMaxInput>;
  min?: InputMaybe<CompanyHavingMinInput>;
  stddevPopulation?: InputMaybe<CompanyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CompanyHavingStddevSampleInput>;
  sum?: InputMaybe<CompanyHavingSumInput>;
  variancePopulation?: InputMaybe<CompanyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CompanyHavingVarianceSampleInput>;
};

export type CompanyHavingMaxInput = {
  lastIndustryEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastLegalUrlsEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastPerigonEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastSecEnrichment?: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyHavingMinInput = {
  lastIndustryEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastLegalUrlsEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastPerigonEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastSecEnrichment?: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyHavingStddevPopulationInput = {
  lastIndustryEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastLegalUrlsEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastPerigonEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastSecEnrichment?: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyHavingStddevSampleInput = {
  lastIndustryEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastLegalUrlsEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastPerigonEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastSecEnrichment?: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyHavingSumInput = {
  lastIndustryEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastLegalUrlsEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastPerigonEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastSecEnrichment?: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyHavingVariancePopulationInput = {
  lastIndustryEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastLegalUrlsEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastPerigonEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastSecEnrichment?: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyHavingVarianceSampleInput = {
  lastIndustryEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastLegalUrlsEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastPerigonEnrichment?: InputMaybe<HavingDatetimeFilter>;
  lastSecEnrichment?: InputMaybe<HavingDatetimeFilter>;
};

export type CompanyPersonalisation = Node & {
  __typename?: 'CompanyPersonalisation';
  /** Reads a single `Company` that is related to this `CompanyPersonalisation`. */
  companyByCompanyId?: Maybe<Company>;
  companyId: Scalars['UUID']['output'];
  dateValue?: Maybe<Scalars['Datetime']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  intValue?: Maybe<Scalars['BigInt']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `PersonalisationType` that is related to this `CompanyPersonalisation`. */
  personalisationTypeByTypeId?: Maybe<PersonalisationType>;
  strValue?: Maybe<Scalars['String']['output']>;
  typeId: Scalars['UUID']['output'];
};

export type CompanyPersonalisationAggregates = {
  __typename?: 'CompanyPersonalisationAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CompanyPersonalisationAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CompanyPersonalisationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CompanyPersonalisationMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CompanyPersonalisationMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CompanyPersonalisationStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CompanyPersonalisationStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CompanyPersonalisationSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CompanyPersonalisationVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CompanyPersonalisationVarianceSampleAggregates>;
};

export type CompanyPersonalisationAverageAggregates = {
  __typename?: 'CompanyPersonalisationAverageAggregates';
  /** Mean average of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `CompanyPersonalisation` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CompanyPersonalisationCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `dateValue` field. */
  dateValue?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `intValue` field. */
  intValue?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `strValue` field. */
  strValue?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `typeId` field. */
  typeId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CompanyPersonalisationDistinctCountAggregates = {
  __typename?: 'CompanyPersonalisationDistinctCountAggregates';
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dateValue across the matching connection */
  dateValue?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of strValue across the matching connection */
  strValue?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of typeId across the matching connection */
  typeId?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `CompanyPersonalisation` object types. All fields are combined with a logical ‘and.’ */
export type CompanyPersonalisationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CompanyPersonalisationFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `dateValue` field. */
  dateValue?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `intValue` field. */
  intValue?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CompanyPersonalisationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CompanyPersonalisationFilter>>;
  /** Filter by the object’s `strValue` field. */
  strValue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `typeId` field. */
  typeId?: InputMaybe<UuidFilter>;
};

/** Grouping methods for `CompanyPersonalisation` for usage during aggregation. */
export enum CompanyPersonalisationGroupBy {
  CompanyId = 'COMPANY_ID',
  DateValue = 'DATE_VALUE',
  DateValueTruncatedToDay = 'DATE_VALUE_TRUNCATED_TO_DAY',
  DateValueTruncatedToHour = 'DATE_VALUE_TRUNCATED_TO_HOUR',
  IntValue = 'INT_VALUE',
  StrValue = 'STR_VALUE',
  TypeId = 'TYPE_ID'
}

export type CompanyPersonalisationHavingAverageInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type CompanyPersonalisationHavingDistinctCountInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `CompanyPersonalisation` aggregates. */
export type CompanyPersonalisationHavingInput = {
  AND?: InputMaybe<Array<CompanyPersonalisationHavingInput>>;
  OR?: InputMaybe<Array<CompanyPersonalisationHavingInput>>;
  average?: InputMaybe<CompanyPersonalisationHavingAverageInput>;
  distinctCount?: InputMaybe<CompanyPersonalisationHavingDistinctCountInput>;
  max?: InputMaybe<CompanyPersonalisationHavingMaxInput>;
  min?: InputMaybe<CompanyPersonalisationHavingMinInput>;
  stddevPopulation?: InputMaybe<CompanyPersonalisationHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CompanyPersonalisationHavingStddevSampleInput>;
  sum?: InputMaybe<CompanyPersonalisationHavingSumInput>;
  variancePopulation?: InputMaybe<CompanyPersonalisationHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CompanyPersonalisationHavingVarianceSampleInput>;
};

export type CompanyPersonalisationHavingMaxInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type CompanyPersonalisationHavingMinInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type CompanyPersonalisationHavingStddevPopulationInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type CompanyPersonalisationHavingStddevSampleInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type CompanyPersonalisationHavingSumInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type CompanyPersonalisationHavingVariancePopulationInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type CompanyPersonalisationHavingVarianceSampleInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type CompanyPersonalisationMaxAggregates = {
  __typename?: 'CompanyPersonalisationMaxAggregates';
  /** Maximum of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigInt']['output']>;
};

export type CompanyPersonalisationMinAggregates = {
  __typename?: 'CompanyPersonalisationMinAggregates';
  /** Minimum of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigInt']['output']>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type CompanyPersonalisationSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: CompanyPersonalisationSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type CompanyPersonalisationSortBy = {
  field?: InputMaybe<CompanyPersonalisationSortableField>;
};

/** Sortable concrete fields for the `CompanyPersonalisation` type. */
export enum CompanyPersonalisationSortableField {
  CompanyId = 'COMPANY_ID',
  DateValue = 'DATE_VALUE',
  Id = 'ID',
  IntValue = 'INT_VALUE',
  StrValue = 'STR_VALUE',
  TypeId = 'TYPE_ID'
}

export type CompanyPersonalisationStddevPopulationAggregates = {
  __typename?: 'CompanyPersonalisationStddevPopulationAggregates';
  /** Population standard deviation of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigFloat']['output']>;
};

export type CompanyPersonalisationStddevSampleAggregates = {
  __typename?: 'CompanyPersonalisationStddevSampleAggregates';
  /** Sample standard deviation of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigFloat']['output']>;
};

export type CompanyPersonalisationSumAggregates = {
  __typename?: 'CompanyPersonalisationSumAggregates';
  /** Sum of intValue across the matching connection */
  intValue: Scalars['BigFloat']['output'];
};

export type CompanyPersonalisationVariancePopulationAggregates = {
  __typename?: 'CompanyPersonalisationVariancePopulationAggregates';
  /** Population variance of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigFloat']['output']>;
};

export type CompanyPersonalisationVarianceSampleAggregates = {
  __typename?: 'CompanyPersonalisationVarianceSampleAggregates';
  /** Sample variance of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `CompanyPersonalisation` values. */
export type CompanyPersonalisationsConnection = {
  __typename?: 'CompanyPersonalisationsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CompanyPersonalisationAggregates>;
  /** A list of edges which contains the `CompanyPersonalisation` and cursor to aid in pagination. */
  edges: Array<CompanyPersonalisationsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CompanyPersonalisationAggregates>>;
  /** A list of `CompanyPersonalisation` objects. */
  nodes: Array<Maybe<CompanyPersonalisation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CompanyPersonalisation` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `CompanyPersonalisation` values. */
export type CompanyPersonalisationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CompanyPersonalisationGroupBy>;
  having?: InputMaybe<CompanyPersonalisationHavingInput>;
};

/** A `CompanyPersonalisation` edge in the connection. */
export type CompanyPersonalisationsEdge = {
  __typename?: 'CompanyPersonalisationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `CompanyPersonalisation` at the end of the edge. */
  node?: Maybe<CompanyPersonalisation>;
};

/** Methods to use when ordering `CompanyPersonalisation`. */
export enum CompanyPersonalisationsOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  DateValueAsc = 'DATE_VALUE_ASC',
  DateValueDesc = 'DATE_VALUE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntValueAsc = 'INT_VALUE_ASC',
  IntValueDesc = 'INT_VALUE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StrValueAsc = 'STR_VALUE_ASC',
  StrValueDesc = 'STR_VALUE_DESC',
  TypeIdAsc = 'TYPE_ID_ASC',
  TypeIdDesc = 'TYPE_ID_DESC'
}

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type CompanySort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: CompanySortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type CompanySortBy = {
  field?: InputMaybe<CompanySortableField>;
};

/** Sortable concrete fields for the `Company` type. */
export enum CompanySortableField {
  ApolloId = 'APOLLO_ID',
  FacebookUrl = 'FACEBOOK_URL',
  HouseUrl = 'HOUSE_URL',
  Id = 'ID',
  Keywords = 'KEYWORDS',
  LastIndustryEnrichment = 'LAST_INDUSTRY_ENRICHMENT',
  LastLegalUrlsEnrichment = 'LAST_LEGAL_URLS_ENRICHMENT',
  LastPerigonEnrichment = 'LAST_PERIGON_ENRICHMENT',
  LastSecEnrichment = 'LAST_SEC_ENRICHMENT',
  LegalName = 'LEGAL_NAME',
  LinkedinUid = 'LINKEDIN_UID',
  LinkedinUrl = 'LINKEDIN_URL',
  LogoUrl = 'LOGO_URL',
  Name = 'NAME',
  Summary = 'SUMMARY',
  TwitterUrl = 'TWITTER_URL',
  WebsiteUrl = 'WEBSITE_URL'
}

export type Contact = Node & {
  __typename?: 'Contact';
  apolloEnrichmentPayload?: Maybe<Scalars['JSON']['output']>;
  apolloId?: Maybe<Scalars['String']['output']>;
  apolloQueryId?: Maybe<Scalars['UUID']['output']>;
  /** Reads and enables pagination through a set of `CampaignMember`. */
  campaignMembersByContactId: CampaignMembersConnection;
  /** Reads and enables pagination through a set of `Client`. */
  clientsByContactId: ClientsConnection;
  /** Reads a single `Company` that is related to this `Contact`. */
  companyByCompanyId?: Maybe<Company>;
  companyId?: Maybe<Scalars['UUID']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['Datetime']['output'];
  crunchbaseUrl?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailDomainRev?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByContactId: EmailMessagesConnection;
  facebookUrl?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  lastEnriched?: Maybe<Scalars['Datetime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Lawyer`. */
  lawyersByContactId: LawyersConnection;
  linkedinCompanyName?: Maybe<Scalars['String']['output']>;
  linkedinName?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `LinkedinScheduledMessage`. */
  linkedinScheduledMessagesByContactId: LinkedinScheduledMessagesConnection;
  linkedinUid?: Maybe<Scalars['String']['output']>;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `MeetingInvitee`. */
  meetingInviteesByContactId: MeetingInviteesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `Personalisation`. */
  personalisationsByContactId: PersonalisationsConnection;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `ReferralPartner`. */
  referralPartnersByContactId: ReferralPartnersConnection;
  /** Reads and enables pagination through a set of `SocialProof`. */
  socialProofsByContactId: SocialProofsConnection;
  summary?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Testimonial`. */
  testimonialsByContactId: TestimonialsConnection;
  timezone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  twitterUrl?: Maybe<Scalars['String']['output']>;
  updatedTime: Scalars['Datetime']['output'];
};


export type ContactCampaignMembersByContactIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignMemberCondition>;
  filter?: InputMaybe<CampaignMemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignMembersOrderBy>>;
  sort?: InputMaybe<Array<CampaignMemberSort>>;
};


export type ContactClientsByContactIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ClientCondition>;
  filter?: InputMaybe<ClientFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientsOrderBy>>;
  sort?: InputMaybe<Array<ClientSort>>;
};


export type ContactEmailMessagesByContactIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailMessageCondition>;
  filter?: InputMaybe<EmailMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailMessagesOrderBy>>;
  sort?: InputMaybe<Array<EmailMessageSort>>;
};


export type ContactLawyersByContactIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LawyerCondition>;
  filter?: InputMaybe<LawyerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LawyersOrderBy>>;
  sort?: InputMaybe<Array<LawyerSort>>;
};


export type ContactLinkedinScheduledMessagesByContactIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinScheduledMessageCondition>;
  filter?: InputMaybe<LinkedinScheduledMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinScheduledMessagesOrderBy>>;
  sort?: InputMaybe<Array<LinkedinScheduledMessageSort>>;
};


export type ContactMeetingInviteesByContactIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MeetingInviteeCondition>;
  filter?: InputMaybe<MeetingInviteeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MeetingInviteesOrderBy>>;
  sort?: InputMaybe<Array<MeetingInviteeSort>>;
};


export type ContactPersonalisationsByContactIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PersonalisationCondition>;
  filter?: InputMaybe<PersonalisationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonalisationsOrderBy>>;
  sort?: InputMaybe<Array<PersonalisationSort>>;
};


export type ContactReferralPartnersByContactIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ReferralPartnerCondition>;
  filter?: InputMaybe<ReferralPartnerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReferralPartnersOrderBy>>;
  sort?: InputMaybe<Array<ReferralPartnerSort>>;
};


export type ContactSocialProofsByContactIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SocialProofCondition>;
  filter?: InputMaybe<SocialProofFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SocialProofsOrderBy>>;
  sort?: InputMaybe<Array<SocialProofSort>>;
};


export type ContactTestimonialsByContactIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TestimonialCondition>;
  filter?: InputMaybe<TestimonialFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestimonialsOrderBy>>;
  sort?: InputMaybe<Array<TestimonialSort>>;
};

export type ContactAggregates = {
  __typename?: 'ContactAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ContactDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/** A condition to be used against `Contact` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ContactCondition = {
  /** Checks for equality with the object’s `apolloEnrichmentPayload` field. */
  apolloEnrichmentPayload?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `apolloId` field. */
  apolloId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `apolloQueryId` field. */
  apolloQueryId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `companyName` field. */
  companyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `crunchbaseUrl` field. */
  crunchbaseUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `emailDomainRev` field. */
  emailDomainRev?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lastEnriched` field. */
  lastEnriched?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `linkedinCompanyName` field. */
  linkedinCompanyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `linkedinName` field. */
  linkedinName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `linkedinUid` field. */
  linkedinUid?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `photoUrl` field. */
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `summary` field. */
  summary?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timezone` field. */
  timezone?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type ContactDistinctCountAggregates = {
  __typename?: 'ContactDistinctCountAggregates';
  /** Distinct count of apolloEnrichmentPayload across the matching connection */
  apolloEnrichmentPayload?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of apolloId across the matching connection */
  apolloId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of apolloQueryId across the matching connection */
  apolloQueryId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyName across the matching connection */
  companyName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of crunchbaseUrl across the matching connection */
  crunchbaseUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emailDomainRev across the matching connection */
  emailDomainRev?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of facebookUrl across the matching connection */
  facebookUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of firstName across the matching connection */
  firstName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastEnriched across the matching connection */
  lastEnriched?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastName across the matching connection */
  lastName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of linkedinCompanyName across the matching connection */
  linkedinCompanyName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of linkedinName across the matching connection */
  linkedinName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of linkedinUid across the matching connection */
  linkedinUid?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of linkedinUrl across the matching connection */
  linkedinUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of phoneNumber across the matching connection */
  phoneNumber?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of photoUrl across the matching connection */
  photoUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of summary across the matching connection */
  summary?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timezone across the matching connection */
  timezone?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of twitterUrl across the matching connection */
  twitterUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Contact` object types. All fields are combined with a logical ‘and.’ */
export type ContactFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ContactFilter>>;
  /** Filter by the object’s `apolloId` field. */
  apolloId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `apolloQueryId` field. */
  apolloQueryId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `companyName` field. */
  companyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `crunchbaseUrl` field. */
  crunchbaseUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emailDomainRev` field. */
  emailDomainRev?: InputMaybe<StringFilter>;
  /** Filter by the object’s `facebookUrl` field. */
  facebookUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lastEnriched` field. */
  lastEnriched?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `linkedinCompanyName` field. */
  linkedinCompanyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `linkedinName` field. */
  linkedinName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `linkedinUid` field. */
  linkedinUid?: InputMaybe<StringFilter>;
  /** Filter by the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ContactFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ContactFilter>>;
  /** Filter by the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `photoUrl` field. */
  photoUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `summary` field. */
  summary?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timezone` field. */
  timezone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `twitterUrl` field. */
  twitterUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Contact` for usage during aggregation. */
export enum ContactGroupBy {
  ApolloEnrichmentPayload = 'APOLLO_ENRICHMENT_PAYLOAD',
  ApolloQueryId = 'APOLLO_QUERY_ID',
  CompanyId = 'COMPANY_ID',
  CompanyName = 'COMPANY_NAME',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  CrunchbaseUrl = 'CRUNCHBASE_URL',
  EmailDomainRev = 'EMAIL_DOMAIN_REV',
  FacebookUrl = 'FACEBOOK_URL',
  FirstName = 'FIRST_NAME',
  LastEnriched = 'LAST_ENRICHED',
  LastEnrichedTruncatedToDay = 'LAST_ENRICHED_TRUNCATED_TO_DAY',
  LastEnrichedTruncatedToHour = 'LAST_ENRICHED_TRUNCATED_TO_HOUR',
  LastName = 'LAST_NAME',
  LinkedinCompanyName = 'LINKEDIN_COMPANY_NAME',
  LinkedinName = 'LINKEDIN_NAME',
  LinkedinUid = 'LINKEDIN_UID',
  PhoneNumber = 'PHONE_NUMBER',
  PhotoUrl = 'PHOTO_URL',
  Summary = 'SUMMARY',
  Timezone = 'TIMEZONE',
  Title = 'TITLE',
  TwitterUrl = 'TWITTER_URL',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type ContactHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lastEnriched?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lastEnriched?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Contact` aggregates. */
export type ContactHavingInput = {
  AND?: InputMaybe<Array<ContactHavingInput>>;
  OR?: InputMaybe<Array<ContactHavingInput>>;
  average?: InputMaybe<ContactHavingAverageInput>;
  distinctCount?: InputMaybe<ContactHavingDistinctCountInput>;
  max?: InputMaybe<ContactHavingMaxInput>;
  min?: InputMaybe<ContactHavingMinInput>;
  stddevPopulation?: InputMaybe<ContactHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ContactHavingStddevSampleInput>;
  sum?: InputMaybe<ContactHavingSumInput>;
  variancePopulation?: InputMaybe<ContactHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ContactHavingVarianceSampleInput>;
};

export type ContactHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lastEnriched?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lastEnriched?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lastEnriched?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lastEnriched?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lastEnriched?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lastEnriched?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ContactHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lastEnriched?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type ContactSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: ContactSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type ContactSortBy = {
  field?: InputMaybe<ContactSortableField>;
};

/** Sortable concrete fields for the `Contact` type. */
export enum ContactSortableField {
  ApolloEnrichmentPayload = 'APOLLO_ENRICHMENT_PAYLOAD',
  ApolloId = 'APOLLO_ID',
  ApolloQueryId = 'APOLLO_QUERY_ID',
  CompanyId = 'COMPANY_ID',
  CompanyName = 'COMPANY_NAME',
  CreatedTime = 'CREATED_TIME',
  CrunchbaseUrl = 'CRUNCHBASE_URL',
  Email = 'EMAIL',
  EmailDomainRev = 'EMAIL_DOMAIN_REV',
  FacebookUrl = 'FACEBOOK_URL',
  FirstName = 'FIRST_NAME',
  Id = 'ID',
  LastEnriched = 'LAST_ENRICHED',
  LastName = 'LAST_NAME',
  LinkedinCompanyName = 'LINKEDIN_COMPANY_NAME',
  LinkedinName = 'LINKEDIN_NAME',
  LinkedinUid = 'LINKEDIN_UID',
  LinkedinUrl = 'LINKEDIN_URL',
  PhoneNumber = 'PHONE_NUMBER',
  PhotoUrl = 'PHOTO_URL',
  Summary = 'SUMMARY',
  Timezone = 'TIMEZONE',
  Title = 'TITLE',
  TwitterUrl = 'TWITTER_URL',
  UpdatedTime = 'UPDATED_TIME'
}

/** A connection to a list of `Contact` values. */
export type ContactsConnection = {
  __typename?: 'ContactsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ContactAggregates>;
  /** A list of edges which contains the `Contact` and cursor to aid in pagination. */
  edges: Array<ContactsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ContactAggregates>>;
  /** A list of `Contact` objects. */
  nodes: Array<Maybe<Contact>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Contact` values. */
export type ContactsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ContactGroupBy>;
  having?: InputMaybe<ContactHavingInput>;
};

/** A `Contact` edge in the connection. */
export type ContactsEdge = {
  __typename?: 'ContactsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Contact` at the end of the edge. */
  node?: Maybe<Contact>;
};

/** Methods to use when ordering `Contact`. */
export enum ContactsOrderBy {
  ApolloEnrichmentPayloadAsc = 'APOLLO_ENRICHMENT_PAYLOAD_ASC',
  ApolloEnrichmentPayloadDesc = 'APOLLO_ENRICHMENT_PAYLOAD_DESC',
  ApolloIdAsc = 'APOLLO_ID_ASC',
  ApolloIdDesc = 'APOLLO_ID_DESC',
  ApolloQueryIdAsc = 'APOLLO_QUERY_ID_ASC',
  ApolloQueryIdDesc = 'APOLLO_QUERY_ID_DESC',
  CampaignMembersByContactIdAverageCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_CAMPAIGN_ID_ASC',
  CampaignMembersByContactIdAverageCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_CAMPAIGN_ID_DESC',
  CampaignMembersByContactIdAverageContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  CampaignMembersByContactIdAverageContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  CampaignMembersByContactIdAverageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  CampaignMembersByContactIdAverageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  CampaignMembersByContactIdAverageIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_ID_ASC',
  CampaignMembersByContactIdAverageIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_ID_DESC',
  CampaignMembersByContactIdAverageIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_INCLUDE_LAWYER_ASC',
  CampaignMembersByContactIdAverageIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_INCLUDE_LAWYER_DESC',
  CampaignMembersByContactIdAverageIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByContactIdAverageIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByContactIdAverageLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByContactIdAverageLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByContactIdAverageLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByContactIdAverageLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByContactIdAverageNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByContactIdAverageNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByContactIdAverageNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_NEXT_ACTION_TIME_ASC',
  CampaignMembersByContactIdAverageNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_NEXT_ACTION_TIME_DESC',
  CampaignMembersByContactIdAverageStatusAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_STATUS_ASC',
  CampaignMembersByContactIdAverageStatusDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_STATUS_DESC',
  CampaignMembersByContactIdAverageUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_ASC',
  CampaignMembersByContactIdAverageUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_DESC',
  CampaignMembersByContactIdAverageVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_VISIBILITY_ASC',
  CampaignMembersByContactIdAverageVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_AVERAGE_VISIBILITY_DESC',
  CampaignMembersByContactIdCountAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_COUNT_ASC',
  CampaignMembersByContactIdCountDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_COUNT_DESC',
  CampaignMembersByContactIdDistinctCountCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  CampaignMembersByContactIdDistinctCountCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  CampaignMembersByContactIdDistinctCountContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  CampaignMembersByContactIdDistinctCountContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  CampaignMembersByContactIdDistinctCountCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CampaignMembersByContactIdDistinctCountCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CampaignMembersByContactIdDistinctCountIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  CampaignMembersByContactIdDistinctCountIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  CampaignMembersByContactIdDistinctCountIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_INCLUDE_LAWYER_ASC',
  CampaignMembersByContactIdDistinctCountIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_INCLUDE_LAWYER_DESC',
  CampaignMembersByContactIdDistinctCountIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByContactIdDistinctCountIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByContactIdDistinctCountLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByContactIdDistinctCountLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByContactIdDistinctCountLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByContactIdDistinctCountLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByContactIdDistinctCountNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByContactIdDistinctCountNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByContactIdDistinctCountNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_NEXT_ACTION_TIME_ASC',
  CampaignMembersByContactIdDistinctCountNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_NEXT_ACTION_TIME_DESC',
  CampaignMembersByContactIdDistinctCountStatusAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_STATUS_ASC',
  CampaignMembersByContactIdDistinctCountStatusDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_STATUS_DESC',
  CampaignMembersByContactIdDistinctCountUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  CampaignMembersByContactIdDistinctCountUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  CampaignMembersByContactIdDistinctCountVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_VISIBILITY_ASC',
  CampaignMembersByContactIdDistinctCountVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_VISIBILITY_DESC',
  CampaignMembersByContactIdMaxCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_CAMPAIGN_ID_ASC',
  CampaignMembersByContactIdMaxCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_CAMPAIGN_ID_DESC',
  CampaignMembersByContactIdMaxContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  CampaignMembersByContactIdMaxContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  CampaignMembersByContactIdMaxCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  CampaignMembersByContactIdMaxCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  CampaignMembersByContactIdMaxIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_ID_ASC',
  CampaignMembersByContactIdMaxIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_ID_DESC',
  CampaignMembersByContactIdMaxIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_INCLUDE_LAWYER_ASC',
  CampaignMembersByContactIdMaxIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_INCLUDE_LAWYER_DESC',
  CampaignMembersByContactIdMaxIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByContactIdMaxIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByContactIdMaxLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByContactIdMaxLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByContactIdMaxLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByContactIdMaxLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByContactIdMaxNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByContactIdMaxNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByContactIdMaxNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_NEXT_ACTION_TIME_ASC',
  CampaignMembersByContactIdMaxNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_NEXT_ACTION_TIME_DESC',
  CampaignMembersByContactIdMaxStatusAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_STATUS_ASC',
  CampaignMembersByContactIdMaxStatusDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_STATUS_DESC',
  CampaignMembersByContactIdMaxUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_UPDATED_TIME_ASC',
  CampaignMembersByContactIdMaxUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_UPDATED_TIME_DESC',
  CampaignMembersByContactIdMaxVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_VISIBILITY_ASC',
  CampaignMembersByContactIdMaxVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MAX_VISIBILITY_DESC',
  CampaignMembersByContactIdMinCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_CAMPAIGN_ID_ASC',
  CampaignMembersByContactIdMinCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_CAMPAIGN_ID_DESC',
  CampaignMembersByContactIdMinContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  CampaignMembersByContactIdMinContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  CampaignMembersByContactIdMinCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  CampaignMembersByContactIdMinCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  CampaignMembersByContactIdMinIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_ID_ASC',
  CampaignMembersByContactIdMinIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_ID_DESC',
  CampaignMembersByContactIdMinIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_INCLUDE_LAWYER_ASC',
  CampaignMembersByContactIdMinIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_INCLUDE_LAWYER_DESC',
  CampaignMembersByContactIdMinIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByContactIdMinIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByContactIdMinLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByContactIdMinLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByContactIdMinLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByContactIdMinLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByContactIdMinNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByContactIdMinNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByContactIdMinNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_NEXT_ACTION_TIME_ASC',
  CampaignMembersByContactIdMinNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_NEXT_ACTION_TIME_DESC',
  CampaignMembersByContactIdMinStatusAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_STATUS_ASC',
  CampaignMembersByContactIdMinStatusDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_STATUS_DESC',
  CampaignMembersByContactIdMinUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_UPDATED_TIME_ASC',
  CampaignMembersByContactIdMinUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_UPDATED_TIME_DESC',
  CampaignMembersByContactIdMinVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_VISIBILITY_ASC',
  CampaignMembersByContactIdMinVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_MIN_VISIBILITY_DESC',
  CampaignMembersByContactIdStddevPopulationCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  CampaignMembersByContactIdStddevPopulationCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  CampaignMembersByContactIdStddevPopulationContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  CampaignMembersByContactIdStddevPopulationContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  CampaignMembersByContactIdStddevPopulationCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CampaignMembersByContactIdStddevPopulationCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CampaignMembersByContactIdStddevPopulationIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  CampaignMembersByContactIdStddevPopulationIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  CampaignMembersByContactIdStddevPopulationIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_INCLUDE_LAWYER_ASC',
  CampaignMembersByContactIdStddevPopulationIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_INCLUDE_LAWYER_DESC',
  CampaignMembersByContactIdStddevPopulationIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByContactIdStddevPopulationIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByContactIdStddevPopulationLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByContactIdStddevPopulationLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByContactIdStddevPopulationLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByContactIdStddevPopulationLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByContactIdStddevPopulationNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByContactIdStddevPopulationNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByContactIdStddevPopulationNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_NEXT_ACTION_TIME_ASC',
  CampaignMembersByContactIdStddevPopulationNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_NEXT_ACTION_TIME_DESC',
  CampaignMembersByContactIdStddevPopulationStatusAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_STATUS_ASC',
  CampaignMembersByContactIdStddevPopulationStatusDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_STATUS_DESC',
  CampaignMembersByContactIdStddevPopulationUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  CampaignMembersByContactIdStddevPopulationUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  CampaignMembersByContactIdStddevPopulationVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_VISIBILITY_ASC',
  CampaignMembersByContactIdStddevPopulationVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_VISIBILITY_DESC',
  CampaignMembersByContactIdStddevSampleCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  CampaignMembersByContactIdStddevSampleCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  CampaignMembersByContactIdStddevSampleContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  CampaignMembersByContactIdStddevSampleContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  CampaignMembersByContactIdStddevSampleCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CampaignMembersByContactIdStddevSampleCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CampaignMembersByContactIdStddevSampleIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  CampaignMembersByContactIdStddevSampleIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  CampaignMembersByContactIdStddevSampleIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_INCLUDE_LAWYER_ASC',
  CampaignMembersByContactIdStddevSampleIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_INCLUDE_LAWYER_DESC',
  CampaignMembersByContactIdStddevSampleIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByContactIdStddevSampleIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByContactIdStddevSampleLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByContactIdStddevSampleLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByContactIdStddevSampleLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByContactIdStddevSampleLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByContactIdStddevSampleNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByContactIdStddevSampleNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByContactIdStddevSampleNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_NEXT_ACTION_TIME_ASC',
  CampaignMembersByContactIdStddevSampleNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_NEXT_ACTION_TIME_DESC',
  CampaignMembersByContactIdStddevSampleStatusAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_STATUS_ASC',
  CampaignMembersByContactIdStddevSampleStatusDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_STATUS_DESC',
  CampaignMembersByContactIdStddevSampleUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  CampaignMembersByContactIdStddevSampleUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  CampaignMembersByContactIdStddevSampleVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_VISIBILITY_ASC',
  CampaignMembersByContactIdStddevSampleVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_VISIBILITY_DESC',
  CampaignMembersByContactIdSumCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_CAMPAIGN_ID_ASC',
  CampaignMembersByContactIdSumCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_CAMPAIGN_ID_DESC',
  CampaignMembersByContactIdSumContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  CampaignMembersByContactIdSumContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  CampaignMembersByContactIdSumCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  CampaignMembersByContactIdSumCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  CampaignMembersByContactIdSumIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_ID_ASC',
  CampaignMembersByContactIdSumIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_ID_DESC',
  CampaignMembersByContactIdSumIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_INCLUDE_LAWYER_ASC',
  CampaignMembersByContactIdSumIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_INCLUDE_LAWYER_DESC',
  CampaignMembersByContactIdSumIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByContactIdSumIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByContactIdSumLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByContactIdSumLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByContactIdSumLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByContactIdSumLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByContactIdSumNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByContactIdSumNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByContactIdSumNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_NEXT_ACTION_TIME_ASC',
  CampaignMembersByContactIdSumNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_NEXT_ACTION_TIME_DESC',
  CampaignMembersByContactIdSumStatusAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_STATUS_ASC',
  CampaignMembersByContactIdSumStatusDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_STATUS_DESC',
  CampaignMembersByContactIdSumUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_UPDATED_TIME_ASC',
  CampaignMembersByContactIdSumUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_UPDATED_TIME_DESC',
  CampaignMembersByContactIdSumVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_VISIBILITY_ASC',
  CampaignMembersByContactIdSumVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_SUM_VISIBILITY_DESC',
  CampaignMembersByContactIdVariancePopulationCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  CampaignMembersByContactIdVariancePopulationCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  CampaignMembersByContactIdVariancePopulationContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  CampaignMembersByContactIdVariancePopulationContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  CampaignMembersByContactIdVariancePopulationCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CampaignMembersByContactIdVariancePopulationCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CampaignMembersByContactIdVariancePopulationIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  CampaignMembersByContactIdVariancePopulationIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  CampaignMembersByContactIdVariancePopulationIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_INCLUDE_LAWYER_ASC',
  CampaignMembersByContactIdVariancePopulationIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_INCLUDE_LAWYER_DESC',
  CampaignMembersByContactIdVariancePopulationIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByContactIdVariancePopulationIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByContactIdVariancePopulationLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByContactIdVariancePopulationLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByContactIdVariancePopulationLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByContactIdVariancePopulationLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByContactIdVariancePopulationNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByContactIdVariancePopulationNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByContactIdVariancePopulationNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_NEXT_ACTION_TIME_ASC',
  CampaignMembersByContactIdVariancePopulationNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_NEXT_ACTION_TIME_DESC',
  CampaignMembersByContactIdVariancePopulationStatusAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_STATUS_ASC',
  CampaignMembersByContactIdVariancePopulationStatusDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_STATUS_DESC',
  CampaignMembersByContactIdVariancePopulationUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  CampaignMembersByContactIdVariancePopulationUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  CampaignMembersByContactIdVariancePopulationVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_VISIBILITY_ASC',
  CampaignMembersByContactIdVariancePopulationVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_VISIBILITY_DESC',
  CampaignMembersByContactIdVarianceSampleCampaignIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  CampaignMembersByContactIdVarianceSampleCampaignIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  CampaignMembersByContactIdVarianceSampleContactIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  CampaignMembersByContactIdVarianceSampleContactIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  CampaignMembersByContactIdVarianceSampleCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CampaignMembersByContactIdVarianceSampleCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CampaignMembersByContactIdVarianceSampleIdAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  CampaignMembersByContactIdVarianceSampleIdDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  CampaignMembersByContactIdVarianceSampleIncludeLawyerAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_INCLUDE_LAWYER_ASC',
  CampaignMembersByContactIdVarianceSampleIncludeLawyerDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_INCLUDE_LAWYER_DESC',
  CampaignMembersByContactIdVarianceSampleIsConversationTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_CONVERSATION_TIME_ASC',
  CampaignMembersByContactIdVarianceSampleIsConversationTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_CONVERSATION_TIME_DESC',
  CampaignMembersByContactIdVarianceSampleLatestEmailMessageCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LATEST_EMAIL_MESSAGE_CREATED_TIME_ASC',
  CampaignMembersByContactIdVarianceSampleLatestEmailMessageCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LATEST_EMAIL_MESSAGE_CREATED_TIME_DESC',
  CampaignMembersByContactIdVarianceSampleLawyerViewedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  CampaignMembersByContactIdVarianceSampleLawyerViewedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  CampaignMembersByContactIdVarianceSampleNatSequenceCreatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_NAT_SEQUENCE_CREATED_TIME_ASC',
  CampaignMembersByContactIdVarianceSampleNatSequenceCreatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_NAT_SEQUENCE_CREATED_TIME_DESC',
  CampaignMembersByContactIdVarianceSampleNextActionTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_NEXT_ACTION_TIME_ASC',
  CampaignMembersByContactIdVarianceSampleNextActionTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_NEXT_ACTION_TIME_DESC',
  CampaignMembersByContactIdVarianceSampleStatusAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STATUS_ASC',
  CampaignMembersByContactIdVarianceSampleStatusDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STATUS_DESC',
  CampaignMembersByContactIdVarianceSampleUpdatedTimeAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  CampaignMembersByContactIdVarianceSampleUpdatedTimeDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  CampaignMembersByContactIdVarianceSampleVisibilityAsc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_VISIBILITY_ASC',
  CampaignMembersByContactIdVarianceSampleVisibilityDesc = 'CAMPAIGN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_VISIBILITY_DESC',
  ClientsByContactIdAverageCompanyNotesAsc = 'CLIENTS_BY_CONTACT_ID_AVERAGE_COMPANY_NOTES_ASC',
  ClientsByContactIdAverageCompanyNotesDesc = 'CLIENTS_BY_CONTACT_ID_AVERAGE_COMPANY_NOTES_DESC',
  ClientsByContactIdAverageContactIdAsc = 'CLIENTS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  ClientsByContactIdAverageContactIdDesc = 'CLIENTS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  ClientsByContactIdAverageContactNotesAsc = 'CLIENTS_BY_CONTACT_ID_AVERAGE_CONTACT_NOTES_ASC',
  ClientsByContactIdAverageContactNotesDesc = 'CLIENTS_BY_CONTACT_ID_AVERAGE_CONTACT_NOTES_DESC',
  ClientsByContactIdAverageCreatedTimeAsc = 'CLIENTS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  ClientsByContactIdAverageCreatedTimeDesc = 'CLIENTS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  ClientsByContactIdAverageIdAsc = 'CLIENTS_BY_CONTACT_ID_AVERAGE_ID_ASC',
  ClientsByContactIdAverageIdDesc = 'CLIENTS_BY_CONTACT_ID_AVERAGE_ID_DESC',
  ClientsByContactIdAverageLawyerIdAsc = 'CLIENTS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_ASC',
  ClientsByContactIdAverageLawyerIdDesc = 'CLIENTS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_DESC',
  ClientsByContactIdCountAsc = 'CLIENTS_BY_CONTACT_ID_COUNT_ASC',
  ClientsByContactIdCountDesc = 'CLIENTS_BY_CONTACT_ID_COUNT_DESC',
  ClientsByContactIdDistinctCountCompanyNotesAsc = 'CLIENTS_BY_CONTACT_ID_DISTINCT_COUNT_COMPANY_NOTES_ASC',
  ClientsByContactIdDistinctCountCompanyNotesDesc = 'CLIENTS_BY_CONTACT_ID_DISTINCT_COUNT_COMPANY_NOTES_DESC',
  ClientsByContactIdDistinctCountContactIdAsc = 'CLIENTS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ClientsByContactIdDistinctCountContactIdDesc = 'CLIENTS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ClientsByContactIdDistinctCountContactNotesAsc = 'CLIENTS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_NOTES_ASC',
  ClientsByContactIdDistinctCountContactNotesDesc = 'CLIENTS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_NOTES_DESC',
  ClientsByContactIdDistinctCountCreatedTimeAsc = 'CLIENTS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  ClientsByContactIdDistinctCountCreatedTimeDesc = 'CLIENTS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  ClientsByContactIdDistinctCountIdAsc = 'CLIENTS_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  ClientsByContactIdDistinctCountIdDesc = 'CLIENTS_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  ClientsByContactIdDistinctCountLawyerIdAsc = 'CLIENTS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  ClientsByContactIdDistinctCountLawyerIdDesc = 'CLIENTS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  ClientsByContactIdMaxCompanyNotesAsc = 'CLIENTS_BY_CONTACT_ID_MAX_COMPANY_NOTES_ASC',
  ClientsByContactIdMaxCompanyNotesDesc = 'CLIENTS_BY_CONTACT_ID_MAX_COMPANY_NOTES_DESC',
  ClientsByContactIdMaxContactIdAsc = 'CLIENTS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  ClientsByContactIdMaxContactIdDesc = 'CLIENTS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  ClientsByContactIdMaxContactNotesAsc = 'CLIENTS_BY_CONTACT_ID_MAX_CONTACT_NOTES_ASC',
  ClientsByContactIdMaxContactNotesDesc = 'CLIENTS_BY_CONTACT_ID_MAX_CONTACT_NOTES_DESC',
  ClientsByContactIdMaxCreatedTimeAsc = 'CLIENTS_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  ClientsByContactIdMaxCreatedTimeDesc = 'CLIENTS_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  ClientsByContactIdMaxIdAsc = 'CLIENTS_BY_CONTACT_ID_MAX_ID_ASC',
  ClientsByContactIdMaxIdDesc = 'CLIENTS_BY_CONTACT_ID_MAX_ID_DESC',
  ClientsByContactIdMaxLawyerIdAsc = 'CLIENTS_BY_CONTACT_ID_MAX_LAWYER_ID_ASC',
  ClientsByContactIdMaxLawyerIdDesc = 'CLIENTS_BY_CONTACT_ID_MAX_LAWYER_ID_DESC',
  ClientsByContactIdMinCompanyNotesAsc = 'CLIENTS_BY_CONTACT_ID_MIN_COMPANY_NOTES_ASC',
  ClientsByContactIdMinCompanyNotesDesc = 'CLIENTS_BY_CONTACT_ID_MIN_COMPANY_NOTES_DESC',
  ClientsByContactIdMinContactIdAsc = 'CLIENTS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  ClientsByContactIdMinContactIdDesc = 'CLIENTS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  ClientsByContactIdMinContactNotesAsc = 'CLIENTS_BY_CONTACT_ID_MIN_CONTACT_NOTES_ASC',
  ClientsByContactIdMinContactNotesDesc = 'CLIENTS_BY_CONTACT_ID_MIN_CONTACT_NOTES_DESC',
  ClientsByContactIdMinCreatedTimeAsc = 'CLIENTS_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  ClientsByContactIdMinCreatedTimeDesc = 'CLIENTS_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  ClientsByContactIdMinIdAsc = 'CLIENTS_BY_CONTACT_ID_MIN_ID_ASC',
  ClientsByContactIdMinIdDesc = 'CLIENTS_BY_CONTACT_ID_MIN_ID_DESC',
  ClientsByContactIdMinLawyerIdAsc = 'CLIENTS_BY_CONTACT_ID_MIN_LAWYER_ID_ASC',
  ClientsByContactIdMinLawyerIdDesc = 'CLIENTS_BY_CONTACT_ID_MIN_LAWYER_ID_DESC',
  ClientsByContactIdStddevPopulationCompanyNotesAsc = 'CLIENTS_BY_CONTACT_ID_STDDEV_POPULATION_COMPANY_NOTES_ASC',
  ClientsByContactIdStddevPopulationCompanyNotesDesc = 'CLIENTS_BY_CONTACT_ID_STDDEV_POPULATION_COMPANY_NOTES_DESC',
  ClientsByContactIdStddevPopulationContactIdAsc = 'CLIENTS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ClientsByContactIdStddevPopulationContactIdDesc = 'CLIENTS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ClientsByContactIdStddevPopulationContactNotesAsc = 'CLIENTS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_NOTES_ASC',
  ClientsByContactIdStddevPopulationContactNotesDesc = 'CLIENTS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_NOTES_DESC',
  ClientsByContactIdStddevPopulationCreatedTimeAsc = 'CLIENTS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  ClientsByContactIdStddevPopulationCreatedTimeDesc = 'CLIENTS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  ClientsByContactIdStddevPopulationIdAsc = 'CLIENTS_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  ClientsByContactIdStddevPopulationIdDesc = 'CLIENTS_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  ClientsByContactIdStddevPopulationLawyerIdAsc = 'CLIENTS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  ClientsByContactIdStddevPopulationLawyerIdDesc = 'CLIENTS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  ClientsByContactIdStddevSampleCompanyNotesAsc = 'CLIENTS_BY_CONTACT_ID_STDDEV_SAMPLE_COMPANY_NOTES_ASC',
  ClientsByContactIdStddevSampleCompanyNotesDesc = 'CLIENTS_BY_CONTACT_ID_STDDEV_SAMPLE_COMPANY_NOTES_DESC',
  ClientsByContactIdStddevSampleContactIdAsc = 'CLIENTS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ClientsByContactIdStddevSampleContactIdDesc = 'CLIENTS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ClientsByContactIdStddevSampleContactNotesAsc = 'CLIENTS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_NOTES_ASC',
  ClientsByContactIdStddevSampleContactNotesDesc = 'CLIENTS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_NOTES_DESC',
  ClientsByContactIdStddevSampleCreatedTimeAsc = 'CLIENTS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  ClientsByContactIdStddevSampleCreatedTimeDesc = 'CLIENTS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  ClientsByContactIdStddevSampleIdAsc = 'CLIENTS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  ClientsByContactIdStddevSampleIdDesc = 'CLIENTS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  ClientsByContactIdStddevSampleLawyerIdAsc = 'CLIENTS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  ClientsByContactIdStddevSampleLawyerIdDesc = 'CLIENTS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  ClientsByContactIdSumCompanyNotesAsc = 'CLIENTS_BY_CONTACT_ID_SUM_COMPANY_NOTES_ASC',
  ClientsByContactIdSumCompanyNotesDesc = 'CLIENTS_BY_CONTACT_ID_SUM_COMPANY_NOTES_DESC',
  ClientsByContactIdSumContactIdAsc = 'CLIENTS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  ClientsByContactIdSumContactIdDesc = 'CLIENTS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  ClientsByContactIdSumContactNotesAsc = 'CLIENTS_BY_CONTACT_ID_SUM_CONTACT_NOTES_ASC',
  ClientsByContactIdSumContactNotesDesc = 'CLIENTS_BY_CONTACT_ID_SUM_CONTACT_NOTES_DESC',
  ClientsByContactIdSumCreatedTimeAsc = 'CLIENTS_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  ClientsByContactIdSumCreatedTimeDesc = 'CLIENTS_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  ClientsByContactIdSumIdAsc = 'CLIENTS_BY_CONTACT_ID_SUM_ID_ASC',
  ClientsByContactIdSumIdDesc = 'CLIENTS_BY_CONTACT_ID_SUM_ID_DESC',
  ClientsByContactIdSumLawyerIdAsc = 'CLIENTS_BY_CONTACT_ID_SUM_LAWYER_ID_ASC',
  ClientsByContactIdSumLawyerIdDesc = 'CLIENTS_BY_CONTACT_ID_SUM_LAWYER_ID_DESC',
  ClientsByContactIdVariancePopulationCompanyNotesAsc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_POPULATION_COMPANY_NOTES_ASC',
  ClientsByContactIdVariancePopulationCompanyNotesDesc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_POPULATION_COMPANY_NOTES_DESC',
  ClientsByContactIdVariancePopulationContactIdAsc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ClientsByContactIdVariancePopulationContactIdDesc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ClientsByContactIdVariancePopulationContactNotesAsc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_NOTES_ASC',
  ClientsByContactIdVariancePopulationContactNotesDesc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_NOTES_DESC',
  ClientsByContactIdVariancePopulationCreatedTimeAsc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  ClientsByContactIdVariancePopulationCreatedTimeDesc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  ClientsByContactIdVariancePopulationIdAsc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  ClientsByContactIdVariancePopulationIdDesc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  ClientsByContactIdVariancePopulationLawyerIdAsc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  ClientsByContactIdVariancePopulationLawyerIdDesc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  ClientsByContactIdVarianceSampleCompanyNotesAsc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_COMPANY_NOTES_ASC',
  ClientsByContactIdVarianceSampleCompanyNotesDesc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_COMPANY_NOTES_DESC',
  ClientsByContactIdVarianceSampleContactIdAsc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ClientsByContactIdVarianceSampleContactIdDesc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ClientsByContactIdVarianceSampleContactNotesAsc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_NOTES_ASC',
  ClientsByContactIdVarianceSampleContactNotesDesc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_NOTES_DESC',
  ClientsByContactIdVarianceSampleCreatedTimeAsc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  ClientsByContactIdVarianceSampleCreatedTimeDesc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  ClientsByContactIdVarianceSampleIdAsc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  ClientsByContactIdVarianceSampleIdDesc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  ClientsByContactIdVarianceSampleLawyerIdAsc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  ClientsByContactIdVarianceSampleLawyerIdDesc = 'CLIENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  CompanyNameAsc = 'COMPANY_NAME_ASC',
  CompanyNameDesc = 'COMPANY_NAME_DESC',
  ContactCampaignTagLinksByContactIdAverageCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_AVERAGE_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByContactIdAverageCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_AVERAGE_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByContactIdAverageContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  ContactCampaignTagLinksByContactIdAverageContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  ContactCampaignTagLinksByContactIdAverageTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_AVERAGE_TAG_ID_ASC',
  ContactCampaignTagLinksByContactIdAverageTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_AVERAGE_TAG_ID_DESC',
  ContactCampaignTagLinksByContactIdCountAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_COUNT_ASC',
  ContactCampaignTagLinksByContactIdCountDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_COUNT_DESC',
  ContactCampaignTagLinksByContactIdDistinctCountCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByContactIdDistinctCountCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByContactIdDistinctCountContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ContactCampaignTagLinksByContactIdDistinctCountContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ContactCampaignTagLinksByContactIdDistinctCountTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_TAG_ID_ASC',
  ContactCampaignTagLinksByContactIdDistinctCountTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_TAG_ID_DESC',
  ContactCampaignTagLinksByContactIdMaxCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_MAX_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByContactIdMaxCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_MAX_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByContactIdMaxContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  ContactCampaignTagLinksByContactIdMaxContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  ContactCampaignTagLinksByContactIdMaxTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_MAX_TAG_ID_ASC',
  ContactCampaignTagLinksByContactIdMaxTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_MAX_TAG_ID_DESC',
  ContactCampaignTagLinksByContactIdMinCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_MIN_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByContactIdMinCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_MIN_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByContactIdMinContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  ContactCampaignTagLinksByContactIdMinContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  ContactCampaignTagLinksByContactIdMinTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_MIN_TAG_ID_ASC',
  ContactCampaignTagLinksByContactIdMinTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_MIN_TAG_ID_DESC',
  ContactCampaignTagLinksByContactIdStddevPopulationCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByContactIdStddevPopulationCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByContactIdStddevPopulationContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ContactCampaignTagLinksByContactIdStddevPopulationContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ContactCampaignTagLinksByContactIdStddevPopulationTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_TAG_ID_ASC',
  ContactCampaignTagLinksByContactIdStddevPopulationTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_TAG_ID_DESC',
  ContactCampaignTagLinksByContactIdStddevSampleCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByContactIdStddevSampleCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByContactIdStddevSampleContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ContactCampaignTagLinksByContactIdStddevSampleContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ContactCampaignTagLinksByContactIdStddevSampleTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_TAG_ID_ASC',
  ContactCampaignTagLinksByContactIdStddevSampleTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_TAG_ID_DESC',
  ContactCampaignTagLinksByContactIdSumCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_SUM_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByContactIdSumCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_SUM_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByContactIdSumContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  ContactCampaignTagLinksByContactIdSumContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  ContactCampaignTagLinksByContactIdSumTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_SUM_TAG_ID_ASC',
  ContactCampaignTagLinksByContactIdSumTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_SUM_TAG_ID_DESC',
  ContactCampaignTagLinksByContactIdVariancePopulationCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByContactIdVariancePopulationCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByContactIdVariancePopulationContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ContactCampaignTagLinksByContactIdVariancePopulationContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ContactCampaignTagLinksByContactIdVariancePopulationTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_TAG_ID_ASC',
  ContactCampaignTagLinksByContactIdVariancePopulationTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_TAG_ID_DESC',
  ContactCampaignTagLinksByContactIdVarianceSampleCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByContactIdVarianceSampleCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByContactIdVarianceSampleContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ContactCampaignTagLinksByContactIdVarianceSampleContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ContactCampaignTagLinksByContactIdVarianceSampleTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_TAG_ID_ASC',
  ContactCampaignTagLinksByContactIdVarianceSampleTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_TAG_ID_DESC',
  ContactEmailAddressLinksByContactIdAverageContactIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  ContactEmailAddressLinksByContactIdAverageContactIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  ContactEmailAddressLinksByContactIdAverageEmailAddressIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_AVERAGE_EMAIL_ADDRESS_ID_ASC',
  ContactEmailAddressLinksByContactIdAverageEmailAddressIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_AVERAGE_EMAIL_ADDRESS_ID_DESC',
  ContactEmailAddressLinksByContactIdCountAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_COUNT_ASC',
  ContactEmailAddressLinksByContactIdCountDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_COUNT_DESC',
  ContactEmailAddressLinksByContactIdDistinctCountContactIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ContactEmailAddressLinksByContactIdDistinctCountContactIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ContactEmailAddressLinksByContactIdDistinctCountEmailAddressIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_EMAIL_ADDRESS_ID_ASC',
  ContactEmailAddressLinksByContactIdDistinctCountEmailAddressIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_EMAIL_ADDRESS_ID_DESC',
  ContactEmailAddressLinksByContactIdMaxContactIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  ContactEmailAddressLinksByContactIdMaxContactIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  ContactEmailAddressLinksByContactIdMaxEmailAddressIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_MAX_EMAIL_ADDRESS_ID_ASC',
  ContactEmailAddressLinksByContactIdMaxEmailAddressIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_MAX_EMAIL_ADDRESS_ID_DESC',
  ContactEmailAddressLinksByContactIdMinContactIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  ContactEmailAddressLinksByContactIdMinContactIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  ContactEmailAddressLinksByContactIdMinEmailAddressIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_MIN_EMAIL_ADDRESS_ID_ASC',
  ContactEmailAddressLinksByContactIdMinEmailAddressIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_MIN_EMAIL_ADDRESS_ID_DESC',
  ContactEmailAddressLinksByContactIdStddevPopulationContactIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ContactEmailAddressLinksByContactIdStddevPopulationContactIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ContactEmailAddressLinksByContactIdStddevPopulationEmailAddressIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_EMAIL_ADDRESS_ID_ASC',
  ContactEmailAddressLinksByContactIdStddevPopulationEmailAddressIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_EMAIL_ADDRESS_ID_DESC',
  ContactEmailAddressLinksByContactIdStddevSampleContactIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ContactEmailAddressLinksByContactIdStddevSampleContactIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ContactEmailAddressLinksByContactIdStddevSampleEmailAddressIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_EMAIL_ADDRESS_ID_ASC',
  ContactEmailAddressLinksByContactIdStddevSampleEmailAddressIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_EMAIL_ADDRESS_ID_DESC',
  ContactEmailAddressLinksByContactIdSumContactIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  ContactEmailAddressLinksByContactIdSumContactIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  ContactEmailAddressLinksByContactIdSumEmailAddressIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_SUM_EMAIL_ADDRESS_ID_ASC',
  ContactEmailAddressLinksByContactIdSumEmailAddressIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_SUM_EMAIL_ADDRESS_ID_DESC',
  ContactEmailAddressLinksByContactIdVariancePopulationContactIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ContactEmailAddressLinksByContactIdVariancePopulationContactIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ContactEmailAddressLinksByContactIdVariancePopulationEmailAddressIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_EMAIL_ADDRESS_ID_ASC',
  ContactEmailAddressLinksByContactIdVariancePopulationEmailAddressIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_EMAIL_ADDRESS_ID_DESC',
  ContactEmailAddressLinksByContactIdVarianceSampleContactIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ContactEmailAddressLinksByContactIdVarianceSampleContactIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ContactEmailAddressLinksByContactIdVarianceSampleEmailAddressIdAsc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_EMAIL_ADDRESS_ID_ASC',
  ContactEmailAddressLinksByContactIdVarianceSampleEmailAddressIdDesc = 'CONTACT_EMAIL_ADDRESS_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_EMAIL_ADDRESS_ID_DESC',
  ContactEnrichmentLogsByContactIdAverageContactIdAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  ContactEnrichmentLogsByContactIdAverageContactIdDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  ContactEnrichmentLogsByContactIdAverageCreatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdAverageCreatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdAverageEnrichmentStatusAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_AVERAGE_ENRICHMENT_STATUS_ASC',
  ContactEnrichmentLogsByContactIdAverageEnrichmentStatusDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_AVERAGE_ENRICHMENT_STATUS_DESC',
  ContactEnrichmentLogsByContactIdAverageEnrichmentTypeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_AVERAGE_ENRICHMENT_TYPE_ASC',
  ContactEnrichmentLogsByContactIdAverageEnrichmentTypeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_AVERAGE_ENRICHMENT_TYPE_DESC',
  ContactEnrichmentLogsByContactIdAverageUpdatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdAverageUpdatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdCountAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_COUNT_ASC',
  ContactEnrichmentLogsByContactIdCountDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_COUNT_DESC',
  ContactEnrichmentLogsByContactIdDistinctCountContactIdAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ContactEnrichmentLogsByContactIdDistinctCountContactIdDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ContactEnrichmentLogsByContactIdDistinctCountCreatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdDistinctCountCreatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdDistinctCountEnrichmentStatusAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_DISTINCT_COUNT_ENRICHMENT_STATUS_ASC',
  ContactEnrichmentLogsByContactIdDistinctCountEnrichmentStatusDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_DISTINCT_COUNT_ENRICHMENT_STATUS_DESC',
  ContactEnrichmentLogsByContactIdDistinctCountEnrichmentTypeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_DISTINCT_COUNT_ENRICHMENT_TYPE_ASC',
  ContactEnrichmentLogsByContactIdDistinctCountEnrichmentTypeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_DISTINCT_COUNT_ENRICHMENT_TYPE_DESC',
  ContactEnrichmentLogsByContactIdDistinctCountUpdatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdDistinctCountUpdatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdMaxContactIdAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  ContactEnrichmentLogsByContactIdMaxContactIdDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  ContactEnrichmentLogsByContactIdMaxCreatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdMaxCreatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdMaxEnrichmentStatusAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MAX_ENRICHMENT_STATUS_ASC',
  ContactEnrichmentLogsByContactIdMaxEnrichmentStatusDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MAX_ENRICHMENT_STATUS_DESC',
  ContactEnrichmentLogsByContactIdMaxEnrichmentTypeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MAX_ENRICHMENT_TYPE_ASC',
  ContactEnrichmentLogsByContactIdMaxEnrichmentTypeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MAX_ENRICHMENT_TYPE_DESC',
  ContactEnrichmentLogsByContactIdMaxUpdatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MAX_UPDATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdMaxUpdatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MAX_UPDATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdMinContactIdAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  ContactEnrichmentLogsByContactIdMinContactIdDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  ContactEnrichmentLogsByContactIdMinCreatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdMinCreatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdMinEnrichmentStatusAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MIN_ENRICHMENT_STATUS_ASC',
  ContactEnrichmentLogsByContactIdMinEnrichmentStatusDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MIN_ENRICHMENT_STATUS_DESC',
  ContactEnrichmentLogsByContactIdMinEnrichmentTypeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MIN_ENRICHMENT_TYPE_ASC',
  ContactEnrichmentLogsByContactIdMinEnrichmentTypeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MIN_ENRICHMENT_TYPE_DESC',
  ContactEnrichmentLogsByContactIdMinUpdatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MIN_UPDATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdMinUpdatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_MIN_UPDATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdStddevPopulationContactIdAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ContactEnrichmentLogsByContactIdStddevPopulationContactIdDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ContactEnrichmentLogsByContactIdStddevPopulationCreatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdStddevPopulationCreatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdStddevPopulationEnrichmentStatusAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_POPULATION_ENRICHMENT_STATUS_ASC',
  ContactEnrichmentLogsByContactIdStddevPopulationEnrichmentStatusDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_POPULATION_ENRICHMENT_STATUS_DESC',
  ContactEnrichmentLogsByContactIdStddevPopulationEnrichmentTypeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_POPULATION_ENRICHMENT_TYPE_ASC',
  ContactEnrichmentLogsByContactIdStddevPopulationEnrichmentTypeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_POPULATION_ENRICHMENT_TYPE_DESC',
  ContactEnrichmentLogsByContactIdStddevPopulationUpdatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdStddevPopulationUpdatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdStddevSampleContactIdAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ContactEnrichmentLogsByContactIdStddevSampleContactIdDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ContactEnrichmentLogsByContactIdStddevSampleCreatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdStddevSampleCreatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdStddevSampleEnrichmentStatusAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_SAMPLE_ENRICHMENT_STATUS_ASC',
  ContactEnrichmentLogsByContactIdStddevSampleEnrichmentStatusDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_SAMPLE_ENRICHMENT_STATUS_DESC',
  ContactEnrichmentLogsByContactIdStddevSampleEnrichmentTypeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_SAMPLE_ENRICHMENT_TYPE_ASC',
  ContactEnrichmentLogsByContactIdStddevSampleEnrichmentTypeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_SAMPLE_ENRICHMENT_TYPE_DESC',
  ContactEnrichmentLogsByContactIdStddevSampleUpdatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdStddevSampleUpdatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdSumContactIdAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  ContactEnrichmentLogsByContactIdSumContactIdDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  ContactEnrichmentLogsByContactIdSumCreatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdSumCreatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdSumEnrichmentStatusAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_SUM_ENRICHMENT_STATUS_ASC',
  ContactEnrichmentLogsByContactIdSumEnrichmentStatusDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_SUM_ENRICHMENT_STATUS_DESC',
  ContactEnrichmentLogsByContactIdSumEnrichmentTypeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_SUM_ENRICHMENT_TYPE_ASC',
  ContactEnrichmentLogsByContactIdSumEnrichmentTypeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_SUM_ENRICHMENT_TYPE_DESC',
  ContactEnrichmentLogsByContactIdSumUpdatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_SUM_UPDATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdSumUpdatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_SUM_UPDATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdVariancePopulationContactIdAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ContactEnrichmentLogsByContactIdVariancePopulationContactIdDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ContactEnrichmentLogsByContactIdVariancePopulationCreatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdVariancePopulationCreatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdVariancePopulationEnrichmentStatusAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_POPULATION_ENRICHMENT_STATUS_ASC',
  ContactEnrichmentLogsByContactIdVariancePopulationEnrichmentStatusDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_POPULATION_ENRICHMENT_STATUS_DESC',
  ContactEnrichmentLogsByContactIdVariancePopulationEnrichmentTypeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_POPULATION_ENRICHMENT_TYPE_ASC',
  ContactEnrichmentLogsByContactIdVariancePopulationEnrichmentTypeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_POPULATION_ENRICHMENT_TYPE_DESC',
  ContactEnrichmentLogsByContactIdVariancePopulationUpdatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdVariancePopulationUpdatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdVarianceSampleContactIdAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ContactEnrichmentLogsByContactIdVarianceSampleContactIdDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ContactEnrichmentLogsByContactIdVarianceSampleCreatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdVarianceSampleCreatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  ContactEnrichmentLogsByContactIdVarianceSampleEnrichmentStatusAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_SAMPLE_ENRICHMENT_STATUS_ASC',
  ContactEnrichmentLogsByContactIdVarianceSampleEnrichmentStatusDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_SAMPLE_ENRICHMENT_STATUS_DESC',
  ContactEnrichmentLogsByContactIdVarianceSampleEnrichmentTypeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_SAMPLE_ENRICHMENT_TYPE_ASC',
  ContactEnrichmentLogsByContactIdVarianceSampleEnrichmentTypeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_SAMPLE_ENRICHMENT_TYPE_DESC',
  ContactEnrichmentLogsByContactIdVarianceSampleUpdatedTimeAsc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  ContactEnrichmentLogsByContactIdVarianceSampleUpdatedTimeDesc = 'CONTACT_ENRICHMENT_LOGS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  ContactEnrichmentLogLinksByContactIdAverageContactIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdAverageContactIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdAverageEnrichmentIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_AVERAGE_ENRICHMENT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdAverageEnrichmentIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_AVERAGE_ENRICHMENT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdCountAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_COUNT_ASC',
  ContactEnrichmentLogLinksByContactIdCountDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_COUNT_DESC',
  ContactEnrichmentLogLinksByContactIdDistinctCountContactIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdDistinctCountContactIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdDistinctCountEnrichmentIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_ENRICHMENT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdDistinctCountEnrichmentIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_ENRICHMENT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdMaxContactIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdMaxContactIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdMaxEnrichmentIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_MAX_ENRICHMENT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdMaxEnrichmentIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_MAX_ENRICHMENT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdMinContactIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdMinContactIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdMinEnrichmentIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_MIN_ENRICHMENT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdMinEnrichmentIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_MIN_ENRICHMENT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdStddevPopulationContactIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdStddevPopulationContactIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdStddevPopulationEnrichmentIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_ENRICHMENT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdStddevPopulationEnrichmentIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_ENRICHMENT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdStddevSampleContactIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdStddevSampleContactIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdStddevSampleEnrichmentIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_ENRICHMENT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdStddevSampleEnrichmentIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_ENRICHMENT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdSumContactIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdSumContactIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdSumEnrichmentIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_SUM_ENRICHMENT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdSumEnrichmentIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_SUM_ENRICHMENT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdVariancePopulationContactIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdVariancePopulationContactIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdVariancePopulationEnrichmentIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_ENRICHMENT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdVariancePopulationEnrichmentIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_ENRICHMENT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdVarianceSampleContactIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdVarianceSampleContactIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ContactEnrichmentLogLinksByContactIdVarianceSampleEnrichmentIdAsc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_ENRICHMENT_ID_ASC',
  ContactEnrichmentLogLinksByContactIdVarianceSampleEnrichmentIdDesc = 'CONTACT_ENRICHMENT_LOG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_ENRICHMENT_ID_DESC',
  ContactTagLinksByContactIdAverageContactIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  ContactTagLinksByContactIdAverageContactIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  ContactTagLinksByContactIdAverageTagIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_AVERAGE_TAG_ID_ASC',
  ContactTagLinksByContactIdAverageTagIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_AVERAGE_TAG_ID_DESC',
  ContactTagLinksByContactIdCountAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_COUNT_ASC',
  ContactTagLinksByContactIdCountDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_COUNT_DESC',
  ContactTagLinksByContactIdDistinctCountContactIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ContactTagLinksByContactIdDistinctCountContactIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ContactTagLinksByContactIdDistinctCountTagIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_TAG_ID_ASC',
  ContactTagLinksByContactIdDistinctCountTagIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_TAG_ID_DESC',
  ContactTagLinksByContactIdMaxContactIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  ContactTagLinksByContactIdMaxContactIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  ContactTagLinksByContactIdMaxTagIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_MAX_TAG_ID_ASC',
  ContactTagLinksByContactIdMaxTagIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_MAX_TAG_ID_DESC',
  ContactTagLinksByContactIdMinContactIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  ContactTagLinksByContactIdMinContactIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  ContactTagLinksByContactIdMinTagIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_MIN_TAG_ID_ASC',
  ContactTagLinksByContactIdMinTagIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_MIN_TAG_ID_DESC',
  ContactTagLinksByContactIdStddevPopulationContactIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ContactTagLinksByContactIdStddevPopulationContactIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ContactTagLinksByContactIdStddevPopulationTagIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_TAG_ID_ASC',
  ContactTagLinksByContactIdStddevPopulationTagIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_TAG_ID_DESC',
  ContactTagLinksByContactIdStddevSampleContactIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ContactTagLinksByContactIdStddevSampleContactIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ContactTagLinksByContactIdStddevSampleTagIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_TAG_ID_ASC',
  ContactTagLinksByContactIdStddevSampleTagIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_TAG_ID_DESC',
  ContactTagLinksByContactIdSumContactIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  ContactTagLinksByContactIdSumContactIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  ContactTagLinksByContactIdSumTagIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_SUM_TAG_ID_ASC',
  ContactTagLinksByContactIdSumTagIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_SUM_TAG_ID_DESC',
  ContactTagLinksByContactIdVariancePopulationContactIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ContactTagLinksByContactIdVariancePopulationContactIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ContactTagLinksByContactIdVariancePopulationTagIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_TAG_ID_ASC',
  ContactTagLinksByContactIdVariancePopulationTagIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_TAG_ID_DESC',
  ContactTagLinksByContactIdVarianceSampleContactIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ContactTagLinksByContactIdVarianceSampleContactIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ContactTagLinksByContactIdVarianceSampleTagIdAsc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_TAG_ID_ASC',
  ContactTagLinksByContactIdVarianceSampleTagIdDesc = 'CONTACT_TAG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_TAG_ID_DESC',
  ContactVectorsByContactIdAverageCollectionAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_AVERAGE_COLLECTION_ASC',
  ContactVectorsByContactIdAverageCollectionDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_AVERAGE_COLLECTION_DESC',
  ContactVectorsByContactIdAverageContactIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  ContactVectorsByContactIdAverageContactIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  ContactVectorsByContactIdAverageEmbeddingIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_AVERAGE_EMBEDDING_ID_ASC',
  ContactVectorsByContactIdAverageEmbeddingIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_AVERAGE_EMBEDDING_ID_DESC',
  ContactVectorsByContactIdCountAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_COUNT_ASC',
  ContactVectorsByContactIdCountDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_COUNT_DESC',
  ContactVectorsByContactIdDistinctCountCollectionAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_DISTINCT_COUNT_COLLECTION_ASC',
  ContactVectorsByContactIdDistinctCountCollectionDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_DISTINCT_COUNT_COLLECTION_DESC',
  ContactVectorsByContactIdDistinctCountContactIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ContactVectorsByContactIdDistinctCountContactIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ContactVectorsByContactIdDistinctCountEmbeddingIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_DISTINCT_COUNT_EMBEDDING_ID_ASC',
  ContactVectorsByContactIdDistinctCountEmbeddingIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_DISTINCT_COUNT_EMBEDDING_ID_DESC',
  ContactVectorsByContactIdMaxCollectionAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_MAX_COLLECTION_ASC',
  ContactVectorsByContactIdMaxCollectionDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_MAX_COLLECTION_DESC',
  ContactVectorsByContactIdMaxContactIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  ContactVectorsByContactIdMaxContactIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  ContactVectorsByContactIdMaxEmbeddingIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_MAX_EMBEDDING_ID_ASC',
  ContactVectorsByContactIdMaxEmbeddingIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_MAX_EMBEDDING_ID_DESC',
  ContactVectorsByContactIdMinCollectionAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_MIN_COLLECTION_ASC',
  ContactVectorsByContactIdMinCollectionDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_MIN_COLLECTION_DESC',
  ContactVectorsByContactIdMinContactIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  ContactVectorsByContactIdMinContactIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  ContactVectorsByContactIdMinEmbeddingIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_MIN_EMBEDDING_ID_ASC',
  ContactVectorsByContactIdMinEmbeddingIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_MIN_EMBEDDING_ID_DESC',
  ContactVectorsByContactIdStddevPopulationCollectionAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_STDDEV_POPULATION_COLLECTION_ASC',
  ContactVectorsByContactIdStddevPopulationCollectionDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_STDDEV_POPULATION_COLLECTION_DESC',
  ContactVectorsByContactIdStddevPopulationContactIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ContactVectorsByContactIdStddevPopulationContactIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ContactVectorsByContactIdStddevPopulationEmbeddingIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_STDDEV_POPULATION_EMBEDDING_ID_ASC',
  ContactVectorsByContactIdStddevPopulationEmbeddingIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_STDDEV_POPULATION_EMBEDDING_ID_DESC',
  ContactVectorsByContactIdStddevSampleCollectionAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_STDDEV_SAMPLE_COLLECTION_ASC',
  ContactVectorsByContactIdStddevSampleCollectionDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_STDDEV_SAMPLE_COLLECTION_DESC',
  ContactVectorsByContactIdStddevSampleContactIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ContactVectorsByContactIdStddevSampleContactIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ContactVectorsByContactIdStddevSampleEmbeddingIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_STDDEV_SAMPLE_EMBEDDING_ID_ASC',
  ContactVectorsByContactIdStddevSampleEmbeddingIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_STDDEV_SAMPLE_EMBEDDING_ID_DESC',
  ContactVectorsByContactIdSumCollectionAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_SUM_COLLECTION_ASC',
  ContactVectorsByContactIdSumCollectionDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_SUM_COLLECTION_DESC',
  ContactVectorsByContactIdSumContactIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  ContactVectorsByContactIdSumContactIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  ContactVectorsByContactIdSumEmbeddingIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_SUM_EMBEDDING_ID_ASC',
  ContactVectorsByContactIdSumEmbeddingIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_SUM_EMBEDDING_ID_DESC',
  ContactVectorsByContactIdVariancePopulationCollectionAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_VARIANCE_POPULATION_COLLECTION_ASC',
  ContactVectorsByContactIdVariancePopulationCollectionDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_VARIANCE_POPULATION_COLLECTION_DESC',
  ContactVectorsByContactIdVariancePopulationContactIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ContactVectorsByContactIdVariancePopulationContactIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ContactVectorsByContactIdVariancePopulationEmbeddingIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_VARIANCE_POPULATION_EMBEDDING_ID_ASC',
  ContactVectorsByContactIdVariancePopulationEmbeddingIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_VARIANCE_POPULATION_EMBEDDING_ID_DESC',
  ContactVectorsByContactIdVarianceSampleCollectionAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_VARIANCE_SAMPLE_COLLECTION_ASC',
  ContactVectorsByContactIdVarianceSampleCollectionDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_VARIANCE_SAMPLE_COLLECTION_DESC',
  ContactVectorsByContactIdVarianceSampleContactIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ContactVectorsByContactIdVarianceSampleContactIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ContactVectorsByContactIdVarianceSampleEmbeddingIdAsc = 'CONTACT_VECTORS_BY_CONTACT_ID_VARIANCE_SAMPLE_EMBEDDING_ID_ASC',
  ContactVectorsByContactIdVarianceSampleEmbeddingIdDesc = 'CONTACT_VECTORS_BY_CONTACT_ID_VARIANCE_SAMPLE_EMBEDDING_ID_DESC',
  ContactVerificationLogLinksByContactIdAverageContactIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  ContactVerificationLogLinksByContactIdAverageContactIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  ContactVerificationLogLinksByContactIdAverageVerificationIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_AVERAGE_VERIFICATION_ID_ASC',
  ContactVerificationLogLinksByContactIdAverageVerificationIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_AVERAGE_VERIFICATION_ID_DESC',
  ContactVerificationLogLinksByContactIdCountAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_COUNT_ASC',
  ContactVerificationLogLinksByContactIdCountDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_COUNT_DESC',
  ContactVerificationLogLinksByContactIdDistinctCountContactIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ContactVerificationLogLinksByContactIdDistinctCountContactIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ContactVerificationLogLinksByContactIdDistinctCountVerificationIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_VERIFICATION_ID_ASC',
  ContactVerificationLogLinksByContactIdDistinctCountVerificationIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_VERIFICATION_ID_DESC',
  ContactVerificationLogLinksByContactIdMaxContactIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  ContactVerificationLogLinksByContactIdMaxContactIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  ContactVerificationLogLinksByContactIdMaxVerificationIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_MAX_VERIFICATION_ID_ASC',
  ContactVerificationLogLinksByContactIdMaxVerificationIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_MAX_VERIFICATION_ID_DESC',
  ContactVerificationLogLinksByContactIdMinContactIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  ContactVerificationLogLinksByContactIdMinContactIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  ContactVerificationLogLinksByContactIdMinVerificationIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_MIN_VERIFICATION_ID_ASC',
  ContactVerificationLogLinksByContactIdMinVerificationIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_MIN_VERIFICATION_ID_DESC',
  ContactVerificationLogLinksByContactIdStddevPopulationContactIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ContactVerificationLogLinksByContactIdStddevPopulationContactIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ContactVerificationLogLinksByContactIdStddevPopulationVerificationIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_VERIFICATION_ID_ASC',
  ContactVerificationLogLinksByContactIdStddevPopulationVerificationIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_VERIFICATION_ID_DESC',
  ContactVerificationLogLinksByContactIdStddevSampleContactIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ContactVerificationLogLinksByContactIdStddevSampleContactIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ContactVerificationLogLinksByContactIdStddevSampleVerificationIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_VERIFICATION_ID_ASC',
  ContactVerificationLogLinksByContactIdStddevSampleVerificationIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_VERIFICATION_ID_DESC',
  ContactVerificationLogLinksByContactIdSumContactIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  ContactVerificationLogLinksByContactIdSumContactIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  ContactVerificationLogLinksByContactIdSumVerificationIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_SUM_VERIFICATION_ID_ASC',
  ContactVerificationLogLinksByContactIdSumVerificationIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_SUM_VERIFICATION_ID_DESC',
  ContactVerificationLogLinksByContactIdVariancePopulationContactIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ContactVerificationLogLinksByContactIdVariancePopulationContactIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ContactVerificationLogLinksByContactIdVariancePopulationVerificationIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_VERIFICATION_ID_ASC',
  ContactVerificationLogLinksByContactIdVariancePopulationVerificationIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_VERIFICATION_ID_DESC',
  ContactVerificationLogLinksByContactIdVarianceSampleContactIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ContactVerificationLogLinksByContactIdVarianceSampleContactIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ContactVerificationLogLinksByContactIdVarianceSampleVerificationIdAsc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_VERIFICATION_ID_ASC',
  ContactVerificationLogLinksByContactIdVarianceSampleVerificationIdDesc = 'CONTACT_VERIFICATION_LOG_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_VERIFICATION_ID_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  CrunchbaseUrlAsc = 'CRUNCHBASE_URL_ASC',
  CrunchbaseUrlDesc = 'CRUNCHBASE_URL_DESC',
  DryRunMembersByContactIdAverageContactIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  DryRunMembersByContactIdAverageContactIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  DryRunMembersByContactIdAverageDryRunIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_AVERAGE_DRY_RUN_ID_ASC',
  DryRunMembersByContactIdAverageDryRunIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_AVERAGE_DRY_RUN_ID_DESC',
  DryRunMembersByContactIdAverageScoreAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_AVERAGE_SCORE_ASC',
  DryRunMembersByContactIdAverageScoreDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_AVERAGE_SCORE_DESC',
  DryRunMembersByContactIdCountAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_COUNT_ASC',
  DryRunMembersByContactIdCountDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_COUNT_DESC',
  DryRunMembersByContactIdDistinctCountContactIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  DryRunMembersByContactIdDistinctCountContactIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  DryRunMembersByContactIdDistinctCountDryRunIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_DRY_RUN_ID_ASC',
  DryRunMembersByContactIdDistinctCountDryRunIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_DRY_RUN_ID_DESC',
  DryRunMembersByContactIdDistinctCountScoreAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_SCORE_ASC',
  DryRunMembersByContactIdDistinctCountScoreDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_DISTINCT_COUNT_SCORE_DESC',
  DryRunMembersByContactIdMaxContactIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  DryRunMembersByContactIdMaxContactIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  DryRunMembersByContactIdMaxDryRunIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_MAX_DRY_RUN_ID_ASC',
  DryRunMembersByContactIdMaxDryRunIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_MAX_DRY_RUN_ID_DESC',
  DryRunMembersByContactIdMaxScoreAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_MAX_SCORE_ASC',
  DryRunMembersByContactIdMaxScoreDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_MAX_SCORE_DESC',
  DryRunMembersByContactIdMinContactIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  DryRunMembersByContactIdMinContactIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  DryRunMembersByContactIdMinDryRunIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_MIN_DRY_RUN_ID_ASC',
  DryRunMembersByContactIdMinDryRunIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_MIN_DRY_RUN_ID_DESC',
  DryRunMembersByContactIdMinScoreAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_MIN_SCORE_ASC',
  DryRunMembersByContactIdMinScoreDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_MIN_SCORE_DESC',
  DryRunMembersByContactIdStddevPopulationContactIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  DryRunMembersByContactIdStddevPopulationContactIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  DryRunMembersByContactIdStddevPopulationDryRunIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_DRY_RUN_ID_ASC',
  DryRunMembersByContactIdStddevPopulationDryRunIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_DRY_RUN_ID_DESC',
  DryRunMembersByContactIdStddevPopulationScoreAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_SCORE_ASC',
  DryRunMembersByContactIdStddevPopulationScoreDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_STDDEV_POPULATION_SCORE_DESC',
  DryRunMembersByContactIdStddevSampleContactIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  DryRunMembersByContactIdStddevSampleContactIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  DryRunMembersByContactIdStddevSampleDryRunIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_DRY_RUN_ID_ASC',
  DryRunMembersByContactIdStddevSampleDryRunIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_DRY_RUN_ID_DESC',
  DryRunMembersByContactIdStddevSampleScoreAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_SCORE_ASC',
  DryRunMembersByContactIdStddevSampleScoreDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_STDDEV_SAMPLE_SCORE_DESC',
  DryRunMembersByContactIdSumContactIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  DryRunMembersByContactIdSumContactIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  DryRunMembersByContactIdSumDryRunIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_SUM_DRY_RUN_ID_ASC',
  DryRunMembersByContactIdSumDryRunIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_SUM_DRY_RUN_ID_DESC',
  DryRunMembersByContactIdSumScoreAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_SUM_SCORE_ASC',
  DryRunMembersByContactIdSumScoreDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_SUM_SCORE_DESC',
  DryRunMembersByContactIdVariancePopulationContactIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  DryRunMembersByContactIdVariancePopulationContactIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  DryRunMembersByContactIdVariancePopulationDryRunIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_DRY_RUN_ID_ASC',
  DryRunMembersByContactIdVariancePopulationDryRunIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_DRY_RUN_ID_DESC',
  DryRunMembersByContactIdVariancePopulationScoreAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_SCORE_ASC',
  DryRunMembersByContactIdVariancePopulationScoreDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_VARIANCE_POPULATION_SCORE_DESC',
  DryRunMembersByContactIdVarianceSampleContactIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  DryRunMembersByContactIdVarianceSampleContactIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  DryRunMembersByContactIdVarianceSampleDryRunIdAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_DRY_RUN_ID_ASC',
  DryRunMembersByContactIdVarianceSampleDryRunIdDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_DRY_RUN_ID_DESC',
  DryRunMembersByContactIdVarianceSampleScoreAsc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SCORE_ASC',
  DryRunMembersByContactIdVarianceSampleScoreDesc = 'DRY_RUN_MEMBERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SCORE_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdAverageContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByContactIdAverageContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByContactIdAverageCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdAverageCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdAverageEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByContactIdAverageEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByContactIdAverageErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdAverageErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdAverageErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByContactIdAverageErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByContactIdAverageFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdAverageFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdAverageIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_ID_ASC',
  EmailDeliverabilityResultsByContactIdAverageIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_ID_DESC',
  EmailDeliverabilityResultsByContactIdAverageIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByContactIdAverageIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByContactIdAverageLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByContactIdAverageLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByContactIdAverageResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByContactIdAverageResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByContactIdAverageResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdAverageResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdAverageToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByContactIdAverageToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByContactIdAverageToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdAverageToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdAverageUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdAverageUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdCountAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_COUNT_ASC',
  EmailDeliverabilityResultsByContactIdCountDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_COUNT_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdDistinctCountUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdDistinctCountUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdMaxContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByContactIdMaxContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByContactIdMaxCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdMaxCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdMaxEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByContactIdMaxEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByContactIdMaxErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdMaxErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdMaxErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByContactIdMaxErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByContactIdMaxFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdMaxFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdMaxIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_ID_ASC',
  EmailDeliverabilityResultsByContactIdMaxIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_ID_DESC',
  EmailDeliverabilityResultsByContactIdMaxIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByContactIdMaxIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByContactIdMaxLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByContactIdMaxLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByContactIdMaxResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByContactIdMaxResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByContactIdMaxResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdMaxResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdMaxToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByContactIdMaxToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByContactIdMaxToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdMaxToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdMaxUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdMaxUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MAX_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdMinContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByContactIdMinContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByContactIdMinCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdMinCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdMinEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByContactIdMinEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByContactIdMinErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdMinErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdMinErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByContactIdMinErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByContactIdMinFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdMinFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdMinIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_ID_ASC',
  EmailDeliverabilityResultsByContactIdMinIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_ID_DESC',
  EmailDeliverabilityResultsByContactIdMinIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByContactIdMinIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByContactIdMinLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByContactIdMinLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByContactIdMinResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByContactIdMinResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByContactIdMinResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdMinResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdMinToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByContactIdMinToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByContactIdMinToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdMinToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdMinUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdMinUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_MIN_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdStddevPopulationUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdStddevPopulationUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdStddevSampleUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdStddevSampleUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdSumContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByContactIdSumContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByContactIdSumCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdSumCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdSumEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByContactIdSumEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByContactIdSumErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdSumErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdSumErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByContactIdSumErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByContactIdSumFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdSumFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdSumIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_ID_ASC',
  EmailDeliverabilityResultsByContactIdSumIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_ID_DESC',
  EmailDeliverabilityResultsByContactIdSumIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByContactIdSumIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByContactIdSumLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByContactIdSumLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByContactIdSumResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByContactIdSumResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByContactIdSumResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdSumResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdSumToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByContactIdSumToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByContactIdSumToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdSumToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdSumUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdSumUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_SUM_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdVariancePopulationUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdVariancePopulationUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByContactIdVarianceSampleUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByContactIdVarianceSampleUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EmailDesc = 'EMAIL_DESC',
  EmailDomainRevAsc = 'EMAIL_DOMAIN_REV_ASC',
  EmailDomainRevDesc = 'EMAIL_DOMAIN_REV_DESC',
  EmailMessagesByContactIdAverageAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_AUTO_REPLIED_ASC',
  EmailMessagesByContactIdAverageAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_AUTO_REPLIED_DESC',
  EmailMessagesByContactIdAverageCampaignIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CAMPAIGN_ID_ASC',
  EmailMessagesByContactIdAverageCampaignIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CAMPAIGN_ID_DESC',
  EmailMessagesByContactIdAverageCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByContactIdAverageCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByContactIdAverageCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdAverageCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdAverageCompanyIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_COMPANY_ID_ASC',
  EmailMessagesByContactIdAverageCompanyIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_COMPANY_ID_DESC',
  EmailMessagesByContactIdAverageContactIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  EmailMessagesByContactIdAverageContactIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  EmailMessagesByContactIdAverageContentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CONTENT_ASC',
  EmailMessagesByContactIdAverageContentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CONTENT_DESC',
  EmailMessagesByContactIdAverageContentHtmlAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CONTENT_HTML_ASC',
  EmailMessagesByContactIdAverageContentHtmlDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CONTENT_HTML_DESC',
  EmailMessagesByContactIdAverageCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  EmailMessagesByContactIdAverageCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  EmailMessagesByContactIdAverageEmailIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_EMAIL_ID_ASC',
  EmailMessagesByContactIdAverageEmailIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_EMAIL_ID_DESC',
  EmailMessagesByContactIdAverageForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByContactIdAverageForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByContactIdAverageForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByContactIdAverageForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByContactIdAverageGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_GENERATED_REPLY_ASC',
  EmailMessagesByContactIdAverageGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_GENERATED_REPLY_DESC',
  EmailMessagesByContactIdAverageHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByContactIdAverageHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByContactIdAverageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_ID_ASC',
  EmailMessagesByContactIdAverageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_ID_DESC',
  EmailMessagesByContactIdAverageIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_INTENT_ASC',
  EmailMessagesByContactIdAverageIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_INTENT_DESC',
  EmailMessagesByContactIdAverageIsLawyerAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_IS_LAWYER_ASC',
  EmailMessagesByContactIdAverageIsLawyerDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_IS_LAWYER_DESC',
  EmailMessagesByContactIdAverageIsReviewedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_IS_REVIEWED_ASC',
  EmailMessagesByContactIdAverageIsReviewedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_IS_REVIEWED_DESC',
  EmailMessagesByContactIdAverageLawyerIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_LAWYER_ID_ASC',
  EmailMessagesByContactIdAverageLawyerIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_LAWYER_ID_DESC',
  EmailMessagesByContactIdAverageLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByContactIdAverageLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByContactIdAverageNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByContactIdAverageNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByContactIdAverageOrderIndexAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_ORDER_INDEX_ASC',
  EmailMessagesByContactIdAverageOrderIndexDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_ORDER_INDEX_DESC',
  EmailMessagesByContactIdAveragePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByContactIdAveragePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByContactIdAverageRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_REPLIED_INTENT_ASC',
  EmailMessagesByContactIdAverageRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_REPLIED_INTENT_DESC',
  EmailMessagesByContactIdAverageRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_REPLIED_TIME_ASC',
  EmailMessagesByContactIdAverageRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_REPLIED_TIME_DESC',
  EmailMessagesByContactIdAverageRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdAverageRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdAverageReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_REVIEWED_TIME_ASC',
  EmailMessagesByContactIdAverageReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_REVIEWED_TIME_DESC',
  EmailMessagesByContactIdAverageSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByContactIdAverageSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByContactIdAverageSentTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_SENT_TIME_ASC',
  EmailMessagesByContactIdAverageSentTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_SENT_TIME_DESC',
  EmailMessagesByContactIdAverageSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByContactIdAverageSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByContactIdAverageSubjectAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_SUBJECT_ASC',
  EmailMessagesByContactIdAverageSubjectDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_SUBJECT_DESC',
  EmailMessagesByContactIdAverageTemplateIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_TEMPLATE_ID_ASC',
  EmailMessagesByContactIdAverageTemplateIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_TEMPLATE_ID_DESC',
  EmailMessagesByContactIdAverageUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailMessagesByContactIdAverageUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailMessagesByContactIdCountAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_COUNT_ASC',
  EmailMessagesByContactIdCountDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_COUNT_DESC',
  EmailMessagesByContactIdDistinctCountAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_AUTO_REPLIED_ASC',
  EmailMessagesByContactIdDistinctCountAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_AUTO_REPLIED_DESC',
  EmailMessagesByContactIdDistinctCountCampaignIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  EmailMessagesByContactIdDistinctCountCampaignIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  EmailMessagesByContactIdDistinctCountCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByContactIdDistinctCountCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByContactIdDistinctCountCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdDistinctCountCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdDistinctCountCompanyIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  EmailMessagesByContactIdDistinctCountCompanyIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  EmailMessagesByContactIdDistinctCountContactIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  EmailMessagesByContactIdDistinctCountContactIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  EmailMessagesByContactIdDistinctCountContentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CONTENT_ASC',
  EmailMessagesByContactIdDistinctCountContentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CONTENT_DESC',
  EmailMessagesByContactIdDistinctCountContentHtmlAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CONTENT_HTML_ASC',
  EmailMessagesByContactIdDistinctCountContentHtmlDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CONTENT_HTML_DESC',
  EmailMessagesByContactIdDistinctCountCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailMessagesByContactIdDistinctCountCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailMessagesByContactIdDistinctCountEmailIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailMessagesByContactIdDistinctCountEmailIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailMessagesByContactIdDistinctCountForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByContactIdDistinctCountForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByContactIdDistinctCountForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByContactIdDistinctCountForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByContactIdDistinctCountGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_GENERATED_REPLY_ASC',
  EmailMessagesByContactIdDistinctCountGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_GENERATED_REPLY_DESC',
  EmailMessagesByContactIdDistinctCountHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByContactIdDistinctCountHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByContactIdDistinctCountIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  EmailMessagesByContactIdDistinctCountIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  EmailMessagesByContactIdDistinctCountIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_INTENT_ASC',
  EmailMessagesByContactIdDistinctCountIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_INTENT_DESC',
  EmailMessagesByContactIdDistinctCountIsLawyerAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  EmailMessagesByContactIdDistinctCountIsLawyerDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  EmailMessagesByContactIdDistinctCountIsReviewedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_IS_REVIEWED_ASC',
  EmailMessagesByContactIdDistinctCountIsReviewedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_IS_REVIEWED_DESC',
  EmailMessagesByContactIdDistinctCountLawyerIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  EmailMessagesByContactIdDistinctCountLawyerIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  EmailMessagesByContactIdDistinctCountLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByContactIdDistinctCountLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByContactIdDistinctCountNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_ASC',
  EmailMessagesByContactIdDistinctCountNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_DESC',
  EmailMessagesByContactIdDistinctCountOrderIndexAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_ORDER_INDEX_ASC',
  EmailMessagesByContactIdDistinctCountOrderIndexDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_ORDER_INDEX_DESC',
  EmailMessagesByContactIdDistinctCountPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByContactIdDistinctCountPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByContactIdDistinctCountRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REPLIED_INTENT_ASC',
  EmailMessagesByContactIdDistinctCountRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REPLIED_INTENT_DESC',
  EmailMessagesByContactIdDistinctCountRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REPLIED_TIME_ASC',
  EmailMessagesByContactIdDistinctCountRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REPLIED_TIME_DESC',
  EmailMessagesByContactIdDistinctCountRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdDistinctCountRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdDistinctCountReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  EmailMessagesByContactIdDistinctCountReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  EmailMessagesByContactIdDistinctCountSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  EmailMessagesByContactIdDistinctCountSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  EmailMessagesByContactIdDistinctCountSentTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_SENT_TIME_ASC',
  EmailMessagesByContactIdDistinctCountSentTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_SENT_TIME_DESC',
  EmailMessagesByContactIdDistinctCountSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByContactIdDistinctCountSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByContactIdDistinctCountSubjectAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_SUBJECT_ASC',
  EmailMessagesByContactIdDistinctCountSubjectDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_SUBJECT_DESC',
  EmailMessagesByContactIdDistinctCountTemplateIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  EmailMessagesByContactIdDistinctCountTemplateIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  EmailMessagesByContactIdDistinctCountUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailMessagesByContactIdDistinctCountUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailMessagesByContactIdMaxAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_AUTO_REPLIED_ASC',
  EmailMessagesByContactIdMaxAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_AUTO_REPLIED_DESC',
  EmailMessagesByContactIdMaxCampaignIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CAMPAIGN_ID_ASC',
  EmailMessagesByContactIdMaxCampaignIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CAMPAIGN_ID_DESC',
  EmailMessagesByContactIdMaxCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByContactIdMaxCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByContactIdMaxCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdMaxCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdMaxCompanyIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_COMPANY_ID_ASC',
  EmailMessagesByContactIdMaxCompanyIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_COMPANY_ID_DESC',
  EmailMessagesByContactIdMaxContactIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  EmailMessagesByContactIdMaxContactIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  EmailMessagesByContactIdMaxContentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CONTENT_ASC',
  EmailMessagesByContactIdMaxContentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CONTENT_DESC',
  EmailMessagesByContactIdMaxContentHtmlAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CONTENT_HTML_ASC',
  EmailMessagesByContactIdMaxContentHtmlDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CONTENT_HTML_DESC',
  EmailMessagesByContactIdMaxCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  EmailMessagesByContactIdMaxCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  EmailMessagesByContactIdMaxEmailIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_EMAIL_ID_ASC',
  EmailMessagesByContactIdMaxEmailIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_EMAIL_ID_DESC',
  EmailMessagesByContactIdMaxForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByContactIdMaxForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByContactIdMaxForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByContactIdMaxForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByContactIdMaxGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_GENERATED_REPLY_ASC',
  EmailMessagesByContactIdMaxGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_GENERATED_REPLY_DESC',
  EmailMessagesByContactIdMaxHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByContactIdMaxHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByContactIdMaxIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_ID_ASC',
  EmailMessagesByContactIdMaxIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_ID_DESC',
  EmailMessagesByContactIdMaxIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_INTENT_ASC',
  EmailMessagesByContactIdMaxIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_INTENT_DESC',
  EmailMessagesByContactIdMaxIsLawyerAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_IS_LAWYER_ASC',
  EmailMessagesByContactIdMaxIsLawyerDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_IS_LAWYER_DESC',
  EmailMessagesByContactIdMaxIsReviewedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_IS_REVIEWED_ASC',
  EmailMessagesByContactIdMaxIsReviewedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_IS_REVIEWED_DESC',
  EmailMessagesByContactIdMaxLawyerIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_LAWYER_ID_ASC',
  EmailMessagesByContactIdMaxLawyerIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_LAWYER_ID_DESC',
  EmailMessagesByContactIdMaxLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByContactIdMaxLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByContactIdMaxNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_NYLAS_THREAD_ID_ASC',
  EmailMessagesByContactIdMaxNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_NYLAS_THREAD_ID_DESC',
  EmailMessagesByContactIdMaxOrderIndexAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_ORDER_INDEX_ASC',
  EmailMessagesByContactIdMaxOrderIndexDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_ORDER_INDEX_DESC',
  EmailMessagesByContactIdMaxPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByContactIdMaxPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByContactIdMaxRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_REPLIED_INTENT_ASC',
  EmailMessagesByContactIdMaxRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_REPLIED_INTENT_DESC',
  EmailMessagesByContactIdMaxRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_REPLIED_TIME_ASC',
  EmailMessagesByContactIdMaxRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_REPLIED_TIME_DESC',
  EmailMessagesByContactIdMaxRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdMaxRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdMaxReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_REVIEWED_TIME_ASC',
  EmailMessagesByContactIdMaxReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_REVIEWED_TIME_DESC',
  EmailMessagesByContactIdMaxSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_SENDER_PROFILE_ID_ASC',
  EmailMessagesByContactIdMaxSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_SENDER_PROFILE_ID_DESC',
  EmailMessagesByContactIdMaxSentTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_SENT_TIME_ASC',
  EmailMessagesByContactIdMaxSentTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_SENT_TIME_DESC',
  EmailMessagesByContactIdMaxSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByContactIdMaxSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByContactIdMaxSubjectAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_SUBJECT_ASC',
  EmailMessagesByContactIdMaxSubjectDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_SUBJECT_DESC',
  EmailMessagesByContactIdMaxTemplateIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_TEMPLATE_ID_ASC',
  EmailMessagesByContactIdMaxTemplateIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_TEMPLATE_ID_DESC',
  EmailMessagesByContactIdMaxUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_UPDATED_TIME_ASC',
  EmailMessagesByContactIdMaxUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MAX_UPDATED_TIME_DESC',
  EmailMessagesByContactIdMinAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_AUTO_REPLIED_ASC',
  EmailMessagesByContactIdMinAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_AUTO_REPLIED_DESC',
  EmailMessagesByContactIdMinCampaignIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CAMPAIGN_ID_ASC',
  EmailMessagesByContactIdMinCampaignIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CAMPAIGN_ID_DESC',
  EmailMessagesByContactIdMinCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByContactIdMinCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByContactIdMinCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdMinCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdMinCompanyIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_COMPANY_ID_ASC',
  EmailMessagesByContactIdMinCompanyIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_COMPANY_ID_DESC',
  EmailMessagesByContactIdMinContactIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  EmailMessagesByContactIdMinContactIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  EmailMessagesByContactIdMinContentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CONTENT_ASC',
  EmailMessagesByContactIdMinContentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CONTENT_DESC',
  EmailMessagesByContactIdMinContentHtmlAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CONTENT_HTML_ASC',
  EmailMessagesByContactIdMinContentHtmlDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CONTENT_HTML_DESC',
  EmailMessagesByContactIdMinCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  EmailMessagesByContactIdMinCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  EmailMessagesByContactIdMinEmailIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_EMAIL_ID_ASC',
  EmailMessagesByContactIdMinEmailIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_EMAIL_ID_DESC',
  EmailMessagesByContactIdMinForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByContactIdMinForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByContactIdMinForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByContactIdMinForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByContactIdMinGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_GENERATED_REPLY_ASC',
  EmailMessagesByContactIdMinGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_GENERATED_REPLY_DESC',
  EmailMessagesByContactIdMinHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByContactIdMinHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByContactIdMinIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_ID_ASC',
  EmailMessagesByContactIdMinIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_ID_DESC',
  EmailMessagesByContactIdMinIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_INTENT_ASC',
  EmailMessagesByContactIdMinIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_INTENT_DESC',
  EmailMessagesByContactIdMinIsLawyerAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_IS_LAWYER_ASC',
  EmailMessagesByContactIdMinIsLawyerDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_IS_LAWYER_DESC',
  EmailMessagesByContactIdMinIsReviewedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_IS_REVIEWED_ASC',
  EmailMessagesByContactIdMinIsReviewedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_IS_REVIEWED_DESC',
  EmailMessagesByContactIdMinLawyerIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_LAWYER_ID_ASC',
  EmailMessagesByContactIdMinLawyerIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_LAWYER_ID_DESC',
  EmailMessagesByContactIdMinLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByContactIdMinLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByContactIdMinNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_NYLAS_THREAD_ID_ASC',
  EmailMessagesByContactIdMinNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_NYLAS_THREAD_ID_DESC',
  EmailMessagesByContactIdMinOrderIndexAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_ORDER_INDEX_ASC',
  EmailMessagesByContactIdMinOrderIndexDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_ORDER_INDEX_DESC',
  EmailMessagesByContactIdMinPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByContactIdMinPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByContactIdMinRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_REPLIED_INTENT_ASC',
  EmailMessagesByContactIdMinRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_REPLIED_INTENT_DESC',
  EmailMessagesByContactIdMinRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_REPLIED_TIME_ASC',
  EmailMessagesByContactIdMinRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_REPLIED_TIME_DESC',
  EmailMessagesByContactIdMinRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdMinRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdMinReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_REVIEWED_TIME_ASC',
  EmailMessagesByContactIdMinReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_REVIEWED_TIME_DESC',
  EmailMessagesByContactIdMinSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_SENDER_PROFILE_ID_ASC',
  EmailMessagesByContactIdMinSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_SENDER_PROFILE_ID_DESC',
  EmailMessagesByContactIdMinSentTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_SENT_TIME_ASC',
  EmailMessagesByContactIdMinSentTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_SENT_TIME_DESC',
  EmailMessagesByContactIdMinSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByContactIdMinSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByContactIdMinSubjectAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_SUBJECT_ASC',
  EmailMessagesByContactIdMinSubjectDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_SUBJECT_DESC',
  EmailMessagesByContactIdMinTemplateIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_TEMPLATE_ID_ASC',
  EmailMessagesByContactIdMinTemplateIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_TEMPLATE_ID_DESC',
  EmailMessagesByContactIdMinUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_UPDATED_TIME_ASC',
  EmailMessagesByContactIdMinUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_MIN_UPDATED_TIME_DESC',
  EmailMessagesByContactIdStddevPopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByContactIdStddevPopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByContactIdStddevPopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByContactIdStddevPopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByContactIdStddevPopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByContactIdStddevPopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByContactIdStddevPopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdStddevPopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdStddevPopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByContactIdStddevPopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByContactIdStddevPopulationContactIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByContactIdStddevPopulationContactIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByContactIdStddevPopulationContentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CONTENT_ASC',
  EmailMessagesByContactIdStddevPopulationContentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CONTENT_DESC',
  EmailMessagesByContactIdStddevPopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByContactIdStddevPopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByContactIdStddevPopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByContactIdStddevPopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByContactIdStddevPopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByContactIdStddevPopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByContactIdStddevPopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByContactIdStddevPopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByContactIdStddevPopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByContactIdStddevPopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByContactIdStddevPopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByContactIdStddevPopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByContactIdStddevPopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByContactIdStddevPopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByContactIdStddevPopulationIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  EmailMessagesByContactIdStddevPopulationIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  EmailMessagesByContactIdStddevPopulationIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_INTENT_ASC',
  EmailMessagesByContactIdStddevPopulationIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_INTENT_DESC',
  EmailMessagesByContactIdStddevPopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByContactIdStddevPopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByContactIdStddevPopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByContactIdStddevPopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByContactIdStddevPopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByContactIdStddevPopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByContactIdStddevPopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByContactIdStddevPopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByContactIdStddevPopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByContactIdStddevPopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByContactIdStddevPopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByContactIdStddevPopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByContactIdStddevPopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByContactIdStddevPopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByContactIdStddevPopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByContactIdStddevPopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByContactIdStddevPopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByContactIdStddevPopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByContactIdStddevPopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdStddevPopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdStddevPopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByContactIdStddevPopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByContactIdStddevPopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByContactIdStddevPopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByContactIdStddevPopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_SENT_TIME_ASC',
  EmailMessagesByContactIdStddevPopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_SENT_TIME_DESC',
  EmailMessagesByContactIdStddevPopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByContactIdStddevPopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByContactIdStddevPopulationSubjectAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_SUBJECT_ASC',
  EmailMessagesByContactIdStddevPopulationSubjectDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_SUBJECT_DESC',
  EmailMessagesByContactIdStddevPopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByContactIdStddevPopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByContactIdStddevPopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByContactIdStddevPopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByContactIdStddevSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByContactIdStddevSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByContactIdStddevSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByContactIdStddevSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByContactIdStddevSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByContactIdStddevSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByContactIdStddevSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdStddevSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdStddevSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByContactIdStddevSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByContactIdStddevSampleContactIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByContactIdStddevSampleContactIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByContactIdStddevSampleContentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CONTENT_ASC',
  EmailMessagesByContactIdStddevSampleContentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CONTENT_DESC',
  EmailMessagesByContactIdStddevSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByContactIdStddevSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByContactIdStddevSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByContactIdStddevSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByContactIdStddevSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByContactIdStddevSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByContactIdStddevSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByContactIdStddevSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByContactIdStddevSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByContactIdStddevSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByContactIdStddevSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByContactIdStddevSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByContactIdStddevSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByContactIdStddevSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByContactIdStddevSampleIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  EmailMessagesByContactIdStddevSampleIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  EmailMessagesByContactIdStddevSampleIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_INTENT_ASC',
  EmailMessagesByContactIdStddevSampleIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_INTENT_DESC',
  EmailMessagesByContactIdStddevSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByContactIdStddevSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByContactIdStddevSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByContactIdStddevSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByContactIdStddevSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByContactIdStddevSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByContactIdStddevSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByContactIdStddevSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByContactIdStddevSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByContactIdStddevSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByContactIdStddevSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByContactIdStddevSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByContactIdStddevSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByContactIdStddevSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByContactIdStddevSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByContactIdStddevSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByContactIdStddevSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByContactIdStddevSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByContactIdStddevSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdStddevSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdStddevSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByContactIdStddevSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByContactIdStddevSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByContactIdStddevSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByContactIdStddevSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByContactIdStddevSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByContactIdStddevSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByContactIdStddevSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByContactIdStddevSampleSubjectAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_SUBJECT_ASC',
  EmailMessagesByContactIdStddevSampleSubjectDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_SUBJECT_DESC',
  EmailMessagesByContactIdStddevSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByContactIdStddevSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByContactIdStddevSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByContactIdStddevSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailMessagesByContactIdSumAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_AUTO_REPLIED_ASC',
  EmailMessagesByContactIdSumAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_AUTO_REPLIED_DESC',
  EmailMessagesByContactIdSumCampaignIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CAMPAIGN_ID_ASC',
  EmailMessagesByContactIdSumCampaignIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CAMPAIGN_ID_DESC',
  EmailMessagesByContactIdSumCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByContactIdSumCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByContactIdSumCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdSumCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdSumCompanyIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_COMPANY_ID_ASC',
  EmailMessagesByContactIdSumCompanyIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_COMPANY_ID_DESC',
  EmailMessagesByContactIdSumContactIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  EmailMessagesByContactIdSumContactIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  EmailMessagesByContactIdSumContentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CONTENT_ASC',
  EmailMessagesByContactIdSumContentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CONTENT_DESC',
  EmailMessagesByContactIdSumContentHtmlAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CONTENT_HTML_ASC',
  EmailMessagesByContactIdSumContentHtmlDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CONTENT_HTML_DESC',
  EmailMessagesByContactIdSumCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  EmailMessagesByContactIdSumCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  EmailMessagesByContactIdSumEmailIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_EMAIL_ID_ASC',
  EmailMessagesByContactIdSumEmailIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_EMAIL_ID_DESC',
  EmailMessagesByContactIdSumForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByContactIdSumForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByContactIdSumForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByContactIdSumForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByContactIdSumGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_GENERATED_REPLY_ASC',
  EmailMessagesByContactIdSumGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_GENERATED_REPLY_DESC',
  EmailMessagesByContactIdSumHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByContactIdSumHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByContactIdSumIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_ID_ASC',
  EmailMessagesByContactIdSumIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_ID_DESC',
  EmailMessagesByContactIdSumIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_INTENT_ASC',
  EmailMessagesByContactIdSumIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_INTENT_DESC',
  EmailMessagesByContactIdSumIsLawyerAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_IS_LAWYER_ASC',
  EmailMessagesByContactIdSumIsLawyerDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_IS_LAWYER_DESC',
  EmailMessagesByContactIdSumIsReviewedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_IS_REVIEWED_ASC',
  EmailMessagesByContactIdSumIsReviewedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_IS_REVIEWED_DESC',
  EmailMessagesByContactIdSumLawyerIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_LAWYER_ID_ASC',
  EmailMessagesByContactIdSumLawyerIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_LAWYER_ID_DESC',
  EmailMessagesByContactIdSumLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByContactIdSumLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByContactIdSumNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_NYLAS_THREAD_ID_ASC',
  EmailMessagesByContactIdSumNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_NYLAS_THREAD_ID_DESC',
  EmailMessagesByContactIdSumOrderIndexAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_ORDER_INDEX_ASC',
  EmailMessagesByContactIdSumOrderIndexDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_ORDER_INDEX_DESC',
  EmailMessagesByContactIdSumPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByContactIdSumPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByContactIdSumRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_REPLIED_INTENT_ASC',
  EmailMessagesByContactIdSumRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_REPLIED_INTENT_DESC',
  EmailMessagesByContactIdSumRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_REPLIED_TIME_ASC',
  EmailMessagesByContactIdSumRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_REPLIED_TIME_DESC',
  EmailMessagesByContactIdSumRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdSumRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdSumReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_REVIEWED_TIME_ASC',
  EmailMessagesByContactIdSumReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_REVIEWED_TIME_DESC',
  EmailMessagesByContactIdSumSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_SENDER_PROFILE_ID_ASC',
  EmailMessagesByContactIdSumSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_SENDER_PROFILE_ID_DESC',
  EmailMessagesByContactIdSumSentTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_SENT_TIME_ASC',
  EmailMessagesByContactIdSumSentTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_SENT_TIME_DESC',
  EmailMessagesByContactIdSumSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByContactIdSumSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByContactIdSumSubjectAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_SUBJECT_ASC',
  EmailMessagesByContactIdSumSubjectDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_SUBJECT_DESC',
  EmailMessagesByContactIdSumTemplateIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_TEMPLATE_ID_ASC',
  EmailMessagesByContactIdSumTemplateIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_TEMPLATE_ID_DESC',
  EmailMessagesByContactIdSumUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_UPDATED_TIME_ASC',
  EmailMessagesByContactIdSumUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_SUM_UPDATED_TIME_DESC',
  EmailMessagesByContactIdVariancePopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByContactIdVariancePopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByContactIdVariancePopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByContactIdVariancePopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByContactIdVariancePopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByContactIdVariancePopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByContactIdVariancePopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdVariancePopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdVariancePopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByContactIdVariancePopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByContactIdVariancePopulationContactIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByContactIdVariancePopulationContactIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByContactIdVariancePopulationContentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CONTENT_ASC',
  EmailMessagesByContactIdVariancePopulationContentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CONTENT_DESC',
  EmailMessagesByContactIdVariancePopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByContactIdVariancePopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByContactIdVariancePopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByContactIdVariancePopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByContactIdVariancePopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByContactIdVariancePopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByContactIdVariancePopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByContactIdVariancePopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByContactIdVariancePopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByContactIdVariancePopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByContactIdVariancePopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByContactIdVariancePopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByContactIdVariancePopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByContactIdVariancePopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByContactIdVariancePopulationIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  EmailMessagesByContactIdVariancePopulationIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  EmailMessagesByContactIdVariancePopulationIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_INTENT_ASC',
  EmailMessagesByContactIdVariancePopulationIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_INTENT_DESC',
  EmailMessagesByContactIdVariancePopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByContactIdVariancePopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByContactIdVariancePopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByContactIdVariancePopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByContactIdVariancePopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByContactIdVariancePopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByContactIdVariancePopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByContactIdVariancePopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByContactIdVariancePopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByContactIdVariancePopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByContactIdVariancePopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByContactIdVariancePopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByContactIdVariancePopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByContactIdVariancePopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByContactIdVariancePopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByContactIdVariancePopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByContactIdVariancePopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByContactIdVariancePopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByContactIdVariancePopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdVariancePopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdVariancePopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByContactIdVariancePopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByContactIdVariancePopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByContactIdVariancePopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByContactIdVariancePopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_SENT_TIME_ASC',
  EmailMessagesByContactIdVariancePopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_SENT_TIME_DESC',
  EmailMessagesByContactIdVariancePopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByContactIdVariancePopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByContactIdVariancePopulationSubjectAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_SUBJECT_ASC',
  EmailMessagesByContactIdVariancePopulationSubjectDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_SUBJECT_DESC',
  EmailMessagesByContactIdVariancePopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByContactIdVariancePopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByContactIdVariancePopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByContactIdVariancePopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByContactIdVarianceSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByContactIdVarianceSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByContactIdVarianceSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByContactIdVarianceSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByContactIdVarianceSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByContactIdVarianceSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByContactIdVarianceSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdVarianceSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdVarianceSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByContactIdVarianceSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByContactIdVarianceSampleContactIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByContactIdVarianceSampleContactIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByContactIdVarianceSampleContentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  EmailMessagesByContactIdVarianceSampleContentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  EmailMessagesByContactIdVarianceSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByContactIdVarianceSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByContactIdVarianceSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByContactIdVarianceSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByContactIdVarianceSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByContactIdVarianceSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByContactIdVarianceSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByContactIdVarianceSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByContactIdVarianceSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByContactIdVarianceSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByContactIdVarianceSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByContactIdVarianceSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByContactIdVarianceSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByContactIdVarianceSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByContactIdVarianceSampleIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailMessagesByContactIdVarianceSampleIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailMessagesByContactIdVarianceSampleIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_INTENT_ASC',
  EmailMessagesByContactIdVarianceSampleIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_INTENT_DESC',
  EmailMessagesByContactIdVarianceSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByContactIdVarianceSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByContactIdVarianceSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByContactIdVarianceSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByContactIdVarianceSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByContactIdVarianceSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByContactIdVarianceSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByContactIdVarianceSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByContactIdVarianceSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByContactIdVarianceSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByContactIdVarianceSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByContactIdVarianceSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByContactIdVarianceSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByContactIdVarianceSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByContactIdVarianceSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByContactIdVarianceSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByContactIdVarianceSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByContactIdVarianceSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByContactIdVarianceSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByContactIdVarianceSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByContactIdVarianceSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByContactIdVarianceSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByContactIdVarianceSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByContactIdVarianceSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByContactIdVarianceSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByContactIdVarianceSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByContactIdVarianceSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByContactIdVarianceSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByContactIdVarianceSampleSubjectAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_SUBJECT_ASC',
  EmailMessagesByContactIdVarianceSampleSubjectDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_SUBJECT_DESC',
  EmailMessagesByContactIdVarianceSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByContactIdVarianceSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByContactIdVarianceSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByContactIdVarianceSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  FacebookUrlAsc = 'FACEBOOK_URL_ASC',
  FacebookUrlDesc = 'FACEBOOK_URL_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  HubspotContactsByContactIdAverageContactIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  HubspotContactsByContactIdAverageContactIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  HubspotContactsByContactIdAverageCreatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  HubspotContactsByContactIdAverageCreatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  HubspotContactsByContactIdAverageHubspotIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_AVERAGE_HUBSPOT_ID_ASC',
  HubspotContactsByContactIdAverageHubspotIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_AVERAGE_HUBSPOT_ID_DESC',
  HubspotContactsByContactIdAverageIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_AVERAGE_ID_ASC',
  HubspotContactsByContactIdAverageIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_AVERAGE_ID_DESC',
  HubspotContactsByContactIdAverageUpdatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_ASC',
  HubspotContactsByContactIdAverageUpdatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_DESC',
  HubspotContactsByContactIdCountAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_COUNT_ASC',
  HubspotContactsByContactIdCountDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_COUNT_DESC',
  HubspotContactsByContactIdDistinctCountContactIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  HubspotContactsByContactIdDistinctCountContactIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  HubspotContactsByContactIdDistinctCountCreatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  HubspotContactsByContactIdDistinctCountCreatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  HubspotContactsByContactIdDistinctCountHubspotIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_DISTINCT_COUNT_HUBSPOT_ID_ASC',
  HubspotContactsByContactIdDistinctCountHubspotIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_DISTINCT_COUNT_HUBSPOT_ID_DESC',
  HubspotContactsByContactIdDistinctCountIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  HubspotContactsByContactIdDistinctCountIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  HubspotContactsByContactIdDistinctCountUpdatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  HubspotContactsByContactIdDistinctCountUpdatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  HubspotContactsByContactIdMaxContactIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  HubspotContactsByContactIdMaxContactIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  HubspotContactsByContactIdMaxCreatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  HubspotContactsByContactIdMaxCreatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  HubspotContactsByContactIdMaxHubspotIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MAX_HUBSPOT_ID_ASC',
  HubspotContactsByContactIdMaxHubspotIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MAX_HUBSPOT_ID_DESC',
  HubspotContactsByContactIdMaxIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MAX_ID_ASC',
  HubspotContactsByContactIdMaxIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MAX_ID_DESC',
  HubspotContactsByContactIdMaxUpdatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MAX_UPDATED_TIME_ASC',
  HubspotContactsByContactIdMaxUpdatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MAX_UPDATED_TIME_DESC',
  HubspotContactsByContactIdMinContactIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  HubspotContactsByContactIdMinContactIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  HubspotContactsByContactIdMinCreatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  HubspotContactsByContactIdMinCreatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  HubspotContactsByContactIdMinHubspotIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MIN_HUBSPOT_ID_ASC',
  HubspotContactsByContactIdMinHubspotIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MIN_HUBSPOT_ID_DESC',
  HubspotContactsByContactIdMinIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MIN_ID_ASC',
  HubspotContactsByContactIdMinIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MIN_ID_DESC',
  HubspotContactsByContactIdMinUpdatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MIN_UPDATED_TIME_ASC',
  HubspotContactsByContactIdMinUpdatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_MIN_UPDATED_TIME_DESC',
  HubspotContactsByContactIdStddevPopulationContactIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  HubspotContactsByContactIdStddevPopulationContactIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  HubspotContactsByContactIdStddevPopulationCreatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  HubspotContactsByContactIdStddevPopulationCreatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  HubspotContactsByContactIdStddevPopulationHubspotIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_POPULATION_HUBSPOT_ID_ASC',
  HubspotContactsByContactIdStddevPopulationHubspotIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_POPULATION_HUBSPOT_ID_DESC',
  HubspotContactsByContactIdStddevPopulationIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  HubspotContactsByContactIdStddevPopulationIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  HubspotContactsByContactIdStddevPopulationUpdatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  HubspotContactsByContactIdStddevPopulationUpdatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  HubspotContactsByContactIdStddevSampleContactIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  HubspotContactsByContactIdStddevSampleContactIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  HubspotContactsByContactIdStddevSampleCreatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  HubspotContactsByContactIdStddevSampleCreatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  HubspotContactsByContactIdStddevSampleHubspotIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_SAMPLE_HUBSPOT_ID_ASC',
  HubspotContactsByContactIdStddevSampleHubspotIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_SAMPLE_HUBSPOT_ID_DESC',
  HubspotContactsByContactIdStddevSampleIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  HubspotContactsByContactIdStddevSampleIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  HubspotContactsByContactIdStddevSampleUpdatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  HubspotContactsByContactIdStddevSampleUpdatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  HubspotContactsByContactIdSumContactIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  HubspotContactsByContactIdSumContactIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  HubspotContactsByContactIdSumCreatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  HubspotContactsByContactIdSumCreatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  HubspotContactsByContactIdSumHubspotIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_SUM_HUBSPOT_ID_ASC',
  HubspotContactsByContactIdSumHubspotIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_SUM_HUBSPOT_ID_DESC',
  HubspotContactsByContactIdSumIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_SUM_ID_ASC',
  HubspotContactsByContactIdSumIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_SUM_ID_DESC',
  HubspotContactsByContactIdSumUpdatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_SUM_UPDATED_TIME_ASC',
  HubspotContactsByContactIdSumUpdatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_SUM_UPDATED_TIME_DESC',
  HubspotContactsByContactIdVariancePopulationContactIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  HubspotContactsByContactIdVariancePopulationContactIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  HubspotContactsByContactIdVariancePopulationCreatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  HubspotContactsByContactIdVariancePopulationCreatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  HubspotContactsByContactIdVariancePopulationHubspotIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_POPULATION_HUBSPOT_ID_ASC',
  HubspotContactsByContactIdVariancePopulationHubspotIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_POPULATION_HUBSPOT_ID_DESC',
  HubspotContactsByContactIdVariancePopulationIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  HubspotContactsByContactIdVariancePopulationIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  HubspotContactsByContactIdVariancePopulationUpdatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  HubspotContactsByContactIdVariancePopulationUpdatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  HubspotContactsByContactIdVarianceSampleContactIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  HubspotContactsByContactIdVarianceSampleContactIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  HubspotContactsByContactIdVarianceSampleCreatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  HubspotContactsByContactIdVarianceSampleCreatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  HubspotContactsByContactIdVarianceSampleHubspotIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_SAMPLE_HUBSPOT_ID_ASC',
  HubspotContactsByContactIdVarianceSampleHubspotIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_SAMPLE_HUBSPOT_ID_DESC',
  HubspotContactsByContactIdVarianceSampleIdAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  HubspotContactsByContactIdVarianceSampleIdDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  HubspotContactsByContactIdVarianceSampleUpdatedTimeAsc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  HubspotContactsByContactIdVarianceSampleUpdatedTimeDesc = 'HUBSPOT_CONTACTS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LastEnrichedAsc = 'LAST_ENRICHED_ASC',
  LastEnrichedDesc = 'LAST_ENRICHED_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  LawyersByContactIdAverageAcquisitionChannelAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ACQUISITION_CHANNEL_ASC',
  LawyersByContactIdAverageAcquisitionChannelDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ACQUISITION_CHANNEL_DESC',
  LawyersByContactIdAverageAcquisitionTypeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ACQUISITION_TYPE_ASC',
  LawyersByContactIdAverageAcquisitionTypeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ACQUISITION_TYPE_DESC',
  LawyersByContactIdAverageAliasesAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ALIASES_ASC',
  LawyersByContactIdAverageAliasesDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ALIASES_DESC',
  LawyersByContactIdAverageBrandColorAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_BRAND_COLOR_ASC',
  LawyersByContactIdAverageBrandColorDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_BRAND_COLOR_DESC',
  LawyersByContactIdAverageCalendlyLinkAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CALENDLY_LINK_ASC',
  LawyersByContactIdAverageCalendlyLinkDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CALENDLY_LINK_DESC',
  LawyersByContactIdAverageCalendlyUriAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CALENDLY_URI_ASC',
  LawyersByContactIdAverageCalendlyUriDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CALENDLY_URI_DESC',
  LawyersByContactIdAverageChargedPerAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CHARGED_PER_ASC',
  LawyersByContactIdAverageChargedPerDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CHARGED_PER_DESC',
  LawyersByContactIdAverageChargeRateAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CHARGE_RATE_ASC',
  LawyersByContactIdAverageChargeRateDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CHARGE_RATE_DESC',
  LawyersByContactIdAverageCityAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CITY_ASC',
  LawyersByContactIdAverageCityDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CITY_DESC',
  LawyersByContactIdAverageClientProfileRequirementsAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByContactIdAverageClientProfileRequirementsDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByContactIdAverageContactIdAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  LawyersByContactIdAverageContactIdDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  LawyersByContactIdAverageCountryAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_COUNTRY_ASC',
  LawyersByContactIdAverageCountryCodeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_COUNTRY_CODE_ASC',
  LawyersByContactIdAverageCountryCodeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_COUNTRY_CODE_DESC',
  LawyersByContactIdAverageCountryDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_COUNTRY_DESC',
  LawyersByContactIdAverageCreatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  LawyersByContactIdAverageCreatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  LawyersByContactIdAverageEmailAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_EMAIL_ASC',
  LawyersByContactIdAverageEmailDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_EMAIL_DESC',
  LawyersByContactIdAverageEnableAutoTestimonialAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByContactIdAverageEnableAutoTestimonialDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByContactIdAverageExternalContactsLastUpdatedAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByContactIdAverageExternalContactsLastUpdatedDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByContactIdAverageFirmDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_FIRM_DESCRIPTION_ASC',
  LawyersByContactIdAverageFirmDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_FIRM_DESCRIPTION_DESC',
  LawyersByContactIdAverageFirmNameAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_FIRM_NAME_ASC',
  LawyersByContactIdAverageFirmNameDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_FIRM_NAME_DESC',
  LawyersByContactIdAverageFreeLeadsAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_FREE_LEADS_ASC',
  LawyersByContactIdAverageFreeLeadsDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_FREE_LEADS_DESC',
  LawyersByContactIdAverageGooglePlaceIdAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_GOOGLE_PLACE_ID_ASC',
  LawyersByContactIdAverageGooglePlaceIdDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_GOOGLE_PLACE_ID_DESC',
  LawyersByContactIdAverageHandoverMethodAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_HANDOVER_METHOD_ASC',
  LawyersByContactIdAverageHandoverMethodDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_HANDOVER_METHOD_DESC',
  LawyersByContactIdAverageHandoverStateAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_HANDOVER_STATE_ASC',
  LawyersByContactIdAverageHandoverStateDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_HANDOVER_STATE_DESC',
  LawyersByContactIdAverageHubspotDealIdAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_HUBSPOT_DEAL_ID_ASC',
  LawyersByContactIdAverageHubspotDealIdDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_HUBSPOT_DEAL_ID_DESC',
  LawyersByContactIdAverageIcpAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ICP_ASC',
  LawyersByContactIdAverageIcpDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ICP_DESC',
  LawyersByContactIdAverageIcpGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ICP_GUESSED_TIME_ASC',
  LawyersByContactIdAverageIcpGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ICP_GUESSED_TIME_DESC',
  LawyersByContactIdAverageIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByContactIdAverageIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByContactIdAverageIdAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ID_ASC',
  LawyersByContactIdAverageIdDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ID_DESC',
  LawyersByContactIdAverageIsManagedAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_IS_MANAGED_ASC',
  LawyersByContactIdAverageIsManagedDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_IS_MANAGED_DESC',
  LawyersByContactIdAverageIsPausedAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_IS_PAUSED_ASC',
  LawyersByContactIdAverageIsPausedDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_IS_PAUSED_DESC',
  LawyersByContactIdAverageLastAccountReviewAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByContactIdAverageLastAccountReviewDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByContactIdAverageLastReportTimeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LAST_REPORT_TIME_ASC',
  LawyersByContactIdAverageLastReportTimeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LAST_REPORT_TIME_DESC',
  LawyersByContactIdAverageLastStatusEmailTimeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByContactIdAverageLastStatusEmailTimeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByContactIdAverageLawyerDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LAWYER_DESCRIPTION_ASC',
  LawyersByContactIdAverageLawyerDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LAWYER_DESCRIPTION_DESC',
  LawyersByContactIdAverageLeadsPerMonthAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LEADS_PER_MONTH_ASC',
  LawyersByContactIdAverageLeadsPerMonthDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LEADS_PER_MONTH_DESC',
  LawyersByContactIdAverageLeverAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LEVER_ASC',
  LawyersByContactIdAverageLeverDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LEVER_DESC',
  LawyersByContactIdAverageLinkedinUrlAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LINKEDIN_URL_ASC',
  LawyersByContactIdAverageLinkedinUrlDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LINKEDIN_URL_DESC',
  LawyersByContactIdAverageLiEmailAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_EMAIL_ASC',
  LawyersByContactIdAverageLiEmailDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_EMAIL_DESC',
  LawyersByContactIdAverageLiEncryptedPwAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_ENCRYPTED_PW_ASC',
  LawyersByContactIdAverageLiEncryptedPwDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_ENCRYPTED_PW_DESC',
  LawyersByContactIdAverageLiLastActiveTimeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByContactIdAverageLiLastActiveTimeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByContactIdAverageLiLoginStatusAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_LOGIN_STATUS_ASC',
  LawyersByContactIdAverageLiLoginStatusDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_LOGIN_STATUS_DESC',
  LawyersByContactIdAverageLiProfileNameAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_PROFILE_NAME_ASC',
  LawyersByContactIdAverageLiProfileNameDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_PROFILE_NAME_DESC',
  LawyersByContactIdAverageLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByContactIdAverageLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByContactIdAverageLiVerificationCodeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_VERIFICATION_CODE_ASC',
  LawyersByContactIdAverageLiVerificationCodeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LI_VERIFICATION_CODE_DESC',
  LawyersByContactIdAverageLogoSrcAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LOGO_SRC_ASC',
  LawyersByContactIdAverageLogoSrcDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_LOGO_SRC_DESC',
  LawyersByContactIdAverageNameAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_NAME_ASC',
  LawyersByContactIdAverageNameDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_NAME_DESC',
  LawyersByContactIdAverageNylasAccessTokenAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByContactIdAverageNylasAccessTokenDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByContactIdAverageOnboardingFeePaidTimeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByContactIdAverageOnboardingFeePaidTimeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByContactIdAverageOnboardingTypeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ONBOARDING_TYPE_ASC',
  LawyersByContactIdAverageOnboardingTypeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ONBOARDING_TYPE_DESC',
  LawyersByContactIdAverageOrganizationIdAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ORGANIZATION_ID_ASC',
  LawyersByContactIdAverageOrganizationIdDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_ORGANIZATION_ID_DESC',
  LawyersByContactIdAveragePaymentMethodAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_PAYMENT_METHOD_ASC',
  LawyersByContactIdAveragePaymentMethodDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_PAYMENT_METHOD_DESC',
  LawyersByContactIdAveragePersonalisationRankingsAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_PERSONALISATION_RANKINGS_ASC',
  LawyersByContactIdAveragePersonalisationRankingsDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_PERSONALISATION_RANKINGS_DESC',
  LawyersByContactIdAveragePostalCodeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_POSTAL_CODE_ASC',
  LawyersByContactIdAveragePostalCodeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_POSTAL_CODE_DESC',
  LawyersByContactIdAveragePriorityAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_PRIORITY_ASC',
  LawyersByContactIdAveragePriorityDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_PRIORITY_DESC',
  LawyersByContactIdAverageProxyIdAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_PROXY_ID_ASC',
  LawyersByContactIdAverageProxyIdDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_PROXY_ID_DESC',
  LawyersByContactIdAveragePublicIdAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_PUBLIC_ID_ASC',
  LawyersByContactIdAveragePublicIdDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_PUBLIC_ID_DESC',
  LawyersByContactIdAverageReplyNotesAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_REPLY_NOTES_ASC',
  LawyersByContactIdAverageReplyNotesDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_REPLY_NOTES_DESC',
  LawyersByContactIdAverageSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByContactIdAverageSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByContactIdAverageSenderDisclaimerAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_SENDER_DISCLAIMER_ASC',
  LawyersByContactIdAverageSenderDisclaimerDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_SENDER_DISCLAIMER_DESC',
  LawyersByContactIdAverageSignatureHtmlAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_SIGNATURE_HTML_ASC',
  LawyersByContactIdAverageSignatureHtmlDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_SIGNATURE_HTML_DESC',
  LawyersByContactIdAverageSlackChannelNameAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_SLACK_CHANNEL_NAME_ASC',
  LawyersByContactIdAverageSlackChannelNameDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_SLACK_CHANNEL_NAME_DESC',
  LawyersByContactIdAverageSlackClientIdAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_SLACK_CLIENT_ID_ASC',
  LawyersByContactIdAverageSlackClientIdDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_SLACK_CLIENT_ID_DESC',
  LawyersByContactIdAverageSlackClientSecretAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_SLACK_CLIENT_SECRET_ASC',
  LawyersByContactIdAverageSlackClientSecretDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_SLACK_CLIENT_SECRET_DESC',
  LawyersByContactIdAverageStageAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STAGE_ASC',
  LawyersByContactIdAverageStageDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STAGE_DESC',
  LawyersByContactIdAverageStateAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STATE_ASC',
  LawyersByContactIdAverageStateDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STATE_DESC',
  LawyersByContactIdAverageStatusAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STATUS_ASC',
  LawyersByContactIdAverageStatusDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STATUS_DESC',
  LawyersByContactIdAverageStreetAddressAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STREET_ADDRESS_ASC',
  LawyersByContactIdAverageStreetAddressDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STREET_ADDRESS_DESC',
  LawyersByContactIdAverageStripeCustomerIdAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STRIPE_CUSTOMER_ID_ASC',
  LawyersByContactIdAverageStripeCustomerIdDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STRIPE_CUSTOMER_ID_DESC',
  LawyersByContactIdAverageStripeDiscountCodeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByContactIdAverageStripeDiscountCodeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByContactIdAverageStripePriceIdAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STRIPE_PRICE_ID_ASC',
  LawyersByContactIdAverageStripePriceIdDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_STRIPE_PRICE_ID_DESC',
  LawyersByContactIdAverageTimezoneAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_TIMEZONE_ASC',
  LawyersByContactIdAverageTimezoneDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_TIMEZONE_DESC',
  LawyersByContactIdAverageTitleAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_TITLE_ASC',
  LawyersByContactIdAverageTitleDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_TITLE_DESC',
  LawyersByContactIdAverageUpdatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_ASC',
  LawyersByContactIdAverageUpdatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_DESC',
  LawyersByContactIdAverageUserTypeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_USER_TYPE_ASC',
  LawyersByContactIdAverageUserTypeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_USER_TYPE_DESC',
  LawyersByContactIdAverageValuePropositionAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_VALUE_PROPOSITION_ASC',
  LawyersByContactIdAverageValuePropositionDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_VALUE_PROPOSITION_DESC',
  LawyersByContactIdAverageValuePropositionGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByContactIdAverageValuePropositionGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByContactIdAverageWantsToChooseDomainsAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByContactIdAverageWantsToChooseDomainsDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByContactIdAverageWebsiteUrlAsc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_WEBSITE_URL_ASC',
  LawyersByContactIdAverageWebsiteUrlDesc = 'LAWYERS_BY_CONTACT_ID_AVERAGE_WEBSITE_URL_DESC',
  LawyersByContactIdCountAsc = 'LAWYERS_BY_CONTACT_ID_COUNT_ASC',
  LawyersByContactIdCountDesc = 'LAWYERS_BY_CONTACT_ID_COUNT_DESC',
  LawyersByContactIdDistinctCountAcquisitionChannelAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ACQUISITION_CHANNEL_ASC',
  LawyersByContactIdDistinctCountAcquisitionChannelDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ACQUISITION_CHANNEL_DESC',
  LawyersByContactIdDistinctCountAcquisitionTypeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ACQUISITION_TYPE_ASC',
  LawyersByContactIdDistinctCountAcquisitionTypeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ACQUISITION_TYPE_DESC',
  LawyersByContactIdDistinctCountAliasesAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ALIASES_ASC',
  LawyersByContactIdDistinctCountAliasesDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ALIASES_DESC',
  LawyersByContactIdDistinctCountBrandColorAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_BRAND_COLOR_ASC',
  LawyersByContactIdDistinctCountBrandColorDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_BRAND_COLOR_DESC',
  LawyersByContactIdDistinctCountCalendlyLinkAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CALENDLY_LINK_ASC',
  LawyersByContactIdDistinctCountCalendlyLinkDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CALENDLY_LINK_DESC',
  LawyersByContactIdDistinctCountCalendlyUriAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CALENDLY_URI_ASC',
  LawyersByContactIdDistinctCountCalendlyUriDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CALENDLY_URI_DESC',
  LawyersByContactIdDistinctCountChargedPerAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CHARGED_PER_ASC',
  LawyersByContactIdDistinctCountChargedPerDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CHARGED_PER_DESC',
  LawyersByContactIdDistinctCountChargeRateAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CHARGE_RATE_ASC',
  LawyersByContactIdDistinctCountChargeRateDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CHARGE_RATE_DESC',
  LawyersByContactIdDistinctCountCityAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CITY_ASC',
  LawyersByContactIdDistinctCountCityDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CITY_DESC',
  LawyersByContactIdDistinctCountClientProfileRequirementsAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByContactIdDistinctCountClientProfileRequirementsDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByContactIdDistinctCountContactIdAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  LawyersByContactIdDistinctCountContactIdDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  LawyersByContactIdDistinctCountCountryAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_COUNTRY_ASC',
  LawyersByContactIdDistinctCountCountryCodeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_COUNTRY_CODE_ASC',
  LawyersByContactIdDistinctCountCountryCodeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_COUNTRY_CODE_DESC',
  LawyersByContactIdDistinctCountCountryDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_COUNTRY_DESC',
  LawyersByContactIdDistinctCountCreatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LawyersByContactIdDistinctCountCreatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LawyersByContactIdDistinctCountEmailAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_EMAIL_ASC',
  LawyersByContactIdDistinctCountEmailDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_EMAIL_DESC',
  LawyersByContactIdDistinctCountEnableAutoTestimonialAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByContactIdDistinctCountEnableAutoTestimonialDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByContactIdDistinctCountExternalContactsLastUpdatedAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByContactIdDistinctCountExternalContactsLastUpdatedDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByContactIdDistinctCountFirmDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_FIRM_DESCRIPTION_ASC',
  LawyersByContactIdDistinctCountFirmDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_FIRM_DESCRIPTION_DESC',
  LawyersByContactIdDistinctCountFirmNameAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_FIRM_NAME_ASC',
  LawyersByContactIdDistinctCountFirmNameDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_FIRM_NAME_DESC',
  LawyersByContactIdDistinctCountFreeLeadsAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_FREE_LEADS_ASC',
  LawyersByContactIdDistinctCountFreeLeadsDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_FREE_LEADS_DESC',
  LawyersByContactIdDistinctCountGooglePlaceIdAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_GOOGLE_PLACE_ID_ASC',
  LawyersByContactIdDistinctCountGooglePlaceIdDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_GOOGLE_PLACE_ID_DESC',
  LawyersByContactIdDistinctCountHandoverMethodAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_HANDOVER_METHOD_ASC',
  LawyersByContactIdDistinctCountHandoverMethodDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_HANDOVER_METHOD_DESC',
  LawyersByContactIdDistinctCountHandoverStateAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_HANDOVER_STATE_ASC',
  LawyersByContactIdDistinctCountHandoverStateDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_HANDOVER_STATE_DESC',
  LawyersByContactIdDistinctCountHubspotDealIdAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_HUBSPOT_DEAL_ID_ASC',
  LawyersByContactIdDistinctCountHubspotDealIdDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_HUBSPOT_DEAL_ID_DESC',
  LawyersByContactIdDistinctCountIcpAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ICP_ASC',
  LawyersByContactIdDistinctCountIcpDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ICP_DESC',
  LawyersByContactIdDistinctCountIcpGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ICP_GUESSED_TIME_ASC',
  LawyersByContactIdDistinctCountIcpGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ICP_GUESSED_TIME_DESC',
  LawyersByContactIdDistinctCountIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByContactIdDistinctCountIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByContactIdDistinctCountIdAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  LawyersByContactIdDistinctCountIdDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  LawyersByContactIdDistinctCountIsManagedAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_IS_MANAGED_ASC',
  LawyersByContactIdDistinctCountIsManagedDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_IS_MANAGED_DESC',
  LawyersByContactIdDistinctCountIsPausedAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_IS_PAUSED_ASC',
  LawyersByContactIdDistinctCountIsPausedDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_IS_PAUSED_DESC',
  LawyersByContactIdDistinctCountLastAccountReviewAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByContactIdDistinctCountLastAccountReviewDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByContactIdDistinctCountLastReportTimeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LAST_REPORT_TIME_ASC',
  LawyersByContactIdDistinctCountLastReportTimeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LAST_REPORT_TIME_DESC',
  LawyersByContactIdDistinctCountLastStatusEmailTimeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByContactIdDistinctCountLastStatusEmailTimeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByContactIdDistinctCountLawyerDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_DESCRIPTION_ASC',
  LawyersByContactIdDistinctCountLawyerDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_DESCRIPTION_DESC',
  LawyersByContactIdDistinctCountLeadsPerMonthAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LEADS_PER_MONTH_ASC',
  LawyersByContactIdDistinctCountLeadsPerMonthDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LEADS_PER_MONTH_DESC',
  LawyersByContactIdDistinctCountLeverAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LEVER_ASC',
  LawyersByContactIdDistinctCountLeverDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LEVER_DESC',
  LawyersByContactIdDistinctCountLinkedinUrlAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LINKEDIN_URL_ASC',
  LawyersByContactIdDistinctCountLinkedinUrlDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LINKEDIN_URL_DESC',
  LawyersByContactIdDistinctCountLiEmailAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_EMAIL_ASC',
  LawyersByContactIdDistinctCountLiEmailDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_EMAIL_DESC',
  LawyersByContactIdDistinctCountLiEncryptedPwAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_ENCRYPTED_PW_ASC',
  LawyersByContactIdDistinctCountLiEncryptedPwDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_ENCRYPTED_PW_DESC',
  LawyersByContactIdDistinctCountLiLastActiveTimeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByContactIdDistinctCountLiLastActiveTimeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByContactIdDistinctCountLiLoginStatusAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_LOGIN_STATUS_ASC',
  LawyersByContactIdDistinctCountLiLoginStatusDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_LOGIN_STATUS_DESC',
  LawyersByContactIdDistinctCountLiProfileNameAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_PROFILE_NAME_ASC',
  LawyersByContactIdDistinctCountLiProfileNameDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_PROFILE_NAME_DESC',
  LawyersByContactIdDistinctCountLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByContactIdDistinctCountLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByContactIdDistinctCountLiVerificationCodeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_VERIFICATION_CODE_ASC',
  LawyersByContactIdDistinctCountLiVerificationCodeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LI_VERIFICATION_CODE_DESC',
  LawyersByContactIdDistinctCountLogoSrcAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LOGO_SRC_ASC',
  LawyersByContactIdDistinctCountLogoSrcDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_LOGO_SRC_DESC',
  LawyersByContactIdDistinctCountNameAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_NAME_ASC',
  LawyersByContactIdDistinctCountNameDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_NAME_DESC',
  LawyersByContactIdDistinctCountNylasAccessTokenAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByContactIdDistinctCountNylasAccessTokenDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByContactIdDistinctCountOnboardingFeePaidTimeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByContactIdDistinctCountOnboardingFeePaidTimeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByContactIdDistinctCountOnboardingTypeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ONBOARDING_TYPE_ASC',
  LawyersByContactIdDistinctCountOnboardingTypeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ONBOARDING_TYPE_DESC',
  LawyersByContactIdDistinctCountOrganizationIdAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ORGANIZATION_ID_ASC',
  LawyersByContactIdDistinctCountOrganizationIdDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_ORGANIZATION_ID_DESC',
  LawyersByContactIdDistinctCountPaymentMethodAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_PAYMENT_METHOD_ASC',
  LawyersByContactIdDistinctCountPaymentMethodDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_PAYMENT_METHOD_DESC',
  LawyersByContactIdDistinctCountPersonalisationRankingsAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_PERSONALISATION_RANKINGS_ASC',
  LawyersByContactIdDistinctCountPersonalisationRankingsDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_PERSONALISATION_RANKINGS_DESC',
  LawyersByContactIdDistinctCountPostalCodeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_POSTAL_CODE_ASC',
  LawyersByContactIdDistinctCountPostalCodeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_POSTAL_CODE_DESC',
  LawyersByContactIdDistinctCountPriorityAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_PRIORITY_ASC',
  LawyersByContactIdDistinctCountPriorityDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_PRIORITY_DESC',
  LawyersByContactIdDistinctCountProxyIdAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_PROXY_ID_ASC',
  LawyersByContactIdDistinctCountProxyIdDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_PROXY_ID_DESC',
  LawyersByContactIdDistinctCountPublicIdAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_PUBLIC_ID_ASC',
  LawyersByContactIdDistinctCountPublicIdDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_PUBLIC_ID_DESC',
  LawyersByContactIdDistinctCountReplyNotesAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_REPLY_NOTES_ASC',
  LawyersByContactIdDistinctCountReplyNotesDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_REPLY_NOTES_DESC',
  LawyersByContactIdDistinctCountSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByContactIdDistinctCountSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByContactIdDistinctCountSenderDisclaimerAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_SENDER_DISCLAIMER_ASC',
  LawyersByContactIdDistinctCountSenderDisclaimerDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_SENDER_DISCLAIMER_DESC',
  LawyersByContactIdDistinctCountSignatureHtmlAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_SIGNATURE_HTML_ASC',
  LawyersByContactIdDistinctCountSignatureHtmlDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_SIGNATURE_HTML_DESC',
  LawyersByContactIdDistinctCountSlackChannelNameAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_CHANNEL_NAME_ASC',
  LawyersByContactIdDistinctCountSlackChannelNameDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_CHANNEL_NAME_DESC',
  LawyersByContactIdDistinctCountSlackClientIdAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_CLIENT_ID_ASC',
  LawyersByContactIdDistinctCountSlackClientIdDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_CLIENT_ID_DESC',
  LawyersByContactIdDistinctCountSlackClientSecretAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_CLIENT_SECRET_ASC',
  LawyersByContactIdDistinctCountSlackClientSecretDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_CLIENT_SECRET_DESC',
  LawyersByContactIdDistinctCountStageAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STAGE_ASC',
  LawyersByContactIdDistinctCountStageDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STAGE_DESC',
  LawyersByContactIdDistinctCountStateAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STATE_ASC',
  LawyersByContactIdDistinctCountStateDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STATE_DESC',
  LawyersByContactIdDistinctCountStatusAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STATUS_ASC',
  LawyersByContactIdDistinctCountStatusDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STATUS_DESC',
  LawyersByContactIdDistinctCountStreetAddressAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STREET_ADDRESS_ASC',
  LawyersByContactIdDistinctCountStreetAddressDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STREET_ADDRESS_DESC',
  LawyersByContactIdDistinctCountStripeCustomerIdAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STRIPE_CUSTOMER_ID_ASC',
  LawyersByContactIdDistinctCountStripeCustomerIdDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STRIPE_CUSTOMER_ID_DESC',
  LawyersByContactIdDistinctCountStripeDiscountCodeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByContactIdDistinctCountStripeDiscountCodeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByContactIdDistinctCountStripePriceIdAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STRIPE_PRICE_ID_ASC',
  LawyersByContactIdDistinctCountStripePriceIdDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_STRIPE_PRICE_ID_DESC',
  LawyersByContactIdDistinctCountTimezoneAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_TIMEZONE_ASC',
  LawyersByContactIdDistinctCountTimezoneDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_TIMEZONE_DESC',
  LawyersByContactIdDistinctCountTitleAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_TITLE_ASC',
  LawyersByContactIdDistinctCountTitleDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_TITLE_DESC',
  LawyersByContactIdDistinctCountUpdatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  LawyersByContactIdDistinctCountUpdatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  LawyersByContactIdDistinctCountUserTypeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_USER_TYPE_ASC',
  LawyersByContactIdDistinctCountUserTypeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_USER_TYPE_DESC',
  LawyersByContactIdDistinctCountValuePropositionAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_VALUE_PROPOSITION_ASC',
  LawyersByContactIdDistinctCountValuePropositionDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_VALUE_PROPOSITION_DESC',
  LawyersByContactIdDistinctCountValuePropositionGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByContactIdDistinctCountValuePropositionGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByContactIdDistinctCountWantsToChooseDomainsAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByContactIdDistinctCountWantsToChooseDomainsDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByContactIdDistinctCountWebsiteUrlAsc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_WEBSITE_URL_ASC',
  LawyersByContactIdDistinctCountWebsiteUrlDesc = 'LAWYERS_BY_CONTACT_ID_DISTINCT_COUNT_WEBSITE_URL_DESC',
  LawyersByContactIdMaxAcquisitionChannelAsc = 'LAWYERS_BY_CONTACT_ID_MAX_ACQUISITION_CHANNEL_ASC',
  LawyersByContactIdMaxAcquisitionChannelDesc = 'LAWYERS_BY_CONTACT_ID_MAX_ACQUISITION_CHANNEL_DESC',
  LawyersByContactIdMaxAcquisitionTypeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_ACQUISITION_TYPE_ASC',
  LawyersByContactIdMaxAcquisitionTypeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_ACQUISITION_TYPE_DESC',
  LawyersByContactIdMaxAliasesAsc = 'LAWYERS_BY_CONTACT_ID_MAX_ALIASES_ASC',
  LawyersByContactIdMaxAliasesDesc = 'LAWYERS_BY_CONTACT_ID_MAX_ALIASES_DESC',
  LawyersByContactIdMaxBrandColorAsc = 'LAWYERS_BY_CONTACT_ID_MAX_BRAND_COLOR_ASC',
  LawyersByContactIdMaxBrandColorDesc = 'LAWYERS_BY_CONTACT_ID_MAX_BRAND_COLOR_DESC',
  LawyersByContactIdMaxCalendlyLinkAsc = 'LAWYERS_BY_CONTACT_ID_MAX_CALENDLY_LINK_ASC',
  LawyersByContactIdMaxCalendlyLinkDesc = 'LAWYERS_BY_CONTACT_ID_MAX_CALENDLY_LINK_DESC',
  LawyersByContactIdMaxCalendlyUriAsc = 'LAWYERS_BY_CONTACT_ID_MAX_CALENDLY_URI_ASC',
  LawyersByContactIdMaxCalendlyUriDesc = 'LAWYERS_BY_CONTACT_ID_MAX_CALENDLY_URI_DESC',
  LawyersByContactIdMaxChargedPerAsc = 'LAWYERS_BY_CONTACT_ID_MAX_CHARGED_PER_ASC',
  LawyersByContactIdMaxChargedPerDesc = 'LAWYERS_BY_CONTACT_ID_MAX_CHARGED_PER_DESC',
  LawyersByContactIdMaxChargeRateAsc = 'LAWYERS_BY_CONTACT_ID_MAX_CHARGE_RATE_ASC',
  LawyersByContactIdMaxChargeRateDesc = 'LAWYERS_BY_CONTACT_ID_MAX_CHARGE_RATE_DESC',
  LawyersByContactIdMaxCityAsc = 'LAWYERS_BY_CONTACT_ID_MAX_CITY_ASC',
  LawyersByContactIdMaxCityDesc = 'LAWYERS_BY_CONTACT_ID_MAX_CITY_DESC',
  LawyersByContactIdMaxClientProfileRequirementsAsc = 'LAWYERS_BY_CONTACT_ID_MAX_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByContactIdMaxClientProfileRequirementsDesc = 'LAWYERS_BY_CONTACT_ID_MAX_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByContactIdMaxContactIdAsc = 'LAWYERS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  LawyersByContactIdMaxContactIdDesc = 'LAWYERS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  LawyersByContactIdMaxCountryAsc = 'LAWYERS_BY_CONTACT_ID_MAX_COUNTRY_ASC',
  LawyersByContactIdMaxCountryCodeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_COUNTRY_CODE_ASC',
  LawyersByContactIdMaxCountryCodeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_COUNTRY_CODE_DESC',
  LawyersByContactIdMaxCountryDesc = 'LAWYERS_BY_CONTACT_ID_MAX_COUNTRY_DESC',
  LawyersByContactIdMaxCreatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  LawyersByContactIdMaxCreatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  LawyersByContactIdMaxEmailAsc = 'LAWYERS_BY_CONTACT_ID_MAX_EMAIL_ASC',
  LawyersByContactIdMaxEmailDesc = 'LAWYERS_BY_CONTACT_ID_MAX_EMAIL_DESC',
  LawyersByContactIdMaxEnableAutoTestimonialAsc = 'LAWYERS_BY_CONTACT_ID_MAX_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByContactIdMaxEnableAutoTestimonialDesc = 'LAWYERS_BY_CONTACT_ID_MAX_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByContactIdMaxExternalContactsLastUpdatedAsc = 'LAWYERS_BY_CONTACT_ID_MAX_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByContactIdMaxExternalContactsLastUpdatedDesc = 'LAWYERS_BY_CONTACT_ID_MAX_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByContactIdMaxFirmDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_MAX_FIRM_DESCRIPTION_ASC',
  LawyersByContactIdMaxFirmDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_MAX_FIRM_DESCRIPTION_DESC',
  LawyersByContactIdMaxFirmNameAsc = 'LAWYERS_BY_CONTACT_ID_MAX_FIRM_NAME_ASC',
  LawyersByContactIdMaxFirmNameDesc = 'LAWYERS_BY_CONTACT_ID_MAX_FIRM_NAME_DESC',
  LawyersByContactIdMaxFreeLeadsAsc = 'LAWYERS_BY_CONTACT_ID_MAX_FREE_LEADS_ASC',
  LawyersByContactIdMaxFreeLeadsDesc = 'LAWYERS_BY_CONTACT_ID_MAX_FREE_LEADS_DESC',
  LawyersByContactIdMaxGooglePlaceIdAsc = 'LAWYERS_BY_CONTACT_ID_MAX_GOOGLE_PLACE_ID_ASC',
  LawyersByContactIdMaxGooglePlaceIdDesc = 'LAWYERS_BY_CONTACT_ID_MAX_GOOGLE_PLACE_ID_DESC',
  LawyersByContactIdMaxHandoverMethodAsc = 'LAWYERS_BY_CONTACT_ID_MAX_HANDOVER_METHOD_ASC',
  LawyersByContactIdMaxHandoverMethodDesc = 'LAWYERS_BY_CONTACT_ID_MAX_HANDOVER_METHOD_DESC',
  LawyersByContactIdMaxHandoverStateAsc = 'LAWYERS_BY_CONTACT_ID_MAX_HANDOVER_STATE_ASC',
  LawyersByContactIdMaxHandoverStateDesc = 'LAWYERS_BY_CONTACT_ID_MAX_HANDOVER_STATE_DESC',
  LawyersByContactIdMaxHubspotDealIdAsc = 'LAWYERS_BY_CONTACT_ID_MAX_HUBSPOT_DEAL_ID_ASC',
  LawyersByContactIdMaxHubspotDealIdDesc = 'LAWYERS_BY_CONTACT_ID_MAX_HUBSPOT_DEAL_ID_DESC',
  LawyersByContactIdMaxIcpAsc = 'LAWYERS_BY_CONTACT_ID_MAX_ICP_ASC',
  LawyersByContactIdMaxIcpDesc = 'LAWYERS_BY_CONTACT_ID_MAX_ICP_DESC',
  LawyersByContactIdMaxIcpGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_ICP_GUESSED_TIME_ASC',
  LawyersByContactIdMaxIcpGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_ICP_GUESSED_TIME_DESC',
  LawyersByContactIdMaxIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_CONTACT_ID_MAX_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByContactIdMaxIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_CONTACT_ID_MAX_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByContactIdMaxIdAsc = 'LAWYERS_BY_CONTACT_ID_MAX_ID_ASC',
  LawyersByContactIdMaxIdDesc = 'LAWYERS_BY_CONTACT_ID_MAX_ID_DESC',
  LawyersByContactIdMaxIsManagedAsc = 'LAWYERS_BY_CONTACT_ID_MAX_IS_MANAGED_ASC',
  LawyersByContactIdMaxIsManagedDesc = 'LAWYERS_BY_CONTACT_ID_MAX_IS_MANAGED_DESC',
  LawyersByContactIdMaxIsPausedAsc = 'LAWYERS_BY_CONTACT_ID_MAX_IS_PAUSED_ASC',
  LawyersByContactIdMaxIsPausedDesc = 'LAWYERS_BY_CONTACT_ID_MAX_IS_PAUSED_DESC',
  LawyersByContactIdMaxLastAccountReviewAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByContactIdMaxLastAccountReviewDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByContactIdMaxLastReportTimeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LAST_REPORT_TIME_ASC',
  LawyersByContactIdMaxLastReportTimeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LAST_REPORT_TIME_DESC',
  LawyersByContactIdMaxLastStatusEmailTimeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByContactIdMaxLastStatusEmailTimeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByContactIdMaxLawyerDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LAWYER_DESCRIPTION_ASC',
  LawyersByContactIdMaxLawyerDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LAWYER_DESCRIPTION_DESC',
  LawyersByContactIdMaxLeadsPerMonthAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LEADS_PER_MONTH_ASC',
  LawyersByContactIdMaxLeadsPerMonthDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LEADS_PER_MONTH_DESC',
  LawyersByContactIdMaxLeverAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LEVER_ASC',
  LawyersByContactIdMaxLeverDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LEVER_DESC',
  LawyersByContactIdMaxLinkedinUrlAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LINKEDIN_URL_ASC',
  LawyersByContactIdMaxLinkedinUrlDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LINKEDIN_URL_DESC',
  LawyersByContactIdMaxLiEmailAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_EMAIL_ASC',
  LawyersByContactIdMaxLiEmailDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_EMAIL_DESC',
  LawyersByContactIdMaxLiEncryptedPwAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_ENCRYPTED_PW_ASC',
  LawyersByContactIdMaxLiEncryptedPwDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_ENCRYPTED_PW_DESC',
  LawyersByContactIdMaxLiLastActiveTimeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByContactIdMaxLiLastActiveTimeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByContactIdMaxLiLoginStatusAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_LOGIN_STATUS_ASC',
  LawyersByContactIdMaxLiLoginStatusDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_LOGIN_STATUS_DESC',
  LawyersByContactIdMaxLiProfileNameAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_PROFILE_NAME_ASC',
  LawyersByContactIdMaxLiProfileNameDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_PROFILE_NAME_DESC',
  LawyersByContactIdMaxLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByContactIdMaxLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByContactIdMaxLiVerificationCodeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_VERIFICATION_CODE_ASC',
  LawyersByContactIdMaxLiVerificationCodeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LI_VERIFICATION_CODE_DESC',
  LawyersByContactIdMaxLogoSrcAsc = 'LAWYERS_BY_CONTACT_ID_MAX_LOGO_SRC_ASC',
  LawyersByContactIdMaxLogoSrcDesc = 'LAWYERS_BY_CONTACT_ID_MAX_LOGO_SRC_DESC',
  LawyersByContactIdMaxNameAsc = 'LAWYERS_BY_CONTACT_ID_MAX_NAME_ASC',
  LawyersByContactIdMaxNameDesc = 'LAWYERS_BY_CONTACT_ID_MAX_NAME_DESC',
  LawyersByContactIdMaxNylasAccessTokenAsc = 'LAWYERS_BY_CONTACT_ID_MAX_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByContactIdMaxNylasAccessTokenDesc = 'LAWYERS_BY_CONTACT_ID_MAX_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByContactIdMaxOnboardingFeePaidTimeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByContactIdMaxOnboardingFeePaidTimeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByContactIdMaxOnboardingTypeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_ONBOARDING_TYPE_ASC',
  LawyersByContactIdMaxOnboardingTypeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_ONBOARDING_TYPE_DESC',
  LawyersByContactIdMaxOrganizationIdAsc = 'LAWYERS_BY_CONTACT_ID_MAX_ORGANIZATION_ID_ASC',
  LawyersByContactIdMaxOrganizationIdDesc = 'LAWYERS_BY_CONTACT_ID_MAX_ORGANIZATION_ID_DESC',
  LawyersByContactIdMaxPaymentMethodAsc = 'LAWYERS_BY_CONTACT_ID_MAX_PAYMENT_METHOD_ASC',
  LawyersByContactIdMaxPaymentMethodDesc = 'LAWYERS_BY_CONTACT_ID_MAX_PAYMENT_METHOD_DESC',
  LawyersByContactIdMaxPersonalisationRankingsAsc = 'LAWYERS_BY_CONTACT_ID_MAX_PERSONALISATION_RANKINGS_ASC',
  LawyersByContactIdMaxPersonalisationRankingsDesc = 'LAWYERS_BY_CONTACT_ID_MAX_PERSONALISATION_RANKINGS_DESC',
  LawyersByContactIdMaxPostalCodeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_POSTAL_CODE_ASC',
  LawyersByContactIdMaxPostalCodeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_POSTAL_CODE_DESC',
  LawyersByContactIdMaxPriorityAsc = 'LAWYERS_BY_CONTACT_ID_MAX_PRIORITY_ASC',
  LawyersByContactIdMaxPriorityDesc = 'LAWYERS_BY_CONTACT_ID_MAX_PRIORITY_DESC',
  LawyersByContactIdMaxProxyIdAsc = 'LAWYERS_BY_CONTACT_ID_MAX_PROXY_ID_ASC',
  LawyersByContactIdMaxProxyIdDesc = 'LAWYERS_BY_CONTACT_ID_MAX_PROXY_ID_DESC',
  LawyersByContactIdMaxPublicIdAsc = 'LAWYERS_BY_CONTACT_ID_MAX_PUBLIC_ID_ASC',
  LawyersByContactIdMaxPublicIdDesc = 'LAWYERS_BY_CONTACT_ID_MAX_PUBLIC_ID_DESC',
  LawyersByContactIdMaxReplyNotesAsc = 'LAWYERS_BY_CONTACT_ID_MAX_REPLY_NOTES_ASC',
  LawyersByContactIdMaxReplyNotesDesc = 'LAWYERS_BY_CONTACT_ID_MAX_REPLY_NOTES_DESC',
  LawyersByContactIdMaxSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_CONTACT_ID_MAX_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByContactIdMaxSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_CONTACT_ID_MAX_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByContactIdMaxSenderDisclaimerAsc = 'LAWYERS_BY_CONTACT_ID_MAX_SENDER_DISCLAIMER_ASC',
  LawyersByContactIdMaxSenderDisclaimerDesc = 'LAWYERS_BY_CONTACT_ID_MAX_SENDER_DISCLAIMER_DESC',
  LawyersByContactIdMaxSignatureHtmlAsc = 'LAWYERS_BY_CONTACT_ID_MAX_SIGNATURE_HTML_ASC',
  LawyersByContactIdMaxSignatureHtmlDesc = 'LAWYERS_BY_CONTACT_ID_MAX_SIGNATURE_HTML_DESC',
  LawyersByContactIdMaxSlackChannelNameAsc = 'LAWYERS_BY_CONTACT_ID_MAX_SLACK_CHANNEL_NAME_ASC',
  LawyersByContactIdMaxSlackChannelNameDesc = 'LAWYERS_BY_CONTACT_ID_MAX_SLACK_CHANNEL_NAME_DESC',
  LawyersByContactIdMaxSlackClientIdAsc = 'LAWYERS_BY_CONTACT_ID_MAX_SLACK_CLIENT_ID_ASC',
  LawyersByContactIdMaxSlackClientIdDesc = 'LAWYERS_BY_CONTACT_ID_MAX_SLACK_CLIENT_ID_DESC',
  LawyersByContactIdMaxSlackClientSecretAsc = 'LAWYERS_BY_CONTACT_ID_MAX_SLACK_CLIENT_SECRET_ASC',
  LawyersByContactIdMaxSlackClientSecretDesc = 'LAWYERS_BY_CONTACT_ID_MAX_SLACK_CLIENT_SECRET_DESC',
  LawyersByContactIdMaxStageAsc = 'LAWYERS_BY_CONTACT_ID_MAX_STAGE_ASC',
  LawyersByContactIdMaxStageDesc = 'LAWYERS_BY_CONTACT_ID_MAX_STAGE_DESC',
  LawyersByContactIdMaxStateAsc = 'LAWYERS_BY_CONTACT_ID_MAX_STATE_ASC',
  LawyersByContactIdMaxStateDesc = 'LAWYERS_BY_CONTACT_ID_MAX_STATE_DESC',
  LawyersByContactIdMaxStatusAsc = 'LAWYERS_BY_CONTACT_ID_MAX_STATUS_ASC',
  LawyersByContactIdMaxStatusDesc = 'LAWYERS_BY_CONTACT_ID_MAX_STATUS_DESC',
  LawyersByContactIdMaxStreetAddressAsc = 'LAWYERS_BY_CONTACT_ID_MAX_STREET_ADDRESS_ASC',
  LawyersByContactIdMaxStreetAddressDesc = 'LAWYERS_BY_CONTACT_ID_MAX_STREET_ADDRESS_DESC',
  LawyersByContactIdMaxStripeCustomerIdAsc = 'LAWYERS_BY_CONTACT_ID_MAX_STRIPE_CUSTOMER_ID_ASC',
  LawyersByContactIdMaxStripeCustomerIdDesc = 'LAWYERS_BY_CONTACT_ID_MAX_STRIPE_CUSTOMER_ID_DESC',
  LawyersByContactIdMaxStripeDiscountCodeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByContactIdMaxStripeDiscountCodeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByContactIdMaxStripePriceIdAsc = 'LAWYERS_BY_CONTACT_ID_MAX_STRIPE_PRICE_ID_ASC',
  LawyersByContactIdMaxStripePriceIdDesc = 'LAWYERS_BY_CONTACT_ID_MAX_STRIPE_PRICE_ID_DESC',
  LawyersByContactIdMaxTimezoneAsc = 'LAWYERS_BY_CONTACT_ID_MAX_TIMEZONE_ASC',
  LawyersByContactIdMaxTimezoneDesc = 'LAWYERS_BY_CONTACT_ID_MAX_TIMEZONE_DESC',
  LawyersByContactIdMaxTitleAsc = 'LAWYERS_BY_CONTACT_ID_MAX_TITLE_ASC',
  LawyersByContactIdMaxTitleDesc = 'LAWYERS_BY_CONTACT_ID_MAX_TITLE_DESC',
  LawyersByContactIdMaxUpdatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_UPDATED_TIME_ASC',
  LawyersByContactIdMaxUpdatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_UPDATED_TIME_DESC',
  LawyersByContactIdMaxUserTypeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_USER_TYPE_ASC',
  LawyersByContactIdMaxUserTypeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_USER_TYPE_DESC',
  LawyersByContactIdMaxValuePropositionAsc = 'LAWYERS_BY_CONTACT_ID_MAX_VALUE_PROPOSITION_ASC',
  LawyersByContactIdMaxValuePropositionDesc = 'LAWYERS_BY_CONTACT_ID_MAX_VALUE_PROPOSITION_DESC',
  LawyersByContactIdMaxValuePropositionGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_MAX_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByContactIdMaxValuePropositionGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_MAX_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByContactIdMaxWantsToChooseDomainsAsc = 'LAWYERS_BY_CONTACT_ID_MAX_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByContactIdMaxWantsToChooseDomainsDesc = 'LAWYERS_BY_CONTACT_ID_MAX_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByContactIdMaxWebsiteUrlAsc = 'LAWYERS_BY_CONTACT_ID_MAX_WEBSITE_URL_ASC',
  LawyersByContactIdMaxWebsiteUrlDesc = 'LAWYERS_BY_CONTACT_ID_MAX_WEBSITE_URL_DESC',
  LawyersByContactIdMinAcquisitionChannelAsc = 'LAWYERS_BY_CONTACT_ID_MIN_ACQUISITION_CHANNEL_ASC',
  LawyersByContactIdMinAcquisitionChannelDesc = 'LAWYERS_BY_CONTACT_ID_MIN_ACQUISITION_CHANNEL_DESC',
  LawyersByContactIdMinAcquisitionTypeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_ACQUISITION_TYPE_ASC',
  LawyersByContactIdMinAcquisitionTypeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_ACQUISITION_TYPE_DESC',
  LawyersByContactIdMinAliasesAsc = 'LAWYERS_BY_CONTACT_ID_MIN_ALIASES_ASC',
  LawyersByContactIdMinAliasesDesc = 'LAWYERS_BY_CONTACT_ID_MIN_ALIASES_DESC',
  LawyersByContactIdMinBrandColorAsc = 'LAWYERS_BY_CONTACT_ID_MIN_BRAND_COLOR_ASC',
  LawyersByContactIdMinBrandColorDesc = 'LAWYERS_BY_CONTACT_ID_MIN_BRAND_COLOR_DESC',
  LawyersByContactIdMinCalendlyLinkAsc = 'LAWYERS_BY_CONTACT_ID_MIN_CALENDLY_LINK_ASC',
  LawyersByContactIdMinCalendlyLinkDesc = 'LAWYERS_BY_CONTACT_ID_MIN_CALENDLY_LINK_DESC',
  LawyersByContactIdMinCalendlyUriAsc = 'LAWYERS_BY_CONTACT_ID_MIN_CALENDLY_URI_ASC',
  LawyersByContactIdMinCalendlyUriDesc = 'LAWYERS_BY_CONTACT_ID_MIN_CALENDLY_URI_DESC',
  LawyersByContactIdMinChargedPerAsc = 'LAWYERS_BY_CONTACT_ID_MIN_CHARGED_PER_ASC',
  LawyersByContactIdMinChargedPerDesc = 'LAWYERS_BY_CONTACT_ID_MIN_CHARGED_PER_DESC',
  LawyersByContactIdMinChargeRateAsc = 'LAWYERS_BY_CONTACT_ID_MIN_CHARGE_RATE_ASC',
  LawyersByContactIdMinChargeRateDesc = 'LAWYERS_BY_CONTACT_ID_MIN_CHARGE_RATE_DESC',
  LawyersByContactIdMinCityAsc = 'LAWYERS_BY_CONTACT_ID_MIN_CITY_ASC',
  LawyersByContactIdMinCityDesc = 'LAWYERS_BY_CONTACT_ID_MIN_CITY_DESC',
  LawyersByContactIdMinClientProfileRequirementsAsc = 'LAWYERS_BY_CONTACT_ID_MIN_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByContactIdMinClientProfileRequirementsDesc = 'LAWYERS_BY_CONTACT_ID_MIN_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByContactIdMinContactIdAsc = 'LAWYERS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  LawyersByContactIdMinContactIdDesc = 'LAWYERS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  LawyersByContactIdMinCountryAsc = 'LAWYERS_BY_CONTACT_ID_MIN_COUNTRY_ASC',
  LawyersByContactIdMinCountryCodeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_COUNTRY_CODE_ASC',
  LawyersByContactIdMinCountryCodeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_COUNTRY_CODE_DESC',
  LawyersByContactIdMinCountryDesc = 'LAWYERS_BY_CONTACT_ID_MIN_COUNTRY_DESC',
  LawyersByContactIdMinCreatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  LawyersByContactIdMinCreatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  LawyersByContactIdMinEmailAsc = 'LAWYERS_BY_CONTACT_ID_MIN_EMAIL_ASC',
  LawyersByContactIdMinEmailDesc = 'LAWYERS_BY_CONTACT_ID_MIN_EMAIL_DESC',
  LawyersByContactIdMinEnableAutoTestimonialAsc = 'LAWYERS_BY_CONTACT_ID_MIN_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByContactIdMinEnableAutoTestimonialDesc = 'LAWYERS_BY_CONTACT_ID_MIN_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByContactIdMinExternalContactsLastUpdatedAsc = 'LAWYERS_BY_CONTACT_ID_MIN_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByContactIdMinExternalContactsLastUpdatedDesc = 'LAWYERS_BY_CONTACT_ID_MIN_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByContactIdMinFirmDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_MIN_FIRM_DESCRIPTION_ASC',
  LawyersByContactIdMinFirmDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_MIN_FIRM_DESCRIPTION_DESC',
  LawyersByContactIdMinFirmNameAsc = 'LAWYERS_BY_CONTACT_ID_MIN_FIRM_NAME_ASC',
  LawyersByContactIdMinFirmNameDesc = 'LAWYERS_BY_CONTACT_ID_MIN_FIRM_NAME_DESC',
  LawyersByContactIdMinFreeLeadsAsc = 'LAWYERS_BY_CONTACT_ID_MIN_FREE_LEADS_ASC',
  LawyersByContactIdMinFreeLeadsDesc = 'LAWYERS_BY_CONTACT_ID_MIN_FREE_LEADS_DESC',
  LawyersByContactIdMinGooglePlaceIdAsc = 'LAWYERS_BY_CONTACT_ID_MIN_GOOGLE_PLACE_ID_ASC',
  LawyersByContactIdMinGooglePlaceIdDesc = 'LAWYERS_BY_CONTACT_ID_MIN_GOOGLE_PLACE_ID_DESC',
  LawyersByContactIdMinHandoverMethodAsc = 'LAWYERS_BY_CONTACT_ID_MIN_HANDOVER_METHOD_ASC',
  LawyersByContactIdMinHandoverMethodDesc = 'LAWYERS_BY_CONTACT_ID_MIN_HANDOVER_METHOD_DESC',
  LawyersByContactIdMinHandoverStateAsc = 'LAWYERS_BY_CONTACT_ID_MIN_HANDOVER_STATE_ASC',
  LawyersByContactIdMinHandoverStateDesc = 'LAWYERS_BY_CONTACT_ID_MIN_HANDOVER_STATE_DESC',
  LawyersByContactIdMinHubspotDealIdAsc = 'LAWYERS_BY_CONTACT_ID_MIN_HUBSPOT_DEAL_ID_ASC',
  LawyersByContactIdMinHubspotDealIdDesc = 'LAWYERS_BY_CONTACT_ID_MIN_HUBSPOT_DEAL_ID_DESC',
  LawyersByContactIdMinIcpAsc = 'LAWYERS_BY_CONTACT_ID_MIN_ICP_ASC',
  LawyersByContactIdMinIcpDesc = 'LAWYERS_BY_CONTACT_ID_MIN_ICP_DESC',
  LawyersByContactIdMinIcpGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_ICP_GUESSED_TIME_ASC',
  LawyersByContactIdMinIcpGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_ICP_GUESSED_TIME_DESC',
  LawyersByContactIdMinIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_CONTACT_ID_MIN_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByContactIdMinIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_CONTACT_ID_MIN_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByContactIdMinIdAsc = 'LAWYERS_BY_CONTACT_ID_MIN_ID_ASC',
  LawyersByContactIdMinIdDesc = 'LAWYERS_BY_CONTACT_ID_MIN_ID_DESC',
  LawyersByContactIdMinIsManagedAsc = 'LAWYERS_BY_CONTACT_ID_MIN_IS_MANAGED_ASC',
  LawyersByContactIdMinIsManagedDesc = 'LAWYERS_BY_CONTACT_ID_MIN_IS_MANAGED_DESC',
  LawyersByContactIdMinIsPausedAsc = 'LAWYERS_BY_CONTACT_ID_MIN_IS_PAUSED_ASC',
  LawyersByContactIdMinIsPausedDesc = 'LAWYERS_BY_CONTACT_ID_MIN_IS_PAUSED_DESC',
  LawyersByContactIdMinLastAccountReviewAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByContactIdMinLastAccountReviewDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByContactIdMinLastReportTimeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LAST_REPORT_TIME_ASC',
  LawyersByContactIdMinLastReportTimeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LAST_REPORT_TIME_DESC',
  LawyersByContactIdMinLastStatusEmailTimeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByContactIdMinLastStatusEmailTimeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByContactIdMinLawyerDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LAWYER_DESCRIPTION_ASC',
  LawyersByContactIdMinLawyerDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LAWYER_DESCRIPTION_DESC',
  LawyersByContactIdMinLeadsPerMonthAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LEADS_PER_MONTH_ASC',
  LawyersByContactIdMinLeadsPerMonthDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LEADS_PER_MONTH_DESC',
  LawyersByContactIdMinLeverAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LEVER_ASC',
  LawyersByContactIdMinLeverDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LEVER_DESC',
  LawyersByContactIdMinLinkedinUrlAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LINKEDIN_URL_ASC',
  LawyersByContactIdMinLinkedinUrlDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LINKEDIN_URL_DESC',
  LawyersByContactIdMinLiEmailAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_EMAIL_ASC',
  LawyersByContactIdMinLiEmailDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_EMAIL_DESC',
  LawyersByContactIdMinLiEncryptedPwAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_ENCRYPTED_PW_ASC',
  LawyersByContactIdMinLiEncryptedPwDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_ENCRYPTED_PW_DESC',
  LawyersByContactIdMinLiLastActiveTimeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByContactIdMinLiLastActiveTimeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByContactIdMinLiLoginStatusAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_LOGIN_STATUS_ASC',
  LawyersByContactIdMinLiLoginStatusDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_LOGIN_STATUS_DESC',
  LawyersByContactIdMinLiProfileNameAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_PROFILE_NAME_ASC',
  LawyersByContactIdMinLiProfileNameDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_PROFILE_NAME_DESC',
  LawyersByContactIdMinLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByContactIdMinLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByContactIdMinLiVerificationCodeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_VERIFICATION_CODE_ASC',
  LawyersByContactIdMinLiVerificationCodeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LI_VERIFICATION_CODE_DESC',
  LawyersByContactIdMinLogoSrcAsc = 'LAWYERS_BY_CONTACT_ID_MIN_LOGO_SRC_ASC',
  LawyersByContactIdMinLogoSrcDesc = 'LAWYERS_BY_CONTACT_ID_MIN_LOGO_SRC_DESC',
  LawyersByContactIdMinNameAsc = 'LAWYERS_BY_CONTACT_ID_MIN_NAME_ASC',
  LawyersByContactIdMinNameDesc = 'LAWYERS_BY_CONTACT_ID_MIN_NAME_DESC',
  LawyersByContactIdMinNylasAccessTokenAsc = 'LAWYERS_BY_CONTACT_ID_MIN_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByContactIdMinNylasAccessTokenDesc = 'LAWYERS_BY_CONTACT_ID_MIN_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByContactIdMinOnboardingFeePaidTimeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByContactIdMinOnboardingFeePaidTimeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByContactIdMinOnboardingTypeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_ONBOARDING_TYPE_ASC',
  LawyersByContactIdMinOnboardingTypeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_ONBOARDING_TYPE_DESC',
  LawyersByContactIdMinOrganizationIdAsc = 'LAWYERS_BY_CONTACT_ID_MIN_ORGANIZATION_ID_ASC',
  LawyersByContactIdMinOrganizationIdDesc = 'LAWYERS_BY_CONTACT_ID_MIN_ORGANIZATION_ID_DESC',
  LawyersByContactIdMinPaymentMethodAsc = 'LAWYERS_BY_CONTACT_ID_MIN_PAYMENT_METHOD_ASC',
  LawyersByContactIdMinPaymentMethodDesc = 'LAWYERS_BY_CONTACT_ID_MIN_PAYMENT_METHOD_DESC',
  LawyersByContactIdMinPersonalisationRankingsAsc = 'LAWYERS_BY_CONTACT_ID_MIN_PERSONALISATION_RANKINGS_ASC',
  LawyersByContactIdMinPersonalisationRankingsDesc = 'LAWYERS_BY_CONTACT_ID_MIN_PERSONALISATION_RANKINGS_DESC',
  LawyersByContactIdMinPostalCodeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_POSTAL_CODE_ASC',
  LawyersByContactIdMinPostalCodeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_POSTAL_CODE_DESC',
  LawyersByContactIdMinPriorityAsc = 'LAWYERS_BY_CONTACT_ID_MIN_PRIORITY_ASC',
  LawyersByContactIdMinPriorityDesc = 'LAWYERS_BY_CONTACT_ID_MIN_PRIORITY_DESC',
  LawyersByContactIdMinProxyIdAsc = 'LAWYERS_BY_CONTACT_ID_MIN_PROXY_ID_ASC',
  LawyersByContactIdMinProxyIdDesc = 'LAWYERS_BY_CONTACT_ID_MIN_PROXY_ID_DESC',
  LawyersByContactIdMinPublicIdAsc = 'LAWYERS_BY_CONTACT_ID_MIN_PUBLIC_ID_ASC',
  LawyersByContactIdMinPublicIdDesc = 'LAWYERS_BY_CONTACT_ID_MIN_PUBLIC_ID_DESC',
  LawyersByContactIdMinReplyNotesAsc = 'LAWYERS_BY_CONTACT_ID_MIN_REPLY_NOTES_ASC',
  LawyersByContactIdMinReplyNotesDesc = 'LAWYERS_BY_CONTACT_ID_MIN_REPLY_NOTES_DESC',
  LawyersByContactIdMinSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_CONTACT_ID_MIN_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByContactIdMinSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_CONTACT_ID_MIN_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByContactIdMinSenderDisclaimerAsc = 'LAWYERS_BY_CONTACT_ID_MIN_SENDER_DISCLAIMER_ASC',
  LawyersByContactIdMinSenderDisclaimerDesc = 'LAWYERS_BY_CONTACT_ID_MIN_SENDER_DISCLAIMER_DESC',
  LawyersByContactIdMinSignatureHtmlAsc = 'LAWYERS_BY_CONTACT_ID_MIN_SIGNATURE_HTML_ASC',
  LawyersByContactIdMinSignatureHtmlDesc = 'LAWYERS_BY_CONTACT_ID_MIN_SIGNATURE_HTML_DESC',
  LawyersByContactIdMinSlackChannelNameAsc = 'LAWYERS_BY_CONTACT_ID_MIN_SLACK_CHANNEL_NAME_ASC',
  LawyersByContactIdMinSlackChannelNameDesc = 'LAWYERS_BY_CONTACT_ID_MIN_SLACK_CHANNEL_NAME_DESC',
  LawyersByContactIdMinSlackClientIdAsc = 'LAWYERS_BY_CONTACT_ID_MIN_SLACK_CLIENT_ID_ASC',
  LawyersByContactIdMinSlackClientIdDesc = 'LAWYERS_BY_CONTACT_ID_MIN_SLACK_CLIENT_ID_DESC',
  LawyersByContactIdMinSlackClientSecretAsc = 'LAWYERS_BY_CONTACT_ID_MIN_SLACK_CLIENT_SECRET_ASC',
  LawyersByContactIdMinSlackClientSecretDesc = 'LAWYERS_BY_CONTACT_ID_MIN_SLACK_CLIENT_SECRET_DESC',
  LawyersByContactIdMinStageAsc = 'LAWYERS_BY_CONTACT_ID_MIN_STAGE_ASC',
  LawyersByContactIdMinStageDesc = 'LAWYERS_BY_CONTACT_ID_MIN_STAGE_DESC',
  LawyersByContactIdMinStateAsc = 'LAWYERS_BY_CONTACT_ID_MIN_STATE_ASC',
  LawyersByContactIdMinStateDesc = 'LAWYERS_BY_CONTACT_ID_MIN_STATE_DESC',
  LawyersByContactIdMinStatusAsc = 'LAWYERS_BY_CONTACT_ID_MIN_STATUS_ASC',
  LawyersByContactIdMinStatusDesc = 'LAWYERS_BY_CONTACT_ID_MIN_STATUS_DESC',
  LawyersByContactIdMinStreetAddressAsc = 'LAWYERS_BY_CONTACT_ID_MIN_STREET_ADDRESS_ASC',
  LawyersByContactIdMinStreetAddressDesc = 'LAWYERS_BY_CONTACT_ID_MIN_STREET_ADDRESS_DESC',
  LawyersByContactIdMinStripeCustomerIdAsc = 'LAWYERS_BY_CONTACT_ID_MIN_STRIPE_CUSTOMER_ID_ASC',
  LawyersByContactIdMinStripeCustomerIdDesc = 'LAWYERS_BY_CONTACT_ID_MIN_STRIPE_CUSTOMER_ID_DESC',
  LawyersByContactIdMinStripeDiscountCodeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByContactIdMinStripeDiscountCodeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByContactIdMinStripePriceIdAsc = 'LAWYERS_BY_CONTACT_ID_MIN_STRIPE_PRICE_ID_ASC',
  LawyersByContactIdMinStripePriceIdDesc = 'LAWYERS_BY_CONTACT_ID_MIN_STRIPE_PRICE_ID_DESC',
  LawyersByContactIdMinTimezoneAsc = 'LAWYERS_BY_CONTACT_ID_MIN_TIMEZONE_ASC',
  LawyersByContactIdMinTimezoneDesc = 'LAWYERS_BY_CONTACT_ID_MIN_TIMEZONE_DESC',
  LawyersByContactIdMinTitleAsc = 'LAWYERS_BY_CONTACT_ID_MIN_TITLE_ASC',
  LawyersByContactIdMinTitleDesc = 'LAWYERS_BY_CONTACT_ID_MIN_TITLE_DESC',
  LawyersByContactIdMinUpdatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_UPDATED_TIME_ASC',
  LawyersByContactIdMinUpdatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_UPDATED_TIME_DESC',
  LawyersByContactIdMinUserTypeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_USER_TYPE_ASC',
  LawyersByContactIdMinUserTypeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_USER_TYPE_DESC',
  LawyersByContactIdMinValuePropositionAsc = 'LAWYERS_BY_CONTACT_ID_MIN_VALUE_PROPOSITION_ASC',
  LawyersByContactIdMinValuePropositionDesc = 'LAWYERS_BY_CONTACT_ID_MIN_VALUE_PROPOSITION_DESC',
  LawyersByContactIdMinValuePropositionGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_MIN_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByContactIdMinValuePropositionGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_MIN_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByContactIdMinWantsToChooseDomainsAsc = 'LAWYERS_BY_CONTACT_ID_MIN_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByContactIdMinWantsToChooseDomainsDesc = 'LAWYERS_BY_CONTACT_ID_MIN_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByContactIdMinWebsiteUrlAsc = 'LAWYERS_BY_CONTACT_ID_MIN_WEBSITE_URL_ASC',
  LawyersByContactIdMinWebsiteUrlDesc = 'LAWYERS_BY_CONTACT_ID_MIN_WEBSITE_URL_DESC',
  LawyersByContactIdStddevPopulationAcquisitionChannelAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ACQUISITION_CHANNEL_ASC',
  LawyersByContactIdStddevPopulationAcquisitionChannelDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ACQUISITION_CHANNEL_DESC',
  LawyersByContactIdStddevPopulationAcquisitionTypeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ACQUISITION_TYPE_ASC',
  LawyersByContactIdStddevPopulationAcquisitionTypeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ACQUISITION_TYPE_DESC',
  LawyersByContactIdStddevPopulationAliasesAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ALIASES_ASC',
  LawyersByContactIdStddevPopulationAliasesDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ALIASES_DESC',
  LawyersByContactIdStddevPopulationBrandColorAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_BRAND_COLOR_ASC',
  LawyersByContactIdStddevPopulationBrandColorDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_BRAND_COLOR_DESC',
  LawyersByContactIdStddevPopulationCalendlyLinkAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CALENDLY_LINK_ASC',
  LawyersByContactIdStddevPopulationCalendlyLinkDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CALENDLY_LINK_DESC',
  LawyersByContactIdStddevPopulationCalendlyUriAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CALENDLY_URI_ASC',
  LawyersByContactIdStddevPopulationCalendlyUriDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CALENDLY_URI_DESC',
  LawyersByContactIdStddevPopulationChargedPerAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CHARGED_PER_ASC',
  LawyersByContactIdStddevPopulationChargedPerDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CHARGED_PER_DESC',
  LawyersByContactIdStddevPopulationChargeRateAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CHARGE_RATE_ASC',
  LawyersByContactIdStddevPopulationChargeRateDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CHARGE_RATE_DESC',
  LawyersByContactIdStddevPopulationCityAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CITY_ASC',
  LawyersByContactIdStddevPopulationCityDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CITY_DESC',
  LawyersByContactIdStddevPopulationClientProfileRequirementsAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByContactIdStddevPopulationClientProfileRequirementsDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByContactIdStddevPopulationContactIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  LawyersByContactIdStddevPopulationContactIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  LawyersByContactIdStddevPopulationCountryAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_COUNTRY_ASC',
  LawyersByContactIdStddevPopulationCountryCodeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_COUNTRY_CODE_ASC',
  LawyersByContactIdStddevPopulationCountryCodeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_COUNTRY_CODE_DESC',
  LawyersByContactIdStddevPopulationCountryDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_COUNTRY_DESC',
  LawyersByContactIdStddevPopulationCreatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LawyersByContactIdStddevPopulationCreatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LawyersByContactIdStddevPopulationEmailAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_EMAIL_ASC',
  LawyersByContactIdStddevPopulationEmailDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_EMAIL_DESC',
  LawyersByContactIdStddevPopulationEnableAutoTestimonialAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByContactIdStddevPopulationEnableAutoTestimonialDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByContactIdStddevPopulationExternalContactsLastUpdatedAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByContactIdStddevPopulationExternalContactsLastUpdatedDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByContactIdStddevPopulationFirmDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_FIRM_DESCRIPTION_ASC',
  LawyersByContactIdStddevPopulationFirmDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_FIRM_DESCRIPTION_DESC',
  LawyersByContactIdStddevPopulationFirmNameAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_FIRM_NAME_ASC',
  LawyersByContactIdStddevPopulationFirmNameDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_FIRM_NAME_DESC',
  LawyersByContactIdStddevPopulationFreeLeadsAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_FREE_LEADS_ASC',
  LawyersByContactIdStddevPopulationFreeLeadsDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_FREE_LEADS_DESC',
  LawyersByContactIdStddevPopulationGooglePlaceIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_GOOGLE_PLACE_ID_ASC',
  LawyersByContactIdStddevPopulationGooglePlaceIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_GOOGLE_PLACE_ID_DESC',
  LawyersByContactIdStddevPopulationHandoverMethodAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_HANDOVER_METHOD_ASC',
  LawyersByContactIdStddevPopulationHandoverMethodDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_HANDOVER_METHOD_DESC',
  LawyersByContactIdStddevPopulationHandoverStateAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_HANDOVER_STATE_ASC',
  LawyersByContactIdStddevPopulationHandoverStateDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_HANDOVER_STATE_DESC',
  LawyersByContactIdStddevPopulationHubspotDealIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_HUBSPOT_DEAL_ID_ASC',
  LawyersByContactIdStddevPopulationHubspotDealIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_HUBSPOT_DEAL_ID_DESC',
  LawyersByContactIdStddevPopulationIcpAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ICP_ASC',
  LawyersByContactIdStddevPopulationIcpDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ICP_DESC',
  LawyersByContactIdStddevPopulationIcpGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ICP_GUESSED_TIME_ASC',
  LawyersByContactIdStddevPopulationIcpGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ICP_GUESSED_TIME_DESC',
  LawyersByContactIdStddevPopulationIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByContactIdStddevPopulationIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByContactIdStddevPopulationIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  LawyersByContactIdStddevPopulationIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  LawyersByContactIdStddevPopulationIsManagedAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_IS_MANAGED_ASC',
  LawyersByContactIdStddevPopulationIsManagedDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_IS_MANAGED_DESC',
  LawyersByContactIdStddevPopulationIsPausedAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_IS_PAUSED_ASC',
  LawyersByContactIdStddevPopulationIsPausedDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_IS_PAUSED_DESC',
  LawyersByContactIdStddevPopulationLastAccountReviewAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByContactIdStddevPopulationLastAccountReviewDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByContactIdStddevPopulationLastReportTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LAST_REPORT_TIME_ASC',
  LawyersByContactIdStddevPopulationLastReportTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LAST_REPORT_TIME_DESC',
  LawyersByContactIdStddevPopulationLastStatusEmailTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByContactIdStddevPopulationLastStatusEmailTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByContactIdStddevPopulationLawyerDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_DESCRIPTION_ASC',
  LawyersByContactIdStddevPopulationLawyerDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_DESCRIPTION_DESC',
  LawyersByContactIdStddevPopulationLeadsPerMonthAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LEADS_PER_MONTH_ASC',
  LawyersByContactIdStddevPopulationLeadsPerMonthDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LEADS_PER_MONTH_DESC',
  LawyersByContactIdStddevPopulationLeverAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LEVER_ASC',
  LawyersByContactIdStddevPopulationLeverDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LEVER_DESC',
  LawyersByContactIdStddevPopulationLinkedinUrlAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LINKEDIN_URL_ASC',
  LawyersByContactIdStddevPopulationLinkedinUrlDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LINKEDIN_URL_DESC',
  LawyersByContactIdStddevPopulationLiEmailAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_EMAIL_ASC',
  LawyersByContactIdStddevPopulationLiEmailDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_EMAIL_DESC',
  LawyersByContactIdStddevPopulationLiEncryptedPwAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_ENCRYPTED_PW_ASC',
  LawyersByContactIdStddevPopulationLiEncryptedPwDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_ENCRYPTED_PW_DESC',
  LawyersByContactIdStddevPopulationLiLastActiveTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByContactIdStddevPopulationLiLastActiveTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByContactIdStddevPopulationLiLoginStatusAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_LOGIN_STATUS_ASC',
  LawyersByContactIdStddevPopulationLiLoginStatusDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_LOGIN_STATUS_DESC',
  LawyersByContactIdStddevPopulationLiProfileNameAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_PROFILE_NAME_ASC',
  LawyersByContactIdStddevPopulationLiProfileNameDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_PROFILE_NAME_DESC',
  LawyersByContactIdStddevPopulationLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByContactIdStddevPopulationLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByContactIdStddevPopulationLiVerificationCodeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_VERIFICATION_CODE_ASC',
  LawyersByContactIdStddevPopulationLiVerificationCodeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LI_VERIFICATION_CODE_DESC',
  LawyersByContactIdStddevPopulationLogoSrcAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LOGO_SRC_ASC',
  LawyersByContactIdStddevPopulationLogoSrcDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_LOGO_SRC_DESC',
  LawyersByContactIdStddevPopulationNameAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_NAME_ASC',
  LawyersByContactIdStddevPopulationNameDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_NAME_DESC',
  LawyersByContactIdStddevPopulationNylasAccessTokenAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByContactIdStddevPopulationNylasAccessTokenDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByContactIdStddevPopulationOnboardingFeePaidTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByContactIdStddevPopulationOnboardingFeePaidTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByContactIdStddevPopulationOnboardingTypeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ONBOARDING_TYPE_ASC',
  LawyersByContactIdStddevPopulationOnboardingTypeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ONBOARDING_TYPE_DESC',
  LawyersByContactIdStddevPopulationOrganizationIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ORGANIZATION_ID_ASC',
  LawyersByContactIdStddevPopulationOrganizationIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_ORGANIZATION_ID_DESC',
  LawyersByContactIdStddevPopulationPaymentMethodAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_PAYMENT_METHOD_ASC',
  LawyersByContactIdStddevPopulationPaymentMethodDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_PAYMENT_METHOD_DESC',
  LawyersByContactIdStddevPopulationPersonalisationRankingsAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_PERSONALISATION_RANKINGS_ASC',
  LawyersByContactIdStddevPopulationPersonalisationRankingsDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_PERSONALISATION_RANKINGS_DESC',
  LawyersByContactIdStddevPopulationPostalCodeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_POSTAL_CODE_ASC',
  LawyersByContactIdStddevPopulationPostalCodeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_POSTAL_CODE_DESC',
  LawyersByContactIdStddevPopulationPriorityAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_PRIORITY_ASC',
  LawyersByContactIdStddevPopulationPriorityDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_PRIORITY_DESC',
  LawyersByContactIdStddevPopulationProxyIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_PROXY_ID_ASC',
  LawyersByContactIdStddevPopulationProxyIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_PROXY_ID_DESC',
  LawyersByContactIdStddevPopulationPublicIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_PUBLIC_ID_ASC',
  LawyersByContactIdStddevPopulationPublicIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_PUBLIC_ID_DESC',
  LawyersByContactIdStddevPopulationReplyNotesAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_REPLY_NOTES_ASC',
  LawyersByContactIdStddevPopulationReplyNotesDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_REPLY_NOTES_DESC',
  LawyersByContactIdStddevPopulationSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByContactIdStddevPopulationSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByContactIdStddevPopulationSenderDisclaimerAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_SENDER_DISCLAIMER_ASC',
  LawyersByContactIdStddevPopulationSenderDisclaimerDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_SENDER_DISCLAIMER_DESC',
  LawyersByContactIdStddevPopulationSignatureHtmlAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_SIGNATURE_HTML_ASC',
  LawyersByContactIdStddevPopulationSignatureHtmlDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_SIGNATURE_HTML_DESC',
  LawyersByContactIdStddevPopulationSlackChannelNameAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_CHANNEL_NAME_ASC',
  LawyersByContactIdStddevPopulationSlackChannelNameDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_CHANNEL_NAME_DESC',
  LawyersByContactIdStddevPopulationSlackClientIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_CLIENT_ID_ASC',
  LawyersByContactIdStddevPopulationSlackClientIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_CLIENT_ID_DESC',
  LawyersByContactIdStddevPopulationSlackClientSecretAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_CLIENT_SECRET_ASC',
  LawyersByContactIdStddevPopulationSlackClientSecretDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_CLIENT_SECRET_DESC',
  LawyersByContactIdStddevPopulationStageAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STAGE_ASC',
  LawyersByContactIdStddevPopulationStageDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STAGE_DESC',
  LawyersByContactIdStddevPopulationStateAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STATE_ASC',
  LawyersByContactIdStddevPopulationStateDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STATE_DESC',
  LawyersByContactIdStddevPopulationStatusAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STATUS_ASC',
  LawyersByContactIdStddevPopulationStatusDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STATUS_DESC',
  LawyersByContactIdStddevPopulationStreetAddressAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STREET_ADDRESS_ASC',
  LawyersByContactIdStddevPopulationStreetAddressDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STREET_ADDRESS_DESC',
  LawyersByContactIdStddevPopulationStripeCustomerIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STRIPE_CUSTOMER_ID_ASC',
  LawyersByContactIdStddevPopulationStripeCustomerIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STRIPE_CUSTOMER_ID_DESC',
  LawyersByContactIdStddevPopulationStripeDiscountCodeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByContactIdStddevPopulationStripeDiscountCodeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByContactIdStddevPopulationStripePriceIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STRIPE_PRICE_ID_ASC',
  LawyersByContactIdStddevPopulationStripePriceIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_STRIPE_PRICE_ID_DESC',
  LawyersByContactIdStddevPopulationTimezoneAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_TIMEZONE_ASC',
  LawyersByContactIdStddevPopulationTimezoneDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_TIMEZONE_DESC',
  LawyersByContactIdStddevPopulationTitleAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_TITLE_ASC',
  LawyersByContactIdStddevPopulationTitleDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_TITLE_DESC',
  LawyersByContactIdStddevPopulationUpdatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  LawyersByContactIdStddevPopulationUpdatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  LawyersByContactIdStddevPopulationUserTypeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_USER_TYPE_ASC',
  LawyersByContactIdStddevPopulationUserTypeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_USER_TYPE_DESC',
  LawyersByContactIdStddevPopulationValuePropositionAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_VALUE_PROPOSITION_ASC',
  LawyersByContactIdStddevPopulationValuePropositionDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_VALUE_PROPOSITION_DESC',
  LawyersByContactIdStddevPopulationValuePropositionGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByContactIdStddevPopulationValuePropositionGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByContactIdStddevPopulationWantsToChooseDomainsAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByContactIdStddevPopulationWantsToChooseDomainsDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByContactIdStddevPopulationWebsiteUrlAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_WEBSITE_URL_ASC',
  LawyersByContactIdStddevPopulationWebsiteUrlDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_POPULATION_WEBSITE_URL_DESC',
  LawyersByContactIdStddevSampleAcquisitionChannelAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ACQUISITION_CHANNEL_ASC',
  LawyersByContactIdStddevSampleAcquisitionChannelDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ACQUISITION_CHANNEL_DESC',
  LawyersByContactIdStddevSampleAcquisitionTypeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ACQUISITION_TYPE_ASC',
  LawyersByContactIdStddevSampleAcquisitionTypeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ACQUISITION_TYPE_DESC',
  LawyersByContactIdStddevSampleAliasesAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ALIASES_ASC',
  LawyersByContactIdStddevSampleAliasesDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ALIASES_DESC',
  LawyersByContactIdStddevSampleBrandColorAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_BRAND_COLOR_ASC',
  LawyersByContactIdStddevSampleBrandColorDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_BRAND_COLOR_DESC',
  LawyersByContactIdStddevSampleCalendlyLinkAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CALENDLY_LINK_ASC',
  LawyersByContactIdStddevSampleCalendlyLinkDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CALENDLY_LINK_DESC',
  LawyersByContactIdStddevSampleCalendlyUriAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CALENDLY_URI_ASC',
  LawyersByContactIdStddevSampleCalendlyUriDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CALENDLY_URI_DESC',
  LawyersByContactIdStddevSampleChargedPerAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CHARGED_PER_ASC',
  LawyersByContactIdStddevSampleChargedPerDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CHARGED_PER_DESC',
  LawyersByContactIdStddevSampleChargeRateAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CHARGE_RATE_ASC',
  LawyersByContactIdStddevSampleChargeRateDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CHARGE_RATE_DESC',
  LawyersByContactIdStddevSampleCityAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CITY_ASC',
  LawyersByContactIdStddevSampleCityDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CITY_DESC',
  LawyersByContactIdStddevSampleClientProfileRequirementsAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByContactIdStddevSampleClientProfileRequirementsDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByContactIdStddevSampleContactIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  LawyersByContactIdStddevSampleContactIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  LawyersByContactIdStddevSampleCountryAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_COUNTRY_ASC',
  LawyersByContactIdStddevSampleCountryCodeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_COUNTRY_CODE_ASC',
  LawyersByContactIdStddevSampleCountryCodeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_COUNTRY_CODE_DESC',
  LawyersByContactIdStddevSampleCountryDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_COUNTRY_DESC',
  LawyersByContactIdStddevSampleCreatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LawyersByContactIdStddevSampleCreatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LawyersByContactIdStddevSampleEmailAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_EMAIL_ASC',
  LawyersByContactIdStddevSampleEmailDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_EMAIL_DESC',
  LawyersByContactIdStddevSampleEnableAutoTestimonialAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByContactIdStddevSampleEnableAutoTestimonialDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByContactIdStddevSampleExternalContactsLastUpdatedAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByContactIdStddevSampleExternalContactsLastUpdatedDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByContactIdStddevSampleFirmDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_FIRM_DESCRIPTION_ASC',
  LawyersByContactIdStddevSampleFirmDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_FIRM_DESCRIPTION_DESC',
  LawyersByContactIdStddevSampleFirmNameAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_FIRM_NAME_ASC',
  LawyersByContactIdStddevSampleFirmNameDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_FIRM_NAME_DESC',
  LawyersByContactIdStddevSampleFreeLeadsAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_FREE_LEADS_ASC',
  LawyersByContactIdStddevSampleFreeLeadsDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_FREE_LEADS_DESC',
  LawyersByContactIdStddevSampleGooglePlaceIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_GOOGLE_PLACE_ID_ASC',
  LawyersByContactIdStddevSampleGooglePlaceIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_GOOGLE_PLACE_ID_DESC',
  LawyersByContactIdStddevSampleHandoverMethodAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_HANDOVER_METHOD_ASC',
  LawyersByContactIdStddevSampleHandoverMethodDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_HANDOVER_METHOD_DESC',
  LawyersByContactIdStddevSampleHandoverStateAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_HANDOVER_STATE_ASC',
  LawyersByContactIdStddevSampleHandoverStateDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_HANDOVER_STATE_DESC',
  LawyersByContactIdStddevSampleHubspotDealIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_HUBSPOT_DEAL_ID_ASC',
  LawyersByContactIdStddevSampleHubspotDealIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_HUBSPOT_DEAL_ID_DESC',
  LawyersByContactIdStddevSampleIcpAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ICP_ASC',
  LawyersByContactIdStddevSampleIcpDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ICP_DESC',
  LawyersByContactIdStddevSampleIcpGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ICP_GUESSED_TIME_ASC',
  LawyersByContactIdStddevSampleIcpGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ICP_GUESSED_TIME_DESC',
  LawyersByContactIdStddevSampleIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByContactIdStddevSampleIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByContactIdStddevSampleIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  LawyersByContactIdStddevSampleIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  LawyersByContactIdStddevSampleIsManagedAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_IS_MANAGED_ASC',
  LawyersByContactIdStddevSampleIsManagedDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_IS_MANAGED_DESC',
  LawyersByContactIdStddevSampleIsPausedAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_IS_PAUSED_ASC',
  LawyersByContactIdStddevSampleIsPausedDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_IS_PAUSED_DESC',
  LawyersByContactIdStddevSampleLastAccountReviewAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByContactIdStddevSampleLastAccountReviewDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByContactIdStddevSampleLastReportTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LAST_REPORT_TIME_ASC',
  LawyersByContactIdStddevSampleLastReportTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LAST_REPORT_TIME_DESC',
  LawyersByContactIdStddevSampleLastStatusEmailTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByContactIdStddevSampleLastStatusEmailTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByContactIdStddevSampleLawyerDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_DESCRIPTION_ASC',
  LawyersByContactIdStddevSampleLawyerDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_DESCRIPTION_DESC',
  LawyersByContactIdStddevSampleLeadsPerMonthAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LEADS_PER_MONTH_ASC',
  LawyersByContactIdStddevSampleLeadsPerMonthDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LEADS_PER_MONTH_DESC',
  LawyersByContactIdStddevSampleLeverAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LEVER_ASC',
  LawyersByContactIdStddevSampleLeverDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LEVER_DESC',
  LawyersByContactIdStddevSampleLinkedinUrlAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LINKEDIN_URL_ASC',
  LawyersByContactIdStddevSampleLinkedinUrlDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LINKEDIN_URL_DESC',
  LawyersByContactIdStddevSampleLiEmailAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_EMAIL_ASC',
  LawyersByContactIdStddevSampleLiEmailDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_EMAIL_DESC',
  LawyersByContactIdStddevSampleLiEncryptedPwAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_ENCRYPTED_PW_ASC',
  LawyersByContactIdStddevSampleLiEncryptedPwDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_ENCRYPTED_PW_DESC',
  LawyersByContactIdStddevSampleLiLastActiveTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByContactIdStddevSampleLiLastActiveTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByContactIdStddevSampleLiLoginStatusAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_LOGIN_STATUS_ASC',
  LawyersByContactIdStddevSampleLiLoginStatusDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_LOGIN_STATUS_DESC',
  LawyersByContactIdStddevSampleLiProfileNameAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_PROFILE_NAME_ASC',
  LawyersByContactIdStddevSampleLiProfileNameDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_PROFILE_NAME_DESC',
  LawyersByContactIdStddevSampleLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByContactIdStddevSampleLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByContactIdStddevSampleLiVerificationCodeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_VERIFICATION_CODE_ASC',
  LawyersByContactIdStddevSampleLiVerificationCodeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LI_VERIFICATION_CODE_DESC',
  LawyersByContactIdStddevSampleLogoSrcAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LOGO_SRC_ASC',
  LawyersByContactIdStddevSampleLogoSrcDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_LOGO_SRC_DESC',
  LawyersByContactIdStddevSampleNameAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_NAME_ASC',
  LawyersByContactIdStddevSampleNameDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_NAME_DESC',
  LawyersByContactIdStddevSampleNylasAccessTokenAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByContactIdStddevSampleNylasAccessTokenDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByContactIdStddevSampleOnboardingFeePaidTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByContactIdStddevSampleOnboardingFeePaidTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByContactIdStddevSampleOnboardingTypeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ONBOARDING_TYPE_ASC',
  LawyersByContactIdStddevSampleOnboardingTypeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ONBOARDING_TYPE_DESC',
  LawyersByContactIdStddevSampleOrganizationIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ORGANIZATION_ID_ASC',
  LawyersByContactIdStddevSampleOrganizationIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_ORGANIZATION_ID_DESC',
  LawyersByContactIdStddevSamplePaymentMethodAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_PAYMENT_METHOD_ASC',
  LawyersByContactIdStddevSamplePaymentMethodDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_PAYMENT_METHOD_DESC',
  LawyersByContactIdStddevSamplePersonalisationRankingsAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_PERSONALISATION_RANKINGS_ASC',
  LawyersByContactIdStddevSamplePersonalisationRankingsDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_PERSONALISATION_RANKINGS_DESC',
  LawyersByContactIdStddevSamplePostalCodeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_POSTAL_CODE_ASC',
  LawyersByContactIdStddevSamplePostalCodeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_POSTAL_CODE_DESC',
  LawyersByContactIdStddevSamplePriorityAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_PRIORITY_ASC',
  LawyersByContactIdStddevSamplePriorityDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_PRIORITY_DESC',
  LawyersByContactIdStddevSampleProxyIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_PROXY_ID_ASC',
  LawyersByContactIdStddevSampleProxyIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_PROXY_ID_DESC',
  LawyersByContactIdStddevSamplePublicIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_PUBLIC_ID_ASC',
  LawyersByContactIdStddevSamplePublicIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_PUBLIC_ID_DESC',
  LawyersByContactIdStddevSampleReplyNotesAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_REPLY_NOTES_ASC',
  LawyersByContactIdStddevSampleReplyNotesDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_REPLY_NOTES_DESC',
  LawyersByContactIdStddevSampleSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByContactIdStddevSampleSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByContactIdStddevSampleSenderDisclaimerAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_SENDER_DISCLAIMER_ASC',
  LawyersByContactIdStddevSampleSenderDisclaimerDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_SENDER_DISCLAIMER_DESC',
  LawyersByContactIdStddevSampleSignatureHtmlAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_SIGNATURE_HTML_ASC',
  LawyersByContactIdStddevSampleSignatureHtmlDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_SIGNATURE_HTML_DESC',
  LawyersByContactIdStddevSampleSlackChannelNameAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_CHANNEL_NAME_ASC',
  LawyersByContactIdStddevSampleSlackChannelNameDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_CHANNEL_NAME_DESC',
  LawyersByContactIdStddevSampleSlackClientIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_CLIENT_ID_ASC',
  LawyersByContactIdStddevSampleSlackClientIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_CLIENT_ID_DESC',
  LawyersByContactIdStddevSampleSlackClientSecretAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_CLIENT_SECRET_ASC',
  LawyersByContactIdStddevSampleSlackClientSecretDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_CLIENT_SECRET_DESC',
  LawyersByContactIdStddevSampleStageAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STAGE_ASC',
  LawyersByContactIdStddevSampleStageDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STAGE_DESC',
  LawyersByContactIdStddevSampleStateAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STATE_ASC',
  LawyersByContactIdStddevSampleStateDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STATE_DESC',
  LawyersByContactIdStddevSampleStatusAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STATUS_ASC',
  LawyersByContactIdStddevSampleStatusDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STATUS_DESC',
  LawyersByContactIdStddevSampleStreetAddressAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STREET_ADDRESS_ASC',
  LawyersByContactIdStddevSampleStreetAddressDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STREET_ADDRESS_DESC',
  LawyersByContactIdStddevSampleStripeCustomerIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STRIPE_CUSTOMER_ID_ASC',
  LawyersByContactIdStddevSampleStripeCustomerIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STRIPE_CUSTOMER_ID_DESC',
  LawyersByContactIdStddevSampleStripeDiscountCodeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByContactIdStddevSampleStripeDiscountCodeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByContactIdStddevSampleStripePriceIdAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STRIPE_PRICE_ID_ASC',
  LawyersByContactIdStddevSampleStripePriceIdDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_STRIPE_PRICE_ID_DESC',
  LawyersByContactIdStddevSampleTimezoneAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_TIMEZONE_ASC',
  LawyersByContactIdStddevSampleTimezoneDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_TIMEZONE_DESC',
  LawyersByContactIdStddevSampleTitleAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_TITLE_ASC',
  LawyersByContactIdStddevSampleTitleDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_TITLE_DESC',
  LawyersByContactIdStddevSampleUpdatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  LawyersByContactIdStddevSampleUpdatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  LawyersByContactIdStddevSampleUserTypeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_USER_TYPE_ASC',
  LawyersByContactIdStddevSampleUserTypeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_USER_TYPE_DESC',
  LawyersByContactIdStddevSampleValuePropositionAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_VALUE_PROPOSITION_ASC',
  LawyersByContactIdStddevSampleValuePropositionDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_VALUE_PROPOSITION_DESC',
  LawyersByContactIdStddevSampleValuePropositionGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByContactIdStddevSampleValuePropositionGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByContactIdStddevSampleWantsToChooseDomainsAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByContactIdStddevSampleWantsToChooseDomainsDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByContactIdStddevSampleWebsiteUrlAsc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_WEBSITE_URL_ASC',
  LawyersByContactIdStddevSampleWebsiteUrlDesc = 'LAWYERS_BY_CONTACT_ID_STDDEV_SAMPLE_WEBSITE_URL_DESC',
  LawyersByContactIdSumAcquisitionChannelAsc = 'LAWYERS_BY_CONTACT_ID_SUM_ACQUISITION_CHANNEL_ASC',
  LawyersByContactIdSumAcquisitionChannelDesc = 'LAWYERS_BY_CONTACT_ID_SUM_ACQUISITION_CHANNEL_DESC',
  LawyersByContactIdSumAcquisitionTypeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_ACQUISITION_TYPE_ASC',
  LawyersByContactIdSumAcquisitionTypeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_ACQUISITION_TYPE_DESC',
  LawyersByContactIdSumAliasesAsc = 'LAWYERS_BY_CONTACT_ID_SUM_ALIASES_ASC',
  LawyersByContactIdSumAliasesDesc = 'LAWYERS_BY_CONTACT_ID_SUM_ALIASES_DESC',
  LawyersByContactIdSumBrandColorAsc = 'LAWYERS_BY_CONTACT_ID_SUM_BRAND_COLOR_ASC',
  LawyersByContactIdSumBrandColorDesc = 'LAWYERS_BY_CONTACT_ID_SUM_BRAND_COLOR_DESC',
  LawyersByContactIdSumCalendlyLinkAsc = 'LAWYERS_BY_CONTACT_ID_SUM_CALENDLY_LINK_ASC',
  LawyersByContactIdSumCalendlyLinkDesc = 'LAWYERS_BY_CONTACT_ID_SUM_CALENDLY_LINK_DESC',
  LawyersByContactIdSumCalendlyUriAsc = 'LAWYERS_BY_CONTACT_ID_SUM_CALENDLY_URI_ASC',
  LawyersByContactIdSumCalendlyUriDesc = 'LAWYERS_BY_CONTACT_ID_SUM_CALENDLY_URI_DESC',
  LawyersByContactIdSumChargedPerAsc = 'LAWYERS_BY_CONTACT_ID_SUM_CHARGED_PER_ASC',
  LawyersByContactIdSumChargedPerDesc = 'LAWYERS_BY_CONTACT_ID_SUM_CHARGED_PER_DESC',
  LawyersByContactIdSumChargeRateAsc = 'LAWYERS_BY_CONTACT_ID_SUM_CHARGE_RATE_ASC',
  LawyersByContactIdSumChargeRateDesc = 'LAWYERS_BY_CONTACT_ID_SUM_CHARGE_RATE_DESC',
  LawyersByContactIdSumCityAsc = 'LAWYERS_BY_CONTACT_ID_SUM_CITY_ASC',
  LawyersByContactIdSumCityDesc = 'LAWYERS_BY_CONTACT_ID_SUM_CITY_DESC',
  LawyersByContactIdSumClientProfileRequirementsAsc = 'LAWYERS_BY_CONTACT_ID_SUM_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByContactIdSumClientProfileRequirementsDesc = 'LAWYERS_BY_CONTACT_ID_SUM_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByContactIdSumContactIdAsc = 'LAWYERS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  LawyersByContactIdSumContactIdDesc = 'LAWYERS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  LawyersByContactIdSumCountryAsc = 'LAWYERS_BY_CONTACT_ID_SUM_COUNTRY_ASC',
  LawyersByContactIdSumCountryCodeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_COUNTRY_CODE_ASC',
  LawyersByContactIdSumCountryCodeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_COUNTRY_CODE_DESC',
  LawyersByContactIdSumCountryDesc = 'LAWYERS_BY_CONTACT_ID_SUM_COUNTRY_DESC',
  LawyersByContactIdSumCreatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  LawyersByContactIdSumCreatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  LawyersByContactIdSumEmailAsc = 'LAWYERS_BY_CONTACT_ID_SUM_EMAIL_ASC',
  LawyersByContactIdSumEmailDesc = 'LAWYERS_BY_CONTACT_ID_SUM_EMAIL_DESC',
  LawyersByContactIdSumEnableAutoTestimonialAsc = 'LAWYERS_BY_CONTACT_ID_SUM_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByContactIdSumEnableAutoTestimonialDesc = 'LAWYERS_BY_CONTACT_ID_SUM_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByContactIdSumExternalContactsLastUpdatedAsc = 'LAWYERS_BY_CONTACT_ID_SUM_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByContactIdSumExternalContactsLastUpdatedDesc = 'LAWYERS_BY_CONTACT_ID_SUM_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByContactIdSumFirmDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_SUM_FIRM_DESCRIPTION_ASC',
  LawyersByContactIdSumFirmDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_SUM_FIRM_DESCRIPTION_DESC',
  LawyersByContactIdSumFirmNameAsc = 'LAWYERS_BY_CONTACT_ID_SUM_FIRM_NAME_ASC',
  LawyersByContactIdSumFirmNameDesc = 'LAWYERS_BY_CONTACT_ID_SUM_FIRM_NAME_DESC',
  LawyersByContactIdSumFreeLeadsAsc = 'LAWYERS_BY_CONTACT_ID_SUM_FREE_LEADS_ASC',
  LawyersByContactIdSumFreeLeadsDesc = 'LAWYERS_BY_CONTACT_ID_SUM_FREE_LEADS_DESC',
  LawyersByContactIdSumGooglePlaceIdAsc = 'LAWYERS_BY_CONTACT_ID_SUM_GOOGLE_PLACE_ID_ASC',
  LawyersByContactIdSumGooglePlaceIdDesc = 'LAWYERS_BY_CONTACT_ID_SUM_GOOGLE_PLACE_ID_DESC',
  LawyersByContactIdSumHandoverMethodAsc = 'LAWYERS_BY_CONTACT_ID_SUM_HANDOVER_METHOD_ASC',
  LawyersByContactIdSumHandoverMethodDesc = 'LAWYERS_BY_CONTACT_ID_SUM_HANDOVER_METHOD_DESC',
  LawyersByContactIdSumHandoverStateAsc = 'LAWYERS_BY_CONTACT_ID_SUM_HANDOVER_STATE_ASC',
  LawyersByContactIdSumHandoverStateDesc = 'LAWYERS_BY_CONTACT_ID_SUM_HANDOVER_STATE_DESC',
  LawyersByContactIdSumHubspotDealIdAsc = 'LAWYERS_BY_CONTACT_ID_SUM_HUBSPOT_DEAL_ID_ASC',
  LawyersByContactIdSumHubspotDealIdDesc = 'LAWYERS_BY_CONTACT_ID_SUM_HUBSPOT_DEAL_ID_DESC',
  LawyersByContactIdSumIcpAsc = 'LAWYERS_BY_CONTACT_ID_SUM_ICP_ASC',
  LawyersByContactIdSumIcpDesc = 'LAWYERS_BY_CONTACT_ID_SUM_ICP_DESC',
  LawyersByContactIdSumIcpGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_ICP_GUESSED_TIME_ASC',
  LawyersByContactIdSumIcpGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_ICP_GUESSED_TIME_DESC',
  LawyersByContactIdSumIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_CONTACT_ID_SUM_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByContactIdSumIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_CONTACT_ID_SUM_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByContactIdSumIdAsc = 'LAWYERS_BY_CONTACT_ID_SUM_ID_ASC',
  LawyersByContactIdSumIdDesc = 'LAWYERS_BY_CONTACT_ID_SUM_ID_DESC',
  LawyersByContactIdSumIsManagedAsc = 'LAWYERS_BY_CONTACT_ID_SUM_IS_MANAGED_ASC',
  LawyersByContactIdSumIsManagedDesc = 'LAWYERS_BY_CONTACT_ID_SUM_IS_MANAGED_DESC',
  LawyersByContactIdSumIsPausedAsc = 'LAWYERS_BY_CONTACT_ID_SUM_IS_PAUSED_ASC',
  LawyersByContactIdSumIsPausedDesc = 'LAWYERS_BY_CONTACT_ID_SUM_IS_PAUSED_DESC',
  LawyersByContactIdSumLastAccountReviewAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByContactIdSumLastAccountReviewDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByContactIdSumLastReportTimeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LAST_REPORT_TIME_ASC',
  LawyersByContactIdSumLastReportTimeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LAST_REPORT_TIME_DESC',
  LawyersByContactIdSumLastStatusEmailTimeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByContactIdSumLastStatusEmailTimeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByContactIdSumLawyerDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LAWYER_DESCRIPTION_ASC',
  LawyersByContactIdSumLawyerDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LAWYER_DESCRIPTION_DESC',
  LawyersByContactIdSumLeadsPerMonthAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LEADS_PER_MONTH_ASC',
  LawyersByContactIdSumLeadsPerMonthDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LEADS_PER_MONTH_DESC',
  LawyersByContactIdSumLeverAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LEVER_ASC',
  LawyersByContactIdSumLeverDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LEVER_DESC',
  LawyersByContactIdSumLinkedinUrlAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LINKEDIN_URL_ASC',
  LawyersByContactIdSumLinkedinUrlDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LINKEDIN_URL_DESC',
  LawyersByContactIdSumLiEmailAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_EMAIL_ASC',
  LawyersByContactIdSumLiEmailDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_EMAIL_DESC',
  LawyersByContactIdSumLiEncryptedPwAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_ENCRYPTED_PW_ASC',
  LawyersByContactIdSumLiEncryptedPwDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_ENCRYPTED_PW_DESC',
  LawyersByContactIdSumLiLastActiveTimeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByContactIdSumLiLastActiveTimeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByContactIdSumLiLoginStatusAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_LOGIN_STATUS_ASC',
  LawyersByContactIdSumLiLoginStatusDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_LOGIN_STATUS_DESC',
  LawyersByContactIdSumLiProfileNameAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_PROFILE_NAME_ASC',
  LawyersByContactIdSumLiProfileNameDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_PROFILE_NAME_DESC',
  LawyersByContactIdSumLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByContactIdSumLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByContactIdSumLiVerificationCodeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_VERIFICATION_CODE_ASC',
  LawyersByContactIdSumLiVerificationCodeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LI_VERIFICATION_CODE_DESC',
  LawyersByContactIdSumLogoSrcAsc = 'LAWYERS_BY_CONTACT_ID_SUM_LOGO_SRC_ASC',
  LawyersByContactIdSumLogoSrcDesc = 'LAWYERS_BY_CONTACT_ID_SUM_LOGO_SRC_DESC',
  LawyersByContactIdSumNameAsc = 'LAWYERS_BY_CONTACT_ID_SUM_NAME_ASC',
  LawyersByContactIdSumNameDesc = 'LAWYERS_BY_CONTACT_ID_SUM_NAME_DESC',
  LawyersByContactIdSumNylasAccessTokenAsc = 'LAWYERS_BY_CONTACT_ID_SUM_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByContactIdSumNylasAccessTokenDesc = 'LAWYERS_BY_CONTACT_ID_SUM_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByContactIdSumOnboardingFeePaidTimeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByContactIdSumOnboardingFeePaidTimeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByContactIdSumOnboardingTypeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_ONBOARDING_TYPE_ASC',
  LawyersByContactIdSumOnboardingTypeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_ONBOARDING_TYPE_DESC',
  LawyersByContactIdSumOrganizationIdAsc = 'LAWYERS_BY_CONTACT_ID_SUM_ORGANIZATION_ID_ASC',
  LawyersByContactIdSumOrganizationIdDesc = 'LAWYERS_BY_CONTACT_ID_SUM_ORGANIZATION_ID_DESC',
  LawyersByContactIdSumPaymentMethodAsc = 'LAWYERS_BY_CONTACT_ID_SUM_PAYMENT_METHOD_ASC',
  LawyersByContactIdSumPaymentMethodDesc = 'LAWYERS_BY_CONTACT_ID_SUM_PAYMENT_METHOD_DESC',
  LawyersByContactIdSumPersonalisationRankingsAsc = 'LAWYERS_BY_CONTACT_ID_SUM_PERSONALISATION_RANKINGS_ASC',
  LawyersByContactIdSumPersonalisationRankingsDesc = 'LAWYERS_BY_CONTACT_ID_SUM_PERSONALISATION_RANKINGS_DESC',
  LawyersByContactIdSumPostalCodeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_POSTAL_CODE_ASC',
  LawyersByContactIdSumPostalCodeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_POSTAL_CODE_DESC',
  LawyersByContactIdSumPriorityAsc = 'LAWYERS_BY_CONTACT_ID_SUM_PRIORITY_ASC',
  LawyersByContactIdSumPriorityDesc = 'LAWYERS_BY_CONTACT_ID_SUM_PRIORITY_DESC',
  LawyersByContactIdSumProxyIdAsc = 'LAWYERS_BY_CONTACT_ID_SUM_PROXY_ID_ASC',
  LawyersByContactIdSumProxyIdDesc = 'LAWYERS_BY_CONTACT_ID_SUM_PROXY_ID_DESC',
  LawyersByContactIdSumPublicIdAsc = 'LAWYERS_BY_CONTACT_ID_SUM_PUBLIC_ID_ASC',
  LawyersByContactIdSumPublicIdDesc = 'LAWYERS_BY_CONTACT_ID_SUM_PUBLIC_ID_DESC',
  LawyersByContactIdSumReplyNotesAsc = 'LAWYERS_BY_CONTACT_ID_SUM_REPLY_NOTES_ASC',
  LawyersByContactIdSumReplyNotesDesc = 'LAWYERS_BY_CONTACT_ID_SUM_REPLY_NOTES_DESC',
  LawyersByContactIdSumSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_CONTACT_ID_SUM_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByContactIdSumSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_CONTACT_ID_SUM_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByContactIdSumSenderDisclaimerAsc = 'LAWYERS_BY_CONTACT_ID_SUM_SENDER_DISCLAIMER_ASC',
  LawyersByContactIdSumSenderDisclaimerDesc = 'LAWYERS_BY_CONTACT_ID_SUM_SENDER_DISCLAIMER_DESC',
  LawyersByContactIdSumSignatureHtmlAsc = 'LAWYERS_BY_CONTACT_ID_SUM_SIGNATURE_HTML_ASC',
  LawyersByContactIdSumSignatureHtmlDesc = 'LAWYERS_BY_CONTACT_ID_SUM_SIGNATURE_HTML_DESC',
  LawyersByContactIdSumSlackChannelNameAsc = 'LAWYERS_BY_CONTACT_ID_SUM_SLACK_CHANNEL_NAME_ASC',
  LawyersByContactIdSumSlackChannelNameDesc = 'LAWYERS_BY_CONTACT_ID_SUM_SLACK_CHANNEL_NAME_DESC',
  LawyersByContactIdSumSlackClientIdAsc = 'LAWYERS_BY_CONTACT_ID_SUM_SLACK_CLIENT_ID_ASC',
  LawyersByContactIdSumSlackClientIdDesc = 'LAWYERS_BY_CONTACT_ID_SUM_SLACK_CLIENT_ID_DESC',
  LawyersByContactIdSumSlackClientSecretAsc = 'LAWYERS_BY_CONTACT_ID_SUM_SLACK_CLIENT_SECRET_ASC',
  LawyersByContactIdSumSlackClientSecretDesc = 'LAWYERS_BY_CONTACT_ID_SUM_SLACK_CLIENT_SECRET_DESC',
  LawyersByContactIdSumStageAsc = 'LAWYERS_BY_CONTACT_ID_SUM_STAGE_ASC',
  LawyersByContactIdSumStageDesc = 'LAWYERS_BY_CONTACT_ID_SUM_STAGE_DESC',
  LawyersByContactIdSumStateAsc = 'LAWYERS_BY_CONTACT_ID_SUM_STATE_ASC',
  LawyersByContactIdSumStateDesc = 'LAWYERS_BY_CONTACT_ID_SUM_STATE_DESC',
  LawyersByContactIdSumStatusAsc = 'LAWYERS_BY_CONTACT_ID_SUM_STATUS_ASC',
  LawyersByContactIdSumStatusDesc = 'LAWYERS_BY_CONTACT_ID_SUM_STATUS_DESC',
  LawyersByContactIdSumStreetAddressAsc = 'LAWYERS_BY_CONTACT_ID_SUM_STREET_ADDRESS_ASC',
  LawyersByContactIdSumStreetAddressDesc = 'LAWYERS_BY_CONTACT_ID_SUM_STREET_ADDRESS_DESC',
  LawyersByContactIdSumStripeCustomerIdAsc = 'LAWYERS_BY_CONTACT_ID_SUM_STRIPE_CUSTOMER_ID_ASC',
  LawyersByContactIdSumStripeCustomerIdDesc = 'LAWYERS_BY_CONTACT_ID_SUM_STRIPE_CUSTOMER_ID_DESC',
  LawyersByContactIdSumStripeDiscountCodeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByContactIdSumStripeDiscountCodeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByContactIdSumStripePriceIdAsc = 'LAWYERS_BY_CONTACT_ID_SUM_STRIPE_PRICE_ID_ASC',
  LawyersByContactIdSumStripePriceIdDesc = 'LAWYERS_BY_CONTACT_ID_SUM_STRIPE_PRICE_ID_DESC',
  LawyersByContactIdSumTimezoneAsc = 'LAWYERS_BY_CONTACT_ID_SUM_TIMEZONE_ASC',
  LawyersByContactIdSumTimezoneDesc = 'LAWYERS_BY_CONTACT_ID_SUM_TIMEZONE_DESC',
  LawyersByContactIdSumTitleAsc = 'LAWYERS_BY_CONTACT_ID_SUM_TITLE_ASC',
  LawyersByContactIdSumTitleDesc = 'LAWYERS_BY_CONTACT_ID_SUM_TITLE_DESC',
  LawyersByContactIdSumUpdatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_UPDATED_TIME_ASC',
  LawyersByContactIdSumUpdatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_UPDATED_TIME_DESC',
  LawyersByContactIdSumUserTypeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_USER_TYPE_ASC',
  LawyersByContactIdSumUserTypeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_USER_TYPE_DESC',
  LawyersByContactIdSumValuePropositionAsc = 'LAWYERS_BY_CONTACT_ID_SUM_VALUE_PROPOSITION_ASC',
  LawyersByContactIdSumValuePropositionDesc = 'LAWYERS_BY_CONTACT_ID_SUM_VALUE_PROPOSITION_DESC',
  LawyersByContactIdSumValuePropositionGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_SUM_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByContactIdSumValuePropositionGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_SUM_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByContactIdSumWantsToChooseDomainsAsc = 'LAWYERS_BY_CONTACT_ID_SUM_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByContactIdSumWantsToChooseDomainsDesc = 'LAWYERS_BY_CONTACT_ID_SUM_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByContactIdSumWebsiteUrlAsc = 'LAWYERS_BY_CONTACT_ID_SUM_WEBSITE_URL_ASC',
  LawyersByContactIdSumWebsiteUrlDesc = 'LAWYERS_BY_CONTACT_ID_SUM_WEBSITE_URL_DESC',
  LawyersByContactIdVariancePopulationAcquisitionChannelAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ACQUISITION_CHANNEL_ASC',
  LawyersByContactIdVariancePopulationAcquisitionChannelDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ACQUISITION_CHANNEL_DESC',
  LawyersByContactIdVariancePopulationAcquisitionTypeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ACQUISITION_TYPE_ASC',
  LawyersByContactIdVariancePopulationAcquisitionTypeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ACQUISITION_TYPE_DESC',
  LawyersByContactIdVariancePopulationAliasesAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ALIASES_ASC',
  LawyersByContactIdVariancePopulationAliasesDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ALIASES_DESC',
  LawyersByContactIdVariancePopulationBrandColorAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_BRAND_COLOR_ASC',
  LawyersByContactIdVariancePopulationBrandColorDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_BRAND_COLOR_DESC',
  LawyersByContactIdVariancePopulationCalendlyLinkAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CALENDLY_LINK_ASC',
  LawyersByContactIdVariancePopulationCalendlyLinkDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CALENDLY_LINK_DESC',
  LawyersByContactIdVariancePopulationCalendlyUriAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CALENDLY_URI_ASC',
  LawyersByContactIdVariancePopulationCalendlyUriDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CALENDLY_URI_DESC',
  LawyersByContactIdVariancePopulationChargedPerAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CHARGED_PER_ASC',
  LawyersByContactIdVariancePopulationChargedPerDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CHARGED_PER_DESC',
  LawyersByContactIdVariancePopulationChargeRateAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CHARGE_RATE_ASC',
  LawyersByContactIdVariancePopulationChargeRateDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CHARGE_RATE_DESC',
  LawyersByContactIdVariancePopulationCityAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CITY_ASC',
  LawyersByContactIdVariancePopulationCityDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CITY_DESC',
  LawyersByContactIdVariancePopulationClientProfileRequirementsAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByContactIdVariancePopulationClientProfileRequirementsDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByContactIdVariancePopulationContactIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  LawyersByContactIdVariancePopulationContactIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  LawyersByContactIdVariancePopulationCountryAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_COUNTRY_ASC',
  LawyersByContactIdVariancePopulationCountryCodeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_COUNTRY_CODE_ASC',
  LawyersByContactIdVariancePopulationCountryCodeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_COUNTRY_CODE_DESC',
  LawyersByContactIdVariancePopulationCountryDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_COUNTRY_DESC',
  LawyersByContactIdVariancePopulationCreatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LawyersByContactIdVariancePopulationCreatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LawyersByContactIdVariancePopulationEmailAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_EMAIL_ASC',
  LawyersByContactIdVariancePopulationEmailDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_EMAIL_DESC',
  LawyersByContactIdVariancePopulationEnableAutoTestimonialAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByContactIdVariancePopulationEnableAutoTestimonialDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByContactIdVariancePopulationExternalContactsLastUpdatedAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByContactIdVariancePopulationExternalContactsLastUpdatedDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByContactIdVariancePopulationFirmDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_FIRM_DESCRIPTION_ASC',
  LawyersByContactIdVariancePopulationFirmDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_FIRM_DESCRIPTION_DESC',
  LawyersByContactIdVariancePopulationFirmNameAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_FIRM_NAME_ASC',
  LawyersByContactIdVariancePopulationFirmNameDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_FIRM_NAME_DESC',
  LawyersByContactIdVariancePopulationFreeLeadsAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_FREE_LEADS_ASC',
  LawyersByContactIdVariancePopulationFreeLeadsDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_FREE_LEADS_DESC',
  LawyersByContactIdVariancePopulationGooglePlaceIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_GOOGLE_PLACE_ID_ASC',
  LawyersByContactIdVariancePopulationGooglePlaceIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_GOOGLE_PLACE_ID_DESC',
  LawyersByContactIdVariancePopulationHandoverMethodAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_HANDOVER_METHOD_ASC',
  LawyersByContactIdVariancePopulationHandoverMethodDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_HANDOVER_METHOD_DESC',
  LawyersByContactIdVariancePopulationHandoverStateAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_HANDOVER_STATE_ASC',
  LawyersByContactIdVariancePopulationHandoverStateDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_HANDOVER_STATE_DESC',
  LawyersByContactIdVariancePopulationHubspotDealIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_HUBSPOT_DEAL_ID_ASC',
  LawyersByContactIdVariancePopulationHubspotDealIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_HUBSPOT_DEAL_ID_DESC',
  LawyersByContactIdVariancePopulationIcpAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ICP_ASC',
  LawyersByContactIdVariancePopulationIcpDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ICP_DESC',
  LawyersByContactIdVariancePopulationIcpGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ICP_GUESSED_TIME_ASC',
  LawyersByContactIdVariancePopulationIcpGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ICP_GUESSED_TIME_DESC',
  LawyersByContactIdVariancePopulationIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByContactIdVariancePopulationIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByContactIdVariancePopulationIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  LawyersByContactIdVariancePopulationIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  LawyersByContactIdVariancePopulationIsManagedAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_IS_MANAGED_ASC',
  LawyersByContactIdVariancePopulationIsManagedDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_IS_MANAGED_DESC',
  LawyersByContactIdVariancePopulationIsPausedAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_IS_PAUSED_ASC',
  LawyersByContactIdVariancePopulationIsPausedDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_IS_PAUSED_DESC',
  LawyersByContactIdVariancePopulationLastAccountReviewAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByContactIdVariancePopulationLastAccountReviewDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByContactIdVariancePopulationLastReportTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LAST_REPORT_TIME_ASC',
  LawyersByContactIdVariancePopulationLastReportTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LAST_REPORT_TIME_DESC',
  LawyersByContactIdVariancePopulationLastStatusEmailTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByContactIdVariancePopulationLastStatusEmailTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByContactIdVariancePopulationLawyerDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_DESCRIPTION_ASC',
  LawyersByContactIdVariancePopulationLawyerDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_DESCRIPTION_DESC',
  LawyersByContactIdVariancePopulationLeadsPerMonthAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LEADS_PER_MONTH_ASC',
  LawyersByContactIdVariancePopulationLeadsPerMonthDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LEADS_PER_MONTH_DESC',
  LawyersByContactIdVariancePopulationLeverAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LEVER_ASC',
  LawyersByContactIdVariancePopulationLeverDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LEVER_DESC',
  LawyersByContactIdVariancePopulationLinkedinUrlAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LINKEDIN_URL_ASC',
  LawyersByContactIdVariancePopulationLinkedinUrlDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LINKEDIN_URL_DESC',
  LawyersByContactIdVariancePopulationLiEmailAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_EMAIL_ASC',
  LawyersByContactIdVariancePopulationLiEmailDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_EMAIL_DESC',
  LawyersByContactIdVariancePopulationLiEncryptedPwAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_ENCRYPTED_PW_ASC',
  LawyersByContactIdVariancePopulationLiEncryptedPwDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_ENCRYPTED_PW_DESC',
  LawyersByContactIdVariancePopulationLiLastActiveTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByContactIdVariancePopulationLiLastActiveTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByContactIdVariancePopulationLiLoginStatusAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_LOGIN_STATUS_ASC',
  LawyersByContactIdVariancePopulationLiLoginStatusDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_LOGIN_STATUS_DESC',
  LawyersByContactIdVariancePopulationLiProfileNameAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_PROFILE_NAME_ASC',
  LawyersByContactIdVariancePopulationLiProfileNameDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_PROFILE_NAME_DESC',
  LawyersByContactIdVariancePopulationLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByContactIdVariancePopulationLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByContactIdVariancePopulationLiVerificationCodeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_VERIFICATION_CODE_ASC',
  LawyersByContactIdVariancePopulationLiVerificationCodeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LI_VERIFICATION_CODE_DESC',
  LawyersByContactIdVariancePopulationLogoSrcAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LOGO_SRC_ASC',
  LawyersByContactIdVariancePopulationLogoSrcDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_LOGO_SRC_DESC',
  LawyersByContactIdVariancePopulationNameAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_NAME_ASC',
  LawyersByContactIdVariancePopulationNameDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_NAME_DESC',
  LawyersByContactIdVariancePopulationNylasAccessTokenAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByContactIdVariancePopulationNylasAccessTokenDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByContactIdVariancePopulationOnboardingFeePaidTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByContactIdVariancePopulationOnboardingFeePaidTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByContactIdVariancePopulationOnboardingTypeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ONBOARDING_TYPE_ASC',
  LawyersByContactIdVariancePopulationOnboardingTypeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ONBOARDING_TYPE_DESC',
  LawyersByContactIdVariancePopulationOrganizationIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ORGANIZATION_ID_ASC',
  LawyersByContactIdVariancePopulationOrganizationIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_ORGANIZATION_ID_DESC',
  LawyersByContactIdVariancePopulationPaymentMethodAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_PAYMENT_METHOD_ASC',
  LawyersByContactIdVariancePopulationPaymentMethodDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_PAYMENT_METHOD_DESC',
  LawyersByContactIdVariancePopulationPersonalisationRankingsAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_PERSONALISATION_RANKINGS_ASC',
  LawyersByContactIdVariancePopulationPersonalisationRankingsDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_PERSONALISATION_RANKINGS_DESC',
  LawyersByContactIdVariancePopulationPostalCodeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_POSTAL_CODE_ASC',
  LawyersByContactIdVariancePopulationPostalCodeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_POSTAL_CODE_DESC',
  LawyersByContactIdVariancePopulationPriorityAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_PRIORITY_ASC',
  LawyersByContactIdVariancePopulationPriorityDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_PRIORITY_DESC',
  LawyersByContactIdVariancePopulationProxyIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_PROXY_ID_ASC',
  LawyersByContactIdVariancePopulationProxyIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_PROXY_ID_DESC',
  LawyersByContactIdVariancePopulationPublicIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_PUBLIC_ID_ASC',
  LawyersByContactIdVariancePopulationPublicIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_PUBLIC_ID_DESC',
  LawyersByContactIdVariancePopulationReplyNotesAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_REPLY_NOTES_ASC',
  LawyersByContactIdVariancePopulationReplyNotesDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_REPLY_NOTES_DESC',
  LawyersByContactIdVariancePopulationSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByContactIdVariancePopulationSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByContactIdVariancePopulationSenderDisclaimerAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_SENDER_DISCLAIMER_ASC',
  LawyersByContactIdVariancePopulationSenderDisclaimerDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_SENDER_DISCLAIMER_DESC',
  LawyersByContactIdVariancePopulationSignatureHtmlAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_SIGNATURE_HTML_ASC',
  LawyersByContactIdVariancePopulationSignatureHtmlDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_SIGNATURE_HTML_DESC',
  LawyersByContactIdVariancePopulationSlackChannelNameAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_CHANNEL_NAME_ASC',
  LawyersByContactIdVariancePopulationSlackChannelNameDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_CHANNEL_NAME_DESC',
  LawyersByContactIdVariancePopulationSlackClientIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_CLIENT_ID_ASC',
  LawyersByContactIdVariancePopulationSlackClientIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_CLIENT_ID_DESC',
  LawyersByContactIdVariancePopulationSlackClientSecretAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_CLIENT_SECRET_ASC',
  LawyersByContactIdVariancePopulationSlackClientSecretDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_CLIENT_SECRET_DESC',
  LawyersByContactIdVariancePopulationStageAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STAGE_ASC',
  LawyersByContactIdVariancePopulationStageDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STAGE_DESC',
  LawyersByContactIdVariancePopulationStateAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STATE_ASC',
  LawyersByContactIdVariancePopulationStateDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STATE_DESC',
  LawyersByContactIdVariancePopulationStatusAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STATUS_ASC',
  LawyersByContactIdVariancePopulationStatusDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STATUS_DESC',
  LawyersByContactIdVariancePopulationStreetAddressAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STREET_ADDRESS_ASC',
  LawyersByContactIdVariancePopulationStreetAddressDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STREET_ADDRESS_DESC',
  LawyersByContactIdVariancePopulationStripeCustomerIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STRIPE_CUSTOMER_ID_ASC',
  LawyersByContactIdVariancePopulationStripeCustomerIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STRIPE_CUSTOMER_ID_DESC',
  LawyersByContactIdVariancePopulationStripeDiscountCodeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByContactIdVariancePopulationStripeDiscountCodeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByContactIdVariancePopulationStripePriceIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STRIPE_PRICE_ID_ASC',
  LawyersByContactIdVariancePopulationStripePriceIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_STRIPE_PRICE_ID_DESC',
  LawyersByContactIdVariancePopulationTimezoneAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_TIMEZONE_ASC',
  LawyersByContactIdVariancePopulationTimezoneDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_TIMEZONE_DESC',
  LawyersByContactIdVariancePopulationTitleAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_TITLE_ASC',
  LawyersByContactIdVariancePopulationTitleDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_TITLE_DESC',
  LawyersByContactIdVariancePopulationUpdatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  LawyersByContactIdVariancePopulationUpdatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  LawyersByContactIdVariancePopulationUserTypeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_USER_TYPE_ASC',
  LawyersByContactIdVariancePopulationUserTypeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_USER_TYPE_DESC',
  LawyersByContactIdVariancePopulationValuePropositionAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_VALUE_PROPOSITION_ASC',
  LawyersByContactIdVariancePopulationValuePropositionDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_VALUE_PROPOSITION_DESC',
  LawyersByContactIdVariancePopulationValuePropositionGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByContactIdVariancePopulationValuePropositionGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByContactIdVariancePopulationWantsToChooseDomainsAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByContactIdVariancePopulationWantsToChooseDomainsDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByContactIdVariancePopulationWebsiteUrlAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_WEBSITE_URL_ASC',
  LawyersByContactIdVariancePopulationWebsiteUrlDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_POPULATION_WEBSITE_URL_DESC',
  LawyersByContactIdVarianceSampleAcquisitionChannelAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ACQUISITION_CHANNEL_ASC',
  LawyersByContactIdVarianceSampleAcquisitionChannelDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ACQUISITION_CHANNEL_DESC',
  LawyersByContactIdVarianceSampleAcquisitionTypeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ACQUISITION_TYPE_ASC',
  LawyersByContactIdVarianceSampleAcquisitionTypeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ACQUISITION_TYPE_DESC',
  LawyersByContactIdVarianceSampleAliasesAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ALIASES_ASC',
  LawyersByContactIdVarianceSampleAliasesDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ALIASES_DESC',
  LawyersByContactIdVarianceSampleBrandColorAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_BRAND_COLOR_ASC',
  LawyersByContactIdVarianceSampleBrandColorDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_BRAND_COLOR_DESC',
  LawyersByContactIdVarianceSampleCalendlyLinkAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CALENDLY_LINK_ASC',
  LawyersByContactIdVarianceSampleCalendlyLinkDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CALENDLY_LINK_DESC',
  LawyersByContactIdVarianceSampleCalendlyUriAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CALENDLY_URI_ASC',
  LawyersByContactIdVarianceSampleCalendlyUriDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CALENDLY_URI_DESC',
  LawyersByContactIdVarianceSampleChargedPerAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CHARGED_PER_ASC',
  LawyersByContactIdVarianceSampleChargedPerDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CHARGED_PER_DESC',
  LawyersByContactIdVarianceSampleChargeRateAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CHARGE_RATE_ASC',
  LawyersByContactIdVarianceSampleChargeRateDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CHARGE_RATE_DESC',
  LawyersByContactIdVarianceSampleCityAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CITY_ASC',
  LawyersByContactIdVarianceSampleCityDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CITY_DESC',
  LawyersByContactIdVarianceSampleClientProfileRequirementsAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByContactIdVarianceSampleClientProfileRequirementsDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByContactIdVarianceSampleContactIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  LawyersByContactIdVarianceSampleContactIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  LawyersByContactIdVarianceSampleCountryAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_COUNTRY_ASC',
  LawyersByContactIdVarianceSampleCountryCodeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_COUNTRY_CODE_ASC',
  LawyersByContactIdVarianceSampleCountryCodeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_COUNTRY_CODE_DESC',
  LawyersByContactIdVarianceSampleCountryDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_COUNTRY_DESC',
  LawyersByContactIdVarianceSampleCreatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LawyersByContactIdVarianceSampleCreatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LawyersByContactIdVarianceSampleEmailAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_EMAIL_ASC',
  LawyersByContactIdVarianceSampleEmailDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_EMAIL_DESC',
  LawyersByContactIdVarianceSampleEnableAutoTestimonialAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByContactIdVarianceSampleEnableAutoTestimonialDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByContactIdVarianceSampleExternalContactsLastUpdatedAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByContactIdVarianceSampleExternalContactsLastUpdatedDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByContactIdVarianceSampleFirmDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_FIRM_DESCRIPTION_ASC',
  LawyersByContactIdVarianceSampleFirmDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_FIRM_DESCRIPTION_DESC',
  LawyersByContactIdVarianceSampleFirmNameAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_FIRM_NAME_ASC',
  LawyersByContactIdVarianceSampleFirmNameDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_FIRM_NAME_DESC',
  LawyersByContactIdVarianceSampleFreeLeadsAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_FREE_LEADS_ASC',
  LawyersByContactIdVarianceSampleFreeLeadsDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_FREE_LEADS_DESC',
  LawyersByContactIdVarianceSampleGooglePlaceIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_GOOGLE_PLACE_ID_ASC',
  LawyersByContactIdVarianceSampleGooglePlaceIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_GOOGLE_PLACE_ID_DESC',
  LawyersByContactIdVarianceSampleHandoverMethodAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_HANDOVER_METHOD_ASC',
  LawyersByContactIdVarianceSampleHandoverMethodDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_HANDOVER_METHOD_DESC',
  LawyersByContactIdVarianceSampleHandoverStateAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_HANDOVER_STATE_ASC',
  LawyersByContactIdVarianceSampleHandoverStateDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_HANDOVER_STATE_DESC',
  LawyersByContactIdVarianceSampleHubspotDealIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_HUBSPOT_DEAL_ID_ASC',
  LawyersByContactIdVarianceSampleHubspotDealIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_HUBSPOT_DEAL_ID_DESC',
  LawyersByContactIdVarianceSampleIcpAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ICP_ASC',
  LawyersByContactIdVarianceSampleIcpDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ICP_DESC',
  LawyersByContactIdVarianceSampleIcpGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ICP_GUESSED_TIME_ASC',
  LawyersByContactIdVarianceSampleIcpGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ICP_GUESSED_TIME_DESC',
  LawyersByContactIdVarianceSampleIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByContactIdVarianceSampleIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByContactIdVarianceSampleIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  LawyersByContactIdVarianceSampleIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  LawyersByContactIdVarianceSampleIsManagedAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_MANAGED_ASC',
  LawyersByContactIdVarianceSampleIsManagedDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_MANAGED_DESC',
  LawyersByContactIdVarianceSampleIsPausedAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_PAUSED_ASC',
  LawyersByContactIdVarianceSampleIsPausedDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_PAUSED_DESC',
  LawyersByContactIdVarianceSampleLastAccountReviewAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByContactIdVarianceSampleLastAccountReviewDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByContactIdVarianceSampleLastReportTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAST_REPORT_TIME_ASC',
  LawyersByContactIdVarianceSampleLastReportTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAST_REPORT_TIME_DESC',
  LawyersByContactIdVarianceSampleLastStatusEmailTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByContactIdVarianceSampleLastStatusEmailTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByContactIdVarianceSampleLawyerDescriptionAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_DESCRIPTION_ASC',
  LawyersByContactIdVarianceSampleLawyerDescriptionDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_DESCRIPTION_DESC',
  LawyersByContactIdVarianceSampleLeadsPerMonthAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LEADS_PER_MONTH_ASC',
  LawyersByContactIdVarianceSampleLeadsPerMonthDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LEADS_PER_MONTH_DESC',
  LawyersByContactIdVarianceSampleLeverAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LEVER_ASC',
  LawyersByContactIdVarianceSampleLeverDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LEVER_DESC',
  LawyersByContactIdVarianceSampleLinkedinUrlAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LINKEDIN_URL_ASC',
  LawyersByContactIdVarianceSampleLinkedinUrlDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LINKEDIN_URL_DESC',
  LawyersByContactIdVarianceSampleLiEmailAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_EMAIL_ASC',
  LawyersByContactIdVarianceSampleLiEmailDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_EMAIL_DESC',
  LawyersByContactIdVarianceSampleLiEncryptedPwAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_ENCRYPTED_PW_ASC',
  LawyersByContactIdVarianceSampleLiEncryptedPwDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_ENCRYPTED_PW_DESC',
  LawyersByContactIdVarianceSampleLiLastActiveTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByContactIdVarianceSampleLiLastActiveTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByContactIdVarianceSampleLiLoginStatusAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_LOGIN_STATUS_ASC',
  LawyersByContactIdVarianceSampleLiLoginStatusDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_LOGIN_STATUS_DESC',
  LawyersByContactIdVarianceSampleLiProfileNameAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_PROFILE_NAME_ASC',
  LawyersByContactIdVarianceSampleLiProfileNameDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_PROFILE_NAME_DESC',
  LawyersByContactIdVarianceSampleLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByContactIdVarianceSampleLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByContactIdVarianceSampleLiVerificationCodeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_VERIFICATION_CODE_ASC',
  LawyersByContactIdVarianceSampleLiVerificationCodeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LI_VERIFICATION_CODE_DESC',
  LawyersByContactIdVarianceSampleLogoSrcAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LOGO_SRC_ASC',
  LawyersByContactIdVarianceSampleLogoSrcDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LOGO_SRC_DESC',
  LawyersByContactIdVarianceSampleNameAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_NAME_ASC',
  LawyersByContactIdVarianceSampleNameDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_NAME_DESC',
  LawyersByContactIdVarianceSampleNylasAccessTokenAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByContactIdVarianceSampleNylasAccessTokenDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByContactIdVarianceSampleOnboardingFeePaidTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByContactIdVarianceSampleOnboardingFeePaidTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByContactIdVarianceSampleOnboardingTypeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ONBOARDING_TYPE_ASC',
  LawyersByContactIdVarianceSampleOnboardingTypeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ONBOARDING_TYPE_DESC',
  LawyersByContactIdVarianceSampleOrganizationIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ORGANIZATION_ID_ASC',
  LawyersByContactIdVarianceSampleOrganizationIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ORGANIZATION_ID_DESC',
  LawyersByContactIdVarianceSamplePaymentMethodAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_PAYMENT_METHOD_ASC',
  LawyersByContactIdVarianceSamplePaymentMethodDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_PAYMENT_METHOD_DESC',
  LawyersByContactIdVarianceSamplePersonalisationRankingsAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_PERSONALISATION_RANKINGS_ASC',
  LawyersByContactIdVarianceSamplePersonalisationRankingsDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_PERSONALISATION_RANKINGS_DESC',
  LawyersByContactIdVarianceSamplePostalCodeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_POSTAL_CODE_ASC',
  LawyersByContactIdVarianceSamplePostalCodeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_POSTAL_CODE_DESC',
  LawyersByContactIdVarianceSamplePriorityAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_PRIORITY_ASC',
  LawyersByContactIdVarianceSamplePriorityDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_PRIORITY_DESC',
  LawyersByContactIdVarianceSampleProxyIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_PROXY_ID_ASC',
  LawyersByContactIdVarianceSampleProxyIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_PROXY_ID_DESC',
  LawyersByContactIdVarianceSamplePublicIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_PUBLIC_ID_ASC',
  LawyersByContactIdVarianceSamplePublicIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_PUBLIC_ID_DESC',
  LawyersByContactIdVarianceSampleReplyNotesAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_REPLY_NOTES_ASC',
  LawyersByContactIdVarianceSampleReplyNotesDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_REPLY_NOTES_DESC',
  LawyersByContactIdVarianceSampleSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByContactIdVarianceSampleSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByContactIdVarianceSampleSenderDisclaimerAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SENDER_DISCLAIMER_ASC',
  LawyersByContactIdVarianceSampleSenderDisclaimerDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SENDER_DISCLAIMER_DESC',
  LawyersByContactIdVarianceSampleSignatureHtmlAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SIGNATURE_HTML_ASC',
  LawyersByContactIdVarianceSampleSignatureHtmlDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SIGNATURE_HTML_DESC',
  LawyersByContactIdVarianceSampleSlackChannelNameAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_CHANNEL_NAME_ASC',
  LawyersByContactIdVarianceSampleSlackChannelNameDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_CHANNEL_NAME_DESC',
  LawyersByContactIdVarianceSampleSlackClientIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_CLIENT_ID_ASC',
  LawyersByContactIdVarianceSampleSlackClientIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_CLIENT_ID_DESC',
  LawyersByContactIdVarianceSampleSlackClientSecretAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_CLIENT_SECRET_ASC',
  LawyersByContactIdVarianceSampleSlackClientSecretDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_CLIENT_SECRET_DESC',
  LawyersByContactIdVarianceSampleStageAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STAGE_ASC',
  LawyersByContactIdVarianceSampleStageDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STAGE_DESC',
  LawyersByContactIdVarianceSampleStateAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STATE_ASC',
  LawyersByContactIdVarianceSampleStateDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STATE_DESC',
  LawyersByContactIdVarianceSampleStatusAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STATUS_ASC',
  LawyersByContactIdVarianceSampleStatusDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STATUS_DESC',
  LawyersByContactIdVarianceSampleStreetAddressAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STREET_ADDRESS_ASC',
  LawyersByContactIdVarianceSampleStreetAddressDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STREET_ADDRESS_DESC',
  LawyersByContactIdVarianceSampleStripeCustomerIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STRIPE_CUSTOMER_ID_ASC',
  LawyersByContactIdVarianceSampleStripeCustomerIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STRIPE_CUSTOMER_ID_DESC',
  LawyersByContactIdVarianceSampleStripeDiscountCodeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByContactIdVarianceSampleStripeDiscountCodeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByContactIdVarianceSampleStripePriceIdAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STRIPE_PRICE_ID_ASC',
  LawyersByContactIdVarianceSampleStripePriceIdDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_STRIPE_PRICE_ID_DESC',
  LawyersByContactIdVarianceSampleTimezoneAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_TIMEZONE_ASC',
  LawyersByContactIdVarianceSampleTimezoneDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_TIMEZONE_DESC',
  LawyersByContactIdVarianceSampleTitleAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_TITLE_ASC',
  LawyersByContactIdVarianceSampleTitleDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_TITLE_DESC',
  LawyersByContactIdVarianceSampleUpdatedTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  LawyersByContactIdVarianceSampleUpdatedTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  LawyersByContactIdVarianceSampleUserTypeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_USER_TYPE_ASC',
  LawyersByContactIdVarianceSampleUserTypeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_USER_TYPE_DESC',
  LawyersByContactIdVarianceSampleValuePropositionAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_VALUE_PROPOSITION_ASC',
  LawyersByContactIdVarianceSampleValuePropositionDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_VALUE_PROPOSITION_DESC',
  LawyersByContactIdVarianceSampleValuePropositionGuessedTimeAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByContactIdVarianceSampleValuePropositionGuessedTimeDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByContactIdVarianceSampleWantsToChooseDomainsAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByContactIdVarianceSampleWantsToChooseDomainsDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByContactIdVarianceSampleWebsiteUrlAsc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_WEBSITE_URL_ASC',
  LawyersByContactIdVarianceSampleWebsiteUrlDesc = 'LAWYERS_BY_CONTACT_ID_VARIANCE_SAMPLE_WEBSITE_URL_DESC',
  LinkedinCompanyNameAsc = 'LINKEDIN_COMPANY_NAME_ASC',
  LinkedinCompanyNameDesc = 'LINKEDIN_COMPANY_NAME_DESC',
  LinkedinNameAsc = 'LINKEDIN_NAME_ASC',
  LinkedinNameDesc = 'LINKEDIN_NAME_DESC',
  LinkedinScheduledMessagesByContactIdAverageCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByContactIdAverageCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByContactIdAverageCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByContactIdAverageCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByContactIdAverageCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByContactIdAverageCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByContactIdAverageContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByContactIdAverageContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByContactIdAverageContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_CONTENT_ASC',
  LinkedinScheduledMessagesByContactIdAverageContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_CONTENT_DESC',
  LinkedinScheduledMessagesByContactIdAverageCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdAverageCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdAverageForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByContactIdAverageForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByContactIdAverageIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_ID_ASC',
  LinkedinScheduledMessagesByContactIdAverageIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_ID_DESC',
  LinkedinScheduledMessagesByContactIdAverageIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_INTENT_ASC',
  LinkedinScheduledMessagesByContactIdAverageIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_INTENT_DESC',
  LinkedinScheduledMessagesByContactIdAverageIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByContactIdAverageIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByContactIdAverageIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByContactIdAverageIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByContactIdAverageIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByContactIdAverageIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByContactIdAverageLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByContactIdAverageLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByContactIdAverageLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdAverageLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdAverageRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdAverageRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdAverageReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdAverageReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdAverageReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdAverageReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdAverageSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_SEND_AT_ASC',
  LinkedinScheduledMessagesByContactIdAverageSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_SEND_AT_DESC',
  LinkedinScheduledMessagesByContactIdAverageStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdAverageStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdAverageUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdAverageUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdCountAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_COUNT_ASC',
  LinkedinScheduledMessagesByContactIdCountDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_COUNT_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CONTENT_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CONTENT_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_INTENT_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_INTENT_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_SEND_AT_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_SEND_AT_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdDistinctCountUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdDistinctCountUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdMaxCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByContactIdMaxCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByContactIdMaxCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByContactIdMaxCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByContactIdMaxCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByContactIdMaxCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByContactIdMaxContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByContactIdMaxContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByContactIdMaxContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_CONTENT_ASC',
  LinkedinScheduledMessagesByContactIdMaxContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_CONTENT_DESC',
  LinkedinScheduledMessagesByContactIdMaxCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdMaxCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdMaxForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByContactIdMaxForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByContactIdMaxIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_ID_ASC',
  LinkedinScheduledMessagesByContactIdMaxIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_ID_DESC',
  LinkedinScheduledMessagesByContactIdMaxIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_INTENT_ASC',
  LinkedinScheduledMessagesByContactIdMaxIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_INTENT_DESC',
  LinkedinScheduledMessagesByContactIdMaxIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByContactIdMaxIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByContactIdMaxIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByContactIdMaxIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByContactIdMaxIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByContactIdMaxIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByContactIdMaxLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByContactIdMaxLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByContactIdMaxLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdMaxLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdMaxRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdMaxRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdMaxReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdMaxReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdMaxReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdMaxReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdMaxSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_SEND_AT_ASC',
  LinkedinScheduledMessagesByContactIdMaxSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_SEND_AT_DESC',
  LinkedinScheduledMessagesByContactIdMaxStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdMaxStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdMaxUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdMaxUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MAX_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdMinCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByContactIdMinCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByContactIdMinCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByContactIdMinCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByContactIdMinCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByContactIdMinCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByContactIdMinContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByContactIdMinContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByContactIdMinContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_CONTENT_ASC',
  LinkedinScheduledMessagesByContactIdMinContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_CONTENT_DESC',
  LinkedinScheduledMessagesByContactIdMinCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdMinCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdMinForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByContactIdMinForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByContactIdMinIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_ID_ASC',
  LinkedinScheduledMessagesByContactIdMinIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_ID_DESC',
  LinkedinScheduledMessagesByContactIdMinIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_INTENT_ASC',
  LinkedinScheduledMessagesByContactIdMinIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_INTENT_DESC',
  LinkedinScheduledMessagesByContactIdMinIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByContactIdMinIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByContactIdMinIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByContactIdMinIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByContactIdMinIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByContactIdMinIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByContactIdMinLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByContactIdMinLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByContactIdMinLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdMinLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdMinRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdMinRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdMinReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdMinReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdMinReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdMinReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdMinSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_SEND_AT_ASC',
  LinkedinScheduledMessagesByContactIdMinSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_SEND_AT_DESC',
  LinkedinScheduledMessagesByContactIdMinStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdMinStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdMinUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdMinUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_MIN_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CONTENT_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CONTENT_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_INTENT_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_INTENT_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_SEND_AT_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_SEND_AT_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdStddevPopulationUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdStddevPopulationUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CONTENT_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CONTENT_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_INTENT_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_INTENT_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_SEND_AT_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_SEND_AT_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdStddevSampleUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdStddevSampleUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdSumCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByContactIdSumCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByContactIdSumCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByContactIdSumCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByContactIdSumCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByContactIdSumCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByContactIdSumContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByContactIdSumContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByContactIdSumContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_CONTENT_ASC',
  LinkedinScheduledMessagesByContactIdSumContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_CONTENT_DESC',
  LinkedinScheduledMessagesByContactIdSumCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdSumCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdSumForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByContactIdSumForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByContactIdSumIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_ID_ASC',
  LinkedinScheduledMessagesByContactIdSumIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_ID_DESC',
  LinkedinScheduledMessagesByContactIdSumIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_INTENT_ASC',
  LinkedinScheduledMessagesByContactIdSumIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_INTENT_DESC',
  LinkedinScheduledMessagesByContactIdSumIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByContactIdSumIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByContactIdSumIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByContactIdSumIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByContactIdSumIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByContactIdSumIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByContactIdSumLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByContactIdSumLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByContactIdSumLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdSumLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdSumRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdSumRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdSumReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdSumReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdSumReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdSumReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdSumSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_SEND_AT_ASC',
  LinkedinScheduledMessagesByContactIdSumSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_SEND_AT_DESC',
  LinkedinScheduledMessagesByContactIdSumStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdSumStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdSumUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdSumUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_SUM_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CONTENT_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CONTENT_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_INTENT_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_INTENT_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_SEND_AT_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_SEND_AT_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdVariancePopulationUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdVariancePopulationUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_INTENT_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_INTENT_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_SEND_AT_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_SEND_AT_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_STATUS_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_STATUS_DESC',
  LinkedinScheduledMessagesByContactIdVarianceSampleUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByContactIdVarianceSampleUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  LinkedinUidAsc = 'LINKEDIN_UID_ASC',
  LinkedinUidDesc = 'LINKEDIN_UID_DESC',
  LinkedinUrlAsc = 'LINKEDIN_URL_ASC',
  LinkedinUrlDesc = 'LINKEDIN_URL_DESC',
  MeetingInviteesByContactIdAverageCalendlyUriAsc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_CALENDLY_URI_ASC',
  MeetingInviteesByContactIdAverageCalendlyUriDesc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_CALENDLY_URI_DESC',
  MeetingInviteesByContactIdAverageCancelUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_CANCEL_URL_ASC',
  MeetingInviteesByContactIdAverageCancelUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_CANCEL_URL_DESC',
  MeetingInviteesByContactIdAverageContactIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  MeetingInviteesByContactIdAverageContactIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  MeetingInviteesByContactIdAverageIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_ID_ASC',
  MeetingInviteesByContactIdAverageIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_ID_DESC',
  MeetingInviteesByContactIdAverageIsNoShowAsc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_IS_NO_SHOW_ASC',
  MeetingInviteesByContactIdAverageIsNoShowDesc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_IS_NO_SHOW_DESC',
  MeetingInviteesByContactIdAverageMeetingIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_MEETING_ID_ASC',
  MeetingInviteesByContactIdAverageMeetingIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_MEETING_ID_DESC',
  MeetingInviteesByContactIdAverageRescheduledAsc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_RESCHEDULED_ASC',
  MeetingInviteesByContactIdAverageRescheduledDesc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_RESCHEDULED_DESC',
  MeetingInviteesByContactIdAverageRescheduleUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_RESCHEDULE_URL_ASC',
  MeetingInviteesByContactIdAverageRescheduleUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_RESCHEDULE_URL_DESC',
  MeetingInviteesByContactIdAverageStatusAsc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_STATUS_ASC',
  MeetingInviteesByContactIdAverageStatusDesc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_STATUS_DESC',
  MeetingInviteesByContactIdAverageTimezoneAsc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_TIMEZONE_ASC',
  MeetingInviteesByContactIdAverageTimezoneDesc = 'MEETING_INVITEES_BY_CONTACT_ID_AVERAGE_TIMEZONE_DESC',
  MeetingInviteesByContactIdCountAsc = 'MEETING_INVITEES_BY_CONTACT_ID_COUNT_ASC',
  MeetingInviteesByContactIdCountDesc = 'MEETING_INVITEES_BY_CONTACT_ID_COUNT_DESC',
  MeetingInviteesByContactIdDistinctCountCalendlyUriAsc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_CALENDLY_URI_ASC',
  MeetingInviteesByContactIdDistinctCountCalendlyUriDesc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_CALENDLY_URI_DESC',
  MeetingInviteesByContactIdDistinctCountCancelUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_CANCEL_URL_ASC',
  MeetingInviteesByContactIdDistinctCountCancelUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_CANCEL_URL_DESC',
  MeetingInviteesByContactIdDistinctCountContactIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  MeetingInviteesByContactIdDistinctCountContactIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  MeetingInviteesByContactIdDistinctCountIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  MeetingInviteesByContactIdDistinctCountIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  MeetingInviteesByContactIdDistinctCountIsNoShowAsc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_IS_NO_SHOW_ASC',
  MeetingInviteesByContactIdDistinctCountIsNoShowDesc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_IS_NO_SHOW_DESC',
  MeetingInviteesByContactIdDistinctCountMeetingIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_MEETING_ID_ASC',
  MeetingInviteesByContactIdDistinctCountMeetingIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_MEETING_ID_DESC',
  MeetingInviteesByContactIdDistinctCountRescheduledAsc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_RESCHEDULED_ASC',
  MeetingInviteesByContactIdDistinctCountRescheduledDesc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_RESCHEDULED_DESC',
  MeetingInviteesByContactIdDistinctCountRescheduleUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_RESCHEDULE_URL_ASC',
  MeetingInviteesByContactIdDistinctCountRescheduleUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_RESCHEDULE_URL_DESC',
  MeetingInviteesByContactIdDistinctCountStatusAsc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_STATUS_ASC',
  MeetingInviteesByContactIdDistinctCountStatusDesc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_STATUS_DESC',
  MeetingInviteesByContactIdDistinctCountTimezoneAsc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_TIMEZONE_ASC',
  MeetingInviteesByContactIdDistinctCountTimezoneDesc = 'MEETING_INVITEES_BY_CONTACT_ID_DISTINCT_COUNT_TIMEZONE_DESC',
  MeetingInviteesByContactIdMaxCalendlyUriAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_CALENDLY_URI_ASC',
  MeetingInviteesByContactIdMaxCalendlyUriDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_CALENDLY_URI_DESC',
  MeetingInviteesByContactIdMaxCancelUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_CANCEL_URL_ASC',
  MeetingInviteesByContactIdMaxCancelUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_CANCEL_URL_DESC',
  MeetingInviteesByContactIdMaxContactIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  MeetingInviteesByContactIdMaxContactIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  MeetingInviteesByContactIdMaxIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_ID_ASC',
  MeetingInviteesByContactIdMaxIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_ID_DESC',
  MeetingInviteesByContactIdMaxIsNoShowAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_IS_NO_SHOW_ASC',
  MeetingInviteesByContactIdMaxIsNoShowDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_IS_NO_SHOW_DESC',
  MeetingInviteesByContactIdMaxMeetingIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_MEETING_ID_ASC',
  MeetingInviteesByContactIdMaxMeetingIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_MEETING_ID_DESC',
  MeetingInviteesByContactIdMaxRescheduledAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_RESCHEDULED_ASC',
  MeetingInviteesByContactIdMaxRescheduledDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_RESCHEDULED_DESC',
  MeetingInviteesByContactIdMaxRescheduleUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_RESCHEDULE_URL_ASC',
  MeetingInviteesByContactIdMaxRescheduleUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_RESCHEDULE_URL_DESC',
  MeetingInviteesByContactIdMaxStatusAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_STATUS_ASC',
  MeetingInviteesByContactIdMaxStatusDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_STATUS_DESC',
  MeetingInviteesByContactIdMaxTimezoneAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_TIMEZONE_ASC',
  MeetingInviteesByContactIdMaxTimezoneDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MAX_TIMEZONE_DESC',
  MeetingInviteesByContactIdMinCalendlyUriAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_CALENDLY_URI_ASC',
  MeetingInviteesByContactIdMinCalendlyUriDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_CALENDLY_URI_DESC',
  MeetingInviteesByContactIdMinCancelUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_CANCEL_URL_ASC',
  MeetingInviteesByContactIdMinCancelUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_CANCEL_URL_DESC',
  MeetingInviteesByContactIdMinContactIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  MeetingInviteesByContactIdMinContactIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  MeetingInviteesByContactIdMinIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_ID_ASC',
  MeetingInviteesByContactIdMinIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_ID_DESC',
  MeetingInviteesByContactIdMinIsNoShowAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_IS_NO_SHOW_ASC',
  MeetingInviteesByContactIdMinIsNoShowDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_IS_NO_SHOW_DESC',
  MeetingInviteesByContactIdMinMeetingIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_MEETING_ID_ASC',
  MeetingInviteesByContactIdMinMeetingIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_MEETING_ID_DESC',
  MeetingInviteesByContactIdMinRescheduledAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_RESCHEDULED_ASC',
  MeetingInviteesByContactIdMinRescheduledDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_RESCHEDULED_DESC',
  MeetingInviteesByContactIdMinRescheduleUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_RESCHEDULE_URL_ASC',
  MeetingInviteesByContactIdMinRescheduleUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_RESCHEDULE_URL_DESC',
  MeetingInviteesByContactIdMinStatusAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_STATUS_ASC',
  MeetingInviteesByContactIdMinStatusDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_STATUS_DESC',
  MeetingInviteesByContactIdMinTimezoneAsc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_TIMEZONE_ASC',
  MeetingInviteesByContactIdMinTimezoneDesc = 'MEETING_INVITEES_BY_CONTACT_ID_MIN_TIMEZONE_DESC',
  MeetingInviteesByContactIdStddevPopulationCalendlyUriAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_CALENDLY_URI_ASC',
  MeetingInviteesByContactIdStddevPopulationCalendlyUriDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_CALENDLY_URI_DESC',
  MeetingInviteesByContactIdStddevPopulationCancelUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_CANCEL_URL_ASC',
  MeetingInviteesByContactIdStddevPopulationCancelUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_CANCEL_URL_DESC',
  MeetingInviteesByContactIdStddevPopulationContactIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  MeetingInviteesByContactIdStddevPopulationContactIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  MeetingInviteesByContactIdStddevPopulationIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  MeetingInviteesByContactIdStddevPopulationIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  MeetingInviteesByContactIdStddevPopulationIsNoShowAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_IS_NO_SHOW_ASC',
  MeetingInviteesByContactIdStddevPopulationIsNoShowDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_IS_NO_SHOW_DESC',
  MeetingInviteesByContactIdStddevPopulationMeetingIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_MEETING_ID_ASC',
  MeetingInviteesByContactIdStddevPopulationMeetingIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_MEETING_ID_DESC',
  MeetingInviteesByContactIdStddevPopulationRescheduledAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_RESCHEDULED_ASC',
  MeetingInviteesByContactIdStddevPopulationRescheduledDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_RESCHEDULED_DESC',
  MeetingInviteesByContactIdStddevPopulationRescheduleUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_RESCHEDULE_URL_ASC',
  MeetingInviteesByContactIdStddevPopulationRescheduleUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_RESCHEDULE_URL_DESC',
  MeetingInviteesByContactIdStddevPopulationStatusAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_STATUS_ASC',
  MeetingInviteesByContactIdStddevPopulationStatusDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_STATUS_DESC',
  MeetingInviteesByContactIdStddevPopulationTimezoneAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_TIMEZONE_ASC',
  MeetingInviteesByContactIdStddevPopulationTimezoneDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_POPULATION_TIMEZONE_DESC',
  MeetingInviteesByContactIdStddevSampleCalendlyUriAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_CALENDLY_URI_ASC',
  MeetingInviteesByContactIdStddevSampleCalendlyUriDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_CALENDLY_URI_DESC',
  MeetingInviteesByContactIdStddevSampleCancelUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_CANCEL_URL_ASC',
  MeetingInviteesByContactIdStddevSampleCancelUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_CANCEL_URL_DESC',
  MeetingInviteesByContactIdStddevSampleContactIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  MeetingInviteesByContactIdStddevSampleContactIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  MeetingInviteesByContactIdStddevSampleIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  MeetingInviteesByContactIdStddevSampleIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  MeetingInviteesByContactIdStddevSampleIsNoShowAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_IS_NO_SHOW_ASC',
  MeetingInviteesByContactIdStddevSampleIsNoShowDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_IS_NO_SHOW_DESC',
  MeetingInviteesByContactIdStddevSampleMeetingIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_MEETING_ID_ASC',
  MeetingInviteesByContactIdStddevSampleMeetingIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_MEETING_ID_DESC',
  MeetingInviteesByContactIdStddevSampleRescheduledAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_RESCHEDULED_ASC',
  MeetingInviteesByContactIdStddevSampleRescheduledDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_RESCHEDULED_DESC',
  MeetingInviteesByContactIdStddevSampleRescheduleUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_RESCHEDULE_URL_ASC',
  MeetingInviteesByContactIdStddevSampleRescheduleUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_RESCHEDULE_URL_DESC',
  MeetingInviteesByContactIdStddevSampleStatusAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_STATUS_ASC',
  MeetingInviteesByContactIdStddevSampleStatusDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_STATUS_DESC',
  MeetingInviteesByContactIdStddevSampleTimezoneAsc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_TIMEZONE_ASC',
  MeetingInviteesByContactIdStddevSampleTimezoneDesc = 'MEETING_INVITEES_BY_CONTACT_ID_STDDEV_SAMPLE_TIMEZONE_DESC',
  MeetingInviteesByContactIdSumCalendlyUriAsc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_CALENDLY_URI_ASC',
  MeetingInviteesByContactIdSumCalendlyUriDesc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_CALENDLY_URI_DESC',
  MeetingInviteesByContactIdSumCancelUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_CANCEL_URL_ASC',
  MeetingInviteesByContactIdSumCancelUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_CANCEL_URL_DESC',
  MeetingInviteesByContactIdSumContactIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  MeetingInviteesByContactIdSumContactIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  MeetingInviteesByContactIdSumIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_ID_ASC',
  MeetingInviteesByContactIdSumIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_ID_DESC',
  MeetingInviteesByContactIdSumIsNoShowAsc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_IS_NO_SHOW_ASC',
  MeetingInviteesByContactIdSumIsNoShowDesc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_IS_NO_SHOW_DESC',
  MeetingInviteesByContactIdSumMeetingIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_MEETING_ID_ASC',
  MeetingInviteesByContactIdSumMeetingIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_MEETING_ID_DESC',
  MeetingInviteesByContactIdSumRescheduledAsc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_RESCHEDULED_ASC',
  MeetingInviteesByContactIdSumRescheduledDesc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_RESCHEDULED_DESC',
  MeetingInviteesByContactIdSumRescheduleUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_RESCHEDULE_URL_ASC',
  MeetingInviteesByContactIdSumRescheduleUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_RESCHEDULE_URL_DESC',
  MeetingInviteesByContactIdSumStatusAsc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_STATUS_ASC',
  MeetingInviteesByContactIdSumStatusDesc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_STATUS_DESC',
  MeetingInviteesByContactIdSumTimezoneAsc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_TIMEZONE_ASC',
  MeetingInviteesByContactIdSumTimezoneDesc = 'MEETING_INVITEES_BY_CONTACT_ID_SUM_TIMEZONE_DESC',
  MeetingInviteesByContactIdVariancePopulationCalendlyUriAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_CALENDLY_URI_ASC',
  MeetingInviteesByContactIdVariancePopulationCalendlyUriDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_CALENDLY_URI_DESC',
  MeetingInviteesByContactIdVariancePopulationCancelUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_CANCEL_URL_ASC',
  MeetingInviteesByContactIdVariancePopulationCancelUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_CANCEL_URL_DESC',
  MeetingInviteesByContactIdVariancePopulationContactIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  MeetingInviteesByContactIdVariancePopulationContactIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  MeetingInviteesByContactIdVariancePopulationIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  MeetingInviteesByContactIdVariancePopulationIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  MeetingInviteesByContactIdVariancePopulationIsNoShowAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_IS_NO_SHOW_ASC',
  MeetingInviteesByContactIdVariancePopulationIsNoShowDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_IS_NO_SHOW_DESC',
  MeetingInviteesByContactIdVariancePopulationMeetingIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_MEETING_ID_ASC',
  MeetingInviteesByContactIdVariancePopulationMeetingIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_MEETING_ID_DESC',
  MeetingInviteesByContactIdVariancePopulationRescheduledAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_RESCHEDULED_ASC',
  MeetingInviteesByContactIdVariancePopulationRescheduledDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_RESCHEDULED_DESC',
  MeetingInviteesByContactIdVariancePopulationRescheduleUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_RESCHEDULE_URL_ASC',
  MeetingInviteesByContactIdVariancePopulationRescheduleUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_RESCHEDULE_URL_DESC',
  MeetingInviteesByContactIdVariancePopulationStatusAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_STATUS_ASC',
  MeetingInviteesByContactIdVariancePopulationStatusDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_STATUS_DESC',
  MeetingInviteesByContactIdVariancePopulationTimezoneAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_TIMEZONE_ASC',
  MeetingInviteesByContactIdVariancePopulationTimezoneDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_POPULATION_TIMEZONE_DESC',
  MeetingInviteesByContactIdVarianceSampleCalendlyUriAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_CALENDLY_URI_ASC',
  MeetingInviteesByContactIdVarianceSampleCalendlyUriDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_CALENDLY_URI_DESC',
  MeetingInviteesByContactIdVarianceSampleCancelUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_CANCEL_URL_ASC',
  MeetingInviteesByContactIdVarianceSampleCancelUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_CANCEL_URL_DESC',
  MeetingInviteesByContactIdVarianceSampleContactIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  MeetingInviteesByContactIdVarianceSampleContactIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  MeetingInviteesByContactIdVarianceSampleIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  MeetingInviteesByContactIdVarianceSampleIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  MeetingInviteesByContactIdVarianceSampleIsNoShowAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_NO_SHOW_ASC',
  MeetingInviteesByContactIdVarianceSampleIsNoShowDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_IS_NO_SHOW_DESC',
  MeetingInviteesByContactIdVarianceSampleMeetingIdAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_MEETING_ID_ASC',
  MeetingInviteesByContactIdVarianceSampleMeetingIdDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_MEETING_ID_DESC',
  MeetingInviteesByContactIdVarianceSampleRescheduledAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_RESCHEDULED_ASC',
  MeetingInviteesByContactIdVarianceSampleRescheduledDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_RESCHEDULED_DESC',
  MeetingInviteesByContactIdVarianceSampleRescheduleUrlAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_RESCHEDULE_URL_ASC',
  MeetingInviteesByContactIdVarianceSampleRescheduleUrlDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_RESCHEDULE_URL_DESC',
  MeetingInviteesByContactIdVarianceSampleStatusAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_STATUS_ASC',
  MeetingInviteesByContactIdVarianceSampleStatusDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_STATUS_DESC',
  MeetingInviteesByContactIdVarianceSampleTimezoneAsc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_TIMEZONE_ASC',
  MeetingInviteesByContactIdVarianceSampleTimezoneDesc = 'MEETING_INVITEES_BY_CONTACT_ID_VARIANCE_SAMPLE_TIMEZONE_DESC',
  Natural = 'NATURAL',
  PatentsByContactIdAverageContactIdAsc = 'PATENTS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  PatentsByContactIdAverageContactIdDesc = 'PATENTS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  PatentsByContactIdAverageIdAsc = 'PATENTS_BY_CONTACT_ID_AVERAGE_ID_ASC',
  PatentsByContactIdAverageIdDesc = 'PATENTS_BY_CONTACT_ID_AVERAGE_ID_DESC',
  PatentsByContactIdAveragePatentAbstractAsc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_ABSTRACT_ASC',
  PatentsByContactIdAveragePatentAbstractDesc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_ABSTRACT_DESC',
  PatentsByContactIdAveragePatentCountryAsc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_COUNTRY_ASC',
  PatentsByContactIdAveragePatentCountryDesc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_COUNTRY_DESC',
  PatentsByContactIdAveragePatentDateAsc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_DATE_ASC',
  PatentsByContactIdAveragePatentDateDesc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_DATE_DESC',
  PatentsByContactIdAveragePatentKindAsc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_KIND_ASC',
  PatentsByContactIdAveragePatentKindDesc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_KIND_DESC',
  PatentsByContactIdAveragePatentNumberAsc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_NUMBER_ASC',
  PatentsByContactIdAveragePatentNumberDesc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_NUMBER_DESC',
  PatentsByContactIdAveragePatentTitleAsc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_TITLE_ASC',
  PatentsByContactIdAveragePatentTitleDesc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_TITLE_DESC',
  PatentsByContactIdAveragePatentTypeAsc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_TYPE_ASC',
  PatentsByContactIdAveragePatentTypeDesc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_TYPE_DESC',
  PatentsByContactIdAveragePatentYearAsc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_YEAR_ASC',
  PatentsByContactIdAveragePatentYearDesc = 'PATENTS_BY_CONTACT_ID_AVERAGE_PATENT_YEAR_DESC',
  PatentsByContactIdCountAsc = 'PATENTS_BY_CONTACT_ID_COUNT_ASC',
  PatentsByContactIdCountDesc = 'PATENTS_BY_CONTACT_ID_COUNT_DESC',
  PatentsByContactIdDistinctCountContactIdAsc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  PatentsByContactIdDistinctCountContactIdDesc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  PatentsByContactIdDistinctCountIdAsc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  PatentsByContactIdDistinctCountIdDesc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  PatentsByContactIdDistinctCountPatentAbstractAsc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_ABSTRACT_ASC',
  PatentsByContactIdDistinctCountPatentAbstractDesc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_ABSTRACT_DESC',
  PatentsByContactIdDistinctCountPatentCountryAsc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_COUNTRY_ASC',
  PatentsByContactIdDistinctCountPatentCountryDesc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_COUNTRY_DESC',
  PatentsByContactIdDistinctCountPatentDateAsc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_DATE_ASC',
  PatentsByContactIdDistinctCountPatentDateDesc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_DATE_DESC',
  PatentsByContactIdDistinctCountPatentKindAsc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_KIND_ASC',
  PatentsByContactIdDistinctCountPatentKindDesc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_KIND_DESC',
  PatentsByContactIdDistinctCountPatentNumberAsc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_NUMBER_ASC',
  PatentsByContactIdDistinctCountPatentNumberDesc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_NUMBER_DESC',
  PatentsByContactIdDistinctCountPatentTitleAsc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_TITLE_ASC',
  PatentsByContactIdDistinctCountPatentTitleDesc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_TITLE_DESC',
  PatentsByContactIdDistinctCountPatentTypeAsc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_TYPE_ASC',
  PatentsByContactIdDistinctCountPatentTypeDesc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_TYPE_DESC',
  PatentsByContactIdDistinctCountPatentYearAsc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_YEAR_ASC',
  PatentsByContactIdDistinctCountPatentYearDesc = 'PATENTS_BY_CONTACT_ID_DISTINCT_COUNT_PATENT_YEAR_DESC',
  PatentsByContactIdMaxContactIdAsc = 'PATENTS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  PatentsByContactIdMaxContactIdDesc = 'PATENTS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  PatentsByContactIdMaxIdAsc = 'PATENTS_BY_CONTACT_ID_MAX_ID_ASC',
  PatentsByContactIdMaxIdDesc = 'PATENTS_BY_CONTACT_ID_MAX_ID_DESC',
  PatentsByContactIdMaxPatentAbstractAsc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_ABSTRACT_ASC',
  PatentsByContactIdMaxPatentAbstractDesc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_ABSTRACT_DESC',
  PatentsByContactIdMaxPatentCountryAsc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_COUNTRY_ASC',
  PatentsByContactIdMaxPatentCountryDesc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_COUNTRY_DESC',
  PatentsByContactIdMaxPatentDateAsc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_DATE_ASC',
  PatentsByContactIdMaxPatentDateDesc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_DATE_DESC',
  PatentsByContactIdMaxPatentKindAsc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_KIND_ASC',
  PatentsByContactIdMaxPatentKindDesc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_KIND_DESC',
  PatentsByContactIdMaxPatentNumberAsc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_NUMBER_ASC',
  PatentsByContactIdMaxPatentNumberDesc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_NUMBER_DESC',
  PatentsByContactIdMaxPatentTitleAsc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_TITLE_ASC',
  PatentsByContactIdMaxPatentTitleDesc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_TITLE_DESC',
  PatentsByContactIdMaxPatentTypeAsc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_TYPE_ASC',
  PatentsByContactIdMaxPatentTypeDesc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_TYPE_DESC',
  PatentsByContactIdMaxPatentYearAsc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_YEAR_ASC',
  PatentsByContactIdMaxPatentYearDesc = 'PATENTS_BY_CONTACT_ID_MAX_PATENT_YEAR_DESC',
  PatentsByContactIdMinContactIdAsc = 'PATENTS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  PatentsByContactIdMinContactIdDesc = 'PATENTS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  PatentsByContactIdMinIdAsc = 'PATENTS_BY_CONTACT_ID_MIN_ID_ASC',
  PatentsByContactIdMinIdDesc = 'PATENTS_BY_CONTACT_ID_MIN_ID_DESC',
  PatentsByContactIdMinPatentAbstractAsc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_ABSTRACT_ASC',
  PatentsByContactIdMinPatentAbstractDesc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_ABSTRACT_DESC',
  PatentsByContactIdMinPatentCountryAsc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_COUNTRY_ASC',
  PatentsByContactIdMinPatentCountryDesc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_COUNTRY_DESC',
  PatentsByContactIdMinPatentDateAsc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_DATE_ASC',
  PatentsByContactIdMinPatentDateDesc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_DATE_DESC',
  PatentsByContactIdMinPatentKindAsc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_KIND_ASC',
  PatentsByContactIdMinPatentKindDesc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_KIND_DESC',
  PatentsByContactIdMinPatentNumberAsc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_NUMBER_ASC',
  PatentsByContactIdMinPatentNumberDesc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_NUMBER_DESC',
  PatentsByContactIdMinPatentTitleAsc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_TITLE_ASC',
  PatentsByContactIdMinPatentTitleDesc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_TITLE_DESC',
  PatentsByContactIdMinPatentTypeAsc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_TYPE_ASC',
  PatentsByContactIdMinPatentTypeDesc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_TYPE_DESC',
  PatentsByContactIdMinPatentYearAsc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_YEAR_ASC',
  PatentsByContactIdMinPatentYearDesc = 'PATENTS_BY_CONTACT_ID_MIN_PATENT_YEAR_DESC',
  PatentsByContactIdStddevPopulationContactIdAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  PatentsByContactIdStddevPopulationContactIdDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  PatentsByContactIdStddevPopulationIdAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  PatentsByContactIdStddevPopulationIdDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  PatentsByContactIdStddevPopulationPatentAbstractAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_ABSTRACT_ASC',
  PatentsByContactIdStddevPopulationPatentAbstractDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_ABSTRACT_DESC',
  PatentsByContactIdStddevPopulationPatentCountryAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_COUNTRY_ASC',
  PatentsByContactIdStddevPopulationPatentCountryDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_COUNTRY_DESC',
  PatentsByContactIdStddevPopulationPatentDateAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_DATE_ASC',
  PatentsByContactIdStddevPopulationPatentDateDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_DATE_DESC',
  PatentsByContactIdStddevPopulationPatentKindAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_KIND_ASC',
  PatentsByContactIdStddevPopulationPatentKindDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_KIND_DESC',
  PatentsByContactIdStddevPopulationPatentNumberAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_NUMBER_ASC',
  PatentsByContactIdStddevPopulationPatentNumberDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_NUMBER_DESC',
  PatentsByContactIdStddevPopulationPatentTitleAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_TITLE_ASC',
  PatentsByContactIdStddevPopulationPatentTitleDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_TITLE_DESC',
  PatentsByContactIdStddevPopulationPatentTypeAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_TYPE_ASC',
  PatentsByContactIdStddevPopulationPatentTypeDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_TYPE_DESC',
  PatentsByContactIdStddevPopulationPatentYearAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_YEAR_ASC',
  PatentsByContactIdStddevPopulationPatentYearDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_POPULATION_PATENT_YEAR_DESC',
  PatentsByContactIdStddevSampleContactIdAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  PatentsByContactIdStddevSampleContactIdDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  PatentsByContactIdStddevSampleIdAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  PatentsByContactIdStddevSampleIdDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  PatentsByContactIdStddevSamplePatentAbstractAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_ABSTRACT_ASC',
  PatentsByContactIdStddevSamplePatentAbstractDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_ABSTRACT_DESC',
  PatentsByContactIdStddevSamplePatentCountryAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_COUNTRY_ASC',
  PatentsByContactIdStddevSamplePatentCountryDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_COUNTRY_DESC',
  PatentsByContactIdStddevSamplePatentDateAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_DATE_ASC',
  PatentsByContactIdStddevSamplePatentDateDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_DATE_DESC',
  PatentsByContactIdStddevSamplePatentKindAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_KIND_ASC',
  PatentsByContactIdStddevSamplePatentKindDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_KIND_DESC',
  PatentsByContactIdStddevSamplePatentNumberAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_NUMBER_ASC',
  PatentsByContactIdStddevSamplePatentNumberDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_NUMBER_DESC',
  PatentsByContactIdStddevSamplePatentTitleAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_TITLE_ASC',
  PatentsByContactIdStddevSamplePatentTitleDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_TITLE_DESC',
  PatentsByContactIdStddevSamplePatentTypeAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_TYPE_ASC',
  PatentsByContactIdStddevSamplePatentTypeDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_TYPE_DESC',
  PatentsByContactIdStddevSamplePatentYearAsc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_YEAR_ASC',
  PatentsByContactIdStddevSamplePatentYearDesc = 'PATENTS_BY_CONTACT_ID_STDDEV_SAMPLE_PATENT_YEAR_DESC',
  PatentsByContactIdSumContactIdAsc = 'PATENTS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  PatentsByContactIdSumContactIdDesc = 'PATENTS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  PatentsByContactIdSumIdAsc = 'PATENTS_BY_CONTACT_ID_SUM_ID_ASC',
  PatentsByContactIdSumIdDesc = 'PATENTS_BY_CONTACT_ID_SUM_ID_DESC',
  PatentsByContactIdSumPatentAbstractAsc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_ABSTRACT_ASC',
  PatentsByContactIdSumPatentAbstractDesc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_ABSTRACT_DESC',
  PatentsByContactIdSumPatentCountryAsc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_COUNTRY_ASC',
  PatentsByContactIdSumPatentCountryDesc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_COUNTRY_DESC',
  PatentsByContactIdSumPatentDateAsc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_DATE_ASC',
  PatentsByContactIdSumPatentDateDesc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_DATE_DESC',
  PatentsByContactIdSumPatentKindAsc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_KIND_ASC',
  PatentsByContactIdSumPatentKindDesc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_KIND_DESC',
  PatentsByContactIdSumPatentNumberAsc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_NUMBER_ASC',
  PatentsByContactIdSumPatentNumberDesc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_NUMBER_DESC',
  PatentsByContactIdSumPatentTitleAsc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_TITLE_ASC',
  PatentsByContactIdSumPatentTitleDesc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_TITLE_DESC',
  PatentsByContactIdSumPatentTypeAsc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_TYPE_ASC',
  PatentsByContactIdSumPatentTypeDesc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_TYPE_DESC',
  PatentsByContactIdSumPatentYearAsc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_YEAR_ASC',
  PatentsByContactIdSumPatentYearDesc = 'PATENTS_BY_CONTACT_ID_SUM_PATENT_YEAR_DESC',
  PatentsByContactIdVariancePopulationContactIdAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  PatentsByContactIdVariancePopulationContactIdDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  PatentsByContactIdVariancePopulationIdAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  PatentsByContactIdVariancePopulationIdDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  PatentsByContactIdVariancePopulationPatentAbstractAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_ABSTRACT_ASC',
  PatentsByContactIdVariancePopulationPatentAbstractDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_ABSTRACT_DESC',
  PatentsByContactIdVariancePopulationPatentCountryAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_COUNTRY_ASC',
  PatentsByContactIdVariancePopulationPatentCountryDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_COUNTRY_DESC',
  PatentsByContactIdVariancePopulationPatentDateAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_DATE_ASC',
  PatentsByContactIdVariancePopulationPatentDateDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_DATE_DESC',
  PatentsByContactIdVariancePopulationPatentKindAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_KIND_ASC',
  PatentsByContactIdVariancePopulationPatentKindDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_KIND_DESC',
  PatentsByContactIdVariancePopulationPatentNumberAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_NUMBER_ASC',
  PatentsByContactIdVariancePopulationPatentNumberDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_NUMBER_DESC',
  PatentsByContactIdVariancePopulationPatentTitleAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_TITLE_ASC',
  PatentsByContactIdVariancePopulationPatentTitleDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_TITLE_DESC',
  PatentsByContactIdVariancePopulationPatentTypeAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_TYPE_ASC',
  PatentsByContactIdVariancePopulationPatentTypeDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_TYPE_DESC',
  PatentsByContactIdVariancePopulationPatentYearAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_YEAR_ASC',
  PatentsByContactIdVariancePopulationPatentYearDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_POPULATION_PATENT_YEAR_DESC',
  PatentsByContactIdVarianceSampleContactIdAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  PatentsByContactIdVarianceSampleContactIdDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  PatentsByContactIdVarianceSampleIdAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  PatentsByContactIdVarianceSampleIdDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  PatentsByContactIdVarianceSamplePatentAbstractAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_ABSTRACT_ASC',
  PatentsByContactIdVarianceSamplePatentAbstractDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_ABSTRACT_DESC',
  PatentsByContactIdVarianceSamplePatentCountryAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_COUNTRY_ASC',
  PatentsByContactIdVarianceSamplePatentCountryDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_COUNTRY_DESC',
  PatentsByContactIdVarianceSamplePatentDateAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_DATE_ASC',
  PatentsByContactIdVarianceSamplePatentDateDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_DATE_DESC',
  PatentsByContactIdVarianceSamplePatentKindAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_KIND_ASC',
  PatentsByContactIdVarianceSamplePatentKindDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_KIND_DESC',
  PatentsByContactIdVarianceSamplePatentNumberAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_NUMBER_ASC',
  PatentsByContactIdVarianceSamplePatentNumberDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_NUMBER_DESC',
  PatentsByContactIdVarianceSamplePatentTitleAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_TITLE_ASC',
  PatentsByContactIdVarianceSamplePatentTitleDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_TITLE_DESC',
  PatentsByContactIdVarianceSamplePatentTypeAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_TYPE_ASC',
  PatentsByContactIdVarianceSamplePatentTypeDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_TYPE_DESC',
  PatentsByContactIdVarianceSamplePatentYearAsc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_YEAR_ASC',
  PatentsByContactIdVarianceSamplePatentYearDesc = 'PATENTS_BY_CONTACT_ID_VARIANCE_SAMPLE_PATENT_YEAR_DESC',
  PersonalisationsByContactIdAverageContactIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  PersonalisationsByContactIdAverageContactIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  PersonalisationsByContactIdAverageDateValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_AVERAGE_DATE_VALUE_ASC',
  PersonalisationsByContactIdAverageDateValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_AVERAGE_DATE_VALUE_DESC',
  PersonalisationsByContactIdAverageIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_AVERAGE_ID_ASC',
  PersonalisationsByContactIdAverageIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_AVERAGE_ID_DESC',
  PersonalisationsByContactIdAverageIntValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_AVERAGE_INT_VALUE_ASC',
  PersonalisationsByContactIdAverageIntValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_AVERAGE_INT_VALUE_DESC',
  PersonalisationsByContactIdAverageStrValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_AVERAGE_STR_VALUE_ASC',
  PersonalisationsByContactIdAverageStrValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_AVERAGE_STR_VALUE_DESC',
  PersonalisationsByContactIdAverageTypeIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_AVERAGE_TYPE_ID_ASC',
  PersonalisationsByContactIdAverageTypeIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_AVERAGE_TYPE_ID_DESC',
  PersonalisationsByContactIdCountAsc = 'PERSONALISATIONS_BY_CONTACT_ID_COUNT_ASC',
  PersonalisationsByContactIdCountDesc = 'PERSONALISATIONS_BY_CONTACT_ID_COUNT_DESC',
  PersonalisationsByContactIdDistinctCountContactIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  PersonalisationsByContactIdDistinctCountContactIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  PersonalisationsByContactIdDistinctCountDateValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_DISTINCT_COUNT_DATE_VALUE_ASC',
  PersonalisationsByContactIdDistinctCountDateValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_DISTINCT_COUNT_DATE_VALUE_DESC',
  PersonalisationsByContactIdDistinctCountIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  PersonalisationsByContactIdDistinctCountIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  PersonalisationsByContactIdDistinctCountIntValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_DISTINCT_COUNT_INT_VALUE_ASC',
  PersonalisationsByContactIdDistinctCountIntValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_DISTINCT_COUNT_INT_VALUE_DESC',
  PersonalisationsByContactIdDistinctCountStrValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_DISTINCT_COUNT_STR_VALUE_ASC',
  PersonalisationsByContactIdDistinctCountStrValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_DISTINCT_COUNT_STR_VALUE_DESC',
  PersonalisationsByContactIdDistinctCountTypeIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_DISTINCT_COUNT_TYPE_ID_ASC',
  PersonalisationsByContactIdDistinctCountTypeIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_DISTINCT_COUNT_TYPE_ID_DESC',
  PersonalisationsByContactIdMaxContactIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  PersonalisationsByContactIdMaxContactIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  PersonalisationsByContactIdMaxDateValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_MAX_DATE_VALUE_ASC',
  PersonalisationsByContactIdMaxDateValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_MAX_DATE_VALUE_DESC',
  PersonalisationsByContactIdMaxIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_MAX_ID_ASC',
  PersonalisationsByContactIdMaxIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_MAX_ID_DESC',
  PersonalisationsByContactIdMaxIntValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_MAX_INT_VALUE_ASC',
  PersonalisationsByContactIdMaxIntValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_MAX_INT_VALUE_DESC',
  PersonalisationsByContactIdMaxStrValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_MAX_STR_VALUE_ASC',
  PersonalisationsByContactIdMaxStrValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_MAX_STR_VALUE_DESC',
  PersonalisationsByContactIdMaxTypeIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_MAX_TYPE_ID_ASC',
  PersonalisationsByContactIdMaxTypeIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_MAX_TYPE_ID_DESC',
  PersonalisationsByContactIdMinContactIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  PersonalisationsByContactIdMinContactIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  PersonalisationsByContactIdMinDateValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_MIN_DATE_VALUE_ASC',
  PersonalisationsByContactIdMinDateValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_MIN_DATE_VALUE_DESC',
  PersonalisationsByContactIdMinIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_MIN_ID_ASC',
  PersonalisationsByContactIdMinIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_MIN_ID_DESC',
  PersonalisationsByContactIdMinIntValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_MIN_INT_VALUE_ASC',
  PersonalisationsByContactIdMinIntValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_MIN_INT_VALUE_DESC',
  PersonalisationsByContactIdMinStrValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_MIN_STR_VALUE_ASC',
  PersonalisationsByContactIdMinStrValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_MIN_STR_VALUE_DESC',
  PersonalisationsByContactIdMinTypeIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_MIN_TYPE_ID_ASC',
  PersonalisationsByContactIdMinTypeIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_MIN_TYPE_ID_DESC',
  PersonalisationsByContactIdStddevPopulationContactIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  PersonalisationsByContactIdStddevPopulationContactIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  PersonalisationsByContactIdStddevPopulationDateValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_POPULATION_DATE_VALUE_ASC',
  PersonalisationsByContactIdStddevPopulationDateValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_POPULATION_DATE_VALUE_DESC',
  PersonalisationsByContactIdStddevPopulationIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  PersonalisationsByContactIdStddevPopulationIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  PersonalisationsByContactIdStddevPopulationIntValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_POPULATION_INT_VALUE_ASC',
  PersonalisationsByContactIdStddevPopulationIntValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_POPULATION_INT_VALUE_DESC',
  PersonalisationsByContactIdStddevPopulationStrValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_POPULATION_STR_VALUE_ASC',
  PersonalisationsByContactIdStddevPopulationStrValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_POPULATION_STR_VALUE_DESC',
  PersonalisationsByContactIdStddevPopulationTypeIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_POPULATION_TYPE_ID_ASC',
  PersonalisationsByContactIdStddevPopulationTypeIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_POPULATION_TYPE_ID_DESC',
  PersonalisationsByContactIdStddevSampleContactIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  PersonalisationsByContactIdStddevSampleContactIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  PersonalisationsByContactIdStddevSampleDateValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_SAMPLE_DATE_VALUE_ASC',
  PersonalisationsByContactIdStddevSampleDateValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_SAMPLE_DATE_VALUE_DESC',
  PersonalisationsByContactIdStddevSampleIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  PersonalisationsByContactIdStddevSampleIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  PersonalisationsByContactIdStddevSampleIntValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_SAMPLE_INT_VALUE_ASC',
  PersonalisationsByContactIdStddevSampleIntValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_SAMPLE_INT_VALUE_DESC',
  PersonalisationsByContactIdStddevSampleStrValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_SAMPLE_STR_VALUE_ASC',
  PersonalisationsByContactIdStddevSampleStrValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_SAMPLE_STR_VALUE_DESC',
  PersonalisationsByContactIdStddevSampleTypeIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_SAMPLE_TYPE_ID_ASC',
  PersonalisationsByContactIdStddevSampleTypeIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_STDDEV_SAMPLE_TYPE_ID_DESC',
  PersonalisationsByContactIdSumContactIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  PersonalisationsByContactIdSumContactIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  PersonalisationsByContactIdSumDateValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_SUM_DATE_VALUE_ASC',
  PersonalisationsByContactIdSumDateValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_SUM_DATE_VALUE_DESC',
  PersonalisationsByContactIdSumIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_SUM_ID_ASC',
  PersonalisationsByContactIdSumIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_SUM_ID_DESC',
  PersonalisationsByContactIdSumIntValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_SUM_INT_VALUE_ASC',
  PersonalisationsByContactIdSumIntValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_SUM_INT_VALUE_DESC',
  PersonalisationsByContactIdSumStrValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_SUM_STR_VALUE_ASC',
  PersonalisationsByContactIdSumStrValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_SUM_STR_VALUE_DESC',
  PersonalisationsByContactIdSumTypeIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_SUM_TYPE_ID_ASC',
  PersonalisationsByContactIdSumTypeIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_SUM_TYPE_ID_DESC',
  PersonalisationsByContactIdVariancePopulationContactIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  PersonalisationsByContactIdVariancePopulationContactIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  PersonalisationsByContactIdVariancePopulationDateValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_POPULATION_DATE_VALUE_ASC',
  PersonalisationsByContactIdVariancePopulationDateValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_POPULATION_DATE_VALUE_DESC',
  PersonalisationsByContactIdVariancePopulationIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  PersonalisationsByContactIdVariancePopulationIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  PersonalisationsByContactIdVariancePopulationIntValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_POPULATION_INT_VALUE_ASC',
  PersonalisationsByContactIdVariancePopulationIntValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_POPULATION_INT_VALUE_DESC',
  PersonalisationsByContactIdVariancePopulationStrValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_POPULATION_STR_VALUE_ASC',
  PersonalisationsByContactIdVariancePopulationStrValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_POPULATION_STR_VALUE_DESC',
  PersonalisationsByContactIdVariancePopulationTypeIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_POPULATION_TYPE_ID_ASC',
  PersonalisationsByContactIdVariancePopulationTypeIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_POPULATION_TYPE_ID_DESC',
  PersonalisationsByContactIdVarianceSampleContactIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  PersonalisationsByContactIdVarianceSampleContactIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  PersonalisationsByContactIdVarianceSampleDateValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_DATE_VALUE_ASC',
  PersonalisationsByContactIdVarianceSampleDateValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_DATE_VALUE_DESC',
  PersonalisationsByContactIdVarianceSampleIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  PersonalisationsByContactIdVarianceSampleIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  PersonalisationsByContactIdVarianceSampleIntValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_INT_VALUE_ASC',
  PersonalisationsByContactIdVarianceSampleIntValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_INT_VALUE_DESC',
  PersonalisationsByContactIdVarianceSampleStrValueAsc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_STR_VALUE_ASC',
  PersonalisationsByContactIdVarianceSampleStrValueDesc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_STR_VALUE_DESC',
  PersonalisationsByContactIdVarianceSampleTypeIdAsc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_TYPE_ID_ASC',
  PersonalisationsByContactIdVarianceSampleTypeIdDesc = 'PERSONALISATIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_TYPE_ID_DESC',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  PhotoUrlAsc = 'PHOTO_URL_ASC',
  PhotoUrlDesc = 'PHOTO_URL_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReferralPartnersByContactIdAverageCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_AVERAGE_COMPANY_NOTES_ASC',
  ReferralPartnersByContactIdAverageCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_AVERAGE_COMPANY_NOTES_DESC',
  ReferralPartnersByContactIdAverageContactIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  ReferralPartnersByContactIdAverageContactIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  ReferralPartnersByContactIdAverageContactNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_AVERAGE_CONTACT_NOTES_ASC',
  ReferralPartnersByContactIdAverageContactNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_AVERAGE_CONTACT_NOTES_DESC',
  ReferralPartnersByContactIdAverageCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  ReferralPartnersByContactIdAverageCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  ReferralPartnersByContactIdAverageIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_AVERAGE_ID_ASC',
  ReferralPartnersByContactIdAverageIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_AVERAGE_ID_DESC',
  ReferralPartnersByContactIdAverageLawyerIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_ASC',
  ReferralPartnersByContactIdAverageLawyerIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_DESC',
  ReferralPartnersByContactIdCountAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_COUNT_ASC',
  ReferralPartnersByContactIdCountDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_COUNT_DESC',
  ReferralPartnersByContactIdDistinctCountCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_DISTINCT_COUNT_COMPANY_NOTES_ASC',
  ReferralPartnersByContactIdDistinctCountCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_DISTINCT_COUNT_COMPANY_NOTES_DESC',
  ReferralPartnersByContactIdDistinctCountContactIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ReferralPartnersByContactIdDistinctCountContactIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ReferralPartnersByContactIdDistinctCountContactNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_NOTES_ASC',
  ReferralPartnersByContactIdDistinctCountContactNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_NOTES_DESC',
  ReferralPartnersByContactIdDistinctCountCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  ReferralPartnersByContactIdDistinctCountCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  ReferralPartnersByContactIdDistinctCountIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  ReferralPartnersByContactIdDistinctCountIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  ReferralPartnersByContactIdDistinctCountLawyerIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  ReferralPartnersByContactIdDistinctCountLawyerIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  ReferralPartnersByContactIdMaxCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MAX_COMPANY_NOTES_ASC',
  ReferralPartnersByContactIdMaxCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MAX_COMPANY_NOTES_DESC',
  ReferralPartnersByContactIdMaxContactIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  ReferralPartnersByContactIdMaxContactIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  ReferralPartnersByContactIdMaxContactNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MAX_CONTACT_NOTES_ASC',
  ReferralPartnersByContactIdMaxContactNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MAX_CONTACT_NOTES_DESC',
  ReferralPartnersByContactIdMaxCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  ReferralPartnersByContactIdMaxCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  ReferralPartnersByContactIdMaxIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MAX_ID_ASC',
  ReferralPartnersByContactIdMaxIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MAX_ID_DESC',
  ReferralPartnersByContactIdMaxLawyerIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MAX_LAWYER_ID_ASC',
  ReferralPartnersByContactIdMaxLawyerIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MAX_LAWYER_ID_DESC',
  ReferralPartnersByContactIdMinCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MIN_COMPANY_NOTES_ASC',
  ReferralPartnersByContactIdMinCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MIN_COMPANY_NOTES_DESC',
  ReferralPartnersByContactIdMinContactIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  ReferralPartnersByContactIdMinContactIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  ReferralPartnersByContactIdMinContactNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MIN_CONTACT_NOTES_ASC',
  ReferralPartnersByContactIdMinContactNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MIN_CONTACT_NOTES_DESC',
  ReferralPartnersByContactIdMinCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  ReferralPartnersByContactIdMinCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  ReferralPartnersByContactIdMinIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MIN_ID_ASC',
  ReferralPartnersByContactIdMinIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MIN_ID_DESC',
  ReferralPartnersByContactIdMinLawyerIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MIN_LAWYER_ID_ASC',
  ReferralPartnersByContactIdMinLawyerIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_MIN_LAWYER_ID_DESC',
  ReferralPartnersByContactIdStddevPopulationCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_POPULATION_COMPANY_NOTES_ASC',
  ReferralPartnersByContactIdStddevPopulationCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_POPULATION_COMPANY_NOTES_DESC',
  ReferralPartnersByContactIdStddevPopulationContactIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ReferralPartnersByContactIdStddevPopulationContactIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ReferralPartnersByContactIdStddevPopulationContactNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_NOTES_ASC',
  ReferralPartnersByContactIdStddevPopulationContactNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_NOTES_DESC',
  ReferralPartnersByContactIdStddevPopulationCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  ReferralPartnersByContactIdStddevPopulationCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  ReferralPartnersByContactIdStddevPopulationIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  ReferralPartnersByContactIdStddevPopulationIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  ReferralPartnersByContactIdStddevPopulationLawyerIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  ReferralPartnersByContactIdStddevPopulationLawyerIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  ReferralPartnersByContactIdStddevSampleCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_SAMPLE_COMPANY_NOTES_ASC',
  ReferralPartnersByContactIdStddevSampleCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_SAMPLE_COMPANY_NOTES_DESC',
  ReferralPartnersByContactIdStddevSampleContactIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ReferralPartnersByContactIdStddevSampleContactIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ReferralPartnersByContactIdStddevSampleContactNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_NOTES_ASC',
  ReferralPartnersByContactIdStddevSampleContactNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_NOTES_DESC',
  ReferralPartnersByContactIdStddevSampleCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  ReferralPartnersByContactIdStddevSampleCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  ReferralPartnersByContactIdStddevSampleIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  ReferralPartnersByContactIdStddevSampleIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  ReferralPartnersByContactIdStddevSampleLawyerIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  ReferralPartnersByContactIdStddevSampleLawyerIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  ReferralPartnersByContactIdSumCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_SUM_COMPANY_NOTES_ASC',
  ReferralPartnersByContactIdSumCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_SUM_COMPANY_NOTES_DESC',
  ReferralPartnersByContactIdSumContactIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  ReferralPartnersByContactIdSumContactIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  ReferralPartnersByContactIdSumContactNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_SUM_CONTACT_NOTES_ASC',
  ReferralPartnersByContactIdSumContactNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_SUM_CONTACT_NOTES_DESC',
  ReferralPartnersByContactIdSumCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  ReferralPartnersByContactIdSumCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  ReferralPartnersByContactIdSumIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_SUM_ID_ASC',
  ReferralPartnersByContactIdSumIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_SUM_ID_DESC',
  ReferralPartnersByContactIdSumLawyerIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_SUM_LAWYER_ID_ASC',
  ReferralPartnersByContactIdSumLawyerIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_SUM_LAWYER_ID_DESC',
  ReferralPartnersByContactIdVariancePopulationCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_POPULATION_COMPANY_NOTES_ASC',
  ReferralPartnersByContactIdVariancePopulationCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_POPULATION_COMPANY_NOTES_DESC',
  ReferralPartnersByContactIdVariancePopulationContactIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ReferralPartnersByContactIdVariancePopulationContactIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ReferralPartnersByContactIdVariancePopulationContactNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_NOTES_ASC',
  ReferralPartnersByContactIdVariancePopulationContactNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_NOTES_DESC',
  ReferralPartnersByContactIdVariancePopulationCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  ReferralPartnersByContactIdVariancePopulationCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  ReferralPartnersByContactIdVariancePopulationIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  ReferralPartnersByContactIdVariancePopulationIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  ReferralPartnersByContactIdVariancePopulationLawyerIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  ReferralPartnersByContactIdVariancePopulationLawyerIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  ReferralPartnersByContactIdVarianceSampleCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_SAMPLE_COMPANY_NOTES_ASC',
  ReferralPartnersByContactIdVarianceSampleCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_SAMPLE_COMPANY_NOTES_DESC',
  ReferralPartnersByContactIdVarianceSampleContactIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ReferralPartnersByContactIdVarianceSampleContactIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ReferralPartnersByContactIdVarianceSampleContactNotesAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_NOTES_ASC',
  ReferralPartnersByContactIdVarianceSampleContactNotesDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_NOTES_DESC',
  ReferralPartnersByContactIdVarianceSampleCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  ReferralPartnersByContactIdVarianceSampleCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  ReferralPartnersByContactIdVarianceSampleIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  ReferralPartnersByContactIdVarianceSampleIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  ReferralPartnersByContactIdVarianceSampleLawyerIdAsc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  ReferralPartnersByContactIdVarianceSampleLawyerIdDesc = 'REFERRAL_PARTNERS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdAverageContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdAverageContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdAverageCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdAverageCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdAverageLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdAverageLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdAverageUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdAverageUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdCountAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_COUNT_ASC',
  SalesFunnelLawyerContactLinksByContactIdCountDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_COUNT_DESC',
  SalesFunnelLawyerContactLinksByContactIdDistinctCountContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdDistinctCountContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdDistinctCountCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdDistinctCountCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdDistinctCountLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdDistinctCountLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdDistinctCountUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdDistinctCountUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdMaxContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdMaxContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdMaxCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdMaxCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdMaxLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MAX_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdMaxLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MAX_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdMaxUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MAX_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdMaxUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MAX_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdMinContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdMinContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdMinCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdMinCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdMinLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MIN_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdMinLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MIN_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdMinUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MIN_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdMinUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_MIN_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdStddevPopulationContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdStddevPopulationContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdStddevPopulationCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdStddevPopulationCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdStddevPopulationLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdStddevPopulationLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdStddevPopulationUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdStddevPopulationUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdStddevSampleContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdStddevSampleContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdStddevSampleCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdStddevSampleCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdStddevSampleLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdStddevSampleLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdStddevSampleUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdStddevSampleUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdSumContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdSumContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdSumCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdSumCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdSumLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_SUM_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdSumLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_SUM_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdSumUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_SUM_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdSumUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_SUM_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdVariancePopulationContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdVariancePopulationContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdVariancePopulationCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdVariancePopulationCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdVariancePopulationLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdVariancePopulationLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdVariancePopulationUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdVariancePopulationUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdVarianceSampleContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdVarianceSampleContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdVarianceSampleCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdVarianceSampleCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByContactIdVarianceSampleLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByContactIdVarianceSampleLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByContactIdVarianceSampleUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByContactIdVarianceSampleUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  SlackConnectionsByContactIdAverageConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_CONNECTION_KEY_ASC',
  SlackConnectionsByContactIdAverageConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_CONNECTION_KEY_DESC',
  SlackConnectionsByContactIdAverageConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_CONNECTION_URL_ASC',
  SlackConnectionsByContactIdAverageConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_CONNECTION_URL_DESC',
  SlackConnectionsByContactIdAverageContactIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  SlackConnectionsByContactIdAverageContactIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  SlackConnectionsByContactIdAverageCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  SlackConnectionsByContactIdAverageCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  SlackConnectionsByContactIdAverageIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_ID_ASC',
  SlackConnectionsByContactIdAverageIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_ID_DESC',
  SlackConnectionsByContactIdAverageLawyerIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_ASC',
  SlackConnectionsByContactIdAverageLawyerIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_DESC',
  SlackConnectionsByContactIdAverageSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_APP_ID_ASC',
  SlackConnectionsByContactIdAverageSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_APP_ID_DESC',
  SlackConnectionsByContactIdAverageSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdAverageSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdAverageSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByContactIdAverageSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByContactIdAverageSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByContactIdAverageSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByContactIdAverageSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByContactIdAverageSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByContactIdAverageSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByContactIdAverageSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByContactIdAverageSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByContactIdAverageSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByContactIdAverageSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_TEAM_ID_ASC',
  SlackConnectionsByContactIdAverageSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_TEAM_ID_DESC',
  SlackConnectionsByContactIdAverageSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByContactIdAverageSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByContactIdAverageSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdAverageSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdAverageSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_USER_ID_ASC',
  SlackConnectionsByContactIdAverageSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_USER_ID_DESC',
  SlackConnectionsByContactIdAverageSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByContactIdAverageSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByContactIdAverageUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_ASC',
  SlackConnectionsByContactIdAverageUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_DESC',
  SlackConnectionsByContactIdCountAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_COUNT_ASC',
  SlackConnectionsByContactIdCountDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_COUNT_DESC',
  SlackConnectionsByContactIdDistinctCountConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_CONNECTION_KEY_ASC',
  SlackConnectionsByContactIdDistinctCountConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_CONNECTION_KEY_DESC',
  SlackConnectionsByContactIdDistinctCountConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_CONNECTION_URL_ASC',
  SlackConnectionsByContactIdDistinctCountConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_CONNECTION_URL_DESC',
  SlackConnectionsByContactIdDistinctCountContactIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  SlackConnectionsByContactIdDistinctCountContactIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  SlackConnectionsByContactIdDistinctCountCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  SlackConnectionsByContactIdDistinctCountCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  SlackConnectionsByContactIdDistinctCountIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  SlackConnectionsByContactIdDistinctCountIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  SlackConnectionsByContactIdDistinctCountLawyerIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  SlackConnectionsByContactIdDistinctCountLawyerIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  SlackConnectionsByContactIdDistinctCountSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_APP_ID_ASC',
  SlackConnectionsByContactIdDistinctCountSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_APP_ID_DESC',
  SlackConnectionsByContactIdDistinctCountSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdDistinctCountSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdDistinctCountSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByContactIdDistinctCountSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByContactIdDistinctCountSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByContactIdDistinctCountSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByContactIdDistinctCountSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByContactIdDistinctCountSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByContactIdDistinctCountSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByContactIdDistinctCountSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByContactIdDistinctCountSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByContactIdDistinctCountSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByContactIdDistinctCountSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_TEAM_ID_ASC',
  SlackConnectionsByContactIdDistinctCountSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_TEAM_ID_DESC',
  SlackConnectionsByContactIdDistinctCountSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByContactIdDistinctCountSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByContactIdDistinctCountSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdDistinctCountSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdDistinctCountSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_USER_ID_ASC',
  SlackConnectionsByContactIdDistinctCountSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_USER_ID_DESC',
  SlackConnectionsByContactIdDistinctCountSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByContactIdDistinctCountSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByContactIdDistinctCountUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  SlackConnectionsByContactIdDistinctCountUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  SlackConnectionsByContactIdMaxConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_CONNECTION_KEY_ASC',
  SlackConnectionsByContactIdMaxConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_CONNECTION_KEY_DESC',
  SlackConnectionsByContactIdMaxConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_CONNECTION_URL_ASC',
  SlackConnectionsByContactIdMaxConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_CONNECTION_URL_DESC',
  SlackConnectionsByContactIdMaxContactIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  SlackConnectionsByContactIdMaxContactIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  SlackConnectionsByContactIdMaxCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  SlackConnectionsByContactIdMaxCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  SlackConnectionsByContactIdMaxIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_ID_ASC',
  SlackConnectionsByContactIdMaxIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_ID_DESC',
  SlackConnectionsByContactIdMaxLawyerIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_LAWYER_ID_ASC',
  SlackConnectionsByContactIdMaxLawyerIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_LAWYER_ID_DESC',
  SlackConnectionsByContactIdMaxSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_APP_ID_ASC',
  SlackConnectionsByContactIdMaxSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_APP_ID_DESC',
  SlackConnectionsByContactIdMaxSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdMaxSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdMaxSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByContactIdMaxSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByContactIdMaxSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByContactIdMaxSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByContactIdMaxSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByContactIdMaxSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByContactIdMaxSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByContactIdMaxSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByContactIdMaxSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByContactIdMaxSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByContactIdMaxSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_TEAM_ID_ASC',
  SlackConnectionsByContactIdMaxSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_TEAM_ID_DESC',
  SlackConnectionsByContactIdMaxSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByContactIdMaxSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByContactIdMaxSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdMaxSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdMaxSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_USER_ID_ASC',
  SlackConnectionsByContactIdMaxSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_USER_ID_DESC',
  SlackConnectionsByContactIdMaxSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByContactIdMaxSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByContactIdMaxUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_UPDATED_TIME_ASC',
  SlackConnectionsByContactIdMaxUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MAX_UPDATED_TIME_DESC',
  SlackConnectionsByContactIdMinConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_CONNECTION_KEY_ASC',
  SlackConnectionsByContactIdMinConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_CONNECTION_KEY_DESC',
  SlackConnectionsByContactIdMinConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_CONNECTION_URL_ASC',
  SlackConnectionsByContactIdMinConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_CONNECTION_URL_DESC',
  SlackConnectionsByContactIdMinContactIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  SlackConnectionsByContactIdMinContactIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  SlackConnectionsByContactIdMinCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  SlackConnectionsByContactIdMinCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  SlackConnectionsByContactIdMinIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_ID_ASC',
  SlackConnectionsByContactIdMinIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_ID_DESC',
  SlackConnectionsByContactIdMinLawyerIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_LAWYER_ID_ASC',
  SlackConnectionsByContactIdMinLawyerIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_LAWYER_ID_DESC',
  SlackConnectionsByContactIdMinSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_APP_ID_ASC',
  SlackConnectionsByContactIdMinSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_APP_ID_DESC',
  SlackConnectionsByContactIdMinSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdMinSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdMinSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByContactIdMinSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByContactIdMinSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByContactIdMinSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByContactIdMinSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByContactIdMinSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByContactIdMinSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByContactIdMinSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByContactIdMinSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByContactIdMinSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByContactIdMinSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_TEAM_ID_ASC',
  SlackConnectionsByContactIdMinSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_TEAM_ID_DESC',
  SlackConnectionsByContactIdMinSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByContactIdMinSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByContactIdMinSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdMinSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdMinSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_USER_ID_ASC',
  SlackConnectionsByContactIdMinSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_USER_ID_DESC',
  SlackConnectionsByContactIdMinSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByContactIdMinSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByContactIdMinUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_UPDATED_TIME_ASC',
  SlackConnectionsByContactIdMinUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_MIN_UPDATED_TIME_DESC',
  SlackConnectionsByContactIdStddevPopulationConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_CONNECTION_KEY_ASC',
  SlackConnectionsByContactIdStddevPopulationConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_CONNECTION_KEY_DESC',
  SlackConnectionsByContactIdStddevPopulationConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_CONNECTION_URL_ASC',
  SlackConnectionsByContactIdStddevPopulationConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_CONNECTION_URL_DESC',
  SlackConnectionsByContactIdStddevPopulationContactIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  SlackConnectionsByContactIdStddevPopulationContactIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  SlackConnectionsByContactIdStddevPopulationCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  SlackConnectionsByContactIdStddevPopulationCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  SlackConnectionsByContactIdStddevPopulationIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  SlackConnectionsByContactIdStddevPopulationIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  SlackConnectionsByContactIdStddevPopulationLawyerIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  SlackConnectionsByContactIdStddevPopulationLawyerIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  SlackConnectionsByContactIdStddevPopulationSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_APP_ID_ASC',
  SlackConnectionsByContactIdStddevPopulationSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_APP_ID_DESC',
  SlackConnectionsByContactIdStddevPopulationSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdStddevPopulationSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdStddevPopulationSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByContactIdStddevPopulationSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByContactIdStddevPopulationSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByContactIdStddevPopulationSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByContactIdStddevPopulationSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByContactIdStddevPopulationSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByContactIdStddevPopulationSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByContactIdStddevPopulationSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByContactIdStddevPopulationSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByContactIdStddevPopulationSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByContactIdStddevPopulationSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_TEAM_ID_ASC',
  SlackConnectionsByContactIdStddevPopulationSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_TEAM_ID_DESC',
  SlackConnectionsByContactIdStddevPopulationSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByContactIdStddevPopulationSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByContactIdStddevPopulationSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdStddevPopulationSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdStddevPopulationSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_USER_ID_ASC',
  SlackConnectionsByContactIdStddevPopulationSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_USER_ID_DESC',
  SlackConnectionsByContactIdStddevPopulationSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByContactIdStddevPopulationSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByContactIdStddevPopulationUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  SlackConnectionsByContactIdStddevPopulationUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  SlackConnectionsByContactIdStddevSampleConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_CONNECTION_KEY_ASC',
  SlackConnectionsByContactIdStddevSampleConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_CONNECTION_KEY_DESC',
  SlackConnectionsByContactIdStddevSampleConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_CONNECTION_URL_ASC',
  SlackConnectionsByContactIdStddevSampleConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_CONNECTION_URL_DESC',
  SlackConnectionsByContactIdStddevSampleContactIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  SlackConnectionsByContactIdStddevSampleContactIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  SlackConnectionsByContactIdStddevSampleCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  SlackConnectionsByContactIdStddevSampleCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  SlackConnectionsByContactIdStddevSampleIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  SlackConnectionsByContactIdStddevSampleIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  SlackConnectionsByContactIdStddevSampleLawyerIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  SlackConnectionsByContactIdStddevSampleLawyerIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  SlackConnectionsByContactIdStddevSampleSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_APP_ID_ASC',
  SlackConnectionsByContactIdStddevSampleSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_APP_ID_DESC',
  SlackConnectionsByContactIdStddevSampleSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdStddevSampleSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdStddevSampleSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByContactIdStddevSampleSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByContactIdStddevSampleSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByContactIdStddevSampleSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByContactIdStddevSampleSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByContactIdStddevSampleSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByContactIdStddevSampleSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByContactIdStddevSampleSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByContactIdStddevSampleSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByContactIdStddevSampleSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByContactIdStddevSampleSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_TEAM_ID_ASC',
  SlackConnectionsByContactIdStddevSampleSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_TEAM_ID_DESC',
  SlackConnectionsByContactIdStddevSampleSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByContactIdStddevSampleSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByContactIdStddevSampleSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdStddevSampleSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdStddevSampleSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_USER_ID_ASC',
  SlackConnectionsByContactIdStddevSampleSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_USER_ID_DESC',
  SlackConnectionsByContactIdStddevSampleSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByContactIdStddevSampleSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByContactIdStddevSampleUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  SlackConnectionsByContactIdStddevSampleUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  SlackConnectionsByContactIdSumConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_CONNECTION_KEY_ASC',
  SlackConnectionsByContactIdSumConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_CONNECTION_KEY_DESC',
  SlackConnectionsByContactIdSumConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_CONNECTION_URL_ASC',
  SlackConnectionsByContactIdSumConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_CONNECTION_URL_DESC',
  SlackConnectionsByContactIdSumContactIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  SlackConnectionsByContactIdSumContactIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  SlackConnectionsByContactIdSumCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  SlackConnectionsByContactIdSumCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  SlackConnectionsByContactIdSumIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_ID_ASC',
  SlackConnectionsByContactIdSumIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_ID_DESC',
  SlackConnectionsByContactIdSumLawyerIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_LAWYER_ID_ASC',
  SlackConnectionsByContactIdSumLawyerIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_LAWYER_ID_DESC',
  SlackConnectionsByContactIdSumSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_APP_ID_ASC',
  SlackConnectionsByContactIdSumSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_APP_ID_DESC',
  SlackConnectionsByContactIdSumSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdSumSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdSumSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByContactIdSumSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByContactIdSumSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByContactIdSumSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByContactIdSumSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByContactIdSumSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByContactIdSumSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByContactIdSumSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByContactIdSumSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByContactIdSumSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByContactIdSumSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_TEAM_ID_ASC',
  SlackConnectionsByContactIdSumSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_TEAM_ID_DESC',
  SlackConnectionsByContactIdSumSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByContactIdSumSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByContactIdSumSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdSumSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdSumSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_USER_ID_ASC',
  SlackConnectionsByContactIdSumSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_USER_ID_DESC',
  SlackConnectionsByContactIdSumSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByContactIdSumSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByContactIdSumUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_UPDATED_TIME_ASC',
  SlackConnectionsByContactIdSumUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_SUM_UPDATED_TIME_DESC',
  SlackConnectionsByContactIdVariancePopulationConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_CONNECTION_KEY_ASC',
  SlackConnectionsByContactIdVariancePopulationConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_CONNECTION_KEY_DESC',
  SlackConnectionsByContactIdVariancePopulationConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_CONNECTION_URL_ASC',
  SlackConnectionsByContactIdVariancePopulationConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_CONNECTION_URL_DESC',
  SlackConnectionsByContactIdVariancePopulationContactIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  SlackConnectionsByContactIdVariancePopulationContactIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  SlackConnectionsByContactIdVariancePopulationCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  SlackConnectionsByContactIdVariancePopulationCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  SlackConnectionsByContactIdVariancePopulationIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  SlackConnectionsByContactIdVariancePopulationIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  SlackConnectionsByContactIdVariancePopulationLawyerIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  SlackConnectionsByContactIdVariancePopulationLawyerIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  SlackConnectionsByContactIdVariancePopulationSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_APP_ID_ASC',
  SlackConnectionsByContactIdVariancePopulationSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_APP_ID_DESC',
  SlackConnectionsByContactIdVariancePopulationSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdVariancePopulationSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdVariancePopulationSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByContactIdVariancePopulationSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByContactIdVariancePopulationSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByContactIdVariancePopulationSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByContactIdVariancePopulationSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByContactIdVariancePopulationSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByContactIdVariancePopulationSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByContactIdVariancePopulationSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByContactIdVariancePopulationSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByContactIdVariancePopulationSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByContactIdVariancePopulationSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_TEAM_ID_ASC',
  SlackConnectionsByContactIdVariancePopulationSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_TEAM_ID_DESC',
  SlackConnectionsByContactIdVariancePopulationSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByContactIdVariancePopulationSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByContactIdVariancePopulationSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdVariancePopulationSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdVariancePopulationSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_USER_ID_ASC',
  SlackConnectionsByContactIdVariancePopulationSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_USER_ID_DESC',
  SlackConnectionsByContactIdVariancePopulationSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByContactIdVariancePopulationSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByContactIdVariancePopulationUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  SlackConnectionsByContactIdVariancePopulationUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  SlackConnectionsByContactIdVarianceSampleConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONNECTION_KEY_ASC',
  SlackConnectionsByContactIdVarianceSampleConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONNECTION_KEY_DESC',
  SlackConnectionsByContactIdVarianceSampleConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONNECTION_URL_ASC',
  SlackConnectionsByContactIdVarianceSampleConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONNECTION_URL_DESC',
  SlackConnectionsByContactIdVarianceSampleContactIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  SlackConnectionsByContactIdVarianceSampleContactIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  SlackConnectionsByContactIdVarianceSampleCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  SlackConnectionsByContactIdVarianceSampleCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  SlackConnectionsByContactIdVarianceSampleIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  SlackConnectionsByContactIdVarianceSampleIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  SlackConnectionsByContactIdVarianceSampleLawyerIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  SlackConnectionsByContactIdVarianceSampleLawyerIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  SlackConnectionsByContactIdVarianceSampleSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_APP_ID_ASC',
  SlackConnectionsByContactIdVarianceSampleSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_APP_ID_DESC',
  SlackConnectionsByContactIdVarianceSampleSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdVarianceSampleSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdVarianceSampleSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByContactIdVarianceSampleSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByContactIdVarianceSampleSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByContactIdVarianceSampleSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByContactIdVarianceSampleSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByContactIdVarianceSampleSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByContactIdVarianceSampleSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByContactIdVarianceSampleSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByContactIdVarianceSampleSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByContactIdVarianceSampleSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByContactIdVarianceSampleSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_TEAM_ID_ASC',
  SlackConnectionsByContactIdVarianceSampleSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_TEAM_ID_DESC',
  SlackConnectionsByContactIdVarianceSampleSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByContactIdVarianceSampleSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByContactIdVarianceSampleSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByContactIdVarianceSampleSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByContactIdVarianceSampleSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_USER_ID_ASC',
  SlackConnectionsByContactIdVarianceSampleSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_USER_ID_DESC',
  SlackConnectionsByContactIdVarianceSampleSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByContactIdVarianceSampleSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByContactIdVarianceSampleUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  SlackConnectionsByContactIdVarianceSampleUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  SocialProofsByContactIdAverageCompanyIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_COMPANY_ID_ASC',
  SocialProofsByContactIdAverageCompanyIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_COMPANY_ID_DESC',
  SocialProofsByContactIdAverageContactIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  SocialProofsByContactIdAverageContactIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  SocialProofsByContactIdAverageCreatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  SocialProofsByContactIdAverageCreatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  SocialProofsByContactIdAverageIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_ID_ASC',
  SocialProofsByContactIdAverageIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_ID_DESC',
  SocialProofsByContactIdAverageLawyerIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_ASC',
  SocialProofsByContactIdAverageLawyerIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_DESC',
  SocialProofsByContactIdAverageTextAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_TEXT_ASC',
  SocialProofsByContactIdAverageTextDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_TEXT_DESC',
  SocialProofsByContactIdAverageUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_ASC',
  SocialProofsByContactIdAverageUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_AVERAGE_UPDATED_TIME_DESC',
  SocialProofsByContactIdCountAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_COUNT_ASC',
  SocialProofsByContactIdCountDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_COUNT_DESC',
  SocialProofsByContactIdDistinctCountCompanyIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  SocialProofsByContactIdDistinctCountCompanyIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  SocialProofsByContactIdDistinctCountContactIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  SocialProofsByContactIdDistinctCountContactIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  SocialProofsByContactIdDistinctCountCreatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  SocialProofsByContactIdDistinctCountCreatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  SocialProofsByContactIdDistinctCountIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  SocialProofsByContactIdDistinctCountIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  SocialProofsByContactIdDistinctCountLawyerIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  SocialProofsByContactIdDistinctCountLawyerIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  SocialProofsByContactIdDistinctCountTextAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_TEXT_ASC',
  SocialProofsByContactIdDistinctCountTextDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_TEXT_DESC',
  SocialProofsByContactIdDistinctCountUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  SocialProofsByContactIdDistinctCountUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  SocialProofsByContactIdMaxCompanyIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_COMPANY_ID_ASC',
  SocialProofsByContactIdMaxCompanyIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_COMPANY_ID_DESC',
  SocialProofsByContactIdMaxContactIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  SocialProofsByContactIdMaxContactIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  SocialProofsByContactIdMaxCreatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  SocialProofsByContactIdMaxCreatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  SocialProofsByContactIdMaxIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_ID_ASC',
  SocialProofsByContactIdMaxIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_ID_DESC',
  SocialProofsByContactIdMaxLawyerIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_LAWYER_ID_ASC',
  SocialProofsByContactIdMaxLawyerIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_LAWYER_ID_DESC',
  SocialProofsByContactIdMaxTextAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_TEXT_ASC',
  SocialProofsByContactIdMaxTextDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_TEXT_DESC',
  SocialProofsByContactIdMaxUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_UPDATED_TIME_ASC',
  SocialProofsByContactIdMaxUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MAX_UPDATED_TIME_DESC',
  SocialProofsByContactIdMinCompanyIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_COMPANY_ID_ASC',
  SocialProofsByContactIdMinCompanyIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_COMPANY_ID_DESC',
  SocialProofsByContactIdMinContactIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  SocialProofsByContactIdMinContactIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  SocialProofsByContactIdMinCreatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  SocialProofsByContactIdMinCreatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  SocialProofsByContactIdMinIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_ID_ASC',
  SocialProofsByContactIdMinIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_ID_DESC',
  SocialProofsByContactIdMinLawyerIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_LAWYER_ID_ASC',
  SocialProofsByContactIdMinLawyerIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_LAWYER_ID_DESC',
  SocialProofsByContactIdMinTextAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_TEXT_ASC',
  SocialProofsByContactIdMinTextDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_TEXT_DESC',
  SocialProofsByContactIdMinUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_UPDATED_TIME_ASC',
  SocialProofsByContactIdMinUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_MIN_UPDATED_TIME_DESC',
  SocialProofsByContactIdStddevPopulationCompanyIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  SocialProofsByContactIdStddevPopulationCompanyIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  SocialProofsByContactIdStddevPopulationContactIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  SocialProofsByContactIdStddevPopulationContactIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  SocialProofsByContactIdStddevPopulationCreatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  SocialProofsByContactIdStddevPopulationCreatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  SocialProofsByContactIdStddevPopulationIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  SocialProofsByContactIdStddevPopulationIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  SocialProofsByContactIdStddevPopulationLawyerIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  SocialProofsByContactIdStddevPopulationLawyerIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  SocialProofsByContactIdStddevPopulationTextAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_TEXT_ASC',
  SocialProofsByContactIdStddevPopulationTextDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_TEXT_DESC',
  SocialProofsByContactIdStddevPopulationUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  SocialProofsByContactIdStddevPopulationUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  SocialProofsByContactIdStddevSampleCompanyIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SocialProofsByContactIdStddevSampleCompanyIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SocialProofsByContactIdStddevSampleContactIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  SocialProofsByContactIdStddevSampleContactIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  SocialProofsByContactIdStddevSampleCreatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  SocialProofsByContactIdStddevSampleCreatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  SocialProofsByContactIdStddevSampleIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  SocialProofsByContactIdStddevSampleIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  SocialProofsByContactIdStddevSampleLawyerIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  SocialProofsByContactIdStddevSampleLawyerIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  SocialProofsByContactIdStddevSampleTextAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_TEXT_ASC',
  SocialProofsByContactIdStddevSampleTextDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_TEXT_DESC',
  SocialProofsByContactIdStddevSampleUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  SocialProofsByContactIdStddevSampleUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  SocialProofsByContactIdSumCompanyIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_COMPANY_ID_ASC',
  SocialProofsByContactIdSumCompanyIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_COMPANY_ID_DESC',
  SocialProofsByContactIdSumContactIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  SocialProofsByContactIdSumContactIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  SocialProofsByContactIdSumCreatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  SocialProofsByContactIdSumCreatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  SocialProofsByContactIdSumIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_ID_ASC',
  SocialProofsByContactIdSumIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_ID_DESC',
  SocialProofsByContactIdSumLawyerIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_LAWYER_ID_ASC',
  SocialProofsByContactIdSumLawyerIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_LAWYER_ID_DESC',
  SocialProofsByContactIdSumTextAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_TEXT_ASC',
  SocialProofsByContactIdSumTextDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_TEXT_DESC',
  SocialProofsByContactIdSumUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_UPDATED_TIME_ASC',
  SocialProofsByContactIdSumUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_SUM_UPDATED_TIME_DESC',
  SocialProofsByContactIdVariancePopulationCompanyIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SocialProofsByContactIdVariancePopulationCompanyIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SocialProofsByContactIdVariancePopulationContactIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  SocialProofsByContactIdVariancePopulationContactIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  SocialProofsByContactIdVariancePopulationCreatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  SocialProofsByContactIdVariancePopulationCreatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  SocialProofsByContactIdVariancePopulationIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  SocialProofsByContactIdVariancePopulationIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  SocialProofsByContactIdVariancePopulationLawyerIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  SocialProofsByContactIdVariancePopulationLawyerIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  SocialProofsByContactIdVariancePopulationTextAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_TEXT_ASC',
  SocialProofsByContactIdVariancePopulationTextDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_TEXT_DESC',
  SocialProofsByContactIdVariancePopulationUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  SocialProofsByContactIdVariancePopulationUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  SocialProofsByContactIdVarianceSampleCompanyIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SocialProofsByContactIdVarianceSampleCompanyIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SocialProofsByContactIdVarianceSampleContactIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  SocialProofsByContactIdVarianceSampleContactIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  SocialProofsByContactIdVarianceSampleCreatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  SocialProofsByContactIdVarianceSampleCreatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  SocialProofsByContactIdVarianceSampleIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  SocialProofsByContactIdVarianceSampleIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  SocialProofsByContactIdVarianceSampleLawyerIdAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  SocialProofsByContactIdVarianceSampleLawyerIdDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  SocialProofsByContactIdVarianceSampleTextAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_TEXT_ASC',
  SocialProofsByContactIdVarianceSampleTextDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_TEXT_DESC',
  SocialProofsByContactIdVarianceSampleUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  SocialProofsByContactIdVarianceSampleUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_CONTACT_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  SummaryAsc = 'SUMMARY_ASC',
  SummaryDesc = 'SUMMARY_DESC',
  TestimonialsByContactIdAverageCommunicationRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_COMMUNICATION_RATING_ASC',
  TestimonialsByContactIdAverageCommunicationRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_COMMUNICATION_RATING_DESC',
  TestimonialsByContactIdAverageContactIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  TestimonialsByContactIdAverageContactIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  TestimonialsByContactIdAverageCostRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_COST_RATING_ASC',
  TestimonialsByContactIdAverageCostRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_COST_RATING_DESC',
  TestimonialsByContactIdAverageCreatedTimeAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  TestimonialsByContactIdAverageCreatedTimeDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  TestimonialsByContactIdAverageIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_ID_ASC',
  TestimonialsByContactIdAverageIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_ID_DESC',
  TestimonialsByContactIdAverageLawyerIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_ASC',
  TestimonialsByContactIdAverageLawyerIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_DESC',
  TestimonialsByContactIdAverageNegativeCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_NEGATIVE_COMMENTS_ASC',
  TestimonialsByContactIdAverageNegativeCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_NEGATIVE_COMMENTS_DESC',
  TestimonialsByContactIdAveragePositiveCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_POSITIVE_COMMENTS_ASC',
  TestimonialsByContactIdAveragePositiveCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_POSITIVE_COMMENTS_DESC',
  TestimonialsByContactIdAveragePublicAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_PUBLIC_ASC',
  TestimonialsByContactIdAveragePublicDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_PUBLIC_DESC',
  TestimonialsByContactIdAverageReferenceAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_REFERENCE_ASC',
  TestimonialsByContactIdAverageReferenceDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_REFERENCE_DESC',
  TestimonialsByContactIdAverageResponsivenessRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_RESPONSIVENESS_RATING_ASC',
  TestimonialsByContactIdAverageResponsivenessRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_RESPONSIVENESS_RATING_DESC',
  TestimonialsByContactIdAverageServiceDescriptionAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_SERVICE_DESCRIPTION_ASC',
  TestimonialsByContactIdAverageServiceDescriptionDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_SERVICE_DESCRIPTION_DESC',
  TestimonialsByContactIdAverageTargetRelationshipAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_TARGET_RELATIONSHIP_ASC',
  TestimonialsByContactIdAverageTargetRelationshipDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_TARGET_RELATIONSHIP_DESC',
  TestimonialsByContactIdAverageTimeRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_TIME_RATING_ASC',
  TestimonialsByContactIdAverageTimeRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_AVERAGE_TIME_RATING_DESC',
  TestimonialsByContactIdCountAsc = 'TESTIMONIALS_BY_CONTACT_ID_COUNT_ASC',
  TestimonialsByContactIdCountDesc = 'TESTIMONIALS_BY_CONTACT_ID_COUNT_DESC',
  TestimonialsByContactIdDistinctCountCommunicationRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_COMMUNICATION_RATING_ASC',
  TestimonialsByContactIdDistinctCountCommunicationRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_COMMUNICATION_RATING_DESC',
  TestimonialsByContactIdDistinctCountContactIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  TestimonialsByContactIdDistinctCountContactIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  TestimonialsByContactIdDistinctCountCostRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_COST_RATING_ASC',
  TestimonialsByContactIdDistinctCountCostRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_COST_RATING_DESC',
  TestimonialsByContactIdDistinctCountCreatedTimeAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  TestimonialsByContactIdDistinctCountCreatedTimeDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  TestimonialsByContactIdDistinctCountIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  TestimonialsByContactIdDistinctCountIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  TestimonialsByContactIdDistinctCountLawyerIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  TestimonialsByContactIdDistinctCountLawyerIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  TestimonialsByContactIdDistinctCountNegativeCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_NEGATIVE_COMMENTS_ASC',
  TestimonialsByContactIdDistinctCountNegativeCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_NEGATIVE_COMMENTS_DESC',
  TestimonialsByContactIdDistinctCountPositiveCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_POSITIVE_COMMENTS_ASC',
  TestimonialsByContactIdDistinctCountPositiveCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_POSITIVE_COMMENTS_DESC',
  TestimonialsByContactIdDistinctCountPublicAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_PUBLIC_ASC',
  TestimonialsByContactIdDistinctCountPublicDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_PUBLIC_DESC',
  TestimonialsByContactIdDistinctCountReferenceAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_REFERENCE_ASC',
  TestimonialsByContactIdDistinctCountReferenceDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_REFERENCE_DESC',
  TestimonialsByContactIdDistinctCountResponsivenessRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_RESPONSIVENESS_RATING_ASC',
  TestimonialsByContactIdDistinctCountResponsivenessRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_RESPONSIVENESS_RATING_DESC',
  TestimonialsByContactIdDistinctCountServiceDescriptionAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_SERVICE_DESCRIPTION_ASC',
  TestimonialsByContactIdDistinctCountServiceDescriptionDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_SERVICE_DESCRIPTION_DESC',
  TestimonialsByContactIdDistinctCountTargetRelationshipAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_TARGET_RELATIONSHIP_ASC',
  TestimonialsByContactIdDistinctCountTargetRelationshipDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_TARGET_RELATIONSHIP_DESC',
  TestimonialsByContactIdDistinctCountTimeRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_TIME_RATING_ASC',
  TestimonialsByContactIdDistinctCountTimeRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_DISTINCT_COUNT_TIME_RATING_DESC',
  TestimonialsByContactIdMaxCommunicationRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_COMMUNICATION_RATING_ASC',
  TestimonialsByContactIdMaxCommunicationRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_COMMUNICATION_RATING_DESC',
  TestimonialsByContactIdMaxContactIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  TestimonialsByContactIdMaxContactIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  TestimonialsByContactIdMaxCostRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_COST_RATING_ASC',
  TestimonialsByContactIdMaxCostRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_COST_RATING_DESC',
  TestimonialsByContactIdMaxCreatedTimeAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  TestimonialsByContactIdMaxCreatedTimeDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  TestimonialsByContactIdMaxIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_ID_ASC',
  TestimonialsByContactIdMaxIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_ID_DESC',
  TestimonialsByContactIdMaxLawyerIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_LAWYER_ID_ASC',
  TestimonialsByContactIdMaxLawyerIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_LAWYER_ID_DESC',
  TestimonialsByContactIdMaxNegativeCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_NEGATIVE_COMMENTS_ASC',
  TestimonialsByContactIdMaxNegativeCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_NEGATIVE_COMMENTS_DESC',
  TestimonialsByContactIdMaxPositiveCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_POSITIVE_COMMENTS_ASC',
  TestimonialsByContactIdMaxPositiveCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_POSITIVE_COMMENTS_DESC',
  TestimonialsByContactIdMaxPublicAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_PUBLIC_ASC',
  TestimonialsByContactIdMaxPublicDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_PUBLIC_DESC',
  TestimonialsByContactIdMaxReferenceAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_REFERENCE_ASC',
  TestimonialsByContactIdMaxReferenceDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_REFERENCE_DESC',
  TestimonialsByContactIdMaxResponsivenessRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_RESPONSIVENESS_RATING_ASC',
  TestimonialsByContactIdMaxResponsivenessRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_RESPONSIVENESS_RATING_DESC',
  TestimonialsByContactIdMaxServiceDescriptionAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_SERVICE_DESCRIPTION_ASC',
  TestimonialsByContactIdMaxServiceDescriptionDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_SERVICE_DESCRIPTION_DESC',
  TestimonialsByContactIdMaxTargetRelationshipAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_TARGET_RELATIONSHIP_ASC',
  TestimonialsByContactIdMaxTargetRelationshipDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_TARGET_RELATIONSHIP_DESC',
  TestimonialsByContactIdMaxTimeRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_TIME_RATING_ASC',
  TestimonialsByContactIdMaxTimeRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_MAX_TIME_RATING_DESC',
  TestimonialsByContactIdMinCommunicationRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_COMMUNICATION_RATING_ASC',
  TestimonialsByContactIdMinCommunicationRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_COMMUNICATION_RATING_DESC',
  TestimonialsByContactIdMinContactIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  TestimonialsByContactIdMinContactIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  TestimonialsByContactIdMinCostRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_COST_RATING_ASC',
  TestimonialsByContactIdMinCostRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_COST_RATING_DESC',
  TestimonialsByContactIdMinCreatedTimeAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  TestimonialsByContactIdMinCreatedTimeDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  TestimonialsByContactIdMinIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_ID_ASC',
  TestimonialsByContactIdMinIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_ID_DESC',
  TestimonialsByContactIdMinLawyerIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_LAWYER_ID_ASC',
  TestimonialsByContactIdMinLawyerIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_LAWYER_ID_DESC',
  TestimonialsByContactIdMinNegativeCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_NEGATIVE_COMMENTS_ASC',
  TestimonialsByContactIdMinNegativeCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_NEGATIVE_COMMENTS_DESC',
  TestimonialsByContactIdMinPositiveCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_POSITIVE_COMMENTS_ASC',
  TestimonialsByContactIdMinPositiveCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_POSITIVE_COMMENTS_DESC',
  TestimonialsByContactIdMinPublicAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_PUBLIC_ASC',
  TestimonialsByContactIdMinPublicDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_PUBLIC_DESC',
  TestimonialsByContactIdMinReferenceAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_REFERENCE_ASC',
  TestimonialsByContactIdMinReferenceDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_REFERENCE_DESC',
  TestimonialsByContactIdMinResponsivenessRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_RESPONSIVENESS_RATING_ASC',
  TestimonialsByContactIdMinResponsivenessRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_RESPONSIVENESS_RATING_DESC',
  TestimonialsByContactIdMinServiceDescriptionAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_SERVICE_DESCRIPTION_ASC',
  TestimonialsByContactIdMinServiceDescriptionDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_SERVICE_DESCRIPTION_DESC',
  TestimonialsByContactIdMinTargetRelationshipAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_TARGET_RELATIONSHIP_ASC',
  TestimonialsByContactIdMinTargetRelationshipDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_TARGET_RELATIONSHIP_DESC',
  TestimonialsByContactIdMinTimeRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_TIME_RATING_ASC',
  TestimonialsByContactIdMinTimeRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_MIN_TIME_RATING_DESC',
  TestimonialsByContactIdStddevPopulationCommunicationRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_COMMUNICATION_RATING_ASC',
  TestimonialsByContactIdStddevPopulationCommunicationRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_COMMUNICATION_RATING_DESC',
  TestimonialsByContactIdStddevPopulationContactIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  TestimonialsByContactIdStddevPopulationContactIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  TestimonialsByContactIdStddevPopulationCostRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_COST_RATING_ASC',
  TestimonialsByContactIdStddevPopulationCostRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_COST_RATING_DESC',
  TestimonialsByContactIdStddevPopulationCreatedTimeAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  TestimonialsByContactIdStddevPopulationCreatedTimeDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  TestimonialsByContactIdStddevPopulationIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  TestimonialsByContactIdStddevPopulationIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  TestimonialsByContactIdStddevPopulationLawyerIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  TestimonialsByContactIdStddevPopulationLawyerIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  TestimonialsByContactIdStddevPopulationNegativeCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_NEGATIVE_COMMENTS_ASC',
  TestimonialsByContactIdStddevPopulationNegativeCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_NEGATIVE_COMMENTS_DESC',
  TestimonialsByContactIdStddevPopulationPositiveCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_POSITIVE_COMMENTS_ASC',
  TestimonialsByContactIdStddevPopulationPositiveCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_POSITIVE_COMMENTS_DESC',
  TestimonialsByContactIdStddevPopulationPublicAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_PUBLIC_ASC',
  TestimonialsByContactIdStddevPopulationPublicDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_PUBLIC_DESC',
  TestimonialsByContactIdStddevPopulationReferenceAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_REFERENCE_ASC',
  TestimonialsByContactIdStddevPopulationReferenceDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_REFERENCE_DESC',
  TestimonialsByContactIdStddevPopulationResponsivenessRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_RESPONSIVENESS_RATING_ASC',
  TestimonialsByContactIdStddevPopulationResponsivenessRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_RESPONSIVENESS_RATING_DESC',
  TestimonialsByContactIdStddevPopulationServiceDescriptionAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_SERVICE_DESCRIPTION_ASC',
  TestimonialsByContactIdStddevPopulationServiceDescriptionDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_SERVICE_DESCRIPTION_DESC',
  TestimonialsByContactIdStddevPopulationTargetRelationshipAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_TARGET_RELATIONSHIP_ASC',
  TestimonialsByContactIdStddevPopulationTargetRelationshipDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_TARGET_RELATIONSHIP_DESC',
  TestimonialsByContactIdStddevPopulationTimeRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_TIME_RATING_ASC',
  TestimonialsByContactIdStddevPopulationTimeRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_POPULATION_TIME_RATING_DESC',
  TestimonialsByContactIdStddevSampleCommunicationRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_COMMUNICATION_RATING_ASC',
  TestimonialsByContactIdStddevSampleCommunicationRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_COMMUNICATION_RATING_DESC',
  TestimonialsByContactIdStddevSampleContactIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  TestimonialsByContactIdStddevSampleContactIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  TestimonialsByContactIdStddevSampleCostRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_COST_RATING_ASC',
  TestimonialsByContactIdStddevSampleCostRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_COST_RATING_DESC',
  TestimonialsByContactIdStddevSampleCreatedTimeAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  TestimonialsByContactIdStddevSampleCreatedTimeDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  TestimonialsByContactIdStddevSampleIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  TestimonialsByContactIdStddevSampleIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  TestimonialsByContactIdStddevSampleLawyerIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  TestimonialsByContactIdStddevSampleLawyerIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  TestimonialsByContactIdStddevSampleNegativeCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_NEGATIVE_COMMENTS_ASC',
  TestimonialsByContactIdStddevSampleNegativeCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_NEGATIVE_COMMENTS_DESC',
  TestimonialsByContactIdStddevSamplePositiveCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_POSITIVE_COMMENTS_ASC',
  TestimonialsByContactIdStddevSamplePositiveCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_POSITIVE_COMMENTS_DESC',
  TestimonialsByContactIdStddevSamplePublicAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_PUBLIC_ASC',
  TestimonialsByContactIdStddevSamplePublicDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_PUBLIC_DESC',
  TestimonialsByContactIdStddevSampleReferenceAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_REFERENCE_ASC',
  TestimonialsByContactIdStddevSampleReferenceDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_REFERENCE_DESC',
  TestimonialsByContactIdStddevSampleResponsivenessRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_RESPONSIVENESS_RATING_ASC',
  TestimonialsByContactIdStddevSampleResponsivenessRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_RESPONSIVENESS_RATING_DESC',
  TestimonialsByContactIdStddevSampleServiceDescriptionAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_SERVICE_DESCRIPTION_ASC',
  TestimonialsByContactIdStddevSampleServiceDescriptionDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_SERVICE_DESCRIPTION_DESC',
  TestimonialsByContactIdStddevSampleTargetRelationshipAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_TARGET_RELATIONSHIP_ASC',
  TestimonialsByContactIdStddevSampleTargetRelationshipDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_TARGET_RELATIONSHIP_DESC',
  TestimonialsByContactIdStddevSampleTimeRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_TIME_RATING_ASC',
  TestimonialsByContactIdStddevSampleTimeRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_STDDEV_SAMPLE_TIME_RATING_DESC',
  TestimonialsByContactIdSumCommunicationRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_COMMUNICATION_RATING_ASC',
  TestimonialsByContactIdSumCommunicationRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_COMMUNICATION_RATING_DESC',
  TestimonialsByContactIdSumContactIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  TestimonialsByContactIdSumContactIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  TestimonialsByContactIdSumCostRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_COST_RATING_ASC',
  TestimonialsByContactIdSumCostRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_COST_RATING_DESC',
  TestimonialsByContactIdSumCreatedTimeAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  TestimonialsByContactIdSumCreatedTimeDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  TestimonialsByContactIdSumIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_ID_ASC',
  TestimonialsByContactIdSumIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_ID_DESC',
  TestimonialsByContactIdSumLawyerIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_LAWYER_ID_ASC',
  TestimonialsByContactIdSumLawyerIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_LAWYER_ID_DESC',
  TestimonialsByContactIdSumNegativeCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_NEGATIVE_COMMENTS_ASC',
  TestimonialsByContactIdSumNegativeCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_NEGATIVE_COMMENTS_DESC',
  TestimonialsByContactIdSumPositiveCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_POSITIVE_COMMENTS_ASC',
  TestimonialsByContactIdSumPositiveCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_POSITIVE_COMMENTS_DESC',
  TestimonialsByContactIdSumPublicAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_PUBLIC_ASC',
  TestimonialsByContactIdSumPublicDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_PUBLIC_DESC',
  TestimonialsByContactIdSumReferenceAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_REFERENCE_ASC',
  TestimonialsByContactIdSumReferenceDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_REFERENCE_DESC',
  TestimonialsByContactIdSumResponsivenessRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_RESPONSIVENESS_RATING_ASC',
  TestimonialsByContactIdSumResponsivenessRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_RESPONSIVENESS_RATING_DESC',
  TestimonialsByContactIdSumServiceDescriptionAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_SERVICE_DESCRIPTION_ASC',
  TestimonialsByContactIdSumServiceDescriptionDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_SERVICE_DESCRIPTION_DESC',
  TestimonialsByContactIdSumTargetRelationshipAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_TARGET_RELATIONSHIP_ASC',
  TestimonialsByContactIdSumTargetRelationshipDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_TARGET_RELATIONSHIP_DESC',
  TestimonialsByContactIdSumTimeRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_TIME_RATING_ASC',
  TestimonialsByContactIdSumTimeRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_SUM_TIME_RATING_DESC',
  TestimonialsByContactIdVariancePopulationCommunicationRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_COMMUNICATION_RATING_ASC',
  TestimonialsByContactIdVariancePopulationCommunicationRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_COMMUNICATION_RATING_DESC',
  TestimonialsByContactIdVariancePopulationContactIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  TestimonialsByContactIdVariancePopulationContactIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  TestimonialsByContactIdVariancePopulationCostRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_COST_RATING_ASC',
  TestimonialsByContactIdVariancePopulationCostRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_COST_RATING_DESC',
  TestimonialsByContactIdVariancePopulationCreatedTimeAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  TestimonialsByContactIdVariancePopulationCreatedTimeDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  TestimonialsByContactIdVariancePopulationIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  TestimonialsByContactIdVariancePopulationIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  TestimonialsByContactIdVariancePopulationLawyerIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  TestimonialsByContactIdVariancePopulationLawyerIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  TestimonialsByContactIdVariancePopulationNegativeCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_NEGATIVE_COMMENTS_ASC',
  TestimonialsByContactIdVariancePopulationNegativeCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_NEGATIVE_COMMENTS_DESC',
  TestimonialsByContactIdVariancePopulationPositiveCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_POSITIVE_COMMENTS_ASC',
  TestimonialsByContactIdVariancePopulationPositiveCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_POSITIVE_COMMENTS_DESC',
  TestimonialsByContactIdVariancePopulationPublicAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_PUBLIC_ASC',
  TestimonialsByContactIdVariancePopulationPublicDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_PUBLIC_DESC',
  TestimonialsByContactIdVariancePopulationReferenceAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_REFERENCE_ASC',
  TestimonialsByContactIdVariancePopulationReferenceDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_REFERENCE_DESC',
  TestimonialsByContactIdVariancePopulationResponsivenessRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_RESPONSIVENESS_RATING_ASC',
  TestimonialsByContactIdVariancePopulationResponsivenessRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_RESPONSIVENESS_RATING_DESC',
  TestimonialsByContactIdVariancePopulationServiceDescriptionAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_SERVICE_DESCRIPTION_ASC',
  TestimonialsByContactIdVariancePopulationServiceDescriptionDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_SERVICE_DESCRIPTION_DESC',
  TestimonialsByContactIdVariancePopulationTargetRelationshipAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_TARGET_RELATIONSHIP_ASC',
  TestimonialsByContactIdVariancePopulationTargetRelationshipDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_TARGET_RELATIONSHIP_DESC',
  TestimonialsByContactIdVariancePopulationTimeRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_TIME_RATING_ASC',
  TestimonialsByContactIdVariancePopulationTimeRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_POPULATION_TIME_RATING_DESC',
  TestimonialsByContactIdVarianceSampleCommunicationRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_COMMUNICATION_RATING_ASC',
  TestimonialsByContactIdVarianceSampleCommunicationRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_COMMUNICATION_RATING_DESC',
  TestimonialsByContactIdVarianceSampleContactIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  TestimonialsByContactIdVarianceSampleContactIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  TestimonialsByContactIdVarianceSampleCostRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_COST_RATING_ASC',
  TestimonialsByContactIdVarianceSampleCostRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_COST_RATING_DESC',
  TestimonialsByContactIdVarianceSampleCreatedTimeAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  TestimonialsByContactIdVarianceSampleCreatedTimeDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  TestimonialsByContactIdVarianceSampleIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  TestimonialsByContactIdVarianceSampleIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  TestimonialsByContactIdVarianceSampleLawyerIdAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  TestimonialsByContactIdVarianceSampleLawyerIdDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  TestimonialsByContactIdVarianceSampleNegativeCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_NEGATIVE_COMMENTS_ASC',
  TestimonialsByContactIdVarianceSampleNegativeCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_NEGATIVE_COMMENTS_DESC',
  TestimonialsByContactIdVarianceSamplePositiveCommentsAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_POSITIVE_COMMENTS_ASC',
  TestimonialsByContactIdVarianceSamplePositiveCommentsDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_POSITIVE_COMMENTS_DESC',
  TestimonialsByContactIdVarianceSamplePublicAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_PUBLIC_ASC',
  TestimonialsByContactIdVarianceSamplePublicDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_PUBLIC_DESC',
  TestimonialsByContactIdVarianceSampleReferenceAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_REFERENCE_ASC',
  TestimonialsByContactIdVarianceSampleReferenceDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_REFERENCE_DESC',
  TestimonialsByContactIdVarianceSampleResponsivenessRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_RESPONSIVENESS_RATING_ASC',
  TestimonialsByContactIdVarianceSampleResponsivenessRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_RESPONSIVENESS_RATING_DESC',
  TestimonialsByContactIdVarianceSampleServiceDescriptionAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_SERVICE_DESCRIPTION_ASC',
  TestimonialsByContactIdVarianceSampleServiceDescriptionDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_SERVICE_DESCRIPTION_DESC',
  TestimonialsByContactIdVarianceSampleTargetRelationshipAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_TARGET_RELATIONSHIP_ASC',
  TestimonialsByContactIdVarianceSampleTargetRelationshipDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_TARGET_RELATIONSHIP_DESC',
  TestimonialsByContactIdVarianceSampleTimeRatingAsc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_TIME_RATING_ASC',
  TestimonialsByContactIdVarianceSampleTimeRatingDesc = 'TESTIMONIALS_BY_CONTACT_ID_VARIANCE_SAMPLE_TIME_RATING_DESC',
  TimezoneAsc = 'TIMEZONE_ASC',
  TimezoneDesc = 'TIMEZONE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TransactionalEmailsByContactIdAverageCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_CAMPAIGN_ID_ASC',
  TransactionalEmailsByContactIdAverageCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_CAMPAIGN_ID_DESC',
  TransactionalEmailsByContactIdAverageContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_ASC',
  TransactionalEmailsByContactIdAverageContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_CONTACT_ID_DESC',
  TransactionalEmailsByContactIdAverageCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_ASC',
  TransactionalEmailsByContactIdAverageCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_CREATED_TIME_DESC',
  TransactionalEmailsByContactIdAverageIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_ID_ASC',
  TransactionalEmailsByContactIdAverageIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_ID_DESC',
  TransactionalEmailsByContactIdAverageLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_ASC',
  TransactionalEmailsByContactIdAverageLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_LAWYER_ID_DESC',
  TransactionalEmailsByContactIdAverageMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_MEETING_ID_ASC',
  TransactionalEmailsByContactIdAverageMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_MEETING_ID_DESC',
  TransactionalEmailsByContactIdAveragePostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByContactIdAveragePostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_AVERAGE_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByContactIdCountAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_COUNT_ASC',
  TransactionalEmailsByContactIdCountDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_COUNT_DESC',
  TransactionalEmailsByContactIdDistinctCountCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  TransactionalEmailsByContactIdDistinctCountCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  TransactionalEmailsByContactIdDistinctCountContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  TransactionalEmailsByContactIdDistinctCountContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  TransactionalEmailsByContactIdDistinctCountCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  TransactionalEmailsByContactIdDistinctCountCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  TransactionalEmailsByContactIdDistinctCountIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_ID_ASC',
  TransactionalEmailsByContactIdDistinctCountIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_ID_DESC',
  TransactionalEmailsByContactIdDistinctCountLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  TransactionalEmailsByContactIdDistinctCountLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  TransactionalEmailsByContactIdDistinctCountMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_MEETING_ID_ASC',
  TransactionalEmailsByContactIdDistinctCountMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_MEETING_ID_DESC',
  TransactionalEmailsByContactIdDistinctCountPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByContactIdDistinctCountPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_DISTINCT_COUNT_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByContactIdMaxCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_CAMPAIGN_ID_ASC',
  TransactionalEmailsByContactIdMaxCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_CAMPAIGN_ID_DESC',
  TransactionalEmailsByContactIdMaxContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_CONTACT_ID_ASC',
  TransactionalEmailsByContactIdMaxContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_CONTACT_ID_DESC',
  TransactionalEmailsByContactIdMaxCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_CREATED_TIME_ASC',
  TransactionalEmailsByContactIdMaxCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_CREATED_TIME_DESC',
  TransactionalEmailsByContactIdMaxIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_ID_ASC',
  TransactionalEmailsByContactIdMaxIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_ID_DESC',
  TransactionalEmailsByContactIdMaxLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_LAWYER_ID_ASC',
  TransactionalEmailsByContactIdMaxLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_LAWYER_ID_DESC',
  TransactionalEmailsByContactIdMaxMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_MEETING_ID_ASC',
  TransactionalEmailsByContactIdMaxMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_MEETING_ID_DESC',
  TransactionalEmailsByContactIdMaxPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByContactIdMaxPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MAX_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByContactIdMinCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_CAMPAIGN_ID_ASC',
  TransactionalEmailsByContactIdMinCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_CAMPAIGN_ID_DESC',
  TransactionalEmailsByContactIdMinContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_CONTACT_ID_ASC',
  TransactionalEmailsByContactIdMinContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_CONTACT_ID_DESC',
  TransactionalEmailsByContactIdMinCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_CREATED_TIME_ASC',
  TransactionalEmailsByContactIdMinCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_CREATED_TIME_DESC',
  TransactionalEmailsByContactIdMinIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_ID_ASC',
  TransactionalEmailsByContactIdMinIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_ID_DESC',
  TransactionalEmailsByContactIdMinLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_LAWYER_ID_ASC',
  TransactionalEmailsByContactIdMinLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_LAWYER_ID_DESC',
  TransactionalEmailsByContactIdMinMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_MEETING_ID_ASC',
  TransactionalEmailsByContactIdMinMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_MEETING_ID_DESC',
  TransactionalEmailsByContactIdMinPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByContactIdMinPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_MIN_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByContactIdStddevPopulationCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  TransactionalEmailsByContactIdStddevPopulationCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  TransactionalEmailsByContactIdStddevPopulationContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  TransactionalEmailsByContactIdStddevPopulationContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  TransactionalEmailsByContactIdStddevPopulationCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  TransactionalEmailsByContactIdStddevPopulationCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  TransactionalEmailsByContactIdStddevPopulationIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_ID_ASC',
  TransactionalEmailsByContactIdStddevPopulationIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_ID_DESC',
  TransactionalEmailsByContactIdStddevPopulationLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  TransactionalEmailsByContactIdStddevPopulationLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  TransactionalEmailsByContactIdStddevPopulationMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_MEETING_ID_ASC',
  TransactionalEmailsByContactIdStddevPopulationMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_MEETING_ID_DESC',
  TransactionalEmailsByContactIdStddevPopulationPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByContactIdStddevPopulationPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_POPULATION_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByContactIdStddevSampleCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  TransactionalEmailsByContactIdStddevSampleCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  TransactionalEmailsByContactIdStddevSampleContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  TransactionalEmailsByContactIdStddevSampleContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  TransactionalEmailsByContactIdStddevSampleCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  TransactionalEmailsByContactIdStddevSampleCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  TransactionalEmailsByContactIdStddevSampleIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_ASC',
  TransactionalEmailsByContactIdStddevSampleIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_ID_DESC',
  TransactionalEmailsByContactIdStddevSampleLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  TransactionalEmailsByContactIdStddevSampleLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  TransactionalEmailsByContactIdStddevSampleMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_MEETING_ID_ASC',
  TransactionalEmailsByContactIdStddevSampleMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_MEETING_ID_DESC',
  TransactionalEmailsByContactIdStddevSamplePostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByContactIdStddevSamplePostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_STDDEV_SAMPLE_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByContactIdSumCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_CAMPAIGN_ID_ASC',
  TransactionalEmailsByContactIdSumCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_CAMPAIGN_ID_DESC',
  TransactionalEmailsByContactIdSumContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_CONTACT_ID_ASC',
  TransactionalEmailsByContactIdSumContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_CONTACT_ID_DESC',
  TransactionalEmailsByContactIdSumCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_CREATED_TIME_ASC',
  TransactionalEmailsByContactIdSumCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_CREATED_TIME_DESC',
  TransactionalEmailsByContactIdSumIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_ID_ASC',
  TransactionalEmailsByContactIdSumIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_ID_DESC',
  TransactionalEmailsByContactIdSumLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_LAWYER_ID_ASC',
  TransactionalEmailsByContactIdSumLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_LAWYER_ID_DESC',
  TransactionalEmailsByContactIdSumMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_MEETING_ID_ASC',
  TransactionalEmailsByContactIdSumMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_MEETING_ID_DESC',
  TransactionalEmailsByContactIdSumPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByContactIdSumPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_SUM_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByContactIdVariancePopulationCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  TransactionalEmailsByContactIdVariancePopulationCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  TransactionalEmailsByContactIdVariancePopulationContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  TransactionalEmailsByContactIdVariancePopulationContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  TransactionalEmailsByContactIdVariancePopulationCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  TransactionalEmailsByContactIdVariancePopulationCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  TransactionalEmailsByContactIdVariancePopulationIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_ASC',
  TransactionalEmailsByContactIdVariancePopulationIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_ID_DESC',
  TransactionalEmailsByContactIdVariancePopulationLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  TransactionalEmailsByContactIdVariancePopulationLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  TransactionalEmailsByContactIdVariancePopulationMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_MEETING_ID_ASC',
  TransactionalEmailsByContactIdVariancePopulationMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_MEETING_ID_DESC',
  TransactionalEmailsByContactIdVariancePopulationPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByContactIdVariancePopulationPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_POPULATION_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByContactIdVarianceSampleCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  TransactionalEmailsByContactIdVarianceSampleCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  TransactionalEmailsByContactIdVarianceSampleContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  TransactionalEmailsByContactIdVarianceSampleContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  TransactionalEmailsByContactIdVarianceSampleCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  TransactionalEmailsByContactIdVarianceSampleCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  TransactionalEmailsByContactIdVarianceSampleIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_ASC',
  TransactionalEmailsByContactIdVarianceSampleIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_ID_DESC',
  TransactionalEmailsByContactIdVarianceSampleLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  TransactionalEmailsByContactIdVarianceSampleLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  TransactionalEmailsByContactIdVarianceSampleMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_MEETING_ID_ASC',
  TransactionalEmailsByContactIdVarianceSampleMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_MEETING_ID_DESC',
  TransactionalEmailsByContactIdVarianceSamplePostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByContactIdVarianceSamplePostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_CONTACT_ID_VARIANCE_SAMPLE_POSTMARK_TEMPLATE_ID_DESC',
  TwitterUrlAsc = 'TWITTER_URL_ASC',
  TwitterUrlDesc = 'TWITTER_URL_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

export type CronofyAuth = Node & {
  __typename?: 'CronofyAuth';
  accessToken: Scalars['String']['output'];
  createdTime?: Maybe<Scalars['Datetime']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Lawyer` that is related to this `CronofyAuth`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  refreshToken: Scalars['String']['output'];
  tokenExpiration: Scalars['String']['output'];
  updatedTime?: Maybe<Scalars['Datetime']['output']>;
};

export type CronofyAuthAggregates = {
  __typename?: 'CronofyAuthAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CronofyAuthDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `CronofyAuth` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CronofyAuthCondition = {
  /** Checks for equality with the object’s `accessToken` field. */
  accessToken?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `refreshToken` field. */
  refreshToken?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `tokenExpiration` field. */
  tokenExpiration?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type CronofyAuthDistinctCountAggregates = {
  __typename?: 'CronofyAuthDistinctCountAggregates';
  /** Distinct count of accessToken across the matching connection */
  accessToken?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of refreshToken across the matching connection */
  refreshToken?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of tokenExpiration across the matching connection */
  tokenExpiration?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `CronofyAuth` object types. All fields are combined with a logical ‘and.’ */
export type CronofyAuthFilter = {
  /** Filter by the object’s `accessToken` field. */
  accessToken?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CronofyAuthFilter>>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CronofyAuthFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CronofyAuthFilter>>;
  /** Filter by the object’s `refreshToken` field. */
  refreshToken?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tokenExpiration` field. */
  tokenExpiration?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `CronofyAuth` for usage during aggregation. */
export enum CronofyAuthGroupBy {
  AccessToken = 'ACCESS_TOKEN',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  LawyerId = 'LAWYER_ID',
  RefreshToken = 'REFRESH_TOKEN',
  TokenExpiration = 'TOKEN_EXPIRATION',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type CronofyAuthHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CronofyAuthHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `CronofyAuth` aggregates. */
export type CronofyAuthHavingInput = {
  AND?: InputMaybe<Array<CronofyAuthHavingInput>>;
  OR?: InputMaybe<Array<CronofyAuthHavingInput>>;
  average?: InputMaybe<CronofyAuthHavingAverageInput>;
  distinctCount?: InputMaybe<CronofyAuthHavingDistinctCountInput>;
  max?: InputMaybe<CronofyAuthHavingMaxInput>;
  min?: InputMaybe<CronofyAuthHavingMinInput>;
  stddevPopulation?: InputMaybe<CronofyAuthHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CronofyAuthHavingStddevSampleInput>;
  sum?: InputMaybe<CronofyAuthHavingSumInput>;
  variancePopulation?: InputMaybe<CronofyAuthHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CronofyAuthHavingVarianceSampleInput>;
};

export type CronofyAuthHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CronofyAuthHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CronofyAuthHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CronofyAuthHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CronofyAuthHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CronofyAuthHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type CronofyAuthHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type CronofyAuthSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: CronofyAuthSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type CronofyAuthSortBy = {
  field?: InputMaybe<CronofyAuthSortableField>;
};

/** Sortable concrete fields for the `CronofyAuth` type. */
export enum CronofyAuthSortableField {
  AccessToken = 'ACCESS_TOKEN',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  LawyerId = 'LAWYER_ID',
  RefreshToken = 'REFRESH_TOKEN',
  TokenExpiration = 'TOKEN_EXPIRATION',
  UpdatedTime = 'UPDATED_TIME'
}

/** A connection to a list of `CronofyAuth` values. */
export type CronofyAuthsConnection = {
  __typename?: 'CronofyAuthsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CronofyAuthAggregates>;
  /** A list of edges which contains the `CronofyAuth` and cursor to aid in pagination. */
  edges: Array<CronofyAuthsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CronofyAuthAggregates>>;
  /** A list of `CronofyAuth` objects. */
  nodes: Array<Maybe<CronofyAuth>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CronofyAuth` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `CronofyAuth` values. */
export type CronofyAuthsConnectionGroupedAggregatesArgs = {
  groupBy: Array<CronofyAuthGroupBy>;
  having?: InputMaybe<CronofyAuthHavingInput>;
};

/** A `CronofyAuth` edge in the connection. */
export type CronofyAuthsEdge = {
  __typename?: 'CronofyAuthsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `CronofyAuth` at the end of the edge. */
  node?: Maybe<CronofyAuth>;
};

/** Methods to use when ordering `CronofyAuth`. */
export enum CronofyAuthsOrderBy {
  AccessTokenAsc = 'ACCESS_TOKEN_ASC',
  AccessTokenDesc = 'ACCESS_TOKEN_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RefreshTokenAsc = 'REFRESH_TOKEN_ASC',
  RefreshTokenDesc = 'REFRESH_TOKEN_DESC',
  TokenExpirationAsc = 'TOKEN_EXPIRATION_ASC',
  TokenExpirationDesc = 'TOKEN_EXPIRATION_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

export type DailyStatistic = Node & {
  __typename?: 'DailyStatistic';
  /** Reads a single `Campaign` that is related to this `DailyStatistic`. */
  campaignByCampaignId?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['UUID']['output']>;
  date: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  groupName?: Maybe<Scalars['String']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Lawyer` that is related to this `DailyStatistic`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Template` that is related to this `DailyStatistic`. */
  templateByTemplateId?: Maybe<Template>;
  templateId?: Maybe<Scalars['UUID']['output']>;
  value: Scalars['Float']['output'];
};

export type DailyStatisticAggregates = {
  __typename?: 'DailyStatisticAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DailyStatisticAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DailyStatisticDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DailyStatisticMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DailyStatisticMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DailyStatisticStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DailyStatisticStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DailyStatisticSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DailyStatisticVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DailyStatisticVarianceSampleAggregates>;
};

export type DailyStatisticAverageAggregates = {
  __typename?: 'DailyStatisticAverageAggregates';
  /** Mean average of value across the matching connection */
  value?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `DailyStatistic` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DailyStatisticCondition = {
  /** Checks for equality with the object’s `campaignId` field. */
  campaignId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `groupName` field. */
  groupName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `templateId` field. */
  templateId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['Float']['input']>;
};

export type DailyStatisticDistinctCountAggregates = {
  __typename?: 'DailyStatisticDistinctCountAggregates';
  /** Distinct count of campaignId across the matching connection */
  campaignId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of date across the matching connection */
  date?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of groupName across the matching connection */
  groupName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templateId across the matching connection */
  templateId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `DailyStatistic` object types. All fields are combined with a logical ‘and.’ */
export type DailyStatisticFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DailyStatisticFilter>>;
  /** Filter by the object’s `campaignId` field. */
  campaignId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `date` field. */
  date?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `groupName` field. */
  groupName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DailyStatisticFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DailyStatisticFilter>>;
  /** Filter by the object’s `templateId` field. */
  templateId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<FloatFilter>;
};

export type DailyStatisticMaxAggregates = {
  __typename?: 'DailyStatisticMaxAggregates';
  /** Maximum of value across the matching connection */
  value?: Maybe<Scalars['Float']['output']>;
};

export type DailyStatisticMinAggregates = {
  __typename?: 'DailyStatisticMinAggregates';
  /** Minimum of value across the matching connection */
  value?: Maybe<Scalars['Float']['output']>;
};

export type DailyStatisticStddevPopulationAggregates = {
  __typename?: 'DailyStatisticStddevPopulationAggregates';
  /** Population standard deviation of value across the matching connection */
  value?: Maybe<Scalars['Float']['output']>;
};

export type DailyStatisticStddevSampleAggregates = {
  __typename?: 'DailyStatisticStddevSampleAggregates';
  /** Sample standard deviation of value across the matching connection */
  value?: Maybe<Scalars['Float']['output']>;
};

export type DailyStatisticSumAggregates = {
  __typename?: 'DailyStatisticSumAggregates';
  /** Sum of value across the matching connection */
  value: Scalars['Float']['output'];
};

export type DailyStatisticVariancePopulationAggregates = {
  __typename?: 'DailyStatisticVariancePopulationAggregates';
  /** Population variance of value across the matching connection */
  value?: Maybe<Scalars['Float']['output']>;
};

export type DailyStatisticVarianceSampleAggregates = {
  __typename?: 'DailyStatisticVarianceSampleAggregates';
  /** Sample variance of value across the matching connection */
  value?: Maybe<Scalars['Float']['output']>;
};

/** A connection to a list of `DailyStatistic` values. */
export type DailyStatisticsConnection = {
  __typename?: 'DailyStatisticsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DailyStatisticAggregates>;
  /** A list of edges which contains the `DailyStatistic` and cursor to aid in pagination. */
  edges: Array<DailyStatisticsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DailyStatisticAggregates>>;
  /** A list of `DailyStatistic` objects. */
  nodes: Array<Maybe<DailyStatistic>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DailyStatistic` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `DailyStatistic` values. */
export type DailyStatisticsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DailyStatisticsGroupBy>;
  having?: InputMaybe<DailyStatisticsHavingInput>;
};

/** A `DailyStatistic` edge in the connection. */
export type DailyStatisticsEdge = {
  __typename?: 'DailyStatisticsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `DailyStatistic` at the end of the edge. */
  node?: Maybe<DailyStatistic>;
};

/** Grouping methods for `DailyStatistic` for usage during aggregation. */
export enum DailyStatisticsGroupBy {
  CampaignId = 'CAMPAIGN_ID',
  Date = 'DATE',
  DateTruncatedToDay = 'DATE_TRUNCATED_TO_DAY',
  DateTruncatedToHour = 'DATE_TRUNCATED_TO_HOUR',
  GroupName = 'GROUP_NAME',
  LawyerId = 'LAWYER_ID',
  Name = 'NAME',
  TemplateId = 'TEMPLATE_ID',
  Value = 'VALUE'
}

export type DailyStatisticsHavingAverageInput = {
  date?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type DailyStatisticsHavingDistinctCountInput = {
  date?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `DailyStatistic` aggregates. */
export type DailyStatisticsHavingInput = {
  AND?: InputMaybe<Array<DailyStatisticsHavingInput>>;
  OR?: InputMaybe<Array<DailyStatisticsHavingInput>>;
  average?: InputMaybe<DailyStatisticsHavingAverageInput>;
  distinctCount?: InputMaybe<DailyStatisticsHavingDistinctCountInput>;
  max?: InputMaybe<DailyStatisticsHavingMaxInput>;
  min?: InputMaybe<DailyStatisticsHavingMinInput>;
  stddevPopulation?: InputMaybe<DailyStatisticsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DailyStatisticsHavingStddevSampleInput>;
  sum?: InputMaybe<DailyStatisticsHavingSumInput>;
  variancePopulation?: InputMaybe<DailyStatisticsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DailyStatisticsHavingVarianceSampleInput>;
};

export type DailyStatisticsHavingMaxInput = {
  date?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type DailyStatisticsHavingMinInput = {
  date?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type DailyStatisticsHavingStddevPopulationInput = {
  date?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type DailyStatisticsHavingStddevSampleInput = {
  date?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type DailyStatisticsHavingSumInput = {
  date?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type DailyStatisticsHavingVariancePopulationInput = {
  date?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

export type DailyStatisticsHavingVarianceSampleInput = {
  date?: InputMaybe<HavingDatetimeFilter>;
  value?: InputMaybe<HavingFloatFilter>;
};

/** Methods to use when ordering `DailyStatistic`. */
export enum DailyStatisticsOrderBy {
  CampaignIdAsc = 'CAMPAIGN_ID_ASC',
  CampaignIdDesc = 'CAMPAIGN_ID_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  GroupNameAsc = 'GROUP_NAME_ASC',
  GroupNameDesc = 'GROUP_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TemplateIdAsc = 'TEMPLATE_ID_ASC',
  TemplateIdDesc = 'TEMPLATE_ID_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type DailyStatisticsSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: DailyStatisticsSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type DailyStatisticsSortBy = {
  field?: InputMaybe<DailyStatisticsSortableField>;
};

/** Sortable concrete fields for the `DailyStatistic` type. */
export enum DailyStatisticsSortableField {
  CampaignId = 'CAMPAIGN_ID',
  Date = 'DATE',
  GroupName = 'GROUP_NAME',
  Id = 'ID',
  LawyerId = 'LAWYER_ID',
  Name = 'NAME',
  TemplateId = 'TEMPLATE_ID',
  Value = 'VALUE'
}

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']['input']>>;
};

export type Email = Node & {
  __typename?: 'Email';
  /** Reads a single `CampaignMember` that is related to this `Email`. */
  campaignMemberByCampaignMemberId?: Maybe<CampaignMember>;
  campaignMemberId: Scalars['UUID']['output'];
  content?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `EmailEvent`. */
  emailEventsByEmailId: EmailEventsConnection;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByEmailId: EmailMessagesConnection;
  /** Reads and enables pagination through a set of `EmailPersonalisation`. */
  emailPersonalisationsByEmailId: EmailPersonalisationsConnection;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  sendAt: Scalars['Datetime']['output'];
  /** Reads a single `SenderProfile` that is related to this `Email`. */
  senderProfileBySenderProfileId?: Maybe<SenderProfile>;
  senderProfileId?: Maybe<Scalars['UUID']['output']>;
  sentAt?: Maybe<Scalars['Datetime']['output']>;
  smtpMessageId?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  /** Reads a single `Template` that is related to this `Email`. */
  templateByTemplateId?: Maybe<Template>;
  templateContent?: Maybe<Scalars['String']['output']>;
  templateId?: Maybe<Scalars['UUID']['output']>;
  updatedTime: Scalars['Datetime']['output'];
};


export type EmailEmailEventsByEmailIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailEventCondition>;
  filter?: InputMaybe<EmailEventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailEventsOrderBy>>;
  sort?: InputMaybe<Array<EmailEventSort>>;
};


export type EmailEmailMessagesByEmailIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailMessageCondition>;
  filter?: InputMaybe<EmailMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailMessagesOrderBy>>;
  sort?: InputMaybe<Array<EmailMessageSort>>;
};


export type EmailEmailPersonalisationsByEmailIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailPersonalisationCondition>;
  filter?: InputMaybe<EmailPersonalisationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailPersonalisationsOrderBy>>;
  sort?: InputMaybe<Array<EmailPersonalisationSort>>;
};

export type EmailAggregates = {
  __typename?: 'EmailAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EmailDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/** A condition to be used against `Email` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type EmailCondition = {
  /** Checks for equality with the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `sendAt` field. */
  sendAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `senderProfileId` field. */
  senderProfileId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `sentAt` field. */
  sentAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `smtpMessageId` field. */
  smtpMessageId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `templateContent` field. */
  templateContent?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `templateId` field. */
  templateId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type EmailDistinctCountAggregates = {
  __typename?: 'EmailDistinctCountAggregates';
  /** Distinct count of campaignMemberId across the matching connection */
  campaignMemberId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of content across the matching connection */
  content?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of sendAt across the matching connection */
  sendAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of senderProfileId across the matching connection */
  senderProfileId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of sentAt across the matching connection */
  sentAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of smtpMessageId across the matching connection */
  smtpMessageId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templateContent across the matching connection */
  templateContent?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templateId across the matching connection */
  templateId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

export type EmailEvent = Node & {
  __typename?: 'EmailEvent';
  createdTime: Scalars['Datetime']['output'];
  /** Reads a single `Email` that is related to this `EmailEvent`. */
  emailByEmailId?: Maybe<Email>;
  emailId: Scalars['UUID']['output'];
  eventName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  sendgridEventId?: Maybe<Scalars['String']['output']>;
};

export type EmailEventAggregates = {
  __typename?: 'EmailEventAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EmailEventDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `EmailEvent` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EmailEventCondition = {
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `emailId` field. */
  emailId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `eventName` field. */
  eventName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `sendgridEventId` field. */
  sendgridEventId?: InputMaybe<Scalars['String']['input']>;
};

export type EmailEventDistinctCountAggregates = {
  __typename?: 'EmailEventDistinctCountAggregates';
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emailId across the matching connection */
  emailId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of eventName across the matching connection */
  eventName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of sendgridEventId across the matching connection */
  sendgridEventId?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `EmailEvent` object types. All fields are combined with a logical ‘and.’ */
export type EmailEventFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EmailEventFilter>>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `emailId` field. */
  emailId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `eventName` field. */
  eventName?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EmailEventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EmailEventFilter>>;
  /** Filter by the object’s `sendgridEventId` field. */
  sendgridEventId?: InputMaybe<StringFilter>;
};

/** Grouping methods for `EmailEvent` for usage during aggregation. */
export enum EmailEventGroupBy {
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  EmailId = 'EMAIL_ID',
  EventName = 'EVENT_NAME',
  SendgridEventId = 'SENDGRID_EVENT_ID'
}

export type EmailEventHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailEventHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `EmailEvent` aggregates. */
export type EmailEventHavingInput = {
  AND?: InputMaybe<Array<EmailEventHavingInput>>;
  OR?: InputMaybe<Array<EmailEventHavingInput>>;
  average?: InputMaybe<EmailEventHavingAverageInput>;
  distinctCount?: InputMaybe<EmailEventHavingDistinctCountInput>;
  max?: InputMaybe<EmailEventHavingMaxInput>;
  min?: InputMaybe<EmailEventHavingMinInput>;
  stddevPopulation?: InputMaybe<EmailEventHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<EmailEventHavingStddevSampleInput>;
  sum?: InputMaybe<EmailEventHavingSumInput>;
  variancePopulation?: InputMaybe<EmailEventHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<EmailEventHavingVarianceSampleInput>;
};

export type EmailEventHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailEventHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailEventHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailEventHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailEventHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailEventHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailEventHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type EmailEventSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: EmailEventSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type EmailEventSortBy = {
  field?: InputMaybe<EmailEventSortableField>;
};

/** Sortable concrete fields for the `EmailEvent` type. */
export enum EmailEventSortableField {
  CreatedTime = 'CREATED_TIME',
  EmailId = 'EMAIL_ID',
  EventName = 'EVENT_NAME',
  SendgridEventId = 'SENDGRID_EVENT_ID'
}

/** A connection to a list of `EmailEvent` values. */
export type EmailEventsConnection = {
  __typename?: 'EmailEventsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EmailEventAggregates>;
  /** A list of edges which contains the `EmailEvent` and cursor to aid in pagination. */
  edges: Array<EmailEventsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EmailEventAggregates>>;
  /** A list of `EmailEvent` objects. */
  nodes: Array<Maybe<EmailEvent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EmailEvent` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `EmailEvent` values. */
export type EmailEventsConnectionGroupedAggregatesArgs = {
  groupBy: Array<EmailEventGroupBy>;
  having?: InputMaybe<EmailEventHavingInput>;
};

/** A `EmailEvent` edge in the connection. */
export type EmailEventsEdge = {
  __typename?: 'EmailEventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `EmailEvent` at the end of the edge. */
  node?: Maybe<EmailEvent>;
};

/** Methods to use when ordering `EmailEvent`. */
export enum EmailEventsOrderBy {
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  EmailIdAsc = 'EMAIL_ID_ASC',
  EmailIdDesc = 'EMAIL_ID_DESC',
  EventNameAsc = 'EVENT_NAME_ASC',
  EventNameDesc = 'EVENT_NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SendgridEventIdAsc = 'SENDGRID_EVENT_ID_ASC',
  SendgridEventIdDesc = 'SENDGRID_EVENT_ID_DESC'
}

/** A filter to be used against `Email` object types. All fields are combined with a logical ‘and.’ */
export type EmailFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EmailFilter>>;
  /** Filter by the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EmailFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EmailFilter>>;
  /** Filter by the object’s `sendAt` field. */
  sendAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `senderProfileId` field. */
  senderProfileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `sentAt` field. */
  sentAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `smtpMessageId` field. */
  smtpMessageId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `templateContent` field. */
  templateContent?: InputMaybe<StringFilter>;
  /** Filter by the object’s `templateId` field. */
  templateId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Email` for usage during aggregation. */
export enum EmailGroupBy {
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  Content = 'CONTENT',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  SenderProfileId = 'SENDER_PROFILE_ID',
  SendAt = 'SEND_AT',
  SendAtTruncatedToDay = 'SEND_AT_TRUNCATED_TO_DAY',
  SendAtTruncatedToHour = 'SEND_AT_TRUNCATED_TO_HOUR',
  SentAt = 'SENT_AT',
  SentAtTruncatedToDay = 'SENT_AT_TRUNCATED_TO_DAY',
  SentAtTruncatedToHour = 'SENT_AT_TRUNCATED_TO_HOUR',
  SmtpMessageId = 'SMTP_MESSAGE_ID',
  Status = 'STATUS',
  TemplateContent = 'TEMPLATE_CONTENT',
  TemplateId = 'TEMPLATE_ID',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type EmailHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  sentAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  sentAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Email` aggregates. */
export type EmailHavingInput = {
  AND?: InputMaybe<Array<EmailHavingInput>>;
  OR?: InputMaybe<Array<EmailHavingInput>>;
  average?: InputMaybe<EmailHavingAverageInput>;
  distinctCount?: InputMaybe<EmailHavingDistinctCountInput>;
  max?: InputMaybe<EmailHavingMaxInput>;
  min?: InputMaybe<EmailHavingMinInput>;
  stddevPopulation?: InputMaybe<EmailHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<EmailHavingStddevSampleInput>;
  sum?: InputMaybe<EmailHavingSumInput>;
  variancePopulation?: InputMaybe<EmailHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<EmailHavingVarianceSampleInput>;
};

export type EmailHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  sentAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  sentAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  sentAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  sentAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  sentAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  sentAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  sentAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailMessage = Node & {
  __typename?: 'EmailMessage';
  autoReplied: Scalars['Boolean']['output'];
  /** Reads a single `Campaign` that is related to this `EmailMessage`. */
  campaignByCampaignId?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `CampaignMember` that is related to this `EmailMessage`. */
  campaignMemberByCampaignMemberId?: Maybe<CampaignMember>;
  campaignMemberId?: Maybe<Scalars['UUID']['output']>;
  ccLawyerTime?: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `Company` that is related to this `EmailMessage`. */
  companyByCompanyId?: Maybe<Company>;
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `Contact` that is related to this `EmailMessage`. */
  contactByContactId?: Maybe<Contact>;
  contactId: Scalars['UUID']['output'];
  content: Scalars['String']['output'];
  contentHtml?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['Datetime']['output'];
  /** Reads a single `Email` that is related to this `EmailMessage`. */
  emailByEmailId?: Maybe<Email>;
  emailId?: Maybe<Scalars['UUID']['output']>;
  forwardedInternallyTime?: Maybe<Scalars['Datetime']['output']>;
  forwardedToLawyerTime?: Maybe<Scalars['Datetime']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  generatedReply?: Maybe<Scalars['String']['output']>;
  hedwigMessageId?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `HedwigMessagePayload`. */
  hedwigMessagePayloadsByEmailMessageId: HedwigMessagePayloadsConnection;
  id: Scalars['UUID']['output'];
  intent?: Maybe<Scalars['String']['output']>;
  isLawyer: Scalars['Boolean']['output'];
  isReviewed: Scalars['Boolean']['output'];
  /** Reads a single `Lawyer` that is related to this `EmailMessage`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId: Scalars['UUID']['output'];
  lawyerViewedTime?: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  nylasThreadId?: Maybe<Scalars['String']['output']>;
  orderIndex?: Maybe<Scalars['Int']['output']>;
  previousReplyAttemptTime?: Maybe<Scalars['Datetime']['output']>;
  repliedIntent?: Maybe<Scalars['String']['output']>;
  repliedTime?: Maybe<Scalars['Datetime']['output']>;
  requestedCcLawyerTime?: Maybe<Scalars['Datetime']['output']>;
  reviewedTime?: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `SenderProfile` that is related to this `EmailMessage`. */
  senderProfileBySenderProfileId?: Maybe<SenderProfile>;
  senderProfileId?: Maybe<Scalars['UUID']['output']>;
  sentTime: Scalars['Datetime']['output'];
  smtpMessageId?: Maybe<Scalars['String']['output']>;
  subject: Scalars['String']['output'];
  /** Reads a single `Template` that is related to this `EmailMessage`. */
  templateByTemplateId?: Maybe<Template>;
  templateId?: Maybe<Scalars['UUID']['output']>;
  /** Reads and enables pagination through a set of `Trace`. */
  tracesByEmailMessageId: TracesConnection;
  updatedTime: Scalars['Datetime']['output'];
};


export type EmailMessageHedwigMessagePayloadsByEmailMessageIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<HedwigMessagePayloadCondition>;
  filter?: InputMaybe<HedwigMessagePayloadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<HedwigMessagePayloadsOrderBy>>;
  sort?: InputMaybe<Array<HedwigMessagePayloadSort>>;
};


export type EmailMessageTracesByEmailMessageIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TraceCondition>;
  filter?: InputMaybe<TraceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TracesOrderBy>>;
  sort?: InputMaybe<Array<TraceSort>>;
};

export type EmailMessageAggregates = {
  __typename?: 'EmailMessageAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<EmailMessageAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EmailMessageDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<EmailMessageMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<EmailMessageMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<EmailMessageStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<EmailMessageStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<EmailMessageSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<EmailMessageVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<EmailMessageVarianceSampleAggregates>;
};

export type EmailMessageAverageAggregates = {
  __typename?: 'EmailMessageAverageAggregates';
  /** Mean average of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `EmailMessage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EmailMessageCondition = {
  /** Checks for equality with the object’s `autoReplied` field. */
  autoReplied?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `campaignId` field. */
  campaignId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `ccLawyerTime` field. */
  ccLawyerTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `contactId` field. */
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `contentHtml` field. */
  contentHtml?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `emailId` field. */
  emailId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `forwardedInternallyTime` field. */
  forwardedInternallyTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `forwardedToLawyerTime` field. */
  forwardedToLawyerTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `generatedReply` field. */
  generatedReply?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `hedwigMessageId` field. */
  hedwigMessageId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `intent` field. */
  intent?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isLawyer` field. */
  isLawyer?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isReviewed` field. */
  isReviewed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerViewedTime` field. */
  lawyerViewedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `nylasThreadId` field. */
  nylasThreadId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `orderIndex` field. */
  orderIndex?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `previousReplyAttemptTime` field. */
  previousReplyAttemptTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `repliedIntent` field. */
  repliedIntent?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `repliedTime` field. */
  repliedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `requestedCcLawyerTime` field. */
  requestedCcLawyerTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `reviewedTime` field. */
  reviewedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `senderProfileId` field. */
  senderProfileId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `sentTime` field. */
  sentTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `smtpMessageId` field. */
  smtpMessageId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `subject` field. */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `templateId` field. */
  templateId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type EmailMessageDistinctCountAggregates = {
  __typename?: 'EmailMessageDistinctCountAggregates';
  /** Distinct count of autoReplied across the matching connection */
  autoReplied?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of campaignId across the matching connection */
  campaignId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of campaignMemberId across the matching connection */
  campaignMemberId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of ccLawyerTime across the matching connection */
  ccLawyerTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactId across the matching connection */
  contactId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of content across the matching connection */
  content?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contentHtml across the matching connection */
  contentHtml?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emailId across the matching connection */
  emailId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of forwardedInternallyTime across the matching connection */
  forwardedInternallyTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of forwardedToLawyerTime across the matching connection */
  forwardedToLawyerTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of generatedReply across the matching connection */
  generatedReply?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of hedwigMessageId across the matching connection */
  hedwigMessageId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of intent across the matching connection */
  intent?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isLawyer across the matching connection */
  isLawyer?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isReviewed across the matching connection */
  isReviewed?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerViewedTime across the matching connection */
  lawyerViewedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of nylasThreadId across the matching connection */
  nylasThreadId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of previousReplyAttemptTime across the matching connection */
  previousReplyAttemptTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of repliedIntent across the matching connection */
  repliedIntent?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of repliedTime across the matching connection */
  repliedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of requestedCcLawyerTime across the matching connection */
  requestedCcLawyerTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of reviewedTime across the matching connection */
  reviewedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of senderProfileId across the matching connection */
  senderProfileId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of sentTime across the matching connection */
  sentTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of smtpMessageId across the matching connection */
  smtpMessageId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of subject across the matching connection */
  subject?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templateId across the matching connection */
  templateId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `EmailMessage` object types. All fields are combined with a logical ‘and.’ */
export type EmailMessageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EmailMessageFilter>>;
  /** Filter by the object’s `autoReplied` field. */
  autoReplied?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `campaignId` field. */
  campaignId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `ccLawyerTime` field. */
  ccLawyerTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `contactId` field. */
  contactId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<StringFilter>;
  /** Filter by the object’s `contentHtml` field. */
  contentHtml?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `emailId` field. */
  emailId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `forwardedInternallyTime` field. */
  forwardedInternallyTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `forwardedToLawyerTime` field. */
  forwardedToLawyerTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `generatedReply` field. */
  generatedReply?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hedwigMessageId` field. */
  hedwigMessageId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `intent` field. */
  intent?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isLawyer` field. */
  isLawyer?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isReviewed` field. */
  isReviewed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerViewedTime` field. */
  lawyerViewedTime?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EmailMessageFilter>;
  /** Filter by the object’s `nylasThreadId` field. */
  nylasThreadId?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EmailMessageFilter>>;
  /** Filter by the object’s `orderIndex` field. */
  orderIndex?: InputMaybe<IntFilter>;
  /** Filter by the object’s `previousReplyAttemptTime` field. */
  previousReplyAttemptTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `repliedIntent` field. */
  repliedIntent?: InputMaybe<StringFilter>;
  /** Filter by the object’s `repliedTime` field. */
  repliedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `requestedCcLawyerTime` field. */
  requestedCcLawyerTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `reviewedTime` field. */
  reviewedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `senderProfileId` field. */
  senderProfileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `sentTime` field. */
  sentTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `smtpMessageId` field. */
  smtpMessageId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subject` field. */
  subject?: InputMaybe<StringFilter>;
  /** Filter by the object’s `templateId` field. */
  templateId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `EmailMessage` for usage during aggregation. */
export enum EmailMessageGroupBy {
  AutoReplied = 'AUTO_REPLIED',
  CampaignId = 'CAMPAIGN_ID',
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CcLawyerTime = 'CC_LAWYER_TIME',
  CcLawyerTimeTruncatedToDay = 'CC_LAWYER_TIME_TRUNCATED_TO_DAY',
  CcLawyerTimeTruncatedToHour = 'CC_LAWYER_TIME_TRUNCATED_TO_HOUR',
  CompanyId = 'COMPANY_ID',
  ContactId = 'CONTACT_ID',
  Content = 'CONTENT',
  ContentHtml = 'CONTENT_HTML',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  EmailId = 'EMAIL_ID',
  ForwardedInternallyTime = 'FORWARDED_INTERNALLY_TIME',
  ForwardedInternallyTimeTruncatedToDay = 'FORWARDED_INTERNALLY_TIME_TRUNCATED_TO_DAY',
  ForwardedInternallyTimeTruncatedToHour = 'FORWARDED_INTERNALLY_TIME_TRUNCATED_TO_HOUR',
  ForwardedToLawyerTime = 'FORWARDED_TO_LAWYER_TIME',
  ForwardedToLawyerTimeTruncatedToDay = 'FORWARDED_TO_LAWYER_TIME_TRUNCATED_TO_DAY',
  ForwardedToLawyerTimeTruncatedToHour = 'FORWARDED_TO_LAWYER_TIME_TRUNCATED_TO_HOUR',
  GeneratedReply = 'GENERATED_REPLY',
  Intent = 'INTENT',
  IsLawyer = 'IS_LAWYER',
  IsReviewed = 'IS_REVIEWED',
  LawyerId = 'LAWYER_ID',
  LawyerViewedTime = 'LAWYER_VIEWED_TIME',
  LawyerViewedTimeTruncatedToDay = 'LAWYER_VIEWED_TIME_TRUNCATED_TO_DAY',
  LawyerViewedTimeTruncatedToHour = 'LAWYER_VIEWED_TIME_TRUNCATED_TO_HOUR',
  NylasThreadId = 'NYLAS_THREAD_ID',
  OrderIndex = 'ORDER_INDEX',
  PreviousReplyAttemptTime = 'PREVIOUS_REPLY_ATTEMPT_TIME',
  PreviousReplyAttemptTimeTruncatedToDay = 'PREVIOUS_REPLY_ATTEMPT_TIME_TRUNCATED_TO_DAY',
  PreviousReplyAttemptTimeTruncatedToHour = 'PREVIOUS_REPLY_ATTEMPT_TIME_TRUNCATED_TO_HOUR',
  RepliedIntent = 'REPLIED_INTENT',
  RepliedTime = 'REPLIED_TIME',
  RepliedTimeTruncatedToDay = 'REPLIED_TIME_TRUNCATED_TO_DAY',
  RepliedTimeTruncatedToHour = 'REPLIED_TIME_TRUNCATED_TO_HOUR',
  RequestedCcLawyerTime = 'REQUESTED_CC_LAWYER_TIME',
  RequestedCcLawyerTimeTruncatedToDay = 'REQUESTED_CC_LAWYER_TIME_TRUNCATED_TO_DAY',
  RequestedCcLawyerTimeTruncatedToHour = 'REQUESTED_CC_LAWYER_TIME_TRUNCATED_TO_HOUR',
  ReviewedTime = 'REVIEWED_TIME',
  ReviewedTimeTruncatedToDay = 'REVIEWED_TIME_TRUNCATED_TO_DAY',
  ReviewedTimeTruncatedToHour = 'REVIEWED_TIME_TRUNCATED_TO_HOUR',
  SenderProfileId = 'SENDER_PROFILE_ID',
  SentTime = 'SENT_TIME',
  SentTimeTruncatedToDay = 'SENT_TIME_TRUNCATED_TO_DAY',
  SentTimeTruncatedToHour = 'SENT_TIME_TRUNCATED_TO_HOUR',
  Subject = 'SUBJECT',
  TemplateId = 'TEMPLATE_ID',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type EmailMessageHavingAverageInput = {
  ccLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedInternallyTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  previousReplyAttemptTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  requestedCcLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sentTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailMessageHavingDistinctCountInput = {
  ccLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedInternallyTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  previousReplyAttemptTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  requestedCcLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sentTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `EmailMessage` aggregates. */
export type EmailMessageHavingInput = {
  AND?: InputMaybe<Array<EmailMessageHavingInput>>;
  OR?: InputMaybe<Array<EmailMessageHavingInput>>;
  average?: InputMaybe<EmailMessageHavingAverageInput>;
  distinctCount?: InputMaybe<EmailMessageHavingDistinctCountInput>;
  max?: InputMaybe<EmailMessageHavingMaxInput>;
  min?: InputMaybe<EmailMessageHavingMinInput>;
  stddevPopulation?: InputMaybe<EmailMessageHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<EmailMessageHavingStddevSampleInput>;
  sum?: InputMaybe<EmailMessageHavingSumInput>;
  variancePopulation?: InputMaybe<EmailMessageHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<EmailMessageHavingVarianceSampleInput>;
};

export type EmailMessageHavingMaxInput = {
  ccLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedInternallyTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  previousReplyAttemptTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  requestedCcLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sentTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailMessageHavingMinInput = {
  ccLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedInternallyTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  previousReplyAttemptTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  requestedCcLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sentTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailMessageHavingStddevPopulationInput = {
  ccLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedInternallyTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  previousReplyAttemptTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  requestedCcLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sentTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailMessageHavingStddevSampleInput = {
  ccLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedInternallyTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  previousReplyAttemptTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  requestedCcLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sentTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailMessageHavingSumInput = {
  ccLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedInternallyTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  previousReplyAttemptTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  requestedCcLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sentTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailMessageHavingVariancePopulationInput = {
  ccLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedInternallyTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  previousReplyAttemptTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  requestedCcLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sentTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailMessageHavingVarianceSampleInput = {
  ccLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedInternallyTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  previousReplyAttemptTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  requestedCcLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sentTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type EmailMessageMaxAggregates = {
  __typename?: 'EmailMessageMaxAggregates';
  /** Maximum of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['Int']['output']>;
};

export type EmailMessageMinAggregates = {
  __typename?: 'EmailMessageMinAggregates';
  /** Minimum of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['Int']['output']>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type EmailMessageSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: EmailMessageSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type EmailMessageSortBy = {
  field?: InputMaybe<EmailMessageSortableField>;
};

/** Sortable concrete fields for the `EmailMessage` type. */
export enum EmailMessageSortableField {
  AutoReplied = 'AUTO_REPLIED',
  CampaignId = 'CAMPAIGN_ID',
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CcLawyerTime = 'CC_LAWYER_TIME',
  CompanyId = 'COMPANY_ID',
  ContactId = 'CONTACT_ID',
  Content = 'CONTENT',
  ContentHtml = 'CONTENT_HTML',
  CreatedTime = 'CREATED_TIME',
  EmailId = 'EMAIL_ID',
  ForwardedInternallyTime = 'FORWARDED_INTERNALLY_TIME',
  ForwardedToLawyerTime = 'FORWARDED_TO_LAWYER_TIME',
  GeneratedReply = 'GENERATED_REPLY',
  HedwigMessageId = 'HEDWIG_MESSAGE_ID',
  Id = 'ID',
  Intent = 'INTENT',
  IsLawyer = 'IS_LAWYER',
  IsReviewed = 'IS_REVIEWED',
  LawyerId = 'LAWYER_ID',
  LawyerViewedTime = 'LAWYER_VIEWED_TIME',
  NylasThreadId = 'NYLAS_THREAD_ID',
  OrderIndex = 'ORDER_INDEX',
  PreviousReplyAttemptTime = 'PREVIOUS_REPLY_ATTEMPT_TIME',
  RepliedIntent = 'REPLIED_INTENT',
  RepliedTime = 'REPLIED_TIME',
  RequestedCcLawyerTime = 'REQUESTED_CC_LAWYER_TIME',
  ReviewedTime = 'REVIEWED_TIME',
  SenderProfileId = 'SENDER_PROFILE_ID',
  SentTime = 'SENT_TIME',
  SmtpMessageId = 'SMTP_MESSAGE_ID',
  Subject = 'SUBJECT',
  TemplateId = 'TEMPLATE_ID',
  UpdatedTime = 'UPDATED_TIME'
}

export type EmailMessageStddevPopulationAggregates = {
  __typename?: 'EmailMessageStddevPopulationAggregates';
  /** Population standard deviation of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

export type EmailMessageStddevSampleAggregates = {
  __typename?: 'EmailMessageStddevSampleAggregates';
  /** Sample standard deviation of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

export type EmailMessageSumAggregates = {
  __typename?: 'EmailMessageSumAggregates';
  /** Sum of orderIndex across the matching connection */
  orderIndex: Scalars['BigInt']['output'];
};

export type EmailMessageVariancePopulationAggregates = {
  __typename?: 'EmailMessageVariancePopulationAggregates';
  /** Population variance of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

export type EmailMessageVarianceSampleAggregates = {
  __typename?: 'EmailMessageVarianceSampleAggregates';
  /** Sample variance of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `EmailMessage` values. */
export type EmailMessagesConnection = {
  __typename?: 'EmailMessagesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EmailMessageAggregates>;
  /** A list of edges which contains the `EmailMessage` and cursor to aid in pagination. */
  edges: Array<EmailMessagesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EmailMessageAggregates>>;
  /** A list of `EmailMessage` objects. */
  nodes: Array<Maybe<EmailMessage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EmailMessage` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `EmailMessage` values. */
export type EmailMessagesConnectionGroupedAggregatesArgs = {
  groupBy: Array<EmailMessageGroupBy>;
  having?: InputMaybe<EmailMessageHavingInput>;
};

/** A `EmailMessage` edge in the connection. */
export type EmailMessagesEdge = {
  __typename?: 'EmailMessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `EmailMessage` at the end of the edge. */
  node?: Maybe<EmailMessage>;
};

/** Methods to use when ordering `EmailMessage`. */
export enum EmailMessagesOrderBy {
  AutoRepliedAsc = 'AUTO_REPLIED_ASC',
  AutoRepliedDesc = 'AUTO_REPLIED_DESC',
  CampaignIdAsc = 'CAMPAIGN_ID_ASC',
  CampaignIdDesc = 'CAMPAIGN_ID_DESC',
  CampaignMemberIdAsc = 'CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberIdDesc = 'CAMPAIGN_MEMBER_ID_DESC',
  CcLawyerTimeAsc = 'CC_LAWYER_TIME_ASC',
  CcLawyerTimeDesc = 'CC_LAWYER_TIME_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  ContentHtmlAsc = 'CONTENT_HTML_ASC',
  ContentHtmlDesc = 'CONTENT_HTML_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  EmailIdAsc = 'EMAIL_ID_ASC',
  EmailIdDesc = 'EMAIL_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdAverageCreatedTimeAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_AVERAGE_CREATED_TIME_ASC',
  EmailMessageAttachmentsByEmailMessageIdAverageCreatedTimeDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_AVERAGE_CREATED_TIME_DESC',
  EmailMessageAttachmentsByEmailMessageIdAverageEmailMessageIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdAverageEmailMessageIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdAverageIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_AVERAGE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdAverageIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_AVERAGE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdAverageS3FileIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_AVERAGE_S3_FILE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdAverageS3FileIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_AVERAGE_S3_FILE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdCountAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_COUNT_ASC',
  EmailMessageAttachmentsByEmailMessageIdCountDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_COUNT_DESC',
  EmailMessageAttachmentsByEmailMessageIdDistinctCountCreatedTimeAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailMessageAttachmentsByEmailMessageIdDistinctCountCreatedTimeDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailMessageAttachmentsByEmailMessageIdDistinctCountEmailMessageIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdDistinctCountEmailMessageIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdDistinctCountIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdDistinctCountIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdDistinctCountS3FileIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_S3_FILE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdDistinctCountS3FileIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_S3_FILE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdMaxCreatedTimeAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MAX_CREATED_TIME_ASC',
  EmailMessageAttachmentsByEmailMessageIdMaxCreatedTimeDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MAX_CREATED_TIME_DESC',
  EmailMessageAttachmentsByEmailMessageIdMaxEmailMessageIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MAX_EMAIL_MESSAGE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdMaxEmailMessageIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MAX_EMAIL_MESSAGE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdMaxIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MAX_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdMaxIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MAX_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdMaxS3FileIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MAX_S3_FILE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdMaxS3FileIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MAX_S3_FILE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdMinCreatedTimeAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MIN_CREATED_TIME_ASC',
  EmailMessageAttachmentsByEmailMessageIdMinCreatedTimeDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MIN_CREATED_TIME_DESC',
  EmailMessageAttachmentsByEmailMessageIdMinEmailMessageIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MIN_EMAIL_MESSAGE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdMinEmailMessageIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MIN_EMAIL_MESSAGE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdMinIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MIN_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdMinIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MIN_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdMinS3FileIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MIN_S3_FILE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdMinS3FileIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_MIN_S3_FILE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdStddevPopulationCreatedTimeAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailMessageAttachmentsByEmailMessageIdStddevPopulationCreatedTimeDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailMessageAttachmentsByEmailMessageIdStddevPopulationEmailMessageIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdStddevPopulationEmailMessageIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdStddevPopulationIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdStddevPopulationIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdStddevPopulationS3FileIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_S3_FILE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdStddevPopulationS3FileIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_S3_FILE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdStddevSampleCreatedTimeAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailMessageAttachmentsByEmailMessageIdStddevSampleCreatedTimeDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailMessageAttachmentsByEmailMessageIdStddevSampleEmailMessageIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdStddevSampleEmailMessageIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdStddevSampleIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdStddevSampleIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdStddevSampleS3FileIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_S3_FILE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdStddevSampleS3FileIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_S3_FILE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdSumCreatedTimeAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_SUM_CREATED_TIME_ASC',
  EmailMessageAttachmentsByEmailMessageIdSumCreatedTimeDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_SUM_CREATED_TIME_DESC',
  EmailMessageAttachmentsByEmailMessageIdSumEmailMessageIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_SUM_EMAIL_MESSAGE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdSumEmailMessageIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_SUM_EMAIL_MESSAGE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdSumIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_SUM_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdSumIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_SUM_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdSumS3FileIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_SUM_S3_FILE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdSumS3FileIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_SUM_S3_FILE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdVariancePopulationCreatedTimeAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailMessageAttachmentsByEmailMessageIdVariancePopulationCreatedTimeDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailMessageAttachmentsByEmailMessageIdVariancePopulationEmailMessageIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdVariancePopulationEmailMessageIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdVariancePopulationIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdVariancePopulationIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdVariancePopulationS3FileIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_S3_FILE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdVariancePopulationS3FileIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_S3_FILE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdVarianceSampleCreatedTimeAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailMessageAttachmentsByEmailMessageIdVarianceSampleCreatedTimeDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailMessageAttachmentsByEmailMessageIdVarianceSampleEmailMessageIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdVarianceSampleEmailMessageIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdVarianceSampleIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdVarianceSampleIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailMessageAttachmentsByEmailMessageIdVarianceSampleS3FileIdAsc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_S3_FILE_ID_ASC',
  EmailMessageAttachmentsByEmailMessageIdVarianceSampleS3FileIdDesc = 'EMAIL_MESSAGE_ATTACHMENTS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_S3_FILE_ID_DESC',
  ForwardedInternallyTimeAsc = 'FORWARDED_INTERNALLY_TIME_ASC',
  ForwardedInternallyTimeDesc = 'FORWARDED_INTERNALLY_TIME_DESC',
  ForwardedToLawyerTimeAsc = 'FORWARDED_TO_LAWYER_TIME_ASC',
  ForwardedToLawyerTimeDesc = 'FORWARDED_TO_LAWYER_TIME_DESC',
  GeneratedReplyAsc = 'GENERATED_REPLY_ASC',
  GeneratedReplyDesc = 'GENERATED_REPLY_DESC',
  HedwigMessageIdAsc = 'HEDWIG_MESSAGE_ID_ASC',
  HedwigMessageIdDesc = 'HEDWIG_MESSAGE_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageBodyContentAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_BODY_CONTENT_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageBodyContentDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_BODY_CONTENT_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageBodyContentTypeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_BODY_CONTENT_TYPE_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageBodyContentTypeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_BODY_CONTENT_TYPE_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageConversationIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_CONVERSATION_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageConversationIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_CONVERSATION_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageCreatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_CREATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageCreatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_CREATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageEmailMessageIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageEmailMessageIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageHasAttachmentsAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_HAS_ATTACHMENTS_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageHasAttachmentsDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_HAS_ATTACHMENTS_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageHedwigMessageCategoriesAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_HEDWIG_MESSAGE_CATEGORIES_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageHedwigMessageCategoriesDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_HEDWIG_MESSAGE_CATEGORIES_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageImportanceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_IMPORTANCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageImportanceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_IMPORTANCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageIsDeliveryReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_IS_DELIVERY_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageIsDeliveryReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_IS_DELIVERY_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageIsDraftAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_IS_DRAFT_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageIsDraftDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_IS_DRAFT_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageIsReadAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_IS_READ_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageIsReadDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_IS_READ_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageIsReadReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_IS_READ_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageIsReadReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_IS_READ_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageParentFolderIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_PARENT_FOLDER_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageParentFolderIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_PARENT_FOLDER_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageReceivedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_RECEIVED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageReceivedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_RECEIVED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageSentTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_SENT_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageSentTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_SENT_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageSourceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_SOURCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageSourceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_SOURCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageSubjectAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_SUBJECT_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageSubjectDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_SUBJECT_DESC',
  HedwigMessagePayloadsByEmailMessageIdAverageUpdatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_UPDATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdAverageUpdatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_AVERAGE_UPDATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdCountAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_COUNT_ASC',
  HedwigMessagePayloadsByEmailMessageIdCountDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_COUNT_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountBodyContentAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_BODY_CONTENT_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountBodyContentDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_BODY_CONTENT_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountBodyContentTypeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_BODY_CONTENT_TYPE_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountBodyContentTypeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_BODY_CONTENT_TYPE_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountConversationIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CONVERSATION_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountConversationIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CONVERSATION_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountCreatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountCreatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountEmailMessageIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountEmailMessageIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountHasAttachmentsAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_HAS_ATTACHMENTS_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountHasAttachmentsDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_HAS_ATTACHMENTS_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountHedwigMessageCategoriesAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_CATEGORIES_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountHedwigMessageCategoriesDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_CATEGORIES_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountImportanceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_IMPORTANCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountImportanceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_IMPORTANCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountIsDeliveryReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_IS_DELIVERY_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountIsDeliveryReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_IS_DELIVERY_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountIsDraftAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_IS_DRAFT_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountIsDraftDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_IS_DRAFT_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountIsReadAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_IS_READ_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountIsReadDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_IS_READ_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountIsReadReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_IS_READ_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountIsReadReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_IS_READ_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountParentFolderIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_PARENT_FOLDER_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountParentFolderIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_PARENT_FOLDER_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountReceivedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_RECEIVED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountReceivedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_RECEIVED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountSentTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_SENT_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountSentTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_SENT_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountSourceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_SOURCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountSourceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_SOURCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountSubjectAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_SUBJECT_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountSubjectDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_SUBJECT_DESC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountUpdatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdDistinctCountUpdatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxBodyContentAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_BODY_CONTENT_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxBodyContentDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_BODY_CONTENT_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxBodyContentTypeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_BODY_CONTENT_TYPE_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxBodyContentTypeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_BODY_CONTENT_TYPE_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxConversationIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_CONVERSATION_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxConversationIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_CONVERSATION_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxCreatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_CREATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxCreatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_CREATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxEmailMessageIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_EMAIL_MESSAGE_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxEmailMessageIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_EMAIL_MESSAGE_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxHasAttachmentsAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_HAS_ATTACHMENTS_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxHasAttachmentsDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_HAS_ATTACHMENTS_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxHedwigMessageCategoriesAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_HEDWIG_MESSAGE_CATEGORIES_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxHedwigMessageCategoriesDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_HEDWIG_MESSAGE_CATEGORIES_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxImportanceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_IMPORTANCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxImportanceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_IMPORTANCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxIsDeliveryReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_IS_DELIVERY_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxIsDeliveryReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_IS_DELIVERY_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxIsDraftAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_IS_DRAFT_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxIsDraftDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_IS_DRAFT_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxIsReadAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_IS_READ_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxIsReadDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_IS_READ_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxIsReadReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_IS_READ_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxIsReadReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_IS_READ_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxParentFolderIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_PARENT_FOLDER_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxParentFolderIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_PARENT_FOLDER_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxReceivedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_RECEIVED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxReceivedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_RECEIVED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxSentTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_SENT_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxSentTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_SENT_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxSourceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_SOURCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxSourceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_SOURCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxSubjectAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_SUBJECT_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxSubjectDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_SUBJECT_DESC',
  HedwigMessagePayloadsByEmailMessageIdMaxUpdatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_UPDATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdMaxUpdatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MAX_UPDATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinBodyContentAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_BODY_CONTENT_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinBodyContentDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_BODY_CONTENT_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinBodyContentTypeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_BODY_CONTENT_TYPE_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinBodyContentTypeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_BODY_CONTENT_TYPE_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinConversationIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_CONVERSATION_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinConversationIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_CONVERSATION_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinCreatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_CREATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinCreatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_CREATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinEmailMessageIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_EMAIL_MESSAGE_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinEmailMessageIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_EMAIL_MESSAGE_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinHasAttachmentsAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_HAS_ATTACHMENTS_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinHasAttachmentsDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_HAS_ATTACHMENTS_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinHedwigMessageCategoriesAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_HEDWIG_MESSAGE_CATEGORIES_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinHedwigMessageCategoriesDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_HEDWIG_MESSAGE_CATEGORIES_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinImportanceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_IMPORTANCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinImportanceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_IMPORTANCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinIsDeliveryReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_IS_DELIVERY_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinIsDeliveryReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_IS_DELIVERY_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinIsDraftAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_IS_DRAFT_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinIsDraftDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_IS_DRAFT_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinIsReadAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_IS_READ_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinIsReadDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_IS_READ_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinIsReadReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_IS_READ_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinIsReadReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_IS_READ_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinParentFolderIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_PARENT_FOLDER_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinParentFolderIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_PARENT_FOLDER_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinReceivedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_RECEIVED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinReceivedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_RECEIVED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinSentTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_SENT_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinSentTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_SENT_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinSourceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_SOURCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinSourceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_SOURCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinSubjectAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_SUBJECT_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinSubjectDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_SUBJECT_DESC',
  HedwigMessagePayloadsByEmailMessageIdMinUpdatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_UPDATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdMinUpdatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_MIN_UPDATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationBodyContentAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_BODY_CONTENT_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationBodyContentDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_BODY_CONTENT_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationBodyContentTypeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_BODY_CONTENT_TYPE_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationBodyContentTypeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_BODY_CONTENT_TYPE_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationConversationIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CONVERSATION_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationConversationIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CONVERSATION_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationCreatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationCreatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationEmailMessageIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationEmailMessageIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationHasAttachmentsAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_HAS_ATTACHMENTS_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationHasAttachmentsDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_HAS_ATTACHMENTS_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationHedwigMessageCategoriesAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_CATEGORIES_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationHedwigMessageCategoriesDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_CATEGORIES_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationImportanceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_IMPORTANCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationImportanceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_IMPORTANCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationIsDeliveryReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_IS_DELIVERY_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationIsDeliveryReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_IS_DELIVERY_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationIsDraftAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_IS_DRAFT_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationIsDraftDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_IS_DRAFT_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationIsReadAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_IS_READ_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationIsReadDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_IS_READ_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationIsReadReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_IS_READ_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationIsReadReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_IS_READ_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationParentFolderIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_PARENT_FOLDER_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationParentFolderIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_PARENT_FOLDER_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationReceivedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_RECEIVED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationReceivedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_RECEIVED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationSentTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_SENT_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationSentTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_SENT_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationSourceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_SOURCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationSourceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_SOURCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationSubjectAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_SUBJECT_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationSubjectDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_SUBJECT_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationUpdatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevPopulationUpdatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleBodyContentAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_BODY_CONTENT_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleBodyContentDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_BODY_CONTENT_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleBodyContentTypeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_BODY_CONTENT_TYPE_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleBodyContentTypeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_BODY_CONTENT_TYPE_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleConversationIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CONVERSATION_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleConversationIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CONVERSATION_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleCreatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleCreatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleEmailMessageIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleEmailMessageIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleHasAttachmentsAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_HAS_ATTACHMENTS_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleHasAttachmentsDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_HAS_ATTACHMENTS_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleHedwigMessageCategoriesAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_CATEGORIES_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleHedwigMessageCategoriesDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_CATEGORIES_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleImportanceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_IMPORTANCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleImportanceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_IMPORTANCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleIsDeliveryReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_IS_DELIVERY_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleIsDeliveryReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_IS_DELIVERY_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleIsDraftAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_IS_DRAFT_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleIsDraftDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_IS_DRAFT_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleIsReadAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_IS_READ_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleIsReadDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_IS_READ_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleIsReadReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_IS_READ_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleIsReadReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_IS_READ_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleParentFolderIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_PARENT_FOLDER_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleParentFolderIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_PARENT_FOLDER_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleReceivedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_RECEIVED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleReceivedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_RECEIVED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleSentTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_SENT_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleSentTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_SENT_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleSourceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_SOURCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleSourceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_SOURCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleSubjectAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_SUBJECT_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleSubjectDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_SUBJECT_DESC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleUpdatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdStddevSampleUpdatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumBodyContentAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_BODY_CONTENT_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumBodyContentDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_BODY_CONTENT_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumBodyContentTypeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_BODY_CONTENT_TYPE_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumBodyContentTypeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_BODY_CONTENT_TYPE_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumConversationIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_CONVERSATION_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumConversationIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_CONVERSATION_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumCreatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_CREATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumCreatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_CREATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumEmailMessageIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_EMAIL_MESSAGE_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumEmailMessageIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_EMAIL_MESSAGE_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumHasAttachmentsAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_HAS_ATTACHMENTS_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumHasAttachmentsDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_HAS_ATTACHMENTS_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumHedwigMessageCategoriesAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_HEDWIG_MESSAGE_CATEGORIES_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumHedwigMessageCategoriesDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_HEDWIG_MESSAGE_CATEGORIES_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumImportanceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_IMPORTANCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumImportanceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_IMPORTANCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumIsDeliveryReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_IS_DELIVERY_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumIsDeliveryReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_IS_DELIVERY_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumIsDraftAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_IS_DRAFT_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumIsDraftDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_IS_DRAFT_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumIsReadAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_IS_READ_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumIsReadDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_IS_READ_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumIsReadReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_IS_READ_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumIsReadReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_IS_READ_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumParentFolderIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_PARENT_FOLDER_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumParentFolderIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_PARENT_FOLDER_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumReceivedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_RECEIVED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumReceivedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_RECEIVED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumSentTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_SENT_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumSentTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_SENT_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumSourceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_SOURCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumSourceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_SOURCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumSubjectAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_SUBJECT_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumSubjectDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_SUBJECT_DESC',
  HedwigMessagePayloadsByEmailMessageIdSumUpdatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_UPDATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdSumUpdatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_SUM_UPDATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationBodyContentAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_BODY_CONTENT_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationBodyContentDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_BODY_CONTENT_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationBodyContentTypeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_BODY_CONTENT_TYPE_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationBodyContentTypeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_BODY_CONTENT_TYPE_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationConversationIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CONVERSATION_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationConversationIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CONVERSATION_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationCreatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationCreatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationEmailMessageIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationEmailMessageIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationHasAttachmentsAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_HAS_ATTACHMENTS_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationHasAttachmentsDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_HAS_ATTACHMENTS_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationHedwigMessageCategoriesAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_CATEGORIES_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationHedwigMessageCategoriesDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_CATEGORIES_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationImportanceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_IMPORTANCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationImportanceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_IMPORTANCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationIsDeliveryReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_IS_DELIVERY_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationIsDeliveryReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_IS_DELIVERY_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationIsDraftAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_IS_DRAFT_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationIsDraftDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_IS_DRAFT_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationIsReadAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_IS_READ_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationIsReadDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_IS_READ_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationIsReadReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_IS_READ_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationIsReadReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_IS_READ_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationParentFolderIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_PARENT_FOLDER_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationParentFolderIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_PARENT_FOLDER_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationReceivedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_RECEIVED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationReceivedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_RECEIVED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationSentTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_SENT_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationSentTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_SENT_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationSourceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_SOURCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationSourceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_SOURCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationSubjectAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_SUBJECT_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationSubjectDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_SUBJECT_DESC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationUpdatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdVariancePopulationUpdatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleBodyContentAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_BODY_CONTENT_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleBodyContentDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_BODY_CONTENT_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleBodyContentTypeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_BODY_CONTENT_TYPE_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleBodyContentTypeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_BODY_CONTENT_TYPE_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleConversationIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CONVERSATION_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleConversationIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CONVERSATION_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleCreatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleCreatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleEmailMessageIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleEmailMessageIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleHasAttachmentsAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_HAS_ATTACHMENTS_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleHasAttachmentsDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_HAS_ATTACHMENTS_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleHedwigMessageCategoriesAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_CATEGORIES_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleHedwigMessageCategoriesDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_CATEGORIES_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleImportanceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_IMPORTANCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleImportanceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_IMPORTANCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleIsDeliveryReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_IS_DELIVERY_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleIsDeliveryReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_IS_DELIVERY_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleIsDraftAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_IS_DRAFT_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleIsDraftDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_IS_DRAFT_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleIsReadAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_IS_READ_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleIsReadDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_IS_READ_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleIsReadReceiptRequestedAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_IS_READ_RECEIPT_REQUESTED_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleIsReadReceiptRequestedDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_IS_READ_RECEIPT_REQUESTED_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleParentFolderIdAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_PARENT_FOLDER_ID_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleParentFolderIdDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_PARENT_FOLDER_ID_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleReceivedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_RECEIVED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleReceivedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_RECEIVED_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleSentTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_SENT_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleSentTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_SENT_TIME_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleSourceAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_SOURCE_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleSourceDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_SOURCE_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleSubjectAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_SUBJECT_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleSubjectDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_SUBJECT_DESC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleUpdatedTimeAsc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  HedwigMessagePayloadsByEmailMessageIdVarianceSampleUpdatedTimeDesc = 'HEDWIG_MESSAGE_PAYLOADS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntentAsc = 'INTENT_ASC',
  IntentDesc = 'INTENT_DESC',
  IsLawyerAsc = 'IS_LAWYER_ASC',
  IsLawyerDesc = 'IS_LAWYER_DESC',
  IsReviewedAsc = 'IS_REVIEWED_ASC',
  IsReviewedDesc = 'IS_REVIEWED_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  LawyerViewedTimeAsc = 'LAWYER_VIEWED_TIME_ASC',
  LawyerViewedTimeDesc = 'LAWYER_VIEWED_TIME_DESC',
  Natural = 'NATURAL',
  NylasThreadIdAsc = 'NYLAS_THREAD_ID_ASC',
  NylasThreadIdDesc = 'NYLAS_THREAD_ID_DESC',
  OrderIndexAsc = 'ORDER_INDEX_ASC',
  OrderIndexDesc = 'ORDER_INDEX_DESC',
  PreviousReplyAttemptTimeAsc = 'PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  PreviousReplyAttemptTimeDesc = 'PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RepliedIntentAsc = 'REPLIED_INTENT_ASC',
  RepliedIntentDesc = 'REPLIED_INTENT_DESC',
  RepliedTimeAsc = 'REPLIED_TIME_ASC',
  RepliedTimeDesc = 'REPLIED_TIME_DESC',
  RequestedCcLawyerTimeAsc = 'REQUESTED_CC_LAWYER_TIME_ASC',
  RequestedCcLawyerTimeDesc = 'REQUESTED_CC_LAWYER_TIME_DESC',
  ReviewedTimeAsc = 'REVIEWED_TIME_ASC',
  ReviewedTimeDesc = 'REVIEWED_TIME_DESC',
  SenderProfileIdAsc = 'SENDER_PROFILE_ID_ASC',
  SenderProfileIdDesc = 'SENDER_PROFILE_ID_DESC',
  SentTimeAsc = 'SENT_TIME_ASC',
  SentTimeDesc = 'SENT_TIME_DESC',
  SmtpMessageIdAsc = 'SMTP_MESSAGE_ID_ASC',
  SmtpMessageIdDesc = 'SMTP_MESSAGE_ID_DESC',
  SubjectAsc = 'SUBJECT_ASC',
  SubjectDesc = 'SUBJECT_DESC',
  TemplateIdAsc = 'TEMPLATE_ID_ASC',
  TemplateIdDesc = 'TEMPLATE_ID_DESC',
  TracesByEmailMessageIdAverageActionAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_ACTION_ASC',
  TracesByEmailMessageIdAverageActionDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_ACTION_DESC',
  TracesByEmailMessageIdAverageCampaignMemberIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  TracesByEmailMessageIdAverageCampaignMemberIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  TracesByEmailMessageIdAverageCreatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_CREATED_TIME_ASC',
  TracesByEmailMessageIdAverageCreatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_CREATED_TIME_DESC',
  TracesByEmailMessageIdAverageEmailMessageIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  TracesByEmailMessageIdAverageEmailMessageIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  TracesByEmailMessageIdAverageIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_ID_ASC',
  TracesByEmailMessageIdAverageIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_ID_DESC',
  TracesByEmailMessageIdAverageProfileIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_PROFILE_ID_ASC',
  TracesByEmailMessageIdAverageProfileIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_PROFILE_ID_DESC',
  TracesByEmailMessageIdAverageUpdatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_UPDATED_TIME_ASC',
  TracesByEmailMessageIdAverageUpdatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_AVERAGE_UPDATED_TIME_DESC',
  TracesByEmailMessageIdCountAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_COUNT_ASC',
  TracesByEmailMessageIdCountDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_COUNT_DESC',
  TracesByEmailMessageIdDistinctCountActionAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_ACTION_ASC',
  TracesByEmailMessageIdDistinctCountActionDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_ACTION_DESC',
  TracesByEmailMessageIdDistinctCountCampaignMemberIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  TracesByEmailMessageIdDistinctCountCampaignMemberIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  TracesByEmailMessageIdDistinctCountCreatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  TracesByEmailMessageIdDistinctCountCreatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  TracesByEmailMessageIdDistinctCountEmailMessageIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  TracesByEmailMessageIdDistinctCountEmailMessageIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  TracesByEmailMessageIdDistinctCountIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_ID_ASC',
  TracesByEmailMessageIdDistinctCountIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_ID_DESC',
  TracesByEmailMessageIdDistinctCountProfileIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_PROFILE_ID_ASC',
  TracesByEmailMessageIdDistinctCountProfileIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_PROFILE_ID_DESC',
  TracesByEmailMessageIdDistinctCountUpdatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  TracesByEmailMessageIdDistinctCountUpdatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  TracesByEmailMessageIdMaxActionAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_ACTION_ASC',
  TracesByEmailMessageIdMaxActionDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_ACTION_DESC',
  TracesByEmailMessageIdMaxCampaignMemberIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  TracesByEmailMessageIdMaxCampaignMemberIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  TracesByEmailMessageIdMaxCreatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_CREATED_TIME_ASC',
  TracesByEmailMessageIdMaxCreatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_CREATED_TIME_DESC',
  TracesByEmailMessageIdMaxEmailMessageIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_EMAIL_MESSAGE_ID_ASC',
  TracesByEmailMessageIdMaxEmailMessageIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_EMAIL_MESSAGE_ID_DESC',
  TracesByEmailMessageIdMaxIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_ID_ASC',
  TracesByEmailMessageIdMaxIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_ID_DESC',
  TracesByEmailMessageIdMaxProfileIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_PROFILE_ID_ASC',
  TracesByEmailMessageIdMaxProfileIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_PROFILE_ID_DESC',
  TracesByEmailMessageIdMaxUpdatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_UPDATED_TIME_ASC',
  TracesByEmailMessageIdMaxUpdatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MAX_UPDATED_TIME_DESC',
  TracesByEmailMessageIdMinActionAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_ACTION_ASC',
  TracesByEmailMessageIdMinActionDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_ACTION_DESC',
  TracesByEmailMessageIdMinCampaignMemberIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  TracesByEmailMessageIdMinCampaignMemberIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  TracesByEmailMessageIdMinCreatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_CREATED_TIME_ASC',
  TracesByEmailMessageIdMinCreatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_CREATED_TIME_DESC',
  TracesByEmailMessageIdMinEmailMessageIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_EMAIL_MESSAGE_ID_ASC',
  TracesByEmailMessageIdMinEmailMessageIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_EMAIL_MESSAGE_ID_DESC',
  TracesByEmailMessageIdMinIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_ID_ASC',
  TracesByEmailMessageIdMinIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_ID_DESC',
  TracesByEmailMessageIdMinProfileIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_PROFILE_ID_ASC',
  TracesByEmailMessageIdMinProfileIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_PROFILE_ID_DESC',
  TracesByEmailMessageIdMinUpdatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_UPDATED_TIME_ASC',
  TracesByEmailMessageIdMinUpdatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_MIN_UPDATED_TIME_DESC',
  TracesByEmailMessageIdStddevPopulationActionAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_ACTION_ASC',
  TracesByEmailMessageIdStddevPopulationActionDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_ACTION_DESC',
  TracesByEmailMessageIdStddevPopulationCampaignMemberIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  TracesByEmailMessageIdStddevPopulationCampaignMemberIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  TracesByEmailMessageIdStddevPopulationCreatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  TracesByEmailMessageIdStddevPopulationCreatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  TracesByEmailMessageIdStddevPopulationEmailMessageIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  TracesByEmailMessageIdStddevPopulationEmailMessageIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  TracesByEmailMessageIdStddevPopulationIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_ID_ASC',
  TracesByEmailMessageIdStddevPopulationIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_ID_DESC',
  TracesByEmailMessageIdStddevPopulationProfileIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_PROFILE_ID_ASC',
  TracesByEmailMessageIdStddevPopulationProfileIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_PROFILE_ID_DESC',
  TracesByEmailMessageIdStddevPopulationUpdatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  TracesByEmailMessageIdStddevPopulationUpdatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  TracesByEmailMessageIdStddevSampleActionAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_ACTION_ASC',
  TracesByEmailMessageIdStddevSampleActionDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_ACTION_DESC',
  TracesByEmailMessageIdStddevSampleCampaignMemberIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  TracesByEmailMessageIdStddevSampleCampaignMemberIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  TracesByEmailMessageIdStddevSampleCreatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  TracesByEmailMessageIdStddevSampleCreatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  TracesByEmailMessageIdStddevSampleEmailMessageIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  TracesByEmailMessageIdStddevSampleEmailMessageIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  TracesByEmailMessageIdStddevSampleIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC',
  TracesByEmailMessageIdStddevSampleIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC',
  TracesByEmailMessageIdStddevSampleProfileIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_PROFILE_ID_ASC',
  TracesByEmailMessageIdStddevSampleProfileIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_PROFILE_ID_DESC',
  TracesByEmailMessageIdStddevSampleUpdatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  TracesByEmailMessageIdStddevSampleUpdatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  TracesByEmailMessageIdSumActionAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_ACTION_ASC',
  TracesByEmailMessageIdSumActionDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_ACTION_DESC',
  TracesByEmailMessageIdSumCampaignMemberIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  TracesByEmailMessageIdSumCampaignMemberIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  TracesByEmailMessageIdSumCreatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_CREATED_TIME_ASC',
  TracesByEmailMessageIdSumCreatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_CREATED_TIME_DESC',
  TracesByEmailMessageIdSumEmailMessageIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_EMAIL_MESSAGE_ID_ASC',
  TracesByEmailMessageIdSumEmailMessageIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_EMAIL_MESSAGE_ID_DESC',
  TracesByEmailMessageIdSumIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_ID_ASC',
  TracesByEmailMessageIdSumIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_ID_DESC',
  TracesByEmailMessageIdSumProfileIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_PROFILE_ID_ASC',
  TracesByEmailMessageIdSumProfileIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_PROFILE_ID_DESC',
  TracesByEmailMessageIdSumUpdatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_UPDATED_TIME_ASC',
  TracesByEmailMessageIdSumUpdatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_SUM_UPDATED_TIME_DESC',
  TracesByEmailMessageIdVariancePopulationActionAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_ACTION_ASC',
  TracesByEmailMessageIdVariancePopulationActionDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_ACTION_DESC',
  TracesByEmailMessageIdVariancePopulationCampaignMemberIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  TracesByEmailMessageIdVariancePopulationCampaignMemberIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  TracesByEmailMessageIdVariancePopulationCreatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  TracesByEmailMessageIdVariancePopulationCreatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  TracesByEmailMessageIdVariancePopulationEmailMessageIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  TracesByEmailMessageIdVariancePopulationEmailMessageIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  TracesByEmailMessageIdVariancePopulationIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC',
  TracesByEmailMessageIdVariancePopulationIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC',
  TracesByEmailMessageIdVariancePopulationProfileIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_PROFILE_ID_ASC',
  TracesByEmailMessageIdVariancePopulationProfileIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_PROFILE_ID_DESC',
  TracesByEmailMessageIdVariancePopulationUpdatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  TracesByEmailMessageIdVariancePopulationUpdatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  TracesByEmailMessageIdVarianceSampleActionAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_ACTION_ASC',
  TracesByEmailMessageIdVarianceSampleActionDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_ACTION_DESC',
  TracesByEmailMessageIdVarianceSampleCampaignMemberIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  TracesByEmailMessageIdVarianceSampleCampaignMemberIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  TracesByEmailMessageIdVarianceSampleCreatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  TracesByEmailMessageIdVarianceSampleCreatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  TracesByEmailMessageIdVarianceSampleEmailMessageIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  TracesByEmailMessageIdVarianceSampleEmailMessageIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  TracesByEmailMessageIdVarianceSampleIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC',
  TracesByEmailMessageIdVarianceSampleIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC',
  TracesByEmailMessageIdVarianceSampleProfileIdAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  TracesByEmailMessageIdVarianceSampleProfileIdDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_PROFILE_ID_DESC',
  TracesByEmailMessageIdVarianceSampleUpdatedTimeAsc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  TracesByEmailMessageIdVarianceSampleUpdatedTimeDesc = 'TRACES_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdAverageContentAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_CONTENT_ASC',
  WarmupEmailsByEmailMessageIdAverageContentDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_CONTENT_DESC',
  WarmupEmailsByEmailMessageIdAverageCreatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_CREATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdAverageCreatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_CREATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdAverageEmailMailboxIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_EMAIL_MAILBOX_ID_ASC',
  WarmupEmailsByEmailMessageIdAverageEmailMailboxIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_EMAIL_MAILBOX_ID_DESC',
  WarmupEmailsByEmailMessageIdAverageEmailMessageIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  WarmupEmailsByEmailMessageIdAverageEmailMessageIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  WarmupEmailsByEmailMessageIdAverageFailedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_FAILED_TIME_ASC',
  WarmupEmailsByEmailMessageIdAverageFailedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_FAILED_TIME_DESC',
  WarmupEmailsByEmailMessageIdAverageIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_ID_ASC',
  WarmupEmailsByEmailMessageIdAverageIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_ID_DESC',
  WarmupEmailsByEmailMessageIdAverageSentTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_SENT_TIME_ASC',
  WarmupEmailsByEmailMessageIdAverageSentTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_SENT_TIME_DESC',
  WarmupEmailsByEmailMessageIdAverageStatusAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_STATUS_ASC',
  WarmupEmailsByEmailMessageIdAverageStatusDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_STATUS_DESC',
  WarmupEmailsByEmailMessageIdAverageThreadIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_THREAD_ID_ASC',
  WarmupEmailsByEmailMessageIdAverageThreadIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_THREAD_ID_DESC',
  WarmupEmailsByEmailMessageIdAverageToEmailAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_TO_EMAIL_ASC',
  WarmupEmailsByEmailMessageIdAverageToEmailDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_TO_EMAIL_DESC',
  WarmupEmailsByEmailMessageIdAverageUnmarkedSpamTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_UNMARKED_SPAM_TIME_ASC',
  WarmupEmailsByEmailMessageIdAverageUnmarkedSpamTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_UNMARKED_SPAM_TIME_DESC',
  WarmupEmailsByEmailMessageIdAverageUpdatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_UPDATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdAverageUpdatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_AVERAGE_UPDATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdCountAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_COUNT_ASC',
  WarmupEmailsByEmailMessageIdCountDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_COUNT_DESC',
  WarmupEmailsByEmailMessageIdDistinctCountContentAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CONTENT_ASC',
  WarmupEmailsByEmailMessageIdDistinctCountContentDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CONTENT_DESC',
  WarmupEmailsByEmailMessageIdDistinctCountCreatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdDistinctCountCreatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdDistinctCountEmailMailboxIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_EMAIL_MAILBOX_ID_ASC',
  WarmupEmailsByEmailMessageIdDistinctCountEmailMailboxIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_EMAIL_MAILBOX_ID_DESC',
  WarmupEmailsByEmailMessageIdDistinctCountEmailMessageIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  WarmupEmailsByEmailMessageIdDistinctCountEmailMessageIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  WarmupEmailsByEmailMessageIdDistinctCountFailedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_FAILED_TIME_ASC',
  WarmupEmailsByEmailMessageIdDistinctCountFailedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_FAILED_TIME_DESC',
  WarmupEmailsByEmailMessageIdDistinctCountIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_ID_ASC',
  WarmupEmailsByEmailMessageIdDistinctCountIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_ID_DESC',
  WarmupEmailsByEmailMessageIdDistinctCountSentTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_SENT_TIME_ASC',
  WarmupEmailsByEmailMessageIdDistinctCountSentTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_SENT_TIME_DESC',
  WarmupEmailsByEmailMessageIdDistinctCountStatusAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_STATUS_ASC',
  WarmupEmailsByEmailMessageIdDistinctCountStatusDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_STATUS_DESC',
  WarmupEmailsByEmailMessageIdDistinctCountThreadIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_THREAD_ID_ASC',
  WarmupEmailsByEmailMessageIdDistinctCountThreadIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_THREAD_ID_DESC',
  WarmupEmailsByEmailMessageIdDistinctCountToEmailAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_TO_EMAIL_ASC',
  WarmupEmailsByEmailMessageIdDistinctCountToEmailDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_TO_EMAIL_DESC',
  WarmupEmailsByEmailMessageIdDistinctCountUnmarkedSpamTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_UNMARKED_SPAM_TIME_ASC',
  WarmupEmailsByEmailMessageIdDistinctCountUnmarkedSpamTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_UNMARKED_SPAM_TIME_DESC',
  WarmupEmailsByEmailMessageIdDistinctCountUpdatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdDistinctCountUpdatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdMaxContentAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_CONTENT_ASC',
  WarmupEmailsByEmailMessageIdMaxContentDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_CONTENT_DESC',
  WarmupEmailsByEmailMessageIdMaxCreatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_CREATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdMaxCreatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_CREATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdMaxEmailMailboxIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_EMAIL_MAILBOX_ID_ASC',
  WarmupEmailsByEmailMessageIdMaxEmailMailboxIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_EMAIL_MAILBOX_ID_DESC',
  WarmupEmailsByEmailMessageIdMaxEmailMessageIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_EMAIL_MESSAGE_ID_ASC',
  WarmupEmailsByEmailMessageIdMaxEmailMessageIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_EMAIL_MESSAGE_ID_DESC',
  WarmupEmailsByEmailMessageIdMaxFailedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_FAILED_TIME_ASC',
  WarmupEmailsByEmailMessageIdMaxFailedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_FAILED_TIME_DESC',
  WarmupEmailsByEmailMessageIdMaxIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_ID_ASC',
  WarmupEmailsByEmailMessageIdMaxIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_ID_DESC',
  WarmupEmailsByEmailMessageIdMaxSentTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_SENT_TIME_ASC',
  WarmupEmailsByEmailMessageIdMaxSentTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_SENT_TIME_DESC',
  WarmupEmailsByEmailMessageIdMaxStatusAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_STATUS_ASC',
  WarmupEmailsByEmailMessageIdMaxStatusDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_STATUS_DESC',
  WarmupEmailsByEmailMessageIdMaxThreadIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_THREAD_ID_ASC',
  WarmupEmailsByEmailMessageIdMaxThreadIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_THREAD_ID_DESC',
  WarmupEmailsByEmailMessageIdMaxToEmailAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_TO_EMAIL_ASC',
  WarmupEmailsByEmailMessageIdMaxToEmailDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_TO_EMAIL_DESC',
  WarmupEmailsByEmailMessageIdMaxUnmarkedSpamTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_UNMARKED_SPAM_TIME_ASC',
  WarmupEmailsByEmailMessageIdMaxUnmarkedSpamTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_UNMARKED_SPAM_TIME_DESC',
  WarmupEmailsByEmailMessageIdMaxUpdatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_UPDATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdMaxUpdatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MAX_UPDATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdMinContentAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_CONTENT_ASC',
  WarmupEmailsByEmailMessageIdMinContentDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_CONTENT_DESC',
  WarmupEmailsByEmailMessageIdMinCreatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_CREATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdMinCreatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_CREATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdMinEmailMailboxIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_EMAIL_MAILBOX_ID_ASC',
  WarmupEmailsByEmailMessageIdMinEmailMailboxIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_EMAIL_MAILBOX_ID_DESC',
  WarmupEmailsByEmailMessageIdMinEmailMessageIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_EMAIL_MESSAGE_ID_ASC',
  WarmupEmailsByEmailMessageIdMinEmailMessageIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_EMAIL_MESSAGE_ID_DESC',
  WarmupEmailsByEmailMessageIdMinFailedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_FAILED_TIME_ASC',
  WarmupEmailsByEmailMessageIdMinFailedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_FAILED_TIME_DESC',
  WarmupEmailsByEmailMessageIdMinIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_ID_ASC',
  WarmupEmailsByEmailMessageIdMinIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_ID_DESC',
  WarmupEmailsByEmailMessageIdMinSentTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_SENT_TIME_ASC',
  WarmupEmailsByEmailMessageIdMinSentTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_SENT_TIME_DESC',
  WarmupEmailsByEmailMessageIdMinStatusAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_STATUS_ASC',
  WarmupEmailsByEmailMessageIdMinStatusDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_STATUS_DESC',
  WarmupEmailsByEmailMessageIdMinThreadIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_THREAD_ID_ASC',
  WarmupEmailsByEmailMessageIdMinThreadIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_THREAD_ID_DESC',
  WarmupEmailsByEmailMessageIdMinToEmailAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_TO_EMAIL_ASC',
  WarmupEmailsByEmailMessageIdMinToEmailDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_TO_EMAIL_DESC',
  WarmupEmailsByEmailMessageIdMinUnmarkedSpamTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_UNMARKED_SPAM_TIME_ASC',
  WarmupEmailsByEmailMessageIdMinUnmarkedSpamTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_UNMARKED_SPAM_TIME_DESC',
  WarmupEmailsByEmailMessageIdMinUpdatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_UPDATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdMinUpdatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_MIN_UPDATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdStddevPopulationContentAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CONTENT_ASC',
  WarmupEmailsByEmailMessageIdStddevPopulationContentDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CONTENT_DESC',
  WarmupEmailsByEmailMessageIdStddevPopulationCreatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdStddevPopulationCreatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdStddevPopulationEmailMailboxIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_EMAIL_MAILBOX_ID_ASC',
  WarmupEmailsByEmailMessageIdStddevPopulationEmailMailboxIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_EMAIL_MAILBOX_ID_DESC',
  WarmupEmailsByEmailMessageIdStddevPopulationEmailMessageIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  WarmupEmailsByEmailMessageIdStddevPopulationEmailMessageIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  WarmupEmailsByEmailMessageIdStddevPopulationFailedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_FAILED_TIME_ASC',
  WarmupEmailsByEmailMessageIdStddevPopulationFailedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_FAILED_TIME_DESC',
  WarmupEmailsByEmailMessageIdStddevPopulationIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_ID_ASC',
  WarmupEmailsByEmailMessageIdStddevPopulationIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_ID_DESC',
  WarmupEmailsByEmailMessageIdStddevPopulationSentTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_SENT_TIME_ASC',
  WarmupEmailsByEmailMessageIdStddevPopulationSentTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_SENT_TIME_DESC',
  WarmupEmailsByEmailMessageIdStddevPopulationStatusAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_STATUS_ASC',
  WarmupEmailsByEmailMessageIdStddevPopulationStatusDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_STATUS_DESC',
  WarmupEmailsByEmailMessageIdStddevPopulationThreadIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_THREAD_ID_ASC',
  WarmupEmailsByEmailMessageIdStddevPopulationThreadIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_THREAD_ID_DESC',
  WarmupEmailsByEmailMessageIdStddevPopulationToEmailAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_TO_EMAIL_ASC',
  WarmupEmailsByEmailMessageIdStddevPopulationToEmailDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_TO_EMAIL_DESC',
  WarmupEmailsByEmailMessageIdStddevPopulationUnmarkedSpamTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_UNMARKED_SPAM_TIME_ASC',
  WarmupEmailsByEmailMessageIdStddevPopulationUnmarkedSpamTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_UNMARKED_SPAM_TIME_DESC',
  WarmupEmailsByEmailMessageIdStddevPopulationUpdatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdStddevPopulationUpdatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdStddevSampleContentAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CONTENT_ASC',
  WarmupEmailsByEmailMessageIdStddevSampleContentDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CONTENT_DESC',
  WarmupEmailsByEmailMessageIdStddevSampleCreatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdStddevSampleCreatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdStddevSampleEmailMailboxIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_EMAIL_MAILBOX_ID_ASC',
  WarmupEmailsByEmailMessageIdStddevSampleEmailMailboxIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_EMAIL_MAILBOX_ID_DESC',
  WarmupEmailsByEmailMessageIdStddevSampleEmailMessageIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  WarmupEmailsByEmailMessageIdStddevSampleEmailMessageIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  WarmupEmailsByEmailMessageIdStddevSampleFailedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_FAILED_TIME_ASC',
  WarmupEmailsByEmailMessageIdStddevSampleFailedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_FAILED_TIME_DESC',
  WarmupEmailsByEmailMessageIdStddevSampleIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC',
  WarmupEmailsByEmailMessageIdStddevSampleIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC',
  WarmupEmailsByEmailMessageIdStddevSampleSentTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_SENT_TIME_ASC',
  WarmupEmailsByEmailMessageIdStddevSampleSentTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_SENT_TIME_DESC',
  WarmupEmailsByEmailMessageIdStddevSampleStatusAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_STATUS_ASC',
  WarmupEmailsByEmailMessageIdStddevSampleStatusDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_STATUS_DESC',
  WarmupEmailsByEmailMessageIdStddevSampleThreadIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_THREAD_ID_ASC',
  WarmupEmailsByEmailMessageIdStddevSampleThreadIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_THREAD_ID_DESC',
  WarmupEmailsByEmailMessageIdStddevSampleToEmailAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_TO_EMAIL_ASC',
  WarmupEmailsByEmailMessageIdStddevSampleToEmailDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_TO_EMAIL_DESC',
  WarmupEmailsByEmailMessageIdStddevSampleUnmarkedSpamTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_UNMARKED_SPAM_TIME_ASC',
  WarmupEmailsByEmailMessageIdStddevSampleUnmarkedSpamTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_UNMARKED_SPAM_TIME_DESC',
  WarmupEmailsByEmailMessageIdStddevSampleUpdatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdStddevSampleUpdatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdSumContentAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_CONTENT_ASC',
  WarmupEmailsByEmailMessageIdSumContentDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_CONTENT_DESC',
  WarmupEmailsByEmailMessageIdSumCreatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_CREATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdSumCreatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_CREATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdSumEmailMailboxIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_EMAIL_MAILBOX_ID_ASC',
  WarmupEmailsByEmailMessageIdSumEmailMailboxIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_EMAIL_MAILBOX_ID_DESC',
  WarmupEmailsByEmailMessageIdSumEmailMessageIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_EMAIL_MESSAGE_ID_ASC',
  WarmupEmailsByEmailMessageIdSumEmailMessageIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_EMAIL_MESSAGE_ID_DESC',
  WarmupEmailsByEmailMessageIdSumFailedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_FAILED_TIME_ASC',
  WarmupEmailsByEmailMessageIdSumFailedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_FAILED_TIME_DESC',
  WarmupEmailsByEmailMessageIdSumIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_ID_ASC',
  WarmupEmailsByEmailMessageIdSumIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_ID_DESC',
  WarmupEmailsByEmailMessageIdSumSentTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_SENT_TIME_ASC',
  WarmupEmailsByEmailMessageIdSumSentTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_SENT_TIME_DESC',
  WarmupEmailsByEmailMessageIdSumStatusAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_STATUS_ASC',
  WarmupEmailsByEmailMessageIdSumStatusDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_STATUS_DESC',
  WarmupEmailsByEmailMessageIdSumThreadIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_THREAD_ID_ASC',
  WarmupEmailsByEmailMessageIdSumThreadIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_THREAD_ID_DESC',
  WarmupEmailsByEmailMessageIdSumToEmailAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_TO_EMAIL_ASC',
  WarmupEmailsByEmailMessageIdSumToEmailDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_TO_EMAIL_DESC',
  WarmupEmailsByEmailMessageIdSumUnmarkedSpamTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_UNMARKED_SPAM_TIME_ASC',
  WarmupEmailsByEmailMessageIdSumUnmarkedSpamTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_UNMARKED_SPAM_TIME_DESC',
  WarmupEmailsByEmailMessageIdSumUpdatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_UPDATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdSumUpdatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_SUM_UPDATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdVariancePopulationContentAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CONTENT_ASC',
  WarmupEmailsByEmailMessageIdVariancePopulationContentDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CONTENT_DESC',
  WarmupEmailsByEmailMessageIdVariancePopulationCreatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdVariancePopulationCreatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdVariancePopulationEmailMailboxIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_EMAIL_MAILBOX_ID_ASC',
  WarmupEmailsByEmailMessageIdVariancePopulationEmailMailboxIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_EMAIL_MAILBOX_ID_DESC',
  WarmupEmailsByEmailMessageIdVariancePopulationEmailMessageIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  WarmupEmailsByEmailMessageIdVariancePopulationEmailMessageIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  WarmupEmailsByEmailMessageIdVariancePopulationFailedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_FAILED_TIME_ASC',
  WarmupEmailsByEmailMessageIdVariancePopulationFailedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_FAILED_TIME_DESC',
  WarmupEmailsByEmailMessageIdVariancePopulationIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC',
  WarmupEmailsByEmailMessageIdVariancePopulationIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC',
  WarmupEmailsByEmailMessageIdVariancePopulationSentTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_SENT_TIME_ASC',
  WarmupEmailsByEmailMessageIdVariancePopulationSentTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_SENT_TIME_DESC',
  WarmupEmailsByEmailMessageIdVariancePopulationStatusAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_STATUS_ASC',
  WarmupEmailsByEmailMessageIdVariancePopulationStatusDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_STATUS_DESC',
  WarmupEmailsByEmailMessageIdVariancePopulationThreadIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_THREAD_ID_ASC',
  WarmupEmailsByEmailMessageIdVariancePopulationThreadIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_THREAD_ID_DESC',
  WarmupEmailsByEmailMessageIdVariancePopulationToEmailAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_TO_EMAIL_ASC',
  WarmupEmailsByEmailMessageIdVariancePopulationToEmailDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_TO_EMAIL_DESC',
  WarmupEmailsByEmailMessageIdVariancePopulationUnmarkedSpamTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_UNMARKED_SPAM_TIME_ASC',
  WarmupEmailsByEmailMessageIdVariancePopulationUnmarkedSpamTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_UNMARKED_SPAM_TIME_DESC',
  WarmupEmailsByEmailMessageIdVariancePopulationUpdatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdVariancePopulationUpdatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdVarianceSampleContentAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  WarmupEmailsByEmailMessageIdVarianceSampleContentDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  WarmupEmailsByEmailMessageIdVarianceSampleCreatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdVarianceSampleCreatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  WarmupEmailsByEmailMessageIdVarianceSampleEmailMailboxIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_EMAIL_MAILBOX_ID_ASC',
  WarmupEmailsByEmailMessageIdVarianceSampleEmailMailboxIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_EMAIL_MAILBOX_ID_DESC',
  WarmupEmailsByEmailMessageIdVarianceSampleEmailMessageIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  WarmupEmailsByEmailMessageIdVarianceSampleEmailMessageIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  WarmupEmailsByEmailMessageIdVarianceSampleFailedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_FAILED_TIME_ASC',
  WarmupEmailsByEmailMessageIdVarianceSampleFailedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_FAILED_TIME_DESC',
  WarmupEmailsByEmailMessageIdVarianceSampleIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC',
  WarmupEmailsByEmailMessageIdVarianceSampleIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC',
  WarmupEmailsByEmailMessageIdVarianceSampleSentTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_SENT_TIME_ASC',
  WarmupEmailsByEmailMessageIdVarianceSampleSentTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_SENT_TIME_DESC',
  WarmupEmailsByEmailMessageIdVarianceSampleStatusAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_STATUS_ASC',
  WarmupEmailsByEmailMessageIdVarianceSampleStatusDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_STATUS_DESC',
  WarmupEmailsByEmailMessageIdVarianceSampleThreadIdAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_THREAD_ID_ASC',
  WarmupEmailsByEmailMessageIdVarianceSampleThreadIdDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_THREAD_ID_DESC',
  WarmupEmailsByEmailMessageIdVarianceSampleToEmailAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_TO_EMAIL_ASC',
  WarmupEmailsByEmailMessageIdVarianceSampleToEmailDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_TO_EMAIL_DESC',
  WarmupEmailsByEmailMessageIdVarianceSampleUnmarkedSpamTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_UNMARKED_SPAM_TIME_ASC',
  WarmupEmailsByEmailMessageIdVarianceSampleUnmarkedSpamTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_UNMARKED_SPAM_TIME_DESC',
  WarmupEmailsByEmailMessageIdVarianceSampleUpdatedTimeAsc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  WarmupEmailsByEmailMessageIdVarianceSampleUpdatedTimeDesc = 'WARMUP_EMAILS_BY_EMAIL_MESSAGE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC'
}

export type EmailPersonalisation = Node & {
  __typename?: 'EmailPersonalisation';
  /** Reads a single `Email` that is related to this `EmailPersonalisation`. */
  emailByEmailId?: Maybe<Email>;
  emailId: Scalars['UUID']['output'];
  flag: Scalars['String']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  value: Scalars['String']['output'];
};

export type EmailPersonalisationAggregates = {
  __typename?: 'EmailPersonalisationAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EmailPersonalisationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `EmailPersonalisation` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type EmailPersonalisationCondition = {
  /** Checks for equality with the object’s `emailId` field. */
  emailId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `flag` field. */
  flag?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type EmailPersonalisationDistinctCountAggregates = {
  __typename?: 'EmailPersonalisationDistinctCountAggregates';
  /** Distinct count of emailId across the matching connection */
  emailId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of flag across the matching connection */
  flag?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `EmailPersonalisation` object types. All fields are combined with a logical ‘and.’ */
export type EmailPersonalisationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EmailPersonalisationFilter>>;
  /** Filter by the object’s `emailId` field. */
  emailId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `flag` field. */
  flag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EmailPersonalisationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EmailPersonalisationFilter>>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<StringFilter>;
};

/** Grouping methods for `EmailPersonalisation` for usage during aggregation. */
export enum EmailPersonalisationGroupBy {
  EmailId = 'EMAIL_ID',
  Flag = 'FLAG',
  Id = 'ID',
  Value = 'VALUE'
}

/** Conditions for `EmailPersonalisation` aggregates. */
export type EmailPersonalisationHavingInput = {
  AND?: InputMaybe<Array<EmailPersonalisationHavingInput>>;
  OR?: InputMaybe<Array<EmailPersonalisationHavingInput>>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type EmailPersonalisationSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: EmailPersonalisationSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type EmailPersonalisationSortBy = {
  field?: InputMaybe<EmailPersonalisationSortableField>;
};

/** Sortable concrete fields for the `EmailPersonalisation` type. */
export enum EmailPersonalisationSortableField {
  EmailId = 'EMAIL_ID',
  Flag = 'FLAG',
  Id = 'ID',
  Value = 'VALUE'
}

/** A connection to a list of `EmailPersonalisation` values. */
export type EmailPersonalisationsConnection = {
  __typename?: 'EmailPersonalisationsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EmailPersonalisationAggregates>;
  /** A list of edges which contains the `EmailPersonalisation` and cursor to aid in pagination. */
  edges: Array<EmailPersonalisationsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EmailPersonalisationAggregates>>;
  /** A list of `EmailPersonalisation` objects. */
  nodes: Array<Maybe<EmailPersonalisation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EmailPersonalisation` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `EmailPersonalisation` values. */
export type EmailPersonalisationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<EmailPersonalisationGroupBy>;
  having?: InputMaybe<EmailPersonalisationHavingInput>;
};

/** A `EmailPersonalisation` edge in the connection. */
export type EmailPersonalisationsEdge = {
  __typename?: 'EmailPersonalisationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `EmailPersonalisation` at the end of the edge. */
  node?: Maybe<EmailPersonalisation>;
};

/** Methods to use when ordering `EmailPersonalisation`. */
export enum EmailPersonalisationsOrderBy {
  EmailIdAsc = 'EMAIL_ID_ASC',
  EmailIdDesc = 'EMAIL_ID_DESC',
  FlagAsc = 'FLAG_ASC',
  FlagDesc = 'FLAG_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type EmailSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: EmailSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type EmailSortBy = {
  field?: InputMaybe<EmailSortableField>;
};

/** Sortable concrete fields for the `Email` type. */
export enum EmailSortableField {
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  Content = 'CONTENT',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  SenderProfileId = 'SENDER_PROFILE_ID',
  SendAt = 'SEND_AT',
  SentAt = 'SENT_AT',
  SmtpMessageId = 'SMTP_MESSAGE_ID',
  Status = 'STATUS',
  TemplateContent = 'TEMPLATE_CONTENT',
  TemplateId = 'TEMPLATE_ID',
  UpdatedTime = 'UPDATED_TIME'
}

/** A connection to a list of `Email` values. */
export type EmailsConnection = {
  __typename?: 'EmailsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EmailAggregates>;
  /** A list of edges which contains the `Email` and cursor to aid in pagination. */
  edges: Array<EmailsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EmailAggregates>>;
  /** A list of `Email` objects. */
  nodes: Array<Maybe<Email>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Email` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Email` values. */
export type EmailsConnectionGroupedAggregatesArgs = {
  groupBy: Array<EmailGroupBy>;
  having?: InputMaybe<EmailHavingInput>;
};

/** A `Email` edge in the connection. */
export type EmailsEdge = {
  __typename?: 'EmailsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Email` at the end of the edge. */
  node?: Maybe<Email>;
};

/** Methods to use when ordering `Email`. */
export enum EmailsOrderBy {
  CampaignMemberIdAsc = 'CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberIdDesc = 'CAMPAIGN_MEMBER_ID_DESC',
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdAverageContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByEmailIdAverageContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByEmailIdAverageCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdAverageCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdAverageEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByEmailIdAverageEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByEmailIdAverageErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdAverageErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdAverageErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByEmailIdAverageErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByEmailIdAverageFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdAverageFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdAverageIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_ID_ASC',
  EmailDeliverabilityResultsByEmailIdAverageIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_ID_DESC',
  EmailDeliverabilityResultsByEmailIdAverageIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByEmailIdAverageIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByEmailIdAverageLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByEmailIdAverageLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByEmailIdAverageResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByEmailIdAverageResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByEmailIdAverageResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdAverageResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdAverageToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByEmailIdAverageToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByEmailIdAverageToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdAverageToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdAverageUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdAverageUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdCountAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_COUNT_ASC',
  EmailDeliverabilityResultsByEmailIdCountDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_COUNT_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_ID_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_ID_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdDistinctCountUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdDistinctCountUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdMaxContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByEmailIdMaxContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByEmailIdMaxCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdMaxCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdMaxEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByEmailIdMaxEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByEmailIdMaxErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdMaxErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdMaxErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByEmailIdMaxErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByEmailIdMaxFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdMaxFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdMaxIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_ID_ASC',
  EmailDeliverabilityResultsByEmailIdMaxIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_ID_DESC',
  EmailDeliverabilityResultsByEmailIdMaxIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByEmailIdMaxIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByEmailIdMaxLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByEmailIdMaxLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByEmailIdMaxResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByEmailIdMaxResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByEmailIdMaxResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdMaxResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdMaxToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByEmailIdMaxToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByEmailIdMaxToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdMaxToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdMaxUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdMaxUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MAX_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdMinContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByEmailIdMinContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByEmailIdMinCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdMinCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdMinEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByEmailIdMinEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByEmailIdMinErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdMinErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdMinErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByEmailIdMinErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByEmailIdMinFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdMinFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdMinIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_ID_ASC',
  EmailDeliverabilityResultsByEmailIdMinIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_ID_DESC',
  EmailDeliverabilityResultsByEmailIdMinIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByEmailIdMinIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByEmailIdMinLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByEmailIdMinLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByEmailIdMinResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByEmailIdMinResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByEmailIdMinResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdMinResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdMinToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByEmailIdMinToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByEmailIdMinToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdMinToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdMinUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdMinUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_MIN_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_ID_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_ID_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdStddevPopulationUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_ID_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_ID_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdStddevSampleUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdStddevSampleUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdSumContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByEmailIdSumContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByEmailIdSumCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdSumCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdSumEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByEmailIdSumEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByEmailIdSumErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdSumErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdSumErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByEmailIdSumErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByEmailIdSumFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdSumFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdSumIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_ID_ASC',
  EmailDeliverabilityResultsByEmailIdSumIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_ID_DESC',
  EmailDeliverabilityResultsByEmailIdSumIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByEmailIdSumIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByEmailIdSumLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByEmailIdSumLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByEmailIdSumResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByEmailIdSumResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByEmailIdSumResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdSumResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdSumToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByEmailIdSumToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByEmailIdSumToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdSumToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdSumUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdSumUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_SUM_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_ID_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_ID_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdVariancePopulationUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByEmailIdVarianceSampleUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_EMAIL_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EmailEventsByEmailIdAverageCreatedTimeAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_AVERAGE_CREATED_TIME_ASC',
  EmailEventsByEmailIdAverageCreatedTimeDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_AVERAGE_CREATED_TIME_DESC',
  EmailEventsByEmailIdAverageEmailIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_AVERAGE_EMAIL_ID_ASC',
  EmailEventsByEmailIdAverageEmailIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_AVERAGE_EMAIL_ID_DESC',
  EmailEventsByEmailIdAverageEventNameAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_AVERAGE_EVENT_NAME_ASC',
  EmailEventsByEmailIdAverageEventNameDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_AVERAGE_EVENT_NAME_DESC',
  EmailEventsByEmailIdAverageSendgridEventIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_AVERAGE_SENDGRID_EVENT_ID_ASC',
  EmailEventsByEmailIdAverageSendgridEventIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_AVERAGE_SENDGRID_EVENT_ID_DESC',
  EmailEventsByEmailIdCountAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_COUNT_ASC',
  EmailEventsByEmailIdCountDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_COUNT_DESC',
  EmailEventsByEmailIdDistinctCountCreatedTimeAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailEventsByEmailIdDistinctCountCreatedTimeDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailEventsByEmailIdDistinctCountEmailIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailEventsByEmailIdDistinctCountEmailIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailEventsByEmailIdDistinctCountEventNameAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_DISTINCT_COUNT_EVENT_NAME_ASC',
  EmailEventsByEmailIdDistinctCountEventNameDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_DISTINCT_COUNT_EVENT_NAME_DESC',
  EmailEventsByEmailIdDistinctCountSendgridEventIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_DISTINCT_COUNT_SENDGRID_EVENT_ID_ASC',
  EmailEventsByEmailIdDistinctCountSendgridEventIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_DISTINCT_COUNT_SENDGRID_EVENT_ID_DESC',
  EmailEventsByEmailIdMaxCreatedTimeAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_MAX_CREATED_TIME_ASC',
  EmailEventsByEmailIdMaxCreatedTimeDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_MAX_CREATED_TIME_DESC',
  EmailEventsByEmailIdMaxEmailIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_MAX_EMAIL_ID_ASC',
  EmailEventsByEmailIdMaxEmailIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_MAX_EMAIL_ID_DESC',
  EmailEventsByEmailIdMaxEventNameAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_MAX_EVENT_NAME_ASC',
  EmailEventsByEmailIdMaxEventNameDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_MAX_EVENT_NAME_DESC',
  EmailEventsByEmailIdMaxSendgridEventIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_MAX_SENDGRID_EVENT_ID_ASC',
  EmailEventsByEmailIdMaxSendgridEventIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_MAX_SENDGRID_EVENT_ID_DESC',
  EmailEventsByEmailIdMinCreatedTimeAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_MIN_CREATED_TIME_ASC',
  EmailEventsByEmailIdMinCreatedTimeDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_MIN_CREATED_TIME_DESC',
  EmailEventsByEmailIdMinEmailIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_MIN_EMAIL_ID_ASC',
  EmailEventsByEmailIdMinEmailIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_MIN_EMAIL_ID_DESC',
  EmailEventsByEmailIdMinEventNameAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_MIN_EVENT_NAME_ASC',
  EmailEventsByEmailIdMinEventNameDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_MIN_EVENT_NAME_DESC',
  EmailEventsByEmailIdMinSendgridEventIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_MIN_SENDGRID_EVENT_ID_ASC',
  EmailEventsByEmailIdMinSendgridEventIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_MIN_SENDGRID_EVENT_ID_DESC',
  EmailEventsByEmailIdStddevPopulationCreatedTimeAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailEventsByEmailIdStddevPopulationCreatedTimeDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailEventsByEmailIdStddevPopulationEmailIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailEventsByEmailIdStddevPopulationEmailIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailEventsByEmailIdStddevPopulationEventNameAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_POPULATION_EVENT_NAME_ASC',
  EmailEventsByEmailIdStddevPopulationEventNameDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_POPULATION_EVENT_NAME_DESC',
  EmailEventsByEmailIdStddevPopulationSendgridEventIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_POPULATION_SENDGRID_EVENT_ID_ASC',
  EmailEventsByEmailIdStddevPopulationSendgridEventIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_POPULATION_SENDGRID_EVENT_ID_DESC',
  EmailEventsByEmailIdStddevSampleCreatedTimeAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailEventsByEmailIdStddevSampleCreatedTimeDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailEventsByEmailIdStddevSampleEmailIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailEventsByEmailIdStddevSampleEmailIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailEventsByEmailIdStddevSampleEventNameAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_SAMPLE_EVENT_NAME_ASC',
  EmailEventsByEmailIdStddevSampleEventNameDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_SAMPLE_EVENT_NAME_DESC',
  EmailEventsByEmailIdStddevSampleSendgridEventIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_SAMPLE_SENDGRID_EVENT_ID_ASC',
  EmailEventsByEmailIdStddevSampleSendgridEventIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_STDDEV_SAMPLE_SENDGRID_EVENT_ID_DESC',
  EmailEventsByEmailIdSumCreatedTimeAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_SUM_CREATED_TIME_ASC',
  EmailEventsByEmailIdSumCreatedTimeDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_SUM_CREATED_TIME_DESC',
  EmailEventsByEmailIdSumEmailIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_SUM_EMAIL_ID_ASC',
  EmailEventsByEmailIdSumEmailIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_SUM_EMAIL_ID_DESC',
  EmailEventsByEmailIdSumEventNameAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_SUM_EVENT_NAME_ASC',
  EmailEventsByEmailIdSumEventNameDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_SUM_EVENT_NAME_DESC',
  EmailEventsByEmailIdSumSendgridEventIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_SUM_SENDGRID_EVENT_ID_ASC',
  EmailEventsByEmailIdSumSendgridEventIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_SUM_SENDGRID_EVENT_ID_DESC',
  EmailEventsByEmailIdVariancePopulationCreatedTimeAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailEventsByEmailIdVariancePopulationCreatedTimeDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailEventsByEmailIdVariancePopulationEmailIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailEventsByEmailIdVariancePopulationEmailIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailEventsByEmailIdVariancePopulationEventNameAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_POPULATION_EVENT_NAME_ASC',
  EmailEventsByEmailIdVariancePopulationEventNameDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_POPULATION_EVENT_NAME_DESC',
  EmailEventsByEmailIdVariancePopulationSendgridEventIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_POPULATION_SENDGRID_EVENT_ID_ASC',
  EmailEventsByEmailIdVariancePopulationSendgridEventIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_POPULATION_SENDGRID_EVENT_ID_DESC',
  EmailEventsByEmailIdVarianceSampleCreatedTimeAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailEventsByEmailIdVarianceSampleCreatedTimeDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailEventsByEmailIdVarianceSampleEmailIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailEventsByEmailIdVarianceSampleEmailIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailEventsByEmailIdVarianceSampleEventNameAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_SAMPLE_EVENT_NAME_ASC',
  EmailEventsByEmailIdVarianceSampleEventNameDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_SAMPLE_EVENT_NAME_DESC',
  EmailEventsByEmailIdVarianceSampleSendgridEventIdAsc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_SAMPLE_SENDGRID_EVENT_ID_ASC',
  EmailEventsByEmailIdVarianceSampleSendgridEventIdDesc = 'EMAIL_EVENTS_BY_EMAIL_ID_VARIANCE_SAMPLE_SENDGRID_EVENT_ID_DESC',
  EmailMessagesByEmailIdAverageAutoRepliedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_AUTO_REPLIED_ASC',
  EmailMessagesByEmailIdAverageAutoRepliedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_AUTO_REPLIED_DESC',
  EmailMessagesByEmailIdAverageCampaignIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CAMPAIGN_ID_ASC',
  EmailMessagesByEmailIdAverageCampaignIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CAMPAIGN_ID_DESC',
  EmailMessagesByEmailIdAverageCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByEmailIdAverageCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByEmailIdAverageCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdAverageCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdAverageCompanyIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_COMPANY_ID_ASC',
  EmailMessagesByEmailIdAverageCompanyIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_COMPANY_ID_DESC',
  EmailMessagesByEmailIdAverageContactIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CONTACT_ID_ASC',
  EmailMessagesByEmailIdAverageContactIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CONTACT_ID_DESC',
  EmailMessagesByEmailIdAverageContentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CONTENT_ASC',
  EmailMessagesByEmailIdAverageContentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CONTENT_DESC',
  EmailMessagesByEmailIdAverageContentHtmlAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CONTENT_HTML_ASC',
  EmailMessagesByEmailIdAverageContentHtmlDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CONTENT_HTML_DESC',
  EmailMessagesByEmailIdAverageCreatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CREATED_TIME_ASC',
  EmailMessagesByEmailIdAverageCreatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_CREATED_TIME_DESC',
  EmailMessagesByEmailIdAverageEmailIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_EMAIL_ID_ASC',
  EmailMessagesByEmailIdAverageEmailIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_EMAIL_ID_DESC',
  EmailMessagesByEmailIdAverageForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByEmailIdAverageForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByEmailIdAverageForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdAverageForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdAverageGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_GENERATED_REPLY_ASC',
  EmailMessagesByEmailIdAverageGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_GENERATED_REPLY_DESC',
  EmailMessagesByEmailIdAverageHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdAverageHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdAverageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_ID_ASC',
  EmailMessagesByEmailIdAverageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_ID_DESC',
  EmailMessagesByEmailIdAverageIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_INTENT_ASC',
  EmailMessagesByEmailIdAverageIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_INTENT_DESC',
  EmailMessagesByEmailIdAverageIsLawyerAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_IS_LAWYER_ASC',
  EmailMessagesByEmailIdAverageIsLawyerDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_IS_LAWYER_DESC',
  EmailMessagesByEmailIdAverageIsReviewedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_IS_REVIEWED_ASC',
  EmailMessagesByEmailIdAverageIsReviewedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_IS_REVIEWED_DESC',
  EmailMessagesByEmailIdAverageLawyerIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_LAWYER_ID_ASC',
  EmailMessagesByEmailIdAverageLawyerIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_LAWYER_ID_DESC',
  EmailMessagesByEmailIdAverageLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByEmailIdAverageLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByEmailIdAverageNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByEmailIdAverageNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByEmailIdAverageOrderIndexAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_ORDER_INDEX_ASC',
  EmailMessagesByEmailIdAverageOrderIndexDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_ORDER_INDEX_DESC',
  EmailMessagesByEmailIdAveragePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByEmailIdAveragePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByEmailIdAverageRepliedIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_REPLIED_INTENT_ASC',
  EmailMessagesByEmailIdAverageRepliedIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_REPLIED_INTENT_DESC',
  EmailMessagesByEmailIdAverageRepliedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_REPLIED_TIME_ASC',
  EmailMessagesByEmailIdAverageRepliedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_REPLIED_TIME_DESC',
  EmailMessagesByEmailIdAverageRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdAverageRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdAverageReviewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_REVIEWED_TIME_ASC',
  EmailMessagesByEmailIdAverageReviewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_REVIEWED_TIME_DESC',
  EmailMessagesByEmailIdAverageSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByEmailIdAverageSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByEmailIdAverageSentTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_SENT_TIME_ASC',
  EmailMessagesByEmailIdAverageSentTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_SENT_TIME_DESC',
  EmailMessagesByEmailIdAverageSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdAverageSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdAverageSubjectAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_SUBJECT_ASC',
  EmailMessagesByEmailIdAverageSubjectDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_SUBJECT_DESC',
  EmailMessagesByEmailIdAverageTemplateIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_TEMPLATE_ID_ASC',
  EmailMessagesByEmailIdAverageTemplateIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_TEMPLATE_ID_DESC',
  EmailMessagesByEmailIdAverageUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailMessagesByEmailIdAverageUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailMessagesByEmailIdCountAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_COUNT_ASC',
  EmailMessagesByEmailIdCountDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_COUNT_DESC',
  EmailMessagesByEmailIdDistinctCountAutoRepliedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_AUTO_REPLIED_ASC',
  EmailMessagesByEmailIdDistinctCountAutoRepliedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_AUTO_REPLIED_DESC',
  EmailMessagesByEmailIdDistinctCountCampaignIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  EmailMessagesByEmailIdDistinctCountCampaignIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  EmailMessagesByEmailIdDistinctCountCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByEmailIdDistinctCountCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByEmailIdDistinctCountCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdDistinctCountCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdDistinctCountCompanyIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  EmailMessagesByEmailIdDistinctCountCompanyIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  EmailMessagesByEmailIdDistinctCountContactIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  EmailMessagesByEmailIdDistinctCountContactIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  EmailMessagesByEmailIdDistinctCountContentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CONTENT_ASC',
  EmailMessagesByEmailIdDistinctCountContentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CONTENT_DESC',
  EmailMessagesByEmailIdDistinctCountContentHtmlAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CONTENT_HTML_ASC',
  EmailMessagesByEmailIdDistinctCountContentHtmlDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CONTENT_HTML_DESC',
  EmailMessagesByEmailIdDistinctCountCreatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailMessagesByEmailIdDistinctCountCreatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailMessagesByEmailIdDistinctCountEmailIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailMessagesByEmailIdDistinctCountEmailIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailMessagesByEmailIdDistinctCountForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByEmailIdDistinctCountForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByEmailIdDistinctCountForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdDistinctCountForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdDistinctCountGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_GENERATED_REPLY_ASC',
  EmailMessagesByEmailIdDistinctCountGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_GENERATED_REPLY_DESC',
  EmailMessagesByEmailIdDistinctCountHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdDistinctCountHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdDistinctCountIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_ID_ASC',
  EmailMessagesByEmailIdDistinctCountIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_ID_DESC',
  EmailMessagesByEmailIdDistinctCountIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_INTENT_ASC',
  EmailMessagesByEmailIdDistinctCountIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_INTENT_DESC',
  EmailMessagesByEmailIdDistinctCountIsLawyerAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  EmailMessagesByEmailIdDistinctCountIsLawyerDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  EmailMessagesByEmailIdDistinctCountIsReviewedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_IS_REVIEWED_ASC',
  EmailMessagesByEmailIdDistinctCountIsReviewedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_IS_REVIEWED_DESC',
  EmailMessagesByEmailIdDistinctCountLawyerIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  EmailMessagesByEmailIdDistinctCountLawyerIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  EmailMessagesByEmailIdDistinctCountLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByEmailIdDistinctCountLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByEmailIdDistinctCountNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_ASC',
  EmailMessagesByEmailIdDistinctCountNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_DESC',
  EmailMessagesByEmailIdDistinctCountOrderIndexAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_ORDER_INDEX_ASC',
  EmailMessagesByEmailIdDistinctCountOrderIndexDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_ORDER_INDEX_DESC',
  EmailMessagesByEmailIdDistinctCountPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByEmailIdDistinctCountPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByEmailIdDistinctCountRepliedIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_REPLIED_INTENT_ASC',
  EmailMessagesByEmailIdDistinctCountRepliedIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_REPLIED_INTENT_DESC',
  EmailMessagesByEmailIdDistinctCountRepliedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_REPLIED_TIME_ASC',
  EmailMessagesByEmailIdDistinctCountRepliedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_REPLIED_TIME_DESC',
  EmailMessagesByEmailIdDistinctCountRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdDistinctCountRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdDistinctCountReviewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  EmailMessagesByEmailIdDistinctCountReviewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  EmailMessagesByEmailIdDistinctCountSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  EmailMessagesByEmailIdDistinctCountSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  EmailMessagesByEmailIdDistinctCountSentTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_SENT_TIME_ASC',
  EmailMessagesByEmailIdDistinctCountSentTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_SENT_TIME_DESC',
  EmailMessagesByEmailIdDistinctCountSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdDistinctCountSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdDistinctCountSubjectAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_SUBJECT_ASC',
  EmailMessagesByEmailIdDistinctCountSubjectDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_SUBJECT_DESC',
  EmailMessagesByEmailIdDistinctCountTemplateIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  EmailMessagesByEmailIdDistinctCountTemplateIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  EmailMessagesByEmailIdDistinctCountUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailMessagesByEmailIdDistinctCountUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailMessagesByEmailIdMaxAutoRepliedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_AUTO_REPLIED_ASC',
  EmailMessagesByEmailIdMaxAutoRepliedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_AUTO_REPLIED_DESC',
  EmailMessagesByEmailIdMaxCampaignIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CAMPAIGN_ID_ASC',
  EmailMessagesByEmailIdMaxCampaignIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CAMPAIGN_ID_DESC',
  EmailMessagesByEmailIdMaxCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByEmailIdMaxCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByEmailIdMaxCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdMaxCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdMaxCompanyIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_COMPANY_ID_ASC',
  EmailMessagesByEmailIdMaxCompanyIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_COMPANY_ID_DESC',
  EmailMessagesByEmailIdMaxContactIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CONTACT_ID_ASC',
  EmailMessagesByEmailIdMaxContactIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CONTACT_ID_DESC',
  EmailMessagesByEmailIdMaxContentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CONTENT_ASC',
  EmailMessagesByEmailIdMaxContentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CONTENT_DESC',
  EmailMessagesByEmailIdMaxContentHtmlAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CONTENT_HTML_ASC',
  EmailMessagesByEmailIdMaxContentHtmlDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CONTENT_HTML_DESC',
  EmailMessagesByEmailIdMaxCreatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CREATED_TIME_ASC',
  EmailMessagesByEmailIdMaxCreatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_CREATED_TIME_DESC',
  EmailMessagesByEmailIdMaxEmailIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_EMAIL_ID_ASC',
  EmailMessagesByEmailIdMaxEmailIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_EMAIL_ID_DESC',
  EmailMessagesByEmailIdMaxForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByEmailIdMaxForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByEmailIdMaxForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdMaxForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdMaxGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_GENERATED_REPLY_ASC',
  EmailMessagesByEmailIdMaxGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_GENERATED_REPLY_DESC',
  EmailMessagesByEmailIdMaxHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdMaxHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdMaxIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_ID_ASC',
  EmailMessagesByEmailIdMaxIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_ID_DESC',
  EmailMessagesByEmailIdMaxIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_INTENT_ASC',
  EmailMessagesByEmailIdMaxIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_INTENT_DESC',
  EmailMessagesByEmailIdMaxIsLawyerAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_IS_LAWYER_ASC',
  EmailMessagesByEmailIdMaxIsLawyerDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_IS_LAWYER_DESC',
  EmailMessagesByEmailIdMaxIsReviewedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_IS_REVIEWED_ASC',
  EmailMessagesByEmailIdMaxIsReviewedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_IS_REVIEWED_DESC',
  EmailMessagesByEmailIdMaxLawyerIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_LAWYER_ID_ASC',
  EmailMessagesByEmailIdMaxLawyerIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_LAWYER_ID_DESC',
  EmailMessagesByEmailIdMaxLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByEmailIdMaxLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByEmailIdMaxNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_NYLAS_THREAD_ID_ASC',
  EmailMessagesByEmailIdMaxNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_NYLAS_THREAD_ID_DESC',
  EmailMessagesByEmailIdMaxOrderIndexAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_ORDER_INDEX_ASC',
  EmailMessagesByEmailIdMaxOrderIndexDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_ORDER_INDEX_DESC',
  EmailMessagesByEmailIdMaxPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByEmailIdMaxPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByEmailIdMaxRepliedIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_REPLIED_INTENT_ASC',
  EmailMessagesByEmailIdMaxRepliedIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_REPLIED_INTENT_DESC',
  EmailMessagesByEmailIdMaxRepliedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_REPLIED_TIME_ASC',
  EmailMessagesByEmailIdMaxRepliedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_REPLIED_TIME_DESC',
  EmailMessagesByEmailIdMaxRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdMaxRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdMaxReviewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_REVIEWED_TIME_ASC',
  EmailMessagesByEmailIdMaxReviewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_REVIEWED_TIME_DESC',
  EmailMessagesByEmailIdMaxSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_SENDER_PROFILE_ID_ASC',
  EmailMessagesByEmailIdMaxSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_SENDER_PROFILE_ID_DESC',
  EmailMessagesByEmailIdMaxSentTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_SENT_TIME_ASC',
  EmailMessagesByEmailIdMaxSentTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_SENT_TIME_DESC',
  EmailMessagesByEmailIdMaxSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdMaxSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdMaxSubjectAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_SUBJECT_ASC',
  EmailMessagesByEmailIdMaxSubjectDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_SUBJECT_DESC',
  EmailMessagesByEmailIdMaxTemplateIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_TEMPLATE_ID_ASC',
  EmailMessagesByEmailIdMaxTemplateIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_TEMPLATE_ID_DESC',
  EmailMessagesByEmailIdMaxUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_UPDATED_TIME_ASC',
  EmailMessagesByEmailIdMaxUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MAX_UPDATED_TIME_DESC',
  EmailMessagesByEmailIdMinAutoRepliedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_AUTO_REPLIED_ASC',
  EmailMessagesByEmailIdMinAutoRepliedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_AUTO_REPLIED_DESC',
  EmailMessagesByEmailIdMinCampaignIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CAMPAIGN_ID_ASC',
  EmailMessagesByEmailIdMinCampaignIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CAMPAIGN_ID_DESC',
  EmailMessagesByEmailIdMinCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByEmailIdMinCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByEmailIdMinCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdMinCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdMinCompanyIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_COMPANY_ID_ASC',
  EmailMessagesByEmailIdMinCompanyIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_COMPANY_ID_DESC',
  EmailMessagesByEmailIdMinContactIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CONTACT_ID_ASC',
  EmailMessagesByEmailIdMinContactIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CONTACT_ID_DESC',
  EmailMessagesByEmailIdMinContentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CONTENT_ASC',
  EmailMessagesByEmailIdMinContentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CONTENT_DESC',
  EmailMessagesByEmailIdMinContentHtmlAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CONTENT_HTML_ASC',
  EmailMessagesByEmailIdMinContentHtmlDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CONTENT_HTML_DESC',
  EmailMessagesByEmailIdMinCreatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CREATED_TIME_ASC',
  EmailMessagesByEmailIdMinCreatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_CREATED_TIME_DESC',
  EmailMessagesByEmailIdMinEmailIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_EMAIL_ID_ASC',
  EmailMessagesByEmailIdMinEmailIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_EMAIL_ID_DESC',
  EmailMessagesByEmailIdMinForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByEmailIdMinForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByEmailIdMinForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdMinForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdMinGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_GENERATED_REPLY_ASC',
  EmailMessagesByEmailIdMinGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_GENERATED_REPLY_DESC',
  EmailMessagesByEmailIdMinHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdMinHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdMinIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_ID_ASC',
  EmailMessagesByEmailIdMinIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_ID_DESC',
  EmailMessagesByEmailIdMinIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_INTENT_ASC',
  EmailMessagesByEmailIdMinIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_INTENT_DESC',
  EmailMessagesByEmailIdMinIsLawyerAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_IS_LAWYER_ASC',
  EmailMessagesByEmailIdMinIsLawyerDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_IS_LAWYER_DESC',
  EmailMessagesByEmailIdMinIsReviewedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_IS_REVIEWED_ASC',
  EmailMessagesByEmailIdMinIsReviewedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_IS_REVIEWED_DESC',
  EmailMessagesByEmailIdMinLawyerIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_LAWYER_ID_ASC',
  EmailMessagesByEmailIdMinLawyerIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_LAWYER_ID_DESC',
  EmailMessagesByEmailIdMinLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByEmailIdMinLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByEmailIdMinNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_NYLAS_THREAD_ID_ASC',
  EmailMessagesByEmailIdMinNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_NYLAS_THREAD_ID_DESC',
  EmailMessagesByEmailIdMinOrderIndexAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_ORDER_INDEX_ASC',
  EmailMessagesByEmailIdMinOrderIndexDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_ORDER_INDEX_DESC',
  EmailMessagesByEmailIdMinPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByEmailIdMinPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByEmailIdMinRepliedIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_REPLIED_INTENT_ASC',
  EmailMessagesByEmailIdMinRepliedIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_REPLIED_INTENT_DESC',
  EmailMessagesByEmailIdMinRepliedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_REPLIED_TIME_ASC',
  EmailMessagesByEmailIdMinRepliedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_REPLIED_TIME_DESC',
  EmailMessagesByEmailIdMinRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdMinRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdMinReviewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_REVIEWED_TIME_ASC',
  EmailMessagesByEmailIdMinReviewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_REVIEWED_TIME_DESC',
  EmailMessagesByEmailIdMinSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_SENDER_PROFILE_ID_ASC',
  EmailMessagesByEmailIdMinSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_SENDER_PROFILE_ID_DESC',
  EmailMessagesByEmailIdMinSentTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_SENT_TIME_ASC',
  EmailMessagesByEmailIdMinSentTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_SENT_TIME_DESC',
  EmailMessagesByEmailIdMinSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdMinSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdMinSubjectAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_SUBJECT_ASC',
  EmailMessagesByEmailIdMinSubjectDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_SUBJECT_DESC',
  EmailMessagesByEmailIdMinTemplateIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_TEMPLATE_ID_ASC',
  EmailMessagesByEmailIdMinTemplateIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_TEMPLATE_ID_DESC',
  EmailMessagesByEmailIdMinUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_UPDATED_TIME_ASC',
  EmailMessagesByEmailIdMinUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_MIN_UPDATED_TIME_DESC',
  EmailMessagesByEmailIdStddevPopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByEmailIdStddevPopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByEmailIdStddevPopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByEmailIdStddevPopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByEmailIdStddevPopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByEmailIdStddevPopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByEmailIdStddevPopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdStddevPopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdStddevPopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByEmailIdStddevPopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByEmailIdStddevPopulationContactIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByEmailIdStddevPopulationContactIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByEmailIdStddevPopulationContentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CONTENT_ASC',
  EmailMessagesByEmailIdStddevPopulationContentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CONTENT_DESC',
  EmailMessagesByEmailIdStddevPopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByEmailIdStddevPopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByEmailIdStddevPopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByEmailIdStddevPopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByEmailIdStddevPopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByEmailIdStddevPopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByEmailIdStddevPopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByEmailIdStddevPopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByEmailIdStddevPopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdStddevPopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdStddevPopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByEmailIdStddevPopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByEmailIdStddevPopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdStddevPopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdStddevPopulationIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_ID_ASC',
  EmailMessagesByEmailIdStddevPopulationIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_ID_DESC',
  EmailMessagesByEmailIdStddevPopulationIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_INTENT_ASC',
  EmailMessagesByEmailIdStddevPopulationIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_INTENT_DESC',
  EmailMessagesByEmailIdStddevPopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByEmailIdStddevPopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByEmailIdStddevPopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByEmailIdStddevPopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByEmailIdStddevPopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByEmailIdStddevPopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByEmailIdStddevPopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByEmailIdStddevPopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByEmailIdStddevPopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByEmailIdStddevPopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByEmailIdStddevPopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByEmailIdStddevPopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByEmailIdStddevPopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByEmailIdStddevPopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByEmailIdStddevPopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByEmailIdStddevPopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByEmailIdStddevPopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByEmailIdStddevPopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByEmailIdStddevPopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdStddevPopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdStddevPopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByEmailIdStddevPopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByEmailIdStddevPopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByEmailIdStddevPopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByEmailIdStddevPopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_SENT_TIME_ASC',
  EmailMessagesByEmailIdStddevPopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_SENT_TIME_DESC',
  EmailMessagesByEmailIdStddevPopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdStddevPopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdStddevPopulationSubjectAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_SUBJECT_ASC',
  EmailMessagesByEmailIdStddevPopulationSubjectDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_SUBJECT_DESC',
  EmailMessagesByEmailIdStddevPopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByEmailIdStddevPopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByEmailIdStddevPopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByEmailIdStddevPopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByEmailIdStddevSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByEmailIdStddevSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByEmailIdStddevSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByEmailIdStddevSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByEmailIdStddevSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByEmailIdStddevSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByEmailIdStddevSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdStddevSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdStddevSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByEmailIdStddevSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByEmailIdStddevSampleContactIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByEmailIdStddevSampleContactIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByEmailIdStddevSampleContentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CONTENT_ASC',
  EmailMessagesByEmailIdStddevSampleContentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CONTENT_DESC',
  EmailMessagesByEmailIdStddevSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByEmailIdStddevSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByEmailIdStddevSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByEmailIdStddevSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByEmailIdStddevSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByEmailIdStddevSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByEmailIdStddevSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByEmailIdStddevSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByEmailIdStddevSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdStddevSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdStddevSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByEmailIdStddevSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByEmailIdStddevSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdStddevSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdStddevSampleIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_ID_ASC',
  EmailMessagesByEmailIdStddevSampleIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_ID_DESC',
  EmailMessagesByEmailIdStddevSampleIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_INTENT_ASC',
  EmailMessagesByEmailIdStddevSampleIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_INTENT_DESC',
  EmailMessagesByEmailIdStddevSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByEmailIdStddevSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByEmailIdStddevSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByEmailIdStddevSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByEmailIdStddevSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByEmailIdStddevSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByEmailIdStddevSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByEmailIdStddevSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByEmailIdStddevSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByEmailIdStddevSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByEmailIdStddevSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByEmailIdStddevSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByEmailIdStddevSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByEmailIdStddevSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByEmailIdStddevSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByEmailIdStddevSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByEmailIdStddevSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByEmailIdStddevSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByEmailIdStddevSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdStddevSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdStddevSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByEmailIdStddevSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByEmailIdStddevSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByEmailIdStddevSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByEmailIdStddevSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByEmailIdStddevSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByEmailIdStddevSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdStddevSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdStddevSampleSubjectAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_SUBJECT_ASC',
  EmailMessagesByEmailIdStddevSampleSubjectDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_SUBJECT_DESC',
  EmailMessagesByEmailIdStddevSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByEmailIdStddevSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByEmailIdStddevSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByEmailIdStddevSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailMessagesByEmailIdSumAutoRepliedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_AUTO_REPLIED_ASC',
  EmailMessagesByEmailIdSumAutoRepliedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_AUTO_REPLIED_DESC',
  EmailMessagesByEmailIdSumCampaignIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CAMPAIGN_ID_ASC',
  EmailMessagesByEmailIdSumCampaignIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CAMPAIGN_ID_DESC',
  EmailMessagesByEmailIdSumCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByEmailIdSumCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByEmailIdSumCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdSumCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdSumCompanyIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_COMPANY_ID_ASC',
  EmailMessagesByEmailIdSumCompanyIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_COMPANY_ID_DESC',
  EmailMessagesByEmailIdSumContactIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CONTACT_ID_ASC',
  EmailMessagesByEmailIdSumContactIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CONTACT_ID_DESC',
  EmailMessagesByEmailIdSumContentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CONTENT_ASC',
  EmailMessagesByEmailIdSumContentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CONTENT_DESC',
  EmailMessagesByEmailIdSumContentHtmlAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CONTENT_HTML_ASC',
  EmailMessagesByEmailIdSumContentHtmlDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CONTENT_HTML_DESC',
  EmailMessagesByEmailIdSumCreatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CREATED_TIME_ASC',
  EmailMessagesByEmailIdSumCreatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_CREATED_TIME_DESC',
  EmailMessagesByEmailIdSumEmailIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_EMAIL_ID_ASC',
  EmailMessagesByEmailIdSumEmailIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_EMAIL_ID_DESC',
  EmailMessagesByEmailIdSumForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByEmailIdSumForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByEmailIdSumForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdSumForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdSumGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_GENERATED_REPLY_ASC',
  EmailMessagesByEmailIdSumGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_GENERATED_REPLY_DESC',
  EmailMessagesByEmailIdSumHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdSumHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdSumIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_ID_ASC',
  EmailMessagesByEmailIdSumIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_ID_DESC',
  EmailMessagesByEmailIdSumIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_INTENT_ASC',
  EmailMessagesByEmailIdSumIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_INTENT_DESC',
  EmailMessagesByEmailIdSumIsLawyerAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_IS_LAWYER_ASC',
  EmailMessagesByEmailIdSumIsLawyerDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_IS_LAWYER_DESC',
  EmailMessagesByEmailIdSumIsReviewedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_IS_REVIEWED_ASC',
  EmailMessagesByEmailIdSumIsReviewedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_IS_REVIEWED_DESC',
  EmailMessagesByEmailIdSumLawyerIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_LAWYER_ID_ASC',
  EmailMessagesByEmailIdSumLawyerIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_LAWYER_ID_DESC',
  EmailMessagesByEmailIdSumLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByEmailIdSumLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByEmailIdSumNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_NYLAS_THREAD_ID_ASC',
  EmailMessagesByEmailIdSumNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_NYLAS_THREAD_ID_DESC',
  EmailMessagesByEmailIdSumOrderIndexAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_ORDER_INDEX_ASC',
  EmailMessagesByEmailIdSumOrderIndexDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_ORDER_INDEX_DESC',
  EmailMessagesByEmailIdSumPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByEmailIdSumPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByEmailIdSumRepliedIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_REPLIED_INTENT_ASC',
  EmailMessagesByEmailIdSumRepliedIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_REPLIED_INTENT_DESC',
  EmailMessagesByEmailIdSumRepliedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_REPLIED_TIME_ASC',
  EmailMessagesByEmailIdSumRepliedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_REPLIED_TIME_DESC',
  EmailMessagesByEmailIdSumRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdSumRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdSumReviewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_REVIEWED_TIME_ASC',
  EmailMessagesByEmailIdSumReviewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_REVIEWED_TIME_DESC',
  EmailMessagesByEmailIdSumSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_SENDER_PROFILE_ID_ASC',
  EmailMessagesByEmailIdSumSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_SENDER_PROFILE_ID_DESC',
  EmailMessagesByEmailIdSumSentTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_SENT_TIME_ASC',
  EmailMessagesByEmailIdSumSentTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_SENT_TIME_DESC',
  EmailMessagesByEmailIdSumSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdSumSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdSumSubjectAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_SUBJECT_ASC',
  EmailMessagesByEmailIdSumSubjectDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_SUBJECT_DESC',
  EmailMessagesByEmailIdSumTemplateIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_TEMPLATE_ID_ASC',
  EmailMessagesByEmailIdSumTemplateIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_TEMPLATE_ID_DESC',
  EmailMessagesByEmailIdSumUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_UPDATED_TIME_ASC',
  EmailMessagesByEmailIdSumUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_SUM_UPDATED_TIME_DESC',
  EmailMessagesByEmailIdVariancePopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByEmailIdVariancePopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByEmailIdVariancePopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByEmailIdVariancePopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByEmailIdVariancePopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByEmailIdVariancePopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByEmailIdVariancePopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdVariancePopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdVariancePopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByEmailIdVariancePopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByEmailIdVariancePopulationContactIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByEmailIdVariancePopulationContactIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByEmailIdVariancePopulationContentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CONTENT_ASC',
  EmailMessagesByEmailIdVariancePopulationContentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CONTENT_DESC',
  EmailMessagesByEmailIdVariancePopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByEmailIdVariancePopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByEmailIdVariancePopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByEmailIdVariancePopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByEmailIdVariancePopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByEmailIdVariancePopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByEmailIdVariancePopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByEmailIdVariancePopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByEmailIdVariancePopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdVariancePopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdVariancePopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByEmailIdVariancePopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByEmailIdVariancePopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdVariancePopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdVariancePopulationIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_ID_ASC',
  EmailMessagesByEmailIdVariancePopulationIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_ID_DESC',
  EmailMessagesByEmailIdVariancePopulationIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_INTENT_ASC',
  EmailMessagesByEmailIdVariancePopulationIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_INTENT_DESC',
  EmailMessagesByEmailIdVariancePopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByEmailIdVariancePopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByEmailIdVariancePopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByEmailIdVariancePopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByEmailIdVariancePopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByEmailIdVariancePopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByEmailIdVariancePopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByEmailIdVariancePopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByEmailIdVariancePopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByEmailIdVariancePopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByEmailIdVariancePopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByEmailIdVariancePopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByEmailIdVariancePopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByEmailIdVariancePopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByEmailIdVariancePopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByEmailIdVariancePopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByEmailIdVariancePopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByEmailIdVariancePopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByEmailIdVariancePopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdVariancePopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdVariancePopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByEmailIdVariancePopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByEmailIdVariancePopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByEmailIdVariancePopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByEmailIdVariancePopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_SENT_TIME_ASC',
  EmailMessagesByEmailIdVariancePopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_SENT_TIME_DESC',
  EmailMessagesByEmailIdVariancePopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdVariancePopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdVariancePopulationSubjectAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_SUBJECT_ASC',
  EmailMessagesByEmailIdVariancePopulationSubjectDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_SUBJECT_DESC',
  EmailMessagesByEmailIdVariancePopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByEmailIdVariancePopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByEmailIdVariancePopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByEmailIdVariancePopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByEmailIdVarianceSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByEmailIdVarianceSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByEmailIdVarianceSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByEmailIdVarianceSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByEmailIdVarianceSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByEmailIdVarianceSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByEmailIdVarianceSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdVarianceSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdVarianceSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByEmailIdVarianceSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByEmailIdVarianceSampleContactIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByEmailIdVarianceSampleContactIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByEmailIdVarianceSampleContentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  EmailMessagesByEmailIdVarianceSampleContentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  EmailMessagesByEmailIdVarianceSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByEmailIdVarianceSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByEmailIdVarianceSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByEmailIdVarianceSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByEmailIdVarianceSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByEmailIdVarianceSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByEmailIdVarianceSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByEmailIdVarianceSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByEmailIdVarianceSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdVarianceSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdVarianceSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByEmailIdVarianceSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByEmailIdVarianceSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdVarianceSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdVarianceSampleIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailMessagesByEmailIdVarianceSampleIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailMessagesByEmailIdVarianceSampleIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_INTENT_ASC',
  EmailMessagesByEmailIdVarianceSampleIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_INTENT_DESC',
  EmailMessagesByEmailIdVarianceSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByEmailIdVarianceSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByEmailIdVarianceSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByEmailIdVarianceSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByEmailIdVarianceSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByEmailIdVarianceSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByEmailIdVarianceSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByEmailIdVarianceSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByEmailIdVarianceSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByEmailIdVarianceSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByEmailIdVarianceSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByEmailIdVarianceSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByEmailIdVarianceSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByEmailIdVarianceSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByEmailIdVarianceSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByEmailIdVarianceSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByEmailIdVarianceSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByEmailIdVarianceSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByEmailIdVarianceSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByEmailIdVarianceSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByEmailIdVarianceSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByEmailIdVarianceSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByEmailIdVarianceSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByEmailIdVarianceSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByEmailIdVarianceSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByEmailIdVarianceSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByEmailIdVarianceSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByEmailIdVarianceSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByEmailIdVarianceSampleSubjectAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_SUBJECT_ASC',
  EmailMessagesByEmailIdVarianceSampleSubjectDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_SUBJECT_DESC',
  EmailMessagesByEmailIdVarianceSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByEmailIdVarianceSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByEmailIdVarianceSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByEmailIdVarianceSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_EMAIL_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EmailPersonalisationsByEmailIdAverageEmailIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_AVERAGE_EMAIL_ID_ASC',
  EmailPersonalisationsByEmailIdAverageEmailIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_AVERAGE_EMAIL_ID_DESC',
  EmailPersonalisationsByEmailIdAverageFlagAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_AVERAGE_FLAG_ASC',
  EmailPersonalisationsByEmailIdAverageFlagDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_AVERAGE_FLAG_DESC',
  EmailPersonalisationsByEmailIdAverageIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_AVERAGE_ID_ASC',
  EmailPersonalisationsByEmailIdAverageIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_AVERAGE_ID_DESC',
  EmailPersonalisationsByEmailIdAverageValueAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_AVERAGE_VALUE_ASC',
  EmailPersonalisationsByEmailIdAverageValueDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_AVERAGE_VALUE_DESC',
  EmailPersonalisationsByEmailIdCountAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_COUNT_ASC',
  EmailPersonalisationsByEmailIdCountDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_COUNT_DESC',
  EmailPersonalisationsByEmailIdDistinctCountEmailIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailPersonalisationsByEmailIdDistinctCountEmailIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailPersonalisationsByEmailIdDistinctCountFlagAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_DISTINCT_COUNT_FLAG_ASC',
  EmailPersonalisationsByEmailIdDistinctCountFlagDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_DISTINCT_COUNT_FLAG_DESC',
  EmailPersonalisationsByEmailIdDistinctCountIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_DISTINCT_COUNT_ID_ASC',
  EmailPersonalisationsByEmailIdDistinctCountIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_DISTINCT_COUNT_ID_DESC',
  EmailPersonalisationsByEmailIdDistinctCountValueAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_DISTINCT_COUNT_VALUE_ASC',
  EmailPersonalisationsByEmailIdDistinctCountValueDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_DISTINCT_COUNT_VALUE_DESC',
  EmailPersonalisationsByEmailIdMaxEmailIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MAX_EMAIL_ID_ASC',
  EmailPersonalisationsByEmailIdMaxEmailIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MAX_EMAIL_ID_DESC',
  EmailPersonalisationsByEmailIdMaxFlagAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MAX_FLAG_ASC',
  EmailPersonalisationsByEmailIdMaxFlagDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MAX_FLAG_DESC',
  EmailPersonalisationsByEmailIdMaxIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MAX_ID_ASC',
  EmailPersonalisationsByEmailIdMaxIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MAX_ID_DESC',
  EmailPersonalisationsByEmailIdMaxValueAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MAX_VALUE_ASC',
  EmailPersonalisationsByEmailIdMaxValueDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MAX_VALUE_DESC',
  EmailPersonalisationsByEmailIdMinEmailIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MIN_EMAIL_ID_ASC',
  EmailPersonalisationsByEmailIdMinEmailIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MIN_EMAIL_ID_DESC',
  EmailPersonalisationsByEmailIdMinFlagAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MIN_FLAG_ASC',
  EmailPersonalisationsByEmailIdMinFlagDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MIN_FLAG_DESC',
  EmailPersonalisationsByEmailIdMinIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MIN_ID_ASC',
  EmailPersonalisationsByEmailIdMinIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MIN_ID_DESC',
  EmailPersonalisationsByEmailIdMinValueAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MIN_VALUE_ASC',
  EmailPersonalisationsByEmailIdMinValueDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_MIN_VALUE_DESC',
  EmailPersonalisationsByEmailIdStddevPopulationEmailIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailPersonalisationsByEmailIdStddevPopulationEmailIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailPersonalisationsByEmailIdStddevPopulationFlagAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_POPULATION_FLAG_ASC',
  EmailPersonalisationsByEmailIdStddevPopulationFlagDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_POPULATION_FLAG_DESC',
  EmailPersonalisationsByEmailIdStddevPopulationIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_POPULATION_ID_ASC',
  EmailPersonalisationsByEmailIdStddevPopulationIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_POPULATION_ID_DESC',
  EmailPersonalisationsByEmailIdStddevPopulationValueAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_POPULATION_VALUE_ASC',
  EmailPersonalisationsByEmailIdStddevPopulationValueDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_POPULATION_VALUE_DESC',
  EmailPersonalisationsByEmailIdStddevSampleEmailIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailPersonalisationsByEmailIdStddevSampleEmailIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailPersonalisationsByEmailIdStddevSampleFlagAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_FLAG_ASC',
  EmailPersonalisationsByEmailIdStddevSampleFlagDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_FLAG_DESC',
  EmailPersonalisationsByEmailIdStddevSampleIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_ID_ASC',
  EmailPersonalisationsByEmailIdStddevSampleIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_ID_DESC',
  EmailPersonalisationsByEmailIdStddevSampleValueAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_VALUE_ASC',
  EmailPersonalisationsByEmailIdStddevSampleValueDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_VALUE_DESC',
  EmailPersonalisationsByEmailIdSumEmailIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_SUM_EMAIL_ID_ASC',
  EmailPersonalisationsByEmailIdSumEmailIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_SUM_EMAIL_ID_DESC',
  EmailPersonalisationsByEmailIdSumFlagAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_SUM_FLAG_ASC',
  EmailPersonalisationsByEmailIdSumFlagDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_SUM_FLAG_DESC',
  EmailPersonalisationsByEmailIdSumIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_SUM_ID_ASC',
  EmailPersonalisationsByEmailIdSumIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_SUM_ID_DESC',
  EmailPersonalisationsByEmailIdSumValueAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_SUM_VALUE_ASC',
  EmailPersonalisationsByEmailIdSumValueDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_SUM_VALUE_DESC',
  EmailPersonalisationsByEmailIdVariancePopulationEmailIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailPersonalisationsByEmailIdVariancePopulationEmailIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailPersonalisationsByEmailIdVariancePopulationFlagAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_FLAG_ASC',
  EmailPersonalisationsByEmailIdVariancePopulationFlagDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_FLAG_DESC',
  EmailPersonalisationsByEmailIdVariancePopulationIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_ID_ASC',
  EmailPersonalisationsByEmailIdVariancePopulationIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_ID_DESC',
  EmailPersonalisationsByEmailIdVariancePopulationValueAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_VALUE_ASC',
  EmailPersonalisationsByEmailIdVariancePopulationValueDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_VALUE_DESC',
  EmailPersonalisationsByEmailIdVarianceSampleEmailIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailPersonalisationsByEmailIdVarianceSampleEmailIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailPersonalisationsByEmailIdVarianceSampleFlagAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_FLAG_ASC',
  EmailPersonalisationsByEmailIdVarianceSampleFlagDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_FLAG_DESC',
  EmailPersonalisationsByEmailIdVarianceSampleIdAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailPersonalisationsByEmailIdVarianceSampleIdDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailPersonalisationsByEmailIdVarianceSampleValueAsc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_VALUE_ASC',
  EmailPersonalisationsByEmailIdVarianceSampleValueDesc = 'EMAIL_PERSONALISATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_VALUE_DESC',
  EmailTagsByEmailIdAverageEmailIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_AVERAGE_EMAIL_ID_ASC',
  EmailTagsByEmailIdAverageEmailIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_AVERAGE_EMAIL_ID_DESC',
  EmailTagsByEmailIdAverageTagIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_AVERAGE_TAG_ID_ASC',
  EmailTagsByEmailIdAverageTagIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_AVERAGE_TAG_ID_DESC',
  EmailTagsByEmailIdCountAsc = 'EMAIL_TAGS_BY_EMAIL_ID_COUNT_ASC',
  EmailTagsByEmailIdCountDesc = 'EMAIL_TAGS_BY_EMAIL_ID_COUNT_DESC',
  EmailTagsByEmailIdDistinctCountEmailIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailTagsByEmailIdDistinctCountEmailIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailTagsByEmailIdDistinctCountTagIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_DISTINCT_COUNT_TAG_ID_ASC',
  EmailTagsByEmailIdDistinctCountTagIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_DISTINCT_COUNT_TAG_ID_DESC',
  EmailTagsByEmailIdMaxEmailIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_MAX_EMAIL_ID_ASC',
  EmailTagsByEmailIdMaxEmailIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_MAX_EMAIL_ID_DESC',
  EmailTagsByEmailIdMaxTagIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_MAX_TAG_ID_ASC',
  EmailTagsByEmailIdMaxTagIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_MAX_TAG_ID_DESC',
  EmailTagsByEmailIdMinEmailIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_MIN_EMAIL_ID_ASC',
  EmailTagsByEmailIdMinEmailIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_MIN_EMAIL_ID_DESC',
  EmailTagsByEmailIdMinTagIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_MIN_TAG_ID_ASC',
  EmailTagsByEmailIdMinTagIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_MIN_TAG_ID_DESC',
  EmailTagsByEmailIdStddevPopulationEmailIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailTagsByEmailIdStddevPopulationEmailIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailTagsByEmailIdStddevPopulationTagIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_STDDEV_POPULATION_TAG_ID_ASC',
  EmailTagsByEmailIdStddevPopulationTagIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_STDDEV_POPULATION_TAG_ID_DESC',
  EmailTagsByEmailIdStddevSampleEmailIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailTagsByEmailIdStddevSampleEmailIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailTagsByEmailIdStddevSampleTagIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_STDDEV_SAMPLE_TAG_ID_ASC',
  EmailTagsByEmailIdStddevSampleTagIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_STDDEV_SAMPLE_TAG_ID_DESC',
  EmailTagsByEmailIdSumEmailIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_SUM_EMAIL_ID_ASC',
  EmailTagsByEmailIdSumEmailIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_SUM_EMAIL_ID_DESC',
  EmailTagsByEmailIdSumTagIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_SUM_TAG_ID_ASC',
  EmailTagsByEmailIdSumTagIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_SUM_TAG_ID_DESC',
  EmailTagsByEmailIdVariancePopulationEmailIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailTagsByEmailIdVariancePopulationEmailIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailTagsByEmailIdVariancePopulationTagIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_VARIANCE_POPULATION_TAG_ID_ASC',
  EmailTagsByEmailIdVariancePopulationTagIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_VARIANCE_POPULATION_TAG_ID_DESC',
  EmailTagsByEmailIdVarianceSampleEmailIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailTagsByEmailIdVarianceSampleEmailIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailTagsByEmailIdVarianceSampleTagIdAsc = 'EMAIL_TAGS_BY_EMAIL_ID_VARIANCE_SAMPLE_TAG_ID_ASC',
  EmailTagsByEmailIdVarianceSampleTagIdDesc = 'EMAIL_TAGS_BY_EMAIL_ID_VARIANCE_SAMPLE_TAG_ID_DESC',
  EmailVariationsByEmailIdAverageCreatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_AVERAGE_CREATED_TIME_ASC',
  EmailVariationsByEmailIdAverageCreatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_AVERAGE_CREATED_TIME_DESC',
  EmailVariationsByEmailIdAverageEmailIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_AVERAGE_EMAIL_ID_ASC',
  EmailVariationsByEmailIdAverageEmailIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_AVERAGE_EMAIL_ID_DESC',
  EmailVariationsByEmailIdAverageIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_AVERAGE_ID_ASC',
  EmailVariationsByEmailIdAverageIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_AVERAGE_ID_DESC',
  EmailVariationsByEmailIdAverageUpdatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailVariationsByEmailIdAverageUpdatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailVariationsByEmailIdAverageVariationIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_AVERAGE_VARIATION_ID_ASC',
  EmailVariationsByEmailIdAverageVariationIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_AVERAGE_VARIATION_ID_DESC',
  EmailVariationsByEmailIdCountAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_COUNT_ASC',
  EmailVariationsByEmailIdCountDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_COUNT_DESC',
  EmailVariationsByEmailIdDistinctCountCreatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailVariationsByEmailIdDistinctCountCreatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailVariationsByEmailIdDistinctCountEmailIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailVariationsByEmailIdDistinctCountEmailIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailVariationsByEmailIdDistinctCountIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_DISTINCT_COUNT_ID_ASC',
  EmailVariationsByEmailIdDistinctCountIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_DISTINCT_COUNT_ID_DESC',
  EmailVariationsByEmailIdDistinctCountUpdatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailVariationsByEmailIdDistinctCountUpdatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailVariationsByEmailIdDistinctCountVariationIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_DISTINCT_COUNT_VARIATION_ID_ASC',
  EmailVariationsByEmailIdDistinctCountVariationIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_DISTINCT_COUNT_VARIATION_ID_DESC',
  EmailVariationsByEmailIdMaxCreatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MAX_CREATED_TIME_ASC',
  EmailVariationsByEmailIdMaxCreatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MAX_CREATED_TIME_DESC',
  EmailVariationsByEmailIdMaxEmailIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MAX_EMAIL_ID_ASC',
  EmailVariationsByEmailIdMaxEmailIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MAX_EMAIL_ID_DESC',
  EmailVariationsByEmailIdMaxIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MAX_ID_ASC',
  EmailVariationsByEmailIdMaxIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MAX_ID_DESC',
  EmailVariationsByEmailIdMaxUpdatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MAX_UPDATED_TIME_ASC',
  EmailVariationsByEmailIdMaxUpdatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MAX_UPDATED_TIME_DESC',
  EmailVariationsByEmailIdMaxVariationIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MAX_VARIATION_ID_ASC',
  EmailVariationsByEmailIdMaxVariationIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MAX_VARIATION_ID_DESC',
  EmailVariationsByEmailIdMinCreatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MIN_CREATED_TIME_ASC',
  EmailVariationsByEmailIdMinCreatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MIN_CREATED_TIME_DESC',
  EmailVariationsByEmailIdMinEmailIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MIN_EMAIL_ID_ASC',
  EmailVariationsByEmailIdMinEmailIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MIN_EMAIL_ID_DESC',
  EmailVariationsByEmailIdMinIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MIN_ID_ASC',
  EmailVariationsByEmailIdMinIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MIN_ID_DESC',
  EmailVariationsByEmailIdMinUpdatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MIN_UPDATED_TIME_ASC',
  EmailVariationsByEmailIdMinUpdatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MIN_UPDATED_TIME_DESC',
  EmailVariationsByEmailIdMinVariationIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MIN_VARIATION_ID_ASC',
  EmailVariationsByEmailIdMinVariationIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_MIN_VARIATION_ID_DESC',
  EmailVariationsByEmailIdStddevPopulationCreatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailVariationsByEmailIdStddevPopulationCreatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailVariationsByEmailIdStddevPopulationEmailIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailVariationsByEmailIdStddevPopulationEmailIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailVariationsByEmailIdStddevPopulationIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_POPULATION_ID_ASC',
  EmailVariationsByEmailIdStddevPopulationIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_POPULATION_ID_DESC',
  EmailVariationsByEmailIdStddevPopulationUpdatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailVariationsByEmailIdStddevPopulationUpdatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailVariationsByEmailIdStddevPopulationVariationIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_POPULATION_VARIATION_ID_ASC',
  EmailVariationsByEmailIdStddevPopulationVariationIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_POPULATION_VARIATION_ID_DESC',
  EmailVariationsByEmailIdStddevSampleCreatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailVariationsByEmailIdStddevSampleCreatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailVariationsByEmailIdStddevSampleEmailIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailVariationsByEmailIdStddevSampleEmailIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailVariationsByEmailIdStddevSampleIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_ID_ASC',
  EmailVariationsByEmailIdStddevSampleIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_ID_DESC',
  EmailVariationsByEmailIdStddevSampleUpdatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailVariationsByEmailIdStddevSampleUpdatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailVariationsByEmailIdStddevSampleVariationIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_VARIATION_ID_ASC',
  EmailVariationsByEmailIdStddevSampleVariationIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_STDDEV_SAMPLE_VARIATION_ID_DESC',
  EmailVariationsByEmailIdSumCreatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_SUM_CREATED_TIME_ASC',
  EmailVariationsByEmailIdSumCreatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_SUM_CREATED_TIME_DESC',
  EmailVariationsByEmailIdSumEmailIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_SUM_EMAIL_ID_ASC',
  EmailVariationsByEmailIdSumEmailIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_SUM_EMAIL_ID_DESC',
  EmailVariationsByEmailIdSumIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_SUM_ID_ASC',
  EmailVariationsByEmailIdSumIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_SUM_ID_DESC',
  EmailVariationsByEmailIdSumUpdatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_SUM_UPDATED_TIME_ASC',
  EmailVariationsByEmailIdSumUpdatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_SUM_UPDATED_TIME_DESC',
  EmailVariationsByEmailIdSumVariationIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_SUM_VARIATION_ID_ASC',
  EmailVariationsByEmailIdSumVariationIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_SUM_VARIATION_ID_DESC',
  EmailVariationsByEmailIdVariancePopulationCreatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailVariationsByEmailIdVariancePopulationCreatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailVariationsByEmailIdVariancePopulationEmailIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailVariationsByEmailIdVariancePopulationEmailIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailVariationsByEmailIdVariancePopulationIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_ID_ASC',
  EmailVariationsByEmailIdVariancePopulationIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_ID_DESC',
  EmailVariationsByEmailIdVariancePopulationUpdatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailVariationsByEmailIdVariancePopulationUpdatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailVariationsByEmailIdVariancePopulationVariationIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_VARIATION_ID_ASC',
  EmailVariationsByEmailIdVariancePopulationVariationIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_POPULATION_VARIATION_ID_DESC',
  EmailVariationsByEmailIdVarianceSampleCreatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailVariationsByEmailIdVarianceSampleCreatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailVariationsByEmailIdVarianceSampleEmailIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailVariationsByEmailIdVarianceSampleEmailIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailVariationsByEmailIdVarianceSampleIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailVariationsByEmailIdVarianceSampleIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailVariationsByEmailIdVarianceSampleUpdatedTimeAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailVariationsByEmailIdVarianceSampleUpdatedTimeDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EmailVariationsByEmailIdVarianceSampleVariationIdAsc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_VARIATION_ID_ASC',
  EmailVariationsByEmailIdVarianceSampleVariationIdDesc = 'EMAIL_VARIATIONS_BY_EMAIL_ID_VARIANCE_SAMPLE_VARIATION_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SenderProfileIdAsc = 'SENDER_PROFILE_ID_ASC',
  SenderProfileIdDesc = 'SENDER_PROFILE_ID_DESC',
  SendAtAsc = 'SEND_AT_ASC',
  SendAtDesc = 'SEND_AT_DESC',
  SentAtAsc = 'SENT_AT_ASC',
  SentAtDesc = 'SENT_AT_DESC',
  SmtpMessageIdAsc = 'SMTP_MESSAGE_ID_ASC',
  SmtpMessageIdDesc = 'SMTP_MESSAGE_ID_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TemplateContentAsc = 'TEMPLATE_CONTENT_ASC',
  TemplateContentDesc = 'TEMPLATE_CONTENT_DESC',
  TemplateIdAsc = 'TEMPLATE_ID_ASC',
  TemplateIdDesc = 'TEMPLATE_ID_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Float']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Float']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Float']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Float']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Float']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Float']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Float']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Float']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

/** All input for the `fromEmailToDomainRev` mutation. */
export type FromEmailToDomainRevInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `fromEmailToDomainRev` mutation. */
export type FromEmailToDomainRevPayload = {
  __typename?: 'FromEmailToDomainRevPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']['output']>;
};

export enum HandoverMethod {
  Cc = 'CC',
  Forward = 'FORWARD'
}

/** A filter to be used against HandoverMethod fields. All fields are combined with a logical ‘and.’ */
export type HandoverMethodFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<HandoverMethod>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<HandoverMethod>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<HandoverMethod>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<HandoverMethod>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<HandoverMethod>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<HandoverMethod>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<HandoverMethod>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<HandoverMethod>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<HandoverMethod>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<HandoverMethod>>;
};

export enum HandoverState {
  Any = 'ANY',
  MeetingBooked = 'MEETING_BOOKED',
  ReactOut = 'REACT_OUT_',
  Yes = 'YES_'
}

/** A filter to be used against HandoverState fields. All fields are combined with a logical ‘and.’ */
export type HandoverStateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<HandoverState>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<HandoverState>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<HandoverState>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<HandoverState>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<HandoverState>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<HandoverState>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<HandoverState>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<HandoverState>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<HandoverState>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<HandoverState>>;
};

export type HavingBigintFilter = {
  equalTo?: InputMaybe<Scalars['BigInt']['input']>;
  greaterThan?: InputMaybe<Scalars['BigInt']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  lessThan?: InputMaybe<Scalars['BigInt']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  notEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
};

export type HavingDatetimeFilter = {
  equalTo?: InputMaybe<Scalars['Datetime']['input']>;
  greaterThan?: InputMaybe<Scalars['Datetime']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  lessThan?: InputMaybe<Scalars['Datetime']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  notEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
};

export type HavingFloatFilter = {
  equalTo?: InputMaybe<Scalars['Float']['input']>;
  greaterThan?: InputMaybe<Scalars['Float']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Float']['input']>;
  lessThan?: InputMaybe<Scalars['Float']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Float']['input']>;
  notEqualTo?: InputMaybe<Scalars['Float']['input']>;
};

export type HavingIntFilter = {
  equalTo?: InputMaybe<Scalars['Int']['input']>;
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  notEqualTo?: InputMaybe<Scalars['Int']['input']>;
};

/** A connection to a list of `HedwigIdentity` values. */
export type HedwigIdentitiesConnection = {
  __typename?: 'HedwigIdentitiesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<HedwigIdentityAggregates>;
  /** A list of edges which contains the `HedwigIdentity` and cursor to aid in pagination. */
  edges: Array<HedwigIdentitiesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<HedwigIdentityAggregates>>;
  /** A list of `HedwigIdentity` objects. */
  nodes: Array<Maybe<HedwigIdentity>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `HedwigIdentity` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `HedwigIdentity` values. */
export type HedwigIdentitiesConnectionGroupedAggregatesArgs = {
  groupBy: Array<HedwigIdentityGroupBy>;
  having?: InputMaybe<HedwigIdentityHavingInput>;
};

/** A `HedwigIdentity` edge in the connection. */
export type HedwigIdentitiesEdge = {
  __typename?: 'HedwigIdentitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `HedwigIdentity` at the end of the edge. */
  node?: Maybe<HedwigIdentity>;
};

/** Methods to use when ordering `HedwigIdentity`. */
export enum HedwigIdentitiesOrderBy {
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  HedwigMessagePayloadIdAsc = 'HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigMessagePayloadIdDesc = 'HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  IdentityTypeAsc = 'IDENTITY_TYPE_ASC',
  IdentityTypeDesc = 'IDENTITY_TYPE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

export type HedwigIdentity = Node & {
  __typename?: 'HedwigIdentity';
  createdTime: Scalars['Datetime']['output'];
  email: Scalars['String']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `HedwigMessagePayload` that is related to this `HedwigIdentity`. */
  hedwigMessagePayloadByHedwigMessagePayloadId?: Maybe<HedwigMessagePayload>;
  hedwigMessagePayloadId: Scalars['UUID']['output'];
  id: Scalars['UUID']['output'];
  identityType: Hedwigidentitytype;
  name?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  updatedTime: Scalars['Datetime']['output'];
};

export type HedwigIdentityAggregates = {
  __typename?: 'HedwigIdentityAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<HedwigIdentityDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `HedwigIdentity` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type HedwigIdentityCondition = {
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `hedwigMessagePayloadId` field. */
  hedwigMessagePayloadId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `identityType` field. */
  identityType?: InputMaybe<Hedwigidentitytype>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type HedwigIdentityDistinctCountAggregates = {
  __typename?: 'HedwigIdentityDistinctCountAggregates';
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of hedwigMessagePayloadId across the matching connection */
  hedwigMessagePayloadId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of identityType across the matching connection */
  identityType?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `HedwigIdentity` object types. All fields are combined with a logical ‘and.’ */
export type HedwigIdentityFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<HedwigIdentityFilter>>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hedwigMessagePayloadId` field. */
  hedwigMessagePayloadId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `identityType` field. */
  identityType?: InputMaybe<HedwigidentitytypeFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<HedwigIdentityFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<HedwigIdentityFilter>>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `HedwigIdentity` for usage during aggregation. */
export enum HedwigIdentityGroupBy {
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  Email = 'EMAIL',
  HedwigMessagePayloadId = 'HEDWIG_MESSAGE_PAYLOAD_ID',
  IdentityType = 'IDENTITY_TYPE',
  Name = 'NAME',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type HedwigIdentityHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type HedwigIdentityHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `HedwigIdentity` aggregates. */
export type HedwigIdentityHavingInput = {
  AND?: InputMaybe<Array<HedwigIdentityHavingInput>>;
  OR?: InputMaybe<Array<HedwigIdentityHavingInput>>;
  average?: InputMaybe<HedwigIdentityHavingAverageInput>;
  distinctCount?: InputMaybe<HedwigIdentityHavingDistinctCountInput>;
  max?: InputMaybe<HedwigIdentityHavingMaxInput>;
  min?: InputMaybe<HedwigIdentityHavingMinInput>;
  stddevPopulation?: InputMaybe<HedwigIdentityHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<HedwigIdentityHavingStddevSampleInput>;
  sum?: InputMaybe<HedwigIdentityHavingSumInput>;
  variancePopulation?: InputMaybe<HedwigIdentityHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<HedwigIdentityHavingVarianceSampleInput>;
};

export type HedwigIdentityHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type HedwigIdentityHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type HedwigIdentityHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type HedwigIdentityHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type HedwigIdentityHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type HedwigIdentityHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type HedwigIdentityHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type HedwigIdentitySort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: HedwigIdentitySortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type HedwigIdentitySortBy = {
  field?: InputMaybe<HedwigIdentitySortableField>;
};

/** Sortable concrete fields for the `HedwigIdentity` type. */
export enum HedwigIdentitySortableField {
  CreatedTime = 'CREATED_TIME',
  Email = 'EMAIL',
  HedwigMessagePayloadId = 'HEDWIG_MESSAGE_PAYLOAD_ID',
  Id = 'ID',
  IdentityType = 'IDENTITY_TYPE',
  Name = 'NAME',
  UpdatedTime = 'UPDATED_TIME'
}

export type HedwigMessagePayload = Node & {
  __typename?: 'HedwigMessagePayload';
  /** Reads a single `EmailMessage` that is related to this `HedwigMessagePayload`. */
  emailMessageByEmailMessageId?: Maybe<EmailMessage>;
  emailMessageId: Scalars['UUID']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Reads and enables pagination through a set of `HedwigIdentity`. */
  hedwigIdentitiesByHedwigMessagePayloadId: HedwigIdentitiesConnection;
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};


export type HedwigMessagePayloadHedwigIdentitiesByHedwigMessagePayloadIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<HedwigIdentityCondition>;
  filter?: InputMaybe<HedwigIdentityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<HedwigIdentitiesOrderBy>>;
  sort?: InputMaybe<Array<HedwigIdentitySort>>;
};

export type HedwigMessagePayloadAggregates = {
  __typename?: 'HedwigMessagePayloadAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<HedwigMessagePayloadDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `HedwigMessagePayload` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type HedwigMessagePayloadCondition = {
  /** Checks for equality with the object’s `emailMessageId` field. */
  emailMessageId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
};

export type HedwigMessagePayloadDistinctCountAggregates = {
  __typename?: 'HedwigMessagePayloadDistinctCountAggregates';
  /** Distinct count of bodyContent across the matching connection */
  bodyContent?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of bodyContentType across the matching connection */
  bodyContentType?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of conversationId across the matching connection */
  conversationId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emailMessageId across the matching connection */
  emailMessageId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of hasAttachments across the matching connection */
  hasAttachments?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of hedwigMessageCategories across the matching connection */
  hedwigMessageCategories?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of importance across the matching connection */
  importance?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isDeliveryReceiptRequested across the matching connection */
  isDeliveryReceiptRequested?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isDraft across the matching connection */
  isDraft?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isRead across the matching connection */
  isRead?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isReadReceiptRequested across the matching connection */
  isReadReceiptRequested?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of parentFolderId across the matching connection */
  parentFolderId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of receivedTime across the matching connection */
  receivedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of sentTime across the matching connection */
  sentTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of source across the matching connection */
  source?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of subject across the matching connection */
  subject?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `HedwigMessagePayload` object types. All fields are combined with a logical ‘and.’ */
export type HedwigMessagePayloadFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<HedwigMessagePayloadFilter>>;
  /** Filter by the object’s `emailMessageId` field. */
  emailMessageId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<HedwigMessagePayloadFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<HedwigMessagePayloadFilter>>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type HedwigMessagePayloadSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: HedwigMessagePayloadSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type HedwigMessagePayloadSortBy = {
  field?: InputMaybe<HedwigMessagePayloadSortableField>;
};

/** Sortable concrete fields for the `HedwigMessagePayload` type. */
export enum HedwigMessagePayloadSortableField {
  EmailMessageId = 'EMAIL_MESSAGE_ID',
  Id = 'ID'
}

/** A connection to a list of `HedwigMessagePayload` values. */
export type HedwigMessagePayloadsConnection = {
  __typename?: 'HedwigMessagePayloadsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<HedwigMessagePayloadAggregates>;
  /** A list of edges which contains the `HedwigMessagePayload` and cursor to aid in pagination. */
  edges: Array<HedwigMessagePayloadsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** A list of `HedwigMessagePayload` objects. */
  nodes: Array<Maybe<HedwigMessagePayload>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `HedwigMessagePayload` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `HedwigMessagePayload` edge in the connection. */
export type HedwigMessagePayloadsEdge = {
  __typename?: 'HedwigMessagePayloadsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `HedwigMessagePayload` at the end of the edge. */
  node?: Maybe<HedwigMessagePayload>;
};

/** Methods to use when ordering `HedwigMessagePayload`. */
export enum HedwigMessagePayloadsOrderBy {
  EmailMessageIdAsc = 'EMAIL_MESSAGE_ID_ASC',
  EmailMessageIdDesc = 'EMAIL_MESSAGE_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageCreatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_CREATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageCreatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_CREATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageEmailAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_EMAIL_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageEmailDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_EMAIL_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageHedwigMessagePayloadIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageHedwigMessagePayloadIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageIdentityTypeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_IDENTITY_TYPE_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageIdentityTypeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_IDENTITY_TYPE_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageNameAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_NAME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageNameDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_NAME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageUpdatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_UPDATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdAverageUpdatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_UPDATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdCountAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_COUNT_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdCountDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_COUNT_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountCreatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountCreatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountEmailAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_EMAIL_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountEmailDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_EMAIL_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountHedwigMessagePayloadIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountHedwigMessagePayloadIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountIdentityTypeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_IDENTITY_TYPE_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountIdentityTypeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_IDENTITY_TYPE_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountNameAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_NAME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountNameDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_NAME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountUpdatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdDistinctCountUpdatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxCreatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_CREATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxCreatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_CREATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxEmailAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_EMAIL_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxEmailDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_EMAIL_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxHedwigMessagePayloadIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxHedwigMessagePayloadIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxIdentityTypeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_IDENTITY_TYPE_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxIdentityTypeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_IDENTITY_TYPE_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxNameAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_NAME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxNameDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_NAME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxUpdatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_UPDATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMaxUpdatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_UPDATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinCreatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_CREATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinCreatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_CREATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinEmailAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_EMAIL_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinEmailDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_EMAIL_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinHedwigMessagePayloadIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinHedwigMessagePayloadIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinIdentityTypeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_IDENTITY_TYPE_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinIdentityTypeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_IDENTITY_TYPE_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinNameAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_NAME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinNameDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_NAME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinUpdatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_UPDATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdMinUpdatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_UPDATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationCreatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationCreatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationEmailAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_EMAIL_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationEmailDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_EMAIL_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationHedwigMessagePayloadIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationHedwigMessagePayloadIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationIdentityTypeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_IDENTITY_TYPE_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationIdentityTypeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_IDENTITY_TYPE_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationNameAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_NAME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationNameDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_NAME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationUpdatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevPopulationUpdatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleCreatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleCreatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleEmailAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_EMAIL_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleEmailDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_EMAIL_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleHedwigMessagePayloadIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleHedwigMessagePayloadIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleIdentityTypeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_IDENTITY_TYPE_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleIdentityTypeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_IDENTITY_TYPE_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleNameAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_NAME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleNameDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_NAME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleUpdatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdStddevSampleUpdatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumCreatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_CREATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumCreatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_CREATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumEmailAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_EMAIL_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumEmailDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_EMAIL_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumHedwigMessagePayloadIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumHedwigMessagePayloadIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumIdentityTypeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_IDENTITY_TYPE_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumIdentityTypeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_IDENTITY_TYPE_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumNameAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_NAME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumNameDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_NAME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumUpdatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_UPDATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdSumUpdatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_UPDATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationCreatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationCreatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationEmailAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_EMAIL_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationEmailDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_EMAIL_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationHedwigMessagePayloadIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationHedwigMessagePayloadIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationIdentityTypeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_IDENTITY_TYPE_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationIdentityTypeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_IDENTITY_TYPE_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationNameAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_NAME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationNameDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_NAME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationUpdatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVariancePopulationUpdatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleCreatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleCreatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleEmailAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_EMAIL_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleEmailDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_EMAIL_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleHedwigMessagePayloadIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleHedwigMessagePayloadIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleIdentityTypeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_IDENTITY_TYPE_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleIdentityTypeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_IDENTITY_TYPE_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleIdAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_ID_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleIdDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_ID_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleNameAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_NAME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleNameDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_NAME_DESC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleUpdatedTimeAsc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  HedwigIdentitiesByHedwigMessagePayloadIdVarianceSampleUpdatedTimeDesc = 'HEDWIG_IDENTITIES_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdAverageCreatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_CREATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdAverageCreatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_CREATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdAverageHeaderTypeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_HEADER_TYPE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdAverageHeaderTypeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_HEADER_TYPE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdAverageHeaderValueAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_HEADER_VALUE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdAverageHeaderValueDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_HEADER_VALUE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdAverageHedwigMessagePayloadIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdAverageHedwigMessagePayloadIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdAverageIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdAverageIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdAverageUpdatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_UPDATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdAverageUpdatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_AVERAGE_UPDATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdCountAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_COUNT_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdCountDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_COUNT_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdDistinctCountCreatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdDistinctCountCreatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdDistinctCountHeaderTypeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_HEADER_TYPE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdDistinctCountHeaderTypeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_HEADER_TYPE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdDistinctCountHeaderValueAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_HEADER_VALUE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdDistinctCountHeaderValueDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_HEADER_VALUE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdDistinctCountHedwigMessagePayloadIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdDistinctCountHedwigMessagePayloadIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdDistinctCountIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdDistinctCountIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdDistinctCountUpdatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdDistinctCountUpdatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMaxCreatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_CREATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMaxCreatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_CREATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMaxHeaderTypeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_HEADER_TYPE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMaxHeaderTypeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_HEADER_TYPE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMaxHeaderValueAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_HEADER_VALUE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMaxHeaderValueDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_HEADER_VALUE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMaxHedwigMessagePayloadIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMaxHedwigMessagePayloadIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMaxIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMaxIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMaxUpdatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_UPDATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMaxUpdatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MAX_UPDATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMinCreatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_CREATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMinCreatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_CREATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMinHeaderTypeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_HEADER_TYPE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMinHeaderTypeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_HEADER_TYPE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMinHeaderValueAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_HEADER_VALUE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMinHeaderValueDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_HEADER_VALUE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMinHedwigMessagePayloadIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMinHedwigMessagePayloadIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMinIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMinIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMinUpdatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_UPDATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdMinUpdatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_MIN_UPDATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevPopulationCreatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevPopulationCreatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevPopulationHeaderTypeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_HEADER_TYPE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevPopulationHeaderTypeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_HEADER_TYPE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevPopulationHeaderValueAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_HEADER_VALUE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevPopulationHeaderValueDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_HEADER_VALUE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevPopulationHedwigMessagePayloadIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevPopulationHedwigMessagePayloadIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevPopulationIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevPopulationIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevPopulationUpdatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevPopulationUpdatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevSampleCreatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevSampleCreatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevSampleHeaderTypeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_HEADER_TYPE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevSampleHeaderTypeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_HEADER_TYPE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevSampleHeaderValueAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_HEADER_VALUE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevSampleHeaderValueDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_HEADER_VALUE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevSampleHedwigMessagePayloadIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevSampleHedwigMessagePayloadIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevSampleIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevSampleIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevSampleUpdatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdStddevSampleUpdatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdSumCreatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_CREATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdSumCreatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_CREATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdSumHeaderTypeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_HEADER_TYPE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdSumHeaderTypeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_HEADER_TYPE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdSumHeaderValueAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_HEADER_VALUE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdSumHeaderValueDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_HEADER_VALUE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdSumHedwigMessagePayloadIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdSumHedwigMessagePayloadIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdSumIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdSumIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdSumUpdatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_UPDATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdSumUpdatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_SUM_UPDATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVariancePopulationCreatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVariancePopulationCreatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVariancePopulationHeaderTypeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_HEADER_TYPE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVariancePopulationHeaderTypeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_HEADER_TYPE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVariancePopulationHeaderValueAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_HEADER_VALUE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVariancePopulationHeaderValueDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_HEADER_VALUE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVariancePopulationHedwigMessagePayloadIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVariancePopulationHedwigMessagePayloadIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVariancePopulationIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVariancePopulationIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVariancePopulationUpdatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVariancePopulationUpdatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVarianceSampleCreatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVarianceSampleCreatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVarianceSampleHeaderTypeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_HEADER_TYPE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVarianceSampleHeaderTypeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_HEADER_TYPE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVarianceSampleHeaderValueAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_HEADER_VALUE_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVarianceSampleHeaderValueDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_HEADER_VALUE_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVarianceSampleHedwigMessagePayloadIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_PAYLOAD_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVarianceSampleHedwigMessagePayloadIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_PAYLOAD_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVarianceSampleIdAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_ID_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVarianceSampleIdDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_ID_DESC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVarianceSampleUpdatedTimeAsc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  HedwigMessageHeadersByHedwigMessagePayloadIdVarianceSampleUpdatedTimeDesc = 'HEDWIG_MESSAGE_HEADERS_BY_HEDWIG_MESSAGE_PAYLOAD_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum Hedwigidentitytype {
  BccRecipient = 'BCC_RECIPIENT',
  CcRecipient = 'CC_RECIPIENT',
  ReplyTo = 'REPLY_TO',
  Sender = 'SENDER',
  ToRecipient = 'TO_RECIPIENT'
}

/** A filter to be used against Hedwigidentitytype fields. All fields are combined with a logical ‘and.’ */
export type HedwigidentitytypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Hedwigidentitytype>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Hedwigidentitytype>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Hedwigidentitytype>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Hedwigidentitytype>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Hedwigidentitytype>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Hedwigidentitytype>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Hedwigidentitytype>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Hedwigidentitytype>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Hedwigidentitytype>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Hedwigidentitytype>>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars['JSON']['input']>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars['JSON']['input']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['JSON']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['JSON']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['JSON']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['JSON']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['JSON']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['JSON']['input']>>;
};

export type Lawyer = Node & {
  __typename?: 'Lawyer';
  acquisitionChannel?: Maybe<Scalars['String']['output']>;
  acquisitionType: LawyerAcquisitionType;
  aliases?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `BlacklistedOutreach`. */
  blacklistedOutreachesByLawyerId: BlacklistedOutreachesConnection;
  brandColor?: Maybe<Scalars['String']['output']>;
  calendlyLink?: Maybe<Scalars['String']['output']>;
  calendlyUri?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Campaign`. */
  campaignsByLawyerId: CampaignsConnection;
  chargeRate?: Maybe<Scalars['Float']['output']>;
  chargedPer: ChargedPer;
  city?: Maybe<Scalars['String']['output']>;
  clientProfileRequirements?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Client`. */
  clientsByLawyerId: ClientsConnection;
  /** Reads a single `Contact` that is related to this `Lawyer`. */
  contactByContactId?: Maybe<Contact>;
  contactId?: Maybe<Scalars['UUID']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  createdTime?: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `CronofyAuth`. */
  cronofyAuthsByLawyerId: CronofyAuthsConnection;
  /** Reads and enables pagination through a set of `DailyStatistic`. */
  dailyStatisticsByLawyerId: DailyStatisticsConnection;
  email?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByLawyerId: EmailMessagesConnection;
  enableAutoTestimonial?: Maybe<Scalars['Boolean']['output']>;
  externalContactsLastUpdated?: Maybe<Scalars['Datetime']['output']>;
  firmDescription?: Maybe<Scalars['String']['output']>;
  firmName?: Maybe<Scalars['String']['output']>;
  freeLeads: Scalars['Int']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  googlePlaceId?: Maybe<Scalars['String']['output']>;
  handoverMethod?: Maybe<HandoverMethod>;
  handoverState?: Maybe<HandoverState>;
  hubspotDealId?: Maybe<Scalars['UUID']['output']>;
  icp?: Maybe<Scalars['String']['output']>;
  icpGuessedTime?: Maybe<Scalars['Datetime']['output']>;
  id: Scalars['UUID']['output'];
  idealNumWeeklyMeetings?: Maybe<Scalars['Int']['output']>;
  isManaged: Scalars['Boolean']['output'];
  isPaused: Scalars['Boolean']['output'];
  lastAccountReview: Scalars['Datetime']['output'];
  lastReportTime?: Maybe<Scalars['Datetime']['output']>;
  lastStatusEmailTime?: Maybe<Scalars['Datetime']['output']>;
  lawyerDescription?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `LawyerSetting`. */
  lawyerSettingsByLawyerId: LawyerSettingsConnection;
  /** Reads and enables pagination through a set of `LawyerTag`. */
  lawyerTagsByLawyerId: LawyerTagsConnection;
  leadsPerMonth?: Maybe<Scalars['Int']['output']>;
  lever: LawyerLever;
  liEmail?: Maybe<Scalars['String']['output']>;
  liEncryptedPw?: Maybe<Scalars['String']['output']>;
  liLastActiveTime?: Maybe<Scalars['Datetime']['output']>;
  liLoginStatus: LinkedinLoginStatus;
  liProfileName?: Maybe<Scalars['String']['output']>;
  liStopConnectionReqsUntil?: Maybe<Scalars['Datetime']['output']>;
  liVerificationCode?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `LinkedinScheduledMessage`. */
  linkedinScheduledMessagesByLawyerId: LinkedinScheduledMessagesConnection;
  linkedinUrl?: Maybe<Scalars['String']['output']>;
  logoSrc?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Meeting`. */
  meetingsByLawyerId: MeetingsConnection;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  nylasAccessToken?: Maybe<Scalars['String']['output']>;
  onboardingFeePaidTime?: Maybe<Scalars['Datetime']['output']>;
  onboardingType: LawyerOnboardingType;
  /** Reads a single `Organization` that is related to this `Lawyer`. */
  organizationByOrganizationId?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['UUID']['output']>;
  paymentMethod: PaymentMethod;
  personalisationRankings?: Maybe<Scalars['JSON']['output']>;
  /** Reads and enables pagination through a set of `PersonalisationStatistic`. */
  personalisationStatisticsByLawyerId: PersonalisationStatisticsConnection;
  postalCode?: Maybe<Scalars['String']['output']>;
  priority: LawyerPriority;
  /** Reads and enables pagination through a set of `Profile`. */
  profilesByLawyerId: ProfilesConnection;
  proxyId?: Maybe<Scalars['UUID']['output']>;
  publicId?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `ReferralPartner`. */
  referralPartnersByLawyerId: ReferralPartnersConnection;
  /** Reads and enables pagination through a set of `ReferralRequest`. */
  referralRequestsByLawyerId: ReferralRequestsConnection;
  replyNotes?: Maybe<Scalars['String']['output']>;
  salesFunnelSubscriberStatus: Scalars['Boolean']['output'];
  senderDisclaimer?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `SenderProfile`. */
  senderProfilesByLawyerId: SenderProfilesConnection;
  signatureHtml?: Maybe<Scalars['String']['output']>;
  slackChannelName?: Maybe<Scalars['String']['output']>;
  slackClientId?: Maybe<Scalars['String']['output']>;
  slackClientSecret?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `SocialProof`. */
  socialProofsByLawyerId: SocialProofsConnection;
  stage: LawyerStage;
  state?: Maybe<Scalars['String']['output']>;
  status: LawyerStatus;
  streetAddress?: Maybe<Scalars['String']['output']>;
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeDiscountCode?: Maybe<Scalars['String']['output']>;
  stripePriceId?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `SuggestedTargeting`. */
  suggestedTargetingsByLawyerId: SuggestedTargetingsConnection;
  /** Reads and enables pagination through a set of `Testimonial`. */
  testimonialsByLawyerId: TestimonialsConnection;
  timezone?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedTime?: Maybe<Scalars['Datetime']['output']>;
  userType: LawyerUserType;
  valueProposition?: Maybe<Scalars['String']['output']>;
  valuePropositionGuessedTime?: Maybe<Scalars['Datetime']['output']>;
  wantsToChooseDomains: Scalars['Boolean']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};


export type LawyerBlacklistedOutreachesByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BlacklistedOutreachCondition>;
  filter?: InputMaybe<BlacklistedOutreachFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlacklistedOutreachesOrderBy>>;
  sort?: InputMaybe<Array<BlacklistedOutreachSort>>;
};


export type LawyerCampaignsByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
  sort?: InputMaybe<Array<CampaignSort>>;
};


export type LawyerClientsByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ClientCondition>;
  filter?: InputMaybe<ClientFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientsOrderBy>>;
  sort?: InputMaybe<Array<ClientSort>>;
};


export type LawyerCronofyAuthsByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CronofyAuthCondition>;
  filter?: InputMaybe<CronofyAuthFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CronofyAuthsOrderBy>>;
  sort?: InputMaybe<Array<CronofyAuthSort>>;
};


export type LawyerDailyStatisticsByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DailyStatisticCondition>;
  filter?: InputMaybe<DailyStatisticFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DailyStatisticsOrderBy>>;
  sort?: InputMaybe<Array<DailyStatisticsSort>>;
};


export type LawyerEmailMessagesByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailMessageCondition>;
  filter?: InputMaybe<EmailMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailMessagesOrderBy>>;
  sort?: InputMaybe<Array<EmailMessageSort>>;
};


export type LawyerLawyerSettingsByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LawyerSettingCondition>;
  filter?: InputMaybe<LawyerSettingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LawyerSettingsOrderBy>>;
  sort?: InputMaybe<Array<LawyerSettingSort>>;
};


export type LawyerLawyerTagsByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LawyerTagCondition>;
  filter?: InputMaybe<LawyerTagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LawyerTagsOrderBy>>;
  sort?: InputMaybe<Array<LawyerTagSort>>;
};


export type LawyerLinkedinScheduledMessagesByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinScheduledMessageCondition>;
  filter?: InputMaybe<LinkedinScheduledMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinScheduledMessagesOrderBy>>;
  sort?: InputMaybe<Array<LinkedinScheduledMessageSort>>;
};


export type LawyerMeetingsByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MeetingCondition>;
  filter?: InputMaybe<MeetingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MeetingsOrderBy>>;
  sort?: InputMaybe<Array<MeetingSort>>;
};


export type LawyerPersonalisationStatisticsByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PersonalisationStatisticCondition>;
  filter?: InputMaybe<PersonalisationStatisticFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonalisationStatisticsOrderBy>>;
  sort?: InputMaybe<Array<PersonalisationStatisticsSort>>;
};


export type LawyerProfilesByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProfileCondition>;
  filter?: InputMaybe<ProfileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProfilesOrderBy>>;
  sort?: InputMaybe<Array<ProfileSort>>;
};


export type LawyerReferralPartnersByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ReferralPartnerCondition>;
  filter?: InputMaybe<ReferralPartnerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReferralPartnersOrderBy>>;
  sort?: InputMaybe<Array<ReferralPartnerSort>>;
};


export type LawyerReferralRequestsByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ReferralRequestCondition>;
  filter?: InputMaybe<ReferralRequestFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReferralRequestsOrderBy>>;
  sort?: InputMaybe<Array<ReferralRequestSort>>;
};


export type LawyerSenderProfilesByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SenderProfileCondition>;
  filter?: InputMaybe<SenderProfileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SenderProfilesOrderBy>>;
  sort?: InputMaybe<Array<SenderProfileSort>>;
};


export type LawyerSocialProofsByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SocialProofCondition>;
  filter?: InputMaybe<SocialProofFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SocialProofsOrderBy>>;
  sort?: InputMaybe<Array<SocialProofSort>>;
};


export type LawyerSuggestedTargetingsByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SuggestedTargetingCondition>;
  filter?: InputMaybe<SuggestedTargetingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SuggestedTargetingsOrderBy>>;
  sort?: InputMaybe<Array<SuggestedTargetingSort>>;
};


export type LawyerTestimonialsByLawyerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TestimonialCondition>;
  filter?: InputMaybe<TestimonialFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestimonialsOrderBy>>;
  sort?: InputMaybe<Array<TestimonialSort>>;
};

export enum LawyerAcquisitionType {
  DirectWebsite = 'DIRECT_WEBSITE',
  Expansion = 'EXPANSION',
  K4KEmail = 'K4_K_EMAIL',
  Linkedin = 'LINKEDIN',
  Other = 'OTHER',
  Referral = 'REFERRAL',
  Search = 'SEARCH'
}

/** A filter to be used against LawyerAcquisitionType fields. All fields are combined with a logical ‘and.’ */
export type LawyerAcquisitionTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LawyerAcquisitionType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LawyerAcquisitionType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LawyerAcquisitionType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LawyerAcquisitionType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LawyerAcquisitionType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LawyerAcquisitionType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LawyerAcquisitionType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LawyerAcquisitionType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LawyerAcquisitionType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LawyerAcquisitionType>>;
};

export type LawyerAggregates = {
  __typename?: 'LawyerAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LawyerAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LawyerDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LawyerMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LawyerMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LawyerStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LawyerStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LawyerSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LawyerVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LawyerVarianceSampleAggregates>;
};

export type LawyerAverageAggregates = {
  __typename?: 'LawyerAverageAggregates';
  /** Mean average of chargeRate across the matching connection */
  chargeRate?: Maybe<Scalars['Float']['output']>;
  /** Mean average of freeLeads across the matching connection */
  freeLeads?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of idealNumWeeklyMeetings across the matching connection */
  idealNumWeeklyMeetings?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of leadsPerMonth across the matching connection */
  leadsPerMonth?: Maybe<Scalars['BigFloat']['output']>;
};

/** A condition to be used against `Lawyer` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LawyerCondition = {
  /** Checks for equality with the object’s `acquisitionChannel` field. */
  acquisitionChannel?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `acquisitionType` field. */
  acquisitionType?: InputMaybe<LawyerAcquisitionType>;
  /** Checks for equality with the object’s `aliases` field. */
  aliases?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `brandColor` field. */
  brandColor?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `calendlyLink` field. */
  calendlyLink?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `calendlyUri` field. */
  calendlyUri?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `chargeRate` field. */
  chargeRate?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `chargedPer` field. */
  chargedPer?: InputMaybe<ChargedPer>;
  /** Checks for equality with the object’s `city` field. */
  city?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `clientProfileRequirements` field. */
  clientProfileRequirements?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `contactId` field. */
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `country` field. */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `countryCode` field. */
  countryCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `enableAutoTestimonial` field. */
  enableAutoTestimonial?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `externalContactsLastUpdated` field. */
  externalContactsLastUpdated?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `firmDescription` field. */
  firmDescription?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `firmName` field. */
  firmName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `freeLeads` field. */
  freeLeads?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `googlePlaceId` field. */
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `handoverMethod` field. */
  handoverMethod?: InputMaybe<HandoverMethod>;
  /** Checks for equality with the object’s `handoverState` field. */
  handoverState?: InputMaybe<HandoverState>;
  /** Checks for equality with the object’s `hubspotDealId` field. */
  hubspotDealId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `icp` field. */
  icp?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `icpGuessedTime` field. */
  icpGuessedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `idealNumWeeklyMeetings` field. */
  idealNumWeeklyMeetings?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `isManaged` field. */
  isManaged?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isPaused` field. */
  isPaused?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `lastAccountReview` field. */
  lastAccountReview?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastReportTime` field. */
  lastReportTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastStatusEmailTime` field. */
  lastStatusEmailTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lawyerDescription` field. */
  lawyerDescription?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `leadsPerMonth` field. */
  leadsPerMonth?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `lever` field. */
  lever?: InputMaybe<LawyerLever>;
  /** Checks for equality with the object’s `liEmail` field. */
  liEmail?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `liEncryptedPw` field. */
  liEncryptedPw?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `liLastActiveTime` field. */
  liLastActiveTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `liLoginStatus` field. */
  liLoginStatus?: InputMaybe<LinkedinLoginStatus>;
  /** Checks for equality with the object’s `liProfileName` field. */
  liProfileName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `liStopConnectionReqsUntil` field. */
  liStopConnectionReqsUntil?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `liVerificationCode` field. */
  liVerificationCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `logoSrc` field. */
  logoSrc?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `nylasAccessToken` field. */
  nylasAccessToken?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `onboardingFeePaidTime` field. */
  onboardingFeePaidTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `onboardingType` field. */
  onboardingType?: InputMaybe<LawyerOnboardingType>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `paymentMethod` field. */
  paymentMethod?: InputMaybe<PaymentMethod>;
  /** Checks for equality with the object’s `personalisationRankings` field. */
  personalisationRankings?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `postalCode` field. */
  postalCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: InputMaybe<LawyerPriority>;
  /** Checks for equality with the object’s `proxyId` field. */
  proxyId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `publicId` field. */
  publicId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `replyNotes` field. */
  replyNotes?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `salesFunnelSubscriberStatus` field. */
  salesFunnelSubscriberStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `senderDisclaimer` field. */
  senderDisclaimer?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `signatureHtml` field. */
  signatureHtml?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `slackChannelName` field. */
  slackChannelName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `slackClientId` field. */
  slackClientId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `slackClientSecret` field. */
  slackClientSecret?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `stage` field. */
  stage?: InputMaybe<LawyerStage>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<LawyerStatus>;
  /** Checks for equality with the object’s `streetAddress` field. */
  streetAddress?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `stripeCustomerId` field. */
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `stripeDiscountCode` field. */
  stripeDiscountCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `stripePriceId` field. */
  stripePriceId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timezone` field. */
  timezone?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userType` field. */
  userType?: InputMaybe<LawyerUserType>;
  /** Checks for equality with the object’s `valueProposition` field. */
  valueProposition?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `valuePropositionGuessedTime` field. */
  valuePropositionGuessedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `wantsToChooseDomains` field. */
  wantsToChooseDomains?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type LawyerDistinctCountAggregates = {
  __typename?: 'LawyerDistinctCountAggregates';
  /** Distinct count of acquisitionChannel across the matching connection */
  acquisitionChannel?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of acquisitionType across the matching connection */
  acquisitionType?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of aliases across the matching connection */
  aliases?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of brandColor across the matching connection */
  brandColor?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of calendlyLink across the matching connection */
  calendlyLink?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of calendlyUri across the matching connection */
  calendlyUri?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of chargeRate across the matching connection */
  chargeRate?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of chargedPer across the matching connection */
  chargedPer?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of city across the matching connection */
  city?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of clientProfileRequirements across the matching connection */
  clientProfileRequirements?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactId across the matching connection */
  contactId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of country across the matching connection */
  country?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of countryCode across the matching connection */
  countryCode?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of enableAutoTestimonial across the matching connection */
  enableAutoTestimonial?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of externalContactsLastUpdated across the matching connection */
  externalContactsLastUpdated?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of firmDescription across the matching connection */
  firmDescription?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of firmName across the matching connection */
  firmName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of freeLeads across the matching connection */
  freeLeads?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of googlePlaceId across the matching connection */
  googlePlaceId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of handoverMethod across the matching connection */
  handoverMethod?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of handoverState across the matching connection */
  handoverState?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of hubspotDealId across the matching connection */
  hubspotDealId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of icp across the matching connection */
  icp?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of icpGuessedTime across the matching connection */
  icpGuessedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of idealNumWeeklyMeetings across the matching connection */
  idealNumWeeklyMeetings?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isManaged across the matching connection */
  isManaged?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isPaused across the matching connection */
  isPaused?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastAccountReview across the matching connection */
  lastAccountReview?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastReportTime across the matching connection */
  lastReportTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastStatusEmailTime across the matching connection */
  lastStatusEmailTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerDescription across the matching connection */
  lawyerDescription?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of leadsPerMonth across the matching connection */
  leadsPerMonth?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lever across the matching connection */
  lever?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of liEmail across the matching connection */
  liEmail?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of liEncryptedPw across the matching connection */
  liEncryptedPw?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of liLastActiveTime across the matching connection */
  liLastActiveTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of liLoginStatus across the matching connection */
  liLoginStatus?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of liProfileName across the matching connection */
  liProfileName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of liStopConnectionReqsUntil across the matching connection */
  liStopConnectionReqsUntil?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of liVerificationCode across the matching connection */
  liVerificationCode?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of linkedinUrl across the matching connection */
  linkedinUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of logoSrc across the matching connection */
  logoSrc?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of nylasAccessToken across the matching connection */
  nylasAccessToken?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of onboardingFeePaidTime across the matching connection */
  onboardingFeePaidTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of onboardingType across the matching connection */
  onboardingType?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of organizationId across the matching connection */
  organizationId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of paymentMethod across the matching connection */
  paymentMethod?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of personalisationRankings across the matching connection */
  personalisationRankings?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of postalCode across the matching connection */
  postalCode?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of priority across the matching connection */
  priority?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of proxyId across the matching connection */
  proxyId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of publicId across the matching connection */
  publicId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of replyNotes across the matching connection */
  replyNotes?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of salesFunnelSubscriberStatus across the matching connection */
  salesFunnelSubscriberStatus?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of senderDisclaimer across the matching connection */
  senderDisclaimer?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of signatureHtml across the matching connection */
  signatureHtml?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of slackChannelName across the matching connection */
  slackChannelName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of slackClientId across the matching connection */
  slackClientId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of slackClientSecret across the matching connection */
  slackClientSecret?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stage across the matching connection */
  stage?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of state across the matching connection */
  state?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of streetAddress across the matching connection */
  streetAddress?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stripeCustomerId across the matching connection */
  stripeCustomerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stripeDiscountCode across the matching connection */
  stripeDiscountCode?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stripePriceId across the matching connection */
  stripePriceId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timezone across the matching connection */
  timezone?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of userType across the matching connection */
  userType?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of valueProposition across the matching connection */
  valueProposition?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of valuePropositionGuessedTime across the matching connection */
  valuePropositionGuessedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of wantsToChooseDomains across the matching connection */
  wantsToChooseDomains?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of websiteUrl across the matching connection */
  websiteUrl?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Lawyer` object types. All fields are combined with a logical ‘and.’ */
export type LawyerFilter = {
  /** Filter by the object’s `acquisitionChannel` field. */
  acquisitionChannel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `acquisitionType` field. */
  acquisitionType?: InputMaybe<LawyerAcquisitionTypeFilter>;
  /** Filter by the object’s `aliases` field. */
  aliases?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LawyerFilter>>;
  /** Filter by the object’s `brandColor` field. */
  brandColor?: InputMaybe<StringFilter>;
  /** Filter by the object’s `calendlyLink` field. */
  calendlyLink?: InputMaybe<StringFilter>;
  /** Filter by the object’s `calendlyUri` field. */
  calendlyUri?: InputMaybe<StringFilter>;
  /** Filter by the object’s `chargeRate` field. */
  chargeRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `chargedPer` field. */
  chargedPer?: InputMaybe<ChargedPerFilter>;
  /** Filter by the object’s `city` field. */
  city?: InputMaybe<StringFilter>;
  /** Filter by the object’s `clientProfileRequirements` field. */
  clientProfileRequirements?: InputMaybe<StringFilter>;
  /** Filter by the object’s `contactId` field. */
  contactId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `country` field. */
  country?: InputMaybe<StringFilter>;
  /** Filter by the object’s `countryCode` field. */
  countryCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `enableAutoTestimonial` field. */
  enableAutoTestimonial?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `externalContactsLastUpdated` field. */
  externalContactsLastUpdated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `firmDescription` field. */
  firmDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firmName` field. */
  firmName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `freeLeads` field. */
  freeLeads?: InputMaybe<IntFilter>;
  /** Filter by the object’s `googlePlaceId` field. */
  googlePlaceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `handoverMethod` field. */
  handoverMethod?: InputMaybe<HandoverMethodFilter>;
  /** Filter by the object’s `handoverState` field. */
  handoverState?: InputMaybe<HandoverStateFilter>;
  /** Filter by the object’s `hubspotDealId` field. */
  hubspotDealId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `icp` field. */
  icp?: InputMaybe<StringFilter>;
  /** Filter by the object’s `icpGuessedTime` field. */
  icpGuessedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `idealNumWeeklyMeetings` field. */
  idealNumWeeklyMeetings?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isManaged` field. */
  isManaged?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPaused` field. */
  isPaused?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastAccountReview` field. */
  lastAccountReview?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastReportTime` field. */
  lastReportTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastStatusEmailTime` field. */
  lastStatusEmailTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lawyerDescription` field. */
  lawyerDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `leadsPerMonth` field. */
  leadsPerMonth?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lever` field. */
  lever?: InputMaybe<LawyerLeverFilter>;
  /** Filter by the object’s `liEmail` field. */
  liEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `liEncryptedPw` field. */
  liEncryptedPw?: InputMaybe<StringFilter>;
  /** Filter by the object’s `liLastActiveTime` field. */
  liLastActiveTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `liLoginStatus` field. */
  liLoginStatus?: InputMaybe<LinkedinLoginStatusFilter>;
  /** Filter by the object’s `liProfileName` field. */
  liProfileName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `liStopConnectionReqsUntil` field. */
  liStopConnectionReqsUntil?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `liVerificationCode` field. */
  liVerificationCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `linkedinUrl` field. */
  linkedinUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `logoSrc` field. */
  logoSrc?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LawyerFilter>;
  /** Filter by the object’s `nylasAccessToken` field. */
  nylasAccessToken?: InputMaybe<StringFilter>;
  /** Filter by the object’s `onboardingFeePaidTime` field. */
  onboardingFeePaidTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `onboardingType` field. */
  onboardingType?: InputMaybe<LawyerOnboardingTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LawyerFilter>>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `paymentMethod` field. */
  paymentMethod?: InputMaybe<PaymentMethodFilter>;
  /** Filter by the object’s `postalCode` field. */
  postalCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `priority` field. */
  priority?: InputMaybe<LawyerPriorityFilter>;
  /** Filter by the object’s `proxyId` field. */
  proxyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `publicId` field. */
  publicId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `replyNotes` field. */
  replyNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `salesFunnelSubscriberStatus` field. */
  salesFunnelSubscriberStatus?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `senderDisclaimer` field. */
  senderDisclaimer?: InputMaybe<StringFilter>;
  /** Filter by the object’s `signatureHtml` field. */
  signatureHtml?: InputMaybe<StringFilter>;
  /** Filter by the object’s `slackChannelName` field. */
  slackChannelName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `slackClientId` field. */
  slackClientId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `slackClientSecret` field. */
  slackClientSecret?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stage` field. */
  stage?: InputMaybe<LawyerStageFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<LawyerStatusFilter>;
  /** Filter by the object’s `streetAddress` field. */
  streetAddress?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stripeCustomerId` field. */
  stripeCustomerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stripeDiscountCode` field. */
  stripeDiscountCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stripePriceId` field. */
  stripePriceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timezone` field. */
  timezone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userType` field. */
  userType?: InputMaybe<LawyerUserTypeFilter>;
  /** Filter by the object’s `valueProposition` field. */
  valueProposition?: InputMaybe<StringFilter>;
  /** Filter by the object’s `valuePropositionGuessedTime` field. */
  valuePropositionGuessedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `wantsToChooseDomains` field. */
  wantsToChooseDomains?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<StringFilter>;
};

/** Grouping methods for `Lawyer` for usage during aggregation. */
export enum LawyerGroupBy {
  AcquisitionChannel = 'ACQUISITION_CHANNEL',
  AcquisitionType = 'ACQUISITION_TYPE',
  Aliases = 'ALIASES',
  BrandColor = 'BRAND_COLOR',
  CalendlyLink = 'CALENDLY_LINK',
  CalendlyUri = 'CALENDLY_URI',
  ChargedPer = 'CHARGED_PER',
  ChargeRate = 'CHARGE_RATE',
  City = 'CITY',
  ClientProfileRequirements = 'CLIENT_PROFILE_REQUIREMENTS',
  ContactId = 'CONTACT_ID',
  Country = 'COUNTRY',
  CountryCode = 'COUNTRY_CODE',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  Email = 'EMAIL',
  EnableAutoTestimonial = 'ENABLE_AUTO_TESTIMONIAL',
  ExternalContactsLastUpdated = 'EXTERNAL_CONTACTS_LAST_UPDATED',
  ExternalContactsLastUpdatedTruncatedToDay = 'EXTERNAL_CONTACTS_LAST_UPDATED_TRUNCATED_TO_DAY',
  ExternalContactsLastUpdatedTruncatedToHour = 'EXTERNAL_CONTACTS_LAST_UPDATED_TRUNCATED_TO_HOUR',
  FirmDescription = 'FIRM_DESCRIPTION',
  FirmName = 'FIRM_NAME',
  FreeLeads = 'FREE_LEADS',
  GooglePlaceId = 'GOOGLE_PLACE_ID',
  HandoverMethod = 'HANDOVER_METHOD',
  HandoverState = 'HANDOVER_STATE',
  HubspotDealId = 'HUBSPOT_DEAL_ID',
  Icp = 'ICP',
  IcpGuessedTime = 'ICP_GUESSED_TIME',
  IcpGuessedTimeTruncatedToDay = 'ICP_GUESSED_TIME_TRUNCATED_TO_DAY',
  IcpGuessedTimeTruncatedToHour = 'ICP_GUESSED_TIME_TRUNCATED_TO_HOUR',
  IdealNumWeeklyMeetings = 'IDEAL_NUM_WEEKLY_MEETINGS',
  IsManaged = 'IS_MANAGED',
  IsPaused = 'IS_PAUSED',
  LastAccountReview = 'LAST_ACCOUNT_REVIEW',
  LastAccountReviewTruncatedToDay = 'LAST_ACCOUNT_REVIEW_TRUNCATED_TO_DAY',
  LastAccountReviewTruncatedToHour = 'LAST_ACCOUNT_REVIEW_TRUNCATED_TO_HOUR',
  LastReportTime = 'LAST_REPORT_TIME',
  LastReportTimeTruncatedToDay = 'LAST_REPORT_TIME_TRUNCATED_TO_DAY',
  LastReportTimeTruncatedToHour = 'LAST_REPORT_TIME_TRUNCATED_TO_HOUR',
  LastStatusEmailTime = 'LAST_STATUS_EMAIL_TIME',
  LastStatusEmailTimeTruncatedToDay = 'LAST_STATUS_EMAIL_TIME_TRUNCATED_TO_DAY',
  LastStatusEmailTimeTruncatedToHour = 'LAST_STATUS_EMAIL_TIME_TRUNCATED_TO_HOUR',
  LawyerDescription = 'LAWYER_DESCRIPTION',
  LeadsPerMonth = 'LEADS_PER_MONTH',
  Lever = 'LEVER',
  LinkedinUrl = 'LINKEDIN_URL',
  LiEmail = 'LI_EMAIL',
  LiEncryptedPw = 'LI_ENCRYPTED_PW',
  LiLastActiveTime = 'LI_LAST_ACTIVE_TIME',
  LiLastActiveTimeTruncatedToDay = 'LI_LAST_ACTIVE_TIME_TRUNCATED_TO_DAY',
  LiLastActiveTimeTruncatedToHour = 'LI_LAST_ACTIVE_TIME_TRUNCATED_TO_HOUR',
  LiLoginStatus = 'LI_LOGIN_STATUS',
  LiProfileName = 'LI_PROFILE_NAME',
  LiStopConnectionReqsUntil = 'LI_STOP_CONNECTION_REQS_UNTIL',
  LiStopConnectionReqsUntilTruncatedToDay = 'LI_STOP_CONNECTION_REQS_UNTIL_TRUNCATED_TO_DAY',
  LiStopConnectionReqsUntilTruncatedToHour = 'LI_STOP_CONNECTION_REQS_UNTIL_TRUNCATED_TO_HOUR',
  LiVerificationCode = 'LI_VERIFICATION_CODE',
  LogoSrc = 'LOGO_SRC',
  Name = 'NAME',
  NylasAccessToken = 'NYLAS_ACCESS_TOKEN',
  OnboardingFeePaidTime = 'ONBOARDING_FEE_PAID_TIME',
  OnboardingFeePaidTimeTruncatedToDay = 'ONBOARDING_FEE_PAID_TIME_TRUNCATED_TO_DAY',
  OnboardingFeePaidTimeTruncatedToHour = 'ONBOARDING_FEE_PAID_TIME_TRUNCATED_TO_HOUR',
  OnboardingType = 'ONBOARDING_TYPE',
  OrganizationId = 'ORGANIZATION_ID',
  PaymentMethod = 'PAYMENT_METHOD',
  PersonalisationRankings = 'PERSONALISATION_RANKINGS',
  PostalCode = 'POSTAL_CODE',
  Priority = 'PRIORITY',
  ProxyId = 'PROXY_ID',
  PublicId = 'PUBLIC_ID',
  ReplyNotes = 'REPLY_NOTES',
  SalesFunnelSubscriberStatus = 'SALES_FUNNEL_SUBSCRIBER_STATUS',
  SenderDisclaimer = 'SENDER_DISCLAIMER',
  SignatureHtml = 'SIGNATURE_HTML',
  SlackChannelName = 'SLACK_CHANNEL_NAME',
  SlackClientId = 'SLACK_CLIENT_ID',
  SlackClientSecret = 'SLACK_CLIENT_SECRET',
  Stage = 'STAGE',
  State = 'STATE',
  Status = 'STATUS',
  StreetAddress = 'STREET_ADDRESS',
  StripeCustomerId = 'STRIPE_CUSTOMER_ID',
  StripeDiscountCode = 'STRIPE_DISCOUNT_CODE',
  StripePriceId = 'STRIPE_PRICE_ID',
  Timezone = 'TIMEZONE',
  Title = 'TITLE',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR',
  UserType = 'USER_TYPE',
  ValueProposition = 'VALUE_PROPOSITION',
  ValuePropositionGuessedTime = 'VALUE_PROPOSITION_GUESSED_TIME',
  ValuePropositionGuessedTimeTruncatedToDay = 'VALUE_PROPOSITION_GUESSED_TIME_TRUNCATED_TO_DAY',
  ValuePropositionGuessedTimeTruncatedToHour = 'VALUE_PROPOSITION_GUESSED_TIME_TRUNCATED_TO_HOUR',
  WantsToChooseDomains = 'WANTS_TO_CHOOSE_DOMAINS',
  WebsiteUrl = 'WEBSITE_URL'
}

export type LawyerHavingAverageInput = {
  chargeRate?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  externalContactsLastUpdated?: InputMaybe<HavingDatetimeFilter>;
  freeLeads?: InputMaybe<HavingIntFilter>;
  icpGuessedTime?: InputMaybe<HavingDatetimeFilter>;
  idealNumWeeklyMeetings?: InputMaybe<HavingIntFilter>;
  lastAccountReview?: InputMaybe<HavingDatetimeFilter>;
  lastReportTime?: InputMaybe<HavingDatetimeFilter>;
  lastStatusEmailTime?: InputMaybe<HavingDatetimeFilter>;
  leadsPerMonth?: InputMaybe<HavingIntFilter>;
  liLastActiveTime?: InputMaybe<HavingDatetimeFilter>;
  liStopConnectionReqsUntil?: InputMaybe<HavingDatetimeFilter>;
  onboardingFeePaidTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  valuePropositionGuessedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerHavingDistinctCountInput = {
  chargeRate?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  externalContactsLastUpdated?: InputMaybe<HavingDatetimeFilter>;
  freeLeads?: InputMaybe<HavingIntFilter>;
  icpGuessedTime?: InputMaybe<HavingDatetimeFilter>;
  idealNumWeeklyMeetings?: InputMaybe<HavingIntFilter>;
  lastAccountReview?: InputMaybe<HavingDatetimeFilter>;
  lastReportTime?: InputMaybe<HavingDatetimeFilter>;
  lastStatusEmailTime?: InputMaybe<HavingDatetimeFilter>;
  leadsPerMonth?: InputMaybe<HavingIntFilter>;
  liLastActiveTime?: InputMaybe<HavingDatetimeFilter>;
  liStopConnectionReqsUntil?: InputMaybe<HavingDatetimeFilter>;
  onboardingFeePaidTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  valuePropositionGuessedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Lawyer` aggregates. */
export type LawyerHavingInput = {
  AND?: InputMaybe<Array<LawyerHavingInput>>;
  OR?: InputMaybe<Array<LawyerHavingInput>>;
  average?: InputMaybe<LawyerHavingAverageInput>;
  distinctCount?: InputMaybe<LawyerHavingDistinctCountInput>;
  max?: InputMaybe<LawyerHavingMaxInput>;
  min?: InputMaybe<LawyerHavingMinInput>;
  stddevPopulation?: InputMaybe<LawyerHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LawyerHavingStddevSampleInput>;
  sum?: InputMaybe<LawyerHavingSumInput>;
  variancePopulation?: InputMaybe<LawyerHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LawyerHavingVarianceSampleInput>;
};

export type LawyerHavingMaxInput = {
  chargeRate?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  externalContactsLastUpdated?: InputMaybe<HavingDatetimeFilter>;
  freeLeads?: InputMaybe<HavingIntFilter>;
  icpGuessedTime?: InputMaybe<HavingDatetimeFilter>;
  idealNumWeeklyMeetings?: InputMaybe<HavingIntFilter>;
  lastAccountReview?: InputMaybe<HavingDatetimeFilter>;
  lastReportTime?: InputMaybe<HavingDatetimeFilter>;
  lastStatusEmailTime?: InputMaybe<HavingDatetimeFilter>;
  leadsPerMonth?: InputMaybe<HavingIntFilter>;
  liLastActiveTime?: InputMaybe<HavingDatetimeFilter>;
  liStopConnectionReqsUntil?: InputMaybe<HavingDatetimeFilter>;
  onboardingFeePaidTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  valuePropositionGuessedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerHavingMinInput = {
  chargeRate?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  externalContactsLastUpdated?: InputMaybe<HavingDatetimeFilter>;
  freeLeads?: InputMaybe<HavingIntFilter>;
  icpGuessedTime?: InputMaybe<HavingDatetimeFilter>;
  idealNumWeeklyMeetings?: InputMaybe<HavingIntFilter>;
  lastAccountReview?: InputMaybe<HavingDatetimeFilter>;
  lastReportTime?: InputMaybe<HavingDatetimeFilter>;
  lastStatusEmailTime?: InputMaybe<HavingDatetimeFilter>;
  leadsPerMonth?: InputMaybe<HavingIntFilter>;
  liLastActiveTime?: InputMaybe<HavingDatetimeFilter>;
  liStopConnectionReqsUntil?: InputMaybe<HavingDatetimeFilter>;
  onboardingFeePaidTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  valuePropositionGuessedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerHavingStddevPopulationInput = {
  chargeRate?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  externalContactsLastUpdated?: InputMaybe<HavingDatetimeFilter>;
  freeLeads?: InputMaybe<HavingIntFilter>;
  icpGuessedTime?: InputMaybe<HavingDatetimeFilter>;
  idealNumWeeklyMeetings?: InputMaybe<HavingIntFilter>;
  lastAccountReview?: InputMaybe<HavingDatetimeFilter>;
  lastReportTime?: InputMaybe<HavingDatetimeFilter>;
  lastStatusEmailTime?: InputMaybe<HavingDatetimeFilter>;
  leadsPerMonth?: InputMaybe<HavingIntFilter>;
  liLastActiveTime?: InputMaybe<HavingDatetimeFilter>;
  liStopConnectionReqsUntil?: InputMaybe<HavingDatetimeFilter>;
  onboardingFeePaidTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  valuePropositionGuessedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerHavingStddevSampleInput = {
  chargeRate?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  externalContactsLastUpdated?: InputMaybe<HavingDatetimeFilter>;
  freeLeads?: InputMaybe<HavingIntFilter>;
  icpGuessedTime?: InputMaybe<HavingDatetimeFilter>;
  idealNumWeeklyMeetings?: InputMaybe<HavingIntFilter>;
  lastAccountReview?: InputMaybe<HavingDatetimeFilter>;
  lastReportTime?: InputMaybe<HavingDatetimeFilter>;
  lastStatusEmailTime?: InputMaybe<HavingDatetimeFilter>;
  leadsPerMonth?: InputMaybe<HavingIntFilter>;
  liLastActiveTime?: InputMaybe<HavingDatetimeFilter>;
  liStopConnectionReqsUntil?: InputMaybe<HavingDatetimeFilter>;
  onboardingFeePaidTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  valuePropositionGuessedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerHavingSumInput = {
  chargeRate?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  externalContactsLastUpdated?: InputMaybe<HavingDatetimeFilter>;
  freeLeads?: InputMaybe<HavingIntFilter>;
  icpGuessedTime?: InputMaybe<HavingDatetimeFilter>;
  idealNumWeeklyMeetings?: InputMaybe<HavingIntFilter>;
  lastAccountReview?: InputMaybe<HavingDatetimeFilter>;
  lastReportTime?: InputMaybe<HavingDatetimeFilter>;
  lastStatusEmailTime?: InputMaybe<HavingDatetimeFilter>;
  leadsPerMonth?: InputMaybe<HavingIntFilter>;
  liLastActiveTime?: InputMaybe<HavingDatetimeFilter>;
  liStopConnectionReqsUntil?: InputMaybe<HavingDatetimeFilter>;
  onboardingFeePaidTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  valuePropositionGuessedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerHavingVariancePopulationInput = {
  chargeRate?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  externalContactsLastUpdated?: InputMaybe<HavingDatetimeFilter>;
  freeLeads?: InputMaybe<HavingIntFilter>;
  icpGuessedTime?: InputMaybe<HavingDatetimeFilter>;
  idealNumWeeklyMeetings?: InputMaybe<HavingIntFilter>;
  lastAccountReview?: InputMaybe<HavingDatetimeFilter>;
  lastReportTime?: InputMaybe<HavingDatetimeFilter>;
  lastStatusEmailTime?: InputMaybe<HavingDatetimeFilter>;
  leadsPerMonth?: InputMaybe<HavingIntFilter>;
  liLastActiveTime?: InputMaybe<HavingDatetimeFilter>;
  liStopConnectionReqsUntil?: InputMaybe<HavingDatetimeFilter>;
  onboardingFeePaidTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  valuePropositionGuessedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerHavingVarianceSampleInput = {
  chargeRate?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  externalContactsLastUpdated?: InputMaybe<HavingDatetimeFilter>;
  freeLeads?: InputMaybe<HavingIntFilter>;
  icpGuessedTime?: InputMaybe<HavingDatetimeFilter>;
  idealNumWeeklyMeetings?: InputMaybe<HavingIntFilter>;
  lastAccountReview?: InputMaybe<HavingDatetimeFilter>;
  lastReportTime?: InputMaybe<HavingDatetimeFilter>;
  lastStatusEmailTime?: InputMaybe<HavingDatetimeFilter>;
  leadsPerMonth?: InputMaybe<HavingIntFilter>;
  liLastActiveTime?: InputMaybe<HavingDatetimeFilter>;
  liStopConnectionReqsUntil?: InputMaybe<HavingDatetimeFilter>;
  onboardingFeePaidTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
  valuePropositionGuessedTime?: InputMaybe<HavingDatetimeFilter>;
};

export enum LawyerLever {
  Budget = 'BUDGET',
  Campaign = 'CAMPAIGN',
  Conversion = 'CONVERSION',
  Mailbox = 'MAILBOX',
  Time = 'TIME'
}

/** A filter to be used against LawyerLever fields. All fields are combined with a logical ‘and.’ */
export type LawyerLeverFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LawyerLever>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LawyerLever>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LawyerLever>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LawyerLever>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LawyerLever>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LawyerLever>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LawyerLever>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LawyerLever>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LawyerLever>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LawyerLever>>;
};

export type LawyerMaxAggregates = {
  __typename?: 'LawyerMaxAggregates';
  /** Maximum of chargeRate across the matching connection */
  chargeRate?: Maybe<Scalars['Float']['output']>;
  /** Maximum of freeLeads across the matching connection */
  freeLeads?: Maybe<Scalars['Int']['output']>;
  /** Maximum of idealNumWeeklyMeetings across the matching connection */
  idealNumWeeklyMeetings?: Maybe<Scalars['Int']['output']>;
  /** Maximum of leadsPerMonth across the matching connection */
  leadsPerMonth?: Maybe<Scalars['Int']['output']>;
};

export type LawyerMinAggregates = {
  __typename?: 'LawyerMinAggregates';
  /** Minimum of chargeRate across the matching connection */
  chargeRate?: Maybe<Scalars['Float']['output']>;
  /** Minimum of freeLeads across the matching connection */
  freeLeads?: Maybe<Scalars['Int']['output']>;
  /** Minimum of idealNumWeeklyMeetings across the matching connection */
  idealNumWeeklyMeetings?: Maybe<Scalars['Int']['output']>;
  /** Minimum of leadsPerMonth across the matching connection */
  leadsPerMonth?: Maybe<Scalars['Int']['output']>;
};

export enum LawyerOnboardingType {
  HumanOnboarding = 'HUMAN_ONBOARDING',
  SelfServe = 'SELF_SERVE'
}

/** A filter to be used against LawyerOnboardingType fields. All fields are combined with a logical ‘and.’ */
export type LawyerOnboardingTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LawyerOnboardingType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LawyerOnboardingType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LawyerOnboardingType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LawyerOnboardingType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LawyerOnboardingType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LawyerOnboardingType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LawyerOnboardingType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LawyerOnboardingType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LawyerOnboardingType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LawyerOnboardingType>>;
};

export enum LawyerPriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

/** A filter to be used against LawyerPriority fields. All fields are combined with a logical ‘and.’ */
export type LawyerPriorityFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LawyerPriority>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LawyerPriority>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LawyerPriority>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LawyerPriority>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LawyerPriority>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LawyerPriority>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LawyerPriority>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LawyerPriority>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LawyerPriority>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LawyerPriority>>;
};

export type LawyerSetting = Node & {
  __typename?: 'LawyerSetting';
  createdTime?: Maybe<Scalars['Datetime']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Lawyer` that is related to this `LawyerSetting`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  type: LawyerSettingType;
  updatedTime?: Maybe<Scalars['Datetime']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type LawyerSettingAggregates = {
  __typename?: 'LawyerSettingAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LawyerSettingDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `LawyerSetting` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LawyerSettingCondition = {
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<LawyerSettingType>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type LawyerSettingDistinctCountAggregates = {
  __typename?: 'LawyerSettingDistinctCountAggregates';
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `LawyerSetting` object types. All fields are combined with a logical ‘and.’ */
export type LawyerSettingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LawyerSettingFilter>>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LawyerSettingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LawyerSettingFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<LawyerSettingTypeFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<StringFilter>;
};

/** Grouping methods for `LawyerSetting` for usage during aggregation. */
export enum LawyerSettingGroupBy {
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  LawyerId = 'LAWYER_ID',
  Type = 'TYPE',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR',
  Value = 'VALUE'
}

export type LawyerSettingHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerSettingHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LawyerSetting` aggregates. */
export type LawyerSettingHavingInput = {
  AND?: InputMaybe<Array<LawyerSettingHavingInput>>;
  OR?: InputMaybe<Array<LawyerSettingHavingInput>>;
  average?: InputMaybe<LawyerSettingHavingAverageInput>;
  distinctCount?: InputMaybe<LawyerSettingHavingDistinctCountInput>;
  max?: InputMaybe<LawyerSettingHavingMaxInput>;
  min?: InputMaybe<LawyerSettingHavingMinInput>;
  stddevPopulation?: InputMaybe<LawyerSettingHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LawyerSettingHavingStddevSampleInput>;
  sum?: InputMaybe<LawyerSettingHavingSumInput>;
  variancePopulation?: InputMaybe<LawyerSettingHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LawyerSettingHavingVarianceSampleInput>;
};

export type LawyerSettingHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerSettingHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerSettingHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerSettingHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerSettingHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerSettingHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LawyerSettingHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type LawyerSettingSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: LawyerSettingSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type LawyerSettingSortBy = {
  field?: InputMaybe<LawyerSettingSortableField>;
};

/** Sortable concrete fields for the `LawyerSetting` type. */
export enum LawyerSettingSortableField {
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  LawyerId = 'LAWYER_ID',
  Type = 'TYPE',
  UpdatedTime = 'UPDATED_TIME',
  Value = 'VALUE'
}

export enum LawyerSettingType {
  MeetingAvailability = 'MEETING_AVAILABILITY'
}

/** A filter to be used against LawyerSettingType fields. All fields are combined with a logical ‘and.’ */
export type LawyerSettingTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LawyerSettingType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LawyerSettingType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LawyerSettingType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LawyerSettingType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LawyerSettingType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LawyerSettingType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LawyerSettingType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LawyerSettingType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LawyerSettingType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LawyerSettingType>>;
};

/** A connection to a list of `LawyerSetting` values. */
export type LawyerSettingsConnection = {
  __typename?: 'LawyerSettingsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LawyerSettingAggregates>;
  /** A list of edges which contains the `LawyerSetting` and cursor to aid in pagination. */
  edges: Array<LawyerSettingsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LawyerSettingAggregates>>;
  /** A list of `LawyerSetting` objects. */
  nodes: Array<Maybe<LawyerSetting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LawyerSetting` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `LawyerSetting` values. */
export type LawyerSettingsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LawyerSettingGroupBy>;
  having?: InputMaybe<LawyerSettingHavingInput>;
};

/** A `LawyerSetting` edge in the connection. */
export type LawyerSettingsEdge = {
  __typename?: 'LawyerSettingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `LawyerSetting` at the end of the edge. */
  node?: Maybe<LawyerSetting>;
};

/** Methods to use when ordering `LawyerSetting`. */
export enum LawyerSettingsOrderBy {
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type LawyerSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: LawyerSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type LawyerSortBy = {
  field?: InputMaybe<LawyerSortableField>;
};

/** Sortable concrete fields for the `Lawyer` type. */
export enum LawyerSortableField {
  AcquisitionChannel = 'ACQUISITION_CHANNEL',
  AcquisitionType = 'ACQUISITION_TYPE',
  Aliases = 'ALIASES',
  BrandColor = 'BRAND_COLOR',
  CalendlyLink = 'CALENDLY_LINK',
  CalendlyUri = 'CALENDLY_URI',
  ChargedPer = 'CHARGED_PER',
  ChargeRate = 'CHARGE_RATE',
  City = 'CITY',
  ClientProfileRequirements = 'CLIENT_PROFILE_REQUIREMENTS',
  ContactId = 'CONTACT_ID',
  Country = 'COUNTRY',
  CountryCode = 'COUNTRY_CODE',
  CreatedTime = 'CREATED_TIME',
  Email = 'EMAIL',
  EnableAutoTestimonial = 'ENABLE_AUTO_TESTIMONIAL',
  ExternalContactsLastUpdated = 'EXTERNAL_CONTACTS_LAST_UPDATED',
  FirmDescription = 'FIRM_DESCRIPTION',
  FirmName = 'FIRM_NAME',
  FreeLeads = 'FREE_LEADS',
  GooglePlaceId = 'GOOGLE_PLACE_ID',
  HandoverMethod = 'HANDOVER_METHOD',
  HandoverState = 'HANDOVER_STATE',
  HubspotDealId = 'HUBSPOT_DEAL_ID',
  Icp = 'ICP',
  IcpGuessedTime = 'ICP_GUESSED_TIME',
  Id = 'ID',
  IdealNumWeeklyMeetings = 'IDEAL_NUM_WEEKLY_MEETINGS',
  IsManaged = 'IS_MANAGED',
  IsPaused = 'IS_PAUSED',
  LastAccountReview = 'LAST_ACCOUNT_REVIEW',
  LastReportTime = 'LAST_REPORT_TIME',
  LastStatusEmailTime = 'LAST_STATUS_EMAIL_TIME',
  LawyerDescription = 'LAWYER_DESCRIPTION',
  LeadsPerMonth = 'LEADS_PER_MONTH',
  Lever = 'LEVER',
  LinkedinUrl = 'LINKEDIN_URL',
  LiEmail = 'LI_EMAIL',
  LiEncryptedPw = 'LI_ENCRYPTED_PW',
  LiLastActiveTime = 'LI_LAST_ACTIVE_TIME',
  LiLoginStatus = 'LI_LOGIN_STATUS',
  LiProfileName = 'LI_PROFILE_NAME',
  LiStopConnectionReqsUntil = 'LI_STOP_CONNECTION_REQS_UNTIL',
  LiVerificationCode = 'LI_VERIFICATION_CODE',
  LogoSrc = 'LOGO_SRC',
  Name = 'NAME',
  NylasAccessToken = 'NYLAS_ACCESS_TOKEN',
  OnboardingFeePaidTime = 'ONBOARDING_FEE_PAID_TIME',
  OnboardingType = 'ONBOARDING_TYPE',
  OrganizationId = 'ORGANIZATION_ID',
  PaymentMethod = 'PAYMENT_METHOD',
  PersonalisationRankings = 'PERSONALISATION_RANKINGS',
  PostalCode = 'POSTAL_CODE',
  Priority = 'PRIORITY',
  ProxyId = 'PROXY_ID',
  PublicId = 'PUBLIC_ID',
  ReplyNotes = 'REPLY_NOTES',
  SalesFunnelSubscriberStatus = 'SALES_FUNNEL_SUBSCRIBER_STATUS',
  SenderDisclaimer = 'SENDER_DISCLAIMER',
  SignatureHtml = 'SIGNATURE_HTML',
  SlackChannelName = 'SLACK_CHANNEL_NAME',
  SlackClientId = 'SLACK_CLIENT_ID',
  SlackClientSecret = 'SLACK_CLIENT_SECRET',
  Stage = 'STAGE',
  State = 'STATE',
  Status = 'STATUS',
  StreetAddress = 'STREET_ADDRESS',
  StripeCustomerId = 'STRIPE_CUSTOMER_ID',
  StripeDiscountCode = 'STRIPE_DISCOUNT_CODE',
  StripePriceId = 'STRIPE_PRICE_ID',
  Timezone = 'TIMEZONE',
  Title = 'TITLE',
  UpdatedTime = 'UPDATED_TIME',
  UserType = 'USER_TYPE',
  ValueProposition = 'VALUE_PROPOSITION',
  ValuePropositionGuessedTime = 'VALUE_PROPOSITION_GUESSED_TIME',
  WantsToChooseDomains = 'WANTS_TO_CHOOSE_DOMAINS',
  WebsiteUrl = 'WEBSITE_URL'
}

export enum LawyerStage {
  Converted = 'CONVERTED',
  FreeTrial = 'FREE_TRIAL',
  Paying = 'PAYING'
}

/** A filter to be used against LawyerStage fields. All fields are combined with a logical ‘and.’ */
export type LawyerStageFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LawyerStage>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LawyerStage>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LawyerStage>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LawyerStage>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LawyerStage>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LawyerStage>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LawyerStage>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LawyerStage>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LawyerStage>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LawyerStage>>;
};

export enum LawyerStatus {
  Active = 'ACTIVE',
  Delete = 'DELETE',
  Inactive = 'INACTIVE'
}

/** A filter to be used against LawyerStatus fields. All fields are combined with a logical ‘and.’ */
export type LawyerStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LawyerStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LawyerStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LawyerStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LawyerStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LawyerStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LawyerStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LawyerStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LawyerStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LawyerStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LawyerStatus>>;
};

export type LawyerStddevPopulationAggregates = {
  __typename?: 'LawyerStddevPopulationAggregates';
  /** Population standard deviation of chargeRate across the matching connection */
  chargeRate?: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of freeLeads across the matching connection */
  freeLeads?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of idealNumWeeklyMeetings across the matching connection */
  idealNumWeeklyMeetings?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of leadsPerMonth across the matching connection */
  leadsPerMonth?: Maybe<Scalars['BigFloat']['output']>;
};

export type LawyerStddevSampleAggregates = {
  __typename?: 'LawyerStddevSampleAggregates';
  /** Sample standard deviation of chargeRate across the matching connection */
  chargeRate?: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of freeLeads across the matching connection */
  freeLeads?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of idealNumWeeklyMeetings across the matching connection */
  idealNumWeeklyMeetings?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of leadsPerMonth across the matching connection */
  leadsPerMonth?: Maybe<Scalars['BigFloat']['output']>;
};

export type LawyerSumAggregates = {
  __typename?: 'LawyerSumAggregates';
  /** Sum of chargeRate across the matching connection */
  chargeRate: Scalars['Float']['output'];
  /** Sum of freeLeads across the matching connection */
  freeLeads: Scalars['BigInt']['output'];
  /** Sum of idealNumWeeklyMeetings across the matching connection */
  idealNumWeeklyMeetings: Scalars['BigInt']['output'];
  /** Sum of leadsPerMonth across the matching connection */
  leadsPerMonth: Scalars['BigInt']['output'];
};

export type LawyerTag = Node & {
  __typename?: 'LawyerTag';
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `Lawyer` that is related to this `LawyerTag`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Tag` that is related to this `LawyerTag`. */
  tagByTagId?: Maybe<Tag>;
  tagId: Scalars['UUID']['output'];
};

export type LawyerTagAggregates = {
  __typename?: 'LawyerTagAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LawyerTagDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `LawyerTag` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LawyerTagCondition = {
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `tagId` field. */
  tagId?: InputMaybe<Scalars['UUID']['input']>;
};

export type LawyerTagDistinctCountAggregates = {
  __typename?: 'LawyerTagDistinctCountAggregates';
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of tagId across the matching connection */
  tagId?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `LawyerTag` object types. All fields are combined with a logical ‘and.’ */
export type LawyerTagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LawyerTagFilter>>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LawyerTagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LawyerTagFilter>>;
  /** Filter by the object’s `tagId` field. */
  tagId?: InputMaybe<UuidFilter>;
};

/** Grouping methods for `LawyerTag` for usage during aggregation. */
export enum LawyerTagGroupBy {
  LawyerId = 'LAWYER_ID',
  TagId = 'TAG_ID'
}

/** Conditions for `LawyerTag` aggregates. */
export type LawyerTagHavingInput = {
  AND?: InputMaybe<Array<LawyerTagHavingInput>>;
  OR?: InputMaybe<Array<LawyerTagHavingInput>>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type LawyerTagSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: LawyerTagSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type LawyerTagSortBy = {
  field?: InputMaybe<LawyerTagSortableField>;
};

/** Sortable concrete fields for the `LawyerTag` type. */
export enum LawyerTagSortableField {
  LawyerId = 'LAWYER_ID',
  TagId = 'TAG_ID'
}

/** A connection to a list of `LawyerTag` values. */
export type LawyerTagsConnection = {
  __typename?: 'LawyerTagsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LawyerTagAggregates>;
  /** A list of edges which contains the `LawyerTag` and cursor to aid in pagination. */
  edges: Array<LawyerTagsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LawyerTagAggregates>>;
  /** A list of `LawyerTag` objects. */
  nodes: Array<Maybe<LawyerTag>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LawyerTag` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `LawyerTag` values. */
export type LawyerTagsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LawyerTagGroupBy>;
  having?: InputMaybe<LawyerTagHavingInput>;
};

/** A `LawyerTag` edge in the connection. */
export type LawyerTagsEdge = {
  __typename?: 'LawyerTagsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `LawyerTag` at the end of the edge. */
  node?: Maybe<LawyerTag>;
};

/** Methods to use when ordering `LawyerTag`. */
export enum LawyerTagsOrderBy {
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TagIdAsc = 'TAG_ID_ASC',
  TagIdDesc = 'TAG_ID_DESC'
}

export enum LawyerUserType {
  Internal = 'INTERNAL',
  Partner = 'PARTNER',
  ProductLedGrowth = 'PRODUCT_LED_GROWTH',
  Standard = 'STANDARD',
  TestMode = 'TEST_MODE'
}

/** A filter to be used against LawyerUserType fields. All fields are combined with a logical ‘and.’ */
export type LawyerUserTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LawyerUserType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LawyerUserType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LawyerUserType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LawyerUserType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LawyerUserType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LawyerUserType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LawyerUserType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LawyerUserType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LawyerUserType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LawyerUserType>>;
};

export type LawyerVariancePopulationAggregates = {
  __typename?: 'LawyerVariancePopulationAggregates';
  /** Population variance of chargeRate across the matching connection */
  chargeRate?: Maybe<Scalars['Float']['output']>;
  /** Population variance of freeLeads across the matching connection */
  freeLeads?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of idealNumWeeklyMeetings across the matching connection */
  idealNumWeeklyMeetings?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of leadsPerMonth across the matching connection */
  leadsPerMonth?: Maybe<Scalars['BigFloat']['output']>;
};

export type LawyerVarianceSampleAggregates = {
  __typename?: 'LawyerVarianceSampleAggregates';
  /** Sample variance of chargeRate across the matching connection */
  chargeRate?: Maybe<Scalars['Float']['output']>;
  /** Sample variance of freeLeads across the matching connection */
  freeLeads?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of idealNumWeeklyMeetings across the matching connection */
  idealNumWeeklyMeetings?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of leadsPerMonth across the matching connection */
  leadsPerMonth?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `Lawyer` values. */
export type LawyersConnection = {
  __typename?: 'LawyersConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LawyerAggregates>;
  /** A list of edges which contains the `Lawyer` and cursor to aid in pagination. */
  edges: Array<LawyersEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LawyerAggregates>>;
  /** A list of `Lawyer` objects. */
  nodes: Array<Maybe<Lawyer>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lawyer` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Lawyer` values. */
export type LawyersConnectionGroupedAggregatesArgs = {
  groupBy: Array<LawyerGroupBy>;
  having?: InputMaybe<LawyerHavingInput>;
};

/** A `Lawyer` edge in the connection. */
export type LawyersEdge = {
  __typename?: 'LawyersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Lawyer` at the end of the edge. */
  node?: Maybe<Lawyer>;
};

/** Methods to use when ordering `Lawyer`. */
export enum LawyersOrderBy {
  AcquisitionChannelAsc = 'ACQUISITION_CHANNEL_ASC',
  AcquisitionChannelDesc = 'ACQUISITION_CHANNEL_DESC',
  AcquisitionTypeAsc = 'ACQUISITION_TYPE_ASC',
  AcquisitionTypeDesc = 'ACQUISITION_TYPE_DESC',
  AliasesAsc = 'ALIASES_ASC',
  AliasesDesc = 'ALIASES_DESC',
  ArticlesByLawyerIdAverageAmpAsc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_AMP_ASC',
  ArticlesByLawyerIdAverageAmpDesc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_AMP_DESC',
  ArticlesByLawyerIdAverageContentAsc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_CONTENT_ASC',
  ArticlesByLawyerIdAverageContentDesc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_CONTENT_DESC',
  ArticlesByLawyerIdAverageCreatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  ArticlesByLawyerIdAverageCreatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  ArticlesByLawyerIdAverageDescriptionAsc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_DESCRIPTION_ASC',
  ArticlesByLawyerIdAverageDescriptionDesc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_DESCRIPTION_DESC',
  ArticlesByLawyerIdAverageIdAsc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_ID_ASC',
  ArticlesByLawyerIdAverageIdDesc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_ID_DESC',
  ArticlesByLawyerIdAverageImageUrlAsc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_IMAGE_URL_ASC',
  ArticlesByLawyerIdAverageImageUrlDesc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_IMAGE_URL_DESC',
  ArticlesByLawyerIdAverageLawyerIdAsc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  ArticlesByLawyerIdAverageLawyerIdDesc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  ArticlesByLawyerIdAverageSlugAsc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_SLUG_ASC',
  ArticlesByLawyerIdAverageSlugDesc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_SLUG_DESC',
  ArticlesByLawyerIdAverageTagsAsc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_TAGS_ASC',
  ArticlesByLawyerIdAverageTagsDesc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_TAGS_DESC',
  ArticlesByLawyerIdAverageTitleAsc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_TITLE_ASC',
  ArticlesByLawyerIdAverageTitleDesc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_TITLE_DESC',
  ArticlesByLawyerIdAverageUpdatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  ArticlesByLawyerIdAverageUpdatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  ArticlesByLawyerIdCountAsc = 'ARTICLES_BY_LAWYER_ID_COUNT_ASC',
  ArticlesByLawyerIdCountDesc = 'ARTICLES_BY_LAWYER_ID_COUNT_DESC',
  ArticlesByLawyerIdDistinctCountAmpAsc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_AMP_ASC',
  ArticlesByLawyerIdDistinctCountAmpDesc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_AMP_DESC',
  ArticlesByLawyerIdDistinctCountContentAsc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_CONTENT_ASC',
  ArticlesByLawyerIdDistinctCountContentDesc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_CONTENT_DESC',
  ArticlesByLawyerIdDistinctCountCreatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  ArticlesByLawyerIdDistinctCountCreatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  ArticlesByLawyerIdDistinctCountDescriptionAsc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_DESCRIPTION_ASC',
  ArticlesByLawyerIdDistinctCountDescriptionDesc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_DESCRIPTION_DESC',
  ArticlesByLawyerIdDistinctCountIdAsc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  ArticlesByLawyerIdDistinctCountIdDesc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  ArticlesByLawyerIdDistinctCountImageUrlAsc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_IMAGE_URL_ASC',
  ArticlesByLawyerIdDistinctCountImageUrlDesc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_IMAGE_URL_DESC',
  ArticlesByLawyerIdDistinctCountLawyerIdAsc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  ArticlesByLawyerIdDistinctCountLawyerIdDesc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  ArticlesByLawyerIdDistinctCountSlugAsc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_SLUG_ASC',
  ArticlesByLawyerIdDistinctCountSlugDesc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_SLUG_DESC',
  ArticlesByLawyerIdDistinctCountTagsAsc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_TAGS_ASC',
  ArticlesByLawyerIdDistinctCountTagsDesc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_TAGS_DESC',
  ArticlesByLawyerIdDistinctCountTitleAsc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_TITLE_ASC',
  ArticlesByLawyerIdDistinctCountTitleDesc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_TITLE_DESC',
  ArticlesByLawyerIdDistinctCountUpdatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  ArticlesByLawyerIdDistinctCountUpdatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  ArticlesByLawyerIdMaxAmpAsc = 'ARTICLES_BY_LAWYER_ID_MAX_AMP_ASC',
  ArticlesByLawyerIdMaxAmpDesc = 'ARTICLES_BY_LAWYER_ID_MAX_AMP_DESC',
  ArticlesByLawyerIdMaxContentAsc = 'ARTICLES_BY_LAWYER_ID_MAX_CONTENT_ASC',
  ArticlesByLawyerIdMaxContentDesc = 'ARTICLES_BY_LAWYER_ID_MAX_CONTENT_DESC',
  ArticlesByLawyerIdMaxCreatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  ArticlesByLawyerIdMaxCreatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  ArticlesByLawyerIdMaxDescriptionAsc = 'ARTICLES_BY_LAWYER_ID_MAX_DESCRIPTION_ASC',
  ArticlesByLawyerIdMaxDescriptionDesc = 'ARTICLES_BY_LAWYER_ID_MAX_DESCRIPTION_DESC',
  ArticlesByLawyerIdMaxIdAsc = 'ARTICLES_BY_LAWYER_ID_MAX_ID_ASC',
  ArticlesByLawyerIdMaxIdDesc = 'ARTICLES_BY_LAWYER_ID_MAX_ID_DESC',
  ArticlesByLawyerIdMaxImageUrlAsc = 'ARTICLES_BY_LAWYER_ID_MAX_IMAGE_URL_ASC',
  ArticlesByLawyerIdMaxImageUrlDesc = 'ARTICLES_BY_LAWYER_ID_MAX_IMAGE_URL_DESC',
  ArticlesByLawyerIdMaxLawyerIdAsc = 'ARTICLES_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  ArticlesByLawyerIdMaxLawyerIdDesc = 'ARTICLES_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  ArticlesByLawyerIdMaxSlugAsc = 'ARTICLES_BY_LAWYER_ID_MAX_SLUG_ASC',
  ArticlesByLawyerIdMaxSlugDesc = 'ARTICLES_BY_LAWYER_ID_MAX_SLUG_DESC',
  ArticlesByLawyerIdMaxTagsAsc = 'ARTICLES_BY_LAWYER_ID_MAX_TAGS_ASC',
  ArticlesByLawyerIdMaxTagsDesc = 'ARTICLES_BY_LAWYER_ID_MAX_TAGS_DESC',
  ArticlesByLawyerIdMaxTitleAsc = 'ARTICLES_BY_LAWYER_ID_MAX_TITLE_ASC',
  ArticlesByLawyerIdMaxTitleDesc = 'ARTICLES_BY_LAWYER_ID_MAX_TITLE_DESC',
  ArticlesByLawyerIdMaxUpdatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  ArticlesByLawyerIdMaxUpdatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  ArticlesByLawyerIdMinAmpAsc = 'ARTICLES_BY_LAWYER_ID_MIN_AMP_ASC',
  ArticlesByLawyerIdMinAmpDesc = 'ARTICLES_BY_LAWYER_ID_MIN_AMP_DESC',
  ArticlesByLawyerIdMinContentAsc = 'ARTICLES_BY_LAWYER_ID_MIN_CONTENT_ASC',
  ArticlesByLawyerIdMinContentDesc = 'ARTICLES_BY_LAWYER_ID_MIN_CONTENT_DESC',
  ArticlesByLawyerIdMinCreatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  ArticlesByLawyerIdMinCreatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  ArticlesByLawyerIdMinDescriptionAsc = 'ARTICLES_BY_LAWYER_ID_MIN_DESCRIPTION_ASC',
  ArticlesByLawyerIdMinDescriptionDesc = 'ARTICLES_BY_LAWYER_ID_MIN_DESCRIPTION_DESC',
  ArticlesByLawyerIdMinIdAsc = 'ARTICLES_BY_LAWYER_ID_MIN_ID_ASC',
  ArticlesByLawyerIdMinIdDesc = 'ARTICLES_BY_LAWYER_ID_MIN_ID_DESC',
  ArticlesByLawyerIdMinImageUrlAsc = 'ARTICLES_BY_LAWYER_ID_MIN_IMAGE_URL_ASC',
  ArticlesByLawyerIdMinImageUrlDesc = 'ARTICLES_BY_LAWYER_ID_MIN_IMAGE_URL_DESC',
  ArticlesByLawyerIdMinLawyerIdAsc = 'ARTICLES_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  ArticlesByLawyerIdMinLawyerIdDesc = 'ARTICLES_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  ArticlesByLawyerIdMinSlugAsc = 'ARTICLES_BY_LAWYER_ID_MIN_SLUG_ASC',
  ArticlesByLawyerIdMinSlugDesc = 'ARTICLES_BY_LAWYER_ID_MIN_SLUG_DESC',
  ArticlesByLawyerIdMinTagsAsc = 'ARTICLES_BY_LAWYER_ID_MIN_TAGS_ASC',
  ArticlesByLawyerIdMinTagsDesc = 'ARTICLES_BY_LAWYER_ID_MIN_TAGS_DESC',
  ArticlesByLawyerIdMinTitleAsc = 'ARTICLES_BY_LAWYER_ID_MIN_TITLE_ASC',
  ArticlesByLawyerIdMinTitleDesc = 'ARTICLES_BY_LAWYER_ID_MIN_TITLE_DESC',
  ArticlesByLawyerIdMinUpdatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  ArticlesByLawyerIdMinUpdatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  ArticlesByLawyerIdStddevPopulationAmpAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_AMP_ASC',
  ArticlesByLawyerIdStddevPopulationAmpDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_AMP_DESC',
  ArticlesByLawyerIdStddevPopulationContentAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_CONTENT_ASC',
  ArticlesByLawyerIdStddevPopulationContentDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_CONTENT_DESC',
  ArticlesByLawyerIdStddevPopulationCreatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  ArticlesByLawyerIdStddevPopulationCreatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  ArticlesByLawyerIdStddevPopulationDescriptionAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_DESCRIPTION_ASC',
  ArticlesByLawyerIdStddevPopulationDescriptionDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_DESCRIPTION_DESC',
  ArticlesByLawyerIdStddevPopulationIdAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  ArticlesByLawyerIdStddevPopulationIdDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  ArticlesByLawyerIdStddevPopulationImageUrlAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_IMAGE_URL_ASC',
  ArticlesByLawyerIdStddevPopulationImageUrlDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_IMAGE_URL_DESC',
  ArticlesByLawyerIdStddevPopulationLawyerIdAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  ArticlesByLawyerIdStddevPopulationLawyerIdDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  ArticlesByLawyerIdStddevPopulationSlugAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_SLUG_ASC',
  ArticlesByLawyerIdStddevPopulationSlugDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_SLUG_DESC',
  ArticlesByLawyerIdStddevPopulationTagsAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_TAGS_ASC',
  ArticlesByLawyerIdStddevPopulationTagsDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_TAGS_DESC',
  ArticlesByLawyerIdStddevPopulationTitleAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_TITLE_ASC',
  ArticlesByLawyerIdStddevPopulationTitleDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_TITLE_DESC',
  ArticlesByLawyerIdStddevPopulationUpdatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  ArticlesByLawyerIdStddevPopulationUpdatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  ArticlesByLawyerIdStddevSampleAmpAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_AMP_ASC',
  ArticlesByLawyerIdStddevSampleAmpDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_AMP_DESC',
  ArticlesByLawyerIdStddevSampleContentAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_CONTENT_ASC',
  ArticlesByLawyerIdStddevSampleContentDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_CONTENT_DESC',
  ArticlesByLawyerIdStddevSampleCreatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  ArticlesByLawyerIdStddevSampleCreatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  ArticlesByLawyerIdStddevSampleDescriptionAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ArticlesByLawyerIdStddevSampleDescriptionDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ArticlesByLawyerIdStddevSampleIdAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  ArticlesByLawyerIdStddevSampleIdDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  ArticlesByLawyerIdStddevSampleImageUrlAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_IMAGE_URL_ASC',
  ArticlesByLawyerIdStddevSampleImageUrlDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_IMAGE_URL_DESC',
  ArticlesByLawyerIdStddevSampleLawyerIdAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  ArticlesByLawyerIdStddevSampleLawyerIdDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  ArticlesByLawyerIdStddevSampleSlugAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_SLUG_ASC',
  ArticlesByLawyerIdStddevSampleSlugDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_SLUG_DESC',
  ArticlesByLawyerIdStddevSampleTagsAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_TAGS_ASC',
  ArticlesByLawyerIdStddevSampleTagsDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_TAGS_DESC',
  ArticlesByLawyerIdStddevSampleTitleAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_TITLE_ASC',
  ArticlesByLawyerIdStddevSampleTitleDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_TITLE_DESC',
  ArticlesByLawyerIdStddevSampleUpdatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  ArticlesByLawyerIdStddevSampleUpdatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  ArticlesByLawyerIdSumAmpAsc = 'ARTICLES_BY_LAWYER_ID_SUM_AMP_ASC',
  ArticlesByLawyerIdSumAmpDesc = 'ARTICLES_BY_LAWYER_ID_SUM_AMP_DESC',
  ArticlesByLawyerIdSumContentAsc = 'ARTICLES_BY_LAWYER_ID_SUM_CONTENT_ASC',
  ArticlesByLawyerIdSumContentDesc = 'ARTICLES_BY_LAWYER_ID_SUM_CONTENT_DESC',
  ArticlesByLawyerIdSumCreatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  ArticlesByLawyerIdSumCreatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  ArticlesByLawyerIdSumDescriptionAsc = 'ARTICLES_BY_LAWYER_ID_SUM_DESCRIPTION_ASC',
  ArticlesByLawyerIdSumDescriptionDesc = 'ARTICLES_BY_LAWYER_ID_SUM_DESCRIPTION_DESC',
  ArticlesByLawyerIdSumIdAsc = 'ARTICLES_BY_LAWYER_ID_SUM_ID_ASC',
  ArticlesByLawyerIdSumIdDesc = 'ARTICLES_BY_LAWYER_ID_SUM_ID_DESC',
  ArticlesByLawyerIdSumImageUrlAsc = 'ARTICLES_BY_LAWYER_ID_SUM_IMAGE_URL_ASC',
  ArticlesByLawyerIdSumImageUrlDesc = 'ARTICLES_BY_LAWYER_ID_SUM_IMAGE_URL_DESC',
  ArticlesByLawyerIdSumLawyerIdAsc = 'ARTICLES_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  ArticlesByLawyerIdSumLawyerIdDesc = 'ARTICLES_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  ArticlesByLawyerIdSumSlugAsc = 'ARTICLES_BY_LAWYER_ID_SUM_SLUG_ASC',
  ArticlesByLawyerIdSumSlugDesc = 'ARTICLES_BY_LAWYER_ID_SUM_SLUG_DESC',
  ArticlesByLawyerIdSumTagsAsc = 'ARTICLES_BY_LAWYER_ID_SUM_TAGS_ASC',
  ArticlesByLawyerIdSumTagsDesc = 'ARTICLES_BY_LAWYER_ID_SUM_TAGS_DESC',
  ArticlesByLawyerIdSumTitleAsc = 'ARTICLES_BY_LAWYER_ID_SUM_TITLE_ASC',
  ArticlesByLawyerIdSumTitleDesc = 'ARTICLES_BY_LAWYER_ID_SUM_TITLE_DESC',
  ArticlesByLawyerIdSumUpdatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  ArticlesByLawyerIdSumUpdatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  ArticlesByLawyerIdVariancePopulationAmpAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_AMP_ASC',
  ArticlesByLawyerIdVariancePopulationAmpDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_AMP_DESC',
  ArticlesByLawyerIdVariancePopulationContentAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_CONTENT_ASC',
  ArticlesByLawyerIdVariancePopulationContentDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_CONTENT_DESC',
  ArticlesByLawyerIdVariancePopulationCreatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  ArticlesByLawyerIdVariancePopulationCreatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  ArticlesByLawyerIdVariancePopulationDescriptionAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ArticlesByLawyerIdVariancePopulationDescriptionDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ArticlesByLawyerIdVariancePopulationIdAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  ArticlesByLawyerIdVariancePopulationIdDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  ArticlesByLawyerIdVariancePopulationImageUrlAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_IMAGE_URL_ASC',
  ArticlesByLawyerIdVariancePopulationImageUrlDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_IMAGE_URL_DESC',
  ArticlesByLawyerIdVariancePopulationLawyerIdAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  ArticlesByLawyerIdVariancePopulationLawyerIdDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  ArticlesByLawyerIdVariancePopulationSlugAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_SLUG_ASC',
  ArticlesByLawyerIdVariancePopulationSlugDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_SLUG_DESC',
  ArticlesByLawyerIdVariancePopulationTagsAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_TAGS_ASC',
  ArticlesByLawyerIdVariancePopulationTagsDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_TAGS_DESC',
  ArticlesByLawyerIdVariancePopulationTitleAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_TITLE_ASC',
  ArticlesByLawyerIdVariancePopulationTitleDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_TITLE_DESC',
  ArticlesByLawyerIdVariancePopulationUpdatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  ArticlesByLawyerIdVariancePopulationUpdatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  ArticlesByLawyerIdVarianceSampleAmpAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_AMP_ASC',
  ArticlesByLawyerIdVarianceSampleAmpDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_AMP_DESC',
  ArticlesByLawyerIdVarianceSampleContentAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  ArticlesByLawyerIdVarianceSampleContentDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  ArticlesByLawyerIdVarianceSampleCreatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  ArticlesByLawyerIdVarianceSampleCreatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  ArticlesByLawyerIdVarianceSampleDescriptionAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ArticlesByLawyerIdVarianceSampleDescriptionDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ArticlesByLawyerIdVarianceSampleIdAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  ArticlesByLawyerIdVarianceSampleIdDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  ArticlesByLawyerIdVarianceSampleImageUrlAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_IMAGE_URL_ASC',
  ArticlesByLawyerIdVarianceSampleImageUrlDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_IMAGE_URL_DESC',
  ArticlesByLawyerIdVarianceSampleLawyerIdAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  ArticlesByLawyerIdVarianceSampleLawyerIdDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  ArticlesByLawyerIdVarianceSampleSlugAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_SLUG_ASC',
  ArticlesByLawyerIdVarianceSampleSlugDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_SLUG_DESC',
  ArticlesByLawyerIdVarianceSampleTagsAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_TAGS_ASC',
  ArticlesByLawyerIdVarianceSampleTagsDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_TAGS_DESC',
  ArticlesByLawyerIdVarianceSampleTitleAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_TITLE_ASC',
  ArticlesByLawyerIdVarianceSampleTitleDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_TITLE_DESC',
  ArticlesByLawyerIdVarianceSampleUpdatedTimeAsc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  ArticlesByLawyerIdVarianceSampleUpdatedTimeDesc = 'ARTICLES_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdAverageCreatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdAverageCreatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdAverageDeletedAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_DELETED_ASC',
  BlacklistedOutreachesByLawyerIdAverageDeletedDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_DELETED_DESC',
  BlacklistedOutreachesByLawyerIdAverageEmailAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_EMAIL_ASC',
  BlacklistedOutreachesByLawyerIdAverageEmailDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_EMAIL_DESC',
  BlacklistedOutreachesByLawyerIdAverageEmailDomainAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_EMAIL_DOMAIN_ASC',
  BlacklistedOutreachesByLawyerIdAverageEmailDomainDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_EMAIL_DOMAIN_DESC',
  BlacklistedOutreachesByLawyerIdAverageIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_ID_ASC',
  BlacklistedOutreachesByLawyerIdAverageIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_ID_DESC',
  BlacklistedOutreachesByLawyerIdAverageLawyerIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  BlacklistedOutreachesByLawyerIdAverageLawyerIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  BlacklistedOutreachesByLawyerIdAverageLinkedinUrlAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_LINKEDIN_URL_ASC',
  BlacklistedOutreachesByLawyerIdAverageLinkedinUrlDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_LINKEDIN_URL_DESC',
  BlacklistedOutreachesByLawyerIdAverageReasonAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_REASON_ASC',
  BlacklistedOutreachesByLawyerIdAverageReasonDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_REASON_DESC',
  BlacklistedOutreachesByLawyerIdAverageUpdatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdAverageUpdatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdCountAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_COUNT_ASC',
  BlacklistedOutreachesByLawyerIdCountDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_COUNT_DESC',
  BlacklistedOutreachesByLawyerIdDistinctCountCreatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdDistinctCountCreatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdDistinctCountDeletedAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_DELETED_ASC',
  BlacklistedOutreachesByLawyerIdDistinctCountDeletedDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_DELETED_DESC',
  BlacklistedOutreachesByLawyerIdDistinctCountEmailAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_ASC',
  BlacklistedOutreachesByLawyerIdDistinctCountEmailDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_DESC',
  BlacklistedOutreachesByLawyerIdDistinctCountEmailDomainAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_DOMAIN_ASC',
  BlacklistedOutreachesByLawyerIdDistinctCountEmailDomainDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_DOMAIN_DESC',
  BlacklistedOutreachesByLawyerIdDistinctCountIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  BlacklistedOutreachesByLawyerIdDistinctCountIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  BlacklistedOutreachesByLawyerIdDistinctCountLawyerIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  BlacklistedOutreachesByLawyerIdDistinctCountLawyerIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  BlacklistedOutreachesByLawyerIdDistinctCountLinkedinUrlAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_LINKEDIN_URL_ASC',
  BlacklistedOutreachesByLawyerIdDistinctCountLinkedinUrlDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_LINKEDIN_URL_DESC',
  BlacklistedOutreachesByLawyerIdDistinctCountReasonAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_REASON_ASC',
  BlacklistedOutreachesByLawyerIdDistinctCountReasonDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_REASON_DESC',
  BlacklistedOutreachesByLawyerIdDistinctCountUpdatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdDistinctCountUpdatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdMaxCreatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdMaxCreatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdMaxDeletedAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_DELETED_ASC',
  BlacklistedOutreachesByLawyerIdMaxDeletedDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_DELETED_DESC',
  BlacklistedOutreachesByLawyerIdMaxEmailAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_EMAIL_ASC',
  BlacklistedOutreachesByLawyerIdMaxEmailDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_EMAIL_DESC',
  BlacklistedOutreachesByLawyerIdMaxEmailDomainAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_EMAIL_DOMAIN_ASC',
  BlacklistedOutreachesByLawyerIdMaxEmailDomainDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_EMAIL_DOMAIN_DESC',
  BlacklistedOutreachesByLawyerIdMaxIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_ID_ASC',
  BlacklistedOutreachesByLawyerIdMaxIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_ID_DESC',
  BlacklistedOutreachesByLawyerIdMaxLawyerIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  BlacklistedOutreachesByLawyerIdMaxLawyerIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  BlacklistedOutreachesByLawyerIdMaxLinkedinUrlAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_LINKEDIN_URL_ASC',
  BlacklistedOutreachesByLawyerIdMaxLinkedinUrlDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_LINKEDIN_URL_DESC',
  BlacklistedOutreachesByLawyerIdMaxReasonAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_REASON_ASC',
  BlacklistedOutreachesByLawyerIdMaxReasonDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_REASON_DESC',
  BlacklistedOutreachesByLawyerIdMaxUpdatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdMaxUpdatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdMinCreatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdMinCreatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdMinDeletedAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_DELETED_ASC',
  BlacklistedOutreachesByLawyerIdMinDeletedDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_DELETED_DESC',
  BlacklistedOutreachesByLawyerIdMinEmailAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_EMAIL_ASC',
  BlacklistedOutreachesByLawyerIdMinEmailDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_EMAIL_DESC',
  BlacklistedOutreachesByLawyerIdMinEmailDomainAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_EMAIL_DOMAIN_ASC',
  BlacklistedOutreachesByLawyerIdMinEmailDomainDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_EMAIL_DOMAIN_DESC',
  BlacklistedOutreachesByLawyerIdMinIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_ID_ASC',
  BlacklistedOutreachesByLawyerIdMinIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_ID_DESC',
  BlacklistedOutreachesByLawyerIdMinLawyerIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  BlacklistedOutreachesByLawyerIdMinLawyerIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  BlacklistedOutreachesByLawyerIdMinLinkedinUrlAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_LINKEDIN_URL_ASC',
  BlacklistedOutreachesByLawyerIdMinLinkedinUrlDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_LINKEDIN_URL_DESC',
  BlacklistedOutreachesByLawyerIdMinReasonAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_REASON_ASC',
  BlacklistedOutreachesByLawyerIdMinReasonDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_REASON_DESC',
  BlacklistedOutreachesByLawyerIdMinUpdatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdMinUpdatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdStddevPopulationCreatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdStddevPopulationCreatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdStddevPopulationDeletedAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_DELETED_ASC',
  BlacklistedOutreachesByLawyerIdStddevPopulationDeletedDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_DELETED_DESC',
  BlacklistedOutreachesByLawyerIdStddevPopulationEmailAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_ASC',
  BlacklistedOutreachesByLawyerIdStddevPopulationEmailDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_DESC',
  BlacklistedOutreachesByLawyerIdStddevPopulationEmailDomainAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_DOMAIN_ASC',
  BlacklistedOutreachesByLawyerIdStddevPopulationEmailDomainDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_DOMAIN_DESC',
  BlacklistedOutreachesByLawyerIdStddevPopulationIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  BlacklistedOutreachesByLawyerIdStddevPopulationIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  BlacklistedOutreachesByLawyerIdStddevPopulationLawyerIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  BlacklistedOutreachesByLawyerIdStddevPopulationLawyerIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  BlacklistedOutreachesByLawyerIdStddevPopulationLinkedinUrlAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_LINKEDIN_URL_ASC',
  BlacklistedOutreachesByLawyerIdStddevPopulationLinkedinUrlDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_LINKEDIN_URL_DESC',
  BlacklistedOutreachesByLawyerIdStddevPopulationReasonAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_REASON_ASC',
  BlacklistedOutreachesByLawyerIdStddevPopulationReasonDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_REASON_DESC',
  BlacklistedOutreachesByLawyerIdStddevPopulationUpdatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdStddevPopulationUpdatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdStddevSampleCreatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdStddevSampleCreatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdStddevSampleDeletedAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_DELETED_ASC',
  BlacklistedOutreachesByLawyerIdStddevSampleDeletedDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_DELETED_DESC',
  BlacklistedOutreachesByLawyerIdStddevSampleEmailAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_ASC',
  BlacklistedOutreachesByLawyerIdStddevSampleEmailDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_DESC',
  BlacklistedOutreachesByLawyerIdStddevSampleEmailDomainAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_DOMAIN_ASC',
  BlacklistedOutreachesByLawyerIdStddevSampleEmailDomainDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_DOMAIN_DESC',
  BlacklistedOutreachesByLawyerIdStddevSampleIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  BlacklistedOutreachesByLawyerIdStddevSampleIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  BlacklistedOutreachesByLawyerIdStddevSampleLawyerIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  BlacklistedOutreachesByLawyerIdStddevSampleLawyerIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  BlacklistedOutreachesByLawyerIdStddevSampleLinkedinUrlAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_LINKEDIN_URL_ASC',
  BlacklistedOutreachesByLawyerIdStddevSampleLinkedinUrlDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_LINKEDIN_URL_DESC',
  BlacklistedOutreachesByLawyerIdStddevSampleReasonAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_REASON_ASC',
  BlacklistedOutreachesByLawyerIdStddevSampleReasonDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_REASON_DESC',
  BlacklistedOutreachesByLawyerIdStddevSampleUpdatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdStddevSampleUpdatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdSumCreatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdSumCreatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdSumDeletedAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_DELETED_ASC',
  BlacklistedOutreachesByLawyerIdSumDeletedDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_DELETED_DESC',
  BlacklistedOutreachesByLawyerIdSumEmailAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_EMAIL_ASC',
  BlacklistedOutreachesByLawyerIdSumEmailDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_EMAIL_DESC',
  BlacklistedOutreachesByLawyerIdSumEmailDomainAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_EMAIL_DOMAIN_ASC',
  BlacklistedOutreachesByLawyerIdSumEmailDomainDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_EMAIL_DOMAIN_DESC',
  BlacklistedOutreachesByLawyerIdSumIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_ID_ASC',
  BlacklistedOutreachesByLawyerIdSumIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_ID_DESC',
  BlacklistedOutreachesByLawyerIdSumLawyerIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  BlacklistedOutreachesByLawyerIdSumLawyerIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  BlacklistedOutreachesByLawyerIdSumLinkedinUrlAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_LINKEDIN_URL_ASC',
  BlacklistedOutreachesByLawyerIdSumLinkedinUrlDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_LINKEDIN_URL_DESC',
  BlacklistedOutreachesByLawyerIdSumReasonAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_REASON_ASC',
  BlacklistedOutreachesByLawyerIdSumReasonDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_REASON_DESC',
  BlacklistedOutreachesByLawyerIdSumUpdatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdSumUpdatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdVariancePopulationCreatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdVariancePopulationCreatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdVariancePopulationDeletedAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_DELETED_ASC',
  BlacklistedOutreachesByLawyerIdVariancePopulationDeletedDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_DELETED_DESC',
  BlacklistedOutreachesByLawyerIdVariancePopulationEmailAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_ASC',
  BlacklistedOutreachesByLawyerIdVariancePopulationEmailDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_DESC',
  BlacklistedOutreachesByLawyerIdVariancePopulationEmailDomainAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_DOMAIN_ASC',
  BlacklistedOutreachesByLawyerIdVariancePopulationEmailDomainDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_DOMAIN_DESC',
  BlacklistedOutreachesByLawyerIdVariancePopulationIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  BlacklistedOutreachesByLawyerIdVariancePopulationIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  BlacklistedOutreachesByLawyerIdVariancePopulationLawyerIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  BlacklistedOutreachesByLawyerIdVariancePopulationLawyerIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  BlacklistedOutreachesByLawyerIdVariancePopulationLinkedinUrlAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_LINKEDIN_URL_ASC',
  BlacklistedOutreachesByLawyerIdVariancePopulationLinkedinUrlDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_LINKEDIN_URL_DESC',
  BlacklistedOutreachesByLawyerIdVariancePopulationReasonAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_REASON_ASC',
  BlacklistedOutreachesByLawyerIdVariancePopulationReasonDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_REASON_DESC',
  BlacklistedOutreachesByLawyerIdVariancePopulationUpdatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdVariancePopulationUpdatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdVarianceSampleCreatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdVarianceSampleCreatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  BlacklistedOutreachesByLawyerIdVarianceSampleDeletedAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_DELETED_ASC',
  BlacklistedOutreachesByLawyerIdVarianceSampleDeletedDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_DELETED_DESC',
  BlacklistedOutreachesByLawyerIdVarianceSampleEmailAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_ASC',
  BlacklistedOutreachesByLawyerIdVarianceSampleEmailDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_DESC',
  BlacklistedOutreachesByLawyerIdVarianceSampleEmailDomainAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_DOMAIN_ASC',
  BlacklistedOutreachesByLawyerIdVarianceSampleEmailDomainDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_DOMAIN_DESC',
  BlacklistedOutreachesByLawyerIdVarianceSampleIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  BlacklistedOutreachesByLawyerIdVarianceSampleIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  BlacklistedOutreachesByLawyerIdVarianceSampleLawyerIdAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  BlacklistedOutreachesByLawyerIdVarianceSampleLawyerIdDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  BlacklistedOutreachesByLawyerIdVarianceSampleLinkedinUrlAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_LINKEDIN_URL_ASC',
  BlacklistedOutreachesByLawyerIdVarianceSampleLinkedinUrlDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_LINKEDIN_URL_DESC',
  BlacklistedOutreachesByLawyerIdVarianceSampleReasonAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_REASON_ASC',
  BlacklistedOutreachesByLawyerIdVarianceSampleReasonDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_REASON_DESC',
  BlacklistedOutreachesByLawyerIdVarianceSampleUpdatedTimeAsc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  BlacklistedOutreachesByLawyerIdVarianceSampleUpdatedTimeDesc = 'BLACKLISTED_OUTREACHES_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  BrandColorAsc = 'BRAND_COLOR_ASC',
  BrandColorDesc = 'BRAND_COLOR_DESC',
  BudgetLogsByLawyerIdAverageCreatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  BudgetLogsByLawyerIdAverageCreatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  BudgetLogsByLawyerIdAverageIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  BudgetLogsByLawyerIdAverageIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  BudgetLogsByLawyerIdAverageLawyerIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  BudgetLogsByLawyerIdAverageLawyerIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  BudgetLogsByLawyerIdAverageNewBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_NEW_BUDGET_ASC',
  BudgetLogsByLawyerIdAverageNewBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_NEW_BUDGET_DESC',
  BudgetLogsByLawyerIdAverageNewCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_NEW_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdAverageNewCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_NEW_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdAverageNewLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_NEW_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdAverageNewLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_NEW_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdAveragePreviousBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_PREVIOUS_BUDGET_ASC',
  BudgetLogsByLawyerIdAveragePreviousBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_PREVIOUS_BUDGET_DESC',
  BudgetLogsByLawyerIdAveragePreviousCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_PREVIOUS_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdAveragePreviousCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_PREVIOUS_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdAveragePreviousLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_PREVIOUS_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdAveragePreviousLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_PREVIOUS_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdAverageUpdatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  BudgetLogsByLawyerIdAverageUpdatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  BudgetLogsByLawyerIdCountAsc = 'BUDGET_LOGS_BY_LAWYER_ID_COUNT_ASC',
  BudgetLogsByLawyerIdCountDesc = 'BUDGET_LOGS_BY_LAWYER_ID_COUNT_DESC',
  BudgetLogsByLawyerIdDistinctCountCreatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  BudgetLogsByLawyerIdDistinctCountCreatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  BudgetLogsByLawyerIdDistinctCountIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  BudgetLogsByLawyerIdDistinctCountIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  BudgetLogsByLawyerIdDistinctCountLawyerIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  BudgetLogsByLawyerIdDistinctCountLawyerIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  BudgetLogsByLawyerIdDistinctCountNewBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_NEW_BUDGET_ASC',
  BudgetLogsByLawyerIdDistinctCountNewBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_NEW_BUDGET_DESC',
  BudgetLogsByLawyerIdDistinctCountNewCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_NEW_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdDistinctCountNewCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_NEW_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdDistinctCountNewLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_NEW_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdDistinctCountNewLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_NEW_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdDistinctCountPreviousBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_PREVIOUS_BUDGET_ASC',
  BudgetLogsByLawyerIdDistinctCountPreviousBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_PREVIOUS_BUDGET_DESC',
  BudgetLogsByLawyerIdDistinctCountPreviousCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_PREVIOUS_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdDistinctCountPreviousCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_PREVIOUS_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdDistinctCountPreviousLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_PREVIOUS_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdDistinctCountPreviousLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_PREVIOUS_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdDistinctCountUpdatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  BudgetLogsByLawyerIdDistinctCountUpdatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  BudgetLogsByLawyerIdMaxCreatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  BudgetLogsByLawyerIdMaxCreatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  BudgetLogsByLawyerIdMaxIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_ID_ASC',
  BudgetLogsByLawyerIdMaxIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_ID_DESC',
  BudgetLogsByLawyerIdMaxLawyerIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  BudgetLogsByLawyerIdMaxLawyerIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  BudgetLogsByLawyerIdMaxNewBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_NEW_BUDGET_ASC',
  BudgetLogsByLawyerIdMaxNewBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_NEW_BUDGET_DESC',
  BudgetLogsByLawyerIdMaxNewCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_NEW_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdMaxNewCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_NEW_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdMaxNewLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_NEW_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdMaxNewLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_NEW_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdMaxPreviousBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_PREVIOUS_BUDGET_ASC',
  BudgetLogsByLawyerIdMaxPreviousBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_PREVIOUS_BUDGET_DESC',
  BudgetLogsByLawyerIdMaxPreviousCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_PREVIOUS_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdMaxPreviousCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_PREVIOUS_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdMaxPreviousLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_PREVIOUS_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdMaxPreviousLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_PREVIOUS_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdMaxUpdatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  BudgetLogsByLawyerIdMaxUpdatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  BudgetLogsByLawyerIdMinCreatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  BudgetLogsByLawyerIdMinCreatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  BudgetLogsByLawyerIdMinIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_ID_ASC',
  BudgetLogsByLawyerIdMinIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_ID_DESC',
  BudgetLogsByLawyerIdMinLawyerIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  BudgetLogsByLawyerIdMinLawyerIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  BudgetLogsByLawyerIdMinNewBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_NEW_BUDGET_ASC',
  BudgetLogsByLawyerIdMinNewBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_NEW_BUDGET_DESC',
  BudgetLogsByLawyerIdMinNewCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_NEW_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdMinNewCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_NEW_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdMinNewLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_NEW_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdMinNewLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_NEW_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdMinPreviousBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_PREVIOUS_BUDGET_ASC',
  BudgetLogsByLawyerIdMinPreviousBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_PREVIOUS_BUDGET_DESC',
  BudgetLogsByLawyerIdMinPreviousCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_PREVIOUS_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdMinPreviousCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_PREVIOUS_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdMinPreviousLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_PREVIOUS_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdMinPreviousLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_PREVIOUS_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdMinUpdatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  BudgetLogsByLawyerIdMinUpdatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  BudgetLogsByLawyerIdStddevPopulationCreatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  BudgetLogsByLawyerIdStddevPopulationCreatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  BudgetLogsByLawyerIdStddevPopulationIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  BudgetLogsByLawyerIdStddevPopulationIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  BudgetLogsByLawyerIdStddevPopulationLawyerIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  BudgetLogsByLawyerIdStddevPopulationLawyerIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  BudgetLogsByLawyerIdStddevPopulationNewBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_NEW_BUDGET_ASC',
  BudgetLogsByLawyerIdStddevPopulationNewBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_NEW_BUDGET_DESC',
  BudgetLogsByLawyerIdStddevPopulationNewCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_NEW_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdStddevPopulationNewCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_NEW_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdStddevPopulationNewLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_NEW_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdStddevPopulationNewLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_NEW_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdStddevPopulationPreviousBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_PREVIOUS_BUDGET_ASC',
  BudgetLogsByLawyerIdStddevPopulationPreviousBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_PREVIOUS_BUDGET_DESC',
  BudgetLogsByLawyerIdStddevPopulationPreviousCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_PREVIOUS_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdStddevPopulationPreviousCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_PREVIOUS_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdStddevPopulationPreviousLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_PREVIOUS_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdStddevPopulationPreviousLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_PREVIOUS_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdStddevPopulationUpdatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  BudgetLogsByLawyerIdStddevPopulationUpdatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  BudgetLogsByLawyerIdStddevSampleCreatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  BudgetLogsByLawyerIdStddevSampleCreatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  BudgetLogsByLawyerIdStddevSampleIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  BudgetLogsByLawyerIdStddevSampleIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  BudgetLogsByLawyerIdStddevSampleLawyerIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  BudgetLogsByLawyerIdStddevSampleLawyerIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  BudgetLogsByLawyerIdStddevSampleNewBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_NEW_BUDGET_ASC',
  BudgetLogsByLawyerIdStddevSampleNewBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_NEW_BUDGET_DESC',
  BudgetLogsByLawyerIdStddevSampleNewCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_NEW_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdStddevSampleNewCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_NEW_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdStddevSampleNewLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_NEW_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdStddevSampleNewLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_NEW_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdStddevSamplePreviousBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_PREVIOUS_BUDGET_ASC',
  BudgetLogsByLawyerIdStddevSamplePreviousBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_PREVIOUS_BUDGET_DESC',
  BudgetLogsByLawyerIdStddevSamplePreviousCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_PREVIOUS_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdStddevSamplePreviousCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_PREVIOUS_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdStddevSamplePreviousLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_PREVIOUS_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdStddevSamplePreviousLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_PREVIOUS_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdStddevSampleUpdatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  BudgetLogsByLawyerIdStddevSampleUpdatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  BudgetLogsByLawyerIdSumCreatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  BudgetLogsByLawyerIdSumCreatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  BudgetLogsByLawyerIdSumIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_ID_ASC',
  BudgetLogsByLawyerIdSumIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_ID_DESC',
  BudgetLogsByLawyerIdSumLawyerIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  BudgetLogsByLawyerIdSumLawyerIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  BudgetLogsByLawyerIdSumNewBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_NEW_BUDGET_ASC',
  BudgetLogsByLawyerIdSumNewBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_NEW_BUDGET_DESC',
  BudgetLogsByLawyerIdSumNewCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_NEW_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdSumNewCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_NEW_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdSumNewLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_NEW_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdSumNewLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_NEW_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdSumPreviousBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_PREVIOUS_BUDGET_ASC',
  BudgetLogsByLawyerIdSumPreviousBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_PREVIOUS_BUDGET_DESC',
  BudgetLogsByLawyerIdSumPreviousCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_PREVIOUS_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdSumPreviousCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_PREVIOUS_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdSumPreviousLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_PREVIOUS_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdSumPreviousLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_PREVIOUS_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdSumUpdatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  BudgetLogsByLawyerIdSumUpdatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  BudgetLogsByLawyerIdVariancePopulationCreatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  BudgetLogsByLawyerIdVariancePopulationCreatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  BudgetLogsByLawyerIdVariancePopulationIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  BudgetLogsByLawyerIdVariancePopulationIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  BudgetLogsByLawyerIdVariancePopulationLawyerIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  BudgetLogsByLawyerIdVariancePopulationLawyerIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  BudgetLogsByLawyerIdVariancePopulationNewBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_NEW_BUDGET_ASC',
  BudgetLogsByLawyerIdVariancePopulationNewBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_NEW_BUDGET_DESC',
  BudgetLogsByLawyerIdVariancePopulationNewCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_NEW_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdVariancePopulationNewCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_NEW_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdVariancePopulationNewLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_NEW_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdVariancePopulationNewLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_NEW_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdVariancePopulationPreviousBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_PREVIOUS_BUDGET_ASC',
  BudgetLogsByLawyerIdVariancePopulationPreviousBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_PREVIOUS_BUDGET_DESC',
  BudgetLogsByLawyerIdVariancePopulationPreviousCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_PREVIOUS_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdVariancePopulationPreviousCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_PREVIOUS_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdVariancePopulationPreviousLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_PREVIOUS_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdVariancePopulationPreviousLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_PREVIOUS_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdVariancePopulationUpdatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  BudgetLogsByLawyerIdVariancePopulationUpdatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  BudgetLogsByLawyerIdVarianceSampleCreatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  BudgetLogsByLawyerIdVarianceSampleCreatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  BudgetLogsByLawyerIdVarianceSampleIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  BudgetLogsByLawyerIdVarianceSampleIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  BudgetLogsByLawyerIdVarianceSampleLawyerIdAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  BudgetLogsByLawyerIdVarianceSampleLawyerIdDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  BudgetLogsByLawyerIdVarianceSampleNewBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_NEW_BUDGET_ASC',
  BudgetLogsByLawyerIdVarianceSampleNewBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_NEW_BUDGET_DESC',
  BudgetLogsByLawyerIdVarianceSampleNewCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_NEW_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdVarianceSampleNewCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_NEW_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdVarianceSampleNewLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_NEW_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdVarianceSampleNewLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_NEW_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdVarianceSamplePreviousBudgetAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_PREVIOUS_BUDGET_ASC',
  BudgetLogsByLawyerIdVarianceSamplePreviousBudgetDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_PREVIOUS_BUDGET_DESC',
  BudgetLogsByLawyerIdVarianceSamplePreviousCostPerLeadAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_PREVIOUS_COST_PER_LEAD_ASC',
  BudgetLogsByLawyerIdVarianceSamplePreviousCostPerLeadDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_PREVIOUS_COST_PER_LEAD_DESC',
  BudgetLogsByLawyerIdVarianceSamplePreviousLeadsPerMonthAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_PREVIOUS_LEADS_PER_MONTH_ASC',
  BudgetLogsByLawyerIdVarianceSamplePreviousLeadsPerMonthDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_PREVIOUS_LEADS_PER_MONTH_DESC',
  BudgetLogsByLawyerIdVarianceSampleUpdatedTimeAsc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  BudgetLogsByLawyerIdVarianceSampleUpdatedTimeDesc = 'BUDGET_LOGS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  CalendlyLinkAsc = 'CALENDLY_LINK_ASC',
  CalendlyLinkDesc = 'CALENDLY_LINK_DESC',
  CalendlyUriAsc = 'CALENDLY_URI_ASC',
  CalendlyUriDesc = 'CALENDLY_URI_DESC',
  CampaignsByLawyerIdAverageActivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_ACTIVATED_TIME_ASC',
  CampaignsByLawyerIdAverageActivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_ACTIVATED_TIME_DESC',
  CampaignsByLawyerIdAverageCampaignTargetIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_CAMPAIGN_TARGET_ID_ASC',
  CampaignsByLawyerIdAverageCampaignTargetIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_CAMPAIGN_TARGET_ID_DESC',
  CampaignsByLawyerIdAverageChannelAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_CHANNEL_ASC',
  CampaignsByLawyerIdAverageChannelDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_CHANNEL_DESC',
  CampaignsByLawyerIdAverageConversionScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_CONVERSION_SCORE_ASC',
  CampaignsByLawyerIdAverageConversionScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_CONVERSION_SCORE_DESC',
  CampaignsByLawyerIdAverageCreatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  CampaignsByLawyerIdAverageCreatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  CampaignsByLawyerIdAverageDeactivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_DEACTIVATED_TIME_ASC',
  CampaignsByLawyerIdAverageDeactivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_DEACTIVATED_TIME_DESC',
  CampaignsByLawyerIdAverageDeletedAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_DELETED_ASC',
  CampaignsByLawyerIdAverageDeletedDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_DELETED_DESC',
  CampaignsByLawyerIdAverageDraftedByLawyerAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_DRAFTED_BY_LAWYER_ASC',
  CampaignsByLawyerIdAverageDraftedByLawyerDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_DRAFTED_BY_LAWYER_DESC',
  CampaignsByLawyerIdAverageIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  CampaignsByLawyerIdAverageIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  CampaignsByLawyerIdAverageIsPersonalisedAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_IS_PERSONALISED_ASC',
  CampaignsByLawyerIdAverageIsPersonalisedDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_IS_PERSONALISED_DESC',
  CampaignsByLawyerIdAverageIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsByLawyerIdAverageIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsByLawyerIdAverageIsTestModeAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_IS_TEST_MODE_ASC',
  CampaignsByLawyerIdAverageIsTestModeDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_IS_TEST_MODE_DESC',
  CampaignsByLawyerIdAverageLawyerIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  CampaignsByLawyerIdAverageLawyerIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  CampaignsByLawyerIdAverageLawyerViewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  CampaignsByLawyerIdAverageLawyerViewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  CampaignsByLawyerIdAverageMaxAllocationAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_MAX_ALLOCATION_ASC',
  CampaignsByLawyerIdAverageMaxAllocationDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_MAX_ALLOCATION_DESC',
  CampaignsByLawyerIdAverageNameAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_NAME_ASC',
  CampaignsByLawyerIdAverageNameDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_NAME_DESC',
  CampaignsByLawyerIdAverageNotesAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_NOTES_ASC',
  CampaignsByLawyerIdAverageNotesDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_NOTES_DESC',
  CampaignsByLawyerIdAveragePatentQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_PATENT_QUERY_ASC',
  CampaignsByLawyerIdAveragePatentQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_PATENT_QUERY_DESC',
  CampaignsByLawyerIdAveragePersonalisationQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_PERSONALISATION_QUERY_ASC',
  CampaignsByLawyerIdAveragePersonalisationQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_PERSONALISATION_QUERY_DESC',
  CampaignsByLawyerIdAveragePriorityAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_PRIORITY_ASC',
  CampaignsByLawyerIdAveragePriorityDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_PRIORITY_DESC',
  CampaignsByLawyerIdAverageReviewedByAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_REVIEWED_BY_ASC',
  CampaignsByLawyerIdAverageReviewedByDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_REVIEWED_BY_DESC',
  CampaignsByLawyerIdAverageReviewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_REVIEWED_TIME_ASC',
  CampaignsByLawyerIdAverageReviewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_REVIEWED_TIME_DESC',
  CampaignsByLawyerIdAverageScheduledActivationTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsByLawyerIdAverageScheduledActivationTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsByLawyerIdAverageSearchAfterAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SEARCH_AFTER_ASC',
  CampaignsByLawyerIdAverageSearchAfterDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SEARCH_AFTER_DESC',
  CampaignsByLawyerIdAverageSearchAfterScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SEARCH_AFTER_SCORE_ASC',
  CampaignsByLawyerIdAverageSearchAfterScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SEARCH_AFTER_SCORE_DESC',
  CampaignsByLawyerIdAverageSearchBeforeAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SEARCH_BEFORE_ASC',
  CampaignsByLawyerIdAverageSearchBeforeDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SEARCH_BEFORE_DESC',
  CampaignsByLawyerIdAverageSearchStartedAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SEARCH_STARTED_ASC',
  CampaignsByLawyerIdAverageSearchStartedDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SEARCH_STARTED_DESC',
  CampaignsByLawyerIdAverageStatusAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_STATUS_ASC',
  CampaignsByLawyerIdAverageStatusDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_STATUS_DESC',
  CampaignsByLawyerIdAverageSubjectAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SUBJECT_ASC',
  CampaignsByLawyerIdAverageSubjectDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SUBJECT_DESC',
  CampaignsByLawyerIdAverageSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SUGGESTED_TARGETING_ID_ASC',
  CampaignsByLawyerIdAverageSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_SUGGESTED_TARGETING_ID_DESC',
  CampaignsByLawyerIdAverageTargetingRequestAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_TARGETING_REQUEST_ASC',
  CampaignsByLawyerIdAverageTargetingRequestDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_TARGETING_REQUEST_DESC',
  CampaignsByLawyerIdAverageTargetDescriptionAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_TARGET_DESCRIPTION_ASC',
  CampaignsByLawyerIdAverageTargetDescriptionDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_TARGET_DESCRIPTION_DESC',
  CampaignsByLawyerIdAverageUpdatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  CampaignsByLawyerIdAverageUpdatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  CampaignsByLawyerIdAverageUseLlmFilterAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_USE_LLM_FILTER_ASC',
  CampaignsByLawyerIdAverageUseLlmFilterDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_USE_LLM_FILTER_DESC',
  CampaignsByLawyerIdAverageVectorMatchThresholdAsc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsByLawyerIdAverageVectorMatchThresholdDesc = 'CAMPAIGNS_BY_LAWYER_ID_AVERAGE_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsByLawyerIdCountAsc = 'CAMPAIGNS_BY_LAWYER_ID_COUNT_ASC',
  CampaignsByLawyerIdCountDesc = 'CAMPAIGNS_BY_LAWYER_ID_COUNT_DESC',
  CampaignsByLawyerIdDistinctCountActivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_ACTIVATED_TIME_ASC',
  CampaignsByLawyerIdDistinctCountActivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_ACTIVATED_TIME_DESC',
  CampaignsByLawyerIdDistinctCountCampaignTargetIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_TARGET_ID_ASC',
  CampaignsByLawyerIdDistinctCountCampaignTargetIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_TARGET_ID_DESC',
  CampaignsByLawyerIdDistinctCountChannelAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_CHANNEL_ASC',
  CampaignsByLawyerIdDistinctCountChannelDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_CHANNEL_DESC',
  CampaignsByLawyerIdDistinctCountConversionScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_CONVERSION_SCORE_ASC',
  CampaignsByLawyerIdDistinctCountConversionScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_CONVERSION_SCORE_DESC',
  CampaignsByLawyerIdDistinctCountCreatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CampaignsByLawyerIdDistinctCountCreatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CampaignsByLawyerIdDistinctCountDeactivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_DEACTIVATED_TIME_ASC',
  CampaignsByLawyerIdDistinctCountDeactivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_DEACTIVATED_TIME_DESC',
  CampaignsByLawyerIdDistinctCountDeletedAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_DELETED_ASC',
  CampaignsByLawyerIdDistinctCountDeletedDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_DELETED_DESC',
  CampaignsByLawyerIdDistinctCountDraftedByLawyerAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_DRAFTED_BY_LAWYER_ASC',
  CampaignsByLawyerIdDistinctCountDraftedByLawyerDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_DRAFTED_BY_LAWYER_DESC',
  CampaignsByLawyerIdDistinctCountIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  CampaignsByLawyerIdDistinctCountIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  CampaignsByLawyerIdDistinctCountIsPersonalisedAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_IS_PERSONALISED_ASC',
  CampaignsByLawyerIdDistinctCountIsPersonalisedDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_IS_PERSONALISED_DESC',
  CampaignsByLawyerIdDistinctCountIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsByLawyerIdDistinctCountIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsByLawyerIdDistinctCountIsTestModeAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_IS_TEST_MODE_ASC',
  CampaignsByLawyerIdDistinctCountIsTestModeDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_IS_TEST_MODE_DESC',
  CampaignsByLawyerIdDistinctCountLawyerIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  CampaignsByLawyerIdDistinctCountLawyerIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  CampaignsByLawyerIdDistinctCountLawyerViewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  CampaignsByLawyerIdDistinctCountLawyerViewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  CampaignsByLawyerIdDistinctCountMaxAllocationAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_MAX_ALLOCATION_ASC',
  CampaignsByLawyerIdDistinctCountMaxAllocationDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_MAX_ALLOCATION_DESC',
  CampaignsByLawyerIdDistinctCountNameAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_NAME_ASC',
  CampaignsByLawyerIdDistinctCountNameDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_NAME_DESC',
  CampaignsByLawyerIdDistinctCountNotesAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_NOTES_ASC',
  CampaignsByLawyerIdDistinctCountNotesDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_NOTES_DESC',
  CampaignsByLawyerIdDistinctCountPatentQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_PATENT_QUERY_ASC',
  CampaignsByLawyerIdDistinctCountPatentQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_PATENT_QUERY_DESC',
  CampaignsByLawyerIdDistinctCountPersonalisationQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_PERSONALISATION_QUERY_ASC',
  CampaignsByLawyerIdDistinctCountPersonalisationQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_PERSONALISATION_QUERY_DESC',
  CampaignsByLawyerIdDistinctCountPriorityAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_PRIORITY_ASC',
  CampaignsByLawyerIdDistinctCountPriorityDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_PRIORITY_DESC',
  CampaignsByLawyerIdDistinctCountReviewedByAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_REVIEWED_BY_ASC',
  CampaignsByLawyerIdDistinctCountReviewedByDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_REVIEWED_BY_DESC',
  CampaignsByLawyerIdDistinctCountReviewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  CampaignsByLawyerIdDistinctCountReviewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  CampaignsByLawyerIdDistinctCountScheduledActivationTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsByLawyerIdDistinctCountScheduledActivationTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsByLawyerIdDistinctCountSearchAfterAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SEARCH_AFTER_ASC',
  CampaignsByLawyerIdDistinctCountSearchAfterDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SEARCH_AFTER_DESC',
  CampaignsByLawyerIdDistinctCountSearchAfterScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SEARCH_AFTER_SCORE_ASC',
  CampaignsByLawyerIdDistinctCountSearchAfterScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SEARCH_AFTER_SCORE_DESC',
  CampaignsByLawyerIdDistinctCountSearchBeforeAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SEARCH_BEFORE_ASC',
  CampaignsByLawyerIdDistinctCountSearchBeforeDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SEARCH_BEFORE_DESC',
  CampaignsByLawyerIdDistinctCountSearchStartedAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SEARCH_STARTED_ASC',
  CampaignsByLawyerIdDistinctCountSearchStartedDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SEARCH_STARTED_DESC',
  CampaignsByLawyerIdDistinctCountStatusAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_STATUS_ASC',
  CampaignsByLawyerIdDistinctCountStatusDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_STATUS_DESC',
  CampaignsByLawyerIdDistinctCountSubjectAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SUBJECT_ASC',
  CampaignsByLawyerIdDistinctCountSubjectDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SUBJECT_DESC',
  CampaignsByLawyerIdDistinctCountSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SUGGESTED_TARGETING_ID_ASC',
  CampaignsByLawyerIdDistinctCountSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_SUGGESTED_TARGETING_ID_DESC',
  CampaignsByLawyerIdDistinctCountTargetingRequestAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_TARGETING_REQUEST_ASC',
  CampaignsByLawyerIdDistinctCountTargetingRequestDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_TARGETING_REQUEST_DESC',
  CampaignsByLawyerIdDistinctCountTargetDescriptionAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_TARGET_DESCRIPTION_ASC',
  CampaignsByLawyerIdDistinctCountTargetDescriptionDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_TARGET_DESCRIPTION_DESC',
  CampaignsByLawyerIdDistinctCountUpdatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  CampaignsByLawyerIdDistinctCountUpdatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  CampaignsByLawyerIdDistinctCountUseLlmFilterAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_USE_LLM_FILTER_ASC',
  CampaignsByLawyerIdDistinctCountUseLlmFilterDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_USE_LLM_FILTER_DESC',
  CampaignsByLawyerIdDistinctCountVectorMatchThresholdAsc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsByLawyerIdDistinctCountVectorMatchThresholdDesc = 'CAMPAIGNS_BY_LAWYER_ID_DISTINCT_COUNT_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsByLawyerIdMaxActivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_ACTIVATED_TIME_ASC',
  CampaignsByLawyerIdMaxActivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_ACTIVATED_TIME_DESC',
  CampaignsByLawyerIdMaxCampaignTargetIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_CAMPAIGN_TARGET_ID_ASC',
  CampaignsByLawyerIdMaxCampaignTargetIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_CAMPAIGN_TARGET_ID_DESC',
  CampaignsByLawyerIdMaxChannelAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_CHANNEL_ASC',
  CampaignsByLawyerIdMaxChannelDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_CHANNEL_DESC',
  CampaignsByLawyerIdMaxConversionScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_CONVERSION_SCORE_ASC',
  CampaignsByLawyerIdMaxConversionScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_CONVERSION_SCORE_DESC',
  CampaignsByLawyerIdMaxCreatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  CampaignsByLawyerIdMaxCreatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  CampaignsByLawyerIdMaxDeactivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_DEACTIVATED_TIME_ASC',
  CampaignsByLawyerIdMaxDeactivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_DEACTIVATED_TIME_DESC',
  CampaignsByLawyerIdMaxDeletedAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_DELETED_ASC',
  CampaignsByLawyerIdMaxDeletedDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_DELETED_DESC',
  CampaignsByLawyerIdMaxDraftedByLawyerAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_DRAFTED_BY_LAWYER_ASC',
  CampaignsByLawyerIdMaxDraftedByLawyerDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_DRAFTED_BY_LAWYER_DESC',
  CampaignsByLawyerIdMaxIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_ID_ASC',
  CampaignsByLawyerIdMaxIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_ID_DESC',
  CampaignsByLawyerIdMaxIsPersonalisedAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_IS_PERSONALISED_ASC',
  CampaignsByLawyerIdMaxIsPersonalisedDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_IS_PERSONALISED_DESC',
  CampaignsByLawyerIdMaxIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsByLawyerIdMaxIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsByLawyerIdMaxIsTestModeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_IS_TEST_MODE_ASC',
  CampaignsByLawyerIdMaxIsTestModeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_IS_TEST_MODE_DESC',
  CampaignsByLawyerIdMaxLawyerIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  CampaignsByLawyerIdMaxLawyerIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  CampaignsByLawyerIdMaxLawyerViewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  CampaignsByLawyerIdMaxLawyerViewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  CampaignsByLawyerIdMaxMaxAllocationAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_MAX_ALLOCATION_ASC',
  CampaignsByLawyerIdMaxMaxAllocationDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_MAX_ALLOCATION_DESC',
  CampaignsByLawyerIdMaxNameAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_NAME_ASC',
  CampaignsByLawyerIdMaxNameDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_NAME_DESC',
  CampaignsByLawyerIdMaxNotesAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_NOTES_ASC',
  CampaignsByLawyerIdMaxNotesDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_NOTES_DESC',
  CampaignsByLawyerIdMaxPatentQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_PATENT_QUERY_ASC',
  CampaignsByLawyerIdMaxPatentQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_PATENT_QUERY_DESC',
  CampaignsByLawyerIdMaxPersonalisationQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_PERSONALISATION_QUERY_ASC',
  CampaignsByLawyerIdMaxPersonalisationQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_PERSONALISATION_QUERY_DESC',
  CampaignsByLawyerIdMaxPriorityAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_PRIORITY_ASC',
  CampaignsByLawyerIdMaxPriorityDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_PRIORITY_DESC',
  CampaignsByLawyerIdMaxReviewedByAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_REVIEWED_BY_ASC',
  CampaignsByLawyerIdMaxReviewedByDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_REVIEWED_BY_DESC',
  CampaignsByLawyerIdMaxReviewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_REVIEWED_TIME_ASC',
  CampaignsByLawyerIdMaxReviewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_REVIEWED_TIME_DESC',
  CampaignsByLawyerIdMaxScheduledActivationTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsByLawyerIdMaxScheduledActivationTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsByLawyerIdMaxSearchAfterAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SEARCH_AFTER_ASC',
  CampaignsByLawyerIdMaxSearchAfterDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SEARCH_AFTER_DESC',
  CampaignsByLawyerIdMaxSearchAfterScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SEARCH_AFTER_SCORE_ASC',
  CampaignsByLawyerIdMaxSearchAfterScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SEARCH_AFTER_SCORE_DESC',
  CampaignsByLawyerIdMaxSearchBeforeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SEARCH_BEFORE_ASC',
  CampaignsByLawyerIdMaxSearchBeforeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SEARCH_BEFORE_DESC',
  CampaignsByLawyerIdMaxSearchStartedAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SEARCH_STARTED_ASC',
  CampaignsByLawyerIdMaxSearchStartedDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SEARCH_STARTED_DESC',
  CampaignsByLawyerIdMaxStatusAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_STATUS_ASC',
  CampaignsByLawyerIdMaxStatusDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_STATUS_DESC',
  CampaignsByLawyerIdMaxSubjectAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SUBJECT_ASC',
  CampaignsByLawyerIdMaxSubjectDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SUBJECT_DESC',
  CampaignsByLawyerIdMaxSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SUGGESTED_TARGETING_ID_ASC',
  CampaignsByLawyerIdMaxSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_SUGGESTED_TARGETING_ID_DESC',
  CampaignsByLawyerIdMaxTargetingRequestAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_TARGETING_REQUEST_ASC',
  CampaignsByLawyerIdMaxTargetingRequestDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_TARGETING_REQUEST_DESC',
  CampaignsByLawyerIdMaxTargetDescriptionAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_TARGET_DESCRIPTION_ASC',
  CampaignsByLawyerIdMaxTargetDescriptionDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_TARGET_DESCRIPTION_DESC',
  CampaignsByLawyerIdMaxUpdatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  CampaignsByLawyerIdMaxUpdatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  CampaignsByLawyerIdMaxUseLlmFilterAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_USE_LLM_FILTER_ASC',
  CampaignsByLawyerIdMaxUseLlmFilterDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_USE_LLM_FILTER_DESC',
  CampaignsByLawyerIdMaxVectorMatchThresholdAsc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsByLawyerIdMaxVectorMatchThresholdDesc = 'CAMPAIGNS_BY_LAWYER_ID_MAX_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsByLawyerIdMinActivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_ACTIVATED_TIME_ASC',
  CampaignsByLawyerIdMinActivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_ACTIVATED_TIME_DESC',
  CampaignsByLawyerIdMinCampaignTargetIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_CAMPAIGN_TARGET_ID_ASC',
  CampaignsByLawyerIdMinCampaignTargetIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_CAMPAIGN_TARGET_ID_DESC',
  CampaignsByLawyerIdMinChannelAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_CHANNEL_ASC',
  CampaignsByLawyerIdMinChannelDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_CHANNEL_DESC',
  CampaignsByLawyerIdMinConversionScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_CONVERSION_SCORE_ASC',
  CampaignsByLawyerIdMinConversionScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_CONVERSION_SCORE_DESC',
  CampaignsByLawyerIdMinCreatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  CampaignsByLawyerIdMinCreatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  CampaignsByLawyerIdMinDeactivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_DEACTIVATED_TIME_ASC',
  CampaignsByLawyerIdMinDeactivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_DEACTIVATED_TIME_DESC',
  CampaignsByLawyerIdMinDeletedAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_DELETED_ASC',
  CampaignsByLawyerIdMinDeletedDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_DELETED_DESC',
  CampaignsByLawyerIdMinDraftedByLawyerAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_DRAFTED_BY_LAWYER_ASC',
  CampaignsByLawyerIdMinDraftedByLawyerDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_DRAFTED_BY_LAWYER_DESC',
  CampaignsByLawyerIdMinIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_ID_ASC',
  CampaignsByLawyerIdMinIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_ID_DESC',
  CampaignsByLawyerIdMinIsPersonalisedAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_IS_PERSONALISED_ASC',
  CampaignsByLawyerIdMinIsPersonalisedDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_IS_PERSONALISED_DESC',
  CampaignsByLawyerIdMinIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsByLawyerIdMinIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsByLawyerIdMinIsTestModeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_IS_TEST_MODE_ASC',
  CampaignsByLawyerIdMinIsTestModeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_IS_TEST_MODE_DESC',
  CampaignsByLawyerIdMinLawyerIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  CampaignsByLawyerIdMinLawyerIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  CampaignsByLawyerIdMinLawyerViewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  CampaignsByLawyerIdMinLawyerViewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  CampaignsByLawyerIdMinMaxAllocationAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_MAX_ALLOCATION_ASC',
  CampaignsByLawyerIdMinMaxAllocationDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_MAX_ALLOCATION_DESC',
  CampaignsByLawyerIdMinNameAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_NAME_ASC',
  CampaignsByLawyerIdMinNameDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_NAME_DESC',
  CampaignsByLawyerIdMinNotesAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_NOTES_ASC',
  CampaignsByLawyerIdMinNotesDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_NOTES_DESC',
  CampaignsByLawyerIdMinPatentQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_PATENT_QUERY_ASC',
  CampaignsByLawyerIdMinPatentQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_PATENT_QUERY_DESC',
  CampaignsByLawyerIdMinPersonalisationQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_PERSONALISATION_QUERY_ASC',
  CampaignsByLawyerIdMinPersonalisationQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_PERSONALISATION_QUERY_DESC',
  CampaignsByLawyerIdMinPriorityAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_PRIORITY_ASC',
  CampaignsByLawyerIdMinPriorityDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_PRIORITY_DESC',
  CampaignsByLawyerIdMinReviewedByAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_REVIEWED_BY_ASC',
  CampaignsByLawyerIdMinReviewedByDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_REVIEWED_BY_DESC',
  CampaignsByLawyerIdMinReviewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_REVIEWED_TIME_ASC',
  CampaignsByLawyerIdMinReviewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_REVIEWED_TIME_DESC',
  CampaignsByLawyerIdMinScheduledActivationTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsByLawyerIdMinScheduledActivationTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsByLawyerIdMinSearchAfterAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SEARCH_AFTER_ASC',
  CampaignsByLawyerIdMinSearchAfterDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SEARCH_AFTER_DESC',
  CampaignsByLawyerIdMinSearchAfterScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SEARCH_AFTER_SCORE_ASC',
  CampaignsByLawyerIdMinSearchAfterScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SEARCH_AFTER_SCORE_DESC',
  CampaignsByLawyerIdMinSearchBeforeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SEARCH_BEFORE_ASC',
  CampaignsByLawyerIdMinSearchBeforeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SEARCH_BEFORE_DESC',
  CampaignsByLawyerIdMinSearchStartedAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SEARCH_STARTED_ASC',
  CampaignsByLawyerIdMinSearchStartedDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SEARCH_STARTED_DESC',
  CampaignsByLawyerIdMinStatusAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_STATUS_ASC',
  CampaignsByLawyerIdMinStatusDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_STATUS_DESC',
  CampaignsByLawyerIdMinSubjectAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SUBJECT_ASC',
  CampaignsByLawyerIdMinSubjectDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SUBJECT_DESC',
  CampaignsByLawyerIdMinSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SUGGESTED_TARGETING_ID_ASC',
  CampaignsByLawyerIdMinSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_SUGGESTED_TARGETING_ID_DESC',
  CampaignsByLawyerIdMinTargetingRequestAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_TARGETING_REQUEST_ASC',
  CampaignsByLawyerIdMinTargetingRequestDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_TARGETING_REQUEST_DESC',
  CampaignsByLawyerIdMinTargetDescriptionAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_TARGET_DESCRIPTION_ASC',
  CampaignsByLawyerIdMinTargetDescriptionDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_TARGET_DESCRIPTION_DESC',
  CampaignsByLawyerIdMinUpdatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  CampaignsByLawyerIdMinUpdatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  CampaignsByLawyerIdMinUseLlmFilterAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_USE_LLM_FILTER_ASC',
  CampaignsByLawyerIdMinUseLlmFilterDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_USE_LLM_FILTER_DESC',
  CampaignsByLawyerIdMinVectorMatchThresholdAsc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsByLawyerIdMinVectorMatchThresholdDesc = 'CAMPAIGNS_BY_LAWYER_ID_MIN_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsByLawyerIdStddevPopulationActivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_ACTIVATED_TIME_ASC',
  CampaignsByLawyerIdStddevPopulationActivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_ACTIVATED_TIME_DESC',
  CampaignsByLawyerIdStddevPopulationCampaignTargetIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_TARGET_ID_ASC',
  CampaignsByLawyerIdStddevPopulationCampaignTargetIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_TARGET_ID_DESC',
  CampaignsByLawyerIdStddevPopulationChannelAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_CHANNEL_ASC',
  CampaignsByLawyerIdStddevPopulationChannelDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_CHANNEL_DESC',
  CampaignsByLawyerIdStddevPopulationConversionScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_CONVERSION_SCORE_ASC',
  CampaignsByLawyerIdStddevPopulationConversionScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_CONVERSION_SCORE_DESC',
  CampaignsByLawyerIdStddevPopulationCreatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CampaignsByLawyerIdStddevPopulationCreatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CampaignsByLawyerIdStddevPopulationDeactivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_DEACTIVATED_TIME_ASC',
  CampaignsByLawyerIdStddevPopulationDeactivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_DEACTIVATED_TIME_DESC',
  CampaignsByLawyerIdStddevPopulationDeletedAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_DELETED_ASC',
  CampaignsByLawyerIdStddevPopulationDeletedDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_DELETED_DESC',
  CampaignsByLawyerIdStddevPopulationDraftedByLawyerAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_DRAFTED_BY_LAWYER_ASC',
  CampaignsByLawyerIdStddevPopulationDraftedByLawyerDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_DRAFTED_BY_LAWYER_DESC',
  CampaignsByLawyerIdStddevPopulationIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  CampaignsByLawyerIdStddevPopulationIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  CampaignsByLawyerIdStddevPopulationIsPersonalisedAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_IS_PERSONALISED_ASC',
  CampaignsByLawyerIdStddevPopulationIsPersonalisedDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_IS_PERSONALISED_DESC',
  CampaignsByLawyerIdStddevPopulationIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsByLawyerIdStddevPopulationIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsByLawyerIdStddevPopulationIsTestModeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_IS_TEST_MODE_ASC',
  CampaignsByLawyerIdStddevPopulationIsTestModeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_IS_TEST_MODE_DESC',
  CampaignsByLawyerIdStddevPopulationLawyerIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  CampaignsByLawyerIdStddevPopulationLawyerIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  CampaignsByLawyerIdStddevPopulationLawyerViewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  CampaignsByLawyerIdStddevPopulationLawyerViewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  CampaignsByLawyerIdStddevPopulationMaxAllocationAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_MAX_ALLOCATION_ASC',
  CampaignsByLawyerIdStddevPopulationMaxAllocationDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_MAX_ALLOCATION_DESC',
  CampaignsByLawyerIdStddevPopulationNameAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_NAME_ASC',
  CampaignsByLawyerIdStddevPopulationNameDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_NAME_DESC',
  CampaignsByLawyerIdStddevPopulationNotesAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_NOTES_ASC',
  CampaignsByLawyerIdStddevPopulationNotesDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_NOTES_DESC',
  CampaignsByLawyerIdStddevPopulationPatentQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_PATENT_QUERY_ASC',
  CampaignsByLawyerIdStddevPopulationPatentQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_PATENT_QUERY_DESC',
  CampaignsByLawyerIdStddevPopulationPersonalisationQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_PERSONALISATION_QUERY_ASC',
  CampaignsByLawyerIdStddevPopulationPersonalisationQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_PERSONALISATION_QUERY_DESC',
  CampaignsByLawyerIdStddevPopulationPriorityAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_PRIORITY_ASC',
  CampaignsByLawyerIdStddevPopulationPriorityDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_PRIORITY_DESC',
  CampaignsByLawyerIdStddevPopulationReviewedByAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_REVIEWED_BY_ASC',
  CampaignsByLawyerIdStddevPopulationReviewedByDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_REVIEWED_BY_DESC',
  CampaignsByLawyerIdStddevPopulationReviewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  CampaignsByLawyerIdStddevPopulationReviewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  CampaignsByLawyerIdStddevPopulationScheduledActivationTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsByLawyerIdStddevPopulationScheduledActivationTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsByLawyerIdStddevPopulationSearchAfterAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SEARCH_AFTER_ASC',
  CampaignsByLawyerIdStddevPopulationSearchAfterDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SEARCH_AFTER_DESC',
  CampaignsByLawyerIdStddevPopulationSearchAfterScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SEARCH_AFTER_SCORE_ASC',
  CampaignsByLawyerIdStddevPopulationSearchAfterScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SEARCH_AFTER_SCORE_DESC',
  CampaignsByLawyerIdStddevPopulationSearchBeforeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SEARCH_BEFORE_ASC',
  CampaignsByLawyerIdStddevPopulationSearchBeforeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SEARCH_BEFORE_DESC',
  CampaignsByLawyerIdStddevPopulationSearchStartedAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SEARCH_STARTED_ASC',
  CampaignsByLawyerIdStddevPopulationSearchStartedDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SEARCH_STARTED_DESC',
  CampaignsByLawyerIdStddevPopulationStatusAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_STATUS_ASC',
  CampaignsByLawyerIdStddevPopulationStatusDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_STATUS_DESC',
  CampaignsByLawyerIdStddevPopulationSubjectAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SUBJECT_ASC',
  CampaignsByLawyerIdStddevPopulationSubjectDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SUBJECT_DESC',
  CampaignsByLawyerIdStddevPopulationSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SUGGESTED_TARGETING_ID_ASC',
  CampaignsByLawyerIdStddevPopulationSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_SUGGESTED_TARGETING_ID_DESC',
  CampaignsByLawyerIdStddevPopulationTargetingRequestAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_TARGETING_REQUEST_ASC',
  CampaignsByLawyerIdStddevPopulationTargetingRequestDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_TARGETING_REQUEST_DESC',
  CampaignsByLawyerIdStddevPopulationTargetDescriptionAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_TARGET_DESCRIPTION_ASC',
  CampaignsByLawyerIdStddevPopulationTargetDescriptionDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_TARGET_DESCRIPTION_DESC',
  CampaignsByLawyerIdStddevPopulationUpdatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  CampaignsByLawyerIdStddevPopulationUpdatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  CampaignsByLawyerIdStddevPopulationUseLlmFilterAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_USE_LLM_FILTER_ASC',
  CampaignsByLawyerIdStddevPopulationUseLlmFilterDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_USE_LLM_FILTER_DESC',
  CampaignsByLawyerIdStddevPopulationVectorMatchThresholdAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsByLawyerIdStddevPopulationVectorMatchThresholdDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_POPULATION_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsByLawyerIdStddevSampleActivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_ACTIVATED_TIME_ASC',
  CampaignsByLawyerIdStddevSampleActivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_ACTIVATED_TIME_DESC',
  CampaignsByLawyerIdStddevSampleCampaignTargetIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_TARGET_ID_ASC',
  CampaignsByLawyerIdStddevSampleCampaignTargetIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_TARGET_ID_DESC',
  CampaignsByLawyerIdStddevSampleChannelAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_CHANNEL_ASC',
  CampaignsByLawyerIdStddevSampleChannelDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_CHANNEL_DESC',
  CampaignsByLawyerIdStddevSampleConversionScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_CONVERSION_SCORE_ASC',
  CampaignsByLawyerIdStddevSampleConversionScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_CONVERSION_SCORE_DESC',
  CampaignsByLawyerIdStddevSampleCreatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CampaignsByLawyerIdStddevSampleCreatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CampaignsByLawyerIdStddevSampleDeactivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_DEACTIVATED_TIME_ASC',
  CampaignsByLawyerIdStddevSampleDeactivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_DEACTIVATED_TIME_DESC',
  CampaignsByLawyerIdStddevSampleDeletedAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_DELETED_ASC',
  CampaignsByLawyerIdStddevSampleDeletedDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_DELETED_DESC',
  CampaignsByLawyerIdStddevSampleDraftedByLawyerAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_DRAFTED_BY_LAWYER_ASC',
  CampaignsByLawyerIdStddevSampleDraftedByLawyerDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_DRAFTED_BY_LAWYER_DESC',
  CampaignsByLawyerIdStddevSampleIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  CampaignsByLawyerIdStddevSampleIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  CampaignsByLawyerIdStddevSampleIsPersonalisedAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_IS_PERSONALISED_ASC',
  CampaignsByLawyerIdStddevSampleIsPersonalisedDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_IS_PERSONALISED_DESC',
  CampaignsByLawyerIdStddevSampleIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsByLawyerIdStddevSampleIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsByLawyerIdStddevSampleIsTestModeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_IS_TEST_MODE_ASC',
  CampaignsByLawyerIdStddevSampleIsTestModeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_IS_TEST_MODE_DESC',
  CampaignsByLawyerIdStddevSampleLawyerIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  CampaignsByLawyerIdStddevSampleLawyerIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  CampaignsByLawyerIdStddevSampleLawyerViewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  CampaignsByLawyerIdStddevSampleLawyerViewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  CampaignsByLawyerIdStddevSampleMaxAllocationAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_MAX_ALLOCATION_ASC',
  CampaignsByLawyerIdStddevSampleMaxAllocationDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_MAX_ALLOCATION_DESC',
  CampaignsByLawyerIdStddevSampleNameAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_NAME_ASC',
  CampaignsByLawyerIdStddevSampleNameDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_NAME_DESC',
  CampaignsByLawyerIdStddevSampleNotesAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_NOTES_ASC',
  CampaignsByLawyerIdStddevSampleNotesDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_NOTES_DESC',
  CampaignsByLawyerIdStddevSamplePatentQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_PATENT_QUERY_ASC',
  CampaignsByLawyerIdStddevSamplePatentQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_PATENT_QUERY_DESC',
  CampaignsByLawyerIdStddevSamplePersonalisationQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_PERSONALISATION_QUERY_ASC',
  CampaignsByLawyerIdStddevSamplePersonalisationQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_PERSONALISATION_QUERY_DESC',
  CampaignsByLawyerIdStddevSamplePriorityAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_PRIORITY_ASC',
  CampaignsByLawyerIdStddevSamplePriorityDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_PRIORITY_DESC',
  CampaignsByLawyerIdStddevSampleReviewedByAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_REVIEWED_BY_ASC',
  CampaignsByLawyerIdStddevSampleReviewedByDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_REVIEWED_BY_DESC',
  CampaignsByLawyerIdStddevSampleReviewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  CampaignsByLawyerIdStddevSampleReviewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  CampaignsByLawyerIdStddevSampleScheduledActivationTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsByLawyerIdStddevSampleScheduledActivationTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsByLawyerIdStddevSampleSearchAfterAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SEARCH_AFTER_ASC',
  CampaignsByLawyerIdStddevSampleSearchAfterDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SEARCH_AFTER_DESC',
  CampaignsByLawyerIdStddevSampleSearchAfterScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SEARCH_AFTER_SCORE_ASC',
  CampaignsByLawyerIdStddevSampleSearchAfterScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SEARCH_AFTER_SCORE_DESC',
  CampaignsByLawyerIdStddevSampleSearchBeforeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SEARCH_BEFORE_ASC',
  CampaignsByLawyerIdStddevSampleSearchBeforeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SEARCH_BEFORE_DESC',
  CampaignsByLawyerIdStddevSampleSearchStartedAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SEARCH_STARTED_ASC',
  CampaignsByLawyerIdStddevSampleSearchStartedDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SEARCH_STARTED_DESC',
  CampaignsByLawyerIdStddevSampleStatusAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_STATUS_ASC',
  CampaignsByLawyerIdStddevSampleStatusDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_STATUS_DESC',
  CampaignsByLawyerIdStddevSampleSubjectAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SUBJECT_ASC',
  CampaignsByLawyerIdStddevSampleSubjectDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SUBJECT_DESC',
  CampaignsByLawyerIdStddevSampleSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SUGGESTED_TARGETING_ID_ASC',
  CampaignsByLawyerIdStddevSampleSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_SUGGESTED_TARGETING_ID_DESC',
  CampaignsByLawyerIdStddevSampleTargetingRequestAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_TARGETING_REQUEST_ASC',
  CampaignsByLawyerIdStddevSampleTargetingRequestDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_TARGETING_REQUEST_DESC',
  CampaignsByLawyerIdStddevSampleTargetDescriptionAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_TARGET_DESCRIPTION_ASC',
  CampaignsByLawyerIdStddevSampleTargetDescriptionDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_TARGET_DESCRIPTION_DESC',
  CampaignsByLawyerIdStddevSampleUpdatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  CampaignsByLawyerIdStddevSampleUpdatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  CampaignsByLawyerIdStddevSampleUseLlmFilterAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_USE_LLM_FILTER_ASC',
  CampaignsByLawyerIdStddevSampleUseLlmFilterDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_USE_LLM_FILTER_DESC',
  CampaignsByLawyerIdStddevSampleVectorMatchThresholdAsc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsByLawyerIdStddevSampleVectorMatchThresholdDesc = 'CAMPAIGNS_BY_LAWYER_ID_STDDEV_SAMPLE_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsByLawyerIdSumActivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_ACTIVATED_TIME_ASC',
  CampaignsByLawyerIdSumActivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_ACTIVATED_TIME_DESC',
  CampaignsByLawyerIdSumCampaignTargetIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_CAMPAIGN_TARGET_ID_ASC',
  CampaignsByLawyerIdSumCampaignTargetIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_CAMPAIGN_TARGET_ID_DESC',
  CampaignsByLawyerIdSumChannelAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_CHANNEL_ASC',
  CampaignsByLawyerIdSumChannelDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_CHANNEL_DESC',
  CampaignsByLawyerIdSumConversionScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_CONVERSION_SCORE_ASC',
  CampaignsByLawyerIdSumConversionScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_CONVERSION_SCORE_DESC',
  CampaignsByLawyerIdSumCreatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  CampaignsByLawyerIdSumCreatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  CampaignsByLawyerIdSumDeactivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_DEACTIVATED_TIME_ASC',
  CampaignsByLawyerIdSumDeactivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_DEACTIVATED_TIME_DESC',
  CampaignsByLawyerIdSumDeletedAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_DELETED_ASC',
  CampaignsByLawyerIdSumDeletedDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_DELETED_DESC',
  CampaignsByLawyerIdSumDraftedByLawyerAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_DRAFTED_BY_LAWYER_ASC',
  CampaignsByLawyerIdSumDraftedByLawyerDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_DRAFTED_BY_LAWYER_DESC',
  CampaignsByLawyerIdSumIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_ID_ASC',
  CampaignsByLawyerIdSumIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_ID_DESC',
  CampaignsByLawyerIdSumIsPersonalisedAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_IS_PERSONALISED_ASC',
  CampaignsByLawyerIdSumIsPersonalisedDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_IS_PERSONALISED_DESC',
  CampaignsByLawyerIdSumIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsByLawyerIdSumIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsByLawyerIdSumIsTestModeAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_IS_TEST_MODE_ASC',
  CampaignsByLawyerIdSumIsTestModeDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_IS_TEST_MODE_DESC',
  CampaignsByLawyerIdSumLawyerIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  CampaignsByLawyerIdSumLawyerIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  CampaignsByLawyerIdSumLawyerViewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  CampaignsByLawyerIdSumLawyerViewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  CampaignsByLawyerIdSumMaxAllocationAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_MAX_ALLOCATION_ASC',
  CampaignsByLawyerIdSumMaxAllocationDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_MAX_ALLOCATION_DESC',
  CampaignsByLawyerIdSumNameAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_NAME_ASC',
  CampaignsByLawyerIdSumNameDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_NAME_DESC',
  CampaignsByLawyerIdSumNotesAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_NOTES_ASC',
  CampaignsByLawyerIdSumNotesDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_NOTES_DESC',
  CampaignsByLawyerIdSumPatentQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_PATENT_QUERY_ASC',
  CampaignsByLawyerIdSumPatentQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_PATENT_QUERY_DESC',
  CampaignsByLawyerIdSumPersonalisationQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_PERSONALISATION_QUERY_ASC',
  CampaignsByLawyerIdSumPersonalisationQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_PERSONALISATION_QUERY_DESC',
  CampaignsByLawyerIdSumPriorityAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_PRIORITY_ASC',
  CampaignsByLawyerIdSumPriorityDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_PRIORITY_DESC',
  CampaignsByLawyerIdSumReviewedByAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_REVIEWED_BY_ASC',
  CampaignsByLawyerIdSumReviewedByDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_REVIEWED_BY_DESC',
  CampaignsByLawyerIdSumReviewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_REVIEWED_TIME_ASC',
  CampaignsByLawyerIdSumReviewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_REVIEWED_TIME_DESC',
  CampaignsByLawyerIdSumScheduledActivationTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsByLawyerIdSumScheduledActivationTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsByLawyerIdSumSearchAfterAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SEARCH_AFTER_ASC',
  CampaignsByLawyerIdSumSearchAfterDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SEARCH_AFTER_DESC',
  CampaignsByLawyerIdSumSearchAfterScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SEARCH_AFTER_SCORE_ASC',
  CampaignsByLawyerIdSumSearchAfterScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SEARCH_AFTER_SCORE_DESC',
  CampaignsByLawyerIdSumSearchBeforeAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SEARCH_BEFORE_ASC',
  CampaignsByLawyerIdSumSearchBeforeDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SEARCH_BEFORE_DESC',
  CampaignsByLawyerIdSumSearchStartedAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SEARCH_STARTED_ASC',
  CampaignsByLawyerIdSumSearchStartedDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SEARCH_STARTED_DESC',
  CampaignsByLawyerIdSumStatusAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_STATUS_ASC',
  CampaignsByLawyerIdSumStatusDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_STATUS_DESC',
  CampaignsByLawyerIdSumSubjectAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SUBJECT_ASC',
  CampaignsByLawyerIdSumSubjectDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SUBJECT_DESC',
  CampaignsByLawyerIdSumSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SUGGESTED_TARGETING_ID_ASC',
  CampaignsByLawyerIdSumSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_SUGGESTED_TARGETING_ID_DESC',
  CampaignsByLawyerIdSumTargetingRequestAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_TARGETING_REQUEST_ASC',
  CampaignsByLawyerIdSumTargetingRequestDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_TARGETING_REQUEST_DESC',
  CampaignsByLawyerIdSumTargetDescriptionAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_TARGET_DESCRIPTION_ASC',
  CampaignsByLawyerIdSumTargetDescriptionDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_TARGET_DESCRIPTION_DESC',
  CampaignsByLawyerIdSumUpdatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  CampaignsByLawyerIdSumUpdatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  CampaignsByLawyerIdSumUseLlmFilterAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_USE_LLM_FILTER_ASC',
  CampaignsByLawyerIdSumUseLlmFilterDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_USE_LLM_FILTER_DESC',
  CampaignsByLawyerIdSumVectorMatchThresholdAsc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsByLawyerIdSumVectorMatchThresholdDesc = 'CAMPAIGNS_BY_LAWYER_ID_SUM_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsByLawyerIdVariancePopulationActivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_ACTIVATED_TIME_ASC',
  CampaignsByLawyerIdVariancePopulationActivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_ACTIVATED_TIME_DESC',
  CampaignsByLawyerIdVariancePopulationCampaignTargetIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_TARGET_ID_ASC',
  CampaignsByLawyerIdVariancePopulationCampaignTargetIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_TARGET_ID_DESC',
  CampaignsByLawyerIdVariancePopulationChannelAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_CHANNEL_ASC',
  CampaignsByLawyerIdVariancePopulationChannelDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_CHANNEL_DESC',
  CampaignsByLawyerIdVariancePopulationConversionScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_CONVERSION_SCORE_ASC',
  CampaignsByLawyerIdVariancePopulationConversionScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_CONVERSION_SCORE_DESC',
  CampaignsByLawyerIdVariancePopulationCreatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CampaignsByLawyerIdVariancePopulationCreatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CampaignsByLawyerIdVariancePopulationDeactivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_DEACTIVATED_TIME_ASC',
  CampaignsByLawyerIdVariancePopulationDeactivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_DEACTIVATED_TIME_DESC',
  CampaignsByLawyerIdVariancePopulationDeletedAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_DELETED_ASC',
  CampaignsByLawyerIdVariancePopulationDeletedDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_DELETED_DESC',
  CampaignsByLawyerIdVariancePopulationDraftedByLawyerAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_DRAFTED_BY_LAWYER_ASC',
  CampaignsByLawyerIdVariancePopulationDraftedByLawyerDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_DRAFTED_BY_LAWYER_DESC',
  CampaignsByLawyerIdVariancePopulationIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  CampaignsByLawyerIdVariancePopulationIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  CampaignsByLawyerIdVariancePopulationIsPersonalisedAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_IS_PERSONALISED_ASC',
  CampaignsByLawyerIdVariancePopulationIsPersonalisedDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_IS_PERSONALISED_DESC',
  CampaignsByLawyerIdVariancePopulationIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsByLawyerIdVariancePopulationIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsByLawyerIdVariancePopulationIsTestModeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_IS_TEST_MODE_ASC',
  CampaignsByLawyerIdVariancePopulationIsTestModeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_IS_TEST_MODE_DESC',
  CampaignsByLawyerIdVariancePopulationLawyerIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  CampaignsByLawyerIdVariancePopulationLawyerIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  CampaignsByLawyerIdVariancePopulationLawyerViewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  CampaignsByLawyerIdVariancePopulationLawyerViewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  CampaignsByLawyerIdVariancePopulationMaxAllocationAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_MAX_ALLOCATION_ASC',
  CampaignsByLawyerIdVariancePopulationMaxAllocationDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_MAX_ALLOCATION_DESC',
  CampaignsByLawyerIdVariancePopulationNameAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_NAME_ASC',
  CampaignsByLawyerIdVariancePopulationNameDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_NAME_DESC',
  CampaignsByLawyerIdVariancePopulationNotesAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_NOTES_ASC',
  CampaignsByLawyerIdVariancePopulationNotesDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_NOTES_DESC',
  CampaignsByLawyerIdVariancePopulationPatentQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_PATENT_QUERY_ASC',
  CampaignsByLawyerIdVariancePopulationPatentQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_PATENT_QUERY_DESC',
  CampaignsByLawyerIdVariancePopulationPersonalisationQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_PERSONALISATION_QUERY_ASC',
  CampaignsByLawyerIdVariancePopulationPersonalisationQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_PERSONALISATION_QUERY_DESC',
  CampaignsByLawyerIdVariancePopulationPriorityAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_PRIORITY_ASC',
  CampaignsByLawyerIdVariancePopulationPriorityDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_PRIORITY_DESC',
  CampaignsByLawyerIdVariancePopulationReviewedByAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_REVIEWED_BY_ASC',
  CampaignsByLawyerIdVariancePopulationReviewedByDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_REVIEWED_BY_DESC',
  CampaignsByLawyerIdVariancePopulationReviewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  CampaignsByLawyerIdVariancePopulationReviewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  CampaignsByLawyerIdVariancePopulationScheduledActivationTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsByLawyerIdVariancePopulationScheduledActivationTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsByLawyerIdVariancePopulationSearchAfterAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SEARCH_AFTER_ASC',
  CampaignsByLawyerIdVariancePopulationSearchAfterDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SEARCH_AFTER_DESC',
  CampaignsByLawyerIdVariancePopulationSearchAfterScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SEARCH_AFTER_SCORE_ASC',
  CampaignsByLawyerIdVariancePopulationSearchAfterScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SEARCH_AFTER_SCORE_DESC',
  CampaignsByLawyerIdVariancePopulationSearchBeforeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SEARCH_BEFORE_ASC',
  CampaignsByLawyerIdVariancePopulationSearchBeforeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SEARCH_BEFORE_DESC',
  CampaignsByLawyerIdVariancePopulationSearchStartedAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SEARCH_STARTED_ASC',
  CampaignsByLawyerIdVariancePopulationSearchStartedDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SEARCH_STARTED_DESC',
  CampaignsByLawyerIdVariancePopulationStatusAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_STATUS_ASC',
  CampaignsByLawyerIdVariancePopulationStatusDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_STATUS_DESC',
  CampaignsByLawyerIdVariancePopulationSubjectAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SUBJECT_ASC',
  CampaignsByLawyerIdVariancePopulationSubjectDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SUBJECT_DESC',
  CampaignsByLawyerIdVariancePopulationSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SUGGESTED_TARGETING_ID_ASC',
  CampaignsByLawyerIdVariancePopulationSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_SUGGESTED_TARGETING_ID_DESC',
  CampaignsByLawyerIdVariancePopulationTargetingRequestAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_TARGETING_REQUEST_ASC',
  CampaignsByLawyerIdVariancePopulationTargetingRequestDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_TARGETING_REQUEST_DESC',
  CampaignsByLawyerIdVariancePopulationTargetDescriptionAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_TARGET_DESCRIPTION_ASC',
  CampaignsByLawyerIdVariancePopulationTargetDescriptionDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_TARGET_DESCRIPTION_DESC',
  CampaignsByLawyerIdVariancePopulationUpdatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  CampaignsByLawyerIdVariancePopulationUpdatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  CampaignsByLawyerIdVariancePopulationUseLlmFilterAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_USE_LLM_FILTER_ASC',
  CampaignsByLawyerIdVariancePopulationUseLlmFilterDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_USE_LLM_FILTER_DESC',
  CampaignsByLawyerIdVariancePopulationVectorMatchThresholdAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsByLawyerIdVariancePopulationVectorMatchThresholdDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_POPULATION_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsByLawyerIdVarianceSampleActivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_ACTIVATED_TIME_ASC',
  CampaignsByLawyerIdVarianceSampleActivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_ACTIVATED_TIME_DESC',
  CampaignsByLawyerIdVarianceSampleCampaignTargetIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_TARGET_ID_ASC',
  CampaignsByLawyerIdVarianceSampleCampaignTargetIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_TARGET_ID_DESC',
  CampaignsByLawyerIdVarianceSampleChannelAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_CHANNEL_ASC',
  CampaignsByLawyerIdVarianceSampleChannelDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_CHANNEL_DESC',
  CampaignsByLawyerIdVarianceSampleConversionScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONVERSION_SCORE_ASC',
  CampaignsByLawyerIdVarianceSampleConversionScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONVERSION_SCORE_DESC',
  CampaignsByLawyerIdVarianceSampleCreatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CampaignsByLawyerIdVarianceSampleCreatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CampaignsByLawyerIdVarianceSampleDeactivatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_DEACTIVATED_TIME_ASC',
  CampaignsByLawyerIdVarianceSampleDeactivatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_DEACTIVATED_TIME_DESC',
  CampaignsByLawyerIdVarianceSampleDeletedAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_DELETED_ASC',
  CampaignsByLawyerIdVarianceSampleDeletedDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_DELETED_DESC',
  CampaignsByLawyerIdVarianceSampleDraftedByLawyerAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_DRAFTED_BY_LAWYER_ASC',
  CampaignsByLawyerIdVarianceSampleDraftedByLawyerDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_DRAFTED_BY_LAWYER_DESC',
  CampaignsByLawyerIdVarianceSampleIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  CampaignsByLawyerIdVarianceSampleIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  CampaignsByLawyerIdVarianceSampleIsPersonalisedAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_PERSONALISED_ASC',
  CampaignsByLawyerIdVarianceSampleIsPersonalisedDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_PERSONALISED_DESC',
  CampaignsByLawyerIdVarianceSampleIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsByLawyerIdVarianceSampleIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsByLawyerIdVarianceSampleIsTestModeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_TEST_MODE_ASC',
  CampaignsByLawyerIdVarianceSampleIsTestModeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_TEST_MODE_DESC',
  CampaignsByLawyerIdVarianceSampleLawyerIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  CampaignsByLawyerIdVarianceSampleLawyerIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  CampaignsByLawyerIdVarianceSampleLawyerViewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  CampaignsByLawyerIdVarianceSampleLawyerViewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  CampaignsByLawyerIdVarianceSampleMaxAllocationAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_MAX_ALLOCATION_ASC',
  CampaignsByLawyerIdVarianceSampleMaxAllocationDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_MAX_ALLOCATION_DESC',
  CampaignsByLawyerIdVarianceSampleNameAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_NAME_ASC',
  CampaignsByLawyerIdVarianceSampleNameDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_NAME_DESC',
  CampaignsByLawyerIdVarianceSampleNotesAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_NOTES_ASC',
  CampaignsByLawyerIdVarianceSampleNotesDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_NOTES_DESC',
  CampaignsByLawyerIdVarianceSamplePatentQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_PATENT_QUERY_ASC',
  CampaignsByLawyerIdVarianceSamplePatentQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_PATENT_QUERY_DESC',
  CampaignsByLawyerIdVarianceSamplePersonalisationQueryAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_PERSONALISATION_QUERY_ASC',
  CampaignsByLawyerIdVarianceSamplePersonalisationQueryDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_PERSONALISATION_QUERY_DESC',
  CampaignsByLawyerIdVarianceSamplePriorityAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_PRIORITY_ASC',
  CampaignsByLawyerIdVarianceSamplePriorityDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_PRIORITY_DESC',
  CampaignsByLawyerIdVarianceSampleReviewedByAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_REVIEWED_BY_ASC',
  CampaignsByLawyerIdVarianceSampleReviewedByDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_REVIEWED_BY_DESC',
  CampaignsByLawyerIdVarianceSampleReviewedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  CampaignsByLawyerIdVarianceSampleReviewedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  CampaignsByLawyerIdVarianceSampleScheduledActivationTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsByLawyerIdVarianceSampleScheduledActivationTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsByLawyerIdVarianceSampleSearchAfterAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SEARCH_AFTER_ASC',
  CampaignsByLawyerIdVarianceSampleSearchAfterDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SEARCH_AFTER_DESC',
  CampaignsByLawyerIdVarianceSampleSearchAfterScoreAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SEARCH_AFTER_SCORE_ASC',
  CampaignsByLawyerIdVarianceSampleSearchAfterScoreDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SEARCH_AFTER_SCORE_DESC',
  CampaignsByLawyerIdVarianceSampleSearchBeforeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SEARCH_BEFORE_ASC',
  CampaignsByLawyerIdVarianceSampleSearchBeforeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SEARCH_BEFORE_DESC',
  CampaignsByLawyerIdVarianceSampleSearchStartedAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SEARCH_STARTED_ASC',
  CampaignsByLawyerIdVarianceSampleSearchStartedDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SEARCH_STARTED_DESC',
  CampaignsByLawyerIdVarianceSampleStatusAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_STATUS_ASC',
  CampaignsByLawyerIdVarianceSampleStatusDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_STATUS_DESC',
  CampaignsByLawyerIdVarianceSampleSubjectAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SUBJECT_ASC',
  CampaignsByLawyerIdVarianceSampleSubjectDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SUBJECT_DESC',
  CampaignsByLawyerIdVarianceSampleSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SUGGESTED_TARGETING_ID_ASC',
  CampaignsByLawyerIdVarianceSampleSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_SUGGESTED_TARGETING_ID_DESC',
  CampaignsByLawyerIdVarianceSampleTargetingRequestAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_TARGETING_REQUEST_ASC',
  CampaignsByLawyerIdVarianceSampleTargetingRequestDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_TARGETING_REQUEST_DESC',
  CampaignsByLawyerIdVarianceSampleTargetDescriptionAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_TARGET_DESCRIPTION_ASC',
  CampaignsByLawyerIdVarianceSampleTargetDescriptionDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_TARGET_DESCRIPTION_DESC',
  CampaignsByLawyerIdVarianceSampleUpdatedTimeAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  CampaignsByLawyerIdVarianceSampleUpdatedTimeDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  CampaignsByLawyerIdVarianceSampleUseLlmFilterAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_USE_LLM_FILTER_ASC',
  CampaignsByLawyerIdVarianceSampleUseLlmFilterDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_USE_LLM_FILTER_DESC',
  CampaignsByLawyerIdVarianceSampleVectorMatchThresholdAsc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsByLawyerIdVarianceSampleVectorMatchThresholdDesc = 'CAMPAIGNS_BY_LAWYER_ID_VARIANCE_SAMPLE_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignRequestsByLawyerIdAverageCampaignTypeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_CAMPAIGN_TYPE_ASC',
  CampaignRequestsByLawyerIdAverageCampaignTypeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_CAMPAIGN_TYPE_DESC',
  CampaignRequestsByLawyerIdAverageCreatedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_CREATED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdAverageCreatedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_CREATED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdAverageCreatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  CampaignRequestsByLawyerIdAverageCreatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  CampaignRequestsByLawyerIdAverageDescriptionAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_DESCRIPTION_ASC',
  CampaignRequestsByLawyerIdAverageDescriptionDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_DESCRIPTION_DESC',
  CampaignRequestsByLawyerIdAverageDraftedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_DRAFTED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdAverageDraftedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_DRAFTED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdAverageExamplesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_EXAMPLES_ASC',
  CampaignRequestsByLawyerIdAverageExamplesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_EXAMPLES_DESC',
  CampaignRequestsByLawyerIdAverageFocusesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_FOCUSES_ASC',
  CampaignRequestsByLawyerIdAverageFocusesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_FOCUSES_DESC',
  CampaignRequestsByLawyerIdAverageIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  CampaignRequestsByLawyerIdAverageIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  CampaignRequestsByLawyerIdAverageLawyerIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  CampaignRequestsByLawyerIdAverageLawyerIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  CampaignRequestsByLawyerIdAverageLawyerNotesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_LAWYER_NOTES_ASC',
  CampaignRequestsByLawyerIdAverageLawyerNotesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_LAWYER_NOTES_DESC',
  CampaignRequestsByLawyerIdAverageNameAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_NAME_ASC',
  CampaignRequestsByLawyerIdAverageNameDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_NAME_DESC',
  CampaignRequestsByLawyerIdAverageNoActionRequiredAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_NO_ACTION_REQUIRED_ASC',
  CampaignRequestsByLawyerIdAverageNoActionRequiredDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_NO_ACTION_REQUIRED_DESC',
  CampaignRequestsByLawyerIdAverageStatusAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_STATUS_ASC',
  CampaignRequestsByLawyerIdAverageStatusDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_STATUS_DESC',
  CampaignRequestsByLawyerIdAverageUpdatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  CampaignRequestsByLawyerIdAverageUpdatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  CampaignRequestsByLawyerIdCountAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_COUNT_ASC',
  CampaignRequestsByLawyerIdCountDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_COUNT_DESC',
  CampaignRequestsByLawyerIdDistinctCountCampaignTypeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_TYPE_ASC',
  CampaignRequestsByLawyerIdDistinctCountCampaignTypeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_TYPE_DESC',
  CampaignRequestsByLawyerIdDistinctCountCreatedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdDistinctCountCreatedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdDistinctCountCreatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CampaignRequestsByLawyerIdDistinctCountCreatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CampaignRequestsByLawyerIdDistinctCountDescriptionAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_DESCRIPTION_ASC',
  CampaignRequestsByLawyerIdDistinctCountDescriptionDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_DESCRIPTION_DESC',
  CampaignRequestsByLawyerIdDistinctCountDraftedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_DRAFTED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdDistinctCountDraftedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_DRAFTED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdDistinctCountExamplesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_EXAMPLES_ASC',
  CampaignRequestsByLawyerIdDistinctCountExamplesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_EXAMPLES_DESC',
  CampaignRequestsByLawyerIdDistinctCountFocusesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_FOCUSES_ASC',
  CampaignRequestsByLawyerIdDistinctCountFocusesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_FOCUSES_DESC',
  CampaignRequestsByLawyerIdDistinctCountIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  CampaignRequestsByLawyerIdDistinctCountIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  CampaignRequestsByLawyerIdDistinctCountLawyerIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  CampaignRequestsByLawyerIdDistinctCountLawyerIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  CampaignRequestsByLawyerIdDistinctCountLawyerNotesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_NOTES_ASC',
  CampaignRequestsByLawyerIdDistinctCountLawyerNotesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_NOTES_DESC',
  CampaignRequestsByLawyerIdDistinctCountNameAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_NAME_ASC',
  CampaignRequestsByLawyerIdDistinctCountNameDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_NAME_DESC',
  CampaignRequestsByLawyerIdDistinctCountNoActionRequiredAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_NO_ACTION_REQUIRED_ASC',
  CampaignRequestsByLawyerIdDistinctCountNoActionRequiredDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_NO_ACTION_REQUIRED_DESC',
  CampaignRequestsByLawyerIdDistinctCountStatusAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_STATUS_ASC',
  CampaignRequestsByLawyerIdDistinctCountStatusDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_STATUS_DESC',
  CampaignRequestsByLawyerIdDistinctCountUpdatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  CampaignRequestsByLawyerIdDistinctCountUpdatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  CampaignRequestsByLawyerIdMaxCampaignTypeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_CAMPAIGN_TYPE_ASC',
  CampaignRequestsByLawyerIdMaxCampaignTypeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_CAMPAIGN_TYPE_DESC',
  CampaignRequestsByLawyerIdMaxCreatedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_CREATED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdMaxCreatedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_CREATED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdMaxCreatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  CampaignRequestsByLawyerIdMaxCreatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  CampaignRequestsByLawyerIdMaxDescriptionAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_DESCRIPTION_ASC',
  CampaignRequestsByLawyerIdMaxDescriptionDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_DESCRIPTION_DESC',
  CampaignRequestsByLawyerIdMaxDraftedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_DRAFTED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdMaxDraftedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_DRAFTED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdMaxExamplesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_EXAMPLES_ASC',
  CampaignRequestsByLawyerIdMaxExamplesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_EXAMPLES_DESC',
  CampaignRequestsByLawyerIdMaxFocusesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_FOCUSES_ASC',
  CampaignRequestsByLawyerIdMaxFocusesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_FOCUSES_DESC',
  CampaignRequestsByLawyerIdMaxIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_ID_ASC',
  CampaignRequestsByLawyerIdMaxIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_ID_DESC',
  CampaignRequestsByLawyerIdMaxLawyerIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  CampaignRequestsByLawyerIdMaxLawyerIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  CampaignRequestsByLawyerIdMaxLawyerNotesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_LAWYER_NOTES_ASC',
  CampaignRequestsByLawyerIdMaxLawyerNotesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_LAWYER_NOTES_DESC',
  CampaignRequestsByLawyerIdMaxNameAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_NAME_ASC',
  CampaignRequestsByLawyerIdMaxNameDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_NAME_DESC',
  CampaignRequestsByLawyerIdMaxNoActionRequiredAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_NO_ACTION_REQUIRED_ASC',
  CampaignRequestsByLawyerIdMaxNoActionRequiredDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_NO_ACTION_REQUIRED_DESC',
  CampaignRequestsByLawyerIdMaxStatusAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_STATUS_ASC',
  CampaignRequestsByLawyerIdMaxStatusDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_STATUS_DESC',
  CampaignRequestsByLawyerIdMaxUpdatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  CampaignRequestsByLawyerIdMaxUpdatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  CampaignRequestsByLawyerIdMinCampaignTypeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_CAMPAIGN_TYPE_ASC',
  CampaignRequestsByLawyerIdMinCampaignTypeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_CAMPAIGN_TYPE_DESC',
  CampaignRequestsByLawyerIdMinCreatedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_CREATED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdMinCreatedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_CREATED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdMinCreatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  CampaignRequestsByLawyerIdMinCreatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  CampaignRequestsByLawyerIdMinDescriptionAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_DESCRIPTION_ASC',
  CampaignRequestsByLawyerIdMinDescriptionDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_DESCRIPTION_DESC',
  CampaignRequestsByLawyerIdMinDraftedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_DRAFTED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdMinDraftedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_DRAFTED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdMinExamplesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_EXAMPLES_ASC',
  CampaignRequestsByLawyerIdMinExamplesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_EXAMPLES_DESC',
  CampaignRequestsByLawyerIdMinFocusesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_FOCUSES_ASC',
  CampaignRequestsByLawyerIdMinFocusesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_FOCUSES_DESC',
  CampaignRequestsByLawyerIdMinIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_ID_ASC',
  CampaignRequestsByLawyerIdMinIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_ID_DESC',
  CampaignRequestsByLawyerIdMinLawyerIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  CampaignRequestsByLawyerIdMinLawyerIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  CampaignRequestsByLawyerIdMinLawyerNotesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_LAWYER_NOTES_ASC',
  CampaignRequestsByLawyerIdMinLawyerNotesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_LAWYER_NOTES_DESC',
  CampaignRequestsByLawyerIdMinNameAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_NAME_ASC',
  CampaignRequestsByLawyerIdMinNameDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_NAME_DESC',
  CampaignRequestsByLawyerIdMinNoActionRequiredAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_NO_ACTION_REQUIRED_ASC',
  CampaignRequestsByLawyerIdMinNoActionRequiredDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_NO_ACTION_REQUIRED_DESC',
  CampaignRequestsByLawyerIdMinStatusAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_STATUS_ASC',
  CampaignRequestsByLawyerIdMinStatusDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_STATUS_DESC',
  CampaignRequestsByLawyerIdMinUpdatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  CampaignRequestsByLawyerIdMinUpdatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  CampaignRequestsByLawyerIdStddevPopulationCampaignTypeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_TYPE_ASC',
  CampaignRequestsByLawyerIdStddevPopulationCampaignTypeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_TYPE_DESC',
  CampaignRequestsByLawyerIdStddevPopulationCreatedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdStddevPopulationCreatedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdStddevPopulationCreatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CampaignRequestsByLawyerIdStddevPopulationCreatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CampaignRequestsByLawyerIdStddevPopulationDescriptionAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_DESCRIPTION_ASC',
  CampaignRequestsByLawyerIdStddevPopulationDescriptionDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_DESCRIPTION_DESC',
  CampaignRequestsByLawyerIdStddevPopulationDraftedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_DRAFTED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdStddevPopulationDraftedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_DRAFTED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdStddevPopulationExamplesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_EXAMPLES_ASC',
  CampaignRequestsByLawyerIdStddevPopulationExamplesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_EXAMPLES_DESC',
  CampaignRequestsByLawyerIdStddevPopulationFocusesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_FOCUSES_ASC',
  CampaignRequestsByLawyerIdStddevPopulationFocusesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_FOCUSES_DESC',
  CampaignRequestsByLawyerIdStddevPopulationIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  CampaignRequestsByLawyerIdStddevPopulationIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  CampaignRequestsByLawyerIdStddevPopulationLawyerIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  CampaignRequestsByLawyerIdStddevPopulationLawyerIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  CampaignRequestsByLawyerIdStddevPopulationLawyerNotesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_NOTES_ASC',
  CampaignRequestsByLawyerIdStddevPopulationLawyerNotesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_NOTES_DESC',
  CampaignRequestsByLawyerIdStddevPopulationNameAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_NAME_ASC',
  CampaignRequestsByLawyerIdStddevPopulationNameDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_NAME_DESC',
  CampaignRequestsByLawyerIdStddevPopulationNoActionRequiredAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_NO_ACTION_REQUIRED_ASC',
  CampaignRequestsByLawyerIdStddevPopulationNoActionRequiredDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_NO_ACTION_REQUIRED_DESC',
  CampaignRequestsByLawyerIdStddevPopulationStatusAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_STATUS_ASC',
  CampaignRequestsByLawyerIdStddevPopulationStatusDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_STATUS_DESC',
  CampaignRequestsByLawyerIdStddevPopulationUpdatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  CampaignRequestsByLawyerIdStddevPopulationUpdatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  CampaignRequestsByLawyerIdStddevSampleCampaignTypeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_TYPE_ASC',
  CampaignRequestsByLawyerIdStddevSampleCampaignTypeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_TYPE_DESC',
  CampaignRequestsByLawyerIdStddevSampleCreatedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdStddevSampleCreatedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdStddevSampleCreatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CampaignRequestsByLawyerIdStddevSampleCreatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CampaignRequestsByLawyerIdStddevSampleDescriptionAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_DESCRIPTION_ASC',
  CampaignRequestsByLawyerIdStddevSampleDescriptionDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_DESCRIPTION_DESC',
  CampaignRequestsByLawyerIdStddevSampleDraftedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_DRAFTED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdStddevSampleDraftedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_DRAFTED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdStddevSampleExamplesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_EXAMPLES_ASC',
  CampaignRequestsByLawyerIdStddevSampleExamplesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_EXAMPLES_DESC',
  CampaignRequestsByLawyerIdStddevSampleFocusesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_FOCUSES_ASC',
  CampaignRequestsByLawyerIdStddevSampleFocusesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_FOCUSES_DESC',
  CampaignRequestsByLawyerIdStddevSampleIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  CampaignRequestsByLawyerIdStddevSampleIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  CampaignRequestsByLawyerIdStddevSampleLawyerIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  CampaignRequestsByLawyerIdStddevSampleLawyerIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  CampaignRequestsByLawyerIdStddevSampleLawyerNotesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_NOTES_ASC',
  CampaignRequestsByLawyerIdStddevSampleLawyerNotesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_NOTES_DESC',
  CampaignRequestsByLawyerIdStddevSampleNameAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_NAME_ASC',
  CampaignRequestsByLawyerIdStddevSampleNameDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_NAME_DESC',
  CampaignRequestsByLawyerIdStddevSampleNoActionRequiredAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_NO_ACTION_REQUIRED_ASC',
  CampaignRequestsByLawyerIdStddevSampleNoActionRequiredDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_NO_ACTION_REQUIRED_DESC',
  CampaignRequestsByLawyerIdStddevSampleStatusAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_STATUS_ASC',
  CampaignRequestsByLawyerIdStddevSampleStatusDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_STATUS_DESC',
  CampaignRequestsByLawyerIdStddevSampleUpdatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  CampaignRequestsByLawyerIdStddevSampleUpdatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  CampaignRequestsByLawyerIdSumCampaignTypeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_CAMPAIGN_TYPE_ASC',
  CampaignRequestsByLawyerIdSumCampaignTypeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_CAMPAIGN_TYPE_DESC',
  CampaignRequestsByLawyerIdSumCreatedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_CREATED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdSumCreatedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_CREATED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdSumCreatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  CampaignRequestsByLawyerIdSumCreatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  CampaignRequestsByLawyerIdSumDescriptionAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_DESCRIPTION_ASC',
  CampaignRequestsByLawyerIdSumDescriptionDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_DESCRIPTION_DESC',
  CampaignRequestsByLawyerIdSumDraftedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_DRAFTED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdSumDraftedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_DRAFTED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdSumExamplesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_EXAMPLES_ASC',
  CampaignRequestsByLawyerIdSumExamplesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_EXAMPLES_DESC',
  CampaignRequestsByLawyerIdSumFocusesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_FOCUSES_ASC',
  CampaignRequestsByLawyerIdSumFocusesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_FOCUSES_DESC',
  CampaignRequestsByLawyerIdSumIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_ID_ASC',
  CampaignRequestsByLawyerIdSumIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_ID_DESC',
  CampaignRequestsByLawyerIdSumLawyerIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  CampaignRequestsByLawyerIdSumLawyerIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  CampaignRequestsByLawyerIdSumLawyerNotesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_LAWYER_NOTES_ASC',
  CampaignRequestsByLawyerIdSumLawyerNotesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_LAWYER_NOTES_DESC',
  CampaignRequestsByLawyerIdSumNameAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_NAME_ASC',
  CampaignRequestsByLawyerIdSumNameDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_NAME_DESC',
  CampaignRequestsByLawyerIdSumNoActionRequiredAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_NO_ACTION_REQUIRED_ASC',
  CampaignRequestsByLawyerIdSumNoActionRequiredDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_NO_ACTION_REQUIRED_DESC',
  CampaignRequestsByLawyerIdSumStatusAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_STATUS_ASC',
  CampaignRequestsByLawyerIdSumStatusDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_STATUS_DESC',
  CampaignRequestsByLawyerIdSumUpdatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  CampaignRequestsByLawyerIdSumUpdatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  CampaignRequestsByLawyerIdVariancePopulationCampaignTypeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_TYPE_ASC',
  CampaignRequestsByLawyerIdVariancePopulationCampaignTypeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_TYPE_DESC',
  CampaignRequestsByLawyerIdVariancePopulationCreatedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdVariancePopulationCreatedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdVariancePopulationCreatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CampaignRequestsByLawyerIdVariancePopulationCreatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CampaignRequestsByLawyerIdVariancePopulationDescriptionAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_DESCRIPTION_ASC',
  CampaignRequestsByLawyerIdVariancePopulationDescriptionDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_DESCRIPTION_DESC',
  CampaignRequestsByLawyerIdVariancePopulationDraftedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_DRAFTED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdVariancePopulationDraftedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_DRAFTED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdVariancePopulationExamplesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_EXAMPLES_ASC',
  CampaignRequestsByLawyerIdVariancePopulationExamplesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_EXAMPLES_DESC',
  CampaignRequestsByLawyerIdVariancePopulationFocusesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_FOCUSES_ASC',
  CampaignRequestsByLawyerIdVariancePopulationFocusesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_FOCUSES_DESC',
  CampaignRequestsByLawyerIdVariancePopulationIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  CampaignRequestsByLawyerIdVariancePopulationIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  CampaignRequestsByLawyerIdVariancePopulationLawyerIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  CampaignRequestsByLawyerIdVariancePopulationLawyerIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  CampaignRequestsByLawyerIdVariancePopulationLawyerNotesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_NOTES_ASC',
  CampaignRequestsByLawyerIdVariancePopulationLawyerNotesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_NOTES_DESC',
  CampaignRequestsByLawyerIdVariancePopulationNameAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_NAME_ASC',
  CampaignRequestsByLawyerIdVariancePopulationNameDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_NAME_DESC',
  CampaignRequestsByLawyerIdVariancePopulationNoActionRequiredAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_NO_ACTION_REQUIRED_ASC',
  CampaignRequestsByLawyerIdVariancePopulationNoActionRequiredDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_NO_ACTION_REQUIRED_DESC',
  CampaignRequestsByLawyerIdVariancePopulationStatusAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_STATUS_ASC',
  CampaignRequestsByLawyerIdVariancePopulationStatusDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_STATUS_DESC',
  CampaignRequestsByLawyerIdVariancePopulationUpdatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  CampaignRequestsByLawyerIdVariancePopulationUpdatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  CampaignRequestsByLawyerIdVarianceSampleCampaignTypeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_TYPE_ASC',
  CampaignRequestsByLawyerIdVarianceSampleCampaignTypeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_TYPE_DESC',
  CampaignRequestsByLawyerIdVarianceSampleCreatedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdVarianceSampleCreatedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdVarianceSampleCreatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CampaignRequestsByLawyerIdVarianceSampleCreatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CampaignRequestsByLawyerIdVarianceSampleDescriptionAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  CampaignRequestsByLawyerIdVarianceSampleDescriptionDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  CampaignRequestsByLawyerIdVarianceSampleDraftedByLawyerAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_DRAFTED_BY_LAWYER_ASC',
  CampaignRequestsByLawyerIdVarianceSampleDraftedByLawyerDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_DRAFTED_BY_LAWYER_DESC',
  CampaignRequestsByLawyerIdVarianceSampleExamplesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_EXAMPLES_ASC',
  CampaignRequestsByLawyerIdVarianceSampleExamplesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_EXAMPLES_DESC',
  CampaignRequestsByLawyerIdVarianceSampleFocusesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_FOCUSES_ASC',
  CampaignRequestsByLawyerIdVarianceSampleFocusesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_FOCUSES_DESC',
  CampaignRequestsByLawyerIdVarianceSampleIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  CampaignRequestsByLawyerIdVarianceSampleIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  CampaignRequestsByLawyerIdVarianceSampleLawyerIdAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  CampaignRequestsByLawyerIdVarianceSampleLawyerIdDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  CampaignRequestsByLawyerIdVarianceSampleLawyerNotesAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_NOTES_ASC',
  CampaignRequestsByLawyerIdVarianceSampleLawyerNotesDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_NOTES_DESC',
  CampaignRequestsByLawyerIdVarianceSampleNameAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_NAME_ASC',
  CampaignRequestsByLawyerIdVarianceSampleNameDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_NAME_DESC',
  CampaignRequestsByLawyerIdVarianceSampleNoActionRequiredAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_NO_ACTION_REQUIRED_ASC',
  CampaignRequestsByLawyerIdVarianceSampleNoActionRequiredDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_NO_ACTION_REQUIRED_DESC',
  CampaignRequestsByLawyerIdVarianceSampleStatusAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_STATUS_ASC',
  CampaignRequestsByLawyerIdVarianceSampleStatusDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_STATUS_DESC',
  CampaignRequestsByLawyerIdVarianceSampleUpdatedTimeAsc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  CampaignRequestsByLawyerIdVarianceSampleUpdatedTimeDesc = 'CAMPAIGN_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  ChargedPerAsc = 'CHARGED_PER_ASC',
  ChargedPerDesc = 'CHARGED_PER_DESC',
  ChargeRateAsc = 'CHARGE_RATE_ASC',
  ChargeRateDesc = 'CHARGE_RATE_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  ClientsByLawyerIdAverageCompanyNotesAsc = 'CLIENTS_BY_LAWYER_ID_AVERAGE_COMPANY_NOTES_ASC',
  ClientsByLawyerIdAverageCompanyNotesDesc = 'CLIENTS_BY_LAWYER_ID_AVERAGE_COMPANY_NOTES_DESC',
  ClientsByLawyerIdAverageContactIdAsc = 'CLIENTS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_ASC',
  ClientsByLawyerIdAverageContactIdDesc = 'CLIENTS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_DESC',
  ClientsByLawyerIdAverageContactNotesAsc = 'CLIENTS_BY_LAWYER_ID_AVERAGE_CONTACT_NOTES_ASC',
  ClientsByLawyerIdAverageContactNotesDesc = 'CLIENTS_BY_LAWYER_ID_AVERAGE_CONTACT_NOTES_DESC',
  ClientsByLawyerIdAverageCreatedTimeAsc = 'CLIENTS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  ClientsByLawyerIdAverageCreatedTimeDesc = 'CLIENTS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  ClientsByLawyerIdAverageIdAsc = 'CLIENTS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  ClientsByLawyerIdAverageIdDesc = 'CLIENTS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  ClientsByLawyerIdAverageLawyerIdAsc = 'CLIENTS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  ClientsByLawyerIdAverageLawyerIdDesc = 'CLIENTS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  ClientsByLawyerIdCountAsc = 'CLIENTS_BY_LAWYER_ID_COUNT_ASC',
  ClientsByLawyerIdCountDesc = 'CLIENTS_BY_LAWYER_ID_COUNT_DESC',
  ClientsByLawyerIdDistinctCountCompanyNotesAsc = 'CLIENTS_BY_LAWYER_ID_DISTINCT_COUNT_COMPANY_NOTES_ASC',
  ClientsByLawyerIdDistinctCountCompanyNotesDesc = 'CLIENTS_BY_LAWYER_ID_DISTINCT_COUNT_COMPANY_NOTES_DESC',
  ClientsByLawyerIdDistinctCountContactIdAsc = 'CLIENTS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ClientsByLawyerIdDistinctCountContactIdDesc = 'CLIENTS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ClientsByLawyerIdDistinctCountContactNotesAsc = 'CLIENTS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_NOTES_ASC',
  ClientsByLawyerIdDistinctCountContactNotesDesc = 'CLIENTS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_NOTES_DESC',
  ClientsByLawyerIdDistinctCountCreatedTimeAsc = 'CLIENTS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  ClientsByLawyerIdDistinctCountCreatedTimeDesc = 'CLIENTS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  ClientsByLawyerIdDistinctCountIdAsc = 'CLIENTS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  ClientsByLawyerIdDistinctCountIdDesc = 'CLIENTS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  ClientsByLawyerIdDistinctCountLawyerIdAsc = 'CLIENTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  ClientsByLawyerIdDistinctCountLawyerIdDesc = 'CLIENTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  ClientsByLawyerIdMaxCompanyNotesAsc = 'CLIENTS_BY_LAWYER_ID_MAX_COMPANY_NOTES_ASC',
  ClientsByLawyerIdMaxCompanyNotesDesc = 'CLIENTS_BY_LAWYER_ID_MAX_COMPANY_NOTES_DESC',
  ClientsByLawyerIdMaxContactIdAsc = 'CLIENTS_BY_LAWYER_ID_MAX_CONTACT_ID_ASC',
  ClientsByLawyerIdMaxContactIdDesc = 'CLIENTS_BY_LAWYER_ID_MAX_CONTACT_ID_DESC',
  ClientsByLawyerIdMaxContactNotesAsc = 'CLIENTS_BY_LAWYER_ID_MAX_CONTACT_NOTES_ASC',
  ClientsByLawyerIdMaxContactNotesDesc = 'CLIENTS_BY_LAWYER_ID_MAX_CONTACT_NOTES_DESC',
  ClientsByLawyerIdMaxCreatedTimeAsc = 'CLIENTS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  ClientsByLawyerIdMaxCreatedTimeDesc = 'CLIENTS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  ClientsByLawyerIdMaxIdAsc = 'CLIENTS_BY_LAWYER_ID_MAX_ID_ASC',
  ClientsByLawyerIdMaxIdDesc = 'CLIENTS_BY_LAWYER_ID_MAX_ID_DESC',
  ClientsByLawyerIdMaxLawyerIdAsc = 'CLIENTS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  ClientsByLawyerIdMaxLawyerIdDesc = 'CLIENTS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  ClientsByLawyerIdMinCompanyNotesAsc = 'CLIENTS_BY_LAWYER_ID_MIN_COMPANY_NOTES_ASC',
  ClientsByLawyerIdMinCompanyNotesDesc = 'CLIENTS_BY_LAWYER_ID_MIN_COMPANY_NOTES_DESC',
  ClientsByLawyerIdMinContactIdAsc = 'CLIENTS_BY_LAWYER_ID_MIN_CONTACT_ID_ASC',
  ClientsByLawyerIdMinContactIdDesc = 'CLIENTS_BY_LAWYER_ID_MIN_CONTACT_ID_DESC',
  ClientsByLawyerIdMinContactNotesAsc = 'CLIENTS_BY_LAWYER_ID_MIN_CONTACT_NOTES_ASC',
  ClientsByLawyerIdMinContactNotesDesc = 'CLIENTS_BY_LAWYER_ID_MIN_CONTACT_NOTES_DESC',
  ClientsByLawyerIdMinCreatedTimeAsc = 'CLIENTS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  ClientsByLawyerIdMinCreatedTimeDesc = 'CLIENTS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  ClientsByLawyerIdMinIdAsc = 'CLIENTS_BY_LAWYER_ID_MIN_ID_ASC',
  ClientsByLawyerIdMinIdDesc = 'CLIENTS_BY_LAWYER_ID_MIN_ID_DESC',
  ClientsByLawyerIdMinLawyerIdAsc = 'CLIENTS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  ClientsByLawyerIdMinLawyerIdDesc = 'CLIENTS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  ClientsByLawyerIdStddevPopulationCompanyNotesAsc = 'CLIENTS_BY_LAWYER_ID_STDDEV_POPULATION_COMPANY_NOTES_ASC',
  ClientsByLawyerIdStddevPopulationCompanyNotesDesc = 'CLIENTS_BY_LAWYER_ID_STDDEV_POPULATION_COMPANY_NOTES_DESC',
  ClientsByLawyerIdStddevPopulationContactIdAsc = 'CLIENTS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ClientsByLawyerIdStddevPopulationContactIdDesc = 'CLIENTS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ClientsByLawyerIdStddevPopulationContactNotesAsc = 'CLIENTS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_NOTES_ASC',
  ClientsByLawyerIdStddevPopulationContactNotesDesc = 'CLIENTS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_NOTES_DESC',
  ClientsByLawyerIdStddevPopulationCreatedTimeAsc = 'CLIENTS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  ClientsByLawyerIdStddevPopulationCreatedTimeDesc = 'CLIENTS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  ClientsByLawyerIdStddevPopulationIdAsc = 'CLIENTS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  ClientsByLawyerIdStddevPopulationIdDesc = 'CLIENTS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  ClientsByLawyerIdStddevPopulationLawyerIdAsc = 'CLIENTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  ClientsByLawyerIdStddevPopulationLawyerIdDesc = 'CLIENTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  ClientsByLawyerIdStddevSampleCompanyNotesAsc = 'CLIENTS_BY_LAWYER_ID_STDDEV_SAMPLE_COMPANY_NOTES_ASC',
  ClientsByLawyerIdStddevSampleCompanyNotesDesc = 'CLIENTS_BY_LAWYER_ID_STDDEV_SAMPLE_COMPANY_NOTES_DESC',
  ClientsByLawyerIdStddevSampleContactIdAsc = 'CLIENTS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ClientsByLawyerIdStddevSampleContactIdDesc = 'CLIENTS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ClientsByLawyerIdStddevSampleContactNotesAsc = 'CLIENTS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_NOTES_ASC',
  ClientsByLawyerIdStddevSampleContactNotesDesc = 'CLIENTS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_NOTES_DESC',
  ClientsByLawyerIdStddevSampleCreatedTimeAsc = 'CLIENTS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  ClientsByLawyerIdStddevSampleCreatedTimeDesc = 'CLIENTS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  ClientsByLawyerIdStddevSampleIdAsc = 'CLIENTS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  ClientsByLawyerIdStddevSampleIdDesc = 'CLIENTS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  ClientsByLawyerIdStddevSampleLawyerIdAsc = 'CLIENTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  ClientsByLawyerIdStddevSampleLawyerIdDesc = 'CLIENTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  ClientsByLawyerIdSumCompanyNotesAsc = 'CLIENTS_BY_LAWYER_ID_SUM_COMPANY_NOTES_ASC',
  ClientsByLawyerIdSumCompanyNotesDesc = 'CLIENTS_BY_LAWYER_ID_SUM_COMPANY_NOTES_DESC',
  ClientsByLawyerIdSumContactIdAsc = 'CLIENTS_BY_LAWYER_ID_SUM_CONTACT_ID_ASC',
  ClientsByLawyerIdSumContactIdDesc = 'CLIENTS_BY_LAWYER_ID_SUM_CONTACT_ID_DESC',
  ClientsByLawyerIdSumContactNotesAsc = 'CLIENTS_BY_LAWYER_ID_SUM_CONTACT_NOTES_ASC',
  ClientsByLawyerIdSumContactNotesDesc = 'CLIENTS_BY_LAWYER_ID_SUM_CONTACT_NOTES_DESC',
  ClientsByLawyerIdSumCreatedTimeAsc = 'CLIENTS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  ClientsByLawyerIdSumCreatedTimeDesc = 'CLIENTS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  ClientsByLawyerIdSumIdAsc = 'CLIENTS_BY_LAWYER_ID_SUM_ID_ASC',
  ClientsByLawyerIdSumIdDesc = 'CLIENTS_BY_LAWYER_ID_SUM_ID_DESC',
  ClientsByLawyerIdSumLawyerIdAsc = 'CLIENTS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  ClientsByLawyerIdSumLawyerIdDesc = 'CLIENTS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  ClientsByLawyerIdVariancePopulationCompanyNotesAsc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_POPULATION_COMPANY_NOTES_ASC',
  ClientsByLawyerIdVariancePopulationCompanyNotesDesc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_POPULATION_COMPANY_NOTES_DESC',
  ClientsByLawyerIdVariancePopulationContactIdAsc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ClientsByLawyerIdVariancePopulationContactIdDesc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ClientsByLawyerIdVariancePopulationContactNotesAsc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_NOTES_ASC',
  ClientsByLawyerIdVariancePopulationContactNotesDesc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_NOTES_DESC',
  ClientsByLawyerIdVariancePopulationCreatedTimeAsc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  ClientsByLawyerIdVariancePopulationCreatedTimeDesc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  ClientsByLawyerIdVariancePopulationIdAsc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  ClientsByLawyerIdVariancePopulationIdDesc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  ClientsByLawyerIdVariancePopulationLawyerIdAsc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  ClientsByLawyerIdVariancePopulationLawyerIdDesc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  ClientsByLawyerIdVarianceSampleCompanyNotesAsc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_SAMPLE_COMPANY_NOTES_ASC',
  ClientsByLawyerIdVarianceSampleCompanyNotesDesc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_SAMPLE_COMPANY_NOTES_DESC',
  ClientsByLawyerIdVarianceSampleContactIdAsc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ClientsByLawyerIdVarianceSampleContactIdDesc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ClientsByLawyerIdVarianceSampleContactNotesAsc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_NOTES_ASC',
  ClientsByLawyerIdVarianceSampleContactNotesDesc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_NOTES_DESC',
  ClientsByLawyerIdVarianceSampleCreatedTimeAsc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  ClientsByLawyerIdVarianceSampleCreatedTimeDesc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  ClientsByLawyerIdVarianceSampleIdAsc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  ClientsByLawyerIdVarianceSampleIdDesc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  ClientsByLawyerIdVarianceSampleLawyerIdAsc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  ClientsByLawyerIdVarianceSampleLawyerIdDesc = 'CLIENTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  ClientProfileRequirementsAsc = 'CLIENT_PROFILE_REQUIREMENTS_ASC',
  ClientProfileRequirementsDesc = 'CLIENT_PROFILE_REQUIREMENTS_DESC',
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  ConversationsByLawyerIdAverageCreatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  ConversationsByLawyerIdAverageCreatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  ConversationsByLawyerIdAverageIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  ConversationsByLawyerIdAverageIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  ConversationsByLawyerIdAverageLawyerIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  ConversationsByLawyerIdAverageLawyerIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  ConversationsByLawyerIdAverageUpdatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  ConversationsByLawyerIdAverageUpdatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  ConversationsByLawyerIdCountAsc = 'CONVERSATIONS_BY_LAWYER_ID_COUNT_ASC',
  ConversationsByLawyerIdCountDesc = 'CONVERSATIONS_BY_LAWYER_ID_COUNT_DESC',
  ConversationsByLawyerIdDistinctCountCreatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  ConversationsByLawyerIdDistinctCountCreatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  ConversationsByLawyerIdDistinctCountIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  ConversationsByLawyerIdDistinctCountIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  ConversationsByLawyerIdDistinctCountLawyerIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  ConversationsByLawyerIdDistinctCountLawyerIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  ConversationsByLawyerIdDistinctCountUpdatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  ConversationsByLawyerIdDistinctCountUpdatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  ConversationsByLawyerIdMaxCreatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  ConversationsByLawyerIdMaxCreatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  ConversationsByLawyerIdMaxIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_MAX_ID_ASC',
  ConversationsByLawyerIdMaxIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_MAX_ID_DESC',
  ConversationsByLawyerIdMaxLawyerIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  ConversationsByLawyerIdMaxLawyerIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  ConversationsByLawyerIdMaxUpdatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  ConversationsByLawyerIdMaxUpdatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  ConversationsByLawyerIdMinCreatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  ConversationsByLawyerIdMinCreatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  ConversationsByLawyerIdMinIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_MIN_ID_ASC',
  ConversationsByLawyerIdMinIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_MIN_ID_DESC',
  ConversationsByLawyerIdMinLawyerIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  ConversationsByLawyerIdMinLawyerIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  ConversationsByLawyerIdMinUpdatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  ConversationsByLawyerIdMinUpdatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  ConversationsByLawyerIdStddevPopulationCreatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  ConversationsByLawyerIdStddevPopulationCreatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  ConversationsByLawyerIdStddevPopulationIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  ConversationsByLawyerIdStddevPopulationIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  ConversationsByLawyerIdStddevPopulationLawyerIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  ConversationsByLawyerIdStddevPopulationLawyerIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  ConversationsByLawyerIdStddevPopulationUpdatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  ConversationsByLawyerIdStddevPopulationUpdatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  ConversationsByLawyerIdStddevSampleCreatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  ConversationsByLawyerIdStddevSampleCreatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  ConversationsByLawyerIdStddevSampleIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  ConversationsByLawyerIdStddevSampleIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  ConversationsByLawyerIdStddevSampleLawyerIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  ConversationsByLawyerIdStddevSampleLawyerIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  ConversationsByLawyerIdStddevSampleUpdatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  ConversationsByLawyerIdStddevSampleUpdatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  ConversationsByLawyerIdSumCreatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  ConversationsByLawyerIdSumCreatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  ConversationsByLawyerIdSumIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_SUM_ID_ASC',
  ConversationsByLawyerIdSumIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_SUM_ID_DESC',
  ConversationsByLawyerIdSumLawyerIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  ConversationsByLawyerIdSumLawyerIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  ConversationsByLawyerIdSumUpdatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  ConversationsByLawyerIdSumUpdatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  ConversationsByLawyerIdVariancePopulationCreatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  ConversationsByLawyerIdVariancePopulationCreatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  ConversationsByLawyerIdVariancePopulationIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  ConversationsByLawyerIdVariancePopulationIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  ConversationsByLawyerIdVariancePopulationLawyerIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  ConversationsByLawyerIdVariancePopulationLawyerIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  ConversationsByLawyerIdVariancePopulationUpdatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  ConversationsByLawyerIdVariancePopulationUpdatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  ConversationsByLawyerIdVarianceSampleCreatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  ConversationsByLawyerIdVarianceSampleCreatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  ConversationsByLawyerIdVarianceSampleIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  ConversationsByLawyerIdVarianceSampleIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  ConversationsByLawyerIdVarianceSampleLawyerIdAsc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  ConversationsByLawyerIdVarianceSampleLawyerIdDesc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  ConversationsByLawyerIdVarianceSampleUpdatedTimeAsc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  ConversationsByLawyerIdVarianceSampleUpdatedTimeDesc = 'CONVERSATIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  CountryAsc = 'COUNTRY_ASC',
  CountryCodeAsc = 'COUNTRY_CODE_ASC',
  CountryCodeDesc = 'COUNTRY_CODE_DESC',
  CountryDesc = 'COUNTRY_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  CronofyAuthsByLawyerIdAverageAccessTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_ACCESS_TOKEN_ASC',
  CronofyAuthsByLawyerIdAverageAccessTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_ACCESS_TOKEN_DESC',
  CronofyAuthsByLawyerIdAverageCreatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  CronofyAuthsByLawyerIdAverageCreatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  CronofyAuthsByLawyerIdAverageIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  CronofyAuthsByLawyerIdAverageIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  CronofyAuthsByLawyerIdAverageLawyerIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  CronofyAuthsByLawyerIdAverageLawyerIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  CronofyAuthsByLawyerIdAverageRefreshTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_REFRESH_TOKEN_ASC',
  CronofyAuthsByLawyerIdAverageRefreshTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_REFRESH_TOKEN_DESC',
  CronofyAuthsByLawyerIdAverageTokenExpirationAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_TOKEN_EXPIRATION_ASC',
  CronofyAuthsByLawyerIdAverageTokenExpirationDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_TOKEN_EXPIRATION_DESC',
  CronofyAuthsByLawyerIdAverageUpdatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  CronofyAuthsByLawyerIdAverageUpdatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  CronofyAuthsByLawyerIdCountAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_COUNT_ASC',
  CronofyAuthsByLawyerIdCountDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_COUNT_DESC',
  CronofyAuthsByLawyerIdDistinctCountAccessTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_ACCESS_TOKEN_ASC',
  CronofyAuthsByLawyerIdDistinctCountAccessTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_ACCESS_TOKEN_DESC',
  CronofyAuthsByLawyerIdDistinctCountCreatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CronofyAuthsByLawyerIdDistinctCountCreatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CronofyAuthsByLawyerIdDistinctCountIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  CronofyAuthsByLawyerIdDistinctCountIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  CronofyAuthsByLawyerIdDistinctCountLawyerIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  CronofyAuthsByLawyerIdDistinctCountLawyerIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  CronofyAuthsByLawyerIdDistinctCountRefreshTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_REFRESH_TOKEN_ASC',
  CronofyAuthsByLawyerIdDistinctCountRefreshTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_REFRESH_TOKEN_DESC',
  CronofyAuthsByLawyerIdDistinctCountTokenExpirationAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_TOKEN_EXPIRATION_ASC',
  CronofyAuthsByLawyerIdDistinctCountTokenExpirationDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_TOKEN_EXPIRATION_DESC',
  CronofyAuthsByLawyerIdDistinctCountUpdatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  CronofyAuthsByLawyerIdDistinctCountUpdatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  CronofyAuthsByLawyerIdMaxAccessTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_ACCESS_TOKEN_ASC',
  CronofyAuthsByLawyerIdMaxAccessTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_ACCESS_TOKEN_DESC',
  CronofyAuthsByLawyerIdMaxCreatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  CronofyAuthsByLawyerIdMaxCreatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  CronofyAuthsByLawyerIdMaxIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_ID_ASC',
  CronofyAuthsByLawyerIdMaxIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_ID_DESC',
  CronofyAuthsByLawyerIdMaxLawyerIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  CronofyAuthsByLawyerIdMaxLawyerIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  CronofyAuthsByLawyerIdMaxRefreshTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_REFRESH_TOKEN_ASC',
  CronofyAuthsByLawyerIdMaxRefreshTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_REFRESH_TOKEN_DESC',
  CronofyAuthsByLawyerIdMaxTokenExpirationAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_TOKEN_EXPIRATION_ASC',
  CronofyAuthsByLawyerIdMaxTokenExpirationDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_TOKEN_EXPIRATION_DESC',
  CronofyAuthsByLawyerIdMaxUpdatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  CronofyAuthsByLawyerIdMaxUpdatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  CronofyAuthsByLawyerIdMinAccessTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_ACCESS_TOKEN_ASC',
  CronofyAuthsByLawyerIdMinAccessTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_ACCESS_TOKEN_DESC',
  CronofyAuthsByLawyerIdMinCreatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  CronofyAuthsByLawyerIdMinCreatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  CronofyAuthsByLawyerIdMinIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_ID_ASC',
  CronofyAuthsByLawyerIdMinIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_ID_DESC',
  CronofyAuthsByLawyerIdMinLawyerIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  CronofyAuthsByLawyerIdMinLawyerIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  CronofyAuthsByLawyerIdMinRefreshTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_REFRESH_TOKEN_ASC',
  CronofyAuthsByLawyerIdMinRefreshTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_REFRESH_TOKEN_DESC',
  CronofyAuthsByLawyerIdMinTokenExpirationAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_TOKEN_EXPIRATION_ASC',
  CronofyAuthsByLawyerIdMinTokenExpirationDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_TOKEN_EXPIRATION_DESC',
  CronofyAuthsByLawyerIdMinUpdatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  CronofyAuthsByLawyerIdMinUpdatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  CronofyAuthsByLawyerIdStddevPopulationAccessTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_ACCESS_TOKEN_ASC',
  CronofyAuthsByLawyerIdStddevPopulationAccessTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_ACCESS_TOKEN_DESC',
  CronofyAuthsByLawyerIdStddevPopulationCreatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CronofyAuthsByLawyerIdStddevPopulationCreatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CronofyAuthsByLawyerIdStddevPopulationIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  CronofyAuthsByLawyerIdStddevPopulationIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  CronofyAuthsByLawyerIdStddevPopulationLawyerIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  CronofyAuthsByLawyerIdStddevPopulationLawyerIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  CronofyAuthsByLawyerIdStddevPopulationRefreshTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_REFRESH_TOKEN_ASC',
  CronofyAuthsByLawyerIdStddevPopulationRefreshTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_REFRESH_TOKEN_DESC',
  CronofyAuthsByLawyerIdStddevPopulationTokenExpirationAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_TOKEN_EXPIRATION_ASC',
  CronofyAuthsByLawyerIdStddevPopulationTokenExpirationDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_TOKEN_EXPIRATION_DESC',
  CronofyAuthsByLawyerIdStddevPopulationUpdatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  CronofyAuthsByLawyerIdStddevPopulationUpdatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  CronofyAuthsByLawyerIdStddevSampleAccessTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_ACCESS_TOKEN_ASC',
  CronofyAuthsByLawyerIdStddevSampleAccessTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_ACCESS_TOKEN_DESC',
  CronofyAuthsByLawyerIdStddevSampleCreatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CronofyAuthsByLawyerIdStddevSampleCreatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CronofyAuthsByLawyerIdStddevSampleIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  CronofyAuthsByLawyerIdStddevSampleIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  CronofyAuthsByLawyerIdStddevSampleLawyerIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  CronofyAuthsByLawyerIdStddevSampleLawyerIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  CronofyAuthsByLawyerIdStddevSampleRefreshTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_REFRESH_TOKEN_ASC',
  CronofyAuthsByLawyerIdStddevSampleRefreshTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_REFRESH_TOKEN_DESC',
  CronofyAuthsByLawyerIdStddevSampleTokenExpirationAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_TOKEN_EXPIRATION_ASC',
  CronofyAuthsByLawyerIdStddevSampleTokenExpirationDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_TOKEN_EXPIRATION_DESC',
  CronofyAuthsByLawyerIdStddevSampleUpdatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  CronofyAuthsByLawyerIdStddevSampleUpdatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  CronofyAuthsByLawyerIdSumAccessTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_ACCESS_TOKEN_ASC',
  CronofyAuthsByLawyerIdSumAccessTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_ACCESS_TOKEN_DESC',
  CronofyAuthsByLawyerIdSumCreatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  CronofyAuthsByLawyerIdSumCreatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  CronofyAuthsByLawyerIdSumIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_ID_ASC',
  CronofyAuthsByLawyerIdSumIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_ID_DESC',
  CronofyAuthsByLawyerIdSumLawyerIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  CronofyAuthsByLawyerIdSumLawyerIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  CronofyAuthsByLawyerIdSumRefreshTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_REFRESH_TOKEN_ASC',
  CronofyAuthsByLawyerIdSumRefreshTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_REFRESH_TOKEN_DESC',
  CronofyAuthsByLawyerIdSumTokenExpirationAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_TOKEN_EXPIRATION_ASC',
  CronofyAuthsByLawyerIdSumTokenExpirationDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_TOKEN_EXPIRATION_DESC',
  CronofyAuthsByLawyerIdSumUpdatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  CronofyAuthsByLawyerIdSumUpdatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  CronofyAuthsByLawyerIdVariancePopulationAccessTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_ACCESS_TOKEN_ASC',
  CronofyAuthsByLawyerIdVariancePopulationAccessTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_ACCESS_TOKEN_DESC',
  CronofyAuthsByLawyerIdVariancePopulationCreatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CronofyAuthsByLawyerIdVariancePopulationCreatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CronofyAuthsByLawyerIdVariancePopulationIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  CronofyAuthsByLawyerIdVariancePopulationIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  CronofyAuthsByLawyerIdVariancePopulationLawyerIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  CronofyAuthsByLawyerIdVariancePopulationLawyerIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  CronofyAuthsByLawyerIdVariancePopulationRefreshTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_REFRESH_TOKEN_ASC',
  CronofyAuthsByLawyerIdVariancePopulationRefreshTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_REFRESH_TOKEN_DESC',
  CronofyAuthsByLawyerIdVariancePopulationTokenExpirationAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_TOKEN_EXPIRATION_ASC',
  CronofyAuthsByLawyerIdVariancePopulationTokenExpirationDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_TOKEN_EXPIRATION_DESC',
  CronofyAuthsByLawyerIdVariancePopulationUpdatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  CronofyAuthsByLawyerIdVariancePopulationUpdatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  CronofyAuthsByLawyerIdVarianceSampleAccessTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_ACCESS_TOKEN_ASC',
  CronofyAuthsByLawyerIdVarianceSampleAccessTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_ACCESS_TOKEN_DESC',
  CronofyAuthsByLawyerIdVarianceSampleCreatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CronofyAuthsByLawyerIdVarianceSampleCreatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CronofyAuthsByLawyerIdVarianceSampleIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  CronofyAuthsByLawyerIdVarianceSampleIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  CronofyAuthsByLawyerIdVarianceSampleLawyerIdAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  CronofyAuthsByLawyerIdVarianceSampleLawyerIdDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  CronofyAuthsByLawyerIdVarianceSampleRefreshTokenAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_REFRESH_TOKEN_ASC',
  CronofyAuthsByLawyerIdVarianceSampleRefreshTokenDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_REFRESH_TOKEN_DESC',
  CronofyAuthsByLawyerIdVarianceSampleTokenExpirationAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_TOKEN_EXPIRATION_ASC',
  CronofyAuthsByLawyerIdVarianceSampleTokenExpirationDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_TOKEN_EXPIRATION_DESC',
  CronofyAuthsByLawyerIdVarianceSampleUpdatedTimeAsc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  CronofyAuthsByLawyerIdVarianceSampleUpdatedTimeDesc = 'CRONOFY_AUTHS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  DailyStatisticsByLawyerIdAverageCampaignIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_CAMPAIGN_ID_ASC',
  DailyStatisticsByLawyerIdAverageCampaignIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_CAMPAIGN_ID_DESC',
  DailyStatisticsByLawyerIdAverageDateAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_DATE_ASC',
  DailyStatisticsByLawyerIdAverageDateDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_DATE_DESC',
  DailyStatisticsByLawyerIdAverageGroupNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_GROUP_NAME_ASC',
  DailyStatisticsByLawyerIdAverageGroupNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_GROUP_NAME_DESC',
  DailyStatisticsByLawyerIdAverageIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  DailyStatisticsByLawyerIdAverageIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  DailyStatisticsByLawyerIdAverageLawyerIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  DailyStatisticsByLawyerIdAverageLawyerIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  DailyStatisticsByLawyerIdAverageNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_NAME_ASC',
  DailyStatisticsByLawyerIdAverageNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_NAME_DESC',
  DailyStatisticsByLawyerIdAverageTemplateIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_TEMPLATE_ID_ASC',
  DailyStatisticsByLawyerIdAverageTemplateIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_TEMPLATE_ID_DESC',
  DailyStatisticsByLawyerIdAverageValueAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_VALUE_ASC',
  DailyStatisticsByLawyerIdAverageValueDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_AVERAGE_VALUE_DESC',
  DailyStatisticsByLawyerIdCountAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_COUNT_ASC',
  DailyStatisticsByLawyerIdCountDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_COUNT_DESC',
  DailyStatisticsByLawyerIdDistinctCountCampaignIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  DailyStatisticsByLawyerIdDistinctCountCampaignIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  DailyStatisticsByLawyerIdDistinctCountDateAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_DATE_ASC',
  DailyStatisticsByLawyerIdDistinctCountDateDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_DATE_DESC',
  DailyStatisticsByLawyerIdDistinctCountGroupNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_GROUP_NAME_ASC',
  DailyStatisticsByLawyerIdDistinctCountGroupNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_GROUP_NAME_DESC',
  DailyStatisticsByLawyerIdDistinctCountIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  DailyStatisticsByLawyerIdDistinctCountIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  DailyStatisticsByLawyerIdDistinctCountLawyerIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  DailyStatisticsByLawyerIdDistinctCountLawyerIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  DailyStatisticsByLawyerIdDistinctCountNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_NAME_ASC',
  DailyStatisticsByLawyerIdDistinctCountNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_NAME_DESC',
  DailyStatisticsByLawyerIdDistinctCountTemplateIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  DailyStatisticsByLawyerIdDistinctCountTemplateIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  DailyStatisticsByLawyerIdDistinctCountValueAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_VALUE_ASC',
  DailyStatisticsByLawyerIdDistinctCountValueDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_VALUE_DESC',
  DailyStatisticsByLawyerIdMaxCampaignIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_CAMPAIGN_ID_ASC',
  DailyStatisticsByLawyerIdMaxCampaignIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_CAMPAIGN_ID_DESC',
  DailyStatisticsByLawyerIdMaxDateAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_DATE_ASC',
  DailyStatisticsByLawyerIdMaxDateDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_DATE_DESC',
  DailyStatisticsByLawyerIdMaxGroupNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_GROUP_NAME_ASC',
  DailyStatisticsByLawyerIdMaxGroupNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_GROUP_NAME_DESC',
  DailyStatisticsByLawyerIdMaxIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_ID_ASC',
  DailyStatisticsByLawyerIdMaxIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_ID_DESC',
  DailyStatisticsByLawyerIdMaxLawyerIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  DailyStatisticsByLawyerIdMaxLawyerIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  DailyStatisticsByLawyerIdMaxNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_NAME_ASC',
  DailyStatisticsByLawyerIdMaxNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_NAME_DESC',
  DailyStatisticsByLawyerIdMaxTemplateIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_TEMPLATE_ID_ASC',
  DailyStatisticsByLawyerIdMaxTemplateIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_TEMPLATE_ID_DESC',
  DailyStatisticsByLawyerIdMaxValueAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_VALUE_ASC',
  DailyStatisticsByLawyerIdMaxValueDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MAX_VALUE_DESC',
  DailyStatisticsByLawyerIdMinCampaignIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_CAMPAIGN_ID_ASC',
  DailyStatisticsByLawyerIdMinCampaignIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_CAMPAIGN_ID_DESC',
  DailyStatisticsByLawyerIdMinDateAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_DATE_ASC',
  DailyStatisticsByLawyerIdMinDateDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_DATE_DESC',
  DailyStatisticsByLawyerIdMinGroupNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_GROUP_NAME_ASC',
  DailyStatisticsByLawyerIdMinGroupNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_GROUP_NAME_DESC',
  DailyStatisticsByLawyerIdMinIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_ID_ASC',
  DailyStatisticsByLawyerIdMinIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_ID_DESC',
  DailyStatisticsByLawyerIdMinLawyerIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  DailyStatisticsByLawyerIdMinLawyerIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  DailyStatisticsByLawyerIdMinNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_NAME_ASC',
  DailyStatisticsByLawyerIdMinNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_NAME_DESC',
  DailyStatisticsByLawyerIdMinTemplateIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_TEMPLATE_ID_ASC',
  DailyStatisticsByLawyerIdMinTemplateIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_TEMPLATE_ID_DESC',
  DailyStatisticsByLawyerIdMinValueAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_VALUE_ASC',
  DailyStatisticsByLawyerIdMinValueDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_MIN_VALUE_DESC',
  DailyStatisticsByLawyerIdStddevPopulationCampaignIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  DailyStatisticsByLawyerIdStddevPopulationCampaignIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  DailyStatisticsByLawyerIdStddevPopulationDateAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_DATE_ASC',
  DailyStatisticsByLawyerIdStddevPopulationDateDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_DATE_DESC',
  DailyStatisticsByLawyerIdStddevPopulationGroupNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_GROUP_NAME_ASC',
  DailyStatisticsByLawyerIdStddevPopulationGroupNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_GROUP_NAME_DESC',
  DailyStatisticsByLawyerIdStddevPopulationIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  DailyStatisticsByLawyerIdStddevPopulationIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  DailyStatisticsByLawyerIdStddevPopulationLawyerIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  DailyStatisticsByLawyerIdStddevPopulationLawyerIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  DailyStatisticsByLawyerIdStddevPopulationNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_NAME_ASC',
  DailyStatisticsByLawyerIdStddevPopulationNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_NAME_DESC',
  DailyStatisticsByLawyerIdStddevPopulationTemplateIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  DailyStatisticsByLawyerIdStddevPopulationTemplateIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  DailyStatisticsByLawyerIdStddevPopulationValueAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_VALUE_ASC',
  DailyStatisticsByLawyerIdStddevPopulationValueDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_VALUE_DESC',
  DailyStatisticsByLawyerIdStddevSampleCampaignIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  DailyStatisticsByLawyerIdStddevSampleCampaignIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  DailyStatisticsByLawyerIdStddevSampleDateAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_DATE_ASC',
  DailyStatisticsByLawyerIdStddevSampleDateDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_DATE_DESC',
  DailyStatisticsByLawyerIdStddevSampleGroupNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_GROUP_NAME_ASC',
  DailyStatisticsByLawyerIdStddevSampleGroupNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_GROUP_NAME_DESC',
  DailyStatisticsByLawyerIdStddevSampleIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  DailyStatisticsByLawyerIdStddevSampleIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  DailyStatisticsByLawyerIdStddevSampleLawyerIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  DailyStatisticsByLawyerIdStddevSampleLawyerIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  DailyStatisticsByLawyerIdStddevSampleNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_NAME_ASC',
  DailyStatisticsByLawyerIdStddevSampleNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_NAME_DESC',
  DailyStatisticsByLawyerIdStddevSampleTemplateIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  DailyStatisticsByLawyerIdStddevSampleTemplateIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  DailyStatisticsByLawyerIdStddevSampleValueAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_VALUE_ASC',
  DailyStatisticsByLawyerIdStddevSampleValueDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_VALUE_DESC',
  DailyStatisticsByLawyerIdSumCampaignIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_CAMPAIGN_ID_ASC',
  DailyStatisticsByLawyerIdSumCampaignIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_CAMPAIGN_ID_DESC',
  DailyStatisticsByLawyerIdSumDateAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_DATE_ASC',
  DailyStatisticsByLawyerIdSumDateDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_DATE_DESC',
  DailyStatisticsByLawyerIdSumGroupNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_GROUP_NAME_ASC',
  DailyStatisticsByLawyerIdSumGroupNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_GROUP_NAME_DESC',
  DailyStatisticsByLawyerIdSumIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_ID_ASC',
  DailyStatisticsByLawyerIdSumIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_ID_DESC',
  DailyStatisticsByLawyerIdSumLawyerIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  DailyStatisticsByLawyerIdSumLawyerIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  DailyStatisticsByLawyerIdSumNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_NAME_ASC',
  DailyStatisticsByLawyerIdSumNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_NAME_DESC',
  DailyStatisticsByLawyerIdSumTemplateIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_TEMPLATE_ID_ASC',
  DailyStatisticsByLawyerIdSumTemplateIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_TEMPLATE_ID_DESC',
  DailyStatisticsByLawyerIdSumValueAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_VALUE_ASC',
  DailyStatisticsByLawyerIdSumValueDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_SUM_VALUE_DESC',
  DailyStatisticsByLawyerIdVariancePopulationCampaignIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  DailyStatisticsByLawyerIdVariancePopulationCampaignIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  DailyStatisticsByLawyerIdVariancePopulationDateAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_DATE_ASC',
  DailyStatisticsByLawyerIdVariancePopulationDateDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_DATE_DESC',
  DailyStatisticsByLawyerIdVariancePopulationGroupNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_GROUP_NAME_ASC',
  DailyStatisticsByLawyerIdVariancePopulationGroupNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_GROUP_NAME_DESC',
  DailyStatisticsByLawyerIdVariancePopulationIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  DailyStatisticsByLawyerIdVariancePopulationIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  DailyStatisticsByLawyerIdVariancePopulationLawyerIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  DailyStatisticsByLawyerIdVariancePopulationLawyerIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  DailyStatisticsByLawyerIdVariancePopulationNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_NAME_ASC',
  DailyStatisticsByLawyerIdVariancePopulationNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_NAME_DESC',
  DailyStatisticsByLawyerIdVariancePopulationTemplateIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  DailyStatisticsByLawyerIdVariancePopulationTemplateIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  DailyStatisticsByLawyerIdVariancePopulationValueAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_VALUE_ASC',
  DailyStatisticsByLawyerIdVariancePopulationValueDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_VALUE_DESC',
  DailyStatisticsByLawyerIdVarianceSampleCampaignIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  DailyStatisticsByLawyerIdVarianceSampleCampaignIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  DailyStatisticsByLawyerIdVarianceSampleDateAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_DATE_ASC',
  DailyStatisticsByLawyerIdVarianceSampleDateDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_DATE_DESC',
  DailyStatisticsByLawyerIdVarianceSampleGroupNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_GROUP_NAME_ASC',
  DailyStatisticsByLawyerIdVarianceSampleGroupNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_GROUP_NAME_DESC',
  DailyStatisticsByLawyerIdVarianceSampleIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  DailyStatisticsByLawyerIdVarianceSampleIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  DailyStatisticsByLawyerIdVarianceSampleLawyerIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  DailyStatisticsByLawyerIdVarianceSampleLawyerIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  DailyStatisticsByLawyerIdVarianceSampleNameAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_NAME_ASC',
  DailyStatisticsByLawyerIdVarianceSampleNameDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_NAME_DESC',
  DailyStatisticsByLawyerIdVarianceSampleTemplateIdAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  DailyStatisticsByLawyerIdVarianceSampleTemplateIdDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  DailyStatisticsByLawyerIdVarianceSampleValueAsc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_VALUE_ASC',
  DailyStatisticsByLawyerIdVarianceSampleValueDesc = 'DAILY_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_VALUE_DESC',
  DomainsByLawyerIdAverageCreatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  DomainsByLawyerIdAverageCreatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  DomainsByLawyerIdAverageDnsGetStatusAsc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_DNS_GET_STATUS_ASC',
  DomainsByLawyerIdAverageDnsGetStatusDesc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_DNS_GET_STATUS_DESC',
  DomainsByLawyerIdAverageDnsGetStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_DNS_GET_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdAverageDnsGetStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_DNS_GET_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdAverageExpirationTimeAsc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_EXPIRATION_TIME_ASC',
  DomainsByLawyerIdAverageExpirationTimeDesc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_EXPIRATION_TIME_DESC',
  DomainsByLawyerIdAverageForwardedStatusAsc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_FORWARDED_STATUS_ASC',
  DomainsByLawyerIdAverageForwardedStatusDesc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_FORWARDED_STATUS_DESC',
  DomainsByLawyerIdAverageForwardedStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_FORWARDED_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdAverageForwardedStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_FORWARDED_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdAverageIdAsc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  DomainsByLawyerIdAverageIdDesc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  DomainsByLawyerIdAverageLawyerIdAsc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  DomainsByLawyerIdAverageLawyerIdDesc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  DomainsByLawyerIdAverageNameAsc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_NAME_ASC',
  DomainsByLawyerIdAverageNameDesc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_NAME_DESC',
  DomainsByLawyerIdAveragePriceAsc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_PRICE_ASC',
  DomainsByLawyerIdAveragePriceDesc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_PRICE_DESC',
  DomainsByLawyerIdAverageRegistrationTimeAsc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_REGISTRATION_TIME_ASC',
  DomainsByLawyerIdAverageRegistrationTimeDesc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_REGISTRATION_TIME_DESC',
  DomainsByLawyerIdAverageThirdPartyProviderIdAsc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_THIRD_PARTY_PROVIDER_ID_ASC',
  DomainsByLawyerIdAverageThirdPartyProviderIdDesc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_THIRD_PARTY_PROVIDER_ID_DESC',
  DomainsByLawyerIdAverageUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  DomainsByLawyerIdAverageUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  DomainsByLawyerIdCountAsc = 'DOMAINS_BY_LAWYER_ID_COUNT_ASC',
  DomainsByLawyerIdCountDesc = 'DOMAINS_BY_LAWYER_ID_COUNT_DESC',
  DomainsByLawyerIdDistinctCountCreatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  DomainsByLawyerIdDistinctCountCreatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  DomainsByLawyerIdDistinctCountDnsGetStatusAsc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_DNS_GET_STATUS_ASC',
  DomainsByLawyerIdDistinctCountDnsGetStatusDesc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_DNS_GET_STATUS_DESC',
  DomainsByLawyerIdDistinctCountDnsGetStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_DNS_GET_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdDistinctCountDnsGetStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_DNS_GET_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdDistinctCountExpirationTimeAsc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_EXPIRATION_TIME_ASC',
  DomainsByLawyerIdDistinctCountExpirationTimeDesc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_EXPIRATION_TIME_DESC',
  DomainsByLawyerIdDistinctCountForwardedStatusAsc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_FORWARDED_STATUS_ASC',
  DomainsByLawyerIdDistinctCountForwardedStatusDesc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_FORWARDED_STATUS_DESC',
  DomainsByLawyerIdDistinctCountForwardedStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_FORWARDED_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdDistinctCountForwardedStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_FORWARDED_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdDistinctCountIdAsc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  DomainsByLawyerIdDistinctCountIdDesc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  DomainsByLawyerIdDistinctCountLawyerIdAsc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  DomainsByLawyerIdDistinctCountLawyerIdDesc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  DomainsByLawyerIdDistinctCountNameAsc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_NAME_ASC',
  DomainsByLawyerIdDistinctCountNameDesc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_NAME_DESC',
  DomainsByLawyerIdDistinctCountPriceAsc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_PRICE_ASC',
  DomainsByLawyerIdDistinctCountPriceDesc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_PRICE_DESC',
  DomainsByLawyerIdDistinctCountRegistrationTimeAsc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_REGISTRATION_TIME_ASC',
  DomainsByLawyerIdDistinctCountRegistrationTimeDesc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_REGISTRATION_TIME_DESC',
  DomainsByLawyerIdDistinctCountThirdPartyProviderIdAsc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_THIRD_PARTY_PROVIDER_ID_ASC',
  DomainsByLawyerIdDistinctCountThirdPartyProviderIdDesc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_THIRD_PARTY_PROVIDER_ID_DESC',
  DomainsByLawyerIdDistinctCountUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  DomainsByLawyerIdDistinctCountUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  DomainsByLawyerIdMaxCreatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  DomainsByLawyerIdMaxCreatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  DomainsByLawyerIdMaxDnsGetStatusAsc = 'DOMAINS_BY_LAWYER_ID_MAX_DNS_GET_STATUS_ASC',
  DomainsByLawyerIdMaxDnsGetStatusDesc = 'DOMAINS_BY_LAWYER_ID_MAX_DNS_GET_STATUS_DESC',
  DomainsByLawyerIdMaxDnsGetStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_MAX_DNS_GET_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdMaxDnsGetStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_MAX_DNS_GET_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdMaxExpirationTimeAsc = 'DOMAINS_BY_LAWYER_ID_MAX_EXPIRATION_TIME_ASC',
  DomainsByLawyerIdMaxExpirationTimeDesc = 'DOMAINS_BY_LAWYER_ID_MAX_EXPIRATION_TIME_DESC',
  DomainsByLawyerIdMaxForwardedStatusAsc = 'DOMAINS_BY_LAWYER_ID_MAX_FORWARDED_STATUS_ASC',
  DomainsByLawyerIdMaxForwardedStatusDesc = 'DOMAINS_BY_LAWYER_ID_MAX_FORWARDED_STATUS_DESC',
  DomainsByLawyerIdMaxForwardedStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_MAX_FORWARDED_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdMaxForwardedStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_MAX_FORWARDED_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdMaxIdAsc = 'DOMAINS_BY_LAWYER_ID_MAX_ID_ASC',
  DomainsByLawyerIdMaxIdDesc = 'DOMAINS_BY_LAWYER_ID_MAX_ID_DESC',
  DomainsByLawyerIdMaxLawyerIdAsc = 'DOMAINS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  DomainsByLawyerIdMaxLawyerIdDesc = 'DOMAINS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  DomainsByLawyerIdMaxNameAsc = 'DOMAINS_BY_LAWYER_ID_MAX_NAME_ASC',
  DomainsByLawyerIdMaxNameDesc = 'DOMAINS_BY_LAWYER_ID_MAX_NAME_DESC',
  DomainsByLawyerIdMaxPriceAsc = 'DOMAINS_BY_LAWYER_ID_MAX_PRICE_ASC',
  DomainsByLawyerIdMaxPriceDesc = 'DOMAINS_BY_LAWYER_ID_MAX_PRICE_DESC',
  DomainsByLawyerIdMaxRegistrationTimeAsc = 'DOMAINS_BY_LAWYER_ID_MAX_REGISTRATION_TIME_ASC',
  DomainsByLawyerIdMaxRegistrationTimeDesc = 'DOMAINS_BY_LAWYER_ID_MAX_REGISTRATION_TIME_DESC',
  DomainsByLawyerIdMaxThirdPartyProviderIdAsc = 'DOMAINS_BY_LAWYER_ID_MAX_THIRD_PARTY_PROVIDER_ID_ASC',
  DomainsByLawyerIdMaxThirdPartyProviderIdDesc = 'DOMAINS_BY_LAWYER_ID_MAX_THIRD_PARTY_PROVIDER_ID_DESC',
  DomainsByLawyerIdMaxUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  DomainsByLawyerIdMaxUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  DomainsByLawyerIdMinCreatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  DomainsByLawyerIdMinCreatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  DomainsByLawyerIdMinDnsGetStatusAsc = 'DOMAINS_BY_LAWYER_ID_MIN_DNS_GET_STATUS_ASC',
  DomainsByLawyerIdMinDnsGetStatusDesc = 'DOMAINS_BY_LAWYER_ID_MIN_DNS_GET_STATUS_DESC',
  DomainsByLawyerIdMinDnsGetStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_MIN_DNS_GET_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdMinDnsGetStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_MIN_DNS_GET_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdMinExpirationTimeAsc = 'DOMAINS_BY_LAWYER_ID_MIN_EXPIRATION_TIME_ASC',
  DomainsByLawyerIdMinExpirationTimeDesc = 'DOMAINS_BY_LAWYER_ID_MIN_EXPIRATION_TIME_DESC',
  DomainsByLawyerIdMinForwardedStatusAsc = 'DOMAINS_BY_LAWYER_ID_MIN_FORWARDED_STATUS_ASC',
  DomainsByLawyerIdMinForwardedStatusDesc = 'DOMAINS_BY_LAWYER_ID_MIN_FORWARDED_STATUS_DESC',
  DomainsByLawyerIdMinForwardedStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_MIN_FORWARDED_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdMinForwardedStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_MIN_FORWARDED_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdMinIdAsc = 'DOMAINS_BY_LAWYER_ID_MIN_ID_ASC',
  DomainsByLawyerIdMinIdDesc = 'DOMAINS_BY_LAWYER_ID_MIN_ID_DESC',
  DomainsByLawyerIdMinLawyerIdAsc = 'DOMAINS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  DomainsByLawyerIdMinLawyerIdDesc = 'DOMAINS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  DomainsByLawyerIdMinNameAsc = 'DOMAINS_BY_LAWYER_ID_MIN_NAME_ASC',
  DomainsByLawyerIdMinNameDesc = 'DOMAINS_BY_LAWYER_ID_MIN_NAME_DESC',
  DomainsByLawyerIdMinPriceAsc = 'DOMAINS_BY_LAWYER_ID_MIN_PRICE_ASC',
  DomainsByLawyerIdMinPriceDesc = 'DOMAINS_BY_LAWYER_ID_MIN_PRICE_DESC',
  DomainsByLawyerIdMinRegistrationTimeAsc = 'DOMAINS_BY_LAWYER_ID_MIN_REGISTRATION_TIME_ASC',
  DomainsByLawyerIdMinRegistrationTimeDesc = 'DOMAINS_BY_LAWYER_ID_MIN_REGISTRATION_TIME_DESC',
  DomainsByLawyerIdMinThirdPartyProviderIdAsc = 'DOMAINS_BY_LAWYER_ID_MIN_THIRD_PARTY_PROVIDER_ID_ASC',
  DomainsByLawyerIdMinThirdPartyProviderIdDesc = 'DOMAINS_BY_LAWYER_ID_MIN_THIRD_PARTY_PROVIDER_ID_DESC',
  DomainsByLawyerIdMinUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  DomainsByLawyerIdMinUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  DomainsByLawyerIdStddevPopulationCreatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  DomainsByLawyerIdStddevPopulationCreatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  DomainsByLawyerIdStddevPopulationDnsGetStatusAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_DNS_GET_STATUS_ASC',
  DomainsByLawyerIdStddevPopulationDnsGetStatusDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_DNS_GET_STATUS_DESC',
  DomainsByLawyerIdStddevPopulationDnsGetStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_DNS_GET_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdStddevPopulationDnsGetStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_DNS_GET_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdStddevPopulationExpirationTimeAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_EXPIRATION_TIME_ASC',
  DomainsByLawyerIdStddevPopulationExpirationTimeDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_EXPIRATION_TIME_DESC',
  DomainsByLawyerIdStddevPopulationForwardedStatusAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_FORWARDED_STATUS_ASC',
  DomainsByLawyerIdStddevPopulationForwardedStatusDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_FORWARDED_STATUS_DESC',
  DomainsByLawyerIdStddevPopulationForwardedStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_FORWARDED_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdStddevPopulationForwardedStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_FORWARDED_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdStddevPopulationIdAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  DomainsByLawyerIdStddevPopulationIdDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  DomainsByLawyerIdStddevPopulationLawyerIdAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  DomainsByLawyerIdStddevPopulationLawyerIdDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  DomainsByLawyerIdStddevPopulationNameAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_NAME_ASC',
  DomainsByLawyerIdStddevPopulationNameDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_NAME_DESC',
  DomainsByLawyerIdStddevPopulationPriceAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_PRICE_ASC',
  DomainsByLawyerIdStddevPopulationPriceDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_PRICE_DESC',
  DomainsByLawyerIdStddevPopulationRegistrationTimeAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_REGISTRATION_TIME_ASC',
  DomainsByLawyerIdStddevPopulationRegistrationTimeDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_REGISTRATION_TIME_DESC',
  DomainsByLawyerIdStddevPopulationThirdPartyProviderIdAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_THIRD_PARTY_PROVIDER_ID_ASC',
  DomainsByLawyerIdStddevPopulationThirdPartyProviderIdDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_THIRD_PARTY_PROVIDER_ID_DESC',
  DomainsByLawyerIdStddevPopulationUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  DomainsByLawyerIdStddevPopulationUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  DomainsByLawyerIdStddevSampleCreatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  DomainsByLawyerIdStddevSampleCreatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  DomainsByLawyerIdStddevSampleDnsGetStatusAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_DNS_GET_STATUS_ASC',
  DomainsByLawyerIdStddevSampleDnsGetStatusDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_DNS_GET_STATUS_DESC',
  DomainsByLawyerIdStddevSampleDnsGetStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_DNS_GET_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdStddevSampleDnsGetStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_DNS_GET_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdStddevSampleExpirationTimeAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_EXPIRATION_TIME_ASC',
  DomainsByLawyerIdStddevSampleExpirationTimeDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_EXPIRATION_TIME_DESC',
  DomainsByLawyerIdStddevSampleForwardedStatusAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_FORWARDED_STATUS_ASC',
  DomainsByLawyerIdStddevSampleForwardedStatusDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_FORWARDED_STATUS_DESC',
  DomainsByLawyerIdStddevSampleForwardedStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_FORWARDED_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdStddevSampleForwardedStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_FORWARDED_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdStddevSampleIdAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  DomainsByLawyerIdStddevSampleIdDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  DomainsByLawyerIdStddevSampleLawyerIdAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  DomainsByLawyerIdStddevSampleLawyerIdDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  DomainsByLawyerIdStddevSampleNameAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_NAME_ASC',
  DomainsByLawyerIdStddevSampleNameDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_NAME_DESC',
  DomainsByLawyerIdStddevSamplePriceAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_PRICE_ASC',
  DomainsByLawyerIdStddevSamplePriceDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_PRICE_DESC',
  DomainsByLawyerIdStddevSampleRegistrationTimeAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_REGISTRATION_TIME_ASC',
  DomainsByLawyerIdStddevSampleRegistrationTimeDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_REGISTRATION_TIME_DESC',
  DomainsByLawyerIdStddevSampleThirdPartyProviderIdAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_THIRD_PARTY_PROVIDER_ID_ASC',
  DomainsByLawyerIdStddevSampleThirdPartyProviderIdDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_THIRD_PARTY_PROVIDER_ID_DESC',
  DomainsByLawyerIdStddevSampleUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  DomainsByLawyerIdStddevSampleUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  DomainsByLawyerIdSumCreatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  DomainsByLawyerIdSumCreatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  DomainsByLawyerIdSumDnsGetStatusAsc = 'DOMAINS_BY_LAWYER_ID_SUM_DNS_GET_STATUS_ASC',
  DomainsByLawyerIdSumDnsGetStatusDesc = 'DOMAINS_BY_LAWYER_ID_SUM_DNS_GET_STATUS_DESC',
  DomainsByLawyerIdSumDnsGetStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_SUM_DNS_GET_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdSumDnsGetStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_SUM_DNS_GET_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdSumExpirationTimeAsc = 'DOMAINS_BY_LAWYER_ID_SUM_EXPIRATION_TIME_ASC',
  DomainsByLawyerIdSumExpirationTimeDesc = 'DOMAINS_BY_LAWYER_ID_SUM_EXPIRATION_TIME_DESC',
  DomainsByLawyerIdSumForwardedStatusAsc = 'DOMAINS_BY_LAWYER_ID_SUM_FORWARDED_STATUS_ASC',
  DomainsByLawyerIdSumForwardedStatusDesc = 'DOMAINS_BY_LAWYER_ID_SUM_FORWARDED_STATUS_DESC',
  DomainsByLawyerIdSumForwardedStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_SUM_FORWARDED_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdSumForwardedStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_SUM_FORWARDED_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdSumIdAsc = 'DOMAINS_BY_LAWYER_ID_SUM_ID_ASC',
  DomainsByLawyerIdSumIdDesc = 'DOMAINS_BY_LAWYER_ID_SUM_ID_DESC',
  DomainsByLawyerIdSumLawyerIdAsc = 'DOMAINS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  DomainsByLawyerIdSumLawyerIdDesc = 'DOMAINS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  DomainsByLawyerIdSumNameAsc = 'DOMAINS_BY_LAWYER_ID_SUM_NAME_ASC',
  DomainsByLawyerIdSumNameDesc = 'DOMAINS_BY_LAWYER_ID_SUM_NAME_DESC',
  DomainsByLawyerIdSumPriceAsc = 'DOMAINS_BY_LAWYER_ID_SUM_PRICE_ASC',
  DomainsByLawyerIdSumPriceDesc = 'DOMAINS_BY_LAWYER_ID_SUM_PRICE_DESC',
  DomainsByLawyerIdSumRegistrationTimeAsc = 'DOMAINS_BY_LAWYER_ID_SUM_REGISTRATION_TIME_ASC',
  DomainsByLawyerIdSumRegistrationTimeDesc = 'DOMAINS_BY_LAWYER_ID_SUM_REGISTRATION_TIME_DESC',
  DomainsByLawyerIdSumThirdPartyProviderIdAsc = 'DOMAINS_BY_LAWYER_ID_SUM_THIRD_PARTY_PROVIDER_ID_ASC',
  DomainsByLawyerIdSumThirdPartyProviderIdDesc = 'DOMAINS_BY_LAWYER_ID_SUM_THIRD_PARTY_PROVIDER_ID_DESC',
  DomainsByLawyerIdSumUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  DomainsByLawyerIdSumUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  DomainsByLawyerIdVariancePopulationCreatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  DomainsByLawyerIdVariancePopulationCreatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  DomainsByLawyerIdVariancePopulationDnsGetStatusAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_DNS_GET_STATUS_ASC',
  DomainsByLawyerIdVariancePopulationDnsGetStatusDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_DNS_GET_STATUS_DESC',
  DomainsByLawyerIdVariancePopulationDnsGetStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_DNS_GET_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdVariancePopulationDnsGetStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_DNS_GET_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdVariancePopulationExpirationTimeAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_EXPIRATION_TIME_ASC',
  DomainsByLawyerIdVariancePopulationExpirationTimeDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_EXPIRATION_TIME_DESC',
  DomainsByLawyerIdVariancePopulationForwardedStatusAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_FORWARDED_STATUS_ASC',
  DomainsByLawyerIdVariancePopulationForwardedStatusDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_FORWARDED_STATUS_DESC',
  DomainsByLawyerIdVariancePopulationForwardedStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_FORWARDED_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdVariancePopulationForwardedStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_FORWARDED_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdVariancePopulationIdAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  DomainsByLawyerIdVariancePopulationIdDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  DomainsByLawyerIdVariancePopulationLawyerIdAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  DomainsByLawyerIdVariancePopulationLawyerIdDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  DomainsByLawyerIdVariancePopulationNameAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_NAME_ASC',
  DomainsByLawyerIdVariancePopulationNameDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_NAME_DESC',
  DomainsByLawyerIdVariancePopulationPriceAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_PRICE_ASC',
  DomainsByLawyerIdVariancePopulationPriceDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_PRICE_DESC',
  DomainsByLawyerIdVariancePopulationRegistrationTimeAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_REGISTRATION_TIME_ASC',
  DomainsByLawyerIdVariancePopulationRegistrationTimeDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_REGISTRATION_TIME_DESC',
  DomainsByLawyerIdVariancePopulationThirdPartyProviderIdAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_THIRD_PARTY_PROVIDER_ID_ASC',
  DomainsByLawyerIdVariancePopulationThirdPartyProviderIdDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_THIRD_PARTY_PROVIDER_ID_DESC',
  DomainsByLawyerIdVariancePopulationUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  DomainsByLawyerIdVariancePopulationUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  DomainsByLawyerIdVarianceSampleCreatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  DomainsByLawyerIdVarianceSampleCreatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  DomainsByLawyerIdVarianceSampleDnsGetStatusAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_DNS_GET_STATUS_ASC',
  DomainsByLawyerIdVarianceSampleDnsGetStatusDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_DNS_GET_STATUS_DESC',
  DomainsByLawyerIdVarianceSampleDnsGetStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_DNS_GET_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdVarianceSampleDnsGetStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_DNS_GET_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdVarianceSampleExpirationTimeAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_EXPIRATION_TIME_ASC',
  DomainsByLawyerIdVarianceSampleExpirationTimeDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_EXPIRATION_TIME_DESC',
  DomainsByLawyerIdVarianceSampleForwardedStatusAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_FORWARDED_STATUS_ASC',
  DomainsByLawyerIdVarianceSampleForwardedStatusDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_FORWARDED_STATUS_DESC',
  DomainsByLawyerIdVarianceSampleForwardedStatusUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_FORWARDED_STATUS_UPDATED_TIME_ASC',
  DomainsByLawyerIdVarianceSampleForwardedStatusUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_FORWARDED_STATUS_UPDATED_TIME_DESC',
  DomainsByLawyerIdVarianceSampleIdAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  DomainsByLawyerIdVarianceSampleIdDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  DomainsByLawyerIdVarianceSampleLawyerIdAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  DomainsByLawyerIdVarianceSampleLawyerIdDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  DomainsByLawyerIdVarianceSampleNameAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_NAME_ASC',
  DomainsByLawyerIdVarianceSampleNameDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_NAME_DESC',
  DomainsByLawyerIdVarianceSamplePriceAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_PRICE_ASC',
  DomainsByLawyerIdVarianceSamplePriceDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_PRICE_DESC',
  DomainsByLawyerIdVarianceSampleRegistrationTimeAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_REGISTRATION_TIME_ASC',
  DomainsByLawyerIdVarianceSampleRegistrationTimeDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_REGISTRATION_TIME_DESC',
  DomainsByLawyerIdVarianceSampleThirdPartyProviderIdAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_THIRD_PARTY_PROVIDER_ID_ASC',
  DomainsByLawyerIdVarianceSampleThirdPartyProviderIdDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_THIRD_PARTY_PROVIDER_ID_DESC',
  DomainsByLawyerIdVarianceSampleUpdatedTimeAsc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  DomainsByLawyerIdVarianceSampleUpdatedTimeDesc = 'DOMAINS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdAverageCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdAverageEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdAverageErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdAverageErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByLawyerIdAverageFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdAverageIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdAverageIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByLawyerIdAverageLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdAverageResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByLawyerIdAverageResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdAverageToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdAverageToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdAverageUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdAverageUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdCountAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_COUNT_ASC',
  EmailDeliverabilityResultsByLawyerIdCountDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_COUNT_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdDistinctCountUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdMaxUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdMaxUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdMinContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdMinContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdMinCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdMinCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdMinEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdMinEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdMinErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdMinErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdMinErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByLawyerIdMinErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByLawyerIdMinFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdMinFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdMinIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdMinIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdMinIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByLawyerIdMinIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByLawyerIdMinLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdMinLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdMinResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByLawyerIdMinResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByLawyerIdMinResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdMinResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdMinToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdMinToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdMinToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdMinToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdMinUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdMinUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevPopulationUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdStddevSampleUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdSumContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdSumContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdSumCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdSumCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdSumEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdSumEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdSumErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdSumErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdSumErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByLawyerIdSumErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByLawyerIdSumFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdSumFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdSumIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdSumIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdSumIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByLawyerIdSumIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByLawyerIdSumLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdSumLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdSumResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByLawyerIdSumResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByLawyerIdSumResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdSumResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdSumToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdSumToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdSumToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdSumToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdSumUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdSumUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdVariancePopulationUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleContactIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleContactIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleCreatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleCreatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleEmailIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleEmailIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleErrorMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ERROR_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleErrorMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ERROR_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleErrorTypeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ERROR_TYPE_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleErrorTypeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ERROR_TYPE_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleFromEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_FROM_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleFromEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_FROM_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleIsSuccessAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_SUCCESS_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleIsSuccessDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_SUCCESS_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleLawyerIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleLawyerIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleResponseCodeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_RESPONSE_CODE_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleResponseCodeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_RESPONSE_CODE_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleResponseMessageAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_RESPONSE_MESSAGE_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleResponseMessageDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_RESPONSE_MESSAGE_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleToEmailAddressIdAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_TO_EMAIL_ADDRESS_ID_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleToEmailAddressIdDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_TO_EMAIL_ADDRESS_ID_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleToEmailAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_TO_EMAIL_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleToEmailDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_TO_EMAIL_DESC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleUpdatedTimeAsc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailDeliverabilityResultsByLawyerIdVarianceSampleUpdatedTimeDesc = 'EMAIL_DELIVERABILITY_RESULTS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EmailDesc = 'EMAIL_DESC',
  EmailMessagesByLawyerIdAverageAutoRepliedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_AUTO_REPLIED_ASC',
  EmailMessagesByLawyerIdAverageAutoRepliedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_AUTO_REPLIED_DESC',
  EmailMessagesByLawyerIdAverageCampaignIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CAMPAIGN_ID_ASC',
  EmailMessagesByLawyerIdAverageCampaignIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CAMPAIGN_ID_DESC',
  EmailMessagesByLawyerIdAverageCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByLawyerIdAverageCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByLawyerIdAverageCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdAverageCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdAverageCompanyIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_COMPANY_ID_ASC',
  EmailMessagesByLawyerIdAverageCompanyIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_COMPANY_ID_DESC',
  EmailMessagesByLawyerIdAverageContactIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CONTACT_ID_ASC',
  EmailMessagesByLawyerIdAverageContactIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CONTACT_ID_DESC',
  EmailMessagesByLawyerIdAverageContentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CONTENT_ASC',
  EmailMessagesByLawyerIdAverageContentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CONTENT_DESC',
  EmailMessagesByLawyerIdAverageContentHtmlAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CONTENT_HTML_ASC',
  EmailMessagesByLawyerIdAverageContentHtmlDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CONTENT_HTML_DESC',
  EmailMessagesByLawyerIdAverageCreatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  EmailMessagesByLawyerIdAverageCreatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  EmailMessagesByLawyerIdAverageEmailIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_EMAIL_ID_ASC',
  EmailMessagesByLawyerIdAverageEmailIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_EMAIL_ID_DESC',
  EmailMessagesByLawyerIdAverageForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByLawyerIdAverageForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByLawyerIdAverageForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdAverageForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdAverageGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_GENERATED_REPLY_ASC',
  EmailMessagesByLawyerIdAverageGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_GENERATED_REPLY_DESC',
  EmailMessagesByLawyerIdAverageHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdAverageHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdAverageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_ID_ASC',
  EmailMessagesByLawyerIdAverageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_ID_DESC',
  EmailMessagesByLawyerIdAverageIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_INTENT_ASC',
  EmailMessagesByLawyerIdAverageIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_INTENT_DESC',
  EmailMessagesByLawyerIdAverageIsLawyerAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_IS_LAWYER_ASC',
  EmailMessagesByLawyerIdAverageIsLawyerDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_IS_LAWYER_DESC',
  EmailMessagesByLawyerIdAverageIsReviewedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_IS_REVIEWED_ASC',
  EmailMessagesByLawyerIdAverageIsReviewedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_IS_REVIEWED_DESC',
  EmailMessagesByLawyerIdAverageLawyerIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  EmailMessagesByLawyerIdAverageLawyerIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  EmailMessagesByLawyerIdAverageLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByLawyerIdAverageLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByLawyerIdAverageNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByLawyerIdAverageNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByLawyerIdAverageOrderIndexAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_ORDER_INDEX_ASC',
  EmailMessagesByLawyerIdAverageOrderIndexDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_ORDER_INDEX_DESC',
  EmailMessagesByLawyerIdAveragePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByLawyerIdAveragePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByLawyerIdAverageRepliedIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_REPLIED_INTENT_ASC',
  EmailMessagesByLawyerIdAverageRepliedIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_REPLIED_INTENT_DESC',
  EmailMessagesByLawyerIdAverageRepliedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_REPLIED_TIME_ASC',
  EmailMessagesByLawyerIdAverageRepliedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_REPLIED_TIME_DESC',
  EmailMessagesByLawyerIdAverageRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdAverageRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdAverageReviewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_REVIEWED_TIME_ASC',
  EmailMessagesByLawyerIdAverageReviewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_REVIEWED_TIME_DESC',
  EmailMessagesByLawyerIdAverageSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByLawyerIdAverageSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByLawyerIdAverageSentTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_SENT_TIME_ASC',
  EmailMessagesByLawyerIdAverageSentTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_SENT_TIME_DESC',
  EmailMessagesByLawyerIdAverageSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdAverageSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdAverageSubjectAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_SUBJECT_ASC',
  EmailMessagesByLawyerIdAverageSubjectDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_SUBJECT_DESC',
  EmailMessagesByLawyerIdAverageTemplateIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_TEMPLATE_ID_ASC',
  EmailMessagesByLawyerIdAverageTemplateIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_TEMPLATE_ID_DESC',
  EmailMessagesByLawyerIdAverageUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailMessagesByLawyerIdAverageUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailMessagesByLawyerIdCountAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_COUNT_ASC',
  EmailMessagesByLawyerIdCountDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_COUNT_DESC',
  EmailMessagesByLawyerIdDistinctCountAutoRepliedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_AUTO_REPLIED_ASC',
  EmailMessagesByLawyerIdDistinctCountAutoRepliedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_AUTO_REPLIED_DESC',
  EmailMessagesByLawyerIdDistinctCountCampaignIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  EmailMessagesByLawyerIdDistinctCountCampaignIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  EmailMessagesByLawyerIdDistinctCountCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByLawyerIdDistinctCountCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByLawyerIdDistinctCountCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdDistinctCountCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdDistinctCountCompanyIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  EmailMessagesByLawyerIdDistinctCountCompanyIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  EmailMessagesByLawyerIdDistinctCountContactIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  EmailMessagesByLawyerIdDistinctCountContactIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  EmailMessagesByLawyerIdDistinctCountContentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CONTENT_ASC',
  EmailMessagesByLawyerIdDistinctCountContentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CONTENT_DESC',
  EmailMessagesByLawyerIdDistinctCountContentHtmlAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CONTENT_HTML_ASC',
  EmailMessagesByLawyerIdDistinctCountContentHtmlDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CONTENT_HTML_DESC',
  EmailMessagesByLawyerIdDistinctCountCreatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailMessagesByLawyerIdDistinctCountCreatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailMessagesByLawyerIdDistinctCountEmailIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailMessagesByLawyerIdDistinctCountEmailIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailMessagesByLawyerIdDistinctCountForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByLawyerIdDistinctCountForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByLawyerIdDistinctCountForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdDistinctCountForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdDistinctCountGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_GENERATED_REPLY_ASC',
  EmailMessagesByLawyerIdDistinctCountGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_GENERATED_REPLY_DESC',
  EmailMessagesByLawyerIdDistinctCountHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdDistinctCountHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdDistinctCountIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  EmailMessagesByLawyerIdDistinctCountIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  EmailMessagesByLawyerIdDistinctCountIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_INTENT_ASC',
  EmailMessagesByLawyerIdDistinctCountIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_INTENT_DESC',
  EmailMessagesByLawyerIdDistinctCountIsLawyerAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  EmailMessagesByLawyerIdDistinctCountIsLawyerDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  EmailMessagesByLawyerIdDistinctCountIsReviewedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_IS_REVIEWED_ASC',
  EmailMessagesByLawyerIdDistinctCountIsReviewedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_IS_REVIEWED_DESC',
  EmailMessagesByLawyerIdDistinctCountLawyerIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  EmailMessagesByLawyerIdDistinctCountLawyerIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  EmailMessagesByLawyerIdDistinctCountLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByLawyerIdDistinctCountLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByLawyerIdDistinctCountNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_ASC',
  EmailMessagesByLawyerIdDistinctCountNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_DESC',
  EmailMessagesByLawyerIdDistinctCountOrderIndexAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_ORDER_INDEX_ASC',
  EmailMessagesByLawyerIdDistinctCountOrderIndexDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_ORDER_INDEX_DESC',
  EmailMessagesByLawyerIdDistinctCountPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByLawyerIdDistinctCountPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByLawyerIdDistinctCountRepliedIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REPLIED_INTENT_ASC',
  EmailMessagesByLawyerIdDistinctCountRepliedIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REPLIED_INTENT_DESC',
  EmailMessagesByLawyerIdDistinctCountRepliedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REPLIED_TIME_ASC',
  EmailMessagesByLawyerIdDistinctCountRepliedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REPLIED_TIME_DESC',
  EmailMessagesByLawyerIdDistinctCountRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdDistinctCountRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdDistinctCountReviewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  EmailMessagesByLawyerIdDistinctCountReviewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  EmailMessagesByLawyerIdDistinctCountSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  EmailMessagesByLawyerIdDistinctCountSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  EmailMessagesByLawyerIdDistinctCountSentTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_SENT_TIME_ASC',
  EmailMessagesByLawyerIdDistinctCountSentTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_SENT_TIME_DESC',
  EmailMessagesByLawyerIdDistinctCountSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdDistinctCountSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdDistinctCountSubjectAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_SUBJECT_ASC',
  EmailMessagesByLawyerIdDistinctCountSubjectDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_SUBJECT_DESC',
  EmailMessagesByLawyerIdDistinctCountTemplateIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  EmailMessagesByLawyerIdDistinctCountTemplateIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  EmailMessagesByLawyerIdDistinctCountUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailMessagesByLawyerIdDistinctCountUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailMessagesByLawyerIdMaxAutoRepliedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_AUTO_REPLIED_ASC',
  EmailMessagesByLawyerIdMaxAutoRepliedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_AUTO_REPLIED_DESC',
  EmailMessagesByLawyerIdMaxCampaignIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CAMPAIGN_ID_ASC',
  EmailMessagesByLawyerIdMaxCampaignIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CAMPAIGN_ID_DESC',
  EmailMessagesByLawyerIdMaxCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByLawyerIdMaxCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByLawyerIdMaxCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdMaxCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdMaxCompanyIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_COMPANY_ID_ASC',
  EmailMessagesByLawyerIdMaxCompanyIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_COMPANY_ID_DESC',
  EmailMessagesByLawyerIdMaxContactIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CONTACT_ID_ASC',
  EmailMessagesByLawyerIdMaxContactIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CONTACT_ID_DESC',
  EmailMessagesByLawyerIdMaxContentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CONTENT_ASC',
  EmailMessagesByLawyerIdMaxContentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CONTENT_DESC',
  EmailMessagesByLawyerIdMaxContentHtmlAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CONTENT_HTML_ASC',
  EmailMessagesByLawyerIdMaxContentHtmlDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CONTENT_HTML_DESC',
  EmailMessagesByLawyerIdMaxCreatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  EmailMessagesByLawyerIdMaxCreatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  EmailMessagesByLawyerIdMaxEmailIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_EMAIL_ID_ASC',
  EmailMessagesByLawyerIdMaxEmailIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_EMAIL_ID_DESC',
  EmailMessagesByLawyerIdMaxForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByLawyerIdMaxForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByLawyerIdMaxForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdMaxForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdMaxGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_GENERATED_REPLY_ASC',
  EmailMessagesByLawyerIdMaxGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_GENERATED_REPLY_DESC',
  EmailMessagesByLawyerIdMaxHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdMaxHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdMaxIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_ID_ASC',
  EmailMessagesByLawyerIdMaxIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_ID_DESC',
  EmailMessagesByLawyerIdMaxIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_INTENT_ASC',
  EmailMessagesByLawyerIdMaxIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_INTENT_DESC',
  EmailMessagesByLawyerIdMaxIsLawyerAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_IS_LAWYER_ASC',
  EmailMessagesByLawyerIdMaxIsLawyerDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_IS_LAWYER_DESC',
  EmailMessagesByLawyerIdMaxIsReviewedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_IS_REVIEWED_ASC',
  EmailMessagesByLawyerIdMaxIsReviewedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_IS_REVIEWED_DESC',
  EmailMessagesByLawyerIdMaxLawyerIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  EmailMessagesByLawyerIdMaxLawyerIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  EmailMessagesByLawyerIdMaxLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByLawyerIdMaxLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByLawyerIdMaxNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_NYLAS_THREAD_ID_ASC',
  EmailMessagesByLawyerIdMaxNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_NYLAS_THREAD_ID_DESC',
  EmailMessagesByLawyerIdMaxOrderIndexAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_ORDER_INDEX_ASC',
  EmailMessagesByLawyerIdMaxOrderIndexDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_ORDER_INDEX_DESC',
  EmailMessagesByLawyerIdMaxPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByLawyerIdMaxPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByLawyerIdMaxRepliedIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_REPLIED_INTENT_ASC',
  EmailMessagesByLawyerIdMaxRepliedIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_REPLIED_INTENT_DESC',
  EmailMessagesByLawyerIdMaxRepliedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_REPLIED_TIME_ASC',
  EmailMessagesByLawyerIdMaxRepliedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_REPLIED_TIME_DESC',
  EmailMessagesByLawyerIdMaxRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdMaxRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdMaxReviewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_REVIEWED_TIME_ASC',
  EmailMessagesByLawyerIdMaxReviewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_REVIEWED_TIME_DESC',
  EmailMessagesByLawyerIdMaxSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_SENDER_PROFILE_ID_ASC',
  EmailMessagesByLawyerIdMaxSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_SENDER_PROFILE_ID_DESC',
  EmailMessagesByLawyerIdMaxSentTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_SENT_TIME_ASC',
  EmailMessagesByLawyerIdMaxSentTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_SENT_TIME_DESC',
  EmailMessagesByLawyerIdMaxSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdMaxSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdMaxSubjectAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_SUBJECT_ASC',
  EmailMessagesByLawyerIdMaxSubjectDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_SUBJECT_DESC',
  EmailMessagesByLawyerIdMaxTemplateIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_TEMPLATE_ID_ASC',
  EmailMessagesByLawyerIdMaxTemplateIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_TEMPLATE_ID_DESC',
  EmailMessagesByLawyerIdMaxUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  EmailMessagesByLawyerIdMaxUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  EmailMessagesByLawyerIdMinAutoRepliedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_AUTO_REPLIED_ASC',
  EmailMessagesByLawyerIdMinAutoRepliedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_AUTO_REPLIED_DESC',
  EmailMessagesByLawyerIdMinCampaignIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CAMPAIGN_ID_ASC',
  EmailMessagesByLawyerIdMinCampaignIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CAMPAIGN_ID_DESC',
  EmailMessagesByLawyerIdMinCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByLawyerIdMinCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByLawyerIdMinCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdMinCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdMinCompanyIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_COMPANY_ID_ASC',
  EmailMessagesByLawyerIdMinCompanyIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_COMPANY_ID_DESC',
  EmailMessagesByLawyerIdMinContactIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CONTACT_ID_ASC',
  EmailMessagesByLawyerIdMinContactIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CONTACT_ID_DESC',
  EmailMessagesByLawyerIdMinContentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CONTENT_ASC',
  EmailMessagesByLawyerIdMinContentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CONTENT_DESC',
  EmailMessagesByLawyerIdMinContentHtmlAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CONTENT_HTML_ASC',
  EmailMessagesByLawyerIdMinContentHtmlDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CONTENT_HTML_DESC',
  EmailMessagesByLawyerIdMinCreatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  EmailMessagesByLawyerIdMinCreatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  EmailMessagesByLawyerIdMinEmailIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_EMAIL_ID_ASC',
  EmailMessagesByLawyerIdMinEmailIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_EMAIL_ID_DESC',
  EmailMessagesByLawyerIdMinForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByLawyerIdMinForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByLawyerIdMinForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdMinForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdMinGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_GENERATED_REPLY_ASC',
  EmailMessagesByLawyerIdMinGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_GENERATED_REPLY_DESC',
  EmailMessagesByLawyerIdMinHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdMinHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdMinIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_ID_ASC',
  EmailMessagesByLawyerIdMinIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_ID_DESC',
  EmailMessagesByLawyerIdMinIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_INTENT_ASC',
  EmailMessagesByLawyerIdMinIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_INTENT_DESC',
  EmailMessagesByLawyerIdMinIsLawyerAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_IS_LAWYER_ASC',
  EmailMessagesByLawyerIdMinIsLawyerDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_IS_LAWYER_DESC',
  EmailMessagesByLawyerIdMinIsReviewedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_IS_REVIEWED_ASC',
  EmailMessagesByLawyerIdMinIsReviewedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_IS_REVIEWED_DESC',
  EmailMessagesByLawyerIdMinLawyerIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  EmailMessagesByLawyerIdMinLawyerIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  EmailMessagesByLawyerIdMinLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByLawyerIdMinLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByLawyerIdMinNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_NYLAS_THREAD_ID_ASC',
  EmailMessagesByLawyerIdMinNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_NYLAS_THREAD_ID_DESC',
  EmailMessagesByLawyerIdMinOrderIndexAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_ORDER_INDEX_ASC',
  EmailMessagesByLawyerIdMinOrderIndexDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_ORDER_INDEX_DESC',
  EmailMessagesByLawyerIdMinPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByLawyerIdMinPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByLawyerIdMinRepliedIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_REPLIED_INTENT_ASC',
  EmailMessagesByLawyerIdMinRepliedIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_REPLIED_INTENT_DESC',
  EmailMessagesByLawyerIdMinRepliedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_REPLIED_TIME_ASC',
  EmailMessagesByLawyerIdMinRepliedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_REPLIED_TIME_DESC',
  EmailMessagesByLawyerIdMinRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdMinRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdMinReviewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_REVIEWED_TIME_ASC',
  EmailMessagesByLawyerIdMinReviewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_REVIEWED_TIME_DESC',
  EmailMessagesByLawyerIdMinSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_SENDER_PROFILE_ID_ASC',
  EmailMessagesByLawyerIdMinSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_SENDER_PROFILE_ID_DESC',
  EmailMessagesByLawyerIdMinSentTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_SENT_TIME_ASC',
  EmailMessagesByLawyerIdMinSentTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_SENT_TIME_DESC',
  EmailMessagesByLawyerIdMinSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdMinSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdMinSubjectAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_SUBJECT_ASC',
  EmailMessagesByLawyerIdMinSubjectDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_SUBJECT_DESC',
  EmailMessagesByLawyerIdMinTemplateIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_TEMPLATE_ID_ASC',
  EmailMessagesByLawyerIdMinTemplateIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_TEMPLATE_ID_DESC',
  EmailMessagesByLawyerIdMinUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  EmailMessagesByLawyerIdMinUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  EmailMessagesByLawyerIdStddevPopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByLawyerIdStddevPopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByLawyerIdStddevPopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByLawyerIdStddevPopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByLawyerIdStddevPopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByLawyerIdStddevPopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByLawyerIdStddevPopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdStddevPopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdStddevPopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByLawyerIdStddevPopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByLawyerIdStddevPopulationContactIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByLawyerIdStddevPopulationContactIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByLawyerIdStddevPopulationContentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CONTENT_ASC',
  EmailMessagesByLawyerIdStddevPopulationContentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CONTENT_DESC',
  EmailMessagesByLawyerIdStddevPopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByLawyerIdStddevPopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByLawyerIdStddevPopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByLawyerIdStddevPopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByLawyerIdStddevPopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByLawyerIdStddevPopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByLawyerIdStddevPopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByLawyerIdStddevPopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByLawyerIdStddevPopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdStddevPopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdStddevPopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByLawyerIdStddevPopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByLawyerIdStddevPopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdStddevPopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdStddevPopulationIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  EmailMessagesByLawyerIdStddevPopulationIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  EmailMessagesByLawyerIdStddevPopulationIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_INTENT_ASC',
  EmailMessagesByLawyerIdStddevPopulationIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_INTENT_DESC',
  EmailMessagesByLawyerIdStddevPopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByLawyerIdStddevPopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByLawyerIdStddevPopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByLawyerIdStddevPopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByLawyerIdStddevPopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByLawyerIdStddevPopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByLawyerIdStddevPopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByLawyerIdStddevPopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByLawyerIdStddevPopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByLawyerIdStddevPopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByLawyerIdStddevPopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByLawyerIdStddevPopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByLawyerIdStddevPopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByLawyerIdStddevPopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByLawyerIdStddevPopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByLawyerIdStddevPopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByLawyerIdStddevPopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByLawyerIdStddevPopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByLawyerIdStddevPopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdStddevPopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdStddevPopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByLawyerIdStddevPopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByLawyerIdStddevPopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByLawyerIdStddevPopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByLawyerIdStddevPopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_SENT_TIME_ASC',
  EmailMessagesByLawyerIdStddevPopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_SENT_TIME_DESC',
  EmailMessagesByLawyerIdStddevPopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdStddevPopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdStddevPopulationSubjectAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_SUBJECT_ASC',
  EmailMessagesByLawyerIdStddevPopulationSubjectDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_SUBJECT_DESC',
  EmailMessagesByLawyerIdStddevPopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByLawyerIdStddevPopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByLawyerIdStddevPopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByLawyerIdStddevPopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByLawyerIdStddevSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByLawyerIdStddevSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByLawyerIdStddevSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByLawyerIdStddevSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByLawyerIdStddevSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByLawyerIdStddevSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByLawyerIdStddevSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdStddevSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdStddevSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByLawyerIdStddevSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByLawyerIdStddevSampleContactIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByLawyerIdStddevSampleContactIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByLawyerIdStddevSampleContentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CONTENT_ASC',
  EmailMessagesByLawyerIdStddevSampleContentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CONTENT_DESC',
  EmailMessagesByLawyerIdStddevSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByLawyerIdStddevSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByLawyerIdStddevSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByLawyerIdStddevSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByLawyerIdStddevSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByLawyerIdStddevSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByLawyerIdStddevSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByLawyerIdStddevSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByLawyerIdStddevSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdStddevSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdStddevSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByLawyerIdStddevSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByLawyerIdStddevSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdStddevSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdStddevSampleIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  EmailMessagesByLawyerIdStddevSampleIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  EmailMessagesByLawyerIdStddevSampleIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_INTENT_ASC',
  EmailMessagesByLawyerIdStddevSampleIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_INTENT_DESC',
  EmailMessagesByLawyerIdStddevSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByLawyerIdStddevSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByLawyerIdStddevSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByLawyerIdStddevSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByLawyerIdStddevSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByLawyerIdStddevSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByLawyerIdStddevSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByLawyerIdStddevSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByLawyerIdStddevSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByLawyerIdStddevSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByLawyerIdStddevSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByLawyerIdStddevSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByLawyerIdStddevSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByLawyerIdStddevSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByLawyerIdStddevSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByLawyerIdStddevSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByLawyerIdStddevSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByLawyerIdStddevSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByLawyerIdStddevSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdStddevSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdStddevSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByLawyerIdStddevSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByLawyerIdStddevSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByLawyerIdStddevSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByLawyerIdStddevSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByLawyerIdStddevSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByLawyerIdStddevSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdStddevSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdStddevSampleSubjectAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_SUBJECT_ASC',
  EmailMessagesByLawyerIdStddevSampleSubjectDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_SUBJECT_DESC',
  EmailMessagesByLawyerIdStddevSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByLawyerIdStddevSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByLawyerIdStddevSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByLawyerIdStddevSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailMessagesByLawyerIdSumAutoRepliedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_AUTO_REPLIED_ASC',
  EmailMessagesByLawyerIdSumAutoRepliedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_AUTO_REPLIED_DESC',
  EmailMessagesByLawyerIdSumCampaignIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CAMPAIGN_ID_ASC',
  EmailMessagesByLawyerIdSumCampaignIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CAMPAIGN_ID_DESC',
  EmailMessagesByLawyerIdSumCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByLawyerIdSumCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByLawyerIdSumCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdSumCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdSumCompanyIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_COMPANY_ID_ASC',
  EmailMessagesByLawyerIdSumCompanyIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_COMPANY_ID_DESC',
  EmailMessagesByLawyerIdSumContactIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CONTACT_ID_ASC',
  EmailMessagesByLawyerIdSumContactIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CONTACT_ID_DESC',
  EmailMessagesByLawyerIdSumContentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CONTENT_ASC',
  EmailMessagesByLawyerIdSumContentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CONTENT_DESC',
  EmailMessagesByLawyerIdSumContentHtmlAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CONTENT_HTML_ASC',
  EmailMessagesByLawyerIdSumContentHtmlDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CONTENT_HTML_DESC',
  EmailMessagesByLawyerIdSumCreatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  EmailMessagesByLawyerIdSumCreatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  EmailMessagesByLawyerIdSumEmailIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_EMAIL_ID_ASC',
  EmailMessagesByLawyerIdSumEmailIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_EMAIL_ID_DESC',
  EmailMessagesByLawyerIdSumForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByLawyerIdSumForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByLawyerIdSumForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdSumForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdSumGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_GENERATED_REPLY_ASC',
  EmailMessagesByLawyerIdSumGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_GENERATED_REPLY_DESC',
  EmailMessagesByLawyerIdSumHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdSumHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdSumIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_ID_ASC',
  EmailMessagesByLawyerIdSumIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_ID_DESC',
  EmailMessagesByLawyerIdSumIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_INTENT_ASC',
  EmailMessagesByLawyerIdSumIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_INTENT_DESC',
  EmailMessagesByLawyerIdSumIsLawyerAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_IS_LAWYER_ASC',
  EmailMessagesByLawyerIdSumIsLawyerDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_IS_LAWYER_DESC',
  EmailMessagesByLawyerIdSumIsReviewedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_IS_REVIEWED_ASC',
  EmailMessagesByLawyerIdSumIsReviewedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_IS_REVIEWED_DESC',
  EmailMessagesByLawyerIdSumLawyerIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  EmailMessagesByLawyerIdSumLawyerIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  EmailMessagesByLawyerIdSumLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByLawyerIdSumLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByLawyerIdSumNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_NYLAS_THREAD_ID_ASC',
  EmailMessagesByLawyerIdSumNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_NYLAS_THREAD_ID_DESC',
  EmailMessagesByLawyerIdSumOrderIndexAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_ORDER_INDEX_ASC',
  EmailMessagesByLawyerIdSumOrderIndexDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_ORDER_INDEX_DESC',
  EmailMessagesByLawyerIdSumPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByLawyerIdSumPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByLawyerIdSumRepliedIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_REPLIED_INTENT_ASC',
  EmailMessagesByLawyerIdSumRepliedIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_REPLIED_INTENT_DESC',
  EmailMessagesByLawyerIdSumRepliedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_REPLIED_TIME_ASC',
  EmailMessagesByLawyerIdSumRepliedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_REPLIED_TIME_DESC',
  EmailMessagesByLawyerIdSumRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdSumRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdSumReviewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_REVIEWED_TIME_ASC',
  EmailMessagesByLawyerIdSumReviewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_REVIEWED_TIME_DESC',
  EmailMessagesByLawyerIdSumSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_SENDER_PROFILE_ID_ASC',
  EmailMessagesByLawyerIdSumSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_SENDER_PROFILE_ID_DESC',
  EmailMessagesByLawyerIdSumSentTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_SENT_TIME_ASC',
  EmailMessagesByLawyerIdSumSentTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_SENT_TIME_DESC',
  EmailMessagesByLawyerIdSumSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdSumSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdSumSubjectAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_SUBJECT_ASC',
  EmailMessagesByLawyerIdSumSubjectDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_SUBJECT_DESC',
  EmailMessagesByLawyerIdSumTemplateIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_TEMPLATE_ID_ASC',
  EmailMessagesByLawyerIdSumTemplateIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_TEMPLATE_ID_DESC',
  EmailMessagesByLawyerIdSumUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  EmailMessagesByLawyerIdSumUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  EmailMessagesByLawyerIdVariancePopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByLawyerIdVariancePopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByLawyerIdVariancePopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByLawyerIdVariancePopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByLawyerIdVariancePopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByLawyerIdVariancePopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByLawyerIdVariancePopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdVariancePopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdVariancePopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByLawyerIdVariancePopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByLawyerIdVariancePopulationContactIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByLawyerIdVariancePopulationContactIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByLawyerIdVariancePopulationContentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CONTENT_ASC',
  EmailMessagesByLawyerIdVariancePopulationContentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CONTENT_DESC',
  EmailMessagesByLawyerIdVariancePopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByLawyerIdVariancePopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByLawyerIdVariancePopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByLawyerIdVariancePopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByLawyerIdVariancePopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByLawyerIdVariancePopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByLawyerIdVariancePopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByLawyerIdVariancePopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByLawyerIdVariancePopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdVariancePopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdVariancePopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByLawyerIdVariancePopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByLawyerIdVariancePopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdVariancePopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdVariancePopulationIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  EmailMessagesByLawyerIdVariancePopulationIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  EmailMessagesByLawyerIdVariancePopulationIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_INTENT_ASC',
  EmailMessagesByLawyerIdVariancePopulationIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_INTENT_DESC',
  EmailMessagesByLawyerIdVariancePopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByLawyerIdVariancePopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByLawyerIdVariancePopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByLawyerIdVariancePopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByLawyerIdVariancePopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByLawyerIdVariancePopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByLawyerIdVariancePopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByLawyerIdVariancePopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByLawyerIdVariancePopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByLawyerIdVariancePopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByLawyerIdVariancePopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByLawyerIdVariancePopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByLawyerIdVariancePopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByLawyerIdVariancePopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByLawyerIdVariancePopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByLawyerIdVariancePopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByLawyerIdVariancePopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByLawyerIdVariancePopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByLawyerIdVariancePopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdVariancePopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdVariancePopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByLawyerIdVariancePopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByLawyerIdVariancePopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByLawyerIdVariancePopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByLawyerIdVariancePopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_SENT_TIME_ASC',
  EmailMessagesByLawyerIdVariancePopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_SENT_TIME_DESC',
  EmailMessagesByLawyerIdVariancePopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdVariancePopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdVariancePopulationSubjectAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_SUBJECT_ASC',
  EmailMessagesByLawyerIdVariancePopulationSubjectDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_SUBJECT_DESC',
  EmailMessagesByLawyerIdVariancePopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByLawyerIdVariancePopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByLawyerIdVariancePopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByLawyerIdVariancePopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByLawyerIdVarianceSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByLawyerIdVarianceSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByLawyerIdVarianceSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByLawyerIdVarianceSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByLawyerIdVarianceSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByLawyerIdVarianceSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByLawyerIdVarianceSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdVarianceSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdVarianceSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByLawyerIdVarianceSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByLawyerIdVarianceSampleContactIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByLawyerIdVarianceSampleContactIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByLawyerIdVarianceSampleContentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  EmailMessagesByLawyerIdVarianceSampleContentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  EmailMessagesByLawyerIdVarianceSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByLawyerIdVarianceSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByLawyerIdVarianceSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByLawyerIdVarianceSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByLawyerIdVarianceSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByLawyerIdVarianceSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByLawyerIdVarianceSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByLawyerIdVarianceSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByLawyerIdVarianceSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdVarianceSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdVarianceSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByLawyerIdVarianceSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByLawyerIdVarianceSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdVarianceSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdVarianceSampleIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailMessagesByLawyerIdVarianceSampleIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailMessagesByLawyerIdVarianceSampleIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_INTENT_ASC',
  EmailMessagesByLawyerIdVarianceSampleIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_INTENT_DESC',
  EmailMessagesByLawyerIdVarianceSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByLawyerIdVarianceSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByLawyerIdVarianceSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByLawyerIdVarianceSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByLawyerIdVarianceSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByLawyerIdVarianceSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByLawyerIdVarianceSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByLawyerIdVarianceSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByLawyerIdVarianceSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByLawyerIdVarianceSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByLawyerIdVarianceSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByLawyerIdVarianceSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByLawyerIdVarianceSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByLawyerIdVarianceSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByLawyerIdVarianceSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByLawyerIdVarianceSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByLawyerIdVarianceSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByLawyerIdVarianceSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByLawyerIdVarianceSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByLawyerIdVarianceSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByLawyerIdVarianceSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByLawyerIdVarianceSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByLawyerIdVarianceSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByLawyerIdVarianceSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByLawyerIdVarianceSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByLawyerIdVarianceSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByLawyerIdVarianceSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByLawyerIdVarianceSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByLawyerIdVarianceSampleSubjectAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_SUBJECT_ASC',
  EmailMessagesByLawyerIdVarianceSampleSubjectDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_SUBJECT_DESC',
  EmailMessagesByLawyerIdVarianceSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByLawyerIdVarianceSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByLawyerIdVarianceSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByLawyerIdVarianceSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EnableAutoTestimonialAsc = 'ENABLE_AUTO_TESTIMONIAL_ASC',
  EnableAutoTestimonialDesc = 'ENABLE_AUTO_TESTIMONIAL_DESC',
  ExternalContactsLastUpdatedAsc = 'EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  ExternalContactsLastUpdatedDesc = 'EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  FirmDescriptionAsc = 'FIRM_DESCRIPTION_ASC',
  FirmDescriptionDesc = 'FIRM_DESCRIPTION_DESC',
  FirmNameAsc = 'FIRM_NAME_ASC',
  FirmNameDesc = 'FIRM_NAME_DESC',
  FreeLeadsAsc = 'FREE_LEADS_ASC',
  FreeLeadsDesc = 'FREE_LEADS_DESC',
  GooglePlaceIdAsc = 'GOOGLE_PLACE_ID_ASC',
  GooglePlaceIdDesc = 'GOOGLE_PLACE_ID_DESC',
  HandoverMethodAsc = 'HANDOVER_METHOD_ASC',
  HandoverMethodDesc = 'HANDOVER_METHOD_DESC',
  HandoverStateAsc = 'HANDOVER_STATE_ASC',
  HandoverStateDesc = 'HANDOVER_STATE_DESC',
  HubspotDealIdAsc = 'HUBSPOT_DEAL_ID_ASC',
  HubspotDealIdDesc = 'HUBSPOT_DEAL_ID_DESC',
  IcpAsc = 'ICP_ASC',
  IcpDesc = 'ICP_DESC',
  IcpGuessedTimeAsc = 'ICP_GUESSED_TIME_ASC',
  IcpGuessedTimeDesc = 'ICP_GUESSED_TIME_DESC',
  IdealNumWeeklyMeetingsAsc = 'IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  IdealNumWeeklyMeetingsDesc = 'IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsManagedAsc = 'IS_MANAGED_ASC',
  IsManagedDesc = 'IS_MANAGED_DESC',
  IsPausedAsc = 'IS_PAUSED_ASC',
  IsPausedDesc = 'IS_PAUSED_DESC',
  LastAccountReviewAsc = 'LAST_ACCOUNT_REVIEW_ASC',
  LastAccountReviewDesc = 'LAST_ACCOUNT_REVIEW_DESC',
  LastReportTimeAsc = 'LAST_REPORT_TIME_ASC',
  LastReportTimeDesc = 'LAST_REPORT_TIME_DESC',
  LastStatusEmailTimeAsc = 'LAST_STATUS_EMAIL_TIME_ASC',
  LastStatusEmailTimeDesc = 'LAST_STATUS_EMAIL_TIME_DESC',
  LawyerDescriptionAsc = 'LAWYER_DESCRIPTION_ASC',
  LawyerDescriptionDesc = 'LAWYER_DESCRIPTION_DESC',
  LawyerEmailAddressesByLawyerIdAverageEmailAddressIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_AVERAGE_EMAIL_ADDRESS_ID_ASC',
  LawyerEmailAddressesByLawyerIdAverageEmailAddressIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_AVERAGE_EMAIL_ADDRESS_ID_DESC',
  LawyerEmailAddressesByLawyerIdAverageLawyerIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  LawyerEmailAddressesByLawyerIdAverageLawyerIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  LawyerEmailAddressesByLawyerIdCountAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_COUNT_ASC',
  LawyerEmailAddressesByLawyerIdCountDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_COUNT_DESC',
  LawyerEmailAddressesByLawyerIdDistinctCountEmailAddressIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_ADDRESS_ID_ASC',
  LawyerEmailAddressesByLawyerIdDistinctCountEmailAddressIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_ADDRESS_ID_DESC',
  LawyerEmailAddressesByLawyerIdDistinctCountLawyerIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  LawyerEmailAddressesByLawyerIdDistinctCountLawyerIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  LawyerEmailAddressesByLawyerIdMaxEmailAddressIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_MAX_EMAIL_ADDRESS_ID_ASC',
  LawyerEmailAddressesByLawyerIdMaxEmailAddressIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_MAX_EMAIL_ADDRESS_ID_DESC',
  LawyerEmailAddressesByLawyerIdMaxLawyerIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  LawyerEmailAddressesByLawyerIdMaxLawyerIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  LawyerEmailAddressesByLawyerIdMinEmailAddressIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_MIN_EMAIL_ADDRESS_ID_ASC',
  LawyerEmailAddressesByLawyerIdMinEmailAddressIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_MIN_EMAIL_ADDRESS_ID_DESC',
  LawyerEmailAddressesByLawyerIdMinLawyerIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  LawyerEmailAddressesByLawyerIdMinLawyerIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  LawyerEmailAddressesByLawyerIdStddevPopulationEmailAddressIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_ADDRESS_ID_ASC',
  LawyerEmailAddressesByLawyerIdStddevPopulationEmailAddressIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_ADDRESS_ID_DESC',
  LawyerEmailAddressesByLawyerIdStddevPopulationLawyerIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  LawyerEmailAddressesByLawyerIdStddevPopulationLawyerIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  LawyerEmailAddressesByLawyerIdStddevSampleEmailAddressIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_ADDRESS_ID_ASC',
  LawyerEmailAddressesByLawyerIdStddevSampleEmailAddressIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_ADDRESS_ID_DESC',
  LawyerEmailAddressesByLawyerIdStddevSampleLawyerIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  LawyerEmailAddressesByLawyerIdStddevSampleLawyerIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  LawyerEmailAddressesByLawyerIdSumEmailAddressIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_SUM_EMAIL_ADDRESS_ID_ASC',
  LawyerEmailAddressesByLawyerIdSumEmailAddressIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_SUM_EMAIL_ADDRESS_ID_DESC',
  LawyerEmailAddressesByLawyerIdSumLawyerIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  LawyerEmailAddressesByLawyerIdSumLawyerIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  LawyerEmailAddressesByLawyerIdVariancePopulationEmailAddressIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_ADDRESS_ID_ASC',
  LawyerEmailAddressesByLawyerIdVariancePopulationEmailAddressIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_ADDRESS_ID_DESC',
  LawyerEmailAddressesByLawyerIdVariancePopulationLawyerIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  LawyerEmailAddressesByLawyerIdVariancePopulationLawyerIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  LawyerEmailAddressesByLawyerIdVarianceSampleEmailAddressIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_ADDRESS_ID_ASC',
  LawyerEmailAddressesByLawyerIdVarianceSampleEmailAddressIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_ADDRESS_ID_DESC',
  LawyerEmailAddressesByLawyerIdVarianceSampleLawyerIdAsc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  LawyerEmailAddressesByLawyerIdVarianceSampleLawyerIdDesc = 'LAWYER_EMAIL_ADDRESSES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  LawyerExternalContactsByLawyerIdAverageContactEmailAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_AVERAGE_CONTACT_EMAIL_ASC',
  LawyerExternalContactsByLawyerIdAverageContactEmailDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_AVERAGE_CONTACT_EMAIL_DESC',
  LawyerExternalContactsByLawyerIdAverageIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  LawyerExternalContactsByLawyerIdAverageIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  LawyerExternalContactsByLawyerIdAverageLawyerIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  LawyerExternalContactsByLawyerIdAverageLawyerIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  LawyerExternalContactsByLawyerIdCountAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_COUNT_ASC',
  LawyerExternalContactsByLawyerIdCountDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_COUNT_DESC',
  LawyerExternalContactsByLawyerIdDistinctCountContactEmailAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_EMAIL_ASC',
  LawyerExternalContactsByLawyerIdDistinctCountContactEmailDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_EMAIL_DESC',
  LawyerExternalContactsByLawyerIdDistinctCountIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  LawyerExternalContactsByLawyerIdDistinctCountIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  LawyerExternalContactsByLawyerIdDistinctCountLawyerIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  LawyerExternalContactsByLawyerIdDistinctCountLawyerIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  LawyerExternalContactsByLawyerIdMaxContactEmailAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_MAX_CONTACT_EMAIL_ASC',
  LawyerExternalContactsByLawyerIdMaxContactEmailDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_MAX_CONTACT_EMAIL_DESC',
  LawyerExternalContactsByLawyerIdMaxIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_MAX_ID_ASC',
  LawyerExternalContactsByLawyerIdMaxIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_MAX_ID_DESC',
  LawyerExternalContactsByLawyerIdMaxLawyerIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  LawyerExternalContactsByLawyerIdMaxLawyerIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  LawyerExternalContactsByLawyerIdMinContactEmailAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_MIN_CONTACT_EMAIL_ASC',
  LawyerExternalContactsByLawyerIdMinContactEmailDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_MIN_CONTACT_EMAIL_DESC',
  LawyerExternalContactsByLawyerIdMinIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_MIN_ID_ASC',
  LawyerExternalContactsByLawyerIdMinIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_MIN_ID_DESC',
  LawyerExternalContactsByLawyerIdMinLawyerIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  LawyerExternalContactsByLawyerIdMinLawyerIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  LawyerExternalContactsByLawyerIdStddevPopulationContactEmailAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_EMAIL_ASC',
  LawyerExternalContactsByLawyerIdStddevPopulationContactEmailDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_EMAIL_DESC',
  LawyerExternalContactsByLawyerIdStddevPopulationIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  LawyerExternalContactsByLawyerIdStddevPopulationIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  LawyerExternalContactsByLawyerIdStddevPopulationLawyerIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  LawyerExternalContactsByLawyerIdStddevPopulationLawyerIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  LawyerExternalContactsByLawyerIdStddevSampleContactEmailAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_EMAIL_ASC',
  LawyerExternalContactsByLawyerIdStddevSampleContactEmailDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_EMAIL_DESC',
  LawyerExternalContactsByLawyerIdStddevSampleIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  LawyerExternalContactsByLawyerIdStddevSampleIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  LawyerExternalContactsByLawyerIdStddevSampleLawyerIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  LawyerExternalContactsByLawyerIdStddevSampleLawyerIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  LawyerExternalContactsByLawyerIdSumContactEmailAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_SUM_CONTACT_EMAIL_ASC',
  LawyerExternalContactsByLawyerIdSumContactEmailDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_SUM_CONTACT_EMAIL_DESC',
  LawyerExternalContactsByLawyerIdSumIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_SUM_ID_ASC',
  LawyerExternalContactsByLawyerIdSumIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_SUM_ID_DESC',
  LawyerExternalContactsByLawyerIdSumLawyerIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  LawyerExternalContactsByLawyerIdSumLawyerIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  LawyerExternalContactsByLawyerIdVariancePopulationContactEmailAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_EMAIL_ASC',
  LawyerExternalContactsByLawyerIdVariancePopulationContactEmailDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_EMAIL_DESC',
  LawyerExternalContactsByLawyerIdVariancePopulationIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  LawyerExternalContactsByLawyerIdVariancePopulationIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  LawyerExternalContactsByLawyerIdVariancePopulationLawyerIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  LawyerExternalContactsByLawyerIdVariancePopulationLawyerIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  LawyerExternalContactsByLawyerIdVarianceSampleContactEmailAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_EMAIL_ASC',
  LawyerExternalContactsByLawyerIdVarianceSampleContactEmailDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_EMAIL_DESC',
  LawyerExternalContactsByLawyerIdVarianceSampleIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  LawyerExternalContactsByLawyerIdVarianceSampleIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  LawyerExternalContactsByLawyerIdVarianceSampleLawyerIdAsc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  LawyerExternalContactsByLawyerIdVarianceSampleLawyerIdDesc = 'LAWYER_EXTERNAL_CONTACTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdAverageCreatedTimeAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  LawyerLinkedinCookiesByLawyerIdAverageCreatedTimeDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  LawyerLinkedinCookiesByLawyerIdAverageIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_AVERAGE_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdAverageIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_AVERAGE_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdAverageLawyerIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdAverageLawyerIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdAverageValueAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_AVERAGE_VALUE_ASC',
  LawyerLinkedinCookiesByLawyerIdAverageValueDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_AVERAGE_VALUE_DESC',
  LawyerLinkedinCookiesByLawyerIdCountAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_COUNT_ASC',
  LawyerLinkedinCookiesByLawyerIdCountDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_COUNT_DESC',
  LawyerLinkedinCookiesByLawyerIdDistinctCountCreatedTimeAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LawyerLinkedinCookiesByLawyerIdDistinctCountCreatedTimeDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LawyerLinkedinCookiesByLawyerIdDistinctCountIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdDistinctCountIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdDistinctCountLawyerIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdDistinctCountLawyerIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdDistinctCountValueAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_DISTINCT_COUNT_VALUE_ASC',
  LawyerLinkedinCookiesByLawyerIdDistinctCountValueDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_DISTINCT_COUNT_VALUE_DESC',
  LawyerLinkedinCookiesByLawyerIdMaxCreatedTimeAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  LawyerLinkedinCookiesByLawyerIdMaxCreatedTimeDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  LawyerLinkedinCookiesByLawyerIdMaxIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MAX_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdMaxIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MAX_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdMaxLawyerIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdMaxLawyerIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdMaxValueAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MAX_VALUE_ASC',
  LawyerLinkedinCookiesByLawyerIdMaxValueDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MAX_VALUE_DESC',
  LawyerLinkedinCookiesByLawyerIdMinCreatedTimeAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  LawyerLinkedinCookiesByLawyerIdMinCreatedTimeDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  LawyerLinkedinCookiesByLawyerIdMinIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MIN_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdMinIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MIN_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdMinLawyerIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdMinLawyerIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdMinValueAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MIN_VALUE_ASC',
  LawyerLinkedinCookiesByLawyerIdMinValueDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_MIN_VALUE_DESC',
  LawyerLinkedinCookiesByLawyerIdStddevPopulationCreatedTimeAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LawyerLinkedinCookiesByLawyerIdStddevPopulationCreatedTimeDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LawyerLinkedinCookiesByLawyerIdStddevPopulationIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdStddevPopulationIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdStddevPopulationLawyerIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdStddevPopulationLawyerIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdStddevPopulationValueAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_POPULATION_VALUE_ASC',
  LawyerLinkedinCookiesByLawyerIdStddevPopulationValueDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_POPULATION_VALUE_DESC',
  LawyerLinkedinCookiesByLawyerIdStddevSampleCreatedTimeAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LawyerLinkedinCookiesByLawyerIdStddevSampleCreatedTimeDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LawyerLinkedinCookiesByLawyerIdStddevSampleIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdStddevSampleIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdStddevSampleLawyerIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdStddevSampleLawyerIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdStddevSampleValueAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_SAMPLE_VALUE_ASC',
  LawyerLinkedinCookiesByLawyerIdStddevSampleValueDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_STDDEV_SAMPLE_VALUE_DESC',
  LawyerLinkedinCookiesByLawyerIdSumCreatedTimeAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  LawyerLinkedinCookiesByLawyerIdSumCreatedTimeDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  LawyerLinkedinCookiesByLawyerIdSumIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_SUM_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdSumIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_SUM_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdSumLawyerIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdSumLawyerIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdSumValueAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_SUM_VALUE_ASC',
  LawyerLinkedinCookiesByLawyerIdSumValueDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_SUM_VALUE_DESC',
  LawyerLinkedinCookiesByLawyerIdVariancePopulationCreatedTimeAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LawyerLinkedinCookiesByLawyerIdVariancePopulationCreatedTimeDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LawyerLinkedinCookiesByLawyerIdVariancePopulationIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdVariancePopulationIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdVariancePopulationLawyerIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdVariancePopulationLawyerIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdVariancePopulationValueAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_POPULATION_VALUE_ASC',
  LawyerLinkedinCookiesByLawyerIdVariancePopulationValueDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_POPULATION_VALUE_DESC',
  LawyerLinkedinCookiesByLawyerIdVarianceSampleCreatedTimeAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LawyerLinkedinCookiesByLawyerIdVarianceSampleCreatedTimeDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LawyerLinkedinCookiesByLawyerIdVarianceSampleIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdVarianceSampleIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdVarianceSampleLawyerIdAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  LawyerLinkedinCookiesByLawyerIdVarianceSampleLawyerIdDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  LawyerLinkedinCookiesByLawyerIdVarianceSampleValueAsc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_SAMPLE_VALUE_ASC',
  LawyerLinkedinCookiesByLawyerIdVarianceSampleValueDesc = 'LAWYER_LINKEDIN_COOKIES_BY_LAWYER_ID_VARIANCE_SAMPLE_VALUE_DESC',
  LawyerSettingsByLawyerIdAverageCreatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  LawyerSettingsByLawyerIdAverageCreatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  LawyerSettingsByLawyerIdAverageIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  LawyerSettingsByLawyerIdAverageIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  LawyerSettingsByLawyerIdAverageLawyerIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  LawyerSettingsByLawyerIdAverageLawyerIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  LawyerSettingsByLawyerIdAverageTypeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_AVERAGE_TYPE_ASC',
  LawyerSettingsByLawyerIdAverageTypeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_AVERAGE_TYPE_DESC',
  LawyerSettingsByLawyerIdAverageUpdatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  LawyerSettingsByLawyerIdAverageUpdatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  LawyerSettingsByLawyerIdAverageValueAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_AVERAGE_VALUE_ASC',
  LawyerSettingsByLawyerIdAverageValueDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_AVERAGE_VALUE_DESC',
  LawyerSettingsByLawyerIdCountAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_COUNT_ASC',
  LawyerSettingsByLawyerIdCountDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_COUNT_DESC',
  LawyerSettingsByLawyerIdDistinctCountCreatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LawyerSettingsByLawyerIdDistinctCountCreatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LawyerSettingsByLawyerIdDistinctCountIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  LawyerSettingsByLawyerIdDistinctCountIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  LawyerSettingsByLawyerIdDistinctCountLawyerIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  LawyerSettingsByLawyerIdDistinctCountLawyerIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  LawyerSettingsByLawyerIdDistinctCountTypeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_DISTINCT_COUNT_TYPE_ASC',
  LawyerSettingsByLawyerIdDistinctCountTypeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_DISTINCT_COUNT_TYPE_DESC',
  LawyerSettingsByLawyerIdDistinctCountUpdatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  LawyerSettingsByLawyerIdDistinctCountUpdatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  LawyerSettingsByLawyerIdDistinctCountValueAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_DISTINCT_COUNT_VALUE_ASC',
  LawyerSettingsByLawyerIdDistinctCountValueDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_DISTINCT_COUNT_VALUE_DESC',
  LawyerSettingsByLawyerIdMaxCreatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  LawyerSettingsByLawyerIdMaxCreatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  LawyerSettingsByLawyerIdMaxIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MAX_ID_ASC',
  LawyerSettingsByLawyerIdMaxIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MAX_ID_DESC',
  LawyerSettingsByLawyerIdMaxLawyerIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  LawyerSettingsByLawyerIdMaxLawyerIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  LawyerSettingsByLawyerIdMaxTypeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MAX_TYPE_ASC',
  LawyerSettingsByLawyerIdMaxTypeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MAX_TYPE_DESC',
  LawyerSettingsByLawyerIdMaxUpdatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  LawyerSettingsByLawyerIdMaxUpdatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  LawyerSettingsByLawyerIdMaxValueAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MAX_VALUE_ASC',
  LawyerSettingsByLawyerIdMaxValueDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MAX_VALUE_DESC',
  LawyerSettingsByLawyerIdMinCreatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  LawyerSettingsByLawyerIdMinCreatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  LawyerSettingsByLawyerIdMinIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MIN_ID_ASC',
  LawyerSettingsByLawyerIdMinIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MIN_ID_DESC',
  LawyerSettingsByLawyerIdMinLawyerIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  LawyerSettingsByLawyerIdMinLawyerIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  LawyerSettingsByLawyerIdMinTypeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MIN_TYPE_ASC',
  LawyerSettingsByLawyerIdMinTypeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MIN_TYPE_DESC',
  LawyerSettingsByLawyerIdMinUpdatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  LawyerSettingsByLawyerIdMinUpdatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  LawyerSettingsByLawyerIdMinValueAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MIN_VALUE_ASC',
  LawyerSettingsByLawyerIdMinValueDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_MIN_VALUE_DESC',
  LawyerSettingsByLawyerIdStddevPopulationCreatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LawyerSettingsByLawyerIdStddevPopulationCreatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LawyerSettingsByLawyerIdStddevPopulationIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  LawyerSettingsByLawyerIdStddevPopulationIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  LawyerSettingsByLawyerIdStddevPopulationLawyerIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  LawyerSettingsByLawyerIdStddevPopulationLawyerIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  LawyerSettingsByLawyerIdStddevPopulationTypeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_POPULATION_TYPE_ASC',
  LawyerSettingsByLawyerIdStddevPopulationTypeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_POPULATION_TYPE_DESC',
  LawyerSettingsByLawyerIdStddevPopulationUpdatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  LawyerSettingsByLawyerIdStddevPopulationUpdatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  LawyerSettingsByLawyerIdStddevPopulationValueAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_POPULATION_VALUE_ASC',
  LawyerSettingsByLawyerIdStddevPopulationValueDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_POPULATION_VALUE_DESC',
  LawyerSettingsByLawyerIdStddevSampleCreatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LawyerSettingsByLawyerIdStddevSampleCreatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LawyerSettingsByLawyerIdStddevSampleIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  LawyerSettingsByLawyerIdStddevSampleIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  LawyerSettingsByLawyerIdStddevSampleLawyerIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  LawyerSettingsByLawyerIdStddevSampleLawyerIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  LawyerSettingsByLawyerIdStddevSampleTypeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_SAMPLE_TYPE_ASC',
  LawyerSettingsByLawyerIdStddevSampleTypeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_SAMPLE_TYPE_DESC',
  LawyerSettingsByLawyerIdStddevSampleUpdatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  LawyerSettingsByLawyerIdStddevSampleUpdatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  LawyerSettingsByLawyerIdStddevSampleValueAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_SAMPLE_VALUE_ASC',
  LawyerSettingsByLawyerIdStddevSampleValueDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_STDDEV_SAMPLE_VALUE_DESC',
  LawyerSettingsByLawyerIdSumCreatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  LawyerSettingsByLawyerIdSumCreatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  LawyerSettingsByLawyerIdSumIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_SUM_ID_ASC',
  LawyerSettingsByLawyerIdSumIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_SUM_ID_DESC',
  LawyerSettingsByLawyerIdSumLawyerIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  LawyerSettingsByLawyerIdSumLawyerIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  LawyerSettingsByLawyerIdSumTypeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_SUM_TYPE_ASC',
  LawyerSettingsByLawyerIdSumTypeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_SUM_TYPE_DESC',
  LawyerSettingsByLawyerIdSumUpdatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  LawyerSettingsByLawyerIdSumUpdatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  LawyerSettingsByLawyerIdSumValueAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_SUM_VALUE_ASC',
  LawyerSettingsByLawyerIdSumValueDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_SUM_VALUE_DESC',
  LawyerSettingsByLawyerIdVariancePopulationCreatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LawyerSettingsByLawyerIdVariancePopulationCreatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LawyerSettingsByLawyerIdVariancePopulationIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  LawyerSettingsByLawyerIdVariancePopulationIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  LawyerSettingsByLawyerIdVariancePopulationLawyerIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  LawyerSettingsByLawyerIdVariancePopulationLawyerIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  LawyerSettingsByLawyerIdVariancePopulationTypeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_POPULATION_TYPE_ASC',
  LawyerSettingsByLawyerIdVariancePopulationTypeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_POPULATION_TYPE_DESC',
  LawyerSettingsByLawyerIdVariancePopulationUpdatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  LawyerSettingsByLawyerIdVariancePopulationUpdatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  LawyerSettingsByLawyerIdVariancePopulationValueAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_POPULATION_VALUE_ASC',
  LawyerSettingsByLawyerIdVariancePopulationValueDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_POPULATION_VALUE_DESC',
  LawyerSettingsByLawyerIdVarianceSampleCreatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LawyerSettingsByLawyerIdVarianceSampleCreatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LawyerSettingsByLawyerIdVarianceSampleIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  LawyerSettingsByLawyerIdVarianceSampleIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  LawyerSettingsByLawyerIdVarianceSampleLawyerIdAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  LawyerSettingsByLawyerIdVarianceSampleLawyerIdDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  LawyerSettingsByLawyerIdVarianceSampleTypeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_TYPE_ASC',
  LawyerSettingsByLawyerIdVarianceSampleTypeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_TYPE_DESC',
  LawyerSettingsByLawyerIdVarianceSampleUpdatedTimeAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  LawyerSettingsByLawyerIdVarianceSampleUpdatedTimeDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  LawyerSettingsByLawyerIdVarianceSampleValueAsc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_VALUE_ASC',
  LawyerSettingsByLawyerIdVarianceSampleValueDesc = 'LAWYER_SETTINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_VALUE_DESC',
  LawyerTagsByLawyerIdAverageLawyerIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  LawyerTagsByLawyerIdAverageLawyerIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  LawyerTagsByLawyerIdAverageTagIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_AVERAGE_TAG_ID_ASC',
  LawyerTagsByLawyerIdAverageTagIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_AVERAGE_TAG_ID_DESC',
  LawyerTagsByLawyerIdCountAsc = 'LAWYER_TAGS_BY_LAWYER_ID_COUNT_ASC',
  LawyerTagsByLawyerIdCountDesc = 'LAWYER_TAGS_BY_LAWYER_ID_COUNT_DESC',
  LawyerTagsByLawyerIdDistinctCountLawyerIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  LawyerTagsByLawyerIdDistinctCountLawyerIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  LawyerTagsByLawyerIdDistinctCountTagIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_DISTINCT_COUNT_TAG_ID_ASC',
  LawyerTagsByLawyerIdDistinctCountTagIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_DISTINCT_COUNT_TAG_ID_DESC',
  LawyerTagsByLawyerIdMaxLawyerIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  LawyerTagsByLawyerIdMaxLawyerIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  LawyerTagsByLawyerIdMaxTagIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_MAX_TAG_ID_ASC',
  LawyerTagsByLawyerIdMaxTagIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_MAX_TAG_ID_DESC',
  LawyerTagsByLawyerIdMinLawyerIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  LawyerTagsByLawyerIdMinLawyerIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  LawyerTagsByLawyerIdMinTagIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_MIN_TAG_ID_ASC',
  LawyerTagsByLawyerIdMinTagIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_MIN_TAG_ID_DESC',
  LawyerTagsByLawyerIdStddevPopulationLawyerIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  LawyerTagsByLawyerIdStddevPopulationLawyerIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  LawyerTagsByLawyerIdStddevPopulationTagIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_STDDEV_POPULATION_TAG_ID_ASC',
  LawyerTagsByLawyerIdStddevPopulationTagIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_STDDEV_POPULATION_TAG_ID_DESC',
  LawyerTagsByLawyerIdStddevSampleLawyerIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  LawyerTagsByLawyerIdStddevSampleLawyerIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  LawyerTagsByLawyerIdStddevSampleTagIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_STDDEV_SAMPLE_TAG_ID_ASC',
  LawyerTagsByLawyerIdStddevSampleTagIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_STDDEV_SAMPLE_TAG_ID_DESC',
  LawyerTagsByLawyerIdSumLawyerIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  LawyerTagsByLawyerIdSumLawyerIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  LawyerTagsByLawyerIdSumTagIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_SUM_TAG_ID_ASC',
  LawyerTagsByLawyerIdSumTagIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_SUM_TAG_ID_DESC',
  LawyerTagsByLawyerIdVariancePopulationLawyerIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  LawyerTagsByLawyerIdVariancePopulationLawyerIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  LawyerTagsByLawyerIdVariancePopulationTagIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_VARIANCE_POPULATION_TAG_ID_ASC',
  LawyerTagsByLawyerIdVariancePopulationTagIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_VARIANCE_POPULATION_TAG_ID_DESC',
  LawyerTagsByLawyerIdVarianceSampleLawyerIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  LawyerTagsByLawyerIdVarianceSampleLawyerIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  LawyerTagsByLawyerIdVarianceSampleTagIdAsc = 'LAWYER_TAGS_BY_LAWYER_ID_VARIANCE_SAMPLE_TAG_ID_ASC',
  LawyerTagsByLawyerIdVarianceSampleTagIdDesc = 'LAWYER_TAGS_BY_LAWYER_ID_VARIANCE_SAMPLE_TAG_ID_DESC',
  LeadsPerMonthAsc = 'LEADS_PER_MONTH_ASC',
  LeadsPerMonthDesc = 'LEADS_PER_MONTH_DESC',
  LeverAsc = 'LEVER_ASC',
  LeverDesc = 'LEVER_DESC',
  LinkedinPostLikeRequestsByLawyerIdAverageCreatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdAverageCreatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdAverageIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdAverageIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdAverageLawyerIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdAverageLawyerIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdAverageScheduledTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_SCHEDULED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdAverageScheduledTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_SCHEDULED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdAverageStatusAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_STATUS_ASC',
  LinkedinPostLikeRequestsByLawyerIdAverageStatusDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_STATUS_DESC',
  LinkedinPostLikeRequestsByLawyerIdAverageUpdatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdAverageUpdatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdAverageUrlAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_URL_ASC',
  LinkedinPostLikeRequestsByLawyerIdAverageUrlDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_AVERAGE_URL_DESC',
  LinkedinPostLikeRequestsByLawyerIdCountAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_COUNT_ASC',
  LinkedinPostLikeRequestsByLawyerIdCountDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_COUNT_DESC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountCreatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountCreatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountLawyerIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountLawyerIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountScheduledTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_SCHEDULED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountScheduledTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_SCHEDULED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountStatusAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_STATUS_ASC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountStatusDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_STATUS_DESC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountUpdatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountUpdatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountUrlAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_URL_ASC',
  LinkedinPostLikeRequestsByLawyerIdDistinctCountUrlDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_URL_DESC',
  LinkedinPostLikeRequestsByLawyerIdMaxCreatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdMaxCreatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdMaxIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdMaxIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdMaxLawyerIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdMaxLawyerIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdMaxScheduledTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_SCHEDULED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdMaxScheduledTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_SCHEDULED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdMaxStatusAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_STATUS_ASC',
  LinkedinPostLikeRequestsByLawyerIdMaxStatusDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_STATUS_DESC',
  LinkedinPostLikeRequestsByLawyerIdMaxUpdatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdMaxUpdatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdMaxUrlAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_URL_ASC',
  LinkedinPostLikeRequestsByLawyerIdMaxUrlDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MAX_URL_DESC',
  LinkedinPostLikeRequestsByLawyerIdMinCreatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdMinCreatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdMinIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdMinIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdMinLawyerIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdMinLawyerIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdMinScheduledTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_SCHEDULED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdMinScheduledTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_SCHEDULED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdMinStatusAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_STATUS_ASC',
  LinkedinPostLikeRequestsByLawyerIdMinStatusDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_STATUS_DESC',
  LinkedinPostLikeRequestsByLawyerIdMinUpdatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdMinUpdatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdMinUrlAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_URL_ASC',
  LinkedinPostLikeRequestsByLawyerIdMinUrlDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_MIN_URL_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationCreatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationCreatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationLawyerIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationLawyerIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationScheduledTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_SCHEDULED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationScheduledTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_SCHEDULED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationStatusAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_STATUS_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationStatusDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_STATUS_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationUpdatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationUpdatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationUrlAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_URL_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevPopulationUrlDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_URL_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleCreatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleCreatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleLawyerIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleLawyerIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleScheduledTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_SCHEDULED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleScheduledTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_SCHEDULED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleStatusAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_STATUS_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleStatusDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_STATUS_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleUpdatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleUpdatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleUrlAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_URL_ASC',
  LinkedinPostLikeRequestsByLawyerIdStddevSampleUrlDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_URL_DESC',
  LinkedinPostLikeRequestsByLawyerIdSumCreatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdSumCreatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdSumIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdSumIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdSumLawyerIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdSumLawyerIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdSumScheduledTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_SCHEDULED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdSumScheduledTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_SCHEDULED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdSumStatusAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_STATUS_ASC',
  LinkedinPostLikeRequestsByLawyerIdSumStatusDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_STATUS_DESC',
  LinkedinPostLikeRequestsByLawyerIdSumUpdatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdSumUpdatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdSumUrlAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_URL_ASC',
  LinkedinPostLikeRequestsByLawyerIdSumUrlDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_SUM_URL_DESC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationCreatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationCreatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationLawyerIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationLawyerIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationScheduledTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_SCHEDULED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationScheduledTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_SCHEDULED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationStatusAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_STATUS_ASC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationStatusDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_STATUS_DESC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationUpdatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationUpdatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationUrlAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_URL_ASC',
  LinkedinPostLikeRequestsByLawyerIdVariancePopulationUrlDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_URL_DESC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleCreatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleCreatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleLawyerIdAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleLawyerIdDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleScheduledTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_SCHEDULED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleScheduledTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_SCHEDULED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleStatusAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_STATUS_ASC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleStatusDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_STATUS_DESC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleUpdatedTimeAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleUpdatedTimeDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleUrlAsc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_URL_ASC',
  LinkedinPostLikeRequestsByLawyerIdVarianceSampleUrlDesc = 'LINKEDIN_POST_LIKE_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_URL_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_CONTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_CONTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_INTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_INTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_SEND_AT_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_SEND_AT_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdAverageUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdAverageUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdCountAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_COUNT_ASC',
  LinkedinScheduledMessagesByLawyerIdCountDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_COUNT_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CONTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CONTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_INTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_INTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_SEND_AT_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_SEND_AT_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdDistinctCountUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_CONTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_CONTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_INTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_INTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_SEND_AT_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_SEND_AT_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdMaxUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdMaxUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdMinCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdMinCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdMinCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdMinCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdMinCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdMinCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdMinContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdMinContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdMinContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_CONTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdMinContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_CONTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdMinCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdMinCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdMinForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdMinForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdMinIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdMinIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdMinIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_INTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdMinIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_INTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdMinIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByLawyerIdMinIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByLawyerIdMinIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByLawyerIdMinIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByLawyerIdMinIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByLawyerIdMinIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByLawyerIdMinLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdMinLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdMinLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdMinLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdMinRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdMinRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdMinReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdMinReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdMinReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdMinReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdMinSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_SEND_AT_ASC',
  LinkedinScheduledMessagesByLawyerIdMinSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_SEND_AT_DESC',
  LinkedinScheduledMessagesByLawyerIdMinStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdMinStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdMinUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdMinUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CONTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CONTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_INTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_INTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_SEND_AT_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_SEND_AT_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevPopulationUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CONTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CONTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_INTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_INTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_SEND_AT_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_SEND_AT_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdStddevSampleUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdSumCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdSumCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdSumCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdSumCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdSumCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdSumCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdSumContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdSumContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdSumContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_CONTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdSumContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_CONTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdSumCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdSumCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdSumForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdSumForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdSumIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdSumIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdSumIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_INTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdSumIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_INTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdSumIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByLawyerIdSumIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByLawyerIdSumIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByLawyerIdSumIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByLawyerIdSumIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByLawyerIdSumIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByLawyerIdSumLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdSumLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdSumLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdSumLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdSumRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdSumRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdSumReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdSumReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdSumReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdSumReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdSumSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_SEND_AT_ASC',
  LinkedinScheduledMessagesByLawyerIdSumSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_SEND_AT_DESC',
  LinkedinScheduledMessagesByLawyerIdSumStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdSumStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdSumUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdSumUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CONTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CONTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_INTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_INTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_SEND_AT_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_SEND_AT_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdVariancePopulationUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleCampaignIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleCampaignIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleCampaignMemberIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleCampaignMemberIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleCompanyIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleCompanyIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleContactIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleContactIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleContentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleContentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleCreatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleCreatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleForwardedToLawyerTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleForwardedToLawyerTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleIntentAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_INTENT_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleIntentDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_INTENT_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleIsFromCampaignAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_FROM_CAMPAIGN_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleIsFromCampaignDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_FROM_CAMPAIGN_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleIsLawyerAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleIsLawyerDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleIsReviewedAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_REVIEWED_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleIsReviewedDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_REVIEWED_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleLawyerIdAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleLawyerIdDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleLawyerViewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleLawyerViewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleRepliedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REPLIED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleRepliedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REPLIED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleReplyStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REPLY_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleReplyStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REPLY_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleReviewedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleReviewedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleSendAtAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_SEND_AT_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleSendAtDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_SEND_AT_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleStatusAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_STATUS_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleStatusDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_STATUS_DESC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleUpdatedTimeAsc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  LinkedinScheduledMessagesByLawyerIdVarianceSampleUpdatedTimeDesc = 'LINKEDIN_SCHEDULED_MESSAGES_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  LinkedinUrlAsc = 'LINKEDIN_URL_ASC',
  LinkedinUrlDesc = 'LINKEDIN_URL_DESC',
  LiEmailAsc = 'LI_EMAIL_ASC',
  LiEmailDesc = 'LI_EMAIL_DESC',
  LiEncryptedPwAsc = 'LI_ENCRYPTED_PW_ASC',
  LiEncryptedPwDesc = 'LI_ENCRYPTED_PW_DESC',
  LiLastActiveTimeAsc = 'LI_LAST_ACTIVE_TIME_ASC',
  LiLastActiveTimeDesc = 'LI_LAST_ACTIVE_TIME_DESC',
  LiLoginStatusAsc = 'LI_LOGIN_STATUS_ASC',
  LiLoginStatusDesc = 'LI_LOGIN_STATUS_DESC',
  LiProfileNameAsc = 'LI_PROFILE_NAME_ASC',
  LiProfileNameDesc = 'LI_PROFILE_NAME_DESC',
  LiStopConnectionReqsUntilAsc = 'LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LiStopConnectionReqsUntilDesc = 'LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LiVerificationCodeAsc = 'LI_VERIFICATION_CODE_ASC',
  LiVerificationCodeDesc = 'LI_VERIFICATION_CODE_DESC',
  LogoSrcAsc = 'LOGO_SRC_ASC',
  LogoSrcDesc = 'LOGO_SRC_DESC',
  MeetingsByLawyerIdAverageCalendlyUriAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_CALENDLY_URI_ASC',
  MeetingsByLawyerIdAverageCalendlyUriDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_CALENDLY_URI_DESC',
  MeetingsByLawyerIdAverageCanceledByAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_CANCELED_BY_ASC',
  MeetingsByLawyerIdAverageCanceledByDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_CANCELED_BY_DESC',
  MeetingsByLawyerIdAverageCancelerTypeAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_CANCELER_TYPE_ASC',
  MeetingsByLawyerIdAverageCancelerTypeDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_CANCELER_TYPE_DESC',
  MeetingsByLawyerIdAverageCancellationReasonAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_CANCELLATION_REASON_ASC',
  MeetingsByLawyerIdAverageCancellationReasonDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_CANCELLATION_REASON_DESC',
  MeetingsByLawyerIdAverageContactFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdAverageContactFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdAverageCreatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  MeetingsByLawyerIdAverageCreatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  MeetingsByLawyerIdAverageEndTimeAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_END_TIME_ASC',
  MeetingsByLawyerIdAverageEndTimeDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_END_TIME_DESC',
  MeetingsByLawyerIdAverageIdAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  MeetingsByLawyerIdAverageIdDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  MeetingsByLawyerIdAverageLawyerFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdAverageLawyerFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdAverageLawyerIdAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  MeetingsByLawyerIdAverageLawyerIdDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  MeetingsByLawyerIdAverageLocationAddressAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_LOCATION_ADDRESS_ASC',
  MeetingsByLawyerIdAverageLocationAddressDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_LOCATION_ADDRESS_DESC',
  MeetingsByLawyerIdAverageLocationProviderIdAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_LOCATION_PROVIDER_ID_ASC',
  MeetingsByLawyerIdAverageLocationProviderIdDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_LOCATION_PROVIDER_ID_DESC',
  MeetingsByLawyerIdAverageLocationStatusAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_LOCATION_STATUS_ASC',
  MeetingsByLawyerIdAverageLocationStatusDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_LOCATION_STATUS_DESC',
  MeetingsByLawyerIdAverageLocationTypeAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_LOCATION_TYPE_ASC',
  MeetingsByLawyerIdAverageLocationTypeDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_LOCATION_TYPE_DESC',
  MeetingsByLawyerIdAverageMeetingTypeIdAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_MEETING_TYPE_ID_ASC',
  MeetingsByLawyerIdAverageMeetingTypeIdDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_MEETING_TYPE_ID_DESC',
  MeetingsByLawyerIdAverageNameAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_NAME_ASC',
  MeetingsByLawyerIdAverageNameDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_NAME_DESC',
  MeetingsByLawyerIdAverageNotifiedBeforeMeetingAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByLawyerIdAverageNotifiedBeforeMeetingDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByLawyerIdAverageNotifiedInAdvanceAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByLawyerIdAverageNotifiedInAdvanceDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByLawyerIdAverageStartTimeAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_START_TIME_ASC',
  MeetingsByLawyerIdAverageStartTimeDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_START_TIME_DESC',
  MeetingsByLawyerIdAverageStatusAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_STATUS_ASC',
  MeetingsByLawyerIdAverageStatusDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_STATUS_DESC',
  MeetingsByLawyerIdAverageUpdatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  MeetingsByLawyerIdAverageUpdatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  MeetingsByLawyerIdCountAsc = 'MEETINGS_BY_LAWYER_ID_COUNT_ASC',
  MeetingsByLawyerIdCountDesc = 'MEETINGS_BY_LAWYER_ID_COUNT_DESC',
  MeetingsByLawyerIdDistinctCountCalendlyUriAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CALENDLY_URI_ASC',
  MeetingsByLawyerIdDistinctCountCalendlyUriDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CALENDLY_URI_DESC',
  MeetingsByLawyerIdDistinctCountCanceledByAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CANCELED_BY_ASC',
  MeetingsByLawyerIdDistinctCountCanceledByDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CANCELED_BY_DESC',
  MeetingsByLawyerIdDistinctCountCancelerTypeAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CANCELER_TYPE_ASC',
  MeetingsByLawyerIdDistinctCountCancelerTypeDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CANCELER_TYPE_DESC',
  MeetingsByLawyerIdDistinctCountCancellationReasonAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CANCELLATION_REASON_ASC',
  MeetingsByLawyerIdDistinctCountCancellationReasonDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CANCELLATION_REASON_DESC',
  MeetingsByLawyerIdDistinctCountContactFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdDistinctCountContactFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdDistinctCountCreatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  MeetingsByLawyerIdDistinctCountCreatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  MeetingsByLawyerIdDistinctCountEndTimeAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_END_TIME_ASC',
  MeetingsByLawyerIdDistinctCountEndTimeDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_END_TIME_DESC',
  MeetingsByLawyerIdDistinctCountIdAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  MeetingsByLawyerIdDistinctCountIdDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  MeetingsByLawyerIdDistinctCountLawyerFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdDistinctCountLawyerFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdDistinctCountLawyerIdAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  MeetingsByLawyerIdDistinctCountLawyerIdDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  MeetingsByLawyerIdDistinctCountLocationAddressAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LOCATION_ADDRESS_ASC',
  MeetingsByLawyerIdDistinctCountLocationAddressDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LOCATION_ADDRESS_DESC',
  MeetingsByLawyerIdDistinctCountLocationProviderIdAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LOCATION_PROVIDER_ID_ASC',
  MeetingsByLawyerIdDistinctCountLocationProviderIdDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LOCATION_PROVIDER_ID_DESC',
  MeetingsByLawyerIdDistinctCountLocationStatusAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LOCATION_STATUS_ASC',
  MeetingsByLawyerIdDistinctCountLocationStatusDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LOCATION_STATUS_DESC',
  MeetingsByLawyerIdDistinctCountLocationTypeAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LOCATION_TYPE_ASC',
  MeetingsByLawyerIdDistinctCountLocationTypeDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LOCATION_TYPE_DESC',
  MeetingsByLawyerIdDistinctCountMeetingTypeIdAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_MEETING_TYPE_ID_ASC',
  MeetingsByLawyerIdDistinctCountMeetingTypeIdDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_MEETING_TYPE_ID_DESC',
  MeetingsByLawyerIdDistinctCountNameAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_NAME_ASC',
  MeetingsByLawyerIdDistinctCountNameDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_NAME_DESC',
  MeetingsByLawyerIdDistinctCountNotifiedBeforeMeetingAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByLawyerIdDistinctCountNotifiedBeforeMeetingDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByLawyerIdDistinctCountNotifiedInAdvanceAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByLawyerIdDistinctCountNotifiedInAdvanceDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByLawyerIdDistinctCountStartTimeAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_START_TIME_ASC',
  MeetingsByLawyerIdDistinctCountStartTimeDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_START_TIME_DESC',
  MeetingsByLawyerIdDistinctCountStatusAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_STATUS_ASC',
  MeetingsByLawyerIdDistinctCountStatusDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_STATUS_DESC',
  MeetingsByLawyerIdDistinctCountUpdatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  MeetingsByLawyerIdDistinctCountUpdatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  MeetingsByLawyerIdMaxCalendlyUriAsc = 'MEETINGS_BY_LAWYER_ID_MAX_CALENDLY_URI_ASC',
  MeetingsByLawyerIdMaxCalendlyUriDesc = 'MEETINGS_BY_LAWYER_ID_MAX_CALENDLY_URI_DESC',
  MeetingsByLawyerIdMaxCanceledByAsc = 'MEETINGS_BY_LAWYER_ID_MAX_CANCELED_BY_ASC',
  MeetingsByLawyerIdMaxCanceledByDesc = 'MEETINGS_BY_LAWYER_ID_MAX_CANCELED_BY_DESC',
  MeetingsByLawyerIdMaxCancelerTypeAsc = 'MEETINGS_BY_LAWYER_ID_MAX_CANCELER_TYPE_ASC',
  MeetingsByLawyerIdMaxCancelerTypeDesc = 'MEETINGS_BY_LAWYER_ID_MAX_CANCELER_TYPE_DESC',
  MeetingsByLawyerIdMaxCancellationReasonAsc = 'MEETINGS_BY_LAWYER_ID_MAX_CANCELLATION_REASON_ASC',
  MeetingsByLawyerIdMaxCancellationReasonDesc = 'MEETINGS_BY_LAWYER_ID_MAX_CANCELLATION_REASON_DESC',
  MeetingsByLawyerIdMaxContactFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_MAX_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdMaxContactFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_MAX_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdMaxCreatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  MeetingsByLawyerIdMaxCreatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  MeetingsByLawyerIdMaxEndTimeAsc = 'MEETINGS_BY_LAWYER_ID_MAX_END_TIME_ASC',
  MeetingsByLawyerIdMaxEndTimeDesc = 'MEETINGS_BY_LAWYER_ID_MAX_END_TIME_DESC',
  MeetingsByLawyerIdMaxIdAsc = 'MEETINGS_BY_LAWYER_ID_MAX_ID_ASC',
  MeetingsByLawyerIdMaxIdDesc = 'MEETINGS_BY_LAWYER_ID_MAX_ID_DESC',
  MeetingsByLawyerIdMaxLawyerFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_MAX_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdMaxLawyerFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_MAX_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdMaxLawyerIdAsc = 'MEETINGS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  MeetingsByLawyerIdMaxLawyerIdDesc = 'MEETINGS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  MeetingsByLawyerIdMaxLocationAddressAsc = 'MEETINGS_BY_LAWYER_ID_MAX_LOCATION_ADDRESS_ASC',
  MeetingsByLawyerIdMaxLocationAddressDesc = 'MEETINGS_BY_LAWYER_ID_MAX_LOCATION_ADDRESS_DESC',
  MeetingsByLawyerIdMaxLocationProviderIdAsc = 'MEETINGS_BY_LAWYER_ID_MAX_LOCATION_PROVIDER_ID_ASC',
  MeetingsByLawyerIdMaxLocationProviderIdDesc = 'MEETINGS_BY_LAWYER_ID_MAX_LOCATION_PROVIDER_ID_DESC',
  MeetingsByLawyerIdMaxLocationStatusAsc = 'MEETINGS_BY_LAWYER_ID_MAX_LOCATION_STATUS_ASC',
  MeetingsByLawyerIdMaxLocationStatusDesc = 'MEETINGS_BY_LAWYER_ID_MAX_LOCATION_STATUS_DESC',
  MeetingsByLawyerIdMaxLocationTypeAsc = 'MEETINGS_BY_LAWYER_ID_MAX_LOCATION_TYPE_ASC',
  MeetingsByLawyerIdMaxLocationTypeDesc = 'MEETINGS_BY_LAWYER_ID_MAX_LOCATION_TYPE_DESC',
  MeetingsByLawyerIdMaxMeetingTypeIdAsc = 'MEETINGS_BY_LAWYER_ID_MAX_MEETING_TYPE_ID_ASC',
  MeetingsByLawyerIdMaxMeetingTypeIdDesc = 'MEETINGS_BY_LAWYER_ID_MAX_MEETING_TYPE_ID_DESC',
  MeetingsByLawyerIdMaxNameAsc = 'MEETINGS_BY_LAWYER_ID_MAX_NAME_ASC',
  MeetingsByLawyerIdMaxNameDesc = 'MEETINGS_BY_LAWYER_ID_MAX_NAME_DESC',
  MeetingsByLawyerIdMaxNotifiedBeforeMeetingAsc = 'MEETINGS_BY_LAWYER_ID_MAX_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByLawyerIdMaxNotifiedBeforeMeetingDesc = 'MEETINGS_BY_LAWYER_ID_MAX_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByLawyerIdMaxNotifiedInAdvanceAsc = 'MEETINGS_BY_LAWYER_ID_MAX_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByLawyerIdMaxNotifiedInAdvanceDesc = 'MEETINGS_BY_LAWYER_ID_MAX_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByLawyerIdMaxStartTimeAsc = 'MEETINGS_BY_LAWYER_ID_MAX_START_TIME_ASC',
  MeetingsByLawyerIdMaxStartTimeDesc = 'MEETINGS_BY_LAWYER_ID_MAX_START_TIME_DESC',
  MeetingsByLawyerIdMaxStatusAsc = 'MEETINGS_BY_LAWYER_ID_MAX_STATUS_ASC',
  MeetingsByLawyerIdMaxStatusDesc = 'MEETINGS_BY_LAWYER_ID_MAX_STATUS_DESC',
  MeetingsByLawyerIdMaxUpdatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  MeetingsByLawyerIdMaxUpdatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  MeetingsByLawyerIdMinCalendlyUriAsc = 'MEETINGS_BY_LAWYER_ID_MIN_CALENDLY_URI_ASC',
  MeetingsByLawyerIdMinCalendlyUriDesc = 'MEETINGS_BY_LAWYER_ID_MIN_CALENDLY_URI_DESC',
  MeetingsByLawyerIdMinCanceledByAsc = 'MEETINGS_BY_LAWYER_ID_MIN_CANCELED_BY_ASC',
  MeetingsByLawyerIdMinCanceledByDesc = 'MEETINGS_BY_LAWYER_ID_MIN_CANCELED_BY_DESC',
  MeetingsByLawyerIdMinCancelerTypeAsc = 'MEETINGS_BY_LAWYER_ID_MIN_CANCELER_TYPE_ASC',
  MeetingsByLawyerIdMinCancelerTypeDesc = 'MEETINGS_BY_LAWYER_ID_MIN_CANCELER_TYPE_DESC',
  MeetingsByLawyerIdMinCancellationReasonAsc = 'MEETINGS_BY_LAWYER_ID_MIN_CANCELLATION_REASON_ASC',
  MeetingsByLawyerIdMinCancellationReasonDesc = 'MEETINGS_BY_LAWYER_ID_MIN_CANCELLATION_REASON_DESC',
  MeetingsByLawyerIdMinContactFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_MIN_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdMinContactFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_MIN_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdMinCreatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  MeetingsByLawyerIdMinCreatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  MeetingsByLawyerIdMinEndTimeAsc = 'MEETINGS_BY_LAWYER_ID_MIN_END_TIME_ASC',
  MeetingsByLawyerIdMinEndTimeDesc = 'MEETINGS_BY_LAWYER_ID_MIN_END_TIME_DESC',
  MeetingsByLawyerIdMinIdAsc = 'MEETINGS_BY_LAWYER_ID_MIN_ID_ASC',
  MeetingsByLawyerIdMinIdDesc = 'MEETINGS_BY_LAWYER_ID_MIN_ID_DESC',
  MeetingsByLawyerIdMinLawyerFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_MIN_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdMinLawyerFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_MIN_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdMinLawyerIdAsc = 'MEETINGS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  MeetingsByLawyerIdMinLawyerIdDesc = 'MEETINGS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  MeetingsByLawyerIdMinLocationAddressAsc = 'MEETINGS_BY_LAWYER_ID_MIN_LOCATION_ADDRESS_ASC',
  MeetingsByLawyerIdMinLocationAddressDesc = 'MEETINGS_BY_LAWYER_ID_MIN_LOCATION_ADDRESS_DESC',
  MeetingsByLawyerIdMinLocationProviderIdAsc = 'MEETINGS_BY_LAWYER_ID_MIN_LOCATION_PROVIDER_ID_ASC',
  MeetingsByLawyerIdMinLocationProviderIdDesc = 'MEETINGS_BY_LAWYER_ID_MIN_LOCATION_PROVIDER_ID_DESC',
  MeetingsByLawyerIdMinLocationStatusAsc = 'MEETINGS_BY_LAWYER_ID_MIN_LOCATION_STATUS_ASC',
  MeetingsByLawyerIdMinLocationStatusDesc = 'MEETINGS_BY_LAWYER_ID_MIN_LOCATION_STATUS_DESC',
  MeetingsByLawyerIdMinLocationTypeAsc = 'MEETINGS_BY_LAWYER_ID_MIN_LOCATION_TYPE_ASC',
  MeetingsByLawyerIdMinLocationTypeDesc = 'MEETINGS_BY_LAWYER_ID_MIN_LOCATION_TYPE_DESC',
  MeetingsByLawyerIdMinMeetingTypeIdAsc = 'MEETINGS_BY_LAWYER_ID_MIN_MEETING_TYPE_ID_ASC',
  MeetingsByLawyerIdMinMeetingTypeIdDesc = 'MEETINGS_BY_LAWYER_ID_MIN_MEETING_TYPE_ID_DESC',
  MeetingsByLawyerIdMinNameAsc = 'MEETINGS_BY_LAWYER_ID_MIN_NAME_ASC',
  MeetingsByLawyerIdMinNameDesc = 'MEETINGS_BY_LAWYER_ID_MIN_NAME_DESC',
  MeetingsByLawyerIdMinNotifiedBeforeMeetingAsc = 'MEETINGS_BY_LAWYER_ID_MIN_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByLawyerIdMinNotifiedBeforeMeetingDesc = 'MEETINGS_BY_LAWYER_ID_MIN_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByLawyerIdMinNotifiedInAdvanceAsc = 'MEETINGS_BY_LAWYER_ID_MIN_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByLawyerIdMinNotifiedInAdvanceDesc = 'MEETINGS_BY_LAWYER_ID_MIN_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByLawyerIdMinStartTimeAsc = 'MEETINGS_BY_LAWYER_ID_MIN_START_TIME_ASC',
  MeetingsByLawyerIdMinStartTimeDesc = 'MEETINGS_BY_LAWYER_ID_MIN_START_TIME_DESC',
  MeetingsByLawyerIdMinStatusAsc = 'MEETINGS_BY_LAWYER_ID_MIN_STATUS_ASC',
  MeetingsByLawyerIdMinStatusDesc = 'MEETINGS_BY_LAWYER_ID_MIN_STATUS_DESC',
  MeetingsByLawyerIdMinUpdatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  MeetingsByLawyerIdMinUpdatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  MeetingsByLawyerIdStddevPopulationCalendlyUriAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CALENDLY_URI_ASC',
  MeetingsByLawyerIdStddevPopulationCalendlyUriDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CALENDLY_URI_DESC',
  MeetingsByLawyerIdStddevPopulationCanceledByAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CANCELED_BY_ASC',
  MeetingsByLawyerIdStddevPopulationCanceledByDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CANCELED_BY_DESC',
  MeetingsByLawyerIdStddevPopulationCancelerTypeAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CANCELER_TYPE_ASC',
  MeetingsByLawyerIdStddevPopulationCancelerTypeDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CANCELER_TYPE_DESC',
  MeetingsByLawyerIdStddevPopulationCancellationReasonAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CANCELLATION_REASON_ASC',
  MeetingsByLawyerIdStddevPopulationCancellationReasonDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CANCELLATION_REASON_DESC',
  MeetingsByLawyerIdStddevPopulationContactFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdStddevPopulationContactFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdStddevPopulationCreatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  MeetingsByLawyerIdStddevPopulationCreatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  MeetingsByLawyerIdStddevPopulationEndTimeAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_END_TIME_ASC',
  MeetingsByLawyerIdStddevPopulationEndTimeDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_END_TIME_DESC',
  MeetingsByLawyerIdStddevPopulationIdAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  MeetingsByLawyerIdStddevPopulationIdDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  MeetingsByLawyerIdStddevPopulationLawyerFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdStddevPopulationLawyerFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdStddevPopulationLawyerIdAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  MeetingsByLawyerIdStddevPopulationLawyerIdDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  MeetingsByLawyerIdStddevPopulationLocationAddressAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LOCATION_ADDRESS_ASC',
  MeetingsByLawyerIdStddevPopulationLocationAddressDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LOCATION_ADDRESS_DESC',
  MeetingsByLawyerIdStddevPopulationLocationProviderIdAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LOCATION_PROVIDER_ID_ASC',
  MeetingsByLawyerIdStddevPopulationLocationProviderIdDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LOCATION_PROVIDER_ID_DESC',
  MeetingsByLawyerIdStddevPopulationLocationStatusAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LOCATION_STATUS_ASC',
  MeetingsByLawyerIdStddevPopulationLocationStatusDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LOCATION_STATUS_DESC',
  MeetingsByLawyerIdStddevPopulationLocationTypeAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LOCATION_TYPE_ASC',
  MeetingsByLawyerIdStddevPopulationLocationTypeDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LOCATION_TYPE_DESC',
  MeetingsByLawyerIdStddevPopulationMeetingTypeIdAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_MEETING_TYPE_ID_ASC',
  MeetingsByLawyerIdStddevPopulationMeetingTypeIdDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_MEETING_TYPE_ID_DESC',
  MeetingsByLawyerIdStddevPopulationNameAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_NAME_ASC',
  MeetingsByLawyerIdStddevPopulationNameDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_NAME_DESC',
  MeetingsByLawyerIdStddevPopulationNotifiedBeforeMeetingAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByLawyerIdStddevPopulationNotifiedBeforeMeetingDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByLawyerIdStddevPopulationNotifiedInAdvanceAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByLawyerIdStddevPopulationNotifiedInAdvanceDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByLawyerIdStddevPopulationStartTimeAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_START_TIME_ASC',
  MeetingsByLawyerIdStddevPopulationStartTimeDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_START_TIME_DESC',
  MeetingsByLawyerIdStddevPopulationStatusAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_STATUS_ASC',
  MeetingsByLawyerIdStddevPopulationStatusDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_STATUS_DESC',
  MeetingsByLawyerIdStddevPopulationUpdatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  MeetingsByLawyerIdStddevPopulationUpdatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  MeetingsByLawyerIdStddevSampleCalendlyUriAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CALENDLY_URI_ASC',
  MeetingsByLawyerIdStddevSampleCalendlyUriDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CALENDLY_URI_DESC',
  MeetingsByLawyerIdStddevSampleCanceledByAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CANCELED_BY_ASC',
  MeetingsByLawyerIdStddevSampleCanceledByDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CANCELED_BY_DESC',
  MeetingsByLawyerIdStddevSampleCancelerTypeAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CANCELER_TYPE_ASC',
  MeetingsByLawyerIdStddevSampleCancelerTypeDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CANCELER_TYPE_DESC',
  MeetingsByLawyerIdStddevSampleCancellationReasonAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CANCELLATION_REASON_ASC',
  MeetingsByLawyerIdStddevSampleCancellationReasonDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CANCELLATION_REASON_DESC',
  MeetingsByLawyerIdStddevSampleContactFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdStddevSampleContactFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdStddevSampleCreatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  MeetingsByLawyerIdStddevSampleCreatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  MeetingsByLawyerIdStddevSampleEndTimeAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_END_TIME_ASC',
  MeetingsByLawyerIdStddevSampleEndTimeDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_END_TIME_DESC',
  MeetingsByLawyerIdStddevSampleIdAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  MeetingsByLawyerIdStddevSampleIdDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  MeetingsByLawyerIdStddevSampleLawyerFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdStddevSampleLawyerFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdStddevSampleLawyerIdAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  MeetingsByLawyerIdStddevSampleLawyerIdDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  MeetingsByLawyerIdStddevSampleLocationAddressAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LOCATION_ADDRESS_ASC',
  MeetingsByLawyerIdStddevSampleLocationAddressDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LOCATION_ADDRESS_DESC',
  MeetingsByLawyerIdStddevSampleLocationProviderIdAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LOCATION_PROVIDER_ID_ASC',
  MeetingsByLawyerIdStddevSampleLocationProviderIdDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LOCATION_PROVIDER_ID_DESC',
  MeetingsByLawyerIdStddevSampleLocationStatusAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LOCATION_STATUS_ASC',
  MeetingsByLawyerIdStddevSampleLocationStatusDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LOCATION_STATUS_DESC',
  MeetingsByLawyerIdStddevSampleLocationTypeAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LOCATION_TYPE_ASC',
  MeetingsByLawyerIdStddevSampleLocationTypeDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LOCATION_TYPE_DESC',
  MeetingsByLawyerIdStddevSampleMeetingTypeIdAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_MEETING_TYPE_ID_ASC',
  MeetingsByLawyerIdStddevSampleMeetingTypeIdDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_MEETING_TYPE_ID_DESC',
  MeetingsByLawyerIdStddevSampleNameAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_NAME_ASC',
  MeetingsByLawyerIdStddevSampleNameDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_NAME_DESC',
  MeetingsByLawyerIdStddevSampleNotifiedBeforeMeetingAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByLawyerIdStddevSampleNotifiedBeforeMeetingDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByLawyerIdStddevSampleNotifiedInAdvanceAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByLawyerIdStddevSampleNotifiedInAdvanceDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByLawyerIdStddevSampleStartTimeAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_START_TIME_ASC',
  MeetingsByLawyerIdStddevSampleStartTimeDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_START_TIME_DESC',
  MeetingsByLawyerIdStddevSampleStatusAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_STATUS_ASC',
  MeetingsByLawyerIdStddevSampleStatusDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_STATUS_DESC',
  MeetingsByLawyerIdStddevSampleUpdatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  MeetingsByLawyerIdStddevSampleUpdatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  MeetingsByLawyerIdSumCalendlyUriAsc = 'MEETINGS_BY_LAWYER_ID_SUM_CALENDLY_URI_ASC',
  MeetingsByLawyerIdSumCalendlyUriDesc = 'MEETINGS_BY_LAWYER_ID_SUM_CALENDLY_URI_DESC',
  MeetingsByLawyerIdSumCanceledByAsc = 'MEETINGS_BY_LAWYER_ID_SUM_CANCELED_BY_ASC',
  MeetingsByLawyerIdSumCanceledByDesc = 'MEETINGS_BY_LAWYER_ID_SUM_CANCELED_BY_DESC',
  MeetingsByLawyerIdSumCancelerTypeAsc = 'MEETINGS_BY_LAWYER_ID_SUM_CANCELER_TYPE_ASC',
  MeetingsByLawyerIdSumCancelerTypeDesc = 'MEETINGS_BY_LAWYER_ID_SUM_CANCELER_TYPE_DESC',
  MeetingsByLawyerIdSumCancellationReasonAsc = 'MEETINGS_BY_LAWYER_ID_SUM_CANCELLATION_REASON_ASC',
  MeetingsByLawyerIdSumCancellationReasonDesc = 'MEETINGS_BY_LAWYER_ID_SUM_CANCELLATION_REASON_DESC',
  MeetingsByLawyerIdSumContactFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_SUM_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdSumContactFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_SUM_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdSumCreatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  MeetingsByLawyerIdSumCreatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  MeetingsByLawyerIdSumEndTimeAsc = 'MEETINGS_BY_LAWYER_ID_SUM_END_TIME_ASC',
  MeetingsByLawyerIdSumEndTimeDesc = 'MEETINGS_BY_LAWYER_ID_SUM_END_TIME_DESC',
  MeetingsByLawyerIdSumIdAsc = 'MEETINGS_BY_LAWYER_ID_SUM_ID_ASC',
  MeetingsByLawyerIdSumIdDesc = 'MEETINGS_BY_LAWYER_ID_SUM_ID_DESC',
  MeetingsByLawyerIdSumLawyerFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_SUM_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdSumLawyerFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_SUM_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdSumLawyerIdAsc = 'MEETINGS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  MeetingsByLawyerIdSumLawyerIdDesc = 'MEETINGS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  MeetingsByLawyerIdSumLocationAddressAsc = 'MEETINGS_BY_LAWYER_ID_SUM_LOCATION_ADDRESS_ASC',
  MeetingsByLawyerIdSumLocationAddressDesc = 'MEETINGS_BY_LAWYER_ID_SUM_LOCATION_ADDRESS_DESC',
  MeetingsByLawyerIdSumLocationProviderIdAsc = 'MEETINGS_BY_LAWYER_ID_SUM_LOCATION_PROVIDER_ID_ASC',
  MeetingsByLawyerIdSumLocationProviderIdDesc = 'MEETINGS_BY_LAWYER_ID_SUM_LOCATION_PROVIDER_ID_DESC',
  MeetingsByLawyerIdSumLocationStatusAsc = 'MEETINGS_BY_LAWYER_ID_SUM_LOCATION_STATUS_ASC',
  MeetingsByLawyerIdSumLocationStatusDesc = 'MEETINGS_BY_LAWYER_ID_SUM_LOCATION_STATUS_DESC',
  MeetingsByLawyerIdSumLocationTypeAsc = 'MEETINGS_BY_LAWYER_ID_SUM_LOCATION_TYPE_ASC',
  MeetingsByLawyerIdSumLocationTypeDesc = 'MEETINGS_BY_LAWYER_ID_SUM_LOCATION_TYPE_DESC',
  MeetingsByLawyerIdSumMeetingTypeIdAsc = 'MEETINGS_BY_LAWYER_ID_SUM_MEETING_TYPE_ID_ASC',
  MeetingsByLawyerIdSumMeetingTypeIdDesc = 'MEETINGS_BY_LAWYER_ID_SUM_MEETING_TYPE_ID_DESC',
  MeetingsByLawyerIdSumNameAsc = 'MEETINGS_BY_LAWYER_ID_SUM_NAME_ASC',
  MeetingsByLawyerIdSumNameDesc = 'MEETINGS_BY_LAWYER_ID_SUM_NAME_DESC',
  MeetingsByLawyerIdSumNotifiedBeforeMeetingAsc = 'MEETINGS_BY_LAWYER_ID_SUM_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByLawyerIdSumNotifiedBeforeMeetingDesc = 'MEETINGS_BY_LAWYER_ID_SUM_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByLawyerIdSumNotifiedInAdvanceAsc = 'MEETINGS_BY_LAWYER_ID_SUM_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByLawyerIdSumNotifiedInAdvanceDesc = 'MEETINGS_BY_LAWYER_ID_SUM_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByLawyerIdSumStartTimeAsc = 'MEETINGS_BY_LAWYER_ID_SUM_START_TIME_ASC',
  MeetingsByLawyerIdSumStartTimeDesc = 'MEETINGS_BY_LAWYER_ID_SUM_START_TIME_DESC',
  MeetingsByLawyerIdSumStatusAsc = 'MEETINGS_BY_LAWYER_ID_SUM_STATUS_ASC',
  MeetingsByLawyerIdSumStatusDesc = 'MEETINGS_BY_LAWYER_ID_SUM_STATUS_DESC',
  MeetingsByLawyerIdSumUpdatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  MeetingsByLawyerIdSumUpdatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  MeetingsByLawyerIdVariancePopulationCalendlyUriAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CALENDLY_URI_ASC',
  MeetingsByLawyerIdVariancePopulationCalendlyUriDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CALENDLY_URI_DESC',
  MeetingsByLawyerIdVariancePopulationCanceledByAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CANCELED_BY_ASC',
  MeetingsByLawyerIdVariancePopulationCanceledByDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CANCELED_BY_DESC',
  MeetingsByLawyerIdVariancePopulationCancelerTypeAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CANCELER_TYPE_ASC',
  MeetingsByLawyerIdVariancePopulationCancelerTypeDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CANCELER_TYPE_DESC',
  MeetingsByLawyerIdVariancePopulationCancellationReasonAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CANCELLATION_REASON_ASC',
  MeetingsByLawyerIdVariancePopulationCancellationReasonDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CANCELLATION_REASON_DESC',
  MeetingsByLawyerIdVariancePopulationContactFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdVariancePopulationContactFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdVariancePopulationCreatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  MeetingsByLawyerIdVariancePopulationCreatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  MeetingsByLawyerIdVariancePopulationEndTimeAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_END_TIME_ASC',
  MeetingsByLawyerIdVariancePopulationEndTimeDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_END_TIME_DESC',
  MeetingsByLawyerIdVariancePopulationIdAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  MeetingsByLawyerIdVariancePopulationIdDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  MeetingsByLawyerIdVariancePopulationLawyerFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdVariancePopulationLawyerFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdVariancePopulationLawyerIdAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  MeetingsByLawyerIdVariancePopulationLawyerIdDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  MeetingsByLawyerIdVariancePopulationLocationAddressAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LOCATION_ADDRESS_ASC',
  MeetingsByLawyerIdVariancePopulationLocationAddressDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LOCATION_ADDRESS_DESC',
  MeetingsByLawyerIdVariancePopulationLocationProviderIdAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LOCATION_PROVIDER_ID_ASC',
  MeetingsByLawyerIdVariancePopulationLocationProviderIdDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LOCATION_PROVIDER_ID_DESC',
  MeetingsByLawyerIdVariancePopulationLocationStatusAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LOCATION_STATUS_ASC',
  MeetingsByLawyerIdVariancePopulationLocationStatusDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LOCATION_STATUS_DESC',
  MeetingsByLawyerIdVariancePopulationLocationTypeAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LOCATION_TYPE_ASC',
  MeetingsByLawyerIdVariancePopulationLocationTypeDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LOCATION_TYPE_DESC',
  MeetingsByLawyerIdVariancePopulationMeetingTypeIdAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_MEETING_TYPE_ID_ASC',
  MeetingsByLawyerIdVariancePopulationMeetingTypeIdDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_MEETING_TYPE_ID_DESC',
  MeetingsByLawyerIdVariancePopulationNameAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_NAME_ASC',
  MeetingsByLawyerIdVariancePopulationNameDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_NAME_DESC',
  MeetingsByLawyerIdVariancePopulationNotifiedBeforeMeetingAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByLawyerIdVariancePopulationNotifiedBeforeMeetingDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByLawyerIdVariancePopulationNotifiedInAdvanceAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByLawyerIdVariancePopulationNotifiedInAdvanceDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByLawyerIdVariancePopulationStartTimeAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_START_TIME_ASC',
  MeetingsByLawyerIdVariancePopulationStartTimeDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_START_TIME_DESC',
  MeetingsByLawyerIdVariancePopulationStatusAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_STATUS_ASC',
  MeetingsByLawyerIdVariancePopulationStatusDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_STATUS_DESC',
  MeetingsByLawyerIdVariancePopulationUpdatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  MeetingsByLawyerIdVariancePopulationUpdatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  MeetingsByLawyerIdVarianceSampleCalendlyUriAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CALENDLY_URI_ASC',
  MeetingsByLawyerIdVarianceSampleCalendlyUriDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CALENDLY_URI_DESC',
  MeetingsByLawyerIdVarianceSampleCanceledByAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CANCELED_BY_ASC',
  MeetingsByLawyerIdVarianceSampleCanceledByDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CANCELED_BY_DESC',
  MeetingsByLawyerIdVarianceSampleCancelerTypeAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CANCELER_TYPE_ASC',
  MeetingsByLawyerIdVarianceSampleCancelerTypeDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CANCELER_TYPE_DESC',
  MeetingsByLawyerIdVarianceSampleCancellationReasonAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CANCELLATION_REASON_ASC',
  MeetingsByLawyerIdVarianceSampleCancellationReasonDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CANCELLATION_REASON_DESC',
  MeetingsByLawyerIdVarianceSampleContactFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdVarianceSampleContactFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdVarianceSampleCreatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  MeetingsByLawyerIdVarianceSampleCreatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  MeetingsByLawyerIdVarianceSampleEndTimeAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_END_TIME_ASC',
  MeetingsByLawyerIdVarianceSampleEndTimeDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_END_TIME_DESC',
  MeetingsByLawyerIdVarianceSampleIdAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  MeetingsByLawyerIdVarianceSampleIdDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  MeetingsByLawyerIdVarianceSampleLawyerFeedbackRequestedAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByLawyerIdVarianceSampleLawyerFeedbackRequestedDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByLawyerIdVarianceSampleLawyerIdAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  MeetingsByLawyerIdVarianceSampleLawyerIdDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  MeetingsByLawyerIdVarianceSampleLocationAddressAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LOCATION_ADDRESS_ASC',
  MeetingsByLawyerIdVarianceSampleLocationAddressDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LOCATION_ADDRESS_DESC',
  MeetingsByLawyerIdVarianceSampleLocationProviderIdAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LOCATION_PROVIDER_ID_ASC',
  MeetingsByLawyerIdVarianceSampleLocationProviderIdDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LOCATION_PROVIDER_ID_DESC',
  MeetingsByLawyerIdVarianceSampleLocationStatusAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LOCATION_STATUS_ASC',
  MeetingsByLawyerIdVarianceSampleLocationStatusDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LOCATION_STATUS_DESC',
  MeetingsByLawyerIdVarianceSampleLocationTypeAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LOCATION_TYPE_ASC',
  MeetingsByLawyerIdVarianceSampleLocationTypeDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LOCATION_TYPE_DESC',
  MeetingsByLawyerIdVarianceSampleMeetingTypeIdAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_MEETING_TYPE_ID_ASC',
  MeetingsByLawyerIdVarianceSampleMeetingTypeIdDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_MEETING_TYPE_ID_DESC',
  MeetingsByLawyerIdVarianceSampleNameAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_NAME_ASC',
  MeetingsByLawyerIdVarianceSampleNameDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_NAME_DESC',
  MeetingsByLawyerIdVarianceSampleNotifiedBeforeMeetingAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByLawyerIdVarianceSampleNotifiedBeforeMeetingDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByLawyerIdVarianceSampleNotifiedInAdvanceAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByLawyerIdVarianceSampleNotifiedInAdvanceDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByLawyerIdVarianceSampleStartTimeAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_START_TIME_ASC',
  MeetingsByLawyerIdVarianceSampleStartTimeDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_START_TIME_DESC',
  MeetingsByLawyerIdVarianceSampleStatusAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_STATUS_ASC',
  MeetingsByLawyerIdVarianceSampleStatusDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_STATUS_DESC',
  MeetingsByLawyerIdVarianceSampleUpdatedTimeAsc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  MeetingsByLawyerIdVarianceSampleUpdatedTimeDesc = 'MEETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NylasAccessTokenAsc = 'NYLAS_ACCESS_TOKEN_ASC',
  NylasAccessTokenDesc = 'NYLAS_ACCESS_TOKEN_DESC',
  OnboardingFeePaidTimeAsc = 'ONBOARDING_FEE_PAID_TIME_ASC',
  OnboardingFeePaidTimeDesc = 'ONBOARDING_FEE_PAID_TIME_DESC',
  OnboardingTypeAsc = 'ONBOARDING_TYPE_ASC',
  OnboardingTypeDesc = 'ONBOARDING_TYPE_DESC',
  OrganizationIdAsc = 'ORGANIZATION_ID_ASC',
  OrganizationIdDesc = 'ORGANIZATION_ID_DESC',
  PaymentMethodAsc = 'PAYMENT_METHOD_ASC',
  PaymentMethodDesc = 'PAYMENT_METHOD_DESC',
  PersonalisationRankingsAsc = 'PERSONALISATION_RANKINGS_ASC',
  PersonalisationRankingsDesc = 'PERSONALISATION_RANKINGS_DESC',
  PersonalisationStatisticsByLawyerIdAverageCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_AVERAGE_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByLawyerIdAverageCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_AVERAGE_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByLawyerIdAverageIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  PersonalisationStatisticsByLawyerIdAverageIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  PersonalisationStatisticsByLawyerIdAverageLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  PersonalisationStatisticsByLawyerIdAverageLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  PersonalisationStatisticsByLawyerIdAveragePersValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_AVERAGE_PERS_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdAveragePersValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_AVERAGE_PERS_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdAverageTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_AVERAGE_TYPE_ID_ASC',
  PersonalisationStatisticsByLawyerIdAverageTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_AVERAGE_TYPE_ID_DESC',
  PersonalisationStatisticsByLawyerIdAverageValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_AVERAGE_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdAverageValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_AVERAGE_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdCountAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_COUNT_ASC',
  PersonalisationStatisticsByLawyerIdCountDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_COUNT_DESC',
  PersonalisationStatisticsByLawyerIdDistinctCountCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByLawyerIdDistinctCountCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByLawyerIdDistinctCountIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  PersonalisationStatisticsByLawyerIdDistinctCountIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  PersonalisationStatisticsByLawyerIdDistinctCountLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  PersonalisationStatisticsByLawyerIdDistinctCountLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  PersonalisationStatisticsByLawyerIdDistinctCountPersValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_PERS_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdDistinctCountPersValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_PERS_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdDistinctCountTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_TYPE_ID_ASC',
  PersonalisationStatisticsByLawyerIdDistinctCountTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_TYPE_ID_DESC',
  PersonalisationStatisticsByLawyerIdDistinctCountValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdDistinctCountValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_DISTINCT_COUNT_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdMaxCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MAX_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByLawyerIdMaxCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MAX_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByLawyerIdMaxIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MAX_ID_ASC',
  PersonalisationStatisticsByLawyerIdMaxIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MAX_ID_DESC',
  PersonalisationStatisticsByLawyerIdMaxLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  PersonalisationStatisticsByLawyerIdMaxLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  PersonalisationStatisticsByLawyerIdMaxPersValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MAX_PERS_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdMaxPersValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MAX_PERS_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdMaxTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MAX_TYPE_ID_ASC',
  PersonalisationStatisticsByLawyerIdMaxTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MAX_TYPE_ID_DESC',
  PersonalisationStatisticsByLawyerIdMaxValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MAX_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdMaxValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MAX_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdMinCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MIN_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByLawyerIdMinCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MIN_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByLawyerIdMinIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MIN_ID_ASC',
  PersonalisationStatisticsByLawyerIdMinIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MIN_ID_DESC',
  PersonalisationStatisticsByLawyerIdMinLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  PersonalisationStatisticsByLawyerIdMinLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  PersonalisationStatisticsByLawyerIdMinPersValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MIN_PERS_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdMinPersValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MIN_PERS_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdMinTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MIN_TYPE_ID_ASC',
  PersonalisationStatisticsByLawyerIdMinTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MIN_TYPE_ID_DESC',
  PersonalisationStatisticsByLawyerIdMinValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MIN_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdMinValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_MIN_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdStddevPopulationCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByLawyerIdStddevPopulationCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByLawyerIdStddevPopulationIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  PersonalisationStatisticsByLawyerIdStddevPopulationIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  PersonalisationStatisticsByLawyerIdStddevPopulationLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  PersonalisationStatisticsByLawyerIdStddevPopulationLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  PersonalisationStatisticsByLawyerIdStddevPopulationPersValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_PERS_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdStddevPopulationPersValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_PERS_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdStddevPopulationTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_TYPE_ID_ASC',
  PersonalisationStatisticsByLawyerIdStddevPopulationTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_TYPE_ID_DESC',
  PersonalisationStatisticsByLawyerIdStddevPopulationValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdStddevPopulationValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_POPULATION_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdStddevSampleCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByLawyerIdStddevSampleCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByLawyerIdStddevSampleIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  PersonalisationStatisticsByLawyerIdStddevSampleIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  PersonalisationStatisticsByLawyerIdStddevSampleLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  PersonalisationStatisticsByLawyerIdStddevSampleLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  PersonalisationStatisticsByLawyerIdStddevSamplePersValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_PERS_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdStddevSamplePersValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_PERS_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdStddevSampleTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_TYPE_ID_ASC',
  PersonalisationStatisticsByLawyerIdStddevSampleTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_TYPE_ID_DESC',
  PersonalisationStatisticsByLawyerIdStddevSampleValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdStddevSampleValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_STDDEV_SAMPLE_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdSumCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_SUM_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByLawyerIdSumCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_SUM_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByLawyerIdSumIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_SUM_ID_ASC',
  PersonalisationStatisticsByLawyerIdSumIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_SUM_ID_DESC',
  PersonalisationStatisticsByLawyerIdSumLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  PersonalisationStatisticsByLawyerIdSumLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  PersonalisationStatisticsByLawyerIdSumPersValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_SUM_PERS_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdSumPersValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_SUM_PERS_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdSumTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_SUM_TYPE_ID_ASC',
  PersonalisationStatisticsByLawyerIdSumTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_SUM_TYPE_ID_DESC',
  PersonalisationStatisticsByLawyerIdSumValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_SUM_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdSumValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_SUM_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdVariancePopulationCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByLawyerIdVariancePopulationCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByLawyerIdVariancePopulationIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  PersonalisationStatisticsByLawyerIdVariancePopulationIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  PersonalisationStatisticsByLawyerIdVariancePopulationLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  PersonalisationStatisticsByLawyerIdVariancePopulationLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  PersonalisationStatisticsByLawyerIdVariancePopulationPersValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_PERS_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdVariancePopulationPersValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_PERS_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdVariancePopulationTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_TYPE_ID_ASC',
  PersonalisationStatisticsByLawyerIdVariancePopulationTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_TYPE_ID_DESC',
  PersonalisationStatisticsByLawyerIdVariancePopulationValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdVariancePopulationValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_POPULATION_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdVarianceSampleCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByLawyerIdVarianceSampleCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByLawyerIdVarianceSampleIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  PersonalisationStatisticsByLawyerIdVarianceSampleIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  PersonalisationStatisticsByLawyerIdVarianceSampleLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  PersonalisationStatisticsByLawyerIdVarianceSampleLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  PersonalisationStatisticsByLawyerIdVarianceSamplePersValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_PERS_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdVarianceSamplePersValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_PERS_VALUE_DESC',
  PersonalisationStatisticsByLawyerIdVarianceSampleTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_TYPE_ID_ASC',
  PersonalisationStatisticsByLawyerIdVarianceSampleTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_TYPE_ID_DESC',
  PersonalisationStatisticsByLawyerIdVarianceSampleValueAsc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_VALUE_ASC',
  PersonalisationStatisticsByLawyerIdVarianceSampleValueDesc = 'PERSONALISATION_STATISTICS_BY_LAWYER_ID_VARIANCE_SAMPLE_VALUE_DESC',
  PostalCodeAsc = 'POSTAL_CODE_ASC',
  PostalCodeDesc = 'POSTAL_CODE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC',
  ProfilesByLawyerIdAverageBioAsc = 'PROFILES_BY_LAWYER_ID_AVERAGE_BIO_ASC',
  ProfilesByLawyerIdAverageBioDesc = 'PROFILES_BY_LAWYER_ID_AVERAGE_BIO_DESC',
  ProfilesByLawyerIdAverageCreatedTimeAsc = 'PROFILES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  ProfilesByLawyerIdAverageCreatedTimeDesc = 'PROFILES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  ProfilesByLawyerIdAverageEmailAsc = 'PROFILES_BY_LAWYER_ID_AVERAGE_EMAIL_ASC',
  ProfilesByLawyerIdAverageEmailDesc = 'PROFILES_BY_LAWYER_ID_AVERAGE_EMAIL_DESC',
  ProfilesByLawyerIdAverageFirmHistoryAsc = 'PROFILES_BY_LAWYER_ID_AVERAGE_FIRM_HISTORY_ASC',
  ProfilesByLawyerIdAverageFirmHistoryDesc = 'PROFILES_BY_LAWYER_ID_AVERAGE_FIRM_HISTORY_DESC',
  ProfilesByLawyerIdAverageFirstNameAsc = 'PROFILES_BY_LAWYER_ID_AVERAGE_FIRST_NAME_ASC',
  ProfilesByLawyerIdAverageFirstNameDesc = 'PROFILES_BY_LAWYER_ID_AVERAGE_FIRST_NAME_DESC',
  ProfilesByLawyerIdAverageIdAsc = 'PROFILES_BY_LAWYER_ID_AVERAGE_ID_ASC',
  ProfilesByLawyerIdAverageIdDesc = 'PROFILES_BY_LAWYER_ID_AVERAGE_ID_DESC',
  ProfilesByLawyerIdAverageLastNameAsc = 'PROFILES_BY_LAWYER_ID_AVERAGE_LAST_NAME_ASC',
  ProfilesByLawyerIdAverageLastNameDesc = 'PROFILES_BY_LAWYER_ID_AVERAGE_LAST_NAME_DESC',
  ProfilesByLawyerIdAverageLawyerIdAsc = 'PROFILES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  ProfilesByLawyerIdAverageLawyerIdDesc = 'PROFILES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  ProfilesByLawyerIdAveragePhoneNumberAsc = 'PROFILES_BY_LAWYER_ID_AVERAGE_PHONE_NUMBER_ASC',
  ProfilesByLawyerIdAveragePhoneNumberDesc = 'PROFILES_BY_LAWYER_ID_AVERAGE_PHONE_NUMBER_DESC',
  ProfilesByLawyerIdAveragePhotoUrlAsc = 'PROFILES_BY_LAWYER_ID_AVERAGE_PHOTO_URL_ASC',
  ProfilesByLawyerIdAveragePhotoUrlDesc = 'PROFILES_BY_LAWYER_ID_AVERAGE_PHOTO_URL_DESC',
  ProfilesByLawyerIdAverageSlugAsc = 'PROFILES_BY_LAWYER_ID_AVERAGE_SLUG_ASC',
  ProfilesByLawyerIdAverageSlugDesc = 'PROFILES_BY_LAWYER_ID_AVERAGE_SLUG_DESC',
  ProfilesByLawyerIdAverageUserIdAsc = 'PROFILES_BY_LAWYER_ID_AVERAGE_USER_ID_ASC',
  ProfilesByLawyerIdAverageUserIdDesc = 'PROFILES_BY_LAWYER_ID_AVERAGE_USER_ID_DESC',
  ProfilesByLawyerIdAverageWebsiteUrlAsc = 'PROFILES_BY_LAWYER_ID_AVERAGE_WEBSITE_URL_ASC',
  ProfilesByLawyerIdAverageWebsiteUrlDesc = 'PROFILES_BY_LAWYER_ID_AVERAGE_WEBSITE_URL_DESC',
  ProfilesByLawyerIdCountAsc = 'PROFILES_BY_LAWYER_ID_COUNT_ASC',
  ProfilesByLawyerIdCountDesc = 'PROFILES_BY_LAWYER_ID_COUNT_DESC',
  ProfilesByLawyerIdDistinctCountBioAsc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_BIO_ASC',
  ProfilesByLawyerIdDistinctCountBioDesc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_BIO_DESC',
  ProfilesByLawyerIdDistinctCountCreatedTimeAsc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  ProfilesByLawyerIdDistinctCountCreatedTimeDesc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  ProfilesByLawyerIdDistinctCountEmailAsc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_ASC',
  ProfilesByLawyerIdDistinctCountEmailDesc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_DESC',
  ProfilesByLawyerIdDistinctCountFirmHistoryAsc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_FIRM_HISTORY_ASC',
  ProfilesByLawyerIdDistinctCountFirmHistoryDesc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_FIRM_HISTORY_DESC',
  ProfilesByLawyerIdDistinctCountFirstNameAsc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_FIRST_NAME_ASC',
  ProfilesByLawyerIdDistinctCountFirstNameDesc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_FIRST_NAME_DESC',
  ProfilesByLawyerIdDistinctCountIdAsc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  ProfilesByLawyerIdDistinctCountIdDesc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  ProfilesByLawyerIdDistinctCountLastNameAsc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_LAST_NAME_ASC',
  ProfilesByLawyerIdDistinctCountLastNameDesc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_LAST_NAME_DESC',
  ProfilesByLawyerIdDistinctCountLawyerIdAsc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  ProfilesByLawyerIdDistinctCountLawyerIdDesc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  ProfilesByLawyerIdDistinctCountPhoneNumberAsc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PHONE_NUMBER_ASC',
  ProfilesByLawyerIdDistinctCountPhoneNumberDesc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PHONE_NUMBER_DESC',
  ProfilesByLawyerIdDistinctCountPhotoUrlAsc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PHOTO_URL_ASC',
  ProfilesByLawyerIdDistinctCountPhotoUrlDesc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PHOTO_URL_DESC',
  ProfilesByLawyerIdDistinctCountSlugAsc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_SLUG_ASC',
  ProfilesByLawyerIdDistinctCountSlugDesc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_SLUG_DESC',
  ProfilesByLawyerIdDistinctCountUserIdAsc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_USER_ID_ASC',
  ProfilesByLawyerIdDistinctCountUserIdDesc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_USER_ID_DESC',
  ProfilesByLawyerIdDistinctCountWebsiteUrlAsc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_WEBSITE_URL_ASC',
  ProfilesByLawyerIdDistinctCountWebsiteUrlDesc = 'PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_WEBSITE_URL_DESC',
  ProfilesByLawyerIdMaxBioAsc = 'PROFILES_BY_LAWYER_ID_MAX_BIO_ASC',
  ProfilesByLawyerIdMaxBioDesc = 'PROFILES_BY_LAWYER_ID_MAX_BIO_DESC',
  ProfilesByLawyerIdMaxCreatedTimeAsc = 'PROFILES_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  ProfilesByLawyerIdMaxCreatedTimeDesc = 'PROFILES_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  ProfilesByLawyerIdMaxEmailAsc = 'PROFILES_BY_LAWYER_ID_MAX_EMAIL_ASC',
  ProfilesByLawyerIdMaxEmailDesc = 'PROFILES_BY_LAWYER_ID_MAX_EMAIL_DESC',
  ProfilesByLawyerIdMaxFirmHistoryAsc = 'PROFILES_BY_LAWYER_ID_MAX_FIRM_HISTORY_ASC',
  ProfilesByLawyerIdMaxFirmHistoryDesc = 'PROFILES_BY_LAWYER_ID_MAX_FIRM_HISTORY_DESC',
  ProfilesByLawyerIdMaxFirstNameAsc = 'PROFILES_BY_LAWYER_ID_MAX_FIRST_NAME_ASC',
  ProfilesByLawyerIdMaxFirstNameDesc = 'PROFILES_BY_LAWYER_ID_MAX_FIRST_NAME_DESC',
  ProfilesByLawyerIdMaxIdAsc = 'PROFILES_BY_LAWYER_ID_MAX_ID_ASC',
  ProfilesByLawyerIdMaxIdDesc = 'PROFILES_BY_LAWYER_ID_MAX_ID_DESC',
  ProfilesByLawyerIdMaxLastNameAsc = 'PROFILES_BY_LAWYER_ID_MAX_LAST_NAME_ASC',
  ProfilesByLawyerIdMaxLastNameDesc = 'PROFILES_BY_LAWYER_ID_MAX_LAST_NAME_DESC',
  ProfilesByLawyerIdMaxLawyerIdAsc = 'PROFILES_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  ProfilesByLawyerIdMaxLawyerIdDesc = 'PROFILES_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  ProfilesByLawyerIdMaxPhoneNumberAsc = 'PROFILES_BY_LAWYER_ID_MAX_PHONE_NUMBER_ASC',
  ProfilesByLawyerIdMaxPhoneNumberDesc = 'PROFILES_BY_LAWYER_ID_MAX_PHONE_NUMBER_DESC',
  ProfilesByLawyerIdMaxPhotoUrlAsc = 'PROFILES_BY_LAWYER_ID_MAX_PHOTO_URL_ASC',
  ProfilesByLawyerIdMaxPhotoUrlDesc = 'PROFILES_BY_LAWYER_ID_MAX_PHOTO_URL_DESC',
  ProfilesByLawyerIdMaxSlugAsc = 'PROFILES_BY_LAWYER_ID_MAX_SLUG_ASC',
  ProfilesByLawyerIdMaxSlugDesc = 'PROFILES_BY_LAWYER_ID_MAX_SLUG_DESC',
  ProfilesByLawyerIdMaxUserIdAsc = 'PROFILES_BY_LAWYER_ID_MAX_USER_ID_ASC',
  ProfilesByLawyerIdMaxUserIdDesc = 'PROFILES_BY_LAWYER_ID_MAX_USER_ID_DESC',
  ProfilesByLawyerIdMaxWebsiteUrlAsc = 'PROFILES_BY_LAWYER_ID_MAX_WEBSITE_URL_ASC',
  ProfilesByLawyerIdMaxWebsiteUrlDesc = 'PROFILES_BY_LAWYER_ID_MAX_WEBSITE_URL_DESC',
  ProfilesByLawyerIdMinBioAsc = 'PROFILES_BY_LAWYER_ID_MIN_BIO_ASC',
  ProfilesByLawyerIdMinBioDesc = 'PROFILES_BY_LAWYER_ID_MIN_BIO_DESC',
  ProfilesByLawyerIdMinCreatedTimeAsc = 'PROFILES_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  ProfilesByLawyerIdMinCreatedTimeDesc = 'PROFILES_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  ProfilesByLawyerIdMinEmailAsc = 'PROFILES_BY_LAWYER_ID_MIN_EMAIL_ASC',
  ProfilesByLawyerIdMinEmailDesc = 'PROFILES_BY_LAWYER_ID_MIN_EMAIL_DESC',
  ProfilesByLawyerIdMinFirmHistoryAsc = 'PROFILES_BY_LAWYER_ID_MIN_FIRM_HISTORY_ASC',
  ProfilesByLawyerIdMinFirmHistoryDesc = 'PROFILES_BY_LAWYER_ID_MIN_FIRM_HISTORY_DESC',
  ProfilesByLawyerIdMinFirstNameAsc = 'PROFILES_BY_LAWYER_ID_MIN_FIRST_NAME_ASC',
  ProfilesByLawyerIdMinFirstNameDesc = 'PROFILES_BY_LAWYER_ID_MIN_FIRST_NAME_DESC',
  ProfilesByLawyerIdMinIdAsc = 'PROFILES_BY_LAWYER_ID_MIN_ID_ASC',
  ProfilesByLawyerIdMinIdDesc = 'PROFILES_BY_LAWYER_ID_MIN_ID_DESC',
  ProfilesByLawyerIdMinLastNameAsc = 'PROFILES_BY_LAWYER_ID_MIN_LAST_NAME_ASC',
  ProfilesByLawyerIdMinLastNameDesc = 'PROFILES_BY_LAWYER_ID_MIN_LAST_NAME_DESC',
  ProfilesByLawyerIdMinLawyerIdAsc = 'PROFILES_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  ProfilesByLawyerIdMinLawyerIdDesc = 'PROFILES_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  ProfilesByLawyerIdMinPhoneNumberAsc = 'PROFILES_BY_LAWYER_ID_MIN_PHONE_NUMBER_ASC',
  ProfilesByLawyerIdMinPhoneNumberDesc = 'PROFILES_BY_LAWYER_ID_MIN_PHONE_NUMBER_DESC',
  ProfilesByLawyerIdMinPhotoUrlAsc = 'PROFILES_BY_LAWYER_ID_MIN_PHOTO_URL_ASC',
  ProfilesByLawyerIdMinPhotoUrlDesc = 'PROFILES_BY_LAWYER_ID_MIN_PHOTO_URL_DESC',
  ProfilesByLawyerIdMinSlugAsc = 'PROFILES_BY_LAWYER_ID_MIN_SLUG_ASC',
  ProfilesByLawyerIdMinSlugDesc = 'PROFILES_BY_LAWYER_ID_MIN_SLUG_DESC',
  ProfilesByLawyerIdMinUserIdAsc = 'PROFILES_BY_LAWYER_ID_MIN_USER_ID_ASC',
  ProfilesByLawyerIdMinUserIdDesc = 'PROFILES_BY_LAWYER_ID_MIN_USER_ID_DESC',
  ProfilesByLawyerIdMinWebsiteUrlAsc = 'PROFILES_BY_LAWYER_ID_MIN_WEBSITE_URL_ASC',
  ProfilesByLawyerIdMinWebsiteUrlDesc = 'PROFILES_BY_LAWYER_ID_MIN_WEBSITE_URL_DESC',
  ProfilesByLawyerIdStddevPopulationBioAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_BIO_ASC',
  ProfilesByLawyerIdStddevPopulationBioDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_BIO_DESC',
  ProfilesByLawyerIdStddevPopulationCreatedTimeAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  ProfilesByLawyerIdStddevPopulationCreatedTimeDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  ProfilesByLawyerIdStddevPopulationEmailAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_ASC',
  ProfilesByLawyerIdStddevPopulationEmailDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_DESC',
  ProfilesByLawyerIdStddevPopulationFirmHistoryAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_FIRM_HISTORY_ASC',
  ProfilesByLawyerIdStddevPopulationFirmHistoryDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_FIRM_HISTORY_DESC',
  ProfilesByLawyerIdStddevPopulationFirstNameAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_FIRST_NAME_ASC',
  ProfilesByLawyerIdStddevPopulationFirstNameDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_FIRST_NAME_DESC',
  ProfilesByLawyerIdStddevPopulationIdAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  ProfilesByLawyerIdStddevPopulationIdDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  ProfilesByLawyerIdStddevPopulationLastNameAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_LAST_NAME_ASC',
  ProfilesByLawyerIdStddevPopulationLastNameDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_LAST_NAME_DESC',
  ProfilesByLawyerIdStddevPopulationLawyerIdAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  ProfilesByLawyerIdStddevPopulationLawyerIdDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  ProfilesByLawyerIdStddevPopulationPhoneNumberAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PHONE_NUMBER_ASC',
  ProfilesByLawyerIdStddevPopulationPhoneNumberDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PHONE_NUMBER_DESC',
  ProfilesByLawyerIdStddevPopulationPhotoUrlAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PHOTO_URL_ASC',
  ProfilesByLawyerIdStddevPopulationPhotoUrlDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PHOTO_URL_DESC',
  ProfilesByLawyerIdStddevPopulationSlugAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_SLUG_ASC',
  ProfilesByLawyerIdStddevPopulationSlugDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_SLUG_DESC',
  ProfilesByLawyerIdStddevPopulationUserIdAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_USER_ID_ASC',
  ProfilesByLawyerIdStddevPopulationUserIdDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_USER_ID_DESC',
  ProfilesByLawyerIdStddevPopulationWebsiteUrlAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_WEBSITE_URL_ASC',
  ProfilesByLawyerIdStddevPopulationWebsiteUrlDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_WEBSITE_URL_DESC',
  ProfilesByLawyerIdStddevSampleBioAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_BIO_ASC',
  ProfilesByLawyerIdStddevSampleBioDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_BIO_DESC',
  ProfilesByLawyerIdStddevSampleCreatedTimeAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  ProfilesByLawyerIdStddevSampleCreatedTimeDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  ProfilesByLawyerIdStddevSampleEmailAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_ASC',
  ProfilesByLawyerIdStddevSampleEmailDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_DESC',
  ProfilesByLawyerIdStddevSampleFirmHistoryAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_FIRM_HISTORY_ASC',
  ProfilesByLawyerIdStddevSampleFirmHistoryDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_FIRM_HISTORY_DESC',
  ProfilesByLawyerIdStddevSampleFirstNameAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_FIRST_NAME_ASC',
  ProfilesByLawyerIdStddevSampleFirstNameDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_FIRST_NAME_DESC',
  ProfilesByLawyerIdStddevSampleIdAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  ProfilesByLawyerIdStddevSampleIdDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  ProfilesByLawyerIdStddevSampleLastNameAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_LAST_NAME_ASC',
  ProfilesByLawyerIdStddevSampleLastNameDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_LAST_NAME_DESC',
  ProfilesByLawyerIdStddevSampleLawyerIdAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  ProfilesByLawyerIdStddevSampleLawyerIdDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  ProfilesByLawyerIdStddevSamplePhoneNumberAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PHONE_NUMBER_ASC',
  ProfilesByLawyerIdStddevSamplePhoneNumberDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PHONE_NUMBER_DESC',
  ProfilesByLawyerIdStddevSamplePhotoUrlAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PHOTO_URL_ASC',
  ProfilesByLawyerIdStddevSamplePhotoUrlDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PHOTO_URL_DESC',
  ProfilesByLawyerIdStddevSampleSlugAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_SLUG_ASC',
  ProfilesByLawyerIdStddevSampleSlugDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_SLUG_DESC',
  ProfilesByLawyerIdStddevSampleUserIdAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_USER_ID_ASC',
  ProfilesByLawyerIdStddevSampleUserIdDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_USER_ID_DESC',
  ProfilesByLawyerIdStddevSampleWebsiteUrlAsc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_WEBSITE_URL_ASC',
  ProfilesByLawyerIdStddevSampleWebsiteUrlDesc = 'PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_WEBSITE_URL_DESC',
  ProfilesByLawyerIdSumBioAsc = 'PROFILES_BY_LAWYER_ID_SUM_BIO_ASC',
  ProfilesByLawyerIdSumBioDesc = 'PROFILES_BY_LAWYER_ID_SUM_BIO_DESC',
  ProfilesByLawyerIdSumCreatedTimeAsc = 'PROFILES_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  ProfilesByLawyerIdSumCreatedTimeDesc = 'PROFILES_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  ProfilesByLawyerIdSumEmailAsc = 'PROFILES_BY_LAWYER_ID_SUM_EMAIL_ASC',
  ProfilesByLawyerIdSumEmailDesc = 'PROFILES_BY_LAWYER_ID_SUM_EMAIL_DESC',
  ProfilesByLawyerIdSumFirmHistoryAsc = 'PROFILES_BY_LAWYER_ID_SUM_FIRM_HISTORY_ASC',
  ProfilesByLawyerIdSumFirmHistoryDesc = 'PROFILES_BY_LAWYER_ID_SUM_FIRM_HISTORY_DESC',
  ProfilesByLawyerIdSumFirstNameAsc = 'PROFILES_BY_LAWYER_ID_SUM_FIRST_NAME_ASC',
  ProfilesByLawyerIdSumFirstNameDesc = 'PROFILES_BY_LAWYER_ID_SUM_FIRST_NAME_DESC',
  ProfilesByLawyerIdSumIdAsc = 'PROFILES_BY_LAWYER_ID_SUM_ID_ASC',
  ProfilesByLawyerIdSumIdDesc = 'PROFILES_BY_LAWYER_ID_SUM_ID_DESC',
  ProfilesByLawyerIdSumLastNameAsc = 'PROFILES_BY_LAWYER_ID_SUM_LAST_NAME_ASC',
  ProfilesByLawyerIdSumLastNameDesc = 'PROFILES_BY_LAWYER_ID_SUM_LAST_NAME_DESC',
  ProfilesByLawyerIdSumLawyerIdAsc = 'PROFILES_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  ProfilesByLawyerIdSumLawyerIdDesc = 'PROFILES_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  ProfilesByLawyerIdSumPhoneNumberAsc = 'PROFILES_BY_LAWYER_ID_SUM_PHONE_NUMBER_ASC',
  ProfilesByLawyerIdSumPhoneNumberDesc = 'PROFILES_BY_LAWYER_ID_SUM_PHONE_NUMBER_DESC',
  ProfilesByLawyerIdSumPhotoUrlAsc = 'PROFILES_BY_LAWYER_ID_SUM_PHOTO_URL_ASC',
  ProfilesByLawyerIdSumPhotoUrlDesc = 'PROFILES_BY_LAWYER_ID_SUM_PHOTO_URL_DESC',
  ProfilesByLawyerIdSumSlugAsc = 'PROFILES_BY_LAWYER_ID_SUM_SLUG_ASC',
  ProfilesByLawyerIdSumSlugDesc = 'PROFILES_BY_LAWYER_ID_SUM_SLUG_DESC',
  ProfilesByLawyerIdSumUserIdAsc = 'PROFILES_BY_LAWYER_ID_SUM_USER_ID_ASC',
  ProfilesByLawyerIdSumUserIdDesc = 'PROFILES_BY_LAWYER_ID_SUM_USER_ID_DESC',
  ProfilesByLawyerIdSumWebsiteUrlAsc = 'PROFILES_BY_LAWYER_ID_SUM_WEBSITE_URL_ASC',
  ProfilesByLawyerIdSumWebsiteUrlDesc = 'PROFILES_BY_LAWYER_ID_SUM_WEBSITE_URL_DESC',
  ProfilesByLawyerIdVariancePopulationBioAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_BIO_ASC',
  ProfilesByLawyerIdVariancePopulationBioDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_BIO_DESC',
  ProfilesByLawyerIdVariancePopulationCreatedTimeAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  ProfilesByLawyerIdVariancePopulationCreatedTimeDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  ProfilesByLawyerIdVariancePopulationEmailAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_ASC',
  ProfilesByLawyerIdVariancePopulationEmailDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_DESC',
  ProfilesByLawyerIdVariancePopulationFirmHistoryAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_FIRM_HISTORY_ASC',
  ProfilesByLawyerIdVariancePopulationFirmHistoryDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_FIRM_HISTORY_DESC',
  ProfilesByLawyerIdVariancePopulationFirstNameAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_FIRST_NAME_ASC',
  ProfilesByLawyerIdVariancePopulationFirstNameDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_FIRST_NAME_DESC',
  ProfilesByLawyerIdVariancePopulationIdAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  ProfilesByLawyerIdVariancePopulationIdDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  ProfilesByLawyerIdVariancePopulationLastNameAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_LAST_NAME_ASC',
  ProfilesByLawyerIdVariancePopulationLastNameDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_LAST_NAME_DESC',
  ProfilesByLawyerIdVariancePopulationLawyerIdAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  ProfilesByLawyerIdVariancePopulationLawyerIdDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  ProfilesByLawyerIdVariancePopulationPhoneNumberAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PHONE_NUMBER_ASC',
  ProfilesByLawyerIdVariancePopulationPhoneNumberDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PHONE_NUMBER_DESC',
  ProfilesByLawyerIdVariancePopulationPhotoUrlAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PHOTO_URL_ASC',
  ProfilesByLawyerIdVariancePopulationPhotoUrlDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PHOTO_URL_DESC',
  ProfilesByLawyerIdVariancePopulationSlugAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_SLUG_ASC',
  ProfilesByLawyerIdVariancePopulationSlugDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_SLUG_DESC',
  ProfilesByLawyerIdVariancePopulationUserIdAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_USER_ID_ASC',
  ProfilesByLawyerIdVariancePopulationUserIdDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_USER_ID_DESC',
  ProfilesByLawyerIdVariancePopulationWebsiteUrlAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_WEBSITE_URL_ASC',
  ProfilesByLawyerIdVariancePopulationWebsiteUrlDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_WEBSITE_URL_DESC',
  ProfilesByLawyerIdVarianceSampleBioAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_BIO_ASC',
  ProfilesByLawyerIdVarianceSampleBioDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_BIO_DESC',
  ProfilesByLawyerIdVarianceSampleCreatedTimeAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  ProfilesByLawyerIdVarianceSampleCreatedTimeDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  ProfilesByLawyerIdVarianceSampleEmailAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_ASC',
  ProfilesByLawyerIdVarianceSampleEmailDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_DESC',
  ProfilesByLawyerIdVarianceSampleFirmHistoryAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_FIRM_HISTORY_ASC',
  ProfilesByLawyerIdVarianceSampleFirmHistoryDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_FIRM_HISTORY_DESC',
  ProfilesByLawyerIdVarianceSampleFirstNameAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_FIRST_NAME_ASC',
  ProfilesByLawyerIdVarianceSampleFirstNameDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_FIRST_NAME_DESC',
  ProfilesByLawyerIdVarianceSampleIdAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  ProfilesByLawyerIdVarianceSampleIdDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  ProfilesByLawyerIdVarianceSampleLastNameAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAST_NAME_ASC',
  ProfilesByLawyerIdVarianceSampleLastNameDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAST_NAME_DESC',
  ProfilesByLawyerIdVarianceSampleLawyerIdAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  ProfilesByLawyerIdVarianceSampleLawyerIdDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  ProfilesByLawyerIdVarianceSamplePhoneNumberAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PHONE_NUMBER_ASC',
  ProfilesByLawyerIdVarianceSamplePhoneNumberDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PHONE_NUMBER_DESC',
  ProfilesByLawyerIdVarianceSamplePhotoUrlAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PHOTO_URL_ASC',
  ProfilesByLawyerIdVarianceSamplePhotoUrlDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PHOTO_URL_DESC',
  ProfilesByLawyerIdVarianceSampleSlugAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_SLUG_ASC',
  ProfilesByLawyerIdVarianceSampleSlugDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_SLUG_DESC',
  ProfilesByLawyerIdVarianceSampleUserIdAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_USER_ID_ASC',
  ProfilesByLawyerIdVarianceSampleUserIdDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_USER_ID_DESC',
  ProfilesByLawyerIdVarianceSampleWebsiteUrlAsc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_WEBSITE_URL_ASC',
  ProfilesByLawyerIdVarianceSampleWebsiteUrlDesc = 'PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_WEBSITE_URL_DESC',
  ProxyIdAsc = 'PROXY_ID_ASC',
  ProxyIdDesc = 'PROXY_ID_DESC',
  PublicIdAsc = 'PUBLIC_ID_ASC',
  PublicIdDesc = 'PUBLIC_ID_DESC',
  ReferralsByReferredLawyerIdAverageCreatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  ReferralsByReferredLawyerIdAverageCreatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  ReferralsByReferredLawyerIdAverageEmailAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_EMAIL_ASC',
  ReferralsByReferredLawyerIdAverageEmailDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_EMAIL_DESC',
  ReferralsByReferredLawyerIdAverageFirmNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_FIRM_NAME_ASC',
  ReferralsByReferredLawyerIdAverageFirmNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_FIRM_NAME_DESC',
  ReferralsByReferredLawyerIdAverageIcpAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_ICP_ASC',
  ReferralsByReferredLawyerIdAverageIcpDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_ICP_DESC',
  ReferralsByReferredLawyerIdAverageIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_ID_ASC',
  ReferralsByReferredLawyerIdAverageIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_ID_DESC',
  ReferralsByReferredLawyerIdAverageNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_NAME_ASC',
  ReferralsByReferredLawyerIdAverageNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_NAME_DESC',
  ReferralsByReferredLawyerIdAveragePhoneNumberAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_PHONE_NUMBER_ASC',
  ReferralsByReferredLawyerIdAveragePhoneNumberDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_PHONE_NUMBER_DESC',
  ReferralsByReferredLawyerIdAverageReferredLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdAverageReferredLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdAverageReferringLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdAverageReferringLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdAverageSocialProofAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_SOCIAL_PROOF_ASC',
  ReferralsByReferredLawyerIdAverageSocialProofDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_SOCIAL_PROOF_DESC',
  ReferralsByReferredLawyerIdAverageStatusAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_STATUS_ASC',
  ReferralsByReferredLawyerIdAverageStatusDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_STATUS_DESC',
  ReferralsByReferredLawyerIdAverageUpdatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  ReferralsByReferredLawyerIdAverageUpdatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  ReferralsByReferredLawyerIdAverageValuePropositionAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_VALUE_PROPOSITION_ASC',
  ReferralsByReferredLawyerIdAverageValuePropositionDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_AVERAGE_VALUE_PROPOSITION_DESC',
  ReferralsByReferredLawyerIdCountAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_COUNT_ASC',
  ReferralsByReferredLawyerIdCountDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_COUNT_DESC',
  ReferralsByReferredLawyerIdDistinctCountCreatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  ReferralsByReferredLawyerIdDistinctCountCreatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  ReferralsByReferredLawyerIdDistinctCountEmailAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_EMAIL_ASC',
  ReferralsByReferredLawyerIdDistinctCountEmailDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_EMAIL_DESC',
  ReferralsByReferredLawyerIdDistinctCountFirmNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_FIRM_NAME_ASC',
  ReferralsByReferredLawyerIdDistinctCountFirmNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_FIRM_NAME_DESC',
  ReferralsByReferredLawyerIdDistinctCountIcpAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_ICP_ASC',
  ReferralsByReferredLawyerIdDistinctCountIcpDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_ICP_DESC',
  ReferralsByReferredLawyerIdDistinctCountIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  ReferralsByReferredLawyerIdDistinctCountIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  ReferralsByReferredLawyerIdDistinctCountNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_NAME_ASC',
  ReferralsByReferredLawyerIdDistinctCountNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_NAME_DESC',
  ReferralsByReferredLawyerIdDistinctCountPhoneNumberAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_PHONE_NUMBER_ASC',
  ReferralsByReferredLawyerIdDistinctCountPhoneNumberDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_PHONE_NUMBER_DESC',
  ReferralsByReferredLawyerIdDistinctCountReferredLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdDistinctCountReferredLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdDistinctCountReferringLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdDistinctCountReferringLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdDistinctCountSocialProofAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_SOCIAL_PROOF_ASC',
  ReferralsByReferredLawyerIdDistinctCountSocialProofDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_SOCIAL_PROOF_DESC',
  ReferralsByReferredLawyerIdDistinctCountStatusAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_STATUS_ASC',
  ReferralsByReferredLawyerIdDistinctCountStatusDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_STATUS_DESC',
  ReferralsByReferredLawyerIdDistinctCountUpdatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  ReferralsByReferredLawyerIdDistinctCountUpdatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  ReferralsByReferredLawyerIdDistinctCountValuePropositionAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_VALUE_PROPOSITION_ASC',
  ReferralsByReferredLawyerIdDistinctCountValuePropositionDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_DISTINCT_COUNT_VALUE_PROPOSITION_DESC',
  ReferralsByReferredLawyerIdMaxCreatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_CREATED_TIME_ASC',
  ReferralsByReferredLawyerIdMaxCreatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_CREATED_TIME_DESC',
  ReferralsByReferredLawyerIdMaxEmailAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_EMAIL_ASC',
  ReferralsByReferredLawyerIdMaxEmailDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_EMAIL_DESC',
  ReferralsByReferredLawyerIdMaxFirmNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_FIRM_NAME_ASC',
  ReferralsByReferredLawyerIdMaxFirmNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_FIRM_NAME_DESC',
  ReferralsByReferredLawyerIdMaxIcpAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_ICP_ASC',
  ReferralsByReferredLawyerIdMaxIcpDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_ICP_DESC',
  ReferralsByReferredLawyerIdMaxIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_ID_ASC',
  ReferralsByReferredLawyerIdMaxIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_ID_DESC',
  ReferralsByReferredLawyerIdMaxNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_NAME_ASC',
  ReferralsByReferredLawyerIdMaxNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_NAME_DESC',
  ReferralsByReferredLawyerIdMaxPhoneNumberAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_PHONE_NUMBER_ASC',
  ReferralsByReferredLawyerIdMaxPhoneNumberDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_PHONE_NUMBER_DESC',
  ReferralsByReferredLawyerIdMaxReferredLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdMaxReferredLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdMaxReferringLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdMaxReferringLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdMaxSocialProofAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_SOCIAL_PROOF_ASC',
  ReferralsByReferredLawyerIdMaxSocialProofDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_SOCIAL_PROOF_DESC',
  ReferralsByReferredLawyerIdMaxStatusAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_STATUS_ASC',
  ReferralsByReferredLawyerIdMaxStatusDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_STATUS_DESC',
  ReferralsByReferredLawyerIdMaxUpdatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  ReferralsByReferredLawyerIdMaxUpdatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  ReferralsByReferredLawyerIdMaxValuePropositionAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_VALUE_PROPOSITION_ASC',
  ReferralsByReferredLawyerIdMaxValuePropositionDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MAX_VALUE_PROPOSITION_DESC',
  ReferralsByReferredLawyerIdMinCreatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_CREATED_TIME_ASC',
  ReferralsByReferredLawyerIdMinCreatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_CREATED_TIME_DESC',
  ReferralsByReferredLawyerIdMinEmailAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_EMAIL_ASC',
  ReferralsByReferredLawyerIdMinEmailDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_EMAIL_DESC',
  ReferralsByReferredLawyerIdMinFirmNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_FIRM_NAME_ASC',
  ReferralsByReferredLawyerIdMinFirmNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_FIRM_NAME_DESC',
  ReferralsByReferredLawyerIdMinIcpAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_ICP_ASC',
  ReferralsByReferredLawyerIdMinIcpDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_ICP_DESC',
  ReferralsByReferredLawyerIdMinIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_ID_ASC',
  ReferralsByReferredLawyerIdMinIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_ID_DESC',
  ReferralsByReferredLawyerIdMinNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_NAME_ASC',
  ReferralsByReferredLawyerIdMinNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_NAME_DESC',
  ReferralsByReferredLawyerIdMinPhoneNumberAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_PHONE_NUMBER_ASC',
  ReferralsByReferredLawyerIdMinPhoneNumberDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_PHONE_NUMBER_DESC',
  ReferralsByReferredLawyerIdMinReferredLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdMinReferredLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdMinReferringLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdMinReferringLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdMinSocialProofAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_SOCIAL_PROOF_ASC',
  ReferralsByReferredLawyerIdMinSocialProofDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_SOCIAL_PROOF_DESC',
  ReferralsByReferredLawyerIdMinStatusAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_STATUS_ASC',
  ReferralsByReferredLawyerIdMinStatusDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_STATUS_DESC',
  ReferralsByReferredLawyerIdMinUpdatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  ReferralsByReferredLawyerIdMinUpdatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  ReferralsByReferredLawyerIdMinValuePropositionAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_VALUE_PROPOSITION_ASC',
  ReferralsByReferredLawyerIdMinValuePropositionDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_MIN_VALUE_PROPOSITION_DESC',
  ReferralsByReferredLawyerIdStddevPopulationCreatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  ReferralsByReferredLawyerIdStddevPopulationCreatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  ReferralsByReferredLawyerIdStddevPopulationEmailAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_EMAIL_ASC',
  ReferralsByReferredLawyerIdStddevPopulationEmailDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_EMAIL_DESC',
  ReferralsByReferredLawyerIdStddevPopulationFirmNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_FIRM_NAME_ASC',
  ReferralsByReferredLawyerIdStddevPopulationFirmNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_FIRM_NAME_DESC',
  ReferralsByReferredLawyerIdStddevPopulationIcpAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_ICP_ASC',
  ReferralsByReferredLawyerIdStddevPopulationIcpDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_ICP_DESC',
  ReferralsByReferredLawyerIdStddevPopulationIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  ReferralsByReferredLawyerIdStddevPopulationIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  ReferralsByReferredLawyerIdStddevPopulationNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_NAME_ASC',
  ReferralsByReferredLawyerIdStddevPopulationNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_NAME_DESC',
  ReferralsByReferredLawyerIdStddevPopulationPhoneNumberAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_PHONE_NUMBER_ASC',
  ReferralsByReferredLawyerIdStddevPopulationPhoneNumberDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_PHONE_NUMBER_DESC',
  ReferralsByReferredLawyerIdStddevPopulationReferredLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdStddevPopulationReferredLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdStddevPopulationReferringLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdStddevPopulationReferringLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdStddevPopulationSocialProofAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_SOCIAL_PROOF_ASC',
  ReferralsByReferredLawyerIdStddevPopulationSocialProofDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_SOCIAL_PROOF_DESC',
  ReferralsByReferredLawyerIdStddevPopulationStatusAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_STATUS_ASC',
  ReferralsByReferredLawyerIdStddevPopulationStatusDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_STATUS_DESC',
  ReferralsByReferredLawyerIdStddevPopulationUpdatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  ReferralsByReferredLawyerIdStddevPopulationUpdatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  ReferralsByReferredLawyerIdStddevPopulationValuePropositionAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_VALUE_PROPOSITION_ASC',
  ReferralsByReferredLawyerIdStddevPopulationValuePropositionDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_POPULATION_VALUE_PROPOSITION_DESC',
  ReferralsByReferredLawyerIdStddevSampleCreatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  ReferralsByReferredLawyerIdStddevSampleCreatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  ReferralsByReferredLawyerIdStddevSampleEmailAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_EMAIL_ASC',
  ReferralsByReferredLawyerIdStddevSampleEmailDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_EMAIL_DESC',
  ReferralsByReferredLawyerIdStddevSampleFirmNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_FIRM_NAME_ASC',
  ReferralsByReferredLawyerIdStddevSampleFirmNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_FIRM_NAME_DESC',
  ReferralsByReferredLawyerIdStddevSampleIcpAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_ICP_ASC',
  ReferralsByReferredLawyerIdStddevSampleIcpDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_ICP_DESC',
  ReferralsByReferredLawyerIdStddevSampleIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  ReferralsByReferredLawyerIdStddevSampleIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  ReferralsByReferredLawyerIdStddevSampleNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_NAME_ASC',
  ReferralsByReferredLawyerIdStddevSampleNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_NAME_DESC',
  ReferralsByReferredLawyerIdStddevSamplePhoneNumberAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_PHONE_NUMBER_ASC',
  ReferralsByReferredLawyerIdStddevSamplePhoneNumberDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_PHONE_NUMBER_DESC',
  ReferralsByReferredLawyerIdStddevSampleReferredLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdStddevSampleReferredLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdStddevSampleReferringLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdStddevSampleReferringLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdStddevSampleSocialProofAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_SOCIAL_PROOF_ASC',
  ReferralsByReferredLawyerIdStddevSampleSocialProofDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_SOCIAL_PROOF_DESC',
  ReferralsByReferredLawyerIdStddevSampleStatusAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_STATUS_ASC',
  ReferralsByReferredLawyerIdStddevSampleStatusDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_STATUS_DESC',
  ReferralsByReferredLawyerIdStddevSampleUpdatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  ReferralsByReferredLawyerIdStddevSampleUpdatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  ReferralsByReferredLawyerIdStddevSampleValuePropositionAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_VALUE_PROPOSITION_ASC',
  ReferralsByReferredLawyerIdStddevSampleValuePropositionDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_STDDEV_SAMPLE_VALUE_PROPOSITION_DESC',
  ReferralsByReferredLawyerIdSumCreatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_CREATED_TIME_ASC',
  ReferralsByReferredLawyerIdSumCreatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_CREATED_TIME_DESC',
  ReferralsByReferredLawyerIdSumEmailAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_EMAIL_ASC',
  ReferralsByReferredLawyerIdSumEmailDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_EMAIL_DESC',
  ReferralsByReferredLawyerIdSumFirmNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_FIRM_NAME_ASC',
  ReferralsByReferredLawyerIdSumFirmNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_FIRM_NAME_DESC',
  ReferralsByReferredLawyerIdSumIcpAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_ICP_ASC',
  ReferralsByReferredLawyerIdSumIcpDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_ICP_DESC',
  ReferralsByReferredLawyerIdSumIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_ID_ASC',
  ReferralsByReferredLawyerIdSumIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_ID_DESC',
  ReferralsByReferredLawyerIdSumNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_NAME_ASC',
  ReferralsByReferredLawyerIdSumNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_NAME_DESC',
  ReferralsByReferredLawyerIdSumPhoneNumberAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_PHONE_NUMBER_ASC',
  ReferralsByReferredLawyerIdSumPhoneNumberDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_PHONE_NUMBER_DESC',
  ReferralsByReferredLawyerIdSumReferredLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdSumReferredLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdSumReferringLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdSumReferringLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdSumSocialProofAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_SOCIAL_PROOF_ASC',
  ReferralsByReferredLawyerIdSumSocialProofDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_SOCIAL_PROOF_DESC',
  ReferralsByReferredLawyerIdSumStatusAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_STATUS_ASC',
  ReferralsByReferredLawyerIdSumStatusDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_STATUS_DESC',
  ReferralsByReferredLawyerIdSumUpdatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  ReferralsByReferredLawyerIdSumUpdatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  ReferralsByReferredLawyerIdSumValuePropositionAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_VALUE_PROPOSITION_ASC',
  ReferralsByReferredLawyerIdSumValuePropositionDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_SUM_VALUE_PROPOSITION_DESC',
  ReferralsByReferredLawyerIdVariancePopulationCreatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  ReferralsByReferredLawyerIdVariancePopulationCreatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  ReferralsByReferredLawyerIdVariancePopulationEmailAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_EMAIL_ASC',
  ReferralsByReferredLawyerIdVariancePopulationEmailDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_EMAIL_DESC',
  ReferralsByReferredLawyerIdVariancePopulationFirmNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_FIRM_NAME_ASC',
  ReferralsByReferredLawyerIdVariancePopulationFirmNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_FIRM_NAME_DESC',
  ReferralsByReferredLawyerIdVariancePopulationIcpAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_ICP_ASC',
  ReferralsByReferredLawyerIdVariancePopulationIcpDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_ICP_DESC',
  ReferralsByReferredLawyerIdVariancePopulationIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  ReferralsByReferredLawyerIdVariancePopulationIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  ReferralsByReferredLawyerIdVariancePopulationNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_NAME_ASC',
  ReferralsByReferredLawyerIdVariancePopulationNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_NAME_DESC',
  ReferralsByReferredLawyerIdVariancePopulationPhoneNumberAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_PHONE_NUMBER_ASC',
  ReferralsByReferredLawyerIdVariancePopulationPhoneNumberDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_PHONE_NUMBER_DESC',
  ReferralsByReferredLawyerIdVariancePopulationReferredLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdVariancePopulationReferredLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdVariancePopulationReferringLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdVariancePopulationReferringLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdVariancePopulationSocialProofAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_SOCIAL_PROOF_ASC',
  ReferralsByReferredLawyerIdVariancePopulationSocialProofDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_SOCIAL_PROOF_DESC',
  ReferralsByReferredLawyerIdVariancePopulationStatusAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_STATUS_ASC',
  ReferralsByReferredLawyerIdVariancePopulationStatusDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_STATUS_DESC',
  ReferralsByReferredLawyerIdVariancePopulationUpdatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  ReferralsByReferredLawyerIdVariancePopulationUpdatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  ReferralsByReferredLawyerIdVariancePopulationValuePropositionAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_VALUE_PROPOSITION_ASC',
  ReferralsByReferredLawyerIdVariancePopulationValuePropositionDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_POPULATION_VALUE_PROPOSITION_DESC',
  ReferralsByReferredLawyerIdVarianceSampleCreatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  ReferralsByReferredLawyerIdVarianceSampleCreatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  ReferralsByReferredLawyerIdVarianceSampleEmailAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_ASC',
  ReferralsByReferredLawyerIdVarianceSampleEmailDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_DESC',
  ReferralsByReferredLawyerIdVarianceSampleFirmNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_FIRM_NAME_ASC',
  ReferralsByReferredLawyerIdVarianceSampleFirmNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_FIRM_NAME_DESC',
  ReferralsByReferredLawyerIdVarianceSampleIcpAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_ICP_ASC',
  ReferralsByReferredLawyerIdVarianceSampleIcpDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_ICP_DESC',
  ReferralsByReferredLawyerIdVarianceSampleIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  ReferralsByReferredLawyerIdVarianceSampleIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  ReferralsByReferredLawyerIdVarianceSampleNameAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_NAME_ASC',
  ReferralsByReferredLawyerIdVarianceSampleNameDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_NAME_DESC',
  ReferralsByReferredLawyerIdVarianceSamplePhoneNumberAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_PHONE_NUMBER_ASC',
  ReferralsByReferredLawyerIdVarianceSamplePhoneNumberDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_PHONE_NUMBER_DESC',
  ReferralsByReferredLawyerIdVarianceSampleReferredLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdVarianceSampleReferredLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdVarianceSampleReferringLawyerIdAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferredLawyerIdVarianceSampleReferringLawyerIdDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferredLawyerIdVarianceSampleSocialProofAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_SOCIAL_PROOF_ASC',
  ReferralsByReferredLawyerIdVarianceSampleSocialProofDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_SOCIAL_PROOF_DESC',
  ReferralsByReferredLawyerIdVarianceSampleStatusAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_STATUS_ASC',
  ReferralsByReferredLawyerIdVarianceSampleStatusDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_STATUS_DESC',
  ReferralsByReferredLawyerIdVarianceSampleUpdatedTimeAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  ReferralsByReferredLawyerIdVarianceSampleUpdatedTimeDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  ReferralsByReferredLawyerIdVarianceSampleValuePropositionAsc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_VALUE_PROPOSITION_ASC',
  ReferralsByReferredLawyerIdVarianceSampleValuePropositionDesc = 'REFERRALS_BY_REFERRED_LAWYER_ID_VARIANCE_SAMPLE_VALUE_PROPOSITION_DESC',
  ReferralsByReferringLawyerIdAverageCreatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  ReferralsByReferringLawyerIdAverageCreatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  ReferralsByReferringLawyerIdAverageEmailAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_EMAIL_ASC',
  ReferralsByReferringLawyerIdAverageEmailDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_EMAIL_DESC',
  ReferralsByReferringLawyerIdAverageFirmNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_FIRM_NAME_ASC',
  ReferralsByReferringLawyerIdAverageFirmNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_FIRM_NAME_DESC',
  ReferralsByReferringLawyerIdAverageIcpAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_ICP_ASC',
  ReferralsByReferringLawyerIdAverageIcpDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_ICP_DESC',
  ReferralsByReferringLawyerIdAverageIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_ID_ASC',
  ReferralsByReferringLawyerIdAverageIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_ID_DESC',
  ReferralsByReferringLawyerIdAverageNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_NAME_ASC',
  ReferralsByReferringLawyerIdAverageNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_NAME_DESC',
  ReferralsByReferringLawyerIdAveragePhoneNumberAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_PHONE_NUMBER_ASC',
  ReferralsByReferringLawyerIdAveragePhoneNumberDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_PHONE_NUMBER_DESC',
  ReferralsByReferringLawyerIdAverageReferredLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdAverageReferredLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdAverageReferringLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdAverageReferringLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdAverageSocialProofAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_SOCIAL_PROOF_ASC',
  ReferralsByReferringLawyerIdAverageSocialProofDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_SOCIAL_PROOF_DESC',
  ReferralsByReferringLawyerIdAverageStatusAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_STATUS_ASC',
  ReferralsByReferringLawyerIdAverageStatusDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_STATUS_DESC',
  ReferralsByReferringLawyerIdAverageUpdatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  ReferralsByReferringLawyerIdAverageUpdatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  ReferralsByReferringLawyerIdAverageValuePropositionAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_VALUE_PROPOSITION_ASC',
  ReferralsByReferringLawyerIdAverageValuePropositionDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_AVERAGE_VALUE_PROPOSITION_DESC',
  ReferralsByReferringLawyerIdCountAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_COUNT_ASC',
  ReferralsByReferringLawyerIdCountDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_COUNT_DESC',
  ReferralsByReferringLawyerIdDistinctCountCreatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  ReferralsByReferringLawyerIdDistinctCountCreatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  ReferralsByReferringLawyerIdDistinctCountEmailAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_EMAIL_ASC',
  ReferralsByReferringLawyerIdDistinctCountEmailDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_EMAIL_DESC',
  ReferralsByReferringLawyerIdDistinctCountFirmNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_FIRM_NAME_ASC',
  ReferralsByReferringLawyerIdDistinctCountFirmNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_FIRM_NAME_DESC',
  ReferralsByReferringLawyerIdDistinctCountIcpAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_ICP_ASC',
  ReferralsByReferringLawyerIdDistinctCountIcpDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_ICP_DESC',
  ReferralsByReferringLawyerIdDistinctCountIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  ReferralsByReferringLawyerIdDistinctCountIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  ReferralsByReferringLawyerIdDistinctCountNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_NAME_ASC',
  ReferralsByReferringLawyerIdDistinctCountNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_NAME_DESC',
  ReferralsByReferringLawyerIdDistinctCountPhoneNumberAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_PHONE_NUMBER_ASC',
  ReferralsByReferringLawyerIdDistinctCountPhoneNumberDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_PHONE_NUMBER_DESC',
  ReferralsByReferringLawyerIdDistinctCountReferredLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdDistinctCountReferredLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdDistinctCountReferringLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdDistinctCountReferringLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdDistinctCountSocialProofAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_SOCIAL_PROOF_ASC',
  ReferralsByReferringLawyerIdDistinctCountSocialProofDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_SOCIAL_PROOF_DESC',
  ReferralsByReferringLawyerIdDistinctCountStatusAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_STATUS_ASC',
  ReferralsByReferringLawyerIdDistinctCountStatusDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_STATUS_DESC',
  ReferralsByReferringLawyerIdDistinctCountUpdatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  ReferralsByReferringLawyerIdDistinctCountUpdatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  ReferralsByReferringLawyerIdDistinctCountValuePropositionAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_VALUE_PROPOSITION_ASC',
  ReferralsByReferringLawyerIdDistinctCountValuePropositionDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_DISTINCT_COUNT_VALUE_PROPOSITION_DESC',
  ReferralsByReferringLawyerIdMaxCreatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_CREATED_TIME_ASC',
  ReferralsByReferringLawyerIdMaxCreatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_CREATED_TIME_DESC',
  ReferralsByReferringLawyerIdMaxEmailAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_EMAIL_ASC',
  ReferralsByReferringLawyerIdMaxEmailDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_EMAIL_DESC',
  ReferralsByReferringLawyerIdMaxFirmNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_FIRM_NAME_ASC',
  ReferralsByReferringLawyerIdMaxFirmNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_FIRM_NAME_DESC',
  ReferralsByReferringLawyerIdMaxIcpAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_ICP_ASC',
  ReferralsByReferringLawyerIdMaxIcpDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_ICP_DESC',
  ReferralsByReferringLawyerIdMaxIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_ID_ASC',
  ReferralsByReferringLawyerIdMaxIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_ID_DESC',
  ReferralsByReferringLawyerIdMaxNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_NAME_ASC',
  ReferralsByReferringLawyerIdMaxNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_NAME_DESC',
  ReferralsByReferringLawyerIdMaxPhoneNumberAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_PHONE_NUMBER_ASC',
  ReferralsByReferringLawyerIdMaxPhoneNumberDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_PHONE_NUMBER_DESC',
  ReferralsByReferringLawyerIdMaxReferredLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdMaxReferredLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdMaxReferringLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdMaxReferringLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdMaxSocialProofAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_SOCIAL_PROOF_ASC',
  ReferralsByReferringLawyerIdMaxSocialProofDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_SOCIAL_PROOF_DESC',
  ReferralsByReferringLawyerIdMaxStatusAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_STATUS_ASC',
  ReferralsByReferringLawyerIdMaxStatusDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_STATUS_DESC',
  ReferralsByReferringLawyerIdMaxUpdatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  ReferralsByReferringLawyerIdMaxUpdatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  ReferralsByReferringLawyerIdMaxValuePropositionAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_VALUE_PROPOSITION_ASC',
  ReferralsByReferringLawyerIdMaxValuePropositionDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MAX_VALUE_PROPOSITION_DESC',
  ReferralsByReferringLawyerIdMinCreatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_CREATED_TIME_ASC',
  ReferralsByReferringLawyerIdMinCreatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_CREATED_TIME_DESC',
  ReferralsByReferringLawyerIdMinEmailAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_EMAIL_ASC',
  ReferralsByReferringLawyerIdMinEmailDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_EMAIL_DESC',
  ReferralsByReferringLawyerIdMinFirmNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_FIRM_NAME_ASC',
  ReferralsByReferringLawyerIdMinFirmNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_FIRM_NAME_DESC',
  ReferralsByReferringLawyerIdMinIcpAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_ICP_ASC',
  ReferralsByReferringLawyerIdMinIcpDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_ICP_DESC',
  ReferralsByReferringLawyerIdMinIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_ID_ASC',
  ReferralsByReferringLawyerIdMinIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_ID_DESC',
  ReferralsByReferringLawyerIdMinNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_NAME_ASC',
  ReferralsByReferringLawyerIdMinNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_NAME_DESC',
  ReferralsByReferringLawyerIdMinPhoneNumberAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_PHONE_NUMBER_ASC',
  ReferralsByReferringLawyerIdMinPhoneNumberDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_PHONE_NUMBER_DESC',
  ReferralsByReferringLawyerIdMinReferredLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdMinReferredLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdMinReferringLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdMinReferringLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdMinSocialProofAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_SOCIAL_PROOF_ASC',
  ReferralsByReferringLawyerIdMinSocialProofDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_SOCIAL_PROOF_DESC',
  ReferralsByReferringLawyerIdMinStatusAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_STATUS_ASC',
  ReferralsByReferringLawyerIdMinStatusDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_STATUS_DESC',
  ReferralsByReferringLawyerIdMinUpdatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  ReferralsByReferringLawyerIdMinUpdatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  ReferralsByReferringLawyerIdMinValuePropositionAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_VALUE_PROPOSITION_ASC',
  ReferralsByReferringLawyerIdMinValuePropositionDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_MIN_VALUE_PROPOSITION_DESC',
  ReferralsByReferringLawyerIdStddevPopulationCreatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  ReferralsByReferringLawyerIdStddevPopulationCreatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  ReferralsByReferringLawyerIdStddevPopulationEmailAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_EMAIL_ASC',
  ReferralsByReferringLawyerIdStddevPopulationEmailDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_EMAIL_DESC',
  ReferralsByReferringLawyerIdStddevPopulationFirmNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_FIRM_NAME_ASC',
  ReferralsByReferringLawyerIdStddevPopulationFirmNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_FIRM_NAME_DESC',
  ReferralsByReferringLawyerIdStddevPopulationIcpAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_ICP_ASC',
  ReferralsByReferringLawyerIdStddevPopulationIcpDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_ICP_DESC',
  ReferralsByReferringLawyerIdStddevPopulationIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  ReferralsByReferringLawyerIdStddevPopulationIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  ReferralsByReferringLawyerIdStddevPopulationNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_NAME_ASC',
  ReferralsByReferringLawyerIdStddevPopulationNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_NAME_DESC',
  ReferralsByReferringLawyerIdStddevPopulationPhoneNumberAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_PHONE_NUMBER_ASC',
  ReferralsByReferringLawyerIdStddevPopulationPhoneNumberDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_PHONE_NUMBER_DESC',
  ReferralsByReferringLawyerIdStddevPopulationReferredLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdStddevPopulationReferredLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdStddevPopulationReferringLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdStddevPopulationReferringLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdStddevPopulationSocialProofAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_SOCIAL_PROOF_ASC',
  ReferralsByReferringLawyerIdStddevPopulationSocialProofDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_SOCIAL_PROOF_DESC',
  ReferralsByReferringLawyerIdStddevPopulationStatusAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_STATUS_ASC',
  ReferralsByReferringLawyerIdStddevPopulationStatusDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_STATUS_DESC',
  ReferralsByReferringLawyerIdStddevPopulationUpdatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  ReferralsByReferringLawyerIdStddevPopulationUpdatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  ReferralsByReferringLawyerIdStddevPopulationValuePropositionAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_VALUE_PROPOSITION_ASC',
  ReferralsByReferringLawyerIdStddevPopulationValuePropositionDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_POPULATION_VALUE_PROPOSITION_DESC',
  ReferralsByReferringLawyerIdStddevSampleCreatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  ReferralsByReferringLawyerIdStddevSampleCreatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  ReferralsByReferringLawyerIdStddevSampleEmailAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_EMAIL_ASC',
  ReferralsByReferringLawyerIdStddevSampleEmailDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_EMAIL_DESC',
  ReferralsByReferringLawyerIdStddevSampleFirmNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_FIRM_NAME_ASC',
  ReferralsByReferringLawyerIdStddevSampleFirmNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_FIRM_NAME_DESC',
  ReferralsByReferringLawyerIdStddevSampleIcpAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_ICP_ASC',
  ReferralsByReferringLawyerIdStddevSampleIcpDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_ICP_DESC',
  ReferralsByReferringLawyerIdStddevSampleIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  ReferralsByReferringLawyerIdStddevSampleIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  ReferralsByReferringLawyerIdStddevSampleNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_NAME_ASC',
  ReferralsByReferringLawyerIdStddevSampleNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_NAME_DESC',
  ReferralsByReferringLawyerIdStddevSamplePhoneNumberAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_PHONE_NUMBER_ASC',
  ReferralsByReferringLawyerIdStddevSamplePhoneNumberDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_PHONE_NUMBER_DESC',
  ReferralsByReferringLawyerIdStddevSampleReferredLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdStddevSampleReferredLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdStddevSampleReferringLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdStddevSampleReferringLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdStddevSampleSocialProofAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_SOCIAL_PROOF_ASC',
  ReferralsByReferringLawyerIdStddevSampleSocialProofDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_SOCIAL_PROOF_DESC',
  ReferralsByReferringLawyerIdStddevSampleStatusAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_STATUS_ASC',
  ReferralsByReferringLawyerIdStddevSampleStatusDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_STATUS_DESC',
  ReferralsByReferringLawyerIdStddevSampleUpdatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  ReferralsByReferringLawyerIdStddevSampleUpdatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  ReferralsByReferringLawyerIdStddevSampleValuePropositionAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_VALUE_PROPOSITION_ASC',
  ReferralsByReferringLawyerIdStddevSampleValuePropositionDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_STDDEV_SAMPLE_VALUE_PROPOSITION_DESC',
  ReferralsByReferringLawyerIdSumCreatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_CREATED_TIME_ASC',
  ReferralsByReferringLawyerIdSumCreatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_CREATED_TIME_DESC',
  ReferralsByReferringLawyerIdSumEmailAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_EMAIL_ASC',
  ReferralsByReferringLawyerIdSumEmailDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_EMAIL_DESC',
  ReferralsByReferringLawyerIdSumFirmNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_FIRM_NAME_ASC',
  ReferralsByReferringLawyerIdSumFirmNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_FIRM_NAME_DESC',
  ReferralsByReferringLawyerIdSumIcpAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_ICP_ASC',
  ReferralsByReferringLawyerIdSumIcpDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_ICP_DESC',
  ReferralsByReferringLawyerIdSumIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_ID_ASC',
  ReferralsByReferringLawyerIdSumIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_ID_DESC',
  ReferralsByReferringLawyerIdSumNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_NAME_ASC',
  ReferralsByReferringLawyerIdSumNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_NAME_DESC',
  ReferralsByReferringLawyerIdSumPhoneNumberAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_PHONE_NUMBER_ASC',
  ReferralsByReferringLawyerIdSumPhoneNumberDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_PHONE_NUMBER_DESC',
  ReferralsByReferringLawyerIdSumReferredLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdSumReferredLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdSumReferringLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdSumReferringLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdSumSocialProofAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_SOCIAL_PROOF_ASC',
  ReferralsByReferringLawyerIdSumSocialProofDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_SOCIAL_PROOF_DESC',
  ReferralsByReferringLawyerIdSumStatusAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_STATUS_ASC',
  ReferralsByReferringLawyerIdSumStatusDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_STATUS_DESC',
  ReferralsByReferringLawyerIdSumUpdatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  ReferralsByReferringLawyerIdSumUpdatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  ReferralsByReferringLawyerIdSumValuePropositionAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_VALUE_PROPOSITION_ASC',
  ReferralsByReferringLawyerIdSumValuePropositionDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_SUM_VALUE_PROPOSITION_DESC',
  ReferralsByReferringLawyerIdVariancePopulationCreatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  ReferralsByReferringLawyerIdVariancePopulationCreatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  ReferralsByReferringLawyerIdVariancePopulationEmailAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_EMAIL_ASC',
  ReferralsByReferringLawyerIdVariancePopulationEmailDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_EMAIL_DESC',
  ReferralsByReferringLawyerIdVariancePopulationFirmNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_FIRM_NAME_ASC',
  ReferralsByReferringLawyerIdVariancePopulationFirmNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_FIRM_NAME_DESC',
  ReferralsByReferringLawyerIdVariancePopulationIcpAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_ICP_ASC',
  ReferralsByReferringLawyerIdVariancePopulationIcpDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_ICP_DESC',
  ReferralsByReferringLawyerIdVariancePopulationIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  ReferralsByReferringLawyerIdVariancePopulationIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  ReferralsByReferringLawyerIdVariancePopulationNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_NAME_ASC',
  ReferralsByReferringLawyerIdVariancePopulationNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_NAME_DESC',
  ReferralsByReferringLawyerIdVariancePopulationPhoneNumberAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_PHONE_NUMBER_ASC',
  ReferralsByReferringLawyerIdVariancePopulationPhoneNumberDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_PHONE_NUMBER_DESC',
  ReferralsByReferringLawyerIdVariancePopulationReferredLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdVariancePopulationReferredLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdVariancePopulationReferringLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdVariancePopulationReferringLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdVariancePopulationSocialProofAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_SOCIAL_PROOF_ASC',
  ReferralsByReferringLawyerIdVariancePopulationSocialProofDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_SOCIAL_PROOF_DESC',
  ReferralsByReferringLawyerIdVariancePopulationStatusAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_STATUS_ASC',
  ReferralsByReferringLawyerIdVariancePopulationStatusDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_STATUS_DESC',
  ReferralsByReferringLawyerIdVariancePopulationUpdatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  ReferralsByReferringLawyerIdVariancePopulationUpdatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  ReferralsByReferringLawyerIdVariancePopulationValuePropositionAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_VALUE_PROPOSITION_ASC',
  ReferralsByReferringLawyerIdVariancePopulationValuePropositionDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_POPULATION_VALUE_PROPOSITION_DESC',
  ReferralsByReferringLawyerIdVarianceSampleCreatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  ReferralsByReferringLawyerIdVarianceSampleCreatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  ReferralsByReferringLawyerIdVarianceSampleEmailAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_ASC',
  ReferralsByReferringLawyerIdVarianceSampleEmailDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_DESC',
  ReferralsByReferringLawyerIdVarianceSampleFirmNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_FIRM_NAME_ASC',
  ReferralsByReferringLawyerIdVarianceSampleFirmNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_FIRM_NAME_DESC',
  ReferralsByReferringLawyerIdVarianceSampleIcpAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_ICP_ASC',
  ReferralsByReferringLawyerIdVarianceSampleIcpDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_ICP_DESC',
  ReferralsByReferringLawyerIdVarianceSampleIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  ReferralsByReferringLawyerIdVarianceSampleIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  ReferralsByReferringLawyerIdVarianceSampleNameAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_NAME_ASC',
  ReferralsByReferringLawyerIdVarianceSampleNameDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_NAME_DESC',
  ReferralsByReferringLawyerIdVarianceSamplePhoneNumberAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_PHONE_NUMBER_ASC',
  ReferralsByReferringLawyerIdVarianceSamplePhoneNumberDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_PHONE_NUMBER_DESC',
  ReferralsByReferringLawyerIdVarianceSampleReferredLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_REFERRED_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdVarianceSampleReferredLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_REFERRED_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdVarianceSampleReferringLawyerIdAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_REFERRING_LAWYER_ID_ASC',
  ReferralsByReferringLawyerIdVarianceSampleReferringLawyerIdDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_REFERRING_LAWYER_ID_DESC',
  ReferralsByReferringLawyerIdVarianceSampleSocialProofAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_SOCIAL_PROOF_ASC',
  ReferralsByReferringLawyerIdVarianceSampleSocialProofDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_SOCIAL_PROOF_DESC',
  ReferralsByReferringLawyerIdVarianceSampleStatusAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_STATUS_ASC',
  ReferralsByReferringLawyerIdVarianceSampleStatusDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_STATUS_DESC',
  ReferralsByReferringLawyerIdVarianceSampleUpdatedTimeAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  ReferralsByReferringLawyerIdVarianceSampleUpdatedTimeDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  ReferralsByReferringLawyerIdVarianceSampleValuePropositionAsc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_VALUE_PROPOSITION_ASC',
  ReferralsByReferringLawyerIdVarianceSampleValuePropositionDesc = 'REFERRALS_BY_REFERRING_LAWYER_ID_VARIANCE_SAMPLE_VALUE_PROPOSITION_DESC',
  ReferralPartnersByLawyerIdAverageCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_AVERAGE_COMPANY_NOTES_ASC',
  ReferralPartnersByLawyerIdAverageCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_AVERAGE_COMPANY_NOTES_DESC',
  ReferralPartnersByLawyerIdAverageContactIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_ASC',
  ReferralPartnersByLawyerIdAverageContactIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_DESC',
  ReferralPartnersByLawyerIdAverageContactNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_AVERAGE_CONTACT_NOTES_ASC',
  ReferralPartnersByLawyerIdAverageContactNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_AVERAGE_CONTACT_NOTES_DESC',
  ReferralPartnersByLawyerIdAverageCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  ReferralPartnersByLawyerIdAverageCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  ReferralPartnersByLawyerIdAverageIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  ReferralPartnersByLawyerIdAverageIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  ReferralPartnersByLawyerIdAverageLawyerIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  ReferralPartnersByLawyerIdAverageLawyerIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  ReferralPartnersByLawyerIdCountAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_COUNT_ASC',
  ReferralPartnersByLawyerIdCountDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_COUNT_DESC',
  ReferralPartnersByLawyerIdDistinctCountCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_DISTINCT_COUNT_COMPANY_NOTES_ASC',
  ReferralPartnersByLawyerIdDistinctCountCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_DISTINCT_COUNT_COMPANY_NOTES_DESC',
  ReferralPartnersByLawyerIdDistinctCountContactIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ReferralPartnersByLawyerIdDistinctCountContactIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ReferralPartnersByLawyerIdDistinctCountContactNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_NOTES_ASC',
  ReferralPartnersByLawyerIdDistinctCountContactNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_NOTES_DESC',
  ReferralPartnersByLawyerIdDistinctCountCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  ReferralPartnersByLawyerIdDistinctCountCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  ReferralPartnersByLawyerIdDistinctCountIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  ReferralPartnersByLawyerIdDistinctCountIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  ReferralPartnersByLawyerIdDistinctCountLawyerIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  ReferralPartnersByLawyerIdDistinctCountLawyerIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  ReferralPartnersByLawyerIdMaxCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MAX_COMPANY_NOTES_ASC',
  ReferralPartnersByLawyerIdMaxCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MAX_COMPANY_NOTES_DESC',
  ReferralPartnersByLawyerIdMaxContactIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MAX_CONTACT_ID_ASC',
  ReferralPartnersByLawyerIdMaxContactIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MAX_CONTACT_ID_DESC',
  ReferralPartnersByLawyerIdMaxContactNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MAX_CONTACT_NOTES_ASC',
  ReferralPartnersByLawyerIdMaxContactNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MAX_CONTACT_NOTES_DESC',
  ReferralPartnersByLawyerIdMaxCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  ReferralPartnersByLawyerIdMaxCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  ReferralPartnersByLawyerIdMaxIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MAX_ID_ASC',
  ReferralPartnersByLawyerIdMaxIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MAX_ID_DESC',
  ReferralPartnersByLawyerIdMaxLawyerIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  ReferralPartnersByLawyerIdMaxLawyerIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  ReferralPartnersByLawyerIdMinCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MIN_COMPANY_NOTES_ASC',
  ReferralPartnersByLawyerIdMinCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MIN_COMPANY_NOTES_DESC',
  ReferralPartnersByLawyerIdMinContactIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MIN_CONTACT_ID_ASC',
  ReferralPartnersByLawyerIdMinContactIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MIN_CONTACT_ID_DESC',
  ReferralPartnersByLawyerIdMinContactNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MIN_CONTACT_NOTES_ASC',
  ReferralPartnersByLawyerIdMinContactNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MIN_CONTACT_NOTES_DESC',
  ReferralPartnersByLawyerIdMinCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  ReferralPartnersByLawyerIdMinCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  ReferralPartnersByLawyerIdMinIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MIN_ID_ASC',
  ReferralPartnersByLawyerIdMinIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MIN_ID_DESC',
  ReferralPartnersByLawyerIdMinLawyerIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  ReferralPartnersByLawyerIdMinLawyerIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  ReferralPartnersByLawyerIdStddevPopulationCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_POPULATION_COMPANY_NOTES_ASC',
  ReferralPartnersByLawyerIdStddevPopulationCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_POPULATION_COMPANY_NOTES_DESC',
  ReferralPartnersByLawyerIdStddevPopulationContactIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ReferralPartnersByLawyerIdStddevPopulationContactIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ReferralPartnersByLawyerIdStddevPopulationContactNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_NOTES_ASC',
  ReferralPartnersByLawyerIdStddevPopulationContactNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_NOTES_DESC',
  ReferralPartnersByLawyerIdStddevPopulationCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  ReferralPartnersByLawyerIdStddevPopulationCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  ReferralPartnersByLawyerIdStddevPopulationIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  ReferralPartnersByLawyerIdStddevPopulationIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  ReferralPartnersByLawyerIdStddevPopulationLawyerIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  ReferralPartnersByLawyerIdStddevPopulationLawyerIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  ReferralPartnersByLawyerIdStddevSampleCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_SAMPLE_COMPANY_NOTES_ASC',
  ReferralPartnersByLawyerIdStddevSampleCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_SAMPLE_COMPANY_NOTES_DESC',
  ReferralPartnersByLawyerIdStddevSampleContactIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ReferralPartnersByLawyerIdStddevSampleContactIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ReferralPartnersByLawyerIdStddevSampleContactNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_NOTES_ASC',
  ReferralPartnersByLawyerIdStddevSampleContactNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_NOTES_DESC',
  ReferralPartnersByLawyerIdStddevSampleCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  ReferralPartnersByLawyerIdStddevSampleCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  ReferralPartnersByLawyerIdStddevSampleIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  ReferralPartnersByLawyerIdStddevSampleIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  ReferralPartnersByLawyerIdStddevSampleLawyerIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  ReferralPartnersByLawyerIdStddevSampleLawyerIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  ReferralPartnersByLawyerIdSumCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_SUM_COMPANY_NOTES_ASC',
  ReferralPartnersByLawyerIdSumCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_SUM_COMPANY_NOTES_DESC',
  ReferralPartnersByLawyerIdSumContactIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_SUM_CONTACT_ID_ASC',
  ReferralPartnersByLawyerIdSumContactIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_SUM_CONTACT_ID_DESC',
  ReferralPartnersByLawyerIdSumContactNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_SUM_CONTACT_NOTES_ASC',
  ReferralPartnersByLawyerIdSumContactNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_SUM_CONTACT_NOTES_DESC',
  ReferralPartnersByLawyerIdSumCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  ReferralPartnersByLawyerIdSumCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  ReferralPartnersByLawyerIdSumIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_SUM_ID_ASC',
  ReferralPartnersByLawyerIdSumIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_SUM_ID_DESC',
  ReferralPartnersByLawyerIdSumLawyerIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  ReferralPartnersByLawyerIdSumLawyerIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  ReferralPartnersByLawyerIdVariancePopulationCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_POPULATION_COMPANY_NOTES_ASC',
  ReferralPartnersByLawyerIdVariancePopulationCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_POPULATION_COMPANY_NOTES_DESC',
  ReferralPartnersByLawyerIdVariancePopulationContactIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ReferralPartnersByLawyerIdVariancePopulationContactIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ReferralPartnersByLawyerIdVariancePopulationContactNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_NOTES_ASC',
  ReferralPartnersByLawyerIdVariancePopulationContactNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_NOTES_DESC',
  ReferralPartnersByLawyerIdVariancePopulationCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  ReferralPartnersByLawyerIdVariancePopulationCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  ReferralPartnersByLawyerIdVariancePopulationIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  ReferralPartnersByLawyerIdVariancePopulationIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  ReferralPartnersByLawyerIdVariancePopulationLawyerIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  ReferralPartnersByLawyerIdVariancePopulationLawyerIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  ReferralPartnersByLawyerIdVarianceSampleCompanyNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_SAMPLE_COMPANY_NOTES_ASC',
  ReferralPartnersByLawyerIdVarianceSampleCompanyNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_SAMPLE_COMPANY_NOTES_DESC',
  ReferralPartnersByLawyerIdVarianceSampleContactIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ReferralPartnersByLawyerIdVarianceSampleContactIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ReferralPartnersByLawyerIdVarianceSampleContactNotesAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_NOTES_ASC',
  ReferralPartnersByLawyerIdVarianceSampleContactNotesDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_NOTES_DESC',
  ReferralPartnersByLawyerIdVarianceSampleCreatedTimeAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  ReferralPartnersByLawyerIdVarianceSampleCreatedTimeDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  ReferralPartnersByLawyerIdVarianceSampleIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  ReferralPartnersByLawyerIdVarianceSampleIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  ReferralPartnersByLawyerIdVarianceSampleLawyerIdAsc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  ReferralPartnersByLawyerIdVarianceSampleLawyerIdDesc = 'REFERRAL_PARTNERS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  ReferralRequestsByLawyerIdAverageCreatedTimeAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  ReferralRequestsByLawyerIdAverageCreatedTimeDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  ReferralRequestsByLawyerIdAverageDescriptionAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_DESCRIPTION_ASC',
  ReferralRequestsByLawyerIdAverageDescriptionDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_DESCRIPTION_DESC',
  ReferralRequestsByLawyerIdAverageDurationAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_DURATION_ASC',
  ReferralRequestsByLawyerIdAverageDurationDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_DURATION_DESC',
  ReferralRequestsByLawyerIdAverageExpertiseAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_EXPERTISE_ASC',
  ReferralRequestsByLawyerIdAverageExpertiseDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_EXPERTISE_DESC',
  ReferralRequestsByLawyerIdAverageFeePercentageAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_FEE_PERCENTAGE_ASC',
  ReferralRequestsByLawyerIdAverageFeePercentageDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_FEE_PERCENTAGE_DESC',
  ReferralRequestsByLawyerIdAverageIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  ReferralRequestsByLawyerIdAverageIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  ReferralRequestsByLawyerIdAverageLawyerIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  ReferralRequestsByLawyerIdAverageLawyerIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  ReferralRequestsByLawyerIdAveragePriceAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_PRICE_ASC',
  ReferralRequestsByLawyerIdAveragePriceDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_AVERAGE_PRICE_DESC',
  ReferralRequestsByLawyerIdCountAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_COUNT_ASC',
  ReferralRequestsByLawyerIdCountDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_COUNT_DESC',
  ReferralRequestsByLawyerIdDistinctCountCreatedTimeAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  ReferralRequestsByLawyerIdDistinctCountCreatedTimeDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  ReferralRequestsByLawyerIdDistinctCountDescriptionAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_DESCRIPTION_ASC',
  ReferralRequestsByLawyerIdDistinctCountDescriptionDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_DESCRIPTION_DESC',
  ReferralRequestsByLawyerIdDistinctCountDurationAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_DURATION_ASC',
  ReferralRequestsByLawyerIdDistinctCountDurationDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_DURATION_DESC',
  ReferralRequestsByLawyerIdDistinctCountExpertiseAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_EXPERTISE_ASC',
  ReferralRequestsByLawyerIdDistinctCountExpertiseDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_EXPERTISE_DESC',
  ReferralRequestsByLawyerIdDistinctCountFeePercentageAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_FEE_PERCENTAGE_ASC',
  ReferralRequestsByLawyerIdDistinctCountFeePercentageDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_FEE_PERCENTAGE_DESC',
  ReferralRequestsByLawyerIdDistinctCountIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  ReferralRequestsByLawyerIdDistinctCountIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  ReferralRequestsByLawyerIdDistinctCountLawyerIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  ReferralRequestsByLawyerIdDistinctCountLawyerIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  ReferralRequestsByLawyerIdDistinctCountPriceAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_PRICE_ASC',
  ReferralRequestsByLawyerIdDistinctCountPriceDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_DISTINCT_COUNT_PRICE_DESC',
  ReferralRequestsByLawyerIdMaxCreatedTimeAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  ReferralRequestsByLawyerIdMaxCreatedTimeDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  ReferralRequestsByLawyerIdMaxDescriptionAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_DESCRIPTION_ASC',
  ReferralRequestsByLawyerIdMaxDescriptionDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_DESCRIPTION_DESC',
  ReferralRequestsByLawyerIdMaxDurationAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_DURATION_ASC',
  ReferralRequestsByLawyerIdMaxDurationDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_DURATION_DESC',
  ReferralRequestsByLawyerIdMaxExpertiseAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_EXPERTISE_ASC',
  ReferralRequestsByLawyerIdMaxExpertiseDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_EXPERTISE_DESC',
  ReferralRequestsByLawyerIdMaxFeePercentageAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_FEE_PERCENTAGE_ASC',
  ReferralRequestsByLawyerIdMaxFeePercentageDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_FEE_PERCENTAGE_DESC',
  ReferralRequestsByLawyerIdMaxIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_ID_ASC',
  ReferralRequestsByLawyerIdMaxIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_ID_DESC',
  ReferralRequestsByLawyerIdMaxLawyerIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  ReferralRequestsByLawyerIdMaxLawyerIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  ReferralRequestsByLawyerIdMaxPriceAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_PRICE_ASC',
  ReferralRequestsByLawyerIdMaxPriceDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MAX_PRICE_DESC',
  ReferralRequestsByLawyerIdMinCreatedTimeAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  ReferralRequestsByLawyerIdMinCreatedTimeDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  ReferralRequestsByLawyerIdMinDescriptionAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_DESCRIPTION_ASC',
  ReferralRequestsByLawyerIdMinDescriptionDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_DESCRIPTION_DESC',
  ReferralRequestsByLawyerIdMinDurationAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_DURATION_ASC',
  ReferralRequestsByLawyerIdMinDurationDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_DURATION_DESC',
  ReferralRequestsByLawyerIdMinExpertiseAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_EXPERTISE_ASC',
  ReferralRequestsByLawyerIdMinExpertiseDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_EXPERTISE_DESC',
  ReferralRequestsByLawyerIdMinFeePercentageAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_FEE_PERCENTAGE_ASC',
  ReferralRequestsByLawyerIdMinFeePercentageDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_FEE_PERCENTAGE_DESC',
  ReferralRequestsByLawyerIdMinIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_ID_ASC',
  ReferralRequestsByLawyerIdMinIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_ID_DESC',
  ReferralRequestsByLawyerIdMinLawyerIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  ReferralRequestsByLawyerIdMinLawyerIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  ReferralRequestsByLawyerIdMinPriceAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_PRICE_ASC',
  ReferralRequestsByLawyerIdMinPriceDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_MIN_PRICE_DESC',
  ReferralRequestsByLawyerIdStddevPopulationCreatedTimeAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  ReferralRequestsByLawyerIdStddevPopulationCreatedTimeDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  ReferralRequestsByLawyerIdStddevPopulationDescriptionAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_DESCRIPTION_ASC',
  ReferralRequestsByLawyerIdStddevPopulationDescriptionDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_DESCRIPTION_DESC',
  ReferralRequestsByLawyerIdStddevPopulationDurationAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_DURATION_ASC',
  ReferralRequestsByLawyerIdStddevPopulationDurationDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_DURATION_DESC',
  ReferralRequestsByLawyerIdStddevPopulationExpertiseAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_EXPERTISE_ASC',
  ReferralRequestsByLawyerIdStddevPopulationExpertiseDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_EXPERTISE_DESC',
  ReferralRequestsByLawyerIdStddevPopulationFeePercentageAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_FEE_PERCENTAGE_ASC',
  ReferralRequestsByLawyerIdStddevPopulationFeePercentageDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_FEE_PERCENTAGE_DESC',
  ReferralRequestsByLawyerIdStddevPopulationIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  ReferralRequestsByLawyerIdStddevPopulationIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  ReferralRequestsByLawyerIdStddevPopulationLawyerIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  ReferralRequestsByLawyerIdStddevPopulationLawyerIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  ReferralRequestsByLawyerIdStddevPopulationPriceAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_PRICE_ASC',
  ReferralRequestsByLawyerIdStddevPopulationPriceDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_POPULATION_PRICE_DESC',
  ReferralRequestsByLawyerIdStddevSampleCreatedTimeAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  ReferralRequestsByLawyerIdStddevSampleCreatedTimeDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  ReferralRequestsByLawyerIdStddevSampleDescriptionAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_DESCRIPTION_ASC',
  ReferralRequestsByLawyerIdStddevSampleDescriptionDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_DESCRIPTION_DESC',
  ReferralRequestsByLawyerIdStddevSampleDurationAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_DURATION_ASC',
  ReferralRequestsByLawyerIdStddevSampleDurationDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_DURATION_DESC',
  ReferralRequestsByLawyerIdStddevSampleExpertiseAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_EXPERTISE_ASC',
  ReferralRequestsByLawyerIdStddevSampleExpertiseDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_EXPERTISE_DESC',
  ReferralRequestsByLawyerIdStddevSampleFeePercentageAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_FEE_PERCENTAGE_ASC',
  ReferralRequestsByLawyerIdStddevSampleFeePercentageDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_FEE_PERCENTAGE_DESC',
  ReferralRequestsByLawyerIdStddevSampleIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  ReferralRequestsByLawyerIdStddevSampleIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  ReferralRequestsByLawyerIdStddevSampleLawyerIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  ReferralRequestsByLawyerIdStddevSampleLawyerIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  ReferralRequestsByLawyerIdStddevSamplePriceAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_PRICE_ASC',
  ReferralRequestsByLawyerIdStddevSamplePriceDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_STDDEV_SAMPLE_PRICE_DESC',
  ReferralRequestsByLawyerIdSumCreatedTimeAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  ReferralRequestsByLawyerIdSumCreatedTimeDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  ReferralRequestsByLawyerIdSumDescriptionAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_DESCRIPTION_ASC',
  ReferralRequestsByLawyerIdSumDescriptionDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_DESCRIPTION_DESC',
  ReferralRequestsByLawyerIdSumDurationAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_DURATION_ASC',
  ReferralRequestsByLawyerIdSumDurationDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_DURATION_DESC',
  ReferralRequestsByLawyerIdSumExpertiseAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_EXPERTISE_ASC',
  ReferralRequestsByLawyerIdSumExpertiseDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_EXPERTISE_DESC',
  ReferralRequestsByLawyerIdSumFeePercentageAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_FEE_PERCENTAGE_ASC',
  ReferralRequestsByLawyerIdSumFeePercentageDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_FEE_PERCENTAGE_DESC',
  ReferralRequestsByLawyerIdSumIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_ID_ASC',
  ReferralRequestsByLawyerIdSumIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_ID_DESC',
  ReferralRequestsByLawyerIdSumLawyerIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  ReferralRequestsByLawyerIdSumLawyerIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  ReferralRequestsByLawyerIdSumPriceAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_PRICE_ASC',
  ReferralRequestsByLawyerIdSumPriceDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_SUM_PRICE_DESC',
  ReferralRequestsByLawyerIdVariancePopulationCreatedTimeAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  ReferralRequestsByLawyerIdVariancePopulationCreatedTimeDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  ReferralRequestsByLawyerIdVariancePopulationDescriptionAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_DESCRIPTION_ASC',
  ReferralRequestsByLawyerIdVariancePopulationDescriptionDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_DESCRIPTION_DESC',
  ReferralRequestsByLawyerIdVariancePopulationDurationAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_DURATION_ASC',
  ReferralRequestsByLawyerIdVariancePopulationDurationDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_DURATION_DESC',
  ReferralRequestsByLawyerIdVariancePopulationExpertiseAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_EXPERTISE_ASC',
  ReferralRequestsByLawyerIdVariancePopulationExpertiseDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_EXPERTISE_DESC',
  ReferralRequestsByLawyerIdVariancePopulationFeePercentageAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_FEE_PERCENTAGE_ASC',
  ReferralRequestsByLawyerIdVariancePopulationFeePercentageDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_FEE_PERCENTAGE_DESC',
  ReferralRequestsByLawyerIdVariancePopulationIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  ReferralRequestsByLawyerIdVariancePopulationIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  ReferralRequestsByLawyerIdVariancePopulationLawyerIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  ReferralRequestsByLawyerIdVariancePopulationLawyerIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  ReferralRequestsByLawyerIdVariancePopulationPriceAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_PRICE_ASC',
  ReferralRequestsByLawyerIdVariancePopulationPriceDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_POPULATION_PRICE_DESC',
  ReferralRequestsByLawyerIdVarianceSampleCreatedTimeAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  ReferralRequestsByLawyerIdVarianceSampleCreatedTimeDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  ReferralRequestsByLawyerIdVarianceSampleDescriptionAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_DESCRIPTION_ASC',
  ReferralRequestsByLawyerIdVarianceSampleDescriptionDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_DESCRIPTION_DESC',
  ReferralRequestsByLawyerIdVarianceSampleDurationAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_DURATION_ASC',
  ReferralRequestsByLawyerIdVarianceSampleDurationDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_DURATION_DESC',
  ReferralRequestsByLawyerIdVarianceSampleExpertiseAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_EXPERTISE_ASC',
  ReferralRequestsByLawyerIdVarianceSampleExpertiseDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_EXPERTISE_DESC',
  ReferralRequestsByLawyerIdVarianceSampleFeePercentageAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_FEE_PERCENTAGE_ASC',
  ReferralRequestsByLawyerIdVarianceSampleFeePercentageDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_FEE_PERCENTAGE_DESC',
  ReferralRequestsByLawyerIdVarianceSampleIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  ReferralRequestsByLawyerIdVarianceSampleIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  ReferralRequestsByLawyerIdVarianceSampleLawyerIdAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  ReferralRequestsByLawyerIdVarianceSampleLawyerIdDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  ReferralRequestsByLawyerIdVarianceSamplePriceAsc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_PRICE_ASC',
  ReferralRequestsByLawyerIdVarianceSamplePriceDesc = 'REFERRAL_REQUESTS_BY_LAWYER_ID_VARIANCE_SAMPLE_PRICE_DESC',
  ReplyNotesAsc = 'REPLY_NOTES_ASC',
  ReplyNotesDesc = 'REPLY_NOTES_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdAverageContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdAverageContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdAverageCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdAverageCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdAverageLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdAverageLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdAverageUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdAverageUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdCountAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_COUNT_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdCountDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_COUNT_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdDistinctCountContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdDistinctCountContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdDistinctCountCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdDistinctCountCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdDistinctCountLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdDistinctCountLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdDistinctCountUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdDistinctCountUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdMaxContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MAX_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdMaxContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MAX_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdMaxCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdMaxCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdMaxLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdMaxLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdMaxUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdMaxUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdMinContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MIN_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdMinContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MIN_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdMinCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdMinCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdMinLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdMinLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdMinUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdMinUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevPopulationContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevPopulationContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevPopulationCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevPopulationCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevPopulationLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevPopulationLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevPopulationUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevPopulationUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevSampleContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevSampleContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevSampleCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevSampleCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevSampleLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevSampleLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevSampleUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdStddevSampleUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdSumContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_SUM_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdSumContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_SUM_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdSumCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdSumCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdSumLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdSumLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdSumUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdSumUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdVariancePopulationContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdVariancePopulationContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdVariancePopulationCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdVariancePopulationCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdVariancePopulationLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdVariancePopulationLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdVariancePopulationUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdVariancePopulationUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdVarianceSampleContactIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdVarianceSampleContactIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdVarianceSampleCreatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdVarianceSampleCreatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdVarianceSampleLawyerIdAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdVarianceSampleLawyerIdDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  SalesFunnelLawyerContactLinksByLawyerIdVarianceSampleUpdatedTimeAsc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  SalesFunnelLawyerContactLinksByLawyerIdVarianceSampleUpdatedTimeDesc = 'SALES_FUNNEL_LAWYER_CONTACT_LINKS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  SalesFunnelSubscriberStatusAsc = 'SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  SalesFunnelSubscriberStatusDesc = 'SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  SenderDisclaimerAsc = 'SENDER_DISCLAIMER_ASC',
  SenderDisclaimerDesc = 'SENDER_DISCLAIMER_DESC',
  SenderProfilesByLawyerIdAverageBannedFromTalkingToGmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_BANNED_FROM_TALKING_TO_GMAIL_ASC',
  SenderProfilesByLawyerIdAverageBannedFromTalkingToGmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_BANNED_FROM_TALKING_TO_GMAIL_DESC',
  SenderProfilesByLawyerIdAverageBrandColorAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_BRAND_COLOR_ASC',
  SenderProfilesByLawyerIdAverageBrandColorDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_BRAND_COLOR_DESC',
  SenderProfilesByLawyerIdAverageCreatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  SenderProfilesByLawyerIdAverageCreatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  SenderProfilesByLawyerIdAverageCurrentDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_CURRENT_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdAverageCurrentDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_CURRENT_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdAverageCurrentDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_CURRENT_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdAverageCurrentDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_CURRENT_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdAverageDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdAverageDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdAverageDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdAverageDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdAverageDailyCapIncrementAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_DAILY_CAP_INCREMENT_ASC',
  SenderProfilesByLawyerIdAverageDailyCapIncrementDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_DAILY_CAP_INCREMENT_DESC',
  SenderProfilesByLawyerIdAverageEmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_EMAIL_ASC',
  SenderProfilesByLawyerIdAverageEmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_EMAIL_DESC',
  SenderProfilesByLawyerIdAverageFirmNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_FIRM_NAME_ASC',
  SenderProfilesByLawyerIdAverageFirmNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_FIRM_NAME_DESC',
  SenderProfilesByLawyerIdAverageFirstNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_FIRST_NAME_ASC',
  SenderProfilesByLawyerIdAverageFirstNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_FIRST_NAME_DESC',
  SenderProfilesByLawyerIdAverageIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_ID_ASC',
  SenderProfilesByLawyerIdAverageIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_ID_DESC',
  SenderProfilesByLawyerIdAverageIsActiveAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_IS_ACTIVE_ASC',
  SenderProfilesByLawyerIdAverageIsActiveDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_IS_ACTIVE_DESC',
  SenderProfilesByLawyerIdAverageIsActiveUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_IS_ACTIVE_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdAverageIsActiveUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_IS_ACTIVE_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdAverageIsAliasAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_IS_ALIAS_ASC',
  SenderProfilesByLawyerIdAverageIsAliasDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_IS_ALIAS_DESC',
  SenderProfilesByLawyerIdAverageIsImpromptuAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_IS_IMPROMPTU_ASC',
  SenderProfilesByLawyerIdAverageIsImpromptuDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_IS_IMPROMPTU_DESC',
  SenderProfilesByLawyerIdAverageIsImpromptuUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_IS_IMPROMPTU_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdAverageIsImpromptuUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_IS_IMPROMPTU_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdAverageIsPrimaryAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_IS_PRIMARY_ASC',
  SenderProfilesByLawyerIdAverageIsPrimaryDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_IS_PRIMARY_DESC',
  SenderProfilesByLawyerIdAverageLastNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_LAST_NAME_ASC',
  SenderProfilesByLawyerIdAverageLastNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_LAST_NAME_DESC',
  SenderProfilesByLawyerIdAverageLawyerIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  SenderProfilesByLawyerIdAverageLawyerIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  SenderProfilesByLawyerIdAverageLogoSrcAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_LOGO_SRC_ASC',
  SenderProfilesByLawyerIdAverageLogoSrcDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_LOGO_SRC_DESC',
  SenderProfilesByLawyerIdAveragePausedUntilTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_PAUSED_UNTIL_TIME_ASC',
  SenderProfilesByLawyerIdAveragePausedUntilTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_PAUSED_UNTIL_TIME_DESC',
  SenderProfilesByLawyerIdAveragePriorityAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_PRIORITY_ASC',
  SenderProfilesByLawyerIdAveragePriorityDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_PRIORITY_DESC',
  SenderProfilesByLawyerIdAverageProfilePictureIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_PROFILE_PICTURE_ID_ASC',
  SenderProfilesByLawyerIdAverageProfilePictureIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_PROFILE_PICTURE_ID_DESC',
  SenderProfilesByLawyerIdAverageProfilePictureStatusAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_PROFILE_PICTURE_STATUS_ASC',
  SenderProfilesByLawyerIdAverageProfilePictureStatusDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_PROFILE_PICTURE_STATUS_DESC',
  SenderProfilesByLawyerIdAverageProfileTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_PROFILE_TYPE_ASC',
  SenderProfilesByLawyerIdAverageProfileTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_PROFILE_TYPE_DESC',
  SenderProfilesByLawyerIdAverageReachedDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_REACHED_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdAverageReachedDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_REACHED_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdAverageSignatureHtmlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_SIGNATURE_HTML_ASC',
  SenderProfilesByLawyerIdAverageSignatureHtmlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_SIGNATURE_HTML_DESC',
  SenderProfilesByLawyerIdAverageTitleAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_TITLE_ASC',
  SenderProfilesByLawyerIdAverageTitleDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_TITLE_DESC',
  SenderProfilesByLawyerIdAverageUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdAverageUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdAverageUsernameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_USERNAME_ASC',
  SenderProfilesByLawyerIdAverageUsernameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_USERNAME_DESC',
  SenderProfilesByLawyerIdAverageVerificationTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_VERIFICATION_TYPE_ASC',
  SenderProfilesByLawyerIdAverageVerificationTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_VERIFICATION_TYPE_DESC',
  SenderProfilesByLawyerIdAverageWarmupInboxFilterIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_WARMUP_INBOX_FILTER_ID_ASC',
  SenderProfilesByLawyerIdAverageWarmupInboxFilterIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_WARMUP_INBOX_FILTER_ID_DESC',
  SenderProfilesByLawyerIdAverageWarmupInboxIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_WARMUP_INBOX_ID_ASC',
  SenderProfilesByLawyerIdAverageWarmupInboxIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_WARMUP_INBOX_ID_DESC',
  SenderProfilesByLawyerIdAverageWebsiteUrlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_WEBSITE_URL_ASC',
  SenderProfilesByLawyerIdAverageWebsiteUrlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_AVERAGE_WEBSITE_URL_DESC',
  SenderProfilesByLawyerIdCountAsc = 'SENDER_PROFILES_BY_LAWYER_ID_COUNT_ASC',
  SenderProfilesByLawyerIdCountDesc = 'SENDER_PROFILES_BY_LAWYER_ID_COUNT_DESC',
  SenderProfilesByLawyerIdDistinctCountBannedFromTalkingToGmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_BANNED_FROM_TALKING_TO_GMAIL_ASC',
  SenderProfilesByLawyerIdDistinctCountBannedFromTalkingToGmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_BANNED_FROM_TALKING_TO_GMAIL_DESC',
  SenderProfilesByLawyerIdDistinctCountBrandColorAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_BRAND_COLOR_ASC',
  SenderProfilesByLawyerIdDistinctCountBrandColorDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_BRAND_COLOR_DESC',
  SenderProfilesByLawyerIdDistinctCountCreatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  SenderProfilesByLawyerIdDistinctCountCreatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  SenderProfilesByLawyerIdDistinctCountCurrentDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_CURRENT_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdDistinctCountCurrentDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_CURRENT_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdDistinctCountCurrentDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_CURRENT_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdDistinctCountCurrentDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_CURRENT_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdDistinctCountDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdDistinctCountDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdDistinctCountDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdDistinctCountDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdDistinctCountDailyCapIncrementAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_DAILY_CAP_INCREMENT_ASC',
  SenderProfilesByLawyerIdDistinctCountDailyCapIncrementDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_DAILY_CAP_INCREMENT_DESC',
  SenderProfilesByLawyerIdDistinctCountEmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_ASC',
  SenderProfilesByLawyerIdDistinctCountEmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_EMAIL_DESC',
  SenderProfilesByLawyerIdDistinctCountFirmNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_FIRM_NAME_ASC',
  SenderProfilesByLawyerIdDistinctCountFirmNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_FIRM_NAME_DESC',
  SenderProfilesByLawyerIdDistinctCountFirstNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_FIRST_NAME_ASC',
  SenderProfilesByLawyerIdDistinctCountFirstNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_FIRST_NAME_DESC',
  SenderProfilesByLawyerIdDistinctCountIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  SenderProfilesByLawyerIdDistinctCountIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  SenderProfilesByLawyerIdDistinctCountIsActiveAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_IS_ACTIVE_ASC',
  SenderProfilesByLawyerIdDistinctCountIsActiveDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_IS_ACTIVE_DESC',
  SenderProfilesByLawyerIdDistinctCountIsActiveUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_IS_ACTIVE_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdDistinctCountIsActiveUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_IS_ACTIVE_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdDistinctCountIsAliasAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_IS_ALIAS_ASC',
  SenderProfilesByLawyerIdDistinctCountIsAliasDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_IS_ALIAS_DESC',
  SenderProfilesByLawyerIdDistinctCountIsImpromptuAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_IS_IMPROMPTU_ASC',
  SenderProfilesByLawyerIdDistinctCountIsImpromptuDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_IS_IMPROMPTU_DESC',
  SenderProfilesByLawyerIdDistinctCountIsImpromptuUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_IS_IMPROMPTU_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdDistinctCountIsImpromptuUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_IS_IMPROMPTU_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdDistinctCountIsPrimaryAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_IS_PRIMARY_ASC',
  SenderProfilesByLawyerIdDistinctCountIsPrimaryDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_IS_PRIMARY_DESC',
  SenderProfilesByLawyerIdDistinctCountLastNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_LAST_NAME_ASC',
  SenderProfilesByLawyerIdDistinctCountLastNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_LAST_NAME_DESC',
  SenderProfilesByLawyerIdDistinctCountLawyerIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  SenderProfilesByLawyerIdDistinctCountLawyerIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  SenderProfilesByLawyerIdDistinctCountLogoSrcAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_LOGO_SRC_ASC',
  SenderProfilesByLawyerIdDistinctCountLogoSrcDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_LOGO_SRC_DESC',
  SenderProfilesByLawyerIdDistinctCountPausedUntilTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PAUSED_UNTIL_TIME_ASC',
  SenderProfilesByLawyerIdDistinctCountPausedUntilTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PAUSED_UNTIL_TIME_DESC',
  SenderProfilesByLawyerIdDistinctCountPriorityAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PRIORITY_ASC',
  SenderProfilesByLawyerIdDistinctCountPriorityDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PRIORITY_DESC',
  SenderProfilesByLawyerIdDistinctCountProfilePictureIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PROFILE_PICTURE_ID_ASC',
  SenderProfilesByLawyerIdDistinctCountProfilePictureIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PROFILE_PICTURE_ID_DESC',
  SenderProfilesByLawyerIdDistinctCountProfilePictureStatusAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PROFILE_PICTURE_STATUS_ASC',
  SenderProfilesByLawyerIdDistinctCountProfilePictureStatusDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PROFILE_PICTURE_STATUS_DESC',
  SenderProfilesByLawyerIdDistinctCountProfileTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PROFILE_TYPE_ASC',
  SenderProfilesByLawyerIdDistinctCountProfileTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_PROFILE_TYPE_DESC',
  SenderProfilesByLawyerIdDistinctCountReachedDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_REACHED_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdDistinctCountReachedDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_REACHED_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdDistinctCountSignatureHtmlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_SIGNATURE_HTML_ASC',
  SenderProfilesByLawyerIdDistinctCountSignatureHtmlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_SIGNATURE_HTML_DESC',
  SenderProfilesByLawyerIdDistinctCountTitleAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_TITLE_ASC',
  SenderProfilesByLawyerIdDistinctCountTitleDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_TITLE_DESC',
  SenderProfilesByLawyerIdDistinctCountUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdDistinctCountUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdDistinctCountUsernameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_USERNAME_ASC',
  SenderProfilesByLawyerIdDistinctCountUsernameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_USERNAME_DESC',
  SenderProfilesByLawyerIdDistinctCountVerificationTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_VERIFICATION_TYPE_ASC',
  SenderProfilesByLawyerIdDistinctCountVerificationTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_VERIFICATION_TYPE_DESC',
  SenderProfilesByLawyerIdDistinctCountWarmupInboxFilterIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_WARMUP_INBOX_FILTER_ID_ASC',
  SenderProfilesByLawyerIdDistinctCountWarmupInboxFilterIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_WARMUP_INBOX_FILTER_ID_DESC',
  SenderProfilesByLawyerIdDistinctCountWarmupInboxIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_WARMUP_INBOX_ID_ASC',
  SenderProfilesByLawyerIdDistinctCountWarmupInboxIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_WARMUP_INBOX_ID_DESC',
  SenderProfilesByLawyerIdDistinctCountWebsiteUrlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_WEBSITE_URL_ASC',
  SenderProfilesByLawyerIdDistinctCountWebsiteUrlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_DISTINCT_COUNT_WEBSITE_URL_DESC',
  SenderProfilesByLawyerIdMaxBannedFromTalkingToGmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_BANNED_FROM_TALKING_TO_GMAIL_ASC',
  SenderProfilesByLawyerIdMaxBannedFromTalkingToGmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_BANNED_FROM_TALKING_TO_GMAIL_DESC',
  SenderProfilesByLawyerIdMaxBrandColorAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_BRAND_COLOR_ASC',
  SenderProfilesByLawyerIdMaxBrandColorDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_BRAND_COLOR_DESC',
  SenderProfilesByLawyerIdMaxCreatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  SenderProfilesByLawyerIdMaxCreatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  SenderProfilesByLawyerIdMaxCurrentDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_CURRENT_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdMaxCurrentDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_CURRENT_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdMaxCurrentDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_CURRENT_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdMaxCurrentDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_CURRENT_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdMaxDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdMaxDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdMaxDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdMaxDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdMaxDailyCapIncrementAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_DAILY_CAP_INCREMENT_ASC',
  SenderProfilesByLawyerIdMaxDailyCapIncrementDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_DAILY_CAP_INCREMENT_DESC',
  SenderProfilesByLawyerIdMaxEmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_EMAIL_ASC',
  SenderProfilesByLawyerIdMaxEmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_EMAIL_DESC',
  SenderProfilesByLawyerIdMaxFirmNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_FIRM_NAME_ASC',
  SenderProfilesByLawyerIdMaxFirmNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_FIRM_NAME_DESC',
  SenderProfilesByLawyerIdMaxFirstNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_FIRST_NAME_ASC',
  SenderProfilesByLawyerIdMaxFirstNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_FIRST_NAME_DESC',
  SenderProfilesByLawyerIdMaxIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_ID_ASC',
  SenderProfilesByLawyerIdMaxIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_ID_DESC',
  SenderProfilesByLawyerIdMaxIsActiveAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_IS_ACTIVE_ASC',
  SenderProfilesByLawyerIdMaxIsActiveDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_IS_ACTIVE_DESC',
  SenderProfilesByLawyerIdMaxIsActiveUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_IS_ACTIVE_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdMaxIsActiveUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_IS_ACTIVE_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdMaxIsAliasAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_IS_ALIAS_ASC',
  SenderProfilesByLawyerIdMaxIsAliasDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_IS_ALIAS_DESC',
  SenderProfilesByLawyerIdMaxIsImpromptuAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_IS_IMPROMPTU_ASC',
  SenderProfilesByLawyerIdMaxIsImpromptuDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_IS_IMPROMPTU_DESC',
  SenderProfilesByLawyerIdMaxIsImpromptuUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_IS_IMPROMPTU_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdMaxIsImpromptuUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_IS_IMPROMPTU_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdMaxIsPrimaryAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_IS_PRIMARY_ASC',
  SenderProfilesByLawyerIdMaxIsPrimaryDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_IS_PRIMARY_DESC',
  SenderProfilesByLawyerIdMaxLastNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_LAST_NAME_ASC',
  SenderProfilesByLawyerIdMaxLastNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_LAST_NAME_DESC',
  SenderProfilesByLawyerIdMaxLawyerIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  SenderProfilesByLawyerIdMaxLawyerIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  SenderProfilesByLawyerIdMaxLogoSrcAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_LOGO_SRC_ASC',
  SenderProfilesByLawyerIdMaxLogoSrcDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_LOGO_SRC_DESC',
  SenderProfilesByLawyerIdMaxPausedUntilTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_PAUSED_UNTIL_TIME_ASC',
  SenderProfilesByLawyerIdMaxPausedUntilTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_PAUSED_UNTIL_TIME_DESC',
  SenderProfilesByLawyerIdMaxPriorityAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_PRIORITY_ASC',
  SenderProfilesByLawyerIdMaxPriorityDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_PRIORITY_DESC',
  SenderProfilesByLawyerIdMaxProfilePictureIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_PROFILE_PICTURE_ID_ASC',
  SenderProfilesByLawyerIdMaxProfilePictureIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_PROFILE_PICTURE_ID_DESC',
  SenderProfilesByLawyerIdMaxProfilePictureStatusAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_PROFILE_PICTURE_STATUS_ASC',
  SenderProfilesByLawyerIdMaxProfilePictureStatusDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_PROFILE_PICTURE_STATUS_DESC',
  SenderProfilesByLawyerIdMaxProfileTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_PROFILE_TYPE_ASC',
  SenderProfilesByLawyerIdMaxProfileTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_PROFILE_TYPE_DESC',
  SenderProfilesByLawyerIdMaxReachedDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_REACHED_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdMaxReachedDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_REACHED_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdMaxSignatureHtmlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_SIGNATURE_HTML_ASC',
  SenderProfilesByLawyerIdMaxSignatureHtmlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_SIGNATURE_HTML_DESC',
  SenderProfilesByLawyerIdMaxTitleAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_TITLE_ASC',
  SenderProfilesByLawyerIdMaxTitleDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_TITLE_DESC',
  SenderProfilesByLawyerIdMaxUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdMaxUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdMaxUsernameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_USERNAME_ASC',
  SenderProfilesByLawyerIdMaxUsernameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_USERNAME_DESC',
  SenderProfilesByLawyerIdMaxVerificationTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_VERIFICATION_TYPE_ASC',
  SenderProfilesByLawyerIdMaxVerificationTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_VERIFICATION_TYPE_DESC',
  SenderProfilesByLawyerIdMaxWarmupInboxFilterIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_WARMUP_INBOX_FILTER_ID_ASC',
  SenderProfilesByLawyerIdMaxWarmupInboxFilterIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_WARMUP_INBOX_FILTER_ID_DESC',
  SenderProfilesByLawyerIdMaxWarmupInboxIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_WARMUP_INBOX_ID_ASC',
  SenderProfilesByLawyerIdMaxWarmupInboxIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_WARMUP_INBOX_ID_DESC',
  SenderProfilesByLawyerIdMaxWebsiteUrlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_WEBSITE_URL_ASC',
  SenderProfilesByLawyerIdMaxWebsiteUrlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MAX_WEBSITE_URL_DESC',
  SenderProfilesByLawyerIdMinBannedFromTalkingToGmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_BANNED_FROM_TALKING_TO_GMAIL_ASC',
  SenderProfilesByLawyerIdMinBannedFromTalkingToGmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_BANNED_FROM_TALKING_TO_GMAIL_DESC',
  SenderProfilesByLawyerIdMinBrandColorAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_BRAND_COLOR_ASC',
  SenderProfilesByLawyerIdMinBrandColorDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_BRAND_COLOR_DESC',
  SenderProfilesByLawyerIdMinCreatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  SenderProfilesByLawyerIdMinCreatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  SenderProfilesByLawyerIdMinCurrentDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_CURRENT_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdMinCurrentDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_CURRENT_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdMinCurrentDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_CURRENT_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdMinCurrentDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_CURRENT_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdMinDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdMinDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdMinDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdMinDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdMinDailyCapIncrementAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_DAILY_CAP_INCREMENT_ASC',
  SenderProfilesByLawyerIdMinDailyCapIncrementDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_DAILY_CAP_INCREMENT_DESC',
  SenderProfilesByLawyerIdMinEmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_EMAIL_ASC',
  SenderProfilesByLawyerIdMinEmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_EMAIL_DESC',
  SenderProfilesByLawyerIdMinFirmNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_FIRM_NAME_ASC',
  SenderProfilesByLawyerIdMinFirmNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_FIRM_NAME_DESC',
  SenderProfilesByLawyerIdMinFirstNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_FIRST_NAME_ASC',
  SenderProfilesByLawyerIdMinFirstNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_FIRST_NAME_DESC',
  SenderProfilesByLawyerIdMinIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_ID_ASC',
  SenderProfilesByLawyerIdMinIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_ID_DESC',
  SenderProfilesByLawyerIdMinIsActiveAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_IS_ACTIVE_ASC',
  SenderProfilesByLawyerIdMinIsActiveDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_IS_ACTIVE_DESC',
  SenderProfilesByLawyerIdMinIsActiveUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_IS_ACTIVE_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdMinIsActiveUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_IS_ACTIVE_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdMinIsAliasAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_IS_ALIAS_ASC',
  SenderProfilesByLawyerIdMinIsAliasDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_IS_ALIAS_DESC',
  SenderProfilesByLawyerIdMinIsImpromptuAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_IS_IMPROMPTU_ASC',
  SenderProfilesByLawyerIdMinIsImpromptuDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_IS_IMPROMPTU_DESC',
  SenderProfilesByLawyerIdMinIsImpromptuUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_IS_IMPROMPTU_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdMinIsImpromptuUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_IS_IMPROMPTU_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdMinIsPrimaryAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_IS_PRIMARY_ASC',
  SenderProfilesByLawyerIdMinIsPrimaryDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_IS_PRIMARY_DESC',
  SenderProfilesByLawyerIdMinLastNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_LAST_NAME_ASC',
  SenderProfilesByLawyerIdMinLastNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_LAST_NAME_DESC',
  SenderProfilesByLawyerIdMinLawyerIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  SenderProfilesByLawyerIdMinLawyerIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  SenderProfilesByLawyerIdMinLogoSrcAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_LOGO_SRC_ASC',
  SenderProfilesByLawyerIdMinLogoSrcDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_LOGO_SRC_DESC',
  SenderProfilesByLawyerIdMinPausedUntilTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_PAUSED_UNTIL_TIME_ASC',
  SenderProfilesByLawyerIdMinPausedUntilTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_PAUSED_UNTIL_TIME_DESC',
  SenderProfilesByLawyerIdMinPriorityAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_PRIORITY_ASC',
  SenderProfilesByLawyerIdMinPriorityDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_PRIORITY_DESC',
  SenderProfilesByLawyerIdMinProfilePictureIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_PROFILE_PICTURE_ID_ASC',
  SenderProfilesByLawyerIdMinProfilePictureIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_PROFILE_PICTURE_ID_DESC',
  SenderProfilesByLawyerIdMinProfilePictureStatusAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_PROFILE_PICTURE_STATUS_ASC',
  SenderProfilesByLawyerIdMinProfilePictureStatusDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_PROFILE_PICTURE_STATUS_DESC',
  SenderProfilesByLawyerIdMinProfileTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_PROFILE_TYPE_ASC',
  SenderProfilesByLawyerIdMinProfileTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_PROFILE_TYPE_DESC',
  SenderProfilesByLawyerIdMinReachedDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_REACHED_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdMinReachedDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_REACHED_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdMinSignatureHtmlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_SIGNATURE_HTML_ASC',
  SenderProfilesByLawyerIdMinSignatureHtmlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_SIGNATURE_HTML_DESC',
  SenderProfilesByLawyerIdMinTitleAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_TITLE_ASC',
  SenderProfilesByLawyerIdMinTitleDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_TITLE_DESC',
  SenderProfilesByLawyerIdMinUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdMinUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdMinUsernameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_USERNAME_ASC',
  SenderProfilesByLawyerIdMinUsernameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_USERNAME_DESC',
  SenderProfilesByLawyerIdMinVerificationTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_VERIFICATION_TYPE_ASC',
  SenderProfilesByLawyerIdMinVerificationTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_VERIFICATION_TYPE_DESC',
  SenderProfilesByLawyerIdMinWarmupInboxFilterIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_WARMUP_INBOX_FILTER_ID_ASC',
  SenderProfilesByLawyerIdMinWarmupInboxFilterIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_WARMUP_INBOX_FILTER_ID_DESC',
  SenderProfilesByLawyerIdMinWarmupInboxIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_WARMUP_INBOX_ID_ASC',
  SenderProfilesByLawyerIdMinWarmupInboxIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_WARMUP_INBOX_ID_DESC',
  SenderProfilesByLawyerIdMinWebsiteUrlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_WEBSITE_URL_ASC',
  SenderProfilesByLawyerIdMinWebsiteUrlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_MIN_WEBSITE_URL_DESC',
  SenderProfilesByLawyerIdStddevPopulationBannedFromTalkingToGmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_BANNED_FROM_TALKING_TO_GMAIL_ASC',
  SenderProfilesByLawyerIdStddevPopulationBannedFromTalkingToGmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_BANNED_FROM_TALKING_TO_GMAIL_DESC',
  SenderProfilesByLawyerIdStddevPopulationBrandColorAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_BRAND_COLOR_ASC',
  SenderProfilesByLawyerIdStddevPopulationBrandColorDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_BRAND_COLOR_DESC',
  SenderProfilesByLawyerIdStddevPopulationCreatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  SenderProfilesByLawyerIdStddevPopulationCreatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  SenderProfilesByLawyerIdStddevPopulationCurrentDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_CURRENT_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdStddevPopulationCurrentDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_CURRENT_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdStddevPopulationCurrentDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_CURRENT_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdStddevPopulationCurrentDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_CURRENT_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdStddevPopulationDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdStddevPopulationDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdStddevPopulationDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdStddevPopulationDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdStddevPopulationDailyCapIncrementAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_DAILY_CAP_INCREMENT_ASC',
  SenderProfilesByLawyerIdStddevPopulationDailyCapIncrementDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_DAILY_CAP_INCREMENT_DESC',
  SenderProfilesByLawyerIdStddevPopulationEmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_ASC',
  SenderProfilesByLawyerIdStddevPopulationEmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_EMAIL_DESC',
  SenderProfilesByLawyerIdStddevPopulationFirmNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_FIRM_NAME_ASC',
  SenderProfilesByLawyerIdStddevPopulationFirmNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_FIRM_NAME_DESC',
  SenderProfilesByLawyerIdStddevPopulationFirstNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_FIRST_NAME_ASC',
  SenderProfilesByLawyerIdStddevPopulationFirstNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_FIRST_NAME_DESC',
  SenderProfilesByLawyerIdStddevPopulationIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  SenderProfilesByLawyerIdStddevPopulationIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  SenderProfilesByLawyerIdStddevPopulationIsActiveAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_IS_ACTIVE_ASC',
  SenderProfilesByLawyerIdStddevPopulationIsActiveDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_IS_ACTIVE_DESC',
  SenderProfilesByLawyerIdStddevPopulationIsActiveUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_IS_ACTIVE_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdStddevPopulationIsActiveUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_IS_ACTIVE_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdStddevPopulationIsAliasAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_IS_ALIAS_ASC',
  SenderProfilesByLawyerIdStddevPopulationIsAliasDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_IS_ALIAS_DESC',
  SenderProfilesByLawyerIdStddevPopulationIsImpromptuAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_IS_IMPROMPTU_ASC',
  SenderProfilesByLawyerIdStddevPopulationIsImpromptuDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_IS_IMPROMPTU_DESC',
  SenderProfilesByLawyerIdStddevPopulationIsImpromptuUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_IS_IMPROMPTU_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdStddevPopulationIsImpromptuUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_IS_IMPROMPTU_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdStddevPopulationIsPrimaryAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_IS_PRIMARY_ASC',
  SenderProfilesByLawyerIdStddevPopulationIsPrimaryDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_IS_PRIMARY_DESC',
  SenderProfilesByLawyerIdStddevPopulationLastNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_LAST_NAME_ASC',
  SenderProfilesByLawyerIdStddevPopulationLastNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_LAST_NAME_DESC',
  SenderProfilesByLawyerIdStddevPopulationLawyerIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  SenderProfilesByLawyerIdStddevPopulationLawyerIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  SenderProfilesByLawyerIdStddevPopulationLogoSrcAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_LOGO_SRC_ASC',
  SenderProfilesByLawyerIdStddevPopulationLogoSrcDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_LOGO_SRC_DESC',
  SenderProfilesByLawyerIdStddevPopulationPausedUntilTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PAUSED_UNTIL_TIME_ASC',
  SenderProfilesByLawyerIdStddevPopulationPausedUntilTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PAUSED_UNTIL_TIME_DESC',
  SenderProfilesByLawyerIdStddevPopulationPriorityAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PRIORITY_ASC',
  SenderProfilesByLawyerIdStddevPopulationPriorityDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PRIORITY_DESC',
  SenderProfilesByLawyerIdStddevPopulationProfilePictureIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PROFILE_PICTURE_ID_ASC',
  SenderProfilesByLawyerIdStddevPopulationProfilePictureIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PROFILE_PICTURE_ID_DESC',
  SenderProfilesByLawyerIdStddevPopulationProfilePictureStatusAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PROFILE_PICTURE_STATUS_ASC',
  SenderProfilesByLawyerIdStddevPopulationProfilePictureStatusDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PROFILE_PICTURE_STATUS_DESC',
  SenderProfilesByLawyerIdStddevPopulationProfileTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PROFILE_TYPE_ASC',
  SenderProfilesByLawyerIdStddevPopulationProfileTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_PROFILE_TYPE_DESC',
  SenderProfilesByLawyerIdStddevPopulationReachedDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_REACHED_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdStddevPopulationReachedDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_REACHED_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdStddevPopulationSignatureHtmlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_SIGNATURE_HTML_ASC',
  SenderProfilesByLawyerIdStddevPopulationSignatureHtmlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_SIGNATURE_HTML_DESC',
  SenderProfilesByLawyerIdStddevPopulationTitleAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_TITLE_ASC',
  SenderProfilesByLawyerIdStddevPopulationTitleDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_TITLE_DESC',
  SenderProfilesByLawyerIdStddevPopulationUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdStddevPopulationUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdStddevPopulationUsernameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_USERNAME_ASC',
  SenderProfilesByLawyerIdStddevPopulationUsernameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_USERNAME_DESC',
  SenderProfilesByLawyerIdStddevPopulationVerificationTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_VERIFICATION_TYPE_ASC',
  SenderProfilesByLawyerIdStddevPopulationVerificationTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_VERIFICATION_TYPE_DESC',
  SenderProfilesByLawyerIdStddevPopulationWarmupInboxFilterIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_WARMUP_INBOX_FILTER_ID_ASC',
  SenderProfilesByLawyerIdStddevPopulationWarmupInboxFilterIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_WARMUP_INBOX_FILTER_ID_DESC',
  SenderProfilesByLawyerIdStddevPopulationWarmupInboxIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_WARMUP_INBOX_ID_ASC',
  SenderProfilesByLawyerIdStddevPopulationWarmupInboxIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_WARMUP_INBOX_ID_DESC',
  SenderProfilesByLawyerIdStddevPopulationWebsiteUrlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_WEBSITE_URL_ASC',
  SenderProfilesByLawyerIdStddevPopulationWebsiteUrlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_POPULATION_WEBSITE_URL_DESC',
  SenderProfilesByLawyerIdStddevSampleBannedFromTalkingToGmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_BANNED_FROM_TALKING_TO_GMAIL_ASC',
  SenderProfilesByLawyerIdStddevSampleBannedFromTalkingToGmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_BANNED_FROM_TALKING_TO_GMAIL_DESC',
  SenderProfilesByLawyerIdStddevSampleBrandColorAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_BRAND_COLOR_ASC',
  SenderProfilesByLawyerIdStddevSampleBrandColorDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_BRAND_COLOR_DESC',
  SenderProfilesByLawyerIdStddevSampleCreatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  SenderProfilesByLawyerIdStddevSampleCreatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  SenderProfilesByLawyerIdStddevSampleCurrentDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_CURRENT_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdStddevSampleCurrentDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_CURRENT_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdStddevSampleCurrentDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_CURRENT_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdStddevSampleCurrentDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_CURRENT_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdStddevSampleDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdStddevSampleDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdStddevSampleDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdStddevSampleDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdStddevSampleDailyCapIncrementAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_DAILY_CAP_INCREMENT_ASC',
  SenderProfilesByLawyerIdStddevSampleDailyCapIncrementDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_DAILY_CAP_INCREMENT_DESC',
  SenderProfilesByLawyerIdStddevSampleEmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_ASC',
  SenderProfilesByLawyerIdStddevSampleEmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_EMAIL_DESC',
  SenderProfilesByLawyerIdStddevSampleFirmNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_FIRM_NAME_ASC',
  SenderProfilesByLawyerIdStddevSampleFirmNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_FIRM_NAME_DESC',
  SenderProfilesByLawyerIdStddevSampleFirstNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_FIRST_NAME_ASC',
  SenderProfilesByLawyerIdStddevSampleFirstNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_FIRST_NAME_DESC',
  SenderProfilesByLawyerIdStddevSampleIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  SenderProfilesByLawyerIdStddevSampleIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  SenderProfilesByLawyerIdStddevSampleIsActiveAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_ACTIVE_ASC',
  SenderProfilesByLawyerIdStddevSampleIsActiveDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_ACTIVE_DESC',
  SenderProfilesByLawyerIdStddevSampleIsActiveUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_ACTIVE_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdStddevSampleIsActiveUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_ACTIVE_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdStddevSampleIsAliasAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_ALIAS_ASC',
  SenderProfilesByLawyerIdStddevSampleIsAliasDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_ALIAS_DESC',
  SenderProfilesByLawyerIdStddevSampleIsImpromptuAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_IMPROMPTU_ASC',
  SenderProfilesByLawyerIdStddevSampleIsImpromptuDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_IMPROMPTU_DESC',
  SenderProfilesByLawyerIdStddevSampleIsImpromptuUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_IMPROMPTU_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdStddevSampleIsImpromptuUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_IMPROMPTU_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdStddevSampleIsPrimaryAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_PRIMARY_ASC',
  SenderProfilesByLawyerIdStddevSampleIsPrimaryDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_IS_PRIMARY_DESC',
  SenderProfilesByLawyerIdStddevSampleLastNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_LAST_NAME_ASC',
  SenderProfilesByLawyerIdStddevSampleLastNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_LAST_NAME_DESC',
  SenderProfilesByLawyerIdStddevSampleLawyerIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  SenderProfilesByLawyerIdStddevSampleLawyerIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  SenderProfilesByLawyerIdStddevSampleLogoSrcAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_LOGO_SRC_ASC',
  SenderProfilesByLawyerIdStddevSampleLogoSrcDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_LOGO_SRC_DESC',
  SenderProfilesByLawyerIdStddevSamplePausedUntilTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PAUSED_UNTIL_TIME_ASC',
  SenderProfilesByLawyerIdStddevSamplePausedUntilTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PAUSED_UNTIL_TIME_DESC',
  SenderProfilesByLawyerIdStddevSamplePriorityAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PRIORITY_ASC',
  SenderProfilesByLawyerIdStddevSamplePriorityDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PRIORITY_DESC',
  SenderProfilesByLawyerIdStddevSampleProfilePictureIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PROFILE_PICTURE_ID_ASC',
  SenderProfilesByLawyerIdStddevSampleProfilePictureIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PROFILE_PICTURE_ID_DESC',
  SenderProfilesByLawyerIdStddevSampleProfilePictureStatusAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PROFILE_PICTURE_STATUS_ASC',
  SenderProfilesByLawyerIdStddevSampleProfilePictureStatusDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PROFILE_PICTURE_STATUS_DESC',
  SenderProfilesByLawyerIdStddevSampleProfileTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PROFILE_TYPE_ASC',
  SenderProfilesByLawyerIdStddevSampleProfileTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_PROFILE_TYPE_DESC',
  SenderProfilesByLawyerIdStddevSampleReachedDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_REACHED_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdStddevSampleReachedDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_REACHED_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdStddevSampleSignatureHtmlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_SIGNATURE_HTML_ASC',
  SenderProfilesByLawyerIdStddevSampleSignatureHtmlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_SIGNATURE_HTML_DESC',
  SenderProfilesByLawyerIdStddevSampleTitleAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_TITLE_ASC',
  SenderProfilesByLawyerIdStddevSampleTitleDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_TITLE_DESC',
  SenderProfilesByLawyerIdStddevSampleUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdStddevSampleUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdStddevSampleUsernameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_USERNAME_ASC',
  SenderProfilesByLawyerIdStddevSampleUsernameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_USERNAME_DESC',
  SenderProfilesByLawyerIdStddevSampleVerificationTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_VERIFICATION_TYPE_ASC',
  SenderProfilesByLawyerIdStddevSampleVerificationTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_VERIFICATION_TYPE_DESC',
  SenderProfilesByLawyerIdStddevSampleWarmupInboxFilterIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_WARMUP_INBOX_FILTER_ID_ASC',
  SenderProfilesByLawyerIdStddevSampleWarmupInboxFilterIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_WARMUP_INBOX_FILTER_ID_DESC',
  SenderProfilesByLawyerIdStddevSampleWarmupInboxIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_WARMUP_INBOX_ID_ASC',
  SenderProfilesByLawyerIdStddevSampleWarmupInboxIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_WARMUP_INBOX_ID_DESC',
  SenderProfilesByLawyerIdStddevSampleWebsiteUrlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_WEBSITE_URL_ASC',
  SenderProfilesByLawyerIdStddevSampleWebsiteUrlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_STDDEV_SAMPLE_WEBSITE_URL_DESC',
  SenderProfilesByLawyerIdSumBannedFromTalkingToGmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_BANNED_FROM_TALKING_TO_GMAIL_ASC',
  SenderProfilesByLawyerIdSumBannedFromTalkingToGmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_BANNED_FROM_TALKING_TO_GMAIL_DESC',
  SenderProfilesByLawyerIdSumBrandColorAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_BRAND_COLOR_ASC',
  SenderProfilesByLawyerIdSumBrandColorDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_BRAND_COLOR_DESC',
  SenderProfilesByLawyerIdSumCreatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  SenderProfilesByLawyerIdSumCreatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  SenderProfilesByLawyerIdSumCurrentDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_CURRENT_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdSumCurrentDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_CURRENT_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdSumCurrentDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_CURRENT_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdSumCurrentDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_CURRENT_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdSumDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdSumDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdSumDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdSumDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdSumDailyCapIncrementAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_DAILY_CAP_INCREMENT_ASC',
  SenderProfilesByLawyerIdSumDailyCapIncrementDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_DAILY_CAP_INCREMENT_DESC',
  SenderProfilesByLawyerIdSumEmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_EMAIL_ASC',
  SenderProfilesByLawyerIdSumEmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_EMAIL_DESC',
  SenderProfilesByLawyerIdSumFirmNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_FIRM_NAME_ASC',
  SenderProfilesByLawyerIdSumFirmNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_FIRM_NAME_DESC',
  SenderProfilesByLawyerIdSumFirstNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_FIRST_NAME_ASC',
  SenderProfilesByLawyerIdSumFirstNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_FIRST_NAME_DESC',
  SenderProfilesByLawyerIdSumIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_ID_ASC',
  SenderProfilesByLawyerIdSumIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_ID_DESC',
  SenderProfilesByLawyerIdSumIsActiveAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_IS_ACTIVE_ASC',
  SenderProfilesByLawyerIdSumIsActiveDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_IS_ACTIVE_DESC',
  SenderProfilesByLawyerIdSumIsActiveUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_IS_ACTIVE_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdSumIsActiveUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_IS_ACTIVE_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdSumIsAliasAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_IS_ALIAS_ASC',
  SenderProfilesByLawyerIdSumIsAliasDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_IS_ALIAS_DESC',
  SenderProfilesByLawyerIdSumIsImpromptuAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_IS_IMPROMPTU_ASC',
  SenderProfilesByLawyerIdSumIsImpromptuDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_IS_IMPROMPTU_DESC',
  SenderProfilesByLawyerIdSumIsImpromptuUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_IS_IMPROMPTU_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdSumIsImpromptuUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_IS_IMPROMPTU_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdSumIsPrimaryAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_IS_PRIMARY_ASC',
  SenderProfilesByLawyerIdSumIsPrimaryDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_IS_PRIMARY_DESC',
  SenderProfilesByLawyerIdSumLastNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_LAST_NAME_ASC',
  SenderProfilesByLawyerIdSumLastNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_LAST_NAME_DESC',
  SenderProfilesByLawyerIdSumLawyerIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  SenderProfilesByLawyerIdSumLawyerIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  SenderProfilesByLawyerIdSumLogoSrcAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_LOGO_SRC_ASC',
  SenderProfilesByLawyerIdSumLogoSrcDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_LOGO_SRC_DESC',
  SenderProfilesByLawyerIdSumPausedUntilTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_PAUSED_UNTIL_TIME_ASC',
  SenderProfilesByLawyerIdSumPausedUntilTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_PAUSED_UNTIL_TIME_DESC',
  SenderProfilesByLawyerIdSumPriorityAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_PRIORITY_ASC',
  SenderProfilesByLawyerIdSumPriorityDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_PRIORITY_DESC',
  SenderProfilesByLawyerIdSumProfilePictureIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_PROFILE_PICTURE_ID_ASC',
  SenderProfilesByLawyerIdSumProfilePictureIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_PROFILE_PICTURE_ID_DESC',
  SenderProfilesByLawyerIdSumProfilePictureStatusAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_PROFILE_PICTURE_STATUS_ASC',
  SenderProfilesByLawyerIdSumProfilePictureStatusDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_PROFILE_PICTURE_STATUS_DESC',
  SenderProfilesByLawyerIdSumProfileTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_PROFILE_TYPE_ASC',
  SenderProfilesByLawyerIdSumProfileTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_PROFILE_TYPE_DESC',
  SenderProfilesByLawyerIdSumReachedDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_REACHED_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdSumReachedDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_REACHED_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdSumSignatureHtmlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_SIGNATURE_HTML_ASC',
  SenderProfilesByLawyerIdSumSignatureHtmlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_SIGNATURE_HTML_DESC',
  SenderProfilesByLawyerIdSumTitleAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_TITLE_ASC',
  SenderProfilesByLawyerIdSumTitleDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_TITLE_DESC',
  SenderProfilesByLawyerIdSumUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdSumUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdSumUsernameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_USERNAME_ASC',
  SenderProfilesByLawyerIdSumUsernameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_USERNAME_DESC',
  SenderProfilesByLawyerIdSumVerificationTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_VERIFICATION_TYPE_ASC',
  SenderProfilesByLawyerIdSumVerificationTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_VERIFICATION_TYPE_DESC',
  SenderProfilesByLawyerIdSumWarmupInboxFilterIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_WARMUP_INBOX_FILTER_ID_ASC',
  SenderProfilesByLawyerIdSumWarmupInboxFilterIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_WARMUP_INBOX_FILTER_ID_DESC',
  SenderProfilesByLawyerIdSumWarmupInboxIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_WARMUP_INBOX_ID_ASC',
  SenderProfilesByLawyerIdSumWarmupInboxIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_WARMUP_INBOX_ID_DESC',
  SenderProfilesByLawyerIdSumWebsiteUrlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_WEBSITE_URL_ASC',
  SenderProfilesByLawyerIdSumWebsiteUrlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_SUM_WEBSITE_URL_DESC',
  SenderProfilesByLawyerIdVariancePopulationBannedFromTalkingToGmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_BANNED_FROM_TALKING_TO_GMAIL_ASC',
  SenderProfilesByLawyerIdVariancePopulationBannedFromTalkingToGmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_BANNED_FROM_TALKING_TO_GMAIL_DESC',
  SenderProfilesByLawyerIdVariancePopulationBrandColorAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_BRAND_COLOR_ASC',
  SenderProfilesByLawyerIdVariancePopulationBrandColorDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_BRAND_COLOR_DESC',
  SenderProfilesByLawyerIdVariancePopulationCreatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  SenderProfilesByLawyerIdVariancePopulationCreatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  SenderProfilesByLawyerIdVariancePopulationCurrentDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_CURRENT_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdVariancePopulationCurrentDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_CURRENT_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdVariancePopulationCurrentDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_CURRENT_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdVariancePopulationCurrentDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_CURRENT_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdVariancePopulationDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdVariancePopulationDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdVariancePopulationDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdVariancePopulationDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdVariancePopulationDailyCapIncrementAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_DAILY_CAP_INCREMENT_ASC',
  SenderProfilesByLawyerIdVariancePopulationDailyCapIncrementDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_DAILY_CAP_INCREMENT_DESC',
  SenderProfilesByLawyerIdVariancePopulationEmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_ASC',
  SenderProfilesByLawyerIdVariancePopulationEmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_EMAIL_DESC',
  SenderProfilesByLawyerIdVariancePopulationFirmNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_FIRM_NAME_ASC',
  SenderProfilesByLawyerIdVariancePopulationFirmNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_FIRM_NAME_DESC',
  SenderProfilesByLawyerIdVariancePopulationFirstNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_FIRST_NAME_ASC',
  SenderProfilesByLawyerIdVariancePopulationFirstNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_FIRST_NAME_DESC',
  SenderProfilesByLawyerIdVariancePopulationIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  SenderProfilesByLawyerIdVariancePopulationIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  SenderProfilesByLawyerIdVariancePopulationIsActiveAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_ACTIVE_ASC',
  SenderProfilesByLawyerIdVariancePopulationIsActiveDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_ACTIVE_DESC',
  SenderProfilesByLawyerIdVariancePopulationIsActiveUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_ACTIVE_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdVariancePopulationIsActiveUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_ACTIVE_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdVariancePopulationIsAliasAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_ALIAS_ASC',
  SenderProfilesByLawyerIdVariancePopulationIsAliasDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_ALIAS_DESC',
  SenderProfilesByLawyerIdVariancePopulationIsImpromptuAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_IMPROMPTU_ASC',
  SenderProfilesByLawyerIdVariancePopulationIsImpromptuDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_IMPROMPTU_DESC',
  SenderProfilesByLawyerIdVariancePopulationIsImpromptuUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_IMPROMPTU_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdVariancePopulationIsImpromptuUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_IMPROMPTU_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdVariancePopulationIsPrimaryAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_PRIMARY_ASC',
  SenderProfilesByLawyerIdVariancePopulationIsPrimaryDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_IS_PRIMARY_DESC',
  SenderProfilesByLawyerIdVariancePopulationLastNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_LAST_NAME_ASC',
  SenderProfilesByLawyerIdVariancePopulationLastNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_LAST_NAME_DESC',
  SenderProfilesByLawyerIdVariancePopulationLawyerIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  SenderProfilesByLawyerIdVariancePopulationLawyerIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  SenderProfilesByLawyerIdVariancePopulationLogoSrcAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_LOGO_SRC_ASC',
  SenderProfilesByLawyerIdVariancePopulationLogoSrcDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_LOGO_SRC_DESC',
  SenderProfilesByLawyerIdVariancePopulationPausedUntilTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PAUSED_UNTIL_TIME_ASC',
  SenderProfilesByLawyerIdVariancePopulationPausedUntilTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PAUSED_UNTIL_TIME_DESC',
  SenderProfilesByLawyerIdVariancePopulationPriorityAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PRIORITY_ASC',
  SenderProfilesByLawyerIdVariancePopulationPriorityDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PRIORITY_DESC',
  SenderProfilesByLawyerIdVariancePopulationProfilePictureIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PROFILE_PICTURE_ID_ASC',
  SenderProfilesByLawyerIdVariancePopulationProfilePictureIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PROFILE_PICTURE_ID_DESC',
  SenderProfilesByLawyerIdVariancePopulationProfilePictureStatusAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PROFILE_PICTURE_STATUS_ASC',
  SenderProfilesByLawyerIdVariancePopulationProfilePictureStatusDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PROFILE_PICTURE_STATUS_DESC',
  SenderProfilesByLawyerIdVariancePopulationProfileTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PROFILE_TYPE_ASC',
  SenderProfilesByLawyerIdVariancePopulationProfileTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_PROFILE_TYPE_DESC',
  SenderProfilesByLawyerIdVariancePopulationReachedDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_REACHED_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdVariancePopulationReachedDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_REACHED_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdVariancePopulationSignatureHtmlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_SIGNATURE_HTML_ASC',
  SenderProfilesByLawyerIdVariancePopulationSignatureHtmlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_SIGNATURE_HTML_DESC',
  SenderProfilesByLawyerIdVariancePopulationTitleAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_TITLE_ASC',
  SenderProfilesByLawyerIdVariancePopulationTitleDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_TITLE_DESC',
  SenderProfilesByLawyerIdVariancePopulationUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdVariancePopulationUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdVariancePopulationUsernameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_USERNAME_ASC',
  SenderProfilesByLawyerIdVariancePopulationUsernameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_USERNAME_DESC',
  SenderProfilesByLawyerIdVariancePopulationVerificationTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_VERIFICATION_TYPE_ASC',
  SenderProfilesByLawyerIdVariancePopulationVerificationTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_VERIFICATION_TYPE_DESC',
  SenderProfilesByLawyerIdVariancePopulationWarmupInboxFilterIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_WARMUP_INBOX_FILTER_ID_ASC',
  SenderProfilesByLawyerIdVariancePopulationWarmupInboxFilterIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_WARMUP_INBOX_FILTER_ID_DESC',
  SenderProfilesByLawyerIdVariancePopulationWarmupInboxIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_WARMUP_INBOX_ID_ASC',
  SenderProfilesByLawyerIdVariancePopulationWarmupInboxIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_WARMUP_INBOX_ID_DESC',
  SenderProfilesByLawyerIdVariancePopulationWebsiteUrlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_WEBSITE_URL_ASC',
  SenderProfilesByLawyerIdVariancePopulationWebsiteUrlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_POPULATION_WEBSITE_URL_DESC',
  SenderProfilesByLawyerIdVarianceSampleBannedFromTalkingToGmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_BANNED_FROM_TALKING_TO_GMAIL_ASC',
  SenderProfilesByLawyerIdVarianceSampleBannedFromTalkingToGmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_BANNED_FROM_TALKING_TO_GMAIL_DESC',
  SenderProfilesByLawyerIdVarianceSampleBrandColorAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_BRAND_COLOR_ASC',
  SenderProfilesByLawyerIdVarianceSampleBrandColorDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_BRAND_COLOR_DESC',
  SenderProfilesByLawyerIdVarianceSampleCreatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  SenderProfilesByLawyerIdVarianceSampleCreatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  SenderProfilesByLawyerIdVarianceSampleCurrentDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_CURRENT_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdVarianceSampleCurrentDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_CURRENT_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdVarianceSampleCurrentDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_CURRENT_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdVarianceSampleCurrentDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_CURRENT_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdVarianceSampleDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdVarianceSampleDailyCapByEspAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_DAILY_CAP_BY_ESP_ASC',
  SenderProfilesByLawyerIdVarianceSampleDailyCapByEspDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_DAILY_CAP_BY_ESP_DESC',
  SenderProfilesByLawyerIdVarianceSampleDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdVarianceSampleDailyCapIncrementAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_DAILY_CAP_INCREMENT_ASC',
  SenderProfilesByLawyerIdVarianceSampleDailyCapIncrementDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_DAILY_CAP_INCREMENT_DESC',
  SenderProfilesByLawyerIdVarianceSampleEmailAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_ASC',
  SenderProfilesByLawyerIdVarianceSampleEmailDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_EMAIL_DESC',
  SenderProfilesByLawyerIdVarianceSampleFirmNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_FIRM_NAME_ASC',
  SenderProfilesByLawyerIdVarianceSampleFirmNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_FIRM_NAME_DESC',
  SenderProfilesByLawyerIdVarianceSampleFirstNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_FIRST_NAME_ASC',
  SenderProfilesByLawyerIdVarianceSampleFirstNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_FIRST_NAME_DESC',
  SenderProfilesByLawyerIdVarianceSampleIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  SenderProfilesByLawyerIdVarianceSampleIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  SenderProfilesByLawyerIdVarianceSampleIsActiveAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_ACTIVE_ASC',
  SenderProfilesByLawyerIdVarianceSampleIsActiveDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_ACTIVE_DESC',
  SenderProfilesByLawyerIdVarianceSampleIsActiveUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_ACTIVE_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdVarianceSampleIsActiveUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_ACTIVE_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdVarianceSampleIsAliasAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_ALIAS_ASC',
  SenderProfilesByLawyerIdVarianceSampleIsAliasDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_ALIAS_DESC',
  SenderProfilesByLawyerIdVarianceSampleIsImpromptuAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_IMPROMPTU_ASC',
  SenderProfilesByLawyerIdVarianceSampleIsImpromptuDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_IMPROMPTU_DESC',
  SenderProfilesByLawyerIdVarianceSampleIsImpromptuUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_IMPROMPTU_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdVarianceSampleIsImpromptuUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_IMPROMPTU_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdVarianceSampleIsPrimaryAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_PRIMARY_ASC',
  SenderProfilesByLawyerIdVarianceSampleIsPrimaryDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_IS_PRIMARY_DESC',
  SenderProfilesByLawyerIdVarianceSampleLastNameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAST_NAME_ASC',
  SenderProfilesByLawyerIdVarianceSampleLastNameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAST_NAME_DESC',
  SenderProfilesByLawyerIdVarianceSampleLawyerIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  SenderProfilesByLawyerIdVarianceSampleLawyerIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  SenderProfilesByLawyerIdVarianceSampleLogoSrcAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_LOGO_SRC_ASC',
  SenderProfilesByLawyerIdVarianceSampleLogoSrcDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_LOGO_SRC_DESC',
  SenderProfilesByLawyerIdVarianceSamplePausedUntilTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PAUSED_UNTIL_TIME_ASC',
  SenderProfilesByLawyerIdVarianceSamplePausedUntilTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PAUSED_UNTIL_TIME_DESC',
  SenderProfilesByLawyerIdVarianceSamplePriorityAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PRIORITY_ASC',
  SenderProfilesByLawyerIdVarianceSamplePriorityDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PRIORITY_DESC',
  SenderProfilesByLawyerIdVarianceSampleProfilePictureIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PROFILE_PICTURE_ID_ASC',
  SenderProfilesByLawyerIdVarianceSampleProfilePictureIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PROFILE_PICTURE_ID_DESC',
  SenderProfilesByLawyerIdVarianceSampleProfilePictureStatusAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PROFILE_PICTURE_STATUS_ASC',
  SenderProfilesByLawyerIdVarianceSampleProfilePictureStatusDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PROFILE_PICTURE_STATUS_DESC',
  SenderProfilesByLawyerIdVarianceSampleProfileTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PROFILE_TYPE_ASC',
  SenderProfilesByLawyerIdVarianceSampleProfileTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_PROFILE_TYPE_DESC',
  SenderProfilesByLawyerIdVarianceSampleReachedDailyCapAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_REACHED_DAILY_CAP_ASC',
  SenderProfilesByLawyerIdVarianceSampleReachedDailyCapDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_REACHED_DAILY_CAP_DESC',
  SenderProfilesByLawyerIdVarianceSampleSignatureHtmlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_SIGNATURE_HTML_ASC',
  SenderProfilesByLawyerIdVarianceSampleSignatureHtmlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_SIGNATURE_HTML_DESC',
  SenderProfilesByLawyerIdVarianceSampleTitleAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_TITLE_ASC',
  SenderProfilesByLawyerIdVarianceSampleTitleDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_TITLE_DESC',
  SenderProfilesByLawyerIdVarianceSampleUpdatedTimeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  SenderProfilesByLawyerIdVarianceSampleUpdatedTimeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  SenderProfilesByLawyerIdVarianceSampleUsernameAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_USERNAME_ASC',
  SenderProfilesByLawyerIdVarianceSampleUsernameDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_USERNAME_DESC',
  SenderProfilesByLawyerIdVarianceSampleVerificationTypeAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_VERIFICATION_TYPE_ASC',
  SenderProfilesByLawyerIdVarianceSampleVerificationTypeDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_VERIFICATION_TYPE_DESC',
  SenderProfilesByLawyerIdVarianceSampleWarmupInboxFilterIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_WARMUP_INBOX_FILTER_ID_ASC',
  SenderProfilesByLawyerIdVarianceSampleWarmupInboxFilterIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_WARMUP_INBOX_FILTER_ID_DESC',
  SenderProfilesByLawyerIdVarianceSampleWarmupInboxIdAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_WARMUP_INBOX_ID_ASC',
  SenderProfilesByLawyerIdVarianceSampleWarmupInboxIdDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_WARMUP_INBOX_ID_DESC',
  SenderProfilesByLawyerIdVarianceSampleWebsiteUrlAsc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_WEBSITE_URL_ASC',
  SenderProfilesByLawyerIdVarianceSampleWebsiteUrlDesc = 'SENDER_PROFILES_BY_LAWYER_ID_VARIANCE_SAMPLE_WEBSITE_URL_DESC',
  SignatureHtmlAsc = 'SIGNATURE_HTML_ASC',
  SignatureHtmlDesc = 'SIGNATURE_HTML_DESC',
  SlackChannelNameAsc = 'SLACK_CHANNEL_NAME_ASC',
  SlackChannelNameDesc = 'SLACK_CHANNEL_NAME_DESC',
  SlackClientIdAsc = 'SLACK_CLIENT_ID_ASC',
  SlackClientIdDesc = 'SLACK_CLIENT_ID_DESC',
  SlackClientSecretAsc = 'SLACK_CLIENT_SECRET_ASC',
  SlackClientSecretDesc = 'SLACK_CLIENT_SECRET_DESC',
  SlackConnectionsByLawyerIdAverageConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_CONNECTION_KEY_ASC',
  SlackConnectionsByLawyerIdAverageConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_CONNECTION_KEY_DESC',
  SlackConnectionsByLawyerIdAverageConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_CONNECTION_URL_ASC',
  SlackConnectionsByLawyerIdAverageConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_CONNECTION_URL_DESC',
  SlackConnectionsByLawyerIdAverageContactIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_ASC',
  SlackConnectionsByLawyerIdAverageContactIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_DESC',
  SlackConnectionsByLawyerIdAverageCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  SlackConnectionsByLawyerIdAverageCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  SlackConnectionsByLawyerIdAverageIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  SlackConnectionsByLawyerIdAverageIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  SlackConnectionsByLawyerIdAverageLawyerIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  SlackConnectionsByLawyerIdAverageLawyerIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  SlackConnectionsByLawyerIdAverageSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_APP_ID_ASC',
  SlackConnectionsByLawyerIdAverageSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_APP_ID_DESC',
  SlackConnectionsByLawyerIdAverageSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdAverageSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdAverageSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByLawyerIdAverageSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByLawyerIdAverageSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByLawyerIdAverageSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByLawyerIdAverageSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByLawyerIdAverageSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByLawyerIdAverageSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByLawyerIdAverageSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByLawyerIdAverageSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByLawyerIdAverageSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByLawyerIdAverageSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_TEAM_ID_ASC',
  SlackConnectionsByLawyerIdAverageSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_TEAM_ID_DESC',
  SlackConnectionsByLawyerIdAverageSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByLawyerIdAverageSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByLawyerIdAverageSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdAverageSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdAverageSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_USER_ID_ASC',
  SlackConnectionsByLawyerIdAverageSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_USER_ID_DESC',
  SlackConnectionsByLawyerIdAverageSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByLawyerIdAverageSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByLawyerIdAverageUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  SlackConnectionsByLawyerIdAverageUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  SlackConnectionsByLawyerIdCountAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_COUNT_ASC',
  SlackConnectionsByLawyerIdCountDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_COUNT_DESC',
  SlackConnectionsByLawyerIdDistinctCountConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_CONNECTION_KEY_ASC',
  SlackConnectionsByLawyerIdDistinctCountConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_CONNECTION_KEY_DESC',
  SlackConnectionsByLawyerIdDistinctCountConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_CONNECTION_URL_ASC',
  SlackConnectionsByLawyerIdDistinctCountConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_CONNECTION_URL_DESC',
  SlackConnectionsByLawyerIdDistinctCountContactIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  SlackConnectionsByLawyerIdDistinctCountContactIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  SlackConnectionsByLawyerIdDistinctCountCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  SlackConnectionsByLawyerIdDistinctCountCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  SlackConnectionsByLawyerIdDistinctCountIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  SlackConnectionsByLawyerIdDistinctCountIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  SlackConnectionsByLawyerIdDistinctCountLawyerIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  SlackConnectionsByLawyerIdDistinctCountLawyerIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  SlackConnectionsByLawyerIdDistinctCountSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_APP_ID_ASC',
  SlackConnectionsByLawyerIdDistinctCountSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_APP_ID_DESC',
  SlackConnectionsByLawyerIdDistinctCountSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdDistinctCountSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdDistinctCountSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByLawyerIdDistinctCountSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByLawyerIdDistinctCountSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByLawyerIdDistinctCountSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByLawyerIdDistinctCountSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByLawyerIdDistinctCountSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByLawyerIdDistinctCountSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByLawyerIdDistinctCountSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByLawyerIdDistinctCountSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByLawyerIdDistinctCountSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByLawyerIdDistinctCountSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_TEAM_ID_ASC',
  SlackConnectionsByLawyerIdDistinctCountSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_TEAM_ID_DESC',
  SlackConnectionsByLawyerIdDistinctCountSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByLawyerIdDistinctCountSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByLawyerIdDistinctCountSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdDistinctCountSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdDistinctCountSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_USER_ID_ASC',
  SlackConnectionsByLawyerIdDistinctCountSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_USER_ID_DESC',
  SlackConnectionsByLawyerIdDistinctCountSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByLawyerIdDistinctCountSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByLawyerIdDistinctCountUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  SlackConnectionsByLawyerIdDistinctCountUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  SlackConnectionsByLawyerIdMaxConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_CONNECTION_KEY_ASC',
  SlackConnectionsByLawyerIdMaxConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_CONNECTION_KEY_DESC',
  SlackConnectionsByLawyerIdMaxConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_CONNECTION_URL_ASC',
  SlackConnectionsByLawyerIdMaxConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_CONNECTION_URL_DESC',
  SlackConnectionsByLawyerIdMaxContactIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_CONTACT_ID_ASC',
  SlackConnectionsByLawyerIdMaxContactIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_CONTACT_ID_DESC',
  SlackConnectionsByLawyerIdMaxCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  SlackConnectionsByLawyerIdMaxCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  SlackConnectionsByLawyerIdMaxIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_ID_ASC',
  SlackConnectionsByLawyerIdMaxIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_ID_DESC',
  SlackConnectionsByLawyerIdMaxLawyerIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  SlackConnectionsByLawyerIdMaxLawyerIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  SlackConnectionsByLawyerIdMaxSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_APP_ID_ASC',
  SlackConnectionsByLawyerIdMaxSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_APP_ID_DESC',
  SlackConnectionsByLawyerIdMaxSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdMaxSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdMaxSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByLawyerIdMaxSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByLawyerIdMaxSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByLawyerIdMaxSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByLawyerIdMaxSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByLawyerIdMaxSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByLawyerIdMaxSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByLawyerIdMaxSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByLawyerIdMaxSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByLawyerIdMaxSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByLawyerIdMaxSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_TEAM_ID_ASC',
  SlackConnectionsByLawyerIdMaxSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_TEAM_ID_DESC',
  SlackConnectionsByLawyerIdMaxSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByLawyerIdMaxSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByLawyerIdMaxSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdMaxSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdMaxSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_USER_ID_ASC',
  SlackConnectionsByLawyerIdMaxSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_USER_ID_DESC',
  SlackConnectionsByLawyerIdMaxSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByLawyerIdMaxSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByLawyerIdMaxUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  SlackConnectionsByLawyerIdMaxUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  SlackConnectionsByLawyerIdMinConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_CONNECTION_KEY_ASC',
  SlackConnectionsByLawyerIdMinConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_CONNECTION_KEY_DESC',
  SlackConnectionsByLawyerIdMinConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_CONNECTION_URL_ASC',
  SlackConnectionsByLawyerIdMinConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_CONNECTION_URL_DESC',
  SlackConnectionsByLawyerIdMinContactIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_CONTACT_ID_ASC',
  SlackConnectionsByLawyerIdMinContactIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_CONTACT_ID_DESC',
  SlackConnectionsByLawyerIdMinCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  SlackConnectionsByLawyerIdMinCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  SlackConnectionsByLawyerIdMinIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_ID_ASC',
  SlackConnectionsByLawyerIdMinIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_ID_DESC',
  SlackConnectionsByLawyerIdMinLawyerIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  SlackConnectionsByLawyerIdMinLawyerIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  SlackConnectionsByLawyerIdMinSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_APP_ID_ASC',
  SlackConnectionsByLawyerIdMinSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_APP_ID_DESC',
  SlackConnectionsByLawyerIdMinSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdMinSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdMinSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByLawyerIdMinSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByLawyerIdMinSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByLawyerIdMinSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByLawyerIdMinSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByLawyerIdMinSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByLawyerIdMinSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByLawyerIdMinSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByLawyerIdMinSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByLawyerIdMinSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByLawyerIdMinSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_TEAM_ID_ASC',
  SlackConnectionsByLawyerIdMinSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_TEAM_ID_DESC',
  SlackConnectionsByLawyerIdMinSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByLawyerIdMinSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByLawyerIdMinSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdMinSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdMinSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_USER_ID_ASC',
  SlackConnectionsByLawyerIdMinSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_USER_ID_DESC',
  SlackConnectionsByLawyerIdMinSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByLawyerIdMinSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByLawyerIdMinUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  SlackConnectionsByLawyerIdMinUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  SlackConnectionsByLawyerIdStddevPopulationConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_CONNECTION_KEY_ASC',
  SlackConnectionsByLawyerIdStddevPopulationConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_CONNECTION_KEY_DESC',
  SlackConnectionsByLawyerIdStddevPopulationConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_CONNECTION_URL_ASC',
  SlackConnectionsByLawyerIdStddevPopulationConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_CONNECTION_URL_DESC',
  SlackConnectionsByLawyerIdStddevPopulationContactIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  SlackConnectionsByLawyerIdStddevPopulationContactIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  SlackConnectionsByLawyerIdStddevPopulationCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  SlackConnectionsByLawyerIdStddevPopulationCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  SlackConnectionsByLawyerIdStddevPopulationIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  SlackConnectionsByLawyerIdStddevPopulationIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  SlackConnectionsByLawyerIdStddevPopulationLawyerIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  SlackConnectionsByLawyerIdStddevPopulationLawyerIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  SlackConnectionsByLawyerIdStddevPopulationSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_APP_ID_ASC',
  SlackConnectionsByLawyerIdStddevPopulationSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_APP_ID_DESC',
  SlackConnectionsByLawyerIdStddevPopulationSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdStddevPopulationSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdStddevPopulationSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByLawyerIdStddevPopulationSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByLawyerIdStddevPopulationSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByLawyerIdStddevPopulationSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByLawyerIdStddevPopulationSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByLawyerIdStddevPopulationSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByLawyerIdStddevPopulationSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByLawyerIdStddevPopulationSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByLawyerIdStddevPopulationSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByLawyerIdStddevPopulationSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByLawyerIdStddevPopulationSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_TEAM_ID_ASC',
  SlackConnectionsByLawyerIdStddevPopulationSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_TEAM_ID_DESC',
  SlackConnectionsByLawyerIdStddevPopulationSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByLawyerIdStddevPopulationSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByLawyerIdStddevPopulationSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdStddevPopulationSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdStddevPopulationSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_USER_ID_ASC',
  SlackConnectionsByLawyerIdStddevPopulationSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_USER_ID_DESC',
  SlackConnectionsByLawyerIdStddevPopulationSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByLawyerIdStddevPopulationSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByLawyerIdStddevPopulationUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  SlackConnectionsByLawyerIdStddevPopulationUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  SlackConnectionsByLawyerIdStddevSampleConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_CONNECTION_KEY_ASC',
  SlackConnectionsByLawyerIdStddevSampleConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_CONNECTION_KEY_DESC',
  SlackConnectionsByLawyerIdStddevSampleConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_CONNECTION_URL_ASC',
  SlackConnectionsByLawyerIdStddevSampleConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_CONNECTION_URL_DESC',
  SlackConnectionsByLawyerIdStddevSampleContactIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  SlackConnectionsByLawyerIdStddevSampleContactIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  SlackConnectionsByLawyerIdStddevSampleCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  SlackConnectionsByLawyerIdStddevSampleCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  SlackConnectionsByLawyerIdStddevSampleIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  SlackConnectionsByLawyerIdStddevSampleIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  SlackConnectionsByLawyerIdStddevSampleLawyerIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  SlackConnectionsByLawyerIdStddevSampleLawyerIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  SlackConnectionsByLawyerIdStddevSampleSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_APP_ID_ASC',
  SlackConnectionsByLawyerIdStddevSampleSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_APP_ID_DESC',
  SlackConnectionsByLawyerIdStddevSampleSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdStddevSampleSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdStddevSampleSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByLawyerIdStddevSampleSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByLawyerIdStddevSampleSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByLawyerIdStddevSampleSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByLawyerIdStddevSampleSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByLawyerIdStddevSampleSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByLawyerIdStddevSampleSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByLawyerIdStddevSampleSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByLawyerIdStddevSampleSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByLawyerIdStddevSampleSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByLawyerIdStddevSampleSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_TEAM_ID_ASC',
  SlackConnectionsByLawyerIdStddevSampleSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_TEAM_ID_DESC',
  SlackConnectionsByLawyerIdStddevSampleSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByLawyerIdStddevSampleSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByLawyerIdStddevSampleSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdStddevSampleSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdStddevSampleSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_USER_ID_ASC',
  SlackConnectionsByLawyerIdStddevSampleSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_USER_ID_DESC',
  SlackConnectionsByLawyerIdStddevSampleSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByLawyerIdStddevSampleSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByLawyerIdStddevSampleUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  SlackConnectionsByLawyerIdStddevSampleUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  SlackConnectionsByLawyerIdSumConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_CONNECTION_KEY_ASC',
  SlackConnectionsByLawyerIdSumConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_CONNECTION_KEY_DESC',
  SlackConnectionsByLawyerIdSumConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_CONNECTION_URL_ASC',
  SlackConnectionsByLawyerIdSumConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_CONNECTION_URL_DESC',
  SlackConnectionsByLawyerIdSumContactIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_CONTACT_ID_ASC',
  SlackConnectionsByLawyerIdSumContactIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_CONTACT_ID_DESC',
  SlackConnectionsByLawyerIdSumCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  SlackConnectionsByLawyerIdSumCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  SlackConnectionsByLawyerIdSumIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_ID_ASC',
  SlackConnectionsByLawyerIdSumIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_ID_DESC',
  SlackConnectionsByLawyerIdSumLawyerIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  SlackConnectionsByLawyerIdSumLawyerIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  SlackConnectionsByLawyerIdSumSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_APP_ID_ASC',
  SlackConnectionsByLawyerIdSumSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_APP_ID_DESC',
  SlackConnectionsByLawyerIdSumSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdSumSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdSumSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByLawyerIdSumSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByLawyerIdSumSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByLawyerIdSumSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByLawyerIdSumSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByLawyerIdSumSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByLawyerIdSumSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByLawyerIdSumSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByLawyerIdSumSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByLawyerIdSumSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByLawyerIdSumSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_TEAM_ID_ASC',
  SlackConnectionsByLawyerIdSumSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_TEAM_ID_DESC',
  SlackConnectionsByLawyerIdSumSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByLawyerIdSumSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByLawyerIdSumSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdSumSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdSumSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_USER_ID_ASC',
  SlackConnectionsByLawyerIdSumSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_USER_ID_DESC',
  SlackConnectionsByLawyerIdSumSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByLawyerIdSumSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByLawyerIdSumUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  SlackConnectionsByLawyerIdSumUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  SlackConnectionsByLawyerIdVariancePopulationConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_CONNECTION_KEY_ASC',
  SlackConnectionsByLawyerIdVariancePopulationConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_CONNECTION_KEY_DESC',
  SlackConnectionsByLawyerIdVariancePopulationConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_CONNECTION_URL_ASC',
  SlackConnectionsByLawyerIdVariancePopulationConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_CONNECTION_URL_DESC',
  SlackConnectionsByLawyerIdVariancePopulationContactIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  SlackConnectionsByLawyerIdVariancePopulationContactIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  SlackConnectionsByLawyerIdVariancePopulationCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  SlackConnectionsByLawyerIdVariancePopulationCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  SlackConnectionsByLawyerIdVariancePopulationIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  SlackConnectionsByLawyerIdVariancePopulationIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  SlackConnectionsByLawyerIdVariancePopulationLawyerIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  SlackConnectionsByLawyerIdVariancePopulationLawyerIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  SlackConnectionsByLawyerIdVariancePopulationSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_APP_ID_ASC',
  SlackConnectionsByLawyerIdVariancePopulationSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_APP_ID_DESC',
  SlackConnectionsByLawyerIdVariancePopulationSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdVariancePopulationSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdVariancePopulationSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByLawyerIdVariancePopulationSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByLawyerIdVariancePopulationSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByLawyerIdVariancePopulationSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByLawyerIdVariancePopulationSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByLawyerIdVariancePopulationSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByLawyerIdVariancePopulationSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByLawyerIdVariancePopulationSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByLawyerIdVariancePopulationSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByLawyerIdVariancePopulationSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByLawyerIdVariancePopulationSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_TEAM_ID_ASC',
  SlackConnectionsByLawyerIdVariancePopulationSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_TEAM_ID_DESC',
  SlackConnectionsByLawyerIdVariancePopulationSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByLawyerIdVariancePopulationSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByLawyerIdVariancePopulationSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdVariancePopulationSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdVariancePopulationSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_USER_ID_ASC',
  SlackConnectionsByLawyerIdVariancePopulationSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_USER_ID_DESC',
  SlackConnectionsByLawyerIdVariancePopulationSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByLawyerIdVariancePopulationSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByLawyerIdVariancePopulationUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  SlackConnectionsByLawyerIdVariancePopulationUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  SlackConnectionsByLawyerIdVarianceSampleConnectionKeyAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONNECTION_KEY_ASC',
  SlackConnectionsByLawyerIdVarianceSampleConnectionKeyDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONNECTION_KEY_DESC',
  SlackConnectionsByLawyerIdVarianceSampleConnectionUrlAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONNECTION_URL_ASC',
  SlackConnectionsByLawyerIdVarianceSampleConnectionUrlDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONNECTION_URL_DESC',
  SlackConnectionsByLawyerIdVarianceSampleContactIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  SlackConnectionsByLawyerIdVarianceSampleContactIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  SlackConnectionsByLawyerIdVarianceSampleCreatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  SlackConnectionsByLawyerIdVarianceSampleCreatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  SlackConnectionsByLawyerIdVarianceSampleIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  SlackConnectionsByLawyerIdVarianceSampleIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  SlackConnectionsByLawyerIdVarianceSampleLawyerIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  SlackConnectionsByLawyerIdVarianceSampleLawyerIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  SlackConnectionsByLawyerIdVarianceSampleSlackAppIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_APP_ID_ASC',
  SlackConnectionsByLawyerIdVarianceSampleSlackAppIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_APP_ID_DESC',
  SlackConnectionsByLawyerIdVarianceSampleSlackBotAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_BOT_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdVarianceSampleSlackBotAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_BOT_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdVarianceSampleSlackBotScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_BOT_SCOPE_ASC',
  SlackConnectionsByLawyerIdVarianceSampleSlackBotScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_BOT_SCOPE_DESC',
  SlackConnectionsByLawyerIdVarianceSampleSlackBotUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_BOT_USER_ID_ASC',
  SlackConnectionsByLawyerIdVarianceSampleSlackBotUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_BOT_USER_ID_DESC',
  SlackConnectionsByLawyerIdVarianceSampleSlackChannelIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_CHANNEL_ID_ASC',
  SlackConnectionsByLawyerIdVarianceSampleSlackChannelIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_CHANNEL_ID_DESC',
  SlackConnectionsByLawyerIdVarianceSampleSlackChannelNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_CHANNEL_NAME_ASC',
  SlackConnectionsByLawyerIdVarianceSampleSlackChannelNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_CHANNEL_NAME_DESC',
  SlackConnectionsByLawyerIdVarianceSampleSlackKulaMsgTsAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_KULA_MSG_TS_ASC',
  SlackConnectionsByLawyerIdVarianceSampleSlackKulaMsgTsDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_KULA_MSG_TS_DESC',
  SlackConnectionsByLawyerIdVarianceSampleSlackTeamIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_TEAM_ID_ASC',
  SlackConnectionsByLawyerIdVarianceSampleSlackTeamIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_TEAM_ID_DESC',
  SlackConnectionsByLawyerIdVarianceSampleSlackTeamNameAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_TEAM_NAME_ASC',
  SlackConnectionsByLawyerIdVarianceSampleSlackTeamNameDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_TEAM_NAME_DESC',
  SlackConnectionsByLawyerIdVarianceSampleSlackUserAccessTokenAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_USER_ACCESS_TOKEN_ASC',
  SlackConnectionsByLawyerIdVarianceSampleSlackUserAccessTokenDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_USER_ACCESS_TOKEN_DESC',
  SlackConnectionsByLawyerIdVarianceSampleSlackUserIdAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_USER_ID_ASC',
  SlackConnectionsByLawyerIdVarianceSampleSlackUserIdDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_USER_ID_DESC',
  SlackConnectionsByLawyerIdVarianceSampleSlackUserScopeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_USER_SCOPE_ASC',
  SlackConnectionsByLawyerIdVarianceSampleSlackUserScopeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_SLACK_USER_SCOPE_DESC',
  SlackConnectionsByLawyerIdVarianceSampleUpdatedTimeAsc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  SlackConnectionsByLawyerIdVarianceSampleUpdatedTimeDesc = 'SLACK_CONNECTIONS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  SocialProofsByLawyerIdAverageCompanyIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_COMPANY_ID_ASC',
  SocialProofsByLawyerIdAverageCompanyIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_COMPANY_ID_DESC',
  SocialProofsByLawyerIdAverageContactIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_ASC',
  SocialProofsByLawyerIdAverageContactIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_DESC',
  SocialProofsByLawyerIdAverageCreatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  SocialProofsByLawyerIdAverageCreatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  SocialProofsByLawyerIdAverageIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  SocialProofsByLawyerIdAverageIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  SocialProofsByLawyerIdAverageLawyerIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  SocialProofsByLawyerIdAverageLawyerIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  SocialProofsByLawyerIdAverageTextAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_TEXT_ASC',
  SocialProofsByLawyerIdAverageTextDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_TEXT_DESC',
  SocialProofsByLawyerIdAverageUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_ASC',
  SocialProofsByLawyerIdAverageUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_AVERAGE_UPDATED_TIME_DESC',
  SocialProofsByLawyerIdCountAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_COUNT_ASC',
  SocialProofsByLawyerIdCountDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_COUNT_DESC',
  SocialProofsByLawyerIdDistinctCountCompanyIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  SocialProofsByLawyerIdDistinctCountCompanyIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  SocialProofsByLawyerIdDistinctCountContactIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  SocialProofsByLawyerIdDistinctCountContactIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  SocialProofsByLawyerIdDistinctCountCreatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  SocialProofsByLawyerIdDistinctCountCreatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  SocialProofsByLawyerIdDistinctCountIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  SocialProofsByLawyerIdDistinctCountIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  SocialProofsByLawyerIdDistinctCountLawyerIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  SocialProofsByLawyerIdDistinctCountLawyerIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  SocialProofsByLawyerIdDistinctCountTextAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_TEXT_ASC',
  SocialProofsByLawyerIdDistinctCountTextDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_TEXT_DESC',
  SocialProofsByLawyerIdDistinctCountUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  SocialProofsByLawyerIdDistinctCountUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  SocialProofsByLawyerIdMaxCompanyIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_COMPANY_ID_ASC',
  SocialProofsByLawyerIdMaxCompanyIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_COMPANY_ID_DESC',
  SocialProofsByLawyerIdMaxContactIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_CONTACT_ID_ASC',
  SocialProofsByLawyerIdMaxContactIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_CONTACT_ID_DESC',
  SocialProofsByLawyerIdMaxCreatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  SocialProofsByLawyerIdMaxCreatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  SocialProofsByLawyerIdMaxIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_ID_ASC',
  SocialProofsByLawyerIdMaxIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_ID_DESC',
  SocialProofsByLawyerIdMaxLawyerIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  SocialProofsByLawyerIdMaxLawyerIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  SocialProofsByLawyerIdMaxTextAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_TEXT_ASC',
  SocialProofsByLawyerIdMaxTextDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_TEXT_DESC',
  SocialProofsByLawyerIdMaxUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_UPDATED_TIME_ASC',
  SocialProofsByLawyerIdMaxUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MAX_UPDATED_TIME_DESC',
  SocialProofsByLawyerIdMinCompanyIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_COMPANY_ID_ASC',
  SocialProofsByLawyerIdMinCompanyIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_COMPANY_ID_DESC',
  SocialProofsByLawyerIdMinContactIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_CONTACT_ID_ASC',
  SocialProofsByLawyerIdMinContactIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_CONTACT_ID_DESC',
  SocialProofsByLawyerIdMinCreatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  SocialProofsByLawyerIdMinCreatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  SocialProofsByLawyerIdMinIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_ID_ASC',
  SocialProofsByLawyerIdMinIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_ID_DESC',
  SocialProofsByLawyerIdMinLawyerIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  SocialProofsByLawyerIdMinLawyerIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  SocialProofsByLawyerIdMinTextAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_TEXT_ASC',
  SocialProofsByLawyerIdMinTextDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_TEXT_DESC',
  SocialProofsByLawyerIdMinUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_UPDATED_TIME_ASC',
  SocialProofsByLawyerIdMinUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_MIN_UPDATED_TIME_DESC',
  SocialProofsByLawyerIdStddevPopulationCompanyIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  SocialProofsByLawyerIdStddevPopulationCompanyIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  SocialProofsByLawyerIdStddevPopulationContactIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  SocialProofsByLawyerIdStddevPopulationContactIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  SocialProofsByLawyerIdStddevPopulationCreatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  SocialProofsByLawyerIdStddevPopulationCreatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  SocialProofsByLawyerIdStddevPopulationIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  SocialProofsByLawyerIdStddevPopulationIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  SocialProofsByLawyerIdStddevPopulationLawyerIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  SocialProofsByLawyerIdStddevPopulationLawyerIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  SocialProofsByLawyerIdStddevPopulationTextAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_TEXT_ASC',
  SocialProofsByLawyerIdStddevPopulationTextDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_TEXT_DESC',
  SocialProofsByLawyerIdStddevPopulationUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  SocialProofsByLawyerIdStddevPopulationUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  SocialProofsByLawyerIdStddevSampleCompanyIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  SocialProofsByLawyerIdStddevSampleCompanyIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  SocialProofsByLawyerIdStddevSampleContactIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  SocialProofsByLawyerIdStddevSampleContactIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  SocialProofsByLawyerIdStddevSampleCreatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  SocialProofsByLawyerIdStddevSampleCreatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  SocialProofsByLawyerIdStddevSampleIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  SocialProofsByLawyerIdStddevSampleIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  SocialProofsByLawyerIdStddevSampleLawyerIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  SocialProofsByLawyerIdStddevSampleLawyerIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  SocialProofsByLawyerIdStddevSampleTextAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_TEXT_ASC',
  SocialProofsByLawyerIdStddevSampleTextDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_TEXT_DESC',
  SocialProofsByLawyerIdStddevSampleUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  SocialProofsByLawyerIdStddevSampleUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  SocialProofsByLawyerIdSumCompanyIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_COMPANY_ID_ASC',
  SocialProofsByLawyerIdSumCompanyIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_COMPANY_ID_DESC',
  SocialProofsByLawyerIdSumContactIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_CONTACT_ID_ASC',
  SocialProofsByLawyerIdSumContactIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_CONTACT_ID_DESC',
  SocialProofsByLawyerIdSumCreatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  SocialProofsByLawyerIdSumCreatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  SocialProofsByLawyerIdSumIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_ID_ASC',
  SocialProofsByLawyerIdSumIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_ID_DESC',
  SocialProofsByLawyerIdSumLawyerIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  SocialProofsByLawyerIdSumLawyerIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  SocialProofsByLawyerIdSumTextAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_TEXT_ASC',
  SocialProofsByLawyerIdSumTextDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_TEXT_DESC',
  SocialProofsByLawyerIdSumUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_UPDATED_TIME_ASC',
  SocialProofsByLawyerIdSumUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_SUM_UPDATED_TIME_DESC',
  SocialProofsByLawyerIdVariancePopulationCompanyIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  SocialProofsByLawyerIdVariancePopulationCompanyIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  SocialProofsByLawyerIdVariancePopulationContactIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  SocialProofsByLawyerIdVariancePopulationContactIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  SocialProofsByLawyerIdVariancePopulationCreatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  SocialProofsByLawyerIdVariancePopulationCreatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  SocialProofsByLawyerIdVariancePopulationIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  SocialProofsByLawyerIdVariancePopulationIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  SocialProofsByLawyerIdVariancePopulationLawyerIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  SocialProofsByLawyerIdVariancePopulationLawyerIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  SocialProofsByLawyerIdVariancePopulationTextAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_TEXT_ASC',
  SocialProofsByLawyerIdVariancePopulationTextDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_TEXT_DESC',
  SocialProofsByLawyerIdVariancePopulationUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  SocialProofsByLawyerIdVariancePopulationUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  SocialProofsByLawyerIdVarianceSampleCompanyIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  SocialProofsByLawyerIdVarianceSampleCompanyIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  SocialProofsByLawyerIdVarianceSampleContactIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  SocialProofsByLawyerIdVarianceSampleContactIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  SocialProofsByLawyerIdVarianceSampleCreatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  SocialProofsByLawyerIdVarianceSampleCreatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  SocialProofsByLawyerIdVarianceSampleIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  SocialProofsByLawyerIdVarianceSampleIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  SocialProofsByLawyerIdVarianceSampleLawyerIdAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  SocialProofsByLawyerIdVarianceSampleLawyerIdDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  SocialProofsByLawyerIdVarianceSampleTextAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_TEXT_ASC',
  SocialProofsByLawyerIdVarianceSampleTextDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_TEXT_DESC',
  SocialProofsByLawyerIdVarianceSampleUpdatedTimeAsc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  SocialProofsByLawyerIdVarianceSampleUpdatedTimeDesc = 'SOCIAL_PROOFS_BY_LAWYER_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  SqsBlockadesByLawyerIdAverageCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByLawyerIdAverageCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByLawyerIdAverageCreatedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  SqsBlockadesByLawyerIdAverageCreatedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  SqsBlockadesByLawyerIdAverageDelayedUntilAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_DELAYED_UNTIL_ASC',
  SqsBlockadesByLawyerIdAverageDelayedUntilDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_DELAYED_UNTIL_DESC',
  SqsBlockadesByLawyerIdAverageDispatchedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_DISPATCHED_TIME_ASC',
  SqsBlockadesByLawyerIdAverageDispatchedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_DISPATCHED_TIME_DESC',
  SqsBlockadesByLawyerIdAverageEventAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_EVENT_ASC',
  SqsBlockadesByLawyerIdAverageEventDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_EVENT_DESC',
  SqsBlockadesByLawyerIdAverageIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_ID_ASC',
  SqsBlockadesByLawyerIdAverageIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_ID_DESC',
  SqsBlockadesByLawyerIdAverageLawyerIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  SqsBlockadesByLawyerIdAverageLawyerIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  SqsBlockadesByLawyerIdAverageMessageGroupIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByLawyerIdAverageMessageGroupIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByLawyerIdAverageTypeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_TYPE_ASC',
  SqsBlockadesByLawyerIdAverageTypeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_AVERAGE_TYPE_DESC',
  SqsBlockadesByLawyerIdCountAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_COUNT_ASC',
  SqsBlockadesByLawyerIdCountDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_COUNT_DESC',
  SqsBlockadesByLawyerIdDistinctCountCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByLawyerIdDistinctCountCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByLawyerIdDistinctCountCreatedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  SqsBlockadesByLawyerIdDistinctCountCreatedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  SqsBlockadesByLawyerIdDistinctCountDelayedUntilAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_DELAYED_UNTIL_ASC',
  SqsBlockadesByLawyerIdDistinctCountDelayedUntilDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_DELAYED_UNTIL_DESC',
  SqsBlockadesByLawyerIdDistinctCountDispatchedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_DISPATCHED_TIME_ASC',
  SqsBlockadesByLawyerIdDistinctCountDispatchedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_DISPATCHED_TIME_DESC',
  SqsBlockadesByLawyerIdDistinctCountEventAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_EVENT_ASC',
  SqsBlockadesByLawyerIdDistinctCountEventDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_EVENT_DESC',
  SqsBlockadesByLawyerIdDistinctCountIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  SqsBlockadesByLawyerIdDistinctCountIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  SqsBlockadesByLawyerIdDistinctCountLawyerIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  SqsBlockadesByLawyerIdDistinctCountLawyerIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  SqsBlockadesByLawyerIdDistinctCountMessageGroupIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByLawyerIdDistinctCountMessageGroupIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByLawyerIdDistinctCountTypeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_TYPE_ASC',
  SqsBlockadesByLawyerIdDistinctCountTypeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_DISTINCT_COUNT_TYPE_DESC',
  SqsBlockadesByLawyerIdMaxCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByLawyerIdMaxCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByLawyerIdMaxCreatedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  SqsBlockadesByLawyerIdMaxCreatedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  SqsBlockadesByLawyerIdMaxDelayedUntilAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_DELAYED_UNTIL_ASC',
  SqsBlockadesByLawyerIdMaxDelayedUntilDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_DELAYED_UNTIL_DESC',
  SqsBlockadesByLawyerIdMaxDispatchedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_DISPATCHED_TIME_ASC',
  SqsBlockadesByLawyerIdMaxDispatchedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_DISPATCHED_TIME_DESC',
  SqsBlockadesByLawyerIdMaxEventAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_EVENT_ASC',
  SqsBlockadesByLawyerIdMaxEventDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_EVENT_DESC',
  SqsBlockadesByLawyerIdMaxIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_ID_ASC',
  SqsBlockadesByLawyerIdMaxIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_ID_DESC',
  SqsBlockadesByLawyerIdMaxLawyerIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  SqsBlockadesByLawyerIdMaxLawyerIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  SqsBlockadesByLawyerIdMaxMessageGroupIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByLawyerIdMaxMessageGroupIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByLawyerIdMaxTypeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_TYPE_ASC',
  SqsBlockadesByLawyerIdMaxTypeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MAX_TYPE_DESC',
  SqsBlockadesByLawyerIdMinCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByLawyerIdMinCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByLawyerIdMinCreatedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  SqsBlockadesByLawyerIdMinCreatedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  SqsBlockadesByLawyerIdMinDelayedUntilAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_DELAYED_UNTIL_ASC',
  SqsBlockadesByLawyerIdMinDelayedUntilDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_DELAYED_UNTIL_DESC',
  SqsBlockadesByLawyerIdMinDispatchedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_DISPATCHED_TIME_ASC',
  SqsBlockadesByLawyerIdMinDispatchedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_DISPATCHED_TIME_DESC',
  SqsBlockadesByLawyerIdMinEventAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_EVENT_ASC',
  SqsBlockadesByLawyerIdMinEventDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_EVENT_DESC',
  SqsBlockadesByLawyerIdMinIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_ID_ASC',
  SqsBlockadesByLawyerIdMinIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_ID_DESC',
  SqsBlockadesByLawyerIdMinLawyerIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  SqsBlockadesByLawyerIdMinLawyerIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  SqsBlockadesByLawyerIdMinMessageGroupIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByLawyerIdMinMessageGroupIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByLawyerIdMinTypeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_TYPE_ASC',
  SqsBlockadesByLawyerIdMinTypeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_MIN_TYPE_DESC',
  SqsBlockadesByLawyerIdStddevPopulationCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByLawyerIdStddevPopulationCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByLawyerIdStddevPopulationCreatedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  SqsBlockadesByLawyerIdStddevPopulationCreatedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  SqsBlockadesByLawyerIdStddevPopulationDelayedUntilAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_DELAYED_UNTIL_ASC',
  SqsBlockadesByLawyerIdStddevPopulationDelayedUntilDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_DELAYED_UNTIL_DESC',
  SqsBlockadesByLawyerIdStddevPopulationDispatchedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_DISPATCHED_TIME_ASC',
  SqsBlockadesByLawyerIdStddevPopulationDispatchedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_DISPATCHED_TIME_DESC',
  SqsBlockadesByLawyerIdStddevPopulationEventAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_EVENT_ASC',
  SqsBlockadesByLawyerIdStddevPopulationEventDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_EVENT_DESC',
  SqsBlockadesByLawyerIdStddevPopulationIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  SqsBlockadesByLawyerIdStddevPopulationIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  SqsBlockadesByLawyerIdStddevPopulationLawyerIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  SqsBlockadesByLawyerIdStddevPopulationLawyerIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  SqsBlockadesByLawyerIdStddevPopulationMessageGroupIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByLawyerIdStddevPopulationMessageGroupIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByLawyerIdStddevPopulationTypeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_TYPE_ASC',
  SqsBlockadesByLawyerIdStddevPopulationTypeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_POPULATION_TYPE_DESC',
  SqsBlockadesByLawyerIdStddevSampleCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByLawyerIdStddevSampleCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByLawyerIdStddevSampleCreatedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  SqsBlockadesByLawyerIdStddevSampleCreatedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  SqsBlockadesByLawyerIdStddevSampleDelayedUntilAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_DELAYED_UNTIL_ASC',
  SqsBlockadesByLawyerIdStddevSampleDelayedUntilDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_DELAYED_UNTIL_DESC',
  SqsBlockadesByLawyerIdStddevSampleDispatchedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_DISPATCHED_TIME_ASC',
  SqsBlockadesByLawyerIdStddevSampleDispatchedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_DISPATCHED_TIME_DESC',
  SqsBlockadesByLawyerIdStddevSampleEventAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_EVENT_ASC',
  SqsBlockadesByLawyerIdStddevSampleEventDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_EVENT_DESC',
  SqsBlockadesByLawyerIdStddevSampleIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  SqsBlockadesByLawyerIdStddevSampleIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  SqsBlockadesByLawyerIdStddevSampleLawyerIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  SqsBlockadesByLawyerIdStddevSampleLawyerIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  SqsBlockadesByLawyerIdStddevSampleMessageGroupIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByLawyerIdStddevSampleMessageGroupIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByLawyerIdStddevSampleTypeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_TYPE_ASC',
  SqsBlockadesByLawyerIdStddevSampleTypeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_STDDEV_SAMPLE_TYPE_DESC',
  SqsBlockadesByLawyerIdSumCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByLawyerIdSumCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByLawyerIdSumCreatedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  SqsBlockadesByLawyerIdSumCreatedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  SqsBlockadesByLawyerIdSumDelayedUntilAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_DELAYED_UNTIL_ASC',
  SqsBlockadesByLawyerIdSumDelayedUntilDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_DELAYED_UNTIL_DESC',
  SqsBlockadesByLawyerIdSumDispatchedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_DISPATCHED_TIME_ASC',
  SqsBlockadesByLawyerIdSumDispatchedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_DISPATCHED_TIME_DESC',
  SqsBlockadesByLawyerIdSumEventAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_EVENT_ASC',
  SqsBlockadesByLawyerIdSumEventDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_EVENT_DESC',
  SqsBlockadesByLawyerIdSumIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_ID_ASC',
  SqsBlockadesByLawyerIdSumIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_ID_DESC',
  SqsBlockadesByLawyerIdSumLawyerIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  SqsBlockadesByLawyerIdSumLawyerIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  SqsBlockadesByLawyerIdSumMessageGroupIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByLawyerIdSumMessageGroupIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByLawyerIdSumTypeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_TYPE_ASC',
  SqsBlockadesByLawyerIdSumTypeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_SUM_TYPE_DESC',
  SqsBlockadesByLawyerIdVariancePopulationCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByLawyerIdVariancePopulationCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByLawyerIdVariancePopulationCreatedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  SqsBlockadesByLawyerIdVariancePopulationCreatedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  SqsBlockadesByLawyerIdVariancePopulationDelayedUntilAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_DELAYED_UNTIL_ASC',
  SqsBlockadesByLawyerIdVariancePopulationDelayedUntilDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_DELAYED_UNTIL_DESC',
  SqsBlockadesByLawyerIdVariancePopulationDispatchedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_DISPATCHED_TIME_ASC',
  SqsBlockadesByLawyerIdVariancePopulationDispatchedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_DISPATCHED_TIME_DESC',
  SqsBlockadesByLawyerIdVariancePopulationEventAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_EVENT_ASC',
  SqsBlockadesByLawyerIdVariancePopulationEventDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_EVENT_DESC',
  SqsBlockadesByLawyerIdVariancePopulationIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  SqsBlockadesByLawyerIdVariancePopulationIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  SqsBlockadesByLawyerIdVariancePopulationLawyerIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  SqsBlockadesByLawyerIdVariancePopulationLawyerIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  SqsBlockadesByLawyerIdVariancePopulationMessageGroupIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByLawyerIdVariancePopulationMessageGroupIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByLawyerIdVariancePopulationTypeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_TYPE_ASC',
  SqsBlockadesByLawyerIdVariancePopulationTypeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_POPULATION_TYPE_DESC',
  SqsBlockadesByLawyerIdVarianceSampleCampaignMemberIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  SqsBlockadesByLawyerIdVarianceSampleCampaignMemberIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  SqsBlockadesByLawyerIdVarianceSampleCreatedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  SqsBlockadesByLawyerIdVarianceSampleCreatedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  SqsBlockadesByLawyerIdVarianceSampleDelayedUntilAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_DELAYED_UNTIL_ASC',
  SqsBlockadesByLawyerIdVarianceSampleDelayedUntilDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_DELAYED_UNTIL_DESC',
  SqsBlockadesByLawyerIdVarianceSampleDispatchedTimeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_DISPATCHED_TIME_ASC',
  SqsBlockadesByLawyerIdVarianceSampleDispatchedTimeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_DISPATCHED_TIME_DESC',
  SqsBlockadesByLawyerIdVarianceSampleEventAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_EVENT_ASC',
  SqsBlockadesByLawyerIdVarianceSampleEventDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_EVENT_DESC',
  SqsBlockadesByLawyerIdVarianceSampleIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  SqsBlockadesByLawyerIdVarianceSampleIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  SqsBlockadesByLawyerIdVarianceSampleLawyerIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  SqsBlockadesByLawyerIdVarianceSampleLawyerIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  SqsBlockadesByLawyerIdVarianceSampleMessageGroupIdAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_MESSAGE_GROUP_ID_ASC',
  SqsBlockadesByLawyerIdVarianceSampleMessageGroupIdDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_MESSAGE_GROUP_ID_DESC',
  SqsBlockadesByLawyerIdVarianceSampleTypeAsc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_TYPE_ASC',
  SqsBlockadesByLawyerIdVarianceSampleTypeDesc = 'SQS_BLOCKADES_BY_LAWYER_ID_VARIANCE_SAMPLE_TYPE_DESC',
  StageAsc = 'STAGE_ASC',
  StageDesc = 'STAGE_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  StreetAddressAsc = 'STREET_ADDRESS_ASC',
  StreetAddressDesc = 'STREET_ADDRESS_DESC',
  StripeCustomerIdAsc = 'STRIPE_CUSTOMER_ID_ASC',
  StripeCustomerIdDesc = 'STRIPE_CUSTOMER_ID_DESC',
  StripeDiscountCodeAsc = 'STRIPE_DISCOUNT_CODE_ASC',
  StripeDiscountCodeDesc = 'STRIPE_DISCOUNT_CODE_DESC',
  StripePriceIdAsc = 'STRIPE_PRICE_ID_ASC',
  StripePriceIdDesc = 'STRIPE_PRICE_ID_DESC',
  SuggestedTargetingsByLawyerIdAverageCampaignRequestIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_CAMPAIGN_REQUEST_ID_ASC',
  SuggestedTargetingsByLawyerIdAverageCampaignRequestIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_CAMPAIGN_REQUEST_ID_DESC',
  SuggestedTargetingsByLawyerIdAverageCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdAverageCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdAverageContactedCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_CONTACTED_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdAverageContactedCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_CONTACTED_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdAverageCreatedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  SuggestedTargetingsByLawyerIdAverageCreatedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  SuggestedTargetingsByLawyerIdAverageFocusesAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_FOCUSES_ASC',
  SuggestedTargetingsByLawyerIdAverageFocusesDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_FOCUSES_DESC',
  SuggestedTargetingsByLawyerIdAverageIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  SuggestedTargetingsByLawyerIdAverageIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  SuggestedTargetingsByLawyerIdAverageLawyerIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  SuggestedTargetingsByLawyerIdAverageLawyerIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  SuggestedTargetingsByLawyerIdAverageLawyerMatchScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_LAWYER_MATCH_SCORE_ASC',
  SuggestedTargetingsByLawyerIdAverageLawyerMatchScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_LAWYER_MATCH_SCORE_DESC',
  SuggestedTargetingsByLawyerIdAverageLlmQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_LLM_QUERY_ASC',
  SuggestedTargetingsByLawyerIdAverageLlmQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_LLM_QUERY_DESC',
  SuggestedTargetingsByLawyerIdAverageMinimumCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_MINIMUM_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdAverageMinimumCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_MINIMUM_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdAveragePersonalisationQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_PERSONALISATION_QUERY_ASC',
  SuggestedTargetingsByLawyerIdAveragePersonalisationQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_PERSONALISATION_QUERY_DESC',
  SuggestedTargetingsByLawyerIdAverageRawAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_RAW_ASC',
  SuggestedTargetingsByLawyerIdAverageRawDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_RAW_DESC',
  SuggestedTargetingsByLawyerIdAverageRejectedReasonAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_REJECTED_REASON_ASC',
  SuggestedTargetingsByLawyerIdAverageRejectedReasonDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_REJECTED_REASON_DESC',
  SuggestedTargetingsByLawyerIdAverageRejectedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_REJECTED_TIME_ASC',
  SuggestedTargetingsByLawyerIdAverageRejectedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_REJECTED_TIME_DESC',
  SuggestedTargetingsByLawyerIdAverageTargetCompanyDescriptionAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_TARGET_COMPANY_DESCRIPTION_ASC',
  SuggestedTargetingsByLawyerIdAverageTargetCompanyDescriptionDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_TARGET_COMPANY_DESCRIPTION_DESC',
  SuggestedTargetingsByLawyerIdAverageTitleAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_TITLE_ASC',
  SuggestedTargetingsByLawyerIdAverageTitleDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_TITLE_DESC',
  SuggestedTargetingsByLawyerIdAverageTotalScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_TOTAL_SCORE_ASC',
  SuggestedTargetingsByLawyerIdAverageTotalScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_AVERAGE_TOTAL_SCORE_DESC',
  SuggestedTargetingsByLawyerIdCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_COUNT_ASC',
  SuggestedTargetingsByLawyerIdCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_COUNT_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountCampaignRequestIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_REQUEST_ID_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountCampaignRequestIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_REQUEST_ID_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountContactedCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACTED_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountContactedCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACTED_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountCreatedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountCreatedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountFocusesAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_FOCUSES_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountFocusesDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_FOCUSES_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountLawyerIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountLawyerIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountLawyerMatchScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_MATCH_SCORE_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountLawyerMatchScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_MATCH_SCORE_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountLlmQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LLM_QUERY_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountLlmQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_LLM_QUERY_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountMinimumCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_MINIMUM_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountMinimumCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_MINIMUM_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountPersonalisationQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_PERSONALISATION_QUERY_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountPersonalisationQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_PERSONALISATION_QUERY_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountRawAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_RAW_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountRawDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_RAW_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountRejectedReasonAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_REJECTED_REASON_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountRejectedReasonDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_REJECTED_REASON_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountRejectedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_REJECTED_TIME_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountRejectedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_REJECTED_TIME_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountTargetCompanyDescriptionAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_TARGET_COMPANY_DESCRIPTION_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountTargetCompanyDescriptionDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_TARGET_COMPANY_DESCRIPTION_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountTitleAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_TITLE_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountTitleDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_TITLE_DESC',
  SuggestedTargetingsByLawyerIdDistinctCountTotalScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_TOTAL_SCORE_ASC',
  SuggestedTargetingsByLawyerIdDistinctCountTotalScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_DISTINCT_COUNT_TOTAL_SCORE_DESC',
  SuggestedTargetingsByLawyerIdMaxCampaignRequestIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_CAMPAIGN_REQUEST_ID_ASC',
  SuggestedTargetingsByLawyerIdMaxCampaignRequestIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_CAMPAIGN_REQUEST_ID_DESC',
  SuggestedTargetingsByLawyerIdMaxCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdMaxCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdMaxContactedCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_CONTACTED_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdMaxContactedCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_CONTACTED_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdMaxCreatedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  SuggestedTargetingsByLawyerIdMaxCreatedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  SuggestedTargetingsByLawyerIdMaxFocusesAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_FOCUSES_ASC',
  SuggestedTargetingsByLawyerIdMaxFocusesDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_FOCUSES_DESC',
  SuggestedTargetingsByLawyerIdMaxIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_ID_ASC',
  SuggestedTargetingsByLawyerIdMaxIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_ID_DESC',
  SuggestedTargetingsByLawyerIdMaxLawyerIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  SuggestedTargetingsByLawyerIdMaxLawyerIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  SuggestedTargetingsByLawyerIdMaxLawyerMatchScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_LAWYER_MATCH_SCORE_ASC',
  SuggestedTargetingsByLawyerIdMaxLawyerMatchScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_LAWYER_MATCH_SCORE_DESC',
  SuggestedTargetingsByLawyerIdMaxLlmQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_LLM_QUERY_ASC',
  SuggestedTargetingsByLawyerIdMaxLlmQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_LLM_QUERY_DESC',
  SuggestedTargetingsByLawyerIdMaxMinimumCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_MINIMUM_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdMaxMinimumCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_MINIMUM_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdMaxPersonalisationQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_PERSONALISATION_QUERY_ASC',
  SuggestedTargetingsByLawyerIdMaxPersonalisationQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_PERSONALISATION_QUERY_DESC',
  SuggestedTargetingsByLawyerIdMaxRawAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_RAW_ASC',
  SuggestedTargetingsByLawyerIdMaxRawDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_RAW_DESC',
  SuggestedTargetingsByLawyerIdMaxRejectedReasonAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_REJECTED_REASON_ASC',
  SuggestedTargetingsByLawyerIdMaxRejectedReasonDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_REJECTED_REASON_DESC',
  SuggestedTargetingsByLawyerIdMaxRejectedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_REJECTED_TIME_ASC',
  SuggestedTargetingsByLawyerIdMaxRejectedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_REJECTED_TIME_DESC',
  SuggestedTargetingsByLawyerIdMaxTargetCompanyDescriptionAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_TARGET_COMPANY_DESCRIPTION_ASC',
  SuggestedTargetingsByLawyerIdMaxTargetCompanyDescriptionDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_TARGET_COMPANY_DESCRIPTION_DESC',
  SuggestedTargetingsByLawyerIdMaxTitleAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_TITLE_ASC',
  SuggestedTargetingsByLawyerIdMaxTitleDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_TITLE_DESC',
  SuggestedTargetingsByLawyerIdMaxTotalScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_TOTAL_SCORE_ASC',
  SuggestedTargetingsByLawyerIdMaxTotalScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MAX_TOTAL_SCORE_DESC',
  SuggestedTargetingsByLawyerIdMinCampaignRequestIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_CAMPAIGN_REQUEST_ID_ASC',
  SuggestedTargetingsByLawyerIdMinCampaignRequestIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_CAMPAIGN_REQUEST_ID_DESC',
  SuggestedTargetingsByLawyerIdMinCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdMinCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdMinContactedCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_CONTACTED_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdMinContactedCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_CONTACTED_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdMinCreatedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  SuggestedTargetingsByLawyerIdMinCreatedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  SuggestedTargetingsByLawyerIdMinFocusesAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_FOCUSES_ASC',
  SuggestedTargetingsByLawyerIdMinFocusesDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_FOCUSES_DESC',
  SuggestedTargetingsByLawyerIdMinIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_ID_ASC',
  SuggestedTargetingsByLawyerIdMinIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_ID_DESC',
  SuggestedTargetingsByLawyerIdMinLawyerIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  SuggestedTargetingsByLawyerIdMinLawyerIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  SuggestedTargetingsByLawyerIdMinLawyerMatchScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_LAWYER_MATCH_SCORE_ASC',
  SuggestedTargetingsByLawyerIdMinLawyerMatchScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_LAWYER_MATCH_SCORE_DESC',
  SuggestedTargetingsByLawyerIdMinLlmQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_LLM_QUERY_ASC',
  SuggestedTargetingsByLawyerIdMinLlmQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_LLM_QUERY_DESC',
  SuggestedTargetingsByLawyerIdMinMinimumCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_MINIMUM_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdMinMinimumCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_MINIMUM_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdMinPersonalisationQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_PERSONALISATION_QUERY_ASC',
  SuggestedTargetingsByLawyerIdMinPersonalisationQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_PERSONALISATION_QUERY_DESC',
  SuggestedTargetingsByLawyerIdMinRawAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_RAW_ASC',
  SuggestedTargetingsByLawyerIdMinRawDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_RAW_DESC',
  SuggestedTargetingsByLawyerIdMinRejectedReasonAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_REJECTED_REASON_ASC',
  SuggestedTargetingsByLawyerIdMinRejectedReasonDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_REJECTED_REASON_DESC',
  SuggestedTargetingsByLawyerIdMinRejectedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_REJECTED_TIME_ASC',
  SuggestedTargetingsByLawyerIdMinRejectedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_REJECTED_TIME_DESC',
  SuggestedTargetingsByLawyerIdMinTargetCompanyDescriptionAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_TARGET_COMPANY_DESCRIPTION_ASC',
  SuggestedTargetingsByLawyerIdMinTargetCompanyDescriptionDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_TARGET_COMPANY_DESCRIPTION_DESC',
  SuggestedTargetingsByLawyerIdMinTitleAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_TITLE_ASC',
  SuggestedTargetingsByLawyerIdMinTitleDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_TITLE_DESC',
  SuggestedTargetingsByLawyerIdMinTotalScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_TOTAL_SCORE_ASC',
  SuggestedTargetingsByLawyerIdMinTotalScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_MIN_TOTAL_SCORE_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationCampaignRequestIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_REQUEST_ID_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationCampaignRequestIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_REQUEST_ID_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationContactedCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACTED_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationContactedCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACTED_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationCreatedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationCreatedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationFocusesAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_FOCUSES_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationFocusesDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_FOCUSES_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationLawyerIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationLawyerIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationLawyerMatchScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_MATCH_SCORE_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationLawyerMatchScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_MATCH_SCORE_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationLlmQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LLM_QUERY_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationLlmQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_LLM_QUERY_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationMinimumCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_MINIMUM_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationMinimumCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_MINIMUM_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationPersonalisationQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_PERSONALISATION_QUERY_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationPersonalisationQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_PERSONALISATION_QUERY_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationRawAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_RAW_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationRawDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_RAW_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationRejectedReasonAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_REJECTED_REASON_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationRejectedReasonDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_REJECTED_REASON_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationRejectedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_REJECTED_TIME_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationRejectedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_REJECTED_TIME_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationTargetCompanyDescriptionAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_TARGET_COMPANY_DESCRIPTION_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationTargetCompanyDescriptionDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_TARGET_COMPANY_DESCRIPTION_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationTitleAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_TITLE_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationTitleDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_TITLE_DESC',
  SuggestedTargetingsByLawyerIdStddevPopulationTotalScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_TOTAL_SCORE_ASC',
  SuggestedTargetingsByLawyerIdStddevPopulationTotalScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_POPULATION_TOTAL_SCORE_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleCampaignRequestIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_REQUEST_ID_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleCampaignRequestIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_REQUEST_ID_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleContactedCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACTED_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleContactedCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACTED_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleCreatedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleCreatedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleFocusesAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_FOCUSES_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleFocusesDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_FOCUSES_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleLawyerIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleLawyerIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleLawyerMatchScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_MATCH_SCORE_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleLawyerMatchScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_MATCH_SCORE_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleLlmQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LLM_QUERY_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleLlmQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_LLM_QUERY_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleMinimumCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_MINIMUM_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleMinimumCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_MINIMUM_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdStddevSamplePersonalisationQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_PERSONALISATION_QUERY_ASC',
  SuggestedTargetingsByLawyerIdStddevSamplePersonalisationQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_PERSONALISATION_QUERY_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleRawAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_RAW_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleRawDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_RAW_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleRejectedReasonAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_REJECTED_REASON_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleRejectedReasonDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_REJECTED_REASON_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleRejectedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_REJECTED_TIME_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleRejectedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_REJECTED_TIME_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleTargetCompanyDescriptionAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_TARGET_COMPANY_DESCRIPTION_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleTargetCompanyDescriptionDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_TARGET_COMPANY_DESCRIPTION_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleTitleAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_TITLE_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleTitleDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_TITLE_DESC',
  SuggestedTargetingsByLawyerIdStddevSampleTotalScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_TOTAL_SCORE_ASC',
  SuggestedTargetingsByLawyerIdStddevSampleTotalScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_STDDEV_SAMPLE_TOTAL_SCORE_DESC',
  SuggestedTargetingsByLawyerIdSumCampaignRequestIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_CAMPAIGN_REQUEST_ID_ASC',
  SuggestedTargetingsByLawyerIdSumCampaignRequestIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_CAMPAIGN_REQUEST_ID_DESC',
  SuggestedTargetingsByLawyerIdSumCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdSumCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdSumContactedCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_CONTACTED_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdSumContactedCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_CONTACTED_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdSumCreatedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  SuggestedTargetingsByLawyerIdSumCreatedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  SuggestedTargetingsByLawyerIdSumFocusesAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_FOCUSES_ASC',
  SuggestedTargetingsByLawyerIdSumFocusesDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_FOCUSES_DESC',
  SuggestedTargetingsByLawyerIdSumIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_ID_ASC',
  SuggestedTargetingsByLawyerIdSumIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_ID_DESC',
  SuggestedTargetingsByLawyerIdSumLawyerIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  SuggestedTargetingsByLawyerIdSumLawyerIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  SuggestedTargetingsByLawyerIdSumLawyerMatchScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_LAWYER_MATCH_SCORE_ASC',
  SuggestedTargetingsByLawyerIdSumLawyerMatchScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_LAWYER_MATCH_SCORE_DESC',
  SuggestedTargetingsByLawyerIdSumLlmQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_LLM_QUERY_ASC',
  SuggestedTargetingsByLawyerIdSumLlmQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_LLM_QUERY_DESC',
  SuggestedTargetingsByLawyerIdSumMinimumCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_MINIMUM_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdSumMinimumCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_MINIMUM_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdSumPersonalisationQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_PERSONALISATION_QUERY_ASC',
  SuggestedTargetingsByLawyerIdSumPersonalisationQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_PERSONALISATION_QUERY_DESC',
  SuggestedTargetingsByLawyerIdSumRawAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_RAW_ASC',
  SuggestedTargetingsByLawyerIdSumRawDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_RAW_DESC',
  SuggestedTargetingsByLawyerIdSumRejectedReasonAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_REJECTED_REASON_ASC',
  SuggestedTargetingsByLawyerIdSumRejectedReasonDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_REJECTED_REASON_DESC',
  SuggestedTargetingsByLawyerIdSumRejectedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_REJECTED_TIME_ASC',
  SuggestedTargetingsByLawyerIdSumRejectedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_REJECTED_TIME_DESC',
  SuggestedTargetingsByLawyerIdSumTargetCompanyDescriptionAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_TARGET_COMPANY_DESCRIPTION_ASC',
  SuggestedTargetingsByLawyerIdSumTargetCompanyDescriptionDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_TARGET_COMPANY_DESCRIPTION_DESC',
  SuggestedTargetingsByLawyerIdSumTitleAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_TITLE_ASC',
  SuggestedTargetingsByLawyerIdSumTitleDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_TITLE_DESC',
  SuggestedTargetingsByLawyerIdSumTotalScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_TOTAL_SCORE_ASC',
  SuggestedTargetingsByLawyerIdSumTotalScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_SUM_TOTAL_SCORE_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationCampaignRequestIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_REQUEST_ID_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationCampaignRequestIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_REQUEST_ID_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationContactedCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACTED_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationContactedCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACTED_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationCreatedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationCreatedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationFocusesAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_FOCUSES_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationFocusesDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_FOCUSES_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationLawyerIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationLawyerIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationLawyerMatchScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_MATCH_SCORE_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationLawyerMatchScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_MATCH_SCORE_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationLlmQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LLM_QUERY_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationLlmQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_LLM_QUERY_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationMinimumCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_MINIMUM_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationMinimumCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_MINIMUM_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationPersonalisationQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_PERSONALISATION_QUERY_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationPersonalisationQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_PERSONALISATION_QUERY_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationRawAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_RAW_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationRawDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_RAW_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationRejectedReasonAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_REJECTED_REASON_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationRejectedReasonDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_REJECTED_REASON_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationRejectedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_REJECTED_TIME_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationRejectedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_REJECTED_TIME_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationTargetCompanyDescriptionAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_TARGET_COMPANY_DESCRIPTION_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationTargetCompanyDescriptionDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_TARGET_COMPANY_DESCRIPTION_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationTitleAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_TITLE_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationTitleDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_TITLE_DESC',
  SuggestedTargetingsByLawyerIdVariancePopulationTotalScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_TOTAL_SCORE_ASC',
  SuggestedTargetingsByLawyerIdVariancePopulationTotalScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_POPULATION_TOTAL_SCORE_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleCampaignRequestIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_REQUEST_ID_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleCampaignRequestIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_REQUEST_ID_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleContactedCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACTED_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleContactedCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACTED_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleCreatedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleCreatedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleFocusesAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_FOCUSES_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleFocusesDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_FOCUSES_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleLawyerIdAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleLawyerIdDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleLawyerMatchScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_MATCH_SCORE_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleLawyerMatchScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_MATCH_SCORE_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleLlmQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LLM_QUERY_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleLlmQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_LLM_QUERY_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleMinimumCompanyCountAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_MINIMUM_COMPANY_COUNT_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleMinimumCompanyCountDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_MINIMUM_COMPANY_COUNT_DESC',
  SuggestedTargetingsByLawyerIdVarianceSamplePersonalisationQueryAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_PERSONALISATION_QUERY_ASC',
  SuggestedTargetingsByLawyerIdVarianceSamplePersonalisationQueryDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_PERSONALISATION_QUERY_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleRawAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_RAW_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleRawDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_RAW_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleRejectedReasonAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_REJECTED_REASON_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleRejectedReasonDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_REJECTED_REASON_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleRejectedTimeAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_REJECTED_TIME_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleRejectedTimeDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_REJECTED_TIME_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleTargetCompanyDescriptionAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_TARGET_COMPANY_DESCRIPTION_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleTargetCompanyDescriptionDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_TARGET_COMPANY_DESCRIPTION_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleTitleAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_TITLE_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleTitleDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_TITLE_DESC',
  SuggestedTargetingsByLawyerIdVarianceSampleTotalScoreAsc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_TOTAL_SCORE_ASC',
  SuggestedTargetingsByLawyerIdVarianceSampleTotalScoreDesc = 'SUGGESTED_TARGETINGS_BY_LAWYER_ID_VARIANCE_SAMPLE_TOTAL_SCORE_DESC',
  TestimonialsByLawyerIdAverageCommunicationRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_COMMUNICATION_RATING_ASC',
  TestimonialsByLawyerIdAverageCommunicationRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_COMMUNICATION_RATING_DESC',
  TestimonialsByLawyerIdAverageContactIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_ASC',
  TestimonialsByLawyerIdAverageContactIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_DESC',
  TestimonialsByLawyerIdAverageCostRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_COST_RATING_ASC',
  TestimonialsByLawyerIdAverageCostRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_COST_RATING_DESC',
  TestimonialsByLawyerIdAverageCreatedTimeAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  TestimonialsByLawyerIdAverageCreatedTimeDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  TestimonialsByLawyerIdAverageIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  TestimonialsByLawyerIdAverageIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  TestimonialsByLawyerIdAverageLawyerIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  TestimonialsByLawyerIdAverageLawyerIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  TestimonialsByLawyerIdAverageNegativeCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_NEGATIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdAverageNegativeCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_NEGATIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdAveragePositiveCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_POSITIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdAveragePositiveCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_POSITIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdAveragePublicAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_PUBLIC_ASC',
  TestimonialsByLawyerIdAveragePublicDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_PUBLIC_DESC',
  TestimonialsByLawyerIdAverageReferenceAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_REFERENCE_ASC',
  TestimonialsByLawyerIdAverageReferenceDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_REFERENCE_DESC',
  TestimonialsByLawyerIdAverageResponsivenessRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_RESPONSIVENESS_RATING_ASC',
  TestimonialsByLawyerIdAverageResponsivenessRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_RESPONSIVENESS_RATING_DESC',
  TestimonialsByLawyerIdAverageServiceDescriptionAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_SERVICE_DESCRIPTION_ASC',
  TestimonialsByLawyerIdAverageServiceDescriptionDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_SERVICE_DESCRIPTION_DESC',
  TestimonialsByLawyerIdAverageTargetRelationshipAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_TARGET_RELATIONSHIP_ASC',
  TestimonialsByLawyerIdAverageTargetRelationshipDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_TARGET_RELATIONSHIP_DESC',
  TestimonialsByLawyerIdAverageTimeRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_TIME_RATING_ASC',
  TestimonialsByLawyerIdAverageTimeRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_AVERAGE_TIME_RATING_DESC',
  TestimonialsByLawyerIdCountAsc = 'TESTIMONIALS_BY_LAWYER_ID_COUNT_ASC',
  TestimonialsByLawyerIdCountDesc = 'TESTIMONIALS_BY_LAWYER_ID_COUNT_DESC',
  TestimonialsByLawyerIdDistinctCountCommunicationRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_COMMUNICATION_RATING_ASC',
  TestimonialsByLawyerIdDistinctCountCommunicationRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_COMMUNICATION_RATING_DESC',
  TestimonialsByLawyerIdDistinctCountContactIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  TestimonialsByLawyerIdDistinctCountContactIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  TestimonialsByLawyerIdDistinctCountCostRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_COST_RATING_ASC',
  TestimonialsByLawyerIdDistinctCountCostRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_COST_RATING_DESC',
  TestimonialsByLawyerIdDistinctCountCreatedTimeAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  TestimonialsByLawyerIdDistinctCountCreatedTimeDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  TestimonialsByLawyerIdDistinctCountIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  TestimonialsByLawyerIdDistinctCountIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  TestimonialsByLawyerIdDistinctCountLawyerIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  TestimonialsByLawyerIdDistinctCountLawyerIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  TestimonialsByLawyerIdDistinctCountNegativeCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_NEGATIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdDistinctCountNegativeCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_NEGATIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdDistinctCountPositiveCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_POSITIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdDistinctCountPositiveCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_POSITIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdDistinctCountPublicAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_PUBLIC_ASC',
  TestimonialsByLawyerIdDistinctCountPublicDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_PUBLIC_DESC',
  TestimonialsByLawyerIdDistinctCountReferenceAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_REFERENCE_ASC',
  TestimonialsByLawyerIdDistinctCountReferenceDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_REFERENCE_DESC',
  TestimonialsByLawyerIdDistinctCountResponsivenessRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_RESPONSIVENESS_RATING_ASC',
  TestimonialsByLawyerIdDistinctCountResponsivenessRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_RESPONSIVENESS_RATING_DESC',
  TestimonialsByLawyerIdDistinctCountServiceDescriptionAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_SERVICE_DESCRIPTION_ASC',
  TestimonialsByLawyerIdDistinctCountServiceDescriptionDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_SERVICE_DESCRIPTION_DESC',
  TestimonialsByLawyerIdDistinctCountTargetRelationshipAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_TARGET_RELATIONSHIP_ASC',
  TestimonialsByLawyerIdDistinctCountTargetRelationshipDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_TARGET_RELATIONSHIP_DESC',
  TestimonialsByLawyerIdDistinctCountTimeRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_TIME_RATING_ASC',
  TestimonialsByLawyerIdDistinctCountTimeRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_DISTINCT_COUNT_TIME_RATING_DESC',
  TestimonialsByLawyerIdMaxCommunicationRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_COMMUNICATION_RATING_ASC',
  TestimonialsByLawyerIdMaxCommunicationRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_COMMUNICATION_RATING_DESC',
  TestimonialsByLawyerIdMaxContactIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_CONTACT_ID_ASC',
  TestimonialsByLawyerIdMaxContactIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_CONTACT_ID_DESC',
  TestimonialsByLawyerIdMaxCostRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_COST_RATING_ASC',
  TestimonialsByLawyerIdMaxCostRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_COST_RATING_DESC',
  TestimonialsByLawyerIdMaxCreatedTimeAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  TestimonialsByLawyerIdMaxCreatedTimeDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  TestimonialsByLawyerIdMaxIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_ID_ASC',
  TestimonialsByLawyerIdMaxIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_ID_DESC',
  TestimonialsByLawyerIdMaxLawyerIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  TestimonialsByLawyerIdMaxLawyerIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  TestimonialsByLawyerIdMaxNegativeCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_NEGATIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdMaxNegativeCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_NEGATIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdMaxPositiveCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_POSITIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdMaxPositiveCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_POSITIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdMaxPublicAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_PUBLIC_ASC',
  TestimonialsByLawyerIdMaxPublicDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_PUBLIC_DESC',
  TestimonialsByLawyerIdMaxReferenceAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_REFERENCE_ASC',
  TestimonialsByLawyerIdMaxReferenceDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_REFERENCE_DESC',
  TestimonialsByLawyerIdMaxResponsivenessRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_RESPONSIVENESS_RATING_ASC',
  TestimonialsByLawyerIdMaxResponsivenessRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_RESPONSIVENESS_RATING_DESC',
  TestimonialsByLawyerIdMaxServiceDescriptionAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_SERVICE_DESCRIPTION_ASC',
  TestimonialsByLawyerIdMaxServiceDescriptionDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_SERVICE_DESCRIPTION_DESC',
  TestimonialsByLawyerIdMaxTargetRelationshipAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_TARGET_RELATIONSHIP_ASC',
  TestimonialsByLawyerIdMaxTargetRelationshipDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_TARGET_RELATIONSHIP_DESC',
  TestimonialsByLawyerIdMaxTimeRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_TIME_RATING_ASC',
  TestimonialsByLawyerIdMaxTimeRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_MAX_TIME_RATING_DESC',
  TestimonialsByLawyerIdMinCommunicationRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_COMMUNICATION_RATING_ASC',
  TestimonialsByLawyerIdMinCommunicationRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_COMMUNICATION_RATING_DESC',
  TestimonialsByLawyerIdMinContactIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_CONTACT_ID_ASC',
  TestimonialsByLawyerIdMinContactIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_CONTACT_ID_DESC',
  TestimonialsByLawyerIdMinCostRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_COST_RATING_ASC',
  TestimonialsByLawyerIdMinCostRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_COST_RATING_DESC',
  TestimonialsByLawyerIdMinCreatedTimeAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  TestimonialsByLawyerIdMinCreatedTimeDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  TestimonialsByLawyerIdMinIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_ID_ASC',
  TestimonialsByLawyerIdMinIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_ID_DESC',
  TestimonialsByLawyerIdMinLawyerIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  TestimonialsByLawyerIdMinLawyerIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  TestimonialsByLawyerIdMinNegativeCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_NEGATIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdMinNegativeCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_NEGATIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdMinPositiveCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_POSITIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdMinPositiveCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_POSITIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdMinPublicAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_PUBLIC_ASC',
  TestimonialsByLawyerIdMinPublicDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_PUBLIC_DESC',
  TestimonialsByLawyerIdMinReferenceAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_REFERENCE_ASC',
  TestimonialsByLawyerIdMinReferenceDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_REFERENCE_DESC',
  TestimonialsByLawyerIdMinResponsivenessRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_RESPONSIVENESS_RATING_ASC',
  TestimonialsByLawyerIdMinResponsivenessRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_RESPONSIVENESS_RATING_DESC',
  TestimonialsByLawyerIdMinServiceDescriptionAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_SERVICE_DESCRIPTION_ASC',
  TestimonialsByLawyerIdMinServiceDescriptionDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_SERVICE_DESCRIPTION_DESC',
  TestimonialsByLawyerIdMinTargetRelationshipAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_TARGET_RELATIONSHIP_ASC',
  TestimonialsByLawyerIdMinTargetRelationshipDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_TARGET_RELATIONSHIP_DESC',
  TestimonialsByLawyerIdMinTimeRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_TIME_RATING_ASC',
  TestimonialsByLawyerIdMinTimeRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_MIN_TIME_RATING_DESC',
  TestimonialsByLawyerIdStddevPopulationCommunicationRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_COMMUNICATION_RATING_ASC',
  TestimonialsByLawyerIdStddevPopulationCommunicationRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_COMMUNICATION_RATING_DESC',
  TestimonialsByLawyerIdStddevPopulationContactIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  TestimonialsByLawyerIdStddevPopulationContactIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  TestimonialsByLawyerIdStddevPopulationCostRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_COST_RATING_ASC',
  TestimonialsByLawyerIdStddevPopulationCostRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_COST_RATING_DESC',
  TestimonialsByLawyerIdStddevPopulationCreatedTimeAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  TestimonialsByLawyerIdStddevPopulationCreatedTimeDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  TestimonialsByLawyerIdStddevPopulationIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  TestimonialsByLawyerIdStddevPopulationIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  TestimonialsByLawyerIdStddevPopulationLawyerIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  TestimonialsByLawyerIdStddevPopulationLawyerIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  TestimonialsByLawyerIdStddevPopulationNegativeCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_NEGATIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdStddevPopulationNegativeCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_NEGATIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdStddevPopulationPositiveCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_POSITIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdStddevPopulationPositiveCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_POSITIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdStddevPopulationPublicAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_PUBLIC_ASC',
  TestimonialsByLawyerIdStddevPopulationPublicDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_PUBLIC_DESC',
  TestimonialsByLawyerIdStddevPopulationReferenceAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_REFERENCE_ASC',
  TestimonialsByLawyerIdStddevPopulationReferenceDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_REFERENCE_DESC',
  TestimonialsByLawyerIdStddevPopulationResponsivenessRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_RESPONSIVENESS_RATING_ASC',
  TestimonialsByLawyerIdStddevPopulationResponsivenessRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_RESPONSIVENESS_RATING_DESC',
  TestimonialsByLawyerIdStddevPopulationServiceDescriptionAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_SERVICE_DESCRIPTION_ASC',
  TestimonialsByLawyerIdStddevPopulationServiceDescriptionDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_SERVICE_DESCRIPTION_DESC',
  TestimonialsByLawyerIdStddevPopulationTargetRelationshipAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_TARGET_RELATIONSHIP_ASC',
  TestimonialsByLawyerIdStddevPopulationTargetRelationshipDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_TARGET_RELATIONSHIP_DESC',
  TestimonialsByLawyerIdStddevPopulationTimeRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_TIME_RATING_ASC',
  TestimonialsByLawyerIdStddevPopulationTimeRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_POPULATION_TIME_RATING_DESC',
  TestimonialsByLawyerIdStddevSampleCommunicationRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_COMMUNICATION_RATING_ASC',
  TestimonialsByLawyerIdStddevSampleCommunicationRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_COMMUNICATION_RATING_DESC',
  TestimonialsByLawyerIdStddevSampleContactIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  TestimonialsByLawyerIdStddevSampleContactIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  TestimonialsByLawyerIdStddevSampleCostRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_COST_RATING_ASC',
  TestimonialsByLawyerIdStddevSampleCostRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_COST_RATING_DESC',
  TestimonialsByLawyerIdStddevSampleCreatedTimeAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  TestimonialsByLawyerIdStddevSampleCreatedTimeDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  TestimonialsByLawyerIdStddevSampleIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  TestimonialsByLawyerIdStddevSampleIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  TestimonialsByLawyerIdStddevSampleLawyerIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  TestimonialsByLawyerIdStddevSampleLawyerIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  TestimonialsByLawyerIdStddevSampleNegativeCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_NEGATIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdStddevSampleNegativeCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_NEGATIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdStddevSamplePositiveCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_POSITIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdStddevSamplePositiveCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_POSITIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdStddevSamplePublicAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_PUBLIC_ASC',
  TestimonialsByLawyerIdStddevSamplePublicDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_PUBLIC_DESC',
  TestimonialsByLawyerIdStddevSampleReferenceAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_REFERENCE_ASC',
  TestimonialsByLawyerIdStddevSampleReferenceDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_REFERENCE_DESC',
  TestimonialsByLawyerIdStddevSampleResponsivenessRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_RESPONSIVENESS_RATING_ASC',
  TestimonialsByLawyerIdStddevSampleResponsivenessRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_RESPONSIVENESS_RATING_DESC',
  TestimonialsByLawyerIdStddevSampleServiceDescriptionAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_SERVICE_DESCRIPTION_ASC',
  TestimonialsByLawyerIdStddevSampleServiceDescriptionDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_SERVICE_DESCRIPTION_DESC',
  TestimonialsByLawyerIdStddevSampleTargetRelationshipAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_TARGET_RELATIONSHIP_ASC',
  TestimonialsByLawyerIdStddevSampleTargetRelationshipDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_TARGET_RELATIONSHIP_DESC',
  TestimonialsByLawyerIdStddevSampleTimeRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_TIME_RATING_ASC',
  TestimonialsByLawyerIdStddevSampleTimeRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_STDDEV_SAMPLE_TIME_RATING_DESC',
  TestimonialsByLawyerIdSumCommunicationRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_COMMUNICATION_RATING_ASC',
  TestimonialsByLawyerIdSumCommunicationRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_COMMUNICATION_RATING_DESC',
  TestimonialsByLawyerIdSumContactIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_CONTACT_ID_ASC',
  TestimonialsByLawyerIdSumContactIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_CONTACT_ID_DESC',
  TestimonialsByLawyerIdSumCostRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_COST_RATING_ASC',
  TestimonialsByLawyerIdSumCostRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_COST_RATING_DESC',
  TestimonialsByLawyerIdSumCreatedTimeAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  TestimonialsByLawyerIdSumCreatedTimeDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  TestimonialsByLawyerIdSumIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_ID_ASC',
  TestimonialsByLawyerIdSumIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_ID_DESC',
  TestimonialsByLawyerIdSumLawyerIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  TestimonialsByLawyerIdSumLawyerIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  TestimonialsByLawyerIdSumNegativeCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_NEGATIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdSumNegativeCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_NEGATIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdSumPositiveCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_POSITIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdSumPositiveCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_POSITIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdSumPublicAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_PUBLIC_ASC',
  TestimonialsByLawyerIdSumPublicDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_PUBLIC_DESC',
  TestimonialsByLawyerIdSumReferenceAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_REFERENCE_ASC',
  TestimonialsByLawyerIdSumReferenceDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_REFERENCE_DESC',
  TestimonialsByLawyerIdSumResponsivenessRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_RESPONSIVENESS_RATING_ASC',
  TestimonialsByLawyerIdSumResponsivenessRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_RESPONSIVENESS_RATING_DESC',
  TestimonialsByLawyerIdSumServiceDescriptionAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_SERVICE_DESCRIPTION_ASC',
  TestimonialsByLawyerIdSumServiceDescriptionDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_SERVICE_DESCRIPTION_DESC',
  TestimonialsByLawyerIdSumTargetRelationshipAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_TARGET_RELATIONSHIP_ASC',
  TestimonialsByLawyerIdSumTargetRelationshipDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_TARGET_RELATIONSHIP_DESC',
  TestimonialsByLawyerIdSumTimeRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_TIME_RATING_ASC',
  TestimonialsByLawyerIdSumTimeRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_SUM_TIME_RATING_DESC',
  TestimonialsByLawyerIdVariancePopulationCommunicationRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_COMMUNICATION_RATING_ASC',
  TestimonialsByLawyerIdVariancePopulationCommunicationRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_COMMUNICATION_RATING_DESC',
  TestimonialsByLawyerIdVariancePopulationContactIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  TestimonialsByLawyerIdVariancePopulationContactIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  TestimonialsByLawyerIdVariancePopulationCostRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_COST_RATING_ASC',
  TestimonialsByLawyerIdVariancePopulationCostRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_COST_RATING_DESC',
  TestimonialsByLawyerIdVariancePopulationCreatedTimeAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  TestimonialsByLawyerIdVariancePopulationCreatedTimeDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  TestimonialsByLawyerIdVariancePopulationIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  TestimonialsByLawyerIdVariancePopulationIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  TestimonialsByLawyerIdVariancePopulationLawyerIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  TestimonialsByLawyerIdVariancePopulationLawyerIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  TestimonialsByLawyerIdVariancePopulationNegativeCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_NEGATIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdVariancePopulationNegativeCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_NEGATIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdVariancePopulationPositiveCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_POSITIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdVariancePopulationPositiveCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_POSITIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdVariancePopulationPublicAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_PUBLIC_ASC',
  TestimonialsByLawyerIdVariancePopulationPublicDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_PUBLIC_DESC',
  TestimonialsByLawyerIdVariancePopulationReferenceAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_REFERENCE_ASC',
  TestimonialsByLawyerIdVariancePopulationReferenceDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_REFERENCE_DESC',
  TestimonialsByLawyerIdVariancePopulationResponsivenessRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_RESPONSIVENESS_RATING_ASC',
  TestimonialsByLawyerIdVariancePopulationResponsivenessRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_RESPONSIVENESS_RATING_DESC',
  TestimonialsByLawyerIdVariancePopulationServiceDescriptionAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_SERVICE_DESCRIPTION_ASC',
  TestimonialsByLawyerIdVariancePopulationServiceDescriptionDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_SERVICE_DESCRIPTION_DESC',
  TestimonialsByLawyerIdVariancePopulationTargetRelationshipAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_TARGET_RELATIONSHIP_ASC',
  TestimonialsByLawyerIdVariancePopulationTargetRelationshipDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_TARGET_RELATIONSHIP_DESC',
  TestimonialsByLawyerIdVariancePopulationTimeRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_TIME_RATING_ASC',
  TestimonialsByLawyerIdVariancePopulationTimeRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_POPULATION_TIME_RATING_DESC',
  TestimonialsByLawyerIdVarianceSampleCommunicationRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_COMMUNICATION_RATING_ASC',
  TestimonialsByLawyerIdVarianceSampleCommunicationRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_COMMUNICATION_RATING_DESC',
  TestimonialsByLawyerIdVarianceSampleContactIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  TestimonialsByLawyerIdVarianceSampleContactIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  TestimonialsByLawyerIdVarianceSampleCostRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_COST_RATING_ASC',
  TestimonialsByLawyerIdVarianceSampleCostRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_COST_RATING_DESC',
  TestimonialsByLawyerIdVarianceSampleCreatedTimeAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  TestimonialsByLawyerIdVarianceSampleCreatedTimeDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  TestimonialsByLawyerIdVarianceSampleIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  TestimonialsByLawyerIdVarianceSampleIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  TestimonialsByLawyerIdVarianceSampleLawyerIdAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  TestimonialsByLawyerIdVarianceSampleLawyerIdDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  TestimonialsByLawyerIdVarianceSampleNegativeCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_NEGATIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdVarianceSampleNegativeCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_NEGATIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdVarianceSamplePositiveCommentsAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_POSITIVE_COMMENTS_ASC',
  TestimonialsByLawyerIdVarianceSamplePositiveCommentsDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_POSITIVE_COMMENTS_DESC',
  TestimonialsByLawyerIdVarianceSamplePublicAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_PUBLIC_ASC',
  TestimonialsByLawyerIdVarianceSamplePublicDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_PUBLIC_DESC',
  TestimonialsByLawyerIdVarianceSampleReferenceAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_REFERENCE_ASC',
  TestimonialsByLawyerIdVarianceSampleReferenceDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_REFERENCE_DESC',
  TestimonialsByLawyerIdVarianceSampleResponsivenessRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_RESPONSIVENESS_RATING_ASC',
  TestimonialsByLawyerIdVarianceSampleResponsivenessRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_RESPONSIVENESS_RATING_DESC',
  TestimonialsByLawyerIdVarianceSampleServiceDescriptionAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_SERVICE_DESCRIPTION_ASC',
  TestimonialsByLawyerIdVarianceSampleServiceDescriptionDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_SERVICE_DESCRIPTION_DESC',
  TestimonialsByLawyerIdVarianceSampleTargetRelationshipAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_TARGET_RELATIONSHIP_ASC',
  TestimonialsByLawyerIdVarianceSampleTargetRelationshipDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_TARGET_RELATIONSHIP_DESC',
  TestimonialsByLawyerIdVarianceSampleTimeRatingAsc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_TIME_RATING_ASC',
  TestimonialsByLawyerIdVarianceSampleTimeRatingDesc = 'TESTIMONIALS_BY_LAWYER_ID_VARIANCE_SAMPLE_TIME_RATING_DESC',
  TimezoneAsc = 'TIMEZONE_ASC',
  TimezoneDesc = 'TIMEZONE_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TransactionalEmailsByLawyerIdAverageCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_CAMPAIGN_ID_ASC',
  TransactionalEmailsByLawyerIdAverageCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_CAMPAIGN_ID_DESC',
  TransactionalEmailsByLawyerIdAverageContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_ASC',
  TransactionalEmailsByLawyerIdAverageContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_CONTACT_ID_DESC',
  TransactionalEmailsByLawyerIdAverageCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_ASC',
  TransactionalEmailsByLawyerIdAverageCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_CREATED_TIME_DESC',
  TransactionalEmailsByLawyerIdAverageIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_ID_ASC',
  TransactionalEmailsByLawyerIdAverageIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_ID_DESC',
  TransactionalEmailsByLawyerIdAverageLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_ASC',
  TransactionalEmailsByLawyerIdAverageLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_LAWYER_ID_DESC',
  TransactionalEmailsByLawyerIdAverageMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_MEETING_ID_ASC',
  TransactionalEmailsByLawyerIdAverageMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_MEETING_ID_DESC',
  TransactionalEmailsByLawyerIdAveragePostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByLawyerIdAveragePostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_AVERAGE_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByLawyerIdCountAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_COUNT_ASC',
  TransactionalEmailsByLawyerIdCountDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_COUNT_DESC',
  TransactionalEmailsByLawyerIdDistinctCountCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  TransactionalEmailsByLawyerIdDistinctCountCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  TransactionalEmailsByLawyerIdDistinctCountContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  TransactionalEmailsByLawyerIdDistinctCountContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  TransactionalEmailsByLawyerIdDistinctCountCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  TransactionalEmailsByLawyerIdDistinctCountCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  TransactionalEmailsByLawyerIdDistinctCountIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_ID_ASC',
  TransactionalEmailsByLawyerIdDistinctCountIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_ID_DESC',
  TransactionalEmailsByLawyerIdDistinctCountLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  TransactionalEmailsByLawyerIdDistinctCountLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  TransactionalEmailsByLawyerIdDistinctCountMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_MEETING_ID_ASC',
  TransactionalEmailsByLawyerIdDistinctCountMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_MEETING_ID_DESC',
  TransactionalEmailsByLawyerIdDistinctCountPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByLawyerIdDistinctCountPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_DISTINCT_COUNT_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByLawyerIdMaxCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_CAMPAIGN_ID_ASC',
  TransactionalEmailsByLawyerIdMaxCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_CAMPAIGN_ID_DESC',
  TransactionalEmailsByLawyerIdMaxContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_CONTACT_ID_ASC',
  TransactionalEmailsByLawyerIdMaxContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_CONTACT_ID_DESC',
  TransactionalEmailsByLawyerIdMaxCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_CREATED_TIME_ASC',
  TransactionalEmailsByLawyerIdMaxCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_CREATED_TIME_DESC',
  TransactionalEmailsByLawyerIdMaxIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_ID_ASC',
  TransactionalEmailsByLawyerIdMaxIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_ID_DESC',
  TransactionalEmailsByLawyerIdMaxLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_LAWYER_ID_ASC',
  TransactionalEmailsByLawyerIdMaxLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_LAWYER_ID_DESC',
  TransactionalEmailsByLawyerIdMaxMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_MEETING_ID_ASC',
  TransactionalEmailsByLawyerIdMaxMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_MEETING_ID_DESC',
  TransactionalEmailsByLawyerIdMaxPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByLawyerIdMaxPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MAX_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByLawyerIdMinCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_CAMPAIGN_ID_ASC',
  TransactionalEmailsByLawyerIdMinCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_CAMPAIGN_ID_DESC',
  TransactionalEmailsByLawyerIdMinContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_CONTACT_ID_ASC',
  TransactionalEmailsByLawyerIdMinContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_CONTACT_ID_DESC',
  TransactionalEmailsByLawyerIdMinCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_CREATED_TIME_ASC',
  TransactionalEmailsByLawyerIdMinCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_CREATED_TIME_DESC',
  TransactionalEmailsByLawyerIdMinIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_ID_ASC',
  TransactionalEmailsByLawyerIdMinIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_ID_DESC',
  TransactionalEmailsByLawyerIdMinLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_LAWYER_ID_ASC',
  TransactionalEmailsByLawyerIdMinLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_LAWYER_ID_DESC',
  TransactionalEmailsByLawyerIdMinMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_MEETING_ID_ASC',
  TransactionalEmailsByLawyerIdMinMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_MEETING_ID_DESC',
  TransactionalEmailsByLawyerIdMinPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByLawyerIdMinPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_MIN_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByLawyerIdStddevPopulationCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  TransactionalEmailsByLawyerIdStddevPopulationCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  TransactionalEmailsByLawyerIdStddevPopulationContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  TransactionalEmailsByLawyerIdStddevPopulationContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  TransactionalEmailsByLawyerIdStddevPopulationCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  TransactionalEmailsByLawyerIdStddevPopulationCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  TransactionalEmailsByLawyerIdStddevPopulationIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_ID_ASC',
  TransactionalEmailsByLawyerIdStddevPopulationIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_ID_DESC',
  TransactionalEmailsByLawyerIdStddevPopulationLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  TransactionalEmailsByLawyerIdStddevPopulationLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  TransactionalEmailsByLawyerIdStddevPopulationMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_MEETING_ID_ASC',
  TransactionalEmailsByLawyerIdStddevPopulationMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_MEETING_ID_DESC',
  TransactionalEmailsByLawyerIdStddevPopulationPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByLawyerIdStddevPopulationPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_POPULATION_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByLawyerIdStddevSampleCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  TransactionalEmailsByLawyerIdStddevSampleCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  TransactionalEmailsByLawyerIdStddevSampleContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  TransactionalEmailsByLawyerIdStddevSampleContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  TransactionalEmailsByLawyerIdStddevSampleCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  TransactionalEmailsByLawyerIdStddevSampleCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  TransactionalEmailsByLawyerIdStddevSampleIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_ASC',
  TransactionalEmailsByLawyerIdStddevSampleIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_ID_DESC',
  TransactionalEmailsByLawyerIdStddevSampleLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  TransactionalEmailsByLawyerIdStddevSampleLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  TransactionalEmailsByLawyerIdStddevSampleMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_MEETING_ID_ASC',
  TransactionalEmailsByLawyerIdStddevSampleMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_MEETING_ID_DESC',
  TransactionalEmailsByLawyerIdStddevSamplePostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByLawyerIdStddevSamplePostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_STDDEV_SAMPLE_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByLawyerIdSumCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_CAMPAIGN_ID_ASC',
  TransactionalEmailsByLawyerIdSumCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_CAMPAIGN_ID_DESC',
  TransactionalEmailsByLawyerIdSumContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_CONTACT_ID_ASC',
  TransactionalEmailsByLawyerIdSumContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_CONTACT_ID_DESC',
  TransactionalEmailsByLawyerIdSumCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_CREATED_TIME_ASC',
  TransactionalEmailsByLawyerIdSumCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_CREATED_TIME_DESC',
  TransactionalEmailsByLawyerIdSumIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_ID_ASC',
  TransactionalEmailsByLawyerIdSumIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_ID_DESC',
  TransactionalEmailsByLawyerIdSumLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_LAWYER_ID_ASC',
  TransactionalEmailsByLawyerIdSumLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_LAWYER_ID_DESC',
  TransactionalEmailsByLawyerIdSumMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_MEETING_ID_ASC',
  TransactionalEmailsByLawyerIdSumMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_MEETING_ID_DESC',
  TransactionalEmailsByLawyerIdSumPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByLawyerIdSumPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_SUM_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByLawyerIdVariancePopulationCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  TransactionalEmailsByLawyerIdVariancePopulationCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  TransactionalEmailsByLawyerIdVariancePopulationContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  TransactionalEmailsByLawyerIdVariancePopulationContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  TransactionalEmailsByLawyerIdVariancePopulationCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  TransactionalEmailsByLawyerIdVariancePopulationCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  TransactionalEmailsByLawyerIdVariancePopulationIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_ASC',
  TransactionalEmailsByLawyerIdVariancePopulationIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_ID_DESC',
  TransactionalEmailsByLawyerIdVariancePopulationLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  TransactionalEmailsByLawyerIdVariancePopulationLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  TransactionalEmailsByLawyerIdVariancePopulationMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_MEETING_ID_ASC',
  TransactionalEmailsByLawyerIdVariancePopulationMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_MEETING_ID_DESC',
  TransactionalEmailsByLawyerIdVariancePopulationPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByLawyerIdVariancePopulationPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_POPULATION_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByLawyerIdVarianceSampleCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  TransactionalEmailsByLawyerIdVarianceSampleCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  TransactionalEmailsByLawyerIdVarianceSampleContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  TransactionalEmailsByLawyerIdVarianceSampleContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  TransactionalEmailsByLawyerIdVarianceSampleCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  TransactionalEmailsByLawyerIdVarianceSampleCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  TransactionalEmailsByLawyerIdVarianceSampleIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_ASC',
  TransactionalEmailsByLawyerIdVarianceSampleIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_ID_DESC',
  TransactionalEmailsByLawyerIdVarianceSampleLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  TransactionalEmailsByLawyerIdVarianceSampleLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  TransactionalEmailsByLawyerIdVarianceSampleMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_MEETING_ID_ASC',
  TransactionalEmailsByLawyerIdVarianceSampleMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_MEETING_ID_DESC',
  TransactionalEmailsByLawyerIdVarianceSamplePostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByLawyerIdVarianceSamplePostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_LAWYER_ID_VARIANCE_SAMPLE_POSTMARK_TEMPLATE_ID_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC',
  UserTypeAsc = 'USER_TYPE_ASC',
  UserTypeDesc = 'USER_TYPE_DESC',
  ValuePropositionAsc = 'VALUE_PROPOSITION_ASC',
  ValuePropositionDesc = 'VALUE_PROPOSITION_DESC',
  ValuePropositionGuessedTimeAsc = 'VALUE_PROPOSITION_GUESSED_TIME_ASC',
  ValuePropositionGuessedTimeDesc = 'VALUE_PROPOSITION_GUESSED_TIME_DESC',
  WantsToChooseDomainsAsc = 'WANTS_TO_CHOOSE_DOMAINS_ASC',
  WantsToChooseDomainsDesc = 'WANTS_TO_CHOOSE_DOMAINS_DESC',
  WebsiteUrlAsc = 'WEBSITE_URL_ASC',
  WebsiteUrlDesc = 'WEBSITE_URL_DESC'
}

export type LinkedinConnectionRequest = Node & {
  __typename?: 'LinkedinConnectionRequest';
  /** Reads a single `CampaignMember` that is related to this `LinkedinConnectionRequest`. */
  campaignMemberByCampaignMemberId?: Maybe<CampaignMember>;
  campaignMemberId: Scalars['UUID']['output'];
  createdTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  messageText?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  sendAt?: Maybe<Scalars['Datetime']['output']>;
  status: Scalars['String']['output'];
  updatedTime: Scalars['Datetime']['output'];
};

export type LinkedinConnectionRequestAggregates = {
  __typename?: 'LinkedinConnectionRequestAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LinkedinConnectionRequestDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `LinkedinConnectionRequest` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LinkedinConnectionRequestCondition = {
  /** Checks for equality with the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `messageText` field. */
  messageText?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `sendAt` field. */
  sendAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type LinkedinConnectionRequestDistinctCountAggregates = {
  __typename?: 'LinkedinConnectionRequestDistinctCountAggregates';
  /** Distinct count of campaignMemberId across the matching connection */
  campaignMemberId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of messageText across the matching connection */
  messageText?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of sendAt across the matching connection */
  sendAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `LinkedinConnectionRequest` object types. All fields are combined with a logical ‘and.’ */
export type LinkedinConnectionRequestFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LinkedinConnectionRequestFilter>>;
  /** Filter by the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `messageText` field. */
  messageText?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LinkedinConnectionRequestFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LinkedinConnectionRequestFilter>>;
  /** Filter by the object’s `sendAt` field. */
  sendAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `LinkedinConnectionRequest` for usage during aggregation. */
export enum LinkedinConnectionRequestGroupBy {
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  MessageText = 'MESSAGE_TEXT',
  SendAt = 'SEND_AT',
  SendAtTruncatedToDay = 'SEND_AT_TRUNCATED_TO_DAY',
  SendAtTruncatedToHour = 'SEND_AT_TRUNCATED_TO_HOUR',
  Status = 'STATUS',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type LinkedinConnectionRequestHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinConnectionRequestHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LinkedinConnectionRequest` aggregates. */
export type LinkedinConnectionRequestHavingInput = {
  AND?: InputMaybe<Array<LinkedinConnectionRequestHavingInput>>;
  OR?: InputMaybe<Array<LinkedinConnectionRequestHavingInput>>;
  average?: InputMaybe<LinkedinConnectionRequestHavingAverageInput>;
  distinctCount?: InputMaybe<LinkedinConnectionRequestHavingDistinctCountInput>;
  max?: InputMaybe<LinkedinConnectionRequestHavingMaxInput>;
  min?: InputMaybe<LinkedinConnectionRequestHavingMinInput>;
  stddevPopulation?: InputMaybe<LinkedinConnectionRequestHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LinkedinConnectionRequestHavingStddevSampleInput>;
  sum?: InputMaybe<LinkedinConnectionRequestHavingSumInput>;
  variancePopulation?: InputMaybe<LinkedinConnectionRequestHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LinkedinConnectionRequestHavingVarianceSampleInput>;
};

export type LinkedinConnectionRequestHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinConnectionRequestHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinConnectionRequestHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinConnectionRequestHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinConnectionRequestHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinConnectionRequestHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinConnectionRequestHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type LinkedinConnectionRequestSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: LinkedinConnectionRequestSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type LinkedinConnectionRequestSortBy = {
  field?: InputMaybe<LinkedinConnectionRequestSortableField>;
};

/** Sortable concrete fields for the `LinkedinConnectionRequest` type. */
export enum LinkedinConnectionRequestSortableField {
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  MessageText = 'MESSAGE_TEXT',
  SendAt = 'SEND_AT',
  Status = 'STATUS',
  UpdatedTime = 'UPDATED_TIME'
}

/** A connection to a list of `LinkedinConnectionRequest` values. */
export type LinkedinConnectionRequestsConnection = {
  __typename?: 'LinkedinConnectionRequestsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LinkedinConnectionRequestAggregates>;
  /** A list of edges which contains the `LinkedinConnectionRequest` and cursor to aid in pagination. */
  edges: Array<LinkedinConnectionRequestsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LinkedinConnectionRequestAggregates>>;
  /** A list of `LinkedinConnectionRequest` objects. */
  nodes: Array<Maybe<LinkedinConnectionRequest>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LinkedinConnectionRequest` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `LinkedinConnectionRequest` values. */
export type LinkedinConnectionRequestsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LinkedinConnectionRequestGroupBy>;
  having?: InputMaybe<LinkedinConnectionRequestHavingInput>;
};

/** A `LinkedinConnectionRequest` edge in the connection. */
export type LinkedinConnectionRequestsEdge = {
  __typename?: 'LinkedinConnectionRequestsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `LinkedinConnectionRequest` at the end of the edge. */
  node?: Maybe<LinkedinConnectionRequest>;
};

/** Methods to use when ordering `LinkedinConnectionRequest`. */
export enum LinkedinConnectionRequestsOrderBy {
  CampaignMemberIdAsc = 'CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberIdDesc = 'CAMPAIGN_MEMBER_ID_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MessageTextAsc = 'MESSAGE_TEXT_ASC',
  MessageTextDesc = 'MESSAGE_TEXT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SendAtAsc = 'SEND_AT_ASC',
  SendAtDesc = 'SEND_AT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

export type LinkedinEvent = Node & {
  __typename?: 'LinkedinEvent';
  /** Reads a single `CampaignMember` that is related to this `LinkedinEvent`. */
  campaignMemberByCampaignMemberId?: Maybe<CampaignMember>;
  campaignMemberId: Scalars['UUID']['output'];
  createdTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  status: LinkedinEventStatus;
  statusPayload?: Maybe<Scalars['String']['output']>;
};

export type LinkedinEventAggregates = {
  __typename?: 'LinkedinEventAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LinkedinEventDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `LinkedinEvent` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LinkedinEventCondition = {
  /** Checks for equality with the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<LinkedinEventStatus>;
  /** Checks for equality with the object’s `statusPayload` field. */
  statusPayload?: InputMaybe<Scalars['String']['input']>;
};

export type LinkedinEventDistinctCountAggregates = {
  __typename?: 'LinkedinEventDistinctCountAggregates';
  /** Distinct count of campaignMemberId across the matching connection */
  campaignMemberId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of statusPayload across the matching connection */
  statusPayload?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `LinkedinEvent` object types. All fields are combined with a logical ‘and.’ */
export type LinkedinEventFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LinkedinEventFilter>>;
  /** Filter by the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LinkedinEventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LinkedinEventFilter>>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<LinkedinEventStatusFilter>;
  /** Filter by the object’s `statusPayload` field. */
  statusPayload?: InputMaybe<StringFilter>;
};

/** Grouping methods for `LinkedinEvent` for usage during aggregation. */
export enum LinkedinEventGroupBy {
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  Status = 'STATUS',
  StatusPayload = 'STATUS_PAYLOAD'
}

export type LinkedinEventHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinEventHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LinkedinEvent` aggregates. */
export type LinkedinEventHavingInput = {
  AND?: InputMaybe<Array<LinkedinEventHavingInput>>;
  OR?: InputMaybe<Array<LinkedinEventHavingInput>>;
  average?: InputMaybe<LinkedinEventHavingAverageInput>;
  distinctCount?: InputMaybe<LinkedinEventHavingDistinctCountInput>;
  max?: InputMaybe<LinkedinEventHavingMaxInput>;
  min?: InputMaybe<LinkedinEventHavingMinInput>;
  stddevPopulation?: InputMaybe<LinkedinEventHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LinkedinEventHavingStddevSampleInput>;
  sum?: InputMaybe<LinkedinEventHavingSumInput>;
  variancePopulation?: InputMaybe<LinkedinEventHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LinkedinEventHavingVarianceSampleInput>;
};

export type LinkedinEventHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinEventHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinEventHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinEventHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinEventHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinEventHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinEventHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type LinkedinEventSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: LinkedinEventSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type LinkedinEventSortBy = {
  field?: InputMaybe<LinkedinEventSortableField>;
};

/** Sortable concrete fields for the `LinkedinEvent` type. */
export enum LinkedinEventSortableField {
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  Status = 'STATUS',
  StatusPayload = 'STATUS_PAYLOAD'
}

export enum LinkedinEventStatus {
  Created = 'CREATED',
  MemberAdded = 'MEMBER_ADDED',
  MemberConnected = 'MEMBER_CONNECTED',
  MemberInvitationSent = 'MEMBER_INVITATION_SENT',
  MemberInvitationWithdrawn = 'MEMBER_INVITATION_WITHDRAWN',
  MemberRemoved = 'MEMBER_REMOVED',
  ReplyDetected = 'REPLY_DETECTED'
}

/** A filter to be used against LinkedinEventStatus fields. All fields are combined with a logical ‘and.’ */
export type LinkedinEventStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LinkedinEventStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LinkedinEventStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LinkedinEventStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LinkedinEventStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LinkedinEventStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LinkedinEventStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LinkedinEventStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LinkedinEventStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LinkedinEventStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LinkedinEventStatus>>;
};

/** A connection to a list of `LinkedinEvent` values. */
export type LinkedinEventsConnection = {
  __typename?: 'LinkedinEventsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LinkedinEventAggregates>;
  /** A list of edges which contains the `LinkedinEvent` and cursor to aid in pagination. */
  edges: Array<LinkedinEventsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LinkedinEventAggregates>>;
  /** A list of `LinkedinEvent` objects. */
  nodes: Array<Maybe<LinkedinEvent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LinkedinEvent` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `LinkedinEvent` values. */
export type LinkedinEventsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LinkedinEventGroupBy>;
  having?: InputMaybe<LinkedinEventHavingInput>;
};

/** A `LinkedinEvent` edge in the connection. */
export type LinkedinEventsEdge = {
  __typename?: 'LinkedinEventsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `LinkedinEvent` at the end of the edge. */
  node?: Maybe<LinkedinEvent>;
};

/** Methods to use when ordering `LinkedinEvent`. */
export enum LinkedinEventsOrderBy {
  CampaignMemberIdAsc = 'CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberIdDesc = 'CAMPAIGN_MEMBER_ID_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  StatusPayloadAsc = 'STATUS_PAYLOAD_ASC',
  StatusPayloadDesc = 'STATUS_PAYLOAD_DESC'
}

export enum LinkedinLoginStatus {
  IncorrectCreds = 'INCORRECT_CREDS',
  LoggedIn = 'LOGGED_IN',
  LoggedOut = 'LOGGED_OUT',
  VerificationFailed = 'VERIFICATION_FAILED',
  WaitingFor_2Fa = 'WAITING_FOR_2_FA',
  WaitingForVerification = 'WAITING_FOR_VERIFICATION'
}

/** A filter to be used against LinkedinLoginStatus fields. All fields are combined with a logical ‘and.’ */
export type LinkedinLoginStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<LinkedinLoginStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<LinkedinLoginStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<LinkedinLoginStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<LinkedinLoginStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<LinkedinLoginStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<LinkedinLoginStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<LinkedinLoginStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<LinkedinLoginStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<LinkedinLoginStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<LinkedinLoginStatus>>;
};

export type LinkedinMessage = Node & {
  __typename?: 'LinkedinMessage';
  /** Reads a single `CampaignMember` that is related to this `LinkedinMessage`. */
  campaignMemberByCampaignMemberId?: Maybe<CampaignMember>;
  campaignMemberId: Scalars['UUID']['output'];
  content?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['Datetime']['output'];
  date: Scalars['String']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  isLawyer: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

export type LinkedinMessageAggregates = {
  __typename?: 'LinkedinMessageAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LinkedinMessageDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `LinkedinMessage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LinkedinMessageCondition = {
  /** Checks for equality with the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `date` field. */
  date?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `isLawyer` field. */
  isLawyer?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `label` field. */
  label?: InputMaybe<Scalars['String']['input']>;
};

export type LinkedinMessageDistinctCountAggregates = {
  __typename?: 'LinkedinMessageDistinctCountAggregates';
  /** Distinct count of campaignMemberId across the matching connection */
  campaignMemberId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of content across the matching connection */
  content?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of date across the matching connection */
  date?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isLawyer across the matching connection */
  isLawyer?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of label across the matching connection */
  label?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `LinkedinMessage` object types. All fields are combined with a logical ‘and.’ */
export type LinkedinMessageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LinkedinMessageFilter>>;
  /** Filter by the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `date` field. */
  date?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isLawyer` field. */
  isLawyer?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `label` field. */
  label?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LinkedinMessageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LinkedinMessageFilter>>;
};

/** Grouping methods for `LinkedinMessage` for usage during aggregation. */
export enum LinkedinMessageGroupBy {
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  Content = 'CONTENT',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  Date = 'DATE',
  IsLawyer = 'IS_LAWYER',
  Label = 'LABEL'
}

export type LinkedinMessageHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinMessageHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LinkedinMessage` aggregates. */
export type LinkedinMessageHavingInput = {
  AND?: InputMaybe<Array<LinkedinMessageHavingInput>>;
  OR?: InputMaybe<Array<LinkedinMessageHavingInput>>;
  average?: InputMaybe<LinkedinMessageHavingAverageInput>;
  distinctCount?: InputMaybe<LinkedinMessageHavingDistinctCountInput>;
  max?: InputMaybe<LinkedinMessageHavingMaxInput>;
  min?: InputMaybe<LinkedinMessageHavingMinInput>;
  stddevPopulation?: InputMaybe<LinkedinMessageHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LinkedinMessageHavingStddevSampleInput>;
  sum?: InputMaybe<LinkedinMessageHavingSumInput>;
  variancePopulation?: InputMaybe<LinkedinMessageHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LinkedinMessageHavingVarianceSampleInput>;
};

export type LinkedinMessageHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinMessageHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinMessageHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinMessageHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinMessageHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinMessageHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinMessageHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type LinkedinMessageSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: LinkedinMessageSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type LinkedinMessageSortBy = {
  field?: InputMaybe<LinkedinMessageSortableField>;
};

/** Sortable concrete fields for the `LinkedinMessage` type. */
export enum LinkedinMessageSortableField {
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  Content = 'CONTENT',
  CreatedTime = 'CREATED_TIME',
  Date = 'DATE',
  Id = 'ID',
  IsLawyer = 'IS_LAWYER',
  Label = 'LABEL'
}

/** A connection to a list of `LinkedinMessage` values. */
export type LinkedinMessagesConnection = {
  __typename?: 'LinkedinMessagesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LinkedinMessageAggregates>;
  /** A list of edges which contains the `LinkedinMessage` and cursor to aid in pagination. */
  edges: Array<LinkedinMessagesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LinkedinMessageAggregates>>;
  /** A list of `LinkedinMessage` objects. */
  nodes: Array<Maybe<LinkedinMessage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LinkedinMessage` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `LinkedinMessage` values. */
export type LinkedinMessagesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LinkedinMessageGroupBy>;
  having?: InputMaybe<LinkedinMessageHavingInput>;
};

/** A `LinkedinMessage` edge in the connection. */
export type LinkedinMessagesEdge = {
  __typename?: 'LinkedinMessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `LinkedinMessage` at the end of the edge. */
  node?: Maybe<LinkedinMessage>;
};

/** Methods to use when ordering `LinkedinMessage`. */
export enum LinkedinMessagesOrderBy {
  CampaignMemberIdAsc = 'CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberIdDesc = 'CAMPAIGN_MEMBER_ID_DESC',
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  DateAsc = 'DATE_ASC',
  DateDesc = 'DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsLawyerAsc = 'IS_LAWYER_ASC',
  IsLawyerDesc = 'IS_LAWYER_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type LinkedinScheduledMessage = Node & {
  __typename?: 'LinkedinScheduledMessage';
  /** Reads a single `Campaign` that is related to this `LinkedinScheduledMessage`. */
  campaignByCampaignId?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `CampaignMember` that is related to this `LinkedinScheduledMessage`. */
  campaignMemberByCampaignMemberId?: Maybe<CampaignMember>;
  campaignMemberId: Scalars['UUID']['output'];
  /** Reads a single `Company` that is related to this `LinkedinScheduledMessage`. */
  companyByCompanyId?: Maybe<Company>;
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `Contact` that is related to this `LinkedinScheduledMessage`. */
  contactByContactId?: Maybe<Contact>;
  contactId?: Maybe<Scalars['UUID']['output']>;
  content: Scalars['String']['output'];
  createdTime: Scalars['Datetime']['output'];
  forwardedToLawyerTime?: Maybe<Scalars['Datetime']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  intent?: Maybe<Scalars['String']['output']>;
  isFromCampaign?: Maybe<Scalars['Boolean']['output']>;
  isLawyer: Scalars['Boolean']['output'];
  isReviewed: Scalars['Boolean']['output'];
  /** Reads a single `Lawyer` that is related to this `LinkedinScheduledMessage`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId: Scalars['UUID']['output'];
  lawyerViewedTime?: Maybe<Scalars['Datetime']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  repliedTime?: Maybe<Scalars['Datetime']['output']>;
  replyStatus: ReplyStatus;
  reviewedTime?: Maybe<Scalars['Datetime']['output']>;
  sendAt: Scalars['Datetime']['output'];
  status: Scalars['String']['output'];
  updatedTime: Scalars['Datetime']['output'];
};

export type LinkedinScheduledMessageAggregates = {
  __typename?: 'LinkedinScheduledMessageAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LinkedinScheduledMessageDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `LinkedinScheduledMessage` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LinkedinScheduledMessageCondition = {
  /** Checks for equality with the object’s `campaignId` field. */
  campaignId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `contactId` field. */
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `forwardedToLawyerTime` field. */
  forwardedToLawyerTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `intent` field. */
  intent?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isFromCampaign` field. */
  isFromCampaign?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isLawyer` field. */
  isLawyer?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isReviewed` field. */
  isReviewed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerViewedTime` field. */
  lawyerViewedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `repliedTime` field. */
  repliedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `replyStatus` field. */
  replyStatus?: InputMaybe<ReplyStatus>;
  /** Checks for equality with the object’s `reviewedTime` field. */
  reviewedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `sendAt` field. */
  sendAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type LinkedinScheduledMessageDistinctCountAggregates = {
  __typename?: 'LinkedinScheduledMessageDistinctCountAggregates';
  /** Distinct count of campaignId across the matching connection */
  campaignId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of campaignMemberId across the matching connection */
  campaignMemberId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactId across the matching connection */
  contactId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of content across the matching connection */
  content?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of forwardedToLawyerTime across the matching connection */
  forwardedToLawyerTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of intent across the matching connection */
  intent?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isFromCampaign across the matching connection */
  isFromCampaign?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isLawyer across the matching connection */
  isLawyer?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isReviewed across the matching connection */
  isReviewed?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerViewedTime across the matching connection */
  lawyerViewedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of repliedTime across the matching connection */
  repliedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of replyStatus across the matching connection */
  replyStatus?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of reviewedTime across the matching connection */
  reviewedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of sendAt across the matching connection */
  sendAt?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `LinkedinScheduledMessage` object types. All fields are combined with a logical ‘and.’ */
export type LinkedinScheduledMessageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LinkedinScheduledMessageFilter>>;
  /** Filter by the object’s `campaignId` field. */
  campaignId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `contactId` field. */
  contactId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `forwardedToLawyerTime` field. */
  forwardedToLawyerTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `intent` field. */
  intent?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isFromCampaign` field. */
  isFromCampaign?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isLawyer` field. */
  isLawyer?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isReviewed` field. */
  isReviewed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerViewedTime` field. */
  lawyerViewedTime?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LinkedinScheduledMessageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LinkedinScheduledMessageFilter>>;
  /** Filter by the object’s `repliedTime` field. */
  repliedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `replyStatus` field. */
  replyStatus?: InputMaybe<ReplyStatusFilter>;
  /** Filter by the object’s `reviewedTime` field. */
  reviewedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `sendAt` field. */
  sendAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `LinkedinScheduledMessage` for usage during aggregation. */
export enum LinkedinScheduledMessageGroupBy {
  CampaignId = 'CAMPAIGN_ID',
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CompanyId = 'COMPANY_ID',
  ContactId = 'CONTACT_ID',
  Content = 'CONTENT',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  ForwardedToLawyerTime = 'FORWARDED_TO_LAWYER_TIME',
  ForwardedToLawyerTimeTruncatedToDay = 'FORWARDED_TO_LAWYER_TIME_TRUNCATED_TO_DAY',
  ForwardedToLawyerTimeTruncatedToHour = 'FORWARDED_TO_LAWYER_TIME_TRUNCATED_TO_HOUR',
  Intent = 'INTENT',
  IsFromCampaign = 'IS_FROM_CAMPAIGN',
  IsLawyer = 'IS_LAWYER',
  IsReviewed = 'IS_REVIEWED',
  LawyerId = 'LAWYER_ID',
  LawyerViewedTime = 'LAWYER_VIEWED_TIME',
  LawyerViewedTimeTruncatedToDay = 'LAWYER_VIEWED_TIME_TRUNCATED_TO_DAY',
  LawyerViewedTimeTruncatedToHour = 'LAWYER_VIEWED_TIME_TRUNCATED_TO_HOUR',
  RepliedTime = 'REPLIED_TIME',
  RepliedTimeTruncatedToDay = 'REPLIED_TIME_TRUNCATED_TO_DAY',
  RepliedTimeTruncatedToHour = 'REPLIED_TIME_TRUNCATED_TO_HOUR',
  ReplyStatus = 'REPLY_STATUS',
  ReviewedTime = 'REVIEWED_TIME',
  ReviewedTimeTruncatedToDay = 'REVIEWED_TIME_TRUNCATED_TO_DAY',
  ReviewedTimeTruncatedToHour = 'REVIEWED_TIME_TRUNCATED_TO_HOUR',
  SendAt = 'SEND_AT',
  SendAtTruncatedToDay = 'SEND_AT_TRUNCATED_TO_DAY',
  SendAtTruncatedToHour = 'SEND_AT_TRUNCATED_TO_HOUR',
  Status = 'STATUS',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type LinkedinScheduledMessageHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinScheduledMessageHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LinkedinScheduledMessage` aggregates. */
export type LinkedinScheduledMessageHavingInput = {
  AND?: InputMaybe<Array<LinkedinScheduledMessageHavingInput>>;
  OR?: InputMaybe<Array<LinkedinScheduledMessageHavingInput>>;
  average?: InputMaybe<LinkedinScheduledMessageHavingAverageInput>;
  distinctCount?: InputMaybe<LinkedinScheduledMessageHavingDistinctCountInput>;
  max?: InputMaybe<LinkedinScheduledMessageHavingMaxInput>;
  min?: InputMaybe<LinkedinScheduledMessageHavingMinInput>;
  stddevPopulation?: InputMaybe<LinkedinScheduledMessageHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LinkedinScheduledMessageHavingStddevSampleInput>;
  sum?: InputMaybe<LinkedinScheduledMessageHavingSumInput>;
  variancePopulation?: InputMaybe<LinkedinScheduledMessageHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LinkedinScheduledMessageHavingVarianceSampleInput>;
};

export type LinkedinScheduledMessageHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinScheduledMessageHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinScheduledMessageHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinScheduledMessageHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinScheduledMessageHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinScheduledMessageHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinScheduledMessageHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  forwardedToLawyerTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerViewedTime?: InputMaybe<HavingDatetimeFilter>;
  repliedTime?: InputMaybe<HavingDatetimeFilter>;
  reviewedTime?: InputMaybe<HavingDatetimeFilter>;
  sendAt?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type LinkedinScheduledMessageSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: LinkedinScheduledMessageSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type LinkedinScheduledMessageSortBy = {
  field?: InputMaybe<LinkedinScheduledMessageSortableField>;
};

/** Sortable concrete fields for the `LinkedinScheduledMessage` type. */
export enum LinkedinScheduledMessageSortableField {
  CampaignId = 'CAMPAIGN_ID',
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CompanyId = 'COMPANY_ID',
  ContactId = 'CONTACT_ID',
  Content = 'CONTENT',
  CreatedTime = 'CREATED_TIME',
  ForwardedToLawyerTime = 'FORWARDED_TO_LAWYER_TIME',
  Id = 'ID',
  Intent = 'INTENT',
  IsFromCampaign = 'IS_FROM_CAMPAIGN',
  IsLawyer = 'IS_LAWYER',
  IsReviewed = 'IS_REVIEWED',
  LawyerId = 'LAWYER_ID',
  LawyerViewedTime = 'LAWYER_VIEWED_TIME',
  RepliedTime = 'REPLIED_TIME',
  ReplyStatus = 'REPLY_STATUS',
  ReviewedTime = 'REVIEWED_TIME',
  SendAt = 'SEND_AT',
  Status = 'STATUS',
  UpdatedTime = 'UPDATED_TIME'
}

/** A connection to a list of `LinkedinScheduledMessage` values. */
export type LinkedinScheduledMessagesConnection = {
  __typename?: 'LinkedinScheduledMessagesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LinkedinScheduledMessageAggregates>;
  /** A list of edges which contains the `LinkedinScheduledMessage` and cursor to aid in pagination. */
  edges: Array<LinkedinScheduledMessagesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LinkedinScheduledMessageAggregates>>;
  /** A list of `LinkedinScheduledMessage` objects. */
  nodes: Array<Maybe<LinkedinScheduledMessage>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LinkedinScheduledMessage` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `LinkedinScheduledMessage` values. */
export type LinkedinScheduledMessagesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LinkedinScheduledMessageGroupBy>;
  having?: InputMaybe<LinkedinScheduledMessageHavingInput>;
};

/** A `LinkedinScheduledMessage` edge in the connection. */
export type LinkedinScheduledMessagesEdge = {
  __typename?: 'LinkedinScheduledMessagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `LinkedinScheduledMessage` at the end of the edge. */
  node?: Maybe<LinkedinScheduledMessage>;
};

/** Methods to use when ordering `LinkedinScheduledMessage`. */
export enum LinkedinScheduledMessagesOrderBy {
  CampaignIdAsc = 'CAMPAIGN_ID_ASC',
  CampaignIdDesc = 'CAMPAIGN_ID_DESC',
  CampaignMemberIdAsc = 'CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberIdDesc = 'CAMPAIGN_MEMBER_ID_DESC',
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  ForwardedToLawyerTimeAsc = 'FORWARDED_TO_LAWYER_TIME_ASC',
  ForwardedToLawyerTimeDesc = 'FORWARDED_TO_LAWYER_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntentAsc = 'INTENT_ASC',
  IntentDesc = 'INTENT_DESC',
  IsFromCampaignAsc = 'IS_FROM_CAMPAIGN_ASC',
  IsFromCampaignDesc = 'IS_FROM_CAMPAIGN_DESC',
  IsLawyerAsc = 'IS_LAWYER_ASC',
  IsLawyerDesc = 'IS_LAWYER_DESC',
  IsReviewedAsc = 'IS_REVIEWED_ASC',
  IsReviewedDesc = 'IS_REVIEWED_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  LawyerViewedTimeAsc = 'LAWYER_VIEWED_TIME_ASC',
  LawyerViewedTimeDesc = 'LAWYER_VIEWED_TIME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RepliedTimeAsc = 'REPLIED_TIME_ASC',
  RepliedTimeDesc = 'REPLIED_TIME_DESC',
  ReplyStatusAsc = 'REPLY_STATUS_ASC',
  ReplyStatusDesc = 'REPLY_STATUS_DESC',
  ReviewedTimeAsc = 'REVIEWED_TIME_ASC',
  ReviewedTimeDesc = 'REVIEWED_TIME_DESC',
  SendAtAsc = 'SEND_AT_ASC',
  SendAtDesc = 'SEND_AT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

export type LinkedinTemplate = Node & {
  __typename?: 'LinkedinTemplate';
  /** Reads a single `Campaign` that is related to this `LinkedinTemplate`. */
  campaignByCampaignId?: Maybe<Campaign>;
  campaignId: Scalars['UUID']['output'];
  content: Scalars['String']['output'];
  conversionScore?: Maybe<Scalars['Float']['output']>;
  createdTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  language?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `LinkedinTemplateRevision`. */
  linkedinTemplateRevisionsByTemplateId: LinkedinTemplateRevisionsConnection;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  orderIndex: Scalars['Int']['output'];
  revisedContent?: Maybe<Scalars['String']['output']>;
  status: OutreachTemplateStatus;
  updatedTime: Scalars['Datetime']['output'];
};


export type LinkedinTemplateLinkedinTemplateRevisionsByTemplateIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinTemplateRevisionCondition>;
  filter?: InputMaybe<LinkedinTemplateRevisionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinTemplateRevisionsOrderBy>>;
  sort?: InputMaybe<Array<LinkedinTemplateRevisionSort>>;
};

export type LinkedinTemplateAggregates = {
  __typename?: 'LinkedinTemplateAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LinkedinTemplateAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LinkedinTemplateDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LinkedinTemplateMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LinkedinTemplateMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LinkedinTemplateStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LinkedinTemplateStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LinkedinTemplateSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LinkedinTemplateVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LinkedinTemplateVarianceSampleAggregates>;
};

export type LinkedinTemplateAverageAggregates = {
  __typename?: 'LinkedinTemplateAverageAggregates';
  /** Mean average of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Mean average of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `LinkedinTemplate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LinkedinTemplateCondition = {
  /** Checks for equality with the object’s `campaignId` field. */
  campaignId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `conversionScore` field. */
  conversionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `language` field. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `orderIndex` field. */
  orderIndex?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `revisedContent` field. */
  revisedContent?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<OutreachTemplateStatus>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type LinkedinTemplateDistinctCountAggregates = {
  __typename?: 'LinkedinTemplateDistinctCountAggregates';
  /** Distinct count of campaignId across the matching connection */
  campaignId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of content across the matching connection */
  content?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of language across the matching connection */
  language?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of revisedContent across the matching connection */
  revisedContent?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `LinkedinTemplate` object types. All fields are combined with a logical ‘and.’ */
export type LinkedinTemplateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LinkedinTemplateFilter>>;
  /** Filter by the object’s `campaignId` field. */
  campaignId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<StringFilter>;
  /** Filter by the object’s `conversionScore` field. */
  conversionScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `language` field. */
  language?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LinkedinTemplateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LinkedinTemplateFilter>>;
  /** Filter by the object’s `orderIndex` field. */
  orderIndex?: InputMaybe<IntFilter>;
  /** Filter by the object’s `revisedContent` field. */
  revisedContent?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<OutreachTemplateStatusFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `LinkedinTemplate` for usage during aggregation. */
export enum LinkedinTemplateGroupBy {
  CampaignId = 'CAMPAIGN_ID',
  Content = 'CONTENT',
  ConversionScore = 'CONVERSION_SCORE',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  Language = 'LANGUAGE',
  Name = 'NAME',
  OrderIndex = 'ORDER_INDEX',
  RevisedContent = 'REVISED_CONTENT',
  Status = 'STATUS',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type LinkedinTemplateHavingAverageInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateHavingDistinctCountInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LinkedinTemplate` aggregates. */
export type LinkedinTemplateHavingInput = {
  AND?: InputMaybe<Array<LinkedinTemplateHavingInput>>;
  OR?: InputMaybe<Array<LinkedinTemplateHavingInput>>;
  average?: InputMaybe<LinkedinTemplateHavingAverageInput>;
  distinctCount?: InputMaybe<LinkedinTemplateHavingDistinctCountInput>;
  max?: InputMaybe<LinkedinTemplateHavingMaxInput>;
  min?: InputMaybe<LinkedinTemplateHavingMinInput>;
  stddevPopulation?: InputMaybe<LinkedinTemplateHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LinkedinTemplateHavingStddevSampleInput>;
  sum?: InputMaybe<LinkedinTemplateHavingSumInput>;
  variancePopulation?: InputMaybe<LinkedinTemplateHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LinkedinTemplateHavingVarianceSampleInput>;
};

export type LinkedinTemplateHavingMaxInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateHavingMinInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateHavingStddevPopulationInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateHavingStddevSampleInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateHavingSumInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateHavingVariancePopulationInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateHavingVarianceSampleInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateMaxAggregates = {
  __typename?: 'LinkedinTemplateMaxAggregates';
  /** Maximum of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Maximum of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['Int']['output']>;
};

export type LinkedinTemplateMinAggregates = {
  __typename?: 'LinkedinTemplateMinAggregates';
  /** Minimum of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Minimum of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['Int']['output']>;
};

export type LinkedinTemplateRevision = Node & {
  __typename?: 'LinkedinTemplateRevision';
  content?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `LinkedinTemplate` that is related to this `LinkedinTemplateRevision`. */
  linkedinTemplateByTemplateId?: Maybe<LinkedinTemplate>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  templateId: Scalars['UUID']['output'];
};

export type LinkedinTemplateRevisionAggregates = {
  __typename?: 'LinkedinTemplateRevisionAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LinkedinTemplateRevisionDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `LinkedinTemplateRevision` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type LinkedinTemplateRevisionCondition = {
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `templateId` field. */
  templateId?: InputMaybe<Scalars['UUID']['input']>;
};

export type LinkedinTemplateRevisionDistinctCountAggregates = {
  __typename?: 'LinkedinTemplateRevisionDistinctCountAggregates';
  /** Distinct count of content across the matching connection */
  content?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templateId across the matching connection */
  templateId?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `LinkedinTemplateRevision` object types. All fields are combined with a logical ‘and.’ */
export type LinkedinTemplateRevisionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LinkedinTemplateRevisionFilter>>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LinkedinTemplateRevisionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LinkedinTemplateRevisionFilter>>;
  /** Filter by the object’s `templateId` field. */
  templateId?: InputMaybe<UuidFilter>;
};

/** Grouping methods for `LinkedinTemplateRevision` for usage during aggregation. */
export enum LinkedinTemplateRevisionGroupBy {
  Content = 'CONTENT',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  TemplateId = 'TEMPLATE_ID'
}

export type LinkedinTemplateRevisionHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateRevisionHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `LinkedinTemplateRevision` aggregates. */
export type LinkedinTemplateRevisionHavingInput = {
  AND?: InputMaybe<Array<LinkedinTemplateRevisionHavingInput>>;
  OR?: InputMaybe<Array<LinkedinTemplateRevisionHavingInput>>;
  average?: InputMaybe<LinkedinTemplateRevisionHavingAverageInput>;
  distinctCount?: InputMaybe<LinkedinTemplateRevisionHavingDistinctCountInput>;
  max?: InputMaybe<LinkedinTemplateRevisionHavingMaxInput>;
  min?: InputMaybe<LinkedinTemplateRevisionHavingMinInput>;
  stddevPopulation?: InputMaybe<LinkedinTemplateRevisionHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LinkedinTemplateRevisionHavingStddevSampleInput>;
  sum?: InputMaybe<LinkedinTemplateRevisionHavingSumInput>;
  variancePopulation?: InputMaybe<LinkedinTemplateRevisionHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LinkedinTemplateRevisionHavingVarianceSampleInput>;
};

export type LinkedinTemplateRevisionHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateRevisionHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateRevisionHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateRevisionHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateRevisionHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateRevisionHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type LinkedinTemplateRevisionHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type LinkedinTemplateRevisionSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: LinkedinTemplateRevisionSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type LinkedinTemplateRevisionSortBy = {
  field?: InputMaybe<LinkedinTemplateRevisionSortableField>;
};

/** Sortable concrete fields for the `LinkedinTemplateRevision` type. */
export enum LinkedinTemplateRevisionSortableField {
  Content = 'CONTENT',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  TemplateId = 'TEMPLATE_ID'
}

/** A connection to a list of `LinkedinTemplateRevision` values. */
export type LinkedinTemplateRevisionsConnection = {
  __typename?: 'LinkedinTemplateRevisionsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LinkedinTemplateRevisionAggregates>;
  /** A list of edges which contains the `LinkedinTemplateRevision` and cursor to aid in pagination. */
  edges: Array<LinkedinTemplateRevisionsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LinkedinTemplateRevisionAggregates>>;
  /** A list of `LinkedinTemplateRevision` objects. */
  nodes: Array<Maybe<LinkedinTemplateRevision>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LinkedinTemplateRevision` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `LinkedinTemplateRevision` values. */
export type LinkedinTemplateRevisionsConnectionGroupedAggregatesArgs = {
  groupBy: Array<LinkedinTemplateRevisionGroupBy>;
  having?: InputMaybe<LinkedinTemplateRevisionHavingInput>;
};

/** A `LinkedinTemplateRevision` edge in the connection. */
export type LinkedinTemplateRevisionsEdge = {
  __typename?: 'LinkedinTemplateRevisionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `LinkedinTemplateRevision` at the end of the edge. */
  node?: Maybe<LinkedinTemplateRevision>;
};

/** Methods to use when ordering `LinkedinTemplateRevision`. */
export enum LinkedinTemplateRevisionsOrderBy {
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TemplateIdAsc = 'TEMPLATE_ID_ASC',
  TemplateIdDesc = 'TEMPLATE_ID_DESC'
}

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type LinkedinTemplateSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: LinkedinTemplateSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type LinkedinTemplateSortBy = {
  field?: InputMaybe<LinkedinTemplateSortableField>;
};

/** Sortable concrete fields for the `LinkedinTemplate` type. */
export enum LinkedinTemplateSortableField {
  CampaignId = 'CAMPAIGN_ID',
  Content = 'CONTENT',
  ConversionScore = 'CONVERSION_SCORE',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  Language = 'LANGUAGE',
  Name = 'NAME',
  OrderIndex = 'ORDER_INDEX',
  RevisedContent = 'REVISED_CONTENT',
  Status = 'STATUS',
  UpdatedTime = 'UPDATED_TIME'
}

export type LinkedinTemplateStddevPopulationAggregates = {
  __typename?: 'LinkedinTemplateStddevPopulationAggregates';
  /** Population standard deviation of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

export type LinkedinTemplateStddevSampleAggregates = {
  __typename?: 'LinkedinTemplateStddevSampleAggregates';
  /** Sample standard deviation of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

export type LinkedinTemplateSumAggregates = {
  __typename?: 'LinkedinTemplateSumAggregates';
  /** Sum of conversionScore across the matching connection */
  conversionScore: Scalars['Float']['output'];
  /** Sum of orderIndex across the matching connection */
  orderIndex: Scalars['BigInt']['output'];
};

export type LinkedinTemplateVariancePopulationAggregates = {
  __typename?: 'LinkedinTemplateVariancePopulationAggregates';
  /** Population variance of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Population variance of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

export type LinkedinTemplateVarianceSampleAggregates = {
  __typename?: 'LinkedinTemplateVarianceSampleAggregates';
  /** Sample variance of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Sample variance of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `LinkedinTemplate` values. */
export type LinkedinTemplatesConnection = {
  __typename?: 'LinkedinTemplatesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LinkedinTemplateAggregates>;
  /** A list of edges which contains the `LinkedinTemplate` and cursor to aid in pagination. */
  edges: Array<LinkedinTemplatesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LinkedinTemplateAggregates>>;
  /** A list of `LinkedinTemplate` objects. */
  nodes: Array<Maybe<LinkedinTemplate>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LinkedinTemplate` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `LinkedinTemplate` values. */
export type LinkedinTemplatesConnectionGroupedAggregatesArgs = {
  groupBy: Array<LinkedinTemplateGroupBy>;
  having?: InputMaybe<LinkedinTemplateHavingInput>;
};

/** A `LinkedinTemplate` edge in the connection. */
export type LinkedinTemplatesEdge = {
  __typename?: 'LinkedinTemplatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `LinkedinTemplate` at the end of the edge. */
  node?: Maybe<LinkedinTemplate>;
};

/** Methods to use when ordering `LinkedinTemplate`. */
export enum LinkedinTemplatesOrderBy {
  CampaignIdAsc = 'CAMPAIGN_ID_ASC',
  CampaignIdDesc = 'CAMPAIGN_ID_DESC',
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  ConversionScoreAsc = 'CONVERSION_SCORE_ASC',
  ConversionScoreDesc = 'CONVERSION_SCORE_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LanguageAsc = 'LANGUAGE_ASC',
  LanguageDesc = 'LANGUAGE_DESC',
  LinkedinTemplateRevisionsByTemplateIdAverageContentAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_CONTENT_ASC',
  LinkedinTemplateRevisionsByTemplateIdAverageContentDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_CONTENT_DESC',
  LinkedinTemplateRevisionsByTemplateIdAverageCreatedTimeAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_CREATED_TIME_ASC',
  LinkedinTemplateRevisionsByTemplateIdAverageCreatedTimeDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_CREATED_TIME_DESC',
  LinkedinTemplateRevisionsByTemplateIdAverageIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdAverageIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdAverageTemplateIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_TEMPLATE_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdAverageTemplateIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_TEMPLATE_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdCountAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_COUNT_ASC',
  LinkedinTemplateRevisionsByTemplateIdCountDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_COUNT_DESC',
  LinkedinTemplateRevisionsByTemplateIdDistinctCountContentAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_CONTENT_ASC',
  LinkedinTemplateRevisionsByTemplateIdDistinctCountContentDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_CONTENT_DESC',
  LinkedinTemplateRevisionsByTemplateIdDistinctCountCreatedTimeAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LinkedinTemplateRevisionsByTemplateIdDistinctCountCreatedTimeDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LinkedinTemplateRevisionsByTemplateIdDistinctCountIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdDistinctCountIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdDistinctCountTemplateIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdDistinctCountTemplateIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdMaxContentAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_CONTENT_ASC',
  LinkedinTemplateRevisionsByTemplateIdMaxContentDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_CONTENT_DESC',
  LinkedinTemplateRevisionsByTemplateIdMaxCreatedTimeAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_CREATED_TIME_ASC',
  LinkedinTemplateRevisionsByTemplateIdMaxCreatedTimeDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_CREATED_TIME_DESC',
  LinkedinTemplateRevisionsByTemplateIdMaxIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdMaxIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdMaxTemplateIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_TEMPLATE_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdMaxTemplateIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_TEMPLATE_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdMinContentAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_CONTENT_ASC',
  LinkedinTemplateRevisionsByTemplateIdMinContentDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_CONTENT_DESC',
  LinkedinTemplateRevisionsByTemplateIdMinCreatedTimeAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_CREATED_TIME_ASC',
  LinkedinTemplateRevisionsByTemplateIdMinCreatedTimeDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_CREATED_TIME_DESC',
  LinkedinTemplateRevisionsByTemplateIdMinIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdMinIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdMinTemplateIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_TEMPLATE_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdMinTemplateIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_TEMPLATE_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdStddevPopulationContentAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_CONTENT_ASC',
  LinkedinTemplateRevisionsByTemplateIdStddevPopulationContentDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_CONTENT_DESC',
  LinkedinTemplateRevisionsByTemplateIdStddevPopulationCreatedTimeAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LinkedinTemplateRevisionsByTemplateIdStddevPopulationCreatedTimeDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LinkedinTemplateRevisionsByTemplateIdStddevPopulationIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdStddevPopulationIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdStddevPopulationTemplateIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdStddevPopulationTemplateIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdStddevSampleContentAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CONTENT_ASC',
  LinkedinTemplateRevisionsByTemplateIdStddevSampleContentDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CONTENT_DESC',
  LinkedinTemplateRevisionsByTemplateIdStddevSampleCreatedTimeAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LinkedinTemplateRevisionsByTemplateIdStddevSampleCreatedTimeDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LinkedinTemplateRevisionsByTemplateIdStddevSampleIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdStddevSampleIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdStddevSampleTemplateIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdStddevSampleTemplateIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdSumContentAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_CONTENT_ASC',
  LinkedinTemplateRevisionsByTemplateIdSumContentDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_CONTENT_DESC',
  LinkedinTemplateRevisionsByTemplateIdSumCreatedTimeAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_CREATED_TIME_ASC',
  LinkedinTemplateRevisionsByTemplateIdSumCreatedTimeDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_CREATED_TIME_DESC',
  LinkedinTemplateRevisionsByTemplateIdSumIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdSumIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdSumTemplateIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_TEMPLATE_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdSumTemplateIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_TEMPLATE_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdVariancePopulationContentAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CONTENT_ASC',
  LinkedinTemplateRevisionsByTemplateIdVariancePopulationContentDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CONTENT_DESC',
  LinkedinTemplateRevisionsByTemplateIdVariancePopulationCreatedTimeAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LinkedinTemplateRevisionsByTemplateIdVariancePopulationCreatedTimeDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LinkedinTemplateRevisionsByTemplateIdVariancePopulationIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdVariancePopulationIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdVariancePopulationTemplateIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdVariancePopulationTemplateIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdVarianceSampleContentAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  LinkedinTemplateRevisionsByTemplateIdVarianceSampleContentDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  LinkedinTemplateRevisionsByTemplateIdVarianceSampleCreatedTimeAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LinkedinTemplateRevisionsByTemplateIdVarianceSampleCreatedTimeDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LinkedinTemplateRevisionsByTemplateIdVarianceSampleIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdVarianceSampleIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_ID_DESC',
  LinkedinTemplateRevisionsByTemplateIdVarianceSampleTemplateIdAsc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  LinkedinTemplateRevisionsByTemplateIdVarianceSampleTemplateIdDesc = 'LINKEDIN_TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OrderIndexAsc = 'ORDER_INDEX_ASC',
  OrderIndexDesc = 'ORDER_INDEX_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RevisedContentAsc = 'REVISED_CONTENT_ASC',
  RevisedContentDesc = 'REVISED_CONTENT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

export type Meeting = Node & {
  __typename?: 'Meeting';
  calendlyUri: Scalars['String']['output'];
  canceledBy?: Maybe<Scalars['String']['output']>;
  cancelerType?: Maybe<Scalars['String']['output']>;
  cancellationReason?: Maybe<Scalars['String']['output']>;
  contactFeedbackRequested?: Maybe<Scalars['Boolean']['output']>;
  createdTime: Scalars['Datetime']['output'];
  endTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Lawyer` that is related to this `Meeting`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerFeedbackRequested?: Maybe<Scalars['Boolean']['output']>;
  lawyerId: Scalars['UUID']['output'];
  locationAddress?: Maybe<Scalars['String']['output']>;
  locationProviderId?: Maybe<Scalars['String']['output']>;
  locationStatus?: Maybe<Scalars['String']['output']>;
  locationType?: Maybe<Scalars['String']['output']>;
  /** Reads a single `MeetingFeedback` that is related to this `Meeting`. */
  meetingFeedbackByMeetingId?: Maybe<MeetingFeedback>;
  /**
   * Reads and enables pagination through a set of `MeetingFeedback`.
   * @deprecated Please use meetingFeedbackByMeetingId instead
   */
  meetingFeedbacksByMeetingId: MeetingFeedbacksConnection;
  /** Reads and enables pagination through a set of `MeetingInvitee`. */
  meetingInviteesByMeetingId: MeetingInviteesConnection;
  /** Reads a single `MeetingType` that is related to this `Meeting`. */
  meetingTypeByMeetingTypeId?: Maybe<MeetingType>;
  meetingTypeId: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  notifiedBeforeMeeting: Scalars['Boolean']['output'];
  notifiedInAdvance: Scalars['Boolean']['output'];
  startTime: Scalars['Datetime']['output'];
  status: MeetingStatus;
  updatedTime?: Maybe<Scalars['Datetime']['output']>;
};


export type MeetingMeetingFeedbacksByMeetingIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MeetingFeedbackCondition>;
  filter?: InputMaybe<MeetingFeedbackFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MeetingFeedbacksOrderBy>>;
  sort?: InputMaybe<Array<MeetingFeedbackSort>>;
};


export type MeetingMeetingInviteesByMeetingIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MeetingInviteeCondition>;
  filter?: InputMaybe<MeetingInviteeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MeetingInviteesOrderBy>>;
  sort?: InputMaybe<Array<MeetingInviteeSort>>;
};

export type MeetingAggregates = {
  __typename?: 'MeetingAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MeetingDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/** A condition to be used against `Meeting` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type MeetingCondition = {
  /** Checks for equality with the object’s `calendlyUri` field. */
  calendlyUri?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `canceledBy` field. */
  canceledBy?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `cancelerType` field. */
  cancelerType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `cancellationReason` field. */
  cancellationReason?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `contactFeedbackRequested` field. */
  contactFeedbackRequested?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `endTime` field. */
  endTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerFeedbackRequested` field. */
  lawyerFeedbackRequested?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `locationAddress` field. */
  locationAddress?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationProviderId` field. */
  locationProviderId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationStatus` field. */
  locationStatus?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationType` field. */
  locationType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `meetingTypeId` field. */
  meetingTypeId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `notifiedBeforeMeeting` field. */
  notifiedBeforeMeeting?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `notifiedInAdvance` field. */
  notifiedInAdvance?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `startTime` field. */
  startTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<MeetingStatus>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type MeetingDistinctCountAggregates = {
  __typename?: 'MeetingDistinctCountAggregates';
  /** Distinct count of calendlyUri across the matching connection */
  calendlyUri?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of canceledBy across the matching connection */
  canceledBy?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of cancelerType across the matching connection */
  cancelerType?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of cancellationReason across the matching connection */
  cancellationReason?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactFeedbackRequested across the matching connection */
  contactFeedbackRequested?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of endTime across the matching connection */
  endTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerFeedbackRequested across the matching connection */
  lawyerFeedbackRequested?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of locationAddress across the matching connection */
  locationAddress?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of locationProviderId across the matching connection */
  locationProviderId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of locationStatus across the matching connection */
  locationStatus?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of locationType across the matching connection */
  locationType?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of meetingTypeId across the matching connection */
  meetingTypeId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of notifiedBeforeMeeting across the matching connection */
  notifiedBeforeMeeting?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of notifiedInAdvance across the matching connection */
  notifiedInAdvance?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of startTime across the matching connection */
  startTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

export type MeetingFeedback = Node & {
  __typename?: 'MeetingFeedback';
  createdTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Meeting` that is related to this `MeetingFeedback`. */
  meetingByMeetingId?: Maybe<Meeting>;
  meetingId: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  rating: Rating;
};

export type MeetingFeedbackAggregates = {
  __typename?: 'MeetingFeedbackAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MeetingFeedbackDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `MeetingFeedback` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MeetingFeedbackCondition = {
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `meetingId` field. */
  meetingId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `note` field. */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `rating` field. */
  rating?: InputMaybe<Rating>;
};

export type MeetingFeedbackDistinctCountAggregates = {
  __typename?: 'MeetingFeedbackDistinctCountAggregates';
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of meetingId across the matching connection */
  meetingId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of note across the matching connection */
  note?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of rating across the matching connection */
  rating?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `MeetingFeedback` object types. All fields are combined with a logical ‘and.’ */
export type MeetingFeedbackFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MeetingFeedbackFilter>>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `meetingId` field. */
  meetingId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MeetingFeedbackFilter>;
  /** Filter by the object’s `note` field. */
  note?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MeetingFeedbackFilter>>;
  /** Filter by the object’s `rating` field. */
  rating?: InputMaybe<RatingFilter>;
};

/** Grouping methods for `MeetingFeedback` for usage during aggregation. */
export enum MeetingFeedbackGroupBy {
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  Note = 'NOTE',
  Rating = 'RATING'
}

export type MeetingFeedbackHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingFeedbackHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `MeetingFeedback` aggregates. */
export type MeetingFeedbackHavingInput = {
  AND?: InputMaybe<Array<MeetingFeedbackHavingInput>>;
  OR?: InputMaybe<Array<MeetingFeedbackHavingInput>>;
  average?: InputMaybe<MeetingFeedbackHavingAverageInput>;
  distinctCount?: InputMaybe<MeetingFeedbackHavingDistinctCountInput>;
  max?: InputMaybe<MeetingFeedbackHavingMaxInput>;
  min?: InputMaybe<MeetingFeedbackHavingMinInput>;
  stddevPopulation?: InputMaybe<MeetingFeedbackHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MeetingFeedbackHavingStddevSampleInput>;
  sum?: InputMaybe<MeetingFeedbackHavingSumInput>;
  variancePopulation?: InputMaybe<MeetingFeedbackHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MeetingFeedbackHavingVarianceSampleInput>;
};

export type MeetingFeedbackHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingFeedbackHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingFeedbackHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingFeedbackHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingFeedbackHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingFeedbackHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingFeedbackHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type MeetingFeedbackSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: MeetingFeedbackSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type MeetingFeedbackSortBy = {
  field?: InputMaybe<MeetingFeedbackSortableField>;
};

/** Sortable concrete fields for the `MeetingFeedback` type. */
export enum MeetingFeedbackSortableField {
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  MeetingId = 'MEETING_ID',
  Note = 'NOTE',
  Rating = 'RATING'
}

/** A connection to a list of `MeetingFeedback` values. */
export type MeetingFeedbacksConnection = {
  __typename?: 'MeetingFeedbacksConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MeetingFeedbackAggregates>;
  /** A list of edges which contains the `MeetingFeedback` and cursor to aid in pagination. */
  edges: Array<MeetingFeedbacksEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MeetingFeedbackAggregates>>;
  /** A list of `MeetingFeedback` objects. */
  nodes: Array<Maybe<MeetingFeedback>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MeetingFeedback` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `MeetingFeedback` values. */
export type MeetingFeedbacksConnectionGroupedAggregatesArgs = {
  groupBy: Array<MeetingFeedbackGroupBy>;
  having?: InputMaybe<MeetingFeedbackHavingInput>;
};

/** A `MeetingFeedback` edge in the connection. */
export type MeetingFeedbacksEdge = {
  __typename?: 'MeetingFeedbacksEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `MeetingFeedback` at the end of the edge. */
  node?: Maybe<MeetingFeedback>;
};

/** Methods to use when ordering `MeetingFeedback`. */
export enum MeetingFeedbacksOrderBy {
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MeetingIdAsc = 'MEETING_ID_ASC',
  MeetingIdDesc = 'MEETING_ID_DESC',
  Natural = 'NATURAL',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RatingAsc = 'RATING_ASC',
  RatingDesc = 'RATING_DESC'
}

/** A filter to be used against `Meeting` object types. All fields are combined with a logical ‘and.’ */
export type MeetingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MeetingFilter>>;
  /** Filter by the object’s `calendlyUri` field. */
  calendlyUri?: InputMaybe<StringFilter>;
  /** Filter by the object’s `canceledBy` field. */
  canceledBy?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cancelerType` field. */
  cancelerType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cancellationReason` field. */
  cancellationReason?: InputMaybe<StringFilter>;
  /** Filter by the object’s `contactFeedbackRequested` field. */
  contactFeedbackRequested?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `endTime` field. */
  endTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerFeedbackRequested` field. */
  lawyerFeedbackRequested?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `locationAddress` field. */
  locationAddress?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationProviderId` field. */
  locationProviderId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationStatus` field. */
  locationStatus?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationType` field. */
  locationType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `meetingTypeId` field. */
  meetingTypeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MeetingFilter>;
  /** Filter by the object’s `notifiedBeforeMeeting` field. */
  notifiedBeforeMeeting?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `notifiedInAdvance` field. */
  notifiedInAdvance?: InputMaybe<BooleanFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MeetingFilter>>;
  /** Filter by the object’s `startTime` field. */
  startTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<MeetingStatusFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Meeting` for usage during aggregation. */
export enum MeetingGroupBy {
  CalendlyUri = 'CALENDLY_URI',
  CanceledBy = 'CANCELED_BY',
  CancelerType = 'CANCELER_TYPE',
  CancellationReason = 'CANCELLATION_REASON',
  ContactFeedbackRequested = 'CONTACT_FEEDBACK_REQUESTED',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  EndTime = 'END_TIME',
  EndTimeTruncatedToDay = 'END_TIME_TRUNCATED_TO_DAY',
  EndTimeTruncatedToHour = 'END_TIME_TRUNCATED_TO_HOUR',
  LawyerFeedbackRequested = 'LAWYER_FEEDBACK_REQUESTED',
  LawyerId = 'LAWYER_ID',
  LocationAddress = 'LOCATION_ADDRESS',
  LocationProviderId = 'LOCATION_PROVIDER_ID',
  LocationStatus = 'LOCATION_STATUS',
  LocationType = 'LOCATION_TYPE',
  MeetingTypeId = 'MEETING_TYPE_ID',
  Name = 'NAME',
  NotifiedBeforeMeeting = 'NOTIFIED_BEFORE_MEETING',
  NotifiedInAdvance = 'NOTIFIED_IN_ADVANCE',
  StartTime = 'START_TIME',
  StartTimeTruncatedToDay = 'START_TIME_TRUNCATED_TO_DAY',
  StartTimeTruncatedToHour = 'START_TIME_TRUNCATED_TO_HOUR',
  Status = 'STATUS',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type MeetingHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Meeting` aggregates. */
export type MeetingHavingInput = {
  AND?: InputMaybe<Array<MeetingHavingInput>>;
  OR?: InputMaybe<Array<MeetingHavingInput>>;
  average?: InputMaybe<MeetingHavingAverageInput>;
  distinctCount?: InputMaybe<MeetingHavingDistinctCountInput>;
  max?: InputMaybe<MeetingHavingMaxInput>;
  min?: InputMaybe<MeetingHavingMinInput>;
  stddevPopulation?: InputMaybe<MeetingHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MeetingHavingStddevSampleInput>;
  sum?: InputMaybe<MeetingHavingSumInput>;
  variancePopulation?: InputMaybe<MeetingHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MeetingHavingVarianceSampleInput>;
};

export type MeetingHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  endTime?: InputMaybe<HavingDatetimeFilter>;
  startTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type MeetingInvitee = Node & {
  __typename?: 'MeetingInvitee';
  calendlyUri: Scalars['String']['output'];
  cancelUrl: Scalars['String']['output'];
  /** Reads a single `Contact` that is related to this `MeetingInvitee`. */
  contactByContactId?: Maybe<Contact>;
  contactId: Scalars['UUID']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  isNoShow: Scalars['Boolean']['output'];
  /** Reads a single `Meeting` that is related to this `MeetingInvitee`. */
  meetingByMeetingId?: Maybe<Meeting>;
  meetingId: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  rescheduleUrl: Scalars['String']['output'];
  rescheduled: Scalars['Boolean']['output'];
  status: MeetingStatus;
  timezone: Scalars['String']['output'];
};

export type MeetingInviteeAggregates = {
  __typename?: 'MeetingInviteeAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MeetingInviteeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `MeetingInvitee` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MeetingInviteeCondition = {
  /** Checks for equality with the object’s `calendlyUri` field. */
  calendlyUri?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `cancelUrl` field. */
  cancelUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `contactId` field. */
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `isNoShow` field. */
  isNoShow?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `meetingId` field. */
  meetingId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `rescheduleUrl` field. */
  rescheduleUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `rescheduled` field. */
  rescheduled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<MeetingStatus>;
  /** Checks for equality with the object’s `timezone` field. */
  timezone?: InputMaybe<Scalars['String']['input']>;
};

export type MeetingInviteeDistinctCountAggregates = {
  __typename?: 'MeetingInviteeDistinctCountAggregates';
  /** Distinct count of calendlyUri across the matching connection */
  calendlyUri?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of cancelUrl across the matching connection */
  cancelUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactId across the matching connection */
  contactId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isNoShow across the matching connection */
  isNoShow?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of meetingId across the matching connection */
  meetingId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of rescheduleUrl across the matching connection */
  rescheduleUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of rescheduled across the matching connection */
  rescheduled?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timezone across the matching connection */
  timezone?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `MeetingInvitee` object types. All fields are combined with a logical ‘and.’ */
export type MeetingInviteeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MeetingInviteeFilter>>;
  /** Filter by the object’s `calendlyUri` field. */
  calendlyUri?: InputMaybe<StringFilter>;
  /** Filter by the object’s `cancelUrl` field. */
  cancelUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `contactId` field. */
  contactId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isNoShow` field. */
  isNoShow?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meetingId` field. */
  meetingId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MeetingInviteeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MeetingInviteeFilter>>;
  /** Filter by the object’s `rescheduleUrl` field. */
  rescheduleUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `rescheduled` field. */
  rescheduled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<MeetingStatusFilter>;
  /** Filter by the object’s `timezone` field. */
  timezone?: InputMaybe<StringFilter>;
};

/** Grouping methods for `MeetingInvitee` for usage during aggregation. */
export enum MeetingInviteeGroupBy {
  CalendlyUri = 'CALENDLY_URI',
  CancelUrl = 'CANCEL_URL',
  ContactId = 'CONTACT_ID',
  IsNoShow = 'IS_NO_SHOW',
  MeetingId = 'MEETING_ID',
  Rescheduled = 'RESCHEDULED',
  RescheduleUrl = 'RESCHEDULE_URL',
  Status = 'STATUS',
  Timezone = 'TIMEZONE'
}

/** Conditions for `MeetingInvitee` aggregates. */
export type MeetingInviteeHavingInput = {
  AND?: InputMaybe<Array<MeetingInviteeHavingInput>>;
  OR?: InputMaybe<Array<MeetingInviteeHavingInput>>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type MeetingInviteeSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: MeetingInviteeSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type MeetingInviteeSortBy = {
  field?: InputMaybe<MeetingInviteeSortableField>;
};

/** Sortable concrete fields for the `MeetingInvitee` type. */
export enum MeetingInviteeSortableField {
  CalendlyUri = 'CALENDLY_URI',
  CancelUrl = 'CANCEL_URL',
  ContactId = 'CONTACT_ID',
  Id = 'ID',
  IsNoShow = 'IS_NO_SHOW',
  MeetingId = 'MEETING_ID',
  Rescheduled = 'RESCHEDULED',
  RescheduleUrl = 'RESCHEDULE_URL',
  Status = 'STATUS',
  Timezone = 'TIMEZONE'
}

/** A connection to a list of `MeetingInvitee` values. */
export type MeetingInviteesConnection = {
  __typename?: 'MeetingInviteesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MeetingInviteeAggregates>;
  /** A list of edges which contains the `MeetingInvitee` and cursor to aid in pagination. */
  edges: Array<MeetingInviteesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MeetingInviteeAggregates>>;
  /** A list of `MeetingInvitee` objects. */
  nodes: Array<Maybe<MeetingInvitee>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MeetingInvitee` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `MeetingInvitee` values. */
export type MeetingInviteesConnectionGroupedAggregatesArgs = {
  groupBy: Array<MeetingInviteeGroupBy>;
  having?: InputMaybe<MeetingInviteeHavingInput>;
};

/** A `MeetingInvitee` edge in the connection. */
export type MeetingInviteesEdge = {
  __typename?: 'MeetingInviteesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `MeetingInvitee` at the end of the edge. */
  node?: Maybe<MeetingInvitee>;
};

/** Methods to use when ordering `MeetingInvitee`. */
export enum MeetingInviteesOrderBy {
  CalendlyUriAsc = 'CALENDLY_URI_ASC',
  CalendlyUriDesc = 'CALENDLY_URI_DESC',
  CancelUrlAsc = 'CANCEL_URL_ASC',
  CancelUrlDesc = 'CANCEL_URL_DESC',
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsNoShowAsc = 'IS_NO_SHOW_ASC',
  IsNoShowDesc = 'IS_NO_SHOW_DESC',
  MeetingIdAsc = 'MEETING_ID_ASC',
  MeetingIdDesc = 'MEETING_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RescheduledAsc = 'RESCHEDULED_ASC',
  RescheduledDesc = 'RESCHEDULED_DESC',
  RescheduleUrlAsc = 'RESCHEDULE_URL_ASC',
  RescheduleUrlDesc = 'RESCHEDULE_URL_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TimezoneAsc = 'TIMEZONE_ASC',
  TimezoneDesc = 'TIMEZONE_DESC'
}

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type MeetingSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: MeetingSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type MeetingSortBy = {
  field?: InputMaybe<MeetingSortableField>;
};

/** Sortable concrete fields for the `Meeting` type. */
export enum MeetingSortableField {
  CalendlyUri = 'CALENDLY_URI',
  CanceledBy = 'CANCELED_BY',
  CancelerType = 'CANCELER_TYPE',
  CancellationReason = 'CANCELLATION_REASON',
  ContactFeedbackRequested = 'CONTACT_FEEDBACK_REQUESTED',
  CreatedTime = 'CREATED_TIME',
  EndTime = 'END_TIME',
  Id = 'ID',
  LawyerFeedbackRequested = 'LAWYER_FEEDBACK_REQUESTED',
  LawyerId = 'LAWYER_ID',
  LocationAddress = 'LOCATION_ADDRESS',
  LocationProviderId = 'LOCATION_PROVIDER_ID',
  LocationStatus = 'LOCATION_STATUS',
  LocationType = 'LOCATION_TYPE',
  MeetingTypeId = 'MEETING_TYPE_ID',
  Name = 'NAME',
  NotifiedBeforeMeeting = 'NOTIFIED_BEFORE_MEETING',
  NotifiedInAdvance = 'NOTIFIED_IN_ADVANCE',
  StartTime = 'START_TIME',
  Status = 'STATUS',
  UpdatedTime = 'UPDATED_TIME'
}

export enum MeetingStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

/** A filter to be used against MeetingStatus fields. All fields are combined with a logical ‘and.’ */
export type MeetingStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<MeetingStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<MeetingStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<MeetingStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<MeetingStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<MeetingStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<MeetingStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<MeetingStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<MeetingStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<MeetingStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<MeetingStatus>>;
};

export type MeetingType = Node & {
  __typename?: 'MeetingType';
  active: Scalars['Boolean']['output'];
  calendlyUri: Scalars['String']['output'];
  descriptionHtml?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads and enables pagination through a set of `Meeting`. */
  meetingsByMeetingTypeId: MeetingsConnection;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  schedulingUrl: Scalars['String']['output'];
};


export type MeetingTypeMeetingsByMeetingTypeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MeetingCondition>;
  filter?: InputMaybe<MeetingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MeetingsOrderBy>>;
  sort?: InputMaybe<Array<MeetingSort>>;
};

export type MeetingTypeAggregates = {
  __typename?: 'MeetingTypeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<MeetingTypeAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MeetingTypeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<MeetingTypeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<MeetingTypeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<MeetingTypeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<MeetingTypeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<MeetingTypeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<MeetingTypeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<MeetingTypeVarianceSampleAggregates>;
};

export type MeetingTypeAverageAggregates = {
  __typename?: 'MeetingTypeAverageAggregates';
  /** Mean average of duration across the matching connection */
  duration?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `MeetingType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MeetingTypeCondition = {
  /** Checks for equality with the object’s `active` field. */
  active?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `calendlyUri` field. */
  calendlyUri?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `descriptionHtml` field. */
  descriptionHtml?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `schedulingUrl` field. */
  schedulingUrl?: InputMaybe<Scalars['String']['input']>;
};

export type MeetingTypeDistinctCountAggregates = {
  __typename?: 'MeetingTypeDistinctCountAggregates';
  /** Distinct count of active across the matching connection */
  active?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of calendlyUri across the matching connection */
  calendlyUri?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of descriptionHtml across the matching connection */
  descriptionHtml?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of duration across the matching connection */
  duration?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of schedulingUrl across the matching connection */
  schedulingUrl?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `MeetingType` object types. All fields are combined with a logical ‘and.’ */
export type MeetingTypeFilter = {
  /** Filter by the object’s `active` field. */
  active?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MeetingTypeFilter>>;
  /** Filter by the object’s `calendlyUri` field. */
  calendlyUri?: InputMaybe<StringFilter>;
  /** Filter by the object’s `descriptionHtml` field. */
  descriptionHtml?: InputMaybe<StringFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MeetingTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MeetingTypeFilter>>;
  /** Filter by the object’s `schedulingUrl` field. */
  schedulingUrl?: InputMaybe<StringFilter>;
};

/** Grouping methods for `MeetingType` for usage during aggregation. */
export enum MeetingTypeGroupBy {
  Active = 'ACTIVE',
  CalendlyUri = 'CALENDLY_URI',
  DescriptionHtml = 'DESCRIPTION_HTML',
  Duration = 'DURATION',
  Name = 'NAME',
  SchedulingUrl = 'SCHEDULING_URL'
}

export type MeetingTypeHavingAverageInput = {
  duration?: InputMaybe<HavingIntFilter>;
};

export type MeetingTypeHavingDistinctCountInput = {
  duration?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `MeetingType` aggregates. */
export type MeetingTypeHavingInput = {
  AND?: InputMaybe<Array<MeetingTypeHavingInput>>;
  OR?: InputMaybe<Array<MeetingTypeHavingInput>>;
  average?: InputMaybe<MeetingTypeHavingAverageInput>;
  distinctCount?: InputMaybe<MeetingTypeHavingDistinctCountInput>;
  max?: InputMaybe<MeetingTypeHavingMaxInput>;
  min?: InputMaybe<MeetingTypeHavingMinInput>;
  stddevPopulation?: InputMaybe<MeetingTypeHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MeetingTypeHavingStddevSampleInput>;
  sum?: InputMaybe<MeetingTypeHavingSumInput>;
  variancePopulation?: InputMaybe<MeetingTypeHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MeetingTypeHavingVarianceSampleInput>;
};

export type MeetingTypeHavingMaxInput = {
  duration?: InputMaybe<HavingIntFilter>;
};

export type MeetingTypeHavingMinInput = {
  duration?: InputMaybe<HavingIntFilter>;
};

export type MeetingTypeHavingStddevPopulationInput = {
  duration?: InputMaybe<HavingIntFilter>;
};

export type MeetingTypeHavingStddevSampleInput = {
  duration?: InputMaybe<HavingIntFilter>;
};

export type MeetingTypeHavingSumInput = {
  duration?: InputMaybe<HavingIntFilter>;
};

export type MeetingTypeHavingVariancePopulationInput = {
  duration?: InputMaybe<HavingIntFilter>;
};

export type MeetingTypeHavingVarianceSampleInput = {
  duration?: InputMaybe<HavingIntFilter>;
};

export type MeetingTypeMaxAggregates = {
  __typename?: 'MeetingTypeMaxAggregates';
  /** Maximum of duration across the matching connection */
  duration?: Maybe<Scalars['Int']['output']>;
};

export type MeetingTypeMinAggregates = {
  __typename?: 'MeetingTypeMinAggregates';
  /** Minimum of duration across the matching connection */
  duration?: Maybe<Scalars['Int']['output']>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type MeetingTypeSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: MeetingTypeSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type MeetingTypeSortBy = {
  field?: InputMaybe<MeetingTypeSortableField>;
};

/** Sortable concrete fields for the `MeetingType` type. */
export enum MeetingTypeSortableField {
  Active = 'ACTIVE',
  CalendlyUri = 'CALENDLY_URI',
  DescriptionHtml = 'DESCRIPTION_HTML',
  Duration = 'DURATION',
  Id = 'ID',
  Name = 'NAME',
  SchedulingUrl = 'SCHEDULING_URL'
}

export type MeetingTypeStddevPopulationAggregates = {
  __typename?: 'MeetingTypeStddevPopulationAggregates';
  /** Population standard deviation of duration across the matching connection */
  duration?: Maybe<Scalars['BigFloat']['output']>;
};

export type MeetingTypeStddevSampleAggregates = {
  __typename?: 'MeetingTypeStddevSampleAggregates';
  /** Sample standard deviation of duration across the matching connection */
  duration?: Maybe<Scalars['BigFloat']['output']>;
};

export type MeetingTypeSumAggregates = {
  __typename?: 'MeetingTypeSumAggregates';
  /** Sum of duration across the matching connection */
  duration: Scalars['BigInt']['output'];
};

export type MeetingTypeVariancePopulationAggregates = {
  __typename?: 'MeetingTypeVariancePopulationAggregates';
  /** Population variance of duration across the matching connection */
  duration?: Maybe<Scalars['BigFloat']['output']>;
};

export type MeetingTypeVarianceSampleAggregates = {
  __typename?: 'MeetingTypeVarianceSampleAggregates';
  /** Sample variance of duration across the matching connection */
  duration?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `MeetingType` values. */
export type MeetingTypesConnection = {
  __typename?: 'MeetingTypesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MeetingTypeAggregates>;
  /** A list of edges which contains the `MeetingType` and cursor to aid in pagination. */
  edges: Array<MeetingTypesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MeetingTypeAggregates>>;
  /** A list of `MeetingType` objects. */
  nodes: Array<Maybe<MeetingType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MeetingType` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `MeetingType` values. */
export type MeetingTypesConnectionGroupedAggregatesArgs = {
  groupBy: Array<MeetingTypeGroupBy>;
  having?: InputMaybe<MeetingTypeHavingInput>;
};

/** A `MeetingType` edge in the connection. */
export type MeetingTypesEdge = {
  __typename?: 'MeetingTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `MeetingType` at the end of the edge. */
  node?: Maybe<MeetingType>;
};

/** Methods to use when ordering `MeetingType`. */
export enum MeetingTypesOrderBy {
  ActiveAsc = 'ACTIVE_ASC',
  ActiveDesc = 'ACTIVE_DESC',
  CalendlyUriAsc = 'CALENDLY_URI_ASC',
  CalendlyUriDesc = 'CALENDLY_URI_DESC',
  DescriptionHtmlAsc = 'DESCRIPTION_HTML_ASC',
  DescriptionHtmlDesc = 'DESCRIPTION_HTML_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MeetingsByMeetingTypeIdAverageCalendlyUriAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_CALENDLY_URI_ASC',
  MeetingsByMeetingTypeIdAverageCalendlyUriDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_CALENDLY_URI_DESC',
  MeetingsByMeetingTypeIdAverageCanceledByAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_CANCELED_BY_ASC',
  MeetingsByMeetingTypeIdAverageCanceledByDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_CANCELED_BY_DESC',
  MeetingsByMeetingTypeIdAverageCancelerTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_CANCELER_TYPE_ASC',
  MeetingsByMeetingTypeIdAverageCancelerTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_CANCELER_TYPE_DESC',
  MeetingsByMeetingTypeIdAverageCancellationReasonAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_CANCELLATION_REASON_ASC',
  MeetingsByMeetingTypeIdAverageCancellationReasonDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_CANCELLATION_REASON_DESC',
  MeetingsByMeetingTypeIdAverageContactFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdAverageContactFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdAverageCreatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_CREATED_TIME_ASC',
  MeetingsByMeetingTypeIdAverageCreatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_CREATED_TIME_DESC',
  MeetingsByMeetingTypeIdAverageEndTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_END_TIME_ASC',
  MeetingsByMeetingTypeIdAverageEndTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_END_TIME_DESC',
  MeetingsByMeetingTypeIdAverageIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_ID_ASC',
  MeetingsByMeetingTypeIdAverageIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_ID_DESC',
  MeetingsByMeetingTypeIdAverageLawyerFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdAverageLawyerFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdAverageLawyerIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_LAWYER_ID_ASC',
  MeetingsByMeetingTypeIdAverageLawyerIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_LAWYER_ID_DESC',
  MeetingsByMeetingTypeIdAverageLocationAddressAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_LOCATION_ADDRESS_ASC',
  MeetingsByMeetingTypeIdAverageLocationAddressDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_LOCATION_ADDRESS_DESC',
  MeetingsByMeetingTypeIdAverageLocationProviderIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_LOCATION_PROVIDER_ID_ASC',
  MeetingsByMeetingTypeIdAverageLocationProviderIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_LOCATION_PROVIDER_ID_DESC',
  MeetingsByMeetingTypeIdAverageLocationStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_LOCATION_STATUS_ASC',
  MeetingsByMeetingTypeIdAverageLocationStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_LOCATION_STATUS_DESC',
  MeetingsByMeetingTypeIdAverageLocationTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_LOCATION_TYPE_ASC',
  MeetingsByMeetingTypeIdAverageLocationTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_LOCATION_TYPE_DESC',
  MeetingsByMeetingTypeIdAverageMeetingTypeIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_MEETING_TYPE_ID_ASC',
  MeetingsByMeetingTypeIdAverageMeetingTypeIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_MEETING_TYPE_ID_DESC',
  MeetingsByMeetingTypeIdAverageNameAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_NAME_ASC',
  MeetingsByMeetingTypeIdAverageNameDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_NAME_DESC',
  MeetingsByMeetingTypeIdAverageNotifiedBeforeMeetingAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByMeetingTypeIdAverageNotifiedBeforeMeetingDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByMeetingTypeIdAverageNotifiedInAdvanceAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByMeetingTypeIdAverageNotifiedInAdvanceDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByMeetingTypeIdAverageStartTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_START_TIME_ASC',
  MeetingsByMeetingTypeIdAverageStartTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_START_TIME_DESC',
  MeetingsByMeetingTypeIdAverageStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_STATUS_ASC',
  MeetingsByMeetingTypeIdAverageStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_STATUS_DESC',
  MeetingsByMeetingTypeIdAverageUpdatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_UPDATED_TIME_ASC',
  MeetingsByMeetingTypeIdAverageUpdatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_AVERAGE_UPDATED_TIME_DESC',
  MeetingsByMeetingTypeIdCountAsc = 'MEETINGS_BY_MEETING_TYPE_ID_COUNT_ASC',
  MeetingsByMeetingTypeIdCountDesc = 'MEETINGS_BY_MEETING_TYPE_ID_COUNT_DESC',
  MeetingsByMeetingTypeIdDistinctCountCalendlyUriAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_CALENDLY_URI_ASC',
  MeetingsByMeetingTypeIdDistinctCountCalendlyUriDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_CALENDLY_URI_DESC',
  MeetingsByMeetingTypeIdDistinctCountCanceledByAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_CANCELED_BY_ASC',
  MeetingsByMeetingTypeIdDistinctCountCanceledByDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_CANCELED_BY_DESC',
  MeetingsByMeetingTypeIdDistinctCountCancelerTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_CANCELER_TYPE_ASC',
  MeetingsByMeetingTypeIdDistinctCountCancelerTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_CANCELER_TYPE_DESC',
  MeetingsByMeetingTypeIdDistinctCountCancellationReasonAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_CANCELLATION_REASON_ASC',
  MeetingsByMeetingTypeIdDistinctCountCancellationReasonDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_CANCELLATION_REASON_DESC',
  MeetingsByMeetingTypeIdDistinctCountContactFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdDistinctCountContactFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdDistinctCountCreatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  MeetingsByMeetingTypeIdDistinctCountCreatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  MeetingsByMeetingTypeIdDistinctCountEndTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_END_TIME_ASC',
  MeetingsByMeetingTypeIdDistinctCountEndTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_END_TIME_DESC',
  MeetingsByMeetingTypeIdDistinctCountIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_ID_ASC',
  MeetingsByMeetingTypeIdDistinctCountIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_ID_DESC',
  MeetingsByMeetingTypeIdDistinctCountLawyerFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdDistinctCountLawyerFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdDistinctCountLawyerIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  MeetingsByMeetingTypeIdDistinctCountLawyerIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  MeetingsByMeetingTypeIdDistinctCountLocationAddressAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_LOCATION_ADDRESS_ASC',
  MeetingsByMeetingTypeIdDistinctCountLocationAddressDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_LOCATION_ADDRESS_DESC',
  MeetingsByMeetingTypeIdDistinctCountLocationProviderIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_LOCATION_PROVIDER_ID_ASC',
  MeetingsByMeetingTypeIdDistinctCountLocationProviderIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_LOCATION_PROVIDER_ID_DESC',
  MeetingsByMeetingTypeIdDistinctCountLocationStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_LOCATION_STATUS_ASC',
  MeetingsByMeetingTypeIdDistinctCountLocationStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_LOCATION_STATUS_DESC',
  MeetingsByMeetingTypeIdDistinctCountLocationTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_LOCATION_TYPE_ASC',
  MeetingsByMeetingTypeIdDistinctCountLocationTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_LOCATION_TYPE_DESC',
  MeetingsByMeetingTypeIdDistinctCountMeetingTypeIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_MEETING_TYPE_ID_ASC',
  MeetingsByMeetingTypeIdDistinctCountMeetingTypeIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_MEETING_TYPE_ID_DESC',
  MeetingsByMeetingTypeIdDistinctCountNameAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_NAME_ASC',
  MeetingsByMeetingTypeIdDistinctCountNameDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_NAME_DESC',
  MeetingsByMeetingTypeIdDistinctCountNotifiedBeforeMeetingAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByMeetingTypeIdDistinctCountNotifiedBeforeMeetingDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByMeetingTypeIdDistinctCountNotifiedInAdvanceAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByMeetingTypeIdDistinctCountNotifiedInAdvanceDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByMeetingTypeIdDistinctCountStartTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_START_TIME_ASC',
  MeetingsByMeetingTypeIdDistinctCountStartTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_START_TIME_DESC',
  MeetingsByMeetingTypeIdDistinctCountStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_STATUS_ASC',
  MeetingsByMeetingTypeIdDistinctCountStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_STATUS_DESC',
  MeetingsByMeetingTypeIdDistinctCountUpdatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  MeetingsByMeetingTypeIdDistinctCountUpdatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  MeetingsByMeetingTypeIdMaxCalendlyUriAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_CALENDLY_URI_ASC',
  MeetingsByMeetingTypeIdMaxCalendlyUriDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_CALENDLY_URI_DESC',
  MeetingsByMeetingTypeIdMaxCanceledByAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_CANCELED_BY_ASC',
  MeetingsByMeetingTypeIdMaxCanceledByDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_CANCELED_BY_DESC',
  MeetingsByMeetingTypeIdMaxCancelerTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_CANCELER_TYPE_ASC',
  MeetingsByMeetingTypeIdMaxCancelerTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_CANCELER_TYPE_DESC',
  MeetingsByMeetingTypeIdMaxCancellationReasonAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_CANCELLATION_REASON_ASC',
  MeetingsByMeetingTypeIdMaxCancellationReasonDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_CANCELLATION_REASON_DESC',
  MeetingsByMeetingTypeIdMaxContactFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdMaxContactFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdMaxCreatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_CREATED_TIME_ASC',
  MeetingsByMeetingTypeIdMaxCreatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_CREATED_TIME_DESC',
  MeetingsByMeetingTypeIdMaxEndTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_END_TIME_ASC',
  MeetingsByMeetingTypeIdMaxEndTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_END_TIME_DESC',
  MeetingsByMeetingTypeIdMaxIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_ID_ASC',
  MeetingsByMeetingTypeIdMaxIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_ID_DESC',
  MeetingsByMeetingTypeIdMaxLawyerFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdMaxLawyerFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdMaxLawyerIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_LAWYER_ID_ASC',
  MeetingsByMeetingTypeIdMaxLawyerIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_LAWYER_ID_DESC',
  MeetingsByMeetingTypeIdMaxLocationAddressAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_LOCATION_ADDRESS_ASC',
  MeetingsByMeetingTypeIdMaxLocationAddressDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_LOCATION_ADDRESS_DESC',
  MeetingsByMeetingTypeIdMaxLocationProviderIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_LOCATION_PROVIDER_ID_ASC',
  MeetingsByMeetingTypeIdMaxLocationProviderIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_LOCATION_PROVIDER_ID_DESC',
  MeetingsByMeetingTypeIdMaxLocationStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_LOCATION_STATUS_ASC',
  MeetingsByMeetingTypeIdMaxLocationStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_LOCATION_STATUS_DESC',
  MeetingsByMeetingTypeIdMaxLocationTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_LOCATION_TYPE_ASC',
  MeetingsByMeetingTypeIdMaxLocationTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_LOCATION_TYPE_DESC',
  MeetingsByMeetingTypeIdMaxMeetingTypeIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_MEETING_TYPE_ID_ASC',
  MeetingsByMeetingTypeIdMaxMeetingTypeIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_MEETING_TYPE_ID_DESC',
  MeetingsByMeetingTypeIdMaxNameAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_NAME_ASC',
  MeetingsByMeetingTypeIdMaxNameDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_NAME_DESC',
  MeetingsByMeetingTypeIdMaxNotifiedBeforeMeetingAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByMeetingTypeIdMaxNotifiedBeforeMeetingDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByMeetingTypeIdMaxNotifiedInAdvanceAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByMeetingTypeIdMaxNotifiedInAdvanceDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByMeetingTypeIdMaxStartTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_START_TIME_ASC',
  MeetingsByMeetingTypeIdMaxStartTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_START_TIME_DESC',
  MeetingsByMeetingTypeIdMaxStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_STATUS_ASC',
  MeetingsByMeetingTypeIdMaxStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_STATUS_DESC',
  MeetingsByMeetingTypeIdMaxUpdatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_UPDATED_TIME_ASC',
  MeetingsByMeetingTypeIdMaxUpdatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MAX_UPDATED_TIME_DESC',
  MeetingsByMeetingTypeIdMinCalendlyUriAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_CALENDLY_URI_ASC',
  MeetingsByMeetingTypeIdMinCalendlyUriDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_CALENDLY_URI_DESC',
  MeetingsByMeetingTypeIdMinCanceledByAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_CANCELED_BY_ASC',
  MeetingsByMeetingTypeIdMinCanceledByDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_CANCELED_BY_DESC',
  MeetingsByMeetingTypeIdMinCancelerTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_CANCELER_TYPE_ASC',
  MeetingsByMeetingTypeIdMinCancelerTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_CANCELER_TYPE_DESC',
  MeetingsByMeetingTypeIdMinCancellationReasonAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_CANCELLATION_REASON_ASC',
  MeetingsByMeetingTypeIdMinCancellationReasonDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_CANCELLATION_REASON_DESC',
  MeetingsByMeetingTypeIdMinContactFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdMinContactFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdMinCreatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_CREATED_TIME_ASC',
  MeetingsByMeetingTypeIdMinCreatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_CREATED_TIME_DESC',
  MeetingsByMeetingTypeIdMinEndTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_END_TIME_ASC',
  MeetingsByMeetingTypeIdMinEndTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_END_TIME_DESC',
  MeetingsByMeetingTypeIdMinIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_ID_ASC',
  MeetingsByMeetingTypeIdMinIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_ID_DESC',
  MeetingsByMeetingTypeIdMinLawyerFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdMinLawyerFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdMinLawyerIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_LAWYER_ID_ASC',
  MeetingsByMeetingTypeIdMinLawyerIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_LAWYER_ID_DESC',
  MeetingsByMeetingTypeIdMinLocationAddressAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_LOCATION_ADDRESS_ASC',
  MeetingsByMeetingTypeIdMinLocationAddressDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_LOCATION_ADDRESS_DESC',
  MeetingsByMeetingTypeIdMinLocationProviderIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_LOCATION_PROVIDER_ID_ASC',
  MeetingsByMeetingTypeIdMinLocationProviderIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_LOCATION_PROVIDER_ID_DESC',
  MeetingsByMeetingTypeIdMinLocationStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_LOCATION_STATUS_ASC',
  MeetingsByMeetingTypeIdMinLocationStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_LOCATION_STATUS_DESC',
  MeetingsByMeetingTypeIdMinLocationTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_LOCATION_TYPE_ASC',
  MeetingsByMeetingTypeIdMinLocationTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_LOCATION_TYPE_DESC',
  MeetingsByMeetingTypeIdMinMeetingTypeIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_MEETING_TYPE_ID_ASC',
  MeetingsByMeetingTypeIdMinMeetingTypeIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_MEETING_TYPE_ID_DESC',
  MeetingsByMeetingTypeIdMinNameAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_NAME_ASC',
  MeetingsByMeetingTypeIdMinNameDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_NAME_DESC',
  MeetingsByMeetingTypeIdMinNotifiedBeforeMeetingAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByMeetingTypeIdMinNotifiedBeforeMeetingDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByMeetingTypeIdMinNotifiedInAdvanceAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByMeetingTypeIdMinNotifiedInAdvanceDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByMeetingTypeIdMinStartTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_START_TIME_ASC',
  MeetingsByMeetingTypeIdMinStartTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_START_TIME_DESC',
  MeetingsByMeetingTypeIdMinStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_STATUS_ASC',
  MeetingsByMeetingTypeIdMinStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_STATUS_DESC',
  MeetingsByMeetingTypeIdMinUpdatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_UPDATED_TIME_ASC',
  MeetingsByMeetingTypeIdMinUpdatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_MIN_UPDATED_TIME_DESC',
  MeetingsByMeetingTypeIdStddevPopulationCalendlyUriAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_CALENDLY_URI_ASC',
  MeetingsByMeetingTypeIdStddevPopulationCalendlyUriDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_CALENDLY_URI_DESC',
  MeetingsByMeetingTypeIdStddevPopulationCanceledByAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_CANCELED_BY_ASC',
  MeetingsByMeetingTypeIdStddevPopulationCanceledByDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_CANCELED_BY_DESC',
  MeetingsByMeetingTypeIdStddevPopulationCancelerTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_CANCELER_TYPE_ASC',
  MeetingsByMeetingTypeIdStddevPopulationCancelerTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_CANCELER_TYPE_DESC',
  MeetingsByMeetingTypeIdStddevPopulationCancellationReasonAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_CANCELLATION_REASON_ASC',
  MeetingsByMeetingTypeIdStddevPopulationCancellationReasonDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_CANCELLATION_REASON_DESC',
  MeetingsByMeetingTypeIdStddevPopulationContactFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdStddevPopulationContactFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdStddevPopulationCreatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  MeetingsByMeetingTypeIdStddevPopulationCreatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  MeetingsByMeetingTypeIdStddevPopulationEndTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_END_TIME_ASC',
  MeetingsByMeetingTypeIdStddevPopulationEndTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_END_TIME_DESC',
  MeetingsByMeetingTypeIdStddevPopulationIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_ID_ASC',
  MeetingsByMeetingTypeIdStddevPopulationIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_ID_DESC',
  MeetingsByMeetingTypeIdStddevPopulationLawyerFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdStddevPopulationLawyerFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdStddevPopulationLawyerIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  MeetingsByMeetingTypeIdStddevPopulationLawyerIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  MeetingsByMeetingTypeIdStddevPopulationLocationAddressAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_LOCATION_ADDRESS_ASC',
  MeetingsByMeetingTypeIdStddevPopulationLocationAddressDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_LOCATION_ADDRESS_DESC',
  MeetingsByMeetingTypeIdStddevPopulationLocationProviderIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_LOCATION_PROVIDER_ID_ASC',
  MeetingsByMeetingTypeIdStddevPopulationLocationProviderIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_LOCATION_PROVIDER_ID_DESC',
  MeetingsByMeetingTypeIdStddevPopulationLocationStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_LOCATION_STATUS_ASC',
  MeetingsByMeetingTypeIdStddevPopulationLocationStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_LOCATION_STATUS_DESC',
  MeetingsByMeetingTypeIdStddevPopulationLocationTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_LOCATION_TYPE_ASC',
  MeetingsByMeetingTypeIdStddevPopulationLocationTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_LOCATION_TYPE_DESC',
  MeetingsByMeetingTypeIdStddevPopulationMeetingTypeIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_MEETING_TYPE_ID_ASC',
  MeetingsByMeetingTypeIdStddevPopulationMeetingTypeIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_MEETING_TYPE_ID_DESC',
  MeetingsByMeetingTypeIdStddevPopulationNameAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_NAME_ASC',
  MeetingsByMeetingTypeIdStddevPopulationNameDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_NAME_DESC',
  MeetingsByMeetingTypeIdStddevPopulationNotifiedBeforeMeetingAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByMeetingTypeIdStddevPopulationNotifiedBeforeMeetingDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByMeetingTypeIdStddevPopulationNotifiedInAdvanceAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByMeetingTypeIdStddevPopulationNotifiedInAdvanceDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByMeetingTypeIdStddevPopulationStartTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_START_TIME_ASC',
  MeetingsByMeetingTypeIdStddevPopulationStartTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_START_TIME_DESC',
  MeetingsByMeetingTypeIdStddevPopulationStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_STATUS_ASC',
  MeetingsByMeetingTypeIdStddevPopulationStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_STATUS_DESC',
  MeetingsByMeetingTypeIdStddevPopulationUpdatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  MeetingsByMeetingTypeIdStddevPopulationUpdatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  MeetingsByMeetingTypeIdStddevSampleCalendlyUriAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_CALENDLY_URI_ASC',
  MeetingsByMeetingTypeIdStddevSampleCalendlyUriDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_CALENDLY_URI_DESC',
  MeetingsByMeetingTypeIdStddevSampleCanceledByAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_CANCELED_BY_ASC',
  MeetingsByMeetingTypeIdStddevSampleCanceledByDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_CANCELED_BY_DESC',
  MeetingsByMeetingTypeIdStddevSampleCancelerTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_CANCELER_TYPE_ASC',
  MeetingsByMeetingTypeIdStddevSampleCancelerTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_CANCELER_TYPE_DESC',
  MeetingsByMeetingTypeIdStddevSampleCancellationReasonAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_CANCELLATION_REASON_ASC',
  MeetingsByMeetingTypeIdStddevSampleCancellationReasonDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_CANCELLATION_REASON_DESC',
  MeetingsByMeetingTypeIdStddevSampleContactFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdStddevSampleContactFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdStddevSampleCreatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  MeetingsByMeetingTypeIdStddevSampleCreatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  MeetingsByMeetingTypeIdStddevSampleEndTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_END_TIME_ASC',
  MeetingsByMeetingTypeIdStddevSampleEndTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_END_TIME_DESC',
  MeetingsByMeetingTypeIdStddevSampleIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_ID_ASC',
  MeetingsByMeetingTypeIdStddevSampleIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_ID_DESC',
  MeetingsByMeetingTypeIdStddevSampleLawyerFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdStddevSampleLawyerFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdStddevSampleLawyerIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  MeetingsByMeetingTypeIdStddevSampleLawyerIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  MeetingsByMeetingTypeIdStddevSampleLocationAddressAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_LOCATION_ADDRESS_ASC',
  MeetingsByMeetingTypeIdStddevSampleLocationAddressDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_LOCATION_ADDRESS_DESC',
  MeetingsByMeetingTypeIdStddevSampleLocationProviderIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_LOCATION_PROVIDER_ID_ASC',
  MeetingsByMeetingTypeIdStddevSampleLocationProviderIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_LOCATION_PROVIDER_ID_DESC',
  MeetingsByMeetingTypeIdStddevSampleLocationStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_LOCATION_STATUS_ASC',
  MeetingsByMeetingTypeIdStddevSampleLocationStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_LOCATION_STATUS_DESC',
  MeetingsByMeetingTypeIdStddevSampleLocationTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_LOCATION_TYPE_ASC',
  MeetingsByMeetingTypeIdStddevSampleLocationTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_LOCATION_TYPE_DESC',
  MeetingsByMeetingTypeIdStddevSampleMeetingTypeIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_MEETING_TYPE_ID_ASC',
  MeetingsByMeetingTypeIdStddevSampleMeetingTypeIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_MEETING_TYPE_ID_DESC',
  MeetingsByMeetingTypeIdStddevSampleNameAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_NAME_ASC',
  MeetingsByMeetingTypeIdStddevSampleNameDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_NAME_DESC',
  MeetingsByMeetingTypeIdStddevSampleNotifiedBeforeMeetingAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByMeetingTypeIdStddevSampleNotifiedBeforeMeetingDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByMeetingTypeIdStddevSampleNotifiedInAdvanceAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByMeetingTypeIdStddevSampleNotifiedInAdvanceDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByMeetingTypeIdStddevSampleStartTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_START_TIME_ASC',
  MeetingsByMeetingTypeIdStddevSampleStartTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_START_TIME_DESC',
  MeetingsByMeetingTypeIdStddevSampleStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_STATUS_ASC',
  MeetingsByMeetingTypeIdStddevSampleStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_STATUS_DESC',
  MeetingsByMeetingTypeIdStddevSampleUpdatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  MeetingsByMeetingTypeIdStddevSampleUpdatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  MeetingsByMeetingTypeIdSumCalendlyUriAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_CALENDLY_URI_ASC',
  MeetingsByMeetingTypeIdSumCalendlyUriDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_CALENDLY_URI_DESC',
  MeetingsByMeetingTypeIdSumCanceledByAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_CANCELED_BY_ASC',
  MeetingsByMeetingTypeIdSumCanceledByDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_CANCELED_BY_DESC',
  MeetingsByMeetingTypeIdSumCancelerTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_CANCELER_TYPE_ASC',
  MeetingsByMeetingTypeIdSumCancelerTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_CANCELER_TYPE_DESC',
  MeetingsByMeetingTypeIdSumCancellationReasonAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_CANCELLATION_REASON_ASC',
  MeetingsByMeetingTypeIdSumCancellationReasonDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_CANCELLATION_REASON_DESC',
  MeetingsByMeetingTypeIdSumContactFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdSumContactFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdSumCreatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_CREATED_TIME_ASC',
  MeetingsByMeetingTypeIdSumCreatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_CREATED_TIME_DESC',
  MeetingsByMeetingTypeIdSumEndTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_END_TIME_ASC',
  MeetingsByMeetingTypeIdSumEndTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_END_TIME_DESC',
  MeetingsByMeetingTypeIdSumIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_ID_ASC',
  MeetingsByMeetingTypeIdSumIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_ID_DESC',
  MeetingsByMeetingTypeIdSumLawyerFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdSumLawyerFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdSumLawyerIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_LAWYER_ID_ASC',
  MeetingsByMeetingTypeIdSumLawyerIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_LAWYER_ID_DESC',
  MeetingsByMeetingTypeIdSumLocationAddressAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_LOCATION_ADDRESS_ASC',
  MeetingsByMeetingTypeIdSumLocationAddressDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_LOCATION_ADDRESS_DESC',
  MeetingsByMeetingTypeIdSumLocationProviderIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_LOCATION_PROVIDER_ID_ASC',
  MeetingsByMeetingTypeIdSumLocationProviderIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_LOCATION_PROVIDER_ID_DESC',
  MeetingsByMeetingTypeIdSumLocationStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_LOCATION_STATUS_ASC',
  MeetingsByMeetingTypeIdSumLocationStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_LOCATION_STATUS_DESC',
  MeetingsByMeetingTypeIdSumLocationTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_LOCATION_TYPE_ASC',
  MeetingsByMeetingTypeIdSumLocationTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_LOCATION_TYPE_DESC',
  MeetingsByMeetingTypeIdSumMeetingTypeIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_MEETING_TYPE_ID_ASC',
  MeetingsByMeetingTypeIdSumMeetingTypeIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_MEETING_TYPE_ID_DESC',
  MeetingsByMeetingTypeIdSumNameAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_NAME_ASC',
  MeetingsByMeetingTypeIdSumNameDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_NAME_DESC',
  MeetingsByMeetingTypeIdSumNotifiedBeforeMeetingAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByMeetingTypeIdSumNotifiedBeforeMeetingDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByMeetingTypeIdSumNotifiedInAdvanceAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByMeetingTypeIdSumNotifiedInAdvanceDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByMeetingTypeIdSumStartTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_START_TIME_ASC',
  MeetingsByMeetingTypeIdSumStartTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_START_TIME_DESC',
  MeetingsByMeetingTypeIdSumStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_STATUS_ASC',
  MeetingsByMeetingTypeIdSumStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_STATUS_DESC',
  MeetingsByMeetingTypeIdSumUpdatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_UPDATED_TIME_ASC',
  MeetingsByMeetingTypeIdSumUpdatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_SUM_UPDATED_TIME_DESC',
  MeetingsByMeetingTypeIdVariancePopulationCalendlyUriAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_CALENDLY_URI_ASC',
  MeetingsByMeetingTypeIdVariancePopulationCalendlyUriDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_CALENDLY_URI_DESC',
  MeetingsByMeetingTypeIdVariancePopulationCanceledByAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_CANCELED_BY_ASC',
  MeetingsByMeetingTypeIdVariancePopulationCanceledByDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_CANCELED_BY_DESC',
  MeetingsByMeetingTypeIdVariancePopulationCancelerTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_CANCELER_TYPE_ASC',
  MeetingsByMeetingTypeIdVariancePopulationCancelerTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_CANCELER_TYPE_DESC',
  MeetingsByMeetingTypeIdVariancePopulationCancellationReasonAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_CANCELLATION_REASON_ASC',
  MeetingsByMeetingTypeIdVariancePopulationCancellationReasonDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_CANCELLATION_REASON_DESC',
  MeetingsByMeetingTypeIdVariancePopulationContactFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdVariancePopulationContactFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdVariancePopulationCreatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  MeetingsByMeetingTypeIdVariancePopulationCreatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  MeetingsByMeetingTypeIdVariancePopulationEndTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_END_TIME_ASC',
  MeetingsByMeetingTypeIdVariancePopulationEndTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_END_TIME_DESC',
  MeetingsByMeetingTypeIdVariancePopulationIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_ID_ASC',
  MeetingsByMeetingTypeIdVariancePopulationIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_ID_DESC',
  MeetingsByMeetingTypeIdVariancePopulationLawyerFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdVariancePopulationLawyerFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdVariancePopulationLawyerIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  MeetingsByMeetingTypeIdVariancePopulationLawyerIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  MeetingsByMeetingTypeIdVariancePopulationLocationAddressAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_LOCATION_ADDRESS_ASC',
  MeetingsByMeetingTypeIdVariancePopulationLocationAddressDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_LOCATION_ADDRESS_DESC',
  MeetingsByMeetingTypeIdVariancePopulationLocationProviderIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_LOCATION_PROVIDER_ID_ASC',
  MeetingsByMeetingTypeIdVariancePopulationLocationProviderIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_LOCATION_PROVIDER_ID_DESC',
  MeetingsByMeetingTypeIdVariancePopulationLocationStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_LOCATION_STATUS_ASC',
  MeetingsByMeetingTypeIdVariancePopulationLocationStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_LOCATION_STATUS_DESC',
  MeetingsByMeetingTypeIdVariancePopulationLocationTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_LOCATION_TYPE_ASC',
  MeetingsByMeetingTypeIdVariancePopulationLocationTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_LOCATION_TYPE_DESC',
  MeetingsByMeetingTypeIdVariancePopulationMeetingTypeIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_MEETING_TYPE_ID_ASC',
  MeetingsByMeetingTypeIdVariancePopulationMeetingTypeIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_MEETING_TYPE_ID_DESC',
  MeetingsByMeetingTypeIdVariancePopulationNameAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_NAME_ASC',
  MeetingsByMeetingTypeIdVariancePopulationNameDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_NAME_DESC',
  MeetingsByMeetingTypeIdVariancePopulationNotifiedBeforeMeetingAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByMeetingTypeIdVariancePopulationNotifiedBeforeMeetingDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByMeetingTypeIdVariancePopulationNotifiedInAdvanceAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByMeetingTypeIdVariancePopulationNotifiedInAdvanceDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByMeetingTypeIdVariancePopulationStartTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_START_TIME_ASC',
  MeetingsByMeetingTypeIdVariancePopulationStartTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_START_TIME_DESC',
  MeetingsByMeetingTypeIdVariancePopulationStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_STATUS_ASC',
  MeetingsByMeetingTypeIdVariancePopulationStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_STATUS_DESC',
  MeetingsByMeetingTypeIdVariancePopulationUpdatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  MeetingsByMeetingTypeIdVariancePopulationUpdatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  MeetingsByMeetingTypeIdVarianceSampleCalendlyUriAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_CALENDLY_URI_ASC',
  MeetingsByMeetingTypeIdVarianceSampleCalendlyUriDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_CALENDLY_URI_DESC',
  MeetingsByMeetingTypeIdVarianceSampleCanceledByAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_CANCELED_BY_ASC',
  MeetingsByMeetingTypeIdVarianceSampleCanceledByDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_CANCELED_BY_DESC',
  MeetingsByMeetingTypeIdVarianceSampleCancelerTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_CANCELER_TYPE_ASC',
  MeetingsByMeetingTypeIdVarianceSampleCancelerTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_CANCELER_TYPE_DESC',
  MeetingsByMeetingTypeIdVarianceSampleCancellationReasonAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_CANCELLATION_REASON_ASC',
  MeetingsByMeetingTypeIdVarianceSampleCancellationReasonDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_CANCELLATION_REASON_DESC',
  MeetingsByMeetingTypeIdVarianceSampleContactFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_CONTACT_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdVarianceSampleContactFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_CONTACT_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdVarianceSampleCreatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  MeetingsByMeetingTypeIdVarianceSampleCreatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  MeetingsByMeetingTypeIdVarianceSampleEndTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_END_TIME_ASC',
  MeetingsByMeetingTypeIdVarianceSampleEndTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_END_TIME_DESC',
  MeetingsByMeetingTypeIdVarianceSampleIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_ID_ASC',
  MeetingsByMeetingTypeIdVarianceSampleIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_ID_DESC',
  MeetingsByMeetingTypeIdVarianceSampleLawyerFeedbackRequestedAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_LAWYER_FEEDBACK_REQUESTED_ASC',
  MeetingsByMeetingTypeIdVarianceSampleLawyerFeedbackRequestedDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_LAWYER_FEEDBACK_REQUESTED_DESC',
  MeetingsByMeetingTypeIdVarianceSampleLawyerIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  MeetingsByMeetingTypeIdVarianceSampleLawyerIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  MeetingsByMeetingTypeIdVarianceSampleLocationAddressAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_LOCATION_ADDRESS_ASC',
  MeetingsByMeetingTypeIdVarianceSampleLocationAddressDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_LOCATION_ADDRESS_DESC',
  MeetingsByMeetingTypeIdVarianceSampleLocationProviderIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_LOCATION_PROVIDER_ID_ASC',
  MeetingsByMeetingTypeIdVarianceSampleLocationProviderIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_LOCATION_PROVIDER_ID_DESC',
  MeetingsByMeetingTypeIdVarianceSampleLocationStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_LOCATION_STATUS_ASC',
  MeetingsByMeetingTypeIdVarianceSampleLocationStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_LOCATION_STATUS_DESC',
  MeetingsByMeetingTypeIdVarianceSampleLocationTypeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_LOCATION_TYPE_ASC',
  MeetingsByMeetingTypeIdVarianceSampleLocationTypeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_LOCATION_TYPE_DESC',
  MeetingsByMeetingTypeIdVarianceSampleMeetingTypeIdAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_MEETING_TYPE_ID_ASC',
  MeetingsByMeetingTypeIdVarianceSampleMeetingTypeIdDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_MEETING_TYPE_ID_DESC',
  MeetingsByMeetingTypeIdVarianceSampleNameAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_NAME_ASC',
  MeetingsByMeetingTypeIdVarianceSampleNameDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_NAME_DESC',
  MeetingsByMeetingTypeIdVarianceSampleNotifiedBeforeMeetingAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_NOTIFIED_BEFORE_MEETING_ASC',
  MeetingsByMeetingTypeIdVarianceSampleNotifiedBeforeMeetingDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_NOTIFIED_BEFORE_MEETING_DESC',
  MeetingsByMeetingTypeIdVarianceSampleNotifiedInAdvanceAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_NOTIFIED_IN_ADVANCE_ASC',
  MeetingsByMeetingTypeIdVarianceSampleNotifiedInAdvanceDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_NOTIFIED_IN_ADVANCE_DESC',
  MeetingsByMeetingTypeIdVarianceSampleStartTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_START_TIME_ASC',
  MeetingsByMeetingTypeIdVarianceSampleStartTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_START_TIME_DESC',
  MeetingsByMeetingTypeIdVarianceSampleStatusAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_STATUS_ASC',
  MeetingsByMeetingTypeIdVarianceSampleStatusDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_STATUS_DESC',
  MeetingsByMeetingTypeIdVarianceSampleUpdatedTimeAsc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  MeetingsByMeetingTypeIdVarianceSampleUpdatedTimeDesc = 'MEETINGS_BY_MEETING_TYPE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SchedulingUrlAsc = 'SCHEDULING_URL_ASC',
  SchedulingUrlDesc = 'SCHEDULING_URL_DESC'
}

/** A connection to a list of `Meeting` values. */
export type MeetingsConnection = {
  __typename?: 'MeetingsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MeetingAggregates>;
  /** A list of edges which contains the `Meeting` and cursor to aid in pagination. */
  edges: Array<MeetingsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MeetingAggregates>>;
  /** A list of `Meeting` objects. */
  nodes: Array<Maybe<Meeting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Meeting` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Meeting` values. */
export type MeetingsConnectionGroupedAggregatesArgs = {
  groupBy: Array<MeetingGroupBy>;
  having?: InputMaybe<MeetingHavingInput>;
};

/** A `Meeting` edge in the connection. */
export type MeetingsEdge = {
  __typename?: 'MeetingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Meeting` at the end of the edge. */
  node?: Maybe<Meeting>;
};

/** Methods to use when ordering `Meeting`. */
export enum MeetingsOrderBy {
  CalendlyUriAsc = 'CALENDLY_URI_ASC',
  CalendlyUriDesc = 'CALENDLY_URI_DESC',
  CanceledByAsc = 'CANCELED_BY_ASC',
  CanceledByDesc = 'CANCELED_BY_DESC',
  CancelerTypeAsc = 'CANCELER_TYPE_ASC',
  CancelerTypeDesc = 'CANCELER_TYPE_DESC',
  CancellationReasonAsc = 'CANCELLATION_REASON_ASC',
  CancellationReasonDesc = 'CANCELLATION_REASON_DESC',
  ContactFeedbackRequestedAsc = 'CONTACT_FEEDBACK_REQUESTED_ASC',
  ContactFeedbackRequestedDesc = 'CONTACT_FEEDBACK_REQUESTED_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  EndTimeAsc = 'END_TIME_ASC',
  EndTimeDesc = 'END_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyerFeedbackRequestedAsc = 'LAWYER_FEEDBACK_REQUESTED_ASC',
  LawyerFeedbackRequestedDesc = 'LAWYER_FEEDBACK_REQUESTED_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  LocationAddressAsc = 'LOCATION_ADDRESS_ASC',
  LocationAddressDesc = 'LOCATION_ADDRESS_DESC',
  LocationProviderIdAsc = 'LOCATION_PROVIDER_ID_ASC',
  LocationProviderIdDesc = 'LOCATION_PROVIDER_ID_DESC',
  LocationStatusAsc = 'LOCATION_STATUS_ASC',
  LocationStatusDesc = 'LOCATION_STATUS_DESC',
  LocationTypeAsc = 'LOCATION_TYPE_ASC',
  LocationTypeDesc = 'LOCATION_TYPE_DESC',
  MeetingInviteesByMeetingIdAverageCalendlyUriAsc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_CALENDLY_URI_ASC',
  MeetingInviteesByMeetingIdAverageCalendlyUriDesc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_CALENDLY_URI_DESC',
  MeetingInviteesByMeetingIdAverageCancelUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_CANCEL_URL_ASC',
  MeetingInviteesByMeetingIdAverageCancelUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_CANCEL_URL_DESC',
  MeetingInviteesByMeetingIdAverageContactIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_CONTACT_ID_ASC',
  MeetingInviteesByMeetingIdAverageContactIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_CONTACT_ID_DESC',
  MeetingInviteesByMeetingIdAverageIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_ID_ASC',
  MeetingInviteesByMeetingIdAverageIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_ID_DESC',
  MeetingInviteesByMeetingIdAverageIsNoShowAsc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_IS_NO_SHOW_ASC',
  MeetingInviteesByMeetingIdAverageIsNoShowDesc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_IS_NO_SHOW_DESC',
  MeetingInviteesByMeetingIdAverageMeetingIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_MEETING_ID_ASC',
  MeetingInviteesByMeetingIdAverageMeetingIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_MEETING_ID_DESC',
  MeetingInviteesByMeetingIdAverageRescheduledAsc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_RESCHEDULED_ASC',
  MeetingInviteesByMeetingIdAverageRescheduledDesc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_RESCHEDULED_DESC',
  MeetingInviteesByMeetingIdAverageRescheduleUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_RESCHEDULE_URL_ASC',
  MeetingInviteesByMeetingIdAverageRescheduleUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_RESCHEDULE_URL_DESC',
  MeetingInviteesByMeetingIdAverageStatusAsc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_STATUS_ASC',
  MeetingInviteesByMeetingIdAverageStatusDesc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_STATUS_DESC',
  MeetingInviteesByMeetingIdAverageTimezoneAsc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_TIMEZONE_ASC',
  MeetingInviteesByMeetingIdAverageTimezoneDesc = 'MEETING_INVITEES_BY_MEETING_ID_AVERAGE_TIMEZONE_DESC',
  MeetingInviteesByMeetingIdCountAsc = 'MEETING_INVITEES_BY_MEETING_ID_COUNT_ASC',
  MeetingInviteesByMeetingIdCountDesc = 'MEETING_INVITEES_BY_MEETING_ID_COUNT_DESC',
  MeetingInviteesByMeetingIdDistinctCountCalendlyUriAsc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_CALENDLY_URI_ASC',
  MeetingInviteesByMeetingIdDistinctCountCalendlyUriDesc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_CALENDLY_URI_DESC',
  MeetingInviteesByMeetingIdDistinctCountCancelUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_CANCEL_URL_ASC',
  MeetingInviteesByMeetingIdDistinctCountCancelUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_CANCEL_URL_DESC',
  MeetingInviteesByMeetingIdDistinctCountContactIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  MeetingInviteesByMeetingIdDistinctCountContactIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  MeetingInviteesByMeetingIdDistinctCountIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_ID_ASC',
  MeetingInviteesByMeetingIdDistinctCountIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_ID_DESC',
  MeetingInviteesByMeetingIdDistinctCountIsNoShowAsc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_IS_NO_SHOW_ASC',
  MeetingInviteesByMeetingIdDistinctCountIsNoShowDesc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_IS_NO_SHOW_DESC',
  MeetingInviteesByMeetingIdDistinctCountMeetingIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_MEETING_ID_ASC',
  MeetingInviteesByMeetingIdDistinctCountMeetingIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_MEETING_ID_DESC',
  MeetingInviteesByMeetingIdDistinctCountRescheduledAsc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_RESCHEDULED_ASC',
  MeetingInviteesByMeetingIdDistinctCountRescheduledDesc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_RESCHEDULED_DESC',
  MeetingInviteesByMeetingIdDistinctCountRescheduleUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_RESCHEDULE_URL_ASC',
  MeetingInviteesByMeetingIdDistinctCountRescheduleUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_RESCHEDULE_URL_DESC',
  MeetingInviteesByMeetingIdDistinctCountStatusAsc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_STATUS_ASC',
  MeetingInviteesByMeetingIdDistinctCountStatusDesc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_STATUS_DESC',
  MeetingInviteesByMeetingIdDistinctCountTimezoneAsc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_TIMEZONE_ASC',
  MeetingInviteesByMeetingIdDistinctCountTimezoneDesc = 'MEETING_INVITEES_BY_MEETING_ID_DISTINCT_COUNT_TIMEZONE_DESC',
  MeetingInviteesByMeetingIdMaxCalendlyUriAsc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_CALENDLY_URI_ASC',
  MeetingInviteesByMeetingIdMaxCalendlyUriDesc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_CALENDLY_URI_DESC',
  MeetingInviteesByMeetingIdMaxCancelUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_CANCEL_URL_ASC',
  MeetingInviteesByMeetingIdMaxCancelUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_CANCEL_URL_DESC',
  MeetingInviteesByMeetingIdMaxContactIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_CONTACT_ID_ASC',
  MeetingInviteesByMeetingIdMaxContactIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_CONTACT_ID_DESC',
  MeetingInviteesByMeetingIdMaxIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_ID_ASC',
  MeetingInviteesByMeetingIdMaxIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_ID_DESC',
  MeetingInviteesByMeetingIdMaxIsNoShowAsc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_IS_NO_SHOW_ASC',
  MeetingInviteesByMeetingIdMaxIsNoShowDesc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_IS_NO_SHOW_DESC',
  MeetingInviteesByMeetingIdMaxMeetingIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_MEETING_ID_ASC',
  MeetingInviteesByMeetingIdMaxMeetingIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_MEETING_ID_DESC',
  MeetingInviteesByMeetingIdMaxRescheduledAsc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_RESCHEDULED_ASC',
  MeetingInviteesByMeetingIdMaxRescheduledDesc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_RESCHEDULED_DESC',
  MeetingInviteesByMeetingIdMaxRescheduleUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_RESCHEDULE_URL_ASC',
  MeetingInviteesByMeetingIdMaxRescheduleUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_RESCHEDULE_URL_DESC',
  MeetingInviteesByMeetingIdMaxStatusAsc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_STATUS_ASC',
  MeetingInviteesByMeetingIdMaxStatusDesc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_STATUS_DESC',
  MeetingInviteesByMeetingIdMaxTimezoneAsc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_TIMEZONE_ASC',
  MeetingInviteesByMeetingIdMaxTimezoneDesc = 'MEETING_INVITEES_BY_MEETING_ID_MAX_TIMEZONE_DESC',
  MeetingInviteesByMeetingIdMinCalendlyUriAsc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_CALENDLY_URI_ASC',
  MeetingInviteesByMeetingIdMinCalendlyUriDesc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_CALENDLY_URI_DESC',
  MeetingInviteesByMeetingIdMinCancelUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_CANCEL_URL_ASC',
  MeetingInviteesByMeetingIdMinCancelUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_CANCEL_URL_DESC',
  MeetingInviteesByMeetingIdMinContactIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_CONTACT_ID_ASC',
  MeetingInviteesByMeetingIdMinContactIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_CONTACT_ID_DESC',
  MeetingInviteesByMeetingIdMinIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_ID_ASC',
  MeetingInviteesByMeetingIdMinIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_ID_DESC',
  MeetingInviteesByMeetingIdMinIsNoShowAsc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_IS_NO_SHOW_ASC',
  MeetingInviteesByMeetingIdMinIsNoShowDesc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_IS_NO_SHOW_DESC',
  MeetingInviteesByMeetingIdMinMeetingIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_MEETING_ID_ASC',
  MeetingInviteesByMeetingIdMinMeetingIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_MEETING_ID_DESC',
  MeetingInviteesByMeetingIdMinRescheduledAsc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_RESCHEDULED_ASC',
  MeetingInviteesByMeetingIdMinRescheduledDesc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_RESCHEDULED_DESC',
  MeetingInviteesByMeetingIdMinRescheduleUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_RESCHEDULE_URL_ASC',
  MeetingInviteesByMeetingIdMinRescheduleUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_RESCHEDULE_URL_DESC',
  MeetingInviteesByMeetingIdMinStatusAsc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_STATUS_ASC',
  MeetingInviteesByMeetingIdMinStatusDesc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_STATUS_DESC',
  MeetingInviteesByMeetingIdMinTimezoneAsc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_TIMEZONE_ASC',
  MeetingInviteesByMeetingIdMinTimezoneDesc = 'MEETING_INVITEES_BY_MEETING_ID_MIN_TIMEZONE_DESC',
  MeetingInviteesByMeetingIdStddevPopulationCalendlyUriAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_CALENDLY_URI_ASC',
  MeetingInviteesByMeetingIdStddevPopulationCalendlyUriDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_CALENDLY_URI_DESC',
  MeetingInviteesByMeetingIdStddevPopulationCancelUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_CANCEL_URL_ASC',
  MeetingInviteesByMeetingIdStddevPopulationCancelUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_CANCEL_URL_DESC',
  MeetingInviteesByMeetingIdStddevPopulationContactIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  MeetingInviteesByMeetingIdStddevPopulationContactIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  MeetingInviteesByMeetingIdStddevPopulationIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_ID_ASC',
  MeetingInviteesByMeetingIdStddevPopulationIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_ID_DESC',
  MeetingInviteesByMeetingIdStddevPopulationIsNoShowAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_IS_NO_SHOW_ASC',
  MeetingInviteesByMeetingIdStddevPopulationIsNoShowDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_IS_NO_SHOW_DESC',
  MeetingInviteesByMeetingIdStddevPopulationMeetingIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_MEETING_ID_ASC',
  MeetingInviteesByMeetingIdStddevPopulationMeetingIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_MEETING_ID_DESC',
  MeetingInviteesByMeetingIdStddevPopulationRescheduledAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_RESCHEDULED_ASC',
  MeetingInviteesByMeetingIdStddevPopulationRescheduledDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_RESCHEDULED_DESC',
  MeetingInviteesByMeetingIdStddevPopulationRescheduleUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_RESCHEDULE_URL_ASC',
  MeetingInviteesByMeetingIdStddevPopulationRescheduleUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_RESCHEDULE_URL_DESC',
  MeetingInviteesByMeetingIdStddevPopulationStatusAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_STATUS_ASC',
  MeetingInviteesByMeetingIdStddevPopulationStatusDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_STATUS_DESC',
  MeetingInviteesByMeetingIdStddevPopulationTimezoneAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_TIMEZONE_ASC',
  MeetingInviteesByMeetingIdStddevPopulationTimezoneDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_POPULATION_TIMEZONE_DESC',
  MeetingInviteesByMeetingIdStddevSampleCalendlyUriAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_CALENDLY_URI_ASC',
  MeetingInviteesByMeetingIdStddevSampleCalendlyUriDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_CALENDLY_URI_DESC',
  MeetingInviteesByMeetingIdStddevSampleCancelUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_CANCEL_URL_ASC',
  MeetingInviteesByMeetingIdStddevSampleCancelUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_CANCEL_URL_DESC',
  MeetingInviteesByMeetingIdStddevSampleContactIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  MeetingInviteesByMeetingIdStddevSampleContactIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  MeetingInviteesByMeetingIdStddevSampleIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_ID_ASC',
  MeetingInviteesByMeetingIdStddevSampleIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_ID_DESC',
  MeetingInviteesByMeetingIdStddevSampleIsNoShowAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_IS_NO_SHOW_ASC',
  MeetingInviteesByMeetingIdStddevSampleIsNoShowDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_IS_NO_SHOW_DESC',
  MeetingInviteesByMeetingIdStddevSampleMeetingIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_MEETING_ID_ASC',
  MeetingInviteesByMeetingIdStddevSampleMeetingIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_MEETING_ID_DESC',
  MeetingInviteesByMeetingIdStddevSampleRescheduledAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_RESCHEDULED_ASC',
  MeetingInviteesByMeetingIdStddevSampleRescheduledDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_RESCHEDULED_DESC',
  MeetingInviteesByMeetingIdStddevSampleRescheduleUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_RESCHEDULE_URL_ASC',
  MeetingInviteesByMeetingIdStddevSampleRescheduleUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_RESCHEDULE_URL_DESC',
  MeetingInviteesByMeetingIdStddevSampleStatusAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_STATUS_ASC',
  MeetingInviteesByMeetingIdStddevSampleStatusDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_STATUS_DESC',
  MeetingInviteesByMeetingIdStddevSampleTimezoneAsc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_TIMEZONE_ASC',
  MeetingInviteesByMeetingIdStddevSampleTimezoneDesc = 'MEETING_INVITEES_BY_MEETING_ID_STDDEV_SAMPLE_TIMEZONE_DESC',
  MeetingInviteesByMeetingIdSumCalendlyUriAsc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_CALENDLY_URI_ASC',
  MeetingInviteesByMeetingIdSumCalendlyUriDesc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_CALENDLY_URI_DESC',
  MeetingInviteesByMeetingIdSumCancelUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_CANCEL_URL_ASC',
  MeetingInviteesByMeetingIdSumCancelUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_CANCEL_URL_DESC',
  MeetingInviteesByMeetingIdSumContactIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_CONTACT_ID_ASC',
  MeetingInviteesByMeetingIdSumContactIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_CONTACT_ID_DESC',
  MeetingInviteesByMeetingIdSumIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_ID_ASC',
  MeetingInviteesByMeetingIdSumIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_ID_DESC',
  MeetingInviteesByMeetingIdSumIsNoShowAsc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_IS_NO_SHOW_ASC',
  MeetingInviteesByMeetingIdSumIsNoShowDesc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_IS_NO_SHOW_DESC',
  MeetingInviteesByMeetingIdSumMeetingIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_MEETING_ID_ASC',
  MeetingInviteesByMeetingIdSumMeetingIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_MEETING_ID_DESC',
  MeetingInviteesByMeetingIdSumRescheduledAsc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_RESCHEDULED_ASC',
  MeetingInviteesByMeetingIdSumRescheduledDesc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_RESCHEDULED_DESC',
  MeetingInviteesByMeetingIdSumRescheduleUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_RESCHEDULE_URL_ASC',
  MeetingInviteesByMeetingIdSumRescheduleUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_RESCHEDULE_URL_DESC',
  MeetingInviteesByMeetingIdSumStatusAsc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_STATUS_ASC',
  MeetingInviteesByMeetingIdSumStatusDesc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_STATUS_DESC',
  MeetingInviteesByMeetingIdSumTimezoneAsc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_TIMEZONE_ASC',
  MeetingInviteesByMeetingIdSumTimezoneDesc = 'MEETING_INVITEES_BY_MEETING_ID_SUM_TIMEZONE_DESC',
  MeetingInviteesByMeetingIdVariancePopulationCalendlyUriAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_CALENDLY_URI_ASC',
  MeetingInviteesByMeetingIdVariancePopulationCalendlyUriDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_CALENDLY_URI_DESC',
  MeetingInviteesByMeetingIdVariancePopulationCancelUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_CANCEL_URL_ASC',
  MeetingInviteesByMeetingIdVariancePopulationCancelUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_CANCEL_URL_DESC',
  MeetingInviteesByMeetingIdVariancePopulationContactIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  MeetingInviteesByMeetingIdVariancePopulationContactIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  MeetingInviteesByMeetingIdVariancePopulationIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_ID_ASC',
  MeetingInviteesByMeetingIdVariancePopulationIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_ID_DESC',
  MeetingInviteesByMeetingIdVariancePopulationIsNoShowAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_IS_NO_SHOW_ASC',
  MeetingInviteesByMeetingIdVariancePopulationIsNoShowDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_IS_NO_SHOW_DESC',
  MeetingInviteesByMeetingIdVariancePopulationMeetingIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_MEETING_ID_ASC',
  MeetingInviteesByMeetingIdVariancePopulationMeetingIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_MEETING_ID_DESC',
  MeetingInviteesByMeetingIdVariancePopulationRescheduledAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_RESCHEDULED_ASC',
  MeetingInviteesByMeetingIdVariancePopulationRescheduledDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_RESCHEDULED_DESC',
  MeetingInviteesByMeetingIdVariancePopulationRescheduleUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_RESCHEDULE_URL_ASC',
  MeetingInviteesByMeetingIdVariancePopulationRescheduleUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_RESCHEDULE_URL_DESC',
  MeetingInviteesByMeetingIdVariancePopulationStatusAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_STATUS_ASC',
  MeetingInviteesByMeetingIdVariancePopulationStatusDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_STATUS_DESC',
  MeetingInviteesByMeetingIdVariancePopulationTimezoneAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_TIMEZONE_ASC',
  MeetingInviteesByMeetingIdVariancePopulationTimezoneDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_POPULATION_TIMEZONE_DESC',
  MeetingInviteesByMeetingIdVarianceSampleCalendlyUriAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_CALENDLY_URI_ASC',
  MeetingInviteesByMeetingIdVarianceSampleCalendlyUriDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_CALENDLY_URI_DESC',
  MeetingInviteesByMeetingIdVarianceSampleCancelUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_CANCEL_URL_ASC',
  MeetingInviteesByMeetingIdVarianceSampleCancelUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_CANCEL_URL_DESC',
  MeetingInviteesByMeetingIdVarianceSampleContactIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  MeetingInviteesByMeetingIdVarianceSampleContactIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  MeetingInviteesByMeetingIdVarianceSampleIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_ID_ASC',
  MeetingInviteesByMeetingIdVarianceSampleIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_ID_DESC',
  MeetingInviteesByMeetingIdVarianceSampleIsNoShowAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_IS_NO_SHOW_ASC',
  MeetingInviteesByMeetingIdVarianceSampleIsNoShowDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_IS_NO_SHOW_DESC',
  MeetingInviteesByMeetingIdVarianceSampleMeetingIdAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_MEETING_ID_ASC',
  MeetingInviteesByMeetingIdVarianceSampleMeetingIdDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_MEETING_ID_DESC',
  MeetingInviteesByMeetingIdVarianceSampleRescheduledAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_RESCHEDULED_ASC',
  MeetingInviteesByMeetingIdVarianceSampleRescheduledDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_RESCHEDULED_DESC',
  MeetingInviteesByMeetingIdVarianceSampleRescheduleUrlAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_RESCHEDULE_URL_ASC',
  MeetingInviteesByMeetingIdVarianceSampleRescheduleUrlDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_RESCHEDULE_URL_DESC',
  MeetingInviteesByMeetingIdVarianceSampleStatusAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_STATUS_ASC',
  MeetingInviteesByMeetingIdVarianceSampleStatusDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_STATUS_DESC',
  MeetingInviteesByMeetingIdVarianceSampleTimezoneAsc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_TIMEZONE_ASC',
  MeetingInviteesByMeetingIdVarianceSampleTimezoneDesc = 'MEETING_INVITEES_BY_MEETING_ID_VARIANCE_SAMPLE_TIMEZONE_DESC',
  MeetingTypeIdAsc = 'MEETING_TYPE_ID_ASC',
  MeetingTypeIdDesc = 'MEETING_TYPE_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  NotifiedBeforeMeetingAsc = 'NOTIFIED_BEFORE_MEETING_ASC',
  NotifiedBeforeMeetingDesc = 'NOTIFIED_BEFORE_MEETING_DESC',
  NotifiedInAdvanceAsc = 'NOTIFIED_IN_ADVANCE_ASC',
  NotifiedInAdvanceDesc = 'NOTIFIED_IN_ADVANCE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StartTimeAsc = 'START_TIME_ASC',
  StartTimeDesc = 'START_TIME_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TransactionalEmailsByMeetingIdAverageCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_CAMPAIGN_ID_ASC',
  TransactionalEmailsByMeetingIdAverageCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_CAMPAIGN_ID_DESC',
  TransactionalEmailsByMeetingIdAverageContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_CONTACT_ID_ASC',
  TransactionalEmailsByMeetingIdAverageContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_CONTACT_ID_DESC',
  TransactionalEmailsByMeetingIdAverageCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_CREATED_TIME_ASC',
  TransactionalEmailsByMeetingIdAverageCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_CREATED_TIME_DESC',
  TransactionalEmailsByMeetingIdAverageIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_ID_ASC',
  TransactionalEmailsByMeetingIdAverageIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_ID_DESC',
  TransactionalEmailsByMeetingIdAverageLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_LAWYER_ID_ASC',
  TransactionalEmailsByMeetingIdAverageLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_LAWYER_ID_DESC',
  TransactionalEmailsByMeetingIdAverageMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_MEETING_ID_ASC',
  TransactionalEmailsByMeetingIdAverageMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_MEETING_ID_DESC',
  TransactionalEmailsByMeetingIdAveragePostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByMeetingIdAveragePostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_AVERAGE_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByMeetingIdCountAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_COUNT_ASC',
  TransactionalEmailsByMeetingIdCountDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_COUNT_DESC',
  TransactionalEmailsByMeetingIdDistinctCountCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  TransactionalEmailsByMeetingIdDistinctCountCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  TransactionalEmailsByMeetingIdDistinctCountContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  TransactionalEmailsByMeetingIdDistinctCountContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  TransactionalEmailsByMeetingIdDistinctCountCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  TransactionalEmailsByMeetingIdDistinctCountCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  TransactionalEmailsByMeetingIdDistinctCountIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_ID_ASC',
  TransactionalEmailsByMeetingIdDistinctCountIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_ID_DESC',
  TransactionalEmailsByMeetingIdDistinctCountLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  TransactionalEmailsByMeetingIdDistinctCountLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  TransactionalEmailsByMeetingIdDistinctCountMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_MEETING_ID_ASC',
  TransactionalEmailsByMeetingIdDistinctCountMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_MEETING_ID_DESC',
  TransactionalEmailsByMeetingIdDistinctCountPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByMeetingIdDistinctCountPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_DISTINCT_COUNT_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByMeetingIdMaxCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_CAMPAIGN_ID_ASC',
  TransactionalEmailsByMeetingIdMaxCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_CAMPAIGN_ID_DESC',
  TransactionalEmailsByMeetingIdMaxContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_CONTACT_ID_ASC',
  TransactionalEmailsByMeetingIdMaxContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_CONTACT_ID_DESC',
  TransactionalEmailsByMeetingIdMaxCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_CREATED_TIME_ASC',
  TransactionalEmailsByMeetingIdMaxCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_CREATED_TIME_DESC',
  TransactionalEmailsByMeetingIdMaxIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_ID_ASC',
  TransactionalEmailsByMeetingIdMaxIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_ID_DESC',
  TransactionalEmailsByMeetingIdMaxLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_LAWYER_ID_ASC',
  TransactionalEmailsByMeetingIdMaxLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_LAWYER_ID_DESC',
  TransactionalEmailsByMeetingIdMaxMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_MEETING_ID_ASC',
  TransactionalEmailsByMeetingIdMaxMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_MEETING_ID_DESC',
  TransactionalEmailsByMeetingIdMaxPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByMeetingIdMaxPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MAX_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByMeetingIdMinCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_CAMPAIGN_ID_ASC',
  TransactionalEmailsByMeetingIdMinCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_CAMPAIGN_ID_DESC',
  TransactionalEmailsByMeetingIdMinContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_CONTACT_ID_ASC',
  TransactionalEmailsByMeetingIdMinContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_CONTACT_ID_DESC',
  TransactionalEmailsByMeetingIdMinCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_CREATED_TIME_ASC',
  TransactionalEmailsByMeetingIdMinCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_CREATED_TIME_DESC',
  TransactionalEmailsByMeetingIdMinIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_ID_ASC',
  TransactionalEmailsByMeetingIdMinIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_ID_DESC',
  TransactionalEmailsByMeetingIdMinLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_LAWYER_ID_ASC',
  TransactionalEmailsByMeetingIdMinLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_LAWYER_ID_DESC',
  TransactionalEmailsByMeetingIdMinMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_MEETING_ID_ASC',
  TransactionalEmailsByMeetingIdMinMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_MEETING_ID_DESC',
  TransactionalEmailsByMeetingIdMinPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByMeetingIdMinPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_MIN_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByMeetingIdStddevPopulationCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  TransactionalEmailsByMeetingIdStddevPopulationCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  TransactionalEmailsByMeetingIdStddevPopulationContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  TransactionalEmailsByMeetingIdStddevPopulationContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  TransactionalEmailsByMeetingIdStddevPopulationCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  TransactionalEmailsByMeetingIdStddevPopulationCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  TransactionalEmailsByMeetingIdStddevPopulationIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_ID_ASC',
  TransactionalEmailsByMeetingIdStddevPopulationIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_ID_DESC',
  TransactionalEmailsByMeetingIdStddevPopulationLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  TransactionalEmailsByMeetingIdStddevPopulationLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  TransactionalEmailsByMeetingIdStddevPopulationMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_MEETING_ID_ASC',
  TransactionalEmailsByMeetingIdStddevPopulationMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_MEETING_ID_DESC',
  TransactionalEmailsByMeetingIdStddevPopulationPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByMeetingIdStddevPopulationPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_POPULATION_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByMeetingIdStddevSampleCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  TransactionalEmailsByMeetingIdStddevSampleCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  TransactionalEmailsByMeetingIdStddevSampleContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  TransactionalEmailsByMeetingIdStddevSampleContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  TransactionalEmailsByMeetingIdStddevSampleCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  TransactionalEmailsByMeetingIdStddevSampleCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  TransactionalEmailsByMeetingIdStddevSampleIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_ID_ASC',
  TransactionalEmailsByMeetingIdStddevSampleIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_ID_DESC',
  TransactionalEmailsByMeetingIdStddevSampleLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  TransactionalEmailsByMeetingIdStddevSampleLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  TransactionalEmailsByMeetingIdStddevSampleMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_MEETING_ID_ASC',
  TransactionalEmailsByMeetingIdStddevSampleMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_MEETING_ID_DESC',
  TransactionalEmailsByMeetingIdStddevSamplePostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByMeetingIdStddevSamplePostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_STDDEV_SAMPLE_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByMeetingIdSumCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_CAMPAIGN_ID_ASC',
  TransactionalEmailsByMeetingIdSumCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_CAMPAIGN_ID_DESC',
  TransactionalEmailsByMeetingIdSumContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_CONTACT_ID_ASC',
  TransactionalEmailsByMeetingIdSumContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_CONTACT_ID_DESC',
  TransactionalEmailsByMeetingIdSumCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_CREATED_TIME_ASC',
  TransactionalEmailsByMeetingIdSumCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_CREATED_TIME_DESC',
  TransactionalEmailsByMeetingIdSumIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_ID_ASC',
  TransactionalEmailsByMeetingIdSumIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_ID_DESC',
  TransactionalEmailsByMeetingIdSumLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_LAWYER_ID_ASC',
  TransactionalEmailsByMeetingIdSumLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_LAWYER_ID_DESC',
  TransactionalEmailsByMeetingIdSumMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_MEETING_ID_ASC',
  TransactionalEmailsByMeetingIdSumMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_MEETING_ID_DESC',
  TransactionalEmailsByMeetingIdSumPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByMeetingIdSumPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_SUM_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByMeetingIdVariancePopulationCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  TransactionalEmailsByMeetingIdVariancePopulationCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  TransactionalEmailsByMeetingIdVariancePopulationContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  TransactionalEmailsByMeetingIdVariancePopulationContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  TransactionalEmailsByMeetingIdVariancePopulationCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  TransactionalEmailsByMeetingIdVariancePopulationCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  TransactionalEmailsByMeetingIdVariancePopulationIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_ID_ASC',
  TransactionalEmailsByMeetingIdVariancePopulationIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_ID_DESC',
  TransactionalEmailsByMeetingIdVariancePopulationLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  TransactionalEmailsByMeetingIdVariancePopulationLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  TransactionalEmailsByMeetingIdVariancePopulationMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_MEETING_ID_ASC',
  TransactionalEmailsByMeetingIdVariancePopulationMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_MEETING_ID_DESC',
  TransactionalEmailsByMeetingIdVariancePopulationPostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByMeetingIdVariancePopulationPostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_POPULATION_POSTMARK_TEMPLATE_ID_DESC',
  TransactionalEmailsByMeetingIdVarianceSampleCampaignIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  TransactionalEmailsByMeetingIdVarianceSampleCampaignIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  TransactionalEmailsByMeetingIdVarianceSampleContactIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  TransactionalEmailsByMeetingIdVarianceSampleContactIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  TransactionalEmailsByMeetingIdVarianceSampleCreatedTimeAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  TransactionalEmailsByMeetingIdVarianceSampleCreatedTimeDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  TransactionalEmailsByMeetingIdVarianceSampleIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_ID_ASC',
  TransactionalEmailsByMeetingIdVarianceSampleIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_ID_DESC',
  TransactionalEmailsByMeetingIdVarianceSampleLawyerIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  TransactionalEmailsByMeetingIdVarianceSampleLawyerIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  TransactionalEmailsByMeetingIdVarianceSampleMeetingIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_MEETING_ID_ASC',
  TransactionalEmailsByMeetingIdVarianceSampleMeetingIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_MEETING_ID_DESC',
  TransactionalEmailsByMeetingIdVarianceSamplePostmarkTemplateIdAsc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_POSTMARK_TEMPLATE_ID_ASC',
  TransactionalEmailsByMeetingIdVarianceSamplePostmarkTemplateIdDesc = 'TRANSACTIONAL_EMAILS_BY_MEETING_ID_VARIANCE_SAMPLE_POSTMARK_TEMPLATE_ID_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

export enum MemberFeedbackRating {
  Bad = 'BAD',
  Good = 'GOOD',
  Ok = 'OK',
  VeryBad = 'VERY_BAD',
  VeryGood = 'VERY_GOOD'
}

/** A filter to be used against MemberFeedbackRating fields. All fields are combined with a logical ‘and.’ */
export type MemberFeedbackRatingFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<MemberFeedbackRating>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<MemberFeedbackRating>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<MemberFeedbackRating>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<MemberFeedbackRating>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<MemberFeedbackRating>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<MemberFeedbackRating>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<MemberFeedbackRating>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<MemberFeedbackRating>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<MemberFeedbackRating>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<MemberFeedbackRating>>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  fromEmailToDomainRev?: Maybe<FromEmailToDomainRevPayload>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  newUuid4?: Maybe<NewUuid4Payload>;
  processBatch?: Maybe<ProcessBatchPayload>;
  reverseDomain?: Maybe<ReverseDomainPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationFromEmailToDomainRevArgs = {
  input: FromEmailToDomainRevInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationNewUuid4Args = {
  input: NewUuid4Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationProcessBatchArgs = {
  input: ProcessBatchInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationReverseDomainArgs = {
  input: ReverseDomainInput;
};

/** All input for the `newUuid4` mutation. */
export type NewUuid4Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `newUuid4` mutation. */
export type NewUuid4Payload = {
  __typename?: 'NewUuid4Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

export type Organization = Node & {
  __typename?: 'Organization';
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads and enables pagination through a set of `Lawyer`. */
  lawyersByOrganizationId: LawyersConnection;
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};


export type OrganizationLawyersByOrganizationIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LawyerCondition>;
  filter?: InputMaybe<LawyerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LawyersOrderBy>>;
  sort?: InputMaybe<Array<LawyerSort>>;
};

export type OrganizationAggregates = {
  __typename?: 'OrganizationAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OrganizationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `Organization` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OrganizationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type OrganizationDistinctCountAggregates = {
  __typename?: 'OrganizationDistinctCountAggregates';
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Organization` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrganizationFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrganizationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrganizationFilter>>;
};

/** Grouping methods for `Organization` for usage during aggregation. */
export enum OrganizationGroupBy {
  Name = 'NAME'
}

/** Conditions for `Organization` aggregates. */
export type OrganizationHavingInput = {
  AND?: InputMaybe<Array<OrganizationHavingInput>>;
  OR?: InputMaybe<Array<OrganizationHavingInput>>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type OrganizationSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: OrganizationSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type OrganizationSortBy = {
  field?: InputMaybe<OrganizationSortableField>;
};

/** Sortable concrete fields for the `Organization` type. */
export enum OrganizationSortableField {
  Id = 'ID',
  Name = 'NAME'
}

/** A connection to a list of `Organization` values. */
export type OrganizationsConnection = {
  __typename?: 'OrganizationsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OrganizationAggregates>;
  /** A list of edges which contains the `Organization` and cursor to aid in pagination. */
  edges: Array<OrganizationsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OrganizationAggregates>>;
  /** A list of `Organization` objects. */
  nodes: Array<Maybe<Organization>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Organization` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Organization` values. */
export type OrganizationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<OrganizationGroupBy>;
  having?: InputMaybe<OrganizationHavingInput>;
};

/** A `Organization` edge in the connection. */
export type OrganizationsEdge = {
  __typename?: 'OrganizationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Organization` at the end of the edge. */
  node?: Maybe<Organization>;
};

/** Methods to use when ordering `Organization`. */
export enum OrganizationsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyersByOrganizationIdAverageAcquisitionChannelAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ACQUISITION_CHANNEL_ASC',
  LawyersByOrganizationIdAverageAcquisitionChannelDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ACQUISITION_CHANNEL_DESC',
  LawyersByOrganizationIdAverageAcquisitionTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ACQUISITION_TYPE_ASC',
  LawyersByOrganizationIdAverageAcquisitionTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ACQUISITION_TYPE_DESC',
  LawyersByOrganizationIdAverageAliasesAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ALIASES_ASC',
  LawyersByOrganizationIdAverageAliasesDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ALIASES_DESC',
  LawyersByOrganizationIdAverageBrandColorAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_BRAND_COLOR_ASC',
  LawyersByOrganizationIdAverageBrandColorDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_BRAND_COLOR_DESC',
  LawyersByOrganizationIdAverageCalendlyLinkAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CALENDLY_LINK_ASC',
  LawyersByOrganizationIdAverageCalendlyLinkDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CALENDLY_LINK_DESC',
  LawyersByOrganizationIdAverageCalendlyUriAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CALENDLY_URI_ASC',
  LawyersByOrganizationIdAverageCalendlyUriDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CALENDLY_URI_DESC',
  LawyersByOrganizationIdAverageChargedPerAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CHARGED_PER_ASC',
  LawyersByOrganizationIdAverageChargedPerDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CHARGED_PER_DESC',
  LawyersByOrganizationIdAverageChargeRateAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CHARGE_RATE_ASC',
  LawyersByOrganizationIdAverageChargeRateDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CHARGE_RATE_DESC',
  LawyersByOrganizationIdAverageCityAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CITY_ASC',
  LawyersByOrganizationIdAverageCityDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CITY_DESC',
  LawyersByOrganizationIdAverageClientProfileRequirementsAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByOrganizationIdAverageClientProfileRequirementsDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByOrganizationIdAverageContactIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CONTACT_ID_ASC',
  LawyersByOrganizationIdAverageContactIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CONTACT_ID_DESC',
  LawyersByOrganizationIdAverageCountryAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_COUNTRY_ASC',
  LawyersByOrganizationIdAverageCountryCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_COUNTRY_CODE_ASC',
  LawyersByOrganizationIdAverageCountryCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_COUNTRY_CODE_DESC',
  LawyersByOrganizationIdAverageCountryDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_COUNTRY_DESC',
  LawyersByOrganizationIdAverageCreatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CREATED_TIME_ASC',
  LawyersByOrganizationIdAverageCreatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_CREATED_TIME_DESC',
  LawyersByOrganizationIdAverageEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_EMAIL_ASC',
  LawyersByOrganizationIdAverageEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_EMAIL_DESC',
  LawyersByOrganizationIdAverageEnableAutoTestimonialAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByOrganizationIdAverageEnableAutoTestimonialDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByOrganizationIdAverageExternalContactsLastUpdatedAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByOrganizationIdAverageExternalContactsLastUpdatedDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByOrganizationIdAverageFirmDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_FIRM_DESCRIPTION_ASC',
  LawyersByOrganizationIdAverageFirmDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_FIRM_DESCRIPTION_DESC',
  LawyersByOrganizationIdAverageFirmNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_FIRM_NAME_ASC',
  LawyersByOrganizationIdAverageFirmNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_FIRM_NAME_DESC',
  LawyersByOrganizationIdAverageFreeLeadsAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_FREE_LEADS_ASC',
  LawyersByOrganizationIdAverageFreeLeadsDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_FREE_LEADS_DESC',
  LawyersByOrganizationIdAverageGooglePlaceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_GOOGLE_PLACE_ID_ASC',
  LawyersByOrganizationIdAverageGooglePlaceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_GOOGLE_PLACE_ID_DESC',
  LawyersByOrganizationIdAverageHandoverMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_HANDOVER_METHOD_ASC',
  LawyersByOrganizationIdAverageHandoverMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_HANDOVER_METHOD_DESC',
  LawyersByOrganizationIdAverageHandoverStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_HANDOVER_STATE_ASC',
  LawyersByOrganizationIdAverageHandoverStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_HANDOVER_STATE_DESC',
  LawyersByOrganizationIdAverageHubspotDealIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_HUBSPOT_DEAL_ID_ASC',
  LawyersByOrganizationIdAverageHubspotDealIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_HUBSPOT_DEAL_ID_DESC',
  LawyersByOrganizationIdAverageIcpAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ICP_ASC',
  LawyersByOrganizationIdAverageIcpDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ICP_DESC',
  LawyersByOrganizationIdAverageIcpGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ICP_GUESSED_TIME_ASC',
  LawyersByOrganizationIdAverageIcpGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ICP_GUESSED_TIME_DESC',
  LawyersByOrganizationIdAverageIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByOrganizationIdAverageIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByOrganizationIdAverageIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ID_ASC',
  LawyersByOrganizationIdAverageIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ID_DESC',
  LawyersByOrganizationIdAverageIsManagedAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_IS_MANAGED_ASC',
  LawyersByOrganizationIdAverageIsManagedDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_IS_MANAGED_DESC',
  LawyersByOrganizationIdAverageIsPausedAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_IS_PAUSED_ASC',
  LawyersByOrganizationIdAverageIsPausedDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_IS_PAUSED_DESC',
  LawyersByOrganizationIdAverageLastAccountReviewAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByOrganizationIdAverageLastAccountReviewDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByOrganizationIdAverageLastReportTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LAST_REPORT_TIME_ASC',
  LawyersByOrganizationIdAverageLastReportTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LAST_REPORT_TIME_DESC',
  LawyersByOrganizationIdAverageLastStatusEmailTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByOrganizationIdAverageLastStatusEmailTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByOrganizationIdAverageLawyerDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LAWYER_DESCRIPTION_ASC',
  LawyersByOrganizationIdAverageLawyerDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LAWYER_DESCRIPTION_DESC',
  LawyersByOrganizationIdAverageLeadsPerMonthAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LEADS_PER_MONTH_ASC',
  LawyersByOrganizationIdAverageLeadsPerMonthDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LEADS_PER_MONTH_DESC',
  LawyersByOrganizationIdAverageLeverAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LEVER_ASC',
  LawyersByOrganizationIdAverageLeverDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LEVER_DESC',
  LawyersByOrganizationIdAverageLinkedinUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LINKEDIN_URL_ASC',
  LawyersByOrganizationIdAverageLinkedinUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LINKEDIN_URL_DESC',
  LawyersByOrganizationIdAverageLiEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_EMAIL_ASC',
  LawyersByOrganizationIdAverageLiEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_EMAIL_DESC',
  LawyersByOrganizationIdAverageLiEncryptedPwAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_ENCRYPTED_PW_ASC',
  LawyersByOrganizationIdAverageLiEncryptedPwDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_ENCRYPTED_PW_DESC',
  LawyersByOrganizationIdAverageLiLastActiveTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByOrganizationIdAverageLiLastActiveTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByOrganizationIdAverageLiLoginStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_LOGIN_STATUS_ASC',
  LawyersByOrganizationIdAverageLiLoginStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_LOGIN_STATUS_DESC',
  LawyersByOrganizationIdAverageLiProfileNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_PROFILE_NAME_ASC',
  LawyersByOrganizationIdAverageLiProfileNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_PROFILE_NAME_DESC',
  LawyersByOrganizationIdAverageLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByOrganizationIdAverageLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByOrganizationIdAverageLiVerificationCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_VERIFICATION_CODE_ASC',
  LawyersByOrganizationIdAverageLiVerificationCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LI_VERIFICATION_CODE_DESC',
  LawyersByOrganizationIdAverageLogoSrcAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LOGO_SRC_ASC',
  LawyersByOrganizationIdAverageLogoSrcDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_LOGO_SRC_DESC',
  LawyersByOrganizationIdAverageNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_NAME_ASC',
  LawyersByOrganizationIdAverageNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_NAME_DESC',
  LawyersByOrganizationIdAverageNylasAccessTokenAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByOrganizationIdAverageNylasAccessTokenDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByOrganizationIdAverageOnboardingFeePaidTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByOrganizationIdAverageOnboardingFeePaidTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByOrganizationIdAverageOnboardingTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ONBOARDING_TYPE_ASC',
  LawyersByOrganizationIdAverageOnboardingTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ONBOARDING_TYPE_DESC',
  LawyersByOrganizationIdAverageOrganizationIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ORGANIZATION_ID_ASC',
  LawyersByOrganizationIdAverageOrganizationIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_ORGANIZATION_ID_DESC',
  LawyersByOrganizationIdAveragePaymentMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_PAYMENT_METHOD_ASC',
  LawyersByOrganizationIdAveragePaymentMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_PAYMENT_METHOD_DESC',
  LawyersByOrganizationIdAveragePersonalisationRankingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_PERSONALISATION_RANKINGS_ASC',
  LawyersByOrganizationIdAveragePersonalisationRankingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_PERSONALISATION_RANKINGS_DESC',
  LawyersByOrganizationIdAveragePostalCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_POSTAL_CODE_ASC',
  LawyersByOrganizationIdAveragePostalCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_POSTAL_CODE_DESC',
  LawyersByOrganizationIdAveragePriorityAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_PRIORITY_ASC',
  LawyersByOrganizationIdAveragePriorityDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_PRIORITY_DESC',
  LawyersByOrganizationIdAverageProxyIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_PROXY_ID_ASC',
  LawyersByOrganizationIdAverageProxyIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_PROXY_ID_DESC',
  LawyersByOrganizationIdAveragePublicIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_PUBLIC_ID_ASC',
  LawyersByOrganizationIdAveragePublicIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_PUBLIC_ID_DESC',
  LawyersByOrganizationIdAverageReplyNotesAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_REPLY_NOTES_ASC',
  LawyersByOrganizationIdAverageReplyNotesDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_REPLY_NOTES_DESC',
  LawyersByOrganizationIdAverageSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByOrganizationIdAverageSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByOrganizationIdAverageSenderDisclaimerAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_SENDER_DISCLAIMER_ASC',
  LawyersByOrganizationIdAverageSenderDisclaimerDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_SENDER_DISCLAIMER_DESC',
  LawyersByOrganizationIdAverageSignatureHtmlAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_SIGNATURE_HTML_ASC',
  LawyersByOrganizationIdAverageSignatureHtmlDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_SIGNATURE_HTML_DESC',
  LawyersByOrganizationIdAverageSlackChannelNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_SLACK_CHANNEL_NAME_ASC',
  LawyersByOrganizationIdAverageSlackChannelNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_SLACK_CHANNEL_NAME_DESC',
  LawyersByOrganizationIdAverageSlackClientIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_SLACK_CLIENT_ID_ASC',
  LawyersByOrganizationIdAverageSlackClientIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_SLACK_CLIENT_ID_DESC',
  LawyersByOrganizationIdAverageSlackClientSecretAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_SLACK_CLIENT_SECRET_ASC',
  LawyersByOrganizationIdAverageSlackClientSecretDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_SLACK_CLIENT_SECRET_DESC',
  LawyersByOrganizationIdAverageStageAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STAGE_ASC',
  LawyersByOrganizationIdAverageStageDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STAGE_DESC',
  LawyersByOrganizationIdAverageStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STATE_ASC',
  LawyersByOrganizationIdAverageStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STATE_DESC',
  LawyersByOrganizationIdAverageStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STATUS_ASC',
  LawyersByOrganizationIdAverageStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STATUS_DESC',
  LawyersByOrganizationIdAverageStreetAddressAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STREET_ADDRESS_ASC',
  LawyersByOrganizationIdAverageStreetAddressDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STREET_ADDRESS_DESC',
  LawyersByOrganizationIdAverageStripeCustomerIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STRIPE_CUSTOMER_ID_ASC',
  LawyersByOrganizationIdAverageStripeCustomerIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STRIPE_CUSTOMER_ID_DESC',
  LawyersByOrganizationIdAverageStripeDiscountCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByOrganizationIdAverageStripeDiscountCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByOrganizationIdAverageStripePriceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STRIPE_PRICE_ID_ASC',
  LawyersByOrganizationIdAverageStripePriceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_STRIPE_PRICE_ID_DESC',
  LawyersByOrganizationIdAverageTimezoneAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_TIMEZONE_ASC',
  LawyersByOrganizationIdAverageTimezoneDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_TIMEZONE_DESC',
  LawyersByOrganizationIdAverageTitleAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_TITLE_ASC',
  LawyersByOrganizationIdAverageTitleDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_TITLE_DESC',
  LawyersByOrganizationIdAverageUpdatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_UPDATED_TIME_ASC',
  LawyersByOrganizationIdAverageUpdatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_UPDATED_TIME_DESC',
  LawyersByOrganizationIdAverageUserTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_USER_TYPE_ASC',
  LawyersByOrganizationIdAverageUserTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_USER_TYPE_DESC',
  LawyersByOrganizationIdAverageValuePropositionAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_VALUE_PROPOSITION_ASC',
  LawyersByOrganizationIdAverageValuePropositionDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_VALUE_PROPOSITION_DESC',
  LawyersByOrganizationIdAverageValuePropositionGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByOrganizationIdAverageValuePropositionGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByOrganizationIdAverageWantsToChooseDomainsAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByOrganizationIdAverageWantsToChooseDomainsDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByOrganizationIdAverageWebsiteUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_WEBSITE_URL_ASC',
  LawyersByOrganizationIdAverageWebsiteUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_AVERAGE_WEBSITE_URL_DESC',
  LawyersByOrganizationIdCountAsc = 'LAWYERS_BY_ORGANIZATION_ID_COUNT_ASC',
  LawyersByOrganizationIdCountDesc = 'LAWYERS_BY_ORGANIZATION_ID_COUNT_DESC',
  LawyersByOrganizationIdDistinctCountAcquisitionChannelAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ACQUISITION_CHANNEL_ASC',
  LawyersByOrganizationIdDistinctCountAcquisitionChannelDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ACQUISITION_CHANNEL_DESC',
  LawyersByOrganizationIdDistinctCountAcquisitionTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ACQUISITION_TYPE_ASC',
  LawyersByOrganizationIdDistinctCountAcquisitionTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ACQUISITION_TYPE_DESC',
  LawyersByOrganizationIdDistinctCountAliasesAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ALIASES_ASC',
  LawyersByOrganizationIdDistinctCountAliasesDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ALIASES_DESC',
  LawyersByOrganizationIdDistinctCountBrandColorAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_BRAND_COLOR_ASC',
  LawyersByOrganizationIdDistinctCountBrandColorDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_BRAND_COLOR_DESC',
  LawyersByOrganizationIdDistinctCountCalendlyLinkAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CALENDLY_LINK_ASC',
  LawyersByOrganizationIdDistinctCountCalendlyLinkDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CALENDLY_LINK_DESC',
  LawyersByOrganizationIdDistinctCountCalendlyUriAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CALENDLY_URI_ASC',
  LawyersByOrganizationIdDistinctCountCalendlyUriDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CALENDLY_URI_DESC',
  LawyersByOrganizationIdDistinctCountChargedPerAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CHARGED_PER_ASC',
  LawyersByOrganizationIdDistinctCountChargedPerDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CHARGED_PER_DESC',
  LawyersByOrganizationIdDistinctCountChargeRateAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CHARGE_RATE_ASC',
  LawyersByOrganizationIdDistinctCountChargeRateDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CHARGE_RATE_DESC',
  LawyersByOrganizationIdDistinctCountCityAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CITY_ASC',
  LawyersByOrganizationIdDistinctCountCityDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CITY_DESC',
  LawyersByOrganizationIdDistinctCountClientProfileRequirementsAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByOrganizationIdDistinctCountClientProfileRequirementsDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByOrganizationIdDistinctCountContactIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  LawyersByOrganizationIdDistinctCountContactIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  LawyersByOrganizationIdDistinctCountCountryAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_COUNTRY_ASC',
  LawyersByOrganizationIdDistinctCountCountryCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_COUNTRY_CODE_ASC',
  LawyersByOrganizationIdDistinctCountCountryCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_COUNTRY_CODE_DESC',
  LawyersByOrganizationIdDistinctCountCountryDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_COUNTRY_DESC',
  LawyersByOrganizationIdDistinctCountCreatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  LawyersByOrganizationIdDistinctCountCreatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  LawyersByOrganizationIdDistinctCountEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_EMAIL_ASC',
  LawyersByOrganizationIdDistinctCountEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_EMAIL_DESC',
  LawyersByOrganizationIdDistinctCountEnableAutoTestimonialAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByOrganizationIdDistinctCountEnableAutoTestimonialDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByOrganizationIdDistinctCountExternalContactsLastUpdatedAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByOrganizationIdDistinctCountExternalContactsLastUpdatedDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByOrganizationIdDistinctCountFirmDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_FIRM_DESCRIPTION_ASC',
  LawyersByOrganizationIdDistinctCountFirmDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_FIRM_DESCRIPTION_DESC',
  LawyersByOrganizationIdDistinctCountFirmNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_FIRM_NAME_ASC',
  LawyersByOrganizationIdDistinctCountFirmNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_FIRM_NAME_DESC',
  LawyersByOrganizationIdDistinctCountFreeLeadsAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_FREE_LEADS_ASC',
  LawyersByOrganizationIdDistinctCountFreeLeadsDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_FREE_LEADS_DESC',
  LawyersByOrganizationIdDistinctCountGooglePlaceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_GOOGLE_PLACE_ID_ASC',
  LawyersByOrganizationIdDistinctCountGooglePlaceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_GOOGLE_PLACE_ID_DESC',
  LawyersByOrganizationIdDistinctCountHandoverMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_HANDOVER_METHOD_ASC',
  LawyersByOrganizationIdDistinctCountHandoverMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_HANDOVER_METHOD_DESC',
  LawyersByOrganizationIdDistinctCountHandoverStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_HANDOVER_STATE_ASC',
  LawyersByOrganizationIdDistinctCountHandoverStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_HANDOVER_STATE_DESC',
  LawyersByOrganizationIdDistinctCountHubspotDealIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_HUBSPOT_DEAL_ID_ASC',
  LawyersByOrganizationIdDistinctCountHubspotDealIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_HUBSPOT_DEAL_ID_DESC',
  LawyersByOrganizationIdDistinctCountIcpAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ICP_ASC',
  LawyersByOrganizationIdDistinctCountIcpDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ICP_DESC',
  LawyersByOrganizationIdDistinctCountIcpGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ICP_GUESSED_TIME_ASC',
  LawyersByOrganizationIdDistinctCountIcpGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ICP_GUESSED_TIME_DESC',
  LawyersByOrganizationIdDistinctCountIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByOrganizationIdDistinctCountIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByOrganizationIdDistinctCountIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ID_ASC',
  LawyersByOrganizationIdDistinctCountIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ID_DESC',
  LawyersByOrganizationIdDistinctCountIsManagedAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_IS_MANAGED_ASC',
  LawyersByOrganizationIdDistinctCountIsManagedDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_IS_MANAGED_DESC',
  LawyersByOrganizationIdDistinctCountIsPausedAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_IS_PAUSED_ASC',
  LawyersByOrganizationIdDistinctCountIsPausedDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_IS_PAUSED_DESC',
  LawyersByOrganizationIdDistinctCountLastAccountReviewAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByOrganizationIdDistinctCountLastAccountReviewDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByOrganizationIdDistinctCountLastReportTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LAST_REPORT_TIME_ASC',
  LawyersByOrganizationIdDistinctCountLastReportTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LAST_REPORT_TIME_DESC',
  LawyersByOrganizationIdDistinctCountLastStatusEmailTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByOrganizationIdDistinctCountLastStatusEmailTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByOrganizationIdDistinctCountLawyerDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LAWYER_DESCRIPTION_ASC',
  LawyersByOrganizationIdDistinctCountLawyerDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LAWYER_DESCRIPTION_DESC',
  LawyersByOrganizationIdDistinctCountLeadsPerMonthAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LEADS_PER_MONTH_ASC',
  LawyersByOrganizationIdDistinctCountLeadsPerMonthDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LEADS_PER_MONTH_DESC',
  LawyersByOrganizationIdDistinctCountLeverAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LEVER_ASC',
  LawyersByOrganizationIdDistinctCountLeverDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LEVER_DESC',
  LawyersByOrganizationIdDistinctCountLinkedinUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LINKEDIN_URL_ASC',
  LawyersByOrganizationIdDistinctCountLinkedinUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LINKEDIN_URL_DESC',
  LawyersByOrganizationIdDistinctCountLiEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_EMAIL_ASC',
  LawyersByOrganizationIdDistinctCountLiEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_EMAIL_DESC',
  LawyersByOrganizationIdDistinctCountLiEncryptedPwAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_ENCRYPTED_PW_ASC',
  LawyersByOrganizationIdDistinctCountLiEncryptedPwDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_ENCRYPTED_PW_DESC',
  LawyersByOrganizationIdDistinctCountLiLastActiveTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByOrganizationIdDistinctCountLiLastActiveTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByOrganizationIdDistinctCountLiLoginStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_LOGIN_STATUS_ASC',
  LawyersByOrganizationIdDistinctCountLiLoginStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_LOGIN_STATUS_DESC',
  LawyersByOrganizationIdDistinctCountLiProfileNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_PROFILE_NAME_ASC',
  LawyersByOrganizationIdDistinctCountLiProfileNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_PROFILE_NAME_DESC',
  LawyersByOrganizationIdDistinctCountLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByOrganizationIdDistinctCountLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByOrganizationIdDistinctCountLiVerificationCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_VERIFICATION_CODE_ASC',
  LawyersByOrganizationIdDistinctCountLiVerificationCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LI_VERIFICATION_CODE_DESC',
  LawyersByOrganizationIdDistinctCountLogoSrcAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LOGO_SRC_ASC',
  LawyersByOrganizationIdDistinctCountLogoSrcDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_LOGO_SRC_DESC',
  LawyersByOrganizationIdDistinctCountNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_NAME_ASC',
  LawyersByOrganizationIdDistinctCountNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_NAME_DESC',
  LawyersByOrganizationIdDistinctCountNylasAccessTokenAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByOrganizationIdDistinctCountNylasAccessTokenDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByOrganizationIdDistinctCountOnboardingFeePaidTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByOrganizationIdDistinctCountOnboardingFeePaidTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByOrganizationIdDistinctCountOnboardingTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ONBOARDING_TYPE_ASC',
  LawyersByOrganizationIdDistinctCountOnboardingTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ONBOARDING_TYPE_DESC',
  LawyersByOrganizationIdDistinctCountOrganizationIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ORGANIZATION_ID_ASC',
  LawyersByOrganizationIdDistinctCountOrganizationIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ORGANIZATION_ID_DESC',
  LawyersByOrganizationIdDistinctCountPaymentMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_PAYMENT_METHOD_ASC',
  LawyersByOrganizationIdDistinctCountPaymentMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_PAYMENT_METHOD_DESC',
  LawyersByOrganizationIdDistinctCountPersonalisationRankingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_PERSONALISATION_RANKINGS_ASC',
  LawyersByOrganizationIdDistinctCountPersonalisationRankingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_PERSONALISATION_RANKINGS_DESC',
  LawyersByOrganizationIdDistinctCountPostalCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_POSTAL_CODE_ASC',
  LawyersByOrganizationIdDistinctCountPostalCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_POSTAL_CODE_DESC',
  LawyersByOrganizationIdDistinctCountPriorityAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_PRIORITY_ASC',
  LawyersByOrganizationIdDistinctCountPriorityDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_PRIORITY_DESC',
  LawyersByOrganizationIdDistinctCountProxyIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_PROXY_ID_ASC',
  LawyersByOrganizationIdDistinctCountProxyIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_PROXY_ID_DESC',
  LawyersByOrganizationIdDistinctCountPublicIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_PUBLIC_ID_ASC',
  LawyersByOrganizationIdDistinctCountPublicIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_PUBLIC_ID_DESC',
  LawyersByOrganizationIdDistinctCountReplyNotesAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_REPLY_NOTES_ASC',
  LawyersByOrganizationIdDistinctCountReplyNotesDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_REPLY_NOTES_DESC',
  LawyersByOrganizationIdDistinctCountSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByOrganizationIdDistinctCountSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByOrganizationIdDistinctCountSenderDisclaimerAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_SENDER_DISCLAIMER_ASC',
  LawyersByOrganizationIdDistinctCountSenderDisclaimerDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_SENDER_DISCLAIMER_DESC',
  LawyersByOrganizationIdDistinctCountSignatureHtmlAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_SIGNATURE_HTML_ASC',
  LawyersByOrganizationIdDistinctCountSignatureHtmlDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_SIGNATURE_HTML_DESC',
  LawyersByOrganizationIdDistinctCountSlackChannelNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_SLACK_CHANNEL_NAME_ASC',
  LawyersByOrganizationIdDistinctCountSlackChannelNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_SLACK_CHANNEL_NAME_DESC',
  LawyersByOrganizationIdDistinctCountSlackClientIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_SLACK_CLIENT_ID_ASC',
  LawyersByOrganizationIdDistinctCountSlackClientIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_SLACK_CLIENT_ID_DESC',
  LawyersByOrganizationIdDistinctCountSlackClientSecretAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_SLACK_CLIENT_SECRET_ASC',
  LawyersByOrganizationIdDistinctCountSlackClientSecretDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_SLACK_CLIENT_SECRET_DESC',
  LawyersByOrganizationIdDistinctCountStageAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STAGE_ASC',
  LawyersByOrganizationIdDistinctCountStageDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STAGE_DESC',
  LawyersByOrganizationIdDistinctCountStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STATE_ASC',
  LawyersByOrganizationIdDistinctCountStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STATE_DESC',
  LawyersByOrganizationIdDistinctCountStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STATUS_ASC',
  LawyersByOrganizationIdDistinctCountStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STATUS_DESC',
  LawyersByOrganizationIdDistinctCountStreetAddressAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STREET_ADDRESS_ASC',
  LawyersByOrganizationIdDistinctCountStreetAddressDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STREET_ADDRESS_DESC',
  LawyersByOrganizationIdDistinctCountStripeCustomerIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STRIPE_CUSTOMER_ID_ASC',
  LawyersByOrganizationIdDistinctCountStripeCustomerIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STRIPE_CUSTOMER_ID_DESC',
  LawyersByOrganizationIdDistinctCountStripeDiscountCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByOrganizationIdDistinctCountStripeDiscountCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByOrganizationIdDistinctCountStripePriceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STRIPE_PRICE_ID_ASC',
  LawyersByOrganizationIdDistinctCountStripePriceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_STRIPE_PRICE_ID_DESC',
  LawyersByOrganizationIdDistinctCountTimezoneAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_TIMEZONE_ASC',
  LawyersByOrganizationIdDistinctCountTimezoneDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_TIMEZONE_DESC',
  LawyersByOrganizationIdDistinctCountTitleAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_TITLE_ASC',
  LawyersByOrganizationIdDistinctCountTitleDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_TITLE_DESC',
  LawyersByOrganizationIdDistinctCountUpdatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  LawyersByOrganizationIdDistinctCountUpdatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  LawyersByOrganizationIdDistinctCountUserTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_USER_TYPE_ASC',
  LawyersByOrganizationIdDistinctCountUserTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_USER_TYPE_DESC',
  LawyersByOrganizationIdDistinctCountValuePropositionAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_VALUE_PROPOSITION_ASC',
  LawyersByOrganizationIdDistinctCountValuePropositionDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_VALUE_PROPOSITION_DESC',
  LawyersByOrganizationIdDistinctCountValuePropositionGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByOrganizationIdDistinctCountValuePropositionGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByOrganizationIdDistinctCountWantsToChooseDomainsAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByOrganizationIdDistinctCountWantsToChooseDomainsDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByOrganizationIdDistinctCountWebsiteUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_WEBSITE_URL_ASC',
  LawyersByOrganizationIdDistinctCountWebsiteUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_DISTINCT_COUNT_WEBSITE_URL_DESC',
  LawyersByOrganizationIdMaxAcquisitionChannelAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ACQUISITION_CHANNEL_ASC',
  LawyersByOrganizationIdMaxAcquisitionChannelDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ACQUISITION_CHANNEL_DESC',
  LawyersByOrganizationIdMaxAcquisitionTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ACQUISITION_TYPE_ASC',
  LawyersByOrganizationIdMaxAcquisitionTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ACQUISITION_TYPE_DESC',
  LawyersByOrganizationIdMaxAliasesAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ALIASES_ASC',
  LawyersByOrganizationIdMaxAliasesDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ALIASES_DESC',
  LawyersByOrganizationIdMaxBrandColorAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_BRAND_COLOR_ASC',
  LawyersByOrganizationIdMaxBrandColorDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_BRAND_COLOR_DESC',
  LawyersByOrganizationIdMaxCalendlyLinkAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CALENDLY_LINK_ASC',
  LawyersByOrganizationIdMaxCalendlyLinkDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CALENDLY_LINK_DESC',
  LawyersByOrganizationIdMaxCalendlyUriAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CALENDLY_URI_ASC',
  LawyersByOrganizationIdMaxCalendlyUriDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CALENDLY_URI_DESC',
  LawyersByOrganizationIdMaxChargedPerAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CHARGED_PER_ASC',
  LawyersByOrganizationIdMaxChargedPerDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CHARGED_PER_DESC',
  LawyersByOrganizationIdMaxChargeRateAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CHARGE_RATE_ASC',
  LawyersByOrganizationIdMaxChargeRateDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CHARGE_RATE_DESC',
  LawyersByOrganizationIdMaxCityAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CITY_ASC',
  LawyersByOrganizationIdMaxCityDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CITY_DESC',
  LawyersByOrganizationIdMaxClientProfileRequirementsAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByOrganizationIdMaxClientProfileRequirementsDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByOrganizationIdMaxContactIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CONTACT_ID_ASC',
  LawyersByOrganizationIdMaxContactIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CONTACT_ID_DESC',
  LawyersByOrganizationIdMaxCountryAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_COUNTRY_ASC',
  LawyersByOrganizationIdMaxCountryCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_COUNTRY_CODE_ASC',
  LawyersByOrganizationIdMaxCountryCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_COUNTRY_CODE_DESC',
  LawyersByOrganizationIdMaxCountryDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_COUNTRY_DESC',
  LawyersByOrganizationIdMaxCreatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CREATED_TIME_ASC',
  LawyersByOrganizationIdMaxCreatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_CREATED_TIME_DESC',
  LawyersByOrganizationIdMaxEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_EMAIL_ASC',
  LawyersByOrganizationIdMaxEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_EMAIL_DESC',
  LawyersByOrganizationIdMaxEnableAutoTestimonialAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByOrganizationIdMaxEnableAutoTestimonialDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByOrganizationIdMaxExternalContactsLastUpdatedAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByOrganizationIdMaxExternalContactsLastUpdatedDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByOrganizationIdMaxFirmDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_FIRM_DESCRIPTION_ASC',
  LawyersByOrganizationIdMaxFirmDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_FIRM_DESCRIPTION_DESC',
  LawyersByOrganizationIdMaxFirmNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_FIRM_NAME_ASC',
  LawyersByOrganizationIdMaxFirmNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_FIRM_NAME_DESC',
  LawyersByOrganizationIdMaxFreeLeadsAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_FREE_LEADS_ASC',
  LawyersByOrganizationIdMaxFreeLeadsDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_FREE_LEADS_DESC',
  LawyersByOrganizationIdMaxGooglePlaceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_GOOGLE_PLACE_ID_ASC',
  LawyersByOrganizationIdMaxGooglePlaceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_GOOGLE_PLACE_ID_DESC',
  LawyersByOrganizationIdMaxHandoverMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_HANDOVER_METHOD_ASC',
  LawyersByOrganizationIdMaxHandoverMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_HANDOVER_METHOD_DESC',
  LawyersByOrganizationIdMaxHandoverStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_HANDOVER_STATE_ASC',
  LawyersByOrganizationIdMaxHandoverStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_HANDOVER_STATE_DESC',
  LawyersByOrganizationIdMaxHubspotDealIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_HUBSPOT_DEAL_ID_ASC',
  LawyersByOrganizationIdMaxHubspotDealIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_HUBSPOT_DEAL_ID_DESC',
  LawyersByOrganizationIdMaxIcpAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ICP_ASC',
  LawyersByOrganizationIdMaxIcpDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ICP_DESC',
  LawyersByOrganizationIdMaxIcpGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ICP_GUESSED_TIME_ASC',
  LawyersByOrganizationIdMaxIcpGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ICP_GUESSED_TIME_DESC',
  LawyersByOrganizationIdMaxIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByOrganizationIdMaxIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByOrganizationIdMaxIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ID_ASC',
  LawyersByOrganizationIdMaxIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ID_DESC',
  LawyersByOrganizationIdMaxIsManagedAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_IS_MANAGED_ASC',
  LawyersByOrganizationIdMaxIsManagedDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_IS_MANAGED_DESC',
  LawyersByOrganizationIdMaxIsPausedAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_IS_PAUSED_ASC',
  LawyersByOrganizationIdMaxIsPausedDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_IS_PAUSED_DESC',
  LawyersByOrganizationIdMaxLastAccountReviewAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByOrganizationIdMaxLastAccountReviewDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByOrganizationIdMaxLastReportTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LAST_REPORT_TIME_ASC',
  LawyersByOrganizationIdMaxLastReportTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LAST_REPORT_TIME_DESC',
  LawyersByOrganizationIdMaxLastStatusEmailTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByOrganizationIdMaxLastStatusEmailTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByOrganizationIdMaxLawyerDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LAWYER_DESCRIPTION_ASC',
  LawyersByOrganizationIdMaxLawyerDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LAWYER_DESCRIPTION_DESC',
  LawyersByOrganizationIdMaxLeadsPerMonthAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LEADS_PER_MONTH_ASC',
  LawyersByOrganizationIdMaxLeadsPerMonthDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LEADS_PER_MONTH_DESC',
  LawyersByOrganizationIdMaxLeverAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LEVER_ASC',
  LawyersByOrganizationIdMaxLeverDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LEVER_DESC',
  LawyersByOrganizationIdMaxLinkedinUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LINKEDIN_URL_ASC',
  LawyersByOrganizationIdMaxLinkedinUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LINKEDIN_URL_DESC',
  LawyersByOrganizationIdMaxLiEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_EMAIL_ASC',
  LawyersByOrganizationIdMaxLiEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_EMAIL_DESC',
  LawyersByOrganizationIdMaxLiEncryptedPwAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_ENCRYPTED_PW_ASC',
  LawyersByOrganizationIdMaxLiEncryptedPwDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_ENCRYPTED_PW_DESC',
  LawyersByOrganizationIdMaxLiLastActiveTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByOrganizationIdMaxLiLastActiveTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByOrganizationIdMaxLiLoginStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_LOGIN_STATUS_ASC',
  LawyersByOrganizationIdMaxLiLoginStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_LOGIN_STATUS_DESC',
  LawyersByOrganizationIdMaxLiProfileNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_PROFILE_NAME_ASC',
  LawyersByOrganizationIdMaxLiProfileNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_PROFILE_NAME_DESC',
  LawyersByOrganizationIdMaxLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByOrganizationIdMaxLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByOrganizationIdMaxLiVerificationCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_VERIFICATION_CODE_ASC',
  LawyersByOrganizationIdMaxLiVerificationCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LI_VERIFICATION_CODE_DESC',
  LawyersByOrganizationIdMaxLogoSrcAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LOGO_SRC_ASC',
  LawyersByOrganizationIdMaxLogoSrcDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_LOGO_SRC_DESC',
  LawyersByOrganizationIdMaxNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_NAME_ASC',
  LawyersByOrganizationIdMaxNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_NAME_DESC',
  LawyersByOrganizationIdMaxNylasAccessTokenAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByOrganizationIdMaxNylasAccessTokenDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByOrganizationIdMaxOnboardingFeePaidTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByOrganizationIdMaxOnboardingFeePaidTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByOrganizationIdMaxOnboardingTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ONBOARDING_TYPE_ASC',
  LawyersByOrganizationIdMaxOnboardingTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ONBOARDING_TYPE_DESC',
  LawyersByOrganizationIdMaxOrganizationIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ORGANIZATION_ID_ASC',
  LawyersByOrganizationIdMaxOrganizationIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_ORGANIZATION_ID_DESC',
  LawyersByOrganizationIdMaxPaymentMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_PAYMENT_METHOD_ASC',
  LawyersByOrganizationIdMaxPaymentMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_PAYMENT_METHOD_DESC',
  LawyersByOrganizationIdMaxPersonalisationRankingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_PERSONALISATION_RANKINGS_ASC',
  LawyersByOrganizationIdMaxPersonalisationRankingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_PERSONALISATION_RANKINGS_DESC',
  LawyersByOrganizationIdMaxPostalCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_POSTAL_CODE_ASC',
  LawyersByOrganizationIdMaxPostalCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_POSTAL_CODE_DESC',
  LawyersByOrganizationIdMaxPriorityAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_PRIORITY_ASC',
  LawyersByOrganizationIdMaxPriorityDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_PRIORITY_DESC',
  LawyersByOrganizationIdMaxProxyIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_PROXY_ID_ASC',
  LawyersByOrganizationIdMaxProxyIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_PROXY_ID_DESC',
  LawyersByOrganizationIdMaxPublicIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_PUBLIC_ID_ASC',
  LawyersByOrganizationIdMaxPublicIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_PUBLIC_ID_DESC',
  LawyersByOrganizationIdMaxReplyNotesAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_REPLY_NOTES_ASC',
  LawyersByOrganizationIdMaxReplyNotesDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_REPLY_NOTES_DESC',
  LawyersByOrganizationIdMaxSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByOrganizationIdMaxSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByOrganizationIdMaxSenderDisclaimerAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_SENDER_DISCLAIMER_ASC',
  LawyersByOrganizationIdMaxSenderDisclaimerDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_SENDER_DISCLAIMER_DESC',
  LawyersByOrganizationIdMaxSignatureHtmlAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_SIGNATURE_HTML_ASC',
  LawyersByOrganizationIdMaxSignatureHtmlDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_SIGNATURE_HTML_DESC',
  LawyersByOrganizationIdMaxSlackChannelNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_SLACK_CHANNEL_NAME_ASC',
  LawyersByOrganizationIdMaxSlackChannelNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_SLACK_CHANNEL_NAME_DESC',
  LawyersByOrganizationIdMaxSlackClientIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_SLACK_CLIENT_ID_ASC',
  LawyersByOrganizationIdMaxSlackClientIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_SLACK_CLIENT_ID_DESC',
  LawyersByOrganizationIdMaxSlackClientSecretAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_SLACK_CLIENT_SECRET_ASC',
  LawyersByOrganizationIdMaxSlackClientSecretDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_SLACK_CLIENT_SECRET_DESC',
  LawyersByOrganizationIdMaxStageAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STAGE_ASC',
  LawyersByOrganizationIdMaxStageDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STAGE_DESC',
  LawyersByOrganizationIdMaxStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STATE_ASC',
  LawyersByOrganizationIdMaxStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STATE_DESC',
  LawyersByOrganizationIdMaxStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STATUS_ASC',
  LawyersByOrganizationIdMaxStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STATUS_DESC',
  LawyersByOrganizationIdMaxStreetAddressAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STREET_ADDRESS_ASC',
  LawyersByOrganizationIdMaxStreetAddressDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STREET_ADDRESS_DESC',
  LawyersByOrganizationIdMaxStripeCustomerIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STRIPE_CUSTOMER_ID_ASC',
  LawyersByOrganizationIdMaxStripeCustomerIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STRIPE_CUSTOMER_ID_DESC',
  LawyersByOrganizationIdMaxStripeDiscountCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByOrganizationIdMaxStripeDiscountCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByOrganizationIdMaxStripePriceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STRIPE_PRICE_ID_ASC',
  LawyersByOrganizationIdMaxStripePriceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_STRIPE_PRICE_ID_DESC',
  LawyersByOrganizationIdMaxTimezoneAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_TIMEZONE_ASC',
  LawyersByOrganizationIdMaxTimezoneDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_TIMEZONE_DESC',
  LawyersByOrganizationIdMaxTitleAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_TITLE_ASC',
  LawyersByOrganizationIdMaxTitleDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_TITLE_DESC',
  LawyersByOrganizationIdMaxUpdatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_UPDATED_TIME_ASC',
  LawyersByOrganizationIdMaxUpdatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_UPDATED_TIME_DESC',
  LawyersByOrganizationIdMaxUserTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_USER_TYPE_ASC',
  LawyersByOrganizationIdMaxUserTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_USER_TYPE_DESC',
  LawyersByOrganizationIdMaxValuePropositionAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_VALUE_PROPOSITION_ASC',
  LawyersByOrganizationIdMaxValuePropositionDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_VALUE_PROPOSITION_DESC',
  LawyersByOrganizationIdMaxValuePropositionGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByOrganizationIdMaxValuePropositionGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByOrganizationIdMaxWantsToChooseDomainsAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByOrganizationIdMaxWantsToChooseDomainsDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByOrganizationIdMaxWebsiteUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_WEBSITE_URL_ASC',
  LawyersByOrganizationIdMaxWebsiteUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_MAX_WEBSITE_URL_DESC',
  LawyersByOrganizationIdMinAcquisitionChannelAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ACQUISITION_CHANNEL_ASC',
  LawyersByOrganizationIdMinAcquisitionChannelDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ACQUISITION_CHANNEL_DESC',
  LawyersByOrganizationIdMinAcquisitionTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ACQUISITION_TYPE_ASC',
  LawyersByOrganizationIdMinAcquisitionTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ACQUISITION_TYPE_DESC',
  LawyersByOrganizationIdMinAliasesAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ALIASES_ASC',
  LawyersByOrganizationIdMinAliasesDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ALIASES_DESC',
  LawyersByOrganizationIdMinBrandColorAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_BRAND_COLOR_ASC',
  LawyersByOrganizationIdMinBrandColorDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_BRAND_COLOR_DESC',
  LawyersByOrganizationIdMinCalendlyLinkAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CALENDLY_LINK_ASC',
  LawyersByOrganizationIdMinCalendlyLinkDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CALENDLY_LINK_DESC',
  LawyersByOrganizationIdMinCalendlyUriAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CALENDLY_URI_ASC',
  LawyersByOrganizationIdMinCalendlyUriDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CALENDLY_URI_DESC',
  LawyersByOrganizationIdMinChargedPerAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CHARGED_PER_ASC',
  LawyersByOrganizationIdMinChargedPerDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CHARGED_PER_DESC',
  LawyersByOrganizationIdMinChargeRateAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CHARGE_RATE_ASC',
  LawyersByOrganizationIdMinChargeRateDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CHARGE_RATE_DESC',
  LawyersByOrganizationIdMinCityAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CITY_ASC',
  LawyersByOrganizationIdMinCityDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CITY_DESC',
  LawyersByOrganizationIdMinClientProfileRequirementsAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByOrganizationIdMinClientProfileRequirementsDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByOrganizationIdMinContactIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CONTACT_ID_ASC',
  LawyersByOrganizationIdMinContactIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CONTACT_ID_DESC',
  LawyersByOrganizationIdMinCountryAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_COUNTRY_ASC',
  LawyersByOrganizationIdMinCountryCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_COUNTRY_CODE_ASC',
  LawyersByOrganizationIdMinCountryCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_COUNTRY_CODE_DESC',
  LawyersByOrganizationIdMinCountryDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_COUNTRY_DESC',
  LawyersByOrganizationIdMinCreatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CREATED_TIME_ASC',
  LawyersByOrganizationIdMinCreatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_CREATED_TIME_DESC',
  LawyersByOrganizationIdMinEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_EMAIL_ASC',
  LawyersByOrganizationIdMinEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_EMAIL_DESC',
  LawyersByOrganizationIdMinEnableAutoTestimonialAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByOrganizationIdMinEnableAutoTestimonialDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByOrganizationIdMinExternalContactsLastUpdatedAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByOrganizationIdMinExternalContactsLastUpdatedDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByOrganizationIdMinFirmDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_FIRM_DESCRIPTION_ASC',
  LawyersByOrganizationIdMinFirmDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_FIRM_DESCRIPTION_DESC',
  LawyersByOrganizationIdMinFirmNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_FIRM_NAME_ASC',
  LawyersByOrganizationIdMinFirmNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_FIRM_NAME_DESC',
  LawyersByOrganizationIdMinFreeLeadsAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_FREE_LEADS_ASC',
  LawyersByOrganizationIdMinFreeLeadsDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_FREE_LEADS_DESC',
  LawyersByOrganizationIdMinGooglePlaceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_GOOGLE_PLACE_ID_ASC',
  LawyersByOrganizationIdMinGooglePlaceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_GOOGLE_PLACE_ID_DESC',
  LawyersByOrganizationIdMinHandoverMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_HANDOVER_METHOD_ASC',
  LawyersByOrganizationIdMinHandoverMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_HANDOVER_METHOD_DESC',
  LawyersByOrganizationIdMinHandoverStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_HANDOVER_STATE_ASC',
  LawyersByOrganizationIdMinHandoverStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_HANDOVER_STATE_DESC',
  LawyersByOrganizationIdMinHubspotDealIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_HUBSPOT_DEAL_ID_ASC',
  LawyersByOrganizationIdMinHubspotDealIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_HUBSPOT_DEAL_ID_DESC',
  LawyersByOrganizationIdMinIcpAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ICP_ASC',
  LawyersByOrganizationIdMinIcpDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ICP_DESC',
  LawyersByOrganizationIdMinIcpGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ICP_GUESSED_TIME_ASC',
  LawyersByOrganizationIdMinIcpGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ICP_GUESSED_TIME_DESC',
  LawyersByOrganizationIdMinIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByOrganizationIdMinIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByOrganizationIdMinIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ID_ASC',
  LawyersByOrganizationIdMinIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ID_DESC',
  LawyersByOrganizationIdMinIsManagedAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_IS_MANAGED_ASC',
  LawyersByOrganizationIdMinIsManagedDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_IS_MANAGED_DESC',
  LawyersByOrganizationIdMinIsPausedAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_IS_PAUSED_ASC',
  LawyersByOrganizationIdMinIsPausedDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_IS_PAUSED_DESC',
  LawyersByOrganizationIdMinLastAccountReviewAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByOrganizationIdMinLastAccountReviewDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByOrganizationIdMinLastReportTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LAST_REPORT_TIME_ASC',
  LawyersByOrganizationIdMinLastReportTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LAST_REPORT_TIME_DESC',
  LawyersByOrganizationIdMinLastStatusEmailTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByOrganizationIdMinLastStatusEmailTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByOrganizationIdMinLawyerDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LAWYER_DESCRIPTION_ASC',
  LawyersByOrganizationIdMinLawyerDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LAWYER_DESCRIPTION_DESC',
  LawyersByOrganizationIdMinLeadsPerMonthAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LEADS_PER_MONTH_ASC',
  LawyersByOrganizationIdMinLeadsPerMonthDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LEADS_PER_MONTH_DESC',
  LawyersByOrganizationIdMinLeverAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LEVER_ASC',
  LawyersByOrganizationIdMinLeverDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LEVER_DESC',
  LawyersByOrganizationIdMinLinkedinUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LINKEDIN_URL_ASC',
  LawyersByOrganizationIdMinLinkedinUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LINKEDIN_URL_DESC',
  LawyersByOrganizationIdMinLiEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_EMAIL_ASC',
  LawyersByOrganizationIdMinLiEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_EMAIL_DESC',
  LawyersByOrganizationIdMinLiEncryptedPwAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_ENCRYPTED_PW_ASC',
  LawyersByOrganizationIdMinLiEncryptedPwDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_ENCRYPTED_PW_DESC',
  LawyersByOrganizationIdMinLiLastActiveTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByOrganizationIdMinLiLastActiveTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByOrganizationIdMinLiLoginStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_LOGIN_STATUS_ASC',
  LawyersByOrganizationIdMinLiLoginStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_LOGIN_STATUS_DESC',
  LawyersByOrganizationIdMinLiProfileNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_PROFILE_NAME_ASC',
  LawyersByOrganizationIdMinLiProfileNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_PROFILE_NAME_DESC',
  LawyersByOrganizationIdMinLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByOrganizationIdMinLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByOrganizationIdMinLiVerificationCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_VERIFICATION_CODE_ASC',
  LawyersByOrganizationIdMinLiVerificationCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LI_VERIFICATION_CODE_DESC',
  LawyersByOrganizationIdMinLogoSrcAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LOGO_SRC_ASC',
  LawyersByOrganizationIdMinLogoSrcDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_LOGO_SRC_DESC',
  LawyersByOrganizationIdMinNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_NAME_ASC',
  LawyersByOrganizationIdMinNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_NAME_DESC',
  LawyersByOrganizationIdMinNylasAccessTokenAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByOrganizationIdMinNylasAccessTokenDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByOrganizationIdMinOnboardingFeePaidTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByOrganizationIdMinOnboardingFeePaidTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByOrganizationIdMinOnboardingTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ONBOARDING_TYPE_ASC',
  LawyersByOrganizationIdMinOnboardingTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ONBOARDING_TYPE_DESC',
  LawyersByOrganizationIdMinOrganizationIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ORGANIZATION_ID_ASC',
  LawyersByOrganizationIdMinOrganizationIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_ORGANIZATION_ID_DESC',
  LawyersByOrganizationIdMinPaymentMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_PAYMENT_METHOD_ASC',
  LawyersByOrganizationIdMinPaymentMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_PAYMENT_METHOD_DESC',
  LawyersByOrganizationIdMinPersonalisationRankingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_PERSONALISATION_RANKINGS_ASC',
  LawyersByOrganizationIdMinPersonalisationRankingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_PERSONALISATION_RANKINGS_DESC',
  LawyersByOrganizationIdMinPostalCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_POSTAL_CODE_ASC',
  LawyersByOrganizationIdMinPostalCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_POSTAL_CODE_DESC',
  LawyersByOrganizationIdMinPriorityAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_PRIORITY_ASC',
  LawyersByOrganizationIdMinPriorityDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_PRIORITY_DESC',
  LawyersByOrganizationIdMinProxyIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_PROXY_ID_ASC',
  LawyersByOrganizationIdMinProxyIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_PROXY_ID_DESC',
  LawyersByOrganizationIdMinPublicIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_PUBLIC_ID_ASC',
  LawyersByOrganizationIdMinPublicIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_PUBLIC_ID_DESC',
  LawyersByOrganizationIdMinReplyNotesAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_REPLY_NOTES_ASC',
  LawyersByOrganizationIdMinReplyNotesDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_REPLY_NOTES_DESC',
  LawyersByOrganizationIdMinSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByOrganizationIdMinSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByOrganizationIdMinSenderDisclaimerAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_SENDER_DISCLAIMER_ASC',
  LawyersByOrganizationIdMinSenderDisclaimerDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_SENDER_DISCLAIMER_DESC',
  LawyersByOrganizationIdMinSignatureHtmlAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_SIGNATURE_HTML_ASC',
  LawyersByOrganizationIdMinSignatureHtmlDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_SIGNATURE_HTML_DESC',
  LawyersByOrganizationIdMinSlackChannelNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_SLACK_CHANNEL_NAME_ASC',
  LawyersByOrganizationIdMinSlackChannelNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_SLACK_CHANNEL_NAME_DESC',
  LawyersByOrganizationIdMinSlackClientIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_SLACK_CLIENT_ID_ASC',
  LawyersByOrganizationIdMinSlackClientIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_SLACK_CLIENT_ID_DESC',
  LawyersByOrganizationIdMinSlackClientSecretAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_SLACK_CLIENT_SECRET_ASC',
  LawyersByOrganizationIdMinSlackClientSecretDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_SLACK_CLIENT_SECRET_DESC',
  LawyersByOrganizationIdMinStageAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STAGE_ASC',
  LawyersByOrganizationIdMinStageDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STAGE_DESC',
  LawyersByOrganizationIdMinStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STATE_ASC',
  LawyersByOrganizationIdMinStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STATE_DESC',
  LawyersByOrganizationIdMinStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STATUS_ASC',
  LawyersByOrganizationIdMinStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STATUS_DESC',
  LawyersByOrganizationIdMinStreetAddressAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STREET_ADDRESS_ASC',
  LawyersByOrganizationIdMinStreetAddressDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STREET_ADDRESS_DESC',
  LawyersByOrganizationIdMinStripeCustomerIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STRIPE_CUSTOMER_ID_ASC',
  LawyersByOrganizationIdMinStripeCustomerIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STRIPE_CUSTOMER_ID_DESC',
  LawyersByOrganizationIdMinStripeDiscountCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByOrganizationIdMinStripeDiscountCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByOrganizationIdMinStripePriceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STRIPE_PRICE_ID_ASC',
  LawyersByOrganizationIdMinStripePriceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_STRIPE_PRICE_ID_DESC',
  LawyersByOrganizationIdMinTimezoneAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_TIMEZONE_ASC',
  LawyersByOrganizationIdMinTimezoneDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_TIMEZONE_DESC',
  LawyersByOrganizationIdMinTitleAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_TITLE_ASC',
  LawyersByOrganizationIdMinTitleDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_TITLE_DESC',
  LawyersByOrganizationIdMinUpdatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_UPDATED_TIME_ASC',
  LawyersByOrganizationIdMinUpdatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_UPDATED_TIME_DESC',
  LawyersByOrganizationIdMinUserTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_USER_TYPE_ASC',
  LawyersByOrganizationIdMinUserTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_USER_TYPE_DESC',
  LawyersByOrganizationIdMinValuePropositionAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_VALUE_PROPOSITION_ASC',
  LawyersByOrganizationIdMinValuePropositionDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_VALUE_PROPOSITION_DESC',
  LawyersByOrganizationIdMinValuePropositionGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByOrganizationIdMinValuePropositionGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByOrganizationIdMinWantsToChooseDomainsAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByOrganizationIdMinWantsToChooseDomainsDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByOrganizationIdMinWebsiteUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_WEBSITE_URL_ASC',
  LawyersByOrganizationIdMinWebsiteUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_MIN_WEBSITE_URL_DESC',
  LawyersByOrganizationIdStddevPopulationAcquisitionChannelAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ACQUISITION_CHANNEL_ASC',
  LawyersByOrganizationIdStddevPopulationAcquisitionChannelDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ACQUISITION_CHANNEL_DESC',
  LawyersByOrganizationIdStddevPopulationAcquisitionTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ACQUISITION_TYPE_ASC',
  LawyersByOrganizationIdStddevPopulationAcquisitionTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ACQUISITION_TYPE_DESC',
  LawyersByOrganizationIdStddevPopulationAliasesAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ALIASES_ASC',
  LawyersByOrganizationIdStddevPopulationAliasesDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ALIASES_DESC',
  LawyersByOrganizationIdStddevPopulationBrandColorAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_BRAND_COLOR_ASC',
  LawyersByOrganizationIdStddevPopulationBrandColorDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_BRAND_COLOR_DESC',
  LawyersByOrganizationIdStddevPopulationCalendlyLinkAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CALENDLY_LINK_ASC',
  LawyersByOrganizationIdStddevPopulationCalendlyLinkDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CALENDLY_LINK_DESC',
  LawyersByOrganizationIdStddevPopulationCalendlyUriAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CALENDLY_URI_ASC',
  LawyersByOrganizationIdStddevPopulationCalendlyUriDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CALENDLY_URI_DESC',
  LawyersByOrganizationIdStddevPopulationChargedPerAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CHARGED_PER_ASC',
  LawyersByOrganizationIdStddevPopulationChargedPerDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CHARGED_PER_DESC',
  LawyersByOrganizationIdStddevPopulationChargeRateAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CHARGE_RATE_ASC',
  LawyersByOrganizationIdStddevPopulationChargeRateDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CHARGE_RATE_DESC',
  LawyersByOrganizationIdStddevPopulationCityAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CITY_ASC',
  LawyersByOrganizationIdStddevPopulationCityDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CITY_DESC',
  LawyersByOrganizationIdStddevPopulationClientProfileRequirementsAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByOrganizationIdStddevPopulationClientProfileRequirementsDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByOrganizationIdStddevPopulationContactIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  LawyersByOrganizationIdStddevPopulationContactIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  LawyersByOrganizationIdStddevPopulationCountryAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_COUNTRY_ASC',
  LawyersByOrganizationIdStddevPopulationCountryCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_COUNTRY_CODE_ASC',
  LawyersByOrganizationIdStddevPopulationCountryCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_COUNTRY_CODE_DESC',
  LawyersByOrganizationIdStddevPopulationCountryDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_COUNTRY_DESC',
  LawyersByOrganizationIdStddevPopulationCreatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  LawyersByOrganizationIdStddevPopulationCreatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  LawyersByOrganizationIdStddevPopulationEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_EMAIL_ASC',
  LawyersByOrganizationIdStddevPopulationEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_EMAIL_DESC',
  LawyersByOrganizationIdStddevPopulationEnableAutoTestimonialAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByOrganizationIdStddevPopulationEnableAutoTestimonialDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByOrganizationIdStddevPopulationExternalContactsLastUpdatedAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByOrganizationIdStddevPopulationExternalContactsLastUpdatedDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByOrganizationIdStddevPopulationFirmDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_FIRM_DESCRIPTION_ASC',
  LawyersByOrganizationIdStddevPopulationFirmDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_FIRM_DESCRIPTION_DESC',
  LawyersByOrganizationIdStddevPopulationFirmNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_FIRM_NAME_ASC',
  LawyersByOrganizationIdStddevPopulationFirmNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_FIRM_NAME_DESC',
  LawyersByOrganizationIdStddevPopulationFreeLeadsAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_FREE_LEADS_ASC',
  LawyersByOrganizationIdStddevPopulationFreeLeadsDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_FREE_LEADS_DESC',
  LawyersByOrganizationIdStddevPopulationGooglePlaceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_GOOGLE_PLACE_ID_ASC',
  LawyersByOrganizationIdStddevPopulationGooglePlaceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_GOOGLE_PLACE_ID_DESC',
  LawyersByOrganizationIdStddevPopulationHandoverMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_HANDOVER_METHOD_ASC',
  LawyersByOrganizationIdStddevPopulationHandoverMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_HANDOVER_METHOD_DESC',
  LawyersByOrganizationIdStddevPopulationHandoverStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_HANDOVER_STATE_ASC',
  LawyersByOrganizationIdStddevPopulationHandoverStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_HANDOVER_STATE_DESC',
  LawyersByOrganizationIdStddevPopulationHubspotDealIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_HUBSPOT_DEAL_ID_ASC',
  LawyersByOrganizationIdStddevPopulationHubspotDealIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_HUBSPOT_DEAL_ID_DESC',
  LawyersByOrganizationIdStddevPopulationIcpAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ICP_ASC',
  LawyersByOrganizationIdStddevPopulationIcpDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ICP_DESC',
  LawyersByOrganizationIdStddevPopulationIcpGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ICP_GUESSED_TIME_ASC',
  LawyersByOrganizationIdStddevPopulationIcpGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ICP_GUESSED_TIME_DESC',
  LawyersByOrganizationIdStddevPopulationIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByOrganizationIdStddevPopulationIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByOrganizationIdStddevPopulationIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ID_ASC',
  LawyersByOrganizationIdStddevPopulationIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ID_DESC',
  LawyersByOrganizationIdStddevPopulationIsManagedAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_IS_MANAGED_ASC',
  LawyersByOrganizationIdStddevPopulationIsManagedDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_IS_MANAGED_DESC',
  LawyersByOrganizationIdStddevPopulationIsPausedAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_IS_PAUSED_ASC',
  LawyersByOrganizationIdStddevPopulationIsPausedDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_IS_PAUSED_DESC',
  LawyersByOrganizationIdStddevPopulationLastAccountReviewAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByOrganizationIdStddevPopulationLastAccountReviewDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByOrganizationIdStddevPopulationLastReportTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LAST_REPORT_TIME_ASC',
  LawyersByOrganizationIdStddevPopulationLastReportTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LAST_REPORT_TIME_DESC',
  LawyersByOrganizationIdStddevPopulationLastStatusEmailTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByOrganizationIdStddevPopulationLastStatusEmailTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByOrganizationIdStddevPopulationLawyerDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LAWYER_DESCRIPTION_ASC',
  LawyersByOrganizationIdStddevPopulationLawyerDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LAWYER_DESCRIPTION_DESC',
  LawyersByOrganizationIdStddevPopulationLeadsPerMonthAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LEADS_PER_MONTH_ASC',
  LawyersByOrganizationIdStddevPopulationLeadsPerMonthDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LEADS_PER_MONTH_DESC',
  LawyersByOrganizationIdStddevPopulationLeverAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LEVER_ASC',
  LawyersByOrganizationIdStddevPopulationLeverDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LEVER_DESC',
  LawyersByOrganizationIdStddevPopulationLinkedinUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LINKEDIN_URL_ASC',
  LawyersByOrganizationIdStddevPopulationLinkedinUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LINKEDIN_URL_DESC',
  LawyersByOrganizationIdStddevPopulationLiEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_EMAIL_ASC',
  LawyersByOrganizationIdStddevPopulationLiEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_EMAIL_DESC',
  LawyersByOrganizationIdStddevPopulationLiEncryptedPwAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_ENCRYPTED_PW_ASC',
  LawyersByOrganizationIdStddevPopulationLiEncryptedPwDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_ENCRYPTED_PW_DESC',
  LawyersByOrganizationIdStddevPopulationLiLastActiveTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByOrganizationIdStddevPopulationLiLastActiveTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByOrganizationIdStddevPopulationLiLoginStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_LOGIN_STATUS_ASC',
  LawyersByOrganizationIdStddevPopulationLiLoginStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_LOGIN_STATUS_DESC',
  LawyersByOrganizationIdStddevPopulationLiProfileNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_PROFILE_NAME_ASC',
  LawyersByOrganizationIdStddevPopulationLiProfileNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_PROFILE_NAME_DESC',
  LawyersByOrganizationIdStddevPopulationLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByOrganizationIdStddevPopulationLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByOrganizationIdStddevPopulationLiVerificationCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_VERIFICATION_CODE_ASC',
  LawyersByOrganizationIdStddevPopulationLiVerificationCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LI_VERIFICATION_CODE_DESC',
  LawyersByOrganizationIdStddevPopulationLogoSrcAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LOGO_SRC_ASC',
  LawyersByOrganizationIdStddevPopulationLogoSrcDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_LOGO_SRC_DESC',
  LawyersByOrganizationIdStddevPopulationNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_NAME_ASC',
  LawyersByOrganizationIdStddevPopulationNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_NAME_DESC',
  LawyersByOrganizationIdStddevPopulationNylasAccessTokenAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByOrganizationIdStddevPopulationNylasAccessTokenDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByOrganizationIdStddevPopulationOnboardingFeePaidTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByOrganizationIdStddevPopulationOnboardingFeePaidTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByOrganizationIdStddevPopulationOnboardingTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ONBOARDING_TYPE_ASC',
  LawyersByOrganizationIdStddevPopulationOnboardingTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ONBOARDING_TYPE_DESC',
  LawyersByOrganizationIdStddevPopulationOrganizationIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ORGANIZATION_ID_ASC',
  LawyersByOrganizationIdStddevPopulationOrganizationIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ORGANIZATION_ID_DESC',
  LawyersByOrganizationIdStddevPopulationPaymentMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_PAYMENT_METHOD_ASC',
  LawyersByOrganizationIdStddevPopulationPaymentMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_PAYMENT_METHOD_DESC',
  LawyersByOrganizationIdStddevPopulationPersonalisationRankingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_PERSONALISATION_RANKINGS_ASC',
  LawyersByOrganizationIdStddevPopulationPersonalisationRankingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_PERSONALISATION_RANKINGS_DESC',
  LawyersByOrganizationIdStddevPopulationPostalCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_POSTAL_CODE_ASC',
  LawyersByOrganizationIdStddevPopulationPostalCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_POSTAL_CODE_DESC',
  LawyersByOrganizationIdStddevPopulationPriorityAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_PRIORITY_ASC',
  LawyersByOrganizationIdStddevPopulationPriorityDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_PRIORITY_DESC',
  LawyersByOrganizationIdStddevPopulationProxyIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_PROXY_ID_ASC',
  LawyersByOrganizationIdStddevPopulationProxyIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_PROXY_ID_DESC',
  LawyersByOrganizationIdStddevPopulationPublicIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_PUBLIC_ID_ASC',
  LawyersByOrganizationIdStddevPopulationPublicIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_PUBLIC_ID_DESC',
  LawyersByOrganizationIdStddevPopulationReplyNotesAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_REPLY_NOTES_ASC',
  LawyersByOrganizationIdStddevPopulationReplyNotesDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_REPLY_NOTES_DESC',
  LawyersByOrganizationIdStddevPopulationSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByOrganizationIdStddevPopulationSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByOrganizationIdStddevPopulationSenderDisclaimerAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_SENDER_DISCLAIMER_ASC',
  LawyersByOrganizationIdStddevPopulationSenderDisclaimerDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_SENDER_DISCLAIMER_DESC',
  LawyersByOrganizationIdStddevPopulationSignatureHtmlAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_SIGNATURE_HTML_ASC',
  LawyersByOrganizationIdStddevPopulationSignatureHtmlDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_SIGNATURE_HTML_DESC',
  LawyersByOrganizationIdStddevPopulationSlackChannelNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_SLACK_CHANNEL_NAME_ASC',
  LawyersByOrganizationIdStddevPopulationSlackChannelNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_SLACK_CHANNEL_NAME_DESC',
  LawyersByOrganizationIdStddevPopulationSlackClientIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_SLACK_CLIENT_ID_ASC',
  LawyersByOrganizationIdStddevPopulationSlackClientIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_SLACK_CLIENT_ID_DESC',
  LawyersByOrganizationIdStddevPopulationSlackClientSecretAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_SLACK_CLIENT_SECRET_ASC',
  LawyersByOrganizationIdStddevPopulationSlackClientSecretDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_SLACK_CLIENT_SECRET_DESC',
  LawyersByOrganizationIdStddevPopulationStageAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STAGE_ASC',
  LawyersByOrganizationIdStddevPopulationStageDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STAGE_DESC',
  LawyersByOrganizationIdStddevPopulationStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STATE_ASC',
  LawyersByOrganizationIdStddevPopulationStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STATE_DESC',
  LawyersByOrganizationIdStddevPopulationStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STATUS_ASC',
  LawyersByOrganizationIdStddevPopulationStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STATUS_DESC',
  LawyersByOrganizationIdStddevPopulationStreetAddressAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STREET_ADDRESS_ASC',
  LawyersByOrganizationIdStddevPopulationStreetAddressDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STREET_ADDRESS_DESC',
  LawyersByOrganizationIdStddevPopulationStripeCustomerIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STRIPE_CUSTOMER_ID_ASC',
  LawyersByOrganizationIdStddevPopulationStripeCustomerIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STRIPE_CUSTOMER_ID_DESC',
  LawyersByOrganizationIdStddevPopulationStripeDiscountCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByOrganizationIdStddevPopulationStripeDiscountCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByOrganizationIdStddevPopulationStripePriceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STRIPE_PRICE_ID_ASC',
  LawyersByOrganizationIdStddevPopulationStripePriceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_STRIPE_PRICE_ID_DESC',
  LawyersByOrganizationIdStddevPopulationTimezoneAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_TIMEZONE_ASC',
  LawyersByOrganizationIdStddevPopulationTimezoneDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_TIMEZONE_DESC',
  LawyersByOrganizationIdStddevPopulationTitleAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_TITLE_ASC',
  LawyersByOrganizationIdStddevPopulationTitleDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_TITLE_DESC',
  LawyersByOrganizationIdStddevPopulationUpdatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  LawyersByOrganizationIdStddevPopulationUpdatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  LawyersByOrganizationIdStddevPopulationUserTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_USER_TYPE_ASC',
  LawyersByOrganizationIdStddevPopulationUserTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_USER_TYPE_DESC',
  LawyersByOrganizationIdStddevPopulationValuePropositionAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_VALUE_PROPOSITION_ASC',
  LawyersByOrganizationIdStddevPopulationValuePropositionDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_VALUE_PROPOSITION_DESC',
  LawyersByOrganizationIdStddevPopulationValuePropositionGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByOrganizationIdStddevPopulationValuePropositionGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByOrganizationIdStddevPopulationWantsToChooseDomainsAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByOrganizationIdStddevPopulationWantsToChooseDomainsDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByOrganizationIdStddevPopulationWebsiteUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_WEBSITE_URL_ASC',
  LawyersByOrganizationIdStddevPopulationWebsiteUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_POPULATION_WEBSITE_URL_DESC',
  LawyersByOrganizationIdStddevSampleAcquisitionChannelAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ACQUISITION_CHANNEL_ASC',
  LawyersByOrganizationIdStddevSampleAcquisitionChannelDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ACQUISITION_CHANNEL_DESC',
  LawyersByOrganizationIdStddevSampleAcquisitionTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ACQUISITION_TYPE_ASC',
  LawyersByOrganizationIdStddevSampleAcquisitionTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ACQUISITION_TYPE_DESC',
  LawyersByOrganizationIdStddevSampleAliasesAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ALIASES_ASC',
  LawyersByOrganizationIdStddevSampleAliasesDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ALIASES_DESC',
  LawyersByOrganizationIdStddevSampleBrandColorAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_BRAND_COLOR_ASC',
  LawyersByOrganizationIdStddevSampleBrandColorDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_BRAND_COLOR_DESC',
  LawyersByOrganizationIdStddevSampleCalendlyLinkAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CALENDLY_LINK_ASC',
  LawyersByOrganizationIdStddevSampleCalendlyLinkDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CALENDLY_LINK_DESC',
  LawyersByOrganizationIdStddevSampleCalendlyUriAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CALENDLY_URI_ASC',
  LawyersByOrganizationIdStddevSampleCalendlyUriDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CALENDLY_URI_DESC',
  LawyersByOrganizationIdStddevSampleChargedPerAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CHARGED_PER_ASC',
  LawyersByOrganizationIdStddevSampleChargedPerDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CHARGED_PER_DESC',
  LawyersByOrganizationIdStddevSampleChargeRateAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CHARGE_RATE_ASC',
  LawyersByOrganizationIdStddevSampleChargeRateDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CHARGE_RATE_DESC',
  LawyersByOrganizationIdStddevSampleCityAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CITY_ASC',
  LawyersByOrganizationIdStddevSampleCityDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CITY_DESC',
  LawyersByOrganizationIdStddevSampleClientProfileRequirementsAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByOrganizationIdStddevSampleClientProfileRequirementsDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByOrganizationIdStddevSampleContactIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  LawyersByOrganizationIdStddevSampleContactIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  LawyersByOrganizationIdStddevSampleCountryAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_COUNTRY_ASC',
  LawyersByOrganizationIdStddevSampleCountryCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_COUNTRY_CODE_ASC',
  LawyersByOrganizationIdStddevSampleCountryCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_COUNTRY_CODE_DESC',
  LawyersByOrganizationIdStddevSampleCountryDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_COUNTRY_DESC',
  LawyersByOrganizationIdStddevSampleCreatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  LawyersByOrganizationIdStddevSampleCreatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  LawyersByOrganizationIdStddevSampleEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_EMAIL_ASC',
  LawyersByOrganizationIdStddevSampleEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_EMAIL_DESC',
  LawyersByOrganizationIdStddevSampleEnableAutoTestimonialAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByOrganizationIdStddevSampleEnableAutoTestimonialDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByOrganizationIdStddevSampleExternalContactsLastUpdatedAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByOrganizationIdStddevSampleExternalContactsLastUpdatedDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByOrganizationIdStddevSampleFirmDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_FIRM_DESCRIPTION_ASC',
  LawyersByOrganizationIdStddevSampleFirmDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_FIRM_DESCRIPTION_DESC',
  LawyersByOrganizationIdStddevSampleFirmNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_FIRM_NAME_ASC',
  LawyersByOrganizationIdStddevSampleFirmNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_FIRM_NAME_DESC',
  LawyersByOrganizationIdStddevSampleFreeLeadsAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_FREE_LEADS_ASC',
  LawyersByOrganizationIdStddevSampleFreeLeadsDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_FREE_LEADS_DESC',
  LawyersByOrganizationIdStddevSampleGooglePlaceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_GOOGLE_PLACE_ID_ASC',
  LawyersByOrganizationIdStddevSampleGooglePlaceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_GOOGLE_PLACE_ID_DESC',
  LawyersByOrganizationIdStddevSampleHandoverMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_HANDOVER_METHOD_ASC',
  LawyersByOrganizationIdStddevSampleHandoverMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_HANDOVER_METHOD_DESC',
  LawyersByOrganizationIdStddevSampleHandoverStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_HANDOVER_STATE_ASC',
  LawyersByOrganizationIdStddevSampleHandoverStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_HANDOVER_STATE_DESC',
  LawyersByOrganizationIdStddevSampleHubspotDealIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_HUBSPOT_DEAL_ID_ASC',
  LawyersByOrganizationIdStddevSampleHubspotDealIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_HUBSPOT_DEAL_ID_DESC',
  LawyersByOrganizationIdStddevSampleIcpAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ICP_ASC',
  LawyersByOrganizationIdStddevSampleIcpDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ICP_DESC',
  LawyersByOrganizationIdStddevSampleIcpGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ICP_GUESSED_TIME_ASC',
  LawyersByOrganizationIdStddevSampleIcpGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ICP_GUESSED_TIME_DESC',
  LawyersByOrganizationIdStddevSampleIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByOrganizationIdStddevSampleIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByOrganizationIdStddevSampleIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ID_ASC',
  LawyersByOrganizationIdStddevSampleIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ID_DESC',
  LawyersByOrganizationIdStddevSampleIsManagedAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_IS_MANAGED_ASC',
  LawyersByOrganizationIdStddevSampleIsManagedDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_IS_MANAGED_DESC',
  LawyersByOrganizationIdStddevSampleIsPausedAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_IS_PAUSED_ASC',
  LawyersByOrganizationIdStddevSampleIsPausedDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_IS_PAUSED_DESC',
  LawyersByOrganizationIdStddevSampleLastAccountReviewAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByOrganizationIdStddevSampleLastAccountReviewDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByOrganizationIdStddevSampleLastReportTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LAST_REPORT_TIME_ASC',
  LawyersByOrganizationIdStddevSampleLastReportTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LAST_REPORT_TIME_DESC',
  LawyersByOrganizationIdStddevSampleLastStatusEmailTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByOrganizationIdStddevSampleLastStatusEmailTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByOrganizationIdStddevSampleLawyerDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LAWYER_DESCRIPTION_ASC',
  LawyersByOrganizationIdStddevSampleLawyerDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LAWYER_DESCRIPTION_DESC',
  LawyersByOrganizationIdStddevSampleLeadsPerMonthAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LEADS_PER_MONTH_ASC',
  LawyersByOrganizationIdStddevSampleLeadsPerMonthDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LEADS_PER_MONTH_DESC',
  LawyersByOrganizationIdStddevSampleLeverAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LEVER_ASC',
  LawyersByOrganizationIdStddevSampleLeverDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LEVER_DESC',
  LawyersByOrganizationIdStddevSampleLinkedinUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LINKEDIN_URL_ASC',
  LawyersByOrganizationIdStddevSampleLinkedinUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LINKEDIN_URL_DESC',
  LawyersByOrganizationIdStddevSampleLiEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_EMAIL_ASC',
  LawyersByOrganizationIdStddevSampleLiEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_EMAIL_DESC',
  LawyersByOrganizationIdStddevSampleLiEncryptedPwAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_ENCRYPTED_PW_ASC',
  LawyersByOrganizationIdStddevSampleLiEncryptedPwDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_ENCRYPTED_PW_DESC',
  LawyersByOrganizationIdStddevSampleLiLastActiveTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByOrganizationIdStddevSampleLiLastActiveTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByOrganizationIdStddevSampleLiLoginStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_LOGIN_STATUS_ASC',
  LawyersByOrganizationIdStddevSampleLiLoginStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_LOGIN_STATUS_DESC',
  LawyersByOrganizationIdStddevSampleLiProfileNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_PROFILE_NAME_ASC',
  LawyersByOrganizationIdStddevSampleLiProfileNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_PROFILE_NAME_DESC',
  LawyersByOrganizationIdStddevSampleLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByOrganizationIdStddevSampleLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByOrganizationIdStddevSampleLiVerificationCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_VERIFICATION_CODE_ASC',
  LawyersByOrganizationIdStddevSampleLiVerificationCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LI_VERIFICATION_CODE_DESC',
  LawyersByOrganizationIdStddevSampleLogoSrcAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LOGO_SRC_ASC',
  LawyersByOrganizationIdStddevSampleLogoSrcDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_LOGO_SRC_DESC',
  LawyersByOrganizationIdStddevSampleNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_NAME_ASC',
  LawyersByOrganizationIdStddevSampleNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_NAME_DESC',
  LawyersByOrganizationIdStddevSampleNylasAccessTokenAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByOrganizationIdStddevSampleNylasAccessTokenDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByOrganizationIdStddevSampleOnboardingFeePaidTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByOrganizationIdStddevSampleOnboardingFeePaidTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByOrganizationIdStddevSampleOnboardingTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ONBOARDING_TYPE_ASC',
  LawyersByOrganizationIdStddevSampleOnboardingTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ONBOARDING_TYPE_DESC',
  LawyersByOrganizationIdStddevSampleOrganizationIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ORGANIZATION_ID_ASC',
  LawyersByOrganizationIdStddevSampleOrganizationIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ORGANIZATION_ID_DESC',
  LawyersByOrganizationIdStddevSamplePaymentMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_PAYMENT_METHOD_ASC',
  LawyersByOrganizationIdStddevSamplePaymentMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_PAYMENT_METHOD_DESC',
  LawyersByOrganizationIdStddevSamplePersonalisationRankingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_PERSONALISATION_RANKINGS_ASC',
  LawyersByOrganizationIdStddevSamplePersonalisationRankingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_PERSONALISATION_RANKINGS_DESC',
  LawyersByOrganizationIdStddevSamplePostalCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_POSTAL_CODE_ASC',
  LawyersByOrganizationIdStddevSamplePostalCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_POSTAL_CODE_DESC',
  LawyersByOrganizationIdStddevSamplePriorityAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_PRIORITY_ASC',
  LawyersByOrganizationIdStddevSamplePriorityDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_PRIORITY_DESC',
  LawyersByOrganizationIdStddevSampleProxyIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_PROXY_ID_ASC',
  LawyersByOrganizationIdStddevSampleProxyIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_PROXY_ID_DESC',
  LawyersByOrganizationIdStddevSamplePublicIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_PUBLIC_ID_ASC',
  LawyersByOrganizationIdStddevSamplePublicIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_PUBLIC_ID_DESC',
  LawyersByOrganizationIdStddevSampleReplyNotesAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_REPLY_NOTES_ASC',
  LawyersByOrganizationIdStddevSampleReplyNotesDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_REPLY_NOTES_DESC',
  LawyersByOrganizationIdStddevSampleSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByOrganizationIdStddevSampleSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByOrganizationIdStddevSampleSenderDisclaimerAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_SENDER_DISCLAIMER_ASC',
  LawyersByOrganizationIdStddevSampleSenderDisclaimerDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_SENDER_DISCLAIMER_DESC',
  LawyersByOrganizationIdStddevSampleSignatureHtmlAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_SIGNATURE_HTML_ASC',
  LawyersByOrganizationIdStddevSampleSignatureHtmlDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_SIGNATURE_HTML_DESC',
  LawyersByOrganizationIdStddevSampleSlackChannelNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_SLACK_CHANNEL_NAME_ASC',
  LawyersByOrganizationIdStddevSampleSlackChannelNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_SLACK_CHANNEL_NAME_DESC',
  LawyersByOrganizationIdStddevSampleSlackClientIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_SLACK_CLIENT_ID_ASC',
  LawyersByOrganizationIdStddevSampleSlackClientIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_SLACK_CLIENT_ID_DESC',
  LawyersByOrganizationIdStddevSampleSlackClientSecretAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_SLACK_CLIENT_SECRET_ASC',
  LawyersByOrganizationIdStddevSampleSlackClientSecretDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_SLACK_CLIENT_SECRET_DESC',
  LawyersByOrganizationIdStddevSampleStageAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STAGE_ASC',
  LawyersByOrganizationIdStddevSampleStageDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STAGE_DESC',
  LawyersByOrganizationIdStddevSampleStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STATE_ASC',
  LawyersByOrganizationIdStddevSampleStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STATE_DESC',
  LawyersByOrganizationIdStddevSampleStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STATUS_ASC',
  LawyersByOrganizationIdStddevSampleStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STATUS_DESC',
  LawyersByOrganizationIdStddevSampleStreetAddressAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STREET_ADDRESS_ASC',
  LawyersByOrganizationIdStddevSampleStreetAddressDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STREET_ADDRESS_DESC',
  LawyersByOrganizationIdStddevSampleStripeCustomerIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STRIPE_CUSTOMER_ID_ASC',
  LawyersByOrganizationIdStddevSampleStripeCustomerIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STRIPE_CUSTOMER_ID_DESC',
  LawyersByOrganizationIdStddevSampleStripeDiscountCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByOrganizationIdStddevSampleStripeDiscountCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByOrganizationIdStddevSampleStripePriceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STRIPE_PRICE_ID_ASC',
  LawyersByOrganizationIdStddevSampleStripePriceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_STRIPE_PRICE_ID_DESC',
  LawyersByOrganizationIdStddevSampleTimezoneAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_TIMEZONE_ASC',
  LawyersByOrganizationIdStddevSampleTimezoneDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_TIMEZONE_DESC',
  LawyersByOrganizationIdStddevSampleTitleAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_TITLE_ASC',
  LawyersByOrganizationIdStddevSampleTitleDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_TITLE_DESC',
  LawyersByOrganizationIdStddevSampleUpdatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  LawyersByOrganizationIdStddevSampleUpdatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  LawyersByOrganizationIdStddevSampleUserTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_USER_TYPE_ASC',
  LawyersByOrganizationIdStddevSampleUserTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_USER_TYPE_DESC',
  LawyersByOrganizationIdStddevSampleValuePropositionAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_VALUE_PROPOSITION_ASC',
  LawyersByOrganizationIdStddevSampleValuePropositionDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_VALUE_PROPOSITION_DESC',
  LawyersByOrganizationIdStddevSampleValuePropositionGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByOrganizationIdStddevSampleValuePropositionGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByOrganizationIdStddevSampleWantsToChooseDomainsAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByOrganizationIdStddevSampleWantsToChooseDomainsDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByOrganizationIdStddevSampleWebsiteUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_WEBSITE_URL_ASC',
  LawyersByOrganizationIdStddevSampleWebsiteUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_WEBSITE_URL_DESC',
  LawyersByOrganizationIdSumAcquisitionChannelAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ACQUISITION_CHANNEL_ASC',
  LawyersByOrganizationIdSumAcquisitionChannelDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ACQUISITION_CHANNEL_DESC',
  LawyersByOrganizationIdSumAcquisitionTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ACQUISITION_TYPE_ASC',
  LawyersByOrganizationIdSumAcquisitionTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ACQUISITION_TYPE_DESC',
  LawyersByOrganizationIdSumAliasesAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ALIASES_ASC',
  LawyersByOrganizationIdSumAliasesDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ALIASES_DESC',
  LawyersByOrganizationIdSumBrandColorAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_BRAND_COLOR_ASC',
  LawyersByOrganizationIdSumBrandColorDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_BRAND_COLOR_DESC',
  LawyersByOrganizationIdSumCalendlyLinkAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CALENDLY_LINK_ASC',
  LawyersByOrganizationIdSumCalendlyLinkDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CALENDLY_LINK_DESC',
  LawyersByOrganizationIdSumCalendlyUriAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CALENDLY_URI_ASC',
  LawyersByOrganizationIdSumCalendlyUriDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CALENDLY_URI_DESC',
  LawyersByOrganizationIdSumChargedPerAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CHARGED_PER_ASC',
  LawyersByOrganizationIdSumChargedPerDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CHARGED_PER_DESC',
  LawyersByOrganizationIdSumChargeRateAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CHARGE_RATE_ASC',
  LawyersByOrganizationIdSumChargeRateDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CHARGE_RATE_DESC',
  LawyersByOrganizationIdSumCityAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CITY_ASC',
  LawyersByOrganizationIdSumCityDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CITY_DESC',
  LawyersByOrganizationIdSumClientProfileRequirementsAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByOrganizationIdSumClientProfileRequirementsDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByOrganizationIdSumContactIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CONTACT_ID_ASC',
  LawyersByOrganizationIdSumContactIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CONTACT_ID_DESC',
  LawyersByOrganizationIdSumCountryAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_COUNTRY_ASC',
  LawyersByOrganizationIdSumCountryCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_COUNTRY_CODE_ASC',
  LawyersByOrganizationIdSumCountryCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_COUNTRY_CODE_DESC',
  LawyersByOrganizationIdSumCountryDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_COUNTRY_DESC',
  LawyersByOrganizationIdSumCreatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CREATED_TIME_ASC',
  LawyersByOrganizationIdSumCreatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_CREATED_TIME_DESC',
  LawyersByOrganizationIdSumEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_EMAIL_ASC',
  LawyersByOrganizationIdSumEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_EMAIL_DESC',
  LawyersByOrganizationIdSumEnableAutoTestimonialAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByOrganizationIdSumEnableAutoTestimonialDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByOrganizationIdSumExternalContactsLastUpdatedAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByOrganizationIdSumExternalContactsLastUpdatedDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByOrganizationIdSumFirmDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_FIRM_DESCRIPTION_ASC',
  LawyersByOrganizationIdSumFirmDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_FIRM_DESCRIPTION_DESC',
  LawyersByOrganizationIdSumFirmNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_FIRM_NAME_ASC',
  LawyersByOrganizationIdSumFirmNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_FIRM_NAME_DESC',
  LawyersByOrganizationIdSumFreeLeadsAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_FREE_LEADS_ASC',
  LawyersByOrganizationIdSumFreeLeadsDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_FREE_LEADS_DESC',
  LawyersByOrganizationIdSumGooglePlaceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_GOOGLE_PLACE_ID_ASC',
  LawyersByOrganizationIdSumGooglePlaceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_GOOGLE_PLACE_ID_DESC',
  LawyersByOrganizationIdSumHandoverMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_HANDOVER_METHOD_ASC',
  LawyersByOrganizationIdSumHandoverMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_HANDOVER_METHOD_DESC',
  LawyersByOrganizationIdSumHandoverStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_HANDOVER_STATE_ASC',
  LawyersByOrganizationIdSumHandoverStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_HANDOVER_STATE_DESC',
  LawyersByOrganizationIdSumHubspotDealIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_HUBSPOT_DEAL_ID_ASC',
  LawyersByOrganizationIdSumHubspotDealIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_HUBSPOT_DEAL_ID_DESC',
  LawyersByOrganizationIdSumIcpAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ICP_ASC',
  LawyersByOrganizationIdSumIcpDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ICP_DESC',
  LawyersByOrganizationIdSumIcpGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ICP_GUESSED_TIME_ASC',
  LawyersByOrganizationIdSumIcpGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ICP_GUESSED_TIME_DESC',
  LawyersByOrganizationIdSumIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByOrganizationIdSumIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByOrganizationIdSumIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ID_ASC',
  LawyersByOrganizationIdSumIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ID_DESC',
  LawyersByOrganizationIdSumIsManagedAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_IS_MANAGED_ASC',
  LawyersByOrganizationIdSumIsManagedDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_IS_MANAGED_DESC',
  LawyersByOrganizationIdSumIsPausedAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_IS_PAUSED_ASC',
  LawyersByOrganizationIdSumIsPausedDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_IS_PAUSED_DESC',
  LawyersByOrganizationIdSumLastAccountReviewAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByOrganizationIdSumLastAccountReviewDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByOrganizationIdSumLastReportTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LAST_REPORT_TIME_ASC',
  LawyersByOrganizationIdSumLastReportTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LAST_REPORT_TIME_DESC',
  LawyersByOrganizationIdSumLastStatusEmailTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByOrganizationIdSumLastStatusEmailTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByOrganizationIdSumLawyerDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LAWYER_DESCRIPTION_ASC',
  LawyersByOrganizationIdSumLawyerDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LAWYER_DESCRIPTION_DESC',
  LawyersByOrganizationIdSumLeadsPerMonthAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LEADS_PER_MONTH_ASC',
  LawyersByOrganizationIdSumLeadsPerMonthDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LEADS_PER_MONTH_DESC',
  LawyersByOrganizationIdSumLeverAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LEVER_ASC',
  LawyersByOrganizationIdSumLeverDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LEVER_DESC',
  LawyersByOrganizationIdSumLinkedinUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LINKEDIN_URL_ASC',
  LawyersByOrganizationIdSumLinkedinUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LINKEDIN_URL_DESC',
  LawyersByOrganizationIdSumLiEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_EMAIL_ASC',
  LawyersByOrganizationIdSumLiEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_EMAIL_DESC',
  LawyersByOrganizationIdSumLiEncryptedPwAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_ENCRYPTED_PW_ASC',
  LawyersByOrganizationIdSumLiEncryptedPwDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_ENCRYPTED_PW_DESC',
  LawyersByOrganizationIdSumLiLastActiveTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByOrganizationIdSumLiLastActiveTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByOrganizationIdSumLiLoginStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_LOGIN_STATUS_ASC',
  LawyersByOrganizationIdSumLiLoginStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_LOGIN_STATUS_DESC',
  LawyersByOrganizationIdSumLiProfileNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_PROFILE_NAME_ASC',
  LawyersByOrganizationIdSumLiProfileNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_PROFILE_NAME_DESC',
  LawyersByOrganizationIdSumLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByOrganizationIdSumLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByOrganizationIdSumLiVerificationCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_VERIFICATION_CODE_ASC',
  LawyersByOrganizationIdSumLiVerificationCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LI_VERIFICATION_CODE_DESC',
  LawyersByOrganizationIdSumLogoSrcAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LOGO_SRC_ASC',
  LawyersByOrganizationIdSumLogoSrcDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_LOGO_SRC_DESC',
  LawyersByOrganizationIdSumNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_NAME_ASC',
  LawyersByOrganizationIdSumNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_NAME_DESC',
  LawyersByOrganizationIdSumNylasAccessTokenAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByOrganizationIdSumNylasAccessTokenDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByOrganizationIdSumOnboardingFeePaidTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByOrganizationIdSumOnboardingFeePaidTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByOrganizationIdSumOnboardingTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ONBOARDING_TYPE_ASC',
  LawyersByOrganizationIdSumOnboardingTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ONBOARDING_TYPE_DESC',
  LawyersByOrganizationIdSumOrganizationIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ORGANIZATION_ID_ASC',
  LawyersByOrganizationIdSumOrganizationIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_ORGANIZATION_ID_DESC',
  LawyersByOrganizationIdSumPaymentMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_PAYMENT_METHOD_ASC',
  LawyersByOrganizationIdSumPaymentMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_PAYMENT_METHOD_DESC',
  LawyersByOrganizationIdSumPersonalisationRankingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_PERSONALISATION_RANKINGS_ASC',
  LawyersByOrganizationIdSumPersonalisationRankingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_PERSONALISATION_RANKINGS_DESC',
  LawyersByOrganizationIdSumPostalCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_POSTAL_CODE_ASC',
  LawyersByOrganizationIdSumPostalCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_POSTAL_CODE_DESC',
  LawyersByOrganizationIdSumPriorityAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_PRIORITY_ASC',
  LawyersByOrganizationIdSumPriorityDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_PRIORITY_DESC',
  LawyersByOrganizationIdSumProxyIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_PROXY_ID_ASC',
  LawyersByOrganizationIdSumProxyIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_PROXY_ID_DESC',
  LawyersByOrganizationIdSumPublicIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_PUBLIC_ID_ASC',
  LawyersByOrganizationIdSumPublicIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_PUBLIC_ID_DESC',
  LawyersByOrganizationIdSumReplyNotesAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_REPLY_NOTES_ASC',
  LawyersByOrganizationIdSumReplyNotesDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_REPLY_NOTES_DESC',
  LawyersByOrganizationIdSumSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByOrganizationIdSumSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByOrganizationIdSumSenderDisclaimerAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_SENDER_DISCLAIMER_ASC',
  LawyersByOrganizationIdSumSenderDisclaimerDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_SENDER_DISCLAIMER_DESC',
  LawyersByOrganizationIdSumSignatureHtmlAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_SIGNATURE_HTML_ASC',
  LawyersByOrganizationIdSumSignatureHtmlDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_SIGNATURE_HTML_DESC',
  LawyersByOrganizationIdSumSlackChannelNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_SLACK_CHANNEL_NAME_ASC',
  LawyersByOrganizationIdSumSlackChannelNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_SLACK_CHANNEL_NAME_DESC',
  LawyersByOrganizationIdSumSlackClientIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_SLACK_CLIENT_ID_ASC',
  LawyersByOrganizationIdSumSlackClientIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_SLACK_CLIENT_ID_DESC',
  LawyersByOrganizationIdSumSlackClientSecretAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_SLACK_CLIENT_SECRET_ASC',
  LawyersByOrganizationIdSumSlackClientSecretDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_SLACK_CLIENT_SECRET_DESC',
  LawyersByOrganizationIdSumStageAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STAGE_ASC',
  LawyersByOrganizationIdSumStageDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STAGE_DESC',
  LawyersByOrganizationIdSumStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STATE_ASC',
  LawyersByOrganizationIdSumStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STATE_DESC',
  LawyersByOrganizationIdSumStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STATUS_ASC',
  LawyersByOrganizationIdSumStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STATUS_DESC',
  LawyersByOrganizationIdSumStreetAddressAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STREET_ADDRESS_ASC',
  LawyersByOrganizationIdSumStreetAddressDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STREET_ADDRESS_DESC',
  LawyersByOrganizationIdSumStripeCustomerIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STRIPE_CUSTOMER_ID_ASC',
  LawyersByOrganizationIdSumStripeCustomerIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STRIPE_CUSTOMER_ID_DESC',
  LawyersByOrganizationIdSumStripeDiscountCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByOrganizationIdSumStripeDiscountCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByOrganizationIdSumStripePriceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STRIPE_PRICE_ID_ASC',
  LawyersByOrganizationIdSumStripePriceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_STRIPE_PRICE_ID_DESC',
  LawyersByOrganizationIdSumTimezoneAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_TIMEZONE_ASC',
  LawyersByOrganizationIdSumTimezoneDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_TIMEZONE_DESC',
  LawyersByOrganizationIdSumTitleAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_TITLE_ASC',
  LawyersByOrganizationIdSumTitleDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_TITLE_DESC',
  LawyersByOrganizationIdSumUpdatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_UPDATED_TIME_ASC',
  LawyersByOrganizationIdSumUpdatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_UPDATED_TIME_DESC',
  LawyersByOrganizationIdSumUserTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_USER_TYPE_ASC',
  LawyersByOrganizationIdSumUserTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_USER_TYPE_DESC',
  LawyersByOrganizationIdSumValuePropositionAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_VALUE_PROPOSITION_ASC',
  LawyersByOrganizationIdSumValuePropositionDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_VALUE_PROPOSITION_DESC',
  LawyersByOrganizationIdSumValuePropositionGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByOrganizationIdSumValuePropositionGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByOrganizationIdSumWantsToChooseDomainsAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByOrganizationIdSumWantsToChooseDomainsDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByOrganizationIdSumWebsiteUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_WEBSITE_URL_ASC',
  LawyersByOrganizationIdSumWebsiteUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_SUM_WEBSITE_URL_DESC',
  LawyersByOrganizationIdVariancePopulationAcquisitionChannelAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ACQUISITION_CHANNEL_ASC',
  LawyersByOrganizationIdVariancePopulationAcquisitionChannelDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ACQUISITION_CHANNEL_DESC',
  LawyersByOrganizationIdVariancePopulationAcquisitionTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ACQUISITION_TYPE_ASC',
  LawyersByOrganizationIdVariancePopulationAcquisitionTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ACQUISITION_TYPE_DESC',
  LawyersByOrganizationIdVariancePopulationAliasesAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ALIASES_ASC',
  LawyersByOrganizationIdVariancePopulationAliasesDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ALIASES_DESC',
  LawyersByOrganizationIdVariancePopulationBrandColorAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_BRAND_COLOR_ASC',
  LawyersByOrganizationIdVariancePopulationBrandColorDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_BRAND_COLOR_DESC',
  LawyersByOrganizationIdVariancePopulationCalendlyLinkAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CALENDLY_LINK_ASC',
  LawyersByOrganizationIdVariancePopulationCalendlyLinkDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CALENDLY_LINK_DESC',
  LawyersByOrganizationIdVariancePopulationCalendlyUriAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CALENDLY_URI_ASC',
  LawyersByOrganizationIdVariancePopulationCalendlyUriDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CALENDLY_URI_DESC',
  LawyersByOrganizationIdVariancePopulationChargedPerAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CHARGED_PER_ASC',
  LawyersByOrganizationIdVariancePopulationChargedPerDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CHARGED_PER_DESC',
  LawyersByOrganizationIdVariancePopulationChargeRateAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CHARGE_RATE_ASC',
  LawyersByOrganizationIdVariancePopulationChargeRateDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CHARGE_RATE_DESC',
  LawyersByOrganizationIdVariancePopulationCityAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CITY_ASC',
  LawyersByOrganizationIdVariancePopulationCityDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CITY_DESC',
  LawyersByOrganizationIdVariancePopulationClientProfileRequirementsAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByOrganizationIdVariancePopulationClientProfileRequirementsDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByOrganizationIdVariancePopulationContactIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  LawyersByOrganizationIdVariancePopulationContactIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  LawyersByOrganizationIdVariancePopulationCountryAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_COUNTRY_ASC',
  LawyersByOrganizationIdVariancePopulationCountryCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_COUNTRY_CODE_ASC',
  LawyersByOrganizationIdVariancePopulationCountryCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_COUNTRY_CODE_DESC',
  LawyersByOrganizationIdVariancePopulationCountryDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_COUNTRY_DESC',
  LawyersByOrganizationIdVariancePopulationCreatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  LawyersByOrganizationIdVariancePopulationCreatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  LawyersByOrganizationIdVariancePopulationEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_EMAIL_ASC',
  LawyersByOrganizationIdVariancePopulationEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_EMAIL_DESC',
  LawyersByOrganizationIdVariancePopulationEnableAutoTestimonialAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByOrganizationIdVariancePopulationEnableAutoTestimonialDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByOrganizationIdVariancePopulationExternalContactsLastUpdatedAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByOrganizationIdVariancePopulationExternalContactsLastUpdatedDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByOrganizationIdVariancePopulationFirmDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_FIRM_DESCRIPTION_ASC',
  LawyersByOrganizationIdVariancePopulationFirmDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_FIRM_DESCRIPTION_DESC',
  LawyersByOrganizationIdVariancePopulationFirmNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_FIRM_NAME_ASC',
  LawyersByOrganizationIdVariancePopulationFirmNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_FIRM_NAME_DESC',
  LawyersByOrganizationIdVariancePopulationFreeLeadsAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_FREE_LEADS_ASC',
  LawyersByOrganizationIdVariancePopulationFreeLeadsDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_FREE_LEADS_DESC',
  LawyersByOrganizationIdVariancePopulationGooglePlaceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_GOOGLE_PLACE_ID_ASC',
  LawyersByOrganizationIdVariancePopulationGooglePlaceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_GOOGLE_PLACE_ID_DESC',
  LawyersByOrganizationIdVariancePopulationHandoverMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_HANDOVER_METHOD_ASC',
  LawyersByOrganizationIdVariancePopulationHandoverMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_HANDOVER_METHOD_DESC',
  LawyersByOrganizationIdVariancePopulationHandoverStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_HANDOVER_STATE_ASC',
  LawyersByOrganizationIdVariancePopulationHandoverStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_HANDOVER_STATE_DESC',
  LawyersByOrganizationIdVariancePopulationHubspotDealIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_HUBSPOT_DEAL_ID_ASC',
  LawyersByOrganizationIdVariancePopulationHubspotDealIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_HUBSPOT_DEAL_ID_DESC',
  LawyersByOrganizationIdVariancePopulationIcpAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ICP_ASC',
  LawyersByOrganizationIdVariancePopulationIcpDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ICP_DESC',
  LawyersByOrganizationIdVariancePopulationIcpGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ICP_GUESSED_TIME_ASC',
  LawyersByOrganizationIdVariancePopulationIcpGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ICP_GUESSED_TIME_DESC',
  LawyersByOrganizationIdVariancePopulationIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByOrganizationIdVariancePopulationIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByOrganizationIdVariancePopulationIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ID_ASC',
  LawyersByOrganizationIdVariancePopulationIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ID_DESC',
  LawyersByOrganizationIdVariancePopulationIsManagedAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_IS_MANAGED_ASC',
  LawyersByOrganizationIdVariancePopulationIsManagedDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_IS_MANAGED_DESC',
  LawyersByOrganizationIdVariancePopulationIsPausedAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_IS_PAUSED_ASC',
  LawyersByOrganizationIdVariancePopulationIsPausedDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_IS_PAUSED_DESC',
  LawyersByOrganizationIdVariancePopulationLastAccountReviewAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByOrganizationIdVariancePopulationLastAccountReviewDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByOrganizationIdVariancePopulationLastReportTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LAST_REPORT_TIME_ASC',
  LawyersByOrganizationIdVariancePopulationLastReportTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LAST_REPORT_TIME_DESC',
  LawyersByOrganizationIdVariancePopulationLastStatusEmailTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByOrganizationIdVariancePopulationLastStatusEmailTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByOrganizationIdVariancePopulationLawyerDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LAWYER_DESCRIPTION_ASC',
  LawyersByOrganizationIdVariancePopulationLawyerDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LAWYER_DESCRIPTION_DESC',
  LawyersByOrganizationIdVariancePopulationLeadsPerMonthAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LEADS_PER_MONTH_ASC',
  LawyersByOrganizationIdVariancePopulationLeadsPerMonthDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LEADS_PER_MONTH_DESC',
  LawyersByOrganizationIdVariancePopulationLeverAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LEVER_ASC',
  LawyersByOrganizationIdVariancePopulationLeverDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LEVER_DESC',
  LawyersByOrganizationIdVariancePopulationLinkedinUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LINKEDIN_URL_ASC',
  LawyersByOrganizationIdVariancePopulationLinkedinUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LINKEDIN_URL_DESC',
  LawyersByOrganizationIdVariancePopulationLiEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_EMAIL_ASC',
  LawyersByOrganizationIdVariancePopulationLiEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_EMAIL_DESC',
  LawyersByOrganizationIdVariancePopulationLiEncryptedPwAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_ENCRYPTED_PW_ASC',
  LawyersByOrganizationIdVariancePopulationLiEncryptedPwDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_ENCRYPTED_PW_DESC',
  LawyersByOrganizationIdVariancePopulationLiLastActiveTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByOrganizationIdVariancePopulationLiLastActiveTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByOrganizationIdVariancePopulationLiLoginStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_LOGIN_STATUS_ASC',
  LawyersByOrganizationIdVariancePopulationLiLoginStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_LOGIN_STATUS_DESC',
  LawyersByOrganizationIdVariancePopulationLiProfileNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_PROFILE_NAME_ASC',
  LawyersByOrganizationIdVariancePopulationLiProfileNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_PROFILE_NAME_DESC',
  LawyersByOrganizationIdVariancePopulationLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByOrganizationIdVariancePopulationLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByOrganizationIdVariancePopulationLiVerificationCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_VERIFICATION_CODE_ASC',
  LawyersByOrganizationIdVariancePopulationLiVerificationCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LI_VERIFICATION_CODE_DESC',
  LawyersByOrganizationIdVariancePopulationLogoSrcAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LOGO_SRC_ASC',
  LawyersByOrganizationIdVariancePopulationLogoSrcDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_LOGO_SRC_DESC',
  LawyersByOrganizationIdVariancePopulationNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_NAME_ASC',
  LawyersByOrganizationIdVariancePopulationNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_NAME_DESC',
  LawyersByOrganizationIdVariancePopulationNylasAccessTokenAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByOrganizationIdVariancePopulationNylasAccessTokenDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByOrganizationIdVariancePopulationOnboardingFeePaidTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByOrganizationIdVariancePopulationOnboardingFeePaidTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByOrganizationIdVariancePopulationOnboardingTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ONBOARDING_TYPE_ASC',
  LawyersByOrganizationIdVariancePopulationOnboardingTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ONBOARDING_TYPE_DESC',
  LawyersByOrganizationIdVariancePopulationOrganizationIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ORGANIZATION_ID_ASC',
  LawyersByOrganizationIdVariancePopulationOrganizationIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ORGANIZATION_ID_DESC',
  LawyersByOrganizationIdVariancePopulationPaymentMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_PAYMENT_METHOD_ASC',
  LawyersByOrganizationIdVariancePopulationPaymentMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_PAYMENT_METHOD_DESC',
  LawyersByOrganizationIdVariancePopulationPersonalisationRankingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_PERSONALISATION_RANKINGS_ASC',
  LawyersByOrganizationIdVariancePopulationPersonalisationRankingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_PERSONALISATION_RANKINGS_DESC',
  LawyersByOrganizationIdVariancePopulationPostalCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_POSTAL_CODE_ASC',
  LawyersByOrganizationIdVariancePopulationPostalCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_POSTAL_CODE_DESC',
  LawyersByOrganizationIdVariancePopulationPriorityAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_PRIORITY_ASC',
  LawyersByOrganizationIdVariancePopulationPriorityDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_PRIORITY_DESC',
  LawyersByOrganizationIdVariancePopulationProxyIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_PROXY_ID_ASC',
  LawyersByOrganizationIdVariancePopulationProxyIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_PROXY_ID_DESC',
  LawyersByOrganizationIdVariancePopulationPublicIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_PUBLIC_ID_ASC',
  LawyersByOrganizationIdVariancePopulationPublicIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_PUBLIC_ID_DESC',
  LawyersByOrganizationIdVariancePopulationReplyNotesAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_REPLY_NOTES_ASC',
  LawyersByOrganizationIdVariancePopulationReplyNotesDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_REPLY_NOTES_DESC',
  LawyersByOrganizationIdVariancePopulationSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByOrganizationIdVariancePopulationSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByOrganizationIdVariancePopulationSenderDisclaimerAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_SENDER_DISCLAIMER_ASC',
  LawyersByOrganizationIdVariancePopulationSenderDisclaimerDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_SENDER_DISCLAIMER_DESC',
  LawyersByOrganizationIdVariancePopulationSignatureHtmlAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_SIGNATURE_HTML_ASC',
  LawyersByOrganizationIdVariancePopulationSignatureHtmlDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_SIGNATURE_HTML_DESC',
  LawyersByOrganizationIdVariancePopulationSlackChannelNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_SLACK_CHANNEL_NAME_ASC',
  LawyersByOrganizationIdVariancePopulationSlackChannelNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_SLACK_CHANNEL_NAME_DESC',
  LawyersByOrganizationIdVariancePopulationSlackClientIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_SLACK_CLIENT_ID_ASC',
  LawyersByOrganizationIdVariancePopulationSlackClientIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_SLACK_CLIENT_ID_DESC',
  LawyersByOrganizationIdVariancePopulationSlackClientSecretAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_SLACK_CLIENT_SECRET_ASC',
  LawyersByOrganizationIdVariancePopulationSlackClientSecretDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_SLACK_CLIENT_SECRET_DESC',
  LawyersByOrganizationIdVariancePopulationStageAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STAGE_ASC',
  LawyersByOrganizationIdVariancePopulationStageDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STAGE_DESC',
  LawyersByOrganizationIdVariancePopulationStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STATE_ASC',
  LawyersByOrganizationIdVariancePopulationStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STATE_DESC',
  LawyersByOrganizationIdVariancePopulationStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STATUS_ASC',
  LawyersByOrganizationIdVariancePopulationStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STATUS_DESC',
  LawyersByOrganizationIdVariancePopulationStreetAddressAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STREET_ADDRESS_ASC',
  LawyersByOrganizationIdVariancePopulationStreetAddressDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STREET_ADDRESS_DESC',
  LawyersByOrganizationIdVariancePopulationStripeCustomerIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STRIPE_CUSTOMER_ID_ASC',
  LawyersByOrganizationIdVariancePopulationStripeCustomerIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STRIPE_CUSTOMER_ID_DESC',
  LawyersByOrganizationIdVariancePopulationStripeDiscountCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByOrganizationIdVariancePopulationStripeDiscountCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByOrganizationIdVariancePopulationStripePriceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STRIPE_PRICE_ID_ASC',
  LawyersByOrganizationIdVariancePopulationStripePriceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_STRIPE_PRICE_ID_DESC',
  LawyersByOrganizationIdVariancePopulationTimezoneAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_TIMEZONE_ASC',
  LawyersByOrganizationIdVariancePopulationTimezoneDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_TIMEZONE_DESC',
  LawyersByOrganizationIdVariancePopulationTitleAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_TITLE_ASC',
  LawyersByOrganizationIdVariancePopulationTitleDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_TITLE_DESC',
  LawyersByOrganizationIdVariancePopulationUpdatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  LawyersByOrganizationIdVariancePopulationUpdatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  LawyersByOrganizationIdVariancePopulationUserTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_USER_TYPE_ASC',
  LawyersByOrganizationIdVariancePopulationUserTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_USER_TYPE_DESC',
  LawyersByOrganizationIdVariancePopulationValuePropositionAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_VALUE_PROPOSITION_ASC',
  LawyersByOrganizationIdVariancePopulationValuePropositionDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_VALUE_PROPOSITION_DESC',
  LawyersByOrganizationIdVariancePopulationValuePropositionGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByOrganizationIdVariancePopulationValuePropositionGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByOrganizationIdVariancePopulationWantsToChooseDomainsAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByOrganizationIdVariancePopulationWantsToChooseDomainsDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByOrganizationIdVariancePopulationWebsiteUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_WEBSITE_URL_ASC',
  LawyersByOrganizationIdVariancePopulationWebsiteUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_WEBSITE_URL_DESC',
  LawyersByOrganizationIdVarianceSampleAcquisitionChannelAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ACQUISITION_CHANNEL_ASC',
  LawyersByOrganizationIdVarianceSampleAcquisitionChannelDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ACQUISITION_CHANNEL_DESC',
  LawyersByOrganizationIdVarianceSampleAcquisitionTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ACQUISITION_TYPE_ASC',
  LawyersByOrganizationIdVarianceSampleAcquisitionTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ACQUISITION_TYPE_DESC',
  LawyersByOrganizationIdVarianceSampleAliasesAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ALIASES_ASC',
  LawyersByOrganizationIdVarianceSampleAliasesDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ALIASES_DESC',
  LawyersByOrganizationIdVarianceSampleBrandColorAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_BRAND_COLOR_ASC',
  LawyersByOrganizationIdVarianceSampleBrandColorDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_BRAND_COLOR_DESC',
  LawyersByOrganizationIdVarianceSampleCalendlyLinkAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CALENDLY_LINK_ASC',
  LawyersByOrganizationIdVarianceSampleCalendlyLinkDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CALENDLY_LINK_DESC',
  LawyersByOrganizationIdVarianceSampleCalendlyUriAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CALENDLY_URI_ASC',
  LawyersByOrganizationIdVarianceSampleCalendlyUriDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CALENDLY_URI_DESC',
  LawyersByOrganizationIdVarianceSampleChargedPerAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CHARGED_PER_ASC',
  LawyersByOrganizationIdVarianceSampleChargedPerDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CHARGED_PER_DESC',
  LawyersByOrganizationIdVarianceSampleChargeRateAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CHARGE_RATE_ASC',
  LawyersByOrganizationIdVarianceSampleChargeRateDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CHARGE_RATE_DESC',
  LawyersByOrganizationIdVarianceSampleCityAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CITY_ASC',
  LawyersByOrganizationIdVarianceSampleCityDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CITY_DESC',
  LawyersByOrganizationIdVarianceSampleClientProfileRequirementsAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CLIENT_PROFILE_REQUIREMENTS_ASC',
  LawyersByOrganizationIdVarianceSampleClientProfileRequirementsDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CLIENT_PROFILE_REQUIREMENTS_DESC',
  LawyersByOrganizationIdVarianceSampleContactIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  LawyersByOrganizationIdVarianceSampleContactIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  LawyersByOrganizationIdVarianceSampleCountryAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_COUNTRY_ASC',
  LawyersByOrganizationIdVarianceSampleCountryCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_COUNTRY_CODE_ASC',
  LawyersByOrganizationIdVarianceSampleCountryCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_COUNTRY_CODE_DESC',
  LawyersByOrganizationIdVarianceSampleCountryDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_COUNTRY_DESC',
  LawyersByOrganizationIdVarianceSampleCreatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  LawyersByOrganizationIdVarianceSampleCreatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  LawyersByOrganizationIdVarianceSampleEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_EMAIL_ASC',
  LawyersByOrganizationIdVarianceSampleEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_EMAIL_DESC',
  LawyersByOrganizationIdVarianceSampleEnableAutoTestimonialAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ENABLE_AUTO_TESTIMONIAL_ASC',
  LawyersByOrganizationIdVarianceSampleEnableAutoTestimonialDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ENABLE_AUTO_TESTIMONIAL_DESC',
  LawyersByOrganizationIdVarianceSampleExternalContactsLastUpdatedAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_EXTERNAL_CONTACTS_LAST_UPDATED_ASC',
  LawyersByOrganizationIdVarianceSampleExternalContactsLastUpdatedDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_EXTERNAL_CONTACTS_LAST_UPDATED_DESC',
  LawyersByOrganizationIdVarianceSampleFirmDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_FIRM_DESCRIPTION_ASC',
  LawyersByOrganizationIdVarianceSampleFirmDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_FIRM_DESCRIPTION_DESC',
  LawyersByOrganizationIdVarianceSampleFirmNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_FIRM_NAME_ASC',
  LawyersByOrganizationIdVarianceSampleFirmNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_FIRM_NAME_DESC',
  LawyersByOrganizationIdVarianceSampleFreeLeadsAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_FREE_LEADS_ASC',
  LawyersByOrganizationIdVarianceSampleFreeLeadsDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_FREE_LEADS_DESC',
  LawyersByOrganizationIdVarianceSampleGooglePlaceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_GOOGLE_PLACE_ID_ASC',
  LawyersByOrganizationIdVarianceSampleGooglePlaceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_GOOGLE_PLACE_ID_DESC',
  LawyersByOrganizationIdVarianceSampleHandoverMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_HANDOVER_METHOD_ASC',
  LawyersByOrganizationIdVarianceSampleHandoverMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_HANDOVER_METHOD_DESC',
  LawyersByOrganizationIdVarianceSampleHandoverStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_HANDOVER_STATE_ASC',
  LawyersByOrganizationIdVarianceSampleHandoverStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_HANDOVER_STATE_DESC',
  LawyersByOrganizationIdVarianceSampleHubspotDealIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_HUBSPOT_DEAL_ID_ASC',
  LawyersByOrganizationIdVarianceSampleHubspotDealIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_HUBSPOT_DEAL_ID_DESC',
  LawyersByOrganizationIdVarianceSampleIcpAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ICP_ASC',
  LawyersByOrganizationIdVarianceSampleIcpDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ICP_DESC',
  LawyersByOrganizationIdVarianceSampleIcpGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ICP_GUESSED_TIME_ASC',
  LawyersByOrganizationIdVarianceSampleIcpGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ICP_GUESSED_TIME_DESC',
  LawyersByOrganizationIdVarianceSampleIdealNumWeeklyMeetingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_IDEAL_NUM_WEEKLY_MEETINGS_ASC',
  LawyersByOrganizationIdVarianceSampleIdealNumWeeklyMeetingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_IDEAL_NUM_WEEKLY_MEETINGS_DESC',
  LawyersByOrganizationIdVarianceSampleIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ID_ASC',
  LawyersByOrganizationIdVarianceSampleIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ID_DESC',
  LawyersByOrganizationIdVarianceSampleIsManagedAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_IS_MANAGED_ASC',
  LawyersByOrganizationIdVarianceSampleIsManagedDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_IS_MANAGED_DESC',
  LawyersByOrganizationIdVarianceSampleIsPausedAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_IS_PAUSED_ASC',
  LawyersByOrganizationIdVarianceSampleIsPausedDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_IS_PAUSED_DESC',
  LawyersByOrganizationIdVarianceSampleLastAccountReviewAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LAST_ACCOUNT_REVIEW_ASC',
  LawyersByOrganizationIdVarianceSampleLastAccountReviewDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LAST_ACCOUNT_REVIEW_DESC',
  LawyersByOrganizationIdVarianceSampleLastReportTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LAST_REPORT_TIME_ASC',
  LawyersByOrganizationIdVarianceSampleLastReportTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LAST_REPORT_TIME_DESC',
  LawyersByOrganizationIdVarianceSampleLastStatusEmailTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LAST_STATUS_EMAIL_TIME_ASC',
  LawyersByOrganizationIdVarianceSampleLastStatusEmailTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LAST_STATUS_EMAIL_TIME_DESC',
  LawyersByOrganizationIdVarianceSampleLawyerDescriptionAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LAWYER_DESCRIPTION_ASC',
  LawyersByOrganizationIdVarianceSampleLawyerDescriptionDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LAWYER_DESCRIPTION_DESC',
  LawyersByOrganizationIdVarianceSampleLeadsPerMonthAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LEADS_PER_MONTH_ASC',
  LawyersByOrganizationIdVarianceSampleLeadsPerMonthDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LEADS_PER_MONTH_DESC',
  LawyersByOrganizationIdVarianceSampleLeverAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LEVER_ASC',
  LawyersByOrganizationIdVarianceSampleLeverDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LEVER_DESC',
  LawyersByOrganizationIdVarianceSampleLinkedinUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LINKEDIN_URL_ASC',
  LawyersByOrganizationIdVarianceSampleLinkedinUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LINKEDIN_URL_DESC',
  LawyersByOrganizationIdVarianceSampleLiEmailAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_EMAIL_ASC',
  LawyersByOrganizationIdVarianceSampleLiEmailDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_EMAIL_DESC',
  LawyersByOrganizationIdVarianceSampleLiEncryptedPwAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_ENCRYPTED_PW_ASC',
  LawyersByOrganizationIdVarianceSampleLiEncryptedPwDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_ENCRYPTED_PW_DESC',
  LawyersByOrganizationIdVarianceSampleLiLastActiveTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_LAST_ACTIVE_TIME_ASC',
  LawyersByOrganizationIdVarianceSampleLiLastActiveTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_LAST_ACTIVE_TIME_DESC',
  LawyersByOrganizationIdVarianceSampleLiLoginStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_LOGIN_STATUS_ASC',
  LawyersByOrganizationIdVarianceSampleLiLoginStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_LOGIN_STATUS_DESC',
  LawyersByOrganizationIdVarianceSampleLiProfileNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_PROFILE_NAME_ASC',
  LawyersByOrganizationIdVarianceSampleLiProfileNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_PROFILE_NAME_DESC',
  LawyersByOrganizationIdVarianceSampleLiStopConnectionReqsUntilAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_STOP_CONNECTION_REQS_UNTIL_ASC',
  LawyersByOrganizationIdVarianceSampleLiStopConnectionReqsUntilDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_STOP_CONNECTION_REQS_UNTIL_DESC',
  LawyersByOrganizationIdVarianceSampleLiVerificationCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_VERIFICATION_CODE_ASC',
  LawyersByOrganizationIdVarianceSampleLiVerificationCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LI_VERIFICATION_CODE_DESC',
  LawyersByOrganizationIdVarianceSampleLogoSrcAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LOGO_SRC_ASC',
  LawyersByOrganizationIdVarianceSampleLogoSrcDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_LOGO_SRC_DESC',
  LawyersByOrganizationIdVarianceSampleNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_NAME_ASC',
  LawyersByOrganizationIdVarianceSampleNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_NAME_DESC',
  LawyersByOrganizationIdVarianceSampleNylasAccessTokenAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_NYLAS_ACCESS_TOKEN_ASC',
  LawyersByOrganizationIdVarianceSampleNylasAccessTokenDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_NYLAS_ACCESS_TOKEN_DESC',
  LawyersByOrganizationIdVarianceSampleOnboardingFeePaidTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ONBOARDING_FEE_PAID_TIME_ASC',
  LawyersByOrganizationIdVarianceSampleOnboardingFeePaidTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ONBOARDING_FEE_PAID_TIME_DESC',
  LawyersByOrganizationIdVarianceSampleOnboardingTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ONBOARDING_TYPE_ASC',
  LawyersByOrganizationIdVarianceSampleOnboardingTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ONBOARDING_TYPE_DESC',
  LawyersByOrganizationIdVarianceSampleOrganizationIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ORGANIZATION_ID_ASC',
  LawyersByOrganizationIdVarianceSampleOrganizationIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ORGANIZATION_ID_DESC',
  LawyersByOrganizationIdVarianceSamplePaymentMethodAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_PAYMENT_METHOD_ASC',
  LawyersByOrganizationIdVarianceSamplePaymentMethodDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_PAYMENT_METHOD_DESC',
  LawyersByOrganizationIdVarianceSamplePersonalisationRankingsAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_PERSONALISATION_RANKINGS_ASC',
  LawyersByOrganizationIdVarianceSamplePersonalisationRankingsDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_PERSONALISATION_RANKINGS_DESC',
  LawyersByOrganizationIdVarianceSamplePostalCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_POSTAL_CODE_ASC',
  LawyersByOrganizationIdVarianceSamplePostalCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_POSTAL_CODE_DESC',
  LawyersByOrganizationIdVarianceSamplePriorityAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_PRIORITY_ASC',
  LawyersByOrganizationIdVarianceSamplePriorityDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_PRIORITY_DESC',
  LawyersByOrganizationIdVarianceSampleProxyIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_PROXY_ID_ASC',
  LawyersByOrganizationIdVarianceSampleProxyIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_PROXY_ID_DESC',
  LawyersByOrganizationIdVarianceSamplePublicIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_PUBLIC_ID_ASC',
  LawyersByOrganizationIdVarianceSamplePublicIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_PUBLIC_ID_DESC',
  LawyersByOrganizationIdVarianceSampleReplyNotesAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_REPLY_NOTES_ASC',
  LawyersByOrganizationIdVarianceSampleReplyNotesDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_REPLY_NOTES_DESC',
  LawyersByOrganizationIdVarianceSampleSalesFunnelSubscriberStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_SALES_FUNNEL_SUBSCRIBER_STATUS_ASC',
  LawyersByOrganizationIdVarianceSampleSalesFunnelSubscriberStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_SALES_FUNNEL_SUBSCRIBER_STATUS_DESC',
  LawyersByOrganizationIdVarianceSampleSenderDisclaimerAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_SENDER_DISCLAIMER_ASC',
  LawyersByOrganizationIdVarianceSampleSenderDisclaimerDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_SENDER_DISCLAIMER_DESC',
  LawyersByOrganizationIdVarianceSampleSignatureHtmlAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_SIGNATURE_HTML_ASC',
  LawyersByOrganizationIdVarianceSampleSignatureHtmlDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_SIGNATURE_HTML_DESC',
  LawyersByOrganizationIdVarianceSampleSlackChannelNameAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_SLACK_CHANNEL_NAME_ASC',
  LawyersByOrganizationIdVarianceSampleSlackChannelNameDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_SLACK_CHANNEL_NAME_DESC',
  LawyersByOrganizationIdVarianceSampleSlackClientIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_SLACK_CLIENT_ID_ASC',
  LawyersByOrganizationIdVarianceSampleSlackClientIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_SLACK_CLIENT_ID_DESC',
  LawyersByOrganizationIdVarianceSampleSlackClientSecretAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_SLACK_CLIENT_SECRET_ASC',
  LawyersByOrganizationIdVarianceSampleSlackClientSecretDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_SLACK_CLIENT_SECRET_DESC',
  LawyersByOrganizationIdVarianceSampleStageAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STAGE_ASC',
  LawyersByOrganizationIdVarianceSampleStageDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STAGE_DESC',
  LawyersByOrganizationIdVarianceSampleStateAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STATE_ASC',
  LawyersByOrganizationIdVarianceSampleStateDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STATE_DESC',
  LawyersByOrganizationIdVarianceSampleStatusAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STATUS_ASC',
  LawyersByOrganizationIdVarianceSampleStatusDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STATUS_DESC',
  LawyersByOrganizationIdVarianceSampleStreetAddressAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STREET_ADDRESS_ASC',
  LawyersByOrganizationIdVarianceSampleStreetAddressDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STREET_ADDRESS_DESC',
  LawyersByOrganizationIdVarianceSampleStripeCustomerIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STRIPE_CUSTOMER_ID_ASC',
  LawyersByOrganizationIdVarianceSampleStripeCustomerIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STRIPE_CUSTOMER_ID_DESC',
  LawyersByOrganizationIdVarianceSampleStripeDiscountCodeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STRIPE_DISCOUNT_CODE_ASC',
  LawyersByOrganizationIdVarianceSampleStripeDiscountCodeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STRIPE_DISCOUNT_CODE_DESC',
  LawyersByOrganizationIdVarianceSampleStripePriceIdAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STRIPE_PRICE_ID_ASC',
  LawyersByOrganizationIdVarianceSampleStripePriceIdDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_STRIPE_PRICE_ID_DESC',
  LawyersByOrganizationIdVarianceSampleTimezoneAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_TIMEZONE_ASC',
  LawyersByOrganizationIdVarianceSampleTimezoneDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_TIMEZONE_DESC',
  LawyersByOrganizationIdVarianceSampleTitleAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_TITLE_ASC',
  LawyersByOrganizationIdVarianceSampleTitleDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_TITLE_DESC',
  LawyersByOrganizationIdVarianceSampleUpdatedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  LawyersByOrganizationIdVarianceSampleUpdatedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  LawyersByOrganizationIdVarianceSampleUserTypeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_USER_TYPE_ASC',
  LawyersByOrganizationIdVarianceSampleUserTypeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_USER_TYPE_DESC',
  LawyersByOrganizationIdVarianceSampleValuePropositionAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_VALUE_PROPOSITION_ASC',
  LawyersByOrganizationIdVarianceSampleValuePropositionDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_VALUE_PROPOSITION_DESC',
  LawyersByOrganizationIdVarianceSampleValuePropositionGuessedTimeAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_VALUE_PROPOSITION_GUESSED_TIME_ASC',
  LawyersByOrganizationIdVarianceSampleValuePropositionGuessedTimeDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_VALUE_PROPOSITION_GUESSED_TIME_DESC',
  LawyersByOrganizationIdVarianceSampleWantsToChooseDomainsAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_WANTS_TO_CHOOSE_DOMAINS_ASC',
  LawyersByOrganizationIdVarianceSampleWantsToChooseDomainsDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_WANTS_TO_CHOOSE_DOMAINS_DESC',
  LawyersByOrganizationIdVarianceSampleWebsiteUrlAsc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_WEBSITE_URL_ASC',
  LawyersByOrganizationIdVarianceSampleWebsiteUrlDesc = 'LAWYERS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_WEBSITE_URL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum OutreachTemplateStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

/** A filter to be used against OutreachTemplateStatus fields. All fields are combined with a logical ‘and.’ */
export type OutreachTemplateStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<OutreachTemplateStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<OutreachTemplateStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<OutreachTemplateStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<OutreachTemplateStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<OutreachTemplateStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<OutreachTemplateStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<OutreachTemplateStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<OutreachTemplateStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<OutreachTemplateStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<OutreachTemplateStatus>>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

export enum PaymentMethod {
  Other = 'OTHER',
  Stripe = 'STRIPE',
  StripePayg = 'STRIPE_PAYG'
}

/** A filter to be used against PaymentMethod fields. All fields are combined with a logical ‘and.’ */
export type PaymentMethodFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<PaymentMethod>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<PaymentMethod>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<PaymentMethod>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<PaymentMethod>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<PaymentMethod>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<PaymentMethod>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<PaymentMethod>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<PaymentMethod>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<PaymentMethod>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<PaymentMethod>>;
};

export type Personalisation = Node & {
  __typename?: 'Personalisation';
  /** Reads a single `Contact` that is related to this `Personalisation`. */
  contactByContactId?: Maybe<Contact>;
  contactId: Scalars['UUID']['output'];
  dateValue?: Maybe<Scalars['Datetime']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  intValue?: Maybe<Scalars['BigInt']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `PersonalisationType` that is related to this `Personalisation`. */
  personalisationTypeByTypeId?: Maybe<PersonalisationType>;
  strValue?: Maybe<Scalars['String']['output']>;
  typeId: Scalars['UUID']['output'];
};

export type PersonalisationAggregates = {
  __typename?: 'PersonalisationAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<PersonalisationAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<PersonalisationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<PersonalisationMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<PersonalisationMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<PersonalisationStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<PersonalisationStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<PersonalisationSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<PersonalisationVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<PersonalisationVarianceSampleAggregates>;
};

export type PersonalisationAverageAggregates = {
  __typename?: 'PersonalisationAverageAggregates';
  /** Mean average of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `Personalisation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PersonalisationCondition = {
  /** Checks for equality with the object’s `contactId` field. */
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `dateValue` field. */
  dateValue?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `intValue` field. */
  intValue?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `strValue` field. */
  strValue?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `typeId` field. */
  typeId?: InputMaybe<Scalars['UUID']['input']>;
};

export type PersonalisationDistinctCountAggregates = {
  __typename?: 'PersonalisationDistinctCountAggregates';
  /** Distinct count of contactId across the matching connection */
  contactId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dateValue across the matching connection */
  dateValue?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of strValue across the matching connection */
  strValue?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of typeId across the matching connection */
  typeId?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Personalisation` object types. All fields are combined with a logical ‘and.’ */
export type PersonalisationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PersonalisationFilter>>;
  /** Filter by the object’s `contactId` field. */
  contactId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `dateValue` field. */
  dateValue?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `intValue` field. */
  intValue?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PersonalisationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PersonalisationFilter>>;
  /** Filter by the object’s `strValue` field. */
  strValue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `typeId` field. */
  typeId?: InputMaybe<UuidFilter>;
};

/** Grouping methods for `Personalisation` for usage during aggregation. */
export enum PersonalisationGroupBy {
  ContactId = 'CONTACT_ID',
  DateValue = 'DATE_VALUE',
  DateValueTruncatedToDay = 'DATE_VALUE_TRUNCATED_TO_DAY',
  DateValueTruncatedToHour = 'DATE_VALUE_TRUNCATED_TO_HOUR',
  IntValue = 'INT_VALUE',
  StrValue = 'STR_VALUE',
  TypeId = 'TYPE_ID'
}

export type PersonalisationHavingAverageInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type PersonalisationHavingDistinctCountInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `Personalisation` aggregates. */
export type PersonalisationHavingInput = {
  AND?: InputMaybe<Array<PersonalisationHavingInput>>;
  OR?: InputMaybe<Array<PersonalisationHavingInput>>;
  average?: InputMaybe<PersonalisationHavingAverageInput>;
  distinctCount?: InputMaybe<PersonalisationHavingDistinctCountInput>;
  max?: InputMaybe<PersonalisationHavingMaxInput>;
  min?: InputMaybe<PersonalisationHavingMinInput>;
  stddevPopulation?: InputMaybe<PersonalisationHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<PersonalisationHavingStddevSampleInput>;
  sum?: InputMaybe<PersonalisationHavingSumInput>;
  variancePopulation?: InputMaybe<PersonalisationHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<PersonalisationHavingVarianceSampleInput>;
};

export type PersonalisationHavingMaxInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type PersonalisationHavingMinInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type PersonalisationHavingStddevPopulationInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type PersonalisationHavingStddevSampleInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type PersonalisationHavingSumInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type PersonalisationHavingVariancePopulationInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type PersonalisationHavingVarianceSampleInput = {
  dateValue?: InputMaybe<HavingDatetimeFilter>;
  intValue?: InputMaybe<HavingBigintFilter>;
};

export type PersonalisationMaxAggregates = {
  __typename?: 'PersonalisationMaxAggregates';
  /** Maximum of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigInt']['output']>;
};

export type PersonalisationMinAggregates = {
  __typename?: 'PersonalisationMinAggregates';
  /** Minimum of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigInt']['output']>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type PersonalisationSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: PersonalisationSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type PersonalisationSortBy = {
  field?: InputMaybe<PersonalisationSortableField>;
};

/** Sortable concrete fields for the `Personalisation` type. */
export enum PersonalisationSortableField {
  ContactId = 'CONTACT_ID',
  DateValue = 'DATE_VALUE',
  Id = 'ID',
  IntValue = 'INT_VALUE',
  StrValue = 'STR_VALUE',
  TypeId = 'TYPE_ID'
}

export type PersonalisationStatistic = Node & {
  __typename?: 'PersonalisationStatistic';
  /** Reads a single `Campaign` that is related to this `PersonalisationStatistic`. */
  campaignByCampaignId?: Maybe<Campaign>;
  campaignId?: Maybe<Scalars['UUID']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Lawyer` that is related to this `PersonalisationStatistic`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  persValue: Scalars['String']['output'];
  /** Reads a single `PersonalisationType` that is related to this `PersonalisationStatistic`. */
  personalisationTypeByTypeId?: Maybe<PersonalisationType>;
  typeId: Scalars['UUID']['output'];
  value: Scalars['Int']['output'];
};

export type PersonalisationStatisticAggregates = {
  __typename?: 'PersonalisationStatisticAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<PersonalisationStatisticAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<PersonalisationStatisticDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<PersonalisationStatisticMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<PersonalisationStatisticMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<PersonalisationStatisticStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<PersonalisationStatisticStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<PersonalisationStatisticSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<PersonalisationStatisticVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<PersonalisationStatisticVarianceSampleAggregates>;
};

export type PersonalisationStatisticAverageAggregates = {
  __typename?: 'PersonalisationStatisticAverageAggregates';
  /** Mean average of value across the matching connection */
  value?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `PersonalisationStatistic` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type PersonalisationStatisticCondition = {
  /** Checks for equality with the object’s `campaignId` field. */
  campaignId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `persValue` field. */
  persValue?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `typeId` field. */
  typeId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['Int']['input']>;
};

export type PersonalisationStatisticDistinctCountAggregates = {
  __typename?: 'PersonalisationStatisticDistinctCountAggregates';
  /** Distinct count of campaignId across the matching connection */
  campaignId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of persValue across the matching connection */
  persValue?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of typeId across the matching connection */
  typeId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `PersonalisationStatistic` object types. All fields are combined with a logical ‘and.’ */
export type PersonalisationStatisticFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PersonalisationStatisticFilter>>;
  /** Filter by the object’s `campaignId` field. */
  campaignId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PersonalisationStatisticFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PersonalisationStatisticFilter>>;
  /** Filter by the object’s `persValue` field. */
  persValue?: InputMaybe<StringFilter>;
  /** Filter by the object’s `typeId` field. */
  typeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<IntFilter>;
};

export type PersonalisationStatisticMaxAggregates = {
  __typename?: 'PersonalisationStatisticMaxAggregates';
  /** Maximum of value across the matching connection */
  value?: Maybe<Scalars['Int']['output']>;
};

export type PersonalisationStatisticMinAggregates = {
  __typename?: 'PersonalisationStatisticMinAggregates';
  /** Minimum of value across the matching connection */
  value?: Maybe<Scalars['Int']['output']>;
};

export type PersonalisationStatisticStddevPopulationAggregates = {
  __typename?: 'PersonalisationStatisticStddevPopulationAggregates';
  /** Population standard deviation of value across the matching connection */
  value?: Maybe<Scalars['BigFloat']['output']>;
};

export type PersonalisationStatisticStddevSampleAggregates = {
  __typename?: 'PersonalisationStatisticStddevSampleAggregates';
  /** Sample standard deviation of value across the matching connection */
  value?: Maybe<Scalars['BigFloat']['output']>;
};

export type PersonalisationStatisticSumAggregates = {
  __typename?: 'PersonalisationStatisticSumAggregates';
  /** Sum of value across the matching connection */
  value: Scalars['BigInt']['output'];
};

export type PersonalisationStatisticVariancePopulationAggregates = {
  __typename?: 'PersonalisationStatisticVariancePopulationAggregates';
  /** Population variance of value across the matching connection */
  value?: Maybe<Scalars['BigFloat']['output']>;
};

export type PersonalisationStatisticVarianceSampleAggregates = {
  __typename?: 'PersonalisationStatisticVarianceSampleAggregates';
  /** Sample variance of value across the matching connection */
  value?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `PersonalisationStatistic` values. */
export type PersonalisationStatisticsConnection = {
  __typename?: 'PersonalisationStatisticsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<PersonalisationStatisticAggregates>;
  /** A list of edges which contains the `PersonalisationStatistic` and cursor to aid in pagination. */
  edges: Array<PersonalisationStatisticsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<PersonalisationStatisticAggregates>>;
  /** A list of `PersonalisationStatistic` objects. */
  nodes: Array<Maybe<PersonalisationStatistic>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PersonalisationStatistic` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PersonalisationStatistic` values. */
export type PersonalisationStatisticsConnectionGroupedAggregatesArgs = {
  groupBy: Array<PersonalisationStatisticsGroupBy>;
  having?: InputMaybe<PersonalisationStatisticsHavingInput>;
};

/** A `PersonalisationStatistic` edge in the connection. */
export type PersonalisationStatisticsEdge = {
  __typename?: 'PersonalisationStatisticsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `PersonalisationStatistic` at the end of the edge. */
  node?: Maybe<PersonalisationStatistic>;
};

/** Grouping methods for `PersonalisationStatistic` for usage during aggregation. */
export enum PersonalisationStatisticsGroupBy {
  CampaignId = 'CAMPAIGN_ID',
  LawyerId = 'LAWYER_ID',
  PersValue = 'PERS_VALUE',
  TypeId = 'TYPE_ID',
  Value = 'VALUE'
}

export type PersonalisationStatisticsHavingAverageInput = {
  value?: InputMaybe<HavingIntFilter>;
};

export type PersonalisationStatisticsHavingDistinctCountInput = {
  value?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `PersonalisationStatistic` aggregates. */
export type PersonalisationStatisticsHavingInput = {
  AND?: InputMaybe<Array<PersonalisationStatisticsHavingInput>>;
  OR?: InputMaybe<Array<PersonalisationStatisticsHavingInput>>;
  average?: InputMaybe<PersonalisationStatisticsHavingAverageInput>;
  distinctCount?: InputMaybe<PersonalisationStatisticsHavingDistinctCountInput>;
  max?: InputMaybe<PersonalisationStatisticsHavingMaxInput>;
  min?: InputMaybe<PersonalisationStatisticsHavingMinInput>;
  stddevPopulation?: InputMaybe<PersonalisationStatisticsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<PersonalisationStatisticsHavingStddevSampleInput>;
  sum?: InputMaybe<PersonalisationStatisticsHavingSumInput>;
  variancePopulation?: InputMaybe<PersonalisationStatisticsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<PersonalisationStatisticsHavingVarianceSampleInput>;
};

export type PersonalisationStatisticsHavingMaxInput = {
  value?: InputMaybe<HavingIntFilter>;
};

export type PersonalisationStatisticsHavingMinInput = {
  value?: InputMaybe<HavingIntFilter>;
};

export type PersonalisationStatisticsHavingStddevPopulationInput = {
  value?: InputMaybe<HavingIntFilter>;
};

export type PersonalisationStatisticsHavingStddevSampleInput = {
  value?: InputMaybe<HavingIntFilter>;
};

export type PersonalisationStatisticsHavingSumInput = {
  value?: InputMaybe<HavingIntFilter>;
};

export type PersonalisationStatisticsHavingVariancePopulationInput = {
  value?: InputMaybe<HavingIntFilter>;
};

export type PersonalisationStatisticsHavingVarianceSampleInput = {
  value?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `PersonalisationStatistic`. */
export enum PersonalisationStatisticsOrderBy {
  CampaignIdAsc = 'CAMPAIGN_ID_ASC',
  CampaignIdDesc = 'CAMPAIGN_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  Natural = 'NATURAL',
  PersValueAsc = 'PERS_VALUE_ASC',
  PersValueDesc = 'PERS_VALUE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TypeIdAsc = 'TYPE_ID_ASC',
  TypeIdDesc = 'TYPE_ID_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type PersonalisationStatisticsSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: PersonalisationStatisticsSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type PersonalisationStatisticsSortBy = {
  field?: InputMaybe<PersonalisationStatisticsSortableField>;
};

/** Sortable concrete fields for the `PersonalisationStatistic` type. */
export enum PersonalisationStatisticsSortableField {
  CampaignId = 'CAMPAIGN_ID',
  Id = 'ID',
  LawyerId = 'LAWYER_ID',
  PersValue = 'PERS_VALUE',
  TypeId = 'TYPE_ID',
  Value = 'VALUE'
}

export type PersonalisationStddevPopulationAggregates = {
  __typename?: 'PersonalisationStddevPopulationAggregates';
  /** Population standard deviation of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigFloat']['output']>;
};

export type PersonalisationStddevSampleAggregates = {
  __typename?: 'PersonalisationStddevSampleAggregates';
  /** Sample standard deviation of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigFloat']['output']>;
};

export type PersonalisationSumAggregates = {
  __typename?: 'PersonalisationSumAggregates';
  /** Sum of intValue across the matching connection */
  intValue: Scalars['BigFloat']['output'];
};

export type PersonalisationType = Node & {
  __typename?: 'PersonalisationType';
  /** Reads and enables pagination through a set of `CompanyPersonalisation`. */
  companyPersonalisationsByTypeId: CompanyPersonalisationsConnection;
  dataType: Scalars['String']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `PersonalisationStatistic`. */
  personalisationStatisticsByTypeId: PersonalisationStatisticsConnection;
  /** Reads and enables pagination through a set of `Personalisation`. */
  personalisationsByTypeId: PersonalisationsConnection;
  regex?: Maybe<Scalars['String']['output']>;
};


export type PersonalisationTypeCompanyPersonalisationsByTypeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyPersonalisationCondition>;
  filter?: InputMaybe<CompanyPersonalisationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyPersonalisationsOrderBy>>;
  sort?: InputMaybe<Array<CompanyPersonalisationSort>>;
};


export type PersonalisationTypePersonalisationStatisticsByTypeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PersonalisationStatisticCondition>;
  filter?: InputMaybe<PersonalisationStatisticFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonalisationStatisticsOrderBy>>;
  sort?: InputMaybe<Array<PersonalisationStatisticsSort>>;
};


export type PersonalisationTypePersonalisationsByTypeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PersonalisationCondition>;
  filter?: InputMaybe<PersonalisationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonalisationsOrderBy>>;
  sort?: InputMaybe<Array<PersonalisationSort>>;
};

export type PersonalisationTypeAggregates = {
  __typename?: 'PersonalisationTypeAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<PersonalisationTypeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `PersonalisationType` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type PersonalisationTypeCondition = {
  /** Checks for equality with the object’s `dataType` field. */
  dataType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `regex` field. */
  regex?: InputMaybe<Scalars['String']['input']>;
};

export type PersonalisationTypeDistinctCountAggregates = {
  __typename?: 'PersonalisationTypeDistinctCountAggregates';
  /** Distinct count of dataType across the matching connection */
  dataType?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of regex across the matching connection */
  regex?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `PersonalisationType` object types. All fields are combined with a logical ‘and.’ */
export type PersonalisationTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PersonalisationTypeFilter>>;
  /** Filter by the object’s `dataType` field. */
  dataType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PersonalisationTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PersonalisationTypeFilter>>;
  /** Filter by the object’s `regex` field. */
  regex?: InputMaybe<StringFilter>;
};

/** Grouping methods for `PersonalisationType` for usage during aggregation. */
export enum PersonalisationTypeGroupBy {
  DataType = 'DATA_TYPE',
  Name = 'NAME',
  Regex = 'REGEX'
}

/** Conditions for `PersonalisationType` aggregates. */
export type PersonalisationTypeHavingInput = {
  AND?: InputMaybe<Array<PersonalisationTypeHavingInput>>;
  OR?: InputMaybe<Array<PersonalisationTypeHavingInput>>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type PersonalisationTypeSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: PersonalisationTypeSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type PersonalisationTypeSortBy = {
  field?: InputMaybe<PersonalisationTypeSortableField>;
};

/** Sortable concrete fields for the `PersonalisationType` type. */
export enum PersonalisationTypeSortableField {
  DataType = 'DATA_TYPE',
  Id = 'ID',
  Name = 'NAME',
  Regex = 'REGEX'
}

/** A connection to a list of `PersonalisationType` values. */
export type PersonalisationTypesConnection = {
  __typename?: 'PersonalisationTypesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<PersonalisationTypeAggregates>;
  /** A list of edges which contains the `PersonalisationType` and cursor to aid in pagination. */
  edges: Array<PersonalisationTypesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<PersonalisationTypeAggregates>>;
  /** A list of `PersonalisationType` objects. */
  nodes: Array<Maybe<PersonalisationType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PersonalisationType` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PersonalisationType` values. */
export type PersonalisationTypesConnectionGroupedAggregatesArgs = {
  groupBy: Array<PersonalisationTypeGroupBy>;
  having?: InputMaybe<PersonalisationTypeHavingInput>;
};

/** A `PersonalisationType` edge in the connection. */
export type PersonalisationTypesEdge = {
  __typename?: 'PersonalisationTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `PersonalisationType` at the end of the edge. */
  node?: Maybe<PersonalisationType>;
};

/** Methods to use when ordering `PersonalisationType`. */
export enum PersonalisationTypesOrderBy {
  CompanyPersonalisationsByTypeIdAverageCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_AVERAGE_COMPANY_ID_ASC',
  CompanyPersonalisationsByTypeIdAverageCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_AVERAGE_COMPANY_ID_DESC',
  CompanyPersonalisationsByTypeIdAverageDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_AVERAGE_DATE_VALUE_ASC',
  CompanyPersonalisationsByTypeIdAverageDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_AVERAGE_DATE_VALUE_DESC',
  CompanyPersonalisationsByTypeIdAverageIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_AVERAGE_ID_ASC',
  CompanyPersonalisationsByTypeIdAverageIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_AVERAGE_ID_DESC',
  CompanyPersonalisationsByTypeIdAverageIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_AVERAGE_INT_VALUE_ASC',
  CompanyPersonalisationsByTypeIdAverageIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_AVERAGE_INT_VALUE_DESC',
  CompanyPersonalisationsByTypeIdAverageStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_AVERAGE_STR_VALUE_ASC',
  CompanyPersonalisationsByTypeIdAverageStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_AVERAGE_STR_VALUE_DESC',
  CompanyPersonalisationsByTypeIdAverageTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_AVERAGE_TYPE_ID_ASC',
  CompanyPersonalisationsByTypeIdAverageTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_AVERAGE_TYPE_ID_DESC',
  CompanyPersonalisationsByTypeIdCountAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_COUNT_ASC',
  CompanyPersonalisationsByTypeIdCountDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_COUNT_DESC',
  CompanyPersonalisationsByTypeIdDistinctCountCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  CompanyPersonalisationsByTypeIdDistinctCountCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  CompanyPersonalisationsByTypeIdDistinctCountDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_DATE_VALUE_ASC',
  CompanyPersonalisationsByTypeIdDistinctCountDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_DATE_VALUE_DESC',
  CompanyPersonalisationsByTypeIdDistinctCountIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_ID_ASC',
  CompanyPersonalisationsByTypeIdDistinctCountIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_ID_DESC',
  CompanyPersonalisationsByTypeIdDistinctCountIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_INT_VALUE_ASC',
  CompanyPersonalisationsByTypeIdDistinctCountIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_INT_VALUE_DESC',
  CompanyPersonalisationsByTypeIdDistinctCountStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_STR_VALUE_ASC',
  CompanyPersonalisationsByTypeIdDistinctCountStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_STR_VALUE_DESC',
  CompanyPersonalisationsByTypeIdDistinctCountTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_TYPE_ID_ASC',
  CompanyPersonalisationsByTypeIdDistinctCountTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_TYPE_ID_DESC',
  CompanyPersonalisationsByTypeIdMaxCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MAX_COMPANY_ID_ASC',
  CompanyPersonalisationsByTypeIdMaxCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MAX_COMPANY_ID_DESC',
  CompanyPersonalisationsByTypeIdMaxDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MAX_DATE_VALUE_ASC',
  CompanyPersonalisationsByTypeIdMaxDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MAX_DATE_VALUE_DESC',
  CompanyPersonalisationsByTypeIdMaxIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MAX_ID_ASC',
  CompanyPersonalisationsByTypeIdMaxIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MAX_ID_DESC',
  CompanyPersonalisationsByTypeIdMaxIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MAX_INT_VALUE_ASC',
  CompanyPersonalisationsByTypeIdMaxIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MAX_INT_VALUE_DESC',
  CompanyPersonalisationsByTypeIdMaxStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MAX_STR_VALUE_ASC',
  CompanyPersonalisationsByTypeIdMaxStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MAX_STR_VALUE_DESC',
  CompanyPersonalisationsByTypeIdMaxTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MAX_TYPE_ID_ASC',
  CompanyPersonalisationsByTypeIdMaxTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MAX_TYPE_ID_DESC',
  CompanyPersonalisationsByTypeIdMinCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MIN_COMPANY_ID_ASC',
  CompanyPersonalisationsByTypeIdMinCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MIN_COMPANY_ID_DESC',
  CompanyPersonalisationsByTypeIdMinDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MIN_DATE_VALUE_ASC',
  CompanyPersonalisationsByTypeIdMinDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MIN_DATE_VALUE_DESC',
  CompanyPersonalisationsByTypeIdMinIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MIN_ID_ASC',
  CompanyPersonalisationsByTypeIdMinIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MIN_ID_DESC',
  CompanyPersonalisationsByTypeIdMinIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MIN_INT_VALUE_ASC',
  CompanyPersonalisationsByTypeIdMinIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MIN_INT_VALUE_DESC',
  CompanyPersonalisationsByTypeIdMinStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MIN_STR_VALUE_ASC',
  CompanyPersonalisationsByTypeIdMinStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MIN_STR_VALUE_DESC',
  CompanyPersonalisationsByTypeIdMinTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MIN_TYPE_ID_ASC',
  CompanyPersonalisationsByTypeIdMinTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_MIN_TYPE_ID_DESC',
  CompanyPersonalisationsByTypeIdStddevPopulationCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  CompanyPersonalisationsByTypeIdStddevPopulationCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  CompanyPersonalisationsByTypeIdStddevPopulationDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_DATE_VALUE_ASC',
  CompanyPersonalisationsByTypeIdStddevPopulationDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_DATE_VALUE_DESC',
  CompanyPersonalisationsByTypeIdStddevPopulationIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_ID_ASC',
  CompanyPersonalisationsByTypeIdStddevPopulationIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_ID_DESC',
  CompanyPersonalisationsByTypeIdStddevPopulationIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_INT_VALUE_ASC',
  CompanyPersonalisationsByTypeIdStddevPopulationIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_INT_VALUE_DESC',
  CompanyPersonalisationsByTypeIdStddevPopulationStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_STR_VALUE_ASC',
  CompanyPersonalisationsByTypeIdStddevPopulationStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_STR_VALUE_DESC',
  CompanyPersonalisationsByTypeIdStddevPopulationTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_TYPE_ID_ASC',
  CompanyPersonalisationsByTypeIdStddevPopulationTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_TYPE_ID_DESC',
  CompanyPersonalisationsByTypeIdStddevSampleCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  CompanyPersonalisationsByTypeIdStddevSampleCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  CompanyPersonalisationsByTypeIdStddevSampleDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_DATE_VALUE_ASC',
  CompanyPersonalisationsByTypeIdStddevSampleDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_DATE_VALUE_DESC',
  CompanyPersonalisationsByTypeIdStddevSampleIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_ID_ASC',
  CompanyPersonalisationsByTypeIdStddevSampleIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_ID_DESC',
  CompanyPersonalisationsByTypeIdStddevSampleIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_INT_VALUE_ASC',
  CompanyPersonalisationsByTypeIdStddevSampleIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_INT_VALUE_DESC',
  CompanyPersonalisationsByTypeIdStddevSampleStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_STR_VALUE_ASC',
  CompanyPersonalisationsByTypeIdStddevSampleStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_STR_VALUE_DESC',
  CompanyPersonalisationsByTypeIdStddevSampleTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_TYPE_ID_ASC',
  CompanyPersonalisationsByTypeIdStddevSampleTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_TYPE_ID_DESC',
  CompanyPersonalisationsByTypeIdSumCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_SUM_COMPANY_ID_ASC',
  CompanyPersonalisationsByTypeIdSumCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_SUM_COMPANY_ID_DESC',
  CompanyPersonalisationsByTypeIdSumDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_SUM_DATE_VALUE_ASC',
  CompanyPersonalisationsByTypeIdSumDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_SUM_DATE_VALUE_DESC',
  CompanyPersonalisationsByTypeIdSumIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_SUM_ID_ASC',
  CompanyPersonalisationsByTypeIdSumIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_SUM_ID_DESC',
  CompanyPersonalisationsByTypeIdSumIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_SUM_INT_VALUE_ASC',
  CompanyPersonalisationsByTypeIdSumIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_SUM_INT_VALUE_DESC',
  CompanyPersonalisationsByTypeIdSumStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_SUM_STR_VALUE_ASC',
  CompanyPersonalisationsByTypeIdSumStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_SUM_STR_VALUE_DESC',
  CompanyPersonalisationsByTypeIdSumTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_SUM_TYPE_ID_ASC',
  CompanyPersonalisationsByTypeIdSumTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_SUM_TYPE_ID_DESC',
  CompanyPersonalisationsByTypeIdVariancePopulationCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  CompanyPersonalisationsByTypeIdVariancePopulationCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  CompanyPersonalisationsByTypeIdVariancePopulationDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_DATE_VALUE_ASC',
  CompanyPersonalisationsByTypeIdVariancePopulationDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_DATE_VALUE_DESC',
  CompanyPersonalisationsByTypeIdVariancePopulationIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_ID_ASC',
  CompanyPersonalisationsByTypeIdVariancePopulationIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_ID_DESC',
  CompanyPersonalisationsByTypeIdVariancePopulationIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_INT_VALUE_ASC',
  CompanyPersonalisationsByTypeIdVariancePopulationIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_INT_VALUE_DESC',
  CompanyPersonalisationsByTypeIdVariancePopulationStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_STR_VALUE_ASC',
  CompanyPersonalisationsByTypeIdVariancePopulationStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_STR_VALUE_DESC',
  CompanyPersonalisationsByTypeIdVariancePopulationTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_TYPE_ID_ASC',
  CompanyPersonalisationsByTypeIdVariancePopulationTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_TYPE_ID_DESC',
  CompanyPersonalisationsByTypeIdVarianceSampleCompanyIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  CompanyPersonalisationsByTypeIdVarianceSampleCompanyIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  CompanyPersonalisationsByTypeIdVarianceSampleDateValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_DATE_VALUE_ASC',
  CompanyPersonalisationsByTypeIdVarianceSampleDateValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_DATE_VALUE_DESC',
  CompanyPersonalisationsByTypeIdVarianceSampleIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_ID_ASC',
  CompanyPersonalisationsByTypeIdVarianceSampleIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_ID_DESC',
  CompanyPersonalisationsByTypeIdVarianceSampleIntValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_INT_VALUE_ASC',
  CompanyPersonalisationsByTypeIdVarianceSampleIntValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_INT_VALUE_DESC',
  CompanyPersonalisationsByTypeIdVarianceSampleStrValueAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_STR_VALUE_ASC',
  CompanyPersonalisationsByTypeIdVarianceSampleStrValueDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_STR_VALUE_DESC',
  CompanyPersonalisationsByTypeIdVarianceSampleTypeIdAsc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_TYPE_ID_ASC',
  CompanyPersonalisationsByTypeIdVarianceSampleTypeIdDesc = 'COMPANY_PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_TYPE_ID_DESC',
  DataTypeAsc = 'DATA_TYPE_ASC',
  DataTypeDesc = 'DATA_TYPE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PersonalisationsByTypeIdAverageContactIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_AVERAGE_CONTACT_ID_ASC',
  PersonalisationsByTypeIdAverageContactIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_AVERAGE_CONTACT_ID_DESC',
  PersonalisationsByTypeIdAverageDateValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_AVERAGE_DATE_VALUE_ASC',
  PersonalisationsByTypeIdAverageDateValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_AVERAGE_DATE_VALUE_DESC',
  PersonalisationsByTypeIdAverageIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_AVERAGE_ID_ASC',
  PersonalisationsByTypeIdAverageIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_AVERAGE_ID_DESC',
  PersonalisationsByTypeIdAverageIntValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_AVERAGE_INT_VALUE_ASC',
  PersonalisationsByTypeIdAverageIntValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_AVERAGE_INT_VALUE_DESC',
  PersonalisationsByTypeIdAverageStrValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_AVERAGE_STR_VALUE_ASC',
  PersonalisationsByTypeIdAverageStrValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_AVERAGE_STR_VALUE_DESC',
  PersonalisationsByTypeIdAverageTypeIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_AVERAGE_TYPE_ID_ASC',
  PersonalisationsByTypeIdAverageTypeIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_AVERAGE_TYPE_ID_DESC',
  PersonalisationsByTypeIdCountAsc = 'PERSONALISATIONS_BY_TYPE_ID_COUNT_ASC',
  PersonalisationsByTypeIdCountDesc = 'PERSONALISATIONS_BY_TYPE_ID_COUNT_DESC',
  PersonalisationsByTypeIdDistinctCountContactIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  PersonalisationsByTypeIdDistinctCountContactIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  PersonalisationsByTypeIdDistinctCountDateValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_DATE_VALUE_ASC',
  PersonalisationsByTypeIdDistinctCountDateValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_DATE_VALUE_DESC',
  PersonalisationsByTypeIdDistinctCountIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_ID_ASC',
  PersonalisationsByTypeIdDistinctCountIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_ID_DESC',
  PersonalisationsByTypeIdDistinctCountIntValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_INT_VALUE_ASC',
  PersonalisationsByTypeIdDistinctCountIntValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_INT_VALUE_DESC',
  PersonalisationsByTypeIdDistinctCountStrValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_STR_VALUE_ASC',
  PersonalisationsByTypeIdDistinctCountStrValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_STR_VALUE_DESC',
  PersonalisationsByTypeIdDistinctCountTypeIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_TYPE_ID_ASC',
  PersonalisationsByTypeIdDistinctCountTypeIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_DISTINCT_COUNT_TYPE_ID_DESC',
  PersonalisationsByTypeIdMaxContactIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_MAX_CONTACT_ID_ASC',
  PersonalisationsByTypeIdMaxContactIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_MAX_CONTACT_ID_DESC',
  PersonalisationsByTypeIdMaxDateValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_MAX_DATE_VALUE_ASC',
  PersonalisationsByTypeIdMaxDateValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_MAX_DATE_VALUE_DESC',
  PersonalisationsByTypeIdMaxIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_MAX_ID_ASC',
  PersonalisationsByTypeIdMaxIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_MAX_ID_DESC',
  PersonalisationsByTypeIdMaxIntValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_MAX_INT_VALUE_ASC',
  PersonalisationsByTypeIdMaxIntValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_MAX_INT_VALUE_DESC',
  PersonalisationsByTypeIdMaxStrValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_MAX_STR_VALUE_ASC',
  PersonalisationsByTypeIdMaxStrValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_MAX_STR_VALUE_DESC',
  PersonalisationsByTypeIdMaxTypeIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_MAX_TYPE_ID_ASC',
  PersonalisationsByTypeIdMaxTypeIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_MAX_TYPE_ID_DESC',
  PersonalisationsByTypeIdMinContactIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_MIN_CONTACT_ID_ASC',
  PersonalisationsByTypeIdMinContactIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_MIN_CONTACT_ID_DESC',
  PersonalisationsByTypeIdMinDateValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_MIN_DATE_VALUE_ASC',
  PersonalisationsByTypeIdMinDateValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_MIN_DATE_VALUE_DESC',
  PersonalisationsByTypeIdMinIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_MIN_ID_ASC',
  PersonalisationsByTypeIdMinIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_MIN_ID_DESC',
  PersonalisationsByTypeIdMinIntValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_MIN_INT_VALUE_ASC',
  PersonalisationsByTypeIdMinIntValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_MIN_INT_VALUE_DESC',
  PersonalisationsByTypeIdMinStrValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_MIN_STR_VALUE_ASC',
  PersonalisationsByTypeIdMinStrValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_MIN_STR_VALUE_DESC',
  PersonalisationsByTypeIdMinTypeIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_MIN_TYPE_ID_ASC',
  PersonalisationsByTypeIdMinTypeIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_MIN_TYPE_ID_DESC',
  PersonalisationsByTypeIdStddevPopulationContactIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  PersonalisationsByTypeIdStddevPopulationContactIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  PersonalisationsByTypeIdStddevPopulationDateValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_DATE_VALUE_ASC',
  PersonalisationsByTypeIdStddevPopulationDateValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_DATE_VALUE_DESC',
  PersonalisationsByTypeIdStddevPopulationIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_ID_ASC',
  PersonalisationsByTypeIdStddevPopulationIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_ID_DESC',
  PersonalisationsByTypeIdStddevPopulationIntValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_INT_VALUE_ASC',
  PersonalisationsByTypeIdStddevPopulationIntValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_INT_VALUE_DESC',
  PersonalisationsByTypeIdStddevPopulationStrValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_STR_VALUE_ASC',
  PersonalisationsByTypeIdStddevPopulationStrValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_STR_VALUE_DESC',
  PersonalisationsByTypeIdStddevPopulationTypeIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_TYPE_ID_ASC',
  PersonalisationsByTypeIdStddevPopulationTypeIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_POPULATION_TYPE_ID_DESC',
  PersonalisationsByTypeIdStddevSampleContactIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  PersonalisationsByTypeIdStddevSampleContactIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  PersonalisationsByTypeIdStddevSampleDateValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_DATE_VALUE_ASC',
  PersonalisationsByTypeIdStddevSampleDateValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_DATE_VALUE_DESC',
  PersonalisationsByTypeIdStddevSampleIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_ID_ASC',
  PersonalisationsByTypeIdStddevSampleIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_ID_DESC',
  PersonalisationsByTypeIdStddevSampleIntValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_INT_VALUE_ASC',
  PersonalisationsByTypeIdStddevSampleIntValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_INT_VALUE_DESC',
  PersonalisationsByTypeIdStddevSampleStrValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_STR_VALUE_ASC',
  PersonalisationsByTypeIdStddevSampleStrValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_STR_VALUE_DESC',
  PersonalisationsByTypeIdStddevSampleTypeIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_TYPE_ID_ASC',
  PersonalisationsByTypeIdStddevSampleTypeIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_STDDEV_SAMPLE_TYPE_ID_DESC',
  PersonalisationsByTypeIdSumContactIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_SUM_CONTACT_ID_ASC',
  PersonalisationsByTypeIdSumContactIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_SUM_CONTACT_ID_DESC',
  PersonalisationsByTypeIdSumDateValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_SUM_DATE_VALUE_ASC',
  PersonalisationsByTypeIdSumDateValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_SUM_DATE_VALUE_DESC',
  PersonalisationsByTypeIdSumIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_SUM_ID_ASC',
  PersonalisationsByTypeIdSumIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_SUM_ID_DESC',
  PersonalisationsByTypeIdSumIntValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_SUM_INT_VALUE_ASC',
  PersonalisationsByTypeIdSumIntValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_SUM_INT_VALUE_DESC',
  PersonalisationsByTypeIdSumStrValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_SUM_STR_VALUE_ASC',
  PersonalisationsByTypeIdSumStrValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_SUM_STR_VALUE_DESC',
  PersonalisationsByTypeIdSumTypeIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_SUM_TYPE_ID_ASC',
  PersonalisationsByTypeIdSumTypeIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_SUM_TYPE_ID_DESC',
  PersonalisationsByTypeIdVariancePopulationContactIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  PersonalisationsByTypeIdVariancePopulationContactIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  PersonalisationsByTypeIdVariancePopulationDateValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_DATE_VALUE_ASC',
  PersonalisationsByTypeIdVariancePopulationDateValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_DATE_VALUE_DESC',
  PersonalisationsByTypeIdVariancePopulationIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_ID_ASC',
  PersonalisationsByTypeIdVariancePopulationIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_ID_DESC',
  PersonalisationsByTypeIdVariancePopulationIntValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_INT_VALUE_ASC',
  PersonalisationsByTypeIdVariancePopulationIntValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_INT_VALUE_DESC',
  PersonalisationsByTypeIdVariancePopulationStrValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_STR_VALUE_ASC',
  PersonalisationsByTypeIdVariancePopulationStrValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_STR_VALUE_DESC',
  PersonalisationsByTypeIdVariancePopulationTypeIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_TYPE_ID_ASC',
  PersonalisationsByTypeIdVariancePopulationTypeIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_POPULATION_TYPE_ID_DESC',
  PersonalisationsByTypeIdVarianceSampleContactIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  PersonalisationsByTypeIdVarianceSampleContactIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  PersonalisationsByTypeIdVarianceSampleDateValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_DATE_VALUE_ASC',
  PersonalisationsByTypeIdVarianceSampleDateValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_DATE_VALUE_DESC',
  PersonalisationsByTypeIdVarianceSampleIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_ID_ASC',
  PersonalisationsByTypeIdVarianceSampleIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_ID_DESC',
  PersonalisationsByTypeIdVarianceSampleIntValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_INT_VALUE_ASC',
  PersonalisationsByTypeIdVarianceSampleIntValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_INT_VALUE_DESC',
  PersonalisationsByTypeIdVarianceSampleStrValueAsc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_STR_VALUE_ASC',
  PersonalisationsByTypeIdVarianceSampleStrValueDesc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_STR_VALUE_DESC',
  PersonalisationsByTypeIdVarianceSampleTypeIdAsc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_TYPE_ID_ASC',
  PersonalisationsByTypeIdVarianceSampleTypeIdDesc = 'PERSONALISATIONS_BY_TYPE_ID_VARIANCE_SAMPLE_TYPE_ID_DESC',
  PersonalisationStatisticsByTypeIdAverageCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_AVERAGE_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByTypeIdAverageCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_AVERAGE_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByTypeIdAverageIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_AVERAGE_ID_ASC',
  PersonalisationStatisticsByTypeIdAverageIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_AVERAGE_ID_DESC',
  PersonalisationStatisticsByTypeIdAverageLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_AVERAGE_LAWYER_ID_ASC',
  PersonalisationStatisticsByTypeIdAverageLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_AVERAGE_LAWYER_ID_DESC',
  PersonalisationStatisticsByTypeIdAveragePersValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_AVERAGE_PERS_VALUE_ASC',
  PersonalisationStatisticsByTypeIdAveragePersValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_AVERAGE_PERS_VALUE_DESC',
  PersonalisationStatisticsByTypeIdAverageTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_AVERAGE_TYPE_ID_ASC',
  PersonalisationStatisticsByTypeIdAverageTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_AVERAGE_TYPE_ID_DESC',
  PersonalisationStatisticsByTypeIdAverageValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_AVERAGE_VALUE_ASC',
  PersonalisationStatisticsByTypeIdAverageValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_AVERAGE_VALUE_DESC',
  PersonalisationStatisticsByTypeIdCountAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_COUNT_ASC',
  PersonalisationStatisticsByTypeIdCountDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_COUNT_DESC',
  PersonalisationStatisticsByTypeIdDistinctCountCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByTypeIdDistinctCountCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByTypeIdDistinctCountIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_DISTINCT_COUNT_ID_ASC',
  PersonalisationStatisticsByTypeIdDistinctCountIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_DISTINCT_COUNT_ID_DESC',
  PersonalisationStatisticsByTypeIdDistinctCountLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  PersonalisationStatisticsByTypeIdDistinctCountLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  PersonalisationStatisticsByTypeIdDistinctCountPersValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_DISTINCT_COUNT_PERS_VALUE_ASC',
  PersonalisationStatisticsByTypeIdDistinctCountPersValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_DISTINCT_COUNT_PERS_VALUE_DESC',
  PersonalisationStatisticsByTypeIdDistinctCountTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_DISTINCT_COUNT_TYPE_ID_ASC',
  PersonalisationStatisticsByTypeIdDistinctCountTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_DISTINCT_COUNT_TYPE_ID_DESC',
  PersonalisationStatisticsByTypeIdDistinctCountValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_DISTINCT_COUNT_VALUE_ASC',
  PersonalisationStatisticsByTypeIdDistinctCountValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_DISTINCT_COUNT_VALUE_DESC',
  PersonalisationStatisticsByTypeIdMaxCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MAX_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByTypeIdMaxCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MAX_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByTypeIdMaxIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MAX_ID_ASC',
  PersonalisationStatisticsByTypeIdMaxIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MAX_ID_DESC',
  PersonalisationStatisticsByTypeIdMaxLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MAX_LAWYER_ID_ASC',
  PersonalisationStatisticsByTypeIdMaxLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MAX_LAWYER_ID_DESC',
  PersonalisationStatisticsByTypeIdMaxPersValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MAX_PERS_VALUE_ASC',
  PersonalisationStatisticsByTypeIdMaxPersValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MAX_PERS_VALUE_DESC',
  PersonalisationStatisticsByTypeIdMaxTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MAX_TYPE_ID_ASC',
  PersonalisationStatisticsByTypeIdMaxTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MAX_TYPE_ID_DESC',
  PersonalisationStatisticsByTypeIdMaxValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MAX_VALUE_ASC',
  PersonalisationStatisticsByTypeIdMaxValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MAX_VALUE_DESC',
  PersonalisationStatisticsByTypeIdMinCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MIN_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByTypeIdMinCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MIN_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByTypeIdMinIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MIN_ID_ASC',
  PersonalisationStatisticsByTypeIdMinIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MIN_ID_DESC',
  PersonalisationStatisticsByTypeIdMinLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MIN_LAWYER_ID_ASC',
  PersonalisationStatisticsByTypeIdMinLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MIN_LAWYER_ID_DESC',
  PersonalisationStatisticsByTypeIdMinPersValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MIN_PERS_VALUE_ASC',
  PersonalisationStatisticsByTypeIdMinPersValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MIN_PERS_VALUE_DESC',
  PersonalisationStatisticsByTypeIdMinTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MIN_TYPE_ID_ASC',
  PersonalisationStatisticsByTypeIdMinTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MIN_TYPE_ID_DESC',
  PersonalisationStatisticsByTypeIdMinValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MIN_VALUE_ASC',
  PersonalisationStatisticsByTypeIdMinValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_MIN_VALUE_DESC',
  PersonalisationStatisticsByTypeIdStddevPopulationCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByTypeIdStddevPopulationCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByTypeIdStddevPopulationIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_POPULATION_ID_ASC',
  PersonalisationStatisticsByTypeIdStddevPopulationIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_POPULATION_ID_DESC',
  PersonalisationStatisticsByTypeIdStddevPopulationLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  PersonalisationStatisticsByTypeIdStddevPopulationLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  PersonalisationStatisticsByTypeIdStddevPopulationPersValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_POPULATION_PERS_VALUE_ASC',
  PersonalisationStatisticsByTypeIdStddevPopulationPersValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_POPULATION_PERS_VALUE_DESC',
  PersonalisationStatisticsByTypeIdStddevPopulationTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_POPULATION_TYPE_ID_ASC',
  PersonalisationStatisticsByTypeIdStddevPopulationTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_POPULATION_TYPE_ID_DESC',
  PersonalisationStatisticsByTypeIdStddevPopulationValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_POPULATION_VALUE_ASC',
  PersonalisationStatisticsByTypeIdStddevPopulationValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_POPULATION_VALUE_DESC',
  PersonalisationStatisticsByTypeIdStddevSampleCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByTypeIdStddevSampleCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByTypeIdStddevSampleIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_SAMPLE_ID_ASC',
  PersonalisationStatisticsByTypeIdStddevSampleIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_SAMPLE_ID_DESC',
  PersonalisationStatisticsByTypeIdStddevSampleLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  PersonalisationStatisticsByTypeIdStddevSampleLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  PersonalisationStatisticsByTypeIdStddevSamplePersValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_SAMPLE_PERS_VALUE_ASC',
  PersonalisationStatisticsByTypeIdStddevSamplePersValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_SAMPLE_PERS_VALUE_DESC',
  PersonalisationStatisticsByTypeIdStddevSampleTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_SAMPLE_TYPE_ID_ASC',
  PersonalisationStatisticsByTypeIdStddevSampleTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_SAMPLE_TYPE_ID_DESC',
  PersonalisationStatisticsByTypeIdStddevSampleValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_SAMPLE_VALUE_ASC',
  PersonalisationStatisticsByTypeIdStddevSampleValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_STDDEV_SAMPLE_VALUE_DESC',
  PersonalisationStatisticsByTypeIdSumCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_SUM_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByTypeIdSumCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_SUM_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByTypeIdSumIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_SUM_ID_ASC',
  PersonalisationStatisticsByTypeIdSumIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_SUM_ID_DESC',
  PersonalisationStatisticsByTypeIdSumLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_SUM_LAWYER_ID_ASC',
  PersonalisationStatisticsByTypeIdSumLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_SUM_LAWYER_ID_DESC',
  PersonalisationStatisticsByTypeIdSumPersValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_SUM_PERS_VALUE_ASC',
  PersonalisationStatisticsByTypeIdSumPersValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_SUM_PERS_VALUE_DESC',
  PersonalisationStatisticsByTypeIdSumTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_SUM_TYPE_ID_ASC',
  PersonalisationStatisticsByTypeIdSumTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_SUM_TYPE_ID_DESC',
  PersonalisationStatisticsByTypeIdSumValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_SUM_VALUE_ASC',
  PersonalisationStatisticsByTypeIdSumValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_SUM_VALUE_DESC',
  PersonalisationStatisticsByTypeIdVariancePopulationCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByTypeIdVariancePopulationCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByTypeIdVariancePopulationIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_POPULATION_ID_ASC',
  PersonalisationStatisticsByTypeIdVariancePopulationIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_POPULATION_ID_DESC',
  PersonalisationStatisticsByTypeIdVariancePopulationLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  PersonalisationStatisticsByTypeIdVariancePopulationLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  PersonalisationStatisticsByTypeIdVariancePopulationPersValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_POPULATION_PERS_VALUE_ASC',
  PersonalisationStatisticsByTypeIdVariancePopulationPersValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_POPULATION_PERS_VALUE_DESC',
  PersonalisationStatisticsByTypeIdVariancePopulationTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_POPULATION_TYPE_ID_ASC',
  PersonalisationStatisticsByTypeIdVariancePopulationTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_POPULATION_TYPE_ID_DESC',
  PersonalisationStatisticsByTypeIdVariancePopulationValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_POPULATION_VALUE_ASC',
  PersonalisationStatisticsByTypeIdVariancePopulationValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_POPULATION_VALUE_DESC',
  PersonalisationStatisticsByTypeIdVarianceSampleCampaignIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  PersonalisationStatisticsByTypeIdVarianceSampleCampaignIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  PersonalisationStatisticsByTypeIdVarianceSampleIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_SAMPLE_ID_ASC',
  PersonalisationStatisticsByTypeIdVarianceSampleIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_SAMPLE_ID_DESC',
  PersonalisationStatisticsByTypeIdVarianceSampleLawyerIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  PersonalisationStatisticsByTypeIdVarianceSampleLawyerIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  PersonalisationStatisticsByTypeIdVarianceSamplePersValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_SAMPLE_PERS_VALUE_ASC',
  PersonalisationStatisticsByTypeIdVarianceSamplePersValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_SAMPLE_PERS_VALUE_DESC',
  PersonalisationStatisticsByTypeIdVarianceSampleTypeIdAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_SAMPLE_TYPE_ID_ASC',
  PersonalisationStatisticsByTypeIdVarianceSampleTypeIdDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_SAMPLE_TYPE_ID_DESC',
  PersonalisationStatisticsByTypeIdVarianceSampleValueAsc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_SAMPLE_VALUE_ASC',
  PersonalisationStatisticsByTypeIdVarianceSampleValueDesc = 'PERSONALISATION_STATISTICS_BY_TYPE_ID_VARIANCE_SAMPLE_VALUE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RegexAsc = 'REGEX_ASC',
  RegexDesc = 'REGEX_DESC'
}

export type PersonalisationVariancePopulationAggregates = {
  __typename?: 'PersonalisationVariancePopulationAggregates';
  /** Population variance of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigFloat']['output']>;
};

export type PersonalisationVarianceSampleAggregates = {
  __typename?: 'PersonalisationVarianceSampleAggregates';
  /** Sample variance of intValue across the matching connection */
  intValue?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `Personalisation` values. */
export type PersonalisationsConnection = {
  __typename?: 'PersonalisationsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<PersonalisationAggregates>;
  /** A list of edges which contains the `Personalisation` and cursor to aid in pagination. */
  edges: Array<PersonalisationsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<PersonalisationAggregates>>;
  /** A list of `Personalisation` objects. */
  nodes: Array<Maybe<Personalisation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Personalisation` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Personalisation` values. */
export type PersonalisationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<PersonalisationGroupBy>;
  having?: InputMaybe<PersonalisationHavingInput>;
};

/** A `Personalisation` edge in the connection. */
export type PersonalisationsEdge = {
  __typename?: 'PersonalisationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Personalisation` at the end of the edge. */
  node?: Maybe<Personalisation>;
};

/** Methods to use when ordering `Personalisation`. */
export enum PersonalisationsOrderBy {
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  DateValueAsc = 'DATE_VALUE_ASC',
  DateValueDesc = 'DATE_VALUE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IntValueAsc = 'INT_VALUE_ASC',
  IntValueDesc = 'INT_VALUE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StrValueAsc = 'STR_VALUE_ASC',
  StrValueDesc = 'STR_VALUE_DESC',
  TypeIdAsc = 'TYPE_ID_ASC',
  TypeIdDesc = 'TYPE_ID_DESC'
}

/** A connection to a list of `Policy` values. */
export type PoliciesConnection = {
  __typename?: 'PoliciesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<PolicyAggregates>;
  /** A list of edges which contains the `Policy` and cursor to aid in pagination. */
  edges: Array<PoliciesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<PolicyAggregates>>;
  /** A list of `Policy` objects. */
  nodes: Array<Maybe<Policy>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Policy` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Policy` values. */
export type PoliciesConnectionGroupedAggregatesArgs = {
  groupBy: Array<PolicyGroupBy>;
  having?: InputMaybe<PolicyHavingInput>;
};

/** A `Policy` edge in the connection. */
export type PoliciesEdge = {
  __typename?: 'PoliciesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Policy` at the end of the edge. */
  node?: Maybe<Policy>;
};

/** Methods to use when ordering `Policy`. */
export enum PoliciesOrderBy {
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PolicyAgreementsByPolicyIdAverageAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_AVERAGE_AGREED_TIME_ASC',
  PolicyAgreementsByPolicyIdAverageAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_AVERAGE_AGREED_TIME_DESC',
  PolicyAgreementsByPolicyIdAverageIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_AVERAGE_ID_ASC',
  PolicyAgreementsByPolicyIdAverageIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_AVERAGE_ID_DESC',
  PolicyAgreementsByPolicyIdAveragePolicyIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_AVERAGE_POLICY_ID_ASC',
  PolicyAgreementsByPolicyIdAveragePolicyIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_AVERAGE_POLICY_ID_DESC',
  PolicyAgreementsByPolicyIdAverageProfileIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_AVERAGE_PROFILE_ID_ASC',
  PolicyAgreementsByPolicyIdAverageProfileIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_AVERAGE_PROFILE_ID_DESC',
  PolicyAgreementsByPolicyIdCountAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_COUNT_ASC',
  PolicyAgreementsByPolicyIdCountDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_COUNT_DESC',
  PolicyAgreementsByPolicyIdDistinctCountAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_DISTINCT_COUNT_AGREED_TIME_ASC',
  PolicyAgreementsByPolicyIdDistinctCountAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_DISTINCT_COUNT_AGREED_TIME_DESC',
  PolicyAgreementsByPolicyIdDistinctCountIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_DISTINCT_COUNT_ID_ASC',
  PolicyAgreementsByPolicyIdDistinctCountIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_DISTINCT_COUNT_ID_DESC',
  PolicyAgreementsByPolicyIdDistinctCountPolicyIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_DISTINCT_COUNT_POLICY_ID_ASC',
  PolicyAgreementsByPolicyIdDistinctCountPolicyIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_DISTINCT_COUNT_POLICY_ID_DESC',
  PolicyAgreementsByPolicyIdDistinctCountProfileIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_DISTINCT_COUNT_PROFILE_ID_ASC',
  PolicyAgreementsByPolicyIdDistinctCountProfileIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_DISTINCT_COUNT_PROFILE_ID_DESC',
  PolicyAgreementsByPolicyIdMaxAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MAX_AGREED_TIME_ASC',
  PolicyAgreementsByPolicyIdMaxAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MAX_AGREED_TIME_DESC',
  PolicyAgreementsByPolicyIdMaxIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MAX_ID_ASC',
  PolicyAgreementsByPolicyIdMaxIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MAX_ID_DESC',
  PolicyAgreementsByPolicyIdMaxPolicyIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MAX_POLICY_ID_ASC',
  PolicyAgreementsByPolicyIdMaxPolicyIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MAX_POLICY_ID_DESC',
  PolicyAgreementsByPolicyIdMaxProfileIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MAX_PROFILE_ID_ASC',
  PolicyAgreementsByPolicyIdMaxProfileIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MAX_PROFILE_ID_DESC',
  PolicyAgreementsByPolicyIdMinAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MIN_AGREED_TIME_ASC',
  PolicyAgreementsByPolicyIdMinAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MIN_AGREED_TIME_DESC',
  PolicyAgreementsByPolicyIdMinIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MIN_ID_ASC',
  PolicyAgreementsByPolicyIdMinIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MIN_ID_DESC',
  PolicyAgreementsByPolicyIdMinPolicyIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MIN_POLICY_ID_ASC',
  PolicyAgreementsByPolicyIdMinPolicyIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MIN_POLICY_ID_DESC',
  PolicyAgreementsByPolicyIdMinProfileIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MIN_PROFILE_ID_ASC',
  PolicyAgreementsByPolicyIdMinProfileIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_MIN_PROFILE_ID_DESC',
  PolicyAgreementsByPolicyIdStddevPopulationAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_POPULATION_AGREED_TIME_ASC',
  PolicyAgreementsByPolicyIdStddevPopulationAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_POPULATION_AGREED_TIME_DESC',
  PolicyAgreementsByPolicyIdStddevPopulationIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_POPULATION_ID_ASC',
  PolicyAgreementsByPolicyIdStddevPopulationIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_POPULATION_ID_DESC',
  PolicyAgreementsByPolicyIdStddevPopulationPolicyIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_POPULATION_POLICY_ID_ASC',
  PolicyAgreementsByPolicyIdStddevPopulationPolicyIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_POPULATION_POLICY_ID_DESC',
  PolicyAgreementsByPolicyIdStddevPopulationProfileIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_POPULATION_PROFILE_ID_ASC',
  PolicyAgreementsByPolicyIdStddevPopulationProfileIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_POPULATION_PROFILE_ID_DESC',
  PolicyAgreementsByPolicyIdStddevSampleAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_SAMPLE_AGREED_TIME_ASC',
  PolicyAgreementsByPolicyIdStddevSampleAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_SAMPLE_AGREED_TIME_DESC',
  PolicyAgreementsByPolicyIdStddevSampleIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_SAMPLE_ID_ASC',
  PolicyAgreementsByPolicyIdStddevSampleIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_SAMPLE_ID_DESC',
  PolicyAgreementsByPolicyIdStddevSamplePolicyIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_SAMPLE_POLICY_ID_ASC',
  PolicyAgreementsByPolicyIdStddevSamplePolicyIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_SAMPLE_POLICY_ID_DESC',
  PolicyAgreementsByPolicyIdStddevSampleProfileIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_SAMPLE_PROFILE_ID_ASC',
  PolicyAgreementsByPolicyIdStddevSampleProfileIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_STDDEV_SAMPLE_PROFILE_ID_DESC',
  PolicyAgreementsByPolicyIdSumAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_SUM_AGREED_TIME_ASC',
  PolicyAgreementsByPolicyIdSumAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_SUM_AGREED_TIME_DESC',
  PolicyAgreementsByPolicyIdSumIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_SUM_ID_ASC',
  PolicyAgreementsByPolicyIdSumIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_SUM_ID_DESC',
  PolicyAgreementsByPolicyIdSumPolicyIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_SUM_POLICY_ID_ASC',
  PolicyAgreementsByPolicyIdSumPolicyIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_SUM_POLICY_ID_DESC',
  PolicyAgreementsByPolicyIdSumProfileIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_SUM_PROFILE_ID_ASC',
  PolicyAgreementsByPolicyIdSumProfileIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_SUM_PROFILE_ID_DESC',
  PolicyAgreementsByPolicyIdVariancePopulationAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_POPULATION_AGREED_TIME_ASC',
  PolicyAgreementsByPolicyIdVariancePopulationAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_POPULATION_AGREED_TIME_DESC',
  PolicyAgreementsByPolicyIdVariancePopulationIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_POPULATION_ID_ASC',
  PolicyAgreementsByPolicyIdVariancePopulationIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_POPULATION_ID_DESC',
  PolicyAgreementsByPolicyIdVariancePopulationPolicyIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_POPULATION_POLICY_ID_ASC',
  PolicyAgreementsByPolicyIdVariancePopulationPolicyIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_POPULATION_POLICY_ID_DESC',
  PolicyAgreementsByPolicyIdVariancePopulationProfileIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_POPULATION_PROFILE_ID_ASC',
  PolicyAgreementsByPolicyIdVariancePopulationProfileIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_POPULATION_PROFILE_ID_DESC',
  PolicyAgreementsByPolicyIdVarianceSampleAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_SAMPLE_AGREED_TIME_ASC',
  PolicyAgreementsByPolicyIdVarianceSampleAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_SAMPLE_AGREED_TIME_DESC',
  PolicyAgreementsByPolicyIdVarianceSampleIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_SAMPLE_ID_ASC',
  PolicyAgreementsByPolicyIdVarianceSampleIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_SAMPLE_ID_DESC',
  PolicyAgreementsByPolicyIdVarianceSamplePolicyIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_SAMPLE_POLICY_ID_ASC',
  PolicyAgreementsByPolicyIdVarianceSamplePolicyIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_SAMPLE_POLICY_ID_DESC',
  PolicyAgreementsByPolicyIdVarianceSampleProfileIdAsc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  PolicyAgreementsByPolicyIdVarianceSampleProfileIdDesc = 'POLICY_AGREEMENTS_BY_POLICY_ID_VARIANCE_SAMPLE_PROFILE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC'
}

export type Policy = Node & {
  __typename?: 'Policy';
  createdTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads and enables pagination through a set of `PolicyAgreement`. */
  policyAgreementsByPolicyId: PolicyAgreementsConnection;
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
  version: Scalars['String']['output'];
};


export type PolicyPolicyAgreementsByPolicyIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PolicyAgreementCondition>;
  filter?: InputMaybe<PolicyAgreementFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PolicyAgreementsOrderBy>>;
  sort?: InputMaybe<Array<PolicyAgreementSort>>;
};

export type PolicyAggregates = {
  __typename?: 'PolicyAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<PolicyDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

export type PolicyAgreement = Node & {
  __typename?: 'PolicyAgreement';
  agreedTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Policy` that is related to this `PolicyAgreement`. */
  policyByPolicyId?: Maybe<Policy>;
  policyId: Scalars['UUID']['output'];
  /** Reads a single `Profile` that is related to this `PolicyAgreement`. */
  profileByProfileId?: Maybe<Profile>;
  profileId: Scalars['UUID']['output'];
};

export type PolicyAgreementAggregates = {
  __typename?: 'PolicyAgreementAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<PolicyAgreementDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `PolicyAgreement` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PolicyAgreementCondition = {
  /** Checks for equality with the object’s `agreedTime` field. */
  agreedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `policyId` field. */
  policyId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `profileId` field. */
  profileId?: InputMaybe<Scalars['UUID']['input']>;
};

export type PolicyAgreementDistinctCountAggregates = {
  __typename?: 'PolicyAgreementDistinctCountAggregates';
  /** Distinct count of agreedTime across the matching connection */
  agreedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of policyId across the matching connection */
  policyId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of profileId across the matching connection */
  profileId?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `PolicyAgreement` object types. All fields are combined with a logical ‘and.’ */
export type PolicyAgreementFilter = {
  /** Filter by the object’s `agreedTime` field. */
  agreedTime?: InputMaybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PolicyAgreementFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PolicyAgreementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PolicyAgreementFilter>>;
  /** Filter by the object’s `policyId` field. */
  policyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `profileId` field. */
  profileId?: InputMaybe<UuidFilter>;
};

/** Grouping methods for `PolicyAgreement` for usage during aggregation. */
export enum PolicyAgreementGroupBy {
  AgreedTime = 'AGREED_TIME',
  AgreedTimeTruncatedToDay = 'AGREED_TIME_TRUNCATED_TO_DAY',
  AgreedTimeTruncatedToHour = 'AGREED_TIME_TRUNCATED_TO_HOUR',
  PolicyId = 'POLICY_ID',
  ProfileId = 'PROFILE_ID'
}

export type PolicyAgreementHavingAverageInput = {
  agreedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyAgreementHavingDistinctCountInput = {
  agreedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `PolicyAgreement` aggregates. */
export type PolicyAgreementHavingInput = {
  AND?: InputMaybe<Array<PolicyAgreementHavingInput>>;
  OR?: InputMaybe<Array<PolicyAgreementHavingInput>>;
  average?: InputMaybe<PolicyAgreementHavingAverageInput>;
  distinctCount?: InputMaybe<PolicyAgreementHavingDistinctCountInput>;
  max?: InputMaybe<PolicyAgreementHavingMaxInput>;
  min?: InputMaybe<PolicyAgreementHavingMinInput>;
  stddevPopulation?: InputMaybe<PolicyAgreementHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<PolicyAgreementHavingStddevSampleInput>;
  sum?: InputMaybe<PolicyAgreementHavingSumInput>;
  variancePopulation?: InputMaybe<PolicyAgreementHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<PolicyAgreementHavingVarianceSampleInput>;
};

export type PolicyAgreementHavingMaxInput = {
  agreedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyAgreementHavingMinInput = {
  agreedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyAgreementHavingStddevPopulationInput = {
  agreedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyAgreementHavingStddevSampleInput = {
  agreedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyAgreementHavingSumInput = {
  agreedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyAgreementHavingVariancePopulationInput = {
  agreedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyAgreementHavingVarianceSampleInput = {
  agreedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type PolicyAgreementSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: PolicyAgreementSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type PolicyAgreementSortBy = {
  field?: InputMaybe<PolicyAgreementSortableField>;
};

/** Sortable concrete fields for the `PolicyAgreement` type. */
export enum PolicyAgreementSortableField {
  AgreedTime = 'AGREED_TIME',
  Id = 'ID',
  PolicyId = 'POLICY_ID',
  ProfileId = 'PROFILE_ID'
}

/** A connection to a list of `PolicyAgreement` values. */
export type PolicyAgreementsConnection = {
  __typename?: 'PolicyAgreementsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<PolicyAgreementAggregates>;
  /** A list of edges which contains the `PolicyAgreement` and cursor to aid in pagination. */
  edges: Array<PolicyAgreementsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<PolicyAgreementAggregates>>;
  /** A list of `PolicyAgreement` objects. */
  nodes: Array<Maybe<PolicyAgreement>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PolicyAgreement` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `PolicyAgreement` values. */
export type PolicyAgreementsConnectionGroupedAggregatesArgs = {
  groupBy: Array<PolicyAgreementGroupBy>;
  having?: InputMaybe<PolicyAgreementHavingInput>;
};

/** A `PolicyAgreement` edge in the connection. */
export type PolicyAgreementsEdge = {
  __typename?: 'PolicyAgreementsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `PolicyAgreement` at the end of the edge. */
  node?: Maybe<PolicyAgreement>;
};

/** Methods to use when ordering `PolicyAgreement`. */
export enum PolicyAgreementsOrderBy {
  AgreedTimeAsc = 'AGREED_TIME_ASC',
  AgreedTimeDesc = 'AGREED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PolicyIdAsc = 'POLICY_ID_ASC',
  PolicyIdDesc = 'POLICY_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProfileIdAsc = 'PROFILE_ID_ASC',
  ProfileIdDesc = 'PROFILE_ID_DESC'
}

/** A condition to be used against `Policy` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PolicyCondition = {
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `version` field. */
  version?: InputMaybe<Scalars['String']['input']>;
};

export type PolicyDistinctCountAggregates = {
  __typename?: 'PolicyDistinctCountAggregates';
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isActive across the matching connection */
  isActive?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of url across the matching connection */
  url?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of version across the matching connection */
  version?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Policy` object types. All fields are combined with a logical ‘and.’ */
export type PolicyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PolicyFilter>>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PolicyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PolicyFilter>>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `version` field. */
  version?: InputMaybe<StringFilter>;
};

/** Grouping methods for `Policy` for usage during aggregation. */
export enum PolicyGroupBy {
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  IsActive = 'IS_ACTIVE',
  Name = 'NAME',
  Type = 'TYPE',
  Version = 'VERSION'
}

export type PolicyHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Policy` aggregates. */
export type PolicyHavingInput = {
  AND?: InputMaybe<Array<PolicyHavingInput>>;
  OR?: InputMaybe<Array<PolicyHavingInput>>;
  average?: InputMaybe<PolicyHavingAverageInput>;
  distinctCount?: InputMaybe<PolicyHavingDistinctCountInput>;
  max?: InputMaybe<PolicyHavingMaxInput>;
  min?: InputMaybe<PolicyHavingMinInput>;
  stddevPopulation?: InputMaybe<PolicyHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<PolicyHavingStddevSampleInput>;
  sum?: InputMaybe<PolicyHavingSumInput>;
  variancePopulation?: InputMaybe<PolicyHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<PolicyHavingVarianceSampleInput>;
};

export type PolicyHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type PolicyHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type PolicySort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: PolicySortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type PolicySortBy = {
  field?: InputMaybe<PolicySortableField>;
};

/** Sortable concrete fields for the `Policy` type. */
export enum PolicySortableField {
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  IsActive = 'IS_ACTIVE',
  Name = 'NAME',
  Type = 'TYPE',
  Url = 'URL',
  Version = 'VERSION'
}

/** All input for the `processBatch` mutation. */
export type ProcessBatchInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  endId?: InputMaybe<Scalars['UUID']['input']>;
  startId?: InputMaybe<Scalars['UUID']['input']>;
};

/** The output of our `processBatch` mutation. */
export type ProcessBatchPayload = {
  __typename?: 'ProcessBatchPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type Profile = Node & {
  __typename?: 'Profile';
  bio?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['Datetime']['output'];
  email: Scalars['String']['output'];
  firmHistory?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  lastName: Scalars['String']['output'];
  /** Reads a single `Lawyer` that is related to this `Profile`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId?: Maybe<Scalars['UUID']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  photoUrl?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `PolicyAgreement`. */
  policyAgreementsByProfileId: PolicyAgreementsConnection;
  slug?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Trace`. */
  tracesByProfileId: TracesConnection;
  userId: Scalars['UUID']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};


export type ProfilePolicyAgreementsByProfileIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PolicyAgreementCondition>;
  filter?: InputMaybe<PolicyAgreementFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PolicyAgreementsOrderBy>>;
  sort?: InputMaybe<Array<PolicyAgreementSort>>;
};


export type ProfileTracesByProfileIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TraceCondition>;
  filter?: InputMaybe<TraceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TracesOrderBy>>;
  sort?: InputMaybe<Array<TraceSort>>;
};

export type ProfileAggregates = {
  __typename?: 'ProfileAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProfileDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/** A condition to be used against `Profile` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProfileCondition = {
  /** Checks for equality with the object’s `bio` field. */
  bio?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `firmHistory` field. */
  firmHistory?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `photoUrl` field. */
  photoUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `slug` field. */
  slug?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ProfileDistinctCountAggregates = {
  __typename?: 'ProfileDistinctCountAggregates';
  /** Distinct count of bio across the matching connection */
  bio?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of firmHistory across the matching connection */
  firmHistory?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of firstName across the matching connection */
  firstName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastName across the matching connection */
  lastName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of phoneNumber across the matching connection */
  phoneNumber?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of photoUrl across the matching connection */
  photoUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of slug across the matching connection */
  slug?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of websiteUrl across the matching connection */
  websiteUrl?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Profile` object types. All fields are combined with a logical ‘and.’ */
export type ProfileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProfileFilter>>;
  /** Filter by the object’s `bio` field. */
  bio?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firmHistory` field. */
  firmHistory?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProfileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProfileFilter>>;
  /** Filter by the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `photoUrl` field. */
  photoUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `slug` field. */
  slug?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<StringFilter>;
};

/** Grouping methods for `Profile` for usage during aggregation. */
export enum ProfileGroupBy {
  Bio = 'BIO',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  Email = 'EMAIL',
  FirmHistory = 'FIRM_HISTORY',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  LawyerId = 'LAWYER_ID',
  PhoneNumber = 'PHONE_NUMBER',
  PhotoUrl = 'PHOTO_URL',
  Slug = 'SLUG',
  WebsiteUrl = 'WEBSITE_URL'
}

export type ProfileHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ProfileHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Profile` aggregates. */
export type ProfileHavingInput = {
  AND?: InputMaybe<Array<ProfileHavingInput>>;
  OR?: InputMaybe<Array<ProfileHavingInput>>;
  average?: InputMaybe<ProfileHavingAverageInput>;
  distinctCount?: InputMaybe<ProfileHavingDistinctCountInput>;
  max?: InputMaybe<ProfileHavingMaxInput>;
  min?: InputMaybe<ProfileHavingMinInput>;
  stddevPopulation?: InputMaybe<ProfileHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ProfileHavingStddevSampleInput>;
  sum?: InputMaybe<ProfileHavingSumInput>;
  variancePopulation?: InputMaybe<ProfileHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ProfileHavingVarianceSampleInput>;
};

export type ProfileHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ProfileHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ProfileHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ProfileHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ProfileHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ProfileHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ProfileHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type ProfileSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: ProfileSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type ProfileSortBy = {
  field?: InputMaybe<ProfileSortableField>;
};

/** Sortable concrete fields for the `Profile` type. */
export enum ProfileSortableField {
  Bio = 'BIO',
  CreatedTime = 'CREATED_TIME',
  Email = 'EMAIL',
  FirmHistory = 'FIRM_HISTORY',
  FirstName = 'FIRST_NAME',
  Id = 'ID',
  LastName = 'LAST_NAME',
  LawyerId = 'LAWYER_ID',
  PhoneNumber = 'PHONE_NUMBER',
  PhotoUrl = 'PHOTO_URL',
  Slug = 'SLUG',
  UserId = 'USER_ID',
  WebsiteUrl = 'WEBSITE_URL'
}

/** A connection to a list of `Profile` values. */
export type ProfilesConnection = {
  __typename?: 'ProfilesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProfileAggregates>;
  /** A list of edges which contains the `Profile` and cursor to aid in pagination. */
  edges: Array<ProfilesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProfileAggregates>>;
  /** A list of `Profile` objects. */
  nodes: Array<Maybe<Profile>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Profile` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Profile` values. */
export type ProfilesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ProfileGroupBy>;
  having?: InputMaybe<ProfileHavingInput>;
};

/** A `Profile` edge in the connection. */
export type ProfilesEdge = {
  __typename?: 'ProfilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Profile` at the end of the edge. */
  node?: Maybe<Profile>;
};

/** Methods to use when ordering `Profile`. */
export enum ProfilesOrderBy {
  BioAsc = 'BIO_ASC',
  BioDesc = 'BIO_DESC',
  CampaignAuditLogsByProfileIdAverageAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_AVERAGE_AFTER_CHANGE_ASC',
  CampaignAuditLogsByProfileIdAverageAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_AVERAGE_AFTER_CHANGE_DESC',
  CampaignAuditLogsByProfileIdAverageBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_AVERAGE_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByProfileIdAverageBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_AVERAGE_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByProfileIdAverageCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_AVERAGE_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByProfileIdAverageCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_AVERAGE_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByProfileIdAverageCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_AVERAGE_CREATED_TIME_ASC',
  CampaignAuditLogsByProfileIdAverageCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_AVERAGE_CREATED_TIME_DESC',
  CampaignAuditLogsByProfileIdAverageIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_AVERAGE_ID_ASC',
  CampaignAuditLogsByProfileIdAverageIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_AVERAGE_ID_DESC',
  CampaignAuditLogsByProfileIdAverageProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_AVERAGE_PROFILE_ID_ASC',
  CampaignAuditLogsByProfileIdAverageProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_AVERAGE_PROFILE_ID_DESC',
  CampaignAuditLogsByProfileIdCountAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_COUNT_ASC',
  CampaignAuditLogsByProfileIdCountDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_COUNT_DESC',
  CampaignAuditLogsByProfileIdDistinctCountAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_DISTINCT_COUNT_AFTER_CHANGE_ASC',
  CampaignAuditLogsByProfileIdDistinctCountAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_DISTINCT_COUNT_AFTER_CHANGE_DESC',
  CampaignAuditLogsByProfileIdDistinctCountBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_DISTINCT_COUNT_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByProfileIdDistinctCountBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_DISTINCT_COUNT_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByProfileIdDistinctCountCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByProfileIdDistinctCountCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByProfileIdDistinctCountCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CampaignAuditLogsByProfileIdDistinctCountCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CampaignAuditLogsByProfileIdDistinctCountIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_DISTINCT_COUNT_ID_ASC',
  CampaignAuditLogsByProfileIdDistinctCountIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_DISTINCT_COUNT_ID_DESC',
  CampaignAuditLogsByProfileIdDistinctCountProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_DISTINCT_COUNT_PROFILE_ID_ASC',
  CampaignAuditLogsByProfileIdDistinctCountProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_DISTINCT_COUNT_PROFILE_ID_DESC',
  CampaignAuditLogsByProfileIdMaxAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MAX_AFTER_CHANGE_ASC',
  CampaignAuditLogsByProfileIdMaxAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MAX_AFTER_CHANGE_DESC',
  CampaignAuditLogsByProfileIdMaxBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MAX_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByProfileIdMaxBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MAX_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByProfileIdMaxCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MAX_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByProfileIdMaxCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MAX_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByProfileIdMaxCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MAX_CREATED_TIME_ASC',
  CampaignAuditLogsByProfileIdMaxCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MAX_CREATED_TIME_DESC',
  CampaignAuditLogsByProfileIdMaxIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MAX_ID_ASC',
  CampaignAuditLogsByProfileIdMaxIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MAX_ID_DESC',
  CampaignAuditLogsByProfileIdMaxProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MAX_PROFILE_ID_ASC',
  CampaignAuditLogsByProfileIdMaxProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MAX_PROFILE_ID_DESC',
  CampaignAuditLogsByProfileIdMinAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MIN_AFTER_CHANGE_ASC',
  CampaignAuditLogsByProfileIdMinAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MIN_AFTER_CHANGE_DESC',
  CampaignAuditLogsByProfileIdMinBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MIN_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByProfileIdMinBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MIN_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByProfileIdMinCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MIN_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByProfileIdMinCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MIN_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByProfileIdMinCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MIN_CREATED_TIME_ASC',
  CampaignAuditLogsByProfileIdMinCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MIN_CREATED_TIME_DESC',
  CampaignAuditLogsByProfileIdMinIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MIN_ID_ASC',
  CampaignAuditLogsByProfileIdMinIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MIN_ID_DESC',
  CampaignAuditLogsByProfileIdMinProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MIN_PROFILE_ID_ASC',
  CampaignAuditLogsByProfileIdMinProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_MIN_PROFILE_ID_DESC',
  CampaignAuditLogsByProfileIdStddevPopulationAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_POPULATION_AFTER_CHANGE_ASC',
  CampaignAuditLogsByProfileIdStddevPopulationAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_POPULATION_AFTER_CHANGE_DESC',
  CampaignAuditLogsByProfileIdStddevPopulationBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_POPULATION_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByProfileIdStddevPopulationBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_POPULATION_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByProfileIdStddevPopulationCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByProfileIdStddevPopulationCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByProfileIdStddevPopulationCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CampaignAuditLogsByProfileIdStddevPopulationCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CampaignAuditLogsByProfileIdStddevPopulationIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_POPULATION_ID_ASC',
  CampaignAuditLogsByProfileIdStddevPopulationIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_POPULATION_ID_DESC',
  CampaignAuditLogsByProfileIdStddevPopulationProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_POPULATION_PROFILE_ID_ASC',
  CampaignAuditLogsByProfileIdStddevPopulationProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_POPULATION_PROFILE_ID_DESC',
  CampaignAuditLogsByProfileIdStddevSampleAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_SAMPLE_AFTER_CHANGE_ASC',
  CampaignAuditLogsByProfileIdStddevSampleAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_SAMPLE_AFTER_CHANGE_DESC',
  CampaignAuditLogsByProfileIdStddevSampleBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_SAMPLE_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByProfileIdStddevSampleBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_SAMPLE_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByProfileIdStddevSampleCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByProfileIdStddevSampleCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByProfileIdStddevSampleCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CampaignAuditLogsByProfileIdStddevSampleCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CampaignAuditLogsByProfileIdStddevSampleIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_SAMPLE_ID_ASC',
  CampaignAuditLogsByProfileIdStddevSampleIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_SAMPLE_ID_DESC',
  CampaignAuditLogsByProfileIdStddevSampleProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_SAMPLE_PROFILE_ID_ASC',
  CampaignAuditLogsByProfileIdStddevSampleProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_STDDEV_SAMPLE_PROFILE_ID_DESC',
  CampaignAuditLogsByProfileIdSumAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_SUM_AFTER_CHANGE_ASC',
  CampaignAuditLogsByProfileIdSumAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_SUM_AFTER_CHANGE_DESC',
  CampaignAuditLogsByProfileIdSumBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_SUM_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByProfileIdSumBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_SUM_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByProfileIdSumCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_SUM_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByProfileIdSumCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_SUM_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByProfileIdSumCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_SUM_CREATED_TIME_ASC',
  CampaignAuditLogsByProfileIdSumCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_SUM_CREATED_TIME_DESC',
  CampaignAuditLogsByProfileIdSumIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_SUM_ID_ASC',
  CampaignAuditLogsByProfileIdSumIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_SUM_ID_DESC',
  CampaignAuditLogsByProfileIdSumProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_SUM_PROFILE_ID_ASC',
  CampaignAuditLogsByProfileIdSumProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_SUM_PROFILE_ID_DESC',
  CampaignAuditLogsByProfileIdVariancePopulationAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_POPULATION_AFTER_CHANGE_ASC',
  CampaignAuditLogsByProfileIdVariancePopulationAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_POPULATION_AFTER_CHANGE_DESC',
  CampaignAuditLogsByProfileIdVariancePopulationBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_POPULATION_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByProfileIdVariancePopulationBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_POPULATION_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByProfileIdVariancePopulationCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByProfileIdVariancePopulationCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByProfileIdVariancePopulationCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CampaignAuditLogsByProfileIdVariancePopulationCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CampaignAuditLogsByProfileIdVariancePopulationIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_POPULATION_ID_ASC',
  CampaignAuditLogsByProfileIdVariancePopulationIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_POPULATION_ID_DESC',
  CampaignAuditLogsByProfileIdVariancePopulationProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_POPULATION_PROFILE_ID_ASC',
  CampaignAuditLogsByProfileIdVariancePopulationProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_POPULATION_PROFILE_ID_DESC',
  CampaignAuditLogsByProfileIdVarianceSampleAfterChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_SAMPLE_AFTER_CHANGE_ASC',
  CampaignAuditLogsByProfileIdVarianceSampleAfterChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_SAMPLE_AFTER_CHANGE_DESC',
  CampaignAuditLogsByProfileIdVarianceSampleBeforeChangeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_SAMPLE_BEFORE_CHANGE_ASC',
  CampaignAuditLogsByProfileIdVarianceSampleBeforeChangeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_SAMPLE_BEFORE_CHANGE_DESC',
  CampaignAuditLogsByProfileIdVarianceSampleCampaignIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  CampaignAuditLogsByProfileIdVarianceSampleCampaignIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  CampaignAuditLogsByProfileIdVarianceSampleCreatedTimeAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CampaignAuditLogsByProfileIdVarianceSampleCreatedTimeDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CampaignAuditLogsByProfileIdVarianceSampleIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_SAMPLE_ID_ASC',
  CampaignAuditLogsByProfileIdVarianceSampleIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_SAMPLE_ID_DESC',
  CampaignAuditLogsByProfileIdVarianceSampleProfileIdAsc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  CampaignAuditLogsByProfileIdVarianceSampleProfileIdDesc = 'CAMPAIGN_AUDIT_LOGS_BY_PROFILE_ID_VARIANCE_SAMPLE_PROFILE_ID_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FirmHistoryAsc = 'FIRM_HISTORY_ASC',
  FirmHistoryDesc = 'FIRM_HISTORY_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  Natural = 'NATURAL',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  PhotoUrlAsc = 'PHOTO_URL_ASC',
  PhotoUrlDesc = 'PHOTO_URL_DESC',
  PolicyAgreementsByProfileIdAverageAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_AVERAGE_AGREED_TIME_ASC',
  PolicyAgreementsByProfileIdAverageAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_AVERAGE_AGREED_TIME_DESC',
  PolicyAgreementsByProfileIdAverageIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_AVERAGE_ID_ASC',
  PolicyAgreementsByProfileIdAverageIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_AVERAGE_ID_DESC',
  PolicyAgreementsByProfileIdAveragePolicyIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_AVERAGE_POLICY_ID_ASC',
  PolicyAgreementsByProfileIdAveragePolicyIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_AVERAGE_POLICY_ID_DESC',
  PolicyAgreementsByProfileIdAverageProfileIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_AVERAGE_PROFILE_ID_ASC',
  PolicyAgreementsByProfileIdAverageProfileIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_AVERAGE_PROFILE_ID_DESC',
  PolicyAgreementsByProfileIdCountAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_COUNT_ASC',
  PolicyAgreementsByProfileIdCountDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_COUNT_DESC',
  PolicyAgreementsByProfileIdDistinctCountAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_DISTINCT_COUNT_AGREED_TIME_ASC',
  PolicyAgreementsByProfileIdDistinctCountAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_DISTINCT_COUNT_AGREED_TIME_DESC',
  PolicyAgreementsByProfileIdDistinctCountIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_DISTINCT_COUNT_ID_ASC',
  PolicyAgreementsByProfileIdDistinctCountIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_DISTINCT_COUNT_ID_DESC',
  PolicyAgreementsByProfileIdDistinctCountPolicyIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_DISTINCT_COUNT_POLICY_ID_ASC',
  PolicyAgreementsByProfileIdDistinctCountPolicyIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_DISTINCT_COUNT_POLICY_ID_DESC',
  PolicyAgreementsByProfileIdDistinctCountProfileIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_DISTINCT_COUNT_PROFILE_ID_ASC',
  PolicyAgreementsByProfileIdDistinctCountProfileIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_DISTINCT_COUNT_PROFILE_ID_DESC',
  PolicyAgreementsByProfileIdMaxAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MAX_AGREED_TIME_ASC',
  PolicyAgreementsByProfileIdMaxAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MAX_AGREED_TIME_DESC',
  PolicyAgreementsByProfileIdMaxIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MAX_ID_ASC',
  PolicyAgreementsByProfileIdMaxIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MAX_ID_DESC',
  PolicyAgreementsByProfileIdMaxPolicyIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MAX_POLICY_ID_ASC',
  PolicyAgreementsByProfileIdMaxPolicyIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MAX_POLICY_ID_DESC',
  PolicyAgreementsByProfileIdMaxProfileIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MAX_PROFILE_ID_ASC',
  PolicyAgreementsByProfileIdMaxProfileIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MAX_PROFILE_ID_DESC',
  PolicyAgreementsByProfileIdMinAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MIN_AGREED_TIME_ASC',
  PolicyAgreementsByProfileIdMinAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MIN_AGREED_TIME_DESC',
  PolicyAgreementsByProfileIdMinIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MIN_ID_ASC',
  PolicyAgreementsByProfileIdMinIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MIN_ID_DESC',
  PolicyAgreementsByProfileIdMinPolicyIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MIN_POLICY_ID_ASC',
  PolicyAgreementsByProfileIdMinPolicyIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MIN_POLICY_ID_DESC',
  PolicyAgreementsByProfileIdMinProfileIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MIN_PROFILE_ID_ASC',
  PolicyAgreementsByProfileIdMinProfileIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_MIN_PROFILE_ID_DESC',
  PolicyAgreementsByProfileIdStddevPopulationAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_POPULATION_AGREED_TIME_ASC',
  PolicyAgreementsByProfileIdStddevPopulationAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_POPULATION_AGREED_TIME_DESC',
  PolicyAgreementsByProfileIdStddevPopulationIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_POPULATION_ID_ASC',
  PolicyAgreementsByProfileIdStddevPopulationIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_POPULATION_ID_DESC',
  PolicyAgreementsByProfileIdStddevPopulationPolicyIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_POPULATION_POLICY_ID_ASC',
  PolicyAgreementsByProfileIdStddevPopulationPolicyIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_POPULATION_POLICY_ID_DESC',
  PolicyAgreementsByProfileIdStddevPopulationProfileIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_POPULATION_PROFILE_ID_ASC',
  PolicyAgreementsByProfileIdStddevPopulationProfileIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_POPULATION_PROFILE_ID_DESC',
  PolicyAgreementsByProfileIdStddevSampleAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_SAMPLE_AGREED_TIME_ASC',
  PolicyAgreementsByProfileIdStddevSampleAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_SAMPLE_AGREED_TIME_DESC',
  PolicyAgreementsByProfileIdStddevSampleIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_SAMPLE_ID_ASC',
  PolicyAgreementsByProfileIdStddevSampleIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_SAMPLE_ID_DESC',
  PolicyAgreementsByProfileIdStddevSamplePolicyIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_SAMPLE_POLICY_ID_ASC',
  PolicyAgreementsByProfileIdStddevSamplePolicyIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_SAMPLE_POLICY_ID_DESC',
  PolicyAgreementsByProfileIdStddevSampleProfileIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_SAMPLE_PROFILE_ID_ASC',
  PolicyAgreementsByProfileIdStddevSampleProfileIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_STDDEV_SAMPLE_PROFILE_ID_DESC',
  PolicyAgreementsByProfileIdSumAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_SUM_AGREED_TIME_ASC',
  PolicyAgreementsByProfileIdSumAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_SUM_AGREED_TIME_DESC',
  PolicyAgreementsByProfileIdSumIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_SUM_ID_ASC',
  PolicyAgreementsByProfileIdSumIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_SUM_ID_DESC',
  PolicyAgreementsByProfileIdSumPolicyIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_SUM_POLICY_ID_ASC',
  PolicyAgreementsByProfileIdSumPolicyIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_SUM_POLICY_ID_DESC',
  PolicyAgreementsByProfileIdSumProfileIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_SUM_PROFILE_ID_ASC',
  PolicyAgreementsByProfileIdSumProfileIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_SUM_PROFILE_ID_DESC',
  PolicyAgreementsByProfileIdVariancePopulationAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_POPULATION_AGREED_TIME_ASC',
  PolicyAgreementsByProfileIdVariancePopulationAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_POPULATION_AGREED_TIME_DESC',
  PolicyAgreementsByProfileIdVariancePopulationIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_POPULATION_ID_ASC',
  PolicyAgreementsByProfileIdVariancePopulationIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_POPULATION_ID_DESC',
  PolicyAgreementsByProfileIdVariancePopulationPolicyIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_POPULATION_POLICY_ID_ASC',
  PolicyAgreementsByProfileIdVariancePopulationPolicyIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_POPULATION_POLICY_ID_DESC',
  PolicyAgreementsByProfileIdVariancePopulationProfileIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_POPULATION_PROFILE_ID_ASC',
  PolicyAgreementsByProfileIdVariancePopulationProfileIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_POPULATION_PROFILE_ID_DESC',
  PolicyAgreementsByProfileIdVarianceSampleAgreedTimeAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_SAMPLE_AGREED_TIME_ASC',
  PolicyAgreementsByProfileIdVarianceSampleAgreedTimeDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_SAMPLE_AGREED_TIME_DESC',
  PolicyAgreementsByProfileIdVarianceSampleIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_SAMPLE_ID_ASC',
  PolicyAgreementsByProfileIdVarianceSampleIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_SAMPLE_ID_DESC',
  PolicyAgreementsByProfileIdVarianceSamplePolicyIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_SAMPLE_POLICY_ID_ASC',
  PolicyAgreementsByProfileIdVarianceSamplePolicyIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_SAMPLE_POLICY_ID_DESC',
  PolicyAgreementsByProfileIdVarianceSampleProfileIdAsc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  PolicyAgreementsByProfileIdVarianceSampleProfileIdDesc = 'POLICY_AGREEMENTS_BY_PROFILE_ID_VARIANCE_SAMPLE_PROFILE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  SlugAsc = 'SLUG_ASC',
  SlugDesc = 'SLUG_DESC',
  TracesByProfileIdAverageActionAsc = 'TRACES_BY_PROFILE_ID_AVERAGE_ACTION_ASC',
  TracesByProfileIdAverageActionDesc = 'TRACES_BY_PROFILE_ID_AVERAGE_ACTION_DESC',
  TracesByProfileIdAverageCampaignMemberIdAsc = 'TRACES_BY_PROFILE_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  TracesByProfileIdAverageCampaignMemberIdDesc = 'TRACES_BY_PROFILE_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  TracesByProfileIdAverageCreatedTimeAsc = 'TRACES_BY_PROFILE_ID_AVERAGE_CREATED_TIME_ASC',
  TracesByProfileIdAverageCreatedTimeDesc = 'TRACES_BY_PROFILE_ID_AVERAGE_CREATED_TIME_DESC',
  TracesByProfileIdAverageEmailMessageIdAsc = 'TRACES_BY_PROFILE_ID_AVERAGE_EMAIL_MESSAGE_ID_ASC',
  TracesByProfileIdAverageEmailMessageIdDesc = 'TRACES_BY_PROFILE_ID_AVERAGE_EMAIL_MESSAGE_ID_DESC',
  TracesByProfileIdAverageIdAsc = 'TRACES_BY_PROFILE_ID_AVERAGE_ID_ASC',
  TracesByProfileIdAverageIdDesc = 'TRACES_BY_PROFILE_ID_AVERAGE_ID_DESC',
  TracesByProfileIdAverageProfileIdAsc = 'TRACES_BY_PROFILE_ID_AVERAGE_PROFILE_ID_ASC',
  TracesByProfileIdAverageProfileIdDesc = 'TRACES_BY_PROFILE_ID_AVERAGE_PROFILE_ID_DESC',
  TracesByProfileIdAverageUpdatedTimeAsc = 'TRACES_BY_PROFILE_ID_AVERAGE_UPDATED_TIME_ASC',
  TracesByProfileIdAverageUpdatedTimeDesc = 'TRACES_BY_PROFILE_ID_AVERAGE_UPDATED_TIME_DESC',
  TracesByProfileIdCountAsc = 'TRACES_BY_PROFILE_ID_COUNT_ASC',
  TracesByProfileIdCountDesc = 'TRACES_BY_PROFILE_ID_COUNT_DESC',
  TracesByProfileIdDistinctCountActionAsc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_ACTION_ASC',
  TracesByProfileIdDistinctCountActionDesc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_ACTION_DESC',
  TracesByProfileIdDistinctCountCampaignMemberIdAsc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  TracesByProfileIdDistinctCountCampaignMemberIdDesc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  TracesByProfileIdDistinctCountCreatedTimeAsc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  TracesByProfileIdDistinctCountCreatedTimeDesc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  TracesByProfileIdDistinctCountEmailMessageIdAsc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_EMAIL_MESSAGE_ID_ASC',
  TracesByProfileIdDistinctCountEmailMessageIdDesc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_EMAIL_MESSAGE_ID_DESC',
  TracesByProfileIdDistinctCountIdAsc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_ID_ASC',
  TracesByProfileIdDistinctCountIdDesc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_ID_DESC',
  TracesByProfileIdDistinctCountProfileIdAsc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_PROFILE_ID_ASC',
  TracesByProfileIdDistinctCountProfileIdDesc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_PROFILE_ID_DESC',
  TracesByProfileIdDistinctCountUpdatedTimeAsc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  TracesByProfileIdDistinctCountUpdatedTimeDesc = 'TRACES_BY_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  TracesByProfileIdMaxActionAsc = 'TRACES_BY_PROFILE_ID_MAX_ACTION_ASC',
  TracesByProfileIdMaxActionDesc = 'TRACES_BY_PROFILE_ID_MAX_ACTION_DESC',
  TracesByProfileIdMaxCampaignMemberIdAsc = 'TRACES_BY_PROFILE_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  TracesByProfileIdMaxCampaignMemberIdDesc = 'TRACES_BY_PROFILE_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  TracesByProfileIdMaxCreatedTimeAsc = 'TRACES_BY_PROFILE_ID_MAX_CREATED_TIME_ASC',
  TracesByProfileIdMaxCreatedTimeDesc = 'TRACES_BY_PROFILE_ID_MAX_CREATED_TIME_DESC',
  TracesByProfileIdMaxEmailMessageIdAsc = 'TRACES_BY_PROFILE_ID_MAX_EMAIL_MESSAGE_ID_ASC',
  TracesByProfileIdMaxEmailMessageIdDesc = 'TRACES_BY_PROFILE_ID_MAX_EMAIL_MESSAGE_ID_DESC',
  TracesByProfileIdMaxIdAsc = 'TRACES_BY_PROFILE_ID_MAX_ID_ASC',
  TracesByProfileIdMaxIdDesc = 'TRACES_BY_PROFILE_ID_MAX_ID_DESC',
  TracesByProfileIdMaxProfileIdAsc = 'TRACES_BY_PROFILE_ID_MAX_PROFILE_ID_ASC',
  TracesByProfileIdMaxProfileIdDesc = 'TRACES_BY_PROFILE_ID_MAX_PROFILE_ID_DESC',
  TracesByProfileIdMaxUpdatedTimeAsc = 'TRACES_BY_PROFILE_ID_MAX_UPDATED_TIME_ASC',
  TracesByProfileIdMaxUpdatedTimeDesc = 'TRACES_BY_PROFILE_ID_MAX_UPDATED_TIME_DESC',
  TracesByProfileIdMinActionAsc = 'TRACES_BY_PROFILE_ID_MIN_ACTION_ASC',
  TracesByProfileIdMinActionDesc = 'TRACES_BY_PROFILE_ID_MIN_ACTION_DESC',
  TracesByProfileIdMinCampaignMemberIdAsc = 'TRACES_BY_PROFILE_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  TracesByProfileIdMinCampaignMemberIdDesc = 'TRACES_BY_PROFILE_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  TracesByProfileIdMinCreatedTimeAsc = 'TRACES_BY_PROFILE_ID_MIN_CREATED_TIME_ASC',
  TracesByProfileIdMinCreatedTimeDesc = 'TRACES_BY_PROFILE_ID_MIN_CREATED_TIME_DESC',
  TracesByProfileIdMinEmailMessageIdAsc = 'TRACES_BY_PROFILE_ID_MIN_EMAIL_MESSAGE_ID_ASC',
  TracesByProfileIdMinEmailMessageIdDesc = 'TRACES_BY_PROFILE_ID_MIN_EMAIL_MESSAGE_ID_DESC',
  TracesByProfileIdMinIdAsc = 'TRACES_BY_PROFILE_ID_MIN_ID_ASC',
  TracesByProfileIdMinIdDesc = 'TRACES_BY_PROFILE_ID_MIN_ID_DESC',
  TracesByProfileIdMinProfileIdAsc = 'TRACES_BY_PROFILE_ID_MIN_PROFILE_ID_ASC',
  TracesByProfileIdMinProfileIdDesc = 'TRACES_BY_PROFILE_ID_MIN_PROFILE_ID_DESC',
  TracesByProfileIdMinUpdatedTimeAsc = 'TRACES_BY_PROFILE_ID_MIN_UPDATED_TIME_ASC',
  TracesByProfileIdMinUpdatedTimeDesc = 'TRACES_BY_PROFILE_ID_MIN_UPDATED_TIME_DESC',
  TracesByProfileIdStddevPopulationActionAsc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_ACTION_ASC',
  TracesByProfileIdStddevPopulationActionDesc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_ACTION_DESC',
  TracesByProfileIdStddevPopulationCampaignMemberIdAsc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  TracesByProfileIdStddevPopulationCampaignMemberIdDesc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  TracesByProfileIdStddevPopulationCreatedTimeAsc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  TracesByProfileIdStddevPopulationCreatedTimeDesc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  TracesByProfileIdStddevPopulationEmailMessageIdAsc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_EMAIL_MESSAGE_ID_ASC',
  TracesByProfileIdStddevPopulationEmailMessageIdDesc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_EMAIL_MESSAGE_ID_DESC',
  TracesByProfileIdStddevPopulationIdAsc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_ID_ASC',
  TracesByProfileIdStddevPopulationIdDesc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_ID_DESC',
  TracesByProfileIdStddevPopulationProfileIdAsc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_PROFILE_ID_ASC',
  TracesByProfileIdStddevPopulationProfileIdDesc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_PROFILE_ID_DESC',
  TracesByProfileIdStddevPopulationUpdatedTimeAsc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  TracesByProfileIdStddevPopulationUpdatedTimeDesc = 'TRACES_BY_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  TracesByProfileIdStddevSampleActionAsc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_ACTION_ASC',
  TracesByProfileIdStddevSampleActionDesc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_ACTION_DESC',
  TracesByProfileIdStddevSampleCampaignMemberIdAsc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  TracesByProfileIdStddevSampleCampaignMemberIdDesc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  TracesByProfileIdStddevSampleCreatedTimeAsc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  TracesByProfileIdStddevSampleCreatedTimeDesc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  TracesByProfileIdStddevSampleEmailMessageIdAsc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  TracesByProfileIdStddevSampleEmailMessageIdDesc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  TracesByProfileIdStddevSampleIdAsc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_ID_ASC',
  TracesByProfileIdStddevSampleIdDesc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_ID_DESC',
  TracesByProfileIdStddevSampleProfileIdAsc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_PROFILE_ID_ASC',
  TracesByProfileIdStddevSampleProfileIdDesc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_PROFILE_ID_DESC',
  TracesByProfileIdStddevSampleUpdatedTimeAsc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  TracesByProfileIdStddevSampleUpdatedTimeDesc = 'TRACES_BY_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  TracesByProfileIdSumActionAsc = 'TRACES_BY_PROFILE_ID_SUM_ACTION_ASC',
  TracesByProfileIdSumActionDesc = 'TRACES_BY_PROFILE_ID_SUM_ACTION_DESC',
  TracesByProfileIdSumCampaignMemberIdAsc = 'TRACES_BY_PROFILE_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  TracesByProfileIdSumCampaignMemberIdDesc = 'TRACES_BY_PROFILE_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  TracesByProfileIdSumCreatedTimeAsc = 'TRACES_BY_PROFILE_ID_SUM_CREATED_TIME_ASC',
  TracesByProfileIdSumCreatedTimeDesc = 'TRACES_BY_PROFILE_ID_SUM_CREATED_TIME_DESC',
  TracesByProfileIdSumEmailMessageIdAsc = 'TRACES_BY_PROFILE_ID_SUM_EMAIL_MESSAGE_ID_ASC',
  TracesByProfileIdSumEmailMessageIdDesc = 'TRACES_BY_PROFILE_ID_SUM_EMAIL_MESSAGE_ID_DESC',
  TracesByProfileIdSumIdAsc = 'TRACES_BY_PROFILE_ID_SUM_ID_ASC',
  TracesByProfileIdSumIdDesc = 'TRACES_BY_PROFILE_ID_SUM_ID_DESC',
  TracesByProfileIdSumProfileIdAsc = 'TRACES_BY_PROFILE_ID_SUM_PROFILE_ID_ASC',
  TracesByProfileIdSumProfileIdDesc = 'TRACES_BY_PROFILE_ID_SUM_PROFILE_ID_DESC',
  TracesByProfileIdSumUpdatedTimeAsc = 'TRACES_BY_PROFILE_ID_SUM_UPDATED_TIME_ASC',
  TracesByProfileIdSumUpdatedTimeDesc = 'TRACES_BY_PROFILE_ID_SUM_UPDATED_TIME_DESC',
  TracesByProfileIdVariancePopulationActionAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_ACTION_ASC',
  TracesByProfileIdVariancePopulationActionDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_ACTION_DESC',
  TracesByProfileIdVariancePopulationCampaignMemberIdAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  TracesByProfileIdVariancePopulationCampaignMemberIdDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  TracesByProfileIdVariancePopulationCreatedTimeAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  TracesByProfileIdVariancePopulationCreatedTimeDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  TracesByProfileIdVariancePopulationEmailMessageIdAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_ASC',
  TracesByProfileIdVariancePopulationEmailMessageIdDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_EMAIL_MESSAGE_ID_DESC',
  TracesByProfileIdVariancePopulationIdAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_ID_ASC',
  TracesByProfileIdVariancePopulationIdDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_ID_DESC',
  TracesByProfileIdVariancePopulationProfileIdAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_PROFILE_ID_ASC',
  TracesByProfileIdVariancePopulationProfileIdDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_PROFILE_ID_DESC',
  TracesByProfileIdVariancePopulationUpdatedTimeAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  TracesByProfileIdVariancePopulationUpdatedTimeDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  TracesByProfileIdVarianceSampleActionAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_ACTION_ASC',
  TracesByProfileIdVarianceSampleActionDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_ACTION_DESC',
  TracesByProfileIdVarianceSampleCampaignMemberIdAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  TracesByProfileIdVarianceSampleCampaignMemberIdDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  TracesByProfileIdVarianceSampleCreatedTimeAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  TracesByProfileIdVarianceSampleCreatedTimeDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  TracesByProfileIdVarianceSampleEmailMessageIdAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_ASC',
  TracesByProfileIdVarianceSampleEmailMessageIdDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_EMAIL_MESSAGE_ID_DESC',
  TracesByProfileIdVarianceSampleIdAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_ID_ASC',
  TracesByProfileIdVarianceSampleIdDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_ID_DESC',
  TracesByProfileIdVarianceSampleProfileIdAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_PROFILE_ID_ASC',
  TracesByProfileIdVarianceSampleProfileIdDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_PROFILE_ID_DESC',
  TracesByProfileIdVarianceSampleUpdatedTimeAsc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  TracesByProfileIdVarianceSampleUpdatedTimeDesc = 'TRACES_BY_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  WebsiteUrlAsc = 'WEBSITE_URL_ASC',
  WebsiteUrlDesc = 'WEBSITE_URL_DESC'
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /** Reads and enables pagination through a set of `BlacklistedOutreach`. */
  allBlacklistedOutreaches?: Maybe<BlacklistedOutreachesConnection>;
  /** Reads and enables pagination through a set of `CampaignMemberCharge`. */
  allCampaignMemberCharges?: Maybe<CampaignMemberChargesConnection>;
  /** Reads and enables pagination through a set of `CampaignMemberFeedback`. */
  allCampaignMemberFeedbacks?: Maybe<CampaignMemberFeedbacksConnection>;
  /** Reads and enables pagination through a set of `CampaignMemberMeeting`. */
  allCampaignMemberMeetings?: Maybe<CampaignMemberMeetingsConnection>;
  /** Reads and enables pagination through a set of `CampaignMember`. */
  allCampaignMembers?: Maybe<CampaignMembersConnection>;
  /** Reads and enables pagination through a set of `Campaign`. */
  allCampaigns?: Maybe<CampaignsConnection>;
  /** Reads and enables pagination through a set of `Client`. */
  allClients?: Maybe<ClientsConnection>;
  /** Reads and enables pagination through a set of `Company`. */
  allCompanies?: Maybe<CompaniesConnection>;
  /** Reads and enables pagination through a set of `CompanyPersonalisation`. */
  allCompanyPersonalisations?: Maybe<CompanyPersonalisationsConnection>;
  /** Reads and enables pagination through a set of `Contact`. */
  allContacts?: Maybe<ContactsConnection>;
  /** Reads and enables pagination through a set of `CronofyAuth`. */
  allCronofyAuths?: Maybe<CronofyAuthsConnection>;
  /** Reads and enables pagination through a set of `DailyStatistic`. */
  allDailyStatistics?: Maybe<DailyStatisticsConnection>;
  /** Reads and enables pagination through a set of `EmailEvent`. */
  allEmailEvents?: Maybe<EmailEventsConnection>;
  /** Reads and enables pagination through a set of `EmailMessage`. */
  allEmailMessages?: Maybe<EmailMessagesConnection>;
  /** Reads and enables pagination through a set of `EmailPersonalisation`. */
  allEmailPersonalisations?: Maybe<EmailPersonalisationsConnection>;
  /** Reads and enables pagination through a set of `Email`. */
  allEmails?: Maybe<EmailsConnection>;
  /** Reads and enables pagination through a set of `HedwigIdentity`. */
  allHedwigIdentities?: Maybe<HedwigIdentitiesConnection>;
  /** Reads and enables pagination through a set of `HedwigMessagePayload`. */
  allHedwigMessagePayloads?: Maybe<HedwigMessagePayloadsConnection>;
  /** Reads and enables pagination through a set of `LawyerSetting`. */
  allLawyerSettings?: Maybe<LawyerSettingsConnection>;
  /** Reads and enables pagination through a set of `LawyerTag`. */
  allLawyerTags?: Maybe<LawyerTagsConnection>;
  /** Reads and enables pagination through a set of `Lawyer`. */
  allLawyers?: Maybe<LawyersConnection>;
  /** Reads and enables pagination through a set of `LinkedinConnectionRequest`. */
  allLinkedinConnectionRequests?: Maybe<LinkedinConnectionRequestsConnection>;
  /** Reads and enables pagination through a set of `LinkedinEvent`. */
  allLinkedinEvents?: Maybe<LinkedinEventsConnection>;
  /** Reads and enables pagination through a set of `LinkedinMessage`. */
  allLinkedinMessages?: Maybe<LinkedinMessagesConnection>;
  /** Reads and enables pagination through a set of `LinkedinScheduledMessage`. */
  allLinkedinScheduledMessages?: Maybe<LinkedinScheduledMessagesConnection>;
  /** Reads and enables pagination through a set of `LinkedinTemplateRevision`. */
  allLinkedinTemplateRevisions?: Maybe<LinkedinTemplateRevisionsConnection>;
  /** Reads and enables pagination through a set of `LinkedinTemplate`. */
  allLinkedinTemplates?: Maybe<LinkedinTemplatesConnection>;
  /** Reads and enables pagination through a set of `MeetingFeedback`. */
  allMeetingFeedbacks?: Maybe<MeetingFeedbacksConnection>;
  /** Reads and enables pagination through a set of `MeetingInvitee`. */
  allMeetingInvitees?: Maybe<MeetingInviteesConnection>;
  /** Reads and enables pagination through a set of `MeetingType`. */
  allMeetingTypes?: Maybe<MeetingTypesConnection>;
  /** Reads and enables pagination through a set of `Meeting`. */
  allMeetings?: Maybe<MeetingsConnection>;
  /** Reads and enables pagination through a set of `Organization`. */
  allOrganizations?: Maybe<OrganizationsConnection>;
  /** Reads and enables pagination through a set of `PersonalisationStatistic`. */
  allPersonalisationStatistics?: Maybe<PersonalisationStatisticsConnection>;
  /** Reads and enables pagination through a set of `PersonalisationType`. */
  allPersonalisationTypes?: Maybe<PersonalisationTypesConnection>;
  /** Reads and enables pagination through a set of `Personalisation`. */
  allPersonalisations?: Maybe<PersonalisationsConnection>;
  /** Reads and enables pagination through a set of `Policy`. */
  allPolicies?: Maybe<PoliciesConnection>;
  /** Reads and enables pagination through a set of `PolicyAgreement`. */
  allPolicyAgreements?: Maybe<PolicyAgreementsConnection>;
  /** Reads and enables pagination through a set of `Profile`. */
  allProfiles?: Maybe<ProfilesConnection>;
  /** Reads and enables pagination through a set of `ReferralPartner`. */
  allReferralPartners?: Maybe<ReferralPartnersConnection>;
  /** Reads and enables pagination through a set of `ReferralRequest`. */
  allReferralRequests?: Maybe<ReferralRequestsConnection>;
  /** Reads and enables pagination through a set of `Refund`. */
  allRefunds?: Maybe<RefundsConnection>;
  /** Reads and enables pagination through a set of `SenderProfile`. */
  allSenderProfiles?: Maybe<SenderProfilesConnection>;
  /** Reads and enables pagination through a set of `SocialProof`. */
  allSocialProofs?: Maybe<SocialProofsConnection>;
  /** Reads and enables pagination through a set of `SuggestedTargeting`. */
  allSuggestedTargetings?: Maybe<SuggestedTargetingsConnection>;
  /** Reads and enables pagination through a set of `TagType`. */
  allTagTypes?: Maybe<TagTypesConnection>;
  /** Reads and enables pagination through a set of `Tag`. */
  allTags?: Maybe<TagsConnection>;
  /** Reads and enables pagination through a set of `TemplateRevision`. */
  allTemplateRevisions?: Maybe<TemplateRevisionsConnection>;
  /** Reads and enables pagination through a set of `Template`. */
  allTemplates?: Maybe<TemplatesConnection>;
  /** Reads and enables pagination through a set of `Testimonial`. */
  allTestimonials?: Maybe<TestimonialsConnection>;
  /** Reads and enables pagination through a set of `Trace`. */
  allTraces?: Maybe<TracesConnection>;
  /** Reads a single `BlacklistedOutreach` using its globally unique `ID`. */
  blacklistedOutreach?: Maybe<BlacklistedOutreach>;
  blacklistedOutreachById?: Maybe<BlacklistedOutreach>;
  blacklistedOutreachByLawyerIdAndEmailAndLinkedinUrl?: Maybe<BlacklistedOutreach>;
  /** Reads a single `Campaign` using its globally unique `ID`. */
  campaign?: Maybe<Campaign>;
  campaignById?: Maybe<Campaign>;
  /** Reads a single `CampaignMember` using its globally unique `ID`. */
  campaignMember?: Maybe<CampaignMember>;
  campaignMemberById?: Maybe<CampaignMember>;
  /** Reads a single `CampaignMemberCharge` using its globally unique `ID`. */
  campaignMemberCharge?: Maybe<CampaignMemberCharge>;
  campaignMemberChargeById?: Maybe<CampaignMemberCharge>;
  /** Reads a single `CampaignMemberFeedback` using its globally unique `ID`. */
  campaignMemberFeedback?: Maybe<CampaignMemberFeedback>;
  campaignMemberFeedbackById?: Maybe<CampaignMemberFeedback>;
  /** Reads a single `CampaignMemberMeeting` using its globally unique `ID`. */
  campaignMemberMeeting?: Maybe<CampaignMemberMeeting>;
  campaignMemberMeetingById?: Maybe<CampaignMemberMeeting>;
  /** Reads a single `Client` using its globally unique `ID`. */
  client?: Maybe<Client>;
  clientById?: Maybe<Client>;
  clientByLawyerIdAndContactId?: Maybe<Client>;
  /** Reads a single `Company` using its globally unique `ID`. */
  company?: Maybe<Company>;
  companyById?: Maybe<Company>;
  /** Reads a single `CompanyPersonalisation` using its globally unique `ID`. */
  companyPersonalisation?: Maybe<CompanyPersonalisation>;
  companyPersonalisationById?: Maybe<CompanyPersonalisation>;
  /** Reads a single `Contact` using its globally unique `ID`. */
  contact?: Maybe<Contact>;
  contactByApolloId?: Maybe<Contact>;
  contactByEmail?: Maybe<Contact>;
  contactById?: Maybe<Contact>;
  contactByLinkedinUrl?: Maybe<Contact>;
  /** Reads a single `CronofyAuth` using its globally unique `ID`. */
  cronofyAuth?: Maybe<CronofyAuth>;
  cronofyAuthById?: Maybe<CronofyAuth>;
  /** Reads a single `DailyStatistic` using its globally unique `ID`. */
  dailyStatistic?: Maybe<DailyStatistic>;
  dailyStatisticById?: Maybe<DailyStatistic>;
  dailyStatisticByLawyerIdAndCampaignIdAndTemplateIdAndNameAndGroupNameAndDate?: Maybe<DailyStatistic>;
  /** Reads a single `Email` using its globally unique `ID`. */
  email?: Maybe<Email>;
  emailById?: Maybe<Email>;
  /** Reads a single `EmailEvent` using its globally unique `ID`. */
  emailEvent?: Maybe<EmailEvent>;
  emailEventByEventNameAndEmailIdAndCreatedTime?: Maybe<EmailEvent>;
  /** Reads a single `EmailMessage` using its globally unique `ID`. */
  emailMessage?: Maybe<EmailMessage>;
  emailMessageByHedwigMessageId?: Maybe<EmailMessage>;
  emailMessageById?: Maybe<EmailMessage>;
  emailMessageBySmtpMessageId?: Maybe<EmailMessage>;
  /** Reads a single `EmailPersonalisation` using its globally unique `ID`. */
  emailPersonalisation?: Maybe<EmailPersonalisation>;
  emailPersonalisationByIdAndFlag?: Maybe<EmailPersonalisation>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `HedwigIdentity` using its globally unique `ID`. */
  hedwigIdentity?: Maybe<HedwigIdentity>;
  hedwigIdentityById?: Maybe<HedwigIdentity>;
  /** Reads a single `HedwigMessagePayload` using its globally unique `ID`. */
  hedwigMessagePayload?: Maybe<HedwigMessagePayload>;
  hedwigMessagePayloadById?: Maybe<HedwigMessagePayload>;
  /** Reads a single `Lawyer` using its globally unique `ID`. */
  lawyer?: Maybe<Lawyer>;
  lawyerById?: Maybe<Lawyer>;
  /** Reads a single `LawyerSetting` using its globally unique `ID`. */
  lawyerSetting?: Maybe<LawyerSetting>;
  lawyerSettingById?: Maybe<LawyerSetting>;
  /** Reads a single `LawyerTag` using its globally unique `ID`. */
  lawyerTag?: Maybe<LawyerTag>;
  lawyerTagByLawyerIdAndTagId?: Maybe<LawyerTag>;
  /** Reads a single `LinkedinConnectionRequest` using its globally unique `ID`. */
  linkedinConnectionRequest?: Maybe<LinkedinConnectionRequest>;
  linkedinConnectionRequestById?: Maybe<LinkedinConnectionRequest>;
  /** Reads a single `LinkedinEvent` using its globally unique `ID`. */
  linkedinEvent?: Maybe<LinkedinEvent>;
  linkedinEventById?: Maybe<LinkedinEvent>;
  /** Reads a single `LinkedinMessage` using its globally unique `ID`. */
  linkedinMessage?: Maybe<LinkedinMessage>;
  linkedinMessageById?: Maybe<LinkedinMessage>;
  /** Reads a single `LinkedinScheduledMessage` using its globally unique `ID`. */
  linkedinScheduledMessage?: Maybe<LinkedinScheduledMessage>;
  linkedinScheduledMessageById?: Maybe<LinkedinScheduledMessage>;
  /** Reads a single `LinkedinTemplate` using its globally unique `ID`. */
  linkedinTemplate?: Maybe<LinkedinTemplate>;
  linkedinTemplateById?: Maybe<LinkedinTemplate>;
  /** Reads a single `LinkedinTemplateRevision` using its globally unique `ID`. */
  linkedinTemplateRevision?: Maybe<LinkedinTemplateRevision>;
  linkedinTemplateRevisionById?: Maybe<LinkedinTemplateRevision>;
  /** Reads a single `Meeting` using its globally unique `ID`. */
  meeting?: Maybe<Meeting>;
  meetingById?: Maybe<Meeting>;
  /** Reads a single `MeetingFeedback` using its globally unique `ID`. */
  meetingFeedback?: Maybe<MeetingFeedback>;
  meetingFeedbackById?: Maybe<MeetingFeedback>;
  meetingFeedbackByMeetingId?: Maybe<MeetingFeedback>;
  /** Reads a single `MeetingInvitee` using its globally unique `ID`. */
  meetingInvitee?: Maybe<MeetingInvitee>;
  meetingInviteeById?: Maybe<MeetingInvitee>;
  /** Reads a single `MeetingType` using its globally unique `ID`. */
  meetingType?: Maybe<MeetingType>;
  meetingTypeById?: Maybe<MeetingType>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Organization` using its globally unique `ID`. */
  organization?: Maybe<Organization>;
  organizationById?: Maybe<Organization>;
  /** Reads a single `Personalisation` using its globally unique `ID`. */
  personalisation?: Maybe<Personalisation>;
  personalisationById?: Maybe<Personalisation>;
  /** Reads a single `PersonalisationStatistic` using its globally unique `ID`. */
  personalisationStatistic?: Maybe<PersonalisationStatistic>;
  personalisationStatisticById?: Maybe<PersonalisationStatistic>;
  personalisationStatisticByLawyerIdAndCampaignIdAndTypeIdAndPersValue?: Maybe<PersonalisationStatistic>;
  /** Reads a single `PersonalisationType` using its globally unique `ID`. */
  personalisationType?: Maybe<PersonalisationType>;
  personalisationTypeById?: Maybe<PersonalisationType>;
  /** Reads a single `Policy` using its globally unique `ID`. */
  policy?: Maybe<Policy>;
  /** Reads a single `PolicyAgreement` using its globally unique `ID`. */
  policyAgreement?: Maybe<PolicyAgreement>;
  policyAgreementById?: Maybe<PolicyAgreement>;
  policyAgreementByProfileIdAndPolicyId?: Maybe<PolicyAgreement>;
  policyById?: Maybe<Policy>;
  policyByTypeAndVersion?: Maybe<Policy>;
  policyByUrl?: Maybe<Policy>;
  /** Reads a single `Profile` using its globally unique `ID`. */
  profile?: Maybe<Profile>;
  profileById?: Maybe<Profile>;
  profileBySlug?: Maybe<Profile>;
  profileByUserId?: Maybe<Profile>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads a single `ReferralPartner` using its globally unique `ID`. */
  referralPartner?: Maybe<ReferralPartner>;
  referralPartnerById?: Maybe<ReferralPartner>;
  referralPartnerByLawyerIdAndContactId?: Maybe<ReferralPartner>;
  /** Reads a single `ReferralRequest` using its globally unique `ID`. */
  referralRequest?: Maybe<ReferralRequest>;
  referralRequestById?: Maybe<ReferralRequest>;
  /** Reads a single `Refund` using its globally unique `ID`. */
  refund?: Maybe<Refund>;
  refundById?: Maybe<Refund>;
  refundByStripeCreditNoteId?: Maybe<Refund>;
  /** Reads a single `SenderProfile` using its globally unique `ID`. */
  senderProfile?: Maybe<SenderProfile>;
  senderProfileById?: Maybe<SenderProfile>;
  senderProfileByLawyerIdAndEmail?: Maybe<SenderProfile>;
  /** Reads a single `SocialProof` using its globally unique `ID`. */
  socialProof?: Maybe<SocialProof>;
  socialProofById?: Maybe<SocialProof>;
  /** Reads a single `SuggestedTargeting` using its globally unique `ID`. */
  suggestedTargeting?: Maybe<SuggestedTargeting>;
  suggestedTargetingById?: Maybe<SuggestedTargeting>;
  /** Reads a single `Tag` using its globally unique `ID`. */
  tag?: Maybe<Tag>;
  tagById?: Maybe<Tag>;
  tagByTypeIdAndValue?: Maybe<Tag>;
  /** Reads a single `TagType` using its globally unique `ID`. */
  tagType?: Maybe<TagType>;
  tagTypeById?: Maybe<TagType>;
  tagTypeByName?: Maybe<TagType>;
  /** Reads a single `Template` using its globally unique `ID`. */
  template?: Maybe<Template>;
  templateById?: Maybe<Template>;
  /** Reads a single `TemplateRevision` using its globally unique `ID`. */
  templateRevision?: Maybe<TemplateRevision>;
  templateRevisionById?: Maybe<TemplateRevision>;
  /** Reads a single `Testimonial` using its globally unique `ID`. */
  testimonial?: Maybe<Testimonial>;
  testimonialById?: Maybe<Testimonial>;
  /** Reads a single `Trace` using its globally unique `ID`. */
  trace?: Maybe<Trace>;
  traceById?: Maybe<Trace>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllBlacklistedOutreachesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<BlacklistedOutreachCondition>;
  filter?: InputMaybe<BlacklistedOutreachFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<BlacklistedOutreachesOrderBy>>;
  sort?: InputMaybe<Array<BlacklistedOutreachSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCampaignMemberChargesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignMemberChargeCondition>;
  filter?: InputMaybe<CampaignMemberChargeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignMemberChargesOrderBy>>;
  sort?: InputMaybe<Array<CampaignMemberChargeSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCampaignMemberFeedbacksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignMemberFeedbackCondition>;
  filter?: InputMaybe<CampaignMemberFeedbackFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignMemberFeedbacksOrderBy>>;
  sort?: InputMaybe<Array<CampaignMemberFeedbackSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCampaignMemberMeetingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignMemberMeetingCondition>;
  filter?: InputMaybe<CampaignMemberMeetingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignMemberMeetingsOrderBy>>;
  sort?: InputMaybe<Array<CampaignMemberMeetingSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCampaignMembersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignMemberCondition>;
  filter?: InputMaybe<CampaignMemberFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignMembersOrderBy>>;
  sort?: InputMaybe<Array<CampaignMemberSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
  sort?: InputMaybe<Array<CampaignSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllClientsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ClientCondition>;
  filter?: InputMaybe<ClientFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ClientsOrderBy>>;
  sort?: InputMaybe<Array<ClientSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCompaniesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyCondition>;
  filter?: InputMaybe<CompanyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompaniesOrderBy>>;
  sort?: InputMaybe<Array<CompanySort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCompanyPersonalisationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CompanyPersonalisationCondition>;
  filter?: InputMaybe<CompanyPersonalisationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CompanyPersonalisationsOrderBy>>;
  sort?: InputMaybe<Array<CompanyPersonalisationSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllContactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ContactCondition>;
  filter?: InputMaybe<ContactFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactsOrderBy>>;
  sort?: InputMaybe<Array<ContactSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCronofyAuthsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CronofyAuthCondition>;
  filter?: InputMaybe<CronofyAuthFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CronofyAuthsOrderBy>>;
  sort?: InputMaybe<Array<CronofyAuthSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllDailyStatisticsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DailyStatisticCondition>;
  filter?: InputMaybe<DailyStatisticFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DailyStatisticsOrderBy>>;
  sort?: InputMaybe<Array<DailyStatisticsSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllEmailEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailEventCondition>;
  filter?: InputMaybe<EmailEventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailEventsOrderBy>>;
  sort?: InputMaybe<Array<EmailEventSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllEmailMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailMessageCondition>;
  filter?: InputMaybe<EmailMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailMessagesOrderBy>>;
  sort?: InputMaybe<Array<EmailMessageSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllEmailPersonalisationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailPersonalisationCondition>;
  filter?: InputMaybe<EmailPersonalisationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailPersonalisationsOrderBy>>;
  sort?: InputMaybe<Array<EmailPersonalisationSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllEmailsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailCondition>;
  filter?: InputMaybe<EmailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailsOrderBy>>;
  sort?: InputMaybe<Array<EmailSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllHedwigIdentitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<HedwigIdentityCondition>;
  filter?: InputMaybe<HedwigIdentityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<HedwigIdentitiesOrderBy>>;
  sort?: InputMaybe<Array<HedwigIdentitySort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllHedwigMessagePayloadsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<HedwigMessagePayloadCondition>;
  filter?: InputMaybe<HedwigMessagePayloadFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<HedwigMessagePayloadsOrderBy>>;
  sort?: InputMaybe<Array<HedwigMessagePayloadSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLawyerSettingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LawyerSettingCondition>;
  filter?: InputMaybe<LawyerSettingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LawyerSettingsOrderBy>>;
  sort?: InputMaybe<Array<LawyerSettingSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLawyerTagsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LawyerTagCondition>;
  filter?: InputMaybe<LawyerTagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LawyerTagsOrderBy>>;
  sort?: InputMaybe<Array<LawyerTagSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLawyersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LawyerCondition>;
  filter?: InputMaybe<LawyerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LawyersOrderBy>>;
  sort?: InputMaybe<Array<LawyerSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLinkedinConnectionRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinConnectionRequestCondition>;
  filter?: InputMaybe<LinkedinConnectionRequestFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinConnectionRequestsOrderBy>>;
  sort?: InputMaybe<Array<LinkedinConnectionRequestSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLinkedinEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinEventCondition>;
  filter?: InputMaybe<LinkedinEventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinEventsOrderBy>>;
  sort?: InputMaybe<Array<LinkedinEventSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLinkedinMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinMessageCondition>;
  filter?: InputMaybe<LinkedinMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinMessagesOrderBy>>;
  sort?: InputMaybe<Array<LinkedinMessageSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLinkedinScheduledMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinScheduledMessageCondition>;
  filter?: InputMaybe<LinkedinScheduledMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinScheduledMessagesOrderBy>>;
  sort?: InputMaybe<Array<LinkedinScheduledMessageSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLinkedinTemplateRevisionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinTemplateRevisionCondition>;
  filter?: InputMaybe<LinkedinTemplateRevisionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinTemplateRevisionsOrderBy>>;
  sort?: InputMaybe<Array<LinkedinTemplateRevisionSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLinkedinTemplatesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LinkedinTemplateCondition>;
  filter?: InputMaybe<LinkedinTemplateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LinkedinTemplatesOrderBy>>;
  sort?: InputMaybe<Array<LinkedinTemplateSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllMeetingFeedbacksArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MeetingFeedbackCondition>;
  filter?: InputMaybe<MeetingFeedbackFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MeetingFeedbacksOrderBy>>;
  sort?: InputMaybe<Array<MeetingFeedbackSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllMeetingInviteesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MeetingInviteeCondition>;
  filter?: InputMaybe<MeetingInviteeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MeetingInviteesOrderBy>>;
  sort?: InputMaybe<Array<MeetingInviteeSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllMeetingTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MeetingTypeCondition>;
  filter?: InputMaybe<MeetingTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MeetingTypesOrderBy>>;
  sort?: InputMaybe<Array<MeetingTypeSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllMeetingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<MeetingCondition>;
  filter?: InputMaybe<MeetingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<MeetingsOrderBy>>;
  sort?: InputMaybe<Array<MeetingSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllOrganizationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<OrganizationCondition>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
  sort?: InputMaybe<Array<OrganizationSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPersonalisationStatisticsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PersonalisationStatisticCondition>;
  filter?: InputMaybe<PersonalisationStatisticFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonalisationStatisticsOrderBy>>;
  sort?: InputMaybe<Array<PersonalisationStatisticsSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPersonalisationTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PersonalisationTypeCondition>;
  filter?: InputMaybe<PersonalisationTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonalisationTypesOrderBy>>;
  sort?: InputMaybe<Array<PersonalisationTypeSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPersonalisationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PersonalisationCondition>;
  filter?: InputMaybe<PersonalisationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PersonalisationsOrderBy>>;
  sort?: InputMaybe<Array<PersonalisationSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPoliciesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PolicyCondition>;
  filter?: InputMaybe<PolicyFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PoliciesOrderBy>>;
  sort?: InputMaybe<Array<PolicySort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPolicyAgreementsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<PolicyAgreementCondition>;
  filter?: InputMaybe<PolicyAgreementFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<PolicyAgreementsOrderBy>>;
  sort?: InputMaybe<Array<PolicyAgreementSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllProfilesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ProfileCondition>;
  filter?: InputMaybe<ProfileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProfilesOrderBy>>;
  sort?: InputMaybe<Array<ProfileSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllReferralPartnersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ReferralPartnerCondition>;
  filter?: InputMaybe<ReferralPartnerFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReferralPartnersOrderBy>>;
  sort?: InputMaybe<Array<ReferralPartnerSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllReferralRequestsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<ReferralRequestCondition>;
  filter?: InputMaybe<ReferralRequestFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ReferralRequestsOrderBy>>;
  sort?: InputMaybe<Array<ReferralRequestSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllRefundsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<RefundCondition>;
  filter?: InputMaybe<RefundFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<RefundsOrderBy>>;
  sort?: InputMaybe<Array<RefundSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSenderProfilesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SenderProfileCondition>;
  filter?: InputMaybe<SenderProfileFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SenderProfilesOrderBy>>;
  sort?: InputMaybe<Array<SenderProfileSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSocialProofsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SocialProofCondition>;
  filter?: InputMaybe<SocialProofFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SocialProofsOrderBy>>;
  sort?: InputMaybe<Array<SocialProofSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSuggestedTargetingsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<SuggestedTargetingCondition>;
  filter?: InputMaybe<SuggestedTargetingFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<SuggestedTargetingsOrderBy>>;
  sort?: InputMaybe<Array<SuggestedTargetingSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllTagTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TagTypeCondition>;
  filter?: InputMaybe<TagTypeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TagTypesOrderBy>>;
  sort?: InputMaybe<Array<TagTypeSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllTagsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TagCondition>;
  filter?: InputMaybe<TagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
  sort?: InputMaybe<Array<TagSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllTemplateRevisionsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TemplateRevisionCondition>;
  filter?: InputMaybe<TemplateRevisionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TemplateRevisionsOrderBy>>;
  sort?: InputMaybe<Array<TemplateRevisionSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllTemplatesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TemplateCondition>;
  filter?: InputMaybe<TemplateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TemplatesOrderBy>>;
  sort?: InputMaybe<Array<TemplateSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllTestimonialsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TestimonialCondition>;
  filter?: InputMaybe<TestimonialFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TestimonialsOrderBy>>;
  sort?: InputMaybe<Array<TestimonialSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllTracesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TraceCondition>;
  filter?: InputMaybe<TraceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TracesOrderBy>>;
  sort?: InputMaybe<Array<TraceSort>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBlacklistedOutreachArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBlacklistedOutreachByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBlacklistedOutreachByLawyerIdAndEmailAndLinkedinUrlArgs = {
  email: Scalars['String']['input'];
  lawyerId: Scalars['UUID']['input'];
  linkedinUrl: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignMemberArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignMemberByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignMemberChargeArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignMemberChargeByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignMemberFeedbackArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignMemberFeedbackByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignMemberMeetingArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCampaignMemberMeetingByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClientArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClientByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClientByLawyerIdAndContactIdArgs = {
  contactId: Scalars['UUID']['input'];
  lawyerId: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyPersonalisationArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCompanyPersonalisationByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryContactArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryContactByApolloIdArgs = {
  apolloId: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryContactByEmailArgs = {
  email: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryContactByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryContactByLinkedinUrlArgs = {
  linkedinUrl: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCronofyAuthArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCronofyAuthByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDailyStatisticArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDailyStatisticByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDailyStatisticByLawyerIdAndCampaignIdAndTemplateIdAndNameAndGroupNameAndDateArgs = {
  campaignId: Scalars['UUID']['input'];
  date: Scalars['Datetime']['input'];
  groupName: Scalars['String']['input'];
  lawyerId: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
  templateId: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailEventArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailEventByEventNameAndEmailIdAndCreatedTimeArgs = {
  createdTime: Scalars['Datetime']['input'];
  emailId: Scalars['UUID']['input'];
  eventName: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailMessageArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailMessageByHedwigMessageIdArgs = {
  hedwigMessageId: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailMessageByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailMessageBySmtpMessageIdArgs = {
  smtpMessageId: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailPersonalisationArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmailPersonalisationByIdAndFlagArgs = {
  flag: Scalars['String']['input'];
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryHedwigIdentityArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryHedwigIdentityByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryHedwigMessagePayloadArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryHedwigMessagePayloadByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLawyerArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLawyerByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLawyerSettingArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLawyerSettingByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLawyerTagArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLawyerTagByLawyerIdAndTagIdArgs = {
  lawyerId: Scalars['UUID']['input'];
  tagId: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkedinConnectionRequestArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkedinConnectionRequestByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkedinEventArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkedinEventByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkedinMessageArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkedinMessageByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkedinScheduledMessageArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkedinScheduledMessageByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkedinTemplateArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkedinTemplateByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkedinTemplateRevisionArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLinkedinTemplateRevisionByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMeetingArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMeetingByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMeetingFeedbackArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMeetingFeedbackByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMeetingFeedbackByMeetingIdArgs = {
  meetingId: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMeetingInviteeArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMeetingInviteeByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMeetingTypeArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMeetingTypeByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonalisationArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonalisationByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonalisationStatisticArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonalisationStatisticByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonalisationStatisticByLawyerIdAndCampaignIdAndTypeIdAndPersValueArgs = {
  campaignId: Scalars['UUID']['input'];
  lawyerId: Scalars['UUID']['input'];
  persValue: Scalars['String']['input'];
  typeId: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonalisationTypeArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPersonalisationTypeByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPolicyArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPolicyAgreementArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPolicyAgreementByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPolicyAgreementByProfileIdAndPolicyIdArgs = {
  policyId: Scalars['UUID']['input'];
  profileId: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPolicyByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPolicyByTypeAndVersionArgs = {
  type: Scalars['String']['input'];
  version: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPolicyByUrlArgs = {
  url: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProfileArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProfileByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProfileBySlugArgs = {
  slug: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProfileByUserIdArgs = {
  userId: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReferralPartnerArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReferralPartnerByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReferralPartnerByLawyerIdAndContactIdArgs = {
  contactId: Scalars['UUID']['input'];
  lawyerId: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReferralRequestArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReferralRequestByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRefundArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRefundByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRefundByStripeCreditNoteIdArgs = {
  stripeCreditNoteId: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySenderProfileArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySenderProfileByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySenderProfileByLawyerIdAndEmailArgs = {
  email: Scalars['String']['input'];
  lawyerId: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySocialProofArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySocialProofByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySuggestedTargetingArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySuggestedTargetingByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTagArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTagByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTagByTypeIdAndValueArgs = {
  typeId: Scalars['UUID']['input'];
  value: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTagTypeArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTagTypeByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTagTypeByNameArgs = {
  name: Scalars['String']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTemplateArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTemplateByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTemplateRevisionArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTemplateRevisionByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTestimonialArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTestimonialByIdArgs = {
  id: Scalars['UUID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTraceArgs = {
  nodeId: Scalars['ID']['input'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTraceByIdArgs = {
  id: Scalars['UUID']['input'];
};

export enum Rating {
  Bad = 'BAD',
  Good = 'GOOD',
  Ok = 'OK',
  VeryBad = 'VERY_BAD',
  VeryGood = 'VERY_GOOD'
}

/** A filter to be used against Rating fields. All fields are combined with a logical ‘and.’ */
export type RatingFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Rating>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Rating>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Rating>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Rating>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Rating>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Rating>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Rating>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Rating>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Rating>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Rating>>;
};

export type ReferralPartner = Node & {
  __typename?: 'ReferralPartner';
  companyNotes?: Maybe<Scalars['String']['output']>;
  /** Reads a single `Contact` that is related to this `ReferralPartner`. */
  contactByContactId?: Maybe<Contact>;
  contactId: Scalars['UUID']['output'];
  contactNotes?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Lawyer` that is related to this `ReferralPartner`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

export type ReferralPartnerAggregates = {
  __typename?: 'ReferralPartnerAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ReferralPartnerDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `ReferralPartner` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ReferralPartnerCondition = {
  /** Checks for equality with the object’s `companyNotes` field. */
  companyNotes?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `contactId` field. */
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `contactNotes` field. */
  contactNotes?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
};

export type ReferralPartnerDistinctCountAggregates = {
  __typename?: 'ReferralPartnerDistinctCountAggregates';
  /** Distinct count of companyNotes across the matching connection */
  companyNotes?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactId across the matching connection */
  contactId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactNotes across the matching connection */
  contactNotes?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `ReferralPartner` object types. All fields are combined with a logical ‘and.’ */
export type ReferralPartnerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ReferralPartnerFilter>>;
  /** Filter by the object’s `companyNotes` field. */
  companyNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `contactId` field. */
  contactId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `contactNotes` field. */
  contactNotes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ReferralPartnerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ReferralPartnerFilter>>;
};

/** Grouping methods for `ReferralPartner` for usage during aggregation. */
export enum ReferralPartnerGroupBy {
  CompanyNotes = 'COMPANY_NOTES',
  ContactId = 'CONTACT_ID',
  ContactNotes = 'CONTACT_NOTES',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  LawyerId = 'LAWYER_ID'
}

export type ReferralPartnerHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ReferralPartnerHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ReferralPartner` aggregates. */
export type ReferralPartnerHavingInput = {
  AND?: InputMaybe<Array<ReferralPartnerHavingInput>>;
  OR?: InputMaybe<Array<ReferralPartnerHavingInput>>;
  average?: InputMaybe<ReferralPartnerHavingAverageInput>;
  distinctCount?: InputMaybe<ReferralPartnerHavingDistinctCountInput>;
  max?: InputMaybe<ReferralPartnerHavingMaxInput>;
  min?: InputMaybe<ReferralPartnerHavingMinInput>;
  stddevPopulation?: InputMaybe<ReferralPartnerHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ReferralPartnerHavingStddevSampleInput>;
  sum?: InputMaybe<ReferralPartnerHavingSumInput>;
  variancePopulation?: InputMaybe<ReferralPartnerHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ReferralPartnerHavingVarianceSampleInput>;
};

export type ReferralPartnerHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ReferralPartnerHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ReferralPartnerHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ReferralPartnerHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ReferralPartnerHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ReferralPartnerHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type ReferralPartnerHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type ReferralPartnerSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: ReferralPartnerSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type ReferralPartnerSortBy = {
  field?: InputMaybe<ReferralPartnerSortableField>;
};

/** Sortable concrete fields for the `ReferralPartner` type. */
export enum ReferralPartnerSortableField {
  CompanyNotes = 'COMPANY_NOTES',
  ContactId = 'CONTACT_ID',
  ContactNotes = 'CONTACT_NOTES',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  LawyerId = 'LAWYER_ID'
}

/** A connection to a list of `ReferralPartner` values. */
export type ReferralPartnersConnection = {
  __typename?: 'ReferralPartnersConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ReferralPartnerAggregates>;
  /** A list of edges which contains the `ReferralPartner` and cursor to aid in pagination. */
  edges: Array<ReferralPartnersEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ReferralPartnerAggregates>>;
  /** A list of `ReferralPartner` objects. */
  nodes: Array<Maybe<ReferralPartner>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReferralPartner` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ReferralPartner` values. */
export type ReferralPartnersConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReferralPartnerGroupBy>;
  having?: InputMaybe<ReferralPartnerHavingInput>;
};

/** A `ReferralPartner` edge in the connection. */
export type ReferralPartnersEdge = {
  __typename?: 'ReferralPartnersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `ReferralPartner` at the end of the edge. */
  node?: Maybe<ReferralPartner>;
};

/** Methods to use when ordering `ReferralPartner`. */
export enum ReferralPartnersOrderBy {
  CompanyNotesAsc = 'COMPANY_NOTES_ASC',
  CompanyNotesDesc = 'COMPANY_NOTES_DESC',
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  ContactNotesAsc = 'CONTACT_NOTES_ASC',
  ContactNotesDesc = 'CONTACT_NOTES_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ReferralRequest = Node & {
  __typename?: 'ReferralRequest';
  createdTime: Scalars['Datetime']['output'];
  description: Scalars['String']['output'];
  duration?: Maybe<Scalars['Int']['output']>;
  expertise: Scalars['String']['output'];
  feePercentage?: Maybe<Scalars['Int']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Lawyer` that is related to this `ReferralRequest`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  price: Scalars['Int']['output'];
};

export type ReferralRequestAggregates = {
  __typename?: 'ReferralRequestAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ReferralRequestAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ReferralRequestDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ReferralRequestMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ReferralRequestMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ReferralRequestStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ReferralRequestStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ReferralRequestSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ReferralRequestVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ReferralRequestVarianceSampleAggregates>;
};

export type ReferralRequestAverageAggregates = {
  __typename?: 'ReferralRequestAverageAggregates';
  /** Mean average of duration across the matching connection */
  duration?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of feePercentage across the matching connection */
  feePercentage?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of price across the matching connection */
  price?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `ReferralRequest` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ReferralRequestCondition = {
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `expertise` field. */
  expertise?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `feePercentage` field. */
  feePercentage?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `price` field. */
  price?: InputMaybe<Scalars['Int']['input']>;
};

export type ReferralRequestDistinctCountAggregates = {
  __typename?: 'ReferralRequestDistinctCountAggregates';
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of duration across the matching connection */
  duration?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of expertise across the matching connection */
  expertise?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of feePercentage across the matching connection */
  feePercentage?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of price across the matching connection */
  price?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `ReferralRequest` object types. All fields are combined with a logical ‘and.’ */
export type ReferralRequestFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ReferralRequestFilter>>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: InputMaybe<IntFilter>;
  /** Filter by the object’s `expertise` field. */
  expertise?: InputMaybe<StringFilter>;
  /** Filter by the object’s `feePercentage` field. */
  feePercentage?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ReferralRequestFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ReferralRequestFilter>>;
  /** Filter by the object’s `price` field. */
  price?: InputMaybe<IntFilter>;
};

/** Grouping methods for `ReferralRequest` for usage during aggregation. */
export enum ReferralRequestGroupBy {
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  Description = 'DESCRIPTION',
  Duration = 'DURATION',
  Expertise = 'EXPERTISE',
  FeePercentage = 'FEE_PERCENTAGE',
  LawyerId = 'LAWYER_ID',
  Price = 'PRICE'
}

export type ReferralRequestHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
  feePercentage?: InputMaybe<HavingIntFilter>;
  price?: InputMaybe<HavingIntFilter>;
};

export type ReferralRequestHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
  feePercentage?: InputMaybe<HavingIntFilter>;
  price?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ReferralRequest` aggregates. */
export type ReferralRequestHavingInput = {
  AND?: InputMaybe<Array<ReferralRequestHavingInput>>;
  OR?: InputMaybe<Array<ReferralRequestHavingInput>>;
  average?: InputMaybe<ReferralRequestHavingAverageInput>;
  distinctCount?: InputMaybe<ReferralRequestHavingDistinctCountInput>;
  max?: InputMaybe<ReferralRequestHavingMaxInput>;
  min?: InputMaybe<ReferralRequestHavingMinInput>;
  stddevPopulation?: InputMaybe<ReferralRequestHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ReferralRequestHavingStddevSampleInput>;
  sum?: InputMaybe<ReferralRequestHavingSumInput>;
  variancePopulation?: InputMaybe<ReferralRequestHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ReferralRequestHavingVarianceSampleInput>;
};

export type ReferralRequestHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
  feePercentage?: InputMaybe<HavingIntFilter>;
  price?: InputMaybe<HavingIntFilter>;
};

export type ReferralRequestHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
  feePercentage?: InputMaybe<HavingIntFilter>;
  price?: InputMaybe<HavingIntFilter>;
};

export type ReferralRequestHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
  feePercentage?: InputMaybe<HavingIntFilter>;
  price?: InputMaybe<HavingIntFilter>;
};

export type ReferralRequestHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
  feePercentage?: InputMaybe<HavingIntFilter>;
  price?: InputMaybe<HavingIntFilter>;
};

export type ReferralRequestHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
  feePercentage?: InputMaybe<HavingIntFilter>;
  price?: InputMaybe<HavingIntFilter>;
};

export type ReferralRequestHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
  feePercentage?: InputMaybe<HavingIntFilter>;
  price?: InputMaybe<HavingIntFilter>;
};

export type ReferralRequestHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
  feePercentage?: InputMaybe<HavingIntFilter>;
  price?: InputMaybe<HavingIntFilter>;
};

export type ReferralRequestMaxAggregates = {
  __typename?: 'ReferralRequestMaxAggregates';
  /** Maximum of duration across the matching connection */
  duration?: Maybe<Scalars['Int']['output']>;
  /** Maximum of feePercentage across the matching connection */
  feePercentage?: Maybe<Scalars['Int']['output']>;
  /** Maximum of price across the matching connection */
  price?: Maybe<Scalars['Int']['output']>;
};

export type ReferralRequestMinAggregates = {
  __typename?: 'ReferralRequestMinAggregates';
  /** Minimum of duration across the matching connection */
  duration?: Maybe<Scalars['Int']['output']>;
  /** Minimum of feePercentage across the matching connection */
  feePercentage?: Maybe<Scalars['Int']['output']>;
  /** Minimum of price across the matching connection */
  price?: Maybe<Scalars['Int']['output']>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type ReferralRequestSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: ReferralRequestSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type ReferralRequestSortBy = {
  field?: InputMaybe<ReferralRequestSortableField>;
};

/** Sortable concrete fields for the `ReferralRequest` type. */
export enum ReferralRequestSortableField {
  CreatedTime = 'CREATED_TIME',
  Description = 'DESCRIPTION',
  Duration = 'DURATION',
  Expertise = 'EXPERTISE',
  FeePercentage = 'FEE_PERCENTAGE',
  Id = 'ID',
  LawyerId = 'LAWYER_ID',
  Price = 'PRICE'
}

export type ReferralRequestStddevPopulationAggregates = {
  __typename?: 'ReferralRequestStddevPopulationAggregates';
  /** Population standard deviation of duration across the matching connection */
  duration?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of feePercentage across the matching connection */
  feePercentage?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of price across the matching connection */
  price?: Maybe<Scalars['BigFloat']['output']>;
};

export type ReferralRequestStddevSampleAggregates = {
  __typename?: 'ReferralRequestStddevSampleAggregates';
  /** Sample standard deviation of duration across the matching connection */
  duration?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of feePercentage across the matching connection */
  feePercentage?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of price across the matching connection */
  price?: Maybe<Scalars['BigFloat']['output']>;
};

export type ReferralRequestSumAggregates = {
  __typename?: 'ReferralRequestSumAggregates';
  /** Sum of duration across the matching connection */
  duration: Scalars['BigInt']['output'];
  /** Sum of feePercentage across the matching connection */
  feePercentage: Scalars['BigInt']['output'];
  /** Sum of price across the matching connection */
  price: Scalars['BigInt']['output'];
};

export type ReferralRequestVariancePopulationAggregates = {
  __typename?: 'ReferralRequestVariancePopulationAggregates';
  /** Population variance of duration across the matching connection */
  duration?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of feePercentage across the matching connection */
  feePercentage?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of price across the matching connection */
  price?: Maybe<Scalars['BigFloat']['output']>;
};

export type ReferralRequestVarianceSampleAggregates = {
  __typename?: 'ReferralRequestVarianceSampleAggregates';
  /** Sample variance of duration across the matching connection */
  duration?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of feePercentage across the matching connection */
  feePercentage?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of price across the matching connection */
  price?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `ReferralRequest` values. */
export type ReferralRequestsConnection = {
  __typename?: 'ReferralRequestsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ReferralRequestAggregates>;
  /** A list of edges which contains the `ReferralRequest` and cursor to aid in pagination. */
  edges: Array<ReferralRequestsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ReferralRequestAggregates>>;
  /** A list of `ReferralRequest` objects. */
  nodes: Array<Maybe<ReferralRequest>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReferralRequest` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `ReferralRequest` values. */
export type ReferralRequestsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ReferralRequestGroupBy>;
  having?: InputMaybe<ReferralRequestHavingInput>;
};

/** A `ReferralRequest` edge in the connection. */
export type ReferralRequestsEdge = {
  __typename?: 'ReferralRequestsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `ReferralRequest` at the end of the edge. */
  node?: Maybe<ReferralRequest>;
};

/** Methods to use when ordering `ReferralRequest`. */
export enum ReferralRequestsOrderBy {
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  ExpertiseAsc = 'EXPERTISE_ASC',
  ExpertiseDesc = 'EXPERTISE_DESC',
  FeePercentageAsc = 'FEE_PERCENTAGE_ASC',
  FeePercentageDesc = 'FEE_PERCENTAGE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  Natural = 'NATURAL',
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Refund = Node & {
  __typename?: 'Refund';
  amount: Scalars['Int']['output'];
  approvalStatus?: Maybe<Refundapprovalstatus>;
  /** Reads a single `CampaignMemberCharge` that is related to this `Refund`. */
  campaignMemberChargeByCampaignMemberChargeId?: Maybe<CampaignMemberCharge>;
  campaignMemberChargeId: Scalars['UUID']['output'];
  createdTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status: Refundstatus;
  stripeCreditNoteId?: Maybe<Scalars['String']['output']>;
  stripeCreditNoteUrl?: Maybe<Scalars['String']['output']>;
  updatedTime: Scalars['Datetime']['output'];
};

export type RefundAggregates = {
  __typename?: 'RefundAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<RefundAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<RefundDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<RefundMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<RefundMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<RefundStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<RefundStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<RefundSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<RefundVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<RefundVarianceSampleAggregates>;
};

export type RefundAverageAggregates = {
  __typename?: 'RefundAverageAggregates';
  /** Mean average of amount across the matching connection */
  amount?: Maybe<Scalars['BigFloat']['output']>;
};

/** A condition to be used against `Refund` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type RefundCondition = {
  /** Checks for equality with the object’s `amount` field. */
  amount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `approvalStatus` field. */
  approvalStatus?: InputMaybe<Refundapprovalstatus>;
  /** Checks for equality with the object’s `campaignMemberChargeId` field. */
  campaignMemberChargeId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `note` field. */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `reason` field. */
  reason?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Refundstatus>;
  /** Checks for equality with the object’s `stripeCreditNoteId` field. */
  stripeCreditNoteId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `stripeCreditNoteUrl` field. */
  stripeCreditNoteUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type RefundDistinctCountAggregates = {
  __typename?: 'RefundDistinctCountAggregates';
  /** Distinct count of amount across the matching connection */
  amount?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of approvalStatus across the matching connection */
  approvalStatus?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of campaignMemberChargeId across the matching connection */
  campaignMemberChargeId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of note across the matching connection */
  note?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of reason across the matching connection */
  reason?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stripeCreditNoteId across the matching connection */
  stripeCreditNoteId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of stripeCreditNoteUrl across the matching connection */
  stripeCreditNoteUrl?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Refund` object types. All fields are combined with a logical ‘and.’ */
export type RefundFilter = {
  /** Filter by the object’s `amount` field. */
  amount?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RefundFilter>>;
  /** Filter by the object’s `approvalStatus` field. */
  approvalStatus?: InputMaybe<RefundapprovalstatusFilter>;
  /** Filter by the object’s `campaignMemberChargeId` field. */
  campaignMemberChargeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RefundFilter>;
  /** Filter by the object’s `note` field. */
  note?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RefundFilter>>;
  /** Filter by the object’s `reason` field. */
  reason?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<RefundstatusFilter>;
  /** Filter by the object’s `stripeCreditNoteId` field. */
  stripeCreditNoteId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `stripeCreditNoteUrl` field. */
  stripeCreditNoteUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Refund` for usage during aggregation. */
export enum RefundGroupBy {
  Amount = 'AMOUNT',
  ApprovalStatus = 'APPROVAL_STATUS',
  CampaignMemberChargeId = 'CAMPAIGN_MEMBER_CHARGE_ID',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  Note = 'NOTE',
  Reason = 'REASON',
  Status = 'STATUS',
  StripeCreditNoteUrl = 'STRIPE_CREDIT_NOTE_URL',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type RefundHavingAverageInput = {
  amount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type RefundHavingDistinctCountInput = {
  amount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Refund` aggregates. */
export type RefundHavingInput = {
  AND?: InputMaybe<Array<RefundHavingInput>>;
  OR?: InputMaybe<Array<RefundHavingInput>>;
  average?: InputMaybe<RefundHavingAverageInput>;
  distinctCount?: InputMaybe<RefundHavingDistinctCountInput>;
  max?: InputMaybe<RefundHavingMaxInput>;
  min?: InputMaybe<RefundHavingMinInput>;
  stddevPopulation?: InputMaybe<RefundHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<RefundHavingStddevSampleInput>;
  sum?: InputMaybe<RefundHavingSumInput>;
  variancePopulation?: InputMaybe<RefundHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<RefundHavingVarianceSampleInput>;
};

export type RefundHavingMaxInput = {
  amount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type RefundHavingMinInput = {
  amount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type RefundHavingStddevPopulationInput = {
  amount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type RefundHavingStddevSampleInput = {
  amount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type RefundHavingSumInput = {
  amount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type RefundHavingVariancePopulationInput = {
  amount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type RefundHavingVarianceSampleInput = {
  amount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type RefundMaxAggregates = {
  __typename?: 'RefundMaxAggregates';
  /** Maximum of amount across the matching connection */
  amount?: Maybe<Scalars['Int']['output']>;
};

export type RefundMinAggregates = {
  __typename?: 'RefundMinAggregates';
  /** Minimum of amount across the matching connection */
  amount?: Maybe<Scalars['Int']['output']>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type RefundSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: RefundSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type RefundSortBy = {
  field?: InputMaybe<RefundSortableField>;
};

/** Sortable concrete fields for the `Refund` type. */
export enum RefundSortableField {
  Amount = 'AMOUNT',
  ApprovalStatus = 'APPROVAL_STATUS',
  CampaignMemberChargeId = 'CAMPAIGN_MEMBER_CHARGE_ID',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  Note = 'NOTE',
  Reason = 'REASON',
  Status = 'STATUS',
  StripeCreditNoteId = 'STRIPE_CREDIT_NOTE_ID',
  StripeCreditNoteUrl = 'STRIPE_CREDIT_NOTE_URL',
  UpdatedTime = 'UPDATED_TIME'
}

export type RefundStddevPopulationAggregates = {
  __typename?: 'RefundStddevPopulationAggregates';
  /** Population standard deviation of amount across the matching connection */
  amount?: Maybe<Scalars['BigFloat']['output']>;
};

export type RefundStddevSampleAggregates = {
  __typename?: 'RefundStddevSampleAggregates';
  /** Sample standard deviation of amount across the matching connection */
  amount?: Maybe<Scalars['BigFloat']['output']>;
};

export type RefundSumAggregates = {
  __typename?: 'RefundSumAggregates';
  /** Sum of amount across the matching connection */
  amount: Scalars['BigInt']['output'];
};

export type RefundVariancePopulationAggregates = {
  __typename?: 'RefundVariancePopulationAggregates';
  /** Population variance of amount across the matching connection */
  amount?: Maybe<Scalars['BigFloat']['output']>;
};

export type RefundVarianceSampleAggregates = {
  __typename?: 'RefundVarianceSampleAggregates';
  /** Sample variance of amount across the matching connection */
  amount?: Maybe<Scalars['BigFloat']['output']>;
};

export enum Refundapprovalstatus {
  Valid = 'VALID',
  WithDispute = 'WITH_DISPUTE'
}

/** A filter to be used against Refundapprovalstatus fields. All fields are combined with a logical ‘and.’ */
export type RefundapprovalstatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Refundapprovalstatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Refundapprovalstatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Refundapprovalstatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Refundapprovalstatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Refundapprovalstatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Refundapprovalstatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Refundapprovalstatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Refundapprovalstatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Refundapprovalstatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Refundapprovalstatus>>;
};

/** A connection to a list of `Refund` values. */
export type RefundsConnection = {
  __typename?: 'RefundsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<RefundAggregates>;
  /** A list of edges which contains the `Refund` and cursor to aid in pagination. */
  edges: Array<RefundsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<RefundAggregates>>;
  /** A list of `Refund` objects. */
  nodes: Array<Maybe<Refund>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Refund` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Refund` values. */
export type RefundsConnectionGroupedAggregatesArgs = {
  groupBy: Array<RefundGroupBy>;
  having?: InputMaybe<RefundHavingInput>;
};

/** A `Refund` edge in the connection. */
export type RefundsEdge = {
  __typename?: 'RefundsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Refund` at the end of the edge. */
  node?: Maybe<Refund>;
};

/** Methods to use when ordering `Refund`. */
export enum RefundsOrderBy {
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  ApprovalStatusAsc = 'APPROVAL_STATUS_ASC',
  ApprovalStatusDesc = 'APPROVAL_STATUS_DESC',
  CampaignMemberChargeIdAsc = 'CAMPAIGN_MEMBER_CHARGE_ID_ASC',
  CampaignMemberChargeIdDesc = 'CAMPAIGN_MEMBER_CHARGE_ID_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReasonAsc = 'REASON_ASC',
  ReasonDesc = 'REASON_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  StripeCreditNoteIdAsc = 'STRIPE_CREDIT_NOTE_ID_ASC',
  StripeCreditNoteIdDesc = 'STRIPE_CREDIT_NOTE_ID_DESC',
  StripeCreditNoteUrlAsc = 'STRIPE_CREDIT_NOTE_URL_ASC',
  StripeCreditNoteUrlDesc = 'STRIPE_CREDIT_NOTE_URL_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

export enum Refundstatus {
  Approved = 'APPROVED',
  Failed = 'FAILED',
  Rejected = 'REJECTED',
  Requested = 'REQUESTED'
}

/** A filter to be used against Refundstatus fields. All fields are combined with a logical ‘and.’ */
export type RefundstatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Refundstatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Refundstatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Refundstatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Refundstatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Refundstatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Refundstatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Refundstatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Refundstatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Refundstatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Refundstatus>>;
};

export enum ReplyStatus {
  Auto = 'AUTO',
  Manual = 'MANUAL'
}

/** A filter to be used against ReplyStatus fields. All fields are combined with a logical ‘and.’ */
export type ReplyStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ReplyStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ReplyStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ReplyStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ReplyStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ReplyStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ReplyStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ReplyStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ReplyStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ReplyStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ReplyStatus>>;
};

/** All input for the `reverseDomain` mutation. */
export type ReverseDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our `reverseDomain` mutation. */
export type ReverseDomainPayload = {
  __typename?: 'ReverseDomainPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']['output']>;
};

export type SenderProfile = Node & {
  __typename?: 'SenderProfile';
  bannedFromTalkingToGmail: Scalars['Boolean']['output'];
  brandColor?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['Datetime']['output'];
  currentDailyCap?: Maybe<Scalars['Int']['output']>;
  currentDailyCapByEsp?: Maybe<Scalars['JSON']['output']>;
  dailyCap: Scalars['Int']['output'];
  dailyCapByEsp?: Maybe<Scalars['JSON']['output']>;
  dailyCapIncrement?: Maybe<Scalars['Int']['output']>;
  email: Scalars['String']['output'];
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesBySenderProfileId: EmailMessagesConnection;
  /** Reads and enables pagination through a set of `Email`. */
  emailsBySenderProfileId: EmailsConnection;
  firmName?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  isActive: Scalars['Boolean']['output'];
  isActiveUpdatedTime: Scalars['Datetime']['output'];
  isAlias: Scalars['Boolean']['output'];
  isImpromptu: Scalars['Boolean']['output'];
  isImpromptuUpdatedTime: Scalars['Datetime']['output'];
  isPrimary?: Maybe<Scalars['Boolean']['output']>;
  lastName: Scalars['String']['output'];
  /** Reads a single `Lawyer` that is related to this `SenderProfile`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId: Scalars['UUID']['output'];
  logoSrc?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  pausedUntilTime?: Maybe<Scalars['Datetime']['output']>;
  priority: Scalars['Int']['output'];
  profilePictureId?: Maybe<Scalars['UUID']['output']>;
  profilePictureStatus: Scalars['Boolean']['output'];
  profileType: SenderProfileType;
  reachedDailyCap: Scalars['Boolean']['output'];
  signatureHtml?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedTime: Scalars['Datetime']['output'];
  username: Scalars['String']['output'];
  verificationType: VerificationType;
  warmupInboxFilterId?: Maybe<Scalars['String']['output']>;
  warmupInboxId?: Maybe<Scalars['String']['output']>;
  websiteUrl?: Maybe<Scalars['String']['output']>;
};


export type SenderProfileEmailMessagesBySenderProfileIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailMessageCondition>;
  filter?: InputMaybe<EmailMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailMessagesOrderBy>>;
  sort?: InputMaybe<Array<EmailMessageSort>>;
};


export type SenderProfileEmailsBySenderProfileIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailCondition>;
  filter?: InputMaybe<EmailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailsOrderBy>>;
  sort?: InputMaybe<Array<EmailSort>>;
};

export type SenderProfileAggregates = {
  __typename?: 'SenderProfileAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SenderProfileAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SenderProfileDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SenderProfileMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SenderProfileMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SenderProfileStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SenderProfileStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SenderProfileSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SenderProfileVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SenderProfileVarianceSampleAggregates>;
};

export type SenderProfileAverageAggregates = {
  __typename?: 'SenderProfileAverageAggregates';
  /** Mean average of currentDailyCap across the matching connection */
  currentDailyCap?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of dailyCap across the matching connection */
  dailyCap?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of dailyCapIncrement across the matching connection */
  dailyCapIncrement?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of priority across the matching connection */
  priority?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `SenderProfile` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SenderProfileCondition = {
  /** Checks for equality with the object’s `bannedFromTalkingToGmail` field. */
  bannedFromTalkingToGmail?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `brandColor` field. */
  brandColor?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `currentDailyCap` field. */
  currentDailyCap?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `currentDailyCapByEsp` field. */
  currentDailyCapByEsp?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `dailyCap` field. */
  dailyCap?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `dailyCapByEsp` field. */
  dailyCapByEsp?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `dailyCapIncrement` field. */
  dailyCapIncrement?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `firmName` field. */
  firmName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isActiveUpdatedTime` field. */
  isActiveUpdatedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `isAlias` field. */
  isAlias?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isImpromptu` field. */
  isImpromptu?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isImpromptuUpdatedTime` field. */
  isImpromptuUpdatedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `isPrimary` field. */
  isPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `logoSrc` field. */
  logoSrc?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `pausedUntilTime` field. */
  pausedUntilTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `profilePictureId` field. */
  profilePictureId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `profilePictureStatus` field. */
  profilePictureStatus?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `profileType` field. */
  profileType?: InputMaybe<SenderProfileType>;
  /** Checks for equality with the object’s `reachedDailyCap` field. */
  reachedDailyCap?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `signatureHtml` field. */
  signatureHtml?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `verificationType` field. */
  verificationType?: InputMaybe<VerificationType>;
  /** Checks for equality with the object’s `warmupInboxFilterId` field. */
  warmupInboxFilterId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `warmupInboxId` field. */
  warmupInboxId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type SenderProfileDistinctCountAggregates = {
  __typename?: 'SenderProfileDistinctCountAggregates';
  /** Distinct count of bannedFromTalkingToGmail across the matching connection */
  bannedFromTalkingToGmail?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of brandColor across the matching connection */
  brandColor?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of currentDailyCap across the matching connection */
  currentDailyCap?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of currentDailyCapByEsp across the matching connection */
  currentDailyCapByEsp?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dailyCap across the matching connection */
  dailyCap?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dailyCapByEsp across the matching connection */
  dailyCapByEsp?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of dailyCapIncrement across the matching connection */
  dailyCapIncrement?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of firmName across the matching connection */
  firmName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of firstName across the matching connection */
  firstName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isActive across the matching connection */
  isActive?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isActiveUpdatedTime across the matching connection */
  isActiveUpdatedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isAlias across the matching connection */
  isAlias?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isImpromptu across the matching connection */
  isImpromptu?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isImpromptuUpdatedTime across the matching connection */
  isImpromptuUpdatedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of isPrimary across the matching connection */
  isPrimary?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lastName across the matching connection */
  lastName?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of logoSrc across the matching connection */
  logoSrc?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of pausedUntilTime across the matching connection */
  pausedUntilTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of priority across the matching connection */
  priority?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of profilePictureId across the matching connection */
  profilePictureId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of profilePictureStatus across the matching connection */
  profilePictureStatus?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of profileType across the matching connection */
  profileType?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of reachedDailyCap across the matching connection */
  reachedDailyCap?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of signatureHtml across the matching connection */
  signatureHtml?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of username across the matching connection */
  username?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of verificationType across the matching connection */
  verificationType?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of warmupInboxFilterId across the matching connection */
  warmupInboxFilterId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of warmupInboxId across the matching connection */
  warmupInboxId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of websiteUrl across the matching connection */
  websiteUrl?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `SenderProfile` object types. All fields are combined with a logical ‘and.’ */
export type SenderProfileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SenderProfileFilter>>;
  /** Filter by the object’s `bannedFromTalkingToGmail` field. */
  bannedFromTalkingToGmail?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `brandColor` field. */
  brandColor?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currentDailyCap` field. */
  currentDailyCap?: InputMaybe<IntFilter>;
  /** Filter by the object’s `currentDailyCapByEsp` field. */
  currentDailyCapByEsp?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `dailyCap` field. */
  dailyCap?: InputMaybe<IntFilter>;
  /** Filter by the object’s `dailyCapByEsp` field. */
  dailyCapByEsp?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `dailyCapIncrement` field. */
  dailyCapIncrement?: InputMaybe<IntFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firmName` field. */
  firmName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isActiveUpdatedTime` field. */
  isActiveUpdatedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `isAlias` field. */
  isAlias?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isImpromptu` field. */
  isImpromptu?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isImpromptuUpdatedTime` field. */
  isImpromptuUpdatedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `isPrimary` field. */
  isPrimary?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `logoSrc` field. */
  logoSrc?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SenderProfileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SenderProfileFilter>>;
  /** Filter by the object’s `pausedUntilTime` field. */
  pausedUntilTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `priority` field. */
  priority?: InputMaybe<IntFilter>;
  /** Filter by the object’s `profilePictureId` field. */
  profilePictureId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `profilePictureStatus` field. */
  profilePictureStatus?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `profileType` field. */
  profileType?: InputMaybe<SenderProfileTypeFilter>;
  /** Filter by the object’s `reachedDailyCap` field. */
  reachedDailyCap?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `signatureHtml` field. */
  signatureHtml?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
  /** Filter by the object’s `verificationType` field. */
  verificationType?: InputMaybe<VerificationTypeFilter>;
  /** Filter by the object’s `warmupInboxFilterId` field. */
  warmupInboxFilterId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `warmupInboxId` field. */
  warmupInboxId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `websiteUrl` field. */
  websiteUrl?: InputMaybe<StringFilter>;
};

/** Grouping methods for `SenderProfile` for usage during aggregation. */
export enum SenderProfileGroupBy {
  BannedFromTalkingToGmail = 'BANNED_FROM_TALKING_TO_GMAIL',
  BrandColor = 'BRAND_COLOR',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  CurrentDailyCap = 'CURRENT_DAILY_CAP',
  CurrentDailyCapByEsp = 'CURRENT_DAILY_CAP_BY_ESP',
  DailyCap = 'DAILY_CAP',
  DailyCapByEsp = 'DAILY_CAP_BY_ESP',
  DailyCapIncrement = 'DAILY_CAP_INCREMENT',
  FirmName = 'FIRM_NAME',
  FirstName = 'FIRST_NAME',
  IsActive = 'IS_ACTIVE',
  IsActiveUpdatedTime = 'IS_ACTIVE_UPDATED_TIME',
  IsActiveUpdatedTimeTruncatedToDay = 'IS_ACTIVE_UPDATED_TIME_TRUNCATED_TO_DAY',
  IsActiveUpdatedTimeTruncatedToHour = 'IS_ACTIVE_UPDATED_TIME_TRUNCATED_TO_HOUR',
  IsAlias = 'IS_ALIAS',
  IsImpromptu = 'IS_IMPROMPTU',
  IsImpromptuUpdatedTime = 'IS_IMPROMPTU_UPDATED_TIME',
  IsImpromptuUpdatedTimeTruncatedToDay = 'IS_IMPROMPTU_UPDATED_TIME_TRUNCATED_TO_DAY',
  IsImpromptuUpdatedTimeTruncatedToHour = 'IS_IMPROMPTU_UPDATED_TIME_TRUNCATED_TO_HOUR',
  IsPrimary = 'IS_PRIMARY',
  LastName = 'LAST_NAME',
  LawyerId = 'LAWYER_ID',
  LogoSrc = 'LOGO_SRC',
  PausedUntilTime = 'PAUSED_UNTIL_TIME',
  PausedUntilTimeTruncatedToDay = 'PAUSED_UNTIL_TIME_TRUNCATED_TO_DAY',
  PausedUntilTimeTruncatedToHour = 'PAUSED_UNTIL_TIME_TRUNCATED_TO_HOUR',
  Priority = 'PRIORITY',
  ProfilePictureId = 'PROFILE_PICTURE_ID',
  ProfilePictureStatus = 'PROFILE_PICTURE_STATUS',
  ProfileType = 'PROFILE_TYPE',
  ReachedDailyCap = 'REACHED_DAILY_CAP',
  SignatureHtml = 'SIGNATURE_HTML',
  Title = 'TITLE',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR',
  Username = 'USERNAME',
  VerificationType = 'VERIFICATION_TYPE',
  WarmupInboxFilterId = 'WARMUP_INBOX_FILTER_ID',
  WarmupInboxId = 'WARMUP_INBOX_ID',
  WebsiteUrl = 'WEBSITE_URL'
}

export type SenderProfileHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  currentDailyCap?: InputMaybe<HavingIntFilter>;
  dailyCap?: InputMaybe<HavingIntFilter>;
  dailyCapIncrement?: InputMaybe<HavingIntFilter>;
  isActiveUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  isImpromptuUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  pausedUntilTime?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SenderProfileHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  currentDailyCap?: InputMaybe<HavingIntFilter>;
  dailyCap?: InputMaybe<HavingIntFilter>;
  dailyCapIncrement?: InputMaybe<HavingIntFilter>;
  isActiveUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  isImpromptuUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  pausedUntilTime?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `SenderProfile` aggregates. */
export type SenderProfileHavingInput = {
  AND?: InputMaybe<Array<SenderProfileHavingInput>>;
  OR?: InputMaybe<Array<SenderProfileHavingInput>>;
  average?: InputMaybe<SenderProfileHavingAverageInput>;
  distinctCount?: InputMaybe<SenderProfileHavingDistinctCountInput>;
  max?: InputMaybe<SenderProfileHavingMaxInput>;
  min?: InputMaybe<SenderProfileHavingMinInput>;
  stddevPopulation?: InputMaybe<SenderProfileHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SenderProfileHavingStddevSampleInput>;
  sum?: InputMaybe<SenderProfileHavingSumInput>;
  variancePopulation?: InputMaybe<SenderProfileHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SenderProfileHavingVarianceSampleInput>;
};

export type SenderProfileHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  currentDailyCap?: InputMaybe<HavingIntFilter>;
  dailyCap?: InputMaybe<HavingIntFilter>;
  dailyCapIncrement?: InputMaybe<HavingIntFilter>;
  isActiveUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  isImpromptuUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  pausedUntilTime?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SenderProfileHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  currentDailyCap?: InputMaybe<HavingIntFilter>;
  dailyCap?: InputMaybe<HavingIntFilter>;
  dailyCapIncrement?: InputMaybe<HavingIntFilter>;
  isActiveUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  isImpromptuUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  pausedUntilTime?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SenderProfileHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  currentDailyCap?: InputMaybe<HavingIntFilter>;
  dailyCap?: InputMaybe<HavingIntFilter>;
  dailyCapIncrement?: InputMaybe<HavingIntFilter>;
  isActiveUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  isImpromptuUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  pausedUntilTime?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SenderProfileHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  currentDailyCap?: InputMaybe<HavingIntFilter>;
  dailyCap?: InputMaybe<HavingIntFilter>;
  dailyCapIncrement?: InputMaybe<HavingIntFilter>;
  isActiveUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  isImpromptuUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  pausedUntilTime?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SenderProfileHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  currentDailyCap?: InputMaybe<HavingIntFilter>;
  dailyCap?: InputMaybe<HavingIntFilter>;
  dailyCapIncrement?: InputMaybe<HavingIntFilter>;
  isActiveUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  isImpromptuUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  pausedUntilTime?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SenderProfileHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  currentDailyCap?: InputMaybe<HavingIntFilter>;
  dailyCap?: InputMaybe<HavingIntFilter>;
  dailyCapIncrement?: InputMaybe<HavingIntFilter>;
  isActiveUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  isImpromptuUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  pausedUntilTime?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SenderProfileHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  currentDailyCap?: InputMaybe<HavingIntFilter>;
  dailyCap?: InputMaybe<HavingIntFilter>;
  dailyCapIncrement?: InputMaybe<HavingIntFilter>;
  isActiveUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  isImpromptuUpdatedTime?: InputMaybe<HavingDatetimeFilter>;
  pausedUntilTime?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SenderProfileMaxAggregates = {
  __typename?: 'SenderProfileMaxAggregates';
  /** Maximum of currentDailyCap across the matching connection */
  currentDailyCap?: Maybe<Scalars['Int']['output']>;
  /** Maximum of dailyCap across the matching connection */
  dailyCap?: Maybe<Scalars['Int']['output']>;
  /** Maximum of dailyCapIncrement across the matching connection */
  dailyCapIncrement?: Maybe<Scalars['Int']['output']>;
  /** Maximum of priority across the matching connection */
  priority?: Maybe<Scalars['Int']['output']>;
};

export type SenderProfileMinAggregates = {
  __typename?: 'SenderProfileMinAggregates';
  /** Minimum of currentDailyCap across the matching connection */
  currentDailyCap?: Maybe<Scalars['Int']['output']>;
  /** Minimum of dailyCap across the matching connection */
  dailyCap?: Maybe<Scalars['Int']['output']>;
  /** Minimum of dailyCapIncrement across the matching connection */
  dailyCapIncrement?: Maybe<Scalars['Int']['output']>;
  /** Minimum of priority across the matching connection */
  priority?: Maybe<Scalars['Int']['output']>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type SenderProfileSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: SenderProfileSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type SenderProfileSortBy = {
  field?: InputMaybe<SenderProfileSortableField>;
};

/** Sortable concrete fields for the `SenderProfile` type. */
export enum SenderProfileSortableField {
  BannedFromTalkingToGmail = 'BANNED_FROM_TALKING_TO_GMAIL',
  BrandColor = 'BRAND_COLOR',
  CreatedTime = 'CREATED_TIME',
  CurrentDailyCap = 'CURRENT_DAILY_CAP',
  CurrentDailyCapByEsp = 'CURRENT_DAILY_CAP_BY_ESP',
  DailyCap = 'DAILY_CAP',
  DailyCapByEsp = 'DAILY_CAP_BY_ESP',
  DailyCapIncrement = 'DAILY_CAP_INCREMENT',
  Email = 'EMAIL',
  FirmName = 'FIRM_NAME',
  FirstName = 'FIRST_NAME',
  Id = 'ID',
  IsActive = 'IS_ACTIVE',
  IsActiveUpdatedTime = 'IS_ACTIVE_UPDATED_TIME',
  IsAlias = 'IS_ALIAS',
  IsImpromptu = 'IS_IMPROMPTU',
  IsImpromptuUpdatedTime = 'IS_IMPROMPTU_UPDATED_TIME',
  IsPrimary = 'IS_PRIMARY',
  LastName = 'LAST_NAME',
  LawyerId = 'LAWYER_ID',
  LogoSrc = 'LOGO_SRC',
  PausedUntilTime = 'PAUSED_UNTIL_TIME',
  Priority = 'PRIORITY',
  ProfilePictureId = 'PROFILE_PICTURE_ID',
  ProfilePictureStatus = 'PROFILE_PICTURE_STATUS',
  ProfileType = 'PROFILE_TYPE',
  ReachedDailyCap = 'REACHED_DAILY_CAP',
  SignatureHtml = 'SIGNATURE_HTML',
  Title = 'TITLE',
  UpdatedTime = 'UPDATED_TIME',
  Username = 'USERNAME',
  VerificationType = 'VERIFICATION_TYPE',
  WarmupInboxFilterId = 'WARMUP_INBOX_FILTER_ID',
  WarmupInboxId = 'WARMUP_INBOX_ID',
  WebsiteUrl = 'WEBSITE_URL'
}

export type SenderProfileStddevPopulationAggregates = {
  __typename?: 'SenderProfileStddevPopulationAggregates';
  /** Population standard deviation of currentDailyCap across the matching connection */
  currentDailyCap?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of dailyCap across the matching connection */
  dailyCap?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of dailyCapIncrement across the matching connection */
  dailyCapIncrement?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of priority across the matching connection */
  priority?: Maybe<Scalars['BigFloat']['output']>;
};

export type SenderProfileStddevSampleAggregates = {
  __typename?: 'SenderProfileStddevSampleAggregates';
  /** Sample standard deviation of currentDailyCap across the matching connection */
  currentDailyCap?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of dailyCap across the matching connection */
  dailyCap?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of dailyCapIncrement across the matching connection */
  dailyCapIncrement?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of priority across the matching connection */
  priority?: Maybe<Scalars['BigFloat']['output']>;
};

export type SenderProfileSumAggregates = {
  __typename?: 'SenderProfileSumAggregates';
  /** Sum of currentDailyCap across the matching connection */
  currentDailyCap: Scalars['BigInt']['output'];
  /** Sum of dailyCap across the matching connection */
  dailyCap: Scalars['BigInt']['output'];
  /** Sum of dailyCapIncrement across the matching connection */
  dailyCapIncrement: Scalars['BigInt']['output'];
  /** Sum of priority across the matching connection */
  priority: Scalars['BigInt']['output'];
};

export enum SenderProfileType {
  HedwigGmail = 'HEDWIG_GMAIL',
  HedwigOutlook = 'HEDWIG_OUTLOOK',
  HedwigSmtp = 'HEDWIG_SMTP',
  Nylas = 'NYLAS',
  ScrapedGmail = 'SCRAPED_GMAIL',
  ScrapedOutlook = 'SCRAPED_OUTLOOK',
  Sendgrid = 'SENDGRID',
  Support = 'SUPPORT',
  WireGmail = 'WIRE_GMAIL',
  WireInframail = 'WIRE_INFRAMAIL',
  WireMailforge = 'WIRE_MAILFORGE',
  WireMailscale = 'WIRE_MAILSCALE',
  WireOutlook = 'WIRE_OUTLOOK'
}

/** A filter to be used against SenderProfileType fields. All fields are combined with a logical ‘and.’ */
export type SenderProfileTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<SenderProfileType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<SenderProfileType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<SenderProfileType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<SenderProfileType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<SenderProfileType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<SenderProfileType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<SenderProfileType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<SenderProfileType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<SenderProfileType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<SenderProfileType>>;
};

export type SenderProfileVariancePopulationAggregates = {
  __typename?: 'SenderProfileVariancePopulationAggregates';
  /** Population variance of currentDailyCap across the matching connection */
  currentDailyCap?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of dailyCap across the matching connection */
  dailyCap?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of dailyCapIncrement across the matching connection */
  dailyCapIncrement?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of priority across the matching connection */
  priority?: Maybe<Scalars['BigFloat']['output']>;
};

export type SenderProfileVarianceSampleAggregates = {
  __typename?: 'SenderProfileVarianceSampleAggregates';
  /** Sample variance of currentDailyCap across the matching connection */
  currentDailyCap?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of dailyCap across the matching connection */
  dailyCap?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of dailyCapIncrement across the matching connection */
  dailyCapIncrement?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of priority across the matching connection */
  priority?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `SenderProfile` values. */
export type SenderProfilesConnection = {
  __typename?: 'SenderProfilesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SenderProfileAggregates>;
  /** A list of edges which contains the `SenderProfile` and cursor to aid in pagination. */
  edges: Array<SenderProfilesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SenderProfileAggregates>>;
  /** A list of `SenderProfile` objects. */
  nodes: Array<Maybe<SenderProfile>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SenderProfile` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `SenderProfile` values. */
export type SenderProfilesConnectionGroupedAggregatesArgs = {
  groupBy: Array<SenderProfileGroupBy>;
  having?: InputMaybe<SenderProfileHavingInput>;
};

/** A `SenderProfile` edge in the connection. */
export type SenderProfilesEdge = {
  __typename?: 'SenderProfilesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `SenderProfile` at the end of the edge. */
  node?: Maybe<SenderProfile>;
};

/** Methods to use when ordering `SenderProfile`. */
export enum SenderProfilesOrderBy {
  AccountTokensBySenderProfileIdAverageCreatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_ASC',
  AccountTokensBySenderProfileIdAverageCreatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_DESC',
  AccountTokensBySenderProfileIdAverageIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_ID_ASC',
  AccountTokensBySenderProfileIdAverageIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_ID_DESC',
  AccountTokensBySenderProfileIdAverageOauthClientIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_OAUTH_CLIENT_ID_ASC',
  AccountTokensBySenderProfileIdAverageOauthClientIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_OAUTH_CLIENT_ID_DESC',
  AccountTokensBySenderProfileIdAverageSenderProfileIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  AccountTokensBySenderProfileIdAverageSenderProfileIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  AccountTokensBySenderProfileIdAverageTokenAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_TOKEN_ASC',
  AccountTokensBySenderProfileIdAverageTokenDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_TOKEN_DESC',
  AccountTokensBySenderProfileIdAverageTokenTypeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_TOKEN_TYPE_ASC',
  AccountTokensBySenderProfileIdAverageTokenTypeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_TOKEN_TYPE_DESC',
  AccountTokensBySenderProfileIdAverageUpdatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_ASC',
  AccountTokensBySenderProfileIdAverageUpdatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_DESC',
  AccountTokensBySenderProfileIdCountAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_COUNT_ASC',
  AccountTokensBySenderProfileIdCountDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_COUNT_DESC',
  AccountTokensBySenderProfileIdDistinctCountCreatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  AccountTokensBySenderProfileIdDistinctCountCreatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  AccountTokensBySenderProfileIdDistinctCountIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_ASC',
  AccountTokensBySenderProfileIdDistinctCountIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_DESC',
  AccountTokensBySenderProfileIdDistinctCountOauthClientIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_OAUTH_CLIENT_ID_ASC',
  AccountTokensBySenderProfileIdDistinctCountOauthClientIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_OAUTH_CLIENT_ID_DESC',
  AccountTokensBySenderProfileIdDistinctCountSenderProfileIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  AccountTokensBySenderProfileIdDistinctCountSenderProfileIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  AccountTokensBySenderProfileIdDistinctCountTokenAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_TOKEN_ASC',
  AccountTokensBySenderProfileIdDistinctCountTokenDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_TOKEN_DESC',
  AccountTokensBySenderProfileIdDistinctCountTokenTypeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_TOKEN_TYPE_ASC',
  AccountTokensBySenderProfileIdDistinctCountTokenTypeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_TOKEN_TYPE_DESC',
  AccountTokensBySenderProfileIdDistinctCountUpdatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  AccountTokensBySenderProfileIdDistinctCountUpdatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  AccountTokensBySenderProfileIdMaxCreatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_CREATED_TIME_ASC',
  AccountTokensBySenderProfileIdMaxCreatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_CREATED_TIME_DESC',
  AccountTokensBySenderProfileIdMaxIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_ID_ASC',
  AccountTokensBySenderProfileIdMaxIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_ID_DESC',
  AccountTokensBySenderProfileIdMaxOauthClientIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_OAUTH_CLIENT_ID_ASC',
  AccountTokensBySenderProfileIdMaxOauthClientIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_OAUTH_CLIENT_ID_DESC',
  AccountTokensBySenderProfileIdMaxSenderProfileIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_ASC',
  AccountTokensBySenderProfileIdMaxSenderProfileIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_DESC',
  AccountTokensBySenderProfileIdMaxTokenAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_TOKEN_ASC',
  AccountTokensBySenderProfileIdMaxTokenDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_TOKEN_DESC',
  AccountTokensBySenderProfileIdMaxTokenTypeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_TOKEN_TYPE_ASC',
  AccountTokensBySenderProfileIdMaxTokenTypeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_TOKEN_TYPE_DESC',
  AccountTokensBySenderProfileIdMaxUpdatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_ASC',
  AccountTokensBySenderProfileIdMaxUpdatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_DESC',
  AccountTokensBySenderProfileIdMinCreatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_CREATED_TIME_ASC',
  AccountTokensBySenderProfileIdMinCreatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_CREATED_TIME_DESC',
  AccountTokensBySenderProfileIdMinIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_ID_ASC',
  AccountTokensBySenderProfileIdMinIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_ID_DESC',
  AccountTokensBySenderProfileIdMinOauthClientIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_OAUTH_CLIENT_ID_ASC',
  AccountTokensBySenderProfileIdMinOauthClientIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_OAUTH_CLIENT_ID_DESC',
  AccountTokensBySenderProfileIdMinSenderProfileIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_ASC',
  AccountTokensBySenderProfileIdMinSenderProfileIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_DESC',
  AccountTokensBySenderProfileIdMinTokenAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_TOKEN_ASC',
  AccountTokensBySenderProfileIdMinTokenDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_TOKEN_DESC',
  AccountTokensBySenderProfileIdMinTokenTypeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_TOKEN_TYPE_ASC',
  AccountTokensBySenderProfileIdMinTokenTypeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_TOKEN_TYPE_DESC',
  AccountTokensBySenderProfileIdMinUpdatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_ASC',
  AccountTokensBySenderProfileIdMinUpdatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_DESC',
  AccountTokensBySenderProfileIdStddevPopulationCreatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  AccountTokensBySenderProfileIdStddevPopulationCreatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  AccountTokensBySenderProfileIdStddevPopulationIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_ASC',
  AccountTokensBySenderProfileIdStddevPopulationIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_DESC',
  AccountTokensBySenderProfileIdStddevPopulationOauthClientIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_OAUTH_CLIENT_ID_ASC',
  AccountTokensBySenderProfileIdStddevPopulationOauthClientIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_OAUTH_CLIENT_ID_DESC',
  AccountTokensBySenderProfileIdStddevPopulationSenderProfileIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  AccountTokensBySenderProfileIdStddevPopulationSenderProfileIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  AccountTokensBySenderProfileIdStddevPopulationTokenAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_TOKEN_ASC',
  AccountTokensBySenderProfileIdStddevPopulationTokenDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_TOKEN_DESC',
  AccountTokensBySenderProfileIdStddevPopulationTokenTypeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_TOKEN_TYPE_ASC',
  AccountTokensBySenderProfileIdStddevPopulationTokenTypeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_TOKEN_TYPE_DESC',
  AccountTokensBySenderProfileIdStddevPopulationUpdatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  AccountTokensBySenderProfileIdStddevPopulationUpdatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  AccountTokensBySenderProfileIdStddevSampleCreatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  AccountTokensBySenderProfileIdStddevSampleCreatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  AccountTokensBySenderProfileIdStddevSampleIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_ASC',
  AccountTokensBySenderProfileIdStddevSampleIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_DESC',
  AccountTokensBySenderProfileIdStddevSampleOauthClientIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_OAUTH_CLIENT_ID_ASC',
  AccountTokensBySenderProfileIdStddevSampleOauthClientIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_OAUTH_CLIENT_ID_DESC',
  AccountTokensBySenderProfileIdStddevSampleSenderProfileIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  AccountTokensBySenderProfileIdStddevSampleSenderProfileIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  AccountTokensBySenderProfileIdStddevSampleTokenAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_TOKEN_ASC',
  AccountTokensBySenderProfileIdStddevSampleTokenDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_TOKEN_DESC',
  AccountTokensBySenderProfileIdStddevSampleTokenTypeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_TOKEN_TYPE_ASC',
  AccountTokensBySenderProfileIdStddevSampleTokenTypeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_TOKEN_TYPE_DESC',
  AccountTokensBySenderProfileIdStddevSampleUpdatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  AccountTokensBySenderProfileIdStddevSampleUpdatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  AccountTokensBySenderProfileIdSumCreatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_CREATED_TIME_ASC',
  AccountTokensBySenderProfileIdSumCreatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_CREATED_TIME_DESC',
  AccountTokensBySenderProfileIdSumIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_ID_ASC',
  AccountTokensBySenderProfileIdSumIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_ID_DESC',
  AccountTokensBySenderProfileIdSumOauthClientIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_OAUTH_CLIENT_ID_ASC',
  AccountTokensBySenderProfileIdSumOauthClientIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_OAUTH_CLIENT_ID_DESC',
  AccountTokensBySenderProfileIdSumSenderProfileIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_ASC',
  AccountTokensBySenderProfileIdSumSenderProfileIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_DESC',
  AccountTokensBySenderProfileIdSumTokenAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_TOKEN_ASC',
  AccountTokensBySenderProfileIdSumTokenDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_TOKEN_DESC',
  AccountTokensBySenderProfileIdSumTokenTypeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_TOKEN_TYPE_ASC',
  AccountTokensBySenderProfileIdSumTokenTypeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_TOKEN_TYPE_DESC',
  AccountTokensBySenderProfileIdSumUpdatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_ASC',
  AccountTokensBySenderProfileIdSumUpdatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_DESC',
  AccountTokensBySenderProfileIdVariancePopulationCreatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  AccountTokensBySenderProfileIdVariancePopulationCreatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  AccountTokensBySenderProfileIdVariancePopulationIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_ASC',
  AccountTokensBySenderProfileIdVariancePopulationIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_DESC',
  AccountTokensBySenderProfileIdVariancePopulationOauthClientIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_OAUTH_CLIENT_ID_ASC',
  AccountTokensBySenderProfileIdVariancePopulationOauthClientIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_OAUTH_CLIENT_ID_DESC',
  AccountTokensBySenderProfileIdVariancePopulationSenderProfileIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  AccountTokensBySenderProfileIdVariancePopulationSenderProfileIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  AccountTokensBySenderProfileIdVariancePopulationTokenAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_TOKEN_ASC',
  AccountTokensBySenderProfileIdVariancePopulationTokenDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_TOKEN_DESC',
  AccountTokensBySenderProfileIdVariancePopulationTokenTypeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_TOKEN_TYPE_ASC',
  AccountTokensBySenderProfileIdVariancePopulationTokenTypeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_TOKEN_TYPE_DESC',
  AccountTokensBySenderProfileIdVariancePopulationUpdatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  AccountTokensBySenderProfileIdVariancePopulationUpdatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  AccountTokensBySenderProfileIdVarianceSampleCreatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  AccountTokensBySenderProfileIdVarianceSampleCreatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  AccountTokensBySenderProfileIdVarianceSampleIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_ASC',
  AccountTokensBySenderProfileIdVarianceSampleIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_DESC',
  AccountTokensBySenderProfileIdVarianceSampleOauthClientIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_OAUTH_CLIENT_ID_ASC',
  AccountTokensBySenderProfileIdVarianceSampleOauthClientIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_OAUTH_CLIENT_ID_DESC',
  AccountTokensBySenderProfileIdVarianceSampleSenderProfileIdAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  AccountTokensBySenderProfileIdVarianceSampleSenderProfileIdDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  AccountTokensBySenderProfileIdVarianceSampleTokenAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_TOKEN_ASC',
  AccountTokensBySenderProfileIdVarianceSampleTokenDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_TOKEN_DESC',
  AccountTokensBySenderProfileIdVarianceSampleTokenTypeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_TOKEN_TYPE_ASC',
  AccountTokensBySenderProfileIdVarianceSampleTokenTypeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_TOKEN_TYPE_DESC',
  AccountTokensBySenderProfileIdVarianceSampleUpdatedTimeAsc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  AccountTokensBySenderProfileIdVarianceSampleUpdatedTimeDesc = 'ACCOUNT_TOKENS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  BannedFromTalkingToGmailAsc = 'BANNED_FROM_TALKING_TO_GMAIL_ASC',
  BannedFromTalkingToGmailDesc = 'BANNED_FROM_TALKING_TO_GMAIL_DESC',
  BrandColorAsc = 'BRAND_COLOR_ASC',
  BrandColorDesc = 'BRAND_COLOR_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  CurrentDailyCapAsc = 'CURRENT_DAILY_CAP_ASC',
  CurrentDailyCapByEspAsc = 'CURRENT_DAILY_CAP_BY_ESP_ASC',
  CurrentDailyCapByEspDesc = 'CURRENT_DAILY_CAP_BY_ESP_DESC',
  CurrentDailyCapDesc = 'CURRENT_DAILY_CAP_DESC',
  DailyCapAsc = 'DAILY_CAP_ASC',
  DailyCapByEspAsc = 'DAILY_CAP_BY_ESP_ASC',
  DailyCapByEspDesc = 'DAILY_CAP_BY_ESP_DESC',
  DailyCapDesc = 'DAILY_CAP_DESC',
  DailyCapIncrementAsc = 'DAILY_CAP_INCREMENT_ASC',
  DailyCapIncrementDesc = 'DAILY_CAP_INCREMENT_DESC',
  EmailsBySenderProfileIdAverageCampaignMemberIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  EmailsBySenderProfileIdAverageCampaignMemberIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  EmailsBySenderProfileIdAverageContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_CONTENT_ASC',
  EmailsBySenderProfileIdAverageContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_CONTENT_DESC',
  EmailsBySenderProfileIdAverageCreatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_ASC',
  EmailsBySenderProfileIdAverageCreatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_DESC',
  EmailsBySenderProfileIdAverageIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_ID_ASC',
  EmailsBySenderProfileIdAverageIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_ID_DESC',
  EmailsBySenderProfileIdAverageSenderProfileIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  EmailsBySenderProfileIdAverageSenderProfileIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  EmailsBySenderProfileIdAverageSendAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_SEND_AT_ASC',
  EmailsBySenderProfileIdAverageSendAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_SEND_AT_DESC',
  EmailsBySenderProfileIdAverageSentAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_SENT_AT_ASC',
  EmailsBySenderProfileIdAverageSentAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_SENT_AT_DESC',
  EmailsBySenderProfileIdAverageSmtpMessageIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_SMTP_MESSAGE_ID_ASC',
  EmailsBySenderProfileIdAverageSmtpMessageIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_SMTP_MESSAGE_ID_DESC',
  EmailsBySenderProfileIdAverageStatusAsc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_STATUS_ASC',
  EmailsBySenderProfileIdAverageStatusDesc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_STATUS_DESC',
  EmailsBySenderProfileIdAverageTemplateContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_TEMPLATE_CONTENT_ASC',
  EmailsBySenderProfileIdAverageTemplateContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_TEMPLATE_CONTENT_DESC',
  EmailsBySenderProfileIdAverageTemplateIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_TEMPLATE_ID_ASC',
  EmailsBySenderProfileIdAverageTemplateIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_TEMPLATE_ID_DESC',
  EmailsBySenderProfileIdAverageUpdatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailsBySenderProfileIdAverageUpdatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailsBySenderProfileIdCountAsc = 'EMAILS_BY_SENDER_PROFILE_ID_COUNT_ASC',
  EmailsBySenderProfileIdCountDesc = 'EMAILS_BY_SENDER_PROFILE_ID_COUNT_DESC',
  EmailsBySenderProfileIdDistinctCountCampaignMemberIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  EmailsBySenderProfileIdDistinctCountCampaignMemberIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  EmailsBySenderProfileIdDistinctCountContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CONTENT_ASC',
  EmailsBySenderProfileIdDistinctCountContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CONTENT_DESC',
  EmailsBySenderProfileIdDistinctCountCreatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailsBySenderProfileIdDistinctCountCreatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailsBySenderProfileIdDistinctCountIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_ASC',
  EmailsBySenderProfileIdDistinctCountIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_DESC',
  EmailsBySenderProfileIdDistinctCountSenderProfileIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  EmailsBySenderProfileIdDistinctCountSenderProfileIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  EmailsBySenderProfileIdDistinctCountSendAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SEND_AT_ASC',
  EmailsBySenderProfileIdDistinctCountSendAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SEND_AT_DESC',
  EmailsBySenderProfileIdDistinctCountSentAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENT_AT_ASC',
  EmailsBySenderProfileIdDistinctCountSentAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENT_AT_DESC',
  EmailsBySenderProfileIdDistinctCountSmtpMessageIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_ASC',
  EmailsBySenderProfileIdDistinctCountSmtpMessageIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_DESC',
  EmailsBySenderProfileIdDistinctCountStatusAsc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_STATUS_ASC',
  EmailsBySenderProfileIdDistinctCountStatusDesc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_STATUS_DESC',
  EmailsBySenderProfileIdDistinctCountTemplateContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_TEMPLATE_CONTENT_ASC',
  EmailsBySenderProfileIdDistinctCountTemplateContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_TEMPLATE_CONTENT_DESC',
  EmailsBySenderProfileIdDistinctCountTemplateIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  EmailsBySenderProfileIdDistinctCountTemplateIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  EmailsBySenderProfileIdDistinctCountUpdatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailsBySenderProfileIdDistinctCountUpdatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailsBySenderProfileIdMaxCampaignMemberIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  EmailsBySenderProfileIdMaxCampaignMemberIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  EmailsBySenderProfileIdMaxContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_CONTENT_ASC',
  EmailsBySenderProfileIdMaxContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_CONTENT_DESC',
  EmailsBySenderProfileIdMaxCreatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_CREATED_TIME_ASC',
  EmailsBySenderProfileIdMaxCreatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_CREATED_TIME_DESC',
  EmailsBySenderProfileIdMaxIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_ID_ASC',
  EmailsBySenderProfileIdMaxIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_ID_DESC',
  EmailsBySenderProfileIdMaxSenderProfileIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_ASC',
  EmailsBySenderProfileIdMaxSenderProfileIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_DESC',
  EmailsBySenderProfileIdMaxSendAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_SEND_AT_ASC',
  EmailsBySenderProfileIdMaxSendAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_SEND_AT_DESC',
  EmailsBySenderProfileIdMaxSentAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_SENT_AT_ASC',
  EmailsBySenderProfileIdMaxSentAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_SENT_AT_DESC',
  EmailsBySenderProfileIdMaxSmtpMessageIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_SMTP_MESSAGE_ID_ASC',
  EmailsBySenderProfileIdMaxSmtpMessageIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_SMTP_MESSAGE_ID_DESC',
  EmailsBySenderProfileIdMaxStatusAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_STATUS_ASC',
  EmailsBySenderProfileIdMaxStatusDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_STATUS_DESC',
  EmailsBySenderProfileIdMaxTemplateContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_TEMPLATE_CONTENT_ASC',
  EmailsBySenderProfileIdMaxTemplateContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_TEMPLATE_CONTENT_DESC',
  EmailsBySenderProfileIdMaxTemplateIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_TEMPLATE_ID_ASC',
  EmailsBySenderProfileIdMaxTemplateIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_TEMPLATE_ID_DESC',
  EmailsBySenderProfileIdMaxUpdatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_ASC',
  EmailsBySenderProfileIdMaxUpdatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_DESC',
  EmailsBySenderProfileIdMinCampaignMemberIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  EmailsBySenderProfileIdMinCampaignMemberIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  EmailsBySenderProfileIdMinContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_CONTENT_ASC',
  EmailsBySenderProfileIdMinContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_CONTENT_DESC',
  EmailsBySenderProfileIdMinCreatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_CREATED_TIME_ASC',
  EmailsBySenderProfileIdMinCreatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_CREATED_TIME_DESC',
  EmailsBySenderProfileIdMinIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_ID_ASC',
  EmailsBySenderProfileIdMinIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_ID_DESC',
  EmailsBySenderProfileIdMinSenderProfileIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_ASC',
  EmailsBySenderProfileIdMinSenderProfileIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_DESC',
  EmailsBySenderProfileIdMinSendAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_SEND_AT_ASC',
  EmailsBySenderProfileIdMinSendAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_SEND_AT_DESC',
  EmailsBySenderProfileIdMinSentAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_SENT_AT_ASC',
  EmailsBySenderProfileIdMinSentAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_SENT_AT_DESC',
  EmailsBySenderProfileIdMinSmtpMessageIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_SMTP_MESSAGE_ID_ASC',
  EmailsBySenderProfileIdMinSmtpMessageIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_SMTP_MESSAGE_ID_DESC',
  EmailsBySenderProfileIdMinStatusAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_STATUS_ASC',
  EmailsBySenderProfileIdMinStatusDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_STATUS_DESC',
  EmailsBySenderProfileIdMinTemplateContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_TEMPLATE_CONTENT_ASC',
  EmailsBySenderProfileIdMinTemplateContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_TEMPLATE_CONTENT_DESC',
  EmailsBySenderProfileIdMinTemplateIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_TEMPLATE_ID_ASC',
  EmailsBySenderProfileIdMinTemplateIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_TEMPLATE_ID_DESC',
  EmailsBySenderProfileIdMinUpdatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_ASC',
  EmailsBySenderProfileIdMinUpdatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_DESC',
  EmailsBySenderProfileIdStddevPopulationCampaignMemberIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailsBySenderProfileIdStddevPopulationCampaignMemberIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailsBySenderProfileIdStddevPopulationContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CONTENT_ASC',
  EmailsBySenderProfileIdStddevPopulationContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CONTENT_DESC',
  EmailsBySenderProfileIdStddevPopulationCreatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailsBySenderProfileIdStddevPopulationCreatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailsBySenderProfileIdStddevPopulationIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_ASC',
  EmailsBySenderProfileIdStddevPopulationIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_DESC',
  EmailsBySenderProfileIdStddevPopulationSenderProfileIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailsBySenderProfileIdStddevPopulationSenderProfileIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailsBySenderProfileIdStddevPopulationSendAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SEND_AT_ASC',
  EmailsBySenderProfileIdStddevPopulationSendAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SEND_AT_DESC',
  EmailsBySenderProfileIdStddevPopulationSentAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENT_AT_ASC',
  EmailsBySenderProfileIdStddevPopulationSentAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENT_AT_DESC',
  EmailsBySenderProfileIdStddevPopulationSmtpMessageIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailsBySenderProfileIdStddevPopulationSmtpMessageIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailsBySenderProfileIdStddevPopulationStatusAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_STATUS_ASC',
  EmailsBySenderProfileIdStddevPopulationStatusDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_STATUS_DESC',
  EmailsBySenderProfileIdStddevPopulationTemplateContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_TEMPLATE_CONTENT_ASC',
  EmailsBySenderProfileIdStddevPopulationTemplateContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_TEMPLATE_CONTENT_DESC',
  EmailsBySenderProfileIdStddevPopulationTemplateIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  EmailsBySenderProfileIdStddevPopulationTemplateIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  EmailsBySenderProfileIdStddevPopulationUpdatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailsBySenderProfileIdStddevPopulationUpdatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailsBySenderProfileIdStddevSampleCampaignMemberIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailsBySenderProfileIdStddevSampleCampaignMemberIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailsBySenderProfileIdStddevSampleContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CONTENT_ASC',
  EmailsBySenderProfileIdStddevSampleContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CONTENT_DESC',
  EmailsBySenderProfileIdStddevSampleCreatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailsBySenderProfileIdStddevSampleCreatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailsBySenderProfileIdStddevSampleIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_ASC',
  EmailsBySenderProfileIdStddevSampleIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_DESC',
  EmailsBySenderProfileIdStddevSampleSenderProfileIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailsBySenderProfileIdStddevSampleSenderProfileIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailsBySenderProfileIdStddevSampleSendAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SEND_AT_ASC',
  EmailsBySenderProfileIdStddevSampleSendAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SEND_AT_DESC',
  EmailsBySenderProfileIdStddevSampleSentAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENT_AT_ASC',
  EmailsBySenderProfileIdStddevSampleSentAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENT_AT_DESC',
  EmailsBySenderProfileIdStddevSampleSmtpMessageIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailsBySenderProfileIdStddevSampleSmtpMessageIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailsBySenderProfileIdStddevSampleStatusAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_STATUS_ASC',
  EmailsBySenderProfileIdStddevSampleStatusDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_STATUS_DESC',
  EmailsBySenderProfileIdStddevSampleTemplateContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_TEMPLATE_CONTENT_ASC',
  EmailsBySenderProfileIdStddevSampleTemplateContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_TEMPLATE_CONTENT_DESC',
  EmailsBySenderProfileIdStddevSampleTemplateIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  EmailsBySenderProfileIdStddevSampleTemplateIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  EmailsBySenderProfileIdStddevSampleUpdatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailsBySenderProfileIdStddevSampleUpdatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailsBySenderProfileIdSumCampaignMemberIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  EmailsBySenderProfileIdSumCampaignMemberIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  EmailsBySenderProfileIdSumContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_CONTENT_ASC',
  EmailsBySenderProfileIdSumContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_CONTENT_DESC',
  EmailsBySenderProfileIdSumCreatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_CREATED_TIME_ASC',
  EmailsBySenderProfileIdSumCreatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_CREATED_TIME_DESC',
  EmailsBySenderProfileIdSumIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_ID_ASC',
  EmailsBySenderProfileIdSumIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_ID_DESC',
  EmailsBySenderProfileIdSumSenderProfileIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_ASC',
  EmailsBySenderProfileIdSumSenderProfileIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_DESC',
  EmailsBySenderProfileIdSumSendAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_SEND_AT_ASC',
  EmailsBySenderProfileIdSumSendAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_SEND_AT_DESC',
  EmailsBySenderProfileIdSumSentAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_SENT_AT_ASC',
  EmailsBySenderProfileIdSumSentAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_SENT_AT_DESC',
  EmailsBySenderProfileIdSumSmtpMessageIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_SMTP_MESSAGE_ID_ASC',
  EmailsBySenderProfileIdSumSmtpMessageIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_SMTP_MESSAGE_ID_DESC',
  EmailsBySenderProfileIdSumStatusAsc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_STATUS_ASC',
  EmailsBySenderProfileIdSumStatusDesc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_STATUS_DESC',
  EmailsBySenderProfileIdSumTemplateContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_TEMPLATE_CONTENT_ASC',
  EmailsBySenderProfileIdSumTemplateContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_TEMPLATE_CONTENT_DESC',
  EmailsBySenderProfileIdSumTemplateIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_TEMPLATE_ID_ASC',
  EmailsBySenderProfileIdSumTemplateIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_TEMPLATE_ID_DESC',
  EmailsBySenderProfileIdSumUpdatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_ASC',
  EmailsBySenderProfileIdSumUpdatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_DESC',
  EmailsBySenderProfileIdVariancePopulationCampaignMemberIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailsBySenderProfileIdVariancePopulationCampaignMemberIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailsBySenderProfileIdVariancePopulationContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CONTENT_ASC',
  EmailsBySenderProfileIdVariancePopulationContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CONTENT_DESC',
  EmailsBySenderProfileIdVariancePopulationCreatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailsBySenderProfileIdVariancePopulationCreatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailsBySenderProfileIdVariancePopulationIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_ASC',
  EmailsBySenderProfileIdVariancePopulationIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_DESC',
  EmailsBySenderProfileIdVariancePopulationSenderProfileIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailsBySenderProfileIdVariancePopulationSenderProfileIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailsBySenderProfileIdVariancePopulationSendAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SEND_AT_ASC',
  EmailsBySenderProfileIdVariancePopulationSendAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SEND_AT_DESC',
  EmailsBySenderProfileIdVariancePopulationSentAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENT_AT_ASC',
  EmailsBySenderProfileIdVariancePopulationSentAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENT_AT_DESC',
  EmailsBySenderProfileIdVariancePopulationSmtpMessageIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailsBySenderProfileIdVariancePopulationSmtpMessageIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailsBySenderProfileIdVariancePopulationStatusAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_STATUS_ASC',
  EmailsBySenderProfileIdVariancePopulationStatusDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_STATUS_DESC',
  EmailsBySenderProfileIdVariancePopulationTemplateContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_TEMPLATE_CONTENT_ASC',
  EmailsBySenderProfileIdVariancePopulationTemplateContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_TEMPLATE_CONTENT_DESC',
  EmailsBySenderProfileIdVariancePopulationTemplateIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  EmailsBySenderProfileIdVariancePopulationTemplateIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  EmailsBySenderProfileIdVariancePopulationUpdatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailsBySenderProfileIdVariancePopulationUpdatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailsBySenderProfileIdVarianceSampleCampaignMemberIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailsBySenderProfileIdVarianceSampleCampaignMemberIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailsBySenderProfileIdVarianceSampleContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  EmailsBySenderProfileIdVarianceSampleContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  EmailsBySenderProfileIdVarianceSampleCreatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailsBySenderProfileIdVarianceSampleCreatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailsBySenderProfileIdVarianceSampleIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailsBySenderProfileIdVarianceSampleIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailsBySenderProfileIdVarianceSampleSenderProfileIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailsBySenderProfileIdVarianceSampleSenderProfileIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailsBySenderProfileIdVarianceSampleSendAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SEND_AT_ASC',
  EmailsBySenderProfileIdVarianceSampleSendAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SEND_AT_DESC',
  EmailsBySenderProfileIdVarianceSampleSentAtAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENT_AT_ASC',
  EmailsBySenderProfileIdVarianceSampleSentAtDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENT_AT_DESC',
  EmailsBySenderProfileIdVarianceSampleSmtpMessageIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailsBySenderProfileIdVarianceSampleSmtpMessageIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailsBySenderProfileIdVarianceSampleStatusAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_STATUS_ASC',
  EmailsBySenderProfileIdVarianceSampleStatusDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_STATUS_DESC',
  EmailsBySenderProfileIdVarianceSampleTemplateContentAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_TEMPLATE_CONTENT_ASC',
  EmailsBySenderProfileIdVarianceSampleTemplateContentDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_TEMPLATE_CONTENT_DESC',
  EmailsBySenderProfileIdVarianceSampleTemplateIdAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  EmailsBySenderProfileIdVarianceSampleTemplateIdDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  EmailsBySenderProfileIdVarianceSampleUpdatedTimeAsc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailsBySenderProfileIdVarianceSampleUpdatedTimeDesc = 'EMAILS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  EmailMailboxesBySenderProfileIdAverageConnectorStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_CONNECTOR_STATUS_ASC',
  EmailMailboxesBySenderProfileIdAverageConnectorStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_CONNECTOR_STATUS_DESC',
  EmailMailboxesBySenderProfileIdAverageConnectorStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_CONNECTOR_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageConnectorStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_CONNECTOR_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageCookiesIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_COOKIES_ID_ASC',
  EmailMailboxesBySenderProfileIdAverageCookiesIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_COOKIES_ID_DESC',
  EmailMailboxesBySenderProfileIdAverageCreatedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdAverageCreatedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdAverageCreatedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageCreatedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageCreatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageCreatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageDeletedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DELETED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdAverageDeletedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DELETED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdAverageDeletedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DELETED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageDeletedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DELETED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageDkimStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DKIM_STATUS_ASC',
  EmailMailboxesBySenderProfileIdAverageDkimStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DKIM_STATUS_DESC',
  EmailMailboxesBySenderProfileIdAverageDkimStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DKIM_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageDkimStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DKIM_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageDnsVerifyAttemptCountAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DNS_VERIFY_ATTEMPT_COUNT_ASC',
  EmailMailboxesBySenderProfileIdAverageDnsVerifyAttemptCountDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DNS_VERIFY_ATTEMPT_COUNT_DESC',
  EmailMailboxesBySenderProfileIdAverageDnsVerifyStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DNS_VERIFY_STATUS_ASC',
  EmailMailboxesBySenderProfileIdAverageDnsVerifyStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DNS_VERIFY_STATUS_DESC',
  EmailMailboxesBySenderProfileIdAverageDnsVerifyStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DNS_VERIFY_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageDnsVerifyStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DNS_VERIFY_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageDomainIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DOMAIN_ID_ASC',
  EmailMailboxesBySenderProfileIdAverageDomainIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_DOMAIN_ID_DESC',
  EmailMailboxesBySenderProfileIdAverageEspAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_ESP_ASC',
  EmailMailboxesBySenderProfileIdAverageEspDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_ESP_DESC',
  EmailMailboxesBySenderProfileIdAverageHedwigStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_HEDWIG_STATUS_ASC',
  EmailMailboxesBySenderProfileIdAverageHedwigStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_HEDWIG_STATUS_DESC',
  EmailMailboxesBySenderProfileIdAverageHedwigStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_HEDWIG_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageHedwigStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_HEDWIG_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_ID_ASC',
  EmailMailboxesBySenderProfileIdAverageIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_ID_DESC',
  EmailMailboxesBySenderProfileIdAverageImapStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_IMAP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdAverageImapStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_IMAP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdAverageImapStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_IMAP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageImapStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_IMAP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageLastScheduledLoginAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_LAST_SCHEDULED_LOGIN_ASC',
  EmailMailboxesBySenderProfileIdAverageLastScheduledLoginDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_LAST_SCHEDULED_LOGIN_DESC',
  EmailMailboxesBySenderProfileIdAverageLoginBlockedReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_LOGIN_BLOCKED_REASON_ASC',
  EmailMailboxesBySenderProfileIdAverageLoginBlockedReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_LOGIN_BLOCKED_REASON_DESC',
  EmailMailboxesBySenderProfileIdAverageLoginBlockedSeverityAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_LOGIN_BLOCKED_SEVERITY_ASC',
  EmailMailboxesBySenderProfileIdAverageLoginBlockedSeverityDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_LOGIN_BLOCKED_SEVERITY_DESC',
  EmailMailboxesBySenderProfileIdAverageLoginBlockedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_LOGIN_BLOCKED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdAverageLoginBlockedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_LOGIN_BLOCKED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdAverageLoginBlockedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_LOGIN_BLOCKED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageLoginBlockedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_LOGIN_BLOCKED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageLoginBlockedUntilAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_LOGIN_BLOCKED_UNTIL_ASC',
  EmailMailboxesBySenderProfileIdAverageLoginBlockedUntilDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_LOGIN_BLOCKED_UNTIL_DESC',
  EmailMailboxesBySenderProfileIdAverageMarkedForDeletionAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_MARKED_FOR_DELETION_ASC',
  EmailMailboxesBySenderProfileIdAverageMarkedForDeletionDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_MARKED_FOR_DELETION_DESC',
  EmailMailboxesBySenderProfileIdAverageMarkedForDeletionReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_MARKED_FOR_DELETION_REASON_ASC',
  EmailMailboxesBySenderProfileIdAverageMarkedForDeletionReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_MARKED_FOR_DELETION_REASON_DESC',
  EmailMailboxesBySenderProfileIdAverageMarkedForDeletionUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_MARKED_FOR_DELETION_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageMarkedForDeletionUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_MARKED_FOR_DELETION_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageMfaDisabledStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_MFA_DISABLED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdAverageMfaDisabledStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_MFA_DISABLED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdAverageMfaDisabledStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_MFA_DISABLED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageMfaDisabledStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_MFA_DISABLED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageNylasStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_NYLAS_STATUS_ASC',
  EmailMailboxesBySenderProfileIdAverageNylasStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_NYLAS_STATUS_DESC',
  EmailMailboxesBySenderProfileIdAverageNylasStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_NYLAS_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageNylasStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_NYLAS_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageOauthClientStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_OAUTH_CLIENT_STATUS_ASC',
  EmailMailboxesBySenderProfileIdAverageOauthClientStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_OAUTH_CLIENT_STATUS_DESC',
  EmailMailboxesBySenderProfileIdAverageOauthClientStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_OAUTH_CLIENT_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageOauthClientStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_OAUTH_CLIENT_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageOauthWhitelistStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_OAUTH_WHITELIST_STATUS_ASC',
  EmailMailboxesBySenderProfileIdAverageOauthWhitelistStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_OAUTH_WHITELIST_STATUS_DESC',
  EmailMailboxesBySenderProfileIdAverageOauthWhitelistStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_OAUTH_WHITELIST_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageOauthWhitelistStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_OAUTH_WHITELIST_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageOriginsIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_ORIGINS_ID_ASC',
  EmailMailboxesBySenderProfileIdAverageOriginsIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_ORIGINS_ID_DESC',
  EmailMailboxesBySenderProfileIdAveragePasswordAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_PASSWORD_ASC',
  EmailMailboxesBySenderProfileIdAveragePasswordDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_PASSWORD_DESC',
  EmailMailboxesBySenderProfileIdAveragePasswordUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_PASSWORD_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAveragePasswordUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_PASSWORD_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAveragePreferredWorkspaceIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_PREFERRED_WORKSPACE_ID_ASC',
  EmailMailboxesBySenderProfileIdAveragePreferredWorkspaceIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_PREFERRED_WORKSPACE_ID_DESC',
  EmailMailboxesBySenderProfileIdAverageSenderProfileIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  EmailMailboxesBySenderProfileIdAverageSenderProfileIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  EmailMailboxesBySenderProfileIdAverageShouldHavePersonalOauthClientAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_ASC',
  EmailMailboxesBySenderProfileIdAverageShouldHavePersonalOauthClientDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_DESC',
  EmailMailboxesBySenderProfileIdAverageStopOutreachAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_STOP_OUTREACH_ASC',
  EmailMailboxesBySenderProfileIdAverageStopOutreachDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_STOP_OUTREACH_DESC',
  EmailMailboxesBySenderProfileIdAverageStopOutreachReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_STOP_OUTREACH_REASON_ASC',
  EmailMailboxesBySenderProfileIdAverageStopOutreachReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_STOP_OUTREACH_REASON_DESC',
  EmailMailboxesBySenderProfileIdAverageStopOutreachUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_STOP_OUTREACH_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageStopOutreachUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_STOP_OUTREACH_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageThirdPartyProviderIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_THIRD_PARTY_PROVIDER_ID_ASC',
  EmailMailboxesBySenderProfileIdAverageThirdPartyProviderIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_THIRD_PARTY_PROVIDER_ID_DESC',
  EmailMailboxesBySenderProfileIdAverageUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageWarmupProviderAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_WARMUP_PROVIDER_ASC',
  EmailMailboxesBySenderProfileIdAverageWarmupProviderDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_WARMUP_PROVIDER_DESC',
  EmailMailboxesBySenderProfileIdAverageWarmupStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_WARMUP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdAverageWarmupStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_WARMUP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdAverageWarmupStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_WARMUP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdAverageWarmupStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_WARMUP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdAverageWarmupStrategyAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_WARMUP_STRATEGY_ASC',
  EmailMailboxesBySenderProfileIdAverageWarmupStrategyDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_AVERAGE_WARMUP_STRATEGY_DESC',
  EmailMailboxesBySenderProfileIdCountAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_COUNT_ASC',
  EmailMailboxesBySenderProfileIdCountDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_COUNT_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountConnectorStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CONNECTOR_STATUS_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountConnectorStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CONNECTOR_STATUS_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountConnectorStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CONNECTOR_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountConnectorStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CONNECTOR_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountCookiesIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_COOKIES_ID_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountCookiesIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_COOKIES_ID_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountCreatedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountCreatedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountCreatedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountCreatedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountCreatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountCreatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountDeletedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DELETED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountDeletedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DELETED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountDeletedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DELETED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountDeletedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DELETED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountDkimStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DKIM_STATUS_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountDkimStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DKIM_STATUS_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountDkimStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DKIM_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountDkimStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DKIM_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountDnsVerifyAttemptCountAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DNS_VERIFY_ATTEMPT_COUNT_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountDnsVerifyAttemptCountDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DNS_VERIFY_ATTEMPT_COUNT_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountDnsVerifyStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DNS_VERIFY_STATUS_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountDnsVerifyStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DNS_VERIFY_STATUS_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountDnsVerifyStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DNS_VERIFY_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountDnsVerifyStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DNS_VERIFY_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountDomainIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DOMAIN_ID_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountDomainIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_DOMAIN_ID_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountEspAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ESP_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountEspDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ESP_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountHedwigStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_HEDWIG_STATUS_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountHedwigStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_HEDWIG_STATUS_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountHedwigStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_HEDWIG_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountHedwigStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_HEDWIG_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountImapStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_IMAP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountImapStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_IMAP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountImapStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_IMAP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountImapStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_IMAP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountLastScheduledLoginAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LAST_SCHEDULED_LOGIN_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountLastScheduledLoginDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LAST_SCHEDULED_LOGIN_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountLoginBlockedReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LOGIN_BLOCKED_REASON_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountLoginBlockedReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LOGIN_BLOCKED_REASON_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountLoginBlockedSeverityAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LOGIN_BLOCKED_SEVERITY_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountLoginBlockedSeverityDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LOGIN_BLOCKED_SEVERITY_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountLoginBlockedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LOGIN_BLOCKED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountLoginBlockedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LOGIN_BLOCKED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountLoginBlockedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LOGIN_BLOCKED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountLoginBlockedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LOGIN_BLOCKED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountLoginBlockedUntilAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LOGIN_BLOCKED_UNTIL_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountLoginBlockedUntilDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LOGIN_BLOCKED_UNTIL_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountMarkedForDeletionAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_MARKED_FOR_DELETION_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountMarkedForDeletionDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_MARKED_FOR_DELETION_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountMarkedForDeletionReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_MARKED_FOR_DELETION_REASON_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountMarkedForDeletionReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_MARKED_FOR_DELETION_REASON_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountMarkedForDeletionUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_MARKED_FOR_DELETION_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountMarkedForDeletionUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_MARKED_FOR_DELETION_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountMfaDisabledStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_MFA_DISABLED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountMfaDisabledStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_MFA_DISABLED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountMfaDisabledStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_MFA_DISABLED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountMfaDisabledStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_MFA_DISABLED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountNylasStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_NYLAS_STATUS_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountNylasStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_NYLAS_STATUS_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountNylasStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_NYLAS_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountNylasStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_NYLAS_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountOauthClientStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_OAUTH_CLIENT_STATUS_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountOauthClientStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_OAUTH_CLIENT_STATUS_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountOauthClientStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_OAUTH_CLIENT_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountOauthClientStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_OAUTH_CLIENT_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountOauthWhitelistStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_OAUTH_WHITELIST_STATUS_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountOauthWhitelistStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_OAUTH_WHITELIST_STATUS_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountOauthWhitelistStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_OAUTH_WHITELIST_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountOauthWhitelistStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_OAUTH_WHITELIST_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountOriginsIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ORIGINS_ID_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountOriginsIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ORIGINS_ID_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountPasswordAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PASSWORD_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountPasswordDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PASSWORD_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountPasswordUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PASSWORD_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountPasswordUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PASSWORD_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountPreferredWorkspaceIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PREFERRED_WORKSPACE_ID_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountPreferredWorkspaceIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PREFERRED_WORKSPACE_ID_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountSenderProfileIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountSenderProfileIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountShouldHavePersonalOauthClientAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountShouldHavePersonalOauthClientDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountStopOutreachAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_STOP_OUTREACH_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountStopOutreachDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_STOP_OUTREACH_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountStopOutreachReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_STOP_OUTREACH_REASON_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountStopOutreachReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_STOP_OUTREACH_REASON_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountStopOutreachUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_STOP_OUTREACH_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountStopOutreachUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_STOP_OUTREACH_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountThirdPartyProviderIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_THIRD_PARTY_PROVIDER_ID_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountThirdPartyProviderIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_THIRD_PARTY_PROVIDER_ID_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountWarmupProviderAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_WARMUP_PROVIDER_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountWarmupProviderDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_WARMUP_PROVIDER_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountWarmupStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_WARMUP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountWarmupStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_WARMUP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountWarmupStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_WARMUP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountWarmupStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_WARMUP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdDistinctCountWarmupStrategyAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_WARMUP_STRATEGY_ASC',
  EmailMailboxesBySenderProfileIdDistinctCountWarmupStrategyDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_WARMUP_STRATEGY_DESC',
  EmailMailboxesBySenderProfileIdMaxConnectorStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_CONNECTOR_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMaxConnectorStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_CONNECTOR_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMaxConnectorStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_CONNECTOR_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxConnectorStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_CONNECTOR_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxCookiesIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_COOKIES_ID_ASC',
  EmailMailboxesBySenderProfileIdMaxCookiesIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_COOKIES_ID_DESC',
  EmailMailboxesBySenderProfileIdMaxCreatedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_CREATED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMaxCreatedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_CREATED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMaxCreatedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_CREATED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxCreatedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_CREATED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxCreatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_CREATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxCreatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_CREATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxDeletedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DELETED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMaxDeletedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DELETED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMaxDeletedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DELETED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxDeletedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DELETED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxDkimStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DKIM_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMaxDkimStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DKIM_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMaxDkimStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DKIM_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxDkimStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DKIM_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxDnsVerifyAttemptCountAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DNS_VERIFY_ATTEMPT_COUNT_ASC',
  EmailMailboxesBySenderProfileIdMaxDnsVerifyAttemptCountDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DNS_VERIFY_ATTEMPT_COUNT_DESC',
  EmailMailboxesBySenderProfileIdMaxDnsVerifyStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DNS_VERIFY_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMaxDnsVerifyStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DNS_VERIFY_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMaxDnsVerifyStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DNS_VERIFY_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxDnsVerifyStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DNS_VERIFY_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxDomainIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DOMAIN_ID_ASC',
  EmailMailboxesBySenderProfileIdMaxDomainIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_DOMAIN_ID_DESC',
  EmailMailboxesBySenderProfileIdMaxEspAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_ESP_ASC',
  EmailMailboxesBySenderProfileIdMaxEspDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_ESP_DESC',
  EmailMailboxesBySenderProfileIdMaxHedwigStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_HEDWIG_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMaxHedwigStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_HEDWIG_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMaxHedwigStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_HEDWIG_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxHedwigStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_HEDWIG_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_ID_ASC',
  EmailMailboxesBySenderProfileIdMaxIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_ID_DESC',
  EmailMailboxesBySenderProfileIdMaxImapStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_IMAP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMaxImapStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_IMAP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMaxImapStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_IMAP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxImapStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_IMAP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxLastScheduledLoginAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_LAST_SCHEDULED_LOGIN_ASC',
  EmailMailboxesBySenderProfileIdMaxLastScheduledLoginDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_LAST_SCHEDULED_LOGIN_DESC',
  EmailMailboxesBySenderProfileIdMaxLoginBlockedReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_LOGIN_BLOCKED_REASON_ASC',
  EmailMailboxesBySenderProfileIdMaxLoginBlockedReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_LOGIN_BLOCKED_REASON_DESC',
  EmailMailboxesBySenderProfileIdMaxLoginBlockedSeverityAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_LOGIN_BLOCKED_SEVERITY_ASC',
  EmailMailboxesBySenderProfileIdMaxLoginBlockedSeverityDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_LOGIN_BLOCKED_SEVERITY_DESC',
  EmailMailboxesBySenderProfileIdMaxLoginBlockedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_LOGIN_BLOCKED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMaxLoginBlockedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_LOGIN_BLOCKED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMaxLoginBlockedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_LOGIN_BLOCKED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxLoginBlockedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_LOGIN_BLOCKED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxLoginBlockedUntilAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_LOGIN_BLOCKED_UNTIL_ASC',
  EmailMailboxesBySenderProfileIdMaxLoginBlockedUntilDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_LOGIN_BLOCKED_UNTIL_DESC',
  EmailMailboxesBySenderProfileIdMaxMarkedForDeletionAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_MARKED_FOR_DELETION_ASC',
  EmailMailboxesBySenderProfileIdMaxMarkedForDeletionDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_MARKED_FOR_DELETION_DESC',
  EmailMailboxesBySenderProfileIdMaxMarkedForDeletionReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_MARKED_FOR_DELETION_REASON_ASC',
  EmailMailboxesBySenderProfileIdMaxMarkedForDeletionReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_MARKED_FOR_DELETION_REASON_DESC',
  EmailMailboxesBySenderProfileIdMaxMarkedForDeletionUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_MARKED_FOR_DELETION_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxMarkedForDeletionUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_MARKED_FOR_DELETION_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxMfaDisabledStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_MFA_DISABLED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMaxMfaDisabledStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_MFA_DISABLED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMaxMfaDisabledStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_MFA_DISABLED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxMfaDisabledStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_MFA_DISABLED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxNylasStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_NYLAS_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMaxNylasStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_NYLAS_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMaxNylasStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_NYLAS_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxNylasStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_NYLAS_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxOauthClientStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_OAUTH_CLIENT_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMaxOauthClientStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_OAUTH_CLIENT_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMaxOauthClientStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_OAUTH_CLIENT_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxOauthClientStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_OAUTH_CLIENT_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxOauthWhitelistStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_OAUTH_WHITELIST_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMaxOauthWhitelistStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_OAUTH_WHITELIST_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMaxOauthWhitelistStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_OAUTH_WHITELIST_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxOauthWhitelistStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_OAUTH_WHITELIST_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxOriginsIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_ORIGINS_ID_ASC',
  EmailMailboxesBySenderProfileIdMaxOriginsIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_ORIGINS_ID_DESC',
  EmailMailboxesBySenderProfileIdMaxPasswordAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_PASSWORD_ASC',
  EmailMailboxesBySenderProfileIdMaxPasswordDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_PASSWORD_DESC',
  EmailMailboxesBySenderProfileIdMaxPasswordUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_PASSWORD_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxPasswordUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_PASSWORD_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxPreferredWorkspaceIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_PREFERRED_WORKSPACE_ID_ASC',
  EmailMailboxesBySenderProfileIdMaxPreferredWorkspaceIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_PREFERRED_WORKSPACE_ID_DESC',
  EmailMailboxesBySenderProfileIdMaxSenderProfileIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_ASC',
  EmailMailboxesBySenderProfileIdMaxSenderProfileIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_DESC',
  EmailMailboxesBySenderProfileIdMaxShouldHavePersonalOauthClientAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_ASC',
  EmailMailboxesBySenderProfileIdMaxShouldHavePersonalOauthClientDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_DESC',
  EmailMailboxesBySenderProfileIdMaxStopOutreachAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_STOP_OUTREACH_ASC',
  EmailMailboxesBySenderProfileIdMaxStopOutreachDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_STOP_OUTREACH_DESC',
  EmailMailboxesBySenderProfileIdMaxStopOutreachReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_STOP_OUTREACH_REASON_ASC',
  EmailMailboxesBySenderProfileIdMaxStopOutreachReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_STOP_OUTREACH_REASON_DESC',
  EmailMailboxesBySenderProfileIdMaxStopOutreachUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_STOP_OUTREACH_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxStopOutreachUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_STOP_OUTREACH_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxThirdPartyProviderIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_THIRD_PARTY_PROVIDER_ID_ASC',
  EmailMailboxesBySenderProfileIdMaxThirdPartyProviderIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_THIRD_PARTY_PROVIDER_ID_DESC',
  EmailMailboxesBySenderProfileIdMaxUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxWarmupProviderAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_WARMUP_PROVIDER_ASC',
  EmailMailboxesBySenderProfileIdMaxWarmupProviderDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_WARMUP_PROVIDER_DESC',
  EmailMailboxesBySenderProfileIdMaxWarmupStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_WARMUP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMaxWarmupStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_WARMUP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMaxWarmupStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_WARMUP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMaxWarmupStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_WARMUP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMaxWarmupStrategyAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_WARMUP_STRATEGY_ASC',
  EmailMailboxesBySenderProfileIdMaxWarmupStrategyDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MAX_WARMUP_STRATEGY_DESC',
  EmailMailboxesBySenderProfileIdMinConnectorStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_CONNECTOR_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMinConnectorStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_CONNECTOR_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMinConnectorStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_CONNECTOR_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinConnectorStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_CONNECTOR_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinCookiesIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_COOKIES_ID_ASC',
  EmailMailboxesBySenderProfileIdMinCookiesIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_COOKIES_ID_DESC',
  EmailMailboxesBySenderProfileIdMinCreatedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_CREATED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMinCreatedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_CREATED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMinCreatedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_CREATED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinCreatedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_CREATED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinCreatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_CREATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinCreatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_CREATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinDeletedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DELETED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMinDeletedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DELETED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMinDeletedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DELETED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinDeletedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DELETED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinDkimStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DKIM_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMinDkimStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DKIM_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMinDkimStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DKIM_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinDkimStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DKIM_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinDnsVerifyAttemptCountAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DNS_VERIFY_ATTEMPT_COUNT_ASC',
  EmailMailboxesBySenderProfileIdMinDnsVerifyAttemptCountDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DNS_VERIFY_ATTEMPT_COUNT_DESC',
  EmailMailboxesBySenderProfileIdMinDnsVerifyStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DNS_VERIFY_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMinDnsVerifyStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DNS_VERIFY_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMinDnsVerifyStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DNS_VERIFY_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinDnsVerifyStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DNS_VERIFY_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinDomainIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DOMAIN_ID_ASC',
  EmailMailboxesBySenderProfileIdMinDomainIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_DOMAIN_ID_DESC',
  EmailMailboxesBySenderProfileIdMinEspAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_ESP_ASC',
  EmailMailboxesBySenderProfileIdMinEspDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_ESP_DESC',
  EmailMailboxesBySenderProfileIdMinHedwigStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_HEDWIG_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMinHedwigStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_HEDWIG_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMinHedwigStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_HEDWIG_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinHedwigStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_HEDWIG_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_ID_ASC',
  EmailMailboxesBySenderProfileIdMinIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_ID_DESC',
  EmailMailboxesBySenderProfileIdMinImapStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_IMAP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMinImapStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_IMAP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMinImapStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_IMAP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinImapStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_IMAP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinLastScheduledLoginAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_LAST_SCHEDULED_LOGIN_ASC',
  EmailMailboxesBySenderProfileIdMinLastScheduledLoginDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_LAST_SCHEDULED_LOGIN_DESC',
  EmailMailboxesBySenderProfileIdMinLoginBlockedReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_LOGIN_BLOCKED_REASON_ASC',
  EmailMailboxesBySenderProfileIdMinLoginBlockedReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_LOGIN_BLOCKED_REASON_DESC',
  EmailMailboxesBySenderProfileIdMinLoginBlockedSeverityAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_LOGIN_BLOCKED_SEVERITY_ASC',
  EmailMailboxesBySenderProfileIdMinLoginBlockedSeverityDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_LOGIN_BLOCKED_SEVERITY_DESC',
  EmailMailboxesBySenderProfileIdMinLoginBlockedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_LOGIN_BLOCKED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMinLoginBlockedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_LOGIN_BLOCKED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMinLoginBlockedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_LOGIN_BLOCKED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinLoginBlockedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_LOGIN_BLOCKED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinLoginBlockedUntilAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_LOGIN_BLOCKED_UNTIL_ASC',
  EmailMailboxesBySenderProfileIdMinLoginBlockedUntilDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_LOGIN_BLOCKED_UNTIL_DESC',
  EmailMailboxesBySenderProfileIdMinMarkedForDeletionAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_MARKED_FOR_DELETION_ASC',
  EmailMailboxesBySenderProfileIdMinMarkedForDeletionDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_MARKED_FOR_DELETION_DESC',
  EmailMailboxesBySenderProfileIdMinMarkedForDeletionReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_MARKED_FOR_DELETION_REASON_ASC',
  EmailMailboxesBySenderProfileIdMinMarkedForDeletionReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_MARKED_FOR_DELETION_REASON_DESC',
  EmailMailboxesBySenderProfileIdMinMarkedForDeletionUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_MARKED_FOR_DELETION_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinMarkedForDeletionUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_MARKED_FOR_DELETION_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinMfaDisabledStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_MFA_DISABLED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMinMfaDisabledStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_MFA_DISABLED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMinMfaDisabledStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_MFA_DISABLED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinMfaDisabledStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_MFA_DISABLED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinNylasStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_NYLAS_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMinNylasStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_NYLAS_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMinNylasStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_NYLAS_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinNylasStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_NYLAS_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinOauthClientStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_OAUTH_CLIENT_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMinOauthClientStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_OAUTH_CLIENT_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMinOauthClientStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_OAUTH_CLIENT_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinOauthClientStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_OAUTH_CLIENT_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinOauthWhitelistStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_OAUTH_WHITELIST_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMinOauthWhitelistStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_OAUTH_WHITELIST_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMinOauthWhitelistStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_OAUTH_WHITELIST_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinOauthWhitelistStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_OAUTH_WHITELIST_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinOriginsIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_ORIGINS_ID_ASC',
  EmailMailboxesBySenderProfileIdMinOriginsIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_ORIGINS_ID_DESC',
  EmailMailboxesBySenderProfileIdMinPasswordAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_PASSWORD_ASC',
  EmailMailboxesBySenderProfileIdMinPasswordDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_PASSWORD_DESC',
  EmailMailboxesBySenderProfileIdMinPasswordUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_PASSWORD_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinPasswordUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_PASSWORD_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinPreferredWorkspaceIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_PREFERRED_WORKSPACE_ID_ASC',
  EmailMailboxesBySenderProfileIdMinPreferredWorkspaceIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_PREFERRED_WORKSPACE_ID_DESC',
  EmailMailboxesBySenderProfileIdMinSenderProfileIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_ASC',
  EmailMailboxesBySenderProfileIdMinSenderProfileIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_DESC',
  EmailMailboxesBySenderProfileIdMinShouldHavePersonalOauthClientAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_ASC',
  EmailMailboxesBySenderProfileIdMinShouldHavePersonalOauthClientDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_DESC',
  EmailMailboxesBySenderProfileIdMinStopOutreachAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_STOP_OUTREACH_ASC',
  EmailMailboxesBySenderProfileIdMinStopOutreachDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_STOP_OUTREACH_DESC',
  EmailMailboxesBySenderProfileIdMinStopOutreachReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_STOP_OUTREACH_REASON_ASC',
  EmailMailboxesBySenderProfileIdMinStopOutreachReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_STOP_OUTREACH_REASON_DESC',
  EmailMailboxesBySenderProfileIdMinStopOutreachUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_STOP_OUTREACH_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinStopOutreachUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_STOP_OUTREACH_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinThirdPartyProviderIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_THIRD_PARTY_PROVIDER_ID_ASC',
  EmailMailboxesBySenderProfileIdMinThirdPartyProviderIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_THIRD_PARTY_PROVIDER_ID_DESC',
  EmailMailboxesBySenderProfileIdMinUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinWarmupProviderAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_WARMUP_PROVIDER_ASC',
  EmailMailboxesBySenderProfileIdMinWarmupProviderDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_WARMUP_PROVIDER_DESC',
  EmailMailboxesBySenderProfileIdMinWarmupStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_WARMUP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdMinWarmupStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_WARMUP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdMinWarmupStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_WARMUP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdMinWarmupStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_WARMUP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdMinWarmupStrategyAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_WARMUP_STRATEGY_ASC',
  EmailMailboxesBySenderProfileIdMinWarmupStrategyDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_MIN_WARMUP_STRATEGY_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationConnectorStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CONNECTOR_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationConnectorStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CONNECTOR_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationConnectorStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CONNECTOR_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationConnectorStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CONNECTOR_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationCookiesIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_COOKIES_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationCookiesIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_COOKIES_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationCreatedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationCreatedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationCreatedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationCreatedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationCreatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationCreatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationDeletedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DELETED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationDeletedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DELETED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationDeletedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DELETED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationDeletedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DELETED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationDkimStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DKIM_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationDkimStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DKIM_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationDkimStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DKIM_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationDkimStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DKIM_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationDnsVerifyAttemptCountAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DNS_VERIFY_ATTEMPT_COUNT_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationDnsVerifyAttemptCountDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DNS_VERIFY_ATTEMPT_COUNT_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationDnsVerifyStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DNS_VERIFY_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationDnsVerifyStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DNS_VERIFY_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationDnsVerifyStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DNS_VERIFY_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationDnsVerifyStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DNS_VERIFY_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationDomainIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DOMAIN_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationDomainIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_DOMAIN_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationEspAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ESP_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationEspDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ESP_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationHedwigStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_HEDWIG_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationHedwigStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_HEDWIG_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationHedwigStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_HEDWIG_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationHedwigStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_HEDWIG_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationImapStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_IMAP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationImapStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_IMAP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationImapStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_IMAP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationImapStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_IMAP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationLastScheduledLoginAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LAST_SCHEDULED_LOGIN_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationLastScheduledLoginDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LAST_SCHEDULED_LOGIN_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationLoginBlockedReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LOGIN_BLOCKED_REASON_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationLoginBlockedReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LOGIN_BLOCKED_REASON_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationLoginBlockedSeverityAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LOGIN_BLOCKED_SEVERITY_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationLoginBlockedSeverityDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LOGIN_BLOCKED_SEVERITY_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationLoginBlockedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LOGIN_BLOCKED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationLoginBlockedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LOGIN_BLOCKED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationLoginBlockedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LOGIN_BLOCKED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationLoginBlockedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LOGIN_BLOCKED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationLoginBlockedUntilAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LOGIN_BLOCKED_UNTIL_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationLoginBlockedUntilDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LOGIN_BLOCKED_UNTIL_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationMarkedForDeletionAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_MARKED_FOR_DELETION_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationMarkedForDeletionDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_MARKED_FOR_DELETION_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationMarkedForDeletionReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_MARKED_FOR_DELETION_REASON_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationMarkedForDeletionReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_MARKED_FOR_DELETION_REASON_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationMarkedForDeletionUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_MARKED_FOR_DELETION_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationMarkedForDeletionUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_MARKED_FOR_DELETION_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationMfaDisabledStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_MFA_DISABLED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationMfaDisabledStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_MFA_DISABLED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationMfaDisabledStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_MFA_DISABLED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationMfaDisabledStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_MFA_DISABLED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationNylasStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_NYLAS_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationNylasStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_NYLAS_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationNylasStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_NYLAS_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationNylasStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_NYLAS_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationOauthClientStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_OAUTH_CLIENT_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationOauthClientStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_OAUTH_CLIENT_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationOauthClientStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_OAUTH_CLIENT_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationOauthClientStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_OAUTH_CLIENT_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationOauthWhitelistStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_OAUTH_WHITELIST_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationOauthWhitelistStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_OAUTH_WHITELIST_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationOauthWhitelistStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_OAUTH_WHITELIST_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationOauthWhitelistStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_OAUTH_WHITELIST_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationOriginsIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ORIGINS_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationOriginsIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ORIGINS_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationPasswordAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PASSWORD_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationPasswordDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PASSWORD_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationPasswordUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PASSWORD_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationPasswordUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PASSWORD_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationPreferredWorkspaceIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PREFERRED_WORKSPACE_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationPreferredWorkspaceIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PREFERRED_WORKSPACE_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationSenderProfileIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationSenderProfileIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationShouldHavePersonalOauthClientAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationShouldHavePersonalOauthClientDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationStopOutreachAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_STOP_OUTREACH_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationStopOutreachDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_STOP_OUTREACH_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationStopOutreachReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_STOP_OUTREACH_REASON_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationStopOutreachReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_STOP_OUTREACH_REASON_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationStopOutreachUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_STOP_OUTREACH_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationStopOutreachUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_STOP_OUTREACH_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationThirdPartyProviderIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_THIRD_PARTY_PROVIDER_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationThirdPartyProviderIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_THIRD_PARTY_PROVIDER_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationWarmupProviderAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_WARMUP_PROVIDER_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationWarmupProviderDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_WARMUP_PROVIDER_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationWarmupStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_WARMUP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationWarmupStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_WARMUP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationWarmupStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_WARMUP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationWarmupStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_WARMUP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevPopulationWarmupStrategyAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_WARMUP_STRATEGY_ASC',
  EmailMailboxesBySenderProfileIdStddevPopulationWarmupStrategyDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_WARMUP_STRATEGY_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleConnectorStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CONNECTOR_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleConnectorStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CONNECTOR_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleConnectorStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CONNECTOR_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleConnectorStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CONNECTOR_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleCookiesIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_COOKIES_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleCookiesIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_COOKIES_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleCreatedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleCreatedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleCreatedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleCreatedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleCreatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleCreatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleDeletedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DELETED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleDeletedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DELETED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleDeletedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DELETED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleDeletedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DELETED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleDkimStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DKIM_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleDkimStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DKIM_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleDkimStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DKIM_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleDkimStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DKIM_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleDnsVerifyAttemptCountAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DNS_VERIFY_ATTEMPT_COUNT_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleDnsVerifyAttemptCountDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DNS_VERIFY_ATTEMPT_COUNT_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleDnsVerifyStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DNS_VERIFY_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleDnsVerifyStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DNS_VERIFY_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleDnsVerifyStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DNS_VERIFY_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleDnsVerifyStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DNS_VERIFY_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleDomainIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DOMAIN_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleDomainIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_DOMAIN_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleEspAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ESP_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleEspDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ESP_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleHedwigStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_HEDWIG_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleHedwigStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_HEDWIG_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleHedwigStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_HEDWIG_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleHedwigStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_HEDWIG_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleImapStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_IMAP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleImapStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_IMAP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleImapStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_IMAP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleImapStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_IMAP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleLastScheduledLoginAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LAST_SCHEDULED_LOGIN_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleLastScheduledLoginDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LAST_SCHEDULED_LOGIN_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleLoginBlockedReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LOGIN_BLOCKED_REASON_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleLoginBlockedReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LOGIN_BLOCKED_REASON_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleLoginBlockedSeverityAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LOGIN_BLOCKED_SEVERITY_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleLoginBlockedSeverityDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LOGIN_BLOCKED_SEVERITY_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleLoginBlockedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LOGIN_BLOCKED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleLoginBlockedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LOGIN_BLOCKED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleLoginBlockedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LOGIN_BLOCKED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleLoginBlockedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LOGIN_BLOCKED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleLoginBlockedUntilAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LOGIN_BLOCKED_UNTIL_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleLoginBlockedUntilDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LOGIN_BLOCKED_UNTIL_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleMarkedForDeletionAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_MARKED_FOR_DELETION_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleMarkedForDeletionDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_MARKED_FOR_DELETION_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleMarkedForDeletionReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_MARKED_FOR_DELETION_REASON_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleMarkedForDeletionReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_MARKED_FOR_DELETION_REASON_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleMarkedForDeletionUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_MARKED_FOR_DELETION_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleMarkedForDeletionUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_MARKED_FOR_DELETION_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleMfaDisabledStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_MFA_DISABLED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleMfaDisabledStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_MFA_DISABLED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleMfaDisabledStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_MFA_DISABLED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleMfaDisabledStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_MFA_DISABLED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleNylasStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_NYLAS_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleNylasStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_NYLAS_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleNylasStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_NYLAS_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleNylasStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_NYLAS_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleOauthClientStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_OAUTH_CLIENT_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleOauthClientStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_OAUTH_CLIENT_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleOauthClientStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_OAUTH_CLIENT_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleOauthClientStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_OAUTH_CLIENT_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleOauthWhitelistStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_OAUTH_WHITELIST_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleOauthWhitelistStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_OAUTH_WHITELIST_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleOauthWhitelistStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_OAUTH_WHITELIST_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleOauthWhitelistStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_OAUTH_WHITELIST_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleOriginsIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ORIGINS_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleOriginsIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ORIGINS_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevSamplePasswordAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PASSWORD_ASC',
  EmailMailboxesBySenderProfileIdStddevSamplePasswordDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PASSWORD_DESC',
  EmailMailboxesBySenderProfileIdStddevSamplePasswordUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PASSWORD_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSamplePasswordUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PASSWORD_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSamplePreferredWorkspaceIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PREFERRED_WORKSPACE_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevSamplePreferredWorkspaceIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PREFERRED_WORKSPACE_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleSenderProfileIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleSenderProfileIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleShouldHavePersonalOauthClientAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleShouldHavePersonalOauthClientDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleStopOutreachAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_STOP_OUTREACH_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleStopOutreachDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_STOP_OUTREACH_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleStopOutreachReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_STOP_OUTREACH_REASON_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleStopOutreachReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_STOP_OUTREACH_REASON_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleStopOutreachUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_STOP_OUTREACH_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleStopOutreachUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_STOP_OUTREACH_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleThirdPartyProviderIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_THIRD_PARTY_PROVIDER_ID_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleThirdPartyProviderIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_THIRD_PARTY_PROVIDER_ID_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleWarmupProviderAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_WARMUP_PROVIDER_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleWarmupProviderDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_WARMUP_PROVIDER_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleWarmupStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_WARMUP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleWarmupStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_WARMUP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleWarmupStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_WARMUP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleWarmupStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_WARMUP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdStddevSampleWarmupStrategyAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_WARMUP_STRATEGY_ASC',
  EmailMailboxesBySenderProfileIdStddevSampleWarmupStrategyDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_WARMUP_STRATEGY_DESC',
  EmailMailboxesBySenderProfileIdSumConnectorStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_CONNECTOR_STATUS_ASC',
  EmailMailboxesBySenderProfileIdSumConnectorStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_CONNECTOR_STATUS_DESC',
  EmailMailboxesBySenderProfileIdSumConnectorStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_CONNECTOR_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumConnectorStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_CONNECTOR_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumCookiesIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_COOKIES_ID_ASC',
  EmailMailboxesBySenderProfileIdSumCookiesIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_COOKIES_ID_DESC',
  EmailMailboxesBySenderProfileIdSumCreatedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_CREATED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdSumCreatedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_CREATED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdSumCreatedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_CREATED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumCreatedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_CREATED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumCreatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_CREATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumCreatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_CREATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumDeletedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DELETED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdSumDeletedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DELETED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdSumDeletedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DELETED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumDeletedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DELETED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumDkimStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DKIM_STATUS_ASC',
  EmailMailboxesBySenderProfileIdSumDkimStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DKIM_STATUS_DESC',
  EmailMailboxesBySenderProfileIdSumDkimStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DKIM_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumDkimStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DKIM_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumDnsVerifyAttemptCountAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DNS_VERIFY_ATTEMPT_COUNT_ASC',
  EmailMailboxesBySenderProfileIdSumDnsVerifyAttemptCountDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DNS_VERIFY_ATTEMPT_COUNT_DESC',
  EmailMailboxesBySenderProfileIdSumDnsVerifyStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DNS_VERIFY_STATUS_ASC',
  EmailMailboxesBySenderProfileIdSumDnsVerifyStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DNS_VERIFY_STATUS_DESC',
  EmailMailboxesBySenderProfileIdSumDnsVerifyStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DNS_VERIFY_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumDnsVerifyStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DNS_VERIFY_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumDomainIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DOMAIN_ID_ASC',
  EmailMailboxesBySenderProfileIdSumDomainIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_DOMAIN_ID_DESC',
  EmailMailboxesBySenderProfileIdSumEspAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_ESP_ASC',
  EmailMailboxesBySenderProfileIdSumEspDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_ESP_DESC',
  EmailMailboxesBySenderProfileIdSumHedwigStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_HEDWIG_STATUS_ASC',
  EmailMailboxesBySenderProfileIdSumHedwigStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_HEDWIG_STATUS_DESC',
  EmailMailboxesBySenderProfileIdSumHedwigStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_HEDWIG_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumHedwigStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_HEDWIG_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_ID_ASC',
  EmailMailboxesBySenderProfileIdSumIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_ID_DESC',
  EmailMailboxesBySenderProfileIdSumImapStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_IMAP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdSumImapStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_IMAP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdSumImapStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_IMAP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumImapStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_IMAP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumLastScheduledLoginAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_LAST_SCHEDULED_LOGIN_ASC',
  EmailMailboxesBySenderProfileIdSumLastScheduledLoginDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_LAST_SCHEDULED_LOGIN_DESC',
  EmailMailboxesBySenderProfileIdSumLoginBlockedReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_LOGIN_BLOCKED_REASON_ASC',
  EmailMailboxesBySenderProfileIdSumLoginBlockedReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_LOGIN_BLOCKED_REASON_DESC',
  EmailMailboxesBySenderProfileIdSumLoginBlockedSeverityAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_LOGIN_BLOCKED_SEVERITY_ASC',
  EmailMailboxesBySenderProfileIdSumLoginBlockedSeverityDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_LOGIN_BLOCKED_SEVERITY_DESC',
  EmailMailboxesBySenderProfileIdSumLoginBlockedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_LOGIN_BLOCKED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdSumLoginBlockedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_LOGIN_BLOCKED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdSumLoginBlockedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_LOGIN_BLOCKED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumLoginBlockedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_LOGIN_BLOCKED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumLoginBlockedUntilAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_LOGIN_BLOCKED_UNTIL_ASC',
  EmailMailboxesBySenderProfileIdSumLoginBlockedUntilDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_LOGIN_BLOCKED_UNTIL_DESC',
  EmailMailboxesBySenderProfileIdSumMarkedForDeletionAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_MARKED_FOR_DELETION_ASC',
  EmailMailboxesBySenderProfileIdSumMarkedForDeletionDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_MARKED_FOR_DELETION_DESC',
  EmailMailboxesBySenderProfileIdSumMarkedForDeletionReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_MARKED_FOR_DELETION_REASON_ASC',
  EmailMailboxesBySenderProfileIdSumMarkedForDeletionReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_MARKED_FOR_DELETION_REASON_DESC',
  EmailMailboxesBySenderProfileIdSumMarkedForDeletionUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_MARKED_FOR_DELETION_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumMarkedForDeletionUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_MARKED_FOR_DELETION_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumMfaDisabledStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_MFA_DISABLED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdSumMfaDisabledStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_MFA_DISABLED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdSumMfaDisabledStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_MFA_DISABLED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumMfaDisabledStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_MFA_DISABLED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumNylasStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_NYLAS_STATUS_ASC',
  EmailMailboxesBySenderProfileIdSumNylasStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_NYLAS_STATUS_DESC',
  EmailMailboxesBySenderProfileIdSumNylasStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_NYLAS_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumNylasStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_NYLAS_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumOauthClientStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_OAUTH_CLIENT_STATUS_ASC',
  EmailMailboxesBySenderProfileIdSumOauthClientStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_OAUTH_CLIENT_STATUS_DESC',
  EmailMailboxesBySenderProfileIdSumOauthClientStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_OAUTH_CLIENT_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumOauthClientStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_OAUTH_CLIENT_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumOauthWhitelistStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_OAUTH_WHITELIST_STATUS_ASC',
  EmailMailboxesBySenderProfileIdSumOauthWhitelistStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_OAUTH_WHITELIST_STATUS_DESC',
  EmailMailboxesBySenderProfileIdSumOauthWhitelistStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_OAUTH_WHITELIST_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumOauthWhitelistStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_OAUTH_WHITELIST_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumOriginsIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_ORIGINS_ID_ASC',
  EmailMailboxesBySenderProfileIdSumOriginsIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_ORIGINS_ID_DESC',
  EmailMailboxesBySenderProfileIdSumPasswordAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_PASSWORD_ASC',
  EmailMailboxesBySenderProfileIdSumPasswordDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_PASSWORD_DESC',
  EmailMailboxesBySenderProfileIdSumPasswordUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_PASSWORD_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumPasswordUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_PASSWORD_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumPreferredWorkspaceIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_PREFERRED_WORKSPACE_ID_ASC',
  EmailMailboxesBySenderProfileIdSumPreferredWorkspaceIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_PREFERRED_WORKSPACE_ID_DESC',
  EmailMailboxesBySenderProfileIdSumSenderProfileIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_ASC',
  EmailMailboxesBySenderProfileIdSumSenderProfileIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_DESC',
  EmailMailboxesBySenderProfileIdSumShouldHavePersonalOauthClientAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_ASC',
  EmailMailboxesBySenderProfileIdSumShouldHavePersonalOauthClientDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_DESC',
  EmailMailboxesBySenderProfileIdSumStopOutreachAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_STOP_OUTREACH_ASC',
  EmailMailboxesBySenderProfileIdSumStopOutreachDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_STOP_OUTREACH_DESC',
  EmailMailboxesBySenderProfileIdSumStopOutreachReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_STOP_OUTREACH_REASON_ASC',
  EmailMailboxesBySenderProfileIdSumStopOutreachReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_STOP_OUTREACH_REASON_DESC',
  EmailMailboxesBySenderProfileIdSumStopOutreachUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_STOP_OUTREACH_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumStopOutreachUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_STOP_OUTREACH_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumThirdPartyProviderIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_THIRD_PARTY_PROVIDER_ID_ASC',
  EmailMailboxesBySenderProfileIdSumThirdPartyProviderIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_THIRD_PARTY_PROVIDER_ID_DESC',
  EmailMailboxesBySenderProfileIdSumUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumWarmupProviderAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_WARMUP_PROVIDER_ASC',
  EmailMailboxesBySenderProfileIdSumWarmupProviderDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_WARMUP_PROVIDER_DESC',
  EmailMailboxesBySenderProfileIdSumWarmupStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_WARMUP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdSumWarmupStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_WARMUP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdSumWarmupStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_WARMUP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdSumWarmupStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_WARMUP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdSumWarmupStrategyAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_WARMUP_STRATEGY_ASC',
  EmailMailboxesBySenderProfileIdSumWarmupStrategyDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_SUM_WARMUP_STRATEGY_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationConnectorStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CONNECTOR_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationConnectorStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CONNECTOR_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationConnectorStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CONNECTOR_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationConnectorStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CONNECTOR_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationCookiesIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_COOKIES_ID_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationCookiesIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_COOKIES_ID_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationCreatedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationCreatedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationCreatedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationCreatedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationCreatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationCreatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationDeletedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DELETED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationDeletedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DELETED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationDeletedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DELETED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationDeletedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DELETED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationDkimStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DKIM_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationDkimStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DKIM_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationDkimStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DKIM_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationDkimStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DKIM_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationDnsVerifyAttemptCountAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DNS_VERIFY_ATTEMPT_COUNT_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationDnsVerifyAttemptCountDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DNS_VERIFY_ATTEMPT_COUNT_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationDnsVerifyStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DNS_VERIFY_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationDnsVerifyStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DNS_VERIFY_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationDnsVerifyStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DNS_VERIFY_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationDnsVerifyStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DNS_VERIFY_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationDomainIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DOMAIN_ID_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationDomainIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_DOMAIN_ID_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationEspAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ESP_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationEspDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ESP_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationHedwigStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_HEDWIG_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationHedwigStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_HEDWIG_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationHedwigStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_HEDWIG_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationHedwigStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_HEDWIG_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationImapStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_IMAP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationImapStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_IMAP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationImapStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_IMAP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationImapStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_IMAP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationLastScheduledLoginAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LAST_SCHEDULED_LOGIN_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationLastScheduledLoginDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LAST_SCHEDULED_LOGIN_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationLoginBlockedReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LOGIN_BLOCKED_REASON_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationLoginBlockedReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LOGIN_BLOCKED_REASON_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationLoginBlockedSeverityAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LOGIN_BLOCKED_SEVERITY_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationLoginBlockedSeverityDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LOGIN_BLOCKED_SEVERITY_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationLoginBlockedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LOGIN_BLOCKED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationLoginBlockedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LOGIN_BLOCKED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationLoginBlockedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LOGIN_BLOCKED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationLoginBlockedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LOGIN_BLOCKED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationLoginBlockedUntilAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LOGIN_BLOCKED_UNTIL_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationLoginBlockedUntilDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LOGIN_BLOCKED_UNTIL_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationMarkedForDeletionAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_MARKED_FOR_DELETION_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationMarkedForDeletionDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_MARKED_FOR_DELETION_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationMarkedForDeletionReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_MARKED_FOR_DELETION_REASON_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationMarkedForDeletionReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_MARKED_FOR_DELETION_REASON_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationMarkedForDeletionUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_MARKED_FOR_DELETION_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationMarkedForDeletionUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_MARKED_FOR_DELETION_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationMfaDisabledStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_MFA_DISABLED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationMfaDisabledStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_MFA_DISABLED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationMfaDisabledStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_MFA_DISABLED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationMfaDisabledStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_MFA_DISABLED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationNylasStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_NYLAS_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationNylasStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_NYLAS_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationNylasStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_NYLAS_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationNylasStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_NYLAS_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationOauthClientStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_OAUTH_CLIENT_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationOauthClientStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_OAUTH_CLIENT_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationOauthClientStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_OAUTH_CLIENT_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationOauthClientStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_OAUTH_CLIENT_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationOauthWhitelistStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_OAUTH_WHITELIST_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationOauthWhitelistStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_OAUTH_WHITELIST_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationOauthWhitelistStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_OAUTH_WHITELIST_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationOauthWhitelistStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_OAUTH_WHITELIST_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationOriginsIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ORIGINS_ID_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationOriginsIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ORIGINS_ID_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationPasswordAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PASSWORD_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationPasswordDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PASSWORD_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationPasswordUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PASSWORD_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationPasswordUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PASSWORD_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationPreferredWorkspaceIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PREFERRED_WORKSPACE_ID_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationPreferredWorkspaceIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PREFERRED_WORKSPACE_ID_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationSenderProfileIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationSenderProfileIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationShouldHavePersonalOauthClientAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationShouldHavePersonalOauthClientDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationStopOutreachAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_STOP_OUTREACH_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationStopOutreachDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_STOP_OUTREACH_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationStopOutreachReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_STOP_OUTREACH_REASON_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationStopOutreachReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_STOP_OUTREACH_REASON_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationStopOutreachUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_STOP_OUTREACH_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationStopOutreachUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_STOP_OUTREACH_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationThirdPartyProviderIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_THIRD_PARTY_PROVIDER_ID_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationThirdPartyProviderIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_THIRD_PARTY_PROVIDER_ID_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationWarmupProviderAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_WARMUP_PROVIDER_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationWarmupProviderDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_WARMUP_PROVIDER_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationWarmupStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_WARMUP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationWarmupStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_WARMUP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationWarmupStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_WARMUP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationWarmupStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_WARMUP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVariancePopulationWarmupStrategyAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_WARMUP_STRATEGY_ASC',
  EmailMailboxesBySenderProfileIdVariancePopulationWarmupStrategyDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_WARMUP_STRATEGY_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleConnectorStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CONNECTOR_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleConnectorStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CONNECTOR_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleConnectorStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CONNECTOR_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleConnectorStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CONNECTOR_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleCookiesIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_COOKIES_ID_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleCookiesIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_COOKIES_ID_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleCreatedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleCreatedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleCreatedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleCreatedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleCreatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleCreatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleDeletedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DELETED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleDeletedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DELETED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleDeletedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DELETED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleDeletedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DELETED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleDkimStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DKIM_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleDkimStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DKIM_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleDkimStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DKIM_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleDkimStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DKIM_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleDnsVerifyAttemptCountAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DNS_VERIFY_ATTEMPT_COUNT_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleDnsVerifyAttemptCountDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DNS_VERIFY_ATTEMPT_COUNT_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleDnsVerifyStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DNS_VERIFY_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleDnsVerifyStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DNS_VERIFY_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleDnsVerifyStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DNS_VERIFY_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleDnsVerifyStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DNS_VERIFY_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleDomainIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DOMAIN_ID_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleDomainIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_DOMAIN_ID_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleEspAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ESP_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleEspDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ESP_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleHedwigStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_HEDWIG_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleHedwigStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_HEDWIG_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleHedwigStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_HEDWIG_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleHedwigStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_HEDWIG_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleImapStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_IMAP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleImapStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_IMAP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleImapStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_IMAP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleImapStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_IMAP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleLastScheduledLoginAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LAST_SCHEDULED_LOGIN_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleLastScheduledLoginDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LAST_SCHEDULED_LOGIN_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleLoginBlockedReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LOGIN_BLOCKED_REASON_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleLoginBlockedReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LOGIN_BLOCKED_REASON_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleLoginBlockedSeverityAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LOGIN_BLOCKED_SEVERITY_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleLoginBlockedSeverityDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LOGIN_BLOCKED_SEVERITY_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleLoginBlockedStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LOGIN_BLOCKED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleLoginBlockedStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LOGIN_BLOCKED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleLoginBlockedStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LOGIN_BLOCKED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleLoginBlockedStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LOGIN_BLOCKED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleLoginBlockedUntilAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LOGIN_BLOCKED_UNTIL_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleLoginBlockedUntilDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LOGIN_BLOCKED_UNTIL_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleMarkedForDeletionAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_MARKED_FOR_DELETION_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleMarkedForDeletionDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_MARKED_FOR_DELETION_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleMarkedForDeletionReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_MARKED_FOR_DELETION_REASON_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleMarkedForDeletionReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_MARKED_FOR_DELETION_REASON_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleMarkedForDeletionUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_MARKED_FOR_DELETION_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleMarkedForDeletionUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_MARKED_FOR_DELETION_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleMfaDisabledStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_MFA_DISABLED_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleMfaDisabledStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_MFA_DISABLED_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleMfaDisabledStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_MFA_DISABLED_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleMfaDisabledStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_MFA_DISABLED_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleNylasStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_NYLAS_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleNylasStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_NYLAS_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleNylasStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_NYLAS_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleNylasStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_NYLAS_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleOauthClientStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_OAUTH_CLIENT_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleOauthClientStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_OAUTH_CLIENT_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleOauthClientStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_OAUTH_CLIENT_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleOauthClientStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_OAUTH_CLIENT_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleOauthWhitelistStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_OAUTH_WHITELIST_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleOauthWhitelistStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_OAUTH_WHITELIST_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleOauthWhitelistStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_OAUTH_WHITELIST_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleOauthWhitelistStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_OAUTH_WHITELIST_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleOriginsIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ORIGINS_ID_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleOriginsIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ORIGINS_ID_DESC',
  EmailMailboxesBySenderProfileIdVarianceSamplePasswordAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PASSWORD_ASC',
  EmailMailboxesBySenderProfileIdVarianceSamplePasswordDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PASSWORD_DESC',
  EmailMailboxesBySenderProfileIdVarianceSamplePasswordUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PASSWORD_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSamplePasswordUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PASSWORD_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSamplePreferredWorkspaceIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PREFERRED_WORKSPACE_ID_ASC',
  EmailMailboxesBySenderProfileIdVarianceSamplePreferredWorkspaceIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PREFERRED_WORKSPACE_ID_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleSenderProfileIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleSenderProfileIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleShouldHavePersonalOauthClientAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleShouldHavePersonalOauthClientDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SHOULD_HAVE_PERSONAL_OAUTH_CLIENT_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleStopOutreachAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_STOP_OUTREACH_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleStopOutreachDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_STOP_OUTREACH_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleStopOutreachReasonAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_STOP_OUTREACH_REASON_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleStopOutreachReasonDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_STOP_OUTREACH_REASON_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleStopOutreachUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_STOP_OUTREACH_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleStopOutreachUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_STOP_OUTREACH_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleThirdPartyProviderIdAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_THIRD_PARTY_PROVIDER_ID_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleThirdPartyProviderIdDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_THIRD_PARTY_PROVIDER_ID_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleWarmupProviderAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_WARMUP_PROVIDER_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleWarmupProviderDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_WARMUP_PROVIDER_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleWarmupStatusAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_WARMUP_STATUS_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleWarmupStatusDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_WARMUP_STATUS_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleWarmupStatusUpdatedTimeAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_WARMUP_STATUS_UPDATED_TIME_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleWarmupStatusUpdatedTimeDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_WARMUP_STATUS_UPDATED_TIME_DESC',
  EmailMailboxesBySenderProfileIdVarianceSampleWarmupStrategyAsc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_WARMUP_STRATEGY_ASC',
  EmailMailboxesBySenderProfileIdVarianceSampleWarmupStrategyDesc = 'EMAIL_MAILBOXES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_WARMUP_STRATEGY_DESC',
  EmailMessagesBySenderProfileIdAverageAutoRepliedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_AUTO_REPLIED_ASC',
  EmailMessagesBySenderProfileIdAverageAutoRepliedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_AUTO_REPLIED_DESC',
  EmailMessagesBySenderProfileIdAverageCampaignIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CAMPAIGN_ID_ASC',
  EmailMessagesBySenderProfileIdAverageCampaignIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CAMPAIGN_ID_DESC',
  EmailMessagesBySenderProfileIdAverageCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesBySenderProfileIdAverageCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesBySenderProfileIdAverageCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdAverageCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdAverageCompanyIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_COMPANY_ID_ASC',
  EmailMessagesBySenderProfileIdAverageCompanyIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_COMPANY_ID_DESC',
  EmailMessagesBySenderProfileIdAverageContactIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CONTACT_ID_ASC',
  EmailMessagesBySenderProfileIdAverageContactIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CONTACT_ID_DESC',
  EmailMessagesBySenderProfileIdAverageContentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CONTENT_ASC',
  EmailMessagesBySenderProfileIdAverageContentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CONTENT_DESC',
  EmailMessagesBySenderProfileIdAverageContentHtmlAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CONTENT_HTML_ASC',
  EmailMessagesBySenderProfileIdAverageContentHtmlDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CONTENT_HTML_DESC',
  EmailMessagesBySenderProfileIdAverageCreatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_ASC',
  EmailMessagesBySenderProfileIdAverageCreatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_DESC',
  EmailMessagesBySenderProfileIdAverageEmailIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_EMAIL_ID_ASC',
  EmailMessagesBySenderProfileIdAverageEmailIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_EMAIL_ID_DESC',
  EmailMessagesBySenderProfileIdAverageForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesBySenderProfileIdAverageForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesBySenderProfileIdAverageForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdAverageForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdAverageGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_GENERATED_REPLY_ASC',
  EmailMessagesBySenderProfileIdAverageGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_GENERATED_REPLY_DESC',
  EmailMessagesBySenderProfileIdAverageHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdAverageHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdAverageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_ID_ASC',
  EmailMessagesBySenderProfileIdAverageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_ID_DESC',
  EmailMessagesBySenderProfileIdAverageIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_INTENT_ASC',
  EmailMessagesBySenderProfileIdAverageIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_INTENT_DESC',
  EmailMessagesBySenderProfileIdAverageIsLawyerAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_IS_LAWYER_ASC',
  EmailMessagesBySenderProfileIdAverageIsLawyerDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_IS_LAWYER_DESC',
  EmailMessagesBySenderProfileIdAverageIsReviewedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_IS_REVIEWED_ASC',
  EmailMessagesBySenderProfileIdAverageIsReviewedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_IS_REVIEWED_DESC',
  EmailMessagesBySenderProfileIdAverageLawyerIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_LAWYER_ID_ASC',
  EmailMessagesBySenderProfileIdAverageLawyerIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_LAWYER_ID_DESC',
  EmailMessagesBySenderProfileIdAverageLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdAverageLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdAverageNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_NYLAS_THREAD_ID_ASC',
  EmailMessagesBySenderProfileIdAverageNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_NYLAS_THREAD_ID_DESC',
  EmailMessagesBySenderProfileIdAverageOrderIndexAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_ORDER_INDEX_ASC',
  EmailMessagesBySenderProfileIdAverageOrderIndexDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_ORDER_INDEX_DESC',
  EmailMessagesBySenderProfileIdAveragePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesBySenderProfileIdAveragePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesBySenderProfileIdAverageRepliedIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_REPLIED_INTENT_ASC',
  EmailMessagesBySenderProfileIdAverageRepliedIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_REPLIED_INTENT_DESC',
  EmailMessagesBySenderProfileIdAverageRepliedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_REPLIED_TIME_ASC',
  EmailMessagesBySenderProfileIdAverageRepliedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_REPLIED_TIME_DESC',
  EmailMessagesBySenderProfileIdAverageRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdAverageRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdAverageReviewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_REVIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdAverageReviewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_REVIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdAverageSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  EmailMessagesBySenderProfileIdAverageSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  EmailMessagesBySenderProfileIdAverageSentTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_SENT_TIME_ASC',
  EmailMessagesBySenderProfileIdAverageSentTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_SENT_TIME_DESC',
  EmailMessagesBySenderProfileIdAverageSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdAverageSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdAverageSubjectAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_SUBJECT_ASC',
  EmailMessagesBySenderProfileIdAverageSubjectDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_SUBJECT_DESC',
  EmailMessagesBySenderProfileIdAverageTemplateIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_TEMPLATE_ID_ASC',
  EmailMessagesBySenderProfileIdAverageTemplateIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_TEMPLATE_ID_DESC',
  EmailMessagesBySenderProfileIdAverageUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailMessagesBySenderProfileIdAverageUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailMessagesBySenderProfileIdCountAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_COUNT_ASC',
  EmailMessagesBySenderProfileIdCountDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_COUNT_DESC',
  EmailMessagesBySenderProfileIdDistinctCountAutoRepliedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_AUTO_REPLIED_ASC',
  EmailMessagesBySenderProfileIdDistinctCountAutoRepliedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_AUTO_REPLIED_DESC',
  EmailMessagesBySenderProfileIdDistinctCountCampaignIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  EmailMessagesBySenderProfileIdDistinctCountCampaignIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  EmailMessagesBySenderProfileIdDistinctCountCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesBySenderProfileIdDistinctCountCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesBySenderProfileIdDistinctCountCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdDistinctCountCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdDistinctCountCompanyIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  EmailMessagesBySenderProfileIdDistinctCountCompanyIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  EmailMessagesBySenderProfileIdDistinctCountContactIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  EmailMessagesBySenderProfileIdDistinctCountContactIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  EmailMessagesBySenderProfileIdDistinctCountContentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CONTENT_ASC',
  EmailMessagesBySenderProfileIdDistinctCountContentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CONTENT_DESC',
  EmailMessagesBySenderProfileIdDistinctCountContentHtmlAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CONTENT_HTML_ASC',
  EmailMessagesBySenderProfileIdDistinctCountContentHtmlDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CONTENT_HTML_DESC',
  EmailMessagesBySenderProfileIdDistinctCountCreatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailMessagesBySenderProfileIdDistinctCountCreatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailMessagesBySenderProfileIdDistinctCountEmailIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailMessagesBySenderProfileIdDistinctCountEmailIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailMessagesBySenderProfileIdDistinctCountForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesBySenderProfileIdDistinctCountForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesBySenderProfileIdDistinctCountForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdDistinctCountForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdDistinctCountGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_GENERATED_REPLY_ASC',
  EmailMessagesBySenderProfileIdDistinctCountGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_GENERATED_REPLY_DESC',
  EmailMessagesBySenderProfileIdDistinctCountHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdDistinctCountHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdDistinctCountIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_ASC',
  EmailMessagesBySenderProfileIdDistinctCountIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_DESC',
  EmailMessagesBySenderProfileIdDistinctCountIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_INTENT_ASC',
  EmailMessagesBySenderProfileIdDistinctCountIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_INTENT_DESC',
  EmailMessagesBySenderProfileIdDistinctCountIsLawyerAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  EmailMessagesBySenderProfileIdDistinctCountIsLawyerDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  EmailMessagesBySenderProfileIdDistinctCountIsReviewedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_IS_REVIEWED_ASC',
  EmailMessagesBySenderProfileIdDistinctCountIsReviewedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_IS_REVIEWED_DESC',
  EmailMessagesBySenderProfileIdDistinctCountLawyerIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  EmailMessagesBySenderProfileIdDistinctCountLawyerIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  EmailMessagesBySenderProfileIdDistinctCountLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdDistinctCountLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdDistinctCountNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_ASC',
  EmailMessagesBySenderProfileIdDistinctCountNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_DESC',
  EmailMessagesBySenderProfileIdDistinctCountOrderIndexAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ORDER_INDEX_ASC',
  EmailMessagesBySenderProfileIdDistinctCountOrderIndexDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ORDER_INDEX_DESC',
  EmailMessagesBySenderProfileIdDistinctCountPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesBySenderProfileIdDistinctCountPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesBySenderProfileIdDistinctCountRepliedIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_REPLIED_INTENT_ASC',
  EmailMessagesBySenderProfileIdDistinctCountRepliedIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_REPLIED_INTENT_DESC',
  EmailMessagesBySenderProfileIdDistinctCountRepliedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_REPLIED_TIME_ASC',
  EmailMessagesBySenderProfileIdDistinctCountRepliedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_REPLIED_TIME_DESC',
  EmailMessagesBySenderProfileIdDistinctCountRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdDistinctCountRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdDistinctCountReviewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdDistinctCountReviewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdDistinctCountSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  EmailMessagesBySenderProfileIdDistinctCountSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  EmailMessagesBySenderProfileIdDistinctCountSentTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENT_TIME_ASC',
  EmailMessagesBySenderProfileIdDistinctCountSentTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENT_TIME_DESC',
  EmailMessagesBySenderProfileIdDistinctCountSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdDistinctCountSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdDistinctCountSubjectAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SUBJECT_ASC',
  EmailMessagesBySenderProfileIdDistinctCountSubjectDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SUBJECT_DESC',
  EmailMessagesBySenderProfileIdDistinctCountTemplateIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  EmailMessagesBySenderProfileIdDistinctCountTemplateIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  EmailMessagesBySenderProfileIdDistinctCountUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailMessagesBySenderProfileIdDistinctCountUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailMessagesBySenderProfileIdMaxAutoRepliedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_AUTO_REPLIED_ASC',
  EmailMessagesBySenderProfileIdMaxAutoRepliedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_AUTO_REPLIED_DESC',
  EmailMessagesBySenderProfileIdMaxCampaignIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CAMPAIGN_ID_ASC',
  EmailMessagesBySenderProfileIdMaxCampaignIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CAMPAIGN_ID_DESC',
  EmailMessagesBySenderProfileIdMaxCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesBySenderProfileIdMaxCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesBySenderProfileIdMaxCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdMaxCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdMaxCompanyIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_COMPANY_ID_ASC',
  EmailMessagesBySenderProfileIdMaxCompanyIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_COMPANY_ID_DESC',
  EmailMessagesBySenderProfileIdMaxContactIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CONTACT_ID_ASC',
  EmailMessagesBySenderProfileIdMaxContactIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CONTACT_ID_DESC',
  EmailMessagesBySenderProfileIdMaxContentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CONTENT_ASC',
  EmailMessagesBySenderProfileIdMaxContentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CONTENT_DESC',
  EmailMessagesBySenderProfileIdMaxContentHtmlAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CONTENT_HTML_ASC',
  EmailMessagesBySenderProfileIdMaxContentHtmlDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CONTENT_HTML_DESC',
  EmailMessagesBySenderProfileIdMaxCreatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CREATED_TIME_ASC',
  EmailMessagesBySenderProfileIdMaxCreatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_CREATED_TIME_DESC',
  EmailMessagesBySenderProfileIdMaxEmailIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_EMAIL_ID_ASC',
  EmailMessagesBySenderProfileIdMaxEmailIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_EMAIL_ID_DESC',
  EmailMessagesBySenderProfileIdMaxForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesBySenderProfileIdMaxForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesBySenderProfileIdMaxForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdMaxForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdMaxGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_GENERATED_REPLY_ASC',
  EmailMessagesBySenderProfileIdMaxGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_GENERATED_REPLY_DESC',
  EmailMessagesBySenderProfileIdMaxHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdMaxHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdMaxIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_ID_ASC',
  EmailMessagesBySenderProfileIdMaxIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_ID_DESC',
  EmailMessagesBySenderProfileIdMaxIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_INTENT_ASC',
  EmailMessagesBySenderProfileIdMaxIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_INTENT_DESC',
  EmailMessagesBySenderProfileIdMaxIsLawyerAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_IS_LAWYER_ASC',
  EmailMessagesBySenderProfileIdMaxIsLawyerDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_IS_LAWYER_DESC',
  EmailMessagesBySenderProfileIdMaxIsReviewedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_IS_REVIEWED_ASC',
  EmailMessagesBySenderProfileIdMaxIsReviewedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_IS_REVIEWED_DESC',
  EmailMessagesBySenderProfileIdMaxLawyerIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_LAWYER_ID_ASC',
  EmailMessagesBySenderProfileIdMaxLawyerIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_LAWYER_ID_DESC',
  EmailMessagesBySenderProfileIdMaxLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdMaxLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdMaxNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_NYLAS_THREAD_ID_ASC',
  EmailMessagesBySenderProfileIdMaxNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_NYLAS_THREAD_ID_DESC',
  EmailMessagesBySenderProfileIdMaxOrderIndexAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_ORDER_INDEX_ASC',
  EmailMessagesBySenderProfileIdMaxOrderIndexDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_ORDER_INDEX_DESC',
  EmailMessagesBySenderProfileIdMaxPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesBySenderProfileIdMaxPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesBySenderProfileIdMaxRepliedIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_REPLIED_INTENT_ASC',
  EmailMessagesBySenderProfileIdMaxRepliedIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_REPLIED_INTENT_DESC',
  EmailMessagesBySenderProfileIdMaxRepliedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_REPLIED_TIME_ASC',
  EmailMessagesBySenderProfileIdMaxRepliedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_REPLIED_TIME_DESC',
  EmailMessagesBySenderProfileIdMaxRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdMaxRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdMaxReviewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_REVIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdMaxReviewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_REVIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdMaxSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_ASC',
  EmailMessagesBySenderProfileIdMaxSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_DESC',
  EmailMessagesBySenderProfileIdMaxSentTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_SENT_TIME_ASC',
  EmailMessagesBySenderProfileIdMaxSentTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_SENT_TIME_DESC',
  EmailMessagesBySenderProfileIdMaxSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_SMTP_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdMaxSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_SMTP_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdMaxSubjectAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_SUBJECT_ASC',
  EmailMessagesBySenderProfileIdMaxSubjectDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_SUBJECT_DESC',
  EmailMessagesBySenderProfileIdMaxTemplateIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_TEMPLATE_ID_ASC',
  EmailMessagesBySenderProfileIdMaxTemplateIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_TEMPLATE_ID_DESC',
  EmailMessagesBySenderProfileIdMaxUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_ASC',
  EmailMessagesBySenderProfileIdMaxUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_DESC',
  EmailMessagesBySenderProfileIdMinAutoRepliedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_AUTO_REPLIED_ASC',
  EmailMessagesBySenderProfileIdMinAutoRepliedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_AUTO_REPLIED_DESC',
  EmailMessagesBySenderProfileIdMinCampaignIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CAMPAIGN_ID_ASC',
  EmailMessagesBySenderProfileIdMinCampaignIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CAMPAIGN_ID_DESC',
  EmailMessagesBySenderProfileIdMinCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesBySenderProfileIdMinCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesBySenderProfileIdMinCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdMinCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdMinCompanyIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_COMPANY_ID_ASC',
  EmailMessagesBySenderProfileIdMinCompanyIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_COMPANY_ID_DESC',
  EmailMessagesBySenderProfileIdMinContactIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CONTACT_ID_ASC',
  EmailMessagesBySenderProfileIdMinContactIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CONTACT_ID_DESC',
  EmailMessagesBySenderProfileIdMinContentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CONTENT_ASC',
  EmailMessagesBySenderProfileIdMinContentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CONTENT_DESC',
  EmailMessagesBySenderProfileIdMinContentHtmlAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CONTENT_HTML_ASC',
  EmailMessagesBySenderProfileIdMinContentHtmlDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CONTENT_HTML_DESC',
  EmailMessagesBySenderProfileIdMinCreatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CREATED_TIME_ASC',
  EmailMessagesBySenderProfileIdMinCreatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_CREATED_TIME_DESC',
  EmailMessagesBySenderProfileIdMinEmailIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_EMAIL_ID_ASC',
  EmailMessagesBySenderProfileIdMinEmailIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_EMAIL_ID_DESC',
  EmailMessagesBySenderProfileIdMinForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesBySenderProfileIdMinForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesBySenderProfileIdMinForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdMinForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdMinGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_GENERATED_REPLY_ASC',
  EmailMessagesBySenderProfileIdMinGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_GENERATED_REPLY_DESC',
  EmailMessagesBySenderProfileIdMinHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdMinHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdMinIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_ID_ASC',
  EmailMessagesBySenderProfileIdMinIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_ID_DESC',
  EmailMessagesBySenderProfileIdMinIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_INTENT_ASC',
  EmailMessagesBySenderProfileIdMinIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_INTENT_DESC',
  EmailMessagesBySenderProfileIdMinIsLawyerAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_IS_LAWYER_ASC',
  EmailMessagesBySenderProfileIdMinIsLawyerDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_IS_LAWYER_DESC',
  EmailMessagesBySenderProfileIdMinIsReviewedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_IS_REVIEWED_ASC',
  EmailMessagesBySenderProfileIdMinIsReviewedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_IS_REVIEWED_DESC',
  EmailMessagesBySenderProfileIdMinLawyerIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_LAWYER_ID_ASC',
  EmailMessagesBySenderProfileIdMinLawyerIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_LAWYER_ID_DESC',
  EmailMessagesBySenderProfileIdMinLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdMinLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdMinNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_NYLAS_THREAD_ID_ASC',
  EmailMessagesBySenderProfileIdMinNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_NYLAS_THREAD_ID_DESC',
  EmailMessagesBySenderProfileIdMinOrderIndexAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_ORDER_INDEX_ASC',
  EmailMessagesBySenderProfileIdMinOrderIndexDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_ORDER_INDEX_DESC',
  EmailMessagesBySenderProfileIdMinPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesBySenderProfileIdMinPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesBySenderProfileIdMinRepliedIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_REPLIED_INTENT_ASC',
  EmailMessagesBySenderProfileIdMinRepliedIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_REPLIED_INTENT_DESC',
  EmailMessagesBySenderProfileIdMinRepliedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_REPLIED_TIME_ASC',
  EmailMessagesBySenderProfileIdMinRepliedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_REPLIED_TIME_DESC',
  EmailMessagesBySenderProfileIdMinRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdMinRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdMinReviewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_REVIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdMinReviewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_REVIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdMinSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_ASC',
  EmailMessagesBySenderProfileIdMinSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_DESC',
  EmailMessagesBySenderProfileIdMinSentTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_SENT_TIME_ASC',
  EmailMessagesBySenderProfileIdMinSentTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_SENT_TIME_DESC',
  EmailMessagesBySenderProfileIdMinSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_SMTP_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdMinSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_SMTP_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdMinSubjectAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_SUBJECT_ASC',
  EmailMessagesBySenderProfileIdMinSubjectDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_SUBJECT_DESC',
  EmailMessagesBySenderProfileIdMinTemplateIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_TEMPLATE_ID_ASC',
  EmailMessagesBySenderProfileIdMinTemplateIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_TEMPLATE_ID_DESC',
  EmailMessagesBySenderProfileIdMinUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_ASC',
  EmailMessagesBySenderProfileIdMinUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationContactIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationContactIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationContentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CONTENT_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationContentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CONTENT_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_INTENT_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_INTENT_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENT_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENT_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationSubjectAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SUBJECT_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationSubjectDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SUBJECT_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesBySenderProfileIdStddevPopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevPopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesBySenderProfileIdStddevSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesBySenderProfileIdStddevSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesBySenderProfileIdStddevSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesBySenderProfileIdStddevSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesBySenderProfileIdStddevSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesBySenderProfileIdStddevSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesBySenderProfileIdStddevSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesBySenderProfileIdStddevSampleContactIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesBySenderProfileIdStddevSampleContactIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesBySenderProfileIdStddevSampleContentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CONTENT_ASC',
  EmailMessagesBySenderProfileIdStddevSampleContentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CONTENT_DESC',
  EmailMessagesBySenderProfileIdStddevSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesBySenderProfileIdStddevSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesBySenderProfileIdStddevSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesBySenderProfileIdStddevSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesBySenderProfileIdStddevSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesBySenderProfileIdStddevSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesBySenderProfileIdStddevSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdStddevSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdStddevSampleIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_ASC',
  EmailMessagesBySenderProfileIdStddevSampleIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_DESC',
  EmailMessagesBySenderProfileIdStddevSampleIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_INTENT_ASC',
  EmailMessagesBySenderProfileIdStddevSampleIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_INTENT_DESC',
  EmailMessagesBySenderProfileIdStddevSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesBySenderProfileIdStddevSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesBySenderProfileIdStddevSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesBySenderProfileIdStddevSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesBySenderProfileIdStddevSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesBySenderProfileIdStddevSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesBySenderProfileIdStddevSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesBySenderProfileIdStddevSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesBySenderProfileIdStddevSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesBySenderProfileIdStddevSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesBySenderProfileIdStddevSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesBySenderProfileIdStddevSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesBySenderProfileIdStddevSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesBySenderProfileIdStddevSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesBySenderProfileIdStddevSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENT_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENT_TIME_DESC',
  EmailMessagesBySenderProfileIdStddevSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdStddevSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdStddevSampleSubjectAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SUBJECT_ASC',
  EmailMessagesBySenderProfileIdStddevSampleSubjectDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SUBJECT_DESC',
  EmailMessagesBySenderProfileIdStddevSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesBySenderProfileIdStddevSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesBySenderProfileIdStddevSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesBySenderProfileIdStddevSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailMessagesBySenderProfileIdSumAutoRepliedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_AUTO_REPLIED_ASC',
  EmailMessagesBySenderProfileIdSumAutoRepliedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_AUTO_REPLIED_DESC',
  EmailMessagesBySenderProfileIdSumCampaignIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CAMPAIGN_ID_ASC',
  EmailMessagesBySenderProfileIdSumCampaignIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CAMPAIGN_ID_DESC',
  EmailMessagesBySenderProfileIdSumCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesBySenderProfileIdSumCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesBySenderProfileIdSumCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdSumCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdSumCompanyIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_COMPANY_ID_ASC',
  EmailMessagesBySenderProfileIdSumCompanyIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_COMPANY_ID_DESC',
  EmailMessagesBySenderProfileIdSumContactIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CONTACT_ID_ASC',
  EmailMessagesBySenderProfileIdSumContactIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CONTACT_ID_DESC',
  EmailMessagesBySenderProfileIdSumContentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CONTENT_ASC',
  EmailMessagesBySenderProfileIdSumContentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CONTENT_DESC',
  EmailMessagesBySenderProfileIdSumContentHtmlAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CONTENT_HTML_ASC',
  EmailMessagesBySenderProfileIdSumContentHtmlDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CONTENT_HTML_DESC',
  EmailMessagesBySenderProfileIdSumCreatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CREATED_TIME_ASC',
  EmailMessagesBySenderProfileIdSumCreatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_CREATED_TIME_DESC',
  EmailMessagesBySenderProfileIdSumEmailIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_EMAIL_ID_ASC',
  EmailMessagesBySenderProfileIdSumEmailIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_EMAIL_ID_DESC',
  EmailMessagesBySenderProfileIdSumForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesBySenderProfileIdSumForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesBySenderProfileIdSumForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdSumForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdSumGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_GENERATED_REPLY_ASC',
  EmailMessagesBySenderProfileIdSumGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_GENERATED_REPLY_DESC',
  EmailMessagesBySenderProfileIdSumHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdSumHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdSumIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_ID_ASC',
  EmailMessagesBySenderProfileIdSumIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_ID_DESC',
  EmailMessagesBySenderProfileIdSumIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_INTENT_ASC',
  EmailMessagesBySenderProfileIdSumIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_INTENT_DESC',
  EmailMessagesBySenderProfileIdSumIsLawyerAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_IS_LAWYER_ASC',
  EmailMessagesBySenderProfileIdSumIsLawyerDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_IS_LAWYER_DESC',
  EmailMessagesBySenderProfileIdSumIsReviewedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_IS_REVIEWED_ASC',
  EmailMessagesBySenderProfileIdSumIsReviewedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_IS_REVIEWED_DESC',
  EmailMessagesBySenderProfileIdSumLawyerIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_LAWYER_ID_ASC',
  EmailMessagesBySenderProfileIdSumLawyerIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_LAWYER_ID_DESC',
  EmailMessagesBySenderProfileIdSumLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdSumLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdSumNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_NYLAS_THREAD_ID_ASC',
  EmailMessagesBySenderProfileIdSumNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_NYLAS_THREAD_ID_DESC',
  EmailMessagesBySenderProfileIdSumOrderIndexAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_ORDER_INDEX_ASC',
  EmailMessagesBySenderProfileIdSumOrderIndexDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_ORDER_INDEX_DESC',
  EmailMessagesBySenderProfileIdSumPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesBySenderProfileIdSumPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesBySenderProfileIdSumRepliedIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_REPLIED_INTENT_ASC',
  EmailMessagesBySenderProfileIdSumRepliedIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_REPLIED_INTENT_DESC',
  EmailMessagesBySenderProfileIdSumRepliedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_REPLIED_TIME_ASC',
  EmailMessagesBySenderProfileIdSumRepliedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_REPLIED_TIME_DESC',
  EmailMessagesBySenderProfileIdSumRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdSumRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdSumReviewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_REVIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdSumReviewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_REVIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdSumSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_ASC',
  EmailMessagesBySenderProfileIdSumSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_DESC',
  EmailMessagesBySenderProfileIdSumSentTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_SENT_TIME_ASC',
  EmailMessagesBySenderProfileIdSumSentTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_SENT_TIME_DESC',
  EmailMessagesBySenderProfileIdSumSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_SMTP_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdSumSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_SMTP_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdSumSubjectAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_SUBJECT_ASC',
  EmailMessagesBySenderProfileIdSumSubjectDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_SUBJECT_DESC',
  EmailMessagesBySenderProfileIdSumTemplateIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_TEMPLATE_ID_ASC',
  EmailMessagesBySenderProfileIdSumTemplateIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_TEMPLATE_ID_DESC',
  EmailMessagesBySenderProfileIdSumUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_ASC',
  EmailMessagesBySenderProfileIdSumUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationContactIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationContactIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationContentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CONTENT_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationContentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CONTENT_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_INTENT_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_INTENT_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENT_TIME_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENT_TIME_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationSubjectAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SUBJECT_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationSubjectDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SUBJECT_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesBySenderProfileIdVariancePopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesBySenderProfileIdVariancePopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleContactIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleContactIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleContentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleContentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_INTENT_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_INTENT_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesBySenderProfileIdVarianceSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesBySenderProfileIdVarianceSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENT_TIME_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENT_TIME_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleSubjectAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SUBJECT_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleSubjectDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SUBJECT_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesBySenderProfileIdVarianceSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesBySenderProfileIdVarianceSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdAverageCodeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_CODE_ASC',
  EmailVerificationCodesBySenderProfileIdAverageCodeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_CODE_DESC',
  EmailVerificationCodesBySenderProfileIdAverageCreatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdAverageCreatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdAverageIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_ID_ASC',
  EmailVerificationCodesBySenderProfileIdAverageIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_ID_DESC',
  EmailVerificationCodesBySenderProfileIdAverageRawTextAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_RAW_TEXT_ASC',
  EmailVerificationCodesBySenderProfileIdAverageRawTextDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_RAW_TEXT_DESC',
  EmailVerificationCodesBySenderProfileIdAverageSenderProfileIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  EmailVerificationCodesBySenderProfileIdAverageSenderProfileIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  EmailVerificationCodesBySenderProfileIdAverageSentToAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_SENT_TO_ASC',
  EmailVerificationCodesBySenderProfileIdAverageSentToDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_SENT_TO_DESC',
  EmailVerificationCodesBySenderProfileIdAverageUpdatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdAverageUpdatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdCountAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_COUNT_ASC',
  EmailVerificationCodesBySenderProfileIdCountDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_COUNT_DESC',
  EmailVerificationCodesBySenderProfileIdDistinctCountCodeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CODE_ASC',
  EmailVerificationCodesBySenderProfileIdDistinctCountCodeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CODE_DESC',
  EmailVerificationCodesBySenderProfileIdDistinctCountCreatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdDistinctCountCreatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdDistinctCountIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_ASC',
  EmailVerificationCodesBySenderProfileIdDistinctCountIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_DESC',
  EmailVerificationCodesBySenderProfileIdDistinctCountRawTextAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_RAW_TEXT_ASC',
  EmailVerificationCodesBySenderProfileIdDistinctCountRawTextDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_RAW_TEXT_DESC',
  EmailVerificationCodesBySenderProfileIdDistinctCountSenderProfileIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  EmailVerificationCodesBySenderProfileIdDistinctCountSenderProfileIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  EmailVerificationCodesBySenderProfileIdDistinctCountSentToAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENT_TO_ASC',
  EmailVerificationCodesBySenderProfileIdDistinctCountSentToDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENT_TO_DESC',
  EmailVerificationCodesBySenderProfileIdDistinctCountUpdatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdDistinctCountUpdatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdMaxCodeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_CODE_ASC',
  EmailVerificationCodesBySenderProfileIdMaxCodeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_CODE_DESC',
  EmailVerificationCodesBySenderProfileIdMaxCreatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_CREATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdMaxCreatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_CREATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdMaxIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_ID_ASC',
  EmailVerificationCodesBySenderProfileIdMaxIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_ID_DESC',
  EmailVerificationCodesBySenderProfileIdMaxRawTextAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_RAW_TEXT_ASC',
  EmailVerificationCodesBySenderProfileIdMaxRawTextDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_RAW_TEXT_DESC',
  EmailVerificationCodesBySenderProfileIdMaxSenderProfileIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_ASC',
  EmailVerificationCodesBySenderProfileIdMaxSenderProfileIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_DESC',
  EmailVerificationCodesBySenderProfileIdMaxSentToAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_SENT_TO_ASC',
  EmailVerificationCodesBySenderProfileIdMaxSentToDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_SENT_TO_DESC',
  EmailVerificationCodesBySenderProfileIdMaxUpdatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdMaxUpdatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdMinCodeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_CODE_ASC',
  EmailVerificationCodesBySenderProfileIdMinCodeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_CODE_DESC',
  EmailVerificationCodesBySenderProfileIdMinCreatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_CREATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdMinCreatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_CREATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdMinIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_ID_ASC',
  EmailVerificationCodesBySenderProfileIdMinIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_ID_DESC',
  EmailVerificationCodesBySenderProfileIdMinRawTextAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_RAW_TEXT_ASC',
  EmailVerificationCodesBySenderProfileIdMinRawTextDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_RAW_TEXT_DESC',
  EmailVerificationCodesBySenderProfileIdMinSenderProfileIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_ASC',
  EmailVerificationCodesBySenderProfileIdMinSenderProfileIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_DESC',
  EmailVerificationCodesBySenderProfileIdMinSentToAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_SENT_TO_ASC',
  EmailVerificationCodesBySenderProfileIdMinSentToDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_SENT_TO_DESC',
  EmailVerificationCodesBySenderProfileIdMinUpdatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdMinUpdatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationCodeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CODE_ASC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationCodeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CODE_DESC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationCreatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationCreatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_ASC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_DESC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationRawTextAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_RAW_TEXT_ASC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationRawTextDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_RAW_TEXT_DESC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationSenderProfileIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationSenderProfileIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationSentToAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENT_TO_ASC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationSentToDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENT_TO_DESC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationUpdatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdStddevPopulationUpdatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdStddevSampleCodeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CODE_ASC',
  EmailVerificationCodesBySenderProfileIdStddevSampleCodeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CODE_DESC',
  EmailVerificationCodesBySenderProfileIdStddevSampleCreatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdStddevSampleCreatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdStddevSampleIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_ASC',
  EmailVerificationCodesBySenderProfileIdStddevSampleIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_DESC',
  EmailVerificationCodesBySenderProfileIdStddevSampleRawTextAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_RAW_TEXT_ASC',
  EmailVerificationCodesBySenderProfileIdStddevSampleRawTextDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_RAW_TEXT_DESC',
  EmailVerificationCodesBySenderProfileIdStddevSampleSenderProfileIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailVerificationCodesBySenderProfileIdStddevSampleSenderProfileIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailVerificationCodesBySenderProfileIdStddevSampleSentToAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENT_TO_ASC',
  EmailVerificationCodesBySenderProfileIdStddevSampleSentToDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENT_TO_DESC',
  EmailVerificationCodesBySenderProfileIdStddevSampleUpdatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdStddevSampleUpdatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdSumCodeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_CODE_ASC',
  EmailVerificationCodesBySenderProfileIdSumCodeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_CODE_DESC',
  EmailVerificationCodesBySenderProfileIdSumCreatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_CREATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdSumCreatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_CREATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdSumIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_ID_ASC',
  EmailVerificationCodesBySenderProfileIdSumIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_ID_DESC',
  EmailVerificationCodesBySenderProfileIdSumRawTextAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_RAW_TEXT_ASC',
  EmailVerificationCodesBySenderProfileIdSumRawTextDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_RAW_TEXT_DESC',
  EmailVerificationCodesBySenderProfileIdSumSenderProfileIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_ASC',
  EmailVerificationCodesBySenderProfileIdSumSenderProfileIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_DESC',
  EmailVerificationCodesBySenderProfileIdSumSentToAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_SENT_TO_ASC',
  EmailVerificationCodesBySenderProfileIdSumSentToDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_SENT_TO_DESC',
  EmailVerificationCodesBySenderProfileIdSumUpdatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdSumUpdatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationCodeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CODE_ASC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationCodeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CODE_DESC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationCreatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationCreatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_ASC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_DESC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationRawTextAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_RAW_TEXT_ASC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationRawTextDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_RAW_TEXT_DESC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationSenderProfileIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationSenderProfileIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationSentToAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENT_TO_ASC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationSentToDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENT_TO_DESC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationUpdatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdVariancePopulationUpdatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleCodeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CODE_ASC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleCodeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CODE_DESC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleCreatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleCreatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleRawTextAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_RAW_TEXT_ASC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleRawTextDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_RAW_TEXT_DESC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleSenderProfileIdAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleSenderProfileIdDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleSentToAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENT_TO_ASC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleSentToDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENT_TO_DESC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleUpdatedTimeAsc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailVerificationCodesBySenderProfileIdVarianceSampleUpdatedTimeDesc = 'EMAIL_VERIFICATION_CODES_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  FirmNameAsc = 'FIRM_NAME_ASC',
  FirmNameDesc = 'FIRM_NAME_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  IsActiveUpdatedTimeAsc = 'IS_ACTIVE_UPDATED_TIME_ASC',
  IsActiveUpdatedTimeDesc = 'IS_ACTIVE_UPDATED_TIME_DESC',
  IsAliasAsc = 'IS_ALIAS_ASC',
  IsAliasDesc = 'IS_ALIAS_DESC',
  IsImpromptuAsc = 'IS_IMPROMPTU_ASC',
  IsImpromptuDesc = 'IS_IMPROMPTU_DESC',
  IsImpromptuUpdatedTimeAsc = 'IS_IMPROMPTU_UPDATED_TIME_ASC',
  IsImpromptuUpdatedTimeDesc = 'IS_IMPROMPTU_UPDATED_TIME_DESC',
  IsPrimaryAsc = 'IS_PRIMARY_ASC',
  IsPrimaryDesc = 'IS_PRIMARY_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  LogoSrcAsc = 'LOGO_SRC_ASC',
  LogoSrcDesc = 'LOGO_SRC_DESC',
  Natural = 'NATURAL',
  PausedUntilTimeAsc = 'PAUSED_UNTIL_TIME_ASC',
  PausedUntilTimeDesc = 'PAUSED_UNTIL_TIME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PriorityAsc = 'PRIORITY_ASC',
  PriorityDesc = 'PRIORITY_DESC',
  ProfilePictureIdAsc = 'PROFILE_PICTURE_ID_ASC',
  ProfilePictureIdDesc = 'PROFILE_PICTURE_ID_DESC',
  ProfilePictureStatusAsc = 'PROFILE_PICTURE_STATUS_ASC',
  ProfilePictureStatusDesc = 'PROFILE_PICTURE_STATUS_DESC',
  ProfileTypeAsc = 'PROFILE_TYPE_ASC',
  ProfileTypeDesc = 'PROFILE_TYPE_DESC',
  ReachedDailyCapAsc = 'REACHED_DAILY_CAP_ASC',
  ReachedDailyCapDesc = 'REACHED_DAILY_CAP_DESC',
  RestPeriodsBySenderProfileIdAverageCreatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_ASC',
  RestPeriodsBySenderProfileIdAverageCreatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_DESC',
  RestPeriodsBySenderProfileIdAverageIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_ID_ASC',
  RestPeriodsBySenderProfileIdAverageIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_ID_DESC',
  RestPeriodsBySenderProfileIdAveragePeriodEndAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_PERIOD_END_ASC',
  RestPeriodsBySenderProfileIdAveragePeriodEndDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_PERIOD_END_DESC',
  RestPeriodsBySenderProfileIdAveragePeriodNoteAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_PERIOD_NOTE_ASC',
  RestPeriodsBySenderProfileIdAveragePeriodNoteDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_PERIOD_NOTE_DESC',
  RestPeriodsBySenderProfileIdAveragePeriodStartAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_PERIOD_START_ASC',
  RestPeriodsBySenderProfileIdAveragePeriodStartDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_PERIOD_START_DESC',
  RestPeriodsBySenderProfileIdAverageSenderProfileIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  RestPeriodsBySenderProfileIdAverageSenderProfileIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  RestPeriodsBySenderProfileIdAverageUpdatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_ASC',
  RestPeriodsBySenderProfileIdAverageUpdatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_DESC',
  RestPeriodsBySenderProfileIdCountAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_COUNT_ASC',
  RestPeriodsBySenderProfileIdCountDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_COUNT_DESC',
  RestPeriodsBySenderProfileIdDistinctCountCreatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  RestPeriodsBySenderProfileIdDistinctCountCreatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  RestPeriodsBySenderProfileIdDistinctCountIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_ASC',
  RestPeriodsBySenderProfileIdDistinctCountIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_DESC',
  RestPeriodsBySenderProfileIdDistinctCountPeriodEndAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PERIOD_END_ASC',
  RestPeriodsBySenderProfileIdDistinctCountPeriodEndDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PERIOD_END_DESC',
  RestPeriodsBySenderProfileIdDistinctCountPeriodNoteAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PERIOD_NOTE_ASC',
  RestPeriodsBySenderProfileIdDistinctCountPeriodNoteDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PERIOD_NOTE_DESC',
  RestPeriodsBySenderProfileIdDistinctCountPeriodStartAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PERIOD_START_ASC',
  RestPeriodsBySenderProfileIdDistinctCountPeriodStartDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_PERIOD_START_DESC',
  RestPeriodsBySenderProfileIdDistinctCountSenderProfileIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  RestPeriodsBySenderProfileIdDistinctCountSenderProfileIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  RestPeriodsBySenderProfileIdDistinctCountUpdatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  RestPeriodsBySenderProfileIdDistinctCountUpdatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  RestPeriodsBySenderProfileIdMaxCreatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_CREATED_TIME_ASC',
  RestPeriodsBySenderProfileIdMaxCreatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_CREATED_TIME_DESC',
  RestPeriodsBySenderProfileIdMaxIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_ID_ASC',
  RestPeriodsBySenderProfileIdMaxIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_ID_DESC',
  RestPeriodsBySenderProfileIdMaxPeriodEndAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_PERIOD_END_ASC',
  RestPeriodsBySenderProfileIdMaxPeriodEndDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_PERIOD_END_DESC',
  RestPeriodsBySenderProfileIdMaxPeriodNoteAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_PERIOD_NOTE_ASC',
  RestPeriodsBySenderProfileIdMaxPeriodNoteDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_PERIOD_NOTE_DESC',
  RestPeriodsBySenderProfileIdMaxPeriodStartAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_PERIOD_START_ASC',
  RestPeriodsBySenderProfileIdMaxPeriodStartDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_PERIOD_START_DESC',
  RestPeriodsBySenderProfileIdMaxSenderProfileIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_ASC',
  RestPeriodsBySenderProfileIdMaxSenderProfileIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_DESC',
  RestPeriodsBySenderProfileIdMaxUpdatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_ASC',
  RestPeriodsBySenderProfileIdMaxUpdatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_DESC',
  RestPeriodsBySenderProfileIdMinCreatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_CREATED_TIME_ASC',
  RestPeriodsBySenderProfileIdMinCreatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_CREATED_TIME_DESC',
  RestPeriodsBySenderProfileIdMinIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_ID_ASC',
  RestPeriodsBySenderProfileIdMinIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_ID_DESC',
  RestPeriodsBySenderProfileIdMinPeriodEndAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_PERIOD_END_ASC',
  RestPeriodsBySenderProfileIdMinPeriodEndDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_PERIOD_END_DESC',
  RestPeriodsBySenderProfileIdMinPeriodNoteAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_PERIOD_NOTE_ASC',
  RestPeriodsBySenderProfileIdMinPeriodNoteDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_PERIOD_NOTE_DESC',
  RestPeriodsBySenderProfileIdMinPeriodStartAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_PERIOD_START_ASC',
  RestPeriodsBySenderProfileIdMinPeriodStartDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_PERIOD_START_DESC',
  RestPeriodsBySenderProfileIdMinSenderProfileIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_ASC',
  RestPeriodsBySenderProfileIdMinSenderProfileIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_DESC',
  RestPeriodsBySenderProfileIdMinUpdatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_ASC',
  RestPeriodsBySenderProfileIdMinUpdatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_DESC',
  RestPeriodsBySenderProfileIdStddevPopulationCreatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  RestPeriodsBySenderProfileIdStddevPopulationCreatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  RestPeriodsBySenderProfileIdStddevPopulationIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_ASC',
  RestPeriodsBySenderProfileIdStddevPopulationIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_DESC',
  RestPeriodsBySenderProfileIdStddevPopulationPeriodEndAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PERIOD_END_ASC',
  RestPeriodsBySenderProfileIdStddevPopulationPeriodEndDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PERIOD_END_DESC',
  RestPeriodsBySenderProfileIdStddevPopulationPeriodNoteAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PERIOD_NOTE_ASC',
  RestPeriodsBySenderProfileIdStddevPopulationPeriodNoteDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PERIOD_NOTE_DESC',
  RestPeriodsBySenderProfileIdStddevPopulationPeriodStartAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PERIOD_START_ASC',
  RestPeriodsBySenderProfileIdStddevPopulationPeriodStartDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_PERIOD_START_DESC',
  RestPeriodsBySenderProfileIdStddevPopulationSenderProfileIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  RestPeriodsBySenderProfileIdStddevPopulationSenderProfileIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  RestPeriodsBySenderProfileIdStddevPopulationUpdatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  RestPeriodsBySenderProfileIdStddevPopulationUpdatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  RestPeriodsBySenderProfileIdStddevSampleCreatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  RestPeriodsBySenderProfileIdStddevSampleCreatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  RestPeriodsBySenderProfileIdStddevSampleIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_ASC',
  RestPeriodsBySenderProfileIdStddevSampleIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_DESC',
  RestPeriodsBySenderProfileIdStddevSamplePeriodEndAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PERIOD_END_ASC',
  RestPeriodsBySenderProfileIdStddevSamplePeriodEndDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PERIOD_END_DESC',
  RestPeriodsBySenderProfileIdStddevSamplePeriodNoteAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PERIOD_NOTE_ASC',
  RestPeriodsBySenderProfileIdStddevSamplePeriodNoteDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PERIOD_NOTE_DESC',
  RestPeriodsBySenderProfileIdStddevSamplePeriodStartAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PERIOD_START_ASC',
  RestPeriodsBySenderProfileIdStddevSamplePeriodStartDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_PERIOD_START_DESC',
  RestPeriodsBySenderProfileIdStddevSampleSenderProfileIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  RestPeriodsBySenderProfileIdStddevSampleSenderProfileIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  RestPeriodsBySenderProfileIdStddevSampleUpdatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  RestPeriodsBySenderProfileIdStddevSampleUpdatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  RestPeriodsBySenderProfileIdSumCreatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_CREATED_TIME_ASC',
  RestPeriodsBySenderProfileIdSumCreatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_CREATED_TIME_DESC',
  RestPeriodsBySenderProfileIdSumIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_ID_ASC',
  RestPeriodsBySenderProfileIdSumIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_ID_DESC',
  RestPeriodsBySenderProfileIdSumPeriodEndAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_PERIOD_END_ASC',
  RestPeriodsBySenderProfileIdSumPeriodEndDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_PERIOD_END_DESC',
  RestPeriodsBySenderProfileIdSumPeriodNoteAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_PERIOD_NOTE_ASC',
  RestPeriodsBySenderProfileIdSumPeriodNoteDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_PERIOD_NOTE_DESC',
  RestPeriodsBySenderProfileIdSumPeriodStartAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_PERIOD_START_ASC',
  RestPeriodsBySenderProfileIdSumPeriodStartDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_PERIOD_START_DESC',
  RestPeriodsBySenderProfileIdSumSenderProfileIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_ASC',
  RestPeriodsBySenderProfileIdSumSenderProfileIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_DESC',
  RestPeriodsBySenderProfileIdSumUpdatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_ASC',
  RestPeriodsBySenderProfileIdSumUpdatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_DESC',
  RestPeriodsBySenderProfileIdVariancePopulationCreatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  RestPeriodsBySenderProfileIdVariancePopulationCreatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  RestPeriodsBySenderProfileIdVariancePopulationIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_ASC',
  RestPeriodsBySenderProfileIdVariancePopulationIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_DESC',
  RestPeriodsBySenderProfileIdVariancePopulationPeriodEndAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PERIOD_END_ASC',
  RestPeriodsBySenderProfileIdVariancePopulationPeriodEndDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PERIOD_END_DESC',
  RestPeriodsBySenderProfileIdVariancePopulationPeriodNoteAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PERIOD_NOTE_ASC',
  RestPeriodsBySenderProfileIdVariancePopulationPeriodNoteDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PERIOD_NOTE_DESC',
  RestPeriodsBySenderProfileIdVariancePopulationPeriodStartAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PERIOD_START_ASC',
  RestPeriodsBySenderProfileIdVariancePopulationPeriodStartDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_PERIOD_START_DESC',
  RestPeriodsBySenderProfileIdVariancePopulationSenderProfileIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  RestPeriodsBySenderProfileIdVariancePopulationSenderProfileIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  RestPeriodsBySenderProfileIdVariancePopulationUpdatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  RestPeriodsBySenderProfileIdVariancePopulationUpdatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  RestPeriodsBySenderProfileIdVarianceSampleCreatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  RestPeriodsBySenderProfileIdVarianceSampleCreatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  RestPeriodsBySenderProfileIdVarianceSampleIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_ASC',
  RestPeriodsBySenderProfileIdVarianceSampleIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_DESC',
  RestPeriodsBySenderProfileIdVarianceSamplePeriodEndAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PERIOD_END_ASC',
  RestPeriodsBySenderProfileIdVarianceSamplePeriodEndDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PERIOD_END_DESC',
  RestPeriodsBySenderProfileIdVarianceSamplePeriodNoteAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PERIOD_NOTE_ASC',
  RestPeriodsBySenderProfileIdVarianceSamplePeriodNoteDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PERIOD_NOTE_DESC',
  RestPeriodsBySenderProfileIdVarianceSamplePeriodStartAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PERIOD_START_ASC',
  RestPeriodsBySenderProfileIdVarianceSamplePeriodStartDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_PERIOD_START_DESC',
  RestPeriodsBySenderProfileIdVarianceSampleSenderProfileIdAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  RestPeriodsBySenderProfileIdVarianceSampleSenderProfileIdDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  RestPeriodsBySenderProfileIdVarianceSampleUpdatedTimeAsc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  RestPeriodsBySenderProfileIdVarianceSampleUpdatedTimeDesc = 'REST_PERIODS_BY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdAverageCreatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdAverageCreatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_AVERAGE_CREATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdAverageIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_AVERAGE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdAverageIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_AVERAGE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdAverageRecoverySenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_AVERAGE_RECOVERY_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdAverageRecoverySenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_AVERAGE_RECOVERY_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdAverageSenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdAverageSenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdAverageUpdatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdAverageUpdatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_AVERAGE_UPDATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdCountAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_COUNT_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdCountDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_COUNT_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdDistinctCountCreatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdDistinctCountCreatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdDistinctCountIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdDistinctCountIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_DISTINCT_COUNT_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdDistinctCountRecoverySenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_DISTINCT_COUNT_RECOVERY_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdDistinctCountRecoverySenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_DISTINCT_COUNT_RECOVERY_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdDistinctCountSenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdDistinctCountSenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdDistinctCountUpdatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdDistinctCountUpdatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMaxCreatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MAX_CREATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMaxCreatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MAX_CREATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMaxIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MAX_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMaxIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MAX_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMaxRecoverySenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MAX_RECOVERY_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMaxRecoverySenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MAX_RECOVERY_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMaxSenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMaxSenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MAX_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMaxUpdatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMaxUpdatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MAX_UPDATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMinCreatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MIN_CREATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMinCreatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MIN_CREATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMinIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MIN_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMinIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MIN_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMinRecoverySenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MIN_RECOVERY_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMinRecoverySenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MIN_RECOVERY_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMinSenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMinSenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MIN_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMinUpdatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdMinUpdatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_MIN_UPDATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevPopulationCreatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevPopulationCreatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevPopulationIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevPopulationIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_POPULATION_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevPopulationRecoverySenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_POPULATION_RECOVERY_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevPopulationRecoverySenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_POPULATION_RECOVERY_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevPopulationSenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevPopulationSenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevPopulationUpdatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevPopulationUpdatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevSampleCreatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevSampleCreatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevSampleIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevSampleIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_SAMPLE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevSampleRecoverySenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_SAMPLE_RECOVERY_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevSampleRecoverySenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_SAMPLE_RECOVERY_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevSampleSenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevSampleSenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevSampleUpdatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdStddevSampleUpdatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdSumCreatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_SUM_CREATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdSumCreatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_SUM_CREATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdSumIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_SUM_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdSumIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_SUM_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdSumRecoverySenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_SUM_RECOVERY_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdSumRecoverySenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_SUM_RECOVERY_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdSumSenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdSumSenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_SUM_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdSumUpdatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdSumUpdatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_SUM_UPDATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVariancePopulationCreatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVariancePopulationCreatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVariancePopulationIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVariancePopulationIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_POPULATION_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVariancePopulationRecoverySenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_POPULATION_RECOVERY_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVariancePopulationRecoverySenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_POPULATION_RECOVERY_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVariancePopulationSenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVariancePopulationSenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVariancePopulationUpdatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVariancePopulationUpdatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVarianceSampleCreatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVarianceSampleCreatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVarianceSampleIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVarianceSampleIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVarianceSampleRecoverySenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_RECOVERY_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVarianceSampleRecoverySenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_RECOVERY_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVarianceSampleSenderProfileIdAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVarianceSampleSenderProfileIdDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVarianceSampleUpdatedTimeAsc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  SenderProfileRecoveryLinksByRecoverySenderProfileIdVarianceSampleUpdatedTimeDesc = 'SENDER_PROFILE_RECOVERY_LINKS_BY_RECOVERY_SENDER_PROFILE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  SignatureHtmlAsc = 'SIGNATURE_HTML_ASC',
  SignatureHtmlDesc = 'SIGNATURE_HTML_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  VerificationTypeAsc = 'VERIFICATION_TYPE_ASC',
  VerificationTypeDesc = 'VERIFICATION_TYPE_DESC',
  WarmupInboxFilterIdAsc = 'WARMUP_INBOX_FILTER_ID_ASC',
  WarmupInboxFilterIdDesc = 'WARMUP_INBOX_FILTER_ID_DESC',
  WarmupInboxIdAsc = 'WARMUP_INBOX_ID_ASC',
  WarmupInboxIdDesc = 'WARMUP_INBOX_ID_DESC',
  WebsiteUrlAsc = 'WEBSITE_URL_ASC',
  WebsiteUrlDesc = 'WEBSITE_URL_DESC'
}

export type SocialProof = Node & {
  __typename?: 'SocialProof';
  /** Reads a single `Company` that is related to this `SocialProof`. */
  companyByCompanyId?: Maybe<Company>;
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `Contact` that is related to this `SocialProof`. */
  contactByContactId?: Maybe<Contact>;
  contactId?: Maybe<Scalars['UUID']['output']>;
  createdTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Lawyer` that is related to this `SocialProof`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  updatedTime: Scalars['Datetime']['output'];
};

export type SocialProofAggregates = {
  __typename?: 'SocialProofAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SocialProofDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `SocialProof` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SocialProofCondition = {
  /** Checks for equality with the object’s `companyId` field. */
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `contactId` field. */
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `text` field. */
  text?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type SocialProofDistinctCountAggregates = {
  __typename?: 'SocialProofDistinctCountAggregates';
  /** Distinct count of companyId across the matching connection */
  companyId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactId across the matching connection */
  contactId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of text across the matching connection */
  text?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `SocialProof` object types. All fields are combined with a logical ‘and.’ */
export type SocialProofFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SocialProofFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `contactId` field. */
  contactId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SocialProofFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SocialProofFilter>>;
  /** Filter by the object’s `text` field. */
  text?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `SocialProof` for usage during aggregation. */
export enum SocialProofGroupBy {
  CompanyId = 'COMPANY_ID',
  ContactId = 'CONTACT_ID',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  LawyerId = 'LAWYER_ID',
  Text = 'TEXT',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type SocialProofHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SocialProofHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `SocialProof` aggregates. */
export type SocialProofHavingInput = {
  AND?: InputMaybe<Array<SocialProofHavingInput>>;
  OR?: InputMaybe<Array<SocialProofHavingInput>>;
  average?: InputMaybe<SocialProofHavingAverageInput>;
  distinctCount?: InputMaybe<SocialProofHavingDistinctCountInput>;
  max?: InputMaybe<SocialProofHavingMaxInput>;
  min?: InputMaybe<SocialProofHavingMinInput>;
  stddevPopulation?: InputMaybe<SocialProofHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SocialProofHavingStddevSampleInput>;
  sum?: InputMaybe<SocialProofHavingSumInput>;
  variancePopulation?: InputMaybe<SocialProofHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SocialProofHavingVarianceSampleInput>;
};

export type SocialProofHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SocialProofHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SocialProofHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SocialProofHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SocialProofHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SocialProofHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type SocialProofHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type SocialProofSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: SocialProofSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type SocialProofSortBy = {
  field?: InputMaybe<SocialProofSortableField>;
};

/** Sortable concrete fields for the `SocialProof` type. */
export enum SocialProofSortableField {
  CompanyId = 'COMPANY_ID',
  ContactId = 'CONTACT_ID',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  LawyerId = 'LAWYER_ID',
  Text = 'TEXT',
  UpdatedTime = 'UPDATED_TIME'
}

/** A connection to a list of `SocialProof` values. */
export type SocialProofsConnection = {
  __typename?: 'SocialProofsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SocialProofAggregates>;
  /** A list of edges which contains the `SocialProof` and cursor to aid in pagination. */
  edges: Array<SocialProofsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SocialProofAggregates>>;
  /** A list of `SocialProof` objects. */
  nodes: Array<Maybe<SocialProof>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SocialProof` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `SocialProof` values. */
export type SocialProofsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SocialProofGroupBy>;
  having?: InputMaybe<SocialProofHavingInput>;
};

/** A `SocialProof` edge in the connection. */
export type SocialProofsEdge = {
  __typename?: 'SocialProofsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `SocialProof` at the end of the edge. */
  node?: Maybe<SocialProof>;
};

/** Methods to use when ordering `SocialProof`. */
export enum SocialProofsOrderBy {
  CompanyIdAsc = 'COMPANY_ID_ASC',
  CompanyIdDesc = 'COMPANY_ID_DESC',
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TextAsc = 'TEXT_ASC',
  TextDesc = 'TEXT_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

/** When the given sortable is null, should we position this at the end of the list or the beginning of the list? */
export enum SortNulls {
  /** Order nulls last when in ascending order, or first when in descending order. */
  Default = 'DEFAULT',
  /** Order nulls first (at the beginning of the list). */
  First = 'FIRST',
  /** Order nulls last (at the end of the list). */
  Last = 'LAST'
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']['input']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']['input']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']['input']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  __typename?: 'Subscription';
  fullName?: Maybe<Scalars['Boolean']['output']>;
};

export type SuggestedTargeting = Node & {
  __typename?: 'SuggestedTargeting';
  campaignRequestId?: Maybe<Scalars['UUID']['output']>;
  /** Reads and enables pagination through a set of `Campaign`. */
  campaignsBySuggestedTargetingId: CampaignsConnection;
  companyCount?: Maybe<Scalars['Int']['output']>;
  contactedCompanyCount: Scalars['Int']['output'];
  createdTime: Scalars['Datetime']['output'];
  focuses?: Maybe<Scalars['JSON']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Lawyer` that is related to this `SuggestedTargeting`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId: Scalars['UUID']['output'];
  lawyerMatchScore: Scalars['Float']['output'];
  llmQuery?: Maybe<Scalars['String']['output']>;
  minimumCompanyCount?: Maybe<Scalars['Int']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  personalisationQuery?: Maybe<Scalars['JSON']['output']>;
  raw?: Maybe<Scalars['String']['output']>;
  rejectedReason?: Maybe<Scalars['String']['output']>;
  rejectedTime?: Maybe<Scalars['Datetime']['output']>;
  targetCompanyDescription?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  totalScore: Scalars['Float']['output'];
};


export type SuggestedTargetingCampaignsBySuggestedTargetingIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CampaignCondition>;
  filter?: InputMaybe<CampaignFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CampaignsOrderBy>>;
  sort?: InputMaybe<Array<CampaignSort>>;
};

export type SuggestedTargetingAggregates = {
  __typename?: 'SuggestedTargetingAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<SuggestedTargetingAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SuggestedTargetingDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<SuggestedTargetingMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<SuggestedTargetingMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<SuggestedTargetingStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<SuggestedTargetingStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<SuggestedTargetingSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<SuggestedTargetingVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<SuggestedTargetingVarianceSampleAggregates>;
};

export type SuggestedTargetingAverageAggregates = {
  __typename?: 'SuggestedTargetingAverageAggregates';
  /** Mean average of companyCount across the matching connection */
  companyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of contactedCompanyCount across the matching connection */
  contactedCompanyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of lawyerMatchScore across the matching connection */
  lawyerMatchScore?: Maybe<Scalars['Float']['output']>;
  /** Mean average of minimumCompanyCount across the matching connection */
  minimumCompanyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of totalScore across the matching connection */
  totalScore?: Maybe<Scalars['Float']['output']>;
};

/**
 * A condition to be used against `SuggestedTargeting` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SuggestedTargetingCondition = {
  /** Checks for equality with the object’s `campaignRequestId` field. */
  campaignRequestId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `companyCount` field. */
  companyCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `contactedCompanyCount` field. */
  contactedCompanyCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `focuses` field. */
  focuses?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerMatchScore` field. */
  lawyerMatchScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `llmQuery` field. */
  llmQuery?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `minimumCompanyCount` field. */
  minimumCompanyCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `personalisationQuery` field. */
  personalisationQuery?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `raw` field. */
  raw?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `rejectedReason` field. */
  rejectedReason?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `rejectedTime` field. */
  rejectedTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `targetCompanyDescription` field. */
  targetCompanyDescription?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `totalScore` field. */
  totalScore?: InputMaybe<Scalars['Float']['input']>;
};

export type SuggestedTargetingDistinctCountAggregates = {
  __typename?: 'SuggestedTargetingDistinctCountAggregates';
  /** Distinct count of campaignRequestId across the matching connection */
  campaignRequestId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of companyCount across the matching connection */
  companyCount?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactedCompanyCount across the matching connection */
  contactedCompanyCount?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of focuses across the matching connection */
  focuses?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerMatchScore across the matching connection */
  lawyerMatchScore?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of llmQuery across the matching connection */
  llmQuery?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of minimumCompanyCount across the matching connection */
  minimumCompanyCount?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of personalisationQuery across the matching connection */
  personalisationQuery?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of raw across the matching connection */
  raw?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of rejectedReason across the matching connection */
  rejectedReason?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of rejectedTime across the matching connection */
  rejectedTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of targetCompanyDescription across the matching connection */
  targetCompanyDescription?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of totalScore across the matching connection */
  totalScore?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `SuggestedTargeting` object types. All fields are combined with a logical ‘and.’ */
export type SuggestedTargetingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SuggestedTargetingFilter>>;
  /** Filter by the object’s `campaignRequestId` field. */
  campaignRequestId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `companyCount` field. */
  companyCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `contactedCompanyCount` field. */
  contactedCompanyCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `focuses` field. */
  focuses?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerMatchScore` field. */
  lawyerMatchScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `llmQuery` field. */
  llmQuery?: InputMaybe<StringFilter>;
  /** Filter by the object’s `minimumCompanyCount` field. */
  minimumCompanyCount?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SuggestedTargetingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SuggestedTargetingFilter>>;
  /** Filter by the object’s `raw` field. */
  raw?: InputMaybe<StringFilter>;
  /** Filter by the object’s `rejectedReason` field. */
  rejectedReason?: InputMaybe<StringFilter>;
  /** Filter by the object’s `rejectedTime` field. */
  rejectedTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `targetCompanyDescription` field. */
  targetCompanyDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalScore` field. */
  totalScore?: InputMaybe<FloatFilter>;
};

/** Grouping methods for `SuggestedTargeting` for usage during aggregation. */
export enum SuggestedTargetingGroupBy {
  CampaignRequestId = 'CAMPAIGN_REQUEST_ID',
  CompanyCount = 'COMPANY_COUNT',
  ContactedCompanyCount = 'CONTACTED_COMPANY_COUNT',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  Focuses = 'FOCUSES',
  LawyerId = 'LAWYER_ID',
  LawyerMatchScore = 'LAWYER_MATCH_SCORE',
  LlmQuery = 'LLM_QUERY',
  MinimumCompanyCount = 'MINIMUM_COMPANY_COUNT',
  PersonalisationQuery = 'PERSONALISATION_QUERY',
  Raw = 'RAW',
  RejectedReason = 'REJECTED_REASON',
  RejectedTime = 'REJECTED_TIME',
  RejectedTimeTruncatedToDay = 'REJECTED_TIME_TRUNCATED_TO_DAY',
  RejectedTimeTruncatedToHour = 'REJECTED_TIME_TRUNCATED_TO_HOUR',
  TargetCompanyDescription = 'TARGET_COMPANY_DESCRIPTION',
  Title = 'TITLE',
  TotalScore = 'TOTAL_SCORE'
}

export type SuggestedTargetingHavingAverageInput = {
  companyCount?: InputMaybe<HavingIntFilter>;
  contactedCompanyCount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerMatchScore?: InputMaybe<HavingFloatFilter>;
  minimumCompanyCount?: InputMaybe<HavingIntFilter>;
  rejectedTime?: InputMaybe<HavingDatetimeFilter>;
  totalScore?: InputMaybe<HavingFloatFilter>;
};

export type SuggestedTargetingHavingDistinctCountInput = {
  companyCount?: InputMaybe<HavingIntFilter>;
  contactedCompanyCount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerMatchScore?: InputMaybe<HavingFloatFilter>;
  minimumCompanyCount?: InputMaybe<HavingIntFilter>;
  rejectedTime?: InputMaybe<HavingDatetimeFilter>;
  totalScore?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `SuggestedTargeting` aggregates. */
export type SuggestedTargetingHavingInput = {
  AND?: InputMaybe<Array<SuggestedTargetingHavingInput>>;
  OR?: InputMaybe<Array<SuggestedTargetingHavingInput>>;
  average?: InputMaybe<SuggestedTargetingHavingAverageInput>;
  distinctCount?: InputMaybe<SuggestedTargetingHavingDistinctCountInput>;
  max?: InputMaybe<SuggestedTargetingHavingMaxInput>;
  min?: InputMaybe<SuggestedTargetingHavingMinInput>;
  stddevPopulation?: InputMaybe<SuggestedTargetingHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SuggestedTargetingHavingStddevSampleInput>;
  sum?: InputMaybe<SuggestedTargetingHavingSumInput>;
  variancePopulation?: InputMaybe<SuggestedTargetingHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SuggestedTargetingHavingVarianceSampleInput>;
};

export type SuggestedTargetingHavingMaxInput = {
  companyCount?: InputMaybe<HavingIntFilter>;
  contactedCompanyCount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerMatchScore?: InputMaybe<HavingFloatFilter>;
  minimumCompanyCount?: InputMaybe<HavingIntFilter>;
  rejectedTime?: InputMaybe<HavingDatetimeFilter>;
  totalScore?: InputMaybe<HavingFloatFilter>;
};

export type SuggestedTargetingHavingMinInput = {
  companyCount?: InputMaybe<HavingIntFilter>;
  contactedCompanyCount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerMatchScore?: InputMaybe<HavingFloatFilter>;
  minimumCompanyCount?: InputMaybe<HavingIntFilter>;
  rejectedTime?: InputMaybe<HavingDatetimeFilter>;
  totalScore?: InputMaybe<HavingFloatFilter>;
};

export type SuggestedTargetingHavingStddevPopulationInput = {
  companyCount?: InputMaybe<HavingIntFilter>;
  contactedCompanyCount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerMatchScore?: InputMaybe<HavingFloatFilter>;
  minimumCompanyCount?: InputMaybe<HavingIntFilter>;
  rejectedTime?: InputMaybe<HavingDatetimeFilter>;
  totalScore?: InputMaybe<HavingFloatFilter>;
};

export type SuggestedTargetingHavingStddevSampleInput = {
  companyCount?: InputMaybe<HavingIntFilter>;
  contactedCompanyCount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerMatchScore?: InputMaybe<HavingFloatFilter>;
  minimumCompanyCount?: InputMaybe<HavingIntFilter>;
  rejectedTime?: InputMaybe<HavingDatetimeFilter>;
  totalScore?: InputMaybe<HavingFloatFilter>;
};

export type SuggestedTargetingHavingSumInput = {
  companyCount?: InputMaybe<HavingIntFilter>;
  contactedCompanyCount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerMatchScore?: InputMaybe<HavingFloatFilter>;
  minimumCompanyCount?: InputMaybe<HavingIntFilter>;
  rejectedTime?: InputMaybe<HavingDatetimeFilter>;
  totalScore?: InputMaybe<HavingFloatFilter>;
};

export type SuggestedTargetingHavingVariancePopulationInput = {
  companyCount?: InputMaybe<HavingIntFilter>;
  contactedCompanyCount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerMatchScore?: InputMaybe<HavingFloatFilter>;
  minimumCompanyCount?: InputMaybe<HavingIntFilter>;
  rejectedTime?: InputMaybe<HavingDatetimeFilter>;
  totalScore?: InputMaybe<HavingFloatFilter>;
};

export type SuggestedTargetingHavingVarianceSampleInput = {
  companyCount?: InputMaybe<HavingIntFilter>;
  contactedCompanyCount?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  lawyerMatchScore?: InputMaybe<HavingFloatFilter>;
  minimumCompanyCount?: InputMaybe<HavingIntFilter>;
  rejectedTime?: InputMaybe<HavingDatetimeFilter>;
  totalScore?: InputMaybe<HavingFloatFilter>;
};

export type SuggestedTargetingMaxAggregates = {
  __typename?: 'SuggestedTargetingMaxAggregates';
  /** Maximum of companyCount across the matching connection */
  companyCount?: Maybe<Scalars['Int']['output']>;
  /** Maximum of contactedCompanyCount across the matching connection */
  contactedCompanyCount?: Maybe<Scalars['Int']['output']>;
  /** Maximum of lawyerMatchScore across the matching connection */
  lawyerMatchScore?: Maybe<Scalars['Float']['output']>;
  /** Maximum of minimumCompanyCount across the matching connection */
  minimumCompanyCount?: Maybe<Scalars['Int']['output']>;
  /** Maximum of totalScore across the matching connection */
  totalScore?: Maybe<Scalars['Float']['output']>;
};

export type SuggestedTargetingMinAggregates = {
  __typename?: 'SuggestedTargetingMinAggregates';
  /** Minimum of companyCount across the matching connection */
  companyCount?: Maybe<Scalars['Int']['output']>;
  /** Minimum of contactedCompanyCount across the matching connection */
  contactedCompanyCount?: Maybe<Scalars['Int']['output']>;
  /** Minimum of lawyerMatchScore across the matching connection */
  lawyerMatchScore?: Maybe<Scalars['Float']['output']>;
  /** Minimum of minimumCompanyCount across the matching connection */
  minimumCompanyCount?: Maybe<Scalars['Int']['output']>;
  /** Minimum of totalScore across the matching connection */
  totalScore?: Maybe<Scalars['Float']['output']>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type SuggestedTargetingSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: SuggestedTargetingSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type SuggestedTargetingSortBy = {
  field?: InputMaybe<SuggestedTargetingSortableField>;
};

/** Sortable concrete fields for the `SuggestedTargeting` type. */
export enum SuggestedTargetingSortableField {
  CampaignRequestId = 'CAMPAIGN_REQUEST_ID',
  CompanyCount = 'COMPANY_COUNT',
  ContactedCompanyCount = 'CONTACTED_COMPANY_COUNT',
  CreatedTime = 'CREATED_TIME',
  Focuses = 'FOCUSES',
  Id = 'ID',
  LawyerId = 'LAWYER_ID',
  LawyerMatchScore = 'LAWYER_MATCH_SCORE',
  LlmQuery = 'LLM_QUERY',
  MinimumCompanyCount = 'MINIMUM_COMPANY_COUNT',
  PersonalisationQuery = 'PERSONALISATION_QUERY',
  Raw = 'RAW',
  RejectedReason = 'REJECTED_REASON',
  RejectedTime = 'REJECTED_TIME',
  TargetCompanyDescription = 'TARGET_COMPANY_DESCRIPTION',
  Title = 'TITLE',
  TotalScore = 'TOTAL_SCORE'
}

export type SuggestedTargetingStddevPopulationAggregates = {
  __typename?: 'SuggestedTargetingStddevPopulationAggregates';
  /** Population standard deviation of companyCount across the matching connection */
  companyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of contactedCompanyCount across the matching connection */
  contactedCompanyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of lawyerMatchScore across the matching connection */
  lawyerMatchScore?: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of minimumCompanyCount across the matching connection */
  minimumCompanyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of totalScore across the matching connection */
  totalScore?: Maybe<Scalars['Float']['output']>;
};

export type SuggestedTargetingStddevSampleAggregates = {
  __typename?: 'SuggestedTargetingStddevSampleAggregates';
  /** Sample standard deviation of companyCount across the matching connection */
  companyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of contactedCompanyCount across the matching connection */
  contactedCompanyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of lawyerMatchScore across the matching connection */
  lawyerMatchScore?: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of minimumCompanyCount across the matching connection */
  minimumCompanyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of totalScore across the matching connection */
  totalScore?: Maybe<Scalars['Float']['output']>;
};

export type SuggestedTargetingSumAggregates = {
  __typename?: 'SuggestedTargetingSumAggregates';
  /** Sum of companyCount across the matching connection */
  companyCount: Scalars['BigInt']['output'];
  /** Sum of contactedCompanyCount across the matching connection */
  contactedCompanyCount: Scalars['BigInt']['output'];
  /** Sum of lawyerMatchScore across the matching connection */
  lawyerMatchScore: Scalars['Float']['output'];
  /** Sum of minimumCompanyCount across the matching connection */
  minimumCompanyCount: Scalars['BigInt']['output'];
  /** Sum of totalScore across the matching connection */
  totalScore: Scalars['Float']['output'];
};

export type SuggestedTargetingVariancePopulationAggregates = {
  __typename?: 'SuggestedTargetingVariancePopulationAggregates';
  /** Population variance of companyCount across the matching connection */
  companyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of contactedCompanyCount across the matching connection */
  contactedCompanyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of lawyerMatchScore across the matching connection */
  lawyerMatchScore?: Maybe<Scalars['Float']['output']>;
  /** Population variance of minimumCompanyCount across the matching connection */
  minimumCompanyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of totalScore across the matching connection */
  totalScore?: Maybe<Scalars['Float']['output']>;
};

export type SuggestedTargetingVarianceSampleAggregates = {
  __typename?: 'SuggestedTargetingVarianceSampleAggregates';
  /** Sample variance of companyCount across the matching connection */
  companyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of contactedCompanyCount across the matching connection */
  contactedCompanyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of lawyerMatchScore across the matching connection */
  lawyerMatchScore?: Maybe<Scalars['Float']['output']>;
  /** Sample variance of minimumCompanyCount across the matching connection */
  minimumCompanyCount?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of totalScore across the matching connection */
  totalScore?: Maybe<Scalars['Float']['output']>;
};

/** A connection to a list of `SuggestedTargeting` values. */
export type SuggestedTargetingsConnection = {
  __typename?: 'SuggestedTargetingsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SuggestedTargetingAggregates>;
  /** A list of edges which contains the `SuggestedTargeting` and cursor to aid in pagination. */
  edges: Array<SuggestedTargetingsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SuggestedTargetingAggregates>>;
  /** A list of `SuggestedTargeting` objects. */
  nodes: Array<Maybe<SuggestedTargeting>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SuggestedTargeting` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `SuggestedTargeting` values. */
export type SuggestedTargetingsConnectionGroupedAggregatesArgs = {
  groupBy: Array<SuggestedTargetingGroupBy>;
  having?: InputMaybe<SuggestedTargetingHavingInput>;
};

/** A `SuggestedTargeting` edge in the connection. */
export type SuggestedTargetingsEdge = {
  __typename?: 'SuggestedTargetingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `SuggestedTargeting` at the end of the edge. */
  node?: Maybe<SuggestedTargeting>;
};

/** Methods to use when ordering `SuggestedTargeting`. */
export enum SuggestedTargetingsOrderBy {
  CampaignsBySuggestedTargetingIdAverageActivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_ACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdAverageActivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_ACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdAverageCampaignTargetIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_CAMPAIGN_TARGET_ID_ASC',
  CampaignsBySuggestedTargetingIdAverageCampaignTargetIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_CAMPAIGN_TARGET_ID_DESC',
  CampaignsBySuggestedTargetingIdAverageChannelAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_CHANNEL_ASC',
  CampaignsBySuggestedTargetingIdAverageChannelDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_CHANNEL_DESC',
  CampaignsBySuggestedTargetingIdAverageConversionScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_CONVERSION_SCORE_ASC',
  CampaignsBySuggestedTargetingIdAverageConversionScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_CONVERSION_SCORE_DESC',
  CampaignsBySuggestedTargetingIdAverageCreatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_CREATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdAverageCreatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_CREATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdAverageDeactivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_DEACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdAverageDeactivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_DEACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdAverageDeletedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_DELETED_ASC',
  CampaignsBySuggestedTargetingIdAverageDeletedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_DELETED_DESC',
  CampaignsBySuggestedTargetingIdAverageDraftedByLawyerAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_DRAFTED_BY_LAWYER_ASC',
  CampaignsBySuggestedTargetingIdAverageDraftedByLawyerDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_DRAFTED_BY_LAWYER_DESC',
  CampaignsBySuggestedTargetingIdAverageIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_ID_ASC',
  CampaignsBySuggestedTargetingIdAverageIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_ID_DESC',
  CampaignsBySuggestedTargetingIdAverageIsPersonalisedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_IS_PERSONALISED_ASC',
  CampaignsBySuggestedTargetingIdAverageIsPersonalisedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_IS_PERSONALISED_DESC',
  CampaignsBySuggestedTargetingIdAverageIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsBySuggestedTargetingIdAverageIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsBySuggestedTargetingIdAverageIsTestModeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_IS_TEST_MODE_ASC',
  CampaignsBySuggestedTargetingIdAverageIsTestModeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_IS_TEST_MODE_DESC',
  CampaignsBySuggestedTargetingIdAverageLawyerIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_LAWYER_ID_ASC',
  CampaignsBySuggestedTargetingIdAverageLawyerIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_LAWYER_ID_DESC',
  CampaignsBySuggestedTargetingIdAverageLawyerViewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdAverageLawyerViewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdAverageMaxAllocationAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_MAX_ALLOCATION_ASC',
  CampaignsBySuggestedTargetingIdAverageMaxAllocationDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_MAX_ALLOCATION_DESC',
  CampaignsBySuggestedTargetingIdAverageNameAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_NAME_ASC',
  CampaignsBySuggestedTargetingIdAverageNameDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_NAME_DESC',
  CampaignsBySuggestedTargetingIdAverageNotesAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_NOTES_ASC',
  CampaignsBySuggestedTargetingIdAverageNotesDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_NOTES_DESC',
  CampaignsBySuggestedTargetingIdAveragePatentQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_PATENT_QUERY_ASC',
  CampaignsBySuggestedTargetingIdAveragePatentQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_PATENT_QUERY_DESC',
  CampaignsBySuggestedTargetingIdAveragePersonalisationQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_PERSONALISATION_QUERY_ASC',
  CampaignsBySuggestedTargetingIdAveragePersonalisationQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_PERSONALISATION_QUERY_DESC',
  CampaignsBySuggestedTargetingIdAveragePriorityAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_PRIORITY_ASC',
  CampaignsBySuggestedTargetingIdAveragePriorityDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_PRIORITY_DESC',
  CampaignsBySuggestedTargetingIdAverageReviewedByAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_REVIEWED_BY_ASC',
  CampaignsBySuggestedTargetingIdAverageReviewedByDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_REVIEWED_BY_DESC',
  CampaignsBySuggestedTargetingIdAverageReviewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_REVIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdAverageReviewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_REVIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdAverageScheduledActivationTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsBySuggestedTargetingIdAverageScheduledActivationTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsBySuggestedTargetingIdAverageSearchAfterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SEARCH_AFTER_ASC',
  CampaignsBySuggestedTargetingIdAverageSearchAfterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SEARCH_AFTER_DESC',
  CampaignsBySuggestedTargetingIdAverageSearchAfterScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SEARCH_AFTER_SCORE_ASC',
  CampaignsBySuggestedTargetingIdAverageSearchAfterScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SEARCH_AFTER_SCORE_DESC',
  CampaignsBySuggestedTargetingIdAverageSearchBeforeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SEARCH_BEFORE_ASC',
  CampaignsBySuggestedTargetingIdAverageSearchBeforeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SEARCH_BEFORE_DESC',
  CampaignsBySuggestedTargetingIdAverageSearchStartedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SEARCH_STARTED_ASC',
  CampaignsBySuggestedTargetingIdAverageSearchStartedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SEARCH_STARTED_DESC',
  CampaignsBySuggestedTargetingIdAverageStatusAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_STATUS_ASC',
  CampaignsBySuggestedTargetingIdAverageStatusDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_STATUS_DESC',
  CampaignsBySuggestedTargetingIdAverageSubjectAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SUBJECT_ASC',
  CampaignsBySuggestedTargetingIdAverageSubjectDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SUBJECT_DESC',
  CampaignsBySuggestedTargetingIdAverageSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SUGGESTED_TARGETING_ID_ASC',
  CampaignsBySuggestedTargetingIdAverageSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_SUGGESTED_TARGETING_ID_DESC',
  CampaignsBySuggestedTargetingIdAverageTargetingRequestAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_TARGETING_REQUEST_ASC',
  CampaignsBySuggestedTargetingIdAverageTargetingRequestDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_TARGETING_REQUEST_DESC',
  CampaignsBySuggestedTargetingIdAverageTargetDescriptionAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_TARGET_DESCRIPTION_ASC',
  CampaignsBySuggestedTargetingIdAverageTargetDescriptionDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_TARGET_DESCRIPTION_DESC',
  CampaignsBySuggestedTargetingIdAverageUpdatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_UPDATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdAverageUpdatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_UPDATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdAverageUseLlmFilterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_USE_LLM_FILTER_ASC',
  CampaignsBySuggestedTargetingIdAverageUseLlmFilterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_USE_LLM_FILTER_DESC',
  CampaignsBySuggestedTargetingIdAverageVectorMatchThresholdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsBySuggestedTargetingIdAverageVectorMatchThresholdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_AVERAGE_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsBySuggestedTargetingIdCountAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_COUNT_ASC',
  CampaignsBySuggestedTargetingIdCountDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_COUNT_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountActivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_ACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountActivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_ACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountCampaignTargetIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_CAMPAIGN_TARGET_ID_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountCampaignTargetIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_CAMPAIGN_TARGET_ID_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountChannelAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_CHANNEL_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountChannelDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_CHANNEL_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountConversionScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_CONVERSION_SCORE_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountConversionScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_CONVERSION_SCORE_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountCreatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountCreatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountDeactivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_DEACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountDeactivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_DEACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountDeletedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_DELETED_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountDeletedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_DELETED_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountDraftedByLawyerAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_DRAFTED_BY_LAWYER_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountDraftedByLawyerDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_DRAFTED_BY_LAWYER_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_ID_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_ID_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountIsPersonalisedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_IS_PERSONALISED_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountIsPersonalisedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_IS_PERSONALISED_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountIsTestModeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_IS_TEST_MODE_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountIsTestModeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_IS_TEST_MODE_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountLawyerIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountLawyerIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountLawyerViewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountLawyerViewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountMaxAllocationAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_MAX_ALLOCATION_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountMaxAllocationDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_MAX_ALLOCATION_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountNameAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_NAME_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountNameDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_NAME_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountNotesAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_NOTES_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountNotesDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_NOTES_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountPatentQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_PATENT_QUERY_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountPatentQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_PATENT_QUERY_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountPersonalisationQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_PERSONALISATION_QUERY_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountPersonalisationQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_PERSONALISATION_QUERY_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountPriorityAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_PRIORITY_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountPriorityDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_PRIORITY_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountReviewedByAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_REVIEWED_BY_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountReviewedByDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_REVIEWED_BY_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountReviewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountReviewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountScheduledActivationTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountScheduledActivationTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountSearchAfterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SEARCH_AFTER_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountSearchAfterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SEARCH_AFTER_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountSearchAfterScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SEARCH_AFTER_SCORE_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountSearchAfterScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SEARCH_AFTER_SCORE_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountSearchBeforeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SEARCH_BEFORE_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountSearchBeforeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SEARCH_BEFORE_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountSearchStartedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SEARCH_STARTED_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountSearchStartedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SEARCH_STARTED_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountStatusAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_STATUS_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountStatusDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_STATUS_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountSubjectAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SUBJECT_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountSubjectDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SUBJECT_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SUGGESTED_TARGETING_ID_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_SUGGESTED_TARGETING_ID_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountTargetingRequestAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_TARGETING_REQUEST_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountTargetingRequestDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_TARGETING_REQUEST_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountTargetDescriptionAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_TARGET_DESCRIPTION_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountTargetDescriptionDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_TARGET_DESCRIPTION_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountUpdatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountUpdatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountUseLlmFilterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_USE_LLM_FILTER_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountUseLlmFilterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_USE_LLM_FILTER_DESC',
  CampaignsBySuggestedTargetingIdDistinctCountVectorMatchThresholdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsBySuggestedTargetingIdDistinctCountVectorMatchThresholdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_DISTINCT_COUNT_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsBySuggestedTargetingIdMaxActivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_ACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdMaxActivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_ACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdMaxCampaignTargetIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_CAMPAIGN_TARGET_ID_ASC',
  CampaignsBySuggestedTargetingIdMaxCampaignTargetIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_CAMPAIGN_TARGET_ID_DESC',
  CampaignsBySuggestedTargetingIdMaxChannelAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_CHANNEL_ASC',
  CampaignsBySuggestedTargetingIdMaxChannelDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_CHANNEL_DESC',
  CampaignsBySuggestedTargetingIdMaxConversionScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_CONVERSION_SCORE_ASC',
  CampaignsBySuggestedTargetingIdMaxConversionScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_CONVERSION_SCORE_DESC',
  CampaignsBySuggestedTargetingIdMaxCreatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_CREATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdMaxCreatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_CREATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdMaxDeactivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_DEACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdMaxDeactivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_DEACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdMaxDeletedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_DELETED_ASC',
  CampaignsBySuggestedTargetingIdMaxDeletedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_DELETED_DESC',
  CampaignsBySuggestedTargetingIdMaxDraftedByLawyerAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_DRAFTED_BY_LAWYER_ASC',
  CampaignsBySuggestedTargetingIdMaxDraftedByLawyerDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_DRAFTED_BY_LAWYER_DESC',
  CampaignsBySuggestedTargetingIdMaxIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_ID_ASC',
  CampaignsBySuggestedTargetingIdMaxIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_ID_DESC',
  CampaignsBySuggestedTargetingIdMaxIsPersonalisedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_IS_PERSONALISED_ASC',
  CampaignsBySuggestedTargetingIdMaxIsPersonalisedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_IS_PERSONALISED_DESC',
  CampaignsBySuggestedTargetingIdMaxIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsBySuggestedTargetingIdMaxIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsBySuggestedTargetingIdMaxIsTestModeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_IS_TEST_MODE_ASC',
  CampaignsBySuggestedTargetingIdMaxIsTestModeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_IS_TEST_MODE_DESC',
  CampaignsBySuggestedTargetingIdMaxLawyerIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_LAWYER_ID_ASC',
  CampaignsBySuggestedTargetingIdMaxLawyerIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_LAWYER_ID_DESC',
  CampaignsBySuggestedTargetingIdMaxLawyerViewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdMaxLawyerViewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdMaxMaxAllocationAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_MAX_ALLOCATION_ASC',
  CampaignsBySuggestedTargetingIdMaxMaxAllocationDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_MAX_ALLOCATION_DESC',
  CampaignsBySuggestedTargetingIdMaxNameAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_NAME_ASC',
  CampaignsBySuggestedTargetingIdMaxNameDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_NAME_DESC',
  CampaignsBySuggestedTargetingIdMaxNotesAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_NOTES_ASC',
  CampaignsBySuggestedTargetingIdMaxNotesDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_NOTES_DESC',
  CampaignsBySuggestedTargetingIdMaxPatentQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_PATENT_QUERY_ASC',
  CampaignsBySuggestedTargetingIdMaxPatentQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_PATENT_QUERY_DESC',
  CampaignsBySuggestedTargetingIdMaxPersonalisationQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_PERSONALISATION_QUERY_ASC',
  CampaignsBySuggestedTargetingIdMaxPersonalisationQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_PERSONALISATION_QUERY_DESC',
  CampaignsBySuggestedTargetingIdMaxPriorityAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_PRIORITY_ASC',
  CampaignsBySuggestedTargetingIdMaxPriorityDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_PRIORITY_DESC',
  CampaignsBySuggestedTargetingIdMaxReviewedByAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_REVIEWED_BY_ASC',
  CampaignsBySuggestedTargetingIdMaxReviewedByDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_REVIEWED_BY_DESC',
  CampaignsBySuggestedTargetingIdMaxReviewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_REVIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdMaxReviewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_REVIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdMaxScheduledActivationTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsBySuggestedTargetingIdMaxScheduledActivationTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsBySuggestedTargetingIdMaxSearchAfterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SEARCH_AFTER_ASC',
  CampaignsBySuggestedTargetingIdMaxSearchAfterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SEARCH_AFTER_DESC',
  CampaignsBySuggestedTargetingIdMaxSearchAfterScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SEARCH_AFTER_SCORE_ASC',
  CampaignsBySuggestedTargetingIdMaxSearchAfterScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SEARCH_AFTER_SCORE_DESC',
  CampaignsBySuggestedTargetingIdMaxSearchBeforeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SEARCH_BEFORE_ASC',
  CampaignsBySuggestedTargetingIdMaxSearchBeforeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SEARCH_BEFORE_DESC',
  CampaignsBySuggestedTargetingIdMaxSearchStartedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SEARCH_STARTED_ASC',
  CampaignsBySuggestedTargetingIdMaxSearchStartedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SEARCH_STARTED_DESC',
  CampaignsBySuggestedTargetingIdMaxStatusAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_STATUS_ASC',
  CampaignsBySuggestedTargetingIdMaxStatusDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_STATUS_DESC',
  CampaignsBySuggestedTargetingIdMaxSubjectAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SUBJECT_ASC',
  CampaignsBySuggestedTargetingIdMaxSubjectDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SUBJECT_DESC',
  CampaignsBySuggestedTargetingIdMaxSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SUGGESTED_TARGETING_ID_ASC',
  CampaignsBySuggestedTargetingIdMaxSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_SUGGESTED_TARGETING_ID_DESC',
  CampaignsBySuggestedTargetingIdMaxTargetingRequestAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_TARGETING_REQUEST_ASC',
  CampaignsBySuggestedTargetingIdMaxTargetingRequestDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_TARGETING_REQUEST_DESC',
  CampaignsBySuggestedTargetingIdMaxTargetDescriptionAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_TARGET_DESCRIPTION_ASC',
  CampaignsBySuggestedTargetingIdMaxTargetDescriptionDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_TARGET_DESCRIPTION_DESC',
  CampaignsBySuggestedTargetingIdMaxUpdatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_UPDATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdMaxUpdatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_UPDATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdMaxUseLlmFilterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_USE_LLM_FILTER_ASC',
  CampaignsBySuggestedTargetingIdMaxUseLlmFilterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_USE_LLM_FILTER_DESC',
  CampaignsBySuggestedTargetingIdMaxVectorMatchThresholdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsBySuggestedTargetingIdMaxVectorMatchThresholdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MAX_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsBySuggestedTargetingIdMinActivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_ACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdMinActivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_ACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdMinCampaignTargetIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_CAMPAIGN_TARGET_ID_ASC',
  CampaignsBySuggestedTargetingIdMinCampaignTargetIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_CAMPAIGN_TARGET_ID_DESC',
  CampaignsBySuggestedTargetingIdMinChannelAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_CHANNEL_ASC',
  CampaignsBySuggestedTargetingIdMinChannelDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_CHANNEL_DESC',
  CampaignsBySuggestedTargetingIdMinConversionScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_CONVERSION_SCORE_ASC',
  CampaignsBySuggestedTargetingIdMinConversionScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_CONVERSION_SCORE_DESC',
  CampaignsBySuggestedTargetingIdMinCreatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_CREATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdMinCreatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_CREATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdMinDeactivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_DEACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdMinDeactivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_DEACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdMinDeletedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_DELETED_ASC',
  CampaignsBySuggestedTargetingIdMinDeletedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_DELETED_DESC',
  CampaignsBySuggestedTargetingIdMinDraftedByLawyerAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_DRAFTED_BY_LAWYER_ASC',
  CampaignsBySuggestedTargetingIdMinDraftedByLawyerDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_DRAFTED_BY_LAWYER_DESC',
  CampaignsBySuggestedTargetingIdMinIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_ID_ASC',
  CampaignsBySuggestedTargetingIdMinIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_ID_DESC',
  CampaignsBySuggestedTargetingIdMinIsPersonalisedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_IS_PERSONALISED_ASC',
  CampaignsBySuggestedTargetingIdMinIsPersonalisedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_IS_PERSONALISED_DESC',
  CampaignsBySuggestedTargetingIdMinIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsBySuggestedTargetingIdMinIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsBySuggestedTargetingIdMinIsTestModeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_IS_TEST_MODE_ASC',
  CampaignsBySuggestedTargetingIdMinIsTestModeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_IS_TEST_MODE_DESC',
  CampaignsBySuggestedTargetingIdMinLawyerIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_LAWYER_ID_ASC',
  CampaignsBySuggestedTargetingIdMinLawyerIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_LAWYER_ID_DESC',
  CampaignsBySuggestedTargetingIdMinLawyerViewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdMinLawyerViewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdMinMaxAllocationAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_MAX_ALLOCATION_ASC',
  CampaignsBySuggestedTargetingIdMinMaxAllocationDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_MAX_ALLOCATION_DESC',
  CampaignsBySuggestedTargetingIdMinNameAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_NAME_ASC',
  CampaignsBySuggestedTargetingIdMinNameDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_NAME_DESC',
  CampaignsBySuggestedTargetingIdMinNotesAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_NOTES_ASC',
  CampaignsBySuggestedTargetingIdMinNotesDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_NOTES_DESC',
  CampaignsBySuggestedTargetingIdMinPatentQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_PATENT_QUERY_ASC',
  CampaignsBySuggestedTargetingIdMinPatentQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_PATENT_QUERY_DESC',
  CampaignsBySuggestedTargetingIdMinPersonalisationQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_PERSONALISATION_QUERY_ASC',
  CampaignsBySuggestedTargetingIdMinPersonalisationQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_PERSONALISATION_QUERY_DESC',
  CampaignsBySuggestedTargetingIdMinPriorityAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_PRIORITY_ASC',
  CampaignsBySuggestedTargetingIdMinPriorityDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_PRIORITY_DESC',
  CampaignsBySuggestedTargetingIdMinReviewedByAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_REVIEWED_BY_ASC',
  CampaignsBySuggestedTargetingIdMinReviewedByDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_REVIEWED_BY_DESC',
  CampaignsBySuggestedTargetingIdMinReviewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_REVIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdMinReviewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_REVIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdMinScheduledActivationTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsBySuggestedTargetingIdMinScheduledActivationTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsBySuggestedTargetingIdMinSearchAfterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SEARCH_AFTER_ASC',
  CampaignsBySuggestedTargetingIdMinSearchAfterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SEARCH_AFTER_DESC',
  CampaignsBySuggestedTargetingIdMinSearchAfterScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SEARCH_AFTER_SCORE_ASC',
  CampaignsBySuggestedTargetingIdMinSearchAfterScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SEARCH_AFTER_SCORE_DESC',
  CampaignsBySuggestedTargetingIdMinSearchBeforeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SEARCH_BEFORE_ASC',
  CampaignsBySuggestedTargetingIdMinSearchBeforeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SEARCH_BEFORE_DESC',
  CampaignsBySuggestedTargetingIdMinSearchStartedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SEARCH_STARTED_ASC',
  CampaignsBySuggestedTargetingIdMinSearchStartedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SEARCH_STARTED_DESC',
  CampaignsBySuggestedTargetingIdMinStatusAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_STATUS_ASC',
  CampaignsBySuggestedTargetingIdMinStatusDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_STATUS_DESC',
  CampaignsBySuggestedTargetingIdMinSubjectAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SUBJECT_ASC',
  CampaignsBySuggestedTargetingIdMinSubjectDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SUBJECT_DESC',
  CampaignsBySuggestedTargetingIdMinSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SUGGESTED_TARGETING_ID_ASC',
  CampaignsBySuggestedTargetingIdMinSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_SUGGESTED_TARGETING_ID_DESC',
  CampaignsBySuggestedTargetingIdMinTargetingRequestAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_TARGETING_REQUEST_ASC',
  CampaignsBySuggestedTargetingIdMinTargetingRequestDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_TARGETING_REQUEST_DESC',
  CampaignsBySuggestedTargetingIdMinTargetDescriptionAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_TARGET_DESCRIPTION_ASC',
  CampaignsBySuggestedTargetingIdMinTargetDescriptionDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_TARGET_DESCRIPTION_DESC',
  CampaignsBySuggestedTargetingIdMinUpdatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_UPDATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdMinUpdatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_UPDATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdMinUseLlmFilterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_USE_LLM_FILTER_ASC',
  CampaignsBySuggestedTargetingIdMinUseLlmFilterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_USE_LLM_FILTER_DESC',
  CampaignsBySuggestedTargetingIdMinVectorMatchThresholdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsBySuggestedTargetingIdMinVectorMatchThresholdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_MIN_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationActivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_ACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationActivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_ACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationCampaignTargetIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_CAMPAIGN_TARGET_ID_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationCampaignTargetIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_CAMPAIGN_TARGET_ID_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationChannelAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_CHANNEL_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationChannelDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_CHANNEL_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationConversionScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_CONVERSION_SCORE_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationConversionScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_CONVERSION_SCORE_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationCreatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationCreatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationDeactivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_DEACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationDeactivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_DEACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationDeletedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_DELETED_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationDeletedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_DELETED_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationDraftedByLawyerAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_DRAFTED_BY_LAWYER_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationDraftedByLawyerDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_DRAFTED_BY_LAWYER_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_ID_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_ID_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationIsPersonalisedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_IS_PERSONALISED_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationIsPersonalisedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_IS_PERSONALISED_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationIsTestModeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_IS_TEST_MODE_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationIsTestModeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_IS_TEST_MODE_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationLawyerIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationLawyerIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationLawyerViewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationLawyerViewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationMaxAllocationAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_MAX_ALLOCATION_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationMaxAllocationDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_MAX_ALLOCATION_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationNameAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_NAME_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationNameDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_NAME_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationNotesAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_NOTES_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationNotesDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_NOTES_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationPatentQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_PATENT_QUERY_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationPatentQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_PATENT_QUERY_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationPersonalisationQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_PERSONALISATION_QUERY_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationPersonalisationQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_PERSONALISATION_QUERY_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationPriorityAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_PRIORITY_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationPriorityDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_PRIORITY_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationReviewedByAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_REVIEWED_BY_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationReviewedByDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_REVIEWED_BY_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationReviewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationReviewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationScheduledActivationTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationScheduledActivationTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationSearchAfterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SEARCH_AFTER_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationSearchAfterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SEARCH_AFTER_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationSearchAfterScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SEARCH_AFTER_SCORE_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationSearchAfterScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SEARCH_AFTER_SCORE_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationSearchBeforeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SEARCH_BEFORE_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationSearchBeforeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SEARCH_BEFORE_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationSearchStartedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SEARCH_STARTED_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationSearchStartedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SEARCH_STARTED_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationStatusAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_STATUS_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationStatusDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_STATUS_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationSubjectAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SUBJECT_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationSubjectDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SUBJECT_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SUGGESTED_TARGETING_ID_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_SUGGESTED_TARGETING_ID_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationTargetingRequestAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_TARGETING_REQUEST_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationTargetingRequestDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_TARGETING_REQUEST_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationTargetDescriptionAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_TARGET_DESCRIPTION_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationTargetDescriptionDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_TARGET_DESCRIPTION_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationUpdatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationUpdatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationUseLlmFilterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_USE_LLM_FILTER_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationUseLlmFilterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_USE_LLM_FILTER_DESC',
  CampaignsBySuggestedTargetingIdStddevPopulationVectorMatchThresholdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsBySuggestedTargetingIdStddevPopulationVectorMatchThresholdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_POPULATION_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleActivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_ACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleActivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_ACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleCampaignTargetIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_CAMPAIGN_TARGET_ID_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleCampaignTargetIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_CAMPAIGN_TARGET_ID_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleChannelAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_CHANNEL_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleChannelDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_CHANNEL_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleConversionScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_CONVERSION_SCORE_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleConversionScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_CONVERSION_SCORE_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleCreatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleCreatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleDeactivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_DEACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleDeactivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_DEACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleDeletedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_DELETED_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleDeletedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_DELETED_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleDraftedByLawyerAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_DRAFTED_BY_LAWYER_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleDraftedByLawyerDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_DRAFTED_BY_LAWYER_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_ID_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_ID_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleIsPersonalisedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_IS_PERSONALISED_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleIsPersonalisedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_IS_PERSONALISED_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleIsTestModeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_IS_TEST_MODE_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleIsTestModeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_IS_TEST_MODE_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleLawyerIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleLawyerIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleLawyerViewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleLawyerViewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleMaxAllocationAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_MAX_ALLOCATION_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleMaxAllocationDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_MAX_ALLOCATION_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleNameAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_NAME_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleNameDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_NAME_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleNotesAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_NOTES_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleNotesDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_NOTES_DESC',
  CampaignsBySuggestedTargetingIdStddevSamplePatentQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_PATENT_QUERY_ASC',
  CampaignsBySuggestedTargetingIdStddevSamplePatentQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_PATENT_QUERY_DESC',
  CampaignsBySuggestedTargetingIdStddevSamplePersonalisationQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_PERSONALISATION_QUERY_ASC',
  CampaignsBySuggestedTargetingIdStddevSamplePersonalisationQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_PERSONALISATION_QUERY_DESC',
  CampaignsBySuggestedTargetingIdStddevSamplePriorityAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_PRIORITY_ASC',
  CampaignsBySuggestedTargetingIdStddevSamplePriorityDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_PRIORITY_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleReviewedByAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_REVIEWED_BY_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleReviewedByDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_REVIEWED_BY_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleReviewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleReviewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleScheduledActivationTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleScheduledActivationTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleSearchAfterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SEARCH_AFTER_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleSearchAfterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SEARCH_AFTER_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleSearchAfterScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SEARCH_AFTER_SCORE_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleSearchAfterScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SEARCH_AFTER_SCORE_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleSearchBeforeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SEARCH_BEFORE_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleSearchBeforeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SEARCH_BEFORE_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleSearchStartedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SEARCH_STARTED_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleSearchStartedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SEARCH_STARTED_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleStatusAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_STATUS_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleStatusDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_STATUS_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleSubjectAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SUBJECT_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleSubjectDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SUBJECT_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SUGGESTED_TARGETING_ID_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_SUGGESTED_TARGETING_ID_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleTargetingRequestAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_TARGETING_REQUEST_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleTargetingRequestDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_TARGETING_REQUEST_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleTargetDescriptionAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_TARGET_DESCRIPTION_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleTargetDescriptionDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_TARGET_DESCRIPTION_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleUpdatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleUpdatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleUseLlmFilterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_USE_LLM_FILTER_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleUseLlmFilterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_USE_LLM_FILTER_DESC',
  CampaignsBySuggestedTargetingIdStddevSampleVectorMatchThresholdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsBySuggestedTargetingIdStddevSampleVectorMatchThresholdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_STDDEV_SAMPLE_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsBySuggestedTargetingIdSumActivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_ACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdSumActivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_ACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdSumCampaignTargetIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_CAMPAIGN_TARGET_ID_ASC',
  CampaignsBySuggestedTargetingIdSumCampaignTargetIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_CAMPAIGN_TARGET_ID_DESC',
  CampaignsBySuggestedTargetingIdSumChannelAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_CHANNEL_ASC',
  CampaignsBySuggestedTargetingIdSumChannelDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_CHANNEL_DESC',
  CampaignsBySuggestedTargetingIdSumConversionScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_CONVERSION_SCORE_ASC',
  CampaignsBySuggestedTargetingIdSumConversionScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_CONVERSION_SCORE_DESC',
  CampaignsBySuggestedTargetingIdSumCreatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_CREATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdSumCreatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_CREATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdSumDeactivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_DEACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdSumDeactivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_DEACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdSumDeletedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_DELETED_ASC',
  CampaignsBySuggestedTargetingIdSumDeletedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_DELETED_DESC',
  CampaignsBySuggestedTargetingIdSumDraftedByLawyerAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_DRAFTED_BY_LAWYER_ASC',
  CampaignsBySuggestedTargetingIdSumDraftedByLawyerDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_DRAFTED_BY_LAWYER_DESC',
  CampaignsBySuggestedTargetingIdSumIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_ID_ASC',
  CampaignsBySuggestedTargetingIdSumIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_ID_DESC',
  CampaignsBySuggestedTargetingIdSumIsPersonalisedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_IS_PERSONALISED_ASC',
  CampaignsBySuggestedTargetingIdSumIsPersonalisedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_IS_PERSONALISED_DESC',
  CampaignsBySuggestedTargetingIdSumIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsBySuggestedTargetingIdSumIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsBySuggestedTargetingIdSumIsTestModeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_IS_TEST_MODE_ASC',
  CampaignsBySuggestedTargetingIdSumIsTestModeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_IS_TEST_MODE_DESC',
  CampaignsBySuggestedTargetingIdSumLawyerIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_LAWYER_ID_ASC',
  CampaignsBySuggestedTargetingIdSumLawyerIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_LAWYER_ID_DESC',
  CampaignsBySuggestedTargetingIdSumLawyerViewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdSumLawyerViewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdSumMaxAllocationAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_MAX_ALLOCATION_ASC',
  CampaignsBySuggestedTargetingIdSumMaxAllocationDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_MAX_ALLOCATION_DESC',
  CampaignsBySuggestedTargetingIdSumNameAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_NAME_ASC',
  CampaignsBySuggestedTargetingIdSumNameDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_NAME_DESC',
  CampaignsBySuggestedTargetingIdSumNotesAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_NOTES_ASC',
  CampaignsBySuggestedTargetingIdSumNotesDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_NOTES_DESC',
  CampaignsBySuggestedTargetingIdSumPatentQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_PATENT_QUERY_ASC',
  CampaignsBySuggestedTargetingIdSumPatentQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_PATENT_QUERY_DESC',
  CampaignsBySuggestedTargetingIdSumPersonalisationQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_PERSONALISATION_QUERY_ASC',
  CampaignsBySuggestedTargetingIdSumPersonalisationQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_PERSONALISATION_QUERY_DESC',
  CampaignsBySuggestedTargetingIdSumPriorityAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_PRIORITY_ASC',
  CampaignsBySuggestedTargetingIdSumPriorityDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_PRIORITY_DESC',
  CampaignsBySuggestedTargetingIdSumReviewedByAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_REVIEWED_BY_ASC',
  CampaignsBySuggestedTargetingIdSumReviewedByDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_REVIEWED_BY_DESC',
  CampaignsBySuggestedTargetingIdSumReviewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_REVIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdSumReviewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_REVIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdSumScheduledActivationTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsBySuggestedTargetingIdSumScheduledActivationTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsBySuggestedTargetingIdSumSearchAfterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SEARCH_AFTER_ASC',
  CampaignsBySuggestedTargetingIdSumSearchAfterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SEARCH_AFTER_DESC',
  CampaignsBySuggestedTargetingIdSumSearchAfterScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SEARCH_AFTER_SCORE_ASC',
  CampaignsBySuggestedTargetingIdSumSearchAfterScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SEARCH_AFTER_SCORE_DESC',
  CampaignsBySuggestedTargetingIdSumSearchBeforeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SEARCH_BEFORE_ASC',
  CampaignsBySuggestedTargetingIdSumSearchBeforeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SEARCH_BEFORE_DESC',
  CampaignsBySuggestedTargetingIdSumSearchStartedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SEARCH_STARTED_ASC',
  CampaignsBySuggestedTargetingIdSumSearchStartedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SEARCH_STARTED_DESC',
  CampaignsBySuggestedTargetingIdSumStatusAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_STATUS_ASC',
  CampaignsBySuggestedTargetingIdSumStatusDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_STATUS_DESC',
  CampaignsBySuggestedTargetingIdSumSubjectAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SUBJECT_ASC',
  CampaignsBySuggestedTargetingIdSumSubjectDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SUBJECT_DESC',
  CampaignsBySuggestedTargetingIdSumSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SUGGESTED_TARGETING_ID_ASC',
  CampaignsBySuggestedTargetingIdSumSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_SUGGESTED_TARGETING_ID_DESC',
  CampaignsBySuggestedTargetingIdSumTargetingRequestAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_TARGETING_REQUEST_ASC',
  CampaignsBySuggestedTargetingIdSumTargetingRequestDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_TARGETING_REQUEST_DESC',
  CampaignsBySuggestedTargetingIdSumTargetDescriptionAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_TARGET_DESCRIPTION_ASC',
  CampaignsBySuggestedTargetingIdSumTargetDescriptionDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_TARGET_DESCRIPTION_DESC',
  CampaignsBySuggestedTargetingIdSumUpdatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_UPDATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdSumUpdatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_UPDATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdSumUseLlmFilterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_USE_LLM_FILTER_ASC',
  CampaignsBySuggestedTargetingIdSumUseLlmFilterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_USE_LLM_FILTER_DESC',
  CampaignsBySuggestedTargetingIdSumVectorMatchThresholdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsBySuggestedTargetingIdSumVectorMatchThresholdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_SUM_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationActivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_ACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationActivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_ACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationCampaignTargetIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_CAMPAIGN_TARGET_ID_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationCampaignTargetIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_CAMPAIGN_TARGET_ID_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationChannelAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_CHANNEL_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationChannelDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_CHANNEL_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationConversionScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_CONVERSION_SCORE_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationConversionScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_CONVERSION_SCORE_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationCreatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationCreatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationDeactivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_DEACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationDeactivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_DEACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationDeletedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_DELETED_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationDeletedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_DELETED_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationDraftedByLawyerAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_DRAFTED_BY_LAWYER_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationDraftedByLawyerDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_DRAFTED_BY_LAWYER_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_ID_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_ID_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationIsPersonalisedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_IS_PERSONALISED_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationIsPersonalisedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_IS_PERSONALISED_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationIsTestModeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_IS_TEST_MODE_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationIsTestModeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_IS_TEST_MODE_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationLawyerIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationLawyerIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationLawyerViewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationLawyerViewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationMaxAllocationAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_MAX_ALLOCATION_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationMaxAllocationDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_MAX_ALLOCATION_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationNameAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_NAME_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationNameDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_NAME_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationNotesAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_NOTES_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationNotesDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_NOTES_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationPatentQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_PATENT_QUERY_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationPatentQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_PATENT_QUERY_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationPersonalisationQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_PERSONALISATION_QUERY_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationPersonalisationQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_PERSONALISATION_QUERY_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationPriorityAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_PRIORITY_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationPriorityDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_PRIORITY_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationReviewedByAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_REVIEWED_BY_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationReviewedByDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_REVIEWED_BY_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationReviewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationReviewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationScheduledActivationTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationScheduledActivationTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationSearchAfterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SEARCH_AFTER_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationSearchAfterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SEARCH_AFTER_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationSearchAfterScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SEARCH_AFTER_SCORE_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationSearchAfterScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SEARCH_AFTER_SCORE_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationSearchBeforeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SEARCH_BEFORE_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationSearchBeforeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SEARCH_BEFORE_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationSearchStartedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SEARCH_STARTED_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationSearchStartedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SEARCH_STARTED_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationStatusAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_STATUS_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationStatusDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_STATUS_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationSubjectAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SUBJECT_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationSubjectDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SUBJECT_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SUGGESTED_TARGETING_ID_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_SUGGESTED_TARGETING_ID_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationTargetingRequestAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_TARGETING_REQUEST_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationTargetingRequestDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_TARGETING_REQUEST_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationTargetDescriptionAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_TARGET_DESCRIPTION_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationTargetDescriptionDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_TARGET_DESCRIPTION_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationUpdatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationUpdatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationUseLlmFilterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_USE_LLM_FILTER_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationUseLlmFilterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_USE_LLM_FILTER_DESC',
  CampaignsBySuggestedTargetingIdVariancePopulationVectorMatchThresholdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsBySuggestedTargetingIdVariancePopulationVectorMatchThresholdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_POPULATION_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleActivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_ACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleActivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_ACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleCampaignTargetIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_CAMPAIGN_TARGET_ID_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleCampaignTargetIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_CAMPAIGN_TARGET_ID_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleChannelAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_CHANNEL_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleChannelDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_CHANNEL_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleConversionScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_CONVERSION_SCORE_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleConversionScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_CONVERSION_SCORE_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleCreatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleCreatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleDeactivatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_DEACTIVATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleDeactivatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_DEACTIVATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleDeletedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_DELETED_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleDeletedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_DELETED_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleDraftedByLawyerAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_DRAFTED_BY_LAWYER_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleDraftedByLawyerDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_DRAFTED_BY_LAWYER_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_ID_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_ID_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleIsPersonalisedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_IS_PERSONALISED_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleIsPersonalisedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_IS_PERSONALISED_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleIsSalesFunnelCampaignAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_IS_SALES_FUNNEL_CAMPAIGN_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleIsSalesFunnelCampaignDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_IS_SALES_FUNNEL_CAMPAIGN_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleIsTestModeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_IS_TEST_MODE_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleIsTestModeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_IS_TEST_MODE_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleLawyerIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleLawyerIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleLawyerViewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleLawyerViewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleMaxAllocationAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_MAX_ALLOCATION_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleMaxAllocationDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_MAX_ALLOCATION_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleNameAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_NAME_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleNameDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_NAME_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleNotesAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_NOTES_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleNotesDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_NOTES_DESC',
  CampaignsBySuggestedTargetingIdVarianceSamplePatentQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_PATENT_QUERY_ASC',
  CampaignsBySuggestedTargetingIdVarianceSamplePatentQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_PATENT_QUERY_DESC',
  CampaignsBySuggestedTargetingIdVarianceSamplePersonalisationQueryAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_PERSONALISATION_QUERY_ASC',
  CampaignsBySuggestedTargetingIdVarianceSamplePersonalisationQueryDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_PERSONALISATION_QUERY_DESC',
  CampaignsBySuggestedTargetingIdVarianceSamplePriorityAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_PRIORITY_ASC',
  CampaignsBySuggestedTargetingIdVarianceSamplePriorityDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_PRIORITY_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleReviewedByAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_REVIEWED_BY_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleReviewedByDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_REVIEWED_BY_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleReviewedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleReviewedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleScheduledActivationTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SCHEDULED_ACTIVATION_TIME_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleScheduledActivationTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SCHEDULED_ACTIVATION_TIME_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleSearchAfterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SEARCH_AFTER_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleSearchAfterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SEARCH_AFTER_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleSearchAfterScoreAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SEARCH_AFTER_SCORE_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleSearchAfterScoreDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SEARCH_AFTER_SCORE_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleSearchBeforeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SEARCH_BEFORE_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleSearchBeforeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SEARCH_BEFORE_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleSearchStartedAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SEARCH_STARTED_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleSearchStartedDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SEARCH_STARTED_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleStatusAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_STATUS_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleStatusDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_STATUS_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleSubjectAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SUBJECT_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleSubjectDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SUBJECT_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleSuggestedTargetingIdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SUGGESTED_TARGETING_ID_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleSuggestedTargetingIdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_SUGGESTED_TARGETING_ID_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleTargetingRequestAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_TARGETING_REQUEST_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleTargetingRequestDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_TARGETING_REQUEST_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleTargetDescriptionAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_TARGET_DESCRIPTION_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleTargetDescriptionDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_TARGET_DESCRIPTION_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleUpdatedTimeAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleUpdatedTimeDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleUseLlmFilterAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_USE_LLM_FILTER_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleUseLlmFilterDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_USE_LLM_FILTER_DESC',
  CampaignsBySuggestedTargetingIdVarianceSampleVectorMatchThresholdAsc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_VECTOR_MATCH_THRESHOLD_ASC',
  CampaignsBySuggestedTargetingIdVarianceSampleVectorMatchThresholdDesc = 'CAMPAIGNS_BY_SUGGESTED_TARGETING_ID_VARIANCE_SAMPLE_VECTOR_MATCH_THRESHOLD_DESC',
  CampaignRequestIdAsc = 'CAMPAIGN_REQUEST_ID_ASC',
  CampaignRequestIdDesc = 'CAMPAIGN_REQUEST_ID_DESC',
  CompanyCountAsc = 'COMPANY_COUNT_ASC',
  CompanyCountDesc = 'COMPANY_COUNT_DESC',
  ContactedCompanyCountAsc = 'CONTACTED_COMPANY_COUNT_ASC',
  ContactedCompanyCountDesc = 'CONTACTED_COMPANY_COUNT_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  FocusesAsc = 'FOCUSES_ASC',
  FocusesDesc = 'FOCUSES_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  LawyerMatchScoreAsc = 'LAWYER_MATCH_SCORE_ASC',
  LawyerMatchScoreDesc = 'LAWYER_MATCH_SCORE_DESC',
  LlmQueryAsc = 'LLM_QUERY_ASC',
  LlmQueryDesc = 'LLM_QUERY_DESC',
  MinimumCompanyCountAsc = 'MINIMUM_COMPANY_COUNT_ASC',
  MinimumCompanyCountDesc = 'MINIMUM_COMPANY_COUNT_DESC',
  Natural = 'NATURAL',
  PersonalisationQueryAsc = 'PERSONALISATION_QUERY_ASC',
  PersonalisationQueryDesc = 'PERSONALISATION_QUERY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RawAsc = 'RAW_ASC',
  RawDesc = 'RAW_DESC',
  RejectedReasonAsc = 'REJECTED_REASON_ASC',
  RejectedReasonDesc = 'REJECTED_REASON_DESC',
  RejectedTimeAsc = 'REJECTED_TIME_ASC',
  RejectedTimeDesc = 'REJECTED_TIME_DESC',
  TargetCompanyDescriptionAsc = 'TARGET_COMPANY_DESCRIPTION_ASC',
  TargetCompanyDescriptionDesc = 'TARGET_COMPANY_DESCRIPTION_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TotalScoreAsc = 'TOTAL_SCORE_ASC',
  TotalScoreDesc = 'TOTAL_SCORE_DESC'
}

export type Tag = Node & {
  __typename?: 'Tag';
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads and enables pagination through a set of `LawyerTag`. */
  lawyerTagsByTagId: LawyerTagsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `TagType` that is related to this `Tag`. */
  tagTypeByTypeId?: Maybe<TagType>;
  typeId: Scalars['UUID']['output'];
  value?: Maybe<Scalars['String']['output']>;
};


export type TagLawyerTagsByTagIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<LawyerTagCondition>;
  filter?: InputMaybe<LawyerTagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<LawyerTagsOrderBy>>;
  sort?: InputMaybe<Array<LawyerTagSort>>;
};

export type TagAggregates = {
  __typename?: 'TagAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TagDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/** A condition to be used against `Tag` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TagCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `typeId` field. */
  typeId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['String']['input']>;
};

export type TagDistinctCountAggregates = {
  __typename?: 'TagDistinctCountAggregates';
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of typeId across the matching connection */
  typeId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of value across the matching connection */
  value?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Tag` object types. All fields are combined with a logical ‘and.’ */
export type TagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TagFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TagFilter>>;
  /** Filter by the object’s `typeId` field. */
  typeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<StringFilter>;
};

/** Grouping methods for `Tag` for usage during aggregation. */
export enum TagGroupBy {
  TypeId = 'TYPE_ID',
  Value = 'VALUE'
}

/** Conditions for `Tag` aggregates. */
export type TagHavingInput = {
  AND?: InputMaybe<Array<TagHavingInput>>;
  OR?: InputMaybe<Array<TagHavingInput>>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type TagSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: TagSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type TagSortBy = {
  field?: InputMaybe<TagSortableField>;
};

/** Sortable concrete fields for the `Tag` type. */
export enum TagSortableField {
  Id = 'ID',
  TypeId = 'TYPE_ID',
  Value = 'VALUE'
}

export type TagType = Node & {
  __typename?: 'TagType';
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  name: Scalars['String']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  regex?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `Tag`. */
  tagsByTypeId: TagsConnection;
};


export type TagTypeTagsByTypeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TagCondition>;
  filter?: InputMaybe<TagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
  sort?: InputMaybe<Array<TagSort>>;
};

export type TagTypeAggregates = {
  __typename?: 'TagTypeAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TagTypeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/** A condition to be used against `TagType` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TagTypeCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `regex` field. */
  regex?: InputMaybe<Scalars['String']['input']>;
};

export type TagTypeDistinctCountAggregates = {
  __typename?: 'TagTypeDistinctCountAggregates';
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of regex across the matching connection */
  regex?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `TagType` object types. All fields are combined with a logical ‘and.’ */
export type TagTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TagTypeFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TagTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TagTypeFilter>>;
  /** Filter by the object’s `regex` field. */
  regex?: InputMaybe<StringFilter>;
};

/** Grouping methods for `TagType` for usage during aggregation. */
export enum TagTypeGroupBy {
  Regex = 'REGEX'
}

/** Conditions for `TagType` aggregates. */
export type TagTypeHavingInput = {
  AND?: InputMaybe<Array<TagTypeHavingInput>>;
  OR?: InputMaybe<Array<TagTypeHavingInput>>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type TagTypeSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: TagTypeSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type TagTypeSortBy = {
  field?: InputMaybe<TagTypeSortableField>;
};

/** Sortable concrete fields for the `TagType` type. */
export enum TagTypeSortableField {
  Id = 'ID',
  Name = 'NAME',
  Regex = 'REGEX'
}

/** A connection to a list of `TagType` values. */
export type TagTypesConnection = {
  __typename?: 'TagTypesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TagTypeAggregates>;
  /** A list of edges which contains the `TagType` and cursor to aid in pagination. */
  edges: Array<TagTypesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TagTypeAggregates>>;
  /** A list of `TagType` objects. */
  nodes: Array<Maybe<TagType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TagType` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `TagType` values. */
export type TagTypesConnectionGroupedAggregatesArgs = {
  groupBy: Array<TagTypeGroupBy>;
  having?: InputMaybe<TagTypeHavingInput>;
};

/** A `TagType` edge in the connection. */
export type TagTypesEdge = {
  __typename?: 'TagTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `TagType` at the end of the edge. */
  node?: Maybe<TagType>;
};

/** Methods to use when ordering `TagType`. */
export enum TagTypesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RegexAsc = 'REGEX_ASC',
  RegexDesc = 'REGEX_DESC',
  TagsByTypeIdAverageIdAsc = 'TAGS_BY_TYPE_ID_AVERAGE_ID_ASC',
  TagsByTypeIdAverageIdDesc = 'TAGS_BY_TYPE_ID_AVERAGE_ID_DESC',
  TagsByTypeIdAverageTypeIdAsc = 'TAGS_BY_TYPE_ID_AVERAGE_TYPE_ID_ASC',
  TagsByTypeIdAverageTypeIdDesc = 'TAGS_BY_TYPE_ID_AVERAGE_TYPE_ID_DESC',
  TagsByTypeIdAverageValueAsc = 'TAGS_BY_TYPE_ID_AVERAGE_VALUE_ASC',
  TagsByTypeIdAverageValueDesc = 'TAGS_BY_TYPE_ID_AVERAGE_VALUE_DESC',
  TagsByTypeIdCountAsc = 'TAGS_BY_TYPE_ID_COUNT_ASC',
  TagsByTypeIdCountDesc = 'TAGS_BY_TYPE_ID_COUNT_DESC',
  TagsByTypeIdDistinctCountIdAsc = 'TAGS_BY_TYPE_ID_DISTINCT_COUNT_ID_ASC',
  TagsByTypeIdDistinctCountIdDesc = 'TAGS_BY_TYPE_ID_DISTINCT_COUNT_ID_DESC',
  TagsByTypeIdDistinctCountTypeIdAsc = 'TAGS_BY_TYPE_ID_DISTINCT_COUNT_TYPE_ID_ASC',
  TagsByTypeIdDistinctCountTypeIdDesc = 'TAGS_BY_TYPE_ID_DISTINCT_COUNT_TYPE_ID_DESC',
  TagsByTypeIdDistinctCountValueAsc = 'TAGS_BY_TYPE_ID_DISTINCT_COUNT_VALUE_ASC',
  TagsByTypeIdDistinctCountValueDesc = 'TAGS_BY_TYPE_ID_DISTINCT_COUNT_VALUE_DESC',
  TagsByTypeIdMaxIdAsc = 'TAGS_BY_TYPE_ID_MAX_ID_ASC',
  TagsByTypeIdMaxIdDesc = 'TAGS_BY_TYPE_ID_MAX_ID_DESC',
  TagsByTypeIdMaxTypeIdAsc = 'TAGS_BY_TYPE_ID_MAX_TYPE_ID_ASC',
  TagsByTypeIdMaxTypeIdDesc = 'TAGS_BY_TYPE_ID_MAX_TYPE_ID_DESC',
  TagsByTypeIdMaxValueAsc = 'TAGS_BY_TYPE_ID_MAX_VALUE_ASC',
  TagsByTypeIdMaxValueDesc = 'TAGS_BY_TYPE_ID_MAX_VALUE_DESC',
  TagsByTypeIdMinIdAsc = 'TAGS_BY_TYPE_ID_MIN_ID_ASC',
  TagsByTypeIdMinIdDesc = 'TAGS_BY_TYPE_ID_MIN_ID_DESC',
  TagsByTypeIdMinTypeIdAsc = 'TAGS_BY_TYPE_ID_MIN_TYPE_ID_ASC',
  TagsByTypeIdMinTypeIdDesc = 'TAGS_BY_TYPE_ID_MIN_TYPE_ID_DESC',
  TagsByTypeIdMinValueAsc = 'TAGS_BY_TYPE_ID_MIN_VALUE_ASC',
  TagsByTypeIdMinValueDesc = 'TAGS_BY_TYPE_ID_MIN_VALUE_DESC',
  TagsByTypeIdStddevPopulationIdAsc = 'TAGS_BY_TYPE_ID_STDDEV_POPULATION_ID_ASC',
  TagsByTypeIdStddevPopulationIdDesc = 'TAGS_BY_TYPE_ID_STDDEV_POPULATION_ID_DESC',
  TagsByTypeIdStddevPopulationTypeIdAsc = 'TAGS_BY_TYPE_ID_STDDEV_POPULATION_TYPE_ID_ASC',
  TagsByTypeIdStddevPopulationTypeIdDesc = 'TAGS_BY_TYPE_ID_STDDEV_POPULATION_TYPE_ID_DESC',
  TagsByTypeIdStddevPopulationValueAsc = 'TAGS_BY_TYPE_ID_STDDEV_POPULATION_VALUE_ASC',
  TagsByTypeIdStddevPopulationValueDesc = 'TAGS_BY_TYPE_ID_STDDEV_POPULATION_VALUE_DESC',
  TagsByTypeIdStddevSampleIdAsc = 'TAGS_BY_TYPE_ID_STDDEV_SAMPLE_ID_ASC',
  TagsByTypeIdStddevSampleIdDesc = 'TAGS_BY_TYPE_ID_STDDEV_SAMPLE_ID_DESC',
  TagsByTypeIdStddevSampleTypeIdAsc = 'TAGS_BY_TYPE_ID_STDDEV_SAMPLE_TYPE_ID_ASC',
  TagsByTypeIdStddevSampleTypeIdDesc = 'TAGS_BY_TYPE_ID_STDDEV_SAMPLE_TYPE_ID_DESC',
  TagsByTypeIdStddevSampleValueAsc = 'TAGS_BY_TYPE_ID_STDDEV_SAMPLE_VALUE_ASC',
  TagsByTypeIdStddevSampleValueDesc = 'TAGS_BY_TYPE_ID_STDDEV_SAMPLE_VALUE_DESC',
  TagsByTypeIdSumIdAsc = 'TAGS_BY_TYPE_ID_SUM_ID_ASC',
  TagsByTypeIdSumIdDesc = 'TAGS_BY_TYPE_ID_SUM_ID_DESC',
  TagsByTypeIdSumTypeIdAsc = 'TAGS_BY_TYPE_ID_SUM_TYPE_ID_ASC',
  TagsByTypeIdSumTypeIdDesc = 'TAGS_BY_TYPE_ID_SUM_TYPE_ID_DESC',
  TagsByTypeIdSumValueAsc = 'TAGS_BY_TYPE_ID_SUM_VALUE_ASC',
  TagsByTypeIdSumValueDesc = 'TAGS_BY_TYPE_ID_SUM_VALUE_DESC',
  TagsByTypeIdVariancePopulationIdAsc = 'TAGS_BY_TYPE_ID_VARIANCE_POPULATION_ID_ASC',
  TagsByTypeIdVariancePopulationIdDesc = 'TAGS_BY_TYPE_ID_VARIANCE_POPULATION_ID_DESC',
  TagsByTypeIdVariancePopulationTypeIdAsc = 'TAGS_BY_TYPE_ID_VARIANCE_POPULATION_TYPE_ID_ASC',
  TagsByTypeIdVariancePopulationTypeIdDesc = 'TAGS_BY_TYPE_ID_VARIANCE_POPULATION_TYPE_ID_DESC',
  TagsByTypeIdVariancePopulationValueAsc = 'TAGS_BY_TYPE_ID_VARIANCE_POPULATION_VALUE_ASC',
  TagsByTypeIdVariancePopulationValueDesc = 'TAGS_BY_TYPE_ID_VARIANCE_POPULATION_VALUE_DESC',
  TagsByTypeIdVarianceSampleIdAsc = 'TAGS_BY_TYPE_ID_VARIANCE_SAMPLE_ID_ASC',
  TagsByTypeIdVarianceSampleIdDesc = 'TAGS_BY_TYPE_ID_VARIANCE_SAMPLE_ID_DESC',
  TagsByTypeIdVarianceSampleTypeIdAsc = 'TAGS_BY_TYPE_ID_VARIANCE_SAMPLE_TYPE_ID_ASC',
  TagsByTypeIdVarianceSampleTypeIdDesc = 'TAGS_BY_TYPE_ID_VARIANCE_SAMPLE_TYPE_ID_DESC',
  TagsByTypeIdVarianceSampleValueAsc = 'TAGS_BY_TYPE_ID_VARIANCE_SAMPLE_VALUE_ASC',
  TagsByTypeIdVarianceSampleValueDesc = 'TAGS_BY_TYPE_ID_VARIANCE_SAMPLE_VALUE_DESC'
}

/** A connection to a list of `Tag` values. */
export type TagsConnection = {
  __typename?: 'TagsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TagAggregates>;
  /** A list of edges which contains the `Tag` and cursor to aid in pagination. */
  edges: Array<TagsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TagAggregates>>;
  /** A list of `Tag` objects. */
  nodes: Array<Maybe<Tag>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Tag` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Tag` values. */
export type TagsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TagGroupBy>;
  having?: InputMaybe<TagHavingInput>;
};

/** A `Tag` edge in the connection. */
export type TagsEdge = {
  __typename?: 'TagsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Tag` at the end of the edge. */
  node?: Maybe<Tag>;
};

/** Methods to use when ordering `Tag`. */
export enum TagsOrderBy {
  ContactCampaignTagLinksByTagIdAverageCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_AVERAGE_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByTagIdAverageCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_AVERAGE_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByTagIdAverageContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_AVERAGE_CONTACT_ID_ASC',
  ContactCampaignTagLinksByTagIdAverageContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_AVERAGE_CONTACT_ID_DESC',
  ContactCampaignTagLinksByTagIdAverageTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_AVERAGE_TAG_ID_ASC',
  ContactCampaignTagLinksByTagIdAverageTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_AVERAGE_TAG_ID_DESC',
  ContactCampaignTagLinksByTagIdCountAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_COUNT_ASC',
  ContactCampaignTagLinksByTagIdCountDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_COUNT_DESC',
  ContactCampaignTagLinksByTagIdDistinctCountCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByTagIdDistinctCountCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByTagIdDistinctCountContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ContactCampaignTagLinksByTagIdDistinctCountContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ContactCampaignTagLinksByTagIdDistinctCountTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_DISTINCT_COUNT_TAG_ID_ASC',
  ContactCampaignTagLinksByTagIdDistinctCountTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_DISTINCT_COUNT_TAG_ID_DESC',
  ContactCampaignTagLinksByTagIdMaxCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_MAX_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByTagIdMaxCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_MAX_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByTagIdMaxContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_MAX_CONTACT_ID_ASC',
  ContactCampaignTagLinksByTagIdMaxContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_MAX_CONTACT_ID_DESC',
  ContactCampaignTagLinksByTagIdMaxTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_MAX_TAG_ID_ASC',
  ContactCampaignTagLinksByTagIdMaxTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_MAX_TAG_ID_DESC',
  ContactCampaignTagLinksByTagIdMinCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_MIN_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByTagIdMinCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_MIN_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByTagIdMinContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_MIN_CONTACT_ID_ASC',
  ContactCampaignTagLinksByTagIdMinContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_MIN_CONTACT_ID_DESC',
  ContactCampaignTagLinksByTagIdMinTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_MIN_TAG_ID_ASC',
  ContactCampaignTagLinksByTagIdMinTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_MIN_TAG_ID_DESC',
  ContactCampaignTagLinksByTagIdStddevPopulationCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByTagIdStddevPopulationCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByTagIdStddevPopulationContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ContactCampaignTagLinksByTagIdStddevPopulationContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ContactCampaignTagLinksByTagIdStddevPopulationTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_STDDEV_POPULATION_TAG_ID_ASC',
  ContactCampaignTagLinksByTagIdStddevPopulationTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_STDDEV_POPULATION_TAG_ID_DESC',
  ContactCampaignTagLinksByTagIdStddevSampleCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByTagIdStddevSampleCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByTagIdStddevSampleContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ContactCampaignTagLinksByTagIdStddevSampleContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ContactCampaignTagLinksByTagIdStddevSampleTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_STDDEV_SAMPLE_TAG_ID_ASC',
  ContactCampaignTagLinksByTagIdStddevSampleTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_STDDEV_SAMPLE_TAG_ID_DESC',
  ContactCampaignTagLinksByTagIdSumCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_SUM_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByTagIdSumCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_SUM_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByTagIdSumContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_SUM_CONTACT_ID_ASC',
  ContactCampaignTagLinksByTagIdSumContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_SUM_CONTACT_ID_DESC',
  ContactCampaignTagLinksByTagIdSumTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_SUM_TAG_ID_ASC',
  ContactCampaignTagLinksByTagIdSumTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_SUM_TAG_ID_DESC',
  ContactCampaignTagLinksByTagIdVariancePopulationCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByTagIdVariancePopulationCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByTagIdVariancePopulationContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ContactCampaignTagLinksByTagIdVariancePopulationContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ContactCampaignTagLinksByTagIdVariancePopulationTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_VARIANCE_POPULATION_TAG_ID_ASC',
  ContactCampaignTagLinksByTagIdVariancePopulationTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_VARIANCE_POPULATION_TAG_ID_DESC',
  ContactCampaignTagLinksByTagIdVarianceSampleCampaignIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  ContactCampaignTagLinksByTagIdVarianceSampleCampaignIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  ContactCampaignTagLinksByTagIdVarianceSampleContactIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ContactCampaignTagLinksByTagIdVarianceSampleContactIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ContactCampaignTagLinksByTagIdVarianceSampleTagIdAsc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_VARIANCE_SAMPLE_TAG_ID_ASC',
  ContactCampaignTagLinksByTagIdVarianceSampleTagIdDesc = 'CONTACT_CAMPAIGN_TAG_LINKS_BY_TAG_ID_VARIANCE_SAMPLE_TAG_ID_DESC',
  ContactTagLinksByTagIdAverageContactIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_AVERAGE_CONTACT_ID_ASC',
  ContactTagLinksByTagIdAverageContactIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_AVERAGE_CONTACT_ID_DESC',
  ContactTagLinksByTagIdAverageTagIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_AVERAGE_TAG_ID_ASC',
  ContactTagLinksByTagIdAverageTagIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_AVERAGE_TAG_ID_DESC',
  ContactTagLinksByTagIdCountAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_COUNT_ASC',
  ContactTagLinksByTagIdCountDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_COUNT_DESC',
  ContactTagLinksByTagIdDistinctCountContactIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  ContactTagLinksByTagIdDistinctCountContactIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  ContactTagLinksByTagIdDistinctCountTagIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_DISTINCT_COUNT_TAG_ID_ASC',
  ContactTagLinksByTagIdDistinctCountTagIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_DISTINCT_COUNT_TAG_ID_DESC',
  ContactTagLinksByTagIdMaxContactIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_MAX_CONTACT_ID_ASC',
  ContactTagLinksByTagIdMaxContactIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_MAX_CONTACT_ID_DESC',
  ContactTagLinksByTagIdMaxTagIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_MAX_TAG_ID_ASC',
  ContactTagLinksByTagIdMaxTagIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_MAX_TAG_ID_DESC',
  ContactTagLinksByTagIdMinContactIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_MIN_CONTACT_ID_ASC',
  ContactTagLinksByTagIdMinContactIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_MIN_CONTACT_ID_DESC',
  ContactTagLinksByTagIdMinTagIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_MIN_TAG_ID_ASC',
  ContactTagLinksByTagIdMinTagIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_MIN_TAG_ID_DESC',
  ContactTagLinksByTagIdStddevPopulationContactIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  ContactTagLinksByTagIdStddevPopulationContactIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  ContactTagLinksByTagIdStddevPopulationTagIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_STDDEV_POPULATION_TAG_ID_ASC',
  ContactTagLinksByTagIdStddevPopulationTagIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_STDDEV_POPULATION_TAG_ID_DESC',
  ContactTagLinksByTagIdStddevSampleContactIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  ContactTagLinksByTagIdStddevSampleContactIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  ContactTagLinksByTagIdStddevSampleTagIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_STDDEV_SAMPLE_TAG_ID_ASC',
  ContactTagLinksByTagIdStddevSampleTagIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_STDDEV_SAMPLE_TAG_ID_DESC',
  ContactTagLinksByTagIdSumContactIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_SUM_CONTACT_ID_ASC',
  ContactTagLinksByTagIdSumContactIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_SUM_CONTACT_ID_DESC',
  ContactTagLinksByTagIdSumTagIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_SUM_TAG_ID_ASC',
  ContactTagLinksByTagIdSumTagIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_SUM_TAG_ID_DESC',
  ContactTagLinksByTagIdVariancePopulationContactIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  ContactTagLinksByTagIdVariancePopulationContactIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  ContactTagLinksByTagIdVariancePopulationTagIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_VARIANCE_POPULATION_TAG_ID_ASC',
  ContactTagLinksByTagIdVariancePopulationTagIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_VARIANCE_POPULATION_TAG_ID_DESC',
  ContactTagLinksByTagIdVarianceSampleContactIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  ContactTagLinksByTagIdVarianceSampleContactIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  ContactTagLinksByTagIdVarianceSampleTagIdAsc = 'CONTACT_TAG_LINKS_BY_TAG_ID_VARIANCE_SAMPLE_TAG_ID_ASC',
  ContactTagLinksByTagIdVarianceSampleTagIdDesc = 'CONTACT_TAG_LINKS_BY_TAG_ID_VARIANCE_SAMPLE_TAG_ID_DESC',
  EmailTagsByTagIdAverageEmailIdAsc = 'EMAIL_TAGS_BY_TAG_ID_AVERAGE_EMAIL_ID_ASC',
  EmailTagsByTagIdAverageEmailIdDesc = 'EMAIL_TAGS_BY_TAG_ID_AVERAGE_EMAIL_ID_DESC',
  EmailTagsByTagIdAverageTagIdAsc = 'EMAIL_TAGS_BY_TAG_ID_AVERAGE_TAG_ID_ASC',
  EmailTagsByTagIdAverageTagIdDesc = 'EMAIL_TAGS_BY_TAG_ID_AVERAGE_TAG_ID_DESC',
  EmailTagsByTagIdCountAsc = 'EMAIL_TAGS_BY_TAG_ID_COUNT_ASC',
  EmailTagsByTagIdCountDesc = 'EMAIL_TAGS_BY_TAG_ID_COUNT_DESC',
  EmailTagsByTagIdDistinctCountEmailIdAsc = 'EMAIL_TAGS_BY_TAG_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailTagsByTagIdDistinctCountEmailIdDesc = 'EMAIL_TAGS_BY_TAG_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailTagsByTagIdDistinctCountTagIdAsc = 'EMAIL_TAGS_BY_TAG_ID_DISTINCT_COUNT_TAG_ID_ASC',
  EmailTagsByTagIdDistinctCountTagIdDesc = 'EMAIL_TAGS_BY_TAG_ID_DISTINCT_COUNT_TAG_ID_DESC',
  EmailTagsByTagIdMaxEmailIdAsc = 'EMAIL_TAGS_BY_TAG_ID_MAX_EMAIL_ID_ASC',
  EmailTagsByTagIdMaxEmailIdDesc = 'EMAIL_TAGS_BY_TAG_ID_MAX_EMAIL_ID_DESC',
  EmailTagsByTagIdMaxTagIdAsc = 'EMAIL_TAGS_BY_TAG_ID_MAX_TAG_ID_ASC',
  EmailTagsByTagIdMaxTagIdDesc = 'EMAIL_TAGS_BY_TAG_ID_MAX_TAG_ID_DESC',
  EmailTagsByTagIdMinEmailIdAsc = 'EMAIL_TAGS_BY_TAG_ID_MIN_EMAIL_ID_ASC',
  EmailTagsByTagIdMinEmailIdDesc = 'EMAIL_TAGS_BY_TAG_ID_MIN_EMAIL_ID_DESC',
  EmailTagsByTagIdMinTagIdAsc = 'EMAIL_TAGS_BY_TAG_ID_MIN_TAG_ID_ASC',
  EmailTagsByTagIdMinTagIdDesc = 'EMAIL_TAGS_BY_TAG_ID_MIN_TAG_ID_DESC',
  EmailTagsByTagIdStddevPopulationEmailIdAsc = 'EMAIL_TAGS_BY_TAG_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailTagsByTagIdStddevPopulationEmailIdDesc = 'EMAIL_TAGS_BY_TAG_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailTagsByTagIdStddevPopulationTagIdAsc = 'EMAIL_TAGS_BY_TAG_ID_STDDEV_POPULATION_TAG_ID_ASC',
  EmailTagsByTagIdStddevPopulationTagIdDesc = 'EMAIL_TAGS_BY_TAG_ID_STDDEV_POPULATION_TAG_ID_DESC',
  EmailTagsByTagIdStddevSampleEmailIdAsc = 'EMAIL_TAGS_BY_TAG_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailTagsByTagIdStddevSampleEmailIdDesc = 'EMAIL_TAGS_BY_TAG_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailTagsByTagIdStddevSampleTagIdAsc = 'EMAIL_TAGS_BY_TAG_ID_STDDEV_SAMPLE_TAG_ID_ASC',
  EmailTagsByTagIdStddevSampleTagIdDesc = 'EMAIL_TAGS_BY_TAG_ID_STDDEV_SAMPLE_TAG_ID_DESC',
  EmailTagsByTagIdSumEmailIdAsc = 'EMAIL_TAGS_BY_TAG_ID_SUM_EMAIL_ID_ASC',
  EmailTagsByTagIdSumEmailIdDesc = 'EMAIL_TAGS_BY_TAG_ID_SUM_EMAIL_ID_DESC',
  EmailTagsByTagIdSumTagIdAsc = 'EMAIL_TAGS_BY_TAG_ID_SUM_TAG_ID_ASC',
  EmailTagsByTagIdSumTagIdDesc = 'EMAIL_TAGS_BY_TAG_ID_SUM_TAG_ID_DESC',
  EmailTagsByTagIdVariancePopulationEmailIdAsc = 'EMAIL_TAGS_BY_TAG_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailTagsByTagIdVariancePopulationEmailIdDesc = 'EMAIL_TAGS_BY_TAG_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailTagsByTagIdVariancePopulationTagIdAsc = 'EMAIL_TAGS_BY_TAG_ID_VARIANCE_POPULATION_TAG_ID_ASC',
  EmailTagsByTagIdVariancePopulationTagIdDesc = 'EMAIL_TAGS_BY_TAG_ID_VARIANCE_POPULATION_TAG_ID_DESC',
  EmailTagsByTagIdVarianceSampleEmailIdAsc = 'EMAIL_TAGS_BY_TAG_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailTagsByTagIdVarianceSampleEmailIdDesc = 'EMAIL_TAGS_BY_TAG_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailTagsByTagIdVarianceSampleTagIdAsc = 'EMAIL_TAGS_BY_TAG_ID_VARIANCE_SAMPLE_TAG_ID_ASC',
  EmailTagsByTagIdVarianceSampleTagIdDesc = 'EMAIL_TAGS_BY_TAG_ID_VARIANCE_SAMPLE_TAG_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyerTagsByTagIdAverageLawyerIdAsc = 'LAWYER_TAGS_BY_TAG_ID_AVERAGE_LAWYER_ID_ASC',
  LawyerTagsByTagIdAverageLawyerIdDesc = 'LAWYER_TAGS_BY_TAG_ID_AVERAGE_LAWYER_ID_DESC',
  LawyerTagsByTagIdAverageTagIdAsc = 'LAWYER_TAGS_BY_TAG_ID_AVERAGE_TAG_ID_ASC',
  LawyerTagsByTagIdAverageTagIdDesc = 'LAWYER_TAGS_BY_TAG_ID_AVERAGE_TAG_ID_DESC',
  LawyerTagsByTagIdCountAsc = 'LAWYER_TAGS_BY_TAG_ID_COUNT_ASC',
  LawyerTagsByTagIdCountDesc = 'LAWYER_TAGS_BY_TAG_ID_COUNT_DESC',
  LawyerTagsByTagIdDistinctCountLawyerIdAsc = 'LAWYER_TAGS_BY_TAG_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  LawyerTagsByTagIdDistinctCountLawyerIdDesc = 'LAWYER_TAGS_BY_TAG_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  LawyerTagsByTagIdDistinctCountTagIdAsc = 'LAWYER_TAGS_BY_TAG_ID_DISTINCT_COUNT_TAG_ID_ASC',
  LawyerTagsByTagIdDistinctCountTagIdDesc = 'LAWYER_TAGS_BY_TAG_ID_DISTINCT_COUNT_TAG_ID_DESC',
  LawyerTagsByTagIdMaxLawyerIdAsc = 'LAWYER_TAGS_BY_TAG_ID_MAX_LAWYER_ID_ASC',
  LawyerTagsByTagIdMaxLawyerIdDesc = 'LAWYER_TAGS_BY_TAG_ID_MAX_LAWYER_ID_DESC',
  LawyerTagsByTagIdMaxTagIdAsc = 'LAWYER_TAGS_BY_TAG_ID_MAX_TAG_ID_ASC',
  LawyerTagsByTagIdMaxTagIdDesc = 'LAWYER_TAGS_BY_TAG_ID_MAX_TAG_ID_DESC',
  LawyerTagsByTagIdMinLawyerIdAsc = 'LAWYER_TAGS_BY_TAG_ID_MIN_LAWYER_ID_ASC',
  LawyerTagsByTagIdMinLawyerIdDesc = 'LAWYER_TAGS_BY_TAG_ID_MIN_LAWYER_ID_DESC',
  LawyerTagsByTagIdMinTagIdAsc = 'LAWYER_TAGS_BY_TAG_ID_MIN_TAG_ID_ASC',
  LawyerTagsByTagIdMinTagIdDesc = 'LAWYER_TAGS_BY_TAG_ID_MIN_TAG_ID_DESC',
  LawyerTagsByTagIdStddevPopulationLawyerIdAsc = 'LAWYER_TAGS_BY_TAG_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  LawyerTagsByTagIdStddevPopulationLawyerIdDesc = 'LAWYER_TAGS_BY_TAG_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  LawyerTagsByTagIdStddevPopulationTagIdAsc = 'LAWYER_TAGS_BY_TAG_ID_STDDEV_POPULATION_TAG_ID_ASC',
  LawyerTagsByTagIdStddevPopulationTagIdDesc = 'LAWYER_TAGS_BY_TAG_ID_STDDEV_POPULATION_TAG_ID_DESC',
  LawyerTagsByTagIdStddevSampleLawyerIdAsc = 'LAWYER_TAGS_BY_TAG_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  LawyerTagsByTagIdStddevSampleLawyerIdDesc = 'LAWYER_TAGS_BY_TAG_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  LawyerTagsByTagIdStddevSampleTagIdAsc = 'LAWYER_TAGS_BY_TAG_ID_STDDEV_SAMPLE_TAG_ID_ASC',
  LawyerTagsByTagIdStddevSampleTagIdDesc = 'LAWYER_TAGS_BY_TAG_ID_STDDEV_SAMPLE_TAG_ID_DESC',
  LawyerTagsByTagIdSumLawyerIdAsc = 'LAWYER_TAGS_BY_TAG_ID_SUM_LAWYER_ID_ASC',
  LawyerTagsByTagIdSumLawyerIdDesc = 'LAWYER_TAGS_BY_TAG_ID_SUM_LAWYER_ID_DESC',
  LawyerTagsByTagIdSumTagIdAsc = 'LAWYER_TAGS_BY_TAG_ID_SUM_TAG_ID_ASC',
  LawyerTagsByTagIdSumTagIdDesc = 'LAWYER_TAGS_BY_TAG_ID_SUM_TAG_ID_DESC',
  LawyerTagsByTagIdVariancePopulationLawyerIdAsc = 'LAWYER_TAGS_BY_TAG_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  LawyerTagsByTagIdVariancePopulationLawyerIdDesc = 'LAWYER_TAGS_BY_TAG_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  LawyerTagsByTagIdVariancePopulationTagIdAsc = 'LAWYER_TAGS_BY_TAG_ID_VARIANCE_POPULATION_TAG_ID_ASC',
  LawyerTagsByTagIdVariancePopulationTagIdDesc = 'LAWYER_TAGS_BY_TAG_ID_VARIANCE_POPULATION_TAG_ID_DESC',
  LawyerTagsByTagIdVarianceSampleLawyerIdAsc = 'LAWYER_TAGS_BY_TAG_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  LawyerTagsByTagIdVarianceSampleLawyerIdDesc = 'LAWYER_TAGS_BY_TAG_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  LawyerTagsByTagIdVarianceSampleTagIdAsc = 'LAWYER_TAGS_BY_TAG_ID_VARIANCE_SAMPLE_TAG_ID_ASC',
  LawyerTagsByTagIdVarianceSampleTagIdDesc = 'LAWYER_TAGS_BY_TAG_ID_VARIANCE_SAMPLE_TAG_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TypeIdAsc = 'TYPE_ID_ASC',
  TypeIdDesc = 'TYPE_ID_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

export type Template = Node & {
  __typename?: 'Template';
  /** Reads a single `Campaign` that is related to this `Template`. */
  campaignByCampaignId?: Maybe<Campaign>;
  campaignId: Scalars['UUID']['output'];
  content?: Maybe<Scalars['String']['output']>;
  conversionScore?: Maybe<Scalars['Float']['output']>;
  createdTime: Scalars['Datetime']['output'];
  /** Reads and enables pagination through a set of `DailyStatistic`. */
  dailyStatisticsByTemplateId: DailyStatisticsConnection;
  delay: Scalars['Int']['output'];
  /** Reads and enables pagination through a set of `EmailMessage`. */
  emailMessagesByTemplateId: EmailMessagesConnection;
  /** Reads and enables pagination through a set of `Email`. */
  emailsByTemplateId: EmailsConnection;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  language?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  orderIndex: Scalars['Int']['output'];
  revisedContent?: Maybe<Scalars['String']['output']>;
  sendgridId?: Maybe<Scalars['String']['output']>;
  spintax?: Maybe<Scalars['String']['output']>;
  status: OutreachTemplateStatus;
  /** Reads and enables pagination through a set of `TemplateRevision`. */
  templateRevisionsByTemplateId: TemplateRevisionsConnection;
  updatedTime: Scalars['Datetime']['output'];
};


export type TemplateDailyStatisticsByTemplateIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<DailyStatisticCondition>;
  filter?: InputMaybe<DailyStatisticFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<DailyStatisticsOrderBy>>;
  sort?: InputMaybe<Array<DailyStatisticsSort>>;
};


export type TemplateEmailMessagesByTemplateIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailMessageCondition>;
  filter?: InputMaybe<EmailMessageFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailMessagesOrderBy>>;
  sort?: InputMaybe<Array<EmailMessageSort>>;
};


export type TemplateEmailsByTemplateIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<EmailCondition>;
  filter?: InputMaybe<EmailFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<EmailsOrderBy>>;
  sort?: InputMaybe<Array<EmailSort>>;
};


export type TemplateTemplateRevisionsByTemplateIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TemplateRevisionCondition>;
  filter?: InputMaybe<TemplateRevisionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TemplateRevisionsOrderBy>>;
  sort?: InputMaybe<Array<TemplateRevisionSort>>;
};

export type TemplateAggregates = {
  __typename?: 'TemplateAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<TemplateAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TemplateDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<TemplateMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<TemplateMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<TemplateStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<TemplateStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<TemplateSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<TemplateVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<TemplateVarianceSampleAggregates>;
};

export type TemplateAverageAggregates = {
  __typename?: 'TemplateAverageAggregates';
  /** Mean average of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Mean average of delay across the matching connection */
  delay?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `Template` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TemplateCondition = {
  /** Checks for equality with the object’s `campaignId` field. */
  campaignId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `conversionScore` field. */
  conversionScore?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `delay` field. */
  delay?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `language` field. */
  language?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `orderIndex` field. */
  orderIndex?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `revisedContent` field. */
  revisedContent?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `sendgridId` field. */
  sendgridId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `spintax` field. */
  spintax?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<OutreachTemplateStatus>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type TemplateDistinctCountAggregates = {
  __typename?: 'TemplateDistinctCountAggregates';
  /** Distinct count of campaignId across the matching connection */
  campaignId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of content across the matching connection */
  content?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of delay across the matching connection */
  delay?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of language across the matching connection */
  language?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of revisedContent across the matching connection */
  revisedContent?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of sendgridId across the matching connection */
  sendgridId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of spintax across the matching connection */
  spintax?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Template` object types. All fields are combined with a logical ‘and.’ */
export type TemplateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TemplateFilter>>;
  /** Filter by the object’s `campaignId` field. */
  campaignId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<StringFilter>;
  /** Filter by the object’s `conversionScore` field. */
  conversionScore?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `delay` field. */
  delay?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `language` field. */
  language?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TemplateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TemplateFilter>>;
  /** Filter by the object’s `orderIndex` field. */
  orderIndex?: InputMaybe<IntFilter>;
  /** Filter by the object’s `revisedContent` field. */
  revisedContent?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sendgridId` field. */
  sendgridId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `spintax` field. */
  spintax?: InputMaybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<OutreachTemplateStatusFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Template` for usage during aggregation. */
export enum TemplateGroupBy {
  CampaignId = 'CAMPAIGN_ID',
  Content = 'CONTENT',
  ConversionScore = 'CONVERSION_SCORE',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  Delay = 'DELAY',
  Language = 'LANGUAGE',
  OrderIndex = 'ORDER_INDEX',
  RevisedContent = 'REVISED_CONTENT',
  SendgridId = 'SENDGRID_ID',
  Spintax = 'SPINTAX',
  Status = 'STATUS',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type TemplateHavingAverageInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  delay?: InputMaybe<HavingIntFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateHavingDistinctCountInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  delay?: InputMaybe<HavingIntFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Template` aggregates. */
export type TemplateHavingInput = {
  AND?: InputMaybe<Array<TemplateHavingInput>>;
  OR?: InputMaybe<Array<TemplateHavingInput>>;
  average?: InputMaybe<TemplateHavingAverageInput>;
  distinctCount?: InputMaybe<TemplateHavingDistinctCountInput>;
  max?: InputMaybe<TemplateHavingMaxInput>;
  min?: InputMaybe<TemplateHavingMinInput>;
  stddevPopulation?: InputMaybe<TemplateHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<TemplateHavingStddevSampleInput>;
  sum?: InputMaybe<TemplateHavingSumInput>;
  variancePopulation?: InputMaybe<TemplateHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<TemplateHavingVarianceSampleInput>;
};

export type TemplateHavingMaxInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  delay?: InputMaybe<HavingIntFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateHavingMinInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  delay?: InputMaybe<HavingIntFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateHavingStddevPopulationInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  delay?: InputMaybe<HavingIntFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateHavingStddevSampleInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  delay?: InputMaybe<HavingIntFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateHavingSumInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  delay?: InputMaybe<HavingIntFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateHavingVariancePopulationInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  delay?: InputMaybe<HavingIntFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateHavingVarianceSampleInput = {
  conversionScore?: InputMaybe<HavingFloatFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  delay?: InputMaybe<HavingIntFilter>;
  orderIndex?: InputMaybe<HavingIntFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateMaxAggregates = {
  __typename?: 'TemplateMaxAggregates';
  /** Maximum of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Maximum of delay across the matching connection */
  delay?: Maybe<Scalars['Int']['output']>;
  /** Maximum of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['Int']['output']>;
};

export type TemplateMinAggregates = {
  __typename?: 'TemplateMinAggregates';
  /** Minimum of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Minimum of delay across the matching connection */
  delay?: Maybe<Scalars['Int']['output']>;
  /** Minimum of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['Int']['output']>;
};

export type TemplateRevision = Node & {
  __typename?: 'TemplateRevision';
  content?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Template` that is related to this `TemplateRevision`. */
  templateByTemplateId?: Maybe<Template>;
  templateId: Scalars['UUID']['output'];
};

export type TemplateRevisionAggregates = {
  __typename?: 'TemplateRevisionAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TemplateRevisionDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/**
 * A condition to be used against `TemplateRevision` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TemplateRevisionCondition = {
  /** Checks for equality with the object’s `content` field. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `templateId` field. */
  templateId?: InputMaybe<Scalars['UUID']['input']>;
};

export type TemplateRevisionDistinctCountAggregates = {
  __typename?: 'TemplateRevisionDistinctCountAggregates';
  /** Distinct count of content across the matching connection */
  content?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of templateId across the matching connection */
  templateId?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `TemplateRevision` object types. All fields are combined with a logical ‘and.’ */
export type TemplateRevisionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TemplateRevisionFilter>>;
  /** Filter by the object’s `content` field. */
  content?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TemplateRevisionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TemplateRevisionFilter>>;
  /** Filter by the object’s `templateId` field. */
  templateId?: InputMaybe<UuidFilter>;
};

/** Grouping methods for `TemplateRevision` for usage during aggregation. */
export enum TemplateRevisionGroupBy {
  Content = 'CONTENT',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  TemplateId = 'TEMPLATE_ID'
}

export type TemplateRevisionHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateRevisionHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `TemplateRevision` aggregates. */
export type TemplateRevisionHavingInput = {
  AND?: InputMaybe<Array<TemplateRevisionHavingInput>>;
  OR?: InputMaybe<Array<TemplateRevisionHavingInput>>;
  average?: InputMaybe<TemplateRevisionHavingAverageInput>;
  distinctCount?: InputMaybe<TemplateRevisionHavingDistinctCountInput>;
  max?: InputMaybe<TemplateRevisionHavingMaxInput>;
  min?: InputMaybe<TemplateRevisionHavingMinInput>;
  stddevPopulation?: InputMaybe<TemplateRevisionHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<TemplateRevisionHavingStddevSampleInput>;
  sum?: InputMaybe<TemplateRevisionHavingSumInput>;
  variancePopulation?: InputMaybe<TemplateRevisionHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<TemplateRevisionHavingVarianceSampleInput>;
};

export type TemplateRevisionHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateRevisionHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateRevisionHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateRevisionHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateRevisionHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateRevisionHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TemplateRevisionHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type TemplateRevisionSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: TemplateRevisionSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type TemplateRevisionSortBy = {
  field?: InputMaybe<TemplateRevisionSortableField>;
};

/** Sortable concrete fields for the `TemplateRevision` type. */
export enum TemplateRevisionSortableField {
  Content = 'CONTENT',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  TemplateId = 'TEMPLATE_ID'
}

/** A connection to a list of `TemplateRevision` values. */
export type TemplateRevisionsConnection = {
  __typename?: 'TemplateRevisionsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TemplateRevisionAggregates>;
  /** A list of edges which contains the `TemplateRevision` and cursor to aid in pagination. */
  edges: Array<TemplateRevisionsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TemplateRevisionAggregates>>;
  /** A list of `TemplateRevision` objects. */
  nodes: Array<Maybe<TemplateRevision>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TemplateRevision` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `TemplateRevision` values. */
export type TemplateRevisionsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TemplateRevisionGroupBy>;
  having?: InputMaybe<TemplateRevisionHavingInput>;
};

/** A `TemplateRevision` edge in the connection. */
export type TemplateRevisionsEdge = {
  __typename?: 'TemplateRevisionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `TemplateRevision` at the end of the edge. */
  node?: Maybe<TemplateRevision>;
};

/** Methods to use when ordering `TemplateRevision`. */
export enum TemplateRevisionsOrderBy {
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TemplateIdAsc = 'TEMPLATE_ID_ASC',
  TemplateIdDesc = 'TEMPLATE_ID_DESC'
}

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type TemplateSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: TemplateSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type TemplateSortBy = {
  field?: InputMaybe<TemplateSortableField>;
};

/** Sortable concrete fields for the `Template` type. */
export enum TemplateSortableField {
  CampaignId = 'CAMPAIGN_ID',
  Content = 'CONTENT',
  ConversionScore = 'CONVERSION_SCORE',
  CreatedTime = 'CREATED_TIME',
  Delay = 'DELAY',
  Id = 'ID',
  Language = 'LANGUAGE',
  OrderIndex = 'ORDER_INDEX',
  RevisedContent = 'REVISED_CONTENT',
  SendgridId = 'SENDGRID_ID',
  Spintax = 'SPINTAX',
  Status = 'STATUS',
  UpdatedTime = 'UPDATED_TIME'
}

export type TemplateStddevPopulationAggregates = {
  __typename?: 'TemplateStddevPopulationAggregates';
  /** Population standard deviation of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Population standard deviation of delay across the matching connection */
  delay?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

export type TemplateStddevSampleAggregates = {
  __typename?: 'TemplateStddevSampleAggregates';
  /** Sample standard deviation of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Sample standard deviation of delay across the matching connection */
  delay?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

export type TemplateSumAggregates = {
  __typename?: 'TemplateSumAggregates';
  /** Sum of conversionScore across the matching connection */
  conversionScore: Scalars['Float']['output'];
  /** Sum of delay across the matching connection */
  delay: Scalars['BigInt']['output'];
  /** Sum of orderIndex across the matching connection */
  orderIndex: Scalars['BigInt']['output'];
};

export type TemplateVariancePopulationAggregates = {
  __typename?: 'TemplateVariancePopulationAggregates';
  /** Population variance of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Population variance of delay across the matching connection */
  delay?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

export type TemplateVarianceSampleAggregates = {
  __typename?: 'TemplateVarianceSampleAggregates';
  /** Sample variance of conversionScore across the matching connection */
  conversionScore?: Maybe<Scalars['Float']['output']>;
  /** Sample variance of delay across the matching connection */
  delay?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of orderIndex across the matching connection */
  orderIndex?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `Template` values. */
export type TemplatesConnection = {
  __typename?: 'TemplatesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TemplateAggregates>;
  /** A list of edges which contains the `Template` and cursor to aid in pagination. */
  edges: Array<TemplatesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TemplateAggregates>>;
  /** A list of `Template` objects. */
  nodes: Array<Maybe<Template>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Template` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Template` values. */
export type TemplatesConnectionGroupedAggregatesArgs = {
  groupBy: Array<TemplateGroupBy>;
  having?: InputMaybe<TemplateHavingInput>;
};

/** A `Template` edge in the connection. */
export type TemplatesEdge = {
  __typename?: 'TemplatesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Template` at the end of the edge. */
  node?: Maybe<Template>;
};

/** Methods to use when ordering `Template`. */
export enum TemplatesOrderBy {
  CampaignIdAsc = 'CAMPAIGN_ID_ASC',
  CampaignIdDesc = 'CAMPAIGN_ID_DESC',
  ContentAsc = 'CONTENT_ASC',
  ContentDesc = 'CONTENT_DESC',
  ConversionScoreAsc = 'CONVERSION_SCORE_ASC',
  ConversionScoreDesc = 'CONVERSION_SCORE_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  DailyStatisticsByTemplateIdAverageCampaignIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_CAMPAIGN_ID_ASC',
  DailyStatisticsByTemplateIdAverageCampaignIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_CAMPAIGN_ID_DESC',
  DailyStatisticsByTemplateIdAverageDateAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_DATE_ASC',
  DailyStatisticsByTemplateIdAverageDateDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_DATE_DESC',
  DailyStatisticsByTemplateIdAverageGroupNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_GROUP_NAME_ASC',
  DailyStatisticsByTemplateIdAverageGroupNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_GROUP_NAME_DESC',
  DailyStatisticsByTemplateIdAverageIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_ID_ASC',
  DailyStatisticsByTemplateIdAverageIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_ID_DESC',
  DailyStatisticsByTemplateIdAverageLawyerIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_LAWYER_ID_ASC',
  DailyStatisticsByTemplateIdAverageLawyerIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_LAWYER_ID_DESC',
  DailyStatisticsByTemplateIdAverageNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_NAME_ASC',
  DailyStatisticsByTemplateIdAverageNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_NAME_DESC',
  DailyStatisticsByTemplateIdAverageTemplateIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_TEMPLATE_ID_ASC',
  DailyStatisticsByTemplateIdAverageTemplateIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_TEMPLATE_ID_DESC',
  DailyStatisticsByTemplateIdAverageValueAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_VALUE_ASC',
  DailyStatisticsByTemplateIdAverageValueDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_AVERAGE_VALUE_DESC',
  DailyStatisticsByTemplateIdCountAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_COUNT_ASC',
  DailyStatisticsByTemplateIdCountDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_COUNT_DESC',
  DailyStatisticsByTemplateIdDistinctCountCampaignIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  DailyStatisticsByTemplateIdDistinctCountCampaignIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  DailyStatisticsByTemplateIdDistinctCountDateAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_DATE_ASC',
  DailyStatisticsByTemplateIdDistinctCountDateDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_DATE_DESC',
  DailyStatisticsByTemplateIdDistinctCountGroupNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_GROUP_NAME_ASC',
  DailyStatisticsByTemplateIdDistinctCountGroupNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_GROUP_NAME_DESC',
  DailyStatisticsByTemplateIdDistinctCountIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_ID_ASC',
  DailyStatisticsByTemplateIdDistinctCountIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_ID_DESC',
  DailyStatisticsByTemplateIdDistinctCountLawyerIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  DailyStatisticsByTemplateIdDistinctCountLawyerIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  DailyStatisticsByTemplateIdDistinctCountNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_NAME_ASC',
  DailyStatisticsByTemplateIdDistinctCountNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_NAME_DESC',
  DailyStatisticsByTemplateIdDistinctCountTemplateIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  DailyStatisticsByTemplateIdDistinctCountTemplateIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  DailyStatisticsByTemplateIdDistinctCountValueAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_VALUE_ASC',
  DailyStatisticsByTemplateIdDistinctCountValueDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_DISTINCT_COUNT_VALUE_DESC',
  DailyStatisticsByTemplateIdMaxCampaignIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_CAMPAIGN_ID_ASC',
  DailyStatisticsByTemplateIdMaxCampaignIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_CAMPAIGN_ID_DESC',
  DailyStatisticsByTemplateIdMaxDateAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_DATE_ASC',
  DailyStatisticsByTemplateIdMaxDateDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_DATE_DESC',
  DailyStatisticsByTemplateIdMaxGroupNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_GROUP_NAME_ASC',
  DailyStatisticsByTemplateIdMaxGroupNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_GROUP_NAME_DESC',
  DailyStatisticsByTemplateIdMaxIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_ID_ASC',
  DailyStatisticsByTemplateIdMaxIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_ID_DESC',
  DailyStatisticsByTemplateIdMaxLawyerIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_LAWYER_ID_ASC',
  DailyStatisticsByTemplateIdMaxLawyerIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_LAWYER_ID_DESC',
  DailyStatisticsByTemplateIdMaxNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_NAME_ASC',
  DailyStatisticsByTemplateIdMaxNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_NAME_DESC',
  DailyStatisticsByTemplateIdMaxTemplateIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_TEMPLATE_ID_ASC',
  DailyStatisticsByTemplateIdMaxTemplateIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_TEMPLATE_ID_DESC',
  DailyStatisticsByTemplateIdMaxValueAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_VALUE_ASC',
  DailyStatisticsByTemplateIdMaxValueDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MAX_VALUE_DESC',
  DailyStatisticsByTemplateIdMinCampaignIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_CAMPAIGN_ID_ASC',
  DailyStatisticsByTemplateIdMinCampaignIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_CAMPAIGN_ID_DESC',
  DailyStatisticsByTemplateIdMinDateAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_DATE_ASC',
  DailyStatisticsByTemplateIdMinDateDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_DATE_DESC',
  DailyStatisticsByTemplateIdMinGroupNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_GROUP_NAME_ASC',
  DailyStatisticsByTemplateIdMinGroupNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_GROUP_NAME_DESC',
  DailyStatisticsByTemplateIdMinIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_ID_ASC',
  DailyStatisticsByTemplateIdMinIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_ID_DESC',
  DailyStatisticsByTemplateIdMinLawyerIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_LAWYER_ID_ASC',
  DailyStatisticsByTemplateIdMinLawyerIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_LAWYER_ID_DESC',
  DailyStatisticsByTemplateIdMinNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_NAME_ASC',
  DailyStatisticsByTemplateIdMinNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_NAME_DESC',
  DailyStatisticsByTemplateIdMinTemplateIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_TEMPLATE_ID_ASC',
  DailyStatisticsByTemplateIdMinTemplateIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_TEMPLATE_ID_DESC',
  DailyStatisticsByTemplateIdMinValueAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_VALUE_ASC',
  DailyStatisticsByTemplateIdMinValueDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_MIN_VALUE_DESC',
  DailyStatisticsByTemplateIdStddevPopulationCampaignIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  DailyStatisticsByTemplateIdStddevPopulationCampaignIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  DailyStatisticsByTemplateIdStddevPopulationDateAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_DATE_ASC',
  DailyStatisticsByTemplateIdStddevPopulationDateDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_DATE_DESC',
  DailyStatisticsByTemplateIdStddevPopulationGroupNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_GROUP_NAME_ASC',
  DailyStatisticsByTemplateIdStddevPopulationGroupNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_GROUP_NAME_DESC',
  DailyStatisticsByTemplateIdStddevPopulationIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_ID_ASC',
  DailyStatisticsByTemplateIdStddevPopulationIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_ID_DESC',
  DailyStatisticsByTemplateIdStddevPopulationLawyerIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  DailyStatisticsByTemplateIdStddevPopulationLawyerIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  DailyStatisticsByTemplateIdStddevPopulationNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_NAME_ASC',
  DailyStatisticsByTemplateIdStddevPopulationNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_NAME_DESC',
  DailyStatisticsByTemplateIdStddevPopulationTemplateIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  DailyStatisticsByTemplateIdStddevPopulationTemplateIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  DailyStatisticsByTemplateIdStddevPopulationValueAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_VALUE_ASC',
  DailyStatisticsByTemplateIdStddevPopulationValueDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_POPULATION_VALUE_DESC',
  DailyStatisticsByTemplateIdStddevSampleCampaignIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  DailyStatisticsByTemplateIdStddevSampleCampaignIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  DailyStatisticsByTemplateIdStddevSampleDateAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_DATE_ASC',
  DailyStatisticsByTemplateIdStddevSampleDateDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_DATE_DESC',
  DailyStatisticsByTemplateIdStddevSampleGroupNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_GROUP_NAME_ASC',
  DailyStatisticsByTemplateIdStddevSampleGroupNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_GROUP_NAME_DESC',
  DailyStatisticsByTemplateIdStddevSampleIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_ID_ASC',
  DailyStatisticsByTemplateIdStddevSampleIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_ID_DESC',
  DailyStatisticsByTemplateIdStddevSampleLawyerIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  DailyStatisticsByTemplateIdStddevSampleLawyerIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  DailyStatisticsByTemplateIdStddevSampleNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_NAME_ASC',
  DailyStatisticsByTemplateIdStddevSampleNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_NAME_DESC',
  DailyStatisticsByTemplateIdStddevSampleTemplateIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  DailyStatisticsByTemplateIdStddevSampleTemplateIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  DailyStatisticsByTemplateIdStddevSampleValueAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_VALUE_ASC',
  DailyStatisticsByTemplateIdStddevSampleValueDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_STDDEV_SAMPLE_VALUE_DESC',
  DailyStatisticsByTemplateIdSumCampaignIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_CAMPAIGN_ID_ASC',
  DailyStatisticsByTemplateIdSumCampaignIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_CAMPAIGN_ID_DESC',
  DailyStatisticsByTemplateIdSumDateAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_DATE_ASC',
  DailyStatisticsByTemplateIdSumDateDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_DATE_DESC',
  DailyStatisticsByTemplateIdSumGroupNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_GROUP_NAME_ASC',
  DailyStatisticsByTemplateIdSumGroupNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_GROUP_NAME_DESC',
  DailyStatisticsByTemplateIdSumIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_ID_ASC',
  DailyStatisticsByTemplateIdSumIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_ID_DESC',
  DailyStatisticsByTemplateIdSumLawyerIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_LAWYER_ID_ASC',
  DailyStatisticsByTemplateIdSumLawyerIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_LAWYER_ID_DESC',
  DailyStatisticsByTemplateIdSumNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_NAME_ASC',
  DailyStatisticsByTemplateIdSumNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_NAME_DESC',
  DailyStatisticsByTemplateIdSumTemplateIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_TEMPLATE_ID_ASC',
  DailyStatisticsByTemplateIdSumTemplateIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_TEMPLATE_ID_DESC',
  DailyStatisticsByTemplateIdSumValueAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_VALUE_ASC',
  DailyStatisticsByTemplateIdSumValueDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_SUM_VALUE_DESC',
  DailyStatisticsByTemplateIdVariancePopulationCampaignIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  DailyStatisticsByTemplateIdVariancePopulationCampaignIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  DailyStatisticsByTemplateIdVariancePopulationDateAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_DATE_ASC',
  DailyStatisticsByTemplateIdVariancePopulationDateDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_DATE_DESC',
  DailyStatisticsByTemplateIdVariancePopulationGroupNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_GROUP_NAME_ASC',
  DailyStatisticsByTemplateIdVariancePopulationGroupNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_GROUP_NAME_DESC',
  DailyStatisticsByTemplateIdVariancePopulationIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_ID_ASC',
  DailyStatisticsByTemplateIdVariancePopulationIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_ID_DESC',
  DailyStatisticsByTemplateIdVariancePopulationLawyerIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  DailyStatisticsByTemplateIdVariancePopulationLawyerIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  DailyStatisticsByTemplateIdVariancePopulationNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_NAME_ASC',
  DailyStatisticsByTemplateIdVariancePopulationNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_NAME_DESC',
  DailyStatisticsByTemplateIdVariancePopulationTemplateIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  DailyStatisticsByTemplateIdVariancePopulationTemplateIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  DailyStatisticsByTemplateIdVariancePopulationValueAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_VALUE_ASC',
  DailyStatisticsByTemplateIdVariancePopulationValueDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_POPULATION_VALUE_DESC',
  DailyStatisticsByTemplateIdVarianceSampleCampaignIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  DailyStatisticsByTemplateIdVarianceSampleCampaignIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  DailyStatisticsByTemplateIdVarianceSampleDateAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_DATE_ASC',
  DailyStatisticsByTemplateIdVarianceSampleDateDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_DATE_DESC',
  DailyStatisticsByTemplateIdVarianceSampleGroupNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_GROUP_NAME_ASC',
  DailyStatisticsByTemplateIdVarianceSampleGroupNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_GROUP_NAME_DESC',
  DailyStatisticsByTemplateIdVarianceSampleIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_ID_ASC',
  DailyStatisticsByTemplateIdVarianceSampleIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_ID_DESC',
  DailyStatisticsByTemplateIdVarianceSampleLawyerIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  DailyStatisticsByTemplateIdVarianceSampleLawyerIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  DailyStatisticsByTemplateIdVarianceSampleNameAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_NAME_ASC',
  DailyStatisticsByTemplateIdVarianceSampleNameDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_NAME_DESC',
  DailyStatisticsByTemplateIdVarianceSampleTemplateIdAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  DailyStatisticsByTemplateIdVarianceSampleTemplateIdDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  DailyStatisticsByTemplateIdVarianceSampleValueAsc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_VALUE_ASC',
  DailyStatisticsByTemplateIdVarianceSampleValueDesc = 'DAILY_STATISTICS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_VALUE_DESC',
  DelayAsc = 'DELAY_ASC',
  DelayDesc = 'DELAY_DESC',
  EmailsByTemplateIdAverageCampaignMemberIdAsc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByTemplateIdAverageCampaignMemberIdDesc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByTemplateIdAverageContentAsc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_CONTENT_ASC',
  EmailsByTemplateIdAverageContentDesc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_CONTENT_DESC',
  EmailsByTemplateIdAverageCreatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_CREATED_TIME_ASC',
  EmailsByTemplateIdAverageCreatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_CREATED_TIME_DESC',
  EmailsByTemplateIdAverageIdAsc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_ID_ASC',
  EmailsByTemplateIdAverageIdDesc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_ID_DESC',
  EmailsByTemplateIdAverageSenderProfileIdAsc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  EmailsByTemplateIdAverageSenderProfileIdDesc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  EmailsByTemplateIdAverageSendAtAsc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_SEND_AT_ASC',
  EmailsByTemplateIdAverageSendAtDesc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_SEND_AT_DESC',
  EmailsByTemplateIdAverageSentAtAsc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_SENT_AT_ASC',
  EmailsByTemplateIdAverageSentAtDesc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_SENT_AT_DESC',
  EmailsByTemplateIdAverageSmtpMessageIdAsc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_SMTP_MESSAGE_ID_ASC',
  EmailsByTemplateIdAverageSmtpMessageIdDesc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_SMTP_MESSAGE_ID_DESC',
  EmailsByTemplateIdAverageStatusAsc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_STATUS_ASC',
  EmailsByTemplateIdAverageStatusDesc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_STATUS_DESC',
  EmailsByTemplateIdAverageTemplateContentAsc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_TEMPLATE_CONTENT_ASC',
  EmailsByTemplateIdAverageTemplateContentDesc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_TEMPLATE_CONTENT_DESC',
  EmailsByTemplateIdAverageTemplateIdAsc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_TEMPLATE_ID_ASC',
  EmailsByTemplateIdAverageTemplateIdDesc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_TEMPLATE_ID_DESC',
  EmailsByTemplateIdAverageUpdatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailsByTemplateIdAverageUpdatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailsByTemplateIdCountAsc = 'EMAILS_BY_TEMPLATE_ID_COUNT_ASC',
  EmailsByTemplateIdCountDesc = 'EMAILS_BY_TEMPLATE_ID_COUNT_DESC',
  EmailsByTemplateIdDistinctCountCampaignMemberIdAsc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByTemplateIdDistinctCountCampaignMemberIdDesc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByTemplateIdDistinctCountContentAsc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_CONTENT_ASC',
  EmailsByTemplateIdDistinctCountContentDesc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_CONTENT_DESC',
  EmailsByTemplateIdDistinctCountCreatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailsByTemplateIdDistinctCountCreatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailsByTemplateIdDistinctCountIdAsc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_ID_ASC',
  EmailsByTemplateIdDistinctCountIdDesc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_ID_DESC',
  EmailsByTemplateIdDistinctCountSenderProfileIdAsc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  EmailsByTemplateIdDistinctCountSenderProfileIdDesc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  EmailsByTemplateIdDistinctCountSendAtAsc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_SEND_AT_ASC',
  EmailsByTemplateIdDistinctCountSendAtDesc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_SEND_AT_DESC',
  EmailsByTemplateIdDistinctCountSentAtAsc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_SENT_AT_ASC',
  EmailsByTemplateIdDistinctCountSentAtDesc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_SENT_AT_DESC',
  EmailsByTemplateIdDistinctCountSmtpMessageIdAsc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_ASC',
  EmailsByTemplateIdDistinctCountSmtpMessageIdDesc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_DESC',
  EmailsByTemplateIdDistinctCountStatusAsc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_STATUS_ASC',
  EmailsByTemplateIdDistinctCountStatusDesc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_STATUS_DESC',
  EmailsByTemplateIdDistinctCountTemplateContentAsc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_TEMPLATE_CONTENT_ASC',
  EmailsByTemplateIdDistinctCountTemplateContentDesc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_TEMPLATE_CONTENT_DESC',
  EmailsByTemplateIdDistinctCountTemplateIdAsc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  EmailsByTemplateIdDistinctCountTemplateIdDesc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  EmailsByTemplateIdDistinctCountUpdatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailsByTemplateIdDistinctCountUpdatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailsByTemplateIdMaxCampaignMemberIdAsc = 'EMAILS_BY_TEMPLATE_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByTemplateIdMaxCampaignMemberIdDesc = 'EMAILS_BY_TEMPLATE_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByTemplateIdMaxContentAsc = 'EMAILS_BY_TEMPLATE_ID_MAX_CONTENT_ASC',
  EmailsByTemplateIdMaxContentDesc = 'EMAILS_BY_TEMPLATE_ID_MAX_CONTENT_DESC',
  EmailsByTemplateIdMaxCreatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_MAX_CREATED_TIME_ASC',
  EmailsByTemplateIdMaxCreatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_MAX_CREATED_TIME_DESC',
  EmailsByTemplateIdMaxIdAsc = 'EMAILS_BY_TEMPLATE_ID_MAX_ID_ASC',
  EmailsByTemplateIdMaxIdDesc = 'EMAILS_BY_TEMPLATE_ID_MAX_ID_DESC',
  EmailsByTemplateIdMaxSenderProfileIdAsc = 'EMAILS_BY_TEMPLATE_ID_MAX_SENDER_PROFILE_ID_ASC',
  EmailsByTemplateIdMaxSenderProfileIdDesc = 'EMAILS_BY_TEMPLATE_ID_MAX_SENDER_PROFILE_ID_DESC',
  EmailsByTemplateIdMaxSendAtAsc = 'EMAILS_BY_TEMPLATE_ID_MAX_SEND_AT_ASC',
  EmailsByTemplateIdMaxSendAtDesc = 'EMAILS_BY_TEMPLATE_ID_MAX_SEND_AT_DESC',
  EmailsByTemplateIdMaxSentAtAsc = 'EMAILS_BY_TEMPLATE_ID_MAX_SENT_AT_ASC',
  EmailsByTemplateIdMaxSentAtDesc = 'EMAILS_BY_TEMPLATE_ID_MAX_SENT_AT_DESC',
  EmailsByTemplateIdMaxSmtpMessageIdAsc = 'EMAILS_BY_TEMPLATE_ID_MAX_SMTP_MESSAGE_ID_ASC',
  EmailsByTemplateIdMaxSmtpMessageIdDesc = 'EMAILS_BY_TEMPLATE_ID_MAX_SMTP_MESSAGE_ID_DESC',
  EmailsByTemplateIdMaxStatusAsc = 'EMAILS_BY_TEMPLATE_ID_MAX_STATUS_ASC',
  EmailsByTemplateIdMaxStatusDesc = 'EMAILS_BY_TEMPLATE_ID_MAX_STATUS_DESC',
  EmailsByTemplateIdMaxTemplateContentAsc = 'EMAILS_BY_TEMPLATE_ID_MAX_TEMPLATE_CONTENT_ASC',
  EmailsByTemplateIdMaxTemplateContentDesc = 'EMAILS_BY_TEMPLATE_ID_MAX_TEMPLATE_CONTENT_DESC',
  EmailsByTemplateIdMaxTemplateIdAsc = 'EMAILS_BY_TEMPLATE_ID_MAX_TEMPLATE_ID_ASC',
  EmailsByTemplateIdMaxTemplateIdDesc = 'EMAILS_BY_TEMPLATE_ID_MAX_TEMPLATE_ID_DESC',
  EmailsByTemplateIdMaxUpdatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_MAX_UPDATED_TIME_ASC',
  EmailsByTemplateIdMaxUpdatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_MAX_UPDATED_TIME_DESC',
  EmailsByTemplateIdMinCampaignMemberIdAsc = 'EMAILS_BY_TEMPLATE_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByTemplateIdMinCampaignMemberIdDesc = 'EMAILS_BY_TEMPLATE_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByTemplateIdMinContentAsc = 'EMAILS_BY_TEMPLATE_ID_MIN_CONTENT_ASC',
  EmailsByTemplateIdMinContentDesc = 'EMAILS_BY_TEMPLATE_ID_MIN_CONTENT_DESC',
  EmailsByTemplateIdMinCreatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_MIN_CREATED_TIME_ASC',
  EmailsByTemplateIdMinCreatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_MIN_CREATED_TIME_DESC',
  EmailsByTemplateIdMinIdAsc = 'EMAILS_BY_TEMPLATE_ID_MIN_ID_ASC',
  EmailsByTemplateIdMinIdDesc = 'EMAILS_BY_TEMPLATE_ID_MIN_ID_DESC',
  EmailsByTemplateIdMinSenderProfileIdAsc = 'EMAILS_BY_TEMPLATE_ID_MIN_SENDER_PROFILE_ID_ASC',
  EmailsByTemplateIdMinSenderProfileIdDesc = 'EMAILS_BY_TEMPLATE_ID_MIN_SENDER_PROFILE_ID_DESC',
  EmailsByTemplateIdMinSendAtAsc = 'EMAILS_BY_TEMPLATE_ID_MIN_SEND_AT_ASC',
  EmailsByTemplateIdMinSendAtDesc = 'EMAILS_BY_TEMPLATE_ID_MIN_SEND_AT_DESC',
  EmailsByTemplateIdMinSentAtAsc = 'EMAILS_BY_TEMPLATE_ID_MIN_SENT_AT_ASC',
  EmailsByTemplateIdMinSentAtDesc = 'EMAILS_BY_TEMPLATE_ID_MIN_SENT_AT_DESC',
  EmailsByTemplateIdMinSmtpMessageIdAsc = 'EMAILS_BY_TEMPLATE_ID_MIN_SMTP_MESSAGE_ID_ASC',
  EmailsByTemplateIdMinSmtpMessageIdDesc = 'EMAILS_BY_TEMPLATE_ID_MIN_SMTP_MESSAGE_ID_DESC',
  EmailsByTemplateIdMinStatusAsc = 'EMAILS_BY_TEMPLATE_ID_MIN_STATUS_ASC',
  EmailsByTemplateIdMinStatusDesc = 'EMAILS_BY_TEMPLATE_ID_MIN_STATUS_DESC',
  EmailsByTemplateIdMinTemplateContentAsc = 'EMAILS_BY_TEMPLATE_ID_MIN_TEMPLATE_CONTENT_ASC',
  EmailsByTemplateIdMinTemplateContentDesc = 'EMAILS_BY_TEMPLATE_ID_MIN_TEMPLATE_CONTENT_DESC',
  EmailsByTemplateIdMinTemplateIdAsc = 'EMAILS_BY_TEMPLATE_ID_MIN_TEMPLATE_ID_ASC',
  EmailsByTemplateIdMinTemplateIdDesc = 'EMAILS_BY_TEMPLATE_ID_MIN_TEMPLATE_ID_DESC',
  EmailsByTemplateIdMinUpdatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_MIN_UPDATED_TIME_ASC',
  EmailsByTemplateIdMinUpdatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_MIN_UPDATED_TIME_DESC',
  EmailsByTemplateIdStddevPopulationCampaignMemberIdAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByTemplateIdStddevPopulationCampaignMemberIdDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByTemplateIdStddevPopulationContentAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_CONTENT_ASC',
  EmailsByTemplateIdStddevPopulationContentDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_CONTENT_DESC',
  EmailsByTemplateIdStddevPopulationCreatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailsByTemplateIdStddevPopulationCreatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailsByTemplateIdStddevPopulationIdAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_ID_ASC',
  EmailsByTemplateIdStddevPopulationIdDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_ID_DESC',
  EmailsByTemplateIdStddevPopulationSenderProfileIdAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailsByTemplateIdStddevPopulationSenderProfileIdDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailsByTemplateIdStddevPopulationSendAtAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_SEND_AT_ASC',
  EmailsByTemplateIdStddevPopulationSendAtDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_SEND_AT_DESC',
  EmailsByTemplateIdStddevPopulationSentAtAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_SENT_AT_ASC',
  EmailsByTemplateIdStddevPopulationSentAtDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_SENT_AT_DESC',
  EmailsByTemplateIdStddevPopulationSmtpMessageIdAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailsByTemplateIdStddevPopulationSmtpMessageIdDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailsByTemplateIdStddevPopulationStatusAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_STATUS_ASC',
  EmailsByTemplateIdStddevPopulationStatusDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_STATUS_DESC',
  EmailsByTemplateIdStddevPopulationTemplateContentAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_TEMPLATE_CONTENT_ASC',
  EmailsByTemplateIdStddevPopulationTemplateContentDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_TEMPLATE_CONTENT_DESC',
  EmailsByTemplateIdStddevPopulationTemplateIdAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  EmailsByTemplateIdStddevPopulationTemplateIdDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  EmailsByTemplateIdStddevPopulationUpdatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailsByTemplateIdStddevPopulationUpdatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailsByTemplateIdStddevSampleCampaignMemberIdAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByTemplateIdStddevSampleCampaignMemberIdDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByTemplateIdStddevSampleContentAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CONTENT_ASC',
  EmailsByTemplateIdStddevSampleContentDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CONTENT_DESC',
  EmailsByTemplateIdStddevSampleCreatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailsByTemplateIdStddevSampleCreatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailsByTemplateIdStddevSampleIdAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_ID_ASC',
  EmailsByTemplateIdStddevSampleIdDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_ID_DESC',
  EmailsByTemplateIdStddevSampleSenderProfileIdAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailsByTemplateIdStddevSampleSenderProfileIdDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailsByTemplateIdStddevSampleSendAtAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_SEND_AT_ASC',
  EmailsByTemplateIdStddevSampleSendAtDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_SEND_AT_DESC',
  EmailsByTemplateIdStddevSampleSentAtAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_SENT_AT_ASC',
  EmailsByTemplateIdStddevSampleSentAtDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_SENT_AT_DESC',
  EmailsByTemplateIdStddevSampleSmtpMessageIdAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailsByTemplateIdStddevSampleSmtpMessageIdDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailsByTemplateIdStddevSampleStatusAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_STATUS_ASC',
  EmailsByTemplateIdStddevSampleStatusDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_STATUS_DESC',
  EmailsByTemplateIdStddevSampleTemplateContentAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_TEMPLATE_CONTENT_ASC',
  EmailsByTemplateIdStddevSampleTemplateContentDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_TEMPLATE_CONTENT_DESC',
  EmailsByTemplateIdStddevSampleTemplateIdAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  EmailsByTemplateIdStddevSampleTemplateIdDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  EmailsByTemplateIdStddevSampleUpdatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailsByTemplateIdStddevSampleUpdatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailsByTemplateIdSumCampaignMemberIdAsc = 'EMAILS_BY_TEMPLATE_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByTemplateIdSumCampaignMemberIdDesc = 'EMAILS_BY_TEMPLATE_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByTemplateIdSumContentAsc = 'EMAILS_BY_TEMPLATE_ID_SUM_CONTENT_ASC',
  EmailsByTemplateIdSumContentDesc = 'EMAILS_BY_TEMPLATE_ID_SUM_CONTENT_DESC',
  EmailsByTemplateIdSumCreatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_SUM_CREATED_TIME_ASC',
  EmailsByTemplateIdSumCreatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_SUM_CREATED_TIME_DESC',
  EmailsByTemplateIdSumIdAsc = 'EMAILS_BY_TEMPLATE_ID_SUM_ID_ASC',
  EmailsByTemplateIdSumIdDesc = 'EMAILS_BY_TEMPLATE_ID_SUM_ID_DESC',
  EmailsByTemplateIdSumSenderProfileIdAsc = 'EMAILS_BY_TEMPLATE_ID_SUM_SENDER_PROFILE_ID_ASC',
  EmailsByTemplateIdSumSenderProfileIdDesc = 'EMAILS_BY_TEMPLATE_ID_SUM_SENDER_PROFILE_ID_DESC',
  EmailsByTemplateIdSumSendAtAsc = 'EMAILS_BY_TEMPLATE_ID_SUM_SEND_AT_ASC',
  EmailsByTemplateIdSumSendAtDesc = 'EMAILS_BY_TEMPLATE_ID_SUM_SEND_AT_DESC',
  EmailsByTemplateIdSumSentAtAsc = 'EMAILS_BY_TEMPLATE_ID_SUM_SENT_AT_ASC',
  EmailsByTemplateIdSumSentAtDesc = 'EMAILS_BY_TEMPLATE_ID_SUM_SENT_AT_DESC',
  EmailsByTemplateIdSumSmtpMessageIdAsc = 'EMAILS_BY_TEMPLATE_ID_SUM_SMTP_MESSAGE_ID_ASC',
  EmailsByTemplateIdSumSmtpMessageIdDesc = 'EMAILS_BY_TEMPLATE_ID_SUM_SMTP_MESSAGE_ID_DESC',
  EmailsByTemplateIdSumStatusAsc = 'EMAILS_BY_TEMPLATE_ID_SUM_STATUS_ASC',
  EmailsByTemplateIdSumStatusDesc = 'EMAILS_BY_TEMPLATE_ID_SUM_STATUS_DESC',
  EmailsByTemplateIdSumTemplateContentAsc = 'EMAILS_BY_TEMPLATE_ID_SUM_TEMPLATE_CONTENT_ASC',
  EmailsByTemplateIdSumTemplateContentDesc = 'EMAILS_BY_TEMPLATE_ID_SUM_TEMPLATE_CONTENT_DESC',
  EmailsByTemplateIdSumTemplateIdAsc = 'EMAILS_BY_TEMPLATE_ID_SUM_TEMPLATE_ID_ASC',
  EmailsByTemplateIdSumTemplateIdDesc = 'EMAILS_BY_TEMPLATE_ID_SUM_TEMPLATE_ID_DESC',
  EmailsByTemplateIdSumUpdatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_SUM_UPDATED_TIME_ASC',
  EmailsByTemplateIdSumUpdatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_SUM_UPDATED_TIME_DESC',
  EmailsByTemplateIdVariancePopulationCampaignMemberIdAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByTemplateIdVariancePopulationCampaignMemberIdDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByTemplateIdVariancePopulationContentAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CONTENT_ASC',
  EmailsByTemplateIdVariancePopulationContentDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CONTENT_DESC',
  EmailsByTemplateIdVariancePopulationCreatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailsByTemplateIdVariancePopulationCreatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailsByTemplateIdVariancePopulationIdAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_ID_ASC',
  EmailsByTemplateIdVariancePopulationIdDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_ID_DESC',
  EmailsByTemplateIdVariancePopulationSenderProfileIdAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailsByTemplateIdVariancePopulationSenderProfileIdDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailsByTemplateIdVariancePopulationSendAtAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_SEND_AT_ASC',
  EmailsByTemplateIdVariancePopulationSendAtDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_SEND_AT_DESC',
  EmailsByTemplateIdVariancePopulationSentAtAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_SENT_AT_ASC',
  EmailsByTemplateIdVariancePopulationSentAtDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_SENT_AT_DESC',
  EmailsByTemplateIdVariancePopulationSmtpMessageIdAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailsByTemplateIdVariancePopulationSmtpMessageIdDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailsByTemplateIdVariancePopulationStatusAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_STATUS_ASC',
  EmailsByTemplateIdVariancePopulationStatusDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_STATUS_DESC',
  EmailsByTemplateIdVariancePopulationTemplateContentAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_TEMPLATE_CONTENT_ASC',
  EmailsByTemplateIdVariancePopulationTemplateContentDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_TEMPLATE_CONTENT_DESC',
  EmailsByTemplateIdVariancePopulationTemplateIdAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  EmailsByTemplateIdVariancePopulationTemplateIdDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  EmailsByTemplateIdVariancePopulationUpdatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailsByTemplateIdVariancePopulationUpdatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailsByTemplateIdVarianceSampleCampaignMemberIdAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailsByTemplateIdVarianceSampleCampaignMemberIdDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailsByTemplateIdVarianceSampleContentAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  EmailsByTemplateIdVarianceSampleContentDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  EmailsByTemplateIdVarianceSampleCreatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailsByTemplateIdVarianceSampleCreatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailsByTemplateIdVarianceSampleIdAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailsByTemplateIdVarianceSampleIdDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailsByTemplateIdVarianceSampleSenderProfileIdAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailsByTemplateIdVarianceSampleSenderProfileIdDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailsByTemplateIdVarianceSampleSendAtAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SEND_AT_ASC',
  EmailsByTemplateIdVarianceSampleSendAtDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SEND_AT_DESC',
  EmailsByTemplateIdVarianceSampleSentAtAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SENT_AT_ASC',
  EmailsByTemplateIdVarianceSampleSentAtDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SENT_AT_DESC',
  EmailsByTemplateIdVarianceSampleSmtpMessageIdAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailsByTemplateIdVarianceSampleSmtpMessageIdDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailsByTemplateIdVarianceSampleStatusAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_STATUS_ASC',
  EmailsByTemplateIdVarianceSampleStatusDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_STATUS_DESC',
  EmailsByTemplateIdVarianceSampleTemplateContentAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_TEMPLATE_CONTENT_ASC',
  EmailsByTemplateIdVarianceSampleTemplateContentDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_TEMPLATE_CONTENT_DESC',
  EmailsByTemplateIdVarianceSampleTemplateIdAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  EmailsByTemplateIdVarianceSampleTemplateIdDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  EmailsByTemplateIdVarianceSampleUpdatedTimeAsc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailsByTemplateIdVarianceSampleUpdatedTimeDesc = 'EMAILS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  EmailMessagesByTemplateIdAverageAutoRepliedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_AUTO_REPLIED_ASC',
  EmailMessagesByTemplateIdAverageAutoRepliedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_AUTO_REPLIED_DESC',
  EmailMessagesByTemplateIdAverageCampaignIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CAMPAIGN_ID_ASC',
  EmailMessagesByTemplateIdAverageCampaignIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CAMPAIGN_ID_DESC',
  EmailMessagesByTemplateIdAverageCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByTemplateIdAverageCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByTemplateIdAverageCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdAverageCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdAverageCompanyIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_COMPANY_ID_ASC',
  EmailMessagesByTemplateIdAverageCompanyIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_COMPANY_ID_DESC',
  EmailMessagesByTemplateIdAverageContactIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CONTACT_ID_ASC',
  EmailMessagesByTemplateIdAverageContactIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CONTACT_ID_DESC',
  EmailMessagesByTemplateIdAverageContentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CONTENT_ASC',
  EmailMessagesByTemplateIdAverageContentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CONTENT_DESC',
  EmailMessagesByTemplateIdAverageContentHtmlAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CONTENT_HTML_ASC',
  EmailMessagesByTemplateIdAverageContentHtmlDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CONTENT_HTML_DESC',
  EmailMessagesByTemplateIdAverageCreatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CREATED_TIME_ASC',
  EmailMessagesByTemplateIdAverageCreatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_CREATED_TIME_DESC',
  EmailMessagesByTemplateIdAverageEmailIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_EMAIL_ID_ASC',
  EmailMessagesByTemplateIdAverageEmailIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_EMAIL_ID_DESC',
  EmailMessagesByTemplateIdAverageForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByTemplateIdAverageForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByTemplateIdAverageForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdAverageForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdAverageGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_GENERATED_REPLY_ASC',
  EmailMessagesByTemplateIdAverageGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_GENERATED_REPLY_DESC',
  EmailMessagesByTemplateIdAverageHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdAverageHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdAverageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_ID_ASC',
  EmailMessagesByTemplateIdAverageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_ID_DESC',
  EmailMessagesByTemplateIdAverageIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_INTENT_ASC',
  EmailMessagesByTemplateIdAverageIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_INTENT_DESC',
  EmailMessagesByTemplateIdAverageIsLawyerAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_IS_LAWYER_ASC',
  EmailMessagesByTemplateIdAverageIsLawyerDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_IS_LAWYER_DESC',
  EmailMessagesByTemplateIdAverageIsReviewedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_IS_REVIEWED_ASC',
  EmailMessagesByTemplateIdAverageIsReviewedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_IS_REVIEWED_DESC',
  EmailMessagesByTemplateIdAverageLawyerIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_LAWYER_ID_ASC',
  EmailMessagesByTemplateIdAverageLawyerIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_LAWYER_ID_DESC',
  EmailMessagesByTemplateIdAverageLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByTemplateIdAverageLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByTemplateIdAverageNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByTemplateIdAverageNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByTemplateIdAverageOrderIndexAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_ORDER_INDEX_ASC',
  EmailMessagesByTemplateIdAverageOrderIndexDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_ORDER_INDEX_DESC',
  EmailMessagesByTemplateIdAveragePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByTemplateIdAveragePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByTemplateIdAverageRepliedIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_REPLIED_INTENT_ASC',
  EmailMessagesByTemplateIdAverageRepliedIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_REPLIED_INTENT_DESC',
  EmailMessagesByTemplateIdAverageRepliedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_REPLIED_TIME_ASC',
  EmailMessagesByTemplateIdAverageRepliedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_REPLIED_TIME_DESC',
  EmailMessagesByTemplateIdAverageRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdAverageRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdAverageReviewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_REVIEWED_TIME_ASC',
  EmailMessagesByTemplateIdAverageReviewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_REVIEWED_TIME_DESC',
  EmailMessagesByTemplateIdAverageSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByTemplateIdAverageSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByTemplateIdAverageSentTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_SENT_TIME_ASC',
  EmailMessagesByTemplateIdAverageSentTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_SENT_TIME_DESC',
  EmailMessagesByTemplateIdAverageSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdAverageSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdAverageSubjectAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_SUBJECT_ASC',
  EmailMessagesByTemplateIdAverageSubjectDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_SUBJECT_DESC',
  EmailMessagesByTemplateIdAverageTemplateIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_TEMPLATE_ID_ASC',
  EmailMessagesByTemplateIdAverageTemplateIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_TEMPLATE_ID_DESC',
  EmailMessagesByTemplateIdAverageUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_UPDATED_TIME_ASC',
  EmailMessagesByTemplateIdAverageUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_AVERAGE_UPDATED_TIME_DESC',
  EmailMessagesByTemplateIdCountAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_COUNT_ASC',
  EmailMessagesByTemplateIdCountDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_COUNT_DESC',
  EmailMessagesByTemplateIdDistinctCountAutoRepliedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_AUTO_REPLIED_ASC',
  EmailMessagesByTemplateIdDistinctCountAutoRepliedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_AUTO_REPLIED_DESC',
  EmailMessagesByTemplateIdDistinctCountCampaignIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CAMPAIGN_ID_ASC',
  EmailMessagesByTemplateIdDistinctCountCampaignIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CAMPAIGN_ID_DESC',
  EmailMessagesByTemplateIdDistinctCountCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByTemplateIdDistinctCountCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByTemplateIdDistinctCountCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdDistinctCountCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdDistinctCountCompanyIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_COMPANY_ID_ASC',
  EmailMessagesByTemplateIdDistinctCountCompanyIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_COMPANY_ID_DESC',
  EmailMessagesByTemplateIdDistinctCountContactIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CONTACT_ID_ASC',
  EmailMessagesByTemplateIdDistinctCountContactIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CONTACT_ID_DESC',
  EmailMessagesByTemplateIdDistinctCountContentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CONTENT_ASC',
  EmailMessagesByTemplateIdDistinctCountContentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CONTENT_DESC',
  EmailMessagesByTemplateIdDistinctCountContentHtmlAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CONTENT_HTML_ASC',
  EmailMessagesByTemplateIdDistinctCountContentHtmlDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CONTENT_HTML_DESC',
  EmailMessagesByTemplateIdDistinctCountCreatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  EmailMessagesByTemplateIdDistinctCountCreatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  EmailMessagesByTemplateIdDistinctCountEmailIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_EMAIL_ID_ASC',
  EmailMessagesByTemplateIdDistinctCountEmailIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_EMAIL_ID_DESC',
  EmailMessagesByTemplateIdDistinctCountForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByTemplateIdDistinctCountForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByTemplateIdDistinctCountForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdDistinctCountForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdDistinctCountGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_GENERATED_REPLY_ASC',
  EmailMessagesByTemplateIdDistinctCountGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_GENERATED_REPLY_DESC',
  EmailMessagesByTemplateIdDistinctCountHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdDistinctCountHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdDistinctCountIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_ID_ASC',
  EmailMessagesByTemplateIdDistinctCountIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_ID_DESC',
  EmailMessagesByTemplateIdDistinctCountIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_INTENT_ASC',
  EmailMessagesByTemplateIdDistinctCountIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_INTENT_DESC',
  EmailMessagesByTemplateIdDistinctCountIsLawyerAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_IS_LAWYER_ASC',
  EmailMessagesByTemplateIdDistinctCountIsLawyerDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_IS_LAWYER_DESC',
  EmailMessagesByTemplateIdDistinctCountIsReviewedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_IS_REVIEWED_ASC',
  EmailMessagesByTemplateIdDistinctCountIsReviewedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_IS_REVIEWED_DESC',
  EmailMessagesByTemplateIdDistinctCountLawyerIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_LAWYER_ID_ASC',
  EmailMessagesByTemplateIdDistinctCountLawyerIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_LAWYER_ID_DESC',
  EmailMessagesByTemplateIdDistinctCountLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByTemplateIdDistinctCountLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByTemplateIdDistinctCountNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_ASC',
  EmailMessagesByTemplateIdDistinctCountNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_NYLAS_THREAD_ID_DESC',
  EmailMessagesByTemplateIdDistinctCountOrderIndexAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_ORDER_INDEX_ASC',
  EmailMessagesByTemplateIdDistinctCountOrderIndexDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_ORDER_INDEX_DESC',
  EmailMessagesByTemplateIdDistinctCountPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByTemplateIdDistinctCountPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByTemplateIdDistinctCountRepliedIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_REPLIED_INTENT_ASC',
  EmailMessagesByTemplateIdDistinctCountRepliedIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_REPLIED_INTENT_DESC',
  EmailMessagesByTemplateIdDistinctCountRepliedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_REPLIED_TIME_ASC',
  EmailMessagesByTemplateIdDistinctCountRepliedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_REPLIED_TIME_DESC',
  EmailMessagesByTemplateIdDistinctCountRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdDistinctCountRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdDistinctCountReviewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_REVIEWED_TIME_ASC',
  EmailMessagesByTemplateIdDistinctCountReviewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_REVIEWED_TIME_DESC',
  EmailMessagesByTemplateIdDistinctCountSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_ASC',
  EmailMessagesByTemplateIdDistinctCountSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_SENDER_PROFILE_ID_DESC',
  EmailMessagesByTemplateIdDistinctCountSentTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_SENT_TIME_ASC',
  EmailMessagesByTemplateIdDistinctCountSentTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_SENT_TIME_DESC',
  EmailMessagesByTemplateIdDistinctCountSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdDistinctCountSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdDistinctCountSubjectAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_SUBJECT_ASC',
  EmailMessagesByTemplateIdDistinctCountSubjectDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_SUBJECT_DESC',
  EmailMessagesByTemplateIdDistinctCountTemplateIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  EmailMessagesByTemplateIdDistinctCountTemplateIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  EmailMessagesByTemplateIdDistinctCountUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_UPDATED_TIME_ASC',
  EmailMessagesByTemplateIdDistinctCountUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_DISTINCT_COUNT_UPDATED_TIME_DESC',
  EmailMessagesByTemplateIdMaxAutoRepliedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_AUTO_REPLIED_ASC',
  EmailMessagesByTemplateIdMaxAutoRepliedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_AUTO_REPLIED_DESC',
  EmailMessagesByTemplateIdMaxCampaignIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CAMPAIGN_ID_ASC',
  EmailMessagesByTemplateIdMaxCampaignIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CAMPAIGN_ID_DESC',
  EmailMessagesByTemplateIdMaxCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByTemplateIdMaxCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByTemplateIdMaxCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdMaxCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdMaxCompanyIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_COMPANY_ID_ASC',
  EmailMessagesByTemplateIdMaxCompanyIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_COMPANY_ID_DESC',
  EmailMessagesByTemplateIdMaxContactIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CONTACT_ID_ASC',
  EmailMessagesByTemplateIdMaxContactIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CONTACT_ID_DESC',
  EmailMessagesByTemplateIdMaxContentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CONTENT_ASC',
  EmailMessagesByTemplateIdMaxContentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CONTENT_DESC',
  EmailMessagesByTemplateIdMaxContentHtmlAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CONTENT_HTML_ASC',
  EmailMessagesByTemplateIdMaxContentHtmlDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CONTENT_HTML_DESC',
  EmailMessagesByTemplateIdMaxCreatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CREATED_TIME_ASC',
  EmailMessagesByTemplateIdMaxCreatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_CREATED_TIME_DESC',
  EmailMessagesByTemplateIdMaxEmailIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_EMAIL_ID_ASC',
  EmailMessagesByTemplateIdMaxEmailIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_EMAIL_ID_DESC',
  EmailMessagesByTemplateIdMaxForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByTemplateIdMaxForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByTemplateIdMaxForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdMaxForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdMaxGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_GENERATED_REPLY_ASC',
  EmailMessagesByTemplateIdMaxGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_GENERATED_REPLY_DESC',
  EmailMessagesByTemplateIdMaxHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdMaxHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdMaxIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_ID_ASC',
  EmailMessagesByTemplateIdMaxIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_ID_DESC',
  EmailMessagesByTemplateIdMaxIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_INTENT_ASC',
  EmailMessagesByTemplateIdMaxIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_INTENT_DESC',
  EmailMessagesByTemplateIdMaxIsLawyerAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_IS_LAWYER_ASC',
  EmailMessagesByTemplateIdMaxIsLawyerDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_IS_LAWYER_DESC',
  EmailMessagesByTemplateIdMaxIsReviewedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_IS_REVIEWED_ASC',
  EmailMessagesByTemplateIdMaxIsReviewedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_IS_REVIEWED_DESC',
  EmailMessagesByTemplateIdMaxLawyerIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_LAWYER_ID_ASC',
  EmailMessagesByTemplateIdMaxLawyerIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_LAWYER_ID_DESC',
  EmailMessagesByTemplateIdMaxLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByTemplateIdMaxLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByTemplateIdMaxNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_NYLAS_THREAD_ID_ASC',
  EmailMessagesByTemplateIdMaxNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_NYLAS_THREAD_ID_DESC',
  EmailMessagesByTemplateIdMaxOrderIndexAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_ORDER_INDEX_ASC',
  EmailMessagesByTemplateIdMaxOrderIndexDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_ORDER_INDEX_DESC',
  EmailMessagesByTemplateIdMaxPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByTemplateIdMaxPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByTemplateIdMaxRepliedIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_REPLIED_INTENT_ASC',
  EmailMessagesByTemplateIdMaxRepliedIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_REPLIED_INTENT_DESC',
  EmailMessagesByTemplateIdMaxRepliedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_REPLIED_TIME_ASC',
  EmailMessagesByTemplateIdMaxRepliedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_REPLIED_TIME_DESC',
  EmailMessagesByTemplateIdMaxRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdMaxRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdMaxReviewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_REVIEWED_TIME_ASC',
  EmailMessagesByTemplateIdMaxReviewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_REVIEWED_TIME_DESC',
  EmailMessagesByTemplateIdMaxSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_SENDER_PROFILE_ID_ASC',
  EmailMessagesByTemplateIdMaxSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_SENDER_PROFILE_ID_DESC',
  EmailMessagesByTemplateIdMaxSentTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_SENT_TIME_ASC',
  EmailMessagesByTemplateIdMaxSentTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_SENT_TIME_DESC',
  EmailMessagesByTemplateIdMaxSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdMaxSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdMaxSubjectAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_SUBJECT_ASC',
  EmailMessagesByTemplateIdMaxSubjectDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_SUBJECT_DESC',
  EmailMessagesByTemplateIdMaxTemplateIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_TEMPLATE_ID_ASC',
  EmailMessagesByTemplateIdMaxTemplateIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_TEMPLATE_ID_DESC',
  EmailMessagesByTemplateIdMaxUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_UPDATED_TIME_ASC',
  EmailMessagesByTemplateIdMaxUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MAX_UPDATED_TIME_DESC',
  EmailMessagesByTemplateIdMinAutoRepliedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_AUTO_REPLIED_ASC',
  EmailMessagesByTemplateIdMinAutoRepliedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_AUTO_REPLIED_DESC',
  EmailMessagesByTemplateIdMinCampaignIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CAMPAIGN_ID_ASC',
  EmailMessagesByTemplateIdMinCampaignIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CAMPAIGN_ID_DESC',
  EmailMessagesByTemplateIdMinCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByTemplateIdMinCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByTemplateIdMinCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdMinCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdMinCompanyIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_COMPANY_ID_ASC',
  EmailMessagesByTemplateIdMinCompanyIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_COMPANY_ID_DESC',
  EmailMessagesByTemplateIdMinContactIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CONTACT_ID_ASC',
  EmailMessagesByTemplateIdMinContactIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CONTACT_ID_DESC',
  EmailMessagesByTemplateIdMinContentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CONTENT_ASC',
  EmailMessagesByTemplateIdMinContentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CONTENT_DESC',
  EmailMessagesByTemplateIdMinContentHtmlAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CONTENT_HTML_ASC',
  EmailMessagesByTemplateIdMinContentHtmlDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CONTENT_HTML_DESC',
  EmailMessagesByTemplateIdMinCreatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CREATED_TIME_ASC',
  EmailMessagesByTemplateIdMinCreatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_CREATED_TIME_DESC',
  EmailMessagesByTemplateIdMinEmailIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_EMAIL_ID_ASC',
  EmailMessagesByTemplateIdMinEmailIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_EMAIL_ID_DESC',
  EmailMessagesByTemplateIdMinForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByTemplateIdMinForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByTemplateIdMinForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdMinForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdMinGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_GENERATED_REPLY_ASC',
  EmailMessagesByTemplateIdMinGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_GENERATED_REPLY_DESC',
  EmailMessagesByTemplateIdMinHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdMinHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdMinIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_ID_ASC',
  EmailMessagesByTemplateIdMinIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_ID_DESC',
  EmailMessagesByTemplateIdMinIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_INTENT_ASC',
  EmailMessagesByTemplateIdMinIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_INTENT_DESC',
  EmailMessagesByTemplateIdMinIsLawyerAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_IS_LAWYER_ASC',
  EmailMessagesByTemplateIdMinIsLawyerDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_IS_LAWYER_DESC',
  EmailMessagesByTemplateIdMinIsReviewedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_IS_REVIEWED_ASC',
  EmailMessagesByTemplateIdMinIsReviewedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_IS_REVIEWED_DESC',
  EmailMessagesByTemplateIdMinLawyerIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_LAWYER_ID_ASC',
  EmailMessagesByTemplateIdMinLawyerIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_LAWYER_ID_DESC',
  EmailMessagesByTemplateIdMinLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByTemplateIdMinLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByTemplateIdMinNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_NYLAS_THREAD_ID_ASC',
  EmailMessagesByTemplateIdMinNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_NYLAS_THREAD_ID_DESC',
  EmailMessagesByTemplateIdMinOrderIndexAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_ORDER_INDEX_ASC',
  EmailMessagesByTemplateIdMinOrderIndexDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_ORDER_INDEX_DESC',
  EmailMessagesByTemplateIdMinPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByTemplateIdMinPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByTemplateIdMinRepliedIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_REPLIED_INTENT_ASC',
  EmailMessagesByTemplateIdMinRepliedIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_REPLIED_INTENT_DESC',
  EmailMessagesByTemplateIdMinRepliedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_REPLIED_TIME_ASC',
  EmailMessagesByTemplateIdMinRepliedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_REPLIED_TIME_DESC',
  EmailMessagesByTemplateIdMinRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdMinRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdMinReviewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_REVIEWED_TIME_ASC',
  EmailMessagesByTemplateIdMinReviewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_REVIEWED_TIME_DESC',
  EmailMessagesByTemplateIdMinSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_SENDER_PROFILE_ID_ASC',
  EmailMessagesByTemplateIdMinSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_SENDER_PROFILE_ID_DESC',
  EmailMessagesByTemplateIdMinSentTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_SENT_TIME_ASC',
  EmailMessagesByTemplateIdMinSentTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_SENT_TIME_DESC',
  EmailMessagesByTemplateIdMinSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdMinSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdMinSubjectAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_SUBJECT_ASC',
  EmailMessagesByTemplateIdMinSubjectDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_SUBJECT_DESC',
  EmailMessagesByTemplateIdMinTemplateIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_TEMPLATE_ID_ASC',
  EmailMessagesByTemplateIdMinTemplateIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_TEMPLATE_ID_DESC',
  EmailMessagesByTemplateIdMinUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_UPDATED_TIME_ASC',
  EmailMessagesByTemplateIdMinUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_MIN_UPDATED_TIME_DESC',
  EmailMessagesByTemplateIdStddevPopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByTemplateIdStddevPopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByTemplateIdStddevPopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByTemplateIdStddevPopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByTemplateIdStddevPopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByTemplateIdStddevPopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByTemplateIdStddevPopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdStddevPopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdStddevPopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByTemplateIdStddevPopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByTemplateIdStddevPopulationContactIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByTemplateIdStddevPopulationContactIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByTemplateIdStddevPopulationContentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CONTENT_ASC',
  EmailMessagesByTemplateIdStddevPopulationContentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CONTENT_DESC',
  EmailMessagesByTemplateIdStddevPopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByTemplateIdStddevPopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByTemplateIdStddevPopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByTemplateIdStddevPopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByTemplateIdStddevPopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByTemplateIdStddevPopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByTemplateIdStddevPopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByTemplateIdStddevPopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByTemplateIdStddevPopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdStddevPopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdStddevPopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByTemplateIdStddevPopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByTemplateIdStddevPopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdStddevPopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdStddevPopulationIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_ID_ASC',
  EmailMessagesByTemplateIdStddevPopulationIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_ID_DESC',
  EmailMessagesByTemplateIdStddevPopulationIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_INTENT_ASC',
  EmailMessagesByTemplateIdStddevPopulationIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_INTENT_DESC',
  EmailMessagesByTemplateIdStddevPopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByTemplateIdStddevPopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByTemplateIdStddevPopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByTemplateIdStddevPopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByTemplateIdStddevPopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByTemplateIdStddevPopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByTemplateIdStddevPopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByTemplateIdStddevPopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByTemplateIdStddevPopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByTemplateIdStddevPopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByTemplateIdStddevPopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByTemplateIdStddevPopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByTemplateIdStddevPopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByTemplateIdStddevPopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByTemplateIdStddevPopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByTemplateIdStddevPopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByTemplateIdStddevPopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByTemplateIdStddevPopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByTemplateIdStddevPopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdStddevPopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdStddevPopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByTemplateIdStddevPopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByTemplateIdStddevPopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByTemplateIdStddevPopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByTemplateIdStddevPopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_SENT_TIME_ASC',
  EmailMessagesByTemplateIdStddevPopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_SENT_TIME_DESC',
  EmailMessagesByTemplateIdStddevPopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdStddevPopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdStddevPopulationSubjectAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_SUBJECT_ASC',
  EmailMessagesByTemplateIdStddevPopulationSubjectDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_SUBJECT_DESC',
  EmailMessagesByTemplateIdStddevPopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByTemplateIdStddevPopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByTemplateIdStddevPopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByTemplateIdStddevPopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByTemplateIdStddevSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByTemplateIdStddevSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByTemplateIdStddevSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByTemplateIdStddevSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByTemplateIdStddevSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByTemplateIdStddevSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByTemplateIdStddevSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdStddevSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdStddevSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByTemplateIdStddevSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByTemplateIdStddevSampleContactIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByTemplateIdStddevSampleContactIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByTemplateIdStddevSampleContentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CONTENT_ASC',
  EmailMessagesByTemplateIdStddevSampleContentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CONTENT_DESC',
  EmailMessagesByTemplateIdStddevSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByTemplateIdStddevSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByTemplateIdStddevSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByTemplateIdStddevSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByTemplateIdStddevSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByTemplateIdStddevSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByTemplateIdStddevSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByTemplateIdStddevSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByTemplateIdStddevSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdStddevSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdStddevSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByTemplateIdStddevSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByTemplateIdStddevSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdStddevSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdStddevSampleIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_ID_ASC',
  EmailMessagesByTemplateIdStddevSampleIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_ID_DESC',
  EmailMessagesByTemplateIdStddevSampleIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_INTENT_ASC',
  EmailMessagesByTemplateIdStddevSampleIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_INTENT_DESC',
  EmailMessagesByTemplateIdStddevSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByTemplateIdStddevSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByTemplateIdStddevSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByTemplateIdStddevSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByTemplateIdStddevSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByTemplateIdStddevSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByTemplateIdStddevSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByTemplateIdStddevSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByTemplateIdStddevSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByTemplateIdStddevSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByTemplateIdStddevSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByTemplateIdStddevSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByTemplateIdStddevSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByTemplateIdStddevSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByTemplateIdStddevSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByTemplateIdStddevSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByTemplateIdStddevSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByTemplateIdStddevSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByTemplateIdStddevSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdStddevSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdStddevSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByTemplateIdStddevSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByTemplateIdStddevSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByTemplateIdStddevSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByTemplateIdStddevSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByTemplateIdStddevSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByTemplateIdStddevSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdStddevSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdStddevSampleSubjectAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_SUBJECT_ASC',
  EmailMessagesByTemplateIdStddevSampleSubjectDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_SUBJECT_DESC',
  EmailMessagesByTemplateIdStddevSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByTemplateIdStddevSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByTemplateIdStddevSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByTemplateIdStddevSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_STDDEV_SAMPLE_UPDATED_TIME_DESC',
  EmailMessagesByTemplateIdSumAutoRepliedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_AUTO_REPLIED_ASC',
  EmailMessagesByTemplateIdSumAutoRepliedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_AUTO_REPLIED_DESC',
  EmailMessagesByTemplateIdSumCampaignIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CAMPAIGN_ID_ASC',
  EmailMessagesByTemplateIdSumCampaignIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CAMPAIGN_ID_DESC',
  EmailMessagesByTemplateIdSumCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByTemplateIdSumCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByTemplateIdSumCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdSumCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdSumCompanyIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_COMPANY_ID_ASC',
  EmailMessagesByTemplateIdSumCompanyIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_COMPANY_ID_DESC',
  EmailMessagesByTemplateIdSumContactIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CONTACT_ID_ASC',
  EmailMessagesByTemplateIdSumContactIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CONTACT_ID_DESC',
  EmailMessagesByTemplateIdSumContentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CONTENT_ASC',
  EmailMessagesByTemplateIdSumContentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CONTENT_DESC',
  EmailMessagesByTemplateIdSumContentHtmlAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CONTENT_HTML_ASC',
  EmailMessagesByTemplateIdSumContentHtmlDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CONTENT_HTML_DESC',
  EmailMessagesByTemplateIdSumCreatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CREATED_TIME_ASC',
  EmailMessagesByTemplateIdSumCreatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_CREATED_TIME_DESC',
  EmailMessagesByTemplateIdSumEmailIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_EMAIL_ID_ASC',
  EmailMessagesByTemplateIdSumEmailIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_EMAIL_ID_DESC',
  EmailMessagesByTemplateIdSumForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByTemplateIdSumForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByTemplateIdSumForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdSumForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdSumGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_GENERATED_REPLY_ASC',
  EmailMessagesByTemplateIdSumGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_GENERATED_REPLY_DESC',
  EmailMessagesByTemplateIdSumHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdSumHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdSumIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_ID_ASC',
  EmailMessagesByTemplateIdSumIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_ID_DESC',
  EmailMessagesByTemplateIdSumIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_INTENT_ASC',
  EmailMessagesByTemplateIdSumIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_INTENT_DESC',
  EmailMessagesByTemplateIdSumIsLawyerAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_IS_LAWYER_ASC',
  EmailMessagesByTemplateIdSumIsLawyerDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_IS_LAWYER_DESC',
  EmailMessagesByTemplateIdSumIsReviewedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_IS_REVIEWED_ASC',
  EmailMessagesByTemplateIdSumIsReviewedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_IS_REVIEWED_DESC',
  EmailMessagesByTemplateIdSumLawyerIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_LAWYER_ID_ASC',
  EmailMessagesByTemplateIdSumLawyerIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_LAWYER_ID_DESC',
  EmailMessagesByTemplateIdSumLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByTemplateIdSumLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByTemplateIdSumNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_NYLAS_THREAD_ID_ASC',
  EmailMessagesByTemplateIdSumNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_NYLAS_THREAD_ID_DESC',
  EmailMessagesByTemplateIdSumOrderIndexAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_ORDER_INDEX_ASC',
  EmailMessagesByTemplateIdSumOrderIndexDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_ORDER_INDEX_DESC',
  EmailMessagesByTemplateIdSumPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByTemplateIdSumPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByTemplateIdSumRepliedIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_REPLIED_INTENT_ASC',
  EmailMessagesByTemplateIdSumRepliedIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_REPLIED_INTENT_DESC',
  EmailMessagesByTemplateIdSumRepliedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_REPLIED_TIME_ASC',
  EmailMessagesByTemplateIdSumRepliedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_REPLIED_TIME_DESC',
  EmailMessagesByTemplateIdSumRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdSumRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdSumReviewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_REVIEWED_TIME_ASC',
  EmailMessagesByTemplateIdSumReviewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_REVIEWED_TIME_DESC',
  EmailMessagesByTemplateIdSumSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_SENDER_PROFILE_ID_ASC',
  EmailMessagesByTemplateIdSumSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_SENDER_PROFILE_ID_DESC',
  EmailMessagesByTemplateIdSumSentTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_SENT_TIME_ASC',
  EmailMessagesByTemplateIdSumSentTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_SENT_TIME_DESC',
  EmailMessagesByTemplateIdSumSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdSumSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdSumSubjectAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_SUBJECT_ASC',
  EmailMessagesByTemplateIdSumSubjectDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_SUBJECT_DESC',
  EmailMessagesByTemplateIdSumTemplateIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_TEMPLATE_ID_ASC',
  EmailMessagesByTemplateIdSumTemplateIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_TEMPLATE_ID_DESC',
  EmailMessagesByTemplateIdSumUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_UPDATED_TIME_ASC',
  EmailMessagesByTemplateIdSumUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_SUM_UPDATED_TIME_DESC',
  EmailMessagesByTemplateIdVariancePopulationAutoRepliedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_AUTO_REPLIED_ASC',
  EmailMessagesByTemplateIdVariancePopulationAutoRepliedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_AUTO_REPLIED_DESC',
  EmailMessagesByTemplateIdVariancePopulationCampaignIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CAMPAIGN_ID_ASC',
  EmailMessagesByTemplateIdVariancePopulationCampaignIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CAMPAIGN_ID_DESC',
  EmailMessagesByTemplateIdVariancePopulationCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByTemplateIdVariancePopulationCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByTemplateIdVariancePopulationCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdVariancePopulationCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdVariancePopulationCompanyIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_COMPANY_ID_ASC',
  EmailMessagesByTemplateIdVariancePopulationCompanyIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_COMPANY_ID_DESC',
  EmailMessagesByTemplateIdVariancePopulationContactIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CONTACT_ID_ASC',
  EmailMessagesByTemplateIdVariancePopulationContactIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CONTACT_ID_DESC',
  EmailMessagesByTemplateIdVariancePopulationContentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CONTENT_ASC',
  EmailMessagesByTemplateIdVariancePopulationContentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CONTENT_DESC',
  EmailMessagesByTemplateIdVariancePopulationContentHtmlAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CONTENT_HTML_ASC',
  EmailMessagesByTemplateIdVariancePopulationContentHtmlDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CONTENT_HTML_DESC',
  EmailMessagesByTemplateIdVariancePopulationCreatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  EmailMessagesByTemplateIdVariancePopulationCreatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  EmailMessagesByTemplateIdVariancePopulationEmailIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_EMAIL_ID_ASC',
  EmailMessagesByTemplateIdVariancePopulationEmailIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_EMAIL_ID_DESC',
  EmailMessagesByTemplateIdVariancePopulationForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByTemplateIdVariancePopulationForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByTemplateIdVariancePopulationForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdVariancePopulationForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdVariancePopulationGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_GENERATED_REPLY_ASC',
  EmailMessagesByTemplateIdVariancePopulationGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_GENERATED_REPLY_DESC',
  EmailMessagesByTemplateIdVariancePopulationHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdVariancePopulationHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdVariancePopulationIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_ID_ASC',
  EmailMessagesByTemplateIdVariancePopulationIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_ID_DESC',
  EmailMessagesByTemplateIdVariancePopulationIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_INTENT_ASC',
  EmailMessagesByTemplateIdVariancePopulationIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_INTENT_DESC',
  EmailMessagesByTemplateIdVariancePopulationIsLawyerAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_IS_LAWYER_ASC',
  EmailMessagesByTemplateIdVariancePopulationIsLawyerDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_IS_LAWYER_DESC',
  EmailMessagesByTemplateIdVariancePopulationIsReviewedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_IS_REVIEWED_ASC',
  EmailMessagesByTemplateIdVariancePopulationIsReviewedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_IS_REVIEWED_DESC',
  EmailMessagesByTemplateIdVariancePopulationLawyerIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_LAWYER_ID_ASC',
  EmailMessagesByTemplateIdVariancePopulationLawyerIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_LAWYER_ID_DESC',
  EmailMessagesByTemplateIdVariancePopulationLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByTemplateIdVariancePopulationLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByTemplateIdVariancePopulationNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_ASC',
  EmailMessagesByTemplateIdVariancePopulationNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_NYLAS_THREAD_ID_DESC',
  EmailMessagesByTemplateIdVariancePopulationOrderIndexAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_ORDER_INDEX_ASC',
  EmailMessagesByTemplateIdVariancePopulationOrderIndexDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_ORDER_INDEX_DESC',
  EmailMessagesByTemplateIdVariancePopulationPreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByTemplateIdVariancePopulationPreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByTemplateIdVariancePopulationRepliedIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_REPLIED_INTENT_ASC',
  EmailMessagesByTemplateIdVariancePopulationRepliedIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_REPLIED_INTENT_DESC',
  EmailMessagesByTemplateIdVariancePopulationRepliedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_REPLIED_TIME_ASC',
  EmailMessagesByTemplateIdVariancePopulationRepliedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_REPLIED_TIME_DESC',
  EmailMessagesByTemplateIdVariancePopulationRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdVariancePopulationRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdVariancePopulationReviewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_REVIEWED_TIME_ASC',
  EmailMessagesByTemplateIdVariancePopulationReviewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_REVIEWED_TIME_DESC',
  EmailMessagesByTemplateIdVariancePopulationSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_ASC',
  EmailMessagesByTemplateIdVariancePopulationSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_SENDER_PROFILE_ID_DESC',
  EmailMessagesByTemplateIdVariancePopulationSentTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_SENT_TIME_ASC',
  EmailMessagesByTemplateIdVariancePopulationSentTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_SENT_TIME_DESC',
  EmailMessagesByTemplateIdVariancePopulationSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdVariancePopulationSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdVariancePopulationSubjectAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_SUBJECT_ASC',
  EmailMessagesByTemplateIdVariancePopulationSubjectDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_SUBJECT_DESC',
  EmailMessagesByTemplateIdVariancePopulationTemplateIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  EmailMessagesByTemplateIdVariancePopulationTemplateIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  EmailMessagesByTemplateIdVariancePopulationUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_UPDATED_TIME_ASC',
  EmailMessagesByTemplateIdVariancePopulationUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_POPULATION_UPDATED_TIME_DESC',
  EmailMessagesByTemplateIdVarianceSampleAutoRepliedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_AUTO_REPLIED_ASC',
  EmailMessagesByTemplateIdVarianceSampleAutoRepliedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_AUTO_REPLIED_DESC',
  EmailMessagesByTemplateIdVarianceSampleCampaignIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_ASC',
  EmailMessagesByTemplateIdVarianceSampleCampaignIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CAMPAIGN_ID_DESC',
  EmailMessagesByTemplateIdVarianceSampleCampaignMemberIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_ASC',
  EmailMessagesByTemplateIdVarianceSampleCampaignMemberIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CAMPAIGN_MEMBER_ID_DESC',
  EmailMessagesByTemplateIdVarianceSampleCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdVarianceSampleCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdVarianceSampleCompanyIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_COMPANY_ID_ASC',
  EmailMessagesByTemplateIdVarianceSampleCompanyIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_COMPANY_ID_DESC',
  EmailMessagesByTemplateIdVarianceSampleContactIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CONTACT_ID_ASC',
  EmailMessagesByTemplateIdVarianceSampleContactIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CONTACT_ID_DESC',
  EmailMessagesByTemplateIdVarianceSampleContentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  EmailMessagesByTemplateIdVarianceSampleContentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  EmailMessagesByTemplateIdVarianceSampleContentHtmlAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CONTENT_HTML_ASC',
  EmailMessagesByTemplateIdVarianceSampleContentHtmlDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CONTENT_HTML_DESC',
  EmailMessagesByTemplateIdVarianceSampleCreatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  EmailMessagesByTemplateIdVarianceSampleCreatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  EmailMessagesByTemplateIdVarianceSampleEmailIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_EMAIL_ID_ASC',
  EmailMessagesByTemplateIdVarianceSampleEmailIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_EMAIL_ID_DESC',
  EmailMessagesByTemplateIdVarianceSampleForwardedInternallyTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_ASC',
  EmailMessagesByTemplateIdVarianceSampleForwardedInternallyTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_FORWARDED_INTERNALLY_TIME_DESC',
  EmailMessagesByTemplateIdVarianceSampleForwardedToLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdVarianceSampleForwardedToLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_FORWARDED_TO_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdVarianceSampleGeneratedReplyAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_GENERATED_REPLY_ASC',
  EmailMessagesByTemplateIdVarianceSampleGeneratedReplyDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_GENERATED_REPLY_DESC',
  EmailMessagesByTemplateIdVarianceSampleHedwigMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdVarianceSampleHedwigMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_HEDWIG_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdVarianceSampleIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_ID_ASC',
  EmailMessagesByTemplateIdVarianceSampleIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_ID_DESC',
  EmailMessagesByTemplateIdVarianceSampleIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_INTENT_ASC',
  EmailMessagesByTemplateIdVarianceSampleIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_INTENT_DESC',
  EmailMessagesByTemplateIdVarianceSampleIsLawyerAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_IS_LAWYER_ASC',
  EmailMessagesByTemplateIdVarianceSampleIsLawyerDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_IS_LAWYER_DESC',
  EmailMessagesByTemplateIdVarianceSampleIsReviewedAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_IS_REVIEWED_ASC',
  EmailMessagesByTemplateIdVarianceSampleIsReviewedDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_IS_REVIEWED_DESC',
  EmailMessagesByTemplateIdVarianceSampleLawyerIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_LAWYER_ID_ASC',
  EmailMessagesByTemplateIdVarianceSampleLawyerIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_LAWYER_ID_DESC',
  EmailMessagesByTemplateIdVarianceSampleLawyerViewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_ASC',
  EmailMessagesByTemplateIdVarianceSampleLawyerViewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_LAWYER_VIEWED_TIME_DESC',
  EmailMessagesByTemplateIdVarianceSampleNylasThreadIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_ASC',
  EmailMessagesByTemplateIdVarianceSampleNylasThreadIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_NYLAS_THREAD_ID_DESC',
  EmailMessagesByTemplateIdVarianceSampleOrderIndexAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_ORDER_INDEX_ASC',
  EmailMessagesByTemplateIdVarianceSampleOrderIndexDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_ORDER_INDEX_DESC',
  EmailMessagesByTemplateIdVarianceSamplePreviousReplyAttemptTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_ASC',
  EmailMessagesByTemplateIdVarianceSamplePreviousReplyAttemptTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_PREVIOUS_REPLY_ATTEMPT_TIME_DESC',
  EmailMessagesByTemplateIdVarianceSampleRepliedIntentAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_REPLIED_INTENT_ASC',
  EmailMessagesByTemplateIdVarianceSampleRepliedIntentDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_REPLIED_INTENT_DESC',
  EmailMessagesByTemplateIdVarianceSampleRepliedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_REPLIED_TIME_ASC',
  EmailMessagesByTemplateIdVarianceSampleRepliedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_REPLIED_TIME_DESC',
  EmailMessagesByTemplateIdVarianceSampleRequestedCcLawyerTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_ASC',
  EmailMessagesByTemplateIdVarianceSampleRequestedCcLawyerTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_REQUESTED_CC_LAWYER_TIME_DESC',
  EmailMessagesByTemplateIdVarianceSampleReviewedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_REVIEWED_TIME_ASC',
  EmailMessagesByTemplateIdVarianceSampleReviewedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_REVIEWED_TIME_DESC',
  EmailMessagesByTemplateIdVarianceSampleSenderProfileIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_ASC',
  EmailMessagesByTemplateIdVarianceSampleSenderProfileIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SENDER_PROFILE_ID_DESC',
  EmailMessagesByTemplateIdVarianceSampleSentTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SENT_TIME_ASC',
  EmailMessagesByTemplateIdVarianceSampleSentTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SENT_TIME_DESC',
  EmailMessagesByTemplateIdVarianceSampleSmtpMessageIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_ASC',
  EmailMessagesByTemplateIdVarianceSampleSmtpMessageIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SMTP_MESSAGE_ID_DESC',
  EmailMessagesByTemplateIdVarianceSampleSubjectAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SUBJECT_ASC',
  EmailMessagesByTemplateIdVarianceSampleSubjectDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_SUBJECT_DESC',
  EmailMessagesByTemplateIdVarianceSampleTemplateIdAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  EmailMessagesByTemplateIdVarianceSampleTemplateIdDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  EmailMessagesByTemplateIdVarianceSampleUpdatedTimeAsc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_UPDATED_TIME_ASC',
  EmailMessagesByTemplateIdVarianceSampleUpdatedTimeDesc = 'EMAIL_MESSAGES_BY_TEMPLATE_ID_VARIANCE_SAMPLE_UPDATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LanguageAsc = 'LANGUAGE_ASC',
  LanguageDesc = 'LANGUAGE_DESC',
  Natural = 'NATURAL',
  OrderIndexAsc = 'ORDER_INDEX_ASC',
  OrderIndexDesc = 'ORDER_INDEX_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RevisedContentAsc = 'REVISED_CONTENT_ASC',
  RevisedContentDesc = 'REVISED_CONTENT_DESC',
  SendgridIdAsc = 'SENDGRID_ID_ASC',
  SendgridIdDesc = 'SENDGRID_ID_DESC',
  SpintaxAsc = 'SPINTAX_ASC',
  SpintaxDesc = 'SPINTAX_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TemplateRevisionsByTemplateIdAverageContentAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_CONTENT_ASC',
  TemplateRevisionsByTemplateIdAverageContentDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_CONTENT_DESC',
  TemplateRevisionsByTemplateIdAverageCreatedTimeAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_CREATED_TIME_ASC',
  TemplateRevisionsByTemplateIdAverageCreatedTimeDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_CREATED_TIME_DESC',
  TemplateRevisionsByTemplateIdAverageIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_ID_ASC',
  TemplateRevisionsByTemplateIdAverageIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_ID_DESC',
  TemplateRevisionsByTemplateIdAverageTemplateIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_TEMPLATE_ID_ASC',
  TemplateRevisionsByTemplateIdAverageTemplateIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_AVERAGE_TEMPLATE_ID_DESC',
  TemplateRevisionsByTemplateIdCountAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_COUNT_ASC',
  TemplateRevisionsByTemplateIdCountDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_COUNT_DESC',
  TemplateRevisionsByTemplateIdDistinctCountContentAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_CONTENT_ASC',
  TemplateRevisionsByTemplateIdDistinctCountContentDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_CONTENT_DESC',
  TemplateRevisionsByTemplateIdDistinctCountCreatedTimeAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_CREATED_TIME_ASC',
  TemplateRevisionsByTemplateIdDistinctCountCreatedTimeDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_CREATED_TIME_DESC',
  TemplateRevisionsByTemplateIdDistinctCountIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_ID_ASC',
  TemplateRevisionsByTemplateIdDistinctCountIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_ID_DESC',
  TemplateRevisionsByTemplateIdDistinctCountTemplateIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_TEMPLATE_ID_ASC',
  TemplateRevisionsByTemplateIdDistinctCountTemplateIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_DISTINCT_COUNT_TEMPLATE_ID_DESC',
  TemplateRevisionsByTemplateIdMaxContentAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_CONTENT_ASC',
  TemplateRevisionsByTemplateIdMaxContentDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_CONTENT_DESC',
  TemplateRevisionsByTemplateIdMaxCreatedTimeAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_CREATED_TIME_ASC',
  TemplateRevisionsByTemplateIdMaxCreatedTimeDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_CREATED_TIME_DESC',
  TemplateRevisionsByTemplateIdMaxIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_ID_ASC',
  TemplateRevisionsByTemplateIdMaxIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_ID_DESC',
  TemplateRevisionsByTemplateIdMaxTemplateIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_TEMPLATE_ID_ASC',
  TemplateRevisionsByTemplateIdMaxTemplateIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MAX_TEMPLATE_ID_DESC',
  TemplateRevisionsByTemplateIdMinContentAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_CONTENT_ASC',
  TemplateRevisionsByTemplateIdMinContentDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_CONTENT_DESC',
  TemplateRevisionsByTemplateIdMinCreatedTimeAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_CREATED_TIME_ASC',
  TemplateRevisionsByTemplateIdMinCreatedTimeDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_CREATED_TIME_DESC',
  TemplateRevisionsByTemplateIdMinIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_ID_ASC',
  TemplateRevisionsByTemplateIdMinIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_ID_DESC',
  TemplateRevisionsByTemplateIdMinTemplateIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_TEMPLATE_ID_ASC',
  TemplateRevisionsByTemplateIdMinTemplateIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_MIN_TEMPLATE_ID_DESC',
  TemplateRevisionsByTemplateIdStddevPopulationContentAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_CONTENT_ASC',
  TemplateRevisionsByTemplateIdStddevPopulationContentDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_CONTENT_DESC',
  TemplateRevisionsByTemplateIdStddevPopulationCreatedTimeAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_CREATED_TIME_ASC',
  TemplateRevisionsByTemplateIdStddevPopulationCreatedTimeDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_CREATED_TIME_DESC',
  TemplateRevisionsByTemplateIdStddevPopulationIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_ID_ASC',
  TemplateRevisionsByTemplateIdStddevPopulationIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_ID_DESC',
  TemplateRevisionsByTemplateIdStddevPopulationTemplateIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_TEMPLATE_ID_ASC',
  TemplateRevisionsByTemplateIdStddevPopulationTemplateIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_POPULATION_TEMPLATE_ID_DESC',
  TemplateRevisionsByTemplateIdStddevSampleContentAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CONTENT_ASC',
  TemplateRevisionsByTemplateIdStddevSampleContentDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CONTENT_DESC',
  TemplateRevisionsByTemplateIdStddevSampleCreatedTimeAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CREATED_TIME_ASC',
  TemplateRevisionsByTemplateIdStddevSampleCreatedTimeDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_CREATED_TIME_DESC',
  TemplateRevisionsByTemplateIdStddevSampleIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_ID_ASC',
  TemplateRevisionsByTemplateIdStddevSampleIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_ID_DESC',
  TemplateRevisionsByTemplateIdStddevSampleTemplateIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_TEMPLATE_ID_ASC',
  TemplateRevisionsByTemplateIdStddevSampleTemplateIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_STDDEV_SAMPLE_TEMPLATE_ID_DESC',
  TemplateRevisionsByTemplateIdSumContentAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_CONTENT_ASC',
  TemplateRevisionsByTemplateIdSumContentDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_CONTENT_DESC',
  TemplateRevisionsByTemplateIdSumCreatedTimeAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_CREATED_TIME_ASC',
  TemplateRevisionsByTemplateIdSumCreatedTimeDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_CREATED_TIME_DESC',
  TemplateRevisionsByTemplateIdSumIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_ID_ASC',
  TemplateRevisionsByTemplateIdSumIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_ID_DESC',
  TemplateRevisionsByTemplateIdSumTemplateIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_TEMPLATE_ID_ASC',
  TemplateRevisionsByTemplateIdSumTemplateIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_SUM_TEMPLATE_ID_DESC',
  TemplateRevisionsByTemplateIdVariancePopulationContentAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CONTENT_ASC',
  TemplateRevisionsByTemplateIdVariancePopulationContentDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CONTENT_DESC',
  TemplateRevisionsByTemplateIdVariancePopulationCreatedTimeAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CREATED_TIME_ASC',
  TemplateRevisionsByTemplateIdVariancePopulationCreatedTimeDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_CREATED_TIME_DESC',
  TemplateRevisionsByTemplateIdVariancePopulationIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_ID_ASC',
  TemplateRevisionsByTemplateIdVariancePopulationIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_ID_DESC',
  TemplateRevisionsByTemplateIdVariancePopulationTemplateIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_TEMPLATE_ID_ASC',
  TemplateRevisionsByTemplateIdVariancePopulationTemplateIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_POPULATION_TEMPLATE_ID_DESC',
  TemplateRevisionsByTemplateIdVarianceSampleContentAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CONTENT_ASC',
  TemplateRevisionsByTemplateIdVarianceSampleContentDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CONTENT_DESC',
  TemplateRevisionsByTemplateIdVarianceSampleCreatedTimeAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CREATED_TIME_ASC',
  TemplateRevisionsByTemplateIdVarianceSampleCreatedTimeDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_CREATED_TIME_DESC',
  TemplateRevisionsByTemplateIdVarianceSampleIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_ID_ASC',
  TemplateRevisionsByTemplateIdVarianceSampleIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_ID_DESC',
  TemplateRevisionsByTemplateIdVarianceSampleTemplateIdAsc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_ASC',
  TemplateRevisionsByTemplateIdVarianceSampleTemplateIdDesc = 'TEMPLATE_REVISIONS_BY_TEMPLATE_ID_VARIANCE_SAMPLE_TEMPLATE_ID_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

export type Testimonial = Node & {
  __typename?: 'Testimonial';
  communicationRating?: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Contact` that is related to this `Testimonial`. */
  contactByContactId?: Maybe<Contact>;
  contactId: Scalars['UUID']['output'];
  costRating?: Maybe<Scalars['Int']['output']>;
  createdTime: Scalars['Datetime']['output'];
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** Reads a single `Lawyer` that is related to this `Testimonial`. */
  lawyerByLawyerId?: Maybe<Lawyer>;
  lawyerId?: Maybe<Scalars['UUID']['output']>;
  negativeComments?: Maybe<Scalars['String']['output']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  positiveComments?: Maybe<Scalars['String']['output']>;
  public?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['Boolean']['output']>;
  responsivenessRating?: Maybe<Scalars['Int']['output']>;
  serviceDescription?: Maybe<Scalars['String']['output']>;
  targetRelationship?: Maybe<Scalars['String']['output']>;
  timeRating?: Maybe<Scalars['Int']['output']>;
};

export type TestimonialAggregates = {
  __typename?: 'TestimonialAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<TestimonialAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TestimonialDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<TestimonialMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<TestimonialMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<TestimonialStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<TestimonialStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<TestimonialSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<TestimonialVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<TestimonialVarianceSampleAggregates>;
};

export type TestimonialAverageAggregates = {
  __typename?: 'TestimonialAverageAggregates';
  /** Mean average of communicationRating across the matching connection */
  communicationRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of costRating across the matching connection */
  costRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of responsivenessRating across the matching connection */
  responsivenessRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Mean average of timeRating across the matching connection */
  timeRating?: Maybe<Scalars['BigFloat']['output']>;
};

/**
 * A condition to be used against `Testimonial` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TestimonialCondition = {
  /** Checks for equality with the object’s `communicationRating` field. */
  communicationRating?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `contactId` field. */
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `costRating` field. */
  costRating?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `negativeComments` field. */
  negativeComments?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `positiveComments` field. */
  positiveComments?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `public` field. */
  public?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `reference` field. */
  reference?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `responsivenessRating` field. */
  responsivenessRating?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `serviceDescription` field. */
  serviceDescription?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `targetRelationship` field. */
  targetRelationship?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timeRating` field. */
  timeRating?: InputMaybe<Scalars['Int']['input']>;
};

export type TestimonialDistinctCountAggregates = {
  __typename?: 'TestimonialDistinctCountAggregates';
  /** Distinct count of communicationRating across the matching connection */
  communicationRating?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of contactId across the matching connection */
  contactId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of costRating across the matching connection */
  costRating?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of lawyerId across the matching connection */
  lawyerId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of negativeComments across the matching connection */
  negativeComments?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of positiveComments across the matching connection */
  positiveComments?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of public across the matching connection */
  public?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of reference across the matching connection */
  reference?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of responsivenessRating across the matching connection */
  responsivenessRating?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of serviceDescription across the matching connection */
  serviceDescription?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of targetRelationship across the matching connection */
  targetRelationship?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of timeRating across the matching connection */
  timeRating?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Testimonial` object types. All fields are combined with a logical ‘and.’ */
export type TestimonialFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TestimonialFilter>>;
  /** Filter by the object’s `communicationRating` field. */
  communicationRating?: InputMaybe<IntFilter>;
  /** Filter by the object’s `contactId` field. */
  contactId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `costRating` field. */
  costRating?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lawyerId` field. */
  lawyerId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `negativeComments` field. */
  negativeComments?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TestimonialFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TestimonialFilter>>;
  /** Filter by the object’s `positiveComments` field. */
  positiveComments?: InputMaybe<StringFilter>;
  /** Filter by the object’s `public` field. */
  public?: InputMaybe<StringFilter>;
  /** Filter by the object’s `reference` field. */
  reference?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `responsivenessRating` field. */
  responsivenessRating?: InputMaybe<IntFilter>;
  /** Filter by the object’s `serviceDescription` field. */
  serviceDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `targetRelationship` field. */
  targetRelationship?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timeRating` field. */
  timeRating?: InputMaybe<IntFilter>;
};

/** Grouping methods for `Testimonial` for usage during aggregation. */
export enum TestimonialGroupBy {
  CommunicationRating = 'COMMUNICATION_RATING',
  ContactId = 'CONTACT_ID',
  CostRating = 'COST_RATING',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  LawyerId = 'LAWYER_ID',
  NegativeComments = 'NEGATIVE_COMMENTS',
  PositiveComments = 'POSITIVE_COMMENTS',
  Public = 'PUBLIC',
  Reference = 'REFERENCE',
  ResponsivenessRating = 'RESPONSIVENESS_RATING',
  ServiceDescription = 'SERVICE_DESCRIPTION',
  TargetRelationship = 'TARGET_RELATIONSHIP',
  TimeRating = 'TIME_RATING'
}

export type TestimonialHavingAverageInput = {
  communicationRating?: InputMaybe<HavingIntFilter>;
  costRating?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  responsivenessRating?: InputMaybe<HavingIntFilter>;
  timeRating?: InputMaybe<HavingIntFilter>;
};

export type TestimonialHavingDistinctCountInput = {
  communicationRating?: InputMaybe<HavingIntFilter>;
  costRating?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  responsivenessRating?: InputMaybe<HavingIntFilter>;
  timeRating?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `Testimonial` aggregates. */
export type TestimonialHavingInput = {
  AND?: InputMaybe<Array<TestimonialHavingInput>>;
  OR?: InputMaybe<Array<TestimonialHavingInput>>;
  average?: InputMaybe<TestimonialHavingAverageInput>;
  distinctCount?: InputMaybe<TestimonialHavingDistinctCountInput>;
  max?: InputMaybe<TestimonialHavingMaxInput>;
  min?: InputMaybe<TestimonialHavingMinInput>;
  stddevPopulation?: InputMaybe<TestimonialHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<TestimonialHavingStddevSampleInput>;
  sum?: InputMaybe<TestimonialHavingSumInput>;
  variancePopulation?: InputMaybe<TestimonialHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<TestimonialHavingVarianceSampleInput>;
};

export type TestimonialHavingMaxInput = {
  communicationRating?: InputMaybe<HavingIntFilter>;
  costRating?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  responsivenessRating?: InputMaybe<HavingIntFilter>;
  timeRating?: InputMaybe<HavingIntFilter>;
};

export type TestimonialHavingMinInput = {
  communicationRating?: InputMaybe<HavingIntFilter>;
  costRating?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  responsivenessRating?: InputMaybe<HavingIntFilter>;
  timeRating?: InputMaybe<HavingIntFilter>;
};

export type TestimonialHavingStddevPopulationInput = {
  communicationRating?: InputMaybe<HavingIntFilter>;
  costRating?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  responsivenessRating?: InputMaybe<HavingIntFilter>;
  timeRating?: InputMaybe<HavingIntFilter>;
};

export type TestimonialHavingStddevSampleInput = {
  communicationRating?: InputMaybe<HavingIntFilter>;
  costRating?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  responsivenessRating?: InputMaybe<HavingIntFilter>;
  timeRating?: InputMaybe<HavingIntFilter>;
};

export type TestimonialHavingSumInput = {
  communicationRating?: InputMaybe<HavingIntFilter>;
  costRating?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  responsivenessRating?: InputMaybe<HavingIntFilter>;
  timeRating?: InputMaybe<HavingIntFilter>;
};

export type TestimonialHavingVariancePopulationInput = {
  communicationRating?: InputMaybe<HavingIntFilter>;
  costRating?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  responsivenessRating?: InputMaybe<HavingIntFilter>;
  timeRating?: InputMaybe<HavingIntFilter>;
};

export type TestimonialHavingVarianceSampleInput = {
  communicationRating?: InputMaybe<HavingIntFilter>;
  costRating?: InputMaybe<HavingIntFilter>;
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  responsivenessRating?: InputMaybe<HavingIntFilter>;
  timeRating?: InputMaybe<HavingIntFilter>;
};

export type TestimonialMaxAggregates = {
  __typename?: 'TestimonialMaxAggregates';
  /** Maximum of communicationRating across the matching connection */
  communicationRating?: Maybe<Scalars['Int']['output']>;
  /** Maximum of costRating across the matching connection */
  costRating?: Maybe<Scalars['Int']['output']>;
  /** Maximum of responsivenessRating across the matching connection */
  responsivenessRating?: Maybe<Scalars['Int']['output']>;
  /** Maximum of timeRating across the matching connection */
  timeRating?: Maybe<Scalars['Int']['output']>;
};

export type TestimonialMinAggregates = {
  __typename?: 'TestimonialMinAggregates';
  /** Minimum of communicationRating across the matching connection */
  communicationRating?: Maybe<Scalars['Int']['output']>;
  /** Minimum of costRating across the matching connection */
  costRating?: Maybe<Scalars['Int']['output']>;
  /** Minimum of responsivenessRating across the matching connection */
  responsivenessRating?: Maybe<Scalars['Int']['output']>;
  /** Minimum of timeRating across the matching connection */
  timeRating?: Maybe<Scalars['Int']['output']>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type TestimonialSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: TestimonialSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type TestimonialSortBy = {
  field?: InputMaybe<TestimonialSortableField>;
};

/** Sortable concrete fields for the `Testimonial` type. */
export enum TestimonialSortableField {
  CommunicationRating = 'COMMUNICATION_RATING',
  ContactId = 'CONTACT_ID',
  CostRating = 'COST_RATING',
  CreatedTime = 'CREATED_TIME',
  Id = 'ID',
  LawyerId = 'LAWYER_ID',
  NegativeComments = 'NEGATIVE_COMMENTS',
  PositiveComments = 'POSITIVE_COMMENTS',
  Public = 'PUBLIC',
  Reference = 'REFERENCE',
  ResponsivenessRating = 'RESPONSIVENESS_RATING',
  ServiceDescription = 'SERVICE_DESCRIPTION',
  TargetRelationship = 'TARGET_RELATIONSHIP',
  TimeRating = 'TIME_RATING'
}

export type TestimonialStddevPopulationAggregates = {
  __typename?: 'TestimonialStddevPopulationAggregates';
  /** Population standard deviation of communicationRating across the matching connection */
  communicationRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of costRating across the matching connection */
  costRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of responsivenessRating across the matching connection */
  responsivenessRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Population standard deviation of timeRating across the matching connection */
  timeRating?: Maybe<Scalars['BigFloat']['output']>;
};

export type TestimonialStddevSampleAggregates = {
  __typename?: 'TestimonialStddevSampleAggregates';
  /** Sample standard deviation of communicationRating across the matching connection */
  communicationRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of costRating across the matching connection */
  costRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of responsivenessRating across the matching connection */
  responsivenessRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample standard deviation of timeRating across the matching connection */
  timeRating?: Maybe<Scalars['BigFloat']['output']>;
};

export type TestimonialSumAggregates = {
  __typename?: 'TestimonialSumAggregates';
  /** Sum of communicationRating across the matching connection */
  communicationRating: Scalars['BigInt']['output'];
  /** Sum of costRating across the matching connection */
  costRating: Scalars['BigInt']['output'];
  /** Sum of responsivenessRating across the matching connection */
  responsivenessRating: Scalars['BigInt']['output'];
  /** Sum of timeRating across the matching connection */
  timeRating: Scalars['BigInt']['output'];
};

export type TestimonialVariancePopulationAggregates = {
  __typename?: 'TestimonialVariancePopulationAggregates';
  /** Population variance of communicationRating across the matching connection */
  communicationRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of costRating across the matching connection */
  costRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of responsivenessRating across the matching connection */
  responsivenessRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Population variance of timeRating across the matching connection */
  timeRating?: Maybe<Scalars['BigFloat']['output']>;
};

export type TestimonialVarianceSampleAggregates = {
  __typename?: 'TestimonialVarianceSampleAggregates';
  /** Sample variance of communicationRating across the matching connection */
  communicationRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of costRating across the matching connection */
  costRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of responsivenessRating across the matching connection */
  responsivenessRating?: Maybe<Scalars['BigFloat']['output']>;
  /** Sample variance of timeRating across the matching connection */
  timeRating?: Maybe<Scalars['BigFloat']['output']>;
};

/** A connection to a list of `Testimonial` values. */
export type TestimonialsConnection = {
  __typename?: 'TestimonialsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TestimonialAggregates>;
  /** A list of edges which contains the `Testimonial` and cursor to aid in pagination. */
  edges: Array<TestimonialsEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TestimonialAggregates>>;
  /** A list of `Testimonial` objects. */
  nodes: Array<Maybe<Testimonial>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Testimonial` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Testimonial` values. */
export type TestimonialsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TestimonialGroupBy>;
  having?: InputMaybe<TestimonialHavingInput>;
};

/** A `Testimonial` edge in the connection. */
export type TestimonialsEdge = {
  __typename?: 'TestimonialsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Testimonial` at the end of the edge. */
  node?: Maybe<Testimonial>;
};

/** Methods to use when ordering `Testimonial`. */
export enum TestimonialsOrderBy {
  CommunicationRatingAsc = 'COMMUNICATION_RATING_ASC',
  CommunicationRatingDesc = 'COMMUNICATION_RATING_DESC',
  ContactIdAsc = 'CONTACT_ID_ASC',
  ContactIdDesc = 'CONTACT_ID_DESC',
  CostRatingAsc = 'COST_RATING_ASC',
  CostRatingDesc = 'COST_RATING_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LawyerIdAsc = 'LAWYER_ID_ASC',
  LawyerIdDesc = 'LAWYER_ID_DESC',
  Natural = 'NATURAL',
  NegativeCommentsAsc = 'NEGATIVE_COMMENTS_ASC',
  NegativeCommentsDesc = 'NEGATIVE_COMMENTS_DESC',
  PositiveCommentsAsc = 'POSITIVE_COMMENTS_ASC',
  PositiveCommentsDesc = 'POSITIVE_COMMENTS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PublicAsc = 'PUBLIC_ASC',
  PublicDesc = 'PUBLIC_DESC',
  ReferenceAsc = 'REFERENCE_ASC',
  ReferenceDesc = 'REFERENCE_DESC',
  ResponsivenessRatingAsc = 'RESPONSIVENESS_RATING_ASC',
  ResponsivenessRatingDesc = 'RESPONSIVENESS_RATING_DESC',
  ServiceDescriptionAsc = 'SERVICE_DESCRIPTION_ASC',
  ServiceDescriptionDesc = 'SERVICE_DESCRIPTION_DESC',
  TargetRelationshipAsc = 'TARGET_RELATIONSHIP_ASC',
  TargetRelationshipDesc = 'TARGET_RELATIONSHIP_DESC',
  TimeRatingAsc = 'TIME_RATING_ASC',
  TimeRatingDesc = 'TIME_RATING_DESC'
}

export type Trace = Node & {
  __typename?: 'Trace';
  action: Traceaction;
  /** Reads a single `CampaignMember` that is related to this `Trace`. */
  campaignMemberByCampaignMemberId?: Maybe<CampaignMember>;
  campaignMemberId?: Maybe<Scalars['UUID']['output']>;
  createdTime: Scalars['Datetime']['output'];
  /** Reads a single `EmailMessage` that is related to this `Trace`. */
  emailMessageByEmailMessageId?: Maybe<EmailMessage>;
  emailMessageId?: Maybe<Scalars['UUID']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['UUID']['output'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
  /** Reads a single `Profile` that is related to this `Trace`. */
  profileByProfileId?: Maybe<Profile>;
  profileId: Scalars['UUID']['output'];
  updatedTime: Scalars['Datetime']['output'];
};

export type TraceAggregates = {
  __typename?: 'TraceAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TraceDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']['output']>>;
};

/** A condition to be used against `Trace` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TraceCondition = {
  /** Checks for equality with the object’s `action` field. */
  action?: InputMaybe<Traceaction>;
  /** Checks for equality with the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `createdTime` field. */
  createdTime?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `emailMessageId` field. */
  emailMessageId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `profileId` field. */
  profileId?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<Scalars['Datetime']['input']>;
};

export type TraceDistinctCountAggregates = {
  __typename?: 'TraceDistinctCountAggregates';
  /** Distinct count of action across the matching connection */
  action?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of campaignMemberId across the matching connection */
  campaignMemberId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of createdTime across the matching connection */
  createdTime?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of emailMessageId across the matching connection */
  emailMessageId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of profileId across the matching connection */
  profileId?: Maybe<Scalars['BigInt']['output']>;
  /** Distinct count of updatedTime across the matching connection */
  updatedTime?: Maybe<Scalars['BigInt']['output']>;
};

/** A filter to be used against `Trace` object types. All fields are combined with a logical ‘and.’ */
export type TraceFilter = {
  /** Filter by the object’s `action` field. */
  action?: InputMaybe<TraceactionFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TraceFilter>>;
  /** Filter by the object’s `campaignMemberId` field. */
  campaignMemberId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdTime` field. */
  createdTime?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `emailMessageId` field. */
  emailMessageId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TraceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TraceFilter>>;
  /** Filter by the object’s `profileId` field. */
  profileId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedTime` field. */
  updatedTime?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Trace` for usage during aggregation. */
export enum TraceGroupBy {
  Action = 'ACTION',
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CreatedTime = 'CREATED_TIME',
  CreatedTimeTruncatedToDay = 'CREATED_TIME_TRUNCATED_TO_DAY',
  CreatedTimeTruncatedToHour = 'CREATED_TIME_TRUNCATED_TO_HOUR',
  EmailMessageId = 'EMAIL_MESSAGE_ID',
  ProfileId = 'PROFILE_ID',
  UpdatedTime = 'UPDATED_TIME',
  UpdatedTimeTruncatedToDay = 'UPDATED_TIME_TRUNCATED_TO_DAY',
  UpdatedTimeTruncatedToHour = 'UPDATED_TIME_TRUNCATED_TO_HOUR'
}

export type TraceHavingAverageInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TraceHavingDistinctCountInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Trace` aggregates. */
export type TraceHavingInput = {
  AND?: InputMaybe<Array<TraceHavingInput>>;
  OR?: InputMaybe<Array<TraceHavingInput>>;
  average?: InputMaybe<TraceHavingAverageInput>;
  distinctCount?: InputMaybe<TraceHavingDistinctCountInput>;
  max?: InputMaybe<TraceHavingMaxInput>;
  min?: InputMaybe<TraceHavingMinInput>;
  stddevPopulation?: InputMaybe<TraceHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<TraceHavingStddevSampleInput>;
  sum?: InputMaybe<TraceHavingSumInput>;
  variancePopulation?: InputMaybe<TraceHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<TraceHavingVarianceSampleInput>;
};

export type TraceHavingMaxInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TraceHavingMinInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TraceHavingStddevPopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TraceHavingStddevSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TraceHavingSumInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TraceHavingVariancePopulationInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

export type TraceHavingVarianceSampleInput = {
  createdTime?: InputMaybe<HavingDatetimeFilter>;
  updatedTime?: InputMaybe<HavingDatetimeFilter>;
};

/** Specifies a sort for the `Object` type - what should we sort by, should it be ascending or descending, and how should we handle nulls? */
export type TraceSort = {
  ascending?: Scalars['Boolean']['input'];
  nulls?: SortNulls;
  sortBy: TraceSortBy;
};

/** The specifier of what we should sort by.  Exactly one of these values must be specified and non-null (this will use `@oneOf` [when that feature is merged into GraphQL](https://github.com/graphql/graphql-spec/pull/825)). */
export type TraceSortBy = {
  field?: InputMaybe<TraceSortableField>;
};

/** Sortable concrete fields for the `Trace` type. */
export enum TraceSortableField {
  Action = 'ACTION',
  CampaignMemberId = 'CAMPAIGN_MEMBER_ID',
  CreatedTime = 'CREATED_TIME',
  EmailMessageId = 'EMAIL_MESSAGE_ID',
  Id = 'ID',
  ProfileId = 'PROFILE_ID',
  UpdatedTime = 'UPDATED_TIME'
}

export enum Traceaction {
  CampaignMemberCharged = 'CAMPAIGN_MEMBER_CHARGED',
  EmailMessageCc = 'EMAIL_MESSAGE_CC',
  EmailMessageForward = 'EMAIL_MESSAGE_FORWARD',
  EmailMessagePatch = 'EMAIL_MESSAGE_PATCH',
  EmailMessageReply = 'EMAIL_MESSAGE_REPLY',
  EmailMessageReview = 'EMAIL_MESSAGE_REVIEW'
}

/** A filter to be used against Traceaction fields. All fields are combined with a logical ‘and.’ */
export type TraceactionFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Traceaction>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Traceaction>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Traceaction>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Traceaction>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Traceaction>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Traceaction>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Traceaction>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Traceaction>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Traceaction>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Traceaction>>;
};

/** A connection to a list of `Trace` values. */
export type TracesConnection = {
  __typename?: 'TracesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TraceAggregates>;
  /** A list of edges which contains the `Trace` and cursor to aid in pagination. */
  edges: Array<TracesEdge>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TraceAggregates>>;
  /** A list of `Trace` objects. */
  nodes: Array<Maybe<Trace>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Trace` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};


/** A connection to a list of `Trace` values. */
export type TracesConnectionGroupedAggregatesArgs = {
  groupBy: Array<TraceGroupBy>;
  having?: InputMaybe<TraceHavingInput>;
};

/** A `Trace` edge in the connection. */
export type TracesEdge = {
  __typename?: 'TracesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  fullName?: Maybe<Scalars['Boolean']['output']>;
  /** The `Trace` at the end of the edge. */
  node?: Maybe<Trace>;
};

/** Methods to use when ordering `Trace`. */
export enum TracesOrderBy {
  ActionAsc = 'ACTION_ASC',
  ActionDesc = 'ACTION_DESC',
  CampaignMemberIdAsc = 'CAMPAIGN_MEMBER_ID_ASC',
  CampaignMemberIdDesc = 'CAMPAIGN_MEMBER_ID_DESC',
  CreatedTimeAsc = 'CREATED_TIME_ASC',
  CreatedTimeDesc = 'CREATED_TIME_DESC',
  EmailMessageIdAsc = 'EMAIL_MESSAGE_ID_ASC',
  EmailMessageIdDesc = 'EMAIL_MESSAGE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProfileIdAsc = 'PROFILE_ID_ASC',
  ProfileIdDesc = 'PROFILE_ID_DESC',
  UpdatedTimeAsc = 'UPDATED_TIME_ASC',
  UpdatedTimeDesc = 'UPDATED_TIME_DESC'
}

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export enum VerificationType {
  BounceCheck = 'BOUNCE_CHECK',
  DelayedBounceCheck = 'DELAYED_BOUNCE_CHECK',
  NoCheck = 'NO_CHECK'
}

/** A filter to be used against VerificationType fields. All fields are combined with a logical ‘and.’ */
export type VerificationTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<VerificationType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<VerificationType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<VerificationType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<VerificationType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<VerificationType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<VerificationType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<VerificationType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<VerificationType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<VerificationType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<VerificationType>>;
};

export enum VisibilityState {
  Blurred = 'BLURRED',
  Free = 'FREE',
  FreeExtra = 'FREE_EXTRA',
  Hidden = 'HIDDEN',
  Refunded = 'REFUNDED',
  Visible = 'VISIBLE'
}

/** A filter to be used against VisibilityState fields. All fields are combined with a logical ‘and.’ */
export type VisibilityStateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<VisibilityState>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<VisibilityState>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<VisibilityState>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<VisibilityState>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<VisibilityState>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<VisibilityState>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<VisibilityState>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<VisibilityState>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<VisibilityState>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<VisibilityState>>;
};

export type GetCampaignMemberMeetingQueryVariables = Exact<{
  campaignMemberMeetingId: Scalars['UUID']['input'];
}>;


export type GetCampaignMemberMeetingQuery = { __typename?: 'Query', campaignMemberMeeting?: { __typename?: 'CampaignMemberMeeting', id: any, description?: string | null, endTime?: any | null, startTime?: any | null, status: Campaignmembermeetingstatus, summary?: string | null, updatedTime: any, campaignMemberId: any, schedulingUrl?: string | null, campaignMember?: { __typename?: 'CampaignMember', id: any, campaignId: any, contactId: any, contact?: { __typename?: 'Contact', id: any, firstName?: string | null, lastName?: string | null, email?: string | null, timezone?: string | null, title?: string | null, photoUrl?: string | null, company?: { __typename?: 'Company', name: string, id: any } | null } | null, campaign?: { __typename?: 'Campaign', id: any, lawyer?: { __typename?: 'Lawyer', id: any, firmName?: string | null, name: string, logoSrc?: string | null } | null } | null } | null } | null };

export type GetLawyerByPublicIdQueryVariables = Exact<{
  publicId: Scalars['String']['input'];
}>;


export type GetLawyerByPublicIdQuery = { __typename?: 'Query', allLawyers?: { __typename?: 'LawyersConnection', nodes: Array<{ __typename?: 'Lawyer', id: any, name: string, firmName?: string | null, logoSrc?: string | null, status: LawyerStatus } | null> } | null };


export const GetCampaignMemberMeetingDocument = gql`
    query GetCampaignMemberMeeting($campaignMemberMeetingId: UUID!) {
  campaignMemberMeeting: campaignMemberMeetingById(id: $campaignMemberMeetingId) {
    id
    description
    endTime
    startTime
    status
    summary
    updatedTime
    campaignMemberId
    schedulingUrl
    campaignMember: campaignMemberByCampaignMemberId {
      id
      campaignId
      contactId
      contact: contactByContactId {
        id
        firstName
        lastName
        email
        timezone
        title
        photoUrl
        company: companyByCompanyId {
          name
          id
        }
      }
      campaign: campaignByCampaignId {
        id
        lawyer: lawyerByLawyerId {
          id
          firmName
          name
          logoSrc
        }
      }
    }
  }
}
    `;

/**
 * __useGetCampaignMemberMeetingQuery__
 *
 * To run a query within a React component, call `useGetCampaignMemberMeetingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignMemberMeetingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignMemberMeetingQuery({
 *   variables: {
 *      campaignMemberMeetingId: // value for 'campaignMemberMeetingId'
 *   },
 * });
 */
export function useGetCampaignMemberMeetingQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignMemberMeetingQuery, GetCampaignMemberMeetingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignMemberMeetingQuery, GetCampaignMemberMeetingQueryVariables>(GetCampaignMemberMeetingDocument, options);
      }
export function useGetCampaignMemberMeetingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignMemberMeetingQuery, GetCampaignMemberMeetingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignMemberMeetingQuery, GetCampaignMemberMeetingQueryVariables>(GetCampaignMemberMeetingDocument, options);
        }
export type GetCampaignMemberMeetingQueryHookResult = ReturnType<typeof useGetCampaignMemberMeetingQuery>;
export type GetCampaignMemberMeetingLazyQueryHookResult = ReturnType<typeof useGetCampaignMemberMeetingLazyQuery>;
export type GetCampaignMemberMeetingQueryResult = Apollo.QueryResult<GetCampaignMemberMeetingQuery, GetCampaignMemberMeetingQueryVariables>;
export const GetLawyerByPublicIdDocument = gql`
    query GetLawyerByPublicId($publicId: String!) {
  allLawyers(filter: {publicId: {equalTo: $publicId}}, first: 1) {
    nodes {
      id
      name
      firmName
      logoSrc
      status
    }
  }
}
    `;

/**
 * __useGetLawyerByPublicIdQuery__
 *
 * To run a query within a React component, call `useGetLawyerByPublicIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLawyerByPublicIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLawyerByPublicIdQuery({
 *   variables: {
 *      publicId: // value for 'publicId'
 *   },
 * });
 */
export function useGetLawyerByPublicIdQuery(baseOptions: Apollo.QueryHookOptions<GetLawyerByPublicIdQuery, GetLawyerByPublicIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLawyerByPublicIdQuery, GetLawyerByPublicIdQueryVariables>(GetLawyerByPublicIdDocument, options);
      }
export function useGetLawyerByPublicIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLawyerByPublicIdQuery, GetLawyerByPublicIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLawyerByPublicIdQuery, GetLawyerByPublicIdQueryVariables>(GetLawyerByPublicIdDocument, options);
        }
export type GetLawyerByPublicIdQueryHookResult = ReturnType<typeof useGetLawyerByPublicIdQuery>;
export type GetLawyerByPublicIdLazyQueryHookResult = ReturnType<typeof useGetLawyerByPublicIdLazyQuery>;
export type GetLawyerByPublicIdQueryResult = Apollo.QueryResult<GetLawyerByPublicIdQuery, GetLawyerByPublicIdQueryVariables>;