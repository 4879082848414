import { ThemeOptions } from "@mui/material";

export default {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: "none",
        whiteSpace: "nowrap",
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        backgroundColor: "white",
        alignItems: "center",
        borderColor: "#E5E5E5",
        boxShadow: "none",
        ".MuiAlert-message": {
          width: "100%",
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
        whiteSpace: "nowrap",
        "&.Mui-selected": {
          fontWeight: "bold",
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: "#fff",
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      filled: {
        color: "#FFFFFF",
        avatar: {
          color: "#FFFFFF",
        },
      },
      avatar: {
        color: "inherit",
      },
      colorWarning: {
        backgroundColor: "#FFD700",
        color: "#776713",
      },
    },
  },
} as ThemeOptions["components"];
