import { OutreachMessage } from "@/models";
import { DateTime } from "luxon";

export const getDisplayDate = (utcString: string) => {
  return DateTime.fromISO(utcString).toLocaleString(DateTime.DATETIME_MED);
};

export const getMeetingDisplayDate = (
  utcStartTime: Date,
  utcEndTime: Date,
  timezoneStr?: string,
) => {
  const timeZone = timezoneStr ?? DateTime.local().zoneName;

  // Create DateTime objects from UTC string inputs
  const startDate = DateTime.fromJSDate(utcStartTime, { zone: 'utc' }).setZone(timeZone);
  const endDate = DateTime.fromJSDate(utcEndTime, { zone: 'utc' }).setZone(timeZone);

  // Format the components
  const day = startDate.toLocaleString(DateTime.DATE_MED); // Full weekday name
  const startTime = startDate.toLocaleString(DateTime.TIME_SIMPLE); // Hour:Minute AM/PM
  const endTime = endDate.toLocaleString(DateTime.TIME_SIMPLE); // Hour:Minute AM/PM
  const timeZoneInfo = startDate.toFormat('ZZZZ'); // Abbreviated time zone name

  // Construct the final string
  return `${day}, ${startTime} - ${endTime} ${timeZoneInfo}`;
};

export const getMessageDisplayDate = (
  message: OutreachMessage | null | undefined
) => {
  if (!message) {
    return null;
  }

  const { sentTime, sendAt } = message;

  return [sentTime?.toISOString(), sendAt?.toISOString()]
    .filter(Boolean)
    .map((sentTime) => getDisplayDate(sentTime!))[0];
};

export function formatTimestamp(
  timestamp: number,
  timezone?: string | null
): string {
  if (!timestamp) {
    return "";
  }

  const timeZone = timezone || "utc";
  //
  const date = DateTime.fromSeconds(timestamp, { zone: "utc" });
  const rezoned = date.setZone(timeZone);
  return rezoned.toFormat("D");
}

// Value can be any type, but we only want to parse strings
export const tryParseDate = (value: any): Date | string | null | undefined => {
  // If Date object
  if (value instanceof Date) {
    return new Date(value.toUTCString());
  }

  if (typeof value !== "string") {
    return value; // Return the original value if it's not a string
  }

  try {
    const dateValue = DateTime.fromISO(value, { zone: 'utc' }).toJSDate();
    if (!isNaN(dateValue.getTime())) {
      return dateValue;
    } else {
      return value; // Return the original string if parsing fails
    }
  } catch (e) {
    return value; // Return the original string if an error occurs during parsing
  }
};
