import { ThemeOptions } from "@mui/material/styles";

import components from "./components";
import palette, { AppPaletteOptions } from "./palette";
import typography from "./theme_typography";

type AppThemeOptions = ThemeOptions & {
  palette: AppPaletteOptions;
};

export const themeOptions: AppThemeOptions = {
  typography,
  palette,
  components,
};
